import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext'

import Correction_Bill_18_04_2024_Marks_Item from './_correction_bill_18_04_2024_mark_item.jsx'









const chyronsFacturated = {
  'T000348-V1-0033GLE_de': true,
  'T000348-V1-0033GLE_es': true,
  'T000348-V1-0033GLE_pt_br': true,
  'T000348-V1-0033GLE_pt_pt': true,
  'T000347-V1-0001SPU_de': true,
  'T000347-V1-0001SPU_it': true,
  'T000347-V1-0001SPU_pt_br': true,
  'T000347-V1-0001SPU_pt_pt': true,
  'T000353-V1-0001SPU_de': [104],
  'T000353-V1-0001SPU_en': [104],
  'T000353-V1-0001SPU_it': [104],
  'T000353-V1-0001SPU_pt_br': [104],
  'T000353-V1-0001SPU_pt_pt': [104],
  'T000161-V1-0066DMO_en': true,
  'T000175-V0-0008IFU_en': true,
  'T000164-V0-0074JBA_en': true,
  'T000164-V0-0093VAM_en': true,
  'T000203-V0-0101ANU_en': true,
  'T000199-V0-0090AGO_en': true,
  'T000186-V0-0092AVE_en': true,
  'T000217-V0-0102CBO_en': true,
  'T000197-V0-0017ECO_en': true,
  'T000186-V0-0091ERU_en': true,
  'T000159-V0-0095GPO_en': true,
  'T000201-V0-0019JPI_en': true,
  'T000220-V1-0016JMO_en': true,
  'T000187-V1_en': [56],
  'T000094-V0-0100ABA_en': true,
  'T000062-V0-0056ASO_en': true,
  'T000091-V0-0129ARE_en': true,
  'T000091-V1-0129ARE_en': true,
  'TOPIC068-1-JTO_en': true,
  'T000004-V1-0042PAG_en': true,
  'TOPIC064-1-SG_en': true,
}


 







const Correction_Bill_18_04_2024_Mark_List_Static = ({ moduleID, dataModules }) => {

  const { getJsonString } = useContext(AuthContext)
  const { remove_tags, format_texts, formatNumbersToPrint } = useContext(FuncContext)


  const [dataVideos, setDataVideos] = useState(null);
  const [dataVideoMarks, setDataVideoMarks] = useState(null);
  

  const [wordsList, setWordList] = useState(null);
  const [history, setHistory] = useState(null);
  const [totalPrices, setTotalPrices] = useState(null);








  useEffect(() => {    
    fetch('/var_videos_list.json', { headers : { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then(response => response.json()).then(function(myJson) {
      setDataVideos((myJson)? myJson : null)
    });
    
    fetch('/var_marks_list.json', { headers : { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then(response => response.json()).then(function(myJson) {
      setDataVideoMarks((myJson)? myJson : null)
    });
  }, [])






  useEffect(() => {    
    const videosTMP = (dataVideos?.getCustomerVideos?.length)? dataVideos?.getCustomerVideos : null;
    const marksTMP = (dataVideoMarks?.videoMarksForMotions?.length)? dataVideoMarks?.videoMarksForMotions?.sort((a, b) => (a?.time < b?.time)? -1 : (a?.time > b?.time)? 1 : 0 ) : null;
    const endDates = { 2: '31/01/2024', 32: '27/03/2024', 35: '08/03/2024', 36: '27/03/2024'}
    const checkPrice = true;
    var wordsListTMP = {};
    var totalPriceTMP = { 2: 0, 32: 0, 35: 0, 36: 0, total: 0, total_words_facturated: 0, words_clear: 0, start_price: 0, end_price: 0 };

    
    function formatCleanText(text, removeSymbols) {
      return (
        (removeSymbols)? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)
        : remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.split(' ')?.filter((el) => el)
      )
    }


    if (videosTMP?.length && marksTMP?.length) {
      for (var v in videosTMP) {
        if (videosTMP[v]?.factura10_product_id && parseInt(videosTMP[v]?.module_id) === parseInt(moduleID)) {
          const endDateFormated = endDates[videosTMP[v]?.module_id]
          const langKey = videosTMP[v]?.language;
          const originalLangKey = (videosTMP[v]?.is_translation_of)? ((videosTMP[v]?.language === 'esp')? 'eng' : 'esp') : null;
          const ogLangKey = videosTMP[v]?.original_language;
          var hasSomeError = false;

          const removeSymbols = (videosTMP[v]?.module_id === 2 || videosTMP[v]?.module_id === 32)? true : false

          const start_price = (
            (videosTMP[v]?.module_id === 36)
            || (langKey === 'eng' && (ogLangKey === 'esp' || !ogLangKey))
            || (langKey === 'eng' && (ogLangKey === 'esp' || !ogLangKey))
          )? 0.14 : 0.22

          const end_price = (
            (langKey === 'esp' && (ogLangKey === 'eng' || !ogLangKey))
            || (langKey === 'eng' && (ogLangKey === 'esp' || !ogLangKey))
          )? 0.14 : 0.22


          if (!wordsListTMP[videosTMP[v]?.module_id]) { wordsListTMP[videosTMP[v]?.module_id] = {} }
          if (!wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name]) { wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name] = [] }

          if (!videosTMP[v]?.title_disabled && videosTMP[v]?.title) {
            const text = (videosTMP[v]?.old_title)? videosTMP[v]?.old_title : videosTMP[v]?.title;
            const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text.replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.split(' ') : 0;
            const numberOfWordsCleanTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? formatCleanText(text, removeSymbols) : 0;
           
            if (numberOfWordsTMP?.length && numberOfWordsCleanTMP?.length) {
              const hasError = (numberOfWordsTMP?.length !== numberOfWordsCleanTMP?.length);
              const diffPrice = ((numberOfWordsCleanTMP?.length * end_price) - (numberOfWordsTMP?.length * start_price));

              wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name]?.push({ 
                id: videosTMP[v]?.id,
                type: 'Título del vídeo',
                video: videosTMP[v],
                name: videosTMP[v]?.name,

                words_facturated: (numberOfWordsTMP)? numberOfWordsTMP : 0,
                words_clear: (numberOfWordsCleanTMP)? numberOfWordsCleanTMP : 0,
                price_diff: diffPrice,
                start_price: start_price,
                end_price: end_price,
              })

              if (!checkPrice || !((videosTMP[v]?.module_id !== 36 && !hasError) || (videosTMP[v]?.module_id === 36 && videosTMP[v]?.original_language === 'esp' && videosTMP[v]?.language === 'eng'))) {
                hasSomeError = true;
                totalPriceTMP['total'] = totalPriceTMP['total'] + diffPrice;
                totalPriceTMP['total_words_facturated'] = totalPriceTMP['total_words_facturated'] + numberOfWordsTMP?.length;
                totalPriceTMP['words_clear'] = totalPriceTMP['words_clear'] + numberOfWordsCleanTMP?.length;
                totalPriceTMP['start_price'] = totalPriceTMP['start_price'] + (numberOfWordsTMP?.length * start_price);
                totalPriceTMP['end_price'] = totalPriceTMP['end_price'] + (numberOfWordsCleanTMP?.length * end_price);
              }
            }
          }


          const profeTMP = videosTMP[v]?.professor;
          const facturateChyron = chyronsFacturated[videosTMP[v]?.name];
          if (profeTMP && facturateChyron) {
            if (facturateChyron === true || facturateChyron?.includes(parseInt(profeTMP?.id))) {
              const positions = (profeTMP?.positions)? getJsonString(profeTMP?.positions, true) : null 
              
              const text = (positions && positions[langKey]?.text)? positions[langKey]?.text : (positions && positions[langKey]?.old_text)? positions[langKey]?.old_text : '';
              const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text.replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.split(' ') : 0;
              const numberOfWordsCleanTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? formatCleanText(text, removeSymbols) : 0;
              
              if (numberOfWordsTMP?.length && numberOfWordsCleanTMP?.length) {
                const hasError = (numberOfWordsTMP?.length !== numberOfWordsCleanTMP?.length);
                const diffPrice = ((numberOfWordsCleanTMP?.length * end_price) - (numberOfWordsTMP?.length * start_price));

                wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name]?.push({ 
                  id: profeTMP?.id,
                  type: 'Chyron de profesor',
                  video: videosTMP[v],
                  name: profeTMP?.name,
                  
                  words_facturated: (numberOfWordsTMP)? numberOfWordsTMP : 0,
                  words_clear: (numberOfWordsCleanTMP)? numberOfWordsCleanTMP : 0,
                  price_diff: diffPrice,
                  start_price: start_price,
                  end_price: end_price,
                })

                if (!checkPrice || !((videosTMP[v]?.module_id !== 36 && !hasError) || (videosTMP[v]?.module_id === 36 && videosTMP[v]?.original_language === 'esp' && videosTMP[v]?.language === 'eng'))) {
                  hasSomeError = true;
                  totalPriceTMP['total'] = totalPriceTMP['total'] + diffPrice;
                  totalPriceTMP['total_words_facturated'] = totalPriceTMP['total_words_facturated'] + numberOfWordsTMP?.length;
                  totalPriceTMP['words_clear'] = totalPriceTMP['words_clear'] + numberOfWordsCleanTMP?.length;
                  totalPriceTMP['start_price'] = totalPriceTMP['start_price'] + (numberOfWordsTMP?.length * start_price);
                  totalPriceTMP['end_price'] = totalPriceTMP['end_price'] + (numberOfWordsCleanTMP?.length * end_price);
                }
              }
            }

            for (var p in videosTMP[v]?.extraProfessors) {
              const profeTMP = videosTMP[v]?.extraProfessors[p];

              if (!facturateChyron?.length || facturateChyron?.includes(parseInt(profeTMP?.id))) {
                const positions = (profeTMP?.positions)? getJsonString(profeTMP?.positions, true) : null 
                
                const text = (positions && positions[langKey]?.text)? positions[langKey]?.text : (positions && positions[langKey]?.old_text)? positions[langKey]?.old_text : '';
                const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text.replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.split(' ') : 0;
                const numberOfWordsCleanTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? formatCleanText(text, removeSymbols) : 0;
              
                if (numberOfWordsTMP?.length && numberOfWordsCleanTMP?.length) {
                  const hasError = (numberOfWordsTMP?.length !== numberOfWordsCleanTMP?.length);
                  const diffPrice = ((numberOfWordsCleanTMP?.length * end_price) - (numberOfWordsTMP?.length * start_price));

                  wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name]?.push({ 
                    id: profeTMP?.id, 
                    type: 'Chyron de profesor', 
                    video: videosTMP[v], 
                    name: profeTMP?.name, 

                    words_facturated: (numberOfWordsTMP)? numberOfWordsTMP : 0,
                    words_clear: (numberOfWordsCleanTMP)? numberOfWordsCleanTMP : 0,
                    price_diff: diffPrice,
                    start_price: start_price,
                    end_price: end_price,
                  })

                  if (!checkPrice || !((videosTMP[v]?.module_id !== 36 && !hasError) || (videosTMP[v]?.module_id === 36 && videosTMP[v]?.original_language === 'esp' && videosTMP[v]?.language === 'eng'))) {
                    hasSomeError = true;
                    totalPriceTMP['total'] = totalPriceTMP['total'] + diffPrice;
                    totalPriceTMP['total_words_facturated'] = totalPriceTMP['total_words_facturated'] + numberOfWordsTMP?.length;
                    totalPriceTMP['words_clear'] = totalPriceTMP['words_clear'] + numberOfWordsCleanTMP?.length;
                    totalPriceTMP['start_price'] = totalPriceTMP['start_price'] + (numberOfWordsTMP?.length * start_price);
                    totalPriceTMP['end_price'] = totalPriceTMP['end_price'] + (numberOfWordsCleanTMP?.length * end_price);
                  }
                }

              }
            }
          }



          for (var i in marksTMP) {
            const markTMP = marksTMP[i];
            if (parseInt(markTMP?.video_id) === parseInt(videosTMP[v]?.id)) {
              const texts = (markTMP?.texts)? getJsonString(markTMP?.texts, true) : null 
              const markDate = new Date(markTMP?.createdAt)
              const markDateFormated = (("0" + markDate.getDate()).slice(-2) + '/' + ("0" + (markDate.getMonth() + 1)).slice(-2) + '/' + markDate.getFullYear())
          
              var text = (texts && texts[langKey]?.text)? texts[langKey]?.text : ((texts && texts[langKey]?.old_text)? texts[langKey]?.old_text : '');
              var forceNegative = false

              const numberOfWordsTMP = (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text.replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.split(' ') : 0;
              const numberOfWordsCleanTMP = (forceNegative)? [] : (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? formatCleanText(text, removeSymbols) : 0;
              
              if ((numberOfWordsTMP?.length && numberOfWordsCleanTMP?.length) || forceNegative) {
                const hasError = (forceNegative)? true : (numberOfWordsTMP?.length !== numberOfWordsCleanTMP?.length && parseInt(markTMP?.state) === 3 && markDateFormated <= endDateFormated);
                var diffPrice = ((numberOfWordsCleanTMP?.length * end_price) - (numberOfWordsTMP?.length * start_price));
               
                wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name]?.push({ 
                  id: markTMP?.id, 
                  type: 'Marca',
                  video: videosTMP[v],
                  name: markTMP?.search_type_name,
                  time: markTMP?.time,
  
                  words_facturated: (numberOfWordsTMP)? numberOfWordsTMP : 0,
                  words_clear: (numberOfWordsCleanTMP)? numberOfWordsCleanTMP : 0,
                  price_diff: diffPrice,
                  start_price: start_price,
                  end_price: end_price,
                })
  
                if (!checkPrice || !((videosTMP[v]?.module_id !== 36 && !hasError) || (videosTMP[v]?.module_id === 36 && videosTMP[v]?.original_language === 'esp' && videosTMP[v]?.language === 'eng'))) {
                  hasSomeError = true;
                  totalPriceTMP['total'] = totalPriceTMP['total'] + diffPrice;
                  totalPriceTMP['total_words_facturated'] = totalPriceTMP['total_words_facturated'] + numberOfWordsTMP?.length;
                  totalPriceTMP['words_clear'] = totalPriceTMP['words_clear'] + numberOfWordsCleanTMP?.length;
                  totalPriceTMP['start_price'] = totalPriceTMP['start_price'] + (numberOfWordsTMP?.length * start_price);
                  totalPriceTMP['end_price'] = totalPriceTMP['end_price'] + (numberOfWordsCleanTMP?.length * end_price);
                }
              }
            }
          }


          if (checkPrice && ((videosTMP[v]?.module_id !== 36 && !hasSomeError) || (videosTMP[v]?.module_id === 36 && videosTMP[v]?.original_language === 'esp' && videosTMP[v]?.language === 'eng'))) {
            delete wordsListTMP[videosTMP[v]?.module_id][videosTMP[v]?.name];
          }
        }
      }

      setTotalPrices(totalPriceTMP)
      setWordList(wordsListTMP)
    }

  }, [dataVideoMarks, dataVideos])









  return (
    <div className='w-full flex justify-center pb-[120px]'>
      <div className='w-full max-w-[1300px] text-gray-700 font-bold p-4'>


        {(wordsList && Object.keys(wordsList)?.length)? 


          <div className='space-y-5'>
            {Object.keys(wordsList)?.map((moduleKey, key) => {
              if (parseInt(moduleID) === parseInt(moduleKey)) {
                const videoList = wordsList[moduleKey]
                const module = (dataModules?.getCustomerModules?.length)? dataModules?.getCustomerModules?.filter((el) => parseInt(el?.id) === parseInt(moduleKey))[0] : null


                return <div key={'module-box-' + moduleKey}>
                  <div className={`w-full flex justify-between items-baseline text-2xl opacity-50 pb-2 mt-[80px]`}>
                    <p>{module?.name}</p>
                    {/* <p>{formatNumbersToPrint(totalPrices[module?.id], 2)} €</p> */}
                  </div>


                  <div className='pb-4 space-y-4 text-sm'>
                    <div class="font-normal space-y-1 bg-gray-200 rounded px-4 py-3">
                      <div class="w-full flex justify-between space-x-8 text-xl font-bold">
                        <p>Base imponible:</p>
                        <p>{(totalPrices['total'] > 0)? '+' : ''} {formatNumbersToPrint(totalPrices['total'], 2)} €</p>
                      </div>
                      {/* <div class="w-full flex justify-between space-x-8 opacity-90">
                        <p>IVA (21%):</p>
                        <p>{(totalPrices['total'] > 0)? '+' : ''} {formatNumbersToPrint(totalPrices['total'] * 0.21, 2)} €</p>
                      </div>
                      <div class="w-full flex justify-between space-x-8 opacity-90">
                        <p>Importe total:</p>
                        <p>{(totalPrices['total'] > 0)? '+' : ''} {formatNumbersToPrint(totalPrices['total'] + (totalPrices['total'] * 0.21), 2)} €</p>
                      </div> */}
                    </div>

                    <div className='w-full flex justify-between space-x-4 font-normal'>
                      <p className='bg-gray-100 rounded px-3 py-[6px] whitespace-nowrap'>Precio facturado: {formatNumbersToPrint(totalPrices['start_price'], 2)} € <span className='opacity-70'>({formatNumbersToPrint(totalPrices['total_words_facturated'])} palabras a {formatNumbersToPrint(totalPrices['start_price'] / totalPrices['total_words_facturated'])} € x palabras)</span></p>
                      <p className='bg-gray-100 rounded px-3 py-[6px] whitespace-nowrap'>Precio real: {formatNumbersToPrint(totalPrices['end_price'], 2)} € <span className='opacity-70'>({formatNumbersToPrint(totalPrices['words_clear'])} palabras a {formatNumbersToPrint(totalPrices['end_price'] / totalPrices['words_clear'])} € x palabras)</span></p>
                    </div>
                  </div>


                  <div className='space-y-2'>
                    {Object.keys(videoList)?.map(videoKey => {
                      const list = videoList[videoKey];
                      const orgVideo = (list?.length)? list[0]?.video : null;

                      return (list?.length)?
                        <div key={'word-box-' + videoKey} className='space-y-2'>
                          <Correction_Bill_18_04_2024_Marks_Item
                            list={list}
                            videoKey={videoKey}
                            video={orgVideo}
                            history={history}
                            setHistory={setHistory}

                            og_language={orgVideo?.original_language}
                            language={orgVideo?.language}

                            canCheckBill={false}
                          />
                        </div>
                      : null 
                    })}
                  </div>
                </div>
              }
            })}
          </div>

          
        : null }
      </div>
    </div>
  )
}

export default Correction_Bill_18_04_2024_Mark_List_Static
