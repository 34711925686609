import { useState, useEffect } from 'react'

import VideoHomeSettingsListStateMenu from './VideoHomeSettingsListStateMenu';
import VimeoUrlSettingCell from './SettingsCells/VimeoUrlSettingCell';
import RevisedAndTranslated from './SettingsCells/RevisedAndTranslated';
import NumberOfWords from './SettingsCells/NumberOfWords';
import NumberOfMarksList from './NumberOfMarksList';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ProductionState from './SettingsCells/ProductionState';
import TipologyPlusSegment from './SettingsCells/TipologyPlusSegment';
import Factura10Info from './SettingsCells/Factura10Info';







const VideoHomeSettingsListStateItem = ({ 

  cellKey,
  settingState,
  settingsVar,
  clicksoundUserNames,
  video,

  bgSiluette,
  topTooltip,
  topTooltipDates,
  fill,
  small,
  hiddeDates,
  options,
  disabled,

}) => {



  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )




  const [effect, setEffect] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [actualStateItem, setActualStateItem] = useState(settingState);


  const notNeeded = (
    (!settingsVar?.automated && !video?.is_translation_of && settingsVar?.from_localization)
    || (!settingsVar?.automated && video?.is_translation_of && !settingsVar?.from_localization)
  )? true : false;

  
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });
  




  
  useEffect( () => { 
    if (settingState && parseInt(settingState?.id) !== parseInt(actualStateItem?.id)) {
      setActualStateItem(settingState)
    }
  }, [settingState])










  function renderObjectFunc() {
    return <div
      data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
      className={`relative flex-col items-center justify-center
        ${(fill)? 'w-full h-full' : ''}
        ${(small)? 'pr-1 pl-[2px] text-[12px]' : `px-[6px] text-[14px] 
          ${
            (fill && hiddeDates)? 'pt-[5px] pb-[5px]'
            : ((actualStateItem?.state?.includes('🔥') && !topTooltipDates) || actualStateItem?.state === '---')? 'pt-[10px] pb-[10px]' 
            : 'p-[5px]'
          }
        `}
      `}
    >
      <div
        data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
        className={`flex justify-center items-center space-x-[4px]`}
      >
        {(actualStateItem?.state)? 
          <p
            data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
          >
            {(options && actualStateItem?.state in options)? options[actualStateItem?.state] : actualStateItem?.state}
          </p> 
        :
          <p 
            data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language} 
            className='opacity-0'
          >⬜</p>
        }

        {(actualStateItem?.user_abbreviated_name && actualStateItem?.state && actualStateItem?.state !== '---')? 
          <p 
            data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language} 
            className='text-[11px] font-bold'
          >{actualStateItem?.user_abbreviated_name}</p>
        : null }
      </div>

      {(!small && !hiddeDates && actualStateItem?.state && actualStateItem?.updatedAtFormated && !actualStateItem?.state?.includes('🔥') && actualStateItem?.state !== '---')? 
        <div 
          data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language} 
          className='h-[10px] opacity-70 flex justify-center items-center'
        >
          <p 
            data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
            className='text-[10px] font-bold'
          >{actualStateItem?.updatedAtFormated}</p>
        </div>
      : null }
    </div>
  }




  

  return <>

  
    {(anchorEl && (!notNeeded || (actualStateItem?.state)) && settingsVar && !settingsVar?.automated)? 
      <div className={`flex relative ${(disabled)? 'opacity-50 pointer-events-none' : ''}`}>
        <VideoHomeSettingsListStateMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl} 
          settings={settingsVar}
          object={video}
          actualStateItem={actualStateItem}
          setActualStateItem={setActualStateItem}
          options={options}
          clicksoundUserNames={clicksoundUserNames}
        />
      </div>
    : null }


    {(!bgSiluette && settingsVar && !settingsVar?.automated && (!notNeeded || actualStateItem?.state))?
      <div
        className={`absolute w-full h-full z-50 pointer-events-none p-[2px]
          ${(disabled)? 'opacity-50 pointer-events-none' : ''}
        `}
      >
        <div 
          data-setting-id={settingsVar?.id} 
          data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
          id={'cell-button-' + settingsVar?.position + '-' + cellKey}
          className='w-full h-full pointer-events-none rounded bg-blue-500 opacity-0'
        ></div>
      </div>
    : null }
    



    <button
      data-setting-id={settingsVar?.id}
      data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
      id={((!notNeeded || actualStateItem?.state) && settingsVar && !settingsVar?.automated)? ('cell-' + settingsVar?.position + '-' + cellKey) : null}
      className={`flex justify-center items-center space-x-[6px] w-full h-full 
        ${(!small)? 'p-[2px]' : ''} 
        ${(notNeeded && !actualStateItem?.state)? 'pointer-events-none' : ''}
        ${(settingsVar?.token === 'number_of_marks' || (settingsVar?.token === 'number_of_marks_loc' && (video?.is_translation_of || video?.list_localizations?.length)))? 'max-w-[80px]' : ''}
        ${(disabled)? 'opacity-50 pointer-events-none' : ''}
      `}
    >
      {(settingsVar && !settingsVar?.automated && (!notNeeded || (actualStateItem?.state)))?
      
        <div 
          data-setting-id={settingsVar?.id}
          data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
          className={`relative text-base rounded whitespace-nowrap overflow-hidden
            ${(actualStateItem?.observations && !actualStateItem?.excel_import)? 'rounded-tr-lg' : ''}
            ${(effect || anchorEl)? "animate-wiggle bg-opacity-80 bg-blue-400 pointer-events-none text-white" 
            : `
               bg-opacity-30
              ${
                (!localCurrentUser?.rol?.includes('user'))? 'hover:bg-gray-500 hover:bg-opacity-30 active:bg-opacity-60' 
                : ''
              }
            `} 
            ${(bgSiluette && !(anchorEl && anchorEl?.id === 'cell-button-' + settingsVar?.position + '-' + cellKey))? `bg-gray-400 bg-opacity-[0.25] ` : ''}
            ${(small)? 'text-gray-500' : `text-gray-600`} 
            ${(fill)? 'w-full h-full' : ''}
            ${(actualStateItem?.state)? (
                (!small && actualStateItem?.state && !small && !hiddeDates && actualStateItem?.state && actualStateItem?.updatedAtFormated && !actualStateItem?.state?.includes('🔥'))? 'py-[2px]' : ''
              ) 
              : ((small || bgSiluette)? '' 
              : 'pr-[20px] pb-[14px]')
            }
          `}
          onClick={(event) => { 
            setAnchorEl(event.currentTarget); 
            setEffect(true); 
          }}
          onAnimationEnd={() => setEffect(false)}
        >
          
          {(actualStateItem?.state && !actualStateItem?.excel_import && actualStateItem?.observations)?
            <div
              data-setting-id={settingsVar?.id}
              data-avoid={true} data-cellkey={cellKey} data-setting-position={settingsVar?.position} data-videoid={(video?.is_translation_of)? video?.is_translation_of : video?.id} data-video-language={video?.language}
              className='absolute top-[-9px] right-[-9px] bg-gray-500 opacity-50 p-2 rounded-sm'
            ></div>
          : null }

          {(!localCurrentUser?.rol?.includes('user') && topTooltip && ((actualStateItem?.observations && !actualStateItem?.observations?.includes('Importado: ')) || ((small || topTooltipDates) && actualStateItem?.change_label)))?
            <CustomWidthTooltip
              disableInteractive
              title={
                <div className='text-gray-300'>              
                  {(actualStateItem?.own_change_label)? <p>Creado por <span className='text-white'>{actualStateItem?.own_change_label}</span></p> : null}
                  {(parseInt(actualStateItem?.own_user_id) !== parseInt(actualStateItem?.user_id))? <p>Dirigido a <span className='text-white'>{actualStateItem?.change_label}</span></p> : null}
                  {(actualStateItem?.observations && !actualStateItem?.observations?.includes('Importado: '))? <hr className='my-[2px]'/> : null }
                  {(actualStateItem?.observations && !actualStateItem?.observations?.includes('Importado: '))? <p>{actualStateItem?.observations}</p> : null}
                </div>
              }
              placement="bottom"
              arrow
            >
              {renderObjectFunc()}
            </CustomWidthTooltip>
          :
            renderObjectFunc()
          }
        </div>



      : (notNeeded)?


        <p className='text-gray-400'>---</p>


      : (settingsVar && settingsVar?.automated)?



        (settingsVar?.token?.includes('vimeo_'))?


          <VimeoUrlSettingCell
            ogVideo={video}
            small={small}
            hiddeDates={hiddeDates}
            fill={fill}
            localization={(settingsVar?.token?.includes('_loc'))? true : false}
          />


        : (settingsVar?.token === 'revised' || settingsVar?.token === 'translated')?


          <RevisedAndTranslated
            ogVideo={video}
            small={small}
            hiddeDates={hiddeDates}
            fill={fill}
            localization={(settingsVar?.token?.includes('translated'))? true : false}
          />


        : (settingsVar?.token === 'number_of_marks' || settingsVar?.token === 'number_of_marks_loc')?


          (settingsVar?.token === 'number_of_marks' || (settingsVar?.token === 'number_of_marks_loc' && (video?.is_translation_of || video?.list_localizations?.length)))?
            <div className={`w-full h-full flex justify-center px-2 min-w-[110px] text-xs rounded ${(bgSiluette)? 'bg-gray-400 bg-opacity-[0.25]' : ''}`}>
              <NumberOfMarksList
                video={
                  (settingsVar?.token === 'number_of_marks_loc' && video?.list_localizations?.length && video?.list_localizations[0])? video?.list_localizations[0] 
                  : (settingsVar?.token === 'number_of_marks_loc' && video?.is_translation_of)? video
                  : video
                }
                showIcon={false}
                flex={true}
                pr={false}
              />
            </div>
          : <p className='text-gray-400'>---</p>


        : (settingsVar?.token === 'download')? 


          <div className=''>
            <a 
              className={`font-bold ${(video?.download_video)? '' : 'text-gray-300 pointer-events-none'}`}
              href={(video?.download_video)? `${process.env.REACT_APP_API_ROUTE}customer_media/${video?.customer_token}/video_files/video-${video?.id}/${video?.download_video}` : ''}
              target='_blank'
            >
              LINK
            </a>
            {(video?.download_video_change_label)? 
              <p className={`text-gray-400 text-[10px] leading-3 ${(fill)? 'h-[20px]' : 'h-[10px]'}`}>
                {video?.download_video_change_label?.split(' el ')[1]?.replaceAll('/20', '/')}
              </p> 
            : null}
          </div>


        : (settingsVar?.token === 'uploaded')?

          
          <div className={`w-full px-2 flex items-center justify-center space-x-1 text-gray-500 text-xs`}>
            <p className='opacity-70'>{video?.searchReviewDateFormated}</p>
            {(video?.search_review_version)?
              <p className='text-[10px] bg-gray-200 border border-gray-400 border-opacity-50 rounded px-[3px] py-[1px] font-bold'>
                V{video?.search_review_version}
              </p>
            : <p className='text-gray-400'>---</p> }
          </div>

        
        : (settingsVar?.token === 'number_of_words')?


          <NumberOfWords
            cellKey={cellKey}
            ogVideo={video}
            small={small}
            hiddeDates={hiddeDates}
            fill={fill}
          />


        : (settingsVar?.token === 'tipology_segment')?


          <TipologyPlusSegment
            video={video}
            small={small}
            fill={fill}
          />


        : (settingsVar?.token === 'production_state')?


          <ProductionState
            video={video}
            small={small}
            fill={fill}
          />


        : (settingsVar?.token === 'factura10_info' || settingsVar?.token === 'factura10_facturated')?


          <Factura10Info
            cellKey={cellKey}
            keyName={(settingsVar?.token === 'factura10_facturated')? 'factura10_facturated' : 'factura10'}
            video={video}
            small={small}
            fill={fill}
          />


        : <div>AUTO</div>




      : null }
    </button>
  </>
}

export default VideoHomeSettingsListStateItem
