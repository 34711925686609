import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

import UserProfilePic from './UserProfilePic'
import CustomerProfilePic from './CustomerProfilePic'

import Tooltip from '@mui/material/Tooltip';










export default function MarkCommentHeader({
  
  comment,
  publicReply

}) {
  

  
  const { currentUser } = useContext(AuthContext)
  const guest_name = (comment?.search_guest_data)? comment?.search_guest_data?.split(',')[1] : null

  const replyIconButton = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M798 856q-17 0-29.5-12.5T756 814V699q0-49-34.5-83.5T638 581H280l113 113q12 12 12 28.5T392 752q-12 12-29.5 12T333 752L150 569q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l184-184q12-12 29-12.5t30 12.5q12 12 12 29.5T393 384L280 497h358q84 0 143 59t59 143v115q0 17-12.5 29.5T798 856Z"/></svg>;
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.789-288Q495-288 505.5-298.35 516-308.7 516-324v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-528 454.5-517.65 444-507.3 444-492v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm0-312Q495-600 505.5-610.289q10.5-10.29 10.5-25.5Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.711q-10.5 10.29-10.5 25.5Q444-621 454.289-610.5q10.29 10.5 25.5 10.5Zm.487 504Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const visibilityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M481.118-318Q557-318 610-372.118q53-54.117 53-130Q663-578 609.882-631q-53.117-53-129-53Q405-684 351-630.882q-54 53.117-54 129 0 76.715 53.701 130.299Q404.402-318 481.118-318ZM481-393q-45.417 0-77.208-31.792Q372-456.583 372-502q0-44.583 31.792-75.792Q435.583-609 481-609q44.583 0 75.792 31.208Q588-546.583 588-502q0 45.417-31.208 77.208Q525.583-393 481-393Zm-1 193q-140 0-255-74T52-473q-2-5-3.5-12.5T47-501q0-8 1.5-15.5T52-529q58-125 173.312-198.5Q340.623-801 480-801q139.377 0 254.688 73.5Q850-654 908-529q2 5 3.5 12.5T913-501q0 8-1.5 15.5T908-473q-58 125-173 199t-255 74Z"/></svg>

  const tabs = (comment?.order)? comment?.order?.split(',')?.length : 0



  return (
    <div
      className={`flex justify-between items-center space-x-2 pr-1 py-1 text-xs sm:text-sm
        ${
          (comment?.id && !comment?.public)? 'bg-gradient-to-r from-purple-500 to-purple-300 text-white' 
          : (comment?.id && comment?.is_correction && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? 'bg-yellow-400 bg-opacity-80 text-white' 
          : 'bg-gradient-to-r from-[#0000001a] to-[#0000000a] text-gray-800'
        }
      `}
    >
      <div className='flex space-x-1 items-center truncate'>
        <div className={`ml-[5.5px] flex space-x-2 items-center duration-300 hover:opacity-[0.7]`}>
          <CustomerProfilePic customer={comment?.user?.from_customer} size={23} clickable={false} checkPictureExists={false} professor={(comment?.user)? comment.user.professor : (!comment?.id)? currentUser?.professor : null}/>
        </div>


        <div className={`ml-2 pl-[2px] pr-1 py-[2px] rounded flex space-x-1 items-center duration-300 cursor-pointer text-[15px] ${(parseInt(currentUser?.id) === comment?.user?.id || currentUser?.rol === 'admin')? 'hover:bg-gray-600 hover:bg-opacity-[0.2]' : ''}`} onClick={(e)=> (currentUser?.rol === 'admin')? comment?.user?.professor? window.open('/professor/'+comment?.user?.id, '_blank').focus() : window.open('/user/'+comment?.user?.id, '_blank').focus() : null}>
          {(!(comment?.user && parseInt(comment?.user?.id) !== 80 && !comment?.user?.rol?.includes('user') && currentUser?.rol?.includes('user')))?
            <UserProfilePic
              user={comment?.user}
              size={20}
              clickable={(currentUser?.rol === 'admin')? true : false} 
              checkPictureExists={false}
              className={`${(publicReply)? 'text-gray-500 ' : ''}`}
            /> 
          :  null}

          <p className='text-xs sm:text-sm'>
            {
              (comment?.user && parseInt(comment?.user?.id) !== 80 && !comment?.user?.rol?.includes('user') && currentUser?.rol?.includes('user'))? 'ClickSound'
              : (comment?.user)? comment.user.name 
              : (guest_name)? guest_name 
              : (!comment?.id || (parseInt(comment?.user_id) === parseInt(currentUser?.id)))? currentUser?.name
              : 'Guest'
            }
          </p>
        </div>

        {(comment?.createdAtFormated) &&
          <div className={`space-x-1 text-xs ${(tabs > 6)? 'hidden lg:hidden 2xl:flex' : 'hidden md:flex lg:hidden xl:flex'}`}>
              <Tooltip disableInteractive title={`Creado el ${comment?.createdAtFormated?.replaceAll(' a las', '')}h ${(comment?.createdAtFormated !== comment?.updatedAtFormated)? ` / última edición el ${comment?.updatedAtFormated?.replaceAll(' a las', '')}h${(comment?.edit_user_ids?.split(',')?.length)? ' por ' + comment?.edit_user_ids?.split(',')?.length + ' personas mas' : ''}` : ''}`} placement="bottom" arrow>
                <div className={`w-[160px] md:w-full lg:w-[160px] xl:w-full max-w-[280px] hidden sm:flex items-center ${(publicReply && !comment?.is_correction)? 'opacity-40' : 'opacity-60'} hover:opacity-100 duration-200`}>
                  <div className='scale-[0.8]'>{infoIcon}</div>
                  <p className='truncate'>
                    Creado el {comment?.createdAtFormated?.replaceAll(' a las', '')}h {(comment?.createdAtFormated !== comment?.updatedAtFormated)? ` / última edición el ${comment?.updatedAtFormated?.replaceAll(' a las', '')}h${(comment?.edit_user_ids?.split(',')?.length)? ' por ' + comment?.edit_user_ids?.split(',')?.length + ' personas mas' : ''}` : null}
                  </p>
                </div>
              </Tooltip>
          </div>
        }
      </div>


      <div className={`text-xs ${(tabs > 6)? 'hidden md:flex lg:hidden xl:flex' : 'hidden md:flex lg:hidden xl:flex'}`}>
        {(!comment?.public) && 
          <div className='flex items-center space-x-1 h-4 scale-[0.85] text-white'>
            {visibilityIcon}
            <p>Privada</p>
          </div>
        }

        <div className={`flex items-center space-x-1 h-4 scale-[0.85] ${(comment?.is_correction && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? 'text-white' : (comment?.is_correction)? 'text-yellow-500' : ''}`}>
          <div style={{ transform: 'rotate(180deg)' }}>{replyIconButton}</div>
          <p>
            {(comment?.is_correction)? 'Corrección' : 'Respuesta'}
          </p>
        </div>
      </div>
    </div>
  );
}