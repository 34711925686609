import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { Draggable } from "react-beautiful-dnd";
import { TagsInput } from "react-tag-input-component";

import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';




export default function CustomerSettingsListItem({ customer, cSetting, index, checkChangesFunc, removeItemFunc }) {
  

  const { currentUser } = useContext(AuthContext)


  const [tags, setTags] = useState((cSetting?.states?.split(',')?.length)? cSetting?.states?.split(',')?.map(element => { return element }) : []);
  
  const draggIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>

  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>

  const automatedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M192-384q-40 0-68-28t-28-68q0-40 28-68t68-28v-72q0-29.7 21.15-50.85Q234.3-720 264-720h120q0-40 28-68t68-28q40 0 68 28t28 68h120q29.7 0 50.85 21.15Q768-677.7 768-648v72q40 0 68 28t28 68q0 40-28 68t-68 28v168q0 29.7-21.162 50.85Q725.676-144 695.96-144H263.717Q234-144 213-165.15T192-216v-168Zm168-72q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm228 0q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14ZM371.96-312h215.757Q603-312 613.5-322.289q10.5-10.29 10.5-25.5Q624-363 613.662-373.5 603.323-384 588.04-384H372.283Q357-384 346.5-373.711q-10.5 10.29-10.5 25.5Q336-333 346.338-322.5 356.677-312 371.96-312Z"/></svg>;
  const manualIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M412-96q-22 0-41-9t-33-26L68-457q-10-11-7-26.5T75-510q17-17 41.5-20.5T162-521l126 74v-381q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q339-864 349.5-853.65 360-843.3 360-828v444q0 21.447-18 31.723Q324-342 306-353l-111-65 199 241q3.545 4.2 8.273 6.6Q407-168 412-168h259.619Q702-168 723-189.15T744-240v-348q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q795-624 805.5-613.65 816-603.3 816-588v348q0 60-42 102T672-96H412Zm58-242Zm5.789-382Q491-720 501.5-709.65 512-699.3 512-684v192q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q461-456 450.5-466.35 440-476.7 440-492v-192q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Zm152 48Q643-672 653.5-661.666q10.5 10.334 10.5 25.611v143.779Q664-477 653.711-466.5q-10.29 10.5-25.5 10.5Q613-456 602.5-466.334 592-476.668 592-491.945v-143.779Q592-651 602.289-661.5q10.29-10.5 25.5-10.5Z"/></svg>

  const originalVideoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m216-768 72 144h96l-72-144h72l72 144h96l-72-144h72l72 144h96l-72-144h144q29.7 0 50.85 21.5Q864-725 864-696v432q0 29-21.15 50.5T792-192H168q-29 0-50.5-21.5T96-264v-432q0-29 20.5-50.5T168-768h48Zm-48 216v288h624v-288H168Zm0 0v288-288Z"/></svg>
  const localizationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m608-213-33.105 93.407Q571-109 561.625-102.5T541-96q-20 0-30.5-16t-3.5-34l143-381q4.808-11 14.423-18t21.154-7h28.846q11.539 0 21.154 7T750-527l144 382q7 18-4.368 33.5Q878.263-96 859.316-96 847-96 837.5-103q-9.5-7-13.5-18l-32.211-92H608ZM360-405 194-241q-11 11-25 10.5T144-242q-11-11-11-25t11-25l167-165q-36-38-67-79t-54-89h82q18 32 36 54.5t52 60.5q38-42 70-87.5t52-98.5H84q-15.3 0-25.65-10.289Q48-716.579 48-731.789 48-747 58.35-757.5 68.7-768 84-768h240v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q375-864 385.5-853.65 396-843.3 396-828v60h240q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672-717 661.65-706.5 651.3-696 636-696h-77.567Q537-627 497-568.5T409-457l91 90-28 74-112-112Zm272 126h136l-66-189-70 189Z"/></svg>


  const softHiddenOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-300q-74 0-132-40.5T264-444q17-43 50-75.5t76-50.5l37 37q-35 11-63.5 34T317-444q24 45 67 70.5t96 25.5q29 0 54.5-8t47.5-22l34 35q-29 20-63.5 31.5T480-300Zm188-94-34-34q2-4 4.5-8t4.5-8q-18-34-48-57t-67-33l-54-54q74 0 135 40.5T696-444q-5 14-12.5 26.5T668-394ZM216-144q-30 0-51-21t-21-51v-498l-29-29q-11-11-11-25t11-25q11-11 26-11t26 11l627 628q11 11 11 25t-11 25q-11 11-25 11t-25-11l-30-29H216Zm0-72h426L216-642v426Zm600-30-72-72v-306H438L246-816h498q30 0 51 21t21 51v498Z"/></svg>
  const softHiddenOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm0-72h528v-456H216v456Zm263.88-84Q406-300 348-340.5T264-444q26-63 84.12-103.5 58.11-40.5 132-40.5Q554-588 612-547.5T696-444q-26 63-84.12 103.5-58.11 40.5-132 40.5Zm.12-84q-25 0-42.5-17.5T420-444q0-25 17.5-42.5T480-504q25 0 42.5 17.5T540-444q0 25-17.5 42.5T480-384Zm-.2 24q35.2 0 59.7-24.3 24.5-24.31 24.5-59.5 0-35.2-24.3-59.7-24.31-24.5-59.5-24.5-35.2 0-59.7 24.3-24.5 24.31-24.5 59.5 0 35.2 24.3 59.7 24.31 24.5 59.5 24.5Z"/></svg>

  const panelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm0-240h624v-264H168v264Z"/></svg>

  const billIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M244 976q-52 0-87.5-35.5T121 853v-87q0-17 12.5-29.5T163 724h76V209q0-15 8.5-15.5T267 204l32 32 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 32-32q11-11 19.5-10t8.5 15v644q0 52-35.5 87.5T716 976H244Zm472-84q17 0 28-11t11-28V299H323v425h312q17 0 29.5 12.5T677 766v87q0 17 11 28t28 11ZM400 460q-17 0-29-12.5T359 418q0-17 12.5-29.5T401 376h157q17 0 29.5 12.5T600 418q0 17-12.5 29.5T558 460H400Zm0 121q-17 0-29-12.5T359 539q0-17 12.5-29.5T401 497h157q17 0 29.5 12.5T600 539q0 17-12.5 29.5T558 581H400Zm279-121q-17 0-29.5-12.5T637 418q0-17 12.5-29.5T679 376q17 0 29.5 12.5T721 418q0 17-12.5 29.5T679 460Zm0 121q-17 0-29.5-12.5T637 539q0-17 12.5-29.5T679 497q17 0 29.5 12.5T721 539q0 17-12.5 29.5T679 581ZM244 892h349v-84H205v45q0 17 11 28t28 11Zm-39 0v-84 84Z"/></svg>




  useEffect(() => {
    if (tags?.join(',') !== cSetting?.states) {
      var cSettingTMP = {...cSetting}
      cSettingTMP.states = tags?.join(',');
      checkChangesFunc(cSettingTMP)
    }
  }, [tags])









  return <Draggable draggableId={cSetting.id.toString()} index={index}>
    {(provided, snapshot) => (
      

      <div 
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          userSelect: "none",
          margin: `0 0 8px 0`,

          ...provided.draggableProps.style
        }}
        className={`w-full whitespace-nowrap overflow-hidden cursor-pointer`}
      >
        <div className={`text-sm bg-white border border-gray-300 rounded`}>
          <div className='flex justify-between flex-wrap lg:flex-nowrap space-y-1 lg:space-y-0 p-1'>
            
            
            <div className={`w-full lg:w-auto min-w-[560px] flex items-center space-x-1 lg:pr-2 duration-200 ${(!cSetting?.visible)? 'opacity-30' : ''}`}>
              <div className='opacity-30 hover:opacity-70 select-none'>{draggIcon}</div>
              <div className='w-full duration-200 rounded overflow-hidden'>
                <InputBase
                  className='w-full bg-gray-200 hover:bg-gray-300 rounded duration-200'
                  sx={{ fontSize: 12, paddingRight: 1 }}
                  placeholder="Nombre de la checkbox"
                  onChange={(event)=> {
                    var cSettingTMP = {...cSetting}
                    cSettingTMP.title = (event.target.value && event.target.value?.replaceAll(' ', ''))? event.target.value : null;
                    checkChangesFunc(cSettingTMP)
                  }}
                  defaultValue={cSetting?.title}
                  inputProps={{ maxLength: 50, sx: { paddingInline: 0.8, paddingBlock: 0.5 } }}
                />
              </div>


              <div className='w-full max-w-[122px] duration-200 rounded overflow-hidden'>
                <InputBase
                  className='w-full bg-gray-200 hover:bg-gray-300 rounded duration-200'
                  sx={{ fontSize: 12, paddingRight: 1 }}
                  placeholder="Nombre abreviado"
                  onChange={(event)=> {
                    var cSettingTMP = {...cSetting}
                    cSettingTMP.small_title = (event.target.value && event.target.value?.replaceAll(' ', ''))? event.target.value : null;
                    checkChangesFunc(cSettingTMP)
                  }}
                  defaultValue={cSetting?.small_title}
                  inputProps={{ maxLength: 50, sx: { paddingInline: 0.8, paddingBlock: 0.5 }, className: '' }}
                />
              </div>

              {(cSetting?.automated)? 
                <div className='w-full max-w-[122px] duration-200 rounded overflow-hidden'>
                  <InputBase
                    className='w-full bg-gray-200 hover:bg-gray-300 rounded duration-200'
                    sx={{ fontSize: 12, paddingRight: 1 }}
                    placeholder="Token"
                    onChange={(event)=> {
                      var cSettingTMP = {...cSetting}
                      cSettingTMP.token = (event.target.value && event.target.value?.replaceAll(' ', ''))? event.target.value : null;
                      checkChangesFunc(cSettingTMP)
                    }}
                    defaultValue={cSetting?.token}
                    inputProps={{ maxLength: 20, sx: { paddingInline: 0.8, paddingBlock: 0.5 }, className: '' }}
                  />
                </div>
              : null }
            </div>



            <div className="w-full p-1 lg:p-0 lg:w-auto flex justify-between space-x-1">

              <TagsInput
                value={tags}
                onChange={setTags}
                beforeAddValidate={(tag, existingTags) => {                 
                  return !existingTags || existingTags?.length <= 10
                }}
                name="tags"
                placeHolder="Añade posibles estados..."
              />

              <div className="flex justify-end space-x-1">
                {(currentUser?.rol === 'admin') &&
                  <Tooltip disableInteractive placement="bottom" arrow
                    title={
                      (cSetting?.facturation_type === 3 && !customer?.factura10_client_id)? 'Facturación completada' 
                      : (cSetting?.facturation_type === 2 && !customer?.factura10_client_id)? 'Factura envíada' 
                      : (cSetting?.facturation_type === 1 && !customer?.factura10_client_id)? 'Albaran envíado' 
                      : 'No relacionado a la facturación'
                    }
                  >
                    <div>
                      <button 
                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none 
                          ${
                            (cSetting?.facturation_type === 3 && !customer?.factura10_client_id)? 'rotate-[270deg] bg-blue-500 border border-blue-600 text-white' 
                            : (cSetting?.facturation_type === 2 && !customer?.factura10_client_id)? 'rotate-[180deg] bg-lime-500 border border-lime-600 text-white' 
                            : (cSetting?.facturation_type === 1 && !customer?.factura10_client_id)? 'rotate-[90deg] bg-yellow-500 border border-yellow-600 text-white' 
                            : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'
                          }
                        `}
                        onClick={() => {
                          var cSettingTMP = {...cSetting}
                          cSettingTMP.facturation_type = (customer?.factura10_client_id || parseInt(cSettingTMP.facturation_type) === 3)? 0 : parseInt(cSettingTMP.facturation_type) + 1;
                          checkChangesFunc(cSettingTMP)
                        }}
                      >
                        {
                          (cSetting?.facturation_type === 3 && !customer?.factura10_client_id)? <div className='rotate-[-270deg]'>{billIcon}</div> 
                          : (cSetting?.facturation_type === 2 && !customer?.factura10_client_id)? <div className='rotate-[-180deg]'>{billIcon}</div> 
                          : (cSetting?.facturation_type === 1 && !customer?.factura10_client_id)? <div className='rotate-[-90deg]'>{billIcon}</div> 
                          : billIcon
                        }
                      </button>
                    </div>
                  </Tooltip>
                }

                {(cSetting?.from_localization) &&
                  <Tooltip disableInteractive title={(cSetting?.from_localization_panel)? 'Dentro del panel personalizado de las localizaciones' : 'Fuera del panel personalizado de las localizaciones'} placement="bottom" arrow>
                    <div>
                      <button 
                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(cSetting?.from_localization_panel)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'}`}
                        onClick={() => {
                          var cSettingTMP = {...cSetting}
                          cSettingTMP.from_localization_panel = !cSettingTMP.from_localization_panel;
                          checkChangesFunc(cSettingTMP)
                        }}
                      >
                        {(cSetting?.from_localization_panel)? <div className='rotate-[-180deg]'>{panelIcon}</div> : panelIcon}
                      </button>
                    </div>
                  </Tooltip>
                }


                <Tooltip disableInteractive title={(cSetting?.from_localization)? 'Asignado a las localizaciones' : 'Asignado a vídeos originales'} placement="bottom" arrow>
                  <div>
                    <button 
                      className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(cSetting?.from_localization)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'}`}
                      onClick={() => {
                        var cSettingTMP = {...cSetting}
                        cSettingTMP.from_localization = !cSettingTMP.from_localization;
                        checkChangesFunc(cSettingTMP)
                      }}
                    >
                      {(cSetting?.from_localization)? <div className='rotate-[-180deg]'>{localizationIcon}</div> : originalVideoIcon}
                    </button>
                  </div>
                </Tooltip>


                <Tooltip disableInteractive title={(cSetting?.automated)? 'Campo automatico' : 'Campo manual'} placement="bottom" arrow>
                  <div>
                    <button 
                      className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(cSetting?.automated)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'}`}
                      onClick={() => {
                        var cSettingTMP = {...cSetting}
                        cSettingTMP.automated = !cSettingTMP.automated;
                        checkChangesFunc(cSettingTMP)
                      }}
                    >
                      {(cSetting?.automated)? <div className='rotate-[-180deg]'>{automatedIcon}</div> : manualIcon}
                    </button>
                  </div>
                </Tooltip>

                {(cSetting?.visible) &&
                  <Tooltip disableInteractive title={(!cSetting?.visible)? '' : (cSetting?.soft_hidden)? 'Solo visible si tiene estados' : 'Siempre visible'} placement="bottom" arrow>
                    <div>
                      <button 
                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(cSetting?.soft_hidden)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'}`}
                        onClick={() => {
                          var cSettingTMP = {...cSetting}
                          cSettingTMP.soft_hidden = !cSettingTMP.soft_hidden;
                          checkChangesFunc(cSettingTMP)
                        }}
                        disabled={!cSetting?.visible}
                      >
                        {(cSetting?.soft_hidden)? <div className='rotate-[-180deg]'>{softHiddenOnIcon}</div> : softHiddenOffIcon}
                      </button>
                    </div>
                  </Tooltip>
                }

                <Tooltip disableInteractive title={(cSetting?.visible)? 'Estado visible' : 'Estado no visible'} placement="bottom" arrow>
                  <div>
                    <button 
                      className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(cSetting?.visible)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-200 border border-gray-400 border-opacity-60 text-gray-500'}`}
                      onClick={() => {
                        var cSettingTMP = {...cSetting}
                        cSettingTMP.visible = !cSettingTMP.visible;
                        checkChangesFunc(cSettingTMP)
                      }}
                    >
                      {(cSetting?.visible)? <div className='rotate-[-180deg]'>{visibilityOnIcon}</div> : visibilityOffIcon}
                    </button>
                  </div>
                </Tooltip>

                {(currentUser?.rol === 'admin') && 
                  <Tooltip disableInteractive title={'Eliminar'} placement="bottom" arrow>
                    <div>
                      <button 
                        className={`w-[28px] h-[28px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none bg-red-500 border border-red-600 text-white`}
                        onClick={() => removeItemFunc(cSetting?.id)}
                      >
                        {cancelIcon}
                      </button>
                    </div>
                  </Tooltip>
                }
              </div>
            </div>


          </div>
        </div>
      </div>


    )}
  </Draggable>
}