import { useState, useContext, useEffect } from 'react'







export default function ProductionState({ video, small, fill }) {



  const prodHistory = (video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history) : null;
  const actualState = (prodHistory?.length)? prodHistory[0] : null;
  const stateSplit = (actualState?.video_state_name)? actualState?.video_state_name?.split('-') : null;



  return (actualState?.id)? (
    <div
      className={`relative whitespace-nowrap flex flex-wrap items-center justify-center rounded px-2 max-w-[250px]
        ${(fill)? 'w-full h-full' : ''} 
        ${(small)? 'text-[12px]' : `text-[12px]`}
      `}
    >
      <div className={`flex items-center space-x-1 truncate menu-item-${stateSplit[1]}`}>
        <div className={`hover:opacity-50 p-[5px] menu-item-ball-${stateSplit[1]}`}></div>
        <p>{stateSplit[0]}</p>
      </div>

      <p className='w-full opacity-50 text-[10px]'>{video?.production_state_change_label?.replaceAll('Estado cambiado por ', '')?.replaceAll(' el ', ' ')?.replaceAll('.', '')?.replaceAll('/20', '/')}</p>
    </div>
  ) : <p className='text-gray-500 text-[11px]'>---</p> ;
}