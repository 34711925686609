import { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext';
import { useParams, useNavigate } from "react-router-dom";

import ReactQuillSimpleImput from '../components/ReactQuillSimpleImput';
import CustomSwitch from '../components/Inputs/CustomSwitch';
import CustomTextField from '../components/Inputs/CustomTextField';

import GetRecordingSet from './../graphql/queries/GetRecordingSet'
import CreateOrUpdateRecordingSet from './../graphql/mutations/CreateOrUpdateRecordingSet'
import RemoveRecordingSet from './../graphql/mutations/RemoveRecordingSet'

import CircularProgress from '@mui/material/CircularProgress';








const Set = () => {


  const { id } = useParams()
  const { currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive, setSuccessAlertMsg, setErrorAlertMsg } = useContext(LayoutContext)
  const { remove_tags } = useContext(FuncContext)
  const navigate = useNavigate();
  
  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )
  



  /* Loading vars */
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  /* Set vars */
  const [recordingSet, setRecordingSet] = useState(null);
  const [name, setName] = useState(null);
  const [markLabel, setMarkLabel] = useState(null);
  const [description, setDescription] = useState(null);
  const [showMarkLabel, setShowMarkLabel] = useState(false);



  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>



  const [createOrUpdateRecordingSet] = useMutation(CreateOrUpdateRecordingSet)
  const [removeRecordingSet] = useMutation(RemoveRecordingSet)
  
  const [getRecordingSet, { data: dataRecordingSet, loading: loadingRecordingSetData }] = useLazyQuery(GetRecordingSet, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      id: (id)? id : null,
    }
  })





  

  useEffect(() => {    
    setCurrentNavActive('sets')
  }, [])


  useEffect(() => { 
    if (id) {
      getRecordingSet()
    }
  }, [id])


  useEffect(() => { 
    const setTMP = (dataRecordingSet?.recordingSet)? dataRecordingSet?.recordingSet : null;

    setRecordingSet((setTMP)? setTMP : null)
    setName((setTMP)? setTMP?.name : null)
    setMarkLabel((setTMP)? setTMP?.mark_label : null)
    setDescription((setTMP)? setTMP?.description : null)
    setShowMarkLabel((setTMP)? setTMP?.show_mark_label : null)
  }, [dataRecordingSet])



  useEffect(() => { 
    if (showMarkLabel && !markLabel) {
      setMarkLabel(name)
    } else if (!showMarkLabel) {
      setMarkLabel(null)
    }
  }, [showMarkLabel])



  
  








  async function saveRecordingSetFunc() {
    setLoadingSave(true)
    setAlreadySubmited(true)

    var response = null;
    if ((name && name?.replaceAll(' ', '') !== '') && (!showMarkLabel || (markLabel && markLabel?.replaceAll(' ', '') !== ''))) {
      response = await createOrUpdateRecordingSet({ 
        variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null, 

          id: (parseInt(id))? parseInt(id) : null, 
          name: (name)? name : '---',
          mark_label: (showMarkLabel && markLabel)? markLabel : null,
          description: (description)? description : null,        
          show_mark_label: (showMarkLabel)? true : false,        
          customer_token: currentCustomer?.token,
        }
      })
      response = response?.data?.createOrUpdateRecordingSet;
    }

    if (response?.id) {
      navigate("/sets")
      setSuccessAlertMsg('El set se ha guardado correctamente.')
    } else {
      setErrorAlertMsg('Error al guardar el set.')
    }

    setLoadingSave(false); 
  }


  async function removeRecordingSetFunc() {
    setLoadingRemove(true)

    var response = null;
    if (id) {
      response = await removeRecordingSet({ 
        variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null, 
          id: (parseInt(id))? parseInt(id) : null, 
        }
      })
      response = response?.data?.removeRecordingSet?.status;
    }

    if (response) {
      navigate("/sets")
      setSuccessAlertMsg('El set se ha eliminado correctamente.')
    } else {
      setErrorAlertMsg('Error al eliminar el set.')
    }

    setLoadingRemove(false); 
  }



  








  return (
    <div className="px-5 md:px-10 min-w-[400px]">

      {((id && recordingSet) || !id)?
        !(loadingRecordingSetData)?
          <div>
            <div className='flex items-center justify-between py-14 pl-2'>
              <h1 className='font-bold text-4xl'>{(id)? 'Segmento' : 'Nuevo segmento'}</h1>


              <div className='flex flex-wrap justify-end space-x-2'>
                {(id) &&
                  <button
                    className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
                    onClick={removeRecordingSetFunc}
                    disabled={(loadingSave || loadingRemove || !name || (showMarkLabel && !markLabel))}
                  >
                    {(loadingRemove)? loadingIcon : removeIcon}
                    <p>{(loadingRemove)? 'Eliminando' : 'Eliminar'}</p>
                  </button>
                }

                <button
                  className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
                  onClick={saveRecordingSetFunc}
                  disabled={(loadingSave || loadingRemove || !name || (showMarkLabel && !markLabel))}
                >
                  {(loadingSave)? loadingIcon : saveIcon}
                  <p>{(loadingSave)? 'Guardando' : 'Guardar'}</p>
                </button>
              </div>
            </div>


            <div className='space-y-2'>
              <div className='flex justify-between items-start space-x-2'>
                <CustomTextField
                  id="name"
                  label="Nombre"
                  error={(alreadySubmited && (name === '' || !name))? 'Este campo es obligatorio.' : ''}

                  value={(name)? name : ''}
                  onChange={event => setName((event.target.value)? event.target.value?.slice(0, 100) : null)}
                />


                <CustomSwitch
                  label="Resaltar en las marcas"
                  value={showMarkLabel}
                  onClick={() => setShowMarkLabel(!showMarkLabel)}
                />


                {(showMarkLabel) &&
                  <CustomTextField 
                    id="markLabel"
                    label="Nombre en las marcas"
                    className='w-full max-w-[300px] mui-bg-gray-100'
                    error={(alreadySubmited && (markLabel === '' || !markLabel))? 'Este campo es obligatorio.' : ''}

                    value={(markLabel)? markLabel : ''}
                    onChange={event => setMarkLabel((event.target.value)? event.target.value?.slice(0, 20) : null)}
                  /> 
                }
              </div>


              <div className='flex items-center justify-center pt-2 pb-[2px] px-[1px]'>
                <ReactQuillSimpleImput 
                  id={"text_quilll"} 
                  label={'Descripción'}
                  placeholder={'Descripción de plato de grabación...'}
                  value={(description)? description : ''}
                  style={2}

                  onChange={(value) => {
                    setDescription((value && remove_tags(value))? value : null)
                  }} 
                />
              </div>

            </div>
          </div>
        :
          <div className='w-full flex justify-center items-center space-x-4 text-gray-400 py-[200px]'>
            <CircularProgress  color="inherit"/>
            <p>Cargando...</p>
          </div>
      :
        <p className='text-gray-400 px-1 py-4'>Es probable que este set haya sido eliminado o directamente no haya existido nunca.</p>
      }

    </div>
  )
}

export default Set
