import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';





export default function VideoMarkFileInputs({
  markID,
  selectedMark,
  markTypeObj,
  disabled,
  language,
  translating,

  texts,
  langKey,

  files, 
  setFiles,
  fileName,
  removeFiles,
  setRemoveFiles,
  processType,
  setProcessType,
}) {
  


  const { customerPath } = useContext(AuthContext)


  

  const cancelRemoveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m480-399 73 73q13 12 30 12t28-12q13-13 13-30t-13-29l-73-73 74-74q13-13 12.5-30T611-590q-12-12-29-12t-29 12l-73 73-74-73q-12-12-29-12t-29 12q-12 12-12 29t12 30l73 73-74 74q-12 12-11.5 29t12.5 29q13 11 29.5 11t28.5-11l74-73ZM283-120q-35 0-59.5-24.5T199-204v-510q-17 0-29.5-12.5T157-756q0-17 12.5-29.5T199-798h162q0-17 12.5-29.5T403-840h153q17 0 29.5 12.5T598-798h162q17 0 29.5 12.5T802-756q0 17-12.5 29.5T760-714v510q0 35-24.5 59.5T676-120H283Z"/></svg>
  const trashIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-390v114.072q0 15.269 10.289 25.599Q464.579-240 479.789-240 495-240 505.5-250.329q10.5-10.33 10.5-25.599V-390l31.714 31.714Q553-353 560-350.5t13.5 2.5q6.5 0 13.5-2.5t12-7.5q11-11 11-25.5t-10.862-25.362L505-503q-5.1-5.455-11.05-7.727Q488-513 480-513q-8 0-13.95 2.273Q460.1-508.455 455-503l-94.138 94.138Q350-398 350.462-383.818 350.923-369.636 362-359q11 11 25.5 11t25.223-10.723L444-390ZM263.717-96Q234-96 213-117.15T192-168v-624q0-29.7 21.15-50.85Q234.3-864 264-864h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717ZM528-660q0 15.3 10.35 25.65Q548.7-624 564-624h132L528-792v132Z"/></svg>
  const createIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49Zm-59 59L299 936H120V757l419-419 179 179Z"/></svg>;
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M164 1056q-35 0-59.5-24.5T80 972q0-35 24.5-59.5T164 888h632q35 0 59.5 24.5T880 972q0 35-24.5 59.5T796 1056H164Zm54-241q-17 0-29.5-12.5T176 773v-98q0-8 3-15.5t9-13.5l337-337 157 157-337 337q-6 6-13.5 9t-15.5 3h-98Zm515-400L576 258l70-70q12-12 31-12t31 12l100 100q11 11 8.5 28.5T803 345l-70 70Z"/></svg>;
  const noEditIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M756 970 510 725 299 936H120V757l211-211L85 300l60-59 670 671-59 58ZM629 606 450 427l89-89 179 179-89 89Zm148-148L598 279l49-49q25-25 60-24.5t59 24.5l60 60q24 24 24 59.5T826 409l-49 49Z"/></svg>;





  function removeAllUploadedFilesFunc() {
    const filesToTemoveTMP = (texts && texts[langKey].filename)? texts[langKey].filename : null
    setRemoveFiles((filesToTemoveTMP?.split(',')?.length)? filesToTemoveTMP?.split(',') : null)
  }













  function avoidRemovalFunc(name) {
    setRemoveFiles(removeFiles?.filter((filename)=> filename !== name))
  }


  /* async function setHaveFileNameCopyFunc(name) {
    var newFileNames = files? await Array.from(files)?.map(element => { return element.name }).join(',') : '';
    var haveNamesTMP = await haveFileNameCopy?.split(',')?.length? haveFileNameCopy?.split(',').filter((element) => fileName?.includes(element) || newFileNames?.includes(element) ) : []
    var mayHaveNamesTMP = await mayHaveFileNameCopy?.split(',')?.length? mayHaveFileNameCopy?.split(',').filter((element) => fileName?.includes(element) || newFileNames?.includes(element) ) : []


    if (mayHaveFileNameCopy?.includes(name)) {

      await haveNamesTMP.push(name)
      await haveNamesTMP.filter((element) => element !== '' && element !== null )
      setHaveFileNameCopy([...new Set(haveNamesTMP)].join(','))
      setMayHaveFileNameCopy([...new Set(mayHaveNamesTMP.filter((element) => element !== name && element !== '' && element !== null ))].join(','))

    } else if (haveFileNameCopy?.includes(name)) {

      setMayHaveFileNameCopy([...new Set(mayHaveNamesTMP.filter((element) => element !== name && element !== '' && element !== null ))].join(','))
      setHaveFileNameCopy([...new Set(haveNamesTMP.filter((element) => element !== name && element !== '' && element !== null ))].join(','))

    } else {

      await mayHaveNamesTMP.push(name)
      await mayHaveNamesTMP.filter((element) => element !== '' && element !== null )
      setMayHaveFileNameCopy([...new Set(mayHaveNamesTMP)].join(','))
      setHaveFileNameCopy([...new Set(haveNamesTMP.filter((element) => element !== name && element !== '' && element !== null ))].join(','))

    }
  } */






  return (<div className={`w-full text-sm space-y-2`}>
    

    <div className='w-full flex flex-wrap xl:flex-nowrap items-center justify-between space-y-2 xl:space-y-0 xl:space-x-2'>
      {/* SEE TYPE FILE */}
      {(fileName?.length || files?.length)?
        <ToggleButtonGroup
          className='h-[28px] bg-white max-w-[350px]'
          value={processType}
          exclusive
          onChange={(event, value) => setProcessType(value)}
          aria-label="Procedimiento"
          disabled={translating}
          fullWidth
        >
          <ToggleButton value={1}>
            <Tooltip 
              disableInteractive 
              title={
                (markTypeObj?.type?.includes('images'))? "Imágenes creadas completamente por Clicksound" 
                : (markTypeObj?.type?.includes('video'))? "Vídeo creado completamente por Clicksound" 
                : (markTypeObj?.type?.includes('scheme'))? "Esquema creado completamente por Clicksound" 
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Fichero creado completamente por Clicksound" 
                : "Imagen creada completamente por Clicksound"
              } 
              placement="bottom" 
              arrow
            >
              <div className='w-full flex items-center justify-center space-x-1'>
                <div className='scale-90'>{createIcon}</div>
                <p>
                  {
                    (markTypeObj?.type?.includes('images'))? "Internas" 
                    : (markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('file') || markTypeObj?.type?.includes('special'))? "Interno" 
                    : "Interna"
                  }
                </p>
              </div>
            </Tooltip>
          </ToggleButton>

          <ToggleButton value={2}>
            <Tooltip 
              disableInteractive 
              title={
                (markTypeObj?.type?.includes('images'))? "Imágenes que necesitan modificaciones" 
                : (markTypeObj?.type?.includes('video'))? "Vídeo que necesita modificaciones" 
                : (markTypeObj?.type?.includes('scheme'))? "Esquema que necesita modificaciones" 
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Fichero que necesita modificaciones" 
                : "Imagen que necesita modificaciones"
              } 
              placement="bottom" 
              arrow
            >
              <div className='w-full flex items-center justify-center space-x-1'>
                <div className='scale-90'>{editIcon}</div>
                <p>
                  {
                    (markTypeObj?.type?.includes('images'))? "Integradas" 
                    : (markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Integrado" 
                    : "Integrada"
                  }
                </p>
              </div>
            </Tooltip>
          </ToggleButton>

          <ToggleButton value={3}>
            <Tooltip 
              disableInteractive 
              title={
                (markTypeObj?.type?.includes('images'))? "Imágenes que no necesitan ningúna modificación" 
                : (markTypeObj?.type?.includes('video'))? "Vídeo que no necesita ningúna modificación" 
                : (markTypeObj?.type?.includes('scheme'))? "Esquema que no necesita ningúna modificación" 
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Fichero que no necesita ningúna modificación" 
                : "Imagen que no necesita ningúna modificación"
              } 
              placement="bottom" 
              arrow
            >
              <div className='w-full flex items-center justify-center space-x-1'>
                <div className='scale-90'>{noEditIcon}</div>
                <p>
                  {
                    (markTypeObj?.type?.includes('images'))? "Externas" 
                    : (markTypeObj?.type?.includes('video') || markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Externo" 
                    : "Externa"
                  }
                </p>
              </div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      : null }


      <div className={`flex justify-end ${(fileName?.length || files?.length)? ' ' : 'w-full'}`}>
        <button
          className={`flex items-center justify-center space-x-1 p-1 pr-2 text-gray-600 border border-gray-400 border-opacity-60 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 disabled:pointer-events-none disabled:opacity-50 duration-200 whitespace-nowrap rounded`}
          onClick={() => document.getElementById(`${markID}-file-input`).click()}
          disabled={disabled}
        >
          <input 
            multiple={selectedMark?.multiple} 
            hidden 
            accept={
              (markTypeObj?.type?.includes('video'))? 'video/*' 
              : (markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? ""
              : "image/*"
            } 
            id={`${markID}-file-input`}
            type="file" 
            onChange={(e) => setFiles((e.target.files?.length)? e.target.files : null)}
            disabled={disabled}
          />

          <div>{uploadIcon}</div>

          <p>
            {(markTypeObj?.type?.includes('images'))? 'Subir imagenes' 
            : (markTypeObj?.type?.includes('video'))? 'Subir vídeo' 
            : (markTypeObj?.type?.includes('scheme'))? 'Subir esquema' 
            : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Subir fichero" 
            : 'Subir imagen'} 
            {(language)? ` en ${language?.name}` : ''}
          </p>
        </button>

      </div>
    </div>







    {/* SEE NEW FILES */}
    {(files) && 
      <div className={`w-full mb-10 bg-white border border-gray-400 rounded-md overflow-hidden`}>


        <div className={`w-full flex justify-between items-center space-x-2 pl-2 bg-gray-200 border-b border-gray-400`}>
          <p className='text-xs'>
          {
                (
                  (markTypeObj?.type?.includes('images'))? "Nuevas imagenes" 
                  : (markTypeObj?.type?.includes('video'))? "Nuevo vídeo"
                  : (markTypeObj?.type?.includes('scheme'))? "Nuevo esquema"
                  : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Nuevo fichero" 
                  : 'Nueva imagen'
                ) 
                + ((language)? ` en ${language?.name}` : '') + ' por guardar'
              } 
          </p>

          <Tooltip 
            disableInteractive 
            title={
              (
                (markTypeObj?.type?.includes('images'))? "Eliminar imágenes pendientes de ser guardadas" 
                : (markTypeObj?.type?.includes('video'))? "Eliminar vídeo pendiente de ser guardado" 
                : (markTypeObj?.type?.includes('scheme'))? "Eliminar esquema pendiente de ser guardado" 
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Eliminar fichero pendiente de ser guardado" 
                : 'Eliminar imagen pendiente de ser guardada'
              ) 
              + ((language)? ` en ${language?.name}` : '')
            } 
            placement="bottom" 
            arrow
          >
            <div className='text-gray-600 hover:text-red-500 duration-300'>
              <button
                onClick={(e) => setFiles(null)}
                disabled={disabled}
                className='flex items-center justify-center p-[3px] text-gray-600 hover:text-red-500 hover:bg-gray-500 hover:bg-opacity-20 active:bg-opacity-50 disabled:pointer-events-none disabled:opacity-40 duration-200'
              >
                <div className='scale-90'>{trashIcon}</div>
              </button>
            </div>
          </Tooltip>
        </div>


        <div className={`w-full flex items-center justify-start bg-gray-200 overflow-x-scroll overflow-y-hidden smallScrollBar`}>
          {Array.from(files)?.map((element, key) => {
            return <div 
            key={'upload-file-' + ((language)? language?.abbreviated_name : 'org') + '-' + key}
            className={`w-full flex items-center px-3 border-b border-gray-400 bg-gray-100 cursor-not-allowed  
              ${((key+1) !== Array.from(files)?.length)? 'border-r' : null}
            `}
            >
              <Tooltip 
                disableInteractive 
                title={
                  (
                    (markTypeObj?.type?.includes('video'))? "Vídeo pendiente de ser guardado..." 
                    : (markTypeObj?.type?.includes('scheme'))?'Esquema pendiente de ser guardado...'
                    : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))?'Fichero pendiente de ser guardado...'
                    : 'Imagen pendiente de ser guardada...'
                  ) 
                  + ((language)? ` en ${language?.name}` : '')
                } 
                placement="bottom" 
                arrow
              >
                <div className={`w-full flex items-center justify-start cursor-pointer py-[3px]`} /* onClick={() => window.open(`${customerPath}mark_file/${element}`, '_blank').focus()} */>
                  <p className='truncate'>{element?.name}</p>
                </div>
              </Tooltip>

              {/* <div className='flex ml-2 mt-[2px]'>
                <div>
                  <Tooltip 
                    disableInteractive 
                    title={(haveFileNameCopy?.includes(element?.name))? 'Sí tenemos los derechos de autor' : (mayHaveFileNameCopy?.includes(element?.name))? 'No sabemos si tenemos los derechos de autor' : 'No tenemos los derechos de autor'} 
                    placement="bottom" 
                    arrow
                  >
                    <IconButton 
                      component="label" 
                      size='small'
                      onClick={(e)=> setHaveFileNameCopyFunc(element?.name)} 
                      disabled={disabled}
                    >
                      <p className={`${(haveFileNameCopy?.includes(element?.name))? 'text-green-600' : (mayHaveFileNameCopy?.includes(element?.name))? 'text-yellow-600' : 'text-red-600'}`}>
                        {(haveFileNameCopy?.includes(element?.name))? copyIconFilled : copyIcon}
                      </p>
                    </IconButton>
                  </Tooltip>
                </div>
              </div> */}
            </div>
          })}
        </div>
      </div>
    }







    {/* SEE OLD FILES */}
    {(fileName) &&
      <div className={`w-full bg-gray-200 border border-gray-400 rounded-md overflow-hidden`}>
        
        
        <div className={`w-full flex justify-between items-center space-x-2 pl-2 bg-gray-200 border-b border-gray-400`}>
          <p className='text-xs'>
            {
              (
                (markTypeObj?.type?.includes('images'))? "Imagenes guardadas" 
                : (markTypeObj?.type?.includes('video'))? "Vídeos guardados"
                : (markTypeObj?.type?.includes('scheme'))? "Esquema guardados"
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Fichero guardados"
                : 'Imagen guardada'
              ) 
              + ((language)? ` en ${language?.name}` : '')
            } 
          </p>

          <Tooltip 
            disableInteractive 
            title={
              (
                (removeFiles?.length)? "Cancelar eliminación"
                : (markTypeObj?.type?.includes('images'))? "Eliminar todas las imágenes guardadas" 
                : (markTypeObj?.type?.includes('video'))? "Eliminar todos los vídeos guardados" 
                : (markTypeObj?.type?.includes('scheme'))? "Eliminar todos los esquemas guardados" 
                : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Eliminar todos los ficheros guardados" 
                : 'Eliminar todas las imagens guardadas'
              ) 
              + ((language)? ` en ${language?.name}` : '')
            } 
            placement="bottom" 
            arrow
          >
            <button
              onClick={(e) => (removeFiles?.length)? setRemoveFiles(null) : removeAllUploadedFilesFunc()}
              disabled={disabled}
              className='flex items-center justify-center p-[3px] text-gray-600 hover:text-red-500 hover:bg-gray-500 hover:bg-opacity-20 active:bg-opacity-50 disabled:pointer-events-none disabled:opacity-40 duration-200'
            >
              <div className='scale-90'>{(removeFiles?.length)? cancelRemoveIcon : trashIcon}</div>
            </button>
          </Tooltip>
        </div>


        <div className={`w-full flex justify-start items-center bg-gray-200 overflow-x-scroll overflow-y-hidden smallScrollBar`}>
          {fileName?.split(',')?.map((element, key) => {

            var deleted = false;
            removeFiles?.map(removed => { if (removed === element) { deleted = true; } });

            var elementName = element?.split('-')
            if (elementName?.length) { elementName.shift() }
            elementName = elementName?.join('-')

            return <div 
              key={'see-file-' + ((language)? language?.abbreviated_name : 'org') + '-' + key}
              className={`w-full flex items-center pl-3 border-b border-gray-400 duration-300 
                ${(deleted || (files && !selectedMark?.multiple))? 'bg-red-200 hover:bg-red-300' : 'bg-gray-100 hover:bg-gray-200'} 
                ${((key+1) !== fileName?.split(',')?.length)? 'border-r' : null}
              `}
            >
              <Tooltip 
                disableInteractive 
                title={
                  (
                    (markTypeObj?.type?.includes('video'))? "Ver vídeo guardado" 
                    : (markTypeObj?.type?.includes('scheme'))? "Ver esquema guardado"
                    : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "Ver fichero guardado"
                    : 'Ver imagen guardada'
                  ) 
                  + ((language)? ` en ${language?.name}` : '')
                } 
                placement="bottom" 
                arrow
              >
                <div className={`w-full flex items-center justify-start cursor-pointer py-[3px]`} onClick={() => window.open(`${customerPath}mark_file/${element}`, '_blank').focus()}>
                  <p className='truncate'>{elementName}</p>
                </div>
              </Tooltip>


              <div className='flex items-center ml-2 text-gray-600'>
                {/* <Tooltip 
                  disableInteractive 
                  title={(haveFileNameCopy?.includes(element))? 'Sí tenemos los derechos de autor' : (mayHaveFileNameCopy?.includes(element))? 'No sabemos si tenemos los derechos de autor' : 'No tenemos los derechos de autor'} 
                  placement="bottom" 
                  arrow
                >
                  <div className={`duration-300 ${(haveFileNameCopy?.includes(element))? 'text-green-600' : (mayHaveFileNameCopy?.includes(element))? 'text-yellow-600' : 'text-red-600'}`}>
                    <IconButton 
                      component="label" 
                      size='small'
                      color='inherit'
                      onClick={(e)=> setHaveFileNameCopyFunc(element)} 
                      disabled={disabled}
                    >
                        {(haveFileNameCopy?.includes(element))? copyIconFilled : copyIcon}
                    </IconButton>
                  </div>
                </Tooltip> */}

                {(((!files && !selectedMark?.multiple) || (selectedMark?.multiple)) && fileName?.split(',')?.length > 1) && 
                  <Tooltip 
                    disableInteractive 
                    title={
                      ((deleted)? 'Salvar ' : 'Eliminar ')
                      + (
                        (markTypeObj?.type?.includes('video'))? "vídeo guardado" 
                        : (markTypeObj?.type?.includes('scheme'))? "esquema guardado" 
                        : (markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file'))? "fichero guardado" 
                        : 'imagen guardada'
                      ) 
                      + ((language)? ` en ${language?.name}` : '')
                    } 
                    placement="bottom" 
                    arrow
                  >
                    <button
                      onClick={(e)=> (deleted)? avoidRemovalFunc(element) : ((removeFiles)? setRemoveFiles([...removeFiles, element]) : setRemoveFiles([element])) }
                      disabled={disabled}
                      className='flex items-center justify-center p-[3px] text-gray-600 hover:text-red-500 hover:bg-gray-500 hover:bg-opacity-20 active:bg-opacity-50 disabled:pointer-events-none disabled:opacity-40 duration-200'
                    >
                      <div className='scale-90'>{(deleted)? cancelRemoveIcon : trashIcon}</div>
                    </button>
                  </Tooltip>
                }
              </div>
            </div>
          })}
        </div>


      </div>
    }



  </div>);
}