import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext';
import { FuncContext } from '../../contexts/FuncContext';
import { useQuery, useLazyQuery } from '@apollo/client'

import CalendarDateInput from '../CalendarDateInput';

import GetAllUsersAndProfessorsSimple from '../../graphql/queries/GetAllUsersAndProfessorsSimple'
import GetLastActivities from '../../graphql/queries/GetLastActivities'

import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';










export default function MotionUserChart({
    
  chartColors,
  
}) {



  const { currentCustomer, currentUser } = useContext(AuthContext)
  const { formatNumbersToPrint } = useContext(FuncContext)

  


  
  /* Filter vars */
  const [motionStateFilter, setMotionStateFilter] = useState(3);
  const [startDate, setStartDate] = useState(getMonday(new Date()));
  const [endDate, setEndDate] = useState(getToday(new Date()));
  const [selectedUser, setSelectedUser] = useState(null);
  

  /* Chart vars */
  const [tableKeys, setTableKeys] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [markTypeLegend, setMarkTypeLegend] = useState(null);


  

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day + ((day===0)? -6 : 1);
    var date = new Date(d.setDate(diff))

    if (date) {
      date.setHours(1,0,0,0);
    }

    return date;
  }


  function getToday(d) {
    var date = (d)? new Date(d) : null

    if (date) {
      date.setHours(23,59,59,0);
    }

    return date;
  }







  /* Icon vars */
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>


  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;






  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsersAndProfessorsSimple, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: false,
    }
  })



  const [getActivities, { data: dataActivities, loading: loadingActivities }] = useLazyQuery(GetLastActivities, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      types: 'state_update_video_mark',
      mark_motion_state: (parseInt(motionStateFilter))? parseInt(motionStateFilter) : 3,

      date_start: (startDate)? startDate : null,
      date_end: (endDate)? endDate : null,
    }
  })



  useEffect(() => {
    getActivities()
  }, [motionStateFilter, startDate, endDate])







  /* Generate charts */
  useEffect(() => {
    const users = dataUsers?.usersAndProfessors?.filter((el) => el?.rol?.includes('editor'));
    const history = dataActivities?.getLastActivities;
    var chartDataTMP = [];
    var chartLegendTMP = [];
    var tableKeysTMP = [];

    if (users?.length && history?.length) {
      for (var user of users) {
        var userHistory = history?.filter((el) => parseInt(el?.own_user_id) === parseInt(user?.id))

        var objTMP = {};
        objTMP['name'] = user?.name;
        objTMP['Total'] = 0;

        for (var userHistoryItem of userHistory) {
          if (userHistoryItem?.mark_type !== 'Nota') {
            var nameTagTMP = (userHistoryItem?.mark_type)? userHistoryItem?.mark_type?.split(' / ')[0]?.replaceAll('.', '') : 'Other';
            objTMP[nameTagTMP] = (objTMP[nameTagTMP])? objTMP[nameTagTMP] + 1 : 1;
            objTMP['Total']++;

            if (!chartLegendTMP.includes(nameTagTMP)) { chartLegendTMP.push(nameTagTMP) }
          }
        }

        if (userHistory?.length && objTMP['Total']) {
          chartDataTMP.push(objTMP)
        }

        const diffTime = Math.abs(endDate - startDate);
        const diffDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        objTMP['Media diaria'] = (parseInt(objTMP['Total']))? formatNumbersToPrint(parseInt(objTMP['Total']) / diffDate, 2) + ' motions en ' + diffDate + ' días' : 0;
        /* objTMP['Ver más'] = <div>
          {visibilityOnIcon}
        </div> */
      }


      tableKeysTMP = ['name', 'Total', ...chartLegendTMP, 'Media diaria'/* , 'Ver más' */];


      setTableKeys(tableKeysTMP);
      setMarkTypeLegend(chartLegendTMP)
      setChartData(chartDataTMP)


    } else {
      setMarkTypeLegend(null)
      setChartData(null)
    }
  }, [dataUsers, dataActivities])















  const ChartTooltipFunc = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white text-sm text-gray-700 rounded border border-gray-400">
          <div className='p-2'>
            <h1 className='text-gray-800 font-bold'>{label} / Motions creados</h1>

            <hr className='border-gray-400 my-2' />

            <div className='w-full space-y-1'>
              {payload?.map(element => {
                return (element.value)? 
                  <div className='w-full flex justify-between space-x-8'>
                    <div className='flex items-center space-x-2'>
                      <div style={{ backgroundColor: element?.color }} className='w-[10px] h-[10px] rounded-[3px] mb-[2px]'></div>
                      <p>{(parseInt(element?.name.charAt(0)))? element?.name.replace(/[0-9]/g, '') : element?.name}:</p>
                    </div>
                    <p>{element.value}</p>
                  </div>
                : null
              })}
            </div>
          </div>

        </div>
      );
    }
  
    return null;
  };











  function downloadChartInTXT(data, filename, itemName, decimals) {
    var content = filename;


    for (var i = 0; i < data?.length; i++) {
      var dataItem = data[i];

      Object.keys(dataItem)?.map(key => {
        var value = dataItem[key];

        if (key === 'name') {
          content = (content + "\n\n\n\n - " + value + "\n");
        } else {
          content = (content + "\n\xa0\xa0\xa0\xa0" + key + ":\t\t" + value?.toFixed(decimals) + ((parseInt(value) === 1)? itemName.slice(0, -1) : itemName));
        }
      });
    }


    const link = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = filename + ".txt";
    link.click();
    URL.revokeObjectURL(link.href);
  }










  return (<>

  

    <div className='absolute top-0 right-0'>
      <div className='p-2 flex space-x-1 rounded-t overflow-hidden'>
        <Tooltip disableInteractive title={"Descargar datos en TXT"} placement="bottom" arrow>
          <button 
            onClick={()=> {
              downloadChartInTXT(chartData, `Minutos por ${currentCustomer?.label_professor?.toLowerCase()} y ${currentCustomer?.label_module?.toLowerCase()}`, ' minutos', 2)

            }} 
            className={`bg-gray-200 py-[2px] pl-1 pr-2 text-xs font-bold flex items-center cursor-pointer duration-200 text-gray-500 hover:text-blue-500 active:text-gray-500 active:bg-gray-200 rounded`}
          >
            {downloadIcon}
            <p>TXT</p>
          </button>
        </Tooltip>

        {/* <HomeChartsToExcel 
          data={chartData} 
          filename={'Motions por usuario'}
          modules={null}
        /> */}
      </div>
    </div>


    <div className='w-full p-5 pb-0 flex items-center justify-center flex-wrap space-y-3 lg:space-y-0 lg:space-x-2 lg:flex-nowrap'>
      <TextField
        select
        value={(motionStateFilter)? motionStateFilter : 3}
        onChange={event => setMotionStateFilter(event.target.value)}
        label="Estado"
        className='w-full mui-bg-white'
        size="small"
        sx={{ "& .MuiInputBase-root": { height: 33 } }}
        InputLabelProps={{ sx: { top: "-4px", "&.MuiInputLabel-shrink": { top: 0 } } }}
      >
        <MenuItem value={2}>En creación</MenuItem>
        <MenuItem value={3}>Creadas</MenuItem>
        <MenuItem value={4}>Insertadas</MenuItem>
        {/* <MenuItem value={5}>Subidas</MenuItem> */}
      </TextField>


      <CalendarDateInput
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>



    <div className={`w-full flex flex-wrap`}>
      <div className={`w-full p-5`}>
        <div className={`flex bg-gray-200 rounded py-4 relative w-full ${(chartData?.length && !loadingUsers && !loadingActivities)? 'h-[35vw]' : ''} text-xs`}>


          {/* Chart */}
          {(chartData?.length && !loadingUsers && !loadingActivities)? <>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={chartData} barCategoryGap={0} barGap={0} onClick={(element) => (selectedUser !== element?.activeLabel)? setSelectedUser(element?.activeLabel) : setSelectedUser(null)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0}/>
                <YAxis unit=' motions' width={100}/>

                <ChartTooltip content={<ChartTooltipFunc />} />
                <Legend iconType='circle' iconSize={10}/>


                {markTypeLegend?.map((element, key) => {
                  return <Bar dataKey={element} fill={chartColors[key+1]} type="circle" key={element+'-'+key}/>
                })}

                <Bar dataKey={'Total'} fill={chartColors[chartColors?.length - 1]} type="circle" />

              </BarChart>
            </ResponsiveContainer>
          </> : (!loadingUsers && !loadingActivities)?
            <div className='w-full'>
              <div className='w-full px-5 py-1 flex items-center space-x-2 rounded text-gray-500 bg-gray-200'>
                <p>No se ha encontrado información entre las fechas selecionadas.</p>
              </div>
            </div>
          : 
            <div className='w-full'>
              <div className='w-full px-5 py-1 flex items-center space-x-2 rounded text-gray-500 bg-gray-200'>
                {loadingIcon}
                <p>Cargando...</p>
              </div>
            </div>
          }


        </div>
      </div>




      {/* Table */}
      {(chartData?.length && !loadingActivities && !loadingUsers)? 
        <div className='w-full p-5'>
          <div className='w-full overflow-auto '>
            <table className='w-full text-sm whitespace-nowrap'>
              <tr className='text-left'>
                {tableKeys?.map(element => {
                  return <th key={'row-table-'+element} className='py-2 px-6 bg-gray-100'> {/* sticky top-[55px] */}
                    {
                      (element === 'name')? 'Nombre' 
                      : element
                    }
                  </th>
                })}
              </tr>

              {chartData?.map(element => {
                return <tr
                  key={'user-table-'+element?.name}
                  onClick={() => (selectedUser !== element?.name)? setSelectedUser(element?.name) : setSelectedUser(null)}
                  className={`border-b-4 border-gray-100 rounded duration-200 cursor-pointer ${(element?.name === selectedUser)? 'bg-blue-500 bg-opacity-30' : 'bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:bg-opacity-70'}`}
                >
                  {tableKeys?.map(key => {
                    return <td>
                      <div className='py-2 px-6'>
                        <p className={`${(key !== 'name')? '' : 'font-bold'}`}>
                          {
                            (element[key])? element[key]
                            : '-'
                          }
                        </p>
                      </div>
                    </td>
                  })}
                </tr>
              })} 
            </table>
          </div>
        </div>
      : null }

      {(selectedUser && dataActivities?.getLastActivities?.length && dataUsers?.usersAndProfessors?.length && !loadingActivities && !loadingUsers)?
        <div className='w-full px-4 pb-4'>
          <div className='w-full bg-gray-300 bg-opacity-50 rounded overflow-hidden'>
            <div className='flex space-x-4 justify-between items-center bg-gray-300 pl-3'>
              <p className='text-sm text-gray-500'>Detalles avanzados de <strong>{selectedUser}</strong></p>
              <button className='hover:text-red-500 hover:bg-gray-400 hover:bg-opacity-30 duration-200 p-2' onClick={() => setSelectedUser(null)}>
                {cancelIcon}
              </button>
            </div>

            <div className='space-y-1 p-2'>
              {dataUsers?.usersAndProfessors?.filter((user) => (user?.name === selectedUser))?.map(user => {
                  var userHistory = dataActivities?.getLastActivities?.filter((el) => parseInt(el?.own_user_id) === parseInt(user?.id))

                  var dateInfo = {}
                  for (var historyItem of userHistory) {
                    if (!dateInfo[historyItem?.createdAtFormated]) { dateInfo[historyItem?.createdAtFormated] = {} } 
                    var dataTMP = dateInfo[historyItem?.createdAtFormated][historyItem?.mark_type];
                    dataTMP = (dataTMP)? dataTMP + 1 : 1;
                    dateInfo[historyItem?.createdAtFormated][historyItem?.mark_type] = dataTMP;
                  }

                  return Object.keys(dateInfo)?.map((key, i) => {
                    return <div className='w-full text-sm flex justify-between items-center space-x-2 bg-gray-300 bg-opacity-50 hover:bg-gray-300 pl-2 rounded duration-200'>
                      <p>{key}</p>
                      <div className='flex space-x-2'>
                        {Object.keys(dateInfo[key])?.map((keyName, iName) => {
                          return <p className='text-xs bg-gray-400 bg-opacity-30 px-[6px] py-[4px] rounded'>
                            {keyName} / {dateInfo[key][keyName]}
                          </p>
                        })}
                      </div>
                    </div>
                  })
                  
                })
              }
            </div>
          </div>
        </div>
      : null }





    </div>
  </>);
}