import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as diff from "diff";
import { AuthContext } from "./AuthContext";




export const FuncContext = createContext();
export const FuncContextProvider = ({ children }) => {

  const navigate = useNavigate();


  const { currentCustomer, getJsonString } = useContext(AuthContext)

  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )

  





  const motionInsertedIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m425-338 187-119q13-8.341 13-23.171Q625-495 612-503L425-622q-14-8-27.5-.471Q384-614.943 384-599v238q0 15.943 13.5 23.471Q411-330 425-338Zm55.276 242Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>`
  const doneIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>`
  const motionDoneIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82Q156-260 126-330T96-479q0-110 57-201.5T305-821q14-7 28-2t22 19q7 13 1.5 26.5T337-756q-77 41-123 115t-46 163q0 129 91 219.5T480-168q130 0 221-91t91-220q0-89-46-163T623-757q-14-8-19.5-21.5T605-805q8-14 22-19t28 2q95 49 152 140.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm-36-378v-354q0-15 10.5-25.5T480-864q15 0 25.5 10.5T516-828v354l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-503L505-361q-11 11-25 11t-25-11L313-503q-11-11-10.5-25.5T314-554q11-11 25.5-11t25.5 11l79 80Z"/></svg>`
  const motionInProcessIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M483-337q-29 0-56.5-10T378-378q-20-20-31-46.282-11-26.282-11-55.718 0-9.737.8-18.987.8-9.25 3.2-18.013 2-10-1.656-18.823-3.656-8.824-12.594-12.501-9.75-3.676-18.375.513t-11.625 14.243Q292-521 290-507.63q-2 13.369-2 27.63 0 38 14.712 73.416T344-344q28 28 64.5 42t75.5 14l-27 27q-7 7.364-7 17.182Q450-234 457-227q7 7 16.818 7 9.818 0 17.182-7l59.789-59.789Q562-298 562-312.182T551-337l-59-60q-7.364-7-17.182-7Q465-404 458-397q-7 7-7 16.818 0 9.818 7 17.182l25 26Zm-7-287q29.699 0 57.349 10.5Q561-603 582-582q20 20 31 46.282 11 26.282 11 55.718 0 9-.8 18.5T620-443q-2 10 1.5 19t12.587 12q9.913 3 18.897-1.324 8.985-4.325 12.11-14.703Q669-441 670.5-453.5 672-466 672-480q0-38-15-73.5T615-616q-28-28-65-41.5T474-670l29-29q7-7.364 7-17.182Q510-726 503-733q-7-7-16.818-7-9.818 0-17.182 7l-59.789 59.789Q398-662 398-647.818T409-623l60 60q7.364 7 17.182 7Q496-556 503-563q7-7 7-16.818 0-9.818-7-17.182l-27-27Zm4.276 528Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>`
  const correctionIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-288q75 0 129-49t62-121q1-9-6-15.5t-17-6.5q-10 0-17 6t-9 15q-8 53-47.5 88T480-336q-60 0-102-42t-42-102q0-60 43-102t104-42l-26 27q-7 7-7.5 16.5T457-563q7 7 17 7t17-7l72-72q5-5 5-13t-5-13l-72-72q-7-7-16.5-7.5T457-733q-7 7-7 17t7 17l26 27q-81 0-138 55.5T288-480q0 80 56 136t136 56Zm0 192q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>`
  const pendingIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M6 11.25q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q6.521 8.75 6 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q10.521 8.75 10 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q14.521 8.75 14 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"/></svg>`

  const revisedIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l84 85q11 11 25.5 11t25.5-11l170-170q11-11 11-25t-11-25q-11-11-25.5-11T582-585L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-23 12.5-41t33.5-26l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26t12.5 41v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Z"/></svg>`
  const nonRevisedIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-10.636-11-24.818-11Q339-512 328-501q-11 11-11 25.667 0 14.666 11 25.333l84 85q10.909 11 25.455 11Q452-354 463-365l170-170q11-11 11-25t-11-25q-11-11-25.667-11-14.666 0-25.333 11L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-22.692 12.5-40.846Q193-754 214-762l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26.154T792-695v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Zm0-71q104-32.25 172-129t68-215v-180l-240-92-240 92v180q0 118.25 68 215t172 129Zm0-308Z"/></svg>`
  const tradIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>`
  
  const stateLegend = [
    { icon: pendingIcon, text: 'Pendiente', color: 'text-gray-500'},
    { icon: correctionIcon, text: 'Corrección', color: 'text-yellow-500'},
    { icon: motionInProcessIcon, text: 'En creación', color: 'text-orange-500'},
    { icon: motionDoneIcon, text: 'Creado', color: 'text-blue-500'},
    { icon: motionInsertedIcon, text: 'Insertado', color: 'text-purple-500'},
    { icon: doneIcon, text: 'Finalizado', color: 'text-green-500'},

    { icon: `<div className='scale-[0.8]'>${tradIcon}</div>`, text: 'Traducción eliminada', color: 'text-gray-500'},
    { icon: `<div className='scale-[0.8]'>${tradIcon}</div>`, text: 'Traducido', color: 'text-blue-500'},
    { icon: nonRevisedIcon, text: 'Revisión eliminada', color: 'text-gray-500'},
    { icon: revisedIcon, text: 'Revisado', color: 'text-blue-500'},
  ]


  function getPrioritaryStateFunc(stateTMP, motionStateTMP, revStateTMP, tradStateTMP, type) {
    var stateObjTMP = stateLegend[0];

    

    if (type === 'state_update_video_mark') {

      if (stateTMP === 3) { stateObjTMP = stateLegend[5] }
      else if (motionStateTMP === 4) { stateObjTMP = stateLegend[4] }
      else if (motionStateTMP === 3) { stateObjTMP = stateLegend[3] }
      else if (motionStateTMP === 2) { stateObjTMP = stateLegend[2] }
      else if (stateTMP === 2) { stateObjTMP = stateLegend[1] }
      else if (stateTMP === 1) { stateObjTMP = stateLegend[0] }
  
    } else if (type === 'state_update_video_mark_trad') {
      
      if (tradStateTMP === 0) { stateObjTMP = stateLegend[6] }
      else if (tradStateTMP === 1) { stateObjTMP = stateLegend[7] }
      else if (revStateTMP === 1) { stateObjTMP = stateLegend[8] }
      else if (revStateTMP === 2) { stateObjTMP = stateLegend[9] }

    }

    return stateObjTMP
  }
  















  /* Activity type translator */
  function activityTypeTranslatorFunc(activity) {
    {/* 

      update_user
      create_user

      priority_videos - (NO ACTIVITY)
      warning_videos - (NO ACTIVITY)
      video_priority_up
      update_video
      create_video
      remove_video
      video_state_change
      video_category_change
      video_title_change
      downloaded_video

      remove_video_review
      create_video_review

      update_video_mark
      create_video_mark
      remove_video_mark

      update_video_mark_comment
      create_video_mark_comment
      remove_video_mark_comment

    */}



    var stateTMP = null;
    if (activity.type === 'state_update_video_mark' || activity.type === 'state_update_video_mark_trad') {
      stateTMP = getPrioritaryStateFunc(activity?.mark_state, activity?.mark_motion_state, activity?.mark_rev_state, activity?.mark_trad_state, activity?.type)
    }


    switch(activity.type) {


      case 'user_login':
        return 'ha iniciado sesión.'
      case 'update_user':
        return 'ha editado un usuario.'
      case 'create_user':
        return 'ha creado un usuario.'

      case 'video_priority_up':
        return `ha marcado ${(activity?.video)? `el vídeo <strong>&nbsp; ${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''} &nbsp;</strong> como prioritario` : 'un vídeo como prioritario'}.`
      case 'update_video':
        return `ha editado ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'create_video':
        return `ha creado ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un nuevo vídeo'}.`
      case 'remove_video':
        return 'ha eliminado un vídeo.'
      case 'video_state_change':
        return (activity?.video_state_name)? `<p>ha cambiado el estado del vídeo ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''} a</p> <div class="MuiButtonBase-root px-1"> <p class="menu-item-` + activity?.video_state_name?.split('-')[1] + '">' + (activity?.video_state_name?.split('-')[0]) + '</p></div>.' : 'ha cambiado el estado del vídeo.'
      case 'video_category_change':
        return (activity?.video_state_name)? `<p>ha cambiado la categoría del vídeo ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''} a</p> <div class="MuiButtonBase-root px-1"> <p class="menu-item-` + activity?.video_state_name?.split('-')[1] + '">' + (activity?.video_state_name?.split('-')[0]) + '</p></div>.' : 'ha cambiado la categoría del vídeo.'
      case 'video_title_change':
        return `<p>ha cambiado el título del vídeo ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''}.</p>`
      case 'trad_update_video_mark':
        return `<p>ha cambiado la traducción de una marca en ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''}.</p>`
      case 'downloaded_video':
        return `<p>ha descargado el vídeo ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''}.</p>`

      case 'state_update_video_mark':
        return `<p>ha cambiado estado a </p><span class="flex items-center space-x-1 ${stateTMP?.color} font-bold">${stateTMP?.icon}<span>${stateTMP?.text}</span></span><p> de una marca tipo <strong>&nbsp;"${activity?.mark_type}"</strong> en ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''}.</p>`
      case 'state_update_video_mark_trad':
        return `<p>ha cambiado estado a </p><span class="flex items-center space-x-1 ${stateTMP?.color} font-bold">${stateTMP?.icon}<span>${stateTMP?.text}</span></span><p> de una marca tipo <strong>&nbsp;"${activity?.mark_type}"</strong> en ${(activity?.video)? `<strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : ''}.</p>`
  

      case 'create_video_review':
        return `ha creado una nueva revisión en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'remove_video_review':
        return `ha eliminado una nueva revisión en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`

      case 'update_video_mark':
        return `ha editado una marca en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'create_video_mark':
        return `ha creado una marca en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'remove_video_mark':
        return `ha eliminado una marca en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`

      case 'update_video_mark_comment':
        return `ha editado un comentario en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'create_video_mark_comment':
        return `ha creado un comentario en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`
      case 'remove_video_mark_comment':
        return `ha eliminado un comentario en ${(activity?.video)? `el vídeo <strong>&nbsp;${activity?.video?.name}${(currentCustomer?.setting_tittle_is_relevant && activity?.video?.title)? ' / ' + activity?.video?.title : ''}</strong>` : 'un vídeo'}.`

        
      default:
        return activity.type
    }
  }

  
  function fileExists(image_url){
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return ((image_url) && (http.status !== 404)) ? true : false;
  }


  function formatNumbersToPrint(number, decimals) {
    number = Number(`${Math.round(`${number}e2`)}e-2`);
    return (decimals || decimals === 0)? number?.toFixed(decimals)?.toString()?.replace(".", ",")?.replace(/\B(?=(\d{3})+(?!\d))/g, ".") 
    : number?.toString()?.replace(".", ",")?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  function agentHas(keyword) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
  }





  function navigateOrOpenFunc(event, url) {
    if (event.button === 1 || event.ctrlKey || event.shiftKey) {
      window.open(url).focus()
    } else {
      navigate(url)
    }
  }



  function anyCheckbox() {
    var anyChecked = false
    var inputElements = document.getElementsByTagName("input");
    var everyCheckTMP = [];

    for (var i = 0; i < inputElements.length; i++) {
      if (inputElements[i].name !== 'check-boss' && inputElements[i].name.includes('check-') && inputElements[i].type==="checkbox") {
        if (inputElements[i].checked) {
          everyCheckTMP.push(inputElements[i].value);
          anyChecked = true;
        }
      }
    }

    if (document.getElementById('check-boss')) {
      document.getElementById('check-boss').checked = anyChecked;
    }

    return anyChecked;
  }

  function checkAllFunc(anyChecked, saveChanges) {
    var isChecked = anyCheckbox();
    var everyCheckTMP = (isChecked)? null : [];

    document.querySelectorAll('input[type=checkbox]').forEach(el => {
      if (!isChecked) {
        everyCheckTMP.push(el.value);
      }

      el.checked = !isChecked
    });

    return (!anyChecked || saveChanges)? true : false
  }

  function unCheckAllFunc(clearAll, setLastChecked, setAnyChecked) {
    document.querySelectorAll('input[type=checkbox]').forEach(el => {
      el.checked = false
    });

    if (clearAll) {
      setLastChecked(null)
      setAnyChecked(null)
    }
  }


  function checksFunc(key, shiftKey, lastChecked, setLastChecked) {
    if (shiftKey) {
      for (var i=((lastChecked < key)? lastChecked : key); i<((lastChecked < key)? key+1 : lastChecked+1); i++) {
        var checkElement = document.getElementById('check-' + i)
        if (checkElement) {
          checkElement.checked = true;
        }
      }
    }

    setLastChecked(key)
  }



  function stateFilterFunc(elements, markStateFilter) {
    var motioner = (localCurrentUser?.rol === 'admin' || localCurrentUser?.rol?.includes('editor'))? true : false

    return elements?.filter((element)=> {
      var pass = false;
      var state = (element?.state)? parseInt(element?.state) : 3;
      var motion_state = (element?.motion_state)? parseInt(element?.motion_state) : 5;

      if (element && element?.positions) {
        const positions = (element?.positions)? getJsonString(element?.positions , true) : null;
        if (positions && Object.keys(positions)?.length) {
          for (var langKey of Object.keys(positions)) {
            const position = (positions && positions[langKey])? positions[langKey] : null;

            if ((position?.state < state || position?.state === 2) && state !== 2) {
              state = position?.state;
            }

            if (position?.motion_state < motion_state) {
              motion_state = position?.motion_state;
            }
          }
        }
      }

      var motionStateExtra = (
        ((markStateFilter[2]) || (!markStateFilter[2] && motion_state !== 2)) 
        && ((markStateFilter[3]) || (!markStateFilter[3] && motion_state !== 3))
        && ((markStateFilter[4]) || (!markStateFilter[4] && motion_state !== 4))
      );

      if (markStateFilter[5] && state === 3 && motionStateExtra) { pass = true }
      if (markStateFilter[4] && motion_state === 4 && motioner) { pass = true }
      if (markStateFilter[3] && motion_state === 3 && motioner) { pass = true }
      if (markStateFilter[2] && motion_state === 2 && motioner) { pass = true }
      if (markStateFilter[1] && state === 2 && motionStateExtra) { pass = true }
      if (markStateFilter[0] && state === 1 && motionStateExtra) { pass = true }

      return pass
    })
  }
 

  











  function getRevNumberOfWordsFunc(text) {
    return (text && text?.replace(/\n/g, " ")?.replaceAll(' ', ''))? remove_tags(format_texts(text?.replaceAll('-', '').replace(/\n/g, "<br />"))).replace(/\n/g, " ")?.replace(/[^a-zA-Z0-9 ]+/g, "")?.split(' ')?.filter((el) => el)?.length : 0;
  }



  function format_texts(html, removeLinks) {
    if (html) {
      var counterTMP = 0;
      const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s:?&.\/;#~%"=-]*>))/g;
  
      const htmlNode = document.createElement('div');
      htmlNode.innerHTML = html;
      htmlNode.querySelectorAll('*').forEach(function(node) {
          node.removeAttribute('style');
      });
      html = htmlNode.innerHTML;
      html = html.replaceAll('  ', "<br> ");

      html = html.replaceAll('target="_blank"', '');
      html = html.replaceAll('rel="noopener noreferrer"', '');
      html = html.replaceAll('<em  >', '<em>');
  
      html = html.replaceAll('<a ', "<a target='_blank' ");

      html = html.replaceAll('<li></li>', '');
      html = html?.split('<p><br></p>')?.filter((el) => el ).join('<p><br></p>');

      html = html?.replace(Rexp, function (x) {
        if (x) {
          var name = x.replaceAll("https://www.", '');

          if ((name?.includes('http://localhost:3000') || name?.includes('https://review.clicksound.io')) && name?.split('/')?.length === 6) {
            name = name?.split('/')[name?.split('/')?.length - 1];
          } else {
            counterTMP++;
            name = `[${(counterTMP<10)? '0'+counterTMP : counterTMP}] ${name}`;
          }
    
          return (removeLinks)? '' : `<a href='${x}' target='_blank' class="msgLink">${name}</a>`;
        }

        return x
      });
    }

    return html;
  }

  function remove_tags(html, noFotmat, avoidTrim) {
    if (html) {
      html = html.replaceAll('</p><p>', "\n");

      if (!noFotmat) {
        html = html.replaceAll('<strong>', "**");
        html = html.replaceAll('</strong>', "**");
        html = html.replaceAll('** **', " ");

        html = html.replaceAll('<em>', "*");
        html = html.replaceAll('</em>', "*");
        html = html.replaceAll('* *', " ");

        html = html.replace('<u>', "__");
        html = html.replaceAll('</u>', "__");
        html = html.replaceAll('__ __', " ");

        html = html.replaceAll('<li', "\n <li");
      }
  
  
      html = html.replace(/<br>/g, "$br$");
      html = html.replace(/(?:\r\n|\r|\n)/g, '$br$');
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      html = tmp.textContent || tmp.innerText;
      html = html.replace(/\$br\$/g, "\n");
    }
    
    return (avoidTrim)? html : html?.trim();
  }

  
  function format_message(message, tagInfo) {
    message = (message)? message?.replaceAll('<p><br></p>', '\n')?.trim()?.replaceAll('\n', '<p><br></p>')?.replace(/<img[^>]*>/g,"") : null
    const taggedUserIDs = (tagInfo?.length && tagInfo[0]?.length)? tagInfo[0] : null
    const taggedUserNames = (tagInfo?.length && tagInfo[1]?.length)? tagInfo[1] : null

    if (message && taggedUserIDs?.length) {
      for (var name of taggedUserNames) {
        message = message?.replaceAll(` @${name}`, ` <span class="text-blue-500">@${name}</span> `)
      }
    }

    return (message)? message : null;
  }



  function textDifferenceCounterFunc(oldTextStr, newTextStr) {

    var differences = 0;
    var newText = remove_tags(format_texts(newTextStr.replace(/\n/g, "<br />"))).replace(/\n/g, " ")/* .replaceAll('.', '').replaceAll(',', '') */;
    var oldText = remove_tags(format_texts(oldTextStr.replace(/\n/g, "<br />"))).replace(/\n/g, " ")/* .replaceAll('.', '').replaceAll(',', '') */; /* Original */
    let groups = diff.diffWords(oldText, newText);

    const diffObjects = groups.map(group => {
      const { value, added, removed } = group;
      return (value && value?.replaceAll(' ', '') !== '') && (
        (added)? { type: 'added', text: value, number: value?.split(' ')?.filter((el) => el && el?.replaceAll(' ', ''))?.length } 
        : (removed)? { type: 'removed', text: value, number: value?.split(' ')?.filter((el) => el && el?.replaceAll(' ', ''))?.length } 
        : { type: 'none', text: value, number: value?.split(' ')?.filter((el) => el && el?.replaceAll(' ', ''))?.length }
      );
    })?.filter((el) => el?.type);
    
  
    for (var i=0; i<diffObjects.length; i++) {
      var diffObj = diffObjects[i]
      if (diffObj?.type) {
        if (diffObj?.type === 'added') {
          differences = differences + diffObj?.number;
        } else if (diffObj?.type === 'removed' && diffObjects[i+1] && diffObjects[i+1]?.type === 'added') {
          /* NONE */
        } else if (diffObj?.type === 'removed') {
          differences = differences + diffObj?.number;
        } else {
          /* NONE */
        }
      }
    }

    return differences;
  }
















  function inBetweenDatesFilterFunc(startDate, endDate, orderFilter, includeLocalizations, itemsTMP, returnDate) {
    var videosTMP = itemsTMP?.filter((elementTMP, key) => checkInBetweenDatesFunc(startDate, endDate, orderFilter, includeLocalizations, elementTMP, returnDate))
    return videosTMP
  }
  
  function checkInBetweenDatesFunc(startDate, endDate, orderFilter, includeLocalizations, itemTMP, returnDate) {
    var startDateFormated = (startDate)? new Date(startDate).setDate(new Date(startDate).getDate()) :null 
    startDateFormated = (startDateFormated)? new Date(startDateFormated)?.toISOString().split('T')[0] : null
    
    var endDateFormated = (endDate)? new Date(endDate).setDate(new Date(endDate).getDate() + 1) : null
    endDateFormated = (endDateFormated)? new Date(endDateFormated).toISOString().split('T')[0] : null


    var videoDate = (
      (orderFilter?.includes('UpdatedDate '))? itemTMP?.updatedAt
      : (orderFilter?.includes('CreationDate '))? itemTMP?.createdAt 
      : (orderFilter?.includes('EndDate '))? itemTMP?.endDate 
      : (orderFilter?.includes('ReviewDate '))? itemTMP?.search_review_date
      : (orderFilter?.includes('RecordDate '))? itemTMP?.recordingDate
      : itemTMP?.endDate
    );
    
    var betweenDateFormated = (videoDate)? new Date(videoDate).toISOString().split('T')[0] : null
    var isPriority = (itemTMP?.is_priority)? true : false

    var isBetween = (
      (!startDateFormated || betweenDateFormated >= startDateFormated)
      && (!endDateFormated || betweenDateFormated <= endDateFormated)
    )? true : false;

    if (startDateFormated && !endDateFormated && !betweenDateFormated) {
      isBetween = true
    }


    var anyInBetween = false;
    var dateInBetween = false;
    var localizationBetweenDateFormated = null;
    if (includeLocalizations && itemTMP?.list_localizations?.length) {
      for (var i in itemTMP?.list_localizations) {
        var localizationTMP = itemTMP?.list_localizations[i];

        var localizationVideoDate = (
          (orderFilter?.includes('UpdatedDate '))? localizationTMP?.updatedAt 
          : (orderFilter?.includes('CreationDate '))? localizationTMP?.createdAt 
          : (orderFilter?.includes('EndDate '))? localizationTMP?.endDate 
          : (orderFilter?.includes('ReviewDate '))? localizationTMP?.search_review_date
          : (orderFilter?.includes('RecordDate '))? localizationTMP?.recordingDate
          : localizationTMP?.endDate
        );
        localizationBetweenDateFormated = (localizationVideoDate)? new Date(localizationVideoDate).toISOString().split('T')[0] : ((orderFilter.includes(' ASC'))? 1 : 99999999999999)  
        if (localizationTMP?.is_priority) { isPriority = true }

        var isBetweenTMP = (
            (!startDateFormated || localizationBetweenDateFormated >= startDateFormated)
            && (!endDateFormated || localizationBetweenDateFormated <= endDateFormated)
        )? true : false;
        
        if (startDateFormated && !endDateFormated && !localizationBetweenDateFormated) {
          isBetweenTMP = true
        }

        if (isBetweenTMP) {
          anyInBetween = true;
          dateInBetween = (localizationBetweenDateFormated < betweenDateFormated || !isBetween)? localizationBetweenDateFormated : betweenDateFormated;
          break;
        }
      }
    }


    if ((includeLocalizations && anyInBetween) || isPriority) { isBetween = true; }
    if (!dateInBetween) { dateInBetween = betweenDateFormated; }


    return (returnDate)? dateInBetween : isBetween
  }






  function checkStatePathPointFunc(relations, video) {
    if (relations?.length) {
      if (video?.search_review_version && video?.vimeo_url) { return 'Terminado'  }
      else if (relations?.filter((el) => el?.search_customer_setting_title === 'Sync subs IA' && el?.state === '✅')?.length) { return 'Subiendo el vídeo a vimeo' }
      else if (relations?.filter((el) => el?.search_customer_setting_title === 'ALTERED' && el?.state === '✅')?.length) { return 'Integrando IA y subs' }
      else if (relations?.filter((el) => el?.search_customer_setting_title === 'Voiceover' && el?.state === '✅')?.length) { return 'En IA' }
      else if (relations?.filter((el) => el?.search_customer_setting_title === 'Rask' && el?.state === '✅')?.length) { return 'Generando voces traducidas' }
      else if (relations?.filter((el) => el?.search_customer_setting_title === 'SUBS Traduïts' && el?.state === '✅')?.length) { return 'En proceso de voiceover' }
    }

    return 'En traducción';
  }




  const timeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M396-816q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360-867 370.35-877.5 380.7-888 396-888h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600-837 589.65-826.5 579.3-816 564-816H396Zm83.789 432Q495-384 505.5-394.35 516-404.7 516-420v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-624 454.5-613.65 444-603.3 444-588v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480-96q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144-362 144-432q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410-768 479.56-768q58.28 0 111.86 19.5T691-694l27.282-27.282Q729-732 743-731.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816-490.384 816-431.856 816-362 789.493-301.08q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550-96 480-96Zm0-72q110 0 187-77t77-187q0-110-77-187t-187-77q-110 0-187 77t-77 187q0 110 77 187t187 77Zm0-264Z"/></svg>
  const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M276-384h264q15.3 0 25.65-10.289 10.35-10.29 10.35-25.5Q576-435 565.65-445.5 555.3-456 540-456H276q-15.3 0-25.65 10.289-10.35 10.29-10.35 25.5Q240-405 250.35-394.5 260.7-384 276-384Zm0-132h408q15.3 0 25.65-10.289 10.35-10.29 10.35-25.5Q720-567 709.65-577.5 699.3-588 684-588H276q-15.3 0-25.65 10.289-10.35 10.29-10.35 25.5Q240-537 250.35-526.5 260.7-516 276-516Zm0-132h408q15.3 0 25.65-10.289 10.35-10.29 10.35-25.5Q720-699 709.65-709.5 699.3-720 684-720H276q-15.3 0-25.65 10.289-10.35 10.29-10.35 25.5Q240-669 250.35-658.5 260.7-648 276-648Zm-36 408-82.883 82.883Q140-140 118-149.406 96-158.812 96-183v-609q0-29.7 21.15-50.85Q138.3-864 168-864h624q29.7 0 50.85 21.15Q864-821.7 864-792v480q0 29.7-21.15 50.85Q821.7-240 792-240H240Zm-72-72h624v-480H168v480Zm0 0v-480 480Z"/></svg>
  const translationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m607.968-213-33.201 93.025q-3.795 10.549-13.281 17.262Q552-96 541-96q-20 0-30.5-16t-3.5-34l143-381q4.808-11 14.423-18t21.154-7h28.846q11.539 0 21.154 7T750-527l144 382q7 18-4.368 33.5Q878.263-96 859.316-96 847-96 837.5-103q-9.5-7-13.5-18l-32-92H607.968ZM632-279h136l-66.216-189H698l-66 189ZM360-405 194-241q-11 11-25 10.5T144-242q-11-11-11-25t11-25l167-165q-36-38-67-79t-54-89h82.353Q290-593 313-565t47 55q38-42 70-87.5t52-98.5H84q-15.3 0-25.65-10.289Q48-716.579 48-731.789 48-747 58.35-757.5 68.7-768 84-768h240v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q375-864 385.5-853.65 396-843.3 396-828v60h240q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672-717 661.65-706.5 651.3-696 636-696h-77.567Q537-627 497-568.5T409-457l91 90-28 74-112-112Z"/></svg>
  const correctionIconA = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-288q75 0 129-49t62-121q1-9-6-15.5t-17-6.5q-10 0-17 6t-9 15q-8 53-47.5 88T480-336q-60 0-102-42t-42-102q0-60 43-102t104-42l-26 27q-7 7-7.5 16.5T457-563q7 7 17 7t17-7l72-72q5-5 5-13t-5-13l-72-72q-7-7-16.5-7.5T457-733q-7 7-7 17t7 17l26 27q-81 0-138 55.5T288-480q0 80 56 136t136 56Zm0 192q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>



  const billTypesList = [
    { id: 'minutes', icons: timeIcon, name: 'Minutaje de vídeos originals', label: null },
    { id: 'motions', icons: markIcon, name: 'Motions de vídeos originals', label: null },
    { id: 'translation', icons: translationIcon, name: 'Transcripción, subtítulos y traducción de vídeos originals (SPA-ENG)', label: 'SOLO SPA y ENG' }, /* translation */
    { id: 'translation_multiple_langs', icons: translationIcon, name: 'Transcripción, subtítulos y traducción de vídeos originals', label: 'TODOS LOS IDIOMAS' }, /* translation */
    { id: 'localizations_motions', icons: markIcon, name: 'Motions de las localizaciones' },
    { id: 'linguisticcorrection', icons: correctionIconA, name: 'Correcciones lingüísticas', label: null },
    { id: 'minutes_translation', icons: <>{timeIcon} <p>+</p> {translationIcon}</>, name: 'Minutaje + Trad & Sub', label: 'OLD / DO NOT USE' },
  ];
  








  function getChyronMotionStateFunc(video) {
  
    const chyronOverlapStates = (video?.chyron_states)? getJsonString(video?.chyron_states , true) : null;
    var chyronState = 3;
    var chyronMotionState = 5;

    if (video?.professors?.length) {
      var hasOverlapStates = false;

      for (var profTMP of video?.professors) {
        if (chyronOverlapStates?.length) {
          for (var statesTMP of chyronOverlapStates) {
            if (parseInt(statesTMP?.professor_id) === parseInt(profTMP?.id) && statesTMP?.language === video?.language) {
              if (parseInt(statesTMP?.state) < parseInt(chyronState) && parseInt(chyronState) !== 2) { chyronState = parseInt(statesTMP?.state); }
              if (parseInt(statesTMP?.motion_state) < parseInt(chyronMotionState)) { chyronMotionState = parseInt(statesTMP?.motion_state); }
              hasOverlapStates = true
            }
          }
        }

        const deffault_positions = (profTMP?.positions)? getJsonString(profTMP?.positions , true) : null;
        const deffault_position = (deffault_positions && video?.language && deffault_positions[video?.language])? deffault_positions[video?.language] : null;

        if (!hasOverlapStates || !deffault_position?.state || !deffault_position?.motion_state || parseInt(deffault_position?.state) === 2 || parseInt(deffault_position?.motion_state) === 1) {
          chyronState = (deffault_position && parseInt(deffault_position?.state) && deffault_position?.state < chyronState)? deffault_position?.state : 1;
          chyronMotionState = (deffault_position && parseInt(deffault_position?.motion_state) && deffault_position?.motion_state < chyronState)? deffault_position?.motion_state : 1;
        }
      }
    }

    return chyronMotionState
  }








  return (
    <FuncContext.Provider
      value={{
        activityTypeTranslatorFunc,
        fileExists,
        formatNumbersToPrint,
        agentHas,
        navigateOrOpenFunc,
        billTypesList,
        getChyronMotionStateFunc,
        
        stateFilterFunc,
        checkStatePathPointFunc,

        inBetweenDatesFilterFunc,
        checkInBetweenDatesFunc,

        anyCheckbox,
        checkAllFunc,
        checksFunc,
        unCheckAllFunc,

        format_texts,
        remove_tags,
        format_message,
        textDifferenceCounterFunc,
        getRevNumberOfWordsFunc,
      }}
    >
      {children}
    </FuncContext.Provider>
  );
};
