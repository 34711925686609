import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';

import GetAllUserTags from './../graphql/queries/GetAllUserTags'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';









const Segments = () => {



  const { currentCustomer, currentUser } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)

  


  const columns = (currentUser?.id === 3)? [
    { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC' },
    { id: 'leader', label: 'Usuarios asignados' },
    { id: 'leader_fake', label: 'Usuarios asignados (old)' },
    { id: 'videos', label: 'Vídeoss asignados' },
  ] : [
    { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC' },
    { id: 'leader', label: 'Usuarios asignados' },
    { id: 'videos', label: 'Vídeoss asignados' },
  ];
  
  


  const [recordingSets, setRecordingSets] = useState([]);
  const [search, setSearch] = useState('');
  const [orderFilter, setOrderFilter] = useState('Name DESC');

  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;


  const { loading, data: dataRecordingSets } = useQuery(GetAllUserTags, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'no-cache' 
  })


  const { data: dataProfessors, loading: loadingProfessors } = useQuery(GetAllProfessorsByCustomer, {
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'no-cache' 
  })










  useEffect(() => {    
    setCurrentNavActive('segments')
  }, [])


  useEffect(() => {   
    if (dataRecordingSets?.userTags?.length) {

      var recordingSetsTMP = [...dataRecordingSets?.userTags];
      var entred = false;
      
      /* Search */
      if (search && search.replaceAll(' ', '') !=='') {
          entred = true;
          recordingSetsTMP = recordingSetsTMP.filter(setTMP => {
            var nameFound = setTMP?.name?.toLowerCase().includes(search.toLowerCase());
            var descFound = setTMP?.description?.toLowerCase().includes(search.toLowerCase());
            return (nameFound || descFound)
          })
      }


      /* Order filter */
      if (orderFilter && orderFilter !== 'null') {
        entred = true;

        if (orderFilter.includes('Name')) { /* Text */
          recordingSetsTMP = recordingSetsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.name > a.name)? -1 : (b.name < a.name)? 1 : 0) : ((a.name > b.name)? -1 : (a.name < b.name)? 1 : 0) })
        } else if (orderFilter.includes('CreatedAt')) { /* Date */
          recordingSetsTMP = recordingSetsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? (new Date(b.createdAt) - new Date(a.createdAt)) : (new Date(a.createdAt) - new Date(b.createdAt)); })
        }
      }


      if (!entred) { resetRecordingSets() } else { setRecordingSets(recordingSetsTMP) }
    } else { resetRecordingSets() }

  }, [orderFilter, search, dataRecordingSets])






  function resetRecordingSets() {
    if (dataRecordingSets?.recordingSets?.length) {
      setRecordingSets(dataRecordingSets?.recordingSets)
    } else {
      setRecordingSets(null)
    }
  }




  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <div className='flex flex-wrap py-14 pl-2'>
          <div className='flex flex-wrap sm:flex-nowrap items-center'>
            <h1 className='font-bold text-4xl'>Segmentos de usuario</h1>
            <p className='text-gray-400 mt-0 sm:mt-2 ml-2 text-xs sm:text-base'>{(recordingSets)? ''+recordingSets?.length+' resultados encontrados.' : ''}</p>
          </div>
        </div>




        <Tooltip disableInteractive title="Crear un nuevo segmento" placement="bottom" arrow>
          <IconButton 
            color="primary" 
            size="medium"
            onMouseUp={(event)=> navigateOrOpenFunc(event, "/segments/new") }
          >
            {createItemIcon}
          </IconButton>
        </Tooltip>
      </div>


      <div className='mb-4 flex items-center space-x-2'>
        <div className="search-box">
          <IconButton
            type="button"
            sx={{ p: '4px', borderRadius: 0 }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>

          <InputBase
            id="search-input"
            className='w-full'
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busca por nombre o descripción"
            value={search}
            onChange={(element)=>setSearch(element.target.value)}
          />
        </div>
      </div>


      <div className='w-full'>
        {(loading)?
          <div className='w-full text-sm bg-gray-100 border border-gray-300 p-2 rounded-md text-gray-500 mb-4'> 
            <p className='mb-1'>{(recordingSets)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded-full'/>
          </div>
        : null}
        {(!loading && recordingSets?.length === 0)? <p className='text-sm text-gray-400 px-1'>No se han encontrado {currentCustomer?.label_professors?.toLowerCase()} dentro de {currentCustomer?.name}</p>  : null}
      </div>

    
      {(recordingSets && recordingSets?.length)?
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)', marginBottom: 5 }} className='border border-gray-400 border-opacity-60'>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 310px)', }}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={'columns-'+column.id}
                      className='select-none'
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.width, backgroundColor: 'rgb(243 244 246)' }}
                      onClick={()=>{
                        if (column?.filterUp === orderFilter) {
                          if (column?.filterDown) {
                            setOrderFilter(column?.filterDown)
                          } else {
                            setOrderFilter(null)
                          }
                        } else if (column?.filterDown === orderFilter) {
                          setOrderFilter(null)
                        } else if (column?.filterUp) {
                          if (column?.filterUp) {
                            setOrderFilter(column?.filterUp)
                          } else {
                            setOrderFilter(null)
                          }
                        }
                      }}
                    >
                      <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                        <div> {column.label} </div>
                        {(column?.filterUp === orderFilter) && upArrowIcon}
                        {(column?.filterDown === orderFilter) && downArrowIcon}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {recordingSets?.map((row) => {

                  const profesTMP = (dataProfessors && dataProfessors?.professorsByCustomer?.length)? dataProfessors?.professorsByCustomer : null;
                  const userIDsSplit = (row?.user_ids)? row?.user_ids?.split(',') : null
                  const userLisTMP = (userIDsSplit?.length)? profesTMP?.map((el) => { if (userIDsSplit?.includes(el?.id)) { return { id: el?.id, name: el?.name} } })?.filter((el) => parseInt(el?.id)) : null

                  return (
                    <TableRow 
                      key={'segments-'+row.id} 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      className="hover:bg-gray-100 duration-200 cursor-pointer"
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/segments/"+row.id) }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'column-data-'+column.id} align={column.align} style={{borderBottom: 0}}>
                            {
                              (column.id === 'description')? <p dangerouslySetInnerHTML={{__html: (value)? value : '---' }}></p>
                              : (column.id === 'leader' && userLisTMP?.length)? userLisTMP?.map((el) => el?.name)?.join(', ').replace(/,([^,]*)$/, ' y ' + '$1')
                              : (column.id === 'leader_fake' && row?.assignedUsers?.length)? row?.assignedUsers?.map((el) => el?.name)?.join(', ').replace(/,([^,]*)$/, ' y ' + '$1')
                              : (column.id === 'videos' && row?.assignedVideos?.length)? row?.assignedVideos?.length + ' vídeos'
                              : (value)? value : '---'
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>
        </Paper>
      : (!loading)? <p className='text-sm text-gray-400 px-1'>No se han encontrado segmentos</p>
      : null }


    </div>
  )
}

export default Segments
