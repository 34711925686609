import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import VideoListItem from '../components/VideoListItem';
import ModalVideoEditing from '../components/ModalVideoEditing';
import SearchRecomendations from './SearchRecomendations';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import VideoHomeBaseListTableCell from './VideoHomeBaseListTableCell';
import VideoHomeBaseListFolderCell from './VideoHomeBaseListFolderCell';
import FolderListItem from './FolderListItem';







const VideoHomeBaseList = ({

  videos,
  dataStates,
  getVideos,
  saveChanges,

  hasVideos,
  hasVideosPostFilter,

  clicksoundUserNames,
  settingsRelations,

  showMore,
  setShowMore,
  anyChecked,
  setAnyChecked,
  selectedModule,
  selectedFolder,
  setSelectedFolder,

  listFormat,
  editMode,
  showFullEditMode,

  search,
  setSearch,
  orderFilter,
  setOrderFilter,
  startFilterDate,
  setStartFilterDate,
  endFilterDate,
  setEndFilterDate,
  moduleFilter,
  setModuleFilter,
  showFilters,
  professorFilter,
  setProfessorFilter,

  dataVideoNames,
  categoryOptions,

  loading,
  loadingEditMode,
  loadingSettingsMode,
  baseLoading,
  setBaseLoading,

  videoFolders,
  pathFolders,
  canBeAddedToFolder,
  addedVideosToFolder,
  addVideoToFolderFunc,
  createFolder,
  setCreateFolder,
  
}) => {


  const { currentUser, currentCustomer, marksNearName } = useContext(AuthContext)
  const { checkAllFunc, getChyronMotionStateFunc } = useContext(FuncContext)

  const oneDay = 24 * 60 * 60 * 1000;
  const addedVideosToFolderIDs = (addedVideosToFolder?.length)? addedVideosToFolder?.map((el) => el?.id) : null;




  const [openVideoAdminEitor, setOpenVideoAdminEitor] = useState(false);
  const [videoList, setVideoList] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [windowDimensions, setWindowDimensions] = useState({ width: window?.innerWidth, height: window?.innerHeight });


  /* Edit mode vars */
  const [lastChecked, setLastChecked] = useState(null);


  /* Icon vars */
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;


  /* Table var */
  const [columns, setColumns] = useState([
    { id: 'thumbnail', label: 'Vídeo', width: 115 }, /* calc(100% - 30px) */
    { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC' }, /* calc(100% - 30px) */

    { id: 'production_state', label: 'Estado', filterUp: 'ProdState ASC', filterDown: 'ProdState DESC' },
    { id: 'lastReviewDateFormated', label: 'Revisión', filterUp: 'ReviewDate ASC', filterDown: 'ReviewDate DESC', align: 'right', width: 100  },
    { id: 'search_number_of_marks', label: 'Marcas', filterUp: 'Marks ASC', filterDown: 'Marks DESC', width: 70 },

    { id: 'updatedAtFormated', label: 'Modif', filterUp: 'UpdatedDate ASC', align: 'right', width: 100  },
    { id: 'endDateFormated', label: 'Publi', filterUp: 'EndDate ASC', filterDown: 'EndDate DESC', align: 'right', width: 100  },
  ]);

  

  




  

  
  useEffect(() => {
    function handleResize() {      
      const { innerWidth: width, innerHeight: height } = window; 
      const diff = Math.abs(width - windowDimensions?.width);

      if (diff > 20) {
        setWindowDimensions({ width: width, height: height });
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions])




  useEffect(() => {
    var columnsTMP = [];


    if (windowDimensions?.width > 520 || (currentUser?.rol?.includes('user') && windowDimensions?.width > 630)) {
      columnsTMP.push({ id: 'thumbnail', label: 'Vídeo', width: 115 })
    } 


    columnsTMP.push({ id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC', width: (currentCustomer?.setting_tittle_is_relevant)? 250 : 130 })


    if (windowDimensions?.width > 800 && !showFullEditMode && marksNearName) {
      columnsTMP.push({ id: 'search_number_of_marks', label: 'Marcas', filterUp: 'Marks ASC', filterDown: 'Marks DESC', width: 150 })
    }

    if (windowDimensions?.width > 1000 && !showFullEditMode) {
      columnsTMP.push({ id: 'production_state', label: 'Estado', filterUp: 'ProdState ASC', filterDown: 'ProdState DESC' })
    } 

    if (windowDimensions?.width > 840 && currentCustomer?.setting_professor_col && !showFullEditMode) {
      columnsTMP.push({ id: 'search_professor_name', label: currentCustomer?.label_professor })
    } 

    if (windowDimensions?.width > 1200 && !showFullEditMode && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) {
      columnsTMP.push({ id: 'search_number_of_bill_concepts', label: 'Conceptos', align: 'right', width: 30  })
    }
        
    if (windowDimensions?.width > 800 && !showFullEditMode && !marksNearName) {
      columnsTMP.push({ id: 'search_number_of_marks', label: 'Marcas', filterUp: 'Marks ASC', filterDown: 'Marks DESC', width: 150 })
    }

    if (windowDimensions?.width > 1420 && !showFullEditMode) {
      columnsTMP.push({ id: 'lastReviewDateFormated', label: 'Revisión', filterUp: 'ReviewDate ASC', filterDown: 'ReviewDate DESC', align: 'right', width: 100  })
    }

    if (windowDimensions?.width > 1050 || currentUser?.rol?.includes('user')) {
      columnsTMP.push({ id: 'endDateFormated', label: 'Publi', filterUp: 'EndDate ASC', filterDown: 'EndDate DESC', align: 'right', width: 100  })
    }

    if (!currentUser?.professor && (editMode || canBeAddedToFolder)) columnsTMP.push({ id: 'actions', label: '', align: 'right', width: 50 })

    var idsTMp = (columnsTMP?.length)? columnsTMP?.map((el) => el?.id )?.join(',') : null
    var ids = (columns?.length)? columns?.map((el) => el?.id )?.join(',') : null
    if (idsTMp !== ids) { setColumns(columnsTMP) }
  }, [windowDimensions, columns, showFullEditMode, marksNearName, editMode, canBeAddedToFolder]);

  





  useEffect(() => {
    const selected_folder_id = (parseInt(selectedFolder?.id))? parseInt(selectedFolder?.id) : null
    const start_folders = (videoFolders?.length)? videoFolders?.filter((el) => !el?.search_everything_completed || !el?.search_number_of_videos) : []
    const end_folders = (videoFolders?.length)? videoFolders?.filter((el) => el?.search_everything_completed && el?.search_number_of_videos) : []
    var videoListTMP = ((videos?.length && videoFolders?.length)? [...start_folders, ...videos, ...end_folders] : (videos?.length)? videos : [...start_folders, ...end_folders])
   
    videoListTMP = (videoListTMP?.length)? videoListTMP?.filter((el) => {
      const own_folder_id = (parseInt(el?.folder_id))? parseInt(el?.folder_id) : null
      return el?.__typename === "Video" || own_folder_id === selected_folder_id
    }) : null
    
    setVideoList((videoListTMP?.length)? videoListTMP : null)
  }, [videos, videoFolders, selectedFolder])


























  /* To remember table pagination */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(999999999);
  /* 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  */





  
  return <>

    {(!currentUser?.rol?.includes('user') || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) &&
      <ModalVideoEditing
        open={openVideoAdminEitor}
        setOpen={setOpenVideoAdminEitor}
        video={selectedVideo}
        setVideo={setSelectedVideo}
        getVideos={getVideos}
      />
    }




    {((loading || loadingEditMode || loadingSettingsMode || baseLoading) || (!loading && !loadingEditMode && !loadingSettingsMode && !baseLoading && (videoList?.length === 0 || !videoList))) && 
      <div className='w-full pt-[10px] pb-[2px]'>
        {(loading || loadingEditMode || loadingSettingsMode || baseLoading) &&
          <div className='w-full text-sm bg-gray-100 border border-gray-400 border-opacity-60 p-2 pt-[6px] rounded-md text-gray-500'> 
            <p className='mb-1'>{(videoList)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded'/>
          </div>
        }

        {(!loading && !loadingEditMode && !loadingSettingsMode && !baseLoading && (videoList?.length === 0 || !videoList)) &&
            <div className='flex'>
              <p className='w-auto px-2 py-[3px] rounded text-sm text-gray-400 bg-gray-100 border border-gray-300 border-opacity-50'>
                No se han encontrado vídeos en {currentCustomer?.name}{(startFilterDate && endFilterDate)? ' entre las fechas de inicio y fin introducidas' : (startFilterDate)? ' posteriores la fecha de inicio introducida' : (endFilterDate)? ' anteriores a la fecha de fin introducida' : ''}.
              </p> 
            </div>
        }
      </div> 
    }

    {(hasVideos && !hasVideosPostFilter) && 
      <div className='pt-2'>
        <SearchRecomendations
          searchedText={search?.trim()}
          setSearchedText={setSearch}

          moduleFilter={moduleFilter}
          setModuleFilter={setModuleFilter}

          startFilterDate={startFilterDate}
          setStartFilterDate={setStartFilterDate}
          endFilterDate={endFilterDate}
          setEndFilterDate={setEndFilterDate}
          professorFilter={professorFilter}
          setProfessorFilter={setProfessorFilter}

          hasVideos={hasVideos}
          hasVideosPostFilter={hasVideosPostFilter}
        />
      </div>
    }






    {(listFormat)? 
      ((videoList?.length)?
        <div className={`pt-[10px] z-10 ${(loadingEditMode || loadingSettingsMode)? 'opacity-50 pointer-events-none' : ''}`}>
          <div className="mb-2 rounded-md border border-gray-400 border-opacity-60 bg-gray-100">
              <TableContainer sx={{ width: '100%', overflow: 'initial', boxShadow: 0 }}>
                <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>

                  <TableHead>
                    <TableRow className="table-stiky">
                      {columns.map((column, key) => (
                        <TableCell
                          key={'columns-'+column.id}
                          className={`select-none whitespace-nowrap 
                            ${(column?.filterUp || column?.filterDown)? 'cursor-pointer hover:bg-gray-500 hover:bg-opacity-10 active:bg-opacity-20 duration-200' : ''} 
                            ${(key === 0)? 'table-start' : (((key + 1) === columns?.length)? 'table-end' : '')}
                          `}
                          align={column.align}
                          sx={{ 
                            width: (column.id === 'thumbnail' && showFullEditMode)? '70px !important' : (column.width)? column.width : 'auto',
                            minWidth: (column.id === 'thumbnail' && showFullEditMode)? '70px !important' : (column.minWidth)? column.minWidth : 'auto',
                            maxWidth: (column.id === 'thumbnail' && showFullEditMode)? '70px !important' : 'auto',
                            paddingInline: (column.id !== 'production_state')? '14px' : '6px',
                            paddingBlock: 1,
                            borderBottom: '1px rgba(156, 163, 175, 0.5) solid !important',
                          }}
                          onClick={()=>{
                            if (!editMode) {
                              setOrderFilter((column?.filterUp === orderFilter && column?.filterDown)? column.filterDown : (column?.filterUp)? column?.filterUp : 'EndDate ASC')
                            }
                          }}
                        >
                          <div className="flex text-sm" style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                            {(column.id !== 'actions' || (column.id === 'actions' && !editMode)) && <div> {column.label} </div>}

                            {(column?.filterUp === orderFilter || column?.filterDown === orderFilter)?
                              <div className='opacity-30'>{(column?.filterUp === orderFilter)? upArrowIcon : downArrowIcon}</div>
                            : null}

                            {(column.id === 'actions' && editMode && !canBeAddedToFolder) &&
                              <input 
                                id={'check-boss'} 
                                name={'check-boss'} 
                                type="checkbox" 
                                className='focus:outline-none scale-[1.2]'
                                onChange={(event)=> setAnyChecked(checkAllFunc(anyChecked, saveChanges))} 
                              />
                            }
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  
                  
                  <TableBody className={`relative`}>
                    {videoList?.slice(0, showMore)?.map((video, key) => {



                      if (video?.__typename === "Video") {



                        const vidIdTMP = (parseInt(video?.is_translation_of))? parseInt(video?.is_translation_of) : parseInt(video?.id);
                        const videoSettings = (settingsRelations?.length)? settingsRelations?.filter((el) => (parseInt(el?.video_id) === parseInt(vidIdTMP) && (!video?.is_translation_of || (el?.language === video?.language)))) : null


                        var endDateDifference = null
                        var positive = true
                        if (video?.endDate) {
                          const firstDate = new Date(video?.endDate);
                          const secondDate = new Date();
                          if (secondDate >= firstDate) { positive = false }
                          endDateDifference = Math.round((firstDate - secondDate) / oneDay) /* Math.round(Math.abs((firstDate - secondDate) / oneDay)) */
                        }

                        const lastState = (video?.production_state && dataStates && Object.keys(dataStates)?.length)? dataStates[video?.production_state]?.position : null;
                        const lastStatePosition = (lastState?.position)? lastState?.position : 0;

                        const record = (video?.production_state === 16)? true : false
                        const danger = (video?.is_priority)? true : false
                        const warning = (currentUser?.rol?.includes('user') && !currentUser?.professor && video?.endDate && lastState?.trigger_publication && endDateDifference <= 4)? true : false
                        
                        const chyronMotionState = getChyronMotionStateFunc(video);
                        const nmb_marks = (video?.search_number_of_marks)? video?.search_number_of_marks?.split('/') : 0;
                        const nmb_notes = (video?.search_number_of_notes)? video?.search_number_of_notes?.split('/') : 0;
                        const motionerWarning = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && video?.endDate && (endDateDifference <= 4 || !positive) && (lastStatePosition <= 7 || (lastState?.ended_marks && lastState?.name !== 'Descartado'))
                          && (
                            (!currentCustomer?.setting_tittle_is_relevant && !video?.is_sound_media && !video?.title_disabled && video?.motion_state < 5)
                            || (currentCustomer?.setting_initial_chyron && !video?.is_sound_media && !video?.chyrons_disabled && chyronMotionState < 5)
                            || parseInt(nmb_marks[1]) > 0 || parseInt(nmb_marks[2]) > 0 || parseInt(nmb_marks[3]) > 0 || parseInt(nmb_marks[4]) > 0
                            || parseInt(nmb_notes[1]) > 0 || parseInt(nmb_notes[2]) > 0 || parseInt(nmb_notes[3]) > 0 || parseInt(nmb_notes[4]) > 0
                          )
                        )? true : false


                        
                        return (key < showMore) ? <React.Fragment key={'videos-list-'+video?.id}>
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            className={`group/row has_border ${(video?.list_localizations?.length)? '' : 'has_border'} bg-gray-100 hover:bg-gray-200 duration-200 ${(lastState?.color === 'gray')? 'opacity-30 bg-gray-300 bg-opacity-70 hover:opacity-60' : ''}`}
                          >
                            {columns.map((column, colKey) => {
                              const value = (video && column?.id && column.id in video && video[column.id])? video[column.id] : '-';
                              
                              var extraMarks = [];
                              if (column?.id === 'name' && video?.extra_marks_json) { extraMarks = JSON.parse(video?.extra_marks_json) }
                              var categoryTMP = null;
                              if (column?.id === 'name' && parseInt(video?.category_id) && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) { categoryTMP = categoryOptions?.filter((el) => parseInt(video?.category_id) === parseInt(el?.id))[0] }

                              return (
                                <VideoHomeBaseListTableCell 
                                  key={'column-data-'+column.id}

                                  listKey={key}
                                  column={column}
                                  video={video}
                                  clicksoundUserNames={clicksoundUserNames}
                                  settingsRelations={videoSettings}
                                  value={value}
                                  
                                  orderFilter={orderFilter}
                                  endFilterDate={endFilterDate}
                                  startFilterDate={startFilterDate}
                                  moduleFilter={moduleFilter}
                                  isSubVideo={false}

                                  record={record}
                                  danger={danger}
                                  warning={warning}
                                  motionerWarning={motionerWarning}
                                  extraMarks={extraMarks}
                                  categoryTMP={categoryTMP}
                                
                                  dataVideoNames={dataVideoNames}
                                  categoryOptions={categoryOptions}
                                
                                  setSelectedVideo={setSelectedVideo}
                                  setOpenVideoAdminEitor={setOpenVideoAdminEitor}
                                
                                  editMode={editMode}
                                  showFullEditMode={showFullEditMode}
                                  
                                  lastChecked={lastChecked}
                                  setLastChecked={setLastChecked}
                                  setAnyChecked={setAnyChecked}

                                  canBeAddedToFolder={canBeAddedToFolder}
                                  addedVideosToFolderIDs={addedVideosToFolderIDs}
                                  addVideoToFolderFunc={addVideoToFolderFunc}
                                />
                              );
                            })}
                          </TableRow>


                          {(video?.list_localizations?.length && showFilters?.includes('localizations'))?
                            video?.list_localizations?.map((videoLocalization, localKey) => {
                              const lastState = (videoLocalization?.production_state && dataStates && Object.keys(dataStates)?.length)? dataStates[videoLocalization?.production_state]?.position : null;
                              const lastStatePosition = (lastState?.position)? lastState?.position : 0;
                              
                              const record = (videoLocalization?.production_state === 16)? true : false
                              const danger = (videoLocalization?.is_priority)? true : false

                              const chyronMotionState = getChyronMotionStateFunc(videoLocalization);
                              const nmb_marks = (videoLocalization?.search_number_of_marks)? videoLocalization?.search_number_of_marks?.split('/') : 0;
                              const nmb_notes = (videoLocalization?.search_number_of_notes)? videoLocalization?.search_number_of_notes?.split('/') : 0;
                              const motionerWarning = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && videoLocalization?.endDate && (endDateDifference <= 4 || !positive) && (lastStatePosition <= 7 || (lastState?.ended_marks && lastState?.name !== 'Descartado'))
                                && (
                                  (!currentCustomer?.setting_tittle_is_relevant && !videoLocalization?.is_sound_media && !videoLocalization?.title_disabled && videoLocalization?.motion_state < 5)
                                  || (currentCustomer?.setting_initial_chyron && !videoLocalization?.is_sound_media && !video?.chyrons_disabled && chyronMotionState < 5)
                                  || parseInt(nmb_marks[1]) > 0 || parseInt(nmb_marks[2]) > 0 || parseInt(nmb_marks[3]) > 0 || parseInt(nmb_marks[4]) > 0
                                  || parseInt(nmb_notes[1]) > 0 || parseInt(nmb_notes[2]) > 0 || parseInt(nmb_notes[3]) > 0 || parseInt(nmb_notes[4]) > 0
                                )
                              )? true : false

                              return (
                                <TableRow
                                  key={'videos-localization-list-'+video.id}
                                  role="checkbox"
                                  tabIndex={-1}
                                  className={`group/row has_border bg-[#e9ebee] hover:bg-[#d9dcdf] duration-200 ${(lastState?.color === 'gray')? 'opacity-30 bg-gray-300 bg-opacity-70 hover:opacity-60' : ''}`}
                                > {/* opacity-70 hover:opacity-100 */}
                                  {columns.map((column, colKey) => {
                                    const value = (videoLocalization[column.id])? videoLocalization[column.id] : '-';

                                    var extraMarks = [];
                                    if (column.id === 'name' && videoLocalization?.extra_marks_json) { extraMarks = JSON.parse(videoLocalization?.extra_marks_json) }
                                    var categoryTMP = null;
                                    if (column.id === 'name' && parseInt(video?.category_id) && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) { categoryTMP = categoryOptions?.filter((el) => parseInt(video?.category_id) === parseInt(el?.id))[0] }
              

                                    return /* (column.id !== 'lastReviewDateFormated') && */ (
                                      <VideoHomeBaseListTableCell 
                                        key={'column-data-'+column.id}

                                        listKey={key + '-' + localKey}
                                        column={column}
                                        video={videoLocalization}
                                        clicksoundUserNames={clicksoundUserNames}
                                        settingsRelations={videoSettings?.filter((el) => el?.language === videoLocalization?.language)}
                                        value={value}

                                        orderFilter={orderFilter}
                                        endFilterDate={endFilterDate}
                                        startFilterDate={startFilterDate}                                      
                                        moduleFilter={moduleFilter}
                                        isSubVideo={true}

                                        record={record}
                                        danger={danger}
                                        warning={false}
                                        motionerWarning={motionerWarning}
                                        extraMarks={extraMarks}
                                        categoryTMP={categoryTMP}
                                      
                                        dataVideoNames={dataVideoNames}
                                        categoryOptions={categoryOptions}
                                      
                                        setSelectedVideo={setSelectedVideo}
                                        setOpenVideoAdminEitor={setOpenVideoAdminEitor}
                                      
                                        editMode={editMode}
                                        showFullEditMode={showFullEditMode}
                                        
                                        lastChecked={lastChecked}
                                        setLastChecked={setLastChecked}
                                        setAnyChecked={setAnyChecked}

                                        canBeAddedToFolder={canBeAddedToFolder}
                                        addedVideosToFolderIDs={addedVideosToFolderIDs}
                                        addVideoToFolderFunc={addVideoToFolderFunc}
                                      />
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                          : null }

                        </React.Fragment> : null;



                      } else {


                        return <TableRow
                          key={'videos-localization-list-'+video.id}
                          role="checkbox"
                          tabIndex={-1}
                          className={`group/row has_border bg-[#e9ebee] hover:bg-[#d9dcdf] duration-200 relative`}
                        >
                          {columns.map((column, colKey) => {
                            return <VideoHomeBaseListFolderCell 
                              key={'folder-column-data-'+column.id}

                              column={column}
                              folder={video}

                              pathFolders={pathFolders}
                              setSelectedFolder={setSelectedFolder}

                              showFullEditMode={showFullEditMode}
                              createFolder={createFolder}
                              setCreateFolder={setCreateFolder}
                            />
                          })}
                        </TableRow>

                      }



                    })}
                  </TableBody>
                </Table>
                


                {(showMore < videoList?.length)? 
                  <div 
                    className='group/showMore w-full text-sm flex items-center space-x-3 whitespace-nowrap text-gray-500 opacity-60 hover:opacity-100 bg-gray-300 px-1 py-2 cursor-pointer duration-200' 
                    onClick={()=> { setShowMore(showMore+30) } }
                  >
                    {downArrowIcon}
                    <div>Mostrar más</div>
                    <hr className='w-full border-gray-400 duration-200'/>
                    {downArrowIcon}
                  </div>
                : null }


              </TableContainer>
          </div>
        </div>
      : null )

    :

      <div className={`flex items-center flex-wrap pt-4`}>

        <TransitionGroup component={null} appear={true}>
          {(videoList?.length) ?
            videoList?.slice(0, showMore).map((video, key) => {
              if (key < showMore) {
                return <CSSTransition
                  key={'css_item_'+video.id}
                  timeout={500}
                  classNames="fade"
                >
                  {(video?.__typename === "Video")?
                    <VideoListItem
                      video={video}
                      priorityList={false}
                      setSelectedVideo={setSelectedVideo}
                      setOpenVideoAdminEitor={setOpenVideoAdminEitor}

                      canBeAddedToFolder={canBeAddedToFolder}
                      addedVideosToFolderIDs={addedVideosToFolderIDs}
                      addVideoToFolderFunc={addVideoToFolderFunc}
                    />
                  :
                    <FolderListItem
                      folder={video}

                      moduleFilter={moduleFilter}
                      selectedModule={selectedModule}
                      pathFolders={pathFolders}

                      createFolder={createFolder}
                      setCreateFolder={setCreateFolder}

                    />
                  }
                  
                </CSSTransition>
              }
            })
          : null }
        </TransitionGroup>

        {(videoList?.length && showMore < videoList?.length)? 
          <>
            <div 
              className='group/showMore w-full text-sm flex items-center space-x-3 whitespace-nowrap text-gray-500 opacity-60 hover:opacity-100 bg-gray-300 px-1 py-2 cursor-pointer duration-200' 
              onClick={()=> {setBaseLoading(true); setShowMore(showMore+30)} }
            >
              {downArrowIcon}
              <div>Mostrar más</div>
              <hr className='w-full border-gray-400 duration-200'/>
              {downArrowIcon}
            </div>
          </>
        : null }
      </div>

    }
  </>
}

export default VideoHomeBaseList
