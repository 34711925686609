import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';
import dayjs from 'dayjs';

import GetLastDailyEmails from './../graphql/queries/GetLastDailyEmails'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomDatePicker from '../components/Inputs/CustomDatePicker';





const columns = [
  { id: 'title', label: 'Título', filterUp: 'Title ASC', filterDown: 'Title DESC' },
  { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC', maxWidth: 300 },
  { id: 'sendedAtFormated', label: 'Envíado el', filterUp: 'SendedDate ASC', filterDown: 'SendedDate DESC', width: 180, minWidth: 180, maxWidth: 180, align: 'right' },
];






const Sets = () => {



  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)

  
  const startDateTMP = new Date(dayjs().subtract(7, 'day').startOf('day'));
  const endDateTMP = new Date(dayjs().endOf('day'));

  
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [search, setSearch] = useState('');
  const [orderFilter, setOrderFilter] = useState('SendedDate ASC');
  const [startDate, setStartDate] = useState(startDateTMP);
  const [endDate, setEndDate] = useState(endDateTMP);

  const [baseLoading, setBaseLoading] = useState(true);


  const selectedStartDate = (startDate)? startDate : startDateTMP;
  const selectedEndDate = (endDate)? endDate : endDateTMP;

  const startDateFormated = (("0" + selectedStartDate.getDate()).slice(-2) + '/' + ("0" + (selectedStartDate.getMonth() + 1)).slice(-2) + '/' + selectedStartDate.getFullYear() + ' a las ' + ("0" + selectedStartDate.getHours()).slice(-2) + ':' + ("0" + selectedStartDate.getMinutes()).slice(-2));
  const endDateFormated = (("0" + selectedEndDate.getDate()).slice(-2) + '/' + ("0" + (selectedEndDate.getMonth() + 1)).slice(-2) + '/' + selectedEndDate.getFullYear() + ' a las ' + ("0" + selectedEndDate.getHours()).slice(-2) + ':' + ("0" + selectedEndDate.getMinutes()).slice(-2));





  const [getEmails, { loadingEmails, data: dataEmails }] = useLazyQuery(GetLastDailyEmails, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      date_start: (startDate)? startDate : startDateTMP,
      date_end: (endDate)? endDate : endDateTMP,
    },
    fetchPolicy: 'no-cache' 
  })


  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const emailIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M164-160q-34.65 0-59.325-24.675Q80-209.35 80-244v-472q0-34.65 24.675-59.325Q129.35-800 164-800h632q34.65 0 59.325 24.675Q880-750.65 880-716v472q0 34.65-24.675 59.325Q830.65-160 796-160H164Zm632-472L501.918-448.937Q497-446 491.5-444q-5.5 2-11.5 2t-11.5-1.75Q463-445.5 458-449L164-632v388h632v-388ZM480-518l318-198H163l317 198ZM164-632v9.848-60.598.75-34 34-.292V-622v-10 388-388Z"/></svg>







  useEffect(() => {    
    setCurrentNavActive('summary')
  }, [])


  useEffect(() => {    
    setTimeout(() => {
      setBaseLoading(false)
    }, 1000)
  }, [baseLoading])


  useEffect(() => {    
    getEmails()
  }, [orderFilter, search, startDate, endDate])
  

  useEffect(() => {   
    setBaseLoading(true)

    if (dataEmails?.getLastDailyEmails?.length) {

      var emailsTMP = [...dataEmails?.getLastDailyEmails];
      var entred = false;
      
      /* Search */
      if (search && search.replaceAll(' ', '') !=='') {
          entred = true;
          emailsTMP = emailsTMP.filter(semailTMP => {
            var nameFound = semailTMP?.name?.toLowerCase().includes(search.toLowerCase());
            var titleFound = semailTMP?.title?.toLowerCase().includes(search.toLowerCase());
            var bodyFound = semailTMP?.body?.toLowerCase().includes(search.toLowerCase());
            return (nameFound || titleFound || bodyFound)
          })
      }


      /* Order filter */
      if (orderFilter && orderFilter !== 'null') {
        entred = true;

        if (orderFilter.includes('Name')) { /* Text */
          emailsTMP = emailsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.name > a.name)? -1 : (b.name < a.name)? 1 : 0) : ((a.name > b.name)? -1 : (a.name < b.name)? 1 : 0) })
        } else if (orderFilter.includes('Title')) { /* Date */
          emailsTMP = emailsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.title > a.title)? -1 : (b.title < a.title)? 1 : 0) : ((a.title > b.title)? -1 : (a.title < b.title)? 1 : 0) })
        } else if (orderFilter.includes('Email')) { /* Date */
          emailsTMP = emailsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.email > a.email)? -1 : (b.email < a.email)? 1 : 0) : ((a.email > b.email)? -1 : (a.email < b.email)? 1 : 0) })
        } else if (orderFilter.includes('SendedDate')) { /* Date */
          emailsTMP = emailsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? (new Date(b.sendedAt) - new Date(a.sendedAt)) : (new Date(a.sendedAt) - new Date(b.sendedAt)); })
        } else if (orderFilter.includes('CreatedAt')) { /* Date */
          emailsTMP = emailsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? (new Date(b.createdAt) - new Date(a.createdAt)) : (new Date(a.createdAt) - new Date(b.createdAt)); })
        }
      }
      

      if (!entred) { setEmails() } else {setEmails(emailsTMP)}
    } else { setEmails() }

  }, [dataEmails])

  function resetEmails() {
    if (dataEmails?.getLastDailyEmails?.length) {
      setEmails(dataEmails?.getLastDailyEmails)
    } else {
      setEmails(null)
    }
  }




  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          <div className='relative py-14 pl-2'>
            <h1 className='font-bold text-4xl'>Emails envíados</h1>
            <p className='h-0 text-xs opacity-50'>*Hay un límite de 500 emails por búsqueda así que recuerda utilizar los filtros por fecha de forma correcta.</p>
          </div>

          <p className='text-gray-400 mt-0 sm:mt-2 ml-2 text-xs sm:text-base'>
            {(emails)? '' + emails?.length + ` resultados encontrados entre el ${startDateFormated} y el ${endDateFormated}.` : ''}
          </p>
        </div>





        <Tooltip disableInteractive title="Configurar email diario" placement="bottom" arrow>
          <IconButton 
            color="primary" 
            size="medium"
            onMouseUp={(event)=> navigateOrOpenFunc(event, "/email-template/summary") }
          >
            {emailIcon}
          </IconButton>
        </Tooltip>
      </div>


      <div className='mb-4 flex items-center space-x-2'>
        <div className="search-box h-[30px] flex items-center">
          <IconButton
            type="button"
            sx={{ p: '4px', borderRadius: 0 }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>

          <InputBase
            id="search-input"
            className='w-full'
            sx={{ ml: 1, flex: 1 }}
            placeholder="Busca por nombre o descripción"
            value={search}
            onChange={(element) => setSearch(element.target.value)}
          />
        </div>

        <div className='flex space-x-2 max-w-[350px]'>
          <CustomDatePicker
            label="Fecha inicial"
            inputFormat="DD/MM/YYYY"

            value={(startDate)? startDate : startDateTMP}
            onChange={(value)=> {
              if (new Date(value).getFullYear() > 1970) {
                const dateTMP = new Date(dayjs(new Date(value)).startOf('day'));
                setStartDate(dateTMP)
              } else {
                setStartDate(startDateTMP)
              }
            }}
          />

          <CustomDatePicker
            label="Fecha final"
            inputFormat="DD/MM/YYYY"

            value={(endDate)? endDate : endDateTMP}
            onChange={(value)=> {
              if (new Date(value).getFullYear() > 1970) {
                const dateTMP = new Date(dayjs(new Date(value)).endOf('day'));
                setEndDate(dateTMP)
              } else {
                setEndDate(endDateTMP)
              }
            }}
          />
        </div>
      </div>


      <div className='w-full'>
        {(loadingEmails || baseLoading) &&
          <div className='w-full text-sm bg-gray-100 border border-gray-300 p-2 rounded-md text-gray-500 mb-4'> 
            <p className='mb-1'>{(emails)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded-full'/>
          </div>
        }
        {(!loadingEmails && !baseLoading && emails?.length === 0)? <p className='text-sm text-gray-400 px-1'>No se han encontrado {currentCustomer?.label_professors?.toLowerCase()} dentro de {currentCustomer?.name}</p>  : null}
      </div>

    
      {(emails && emails?.length)?
        <Paper 
          sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)', marginBottom: 5 }} 
          className={`border border-gray-400 border-opacity-60`}
        >
          <TableContainer sx={{ minHeight: '300px', maxHeight: ((selectedEmail)? 'calc(100vh - 720px)' : 'calc(100vh - 275px)') }}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={'columns-'+column.id}
                      className='select-none'
                      align={column.align}
                      style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width, backgroundColor: 'rgb(243 244 246)' }}
                      onClick={()=>{
                        if (column?.filterUp === orderFilter) {
                          if (column?.filterDown) { setOrderFilter(column?.filterDown) } else { setOrderFilter(null) }
                        } else if (column?.filterDown === orderFilter) {
                          setOrderFilter(null)
                        } else if (column?.filterUp) {
                          if (column?.filterUp) { setOrderFilter(column?.filterUp) } else { setOrderFilter(null) }
                        }
                      }}
                    >
                      <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                        <div> {column.label} </div>
                        {(column?.filterUp === orderFilter) && upArrowIcon}
                        {(column?.filterDown === orderFilter) && downArrowIcon}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {emails?.map((row) => {
                  return (
                    <TableRow 
                      key={'sets-'+row.id} 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      className={`hover:bg-gray-100 active:bg-gray-200 duration-200 cursor-pointer whitespace-nowrap`}
                      onMouseUp={(event)=> setSelectedEmail(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        const names = (row?.name)? row?.name?.split(',') : null
                        const emails = (row?.email)? row?.email?.split(',') : null

                        return (
                          <TableCell
                            key={'column-data-'+column.id}
                            align={column.align}
                            style={{ borderBottom: 0, minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width }}
                            className={`${(parseInt(selectedEmail?.id) === parseInt(row?.id))? 'bg-blue-200 bg-opacity-50' : ''}`}
                          >
                            {(column.id === 'name')? 
                              <div className='w-min max-w-[300px]'>
                                <Tooltip disableInteractive title={names?.map((el, key) => names[key] + ' (' + emails[key] + ')' )?.join(', ')?.replace(/,([^,]*)$/, ' y ' + '$1')} placement="bottom" arrow>
                                  <p className='truncate'>{(value)? value?.replaceAll(',', ', ')?.replace(/,([^,]*)$/, ' y ' + '$1') : '---'}</p>
                                </Tooltip>
                              </div>
                            :
                              <p className='truncate'>{(value)? value : '---'}</p>
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>
        </Paper>
      : (!loadingEmails && !baseLoading)? <p className='opacity-50'>No se han encontrado emails disponibles.</p>
      : null }


      {(emails?.length)?
        <div className={`overflow-hidden ${(selectedEmail)? 'mb-12 h-[400px]' : 'h-0'}`}>
          <div className='flex items-center justify-between space-x-3 text-gray-500 px-1 text-xs font-bold'>
            <p>{selectedEmail?.title} <span className='opacity-50'>el {selectedEmail?.sendedAtFormated}</span></p>
            <button className=' text-blue-500 hover:text-blue-700' onClick={() => setSelectedEmail(null)}>Cerrar</button>
          </div>
          
          <div className='relative flex items-start justify-center p-4 rounded bg-gray-50 bg-opacity-30 border border-gray-300 h-[384px] overflow-auto'>
            <div dangerouslySetInnerHTML={{ __html: (selectedEmail?.body)? selectedEmail?.body : '---' }}></div>
          </div>
        </div>
      : null }


    </div>
  )
}

export default Sets
