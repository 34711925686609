import { useState, useEffect, useContext } from 'react'
import { AuthContext } from "../../../contexts/AuthContext";
import { FuncContext } from '../../../contexts/FuncContext';
import { LayoutContext } from "../../../contexts/LayoutContext";
import { useMutation } from '@apollo/client'

import CreateOrUpdateBill from '../../../graphql/mutations/CreateOrUpdateBill'

import ReactQuillSimpleImput from '../../ReactQuillSimpleImput';

import Popover from '@mui/material/Popover';





export default function RowButtonsMenu({ anchorEl, setAnchorEl, noteText, setNoteText, savedNoteText, setSavedNoteText, factura10Bill, billOption, video, hiddenItems, setHiddenItems, disableHidden, center }) {


    
    const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
    const { remove_tags } = useContext(FuncContext)
    const { setErrorAlertMsg } = useContext(LayoutContext)
    const hasPermision = (currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? true : false


    const [hidden, setHidden] = useState((parseInt(video?.id) && hiddenItems?.length)? hiddenItems?.includes(parseInt(video?.id)) : false);
    const [showNoteEditor, setShowNoteEditor] = useState(true);

    const [loadingNotes, setLoadingNotes] = useState(false);

    const noteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m552-216 192-192H588q-15.3 0-25.65 10.35Q552-387.3 552-372v156Zm-336.34 72Q186-144 165-165.15 144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.11Q816-773.77 816-744.12v329.74q0 14.38-5 27.38-5 13-16.2 24.2L597.2-165.2Q586-154 572.97-149q-13.04 5-27.42 5H215.66ZM324-408h120q15.3 0 25.65-10.29Q480-428.58 480-443.79t-10.35-25.71Q459.3-480 444-480H324q-15.3 0-25.65 10.29Q288-459.42 288-444.21t10.35 25.71Q308.7-408 324-408Zm0-144h312q15.3 0 25.65-10.29Q672-572.58 672-587.79t-10.35-25.71Q651.3-624 636-624H324q-15.3 0-25.65 10.29Q288-603.42 288-588.21t10.35 25.71Q308.7-552 324-552Z"/></svg>
    const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
    const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

    const markIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>
    const editIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;

    const visibilityOnIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
    const visibilityOffIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>
  



    const [createOrUpdateBill] = useMutation(CreateOrUpdateBill)






    useEffect(() => {       
        if (!anchorEl) {
            setShowNoteEditor(false)
        }
    }, [anchorEl])

    
    useEffect(() => {       
        resetNotesFunc()
    }, [billOption])

    

    




    
    async function changeVisibilityFunc(video) {
        var hiddenItemsTMP = (hiddenItems?.length)? hiddenItems : []

        if (hiddenItemsTMP?.includes(video?.concepte)) { hiddenItemsTMP = hiddenItemsTMP?.filter((el) => el !== video?.concepte); setHidden(false) }
        else if (hiddenItemsTMP?.length < 35) { hiddenItemsTMP?.push(video?.concepte); setHidden(true) }
        else { setErrorAlertMsg('No se pueden añadir mas elementos a esta factura.') }

        setHiddenItems((hiddenItemsTMP?.length)? [...hiddenItemsTMP] : null)

        const hiddenItemsCompressed = (hiddenItemsTMP && hiddenItemsTMP?.length)? hiddenItemsTMP?.join(',') : null;
        await createOrUpdateBill({variables: { 
            own_user_token: currentUser?.token,
            id: parseInt(billOption?.id),
            hidden_concepts: hiddenItemsCompressed,
        }})
    }


    async function addNotesFunc() {
        if (!disableHidden) {
            setLoadingNotes(true) 

            var billNotes = (billOption?.video_notes)? getJsonString(billOption?.video_notes) : {};
            if (noteText && remove_tags(noteText)) { billNotes[video?.concepte] = noteText; } 
            else if (video?.concepte in billNotes) { delete billNotes[video?.concepte] }

            await createOrUpdateBill({variables: { 
                own_user_token: currentUser?.token,
                id: parseInt(billOption?.id),
                video_notes: (billNotes && Object.keys(billNotes)?.length)? JSON.stringify(billNotes) : null,
            }})

            setTimeout(() => {
                setSavedNoteText((noteText && remove_tags(noteText))? noteText : null)
                setLoadingNotes(false) 
                setAnchorEl(null)
            }, 200 )
        }
    }

    function resetNotesFunc() {
        if (savedNoteText) {
            setNoteText(savedNoteText)
        } else {
            const billNotes = (billOption?.video_notes)? getJsonString(billOption?.video_notes) : null;
            if (video?.concepte && billNotes && billNotes[video?.concepte]) {
                setNoteText(billNotes[video?.concepte])
            } else {
                setNoteText(null)
            }
        }
    }








    return (hasPermision)?
        
        <Popover
            id={'buttons-' + billOption?.id}
            open={(anchorEl)? true : false}
            anchorEl={anchorEl}
            onClose={() => { setTimeout(() => { setAnchorEl(null) }, 400) }}
            anchorOrigin={{ vertical: 'bottom', horizontal: ((center)? 'center' : 'left') }}
            transformOrigin={{ vertical: -10, horizontal: ((center)? 340 : 22) }}
            sx={{ '& .MuiPaper-root': { overflow: 'visible !important' } }}
            data-avoid
        >
            <div data-avoid className={`text-sm whitespace-nowrap text-left z-[30] ${(center)? '' : 'left-[30px]'}`}>
                {(!center)?
                    <div data-avoid className={`absolute w-1 h-1 p-1 mt-[-4px] ${(center)? 'right-[527px] ml-auto mr-auto' : 'left-[28px]'} rotate-45 bg-white border-t border-l border-gray-400 border-opacity-60 z-[31]`}></div>
                : null }

                <div data-avoid id='clickbox' className={`flex flex-col space-y-[2px] p-2 min-w-[200px] bg-white border border-gray-400 border-opacity-60 rounded shadow-2xl`}>
                    {(!showNoteEditor)? <>




                        {/* Video URL */}
                        <a 
                            href={`${process.env.REACT_APP_ROUTE}${currentCustomer?.abbreviated_name}/${(billOption['module'])? billOption['module'] : 'extra'}/${(video['concepte'])? video['concepte'] : (video['name'])? video['name'] : null}`.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                            className={'w-full pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-gray-300 bg-opacity-40 hover:bg-opacity-60 active:bg-opacity-100 rounded text-gray-700 cursor-pointer duration-200'}
                            target="_blank"
                        >
                            {markIcon}
                            <p>Abrir el vídeo</p>
                        </a>


                        {/* Video Edit URL */}
                        <a
                            href={`${process.env.REACT_APP_ROUTE}video/${(video['codi'])? video['codi'] : (video['id'])? video['id'] : null}`.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}
                            className={'w-full pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-gray-300 bg-opacity-40 hover:bg-opacity-60 active:bg-opacity-100 rounded text-gray-700 cursor-pointer duration-200'}
                            target="_blank"
                        >
                            {editIcon}
                            <p>Abrir la edición del vídeo</p>
                        </a>


                        {/* Visibility */}
                        {(!disableHidden && factura10Bill?.document && !parseInt(factura10Bill?.document?.sended))? <>
                            {/* Notes */}
                            <button onClick={() => setShowNoteEditor(!showNoteEditor)} className={'w-full pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-gray-300 bg-opacity-40 hover:bg-opacity-60 active:bg-opacity-100 rounded text-gray-700 cursor-pointer duration-200'}>
                                {noteIcon}
                                <p>Añadir notas</p>
                            </button>


                            <button onClick={() => changeVisibilityFunc(video)} className={'w-full pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-gray-300 bg-opacity-40 hover:bg-opacity-60 active:bg-opacity-100 rounded text-gray-700 cursor-pointer duration-200'}>
                                {(hidden)? visibilityOffIcon : visibilityOnIcon}
                                <p>Esconder vídeo</p>
                            </button>
                        </> : null }




                    </> : (showNoteEditor && !disableHidden)? <>

                        <div className='min-w-[700px]'>
                            <ReactQuillSimpleImput 
                                id={"note_" + billOption?.id} 
                                label={`Nota del vídeo ${video?.concepte}`}
                                placeholder={'Escribe la nota...'}
                                value={(noteText)? noteText : ''}
                                style={1}

                                onChange={(value) => setNoteText(value)} 
                            />
                            <div className='w-full pt-2 flex justify-between space-x-2'>
                                <button onClick={() => { resetNotesFunc(); setShowNoteEditor(!showNoteEditor) }} className={'pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-red-400 hover:bg-red-500 active:bg-red-700 rounded text-white cursor-pointer duration-200'}>
                                    {cancelIcon}
                                    <p>Cancelar</p>
                                </button>

                                <button onClick={() => addNotesFunc(noteText)} className={'pl-1 pr-2 py-[3px] flex items-center space-x-1 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded text-white cursor-pointer duration-200'}>
                                    {(loadingNotes)? loadingIcon : saveIcon}
                                    <p>Guardar</p>
                                </button>
                            </div>
                        </div>
                    
                    </> : null }
                </div>
            </div>
        </Popover>

    : null
}