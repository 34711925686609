import gql from "graphql-tag";
export default gql`
  query GetAllVideoMessages($own_user_token: String!, $own_customer_token: String!, $video_id: Int!) {
    getVideoMessages(own_user_token: $own_user_token, own_customer_token: $own_customer_token, video_id: $video_id) {
      id
      text
      customer_token
      
      has_files

      from_user_id
      to_user_id
      to_video_id
      tagged_user_ids
      tagged_user_names
      
      createdAt
      updatedAt

      from_user {
        id
        name
        profile_picture
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
