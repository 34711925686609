import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import axios from 'axios';

import AddExtraRecordingSets from './AddExtraRecordingSets';

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import RemoveVideo from './../graphql/mutations/RemoveVideo'

import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Collapse from '@mui/material/Collapse';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import ModalVideoLocalizations from './ModalVideoLocalizations'
import CustomAutocomplete from './Inputs/CustomAutocomplete'
import CustomSelect from './Inputs/CustomSelect'
import CustomDatePicker from './Inputs/CustomDatePicker'
import CustomSwitch from './Inputs/CustomSwitch'
import CustomButton from './Inputs/CustomButton'


const VideoHomeEditMode = ({

  showFullEditMode,
  setShowFullEditMode,

  videos,
  dataModules,
  dataStates,
  dataProfessors,

  anyChecked,
  setAnyChecked,

  getVideos,

  categoryOptions,
  categoryOption,
  setCategoryOption,
  category, 
  setCategory,

  editMode,
  setEditMode,
  loadingEditMode,

  saveChanges,
  setSaveChanges,
  
}) => {





  const { currentUser, currentCustomer, languageList } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { checkAllFunc } = useContext(FuncContext)





  const [removeVideos, setRemoveVideos] = useState(false);
  const [creatingLocalizations, setCreatingLocalizations] = useState(false);

  const [selectedVideos, setSelectedVideos] = useState(null);

  const [module, setModule] = useState('null');
  const [professor, setProfessor] = useState('null');
  const [state, setState] = useState('null');
  const [language, setLanguage] = useState('null');
  const [originalLanguage, setOriginalLanguage] = useState('null');
  const [recordingDate, setRecordingDate] = useState(null);
  const [changeRecordingDate, setChangeRecordingDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [publicationEndDate, setPublicationEndDate] = useState(null);
  const [pubEndDateActive, setPubEndDateActive] = useState(false);
  const [changeEndDate, setChangeEndDate] = useState(false);
  const [recordingSetsIds, setRecordingSetsIds] = useState('null');


  const [hasTranscription, setHasTranscription] = useState('null');
  const [hasSubtitulation, setHasSubtitulation] = useState('null');
  const [hasTranslation, setHasTranslation] = useState('null');
  const [titleDisabled, setTitleDisabled] = useState('null');
  const [chyronsDisabled, setChyronsDisabled] = useState('null');


  /* Option vars */
  const [moduleOptions, setModuleOptions] = useState([]);
  const [moduleOption, setModuleOption] = useState({ label: 'Sin modificar', id: 'null' });  
  const [professorOptions, setProfessorOptions] = useState([]);
  const [professorOption, setProfessorOption] = useState({ label: 'Sin modificar', id: 'null' });  


  /* Icon vars */
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const locationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-231q-8.966 0-17.483-3-8.517-3-16.157-8.826Q369-304 292.5-395.414 216-486.829 216-594.037q0-67.963 24.065-118.228t61.814-84.089q37.749-33.823 84.935-50.734Q434-864 480-864q46 0 93.186 16.912 47.186 16.911 84.935 50.734 37.749 33.824 61.814 84.089Q744-662 744-593.716 744-486 668-395.5 592-305 513.73-242.913 506-237 497.483-234T480-231Zm.212-291Q510-522 531-543.737q21-21.738 21-52.263 0-30.938-21.212-52.969-21.213-22.031-51-22.031Q450-671 429-648.956q-21 22.045-21 53Q408-565 429.212-543.5q21.213 21.5 51 21.5ZM252-96q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q216-147 226.35-157.5 236.7-168 252-168h456q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q744-117 733.65-106.5 723.3-96 708-96H252Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>

  const calendarOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-528q0-29 21.5-50.5T216-768h72v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q339-864 349.5-853.65 360-843.3 360-828v60h240v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q651-864 661.5-853.65 672-843.3 672-828v60h72q29 0 50.5 21.5T816-696v169q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q765-491 754.5-501.378q-10.5-10.378-10.5-25.72V-528H216v360h252q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q504-117 493.65-106.5 483.3-96 468-96H216Zm360-36v-62.432q0-7.568 3-14.068t8-11.5l199-198q7.258-7.412 16.129-10.706Q811-432 819.759-432q9.556 0 18.315 3.5Q846.833-425 854-418l44 45q6.588 7.258 10.294 16.129Q912-348 912-339.241q0 8.76-3.294 17.917Q905.412-312.167 898-305L700-107q-5 5-11.5 8t-14.068 3H612q-15.3 0-25.65-10.35Q576-116.7 576-132Zm243-161 45-46-45-45-45 46 45 45Z"/></svg>
  const calendarOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m480-297-56 56q-11 11-25 10.5T374-242q-11-11-11-25.5t11-25.5l55-55-56-56q-11-11-10.5-25.5T374-455q11-11 25.5-11t25.5 11l55 56 56-56q11-11 25.5-11t25.5 11q11 11 11 25.5T587-404l-56 56 56 56q11 11 11 25t-11 25q-11 11-25.5 11T536-242l-56-55ZM216-96q-29.7 0-50.85-21.5Q144-139 144-168v-528q0-29 21.15-50.5T216-768h72v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q339-864 349.5-853.65 360-843.3 360-828v60h240v-60q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q651-864 661.5-853.65 672-843.3 672-828v60h72q29.7 0 50.85 21.5Q816-725 816-696v528q0 29-21.15 50.5T744-96H216Zm0-72h528v-360H216v360Z"/></svg>






  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)
  const [removeVideo] = useMutation(RemoveVideo)









  /* Select modules */
  useEffect( () => { 
    var modulesSelect = [{ label: 'Sin modificar', id: 'null' }, { label: currentCustomer?.label_extras_module, id: null }];
    setModuleOption({ label: 'Sin modificar', id: 'null' })

    if (dataModules?.length) {
      dataModules?.map((element, key) => {
        var optionTMP = { label: element.name, id: element.id, abbreviated_name: element.abbreviated_name }
        modulesSelect.push(optionTMP)
      });
    }

    setModuleOptions(modulesSelect);
  }, [dataModules])

  useEffect( () => { 
    if (moduleOption?.id) {
      setModule(moduleOption?.id)
    } else {
      setModule(null)
    }
  }, [moduleOption])

  
  /* Select professors */
  useEffect( () => { 
    var professorsSelect = [{ label: 'Sin modificar', id: 'null' }, { label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null }];
    setProfessorOption({ label: 'Sin modificar', id: 'null' })

    if (dataProfessors?.length) {
      dataProfessors?.map((element, key) => {
        professorsSelect.push({ label: element.name + ((element.professor_code) && (' / ' + element.professor_code)), id: element.id })
      });
    }

    setProfessorOptions(professorsSelect);
  }, [dataProfessors])

  useEffect( () => { 
    if (professorOption?.id) {
      setProfessor(professorOption?.id)
    } else {
      setProfessor(null)
    }
  }, [professorOption])



  useEffect( () => { 
    if (creatingLocalizations) {
      var inputElements = document.getElementsByTagName("input");
      var ids = [];
  
      if (inputElements?.length) {
        for (var i = 0; i < inputElements.length; i++) {
          if (inputElements[i].type==="checkbox" && inputElements[i].name !== 'check-boss'  && parseInt(inputElements[i].value) && inputElements[i].checked) {
            ids?.push({ 
              id: parseInt(inputElements[i].value),
              name: inputElements[i].getAttribute("data-name"),
              language: inputElements[i].getAttribute("data-language"),
              new_language: (inputElements[i].getAttribute("data-language") === 'esp')? 'eng' : 'esp',
            })
          }
        }
      }
  
      setSelectedVideos((ids?.length)? ids : null)
    } else {
      setSelectedVideos(null)
    }

  }, [creatingLocalizations])










  async function saveChangesFunc() {
    setSaveChanges(true)

    var inputElements = document.getElementsByTagName("input");


    if (inputElements?.length) { 

      var ids = []
      for (var i = 0; i < inputElements.length; i++) {
        var entred = false;


        var input = {
          own_user_token: currentUser?.token, 
        };


        /* Checks */
        if (inputElements[i].type==="checkbox" && inputElements[i].name !== 'check-boss'  && parseInt(inputElements[i].value) && inputElements[i].checked) {          
          entred = true;

          if (!input.id) { input.id = parseInt(inputElements[i].value) }

          if (category !== 'null') { input.category_id = (category)? parseInt(category) : null }
          if (professor !== 'null') { input.professor_id = (professor)? parseInt(professor) : null }
          if (module !== 'null') { input.module_id = (module)? parseInt(module) : null }
          if (state !== 'null') { 
            input.production_state = (state)? parseInt(state) : null 
            input.production_state_change_label = 'Estado cambiado por ' + currentUser?.name + ' el ' + (new Date()?.toLocaleString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" })?.split(' ')[0]?.replace(/,/g, '')) + '.'
          }

          if (language !== 'null') { input.language = (language)? language : 'esp' }
          if (originalLanguage !== 'null') { input.original_language = (originalLanguage && originalLanguage !== 'true')? originalLanguage : null }
          if (hasSubtitulation !== 'null') { input.has_subtitulation = (parseInt(hasSubtitulation))? parseInt(hasSubtitulation) : 0 }
          if (hasTranscription !== 'null') { input.has_transcription = (parseInt(hasTranscription))? parseInt(hasTranscription) : null }
          if (hasTranslation !== 'null') { input.has_translation = ((hasTranslation)? true : false) }
          if (titleDisabled !== 'null') { input.title_disabled = ((titleDisabled)? true : false) }
          if (chyronsDisabled !== 'null') { input.chyrons_disabled = ((chyronsDisabled)? true : false) }
          if (recordingSetsIds !== 'null') { input.recording_set_ids = (recordingSetsIds)? recordingSetsIds : null }
          
          if (changeRecordingDate) { input.recordingDate = (recordingDate)? new Date(recordingDate) : null }
          if (changeEndDate) { input.endDate = (endDate)? new Date(endDate) : null }
          if (pubEndDateActive) { input.publicationEndDate = (publicationEndDate)? new Date(publicationEndDate) : null }
        }


        /* Name, title and vimeo URL */
        if (inputElements[i]?.name?.includes('name')) {
          await videos.map( async videoTMP => {

            if (parseInt(inputElements[i].id) === parseInt(videoTMP?.id)) {
              var resTMP = await checkThingsFunc(videoTMP, inputElements, i, input, entred)
              entred = (resTMP?.entred)? resTMP?.entred : entred;
              input = (resTMP?.input)? resTMP?.input : input;
            }

            if (videoTMP?.list_localizations?.length) {
              await videoTMP?.list_localizations?.map( async videoTMP => {
                if (parseInt(inputElements[i].id) === parseInt(videoTMP?.id)) {
                  var resTMP = await checkThingsFunc(videoTMP, inputElements, i, input, entred)
                  entred = (resTMP?.entred)? resTMP?.entred : entred;
                  input = (resTMP?.input)? resTMP?.input : input;
                }
              });
            }
          });
        }


        if (entred) {
          ids.push(input?.id)
          await createOrUpdateVideo({variables: input})
        }
      }
      

      if (category !== 'null' && ids?.length) {
        axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
          axios.post(
            `${process.env.REACT_APP_API_ROUTE}notificateVideoCategoryChange`,
            { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, category_id: category, video_ids: ids, cats: response?.data }
          );
        });
      }
    }


    setTimeout(()=>{
      setSaveChanges(false)

      setSuccessAlertMsg('Los cambios han sido guardados correctamente!')

      setModule('null')
      setProfessor('null')
      setCategory('null')
      setLanguage('null')
      setOriginalLanguage('null')
      setState('null')

      setHasSubtitulation('null')
      setHasTranscription('null')
      setHasTranslation('null')
      setTitleDisabled('null')
      setChyronsDisabled('null')

      setRecordingDate(null)
      setChangeRecordingDate(false)
      setEndDate(null)
      setChangeEndDate(false)


      setModuleOption({ label: 'Sin modificar', id: 'null' })
      setProfessorOption({ label: 'Sin modificar', id: 'null' })
      setCategoryOption({ label: 'Sin modificar', id: 'null' })

      if (entred) {
        setAnyChecked(checkAllFunc(anyChecked, saveChanges))
        getVideos()
      }
    }, 1000)
  }




  function checkThingsFunc(videoTMP, inputElements, i, input, entred) {
    var entred = entred;
    
    if (parseInt(inputElements[i].id) === parseInt(videoTMP?.id)) {

      var titleElement = document.getElementById("title-"+videoTMP?.id);
      var vimeoURLElement = (document.getElementById("vimeoURL-"+videoTMP?.id));
      var numberOfWordsElement = (document.getElementById("number-of-words-"+videoTMP?.id));
      var categoryElement = document.getElementsByName("category-"+videoTMP?.id)[0];

      var newName = (((inputElements[i].value)? inputElements[i].value?.trim() : null) !== ((videoTMP.name)? videoTMP.name?.trim() : null))? true : false;
      var newTitle = (((titleElement.value)? titleElement.value?.trim() : null) !== ((videoTMP.title)? videoTMP.title?.trim() : null))? true : false;
      var newVimeoURL = (((vimeoURLElement.value)? vimeoURLElement.value?.trim() : null) !== ((videoTMP.vimeo_url)? videoTMP.vimeo_url?.trim() : null))? true : false;
      var newNumberOfWords = (parseInt(numberOfWordsElement.value) !== parseInt(videoTMP.number_of_words))? true : false;
      var newCategory = ((parseInt(categoryElement?.value)? parseInt(categoryElement?.value) : null) !== (parseInt(videoTMP.category_id)? parseInt(videoTMP.category_id) : null))? true : false;


      var changes = (newName || newTitle || newVimeoURL || newNumberOfWords || newCategory)? true : false;


      if (changes) {
        entred = true;

        if (!input.id) { input.id = parseInt(videoTMP?.id) }
        if (newName) { input.name = (inputElements[i].value)? inputElements[i].value?.trim() : videoTMP?.name }

        if (newTitle) { input.title = (titleElement.value)? titleElement.value?.trim() : null }
        if (newVimeoURL) { input.vimeo_url = (vimeoURLElement.value)? vimeoURLElement.value?.trim() : null }
        if (newNumberOfWords) { input.number_of_words = (parseInt(numberOfWordsElement.value))? parseInt(numberOfWordsElement.value) : 0 }
        if (newCategory) { input.category_id = (parseInt(categoryElement?.value))? parseInt(categoryElement?.value) : videoTMP?.category_id }
        
      }


      /* TEMPORAL */
      {/* extra-text-motions extra-chart-motions extra-number-of-words extra-phone-adaptations */}
      var extraTextMotions = (document.getElementById("extra-text-motions-"+videoTMP?.id));
      var extraChartMotions = (document.getElementById("extra-chart-motions-"+videoTMP?.id));
      var extraNumberOfWords = (document.getElementById("extra-number-of-words-"+videoTMP?.id));
      var extraPhoneAdaptations = (document.getElementById("extra-phone-adaptations-"+videoTMP?.id));

      var extraTextMotionsDuplicated = (document.getElementById("extra-text-motions-duplicated-"+videoTMP?.id));


      var newExtraTextMotions = (parseInt(extraTextMotions?.value))? true : false;
      var newExtraChartMotions = (parseInt(extraChartMotions?.value))? true : false;
      var newExtraNumberOfWords = (parseInt(extraNumberOfWords?.value))? true : false;
      var newExtraPhoneAdaptations = (parseInt(extraPhoneAdaptations?.value))? true : false;

      var newExtraTextMotionsDuplicated = (extraTextMotionsDuplicated?.checked)? true : false;


      var changesTMP = ((parseInt(videoTMP?.module?.id) === 1 || parseInt(videoTMP?.module?.id) === 31) && (newExtraTextMotions || newExtraChartMotions || newExtraNumberOfWords || newExtraPhoneAdaptations || newExtraTextMotionsDuplicated))? true : false;
      if (changesTMP) {
        entred = true;

        if (!input.id) { input.id = parseInt(videoTMP?.id) }
        var dataTMP = (videoTMP?.extra_marks_json)? JSON.parse(videoTMP?.extra_marks_json) : [{}];

        dataTMP[0].text_motions = (parseInt(extraTextMotions?.value))? parseInt(extraTextMotions.value) : 0
        dataTMP[0].chart_motions = (parseInt(extraChartMotions?.value))? parseInt(extraChartMotions.value) : 0
        dataTMP[0].motion_number_of_words = (parseInt(extraNumberOfWords?.value))? parseInt(extraNumberOfWords.value) : 0
        dataTMP[0].phone_adaptations = (parseInt(extraPhoneAdaptations?.value))? parseInt(extraPhoneAdaptations.value) : 0
        
        dataTMP[0].text_motions_duplicated = (extraTextMotionsDuplicated?.checked)? 1 : 0

        input.extra_marks_json = JSON.stringify(dataTMP);
      }
      
    }

    return { entred: entred, input: input }
  }












  function removeVideosFunc() {
    var inputElements = document.getElementsByTagName("input");

    if (inputElements?.length && window.confirm('¿Seguro que quieres proceder a eliminar estos vídeos y todos sus contenidos?')) {
      setRemoveVideos(true)

  
      for (var i = 0; i < inputElements.length; i++) {
        if (inputElements[i].type==="checkbox" && inputElements[i].name !== 'check-boss'  && parseInt(inputElements[i].value) && inputElements[i].checked) {

          removeVideo({variables: {
            id: parseInt(inputElements[i].value), 
            own_user_token: currentUser?.token,
          }})
        
        }
      }
  
      setTimeout(()=>{
        setRemoveVideos(false)
  
        setSuccessAlertMsg('Los cambios han sido guardados correctamente!')
  
        setModule('null')
        setProfessor('null')
        setCategory('null')
        setLanguage('null')
        setOriginalLanguage('null')
  
        setHasSubtitulation('null')
        setHasTranscription('null')
        setHasTranslation('null')
        setTitleDisabled('null')
        setChyronsDisabled('null')

        setRecordingDate(null)
        setChangeRecordingDate(false)
        setEndDate(null)
        setChangeEndDate(false)
  
  
        setModuleOption({ label: 'Sin modificar', id: 'null' })
        setProfessorOption({ label: 'Sin modificar', id: 'null' })
        setCategoryOption({ label: 'Sin modificar', id: 'null' })
  
        setAnyChecked(checkAllFunc(anyChecked, saveChanges))
        getVideos()
      }, 1000)
    }
  }










  
  return <>
    <Collapse orientation="vertical" in={(editMode && !loadingEditMode)? true : false} timeout={1000} className='rounded-md'>
      <div className='flex items-center justify-center'>
        <div className='pt-14 pb-16 px-4 lg:px-10 max-w-[1300px] min-w-0'>

          
          <p className='pb-1 pl-2 flex items-center space-x-2'>
            <p className='truncate'>Panel de edición de vídeos {(!anyChecked) && <span className='opacity-50'>/ Selecciona vídeos o activa la edición individual para empezar a hacer cambios.</span>}</p>
          </p>

          <div className='w-full border border-gray-400 border-opacity-60 bg-gray-50 shadow p-3 rounded'>
            <div className='w-full flex flex-wrap space-y-3'>
              <div className='w-full flex flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-2 md:space-y-0'>
                {(currentUser?.rol === 'admin' || currentUser?.rol === 'super_user') &&
                  <CustomAutocomplete 
                    label={currentCustomer?.label_module}
                    className="w-full mui-bg-gray-200 md:max-w-[160px]"
                    disabled={(!anyChecked || saveChanges)}
                    disableClearable
                    
                    options={moduleOptions}
                    value={(moduleOption)? moduleOption : ''}
                    onChange={(event, value) => setModuleOption(value)}
                  />
                }

                <CustomAutocomplete 
                  label={currentCustomer?.label_professor}
                  className='w-full mui-bg-gray-200 md:max-w-[280px]' 
                  disabled={(!anyChecked || saveChanges)}
                  disableClearable
                  
                  options={professorOptions} 
                  value={(professorOption)? professorOption : 'null'} 
                  onChange={(event, value) => setProfessorOption(value)} 
                />


                <div className='w-full flex space-x-2'>
                  <CustomSelect
                    label="Estado"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}

                    value={state}
                    onChange={(event) => setState(event.target.value)}
                  >
                    <MenuItem value={'null'}>Sin modificar</MenuItem>
                    {dataStates?.map(element => {
                      return <MenuItem key={'stat-'+element?.id} value={parseInt(element?.id)}>
                        <div className='flex items-center space-x-4'>
                          <div className={`hover:opacity-50 p-[6px] menu-item-ball-${element.color}`}></div>
                          <p>{element?.name}</p>
                        </div>
                      </MenuItem>
                    })}
                  </CustomSelect>
                </div>


                <AddExtraRecordingSets 
                  previousValues={'null'} /* Put here previous ids */
                  setIds={setRecordingSetsIds}
                  small={true}
                  color={'darkgray'}
                  icon={locationIcon}
                  noChangeOption={true}

                  labelInput={'Sets de grabación'}
                  labelSelect={'Añadir set'}
                  labelNone={'Sin sets de grabación'}
                  inputWidth={'150px'}
                  disabled={(!anyChecked || saveChanges)}
                />
              </div>



              <div className='w-full flex items-center flex-wrap xl:flex-nowrap space-y-3 xl:space-y-0 xl:space-x-2'>
              
                {(categoryOptions?.length > 1 && !currentCustomer?.free_customer) && 
                  <CustomAutocomplete
                    label="Formato"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}
                    disableClearable

                    options={categoryOptions} 
                    value={(categoryOption)? categoryOption : ''}
                    onChange={(event, value) => setCategoryOption(value)}
                  />
                }

                <div className={`w-full flex flex-wrap space-y-3 lg:flex-nowrap lg:space-x-2 lg:space-y-0 ${(currentCustomer?.free_customer)? '' : `xl:w-auto ${((pubEndDateActive)? 'xl:min-w-[760px]' : 'xl:min-w-[500px]')}`}`}>
                  <div className={`w-full flex items-center space-x-2 ${(currentCustomer?.free_customer)? '' : 'xl:w-auto'}`}>
                    <CustomDatePicker
                      label="Fecha de grabación"
                      inputFormat="DD/MM/YYYY"
                      className={`w-full mui-bg-gray-200 ${(currentCustomer?.free_customer)? '' : 'xl:max-w-[180px]'} ${(changeRecordingDate)? null : 'opacity-50'}`}
                      disabled={(!anyChecked || saveChanges || !changeRecordingDate)}

                      value={(recordingDate)? recordingDate : null}
                      onChange={(value)=> (new Date(value).getFullYear() > 1970)? setRecordingDate(value) : setRecordingDate(null)}
                    />

                    <div className={`w-[30px] h-[30px] transition-all duration-300 rounded ${changeRecordingDate? ' bg-red-500' : 'bg-gray-200'}`}>
                      <Tooltip disableInteractive title={changeRecordingDate? "No editar fecha de grabación" : "Editar fecha de grabación"} placement="bottom" arrow>
                        <button 
                          onClick={() => setChangeRecordingDate(!changeRecordingDate)}
                          className={`p-1 border rounded disabled:opacity-50 disabled:pointer-events-none active:scale-95 duration-200
                            ${(changeRecordingDate)? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 border-red-600' : 'text-gray-500 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 border-gray-400 border-opacity-60'}
                          `}
                          disabled={(!anyChecked || saveChanges)}
                        >
                          {(changeRecordingDate)? <div className='text-white'>{calendarOffIcon}</div> : <div>{calendarOnIcon}</div>}
                        </button>
                      </Tooltip>
                    </div>
                  </div>


                  <div className={`w-full flex items-center space-x-2 ${(currentCustomer?.free_customer)? '' : 'xl:w-auto'}`}>
                    <CustomDatePicker
                      label={`Fecha de publicación ${(pubEndDateActive)? 'inicial' : ''}`}
                      inputFormat="DD/MM/YYYY"
                      className={`w-full mui-bg-gray-200 ${(currentCustomer?.free_customer)? '' : 'xl:max-w-[240px]'} ${(changeEndDate)? null : 'opacity-50'}`}
                      disabled={(!anyChecked || saveChanges || !changeEndDate)}

                      value={(endDate)? endDate : ''}
                      onChange={(value)=> (new Date(value).getFullYear() > 1970)? setEndDate(value) : setEndDate(null)}
                    />


                    <Tooltip disableInteractive title={changeEndDate? "No editar fecha de publicación" : "Editar fecha de publicación"} placement="bottom" arrow>
                      <button 
                        onClick={() => setChangeEndDate(!changeEndDate)}
                        className={`p-1 border rounded disabled:opacity-50 disabled:pointer-events-none active:scale-95 duration-200
                          ${(changeEndDate)? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 border-red-600' : 'text-gray-500 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 border-gray-400 border-opacity-60'}
                        `}
                        disabled={(!anyChecked || saveChanges)}
                      >
                        {(changeEndDate)? <div className='text-white'>{calendarOffIcon}</div> : <div>{calendarOnIcon}</div>}
                      </button>
                    </Tooltip>
                  </div>


                  {(changeEndDate) &&
                    <div className={`flex items-center space-x-2 ${(pubEndDateActive)? 'w-full' : ''} ${(currentCustomer?.free_customer)? '' : 'xl:w-auto'}`}>
                      {(pubEndDateActive) &&
                        <CustomDatePicker
                          label={`Fecha de publicación ${(pubEndDateActive)? 'final' : ''}`}
                          inputFormat="DD/MM/YYYY"
                          className={`w-full mui-bg-gray-200 ${(currentCustomer?.free_customer)? '' : 'xl:max-w-[220px]'} ${(changeEndDate)? null : 'opacity-50'}`}
                          disabled={(!anyChecked || saveChanges)}

                          value={(publicationEndDate)? publicationEndDate : ''}
                          onChange={(value)=> (new Date(value).getFullYear() > 1970)? setPublicationEndDate(value) : setPublicationEndDate(null)}
                        />
                      }

                      <Tooltip disableInteractive title={(pubEndDateActive)? "Eliminar fecha final" : "Convertir fecha de publicación en un rango añadiendo una fecha final"} placement="bottom" arrow>
                        <button 
                          onClick={() => {
                            if (publicationEndDate && pubEndDateActive) { setPublicationEndDate(null) }
                            setPubEndDateActive(!pubEndDateActive)
                          }}
                          className={`p-1 border rounded disabled:opacity-50 disabled:pointer-events-none active:scale-95 duration-200
                            ${(pubEndDateActive)? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 border-red-600' : 'text-gray-500 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 border-gray-400 border-opacity-60'}
                          `}
                          disabled={(!anyChecked || saveChanges)}
                        >
                          {(pubEndDateActive)? cancelIcon :  addIcon}
                        </button>
                      </Tooltip>
                    </div>
                  }

                </div>
              </div>



              <div className='w-full flex flex-wrap xl:flex-nowrap space-y-3 xl:space-y-0 xl:space-x-2'>
                <div className='w-full flex flex-wrap lg:flex-nowrap space-y-3 lg:space-y-0 lg:space-x-2'>
                  <CustomSelect
                    label="Idioma del vídeo original"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}

                    value={originalLanguage}
                    onChange={(event) => setOriginalLanguage(event.target.value)}
                  >
                    <MenuItem value={'null'}>Sin modificar</MenuItem>
                    <MenuItem value={'true'}>Este és un vídeo original</MenuItem>
                    
                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem> 
                    })}

                    <MenuItem value={'other'}>Otro</MenuItem>
                  </CustomSelect>



                  <CustomSelect
                    label="Idioma"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}

                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}
                  >
                    <MenuItem value={'null'}>Sin modificar</MenuItem>

                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem> 
                    })}

                    <MenuItem value={'other'}>Otro</MenuItem>
                  </CustomSelect>


                  {(currentUser && !currentUser?.rol?.includes('user')) && <>
                    <CustomSelect
                      label="Tiene subtitulación"
                      className='w-full mui-bg-gray-200'
                      disabled={(!anyChecked || saveChanges)}

                      value={hasSubtitulation}
                      onChange={(event) => setHasSubtitulation(event.target.value)}
                    >
                      <MenuItem value={'null'}>Sin modificar</MenuItem>
                      <MenuItem value={0}>Sin subtitulación</MenuItem>
                      <MenuItem value={1}>Subtitulación de primer idioma</MenuItem>
                      <MenuItem value={2}>Subtitulación de segundo idioma</MenuItem>
                      <MenuItem value={3}>Subtitulación de tercer idioma o posterior</MenuItem>
                    </CustomSelect>


                    <CustomSelect
                      label="Tiene transcripción"
                      className='w-full mui-bg-gray-200'
                      disabled={(!anyChecked || saveChanges)}

                      value={hasTranscription}
                      onChange={(event) => setHasTranscription(event.target.value)}
                    >
                      <MenuItem value={'null'}>Sin modificar</MenuItem>
                      <MenuItem value={2}>Transcripción estándar</MenuItem>
                      <MenuItem value={1}>Transcripción prompterizada</MenuItem>
                      <MenuItem value={0}>Sin transcripción</MenuItem>
                    </CustomSelect>


                    <CustomSelect
                      label="Tiene traducción"
                      className='w-full mui-bg-gray-200'
                      disabled={(!anyChecked || saveChanges)}

                      value={hasTranslation}
                      onChange={(event) => setHasTranslation(event.target.value)}
                    >
                      <MenuItem value={'null'}>Sin modificar</MenuItem>
                      <MenuItem value={true}>Con traducción</MenuItem>
                      <MenuItem value={false}>Sin traducción</MenuItem>
                    </CustomSelect>
                  </>}
                </div>



                <div className='w-full flex flex-wrap lg:flex-nowrap space-y-3 lg:space-y-0 lg:space-x-2'>
                  <CustomSelect
                    label="Motion de título"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}

                    value={titleDisabled}
                    onChange={(event) => setTitleDisabled(event.target.value)}
                  >
                    <MenuItem value={'null'}>Sin modificar</MenuItem>
                    <MenuItem value={false}>Con título</MenuItem>
                    <MenuItem value={true}>Sin título</MenuItem>
                  </CustomSelect>


                  <CustomSelect
                    label="Motion de chyron iniciales"
                    className='w-full mui-bg-gray-200'
                    disabled={(!anyChecked || saveChanges)}

                    value={chyronsDisabled}
                    onChange={(event) => setChyronsDisabled(event.target.value)}
                  >
                    <MenuItem value={'null'}>Sin modificar</MenuItem>
                    <MenuItem value={false}>Con chyrons</MenuItem>
                    <MenuItem value={true}>Sin chyrons</MenuItem>
                  </CustomSelect>
                </div>
              </div>

            </div>
          </div>



          <div className='flex justify-between items-start pt-3'>
            <div className='w-full flex items-center space-x-2'>
              <CustomButton 
                icon={cancelIcon}
                label="Cancelar edición"
                color="red"
                onClick={() => setEditMode(false)} 
              />

              
              <CustomSwitch
                label="Edición individual"
                strong
                darker

                value={showFullEditMode}
                onClick={() => setShowFullEditMode(!showFullEditMode)}
              />

              <ModalVideoLocalizations 
                open={creatingLocalizations}
                setOpen={setCreatingLocalizations}

                videos={selectedVideos}
                setVideos={setSelectedVideos}
                getVideos={getVideos}

                anyChecked={anyChecked}
                setAnyChecked={setAnyChecked}
              />
            </div>

            <div className='flex items-center justify-end flex-wrap space-y-2 lg:flex-nowrap lg:space-y-0 lg:space-x-2'>
              {(currentUser?.rol === 'admin'|| (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && <>
                <CustomButton 
                  icon={removeIcon}
                  label="Eliminar vídeos"
                  loading={(removeVideos) && "Guardando cambios..."}
                  color="red"

                  onClick={() => removeVideosFunc()}
                  disabled={(!anyChecked || saveChanges)}
                />
              </>}

              {(currentUser?.rol === 'admin' || currentUser?.rol?.includes('translator') || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) &&
                <CustomButton 
                  icon={<div className='scale-95'>{addIcon}</div>}
                  label="Crear localizaciones"
                  loading={(creatingLocalizations) && "Creando localizaciones..."}
                  disabled={(removeVideos || saveChanges || !(anyChecked || showFullEditMode))}
                  onClick={() => setCreatingLocalizations(true)} 
                />
              }

              <CustomButton 
                icon={saveIcon}
                label="Guardar cambios"
                loading={(saveChanges) && "Guardando cambios..."}
                disabled={(removeVideos || !(anyChecked || showFullEditMode))}
                onClick={() => saveChangesFunc()} 
              />
            </div>
          </div>



        </div>
      </div>
    </Collapse>
  </>
}

export default VideoHomeEditMode
