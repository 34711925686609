import { useState, useContext, useEffect } from "react";
import { AuthContext } from '../contexts/AuthContext'

import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';




export default function SuccessAlert({tmpMSG, setTmoMSG, type}) {
  



  const { showNavLeft } = useContext(AuthContext)




  /* Alert vars */
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);




  useEffect( () => { 
    if (tmpMSG && !alertMsg) {
      setAlertMsg(tmpMSG)
    }
  }, [tmpMSG])

  useEffect( () => { 
    if (alertMsg) {
      setShowAlertMsg(true)

      setTimeout(() => {
        setShowAlertMsg(false)
        setTimeout(() => {
          setAlertMsg(null)
        }, "400")
      }, "4000")
    } else {
      setTmoMSG(null)
    }
  }, [alertMsg])





  return (
    <Collapse orientation="horizontal" className={`fixed top-0 mt-[100px] z-50 ${(showNavLeft)? 'ml-[100px]' : 'ml-[40px]'}`} in={showAlertMsg} timeout={300}>
      <Alert 
        className='whitespace-nowrap'
        severity={type}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowAlertMsg(false)
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alertMsg}
      </Alert>
    </Collapse>
  );
}