import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';

import GetAllUsersList from './../graphql/queries/GetAllUsersList'
import GetAllCustomers from './../graphql/queries/GetAllCustomers'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CustomSelect from '../components/Inputs/CustomSelect';











const Users = () => {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  



  const columns = [
    { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC' },
    { id: 'abbreviated_name', label: 'Abr' },
    { id: 'email', label: 'Email', filterUp: 'Email ASC', filterDown: 'Email DESC' },
    { id: 'rol', label: 'Rol', filterUp: 'Rol ASC', filterDown: 'Rol DESC' },
    { id: 'from_customer', label: 'Cliente', filterUp: 'FromCustomer ASC', filterDown: 'FromCustomer DESC' },
    { id: 'customer_free', label: 'Permisos de navegación', filterUp: 'Navigation ASC', filterDown: 'Navigation DESC' },
    { id: 'createdAtFormated', label: 'Cread el', align: 'right', filterUp: 'CreatedAt ASC', filterDown: 'CreatedAt DESC' },
  ];
  






  /* Users */
  const [users, setUsers] = useState([]);
  const [originalCustomers, setOriginalCustomers] = useState([]);
  const [navigationCustomers, setNavigationCustomers] = useState([]);


  /* Filter vars */
  const [orderFilter, setOrderFilter] = useState('CreatedAt DESC');
  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('null');
  const [customerFilter, setCustomerFilter] = useState('null');
  const [navigationFilter, setNavigationFilter] = useState('null');

  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const goBackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"/></svg>

  const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192-264v-24q0-23 12.5-43.5T239-366q55-32 116.5-49T480-432q63 0 124.5 17T721-366q22 13 34.5 34t12.5 44v24q0 30-21 51t-51 21H264q-30 0-51-21t-21-51Z"/></svg>
  const superUserIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M96-264v-20q0-26 12.5-47.5T143-366q57-34 120-50.5T392-433q13 0 19.5 11t3.5 24q-4 14-5 29t-1 30q0 27 4 53.5t15 51.5q6 15-2 28.5T403-192H168q-30 0-51-21t-21-51Zm576 0q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21ZM384-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm250 311-7-31q-14-5-26.5-12T577-228l-32 10q-11 3-20.5-1T510-232l-8-13q-5-9-4-19.5t9-18.5l24-23q-4-14-3.5-29t3.5-29l-23-22q-8-8-9.5-18t3.5-20l8-13q5-10 15-14t21-1l30 9q11-10 24-17.5t27-11.5l7-32q2-11 11-17.5t20-6.5h14q11 0 20 6.5t11 17.5l7 32q14 5 27 12t24 17l31-9q11-3 20.5 1.5T834-437l8 13q5 9 3.5 19.5T836-386l-22 21q2 15 2 30t-3 29l23 22q8 8 9.5 18.5T842-246l-8 13q-5 9-14.5 13t-20.5 1l-32-9q-11 9-23.5 16.5T717-200l-8 32q-2 11-10.5 17.5T679-144h-15q-11 0-19.5-7T634-169Z"/></svg>
  
  const editorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M576-180v-47q0-14.22 5-27.11Q586-267 597-278l189-188q7.26-7.41 16.13-10.71Q811-480 819.76-480q9.55 0 18.31 3.5Q846.83-473 854-466l44 45q6.59 7.26 10.29 16.13Q912-396 912-387.24t-3.29 17.92q-3.3 9.15-10.71 16.32L710-165q-11 11-23.89 16T659-144h-47q-15.3 0-25.65-10.35Q576-164.7 576-180Zm-384-48v-60q0-23 12.5-43.5T239-366q55-32 116.36-49T480-432q37.68 0 74.34 6Q591-420 626-409l-90 90q-15 15-23.5 35.06Q504-263.88 504-242v50H228q-15.3 0-25.65-10.35Q192-212.7 192-228Zm627-113 45-46-45-45-45 46 45 45ZM480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Z"/></svg>
  const translatorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M96,-264v-20q0-26,12.5-47.5t34.5-34.5q57-34,120-50.5t174-16.5q13,0,19.5,11t3.5,24q-2,14.193276-3.5,30.29488q0,18.30876,1.5,28.70512q2.5,26.046324,17,63.010676Q488.712582,-249.062468,500,-234q6,15-2,28.5t-23,13.5h-307q-30,0-51-21t-21-51Zm288-216q-60,0-102-42t-42-102t42-102t102-42t102,42t42,102-42,102-102,42Z" transform="translate(0 1.618438)"/><path d="M809.65,-318q-22.65,0-38.15-15.7-15.5-15.71-15.5-38.14v-89.73q0-22.43,15.65-38.43q15.64-16,38-16q22.35,0,38.35,15.8q16,15.79,16,38.36v90.27q0,22.57-15.85,38.07-15.86,15.5-38.5,15.5Zm.35,126q-8,0-13-5t-5-13v-37.21q-41.18-6.2-70.09-35Q693,-311,686,-352q-2-8,3-14t12.7-6q7.71,0,13,4q5.3,4,6.3,11q5.42,32.14,30.73,53.57q25.3,21.43,58.74,21.43q32.53,0,57.53-21t31-52.91q2-7.09,7.1-11.59t11.9-4.5q8,0,13,4.92t4,12.08q-5,42-35,72t-72,36.14v36.86q0,8-5,13t-13,5Z" transform="matrix(1.220584 0 0 1.220584-318.912751 87.970566)"/></svg>
  const facturatorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480,-96q-79,0-149-30t-122.5-82.5-82.5-122.5-30-149q0-80,30-149.5t82.5-122t122.5-82.5t149-30q80,0,149.5,30t122,82.5t82.5,122t30,149.5q0,79-30,149t-82.5,122.5-122,82.5-149.5,30Zm-1-120q14,0,24-9t10-23v-15q49-8,80.5-42t31.5-80q0-45-29-78t-83-52q-57-20-78-36t-21-41q0-20,18-33t47-13q19,0,34,6t25,17q10,12,22.5,16t23.5-1q13-6,17.5-18t-2.5-23q-15-23-37.5-37t-48.5-19v-15q0-14-9.5-23t-23.5-9-23.5,9-9.5,23v15q-45,11-72,40t-27,66q0,43,29,74t98,57q50,19,68,35.5t18,39.5q0,26-21,44t-52,18q-26,0-47.5-13t-33.5-36q-7-12-19.5-17t-24.5,0q-14,5-19,18t1,25q16,31,42.5,52.5t58.5,29.5v16q0,14,9.5,23t23.5,9Z" transform="matrix(.425531 0 0 0.425535 469.106069-132.624096)"/><path d="M96,-264v-20q0-26,12.5-47.5t34.5-34.5q57-34,120-50.5t129-16.5q13,0,19.5,11t3.5,24q-4,14-5,29t-1,30q0,27,4,53.5t15,51.5q6,15-2,28.5t-23,13.5h-235q-30,0-51-21t-21-51Zm288-216q-60,0-102-42t-42-102t42-102t102-42t102,42t42,102-42,102-102,42Z"/></svg>
  const adminIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-192q-29.7 0-50.85-21.15Q144-234.3 144-264v-24q0-23 12.5-43.5T191-366q55-32 116.36-49T432-432q18.67 0 37.33 1.5Q488-429 507-426q9 2 15 9t7 17q4 36 19.5 68t41.5 57q5 5 8.5 11.79 3.5 6.78 3.5 14.92v19.91q0 15.38-10.35 25.88Q581.3-192 566-192H216Zm216-288q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm299.79 72q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm4.86 322.65L701-121q-1-1-5-11.86V-294q-42-11-69-46t-27-80.19q0-54.61 38.72-93.21 38.72-38.6 93.5-38.6t93.28 38.66Q864-474.69 864-420q0 42.58-24.65 75.69Q814.7-311.19 777-297l31.71 31.71Q814-260 814-252q0 8-5.18 13.18l-27.64 27.64Q776-206 776-198t5.18 13.18l27.64 27.64Q814-152 814-144t-5.25 13.25l-45.5 45.5Q758-80 750-80t-13.35-5.35Z"/></svg>

  const selStyle = { backgroundColor: "rgb(243 244 246)", "&:hover": { backgroundColor: "rgb(229 231 235)" }, "&.Mui-selected": { backgroundColor: "rgb(191 219 254)", "&:hover": { backgroundColor: "rgb(147 197 253)" } } }

  const userRols = [
    { value: 'user', icon: userIcon, name: 'Usuario base', type: 0 },
    { value: 'editor_user', icon: editorIcon, name: 'Usuario editor', type: 1 },
    { value: 'translator_user', icon: translatorIcon, name: 'Usuario traductor', type: 1 },
    { value: 'super_user', icon: superUserIcon, name: 'Super usuario', type: 0 },
    { value: null },
    { value: 'editor', icon: editorIcon, name: 'Editor / Motioner', type: 2 },
    { value: 'translator', icon: translatorIcon, name: 'Traductor', type: 2 },
    { value: 'facturator', icon: facturatorIcon, name: 'Facturador', type: 2 },
    { value: 'admin', icon: adminIcon, name: 'Administrador', type: 2 },
  ]






  const { data: dataCustomers, loading: loadingCustomers } = useQuery(GetAllCustomers, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token
    }
  })
  
  const { data: dataUsers, loading } = useQuery(GetAllUsersList, { 
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }, 
    fetchPolicy: 'no-cache' 
  })




  
  useEffect(() => {    
    setCurrentNavActive('users')
  }, [])


  useEffect(() => {    
    setUsers((dataUsers?.usersByCustomer?.length)? dataUsers?.usersByCustomer : null)
  }, [dataUsers])


  useEffect(() => {    
    var originalCustomersTMP = []
    var navigationCustomersTMP = []

    if (dataCustomers?.customers?.length && users?.length) {
      for (var customerTMP of dataCustomers?.customers) {
        for (var userTMP of users) {
          const originalTokens = (userTMP?.original_customer_token)? ((userTMP?.original_customer_token === customerTMP?.token)? true : false) : false
          if (originalTokens) {
            originalCustomersTMP.push(customerTMP);
            break;
          }
        }

        for (var userTMP of users) {
          const navigationTokens = (userTMP?.navigation_customer_tokens)? ((userTMP?.navigation_customer_tokens?.split(',')?.includes(customerTMP?.token))? true : false) : false
          if (navigationTokens) {
            navigationCustomersTMP.push(customerTMP);
            break;
          }
        }
      }
    }

    setOriginalCustomers((originalCustomersTMP?.length)? originalCustomersTMP : null)
    setNavigationCustomers((navigationCustomersTMP?.length)? navigationCustomersTMP : null)
  }, [dataCustomers, users])


  useEffect(() => {    
    if (dataUsers?.usersByCustomer?.length > 0) {
      var usersTMP = [...dataUsers.usersByCustomer];
      var entred = false;
      
      /* Search */
      if (search && search.replaceAll(' ', '') !== '') {
          entred = true;
          usersTMP = usersTMP.filter(professor => {
            var nameFound = professor?.name?.toLowerCase().includes(search.toLowerCase());
            var emailFound = professor?.email?.toLowerCase().includes(search.toLowerCase());
            return (nameFound || emailFound)
          })
      }

      /* Role filter */
      if (roleFilter && roleFilter !== 'null') {
        entred = true;
        usersTMP = usersTMP.filter(user => { return user.rol === roleFilter; })
      }      

      /* Customer filter */
      if (customerFilter && customerFilter !== 'null') {
        entred = true;
        usersTMP = usersTMP.filter(user => { return (customerFilter === 'free')? !user?.navigation_customer_tokens : (user?.navigation_customer_tokens?.split(',')?.includes(customerFilter)); })
      }

      /* Navigation filter */
      if (navigationFilter && navigationFilter !== 'null') {
        entred = true;
        usersTMP = usersTMP.filter(user => { return (navigationFilter === 'free')? (user?.customer_free) : ((!user.customer_free) && (user?.navigation_customer_tokens?.split(',')?.includes(navigationFilter))); })
      }



      /* Order filter */
      if (orderFilter && orderFilter !== 'null') {
        entred = true;

        if (orderFilter.includes('Name ')) { /* Text */
          usersTMP = usersTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b.name > a.name)? -1 : (b.name < a.name)? 1 : 0) : ((a.name > b.name)? -1 : (a.name < b.name)? 1 : 0) })
        } else if (orderFilter.includes('Email ')) { /* Text */
          usersTMP = usersTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b.email > a.email)? -1 : (b.email < a.email)? 1 : 0) : ((a.email > b.email)? -1 : (a.email < b.email)? 1 : 0) })
        } else if (orderFilter.includes('Rol ')) { /* Text */
          usersTMP = usersTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b.rol > a.rol)? -1 : (b.rol < a.rol)? 1 : 0) : ((a.rol > b.rol)? -1 : (a.rol < b.rol)? 1 : 0) })
        } else if (orderFilter.includes('FromCustomer ')) { /* Text */
          usersTMP = usersTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b?.from_customer?.name > a?.from_customer?.name)? -1 : (b?.from_customer?.name < a?.from_customer?.name)? 1 : 0) : ((a?.from_customer?.name > b?.from_customer?.name)? -1 : (a?.from_customer?.name < b?.from_customer?.name)? 1 : 0) })
        } else if (orderFilter.includes('Navigation ')) { /* Text */
          usersTMP = usersTMP.sort(function(a,b){ 
            var aCustomerName = (a.customer_free)? 'Libre / Sin cliente' : a?.customer_permission?.name;
            var bCustomerName = (b.customer_free)? 'Libre / Sin cliente' : b?.customer_permission?.name;
            return orderFilter.includes(' ASC')? ((bCustomerName > aCustomerName)? -1 : (bCustomerName < aCustomerName)? 1 : 0) : ((aCustomerName > bCustomerName)? -1 : (aCustomerName < bCustomerName)? 1 : 0)
          })
        } else if (orderFilter.includes('CreatedAt ')) { /* Date */
          usersTMP = usersTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? (new Date(b.createdAt) - new Date(a.createdAt)) : (new Date(a.createdAt) - new Date(b.createdAt)); })
        }
      }



      if (!entred) { resetUsers() } else {setUsers(usersTMP)}
    } else { resetUsers() }

  }, [orderFilter, search, roleFilter, customerFilter, navigationFilter, dataUsers])


  function resetUsers() {
    if (dataUsers?.usersByCustomer) {
      setUsers(dataUsers.usersByCustomer)
    } else {
      setUsers(null)
    }
  }



  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">



      <div className='flex justify-between items-center'>

        <div className='flex flex-wrap pl-2 py-14'>
          <div className='flex flex-wrap sm:flex-nowrap items-center'>
            <h1 className='font-bold text-4xl'>Usuarios</h1>
            <p className='text-gray-400 mt-0 sm:mt-2 ml-2 text-xs sm:text-base'>{(users)? ''+users?.length+' resultados encontrados.' : ''}</p>
          </div>
        </div>



        <div className='flex'>
          <Tooltip disableInteractive title="Crear un nuevo usuario" placement="bottom" arrow>
              <IconButton 
              color="primary" 
              size="medium"
              onMouseUp={(event)=> navigateOrOpenFunc(event, "/user/new") }
            >
              {createItemIcon}
            </IconButton>
          </Tooltip>

          {(currentUser?.rol?.includes('user') && !(currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) &&
            <div className='flex'>
              <Tooltip disableInteractive title="Volver a los vídeos" placement="bottom" arrow>
                <a href="/videos">
                  <IconButton color="primary" size="medium">
                    {goBackIcon}
                  </IconButton>
                </a>
              </Tooltip>
            </div>
          }
        </div>
      </div>




      {/* <ModalMentionAdministration
        open={openMentionGroupModal}
        setOpen={setOpenMentionGroupModal}
        users={dataUsers?.usersByCustomer}
      /> */}




      <div className='flex flex-wrap xl:flex-nowrap items-center space-x-0 xl:space-x-2 space-y-2 xl:space-y-0 mb-4'>
        <div className="search-box h-[30px] flex items-center">
          <IconButton
            type="button" 
            sx={{ p: '4px', borderRadius: 0 }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            id="search-input"
            className='w-full'
            sx={{ ml: 1, flex: 1, py: 0 }}
            placeholder="Busca por nombre del usuario o email"
            value={search}
            onChange={(element)  => setSearch(element.target.value)}
          />
        </div>

        <div className='w-full flex flex-wrap lg:flex-nowrap items-center space-x-0 lg:space-x-2 space-y-2 lg:space-y-0 mb-4'>
          <CustomSelect
            label={`Rol dentro de ${currentCustomer?.name}`}
            value={(roleFilter)? roleFilter : 'user'}
            onChange={(event) => setRoleFilter(event.target.value)}
          >
            <MenuItem value={'null'}>Sin filtro</MenuItem>
            <hr className='border-gray-300' />

            {userRols?.map((element) => {
              const visible = (!element?.type || (element?.type === 1 && currentCustomer?.free_customer) || (element?.type === 2 && !currentUser?.rol?.includes('user')))? true : false
              return (element?.value && visible)? <MenuItem value={element?.value} sx={(element?.type === 2)? selStyle : {}}><div className='flex items-center space-x-3'><div className='text-gray-500 scale-[1.3]'>{element?.icon}</div> <p>{element?.name}</p></div></MenuItem>
              : (!element?.value)? <hr className='border-gray-300' />
              : null
            })}
          </CustomSelect>



          <CustomSelect
            label="Cliente"
            className='w-full mui-bg-gray-100'
            value={(customerFilter)? customerFilter : 'null'}
            onChange={(event) => setCustomerFilter(event.target.value)}
          >
            <MenuItem value={'null'}>Sin filtro por cliente</MenuItem>
            <MenuItem value={'free'}>Sin cliente</MenuItem>
            {originalCustomers?.map((customer) => {
              return <MenuItem key={'custommer-'+customer.id} value={customer.token}>{customer.name}</MenuItem>
            })}
          </CustomSelect>

          <CustomSelect
            label="Con permiso para navegar en"
            className='w-full mui-bg-gray-100'
            value={(navigationFilter)? navigationFilter : 'null'}
            onChange={event => setNavigationFilter(event.target.value)}
          >
            <MenuItem value={'null'}>Sin filtro por cliente</MenuItem>
            <MenuItem value={'free'}>Libres / Sin cliente</MenuItem>
            {navigationCustomers?.map((customer) => {
              return <MenuItem key={'custommer-'+customer.id} value={customer.token}>{customer.name}</MenuItem>
            })}
          </CustomSelect>
        </div>
      </div>



      <div className='w-full'>
        {(loading || loadingCustomers)?
          <div className='w-full text-sm bg-gray-100 border border-gray-300 p-2 rounded-md text-gray-500 mb-4'> 
            <p className='mb-1'>{(users)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded-full'/>
          </div>
        : null}
        {(!(loading || loadingCustomers) && users?.length === 0)? <p className='text-sm text-gray-400 px-1'>No se han encontrado usuarios dentro de {currentCustomer?.name}</p> : null}
      </div> 


    
      {(users && users?.length)?
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)', marginBottom: 5 }} className='border border-gray-400 border-opacity-60'>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 310px)', }}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={'columns-'+column.id}
                      className='select-none '
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.width, backgroundColor: 'rgb(243 244 246)' }}
                      onClick={()=>{
                        if (column?.filterUp === orderFilter) {
                          if (column?.filterDown) {
                            setOrderFilter(column?.filterDown)
                          } else {
                            setOrderFilter(null)
                          }
                        } else if (column?.filterDown === orderFilter) {
                          setOrderFilter(null)
                        } else if (column?.filterUp) {
                          if (column?.filterUp) {
                            setOrderFilter(column?.filterUp)
                          } else {
                            setOrderFilter(null)
                          }
                        }
                      }}
                    >
                      <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                        <div> {column.label} </div>
                        {(column?.filterUp === orderFilter) && upArrowIcon}
                        {(column?.filterDown === orderFilter) && downArrowIcon}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((row) => {
                  return (
                    <TableRow 
                      key={'users-'+row.id} 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      className="hover:bg-gray-100 duration-200 cursor-pointer"
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/user/"+row.id) }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'column-data-'+column.id} align={column.align} style={{borderBottom: 0}} className={`${(row?.visible && row?.enabled)? '' : 'opacity-40 bg-gray-200'}`}>
                            {
                              (column.id === 'rol')? 
                              
                                (
                                  (value === 'admin')? 'Administrador' 
                                  : (value === 'translator')? 'Traductor' 
                                  : (value === 'editor')? 'Editor / Motioner' 
                                  : (value === 'facturator')? 'Facturador' 
                                  : (value === 'translator_user')? 'Usuario traductor' 
                                  : (value === 'editor_user')? 'Usuario Editor / Motioner' 
                                  : (value === 'super_user')? 'Super usuario' 
                                  : 'Usuario base'
                                )
                              
                              : (column.id === 'customer_free')? (value? 'Libre / Sin cliente' : row?.customer_permission?.abbreviated_name)
                              : (column.id === 'from_customer')? (!value? 'Otro' : row?.from_customer?.abbreviated_name)
                              : value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>
        </Paper>
      : null }



    </div>
  )
}

export default Users
