import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CreateOrUpdateProdState from './../graphql/mutations/CreateOrUpdateProdState'
import RemoveProdState from './../graphql/mutations/RemoveProdState'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';






const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalProductionStateAdministration({ open, setOpen, prodStates, stateFilter, setStateFilter }) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const navigate = useNavigate();





  const [prodStatesTMP, setProdStatesTMP] = useState(null);
  const [showColors, setShowColors] = useState(null);
  const [loading, setLoading] = useState(false);

  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const unDoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M323 856q-17 0-29.5-12.5T281 814q0-17 12.5-29.5T323 772h239q62 0 108-38t46-97q0-59-46-97.5T562 501H320l73 73q12 12 11.5 29T392 632q-12 12-29.5 12T333 632L190 489q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l144-144q12-12 29.5-12t29.5 12q12 12 12 29.5T393 344l-73 73h242q97 0 167.5 62.5T800 636q0 94-70.5 157T562 856H323Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const dragIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M359.788-192Q330-192 309-213.212q-21-21.213-21-51Q288-294 309.212-315q21.213-21 51-21Q390-336 411-314.788q21 21.213 21 51Q432-234 410.788-213q-21.213 21-51 21Zm240 0Q570-192 549-213.212q-21-21.213-21-51Q528-294 549.212-315q21.213-21 51-21Q630-336 651-314.788q21 21.213 21 51Q672-234 650.788-213q-21.213 21-51 21Zm-240-216Q330-408 309-429.212q-21-21.213-21-51Q288-510 309.212-531q21.213-21 51-21Q390-552 411-530.788q21 21.213 21 51Q432-450 410.788-429q-21.213 21-51 21Zm240 0Q570-408 549-429.212q-21-21.213-21-51Q528-510 549.212-531q21.213-21 51-21Q630-552 651-530.788q21 21.213 21 51Q672-450 650.788-429q-21.213 21-51 21Zm-240-216Q330-624 309-645.212q-21-21.213-21-51Q288-726 309.212-747q21.213-21 51-21Q390-768 411-746.788q21 21.213 21 51Q432-666 410.788-645q-21.213 21-51 21Zm240 0Q570-624 549-645.212q-21-21.213-21-51Q528-726 549.212-747q21.213-21 51-21Q630-768 651-746.788q21 21.213 21 51Q672-666 650.788-645q-21.213 21-51 21Z"/></svg>
  const paletteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30.5-149.5t84-122Q264-804 335.5-834T488-864q78 0 146.5 27T754-763q51 47 80.5 110T864-518q0 96-67 163t-163 67h-68q-8 0-14 5t-6 13q0 15 15 25t15 53q0 37-27 66.5T480-96Zm0-384Zm-216 36q25 0 42.5-17.5T324-504q0-25-17.5-42.5T264-564q-25 0-42.5 17.5T204-504q0 25 17.5 42.5T264-444Zm120-144q25 0 42.5-17.5T444-648q0-25-17.5-42.5T384-708q-25 0-42.5 17.5T324-648q0 25 17.5 42.5T384-588Zm192 0q25 0 42.5-17.5T636-648q0-25-17.5-42.5T576-708q-25 0-42.5 17.5T516-648q0 25 17.5 42.5T576-588Zm120 144q25 0 42.5-17.5T756-504q0-25-17.5-42.5T696-564q-25 0-42.5 17.5T636-504q0 25 17.5 42.5T696-444ZM480-168q11 0 17.5-8.5T504-192q0-16-15-28t-15-50q0-38 26.5-64t64.5-26h69q66 0 112-46t46-112q0-115-88.5-194.5T488-792q-134 0-227 91t-93 221q0 130 91 221t221 91Z"/></svg>
  



  const [createOrUpdateProdState] = useMutation(CreateOrUpdateProdState)
  const [removeProdState] = useMutation(RemoveProdState)


  



  useEffect( () => { 
    setProdStatesTMP(prodStates?.slice()?.sort(function(a,b) { return a.position - b.position }))
  }, [open])


  useEffect( () => {
    if (prodStates) {
      setProdStatesTMP([...prodStates?.slice()?.sort(function(a,b) { return a.position - b.position })]);
    } else {
      setProdStatesTMP([]);
    }
  }, [prodStates, open])










  function setEditedProdStateListFunc(originalKey, newName, position, color, randNum, remove) {
    var statesEdited = prodStatesTMP?.map((prodState, key) => {
      if (originalKey === key) {
        return {
          __typename: "VideoStates",
          id: (prodState?.id)? prodState.id : null,
          name: (newName)? newName : null,
          position: (parseInt(position) || parseInt(position) === 0)? parseInt(position) : prodStatesTMP.length + 1,
          color: (color)? color : 'gray',
          original: (prodState?.original)? true : false,
          randNum: (randNum)? randNum : null,
          remove: remove,
        }
      } else {
        return prodState;
      }
    });

    setProdStatesTMP(statesEdited?.slice()?.sort(function(a,b) { return a.position - b.position }));
  }

  
  async function saveChangesFunc() {
    if (window.confirm('¿Seguro que quieres proceder a guardar los cambios? Estos cambios pueden tener efectos importantes en los vídeos pertenecientes a este estado.')) {
      setLoading(true)


      var entred = false;
      for (var prodState of prodStatesTMP) {
        if (prodState.id !== 0) {
          if (prodState?.remove) {

            if (prodState?.id) {
              await removeProdState({variables: {
                id: prodState.id, 
                own_user_token: currentUser? currentUser.token : null,
              }})

              entred = true;

              if (parseInt(stateFilter) === parseInt(prodState.id)) {
                if (prodStates?.length <= 1) {
                  setStateFilter(prodStates[0]?.id)
                  localStorage.setItem(currentCustomer?.token+'-video-state-filter', prodStates[0]?.id)
                } else {
                  setStateFilter('null')
                  localStorage.setItem(currentCustomer?.token+'-video-state-filter', 'null')
                }
              }
            }

          } else if (prodState?.name && prodState.name.toLowerCase().replaceAll(' ', '') !== '') {

            var input = {
              own_user_token: currentUser? currentUser.token : null,
      
              id: prodState?.id? parseInt(prodState.id) : null,
              name: prodState.name,
              position: prodState.position,
              color: prodState.color,
              original: (prodState?.original)? true : false,
              customer_token: currentCustomer?.token,
            };

            entred = true;

            await createOrUpdateProdState({variables: input})
          }
        }
      }


      setTimeout(() => {
        setLoading(false)
        setOpen(false)

        if (entred) {
          navigate(0)
        } 
      }, "1000")
    }
  }


  function addNewProdStateFunc() {
    setProdStatesTMP([...prodStatesTMP, {
      __typename: "VideoStates",
      id: null,
      name: `Nuevo estado`,
      position: prodStatesTMP.length + 1,
      color: 'gray',
      original: false,
      randNum: Math.floor(Math.random() * 10000),
      remove: false,
    }]?.slice()?.sort(function(a,b) { return a.position - b.position }));
  }





  

  const rearangeArr = (arr, source, destination) => {
    const arrCopy = [...arr];

    var groupTMP = (!destination.droppableId || destination.droppableId === 'Otros')? null : destination.droppableId
    if (arrCopy[source.index]?.setting_group !== groupTMP) {
      arrCopy[source.index] = {...arrCopy[source.index], setting_group: groupTMP}
      if (source.index < destination.index && destination.index) { destination.index = destination.index - 1 }
    }

    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);

    return arrCopy?.map((el, key) => { return {...el, position: key} });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !prodStatesTMP?.length || !destination.index) { return; }
    const rearrangedArray = rearangeArr(prodStatesTMP, source, destination);
    setProdStatesTMP(rearrangedArray);
  };


  function changeColorFunc(key, prodState, color) {
    setEditedProdStateListFunc(key, prodState.name, prodState.position, color, prodState.randNum, false)
  }









  return (
    <div>
      <BootstrapDialog
        onClose={()=> setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=> setOpen(false)}>
          Estados de los vídeos en {currentCustomer?.abbreviated_name}
        </BootstrapDialogTitle>


        <DialogContent dividers>
          {(prodStatesTMP?.length)?
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'states'}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`mb-4 pt-2 pr-1 min-w-[300px] max-w-[700px] max-h-[600px] overflow-y-auto smallScrollBar flex flex-wrap space-y-2 ${(loading)? 'opacity-50 pointer-events-none' : ''}`}
                  >
                    {prodStatesTMP?.sort(function(a, b){return a?.position - b?.position})?.map((prodState, key) => {
                      const prodStateKey = (prodState?.id)? `state-id-${prodState?.id}` : (prodState?.randNum)? `state-randnum-${prodState?.randNum}` : `state-key-${key}`;

                      return <Draggable
                        key={'state-admin-' + prodStateKey}
                        draggableId={prodStateKey}
                        index={key}
                      >
                        {(provided, snapshot) => (
                          
                          <div 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              margin: `0 0 8px 0`,
                    
                              ...provided.draggableProps.style
                            }}
                            className="w-full rounded-md flex justify-between items-center space-x-1"
                          >

                            <div className='py-1 px-[2px] bg-gray-100 border border-gray-400 border-opacity-40 hover:bg-gray-300 duration-200 rounded'>
                              {dragIcon}
                            </div>


                            <TextField
                              id={"name-"+key}
                              label={`Nombre del estado n${(key+1)}`}
                              variant="outlined"
                              className={`w-full mui-bg-gray-100`}
                              InputProps={{ sx: { height: "29px", overflow: 'hidden' } }}
                              InputLabelProps={{ sx: { fontSize: '13px', top: "-11px", "&.MuiInputLabel-shrink": { top: 3 } } }}
                              
                              value={(prodState.name)? prodState.name : ''}
                              onChange={(event) => setEditedProdStateListFunc(key, (event.target.value)? event.target.value?.slice(0, 30) : null, prodState.position, prodState.color, prodState.randNum, false)}
                              disabled={(prodState?.remove || prodState.blocked)? true : false}
                            />

                            <div className={`flex justify-end items-center space-x-1`}>
                              <button
                                className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50 
                                  ${
                                    (showColors === prodStateKey)? 'text-white bg-blue-400 border-blue-500 hover:bg-blue-500 active:bg-blue-700 animate-wiggle'
                                    : `text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back`
                                  }
                                `}
                                onClick={() => setShowColors((showColors !== prodStateKey)? prodStateKey : null)}
                                disabled={(prodState?.remove || prodState.blocked)? true : false}
                              >
                                <div className="scale-90">{paletteIcon}</div>
                              </button>



                              <div 
                                className={`bg-gray-200 rounded transition-width duration-500 overflow-hidden 
                                  ${(showColors === prodStateKey)? 'w-[164px]' : 'w-[0px]'}
                                `}
                                style={{ margin: 0 }}
                              >
                                <div className='flex space-x-[2px] p-[2px]'>
                                  <div onClick={() => changeColorFunc(key, prodState, 'gray')} className={`menu-item-ball-gray py-3 ${(!prodState?.color || prodState?.color === 'gray')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'yellow')} className={`menu-item-ball-yellow py-3 ${(prodState?.color === 'yellow')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'orange')} className={`menu-item-ball-orange py-3 ${(prodState?.color === 'orange')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'red')} className={`menu-item-ball-red py-3 ${(prodState?.color === 'red')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'pink')} className={`menu-item-ball-pink py-3 ${(prodState?.color === 'pink')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'purple')} className={`menu-item-ball-purple py-3 ${(prodState?.color === 'purple')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'blue')} className={`menu-item-ball-blue py-3 ${(prodState?.color === 'blue')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                  <div onClick={() => changeColorFunc(key, prodState, 'green')} className={`menu-item-ball-green py-3 ${(prodState?.color === 'green')? 'px-4 cursor-default' : 'px-2 hover:scale-110 active:opacity-50 cursor-pointer'}`} style={{ borderRadius: 5 }}></div>
                                </div>
                              </div>



                              <button
                                onClick={() => setShowColors((showColors !== prodStateKey)? prodStateKey : null)}
                                disabled={(prodState?.remove || prodState.blocked)? true : false}
                                className={`p-[14px] border border-gray-300 cursor-pointer active:opacity-50 disabled:pointer-events-none disabled:opacity-50 menu-item-ball-${(prodState?.color)? prodState?.color : 'gray'}`}
                                style={{ borderRadius: 5 }}
                              ></button>



                              <Tooltip disableInteractive title={(prodState?.remove)? 'Cancelar eliminación' : `Eliminar`} placement="bottom" arrow>
                                <button
                                  className={`p-1 border rounded-md duration-200 disabled:pointer-events-none disabled:opacity-50
                                    ${
                                      (prodState?.remove)? 'text-white bg-red-500 border-red-600 hover:bg-red-600 active:bg-red-800 animate-wiggle'
                                      : 'text-gray-500 bg-gray-200 border-gray-400 border-opacity-60 hover:bg-gray-300 hover:border-gray-400 active:bg-gray-400 animate-wiggle-back'
                                    }
                                  `}
                                  onClick={() => (!prodState.blocked) && setEditedProdStateListFunc(key, prodState.name, prodState.position, prodState.color, prodState.randNum, (prodState?.remove)? false : true)}
                                  disabled={(prodState.blocked || prodState?.original)? true : false}
                                >
                                  <div className='scale-90'>{(prodState?.remove)? unDoIcon : removeIcon}</div>
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          : null }

          <div className='button-trans min-w-[400px] flex items-center space-x-2 bg-gray-50 w-full p-2 rounded-md border border-gray-400 hover:border-blue-600 hover:bg-blue-500 hover:text-white active:bg-blue-300' onClick={()=>addNewProdStateFunc()}>
            <div>{addIcon}</div>
            <div>Crear nuevo estado</div>
          </div>

        </DialogContent>


        <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button variant="contained" size='small' color="error" startIcon={<ClearIcon />} onClick={()=>setOpen(false)}>
              Cancelar
            </Button>

            <LoadingButton 
              loading={loading}
              loadingPosition="start"
              variant="contained"
              size='small'
              autoFocus
              startIcon={<SaveIcon />}
              onClick={()=>saveChangesFunc()}
            >
              Guardar cambios
            </LoadingButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}