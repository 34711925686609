export default function VIDEO_ENTREVISTA_EN_REMOTO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="mt-[-1px] scale-[0.7]">
			<g>
				<g>
					<path d="M398.29,312.41L398.29,312.41c-1.28-2.54-2.55-5.09-3.82-7.64l-0.96-1.92c-3.35-6.7-6.81-13.63-10.27-20.44
						c13.01-19.96,18.33-41.37,15.81-63.65c-4.56-40.3-26.18-68.67-64.26-84.31c-12.19-5.01-25.56-6.97-43.32-6.36
						C276,92.33,239.54,68.3,200.7,68.3l-0.86,0c-19.76,0.21-38.2,5.81-54.8,16.64c-22.05,14.38-37.17,36.77-42.59,63.03
						c-5.4,26.18-0.4,52.62,14.09,74.45c0.07,0.11,0.13,0.2,0.17,0.27c-0.03,0.06-0.06,0.13-0.1,0.2c-3.31,6.41-6.58,12.95-9.77,19.35
						l-0.11,0.22c-1.67,3.34-3.33,6.68-5.01,10.02c-2.23,4.43-1.76,8.75,1.31,12.16c3.15,3.49,7.71,4.24,12.53,2.06
						c0.34-0.15,0.67-0.32,0.96-0.47l7.16-3.58c7.34-3.66,14.67-7.31,22-10.99c19.14,12.44,40.18,17.78,62.59,15.89
						c11.88,27.07,34.57,47.4,62.43,55.87c9.31,2.83,18.97,4.27,28.69,4.27c0.01,0,0.01,0,0.02,0c19.37,0,38.32-5.64,54.84-16.32
						c8.09,4.11,16.34,8.22,24.31,12.19l4.76,2.37c0.11,0.06,0.23,0.12,0.38,0.21c1.04,0.59,2.98,1.67,5.42,1.26
						c2.13-0.37,5.04-0.88,7.38-2.86C399.97,321.64,400.64,317.1,398.29,312.41z M136.92,228.11c2.47-4.62,2.15-9.2-0.94-13.6
						c-15.65-22.3-19.25-46.68-10.67-72.46c11.27-33.9,46.71-56.6,82.45-52.81c37.85,4.01,66.5,32.17,71.29,70.07
						c5.21,41.11-25.13,81.03-66.25,87.18c-21.63,3.23-41.54-1.57-59.19-14.26c-2.33-1.68-4.67-2.49-7.15-2.49
						c-1.98,0-3.94,0.52-6,1.59c-2.32,1.21-4.67,2.4-7.03,3.59C134.57,232.63,135.73,230.34,136.92,228.11z M285.89,219.17
						c12.96-21.66,17.03-45.39,12.12-70.61c0.06,0,0.11,0,0.17,0c5.22,0,10.63,0.28,15.95,1.31c35.32,6.87,61.29,35.43,64.62,71.07
						c1.78,19-2.98,36.6-14.13,52.3h0c-3.72,5.24-4.09,10.37-1.11,15.68c1.06,1.89,2.08,3.84,3.07,5.81c-2.15-1.08-4.29-2.18-6.37-3.31
						c-4.91-2.67-9.63-2.38-14.43,0.91c-25.13,17.17-51.87,19.66-79.48,7.4c-16.22-7.2-28.75-19.32-37.28-36.04
						C253.75,255.9,272.87,240.94,285.89,219.17z"/>
					<path d="M240.01,184.19c0.01,0,0.02,0,0.04,0c8.73-0.02,15.91-7.13,16.01-15.85c0.05-4.26-1.58-8.3-4.6-11.36
						c-3.02-3.07-7.05-4.78-11.35-4.8c-0.03,0-0.06,0-0.08,0c-4.29,0-8.31,1.65-11.33,4.64c-3.02,3-4.69,7.02-4.7,11.31
						c-0.01,4.29,1.66,8.32,4.7,11.36C231.72,182.52,235.74,184.19,240.01,184.19z M240.01,164.3
						C240.01,164.3,240.02,164.3,240.01,164.3c0.99,0,1.93,0.41,2.67,1.15c0.73,0.73,1.15,1.72,1.15,2.71c0,2.11-1.7,3.83-3.8,3.84h0
						c0,0-0.01,0-0.01,0c-1.02,0-1.97-0.39-2.69-1.11c-0.72-0.72-1.12-1.69-1.12-2.72C236.2,166.07,237.95,164.3,240.01,164.3z"/>
					<path d="M200.01,184.19c0.06,0,0.13,0,0.19,0c4.22,0,8.19-1.62,11.19-4.57c3.05-3,4.74-7.03,4.76-11.35
						c0.02-4.35-1.62-8.4-4.61-11.42c-2.98-3-7-4.66-11.31-4.67c-0.02,0-0.03,0-0.05,0c-4.29,0-8.31,1.66-11.33,4.69
						c-3.03,3.02-4.69,7.05-4.68,11.33C184.17,176.91,191.28,184.09,200.01,184.19z M197.57,165.38c0.72-0.7,1.65-1.09,2.6-1.09
						c0.02,0,0.04,0,0.06,0c2.07,0.03,3.79,1.83,3.77,3.92c-0.01,1.03-0.43,1.99-1.17,2.71c-0.73,0.71-1.7,1.06-2.7,1.08h0
						c-2.09-0.03-3.78-1.78-3.75-3.9C196.39,167.1,196.82,166.11,197.57,165.38z"/>
					<path d="M160.32,184.19c0.01,0,0.02,0,0.03,0c4.28,0,8.31-1.67,11.34-4.7c3.03-3.03,4.7-7.06,4.69-11.35
						c-0.02-8.71-7.13-15.86-15.85-15.95h0c-4.33,0-8.38,1.58-11.42,4.57c-3.03,2.98-4.7,7-4.72,11.32c-0.02,4.34,1.63,8.4,4.65,11.44
						C152.03,182.52,156.04,184.18,160.32,184.19z M157.64,165.49c0.74-0.75,1.72-1.19,2.68-1.19c0.01,0,0.01,0,0.02,0
						c2.07,0,3.83,1.75,3.84,3.84c0.02,2.11-1.69,3.84-3.81,3.86c-0.01,0-0.02,0-0.03,0c-2.04,0-3.84-1.77-3.86-3.81
						C156.48,167.22,156.9,166.24,157.64,165.49z"/>
					<path d="M299.75,243.9c0.01,0,0.01,0,0.02,0c4.29,0,8.32-1.67,11.35-4.72c3.03-3.04,4.68-7.07,4.66-11.35
						c-0.04-8.72-7.17-15.88-15.89-15.95c-0.05,0-0.09,0-0.14,0c-4.25,0-8.23,1.63-11.23,4.6c-3.04,3.01-4.72,7.04-4.73,11.35
						c-0.01,4.34,1.63,8.39,4.63,11.4C291.41,242.24,295.43,243.9,299.75,243.9z M299.69,224.08c0.02,0,0.04,0,0.06,0
						c0.99,0,1.93,0.38,2.65,1.08c0.73,0.71,1.15,1.67,1.16,2.7c0.03,2.1-1.69,3.9-3.75,3.94c-0.97,0.07-1.91-0.37-2.65-1.09
						c-0.75-0.73-1.2-1.73-1.21-2.73C295.91,225.86,297.59,224.11,299.69,224.08z"/>
					<path d="M339.56,243.9c0.03,0,0.06,0,0.08,0c4.32-0.02,8.34-1.7,11.31-4.72c2.99-3.03,4.61-7.08,4.57-11.42
						c-0.08-8.87-7.06-15.84-15.91-15.87c-0.02,0-0.03,0-0.05,0c-4.28,0-8.3,1.66-11.33,4.69c-3.03,3.03-4.7,7.05-4.7,11.33
						c0,4.29,1.68,8.31,4.71,11.33C331.28,242.24,335.29,243.9,339.56,243.9z M339.56,224.08c0.01,0,0.01,0,0.02,0
						c0.99,0,1.95,0.4,2.69,1.13c0.75,0.74,1.16,1.69,1.17,2.68c0.01,0.96-0.42,1.95-1.17,2.7c-0.74,0.75-1.72,1.18-2.68,1.18h0
						c0,0-0.01,0-0.01,0c-2.07,0-3.83-1.76-3.84-3.85C335.73,225.82,337.45,224.09,339.56,224.08z"/>
				</g>
				<path d="M490.03,359.11c0.07-10.34-5.55-15.83-16.01-15.88c-4.55-0.02-9.1,0-14.05,0c0-2.31,0-4.08,0-5.86
					c0-67.93,0.02-135.86-0.01-203.8c-0.01-27.43-19-46.55-46.27-46.55C370.3,87,326.91,87,283.52,87
					c7.09,7.25,13.26,15.43,18.25,24.38c10.35,0.26,19.56,1.51,28.12,3.85c27.96,0,55.91,0,83.87,0c11.62,0,18,6.41,18,18.09
					c0.01,68.36,0.01,136.72,0.01,205.08c0,1.37,0,2.75,0,4.54c-1.62,0.11-2.95,0.28-4.29,0.28c-9.94,0.01-19.89,0-29.83,0v0.09H296
					c-4.49,0.4-8.63,1.71-12.43,5.06c-1.92,1.69-5.39,2.24-8.16,2.27c-16.93,0.2-33.87,0.28-50.8-0.05c-3.34-0.07-7.06-1.52-9.87-3.42
					c-3.11-2.11-6.19-3.51-9.76-3.85H101.95v-0.1c-10.53-0.01-21.06-0.01-31.59-0.02c-0.54,0-1.07-0.18-2.01-0.34
					c-0.07-1.5-0.2-2.97-0.2-4.44c-0.01-68.36-0.01-136.72,0-205.08c0-11.71,6.35-18.1,17.98-18.1c3.4,0,6.79,0,10.19,0
					c5.24-10.39,12-19.88,20.08-28.18c-10.1,0-20.19,0-30.29,0.01c-3.86,0-7.82,0.3-11.57,1.15c-20.89,4.71-34.58,22.37-34.59,44.63
					c-0.04,68.21-0.02,136.42-0.02,204.63c0,1.66,0,3.31,0,5.77c-5.31,0-10.14-0.05-14.98,0.01c-9.36,0.13-15.05,5.84-15.1,15.13
					c-0.04,7.92-0.18,15.84,0.05,23.75c0.15,5.38,0.18,10.91,1.43,16.1c4.91,20.38,22.58,33.46,44.65,33.46
					c129.33,0.02,258.67,0.01,388,0.01c0.42,0,0.83,0,1.25,0c22.73-0.33,40.88-15.17,44-37.65
					C490.85,382.58,489.95,370.77,490.03,359.11z M461.42,390.46c-0.82,7.2-6.9,12.17-14.32,12.93c-1.24,0.13-2.5,0.09-3.75,0.09
					c-128.93,0-257.86,0-386.8,0c-10.06,0-17.01-4.6-18.07-13.02c-0.77-6.11-0.15-12.39-0.15-19.08c1.71,0,3.2,0,4.7,0
					c50.71,0,101.42-0.03,152.13,0.09c2.61,0.01,5.45,0.88,7.77,2.13c6.52,3.53,13.38,5.3,20.76,5.29c17.51-0.03,35.01-0.03,52.52,0
					c7.83,0.01,15.09-1.87,21.82-5.95c1.46-0.88,3.35-1.48,5.04-1.48c51.82-0.08,103.65-0.06,155.47-0.05c0.95,0,1.89,0.19,3.02,0.31
					C461.57,378.06,462.12,384.33,461.42,390.46z"/>
			</g>
		</svg>
	</>
}
