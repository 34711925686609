import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateCustomerSettingRelation(
    
    $own_user_token: String!,

    $id: Int,
    $customer_token: String!,
    $type: String,
    $state: String,
    $user_abbreviated_name: String,
    $change_label: String,
    $observations: String,
    $language: String,

    $latest: Boolean,
    $excel_import: Boolean,

    $customer_setting_id: Int,
    $user_id: Int,
    $sec_notificate_user_id: Int,
    $video_id: Int,

    $updatedAt: Date,
    
  ) {
    createOrUpdateCustomerSettingRelation(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      customer_token: $customer_token,
      type: $type,
      state: $state,
      user_abbreviated_name: $user_abbreviated_name,
      change_label: $change_label 
      observations: $observations 
      language: $language 

      latest: $latest 
      excel_import: $excel_import 

      customer_setting_id: $customer_setting_id 
      user_id: $user_id 
      sec_notificate_user_id: $sec_notificate_user_id 
      video_id: $video_id 

      updatedAt: $updatedAt 

    }) {
      
      id
      customer_token
      type
      state
      user_abbreviated_name
      change_label
      own_user_abbreviated_name
      own_change_label
      observations
      language
      
      excel_import

      customer_setting_id
      own_user_id
      user_id
      video_id

      search_customer_setting_title

      updatedAt
      createdAt

      updatedAtFormated
      createdAtFormated
      
    }
  }
`;
