import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateOwnUser(

      $own_user_token: String!,
      $own_customer_token: String!,

      $id: Int,
      $name: String,
      $email: String,
      $password: String,
      $pinned_settings: String,

    ) {
    createOrUpdateUser(input: { 

      own_user_token: $own_user_token, 
      own_customer_token: $own_customer_token, 

      id: $id, 
      name: $name, 
      email: $email, 
      password: $password, 
      pinned_settings: $pinned_settings, 

    }) {

      id
      token
      abbreviated_name
      name
      email
      profile_picture
      language
      professor
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      visible
      enabled
      
      original_customer_token
      navigation_customer_tokens
      customer_free
      license_token
      pinned_settings

      customer_permission {
        id
        token
        icon
        abbreviated_name
        factura10_client_id
        name
        language
      }

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
      }

    }
  }
`;
