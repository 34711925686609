import gql from "graphql-tag";
export default gql`
  query GetUserNotifications($own_user_token: String!, $own_customer_token: String!) {
    getUserNotifications(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      title
      text
      url
      type
      saved
      readed
      from_user_id
      to_user_id

      createdAt
      updatedAt

      from_user {
        id
        name
        profile_picture
        professor
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
