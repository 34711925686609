import { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import md5 from "md5";
import axios from 'axios'
import Resizer from "react-image-file-resizer";
import { FuncContext } from '../contexts/FuncContext';

import RecentActivityList from '../components/RecentActivityList'

import GetAllUsersAndProfessorsSimple from './../graphql/queries/GetAllUsersAndProfessorsSimple'
import GetAllCustomers from './../graphql/queries/GetAllCustomers'
import GetUser from './../graphql/queries/GetUser'
import GetAllUserTags from './../graphql/queries/GetAllUserTags'
import GetVideos from './../graphql/queries/GetVideos'
import CreateOrUpdateUser from './../graphql/mutations/CreateOrUpdateUser'

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import CustomMultipleSelect from '../components/Inputs/CustomMultipleSelect';
import CustomSwitch from '../components/Inputs/CustomSwitch';
import CustomSelect from '../components/Inputs/CustomSelect';
import CustomTextField from '../components/Inputs/CustomTextField';






const User = ({ ownUserId, professorMode }) => {


  

  const { id } = useParams()
  const { currentUser, setCurrentUser, currentCustomer, languageList } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { fileExists } = useContext(FuncContext)
  const navigate = useNavigate();









  /* Other */
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [videos, setVideos] = useState(null);
  const [userEmailExists, setUserEmailExists] = useState(false);
  const [alreadySubmited, setAlreadySubmited] = useState(false);

  /* Loading vars */
  const [baseLoading, setBaseLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  /* User vars */
  const [profilePic, setProfilePic] = useState(null);
  const [abbreviatedName, setAbbreviatedName] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState(null);
  const [language, setLanguage] = useState('esp');
  const [professorCode, setProfessorCode] = useState(null);
  const [vinculatedTo, setVinculatedTo] = useState(null);
  const [sex, setSex] = useState(1);
  const [treatment, setTreatment] = useState(1);
  const [rol, setRol] = useState('user');
  const [reciveEmails, setReciveEmails] = useState(0);
  const [reciveAutomatedEmails, setReciveAutomatedEmails] = useState(0);
  const [reciveReviewEmails, setReciveReviewEmails] = useState(0);
  const [reciveReplyEmails, setReciveReplyEmails] = useState(0);
  const [visible, setVisible] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [originalCustomerToken, setOriginalCustomerToken] = useState(null);
  const [navigationCustomerTokens, setNavigationCustomerTokens] = useState(null);
  const [customerFree, setCustomerFree] = useState(false);

  /* Tags */
  const [allTags, setAllTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);

  /* Upload new profile pic */
  const [newProfilePicFile, setNewProfilePicFile] = useState(false);
  const [newProfilePicURLTMP, setNewProfilePicURLTMP] = useState(null);


  const emailIcon = <div className='text-gray-500 mr-[10px]'><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M164-160q-34.65 0-59.325-24.675Q80-209.35 80-244v-472q0-34.65 24.675-59.325Q129.35-800 164-800h632q34.65 0 59.325 24.675Q880-750.65 880-716v472q0 34.65-24.675 59.325Q830.65-160 796-160H164Zm632-472L501.918-448.937Q497-446 491.5-444q-5.5 2-11.5 2t-11.5-1.75Q463-445.5 458-449L164-632v388h632v-388ZM480-518l318-198H163l317 198ZM164-632v9.848-60.598.75-34 34-.292V-622v-10 388-388Z"/></svg></div>
  const goBackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"/></svg>

  const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192-264v-24q0-23 12.5-43.5T239-366q55-32 116.5-49T480-432q63 0 124.5 17T721-366q22 13 34.5 34t12.5 44v24q0 30-21 51t-51 21H264q-30 0-51-21t-21-51Z"/></svg>
  const superUserIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M96-264v-20q0-26 12.5-47.5T143-366q57-34 120-50.5T392-433q13 0 19.5 11t3.5 24q-4 14-5 29t-1 30q0 27 4 53.5t15 51.5q6 15-2 28.5T403-192H168q-30 0-51-21t-21-51Zm576 0q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21ZM384-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm250 311-7-31q-14-5-26.5-12T577-228l-32 10q-11 3-20.5-1T510-232l-8-13q-5-9-4-19.5t9-18.5l24-23q-4-14-3.5-29t3.5-29l-23-22q-8-8-9.5-18t3.5-20l8-13q5-10 15-14t21-1l30 9q11-10 24-17.5t27-11.5l7-32q2-11 11-17.5t20-6.5h14q11 0 20 6.5t11 17.5l7 32q14 5 27 12t24 17l31-9q11-3 20.5 1.5T834-437l8 13q5 9 3.5 19.5T836-386l-22 21q2 15 2 30t-3 29l23 22q8 8 9.5 18.5T842-246l-8 13q-5 9-14.5 13t-20.5 1l-32-9q-11 9-23.5 16.5T717-200l-8 32q-2 11-10.5 17.5T679-144h-15q-11 0-19.5-7T634-169Z"/></svg>
  
  const editorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M576-180v-47q0-14.22 5-27.11Q586-267 597-278l189-188q7.26-7.41 16.13-10.71Q811-480 819.76-480q9.55 0 18.31 3.5Q846.83-473 854-466l44 45q6.59 7.26 10.29 16.13Q912-396 912-387.24t-3.29 17.92q-3.3 9.15-10.71 16.32L710-165q-11 11-23.89 16T659-144h-47q-15.3 0-25.65-10.35Q576-164.7 576-180Zm-384-48v-60q0-23 12.5-43.5T239-366q55-32 116.36-49T480-432q37.68 0 74.34 6Q591-420 626-409l-90 90q-15 15-23.5 35.06Q504-263.88 504-242v50H228q-15.3 0-25.65-10.35Q192-212.7 192-228Zm627-113 45-46-45-45-45 46 45 45ZM480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Z"/></svg>
  const translatorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M96,-264v-20q0-26,12.5-47.5t34.5-34.5q57-34,120-50.5t174-16.5q13,0,19.5,11t3.5,24q-2,14.193276-3.5,30.29488q0,18.30876,1.5,28.70512q2.5,26.046324,17,63.010676Q488.712582,-249.062468,500,-234q6,15-2,28.5t-23,13.5h-307q-30,0-51-21t-21-51Zm288-216q-60,0-102-42t-42-102t42-102t102-42t102,42t42,102-42,102-102,42Z" transform="translate(0 1.618438)"/><path d="M809.65,-318q-22.65,0-38.15-15.7-15.5-15.71-15.5-38.14v-89.73q0-22.43,15.65-38.43q15.64-16,38-16q22.35,0,38.35,15.8q16,15.79,16,38.36v90.27q0,22.57-15.85,38.07-15.86,15.5-38.5,15.5Zm.35,126q-8,0-13-5t-5-13v-37.21q-41.18-6.2-70.09-35Q693,-311,686,-352q-2-8,3-14t12.7-6q7.71,0,13,4q5.3,4,6.3,11q5.42,32.14,30.73,53.57q25.3,21.43,58.74,21.43q32.53,0,57.53-21t31-52.91q2-7.09,7.1-11.59t11.9-4.5q8,0,13,4.92t4,12.08q-5,42-35,72t-72,36.14v36.86q0,8-5,13t-13,5Z" transform="matrix(1.220584 0 0 1.220584-318.912751 87.970566)"/></svg>
  const facturatorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480,-96q-79,0-149-30t-122.5-82.5-82.5-122.5-30-149q0-80,30-149.5t82.5-122t122.5-82.5t149-30q80,0,149.5,30t122,82.5t82.5,122t30,149.5q0,79-30,149t-82.5,122.5-122,82.5-149.5,30Zm-1-120q14,0,24-9t10-23v-15q49-8,80.5-42t31.5-80q0-45-29-78t-83-52q-57-20-78-36t-21-41q0-20,18-33t47-13q19,0,34,6t25,17q10,12,22.5,16t23.5-1q13-6,17.5-18t-2.5-23q-15-23-37.5-37t-48.5-19v-15q0-14-9.5-23t-23.5-9-23.5,9-9.5,23v15q-45,11-72,40t-27,66q0,43,29,74t98,57q50,19,68,35.5t18,39.5q0,26-21,44t-52,18q-26,0-47.5-13t-33.5-36q-7-12-19.5-17t-24.5,0q-14,5-19,18t1,25q16,31,42.5,52.5t58.5,29.5v16q0,14,9.5,23t23.5,9Z" transform="matrix(.425531 0 0 0.425535 469.106069-132.624096)"/><path d="M96,-264v-20q0-26,12.5-47.5t34.5-34.5q57-34,120-50.5t129-16.5q13,0,19.5,11t3.5,24q-4,14-5,29t-1,30q0,27,4,53.5t15,51.5q6,15-2,28.5t-23,13.5h-235q-30,0-51-21t-21-51Zm288-216q-60,0-102-42t-42-102t42-102t102-42t102,42t42,102-42,102-102,42Z"/></svg>
  const adminIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-192q-29.7 0-50.85-21.15Q144-234.3 144-264v-24q0-23 12.5-43.5T191-366q55-32 116.36-49T432-432q18.67 0 37.33 1.5Q488-429 507-426q9 2 15 9t7 17q4 36 19.5 68t41.5 57q5 5 8.5 11.79 3.5 6.78 3.5 14.92v19.91q0 15.38-10.35 25.88Q581.3-192 566-192H216Zm216-288q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm299.79 72q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5Zm4.86 322.65L701-121q-1-1-5-11.86V-294q-42-11-69-46t-27-80.19q0-54.61 38.72-93.21 38.72-38.6 93.5-38.6t93.28 38.66Q864-474.69 864-420q0 42.58-24.65 75.69Q814.7-311.19 777-297l31.71 31.71Q814-260 814-252q0 8-5.18 13.18l-27.64 27.64Q776-206 776-198t5.18 13.18l27.64 27.64Q814-152 814-144t-5.25 13.25l-45.5 45.5Q758-80 750-80t-13.35-5.35Z"/></svg>


  const selStyle = { backgroundColor: "rgb(243 244 246)", "&:hover": { backgroundColor: "rgb(229 231 235)" }, "&.Mui-selected": { backgroundColor: "rgb(191 219 254)", "&:hover": { backgroundColor: "rgb(147 197 253)" } } }

  const userRols = [
    { value: 'user', icon: userIcon, name: 'Usuario base', type: 0 },
    { value: 'editor_user', icon: editorIcon, name: 'Usuario editor', type: 1 },
    { value: 'translator_user', icon: translatorIcon, name: 'Usuario traductor', type: 1 },
    { value: 'super_user', icon: superUserIcon, name: 'Super usuario', type: 0 },
    { value: null },
    { value: 'editor', icon: editorIcon, name: 'Editor / Motioner', type: 2 },
    { value: 'translator', icon: translatorIcon, name: 'Traductor', type: 2 },
    { value: 'facturator', icon: facturatorIcon, name: 'Facturador', type: 2 },
    { value: 'admin', icon: adminIcon, name: 'Administrador', type: 2 },
  ]







  const [createOrUpdateUser] = useMutation(CreateOrUpdateUser)

  const [getUser, { data: dataUser, loading: loadingUserData }] = useLazyQuery(GetUser, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      id: (ownUserId)? ownUserId : id,
      professor: (professorMode || currentUser?.professor)? 1 : 0,
    }
  })

  const { data: dataVideoNames, loading: loadingVideoNames } = useQuery(GetVideos, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: true, 
    }
  })

  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsersAndProfessorsSimple, { 
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: false
    } 
  })

  const { data: dataCustomer, loading: loadingCustomers } = useQuery(GetAllCustomers, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token
    }
  })

  const { data: dataUserTags, loading: loadingUserTags } = useQuery(GetAllUserTags, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })

  


  

  useEffect(() => {    
    setCurrentNavActive(professorMode? 'professors' : 'users')

    setTimeout(() => {
      setBaseLoading(false)
    }, 1000)
  }, [])


  useEffect(() => { 
    if (id || ownUserId) {
      getUser()
    }
  }, [id, ownUserId])


  useEffect(() => { 
    if (newProfilePicFile) {
      var url = URL.createObjectURL(newProfilePicFile);
      setNewProfilePicURLTMP(url)
    } else {
      setNewProfilePicURLTMP(null)
    }
  }, [newProfilePicFile])


  useEffect(() => { 
    if (dataUser?.userByRol) {
      setUser(dataUser.userByRol)
    } else {
      setUser(null)
    }
  }, [dataUser])


  useEffect(() => {
    setVideos((dataVideoNames?.videos?.length)? dataVideoNames?.videos : null)
  }, [dataVideoNames])


  useEffect(() => {    
    if (dataUsers?.usersAndProfessors) {
      setUsers(dataUsers.usersAndProfessors)
    } else {
      setUsers([])
    }
  }, [dataUsers])


  useEffect(() => { 
    if (user) {

      setProfilePic(user?.profile_picture)
      setAbbreviatedName(user?.abbreviated_name)
      setName(user?.name)
      setEmail(user?.email)
      setLanguage(user?.language)
      setPassword(null)
      setPasswordRepeat(null)
      setProfessorCode(user?.professor_code)
      setVinculatedTo(user?.vinculated_to_id)
      setSelectedTags((user?.tags && user?.tags?.split(','))? user?.tags?.split(',') : null)
      setSex(user?.sex)
      setTreatment(user?.treatment)
      setRol(user?.rol)
      setReciveEmails(user?.recive_emails)
      setReciveAutomatedEmails(user?.recive_automated_emails)
      setReciveReviewEmails(user?.recive_review_emails)
      setReciveReplyEmails(user?.recive_reply_emails)
      setVisible(user?.visible)
      setEnabled(user?.enabled)
      setOriginalCustomerToken(user?.original_customer_token)
      setNavigationCustomerTokens((user?.navigation_customer_tokens)? user?.navigation_customer_tokens?.split(',') : ['null'])
      setCustomerFree(user?.customer_free)

    } else {

      setProfilePic(null)
      setAbbreviatedName(null)
      setName(null)
      setEmail(null)
      setLanguage('esp')
      setPassword(null)
      setPasswordRepeat(null)
      setProfessorCode(null)
      setVinculatedTo(null)
      setSelectedTags(null)
      setSex(1)
      setTreatment(1)
      setRol('user')
      setReciveEmails(0)
      setReciveAutomatedEmails(0)
      setReciveReviewEmails(0)
      setReciveReplyEmails(0)
      setVisible(true)
      setEnabled(true)
      setOriginalCustomerToken(currentCustomer?.token)
      setNavigationCustomerTokens((currentCustomer?.token)? currentCustomer?.token?.split(',') : ['null'])
      setCustomerFree(false)
      
    }
  }, [user, currentCustomer])
  

  useEffect(() => {    
    if (dataUserTags?.userTags?.length) {
      setAllTags(dataUserTags?.userTags?.map((el) => { return { name: el?.name } }))
    } else {
      setAllTags(null)
    }
  }, [dataUserTags])
  







  async function checkUserEmail() {
    var emailRepeated = false;

    if (users?.length && email && email?.replaceAll(' ', '') !== '') {
      await users.map((userTMP, key) => {
        if (userTMP.email && userTMP.email.replaceAll(' ', '') === email.replaceAll(' ', '') && (!user?.id || userTMP.id !== user?.id)) {
          emailRepeated = true;
        }
      });
    }

    setUserEmailExists(emailRepeated);
  }


  async function saveUserData() {
    setLoading(true)
    setAlreadySubmited(true)

    if (
      name && name?.replaceAll(' ', '') !=='' && 
      ((email && email?.replaceAll(' ', '') !=='') || professorMode || currentUser?.professor) && 
      !userEmailExists &&
      ((id || ownUserId) || (password?.replaceAll(' ', '') !=='' && passwordRepeat?.replaceAll(' ', '') !=='' && password === passwordRepeat))
    ) {


      var input = {
        own_user_token: currentUser?.token, 
        own_customer_token: currentCustomer?.token,

        id: (ownUserId)? parseInt(ownUserId) : ((id)? parseInt(id) : null), 
        abbreviated_name: (abbreviatedName)? abbreviatedName : null,
        name: (name)? name : 'Sin nombre',
        email: (email && email?.replaceAll(' ', '') !== '')? email?.toLowerCase()?.replaceAll(' ', '') : null,
        password: (password && password?.replaceAll(' ', '') !=='')? md5(password.replaceAll(' ', '')) : ((professorMode && professorCode)? md5(professorCode) : null),
        language: (language)? language : 'esp',
        professor_code: (professorMode && professorCode)? professorCode : null,
        vinculated_to_id: (professorMode && parseInt(vinculatedTo))? parseInt(vinculatedTo) : null,
        tags: (selectedTags?.length)? selectedTags?.join(',') : null,
        sex: (professorMode && sex)? sex : null,
        treatment: (professorMode && treatment)? treatment : null,
        rol: rol,
        recive_emails: (reciveEmails)? 1 : 0,
        recive_automated_emails: (reciveAutomatedEmails)? true : false,
        recive_review_emails: (reciveReviewEmails)? true : false,
        recive_reply_emails: (reciveReplyEmails)? true : false,
        original_customer_token: (originalCustomerToken && originalCustomerToken !== 'null')? originalCustomerToken : null,
        navigation_customer_tokens: (navigationCustomerTokens?.length && navigationCustomerTokens[0] !== 'null')? navigationCustomerTokens?.join(',') : ((!professorMode)? null : currentCustomer?.token),
        customer_free: (!professorMode)? customerFree : false,
        visible: visible,
        enabled: enabled,
      };
  
      var userTMP = await createOrUpdateUser({variables: input})
      userTMP = userTMP?.data?.createOrUpdateUser;

      if (newProfilePicFile && userTMP?.id) {
        Resizer.imageFileResizer(
          newProfilePicFile,
          200,
          200,
          "png",
          100,
          0,
          async (uri) => {
            var newProfilePicFile = uri;
            var saveFileFormData = new FormData();
            saveFileFormData.append('userID', userTMP.id);
            saveFileFormData.append('file', newProfilePicFile);
            await axios.post(`${process.env.REACT_APP_API_ROUTE}setUserIcon`, saveFileFormData);
          },
          "file",
          5,
          5
        );
      }


      setTimeout(() => {
        if (parseInt(userTMP?.id) === parseInt(currentUser?.id)) {
          userTMP.profile_picture = (newProfilePicFile)? userTMP.id + '-icon.png' : null
          localStorage.setItem('localCurrentUser', JSON.stringify(userTMP));
          setCurrentUser(userTMP)
        }

        setLoading(false); 
        setPassword(null); 
        setPasswordRepeat(null)
  
        if (ownUserId) {
          navigate(0)
        } else {
          navigate(professorMode? "/professors" : "/users")
        }
      }, "1000")
    }

    setTimeout(() => {
      setLoading(false); 
      setPassword(null); 
      setPasswordRepeat(null)
    }, "1000")
  }


  async function removeUserProfilePicFunc() {
    if (profilePic) {
      await axios.post(`${process.env.REACT_APP_API_ROUTE}removeUserIcon`, { own_user_token: currentUser?.token, user_id: user.id });

      if (parseInt(user?.id) === parseInt(currentUser?.id)) {
        var userTMP = currentUser;
        userTMP.profile_picture = null
        localStorage.setItem('localCurrentUser', JSON.stringify(userTMP));
      }
      
      navigate(0)
    }
  }














  return (
    <div className={`px-5 md:px-10 min-w-[400px] ${(ownUserId)? 'ppy-6 md:py-20 md:px-20' : 'py-6 md:py-20 md:px-10'}`}>


      <div className='flex justify-between items-center'>
        <div className='flex justify-between items-center space-x-6 pl-4'>

          <Tooltip disableInteractive title={"Cambiar imagen de perfil"} placement="bottom" arrow>
            <label htmlFor="iconFile" className='text-gray-500 rounded-full hover:opacity-70 hover:shadow-lg transition duration-300 cursor-pointer'>
              <input hidden id="iconFile" accept="image/*" type="file" onChange={(e) => setNewProfilePicFile(e.target.files[0])}/>

              {(newProfilePicURLTMP || (profilePic && fileExists(process.env.REACT_APP_API_ROUTE + 'user_icons/' + profilePic)))?
                <div className='relative flex items-center justify-center p-12 rounded-full overflow-hidden border border-gray-500 bg-gray-400 cursor-pointer'>
                  <div className='absolute top-0 left-0 w-full h-full'>
                    <img 
                      className='object-cover h-full w-full'
                      src={newProfilePicURLTMP? newProfilePicURLTMP : process.env.REACT_APP_API_ROUTE + 'user_icons/' + profilePic} 
                      alt='edit_user_icon'
                    />  
                  </div>
                </div>
              :
                <div className='w-[97.3px] h-[97.3px] flex justify-center items-center'>
                  <AccountCircleIcon className='scale-[4.5]'/>
                </div>
              }

            </label>
          </Tooltip>

          <div>
            <p className='font-bold text-xs pt-9 text-gray-400'>{(id === currentUser?.id || ownUserId  === currentUser?.id)? 'Tu perfil:' : (professorMode)? `${currentCustomer?.label_professor}:` : 'Usuario:'}</p>
            <h1 className='font-bold text-4xl pb-8'>
              {
                (((id || ownUserId) && user) || (!(id || ownUserId) && !user) || (baseLoading || loadingUserData || loadingVideoNames || loadingUsers || loadingCustomers || loadingUserTags))? (
                  (name && name?.replaceAll(' ', '') !=='')? name 
                  : (baseLoading || loadingUserData || loadingVideoNames || loadingUsers || loadingCustomers || loadingUserTags)? 'Cargando...' 
                  : 'Nuevo usuario'
                ) : 'Usuario inexistente'
              }
              {(id === currentUser?.id || ownUserId  === currentUser?.id) && 
                <small className='text-sm text-gray-400 ml-1'>
                  /
                  {(professorMode)? currentCustomer?.label_professor : (

                    (currentUser?.rol === 'admin')? ' Usuario administrador' 
                    : (currentUser?.rol?.includes('translator'))? ' Usuario traductor' 
                    : (currentUser?.rol?.includes('editor'))? ' Usuario editor' 
                    : (currentUser?.rol === 'facturator')? ' Usuario de facturación' 
                    : (currentUser?.rol === 'super_user')? ' Super usuario' 
                    : ' Usuario base'
                    
                  )}
                </small>
              }
            </h1>
          </div>
        </div>


        <div className='flex flex-wrap justify-end space-x-2'>
          {(profilePic) &&
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              color="error"
              startIcon={<SaveIcon />}
              onClick={removeUserProfilePicFunc}
              disabled={!(((id || ownUserId) && user) || (!(id || ownUserId) && !user)) || userEmailExists || (!email && !professorMode) || !name}
            >
              Eliminar foto de perfil
            </LoadingButton>
          }

          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={saveUserData}
            disabled={!(((id || ownUserId) && user) || (!(id || ownUserId) && !user)) || userEmailExists || (!email && !professorMode) || ((!password || password !== passwordRepeat) && !professorMode && !user) || !name}
          >
            Guardar
          </LoadingButton>

          {(currentUser?.rol === 'super_user' && !currentCustomer?.free_customer) &&
            <div className='flex'>
              <Tooltip disableInteractive title={`Volver a los ${(user?.professor)? currentCustomer?.label_professors?.toLowerCase() : 'usuarios'}`} placement="bottom" arrow>
                <a href={`/${(user?.professor)? currentCustomer?.label_professors?.toLowerCase() : 'users'}`}>
                  <IconButton color="primary" size="medium">
                    {goBackIcon}
                  </IconButton>
                </a>
              </Tooltip>
            </div>
          }
        </div>
      </div>


      <hr className='border-t-2 border-gray-200'/>


      {(((id || ownUserId) && user) || (!(id || ownUserId) && !user) || (baseLoading || loadingUserData || loadingVideoNames || loadingUsers || loadingCustomers || loadingUserTags))?
        !(baseLoading || loadingUserData || loadingVideoNames || loadingUsers || loadingCustomers || loadingUserTags)?
          <div>
            <h1 className='input-section-title'>Información básica</h1>

            <div className='w-full flex flex-wrap items-start space-y-3'>
              <div className={`w-full flex items-center flex-wrap md:flex-nowrap space-x-0 space-y-3 md:space-x-2 md:space-y-0`}>
                {(professorMode && (id !== currentUser?.id && ownUserId !== currentUser?.id)) && 
                  <CustomTextField
                    label={`Código de ${currentCustomer?.label_professors?.toLowerCase()}`}
                    className='w-full md:max-w-[150px] mui-bg-gray-100'
                    disabled={currentUser?.rol !== 'admin'}
                    
                    value={(professorCode)? professorCode : ''} 
                    onChange={(event) => setProfessorCode((event.target.value)? event.target.value?.slice(0, 20) : null)} 
                  />
                }
      
                {(!professorMode && (!currentUser?.rol?.includes('user') || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))) &&
                  <CustomTextField
                    label="Nombre abreviado" 
                    type="email"
                    className='w-full max-w-none md:max-w-[162px] md:w-auto mui-bg-gray-100'
                    disabled={currentUser?.rol !== 'admin'}

                    value={(abbreviatedName)? abbreviatedName : ''} 
                    onChange={(event) => setAbbreviatedName((event.target.value?.replaceAll(' ', ''))? event.target.value?.replaceAll(' ', '').substring(0,5) : null)} 
                  />
                } 
                
                {(!currentUser?.professor) &&
                  <CustomTextField
                    label="Nombre" 
                    error={(alreadySubmited || (id || ownUserId)) && (name === '' || !name)? 'Este campo es obligatorio.' : null }
                    
                    value={(name)? name : ''} 
                    onChange={(event) => setName((event.target.value)? event.target.value?.slice(0, 100) : null)}
                  />
                }
                
                {(!vinculatedTo || vinculatedTo === 'null') &&
                  <CustomTextField
                    label="Email" 
                    type="email"
                    error={(!professorMode && (alreadySubmited || id || ownUserId) && (!user?.professor && (email === '' || !email)))? 'Este campo es obligatorio.' : ((userEmailExists)? 'Este email ya se encuentra registrado.' : null)}

                    value={(email)? email : ''} 
                    onChange={(event) => setEmail((event.target.value)? event.target.value.replaceAll(' ', '')?.slice(0, 30) : null)} 
                    onKeyUp={checkUserEmail} 
                  />
                } 

                {(currentUser?.rol === 'admin' && !email) &&
                  <CustomSelect
                    label="Vinculado a"

                    value={(vinculatedTo)? vinculatedTo : 'null'}
                    onChange={(event) => setVinculatedTo(event.target.value)}
                  >
                    <MenuItem value={'null'}>Nadie</MenuItem>
                    {users?.filter((el) => el?.original_customer_token === currentCustomer?.token)?.map((element) => {
                      return <MenuItem key={'user_from_customer_' + element.id} value={element.id}>{element.name}</MenuItem>
                    })}
                  </CustomSelect>
                }

                {((currentUser?.rol === 'admin' || currentUser?.rol === 'super_user') && id !== currentUser?.id && ownUserId !== currentUser?.id) && 
                  <div className='w-full md:w-auto flex space-x-2'>
                    {(currentUser?.rol === 'admin') && 
                      <CustomSwitch
                        label="Visible"
                        addClassName="w-full xl:w-auto"
                        value={visible}
                        onClick={() => setVisible(!visible)}
                      />
                    }

                    <CustomSwitch
                      label={(enabled)? "Habilitado" : "Deshabilitado"}
                      addClassName="w-full xl:w-auto"
                      value={enabled}
                      onClick={() => setEnabled(!enabled)}
                    />
                  </div>
                }
              </div>
              


              {((!currentUser?.professor && !professorMode) || email) &&
                <div className={`w-full flex flex-wrap 2xl:flex-nowrap space-x-0 space-y-3 2xl:space-x-2 2xl:space-y-0`}>
                  {(!currentUser?.professor && !professorMode) &&
                    <div className={`w-full flex flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-x-2 sm:space-y-0`}>
                      <CustomTextField
                        label={(id || ownUserId)? "Nueva contraseña" : 'Contraseña'}
                        type="password"
                        error={(alreadySubmited && !(id || ownUserId)) && (password === '' || !password)? 'Este campo es obligatorio.' : null }

                        value={(password)? password : ''}
                        onChange={(event) => setPassword(event.target.value)}
                      />

                      <CustomTextField
                        label={(id || ownUserId)? "Repite la nueva contraseña" : 'Repite la contraseña'}
                        type="password"
                        error={(alreadySubmited && !(id || ownUserId)) && (passwordRepeat === '' || !passwordRepeat)? 'Este campo es obligatorio.' : null }

                        value={(passwordRepeat)? passwordRepeat : ''}
                        onChange={(event) => setPasswordRepeat(event.target.value)}
                      />
                    </div>
                  }

                  {(email) &&
                    <div className="w-full 2xl:w-auto flex flex-wrap xl:flex-nowrap space-x-0 space-y-3 xl:space-x-2 xl:space-y-0">
                      <div className="w-full 2xl:w-auto flex flex-wrap md:flex-nowrap space-x-0 space-y-3 md:space-x-2 md:space-y-0">
                        <CustomSelect
                          label="Resumen de actividad (Email)"
                          value={(reciveEmails)? reciveEmails : 0}
                          onChange={(event) => setReciveEmails(event.target.value)}
                        >
                          <MenuItem value={0}>{emailIcon} No recibir nunca</MenuItem>
                          <MenuItem value={1}>{emailIcon} Recibirlo diariamente</MenuItem>
                          <MenuItem value={2}>{emailIcon} Recibirlo semanalmente</MenuItem>
                          <MenuItem value={3}>{emailIcon} Recibirlo mensualmente</MenuItem>
                        </CustomSelect>

                        <CustomSwitch
                          label="Correos automatizados (Email)"
                          addClassName="w-full xl:w-auto"
                          value={reciveAutomatedEmails}
                          onClick={() => setReciveAutomatedEmails(!reciveAutomatedEmails)}
                        />
                      </div>

                      <div className="w-full xl:w-auto 2xl:w-auto flex flex-wrap md:flex-nowrap space-x-0 space-y-3 md:space-x-2 md:space-y-0">
                        <CustomSwitch
                          label="Notificar nuevas revisiónes (Email)"
                          addClassName="w-full xl:w-auto"
                          value={reciveReviewEmails}
                          onClick={() => setReciveReviewEmails(!reciveReviewEmails)}
                        />

                        <CustomSwitch
                          label="Notificar respuestas (Email)"
                          addClassName="w-full xl:w-auto"
                          value={reciveReplyEmails}
                          onClick={() => setReciveReplyEmails(!reciveReplyEmails)}
                        />
                      </div>
                    </div>
                  }
                </div>
              }
            </div>



            {(professorMode) &&
              <div className={`pt-3 w-full flex flex-wrap lg:flex-nowrap space-x-0 space-y-3 lg:space-x-2 lg:space-y-0`}>
                <div className={`w-full flex flex-wrap md:flex-nowrap space-x-0 space-y-3 md:space-x-2 md:space-y-0`}>
                  <TextField
                    select
                    value={(sex)? sex : 1}
                    onChange={event => setSex(event.target.value)}
                    label="Genero"
                    className='w-full lg:max-w-[200px] mui-bg-gray-100'
                    size="small"
                  >
                    <MenuItem value={1}>Hombre</MenuItem>
                    <MenuItem value={2}>Mujer</MenuItem>
                  </TextField>

                  <TextField
                    select
                    value={(treatment)? treatment : 1}
                    onChange={event => setTreatment(event.target.value)}
                    label="Trato"
                    className='w-full lg:max-w-[200px] mui-bg-gray-100'
                    size="small"
                  >
                    <MenuItem value={1}>Doctor/a</MenuItem>
                    <MenuItem value={2}>{currentCustomer?.label_professor}</MenuItem>
                    <MenuItem value={3}>Sr/a</MenuItem>
                    <MenuItem value={4}>Ponente</MenuItem>
                  </TextField>

                  <TextField
                    select
                    value={(language)? language : 'esp'}
                    onChange={event => setLanguage(event.target.value)}
                    label="Idioma"
                    className='w-full mui-bg-gray-100'
                    size="small"
                  >
                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem>
                    })}

                    <MenuItem value={'other'}>Otro</MenuItem>
                  </TextField>
                </div>

                {(allTags?.length) &&
                  <Autocomplete
                    multiple
                    options={(allTags?.length)? allTags?.filter((el) => !selectedTags?.includes(el?.name)) : []}
                    getOptionLabel={(option) => option?.name}
                    value={(selectedTags?.length)? selectedTags?.map((el) => { return { name: el } }) : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Segmentos"
                        placeholder=""
                        className='w-full mui-bg-gray-100'
                        size="small"
                      />
                    )}
                    onChange={(e, newValue) => {
                      if (newValue?.length) {
                        setSelectedTags(newValue?.map((el) => el?.name))
                      } else {
                        setSelectedTags(null)
                      }
                    }}
                    className='w-full'
                    size="small"
                  />
                }
              </div>
            }



            {(id !== currentUser?.id && ownUserId !== currentUser?.id && (currentUser?.rol === 'admin' || currentUser?.rol === 'super_user')) && <>
              <h1 className='input-section-title'>Permisos</h1>

              <div className='w-full flex flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-x-2 sm:space-y-0'>
                {(!professorMode && !currentUser?.rol?.includes('user') && !currentCustomer?.free_customer) && 
                  <div className='w-full sm:w-[270px]'>
                    <CustomSwitch
                      label="Usuario avanzado"
                      addClassName="w-full"
                      disabled={(parseInt(currentUser?.id) === parseInt(user?.id))}

                      value={customerFree}
                      onClick={() => {
                        if (!customerFree && !rol?.includes('user')) { 
                          setNavigationCustomerTokens(['null'])
                        } else {
                          setRol('user')
                          setNavigationCustomerTokens([originalCustomerToken])
                        }

                        setCustomerFree(!customerFree)
                      }}
                    />
                  </div>
                }

                
                {(!professorMode) && 
                  <CustomSelect
                    label={`Rol dentro de ${currentCustomer?.name}`}
                    value={(rol)? rol : 'user'}
                    onChange={(event) => setRol(event.target.value)}
                    disabled={(parseInt(currentUser?.id) === parseInt(user?.id))}
                  >
                    {userRols?.map((element) => {
                      const visible = (!element?.type || (element?.type === 1 && currentCustomer?.free_customer) || (element?.type === 2 && !currentCustomer?.free_customer && !currentUser?.rol?.includes('user')))? true : false
                      return (element?.value && visible)? <MenuItem value={element?.value} sx={(element?.type === 2)? selStyle : {}}><div className='flex items-center space-x-3'><div className='text-gray-500 scale-[1.3]'>{element?.icon}</div> <p>{element?.name}</p></div></MenuItem>
                      : (!element?.value)? <hr className='border-gray-300' />
                      : null
                    })}
                  </CustomSelect>
                }

                <CustomSelect
                  label="Proyecto original"

                  value={(originalCustomerToken)? originalCustomerToken : 'null'}
                  onChange={(event) => setOriginalCustomerToken(event.target.value)}
                >
                  <MenuItem value={'null'}>Ningúno</MenuItem>
                  {dataCustomer?.customers?.map(element => {
                    return <MenuItem key={'from_customer_' + element.id} value={element.token}>{element.name}</MenuItem>
                  })}
                </CustomSelect>

                {(!customerFree) && 
                  <CustomMultipleSelect
                    label="Tiene acceso a"

                    value={(navigationCustomerTokens?.length)? navigationCustomerTokens : ['null']}
                    onChange={(event) => {
                      const valueTMP = (
                        (event.target.value?.length > 1 && event.target.value[event.target.value?.length-1] === 'null')? ['null']
                        : (event.target.value?.length > 1)? event.target.value?.filter((el) => el !=='null')
                        : (event.target.value?.length)? event.target.value
                        : ['null']
                      );
                      setNavigationCustomerTokens([...new Set(valueTMP)])
                    }}
                    renderValue={(elements) => {
                      const customersTMP = (dataCustomer?.customers?.length)? dataCustomer?.customers : null;
                      var names = [];

                      if (customersTMP?.length && elements?.length && elements[0] !== 'null') {
                        for (var itemSelected of elements) {
                          const itemTMP = customersTMP?.find((x) => x?.token === itemSelected)
                          if (itemTMP?.name) { names.push(itemTMP?.name) }
                        }
                      }

                      return <p className={`${(names?.length > 4)? 'text-xs' : (names?.length > 2)? 'text-sm' : ''}`}>
                        {(names?.length)? ((names?.length > 1)? '(' + names?.length + ' Clientes) ' : '') + names?.join(', ').replace(/,([^,]*)$/, ' y $1') : 'Ninguno'}
                      </p>
                    }}
                  >
                    <MenuItem value={'null'}>Ningúno</MenuItem>
                    {dataCustomer?.customers?.map(element => {
                      return <MenuItem key={'customer_' + element.token} value={element.token}>{element.name}</MenuItem>
                    })}
                  </CustomMultipleSelect>
                }
              </div>
            </>}
          </div>
        :
          <div className='w-full py-[250px] m-4 flex justify-center items-center space-x-4 text-gray-400 bg-gray-100 rounded-md'>
            <CircularProgress  color="inherit"/>
            <p>Cargando...</p>
          </div>
      :
        <p className='text-gray-400 px-1 py-4'>Es probable que este usuario haya sido eliminado o directamente no haya existido nunca.</p>
      }



      {(id && user?.id && !(baseLoading || loadingUserData || loadingVideoNames || loadingUsers || loadingCustomers || loadingUserTags) && currentUser?.rol === 'admin') &&
        <div className='mt-5 pb-10'>
          <h1 className='input-section-title'>Actividad reciente</h1>
          <RecentActivityList users={users} videos={videos} userId={user?.id} /* selfActivities={user.own_activities} */ defOpen={false}/>
        </div>
      }



    </div>
  )
}

export default User
