import { createContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'

import { esp } from './languages/esp'
import { cat } from './languages/cat'
import { eng } from './languages/eng'







export const AuthContext = createContext()
export const AuthContextProvider = ({ children }) => {




  const navigate = useNavigate();
  const location = useLocation();


  

  const languageList = [
    { name: 'Español', abbreviated_name: 'esp', file_key: '_es', flag: `${process.env.REACT_APP_ROUTE}flags/esp.png` },
    { name: 'Inglés', abbreviated_name: 'eng', file_key: '_en', flag: `${process.env.REACT_APP_ROUTE}flags/eng.png` },
    { name: 'Italiano', abbreviated_name: 'ita', file_key: '_it', flag: `${process.env.REACT_APP_ROUTE}flags/ita.png` },
    { name: 'Alemán', abbreviated_name: 'ale', file_key: '_de', flag: `${process.env.REACT_APP_ROUTE}flags/ale.png` },
    
    { name: 'Portugués (Portugal)', abbreviated_name: 'por', file_key: '_pt_pt', flag: `${process.env.REACT_APP_ROUTE}flags/por.png` },
    { name: 'Portugués (Brasil)', abbreviated_name: 'bra', file_key: '_pt_br', flag: `${process.env.REACT_APP_ROUTE}flags/bra.png` },
  ]


  var activityTypes = [
    { value: 'null', name: 'Todos los tipos'},

    { value: 'update_user, create_user', name: 'Usuarios'},

    { value: 'priority_videos, video_priority_up', name: 'Prioridad en los vídeos'},

    { value: 'update_video, create_video, remove_video, video_state_change, video_title_change, downloaded_video', name: 'Todos los cambios en los vídeos'},
    { value: 'create_video', name: 'Vídeos creados'},
    { value: 'video_state_change', name: 'Cambios de estado en los vídeos'},
    { value: 'video_title_change', name: 'Cambios de título en los vídeos'},

    { value: 'remove_video_review, create_video_review', name: 'Reviews'},

    { value: 'update_video_mark, create_video_mark, remove_video_mark, update_video_mark_comment, create_video_mark_comment, remove_video_mark_comment', name: 'Marcas y comentaríos'},
  ]
  









  /* Auth vars */
  const sesionCurrentUser = (sessionStorage.getItem('localCurrentUser'))? JSON.parse( sessionStorage.getItem('localCurrentUser') ) : null
  const localCurrentUser = (sesionCurrentUser)? sesionCurrentUser : (localStorage.getItem('localCurrentUser'))? JSON.parse( localStorage.getItem('localCurrentUser') ) : null

  const sesionCurrentCustomer = (sessionStorage.getItem('localCurrentCustomer'))? JSON.parse( sessionStorage.getItem('localCurrentCustomer') ) : null
  const localCurrentCustomer = (sesionCurrentCustomer)? sesionCurrentCustomer : (localStorage.getItem('localCurrentCustomer'))? JSON.parse( localStorage.getItem('localCurrentCustomer') ) : null
  const localClicksoundCustomer = JSON.parse( localStorage.getItem('localClicksoundCustomer') )

  const [currentUser, setCurrentUser] = useState((localCurrentUser?.id)? localCurrentUser : null)
  const [currentCustomer, setCurrentCustomer] = useState((localCurrentCustomer?.id)? localCurrentCustomer : null)
  const [customerPath, setCustomerPath] = useState(process.env.REACT_APP_API_ROUTE + 'customer_media/' + localCurrentCustomer?.token + '/')
  const [clicksoundCustomer, setClicksoundCustomer] = useState((localClicksoundCustomer?.id)? localClicksoundCustomer : null)



  const [apiError, setApiError] = useState(false)
  const [history, setHistory] = useState(['', '', '', ''])
  const [customPageTitle, setCustomPageTitle] = useState(null)


  var localStorageLang = localStorage.getItem(currentCustomer?.token+'-language')
  const [language, setLanguage] = useState( localStorageLang === 'cat' ? cat : localStorageLang === 'eng' ? eng : esp)

  var localShowPrices = localStorage.getItem(currentCustomer?.token+'-show-prcies')
  const [showPrices, setShowPrices] = useState((parseInt(localShowPrices))? true : false)

  var localMarksNearTitle = localStorage.getItem(currentCustomer?.token+'-marks-near-marks')
  const [marksNearName, setMarksNearName] = useState((localMarksNearTitle)? ((parseInt(localMarksNearTitle))? true : false) : ((currentUser?.rol?.includes('user'))? 0 : 1))

  const [showNavTop, setShowNavTop] = useState(false)
  const [showNavLeft, setShowNavLeft] = useState(false)


  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;




  /* Change show prices */
  function toggleShowPricesFunc() {
    setShowPrices(!showPrices)
    localStorage.setItem(currentCustomer?.token + '-show-prcies', (!showPrices)? 1 : 0)
  }

  /* Change marks near title */
  function toggleMarksNearName() {
    setMarksNearName(!marksNearName)
    localStorage.setItem(currentCustomer?.token + '-marks-near-marks', (!marksNearName)? 1 : 0)
  }






  useEffect(() => {
    showNavTopFunc()
    showNavLeftFunc()
    if (customPageTitle) { setCustomPageTitle(null) }

    if (location.pathname.toLowerCase() !== history[0]) {
      setHistory([location.pathname.toLowerCase(), history[0], history[1], history[2]])
    }
  }, [location]);


  useEffect(() => {
    if (currentCustomer) {
      var nameTMP = null;
      var splittedPathname = location?.pathname?.split('/');
      var splittedPathnameKey = (splittedPathname[splittedPathname?.length-1] && !parseInt(splittedPathname[splittedPathname?.length-1]))? splittedPathname?.length-1 : splittedPathname?.length-2;

      if (!customPageTitle) {
        if (location?.pathname === '/dashboard') { nameTMP = currentCustomer?.abbreviated_name + ' - ' + 'Inicio' }
        if (location?.pathname === '/videos') { nameTMP = currentCustomer?.abbreviated_name + ' - ' + 'Vídeos' }
        if (splittedPathname?.length > 2 && splittedPathname[1] === currentCustomer?.abbreviated_name) { nameTMP = currentCustomer?.abbreviated_name + ' - ' + splittedPathname[splittedPathnameKey] }
        if (!nameTMP && currentCustomer && currentUser) { nameTMP = currentCustomer?.abbreviated_name + ' - ' + 'Topic Manager'}
        if (!nameTMP) { nameTMP = 'Topic Manager'}
      }

      document.title = (customPageTitle)? customPageTitle : nameTMP;
    }
  }, [location, currentCustomer, currentUser, customPageTitle]);









  /* User var lost */
  useEffect(() => {
    onFocus()
    window.addEventListener("visibilitychange", onFocus)



    function onFocus() {
      if (document?.visibilityState === 'visible') {
        const startDate = new Date();

        setApiError(false);
        var localCurrentUserTMP = (sessionStorage.getItem('localCurrentUser'))? JSON.parse(sessionStorage.getItem('localCurrentUser')) : (localStorage.getItem('localCurrentUser'))? JSON.parse(localStorage.getItem('localCurrentUser')) : null
        var localCurrentCustomerTMP = (sessionStorage.getItem('localCurrentCustomer'))? JSON.parse(sessionStorage.getItem('localCurrentCustomer')) : (localStorage.getItem('localCurrentCustomer'))? JSON.parse(localStorage.getItem('localCurrentCustomer')) : null
        var localClicksoundCustomerTMP = JSON.parse(localStorage.getItem('localClicksoundCustomer'))


        if (!localCurrentUserTMP?.token && currentUser) { localCurrentUserTMP = currentUser; }
        if (!localCurrentCustomerTMP?.token && currentCustomer) { localCurrentCustomerTMP = currentCustomer; }
        if (!localClicksoundCustomerTMP?.token && clicksoundCustomer) { localClicksoundCustomerTMP = clicksoundCustomer; }


        if (localCurrentCustomerTMP?.token && localCurrentUserTMP?.token && showNavTop) {
          axios.post(`${process.env.REACT_APP_API_ROUTE}checkUserAndCustomerExists`, { 
            
            own_user_token: localCurrentUserTMP?.token,
            own_customer_token: localCurrentCustomerTMP?.token,
            clicksound_customer_token: 'jUtZ97TxAs2IDUREraAO'
          
          }).then((response) => {
            setApiError(false);

            if (response?.data && response?.data?.currentUser && response?.data?.currentCustomer && response?.data?.clicksoundCustomer) { 

              /* CURRENT USER */
              localCurrentUserTMP = (currentUser)? {...currentUser, ...response?.data?.currentUser} : response?.data?.currentUser;
              const currentUserString = JSON.stringify(localCurrentUserTMP)
              sessionStorage.setItem('localCurrentUser', currentUserString);
              localStorage.setItem('localCurrentUser', currentUserString);
              if (!currentUser || currentUserString !== JSON.stringify(currentUser)) { setCurrentUser(localCurrentUserTMP); console.log('SET CURRENT USER - ' + localCurrentUserTMP?.name) }

              /* CURRENT CUSTOMER */
              localCurrentCustomerTMP = (currentCustomer)? {...currentCustomer, ...response?.data?.currentCustomer} : response?.data?.currentCustomer;
              const currentCustomerString = JSON.stringify(localCurrentCustomerTMP)
              sessionStorage.setItem('localCurrentCustomer', currentCustomerString);
              localStorage.setItem('localCurrentCustomer', currentCustomerString);
              if (!currentCustomer) { setCurrentCustomer(localCurrentCustomerTMP); console.log('SET CURRENT CUSTOMER - ' + localCurrentCustomerTMP?.name) }

              const customerPathTMP = process.env.REACT_APP_API_ROUTE + 'customer_media/' + localCurrentCustomerTMP?.token + '/';
              if (!customerPath) { setCustomerPath(customerPathTMP) }

              /* CLICKSOUND CUSTOMER */
              localClicksoundCustomerTMP = (clicksoundCustomer)? {...clicksoundCustomer, ...response?.data?.clicksoundCustomer} : response?.data?.clicksoundCustomer;
              const clicksoundCustomerString = JSON.stringify(localClicksoundCustomerTMP)
              localStorage.setItem('localClicksoundCustomer', clicksoundCustomerString);
              if (!clicksoundCustomer || clicksoundCustomerString !== JSON.stringify(clicksoundCustomer)) { setClicksoundCustomer(localClicksoundCustomerTMP); console.log('SET CLICKSOUND CUSTOMER - ' + localClicksoundCustomerTMP?.name) }


              if (localCurrentUserTMP?.id && parseInt(localCurrentUserTMP?.id) === 3) {
                console.log(' ')
                console.log('User checked in ' + ((new Date().getTime() - startDate.getTime()) / 1000) + ' seconds.')
                console.log(' ')
              }

            } else {

              navigate("/signin");

            }

          }).catch(function (error) {

            setApiError(true);
            console.log('Error in: checkUserExists');
            console.log(error);
            navigate("/signin");

          });
        } else if (showNavTop) {
          navigate("/signin");
        }
      }
    }



    return () => {
      window.removeEventListener('visibilitychange', onFocus)
    };
  }, [
    currentUser?.id,
    currentUser?.rol,
    currentUser?.name,
    
    currentCustomer?.id,
    clicksoundCustomer?.id,
    showNavTop
  ])












  /* Hidde top nav */
  function showNavTopFunc() {
    const currentLocation = (location.pathname)? location.pathname?.split('/')[1] : null
    const res = (
      currentUser
      && currentLocation?.toLowerCase() !== 'your-billing'
      && currentLocation?.toLowerCase() !== 'signin'
      && currentLocation?.toLowerCase() !== 'recover-password'
      && currentLocation?.toLowerCase() !== 'customer-selector'
      && currentLocation?.toLowerCase() !== 'customer'
      && currentLocation?.toLowerCase() !== 'shared-video'
    )? true : false

    setShowNavTop(res)
  }

  /* Hidde nav */
  function showNavLeftFunc() {
    const currentLocation = (location.pathname)? location.pathname?.split('/')[1] : null
    const res = (
      (currentUser && (!currentUser?.rol?.includes('user') || (currentCustomer?.free_customer && currentUser?.rol === 'super_user')))
      && currentLocation?.toLowerCase() !== 'your-billing'
      && currentLocation?.toLowerCase() !== 'signin'
      && currentLocation?.toLowerCase() !== 'recover-password'
      && currentLocation?.toLowerCase() !== 'customer-selector'
      && currentLocation?.toLowerCase() !== 'customer'
      && currentLocation?.toLowerCase() !== 'shared-video'
    )? true : false
    
    setShowNavLeft(res)
  }

  /* Change language (Not used) */
  function changeLanguage(lang) {
    if (lang === 'cat' && language.test !== cat.test) {
      setLanguage(cat)
      localStorage.setItem(currentCustomer?.token+'-language', 'cat')
    } else if (lang === 'eng' && language.test !== eng.test) {
      setLanguage(eng)
      localStorage.setItem(currentCustomer?.token+'-language', 'eng')
    } else if (lang === 'esp' && language.test !== esp.test) {
      setLanguage(esp)
      localStorage.setItem(currentCustomer?.token+'-language', 'esp')
    }
  }











  
/* Chyron texts */
function getNewChyronLanguageFunc(selectedLanguages, professorPositions) {
  if (selectedLanguages?.length) {
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      if (!selectedLanguages?.includes('esp') && !professorPositionsTMP['esp']) { selectedLanguages = [...selectedLanguages, 'esp'] }
      if (!selectedLanguages?.includes('eng') && !professorPositionsTMP['eng']) { selectedLanguages = [...selectedLanguages, 'eng'] }

      for (var selectedLanguage of selectedLanguages) {
          if (!professorPositionsTMP[selectedLanguage]) {
              professorPositionsTMP[selectedLanguage] = {
                  language: selectedLanguage,
          
                  text: null,
                  text_change_label: null,
      
                  old_text: null,
                  old_text_change_label: null,
                  old_text_changes: null,
      
                  state: 1,
                  state_change_label: null,
                  motion_state: 1,
                  motion_state_change_label: null,
      
                  revision_state: 1,
                  revision_state_change_label: null,
      
                  trad_done: false,
                  trad_done_change_label: null,
              }
          }
      }

      return professorPositionsTMP;
  }
}



/* Mark texts */
function getNewMarkLanguageFunc(selectedLanguages, isOriginal, texts) {
  if (selectedLanguages?.length) {
    var textsTMP = (texts && Object.keys(texts)?.length)? {...texts} : {};
    if (!selectedLanguages?.includes('esp') && !textsTMP['esp']) { selectedLanguages = [...selectedLanguages, 'esp'] }
    if (!selectedLanguages?.includes('eng') && !textsTMP['eng']) { selectedLanguages = [...selectedLanguages, 'eng'] }

    for (var selectedLanguage of selectedLanguages) {
      if (!textsTMP[selectedLanguage]) {
          textsTMP[selectedLanguage] = {
              language: selectedLanguage,
              original: (selectedLanguage === isOriginal)? true : false,
          
              text: null,
              text_change_label: null,
              filename: null,
              filename_sm: null,
          }

          if (selectedLanguage === isOriginal) {
              textsTMP[selectedLanguage] = {
                  ...textsTMP[selectedLanguage],
                  old_text: null,
                  old_text_change_label: null,
              }
          } else {
              textsTMP[selectedLanguage] = {
                  ...textsTMP[selectedLanguage],
                  old_text: null,
                  old_text_change_label: null,
              }
          }
      }
    }

    return textsTMP;
  }
}






  

  function isJsonString(str) {
    try { JSON.parse(str); }
    catch (e) { 
      console.log(' ')
      console.log('AUTH ERROR JSON')
      console.log(str)
      return false; 
    }
    return true;
  }

  function getJsonString(str, checkKeys) {
    var jsonOBJ = (str && isJsonString(str))? JSON.parse(str) : null;
    return (str && jsonOBJ && (!checkKeys || Object.keys(jsonOBJ)?.length))? jsonOBJ : null;
  }








  return (
    <AuthContext.Provider
      value={{
        apiError,
        
        languageList,
        activityTypes,

        currentUser,
        setCurrentUser,
        currentCustomer,
        setCurrentCustomer,
        clicksoundCustomer,

        getJsonString,

        customerPath,

        customPageTitle,
        setCustomPageTitle,

        language,
        getNewChyronLanguageFunc,
        getNewMarkLanguageFunc,

        showPrices,
        toggleShowPricesFunc,
        marksNearName,
        toggleMarksNearName,
        history,
        setHistory,

        changeLanguage,
        showNavTop,
        showNavLeft,
      }}
    >
      {(currentUser || !showNavTop)?
        children
      :
        <div className={`fixed z-[99999] w-screen h-screen flex items-center justify-center space-x-1 bg-gray-50 duration-400 opacity-100`}>
          <div className="flex items-center justify-center space-x-1 opacity-50">
            {loadingIcon}
            <p>Cargando...</p>
          </div>
        </div>
      }
    </AuthContext.Provider>
  )
}
