export default function PODCAST_EN_PLATO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="scale-[1]">
			<path d="M260.44,328.29c18.85-2.02,36.12-8.3,51.32-19.64c26.89-20.09,41.45-47.01,43.11-80.58c0.56-11.25,0.23-22.55,0.15-33.83
			c-0.07-9.19-6.22-15.21-15.39-15.32c-3.8-0.05-7.6-0.13-11.39,0.03c-2.39,0.11-3.26-0.67-3.2-3.12c0.14-6.07,0.07-12.15,0.04-18.22
			c-0.21-39.35-26.66-69.89-65.54-75.67c-3.18-0.47-6.36-0.69-9.53-0.71c-3.17,0.02-6.35,0.24-9.53,0.71
			c-38.88,5.79-65.34,36.32-65.54,75.67c-0.03,6.07-0.1,12.15,0.04,18.22c0.06,2.45-0.81,3.22-3.2,3.12
			c-3.79-0.17-7.59-0.08-11.39-0.03c-9.17,0.12-15.32,6.14-15.39,15.32c-0.09,11.28-0.41,22.58,0.15,33.83
			c1.66,33.58,16.21,60.5,43.11,80.58c15.19,11.35,32.47,17.62,51.32,19.64c1.01,0.11,2.72,1.09,2.75,1.74
			c0.24,4.5,0.13,9.01,0.13,13.42c-10.97,2.17-15.1,7.26-15.11,18.16c0,3.89,0,7.78,0,12.14c-1.8,0-3.08,0-4.36,0
			c-14.97,0.01-29.93-0.15-44.9,0.09c-8.89,0.14-15.54,4.57-19.5,12.49c-3.91,7.82-3.26,15.61,1.74,22.8
			c4.85,6.99,11.81,9.68,20.21,9.66c23.16-0.04,46.32-0.05,69.48-0.05s46.32,0.01,69.48,0.05c8.4,0.01,15.35-2.68,20.21-9.66
			c4.99-7.19,5.65-14.98,1.74-22.8c-3.96-7.91-10.61-12.35-19.5-12.49c-14.96-0.24-29.93-0.08-44.9-0.09c-1.28,0-2.56,0-4.36,0
			c0-4.36,0-8.25,0-12.14c-0.01-10.89-4.14-15.99-15.11-18.16c0-4.41-0.11-8.92,0.13-13.42C257.72,329.38,259.43,328.4,260.44,328.29z
			M325.18,196.01c0.05-0.77,1.25-2.06,1.98-2.1c3.66-0.22,7.35-0.18,11.01-0.02c0.63,0.03,1.74,1.03,1.76,1.61
			c0.15,4.3,0.08,8.6,0.08,13.34c-4.65,0-8.75,0.1-12.83-0.1c-0.73-0.04-1.95-1.3-2-2.05C324.97,203.15,324.97,199.57,325.18,196.01z
			M309.94,154.7c0.2,4.08,0.1,8.18,0.1,12.7c-10.7,0-20.96,0.13-31.21-0.07c-3.71-0.07-6.39-3.6-6.33-7.37
			c0.07-3.89,2.88-7.18,6.88-7.27c9.51-0.2,19.03-0.14,28.55-0.01C308.64,152.69,309.91,153.97,309.94,154.7z M309.9,225.47
			c-0.03,0.7-1.37,1.89-2.12,1.9c-9.42,0.12-18.85,0.16-28.27-0.01c-3.91-0.07-6.63-3.12-6.79-6.95c-0.16-4.02,2.41-7.52,6.4-7.61
			c10.16-0.23,20.33-0.08,30.88-0.08C310,217.28,310.1,221.38,309.9,225.47z M248.54,96.5c0.49-0.01,0.98,0,1.46,0
			c0.49,0,0.97-0.01,1.46,0c31.22,0.71,51.37,24.59,54.91,41.06c-1.25,0-2.42,0-3.58,0c-7.37,0-14.75-0.03-22.12,0.02
			c-7.35,0.05-13.61,2.65-18.16,8.54c-5.41,7.02-6.57,14.82-2.97,22.96c3.71,8.38,10.45,13,19.56,13.39c8.98,0.38,18,0.13,27,0.15
			c1.25,0,2.5,0,3.71,0c0,5.25,0,9.97,0,14.89c-1.43,0-2.62,0-3.8,0c-8.57,0-17.14-0.12-25.7,0.04c-12.89,0.24-22.68,9.97-22.77,22.41
			c-0.09,12.48,9.93,22.45,22.88,22.65c7.48,0.11,14.96,0.02,22.45,0.02c1.16,0,2.33,0,3.54,0c-2.76,20.83-30.97,41.17-56.41,41.1
			c-25.45,0.07-53.66-20.26-56.41-41.1c1.22,0,2.38,0,3.54,0c7.48,0,14.97,0.09,22.45-0.02c12.96-0.2,22.97-10.17,22.88-22.65
			c-0.09-12.43-9.88-22.17-22.77-22.41c-8.56-0.16-17.13-0.03-25.7-0.04c-1.18,0-2.36,0-3.8,0c0-4.93,0-9.64,0-14.89
			c1.21,0,2.46,0,3.71,0c9-0.02,18.01,0.23,27-0.15c9.11-0.39,15.85-5,19.56-13.39c3.6-8.14,2.45-15.94-2.97-22.96
			c-4.55-5.89-10.81-8.5-18.16-8.54c-7.37-0.05-14.75-0.01-22.12-0.02c-1.17,0-2.34,0-3.58,0C197.17,121.1,217.32,97.21,248.54,96.5z
			M192.21,227.38c-0.75-0.01-2.08-1.21-2.12-1.9c-0.2-4.09-0.1-8.19-0.1-12.73c10.55,0,20.72-0.16,30.88,0.08
			c3.99,0.09,6.56,3.59,6.4,7.61c-0.15,3.83-2.88,6.88-6.79,6.95C211.06,227.53,201.64,227.49,192.21,227.38z M190.06,154.7
			c0.04-0.74,1.3-2.01,2.01-2.02c9.52-0.12,19.04-0.18,28.55,0.01c4,0.08,6.81,3.38,6.88,7.27c0.06,3.77-2.62,7.3-6.33,7.37
			c-10.25,0.2-20.51,0.07-31.21,0.07C189.96,162.89,189.86,158.79,190.06,154.7z M160.07,195.51c0.02-0.58,1.12-1.59,1.76-1.61
			c3.67-0.16,7.35-0.2,11.01,0.02c0.73,0.04,1.93,1.33,1.98,2.1c0.21,3.55,0.21,7.13,0,10.69c-0.05,0.75-1.27,2.02-2,2.05
			c-4.08,0.2-8.18,0.1-12.83,0.1C159.98,204.11,159.92,199.8,160.07,195.51z M320.42,388.81c5.14,0,8.4,3.11,8.31,7.58
			c-0.09,4.36-3.29,7.32-8.25,7.32c-23.5,0.01-46.99-0.01-70.49-0.01c-23.5,0-46.99,0.03-70.49,0.01c-4.95,0-8.16-2.97-8.25-7.32
			c-0.09-4.47,3.17-7.58,8.31-7.58c23.48-0.02,46.95,0,70.42,0.01C273.48,388.81,296.95,388.79,320.42,388.81z M242.68,373.55
			c0-4.88,0-9.58,0-14.74c2.48,0,4.91-0.01,7.32,0c2.41-0.01,4.84,0,7.32,0c0,5.16,0,9.86,0,14.74c-2.47,0-4.88,0-7.32,0
			S245.14,373.55,242.68,373.55z M250,313.94c-40.9-0.05-79.45-29.1-87.96-71.32c-1.21-5.99-1.67-12.13-2.56-18.8
			c5.15,0,9.26,0.05,13.36-0.02c2.4-0.04,2.13,1.63,2.19,3.12c0.52,12.71,4.11,24.51,10.6,35.43c13.75,23.11,38.69,36.36,64.37,36.37
			c25.68-0.02,50.62-13.26,64.37-36.37c6.49-10.92,10.08-22.72,10.6-35.43c0.06-1.49-0.21-3.16,2.19-3.12
			c4.1,0.07,8.21,0.02,13.36,0.02c-0.89,6.67-1.36,12.81-2.56,18.8C329.45,284.84,290.9,313.89,250,313.94z"/>
		</svg>
	</>
}

