import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import axios from 'axios';

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';







export default function VideoOptionsPanelThumbnail({
  
  oldSelector,
  showThumbnailSelector,
  containerRef,

  video,
  selectedReview,

  thumbTime,
  setThumbTime,
  selectedThumbnail,
  setSelectedThumbnail,
  predefThumbnails,
  setThumbFirstime,

  windowHeight,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)



  const [loadingNewThumbnail, setLoadingNewThumbnail] = useState(false);

  const imageNotFoundIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='currentColor'><path d="M33.833 37.833 30.958 35H7.792q-1.167 0-1.98-.812Q5 33.375 5 32.208V9.042L2.417 6.458q-.417-.416-.396-1 .021-.583.437-1 .375-.375.959-.375.583 0 .958.375l31.417 31.417q.416.417.396.979-.021.563-.438.979-.417.417-.958.417-.542 0-.959-.417ZM11.458 27.792H23.75l-3.667-3.709-1.75 2.125-2.791-3.833q-.25-.25-.563-.25-.312 0-.521.25l-3.541 4.25q-.292.375-.084.771.209.396.625.396ZM35 31.167 8.833 5h23.375q1.167 0 1.98.812.812.813.812 1.98Z"/></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)






  async function saveNewThumbnail() {
    setLoadingNewThumbnail(true)

    await axios.post(
      `${process.env.REACT_APP_API_ROUTE}setVideoThumbnail`,
      {
        own_user_token: currentUser?.token, 
        video_id: video?.id, 
        thumbnail: predefThumbnails[selectedThumbnail], 
        make_thumb: true
      }
    )

    var input = {
      own_user_token: currentUser?.token, 

      id: parseInt(video?.id), 
    };

    await createOrUpdateVideo({variables: input})

    setTimeout(() => {
      setSuccessAlertMsg('Thumbnail del tema cambiado correctamente!')

      setLoadingNewThumbnail(false)
    }, "300")
  }








  return (<>

    <Slide direction={(oldSelector >= 4)? "right" : "left"} in={showThumbnailSelector === 4} container={containerRef.current}>
      <div className={`rounded-lg absolute top-0 w-full`}>
        <div className={`p-3 bg-gray-100 border border-gray-300 rounded-lg smallScrollBar overflow-y-auto ${(windowHeight < 750)? 'max-h-[170px]' :  'max-h-[calc(100vh-550px)]'}`}>
          <p className='px-2 text-sm text-gray-500'>Selector de thumbnails</p>

          <div className={`flex space-x-1 mt-2 ${selectedReview?.filename? '' : 'opacity-50 cursor-not-allowed pointer-events-none'}`}>
            {(selectedReview && predefThumbnails) && predefThumbnails?.map((duration, key) => {
              return <div key={'thumb-'+key} className="w-[25%]">
                <Tooltip disableInteractive title="Ver momento de la miniatura" placement="bottom" arrow>
                  <div 
                    className={`group cursor-pointer relative text-gray-50 bg-gray-300 border-2 ${(selectedThumbnail === key)? 'border-blue-500' : 'border-gray-500 hover:border-gray-800'} duration-200 rounded-lg overflow-hidden flex items-center justify-center`} 
                    onMouseUp={()=>{
                      setThumbFirstime(true);
                      setSelectedThumbnail(key)

                      var videoElement = document.getElementById('video');

                      if (duration && videoElement) {
                        videoElement.currentTime = duration
                      }
                    }}
                  >
                    <div className={`w-full h-full bg-black absolute z-20 ${(selectedThumbnail === key)? 'opacity-0' : 'opacity-20 group-hover:opacity-0 '} duration-200`}>
                    </div>

                    <div className='w-full py-[28%] flex justify-center items-center'>
                      {(selectedReview && duration)?
                        <video 
                          id={'thumbnail-'+key}
                          preload="metadata" 
                          className={`w-full absolute z-40 duration-200 ${(selectedThumbnail === key)? '' : 'grayscale-[1] group-hover:grayscale-[0.3]'/* 'blur-[1px] group-hover:blur-[0px]' */}`}
                          onLoadedData={()=> {
                            document.getElementById('thumbnail-'+key).currentTime = duration;
                          }}

                          src={process.env.REACT_APP_API_ROUTE + 'mediaplayer/' + currentCustomer?.token + '/' + ((selectedReview.lowres_version)? 1 : 0) + '/' + selectedReview.filename}
                        />
                      :
                        <div className='w-full absolute z-40 flex justify-center'>{imageNotFoundIcon}</div>
                      }
                    </div>

                    <div className='z-30 absolute'>{loadingIcon}</div>
                  </div>
                </Tooltip>
              </div>
            })}
          </div>


          <div className='w-full flex justify-between items-center space-x-2 mt-3'>
            <div className='flex items-center duration-200 z-40'>
              <div className='small'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    id="timepicker-id"
                    disableOpenPicker={true}
                    ampm={false}
                    openTo="hours"
                    views={['hours', 'minutes', 'seconds']}
                    inputFormat="HH:mm:ss"
                    mask="__:__:__"
                    label="HH:mm:ss"
                    value={thumbTime}
                    onChange={(newValue) => {
                      setThumbFirstime(true);
                      setThumbTime(newValue);

                      var videoElement = document.getElementById('video');
                      var videoThumbElement = document.getElementById('thumbnail-0');

                      if (newValue && newValue.format('HH:mm:ss') !== 'Invalid Date') {
                        var a = newValue.format('HH:mm:ss').split(':');
                        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
                        if (videoElement) { videoElement.currentTime = seconds; }
                        if (videoThumbElement) { videoThumbElement.currentTime = seconds; }
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="w-[90px] bg-white"
                    size="small"
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className='mt-[-4px]'>
              <LoadingButton  loading={loadingNewThumbnail} size="small" loadingPosition="start" variant="contained" startIcon={saveIcon} onMouseUp={saveNewThumbnail} disabled={(!selectedReview?.filename? true : false) && (thumbTime? true : false)}>
                Guardar thumbnail
              </LoadingButton>
            </div>
          </div>


        </div>
      </div>
    </Slide>
      
  </>
  );
}