import gql from "graphql-tag";
export default gql`
  query GetCustomerVideoNamesSimple($own_user_token: String!, $own_customer_token: String!) {
    getCustomerVideos(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      name
      url_name
      production_state
      language
      original_language

      is_sound_media
      search_review_version
      search_media_time
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name
      search_professor_name
    }
  }
`;
