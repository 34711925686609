import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { useMutation } from '@apollo/client'

import VideoFormatIcons from './VideoFormatIcons';
import MenuChangeFormat from './SettingsCells/MenuChangeFormat';

import CreateOrUpdateVideo from '../graphql/mutations/CreateOrUpdateVideo';






export default function VideoChangeFormat({
  
  video,
  category,
  categoryOptions,

}) {


  const { currentUser } = useContext(AuthContext)


  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categoryTMP, setCategoryTMP] = useState(category);



  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)


  


  useEffect(() => {
    if (!anchorEl && open) { setTimeout(() => { setOpen(false) }, 200) }
  }, [open, anchorEl])


  async function saveChange(changeEl) {
    setCategoryTMP(changeEl)
    setAnchorEl(null)

    await createOrUpdateVideo({variables: {
      own_user_token: currentUser?.token,

      id: parseInt(video?.id),
      category_id: (parseInt(changeEl?.id))? parseInt(changeEl?.id) : null,
    }})
  }







  
  return (
    <>
      <button onClick={(el) => { setAnchorEl(el?.currentTarget); setOpen(true) }} className='text-[11px] text-gray-400 flex items-center space-x-1 cursor-pointer active:opacity-50'>
        <div className='scale-[1]'>
          <VideoFormatIcons
            category={categoryTMP}
            note={video?.facturation_note}
          />
        </div>
      </button>

      {(!currentUser?.rol?.includes('user') && open) &&
        <MenuChangeFormat
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl} 
          category={categoryTMP}
          categoryOptions={categoryOptions}
          saveChange={saveChange}
        />
      }
    </>
  );
}