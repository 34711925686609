import gql from "graphql-tag";
export default gql`
  mutation ChangeReviewName(

    $own_user_token: String!, 

    $id: ID!, 
    $filename: String!, 
    
  ) {
    
    changeReviewName(

      own_user_token: $own_user_token, 

      id: $id, 
      filename: $filename 
    
    ) {

      id
      log
      filename
      time
      minutes
      time_added
      minutes_added
      user_id
      video_id
      dateAt
      createdAt
      updatedAt
      
      dateAtFormated
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
