import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateVideo(

    $own_user_token: String!, 

    $id: Int, 
    $name: String, 
    $url_name: String, 
    
    $title: String, 
    $title_change_label: String, 
    $old_title: String, 
    $old_title_changes: Int, 
    $title_eng: String, 
    $title_eng_change_label: String, 
    $title_disabled: Boolean, 
    $chyrons_disabled: Boolean, 
    $end_date_disabled: Boolean, 

    $vimeo_url: String, 
    $project_dir_url: String, 
    $project_dir_url_change_label: String, 
    $production_state: Int, 
    $production_state_change_label: String, 
    $tags: String, 
    $professor_id: Int, 
    $extra_professors: String, 
    $extra_professors_label: String, 
    $category_id: Int, 
    $module_id: Int, 
    $recording_set_ids: String,
    $customer_token: String, 
    $is_active: Boolean, 
    $is_priority: Boolean, 
    $is_part_of: Int, 
    $search_is_part_of_name: String,

    $factura10_product_id: Int,
    $extra_mark_facturation: Int,
    $extra_facturation_correction: Int,
    $extra_mark_facturation_chyron: Int,
    $extra_facturation_correction_chyron: Int,
    $manual_number_of_words: Int,

    $factura10_date: Date,
    $factura10_text: String,
    $factura10_facturated_date: Date,
    $factura10_facturated_text: String,

    $language: String, 
    $original_language: String, 
    $has_subtitulation: Int, 
    $has_transcription: Int, 
    $has_translation: Boolean, 
    $is_translation_of: Int, 
    $number_of_words: Int, 

    $extra_marks_json: String, 
    $extra_languages_json: String, 
    $facturation_note: String, 

    $state: Int,
    $state_change_label: String, 
    $motion_state: Int,
    $motion_state_change_label: String,
    $revision_state: Int, 
    $revision_state_change_label: String,
    $trad_done: Boolean, 
    $trad_done_change_label: String,

    $chyron_states: String, 
    $workflow_done: Boolean, 

    $recordingDate: Date, 
    $publicationEndDate: Date, 
    $endDate: Date, 

    $setting_remove_correction: Boolean, 
  
  ) {
    createOrUpdateVideo(input: { 

      own_user_token: $own_user_token,

      id: $id,
      name: $name,
      url_name: $url_name,

      title: $title,
      title_change_label: $title_change_label,
      old_title: $old_title,
      old_title_changes: $old_title_changes,
      title_eng: $title_eng,
      title_eng_change_label: $title_eng_change_label,
      title_disabled: $title_disabled,
      chyrons_disabled: $chyrons_disabled,
      end_date_disabled: $end_date_disabled,

      vimeo_url: $vimeo_url,
      project_dir_url: $project_dir_url,
      project_dir_url_change_label: $project_dir_url_change_label,
      production_state: $production_state,
      production_state_change_label: $production_state_change_label,
      tags: $tags,
      professor_id: $professor_id,
      extra_professors: $extra_professors,
      extra_professors_label: $extra_professors_label,
      category_id: $category_id,
      module_id: $module_id,
      recording_set_ids: $recording_set_ids,
      customer_token: $customer_token,
      is_active: $is_active,
      is_priority: $is_priority,
      is_part_of: $is_part_of,
      search_is_part_of_name: $search_is_part_of_name,
      
      factura10_product_id: $factura10_product_id,
      extra_mark_facturation: $extra_mark_facturation,
      extra_facturation_correction: $extra_facturation_correction,
      extra_mark_facturation_chyron: $extra_mark_facturation_chyron,
      extra_facturation_correction_chyron: $extra_facturation_correction_chyron,
      manual_number_of_words: $manual_number_of_words,

      factura10_date: $factura10_date,
      factura10_text: $factura10_text,
      factura10_facturated_date: $factura10_facturated_date,
      factura10_facturated_text: $factura10_facturated_text,

      language: $language,
      original_language: $original_language,
      has_subtitulation: $has_subtitulation,
      has_transcription: $has_transcription,
      has_translation: $has_translation,
      is_translation_of: $is_translation_of,
      number_of_words: $number_of_words,

      extra_marks_json: $extra_marks_json,
      extra_languages_json: $extra_languages_json,
      facturation_note: $facturation_note,

      state: $state,
      state_change_label: $state_change_label,
      motion_state: $motion_state,
      motion_state_change_label: $motion_state_change_label,
      revision_state: $revision_state,
      revision_state_change_label: $revision_state_change_label,
      trad_done: $trad_done,
      trad_done_change_label: $trad_done_change_label,

      chyron_states: $chyron_states,
      workflow_done: $workflow_done,

      recordingDate: $recordingDate,
      publicationEndDate: $publicationEndDate,
      endDate: $endDate,

      setting_remove_correction: $setting_remove_correction,
      
    }) {

      id
      name
      url_name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled
      end_date_disabled

      vimeo_url
      project_dir_url
      project_dir_url_change_label
      thumbnail
      thumbnail_small
      production_state
      production_state_change_label
      tags
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      folder_id
      recording_set_ids
      customer_token
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      download_video
      download_video_change_label
      is_sound_media

      search_review_version
      search_media_time
      search_review_date
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name
      search_professor_name
      search_production_state_change_history
      search_is_part_of_name
      search_workflow_bills_state
      
      factura10_product_id
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words

      factura10_date
      factura10_text
      factura10_facturated_date
      factura10_facturated_text

      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label

      chyron_states
      workflow_done

      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      extra_marks_json
      extra_languages_json
      facturation_note

      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt

      professor {
        id
        name
        professor_code
        vinculated_to_id
        positions
      }

      translations {
        id
        name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }

      finalVideo {
        id
        name
        url_name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }

      productionState {
        id
        name
        color
        position
        customer_token
        
        done
        ended_marks
        trigger_notifications
        trigger_publication
        trigger_start_reviewing
        hidden
      }
      
      videoGroup

      searchReviewDateFormated
      isPriorityDateFormated
      recordingDateFormated
      publicationEndDateFormated
      endDateFormated
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
