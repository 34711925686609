import { useState, useEffect } from "react";
import { Slide } from "@mui/material";

import SignInBox from "./signInBoxes/signInBox";
import RecoverPasswordBox from "./signInBoxes/recoverPasswordBox";
import RecoverPasswordCodeBox from "./signInBoxes/recoverPasswordCodeBox";
import RecoverPasswordNewBox from "./signInBoxes/recoverPasswordNewBox";







const SignIn = ({ deffaultTab }) => {




  const [recoverEmail, setRecoverEmail] = useState(null);
  const [recoverCode, setRecoverCode] = useState(null);
  const [tabHistory, setTabHistory] = useState([0, 0]);

  
  useEffect(() => {    
    setTabHistory([tabHistory[1], deffaultTab]);
  }, [deffaultTab])





  return (
    <div className="w-screen h-screen relative overflow-hidden">

      <div className={`w-full absolute duration-300 ${(!tabHistory[1])? '' : 'opacity-0 pointer-events-none'}`}>
        <Slide direction="right" in={(!tabHistory[1])? true : false} timeout={300}>
          <div className="w-full">
            {(tabHistory[1] === 0 || tabHistory[1] === 1) &&
              <SignInBox
                tab={tabHistory[1]}
              />
            }
          </div>
        </Slide>
      </div>

      <div className={`w-full absolute duration-300 ${(tabHistory[1] === 1)? '' : 'opacity-0 pointer-events-none'}`}>
        <Slide direction={(!tabHistory[0] || !tabHistory[1])? "left" : "right"} in={(tabHistory[1] === 1)? true : false} timeout={300}>
          <div className="w-full">
            {(tabHistory[1] === 0 || tabHistory[1] === 1 || tabHistory[1] === 2) &&
              <RecoverPasswordBox
                tab={tabHistory[1]}
                recoverEmail={recoverEmail}
                setRecoverEmail={setRecoverEmail}
              />
            }
          </div>
        </Slide>
      </div>

      <div className={`w-full absolute duration-300 ${(tabHistory[1] === 2)? '' : 'opacity-0 pointer-events-none'}`}>
        <Slide direction={(tabHistory[1] !== 1 || tabHistory[2] !== 1)? "left" : "right"} in={(tabHistory[1] === 2)? true : false} timeout={300}>
          <div className="w-full">
            {(tabHistory[1] === 1 || tabHistory[1] === 2 || tabHistory[1] === 3) &&
              <RecoverPasswordCodeBox
                tab={tabHistory[1]}
                recoverEmail={recoverEmail}
                setRecoverEmail={setRecoverEmail}
                recoverCode={recoverCode}
                setRecoverCode={setRecoverCode}
              />
            }
          </div>
        </Slide>
      </div>

      <div className={`w-full absolute duration-300 ${(tabHistory[1] === 3)? '' : 'opacity-0 pointer-events-none'}`}>
        <Slide direction="left" in={(tabHistory[1] === 3)? true : false} timeout={300}>
          <div className="w-full">
            {(tabHistory[1] === 2 || tabHistory[1] === 3) &&
              <RecoverPasswordNewBox
                tab={tabHistory[1]}
                recoverEmail={recoverEmail}
                setRecoverEmail={setRecoverEmail}
                recoverCode={recoverCode}
                setRecoverCode={setRecoverCode}
              />
            }
          </div>
        </Slide>
      </div>

    </div>
  )
}

export default SignIn
