import gql from "graphql-tag";

export default gql`
  mutation UpdateVideoState(
    
    $own_user_token: String!,
    $id: Int,
    
    $state: Int,
    $state_change_label: String,
    $motion_state: Int,
    $motion_state_change_label: String,
    $revision_state: Int,
    $revision_state_change_label: String,
    $trad_done: Boolean,
    $trad_done_change_label: String,

    $chyron_states: String,

    $extra_mark_facturation: Int
    $extra_facturation_correction: Int
    $extra_mark_facturation_chyron: Int
    $extra_facturation_correction_chyron: Int
    $manual_number_of_words: Int

  ) {
    createOrUpdateVideo(input: { 
      
      own_user_token: $own_user_token,
      id: $id, 

      state: $state,
      state_change_label: $state_change_label,
      motion_state: $motion_state,
      motion_state_change_label: $motion_state_change_label,
      revision_state: $revision_state,
      revision_state_change_label: $revision_state_change_label,
      trad_done: $trad_done,
      trad_done_change_label: $trad_done_change_label,

      chyron_states: $chyron_states,

      extra_mark_facturation: $extra_mark_facturation
      extra_facturation_correction: $extra_facturation_correction
      extra_mark_facturation_chyron: $extra_mark_facturation_chyron
      extra_facturation_correction_chyron: $extra_facturation_correction_chyron
      manual_number_of_words: $manual_number_of_words
      
    }) {
      
      id
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label

      chyron_states

      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words
      
    }
  }
`;
