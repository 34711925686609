import { useState, useEffect } from 'react'
import axios from 'axios'


import Tooltip from '@mui/material/Tooltip';








const MarkQuarterTag = ({
  
  object,
  type,
  motion_state,
  language,

}) => {


  const [date, setDate] = useState(null);
  const [quart, setQuart] = useState(null);


  useEffect( () => {
    if (object?.id && motion_state > 1) {
      axios.post(`${process.env.REACT_APP_API_ROUTE}getCreatedStateDate`, { id: object?.id, type: type, language: language }).then( async (response) => { 
        var dateTMP = (response?.data && new Date(response?.data))? new Date(response?.data) : null;
        const dateMMdd = (dateTMP && dateTMP.getFullYear() > 2023)? (((dateTMP.getMonth() + 1 <= 9)? '0' + (dateTMP.getMonth() + 1) : dateTMP.getMonth() + 1) + '/' + ((dateTMP.getDate() <= 9)? '0' + dateTMP.getDate() : dateTMP.getDate())) : null;

        var quartTMP = (dateMMdd >= '01/01' && dateMMdd <= '03/31')? 'Q1'
        : (dateMMdd >= '04/01' && dateMMdd <= '06/31')? 'Q2'
        : (dateMMdd >= '07/01' && dateMMdd <= '09/31')? 'Q3'
        : (dateMMdd >= '10/01' && dateMMdd <= '12/31')? 'Q4'
        : (dateTMP || motion_state > 1)? 'M2'
        : null

        setDate(dateTMP)
        setQuart(quartTMP)
      })
    } else {
      setDate(null)
      setQuart(null)
    }
  }, [object])


  return <>
    {(quart)?
      <Tooltip disableInteractive title={(date)? date?.getDate() + '/' + (date?.getMonth()+1) + '/' + date?.getFullYear() : null} placement="bottom" arrow>
        <div className={`
          px-2 py-[3px] rounded flex space-x-2 cursor-pointer bg-opacity-20 hover:bg-opacity-30 duration-200 whitespace-nowrap
          ${
            (quart === 'Q1')? 'bg-pink-500' 
            : (quart === 'Q2')? 'bg-yellow-500' 
            : (quart === 'Q3')? 'bg-amber-700' 
            : (quart === 'Q4')? 'bg-blue-500' 
            : 'bg-gray-500'
          }
        `}>
          <p>{quart}</p>
          <p>-</p>
          <p>{(date)? date?.getFullYear() : '2023'}</p>
        </div>
      </Tooltip>
    : null }
  </>
}

export default MarkQuarterTag
