import { useState, useContext } from 'react'
import { FuncContext } from '../contexts/FuncContext';
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types';

import CreateVideoLocalizations from './../graphql/mutations/CreateVideoLocalizations'

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';






const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalVideoLocalizations({ open, setOpen, videos, setVideos, getVideos, setAnyChecked, anyChecked }) {




  
  const { currentUser, languageList } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { checkAllFunc } = useContext(FuncContext)
  





  /* Video obj vars */



  /* Loading vars */
  const [loading, setLoading] = useState(false);


  /* flags */
  const arrowIcon = <svg className='scale-[1.2]' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M630-444H228.049q-15.321 0-25.685-10.289Q192-464.579 192-479.789 192-495 202.364-505.5q10.364-10.5 25.685-10.5H630l-80-80q-11-11-11-25.5t10.522-25.5Q561-658 575.5-658q14.5 0 25.5 11l142.333 142.333Q748-500 750.5-493.7q2.5 6.3 2.5 13.5t-2.5 13.5q-2.5 6.3-7.5 11.7L601-313q-11 11-25 10.5T550.522-314Q540-325 540-339.5t11-25.5l79-79Z"/></svg>





  const [createVideoLocalizations] = useMutation(CreateVideoLocalizations)





  
  async function saveChangesFunc() {
    setLoading(true)
   

    for (var i in videos) {
      var videoTMP = {...videos[i]};
      if (videoTMP?.id && videoTMP?.new_language) {
        videoTMP.own_user_token = currentUser?.token;
        await createVideoLocalizations({variables: videoTMP})
      }
    }


    setTimeout(async () => {
      await getVideos()
      
      setAnyChecked(checkAllFunc(anyChecked, false))

      setOpen(false)
      setLoading(false)
      setSuccessAlertMsg('Localizaciones creadas correctamente!')
    }, "300")
  }




  return (
    <div>
      <BootstrapDialog
        onClose={()=>setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>setOpen(false)} style={{ minWidth: 700 }}>
          <p className='text-3xl'>Creando {videos?.length} {(videos?.length === 1)? 'localización' : 'localizaciones'}</p>
        </BootstrapDialogTitle>


        <DialogContent dividers /* style={{ paddingTop: 0 }} */>
          {(videos?.length)?
            <div className='w-full flex flex-wrap space-y-3 min-w-[1000px] max-h-[800px] overflow-y-auto py-2'>
              {videos?.map((video, key) => {

                                
                const langObjTMP = (video?.language === 'esp')? languageList[1] : languageList[0]
                const nameTMP = video?.name?.split('_')[0] + langObjTMP?.file_key;
                

                if (!videos[key].new_name) { 
                  var videosTMP = [...videos]
                  videosTMP[key].new_name = nameTMP;
                  setVideos(videosTMP)
                }


                return <div className='w-full flex items-center space-x-2 text-sm text-gray-700 whitespace-nowrap'>

                  <TextField 
                    defaultValue={(video?.name)? video?.name : ''}
                    id="title"
                    label="Título del vídeo original"
                    variant="outlined"
                    className='w-full mui-bg-gray-100 pointer-events-none'
                    sx={{ "& .MuiInputBase-root": { height: 34 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                    InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                    InputProps={{ readOnly: true }}
                  />

                  <TextField
                    select
                    value={video?.language}
                    label="Idioma del vídeo original"
                    className={`w-full flex-grow mui-bg-gray-100 pointer-events-none`}
                    InputProps={{ readOnly: true, sx: { height: "33.5px", overflow: 'hidden', minWidth: 150 } }}
                    sx={{ maxWidth: "170px" }}
                  >
                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem> 
                    })}

                    <MenuItem value={'other'}>Otro</MenuItem>
                  </TextField>




                  <p className='text-gray-400'>{arrowIcon}</p>



                        
                  <TextField 
                    value={(videos[key].new_name)? videos[key].new_name : (nameTMP)? nameTMP : ''}
                    onChange={event => {
                      var videosTMP = [...videos]
                      videosTMP[key].new_name = event.target.value;
                      setVideos(videosTMP)
                    }}
                    id="newname"
                    label="Título de la localización"
                    variant="outlined"
                    className='w-full mui-bg-gray-100'
                    sx={{ "& .MuiInputBase-root": { height: 34 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                    InputLabelProps={{ sx: { paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                  />


                  <TextField
                    select
                    value={(video?.new_language)? video?.new_language : ((video?.language === 'esp')? 'eng' : 'esp')}
                    onChange={event => {
                      const langObj = (event.target.value)? languageList?.filter((el) => el?.abbreviated_name === event.target.value)[0] : languageList[0]
                      var videosTMP = [...videos]
                      videosTMP[key].new_name = ((videosTMP[key].new_name)? videosTMP[key].new_name : video?.name)?.split('_')[0] + langObj?.file_key;
                      videosTMP[key].new_language = event.target.value;
                      setVideos(videosTMP)
                    }}
                    label="Idioma de la localización"
                    className={`w-full flex-grow mui-bg-gray-100`}
                    InputProps={{ sx: { height: "33.5px", overflow: 'hidden', minWidth: 150 } }}
                    sx={{ maxWidth: "170px" }}
                    disabled={loading}
                  >
                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem> 
                    })}

                    <MenuItem value={'other'}>Otro</MenuItem>
                  </TextField>
                </div>
              })}
            </div>
          :
            <p>Sin vídeos seleccionados</p>
          }
        </DialogContent>


        <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button
              variant="contained"
              size='small'
              color="error"
              startIcon={<ClearIcon />}
              onClick={()=>setOpen(false)}
            >
              Cancelar
            </Button>

            <LoadingButton 
              loading={loading}
              loadingPosition="start"
              variant="contained"
              size='small'
              autoFocus
              startIcon={<SaveIcon />}
              onClick={()=> saveChangesFunc()}
            >
              Crear
            </LoadingButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}