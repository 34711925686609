import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';







export default function MenuVideoOptions({
  anchorEl,
  setAnchorEl,
  video,
  getVideo,
  selectedReview,

  reviewFilter,
  setReviewFilter,
  showMarksOnScreen,
  setShowMarksOnScreen,
  isPrioritary,
  
  showReviewEdit,
  setShowReviewEdit,
  
  removeLatestReview,
  removeDownloadVideo,
  loadingDeleteReview,
  reviewsNum,
}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)


  const [loadingPriority, setLoadingPriority] = useState(false);


  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.896 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V5.125q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h3.375q0-.354.26-.615.261-.26.615-.26h3.187q.355 0 .615.26.26.261.26.615h3.375q.355 0 .615.26.26.261.26.615t-.26.615q-.26.26-.615.26V15.75q0 .729-.51 1.24-.511.51-1.24.51Zm1.542-4.375q0 .354.26.615.26.26.614.26.355 0 .615-.26.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.615-.26-.354 0-.614.26-.26.261-.26.615Zm3.333 0q0 .354.26.615.261.26.615.26t.614-.26q.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.614-.26t-.615.26q-.26.261-.26.615Z"/></svg>;
  const priorityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M479.876 936Q446 936 422 911.876t-24-58Q398 820 422.124 796t58-24Q514 772 538 796.124t24 58Q562 888 537.876 912t-58 24Zm0-240Q446 696 422 671.912 398 647.825 398 614V298q0-33.825 24.124-57.912 24.124-24.088 58-24.088Q514 216 538 240.088q24 24.087 24 57.912v316q0 33.825-24.124 57.912-24.124 24.088-58 24.088Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const eyelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M481.118 738Q557 738 610 683.882q53-54.117 53-130Q663 478 609.882 425q-53.117-53-129-53Q405 372 351 425.118q-54 53.117-54 129 0 76.715 53.701 130.299Q404.402 738 481.118 738ZM481 663q-45.417 0-77.208-31.792Q372 599.417 372 554q0-44.583 31.792-75.792Q435.583 447 481 447q44.583 0 75.792 31.208Q588 509.417 588 554q0 45.417-31.208 77.208Q525.583 663 481 663Zm-1 193q-140 0-255-74T52 583q-2-5-3.5-12.5T47 555q0-8 1.5-15.5T52 527q58-125 173.312-198.5Q340.623 255 480 255q139.377 0 254.688 73.5Q850 402 908 527q2 5 3.5 12.5T913 555q0 8-1.5 15.5T908 583q-58 125-173 199t-255 74Z"/></svg>
  const cancelEyelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M776 751 649 625q6-14 9.5-32t3.5-38q0-76-53-129t-129-53q-18 0-35.5 3.5T410 386l-98-98q41-17 82-24.5t86-7.5q143 0 257 74.5T908 527q2 5 3.5 12.5T913 555q0 8-1.5 15.5T908 583q-23 50-56.5 92.5T776 751Zm-18 220L621 835q-35 11-70 16t-71 5q-143 0-257-75.5T52 583q-2-6-3.5-13T47 555q0-8 1-15.5t4-12.5q20-43 48.5-80.5T164 378l-80-81q-12-12-12.5-29.5T84 238q12-12 29.5-12t29.5 12l674 675q12 12 12 29t-12 29q-12 12-29.5 12T758 971ZM480 737q10 0 20-1t19-3L303 517q-2 10-3.5 19t-1.5 19q0 76 53 129t129 53Zm108-173L471 447q51-5 86 32t31 85Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const filterIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M456.175 864Q446 864 439 857.1t-7-17.1V613L197 327q-9-12-2.745-25.5Q200.511 288 216 288h528q15.489 0 21.745 13.5Q772 315 763 327L528 613v227q0 10.2-6.883 17.1-6.883 6.9-17.059 6.9h-47.883Z"/></svg>;
  const removeFilterIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M756 970 560 774v42q0 33-23.5 56.5T480 896q-33 0-56.5-23.5T400 816V614L85 299q-12-12-12-29t12-29q12-12 29.5-12t29.5 12l671 671q12 12 12 29t-12 29q-12 12-29.5 12T756 970ZM595 572 279 256h481q25 0 36 22t-4 42L595 572Z"/></svg>;


  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  useEffect(() => {    
    if (loadingPriority) {
      setTimeout(() => {
        setLoadingPriority(null)
      }, 1000)
    }
  }, [loadingPriority])


  async function setPriorityFunc(priority) {
    setLoadingPriority(true)

    var input = {
      own_user_token: currentUser? currentUser.token : null, 

      id: parseInt(video?.id), 
      is_priority: priority,
    };

    await createOrUpdateVideo({variables: input})
    
    setTimeout(async () => {
      await getVideo()
      setLoadingPriority(false)
    }, "500")
  }








  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl? true : false}
      onClose={() => setAnchorEl(null)}
      onClick={() => setAnchorEl(null)}
      PaperProps={{
        elevation: 0,
        sx: {
          paddingBlock: 1,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >


      <div className={`mx-2 ${currentUser?.rol === 'admin'? 'mb-2' : ''}`}>
        <MenuItem onMouseUp={() => { setReviewFilter(((reviewFilter)? 0 : 1)); localStorage.setItem(currentCustomer?.token+'-review-version-filter', ((reviewFilter)? 0 : 1))} } style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
          <ListItemIcon>
            {(reviewFilter)? filterIcon : removeFilterIcon}
          </ListItemIcon>
          <div className='text-sm'>{(reviewFilter)? 'Solo marcas de esta revisión' : 'Marcas de todas las revisiones'}</div>
        </MenuItem>

        <MenuItem onMouseUp={() => setShowMarksOnScreen(!showMarksOnScreen)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
          <ListItemIcon>
            {(showMarksOnScreen)? eyelIcon : cancelEyelIcon}
          </ListItemIcon>
          <div className='text-sm'>{(showMarksOnScreen)? 'Con marcas en pantalla' : 'Sin marcas en pantalla'}</div>
        </MenuItem>

        <MenuItem onMouseUp={() => { setLoadingPriority(true); setPriorityFunc(!isPrioritary); setAnchorEl(null); }} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
          <ListItemIcon>
            {(loadingPriority)? loadingIcon : (isPrioritary)? cancelIcon : priorityIcon}
          </ListItemIcon>
          <div className='text-sm'>{(loadingPriority)? 'Cambiando prioridad' : (isPrioritary)? 'Desmarcar como prioritario' : 'Marcar como prioritario'}</div>
        </MenuItem>
      </div>



      {(currentUser?.rol === 'admin') && <Divider /> }


      {(currentUser?.rol === 'admin') && 
        <div className='mx-2 mt-2'>
          {(reviewsNum)?
            <MenuItem onMouseUp={() => setShowReviewEdit(!showReviewEdit)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
              <ListItemIcon>
                {(showReviewEdit)? cancelIcon : editIcon}
              </ListItemIcon>
              <div className='text-sm'>{(showReviewEdit)? 'Cancelar edición de la review' : 'Editar nombre de la revisión'}</div>
            </MenuItem>
          : null}

          {(reviewsNum && selectedReview)? <>
            <MenuItem onMouseUp={() => removeLatestReview(selectedReview?.id, false)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
              <ListItemIcon>
                {(loadingDeleteReview)? loadingIcon : deleteIcon}
              </ListItemIcon>
              <div className='text-sm'>Eliminar revisión seleccionada</div>
            </MenuItem>

            {(selectedReview?.lowres_version) &&
              <MenuItem onMouseUp={() => removeLatestReview(selectedReview?.id, true)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                <ListItemIcon>
                  {(loadingDeleteReview)? loadingIcon : deleteIcon}
                </ListItemIcon>
                <div className='text-sm'>Eliminar versión de baja resolución</div>
              </MenuItem>
            }

            {(video?.download_video) &&
              <MenuItem onMouseUp={() => removeDownloadVideo(selectedReview?.id, true)} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 6 }}>
                <ListItemIcon>
                  {(loadingDeleteReview)? loadingIcon : deleteIcon}
                </ListItemIcon>
                <div className='text-sm'>Eliminar vídeo de descarga</div>
              </MenuItem>
            }
          </> : null}
        </div>
      }



    </Menu>
  );
}