import { useState, useEffect, useContext } from 'react'
import ReactQuillSimpleImput from './ReactQuillSimpleImput';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';




export default function VideoMarkInputsFacturation({

  mark, 
  markTypeObj, 
  saveMarkEdit, 

  isTranslating,

  extraFacturation,
  setExtraFacturation,
  extraCorrectionFacturation,
  setExtraCorrectionFacturation,
  manualNumberOfWords,
  setManualNumberOfWords,

  newPrice,
  setNewPrice,
  newPriceOBS,
  setNewPriceOBS,
  markDifficulty,
  setMarkDifficulty,
  markDifficultyObj,

  difficulties,

}) {
  

  












  return <div>
    <div className='flex space-x-2 items-center whitespace-nowrap w-full pt-[30px] pb-2 text-sm text-gray-500'>
      <p className='pl-2'>Facturación personalizada</p>
      <hr className='w-full border-gray-300' />
    </div>
    

    <div className='space-y-3 p-2 bg-gray-400 bg-opacity-20 rounded'>
      {(markTypeObj?.type?.includes('special') || markDifficultyObj?.name === 'Otro') && 
        <ReactQuillSimpleImput 
          id={"focus-editing-input-"+mark?.id} 
          label={"Observaciones del nuevo precio"}
          placeholder='Escribe las observaciones del nuevo precio...'

          readOnly={isTranslating}
          value={newPriceOBS}
          onChange={(value) => setNewPriceOBS(value)} 
          onKeyDown={(event) => {
            if((event.code === 'Enter' && event.ctrlKey) && (newPriceOBS && newPriceOBS?.replaceAll(' ', '').replaceAll('\n', '') !=='')) {
              saveMarkEdit()
            }
          }}
        />
      }

      <div className='flex items-end justify-end space-x-2 w-full'>
        <TextField
          select
          value={(parseInt(markDifficulty))? parseInt(markDifficulty) : 1}
          onChange={(event)=>{ setMarkDifficulty(event.target.value) }}
          label="Facturación del motion"
          
          className='w-full mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 32 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-10px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}      
        >
          {difficulties?.map((element) => {
            return <MenuItem value={parseInt(element?.id)} key={element?.name}>
              <div className='w-full flex justify-start items-center space-x-2'>
                <p>{element?.name}</p>
              </div>
            </MenuItem>
          })}
        </TextField>


        {(markDifficultyObj?.name === 'Otro') && 
          <div className='flex items-center bg-gray-300 rounded w-full'>
            <TextField
              value={newPrice}
              onChange={event => {
                setNewPrice((parseFloat(event.target.value) || parseInt(event.target.value) === 0)? parseFloat(parseFloat(event.target.value).toFixed(2)) : null);
              }}
              label="Precio personalizado"
              variant="outlined"
              type='number'

              className='w-full mui-bg-white'
              sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 32 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
              InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-10px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}      
              inputProps={{ step: "0.1" }}
            />
            <p className='px-2 text-gray-600 pointer-events-none'>€</p>
          </div>
        }

        <TextField
          value={(extraFacturation)? extraFacturation : 0}
          onChange={event => {
            setExtraFacturation((parseInt(event.target.value))? parseInt(event.target.value) : 0);
          }}
          label="Unidades extra"
          variant="outlined"
          type='number'

          className='w-full mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 32 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-10px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}      
          inputProps={{ step: "1", min: 0 }}
        />

        <TextField
          value={(extraCorrectionFacturation)? extraCorrectionFacturation : 0}
          onChange={event => {
            setExtraCorrectionFacturation((parseInt(event.target.value))? parseInt(event.target.value) : 0);
          }}
          label="Unidades parciales"
          variant="outlined"
          type='number'

          className='w-full mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 32 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-10px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}      
          inputProps={{ step: "1", min: 0 }}
        />

        <TextField
          value={(manualNumberOfWords)? manualNumberOfWords : 0}
          onChange={event => {
            setManualNumberOfWords((parseInt(event.target.value))? parseInt(event.target.value) : 0);
          }}
          label="Núm. Palabras revisadas"
          variant="outlined"
          type='number'

          className='w-full mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 32 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-10px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}      
          inputProps={{ step: "1", min: 0 }}
        />
      </div>
    </div>
  </div>
}