import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useParams } from "react-router-dom";
import { FuncContext } from '../contexts/FuncContext'
import { useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import VideoHomeEditModeListInputs from './VideoHomeEditModeListInputs';
import VideoFormatIcons from './VideoFormatIcons';
import SmallVideoSettingsList from './SmallVideoSettingsList';
import NumberOfMarksList from './NumberOfMarksList';
import RowHistoryMenu from '../components/CustomTables/Components/RowHistoryMenu.jsx';

import GetBillsByF10IDs from '../graphql/queries/GetBillsByF10IDs'

import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { LayoutContext } from '../contexts/LayoutContext.jsx';









const VideoHomeBaseListTableCell = ({

  listKey,
  column,
  video,
  settingsRelations,
  clicksoundUserNames,
  value,
  
  orderFilter,
  endFilterDate,
  startFilterDate,
  moduleFilter,
  isSubVideo,

  record,
  danger,
  warning,
  motionerWarning,
  extraMarks,
  categoryTMP,

  dataVideoNames,
  categoryOptions,

  setSelectedVideo,
  setOpenVideoAdminEitor,

  editMode,
  showFullEditMode,
  
  lastChecked,
  setLastChecked,
  setAnyChecked,

  canBeAddedToFolder,
  addedVideosToFolderIDs,
  addVideoToFolderFunc,

}) => {




  const { name } = useParams()
  const { currentUser, currentCustomer, customerPath } = useContext(AuthContext)
  const { anyCheckbox, checkInBetweenDatesFunc, checksFunc, checkStatePathPointFunc, remove_tags } = useContext(FuncContext)
  const { setErrorAlertMsg } = useContext(LayoutContext)


  var defCellHeight = (isSubVideo && !showFullEditMode)? 45 : 110


  const [anchorElFactura10, setAnchorElFactura10] = useState(null);
  const [cellHeight, setCellHeight] = useState(defCellHeight);

  const [prodStateHistory, setProdStateHistory] = useState((video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history) : null);
  const [history, setHistory] = useState(null);
  const [extraHistory, setExtraHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  


 
  const loadIcon = <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
  const euroIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M576-144q-109 0-196-62.5T258-372h-78q-15 0-25.5-10.5T144-408q0-15 10.5-25.5T180-444h62q-1-8-1.5-17t-.5-18q0-9 .5-18.5T242-516h-62q-15 0-25.5-10.5T144-552q0-15 10.5-25.5T180-588h78q35-103 122-165.5T576-816q58 0 112.5 19t99.5 56q12 10 13 25.5T791-689q-11 11-26 10.5t-27-9.5q-35-27-76-41.5T576-744q-78 0-143.5 42T335-588h205q15 0 25.5 10.5T576-552q0 15-10.5 25.5T540-516H314q-1 8-1.5 17t-.5 18q0 9 .5 18.5T314-444h226q15 0 25.5 10.5T576-408q0 15-10.5 25.5T540-372H335q32 72 97.5 114T576-216q45 0 86-14.5t76-41.5q12-9 26.5-9.5T790-271q11 11 10.5 26.5T788-219q-45 37-99.5 56T576-144Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const fromAnotherIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M320-160q-117 0-198.5-81.5T40-440q0-107 70.5-186.5T287-718l-36-38q-11-12-11-28t11-27q12-12 29-12t28 11l104 104q12 12 12 28t-12 28L309-549q-12 12-28.5 12T252-549q-11-12-11-28.5t11-27.5l31-31q-71 14-117 69t-46 127q0 83 58.5 141.5T320-240h80q17 0 28.5 11.5T440-200q0 17-11.5 28.5T400-160h-80Zm240-360q-17 0-28.5-11.5T520-560v-200q0-17 11.5-28.5T560-800h280q17 0 28.5 11.5T880-760v200q0 17-11.5 28.5T840-520H560Zm0 360q-17 0-28.5-11.5T520-200v-200q0-17 11.5-28.5T560-440h280q17 0 28.5 11.5T880-400v200q0 17-11.5 28.5T840-160H560Zm40-80h200v-120H600v120Z"/></svg>
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const publicIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480.28-96Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30ZM432-172v-68q-20 0-34-14.1T384-288v-48L175-545q-4 19-5.5 35t-1.5 30q0 115 74.5 203T432-172Zm288-109q18-21 31.5-44t22.36-48.55q8.86-25.56 13.5-52.5Q792-453 792-480q0-94.61-52-172.8Q688-731 600-768v24q0 29.7-21.15 50.85Q557.7-672 528-672h-96v48q0 20.4-13.8 34.2Q404.4-576 384-576h-48v96h240q20.4 0 34.2 13.8Q624-452.4 624-432v96h41q23 0 39 16t16 39Z"/></svg>

  const audioIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40" fill='currentColor'><path d="M773.334-481q0-90.334-49.667-163.167T592.667-751q-13-5.667-18.667-17.834-5.667-12.166-.667-24.5 5.334-13.333 18.834-18.666t27.499.333Q719.333-770 779.667-680.5 840-591 840-481t-60.333 199.5Q719.333-192 619.666-150.333q-13.999 5.666-27.499.333-13.5-5.333-18.834-18.666-5-12.334.667-24.5 5.667-12.167 18.667-17.834 81.333-34 131-106.833T773.334-481ZM280-360H153.333Q139-360 129.5-369.5q-9.5-9.5-9.5-23.833v-173.334q0-14.333 9.5-23.833 9.5-9.5 23.833-9.5H280l143.334-143.334Q439-759 459.5-750.5 480-742 480-719.667v479.334Q480-218 459.5-209.5t-36.166-7.166L280-360Zm380-120q0 48.667-23.667 89.167t-65.334 63.167q-8.666 4.666-16.499-.5-7.834-5.167-7.834-14.5v-276.001q0-9.333 7.834-14.5 7.833-5.167 16.499-.5 41.667 23 65.334 64.333Q660-528 660-480Z"/></svg>
  const imageNotFoundIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='currentColor'><path d="M33.833 37.833 30.958 35H7.792q-1.167 0-1.98-.812Q5 33.375 5 32.208V9.042L2.417 6.458q-.417-.416-.396-1 .021-.583.437-1 .375-.375.959-.375.583 0 .958.375l31.417 31.417q.416.417.396.979-.021.563-.438.979-.417.417-.958.417-.542 0-.959-.417ZM11.458 27.792H23.75l-3.667-3.709-1.75 2.125-2.791-3.833q-.25-.25-.563-.25-.312 0-.521.25l-3.541 4.25q-.292.375-.084.771.209.396.625.396ZM35 31.167 8.833 5h23.375q1.167 0 1.98.812.812.813.812 1.98Z"/></svg>;

  const addedToFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm400-240v40q0 17 11.5 28.5T600-320q17 0 28.5-11.5T640-360v-40h40q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480h-40v-40q0-17-11.5-28.5T600-560q-17 0-28.5 11.5T560-520v40h-40q-17 0-28.5 11.5T480-440q0 17 11.5 28.5T520-400h40Z"/></svg>
  const notAddedToFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"/></svg>
  



  const conceptBillIDs = (history?.concepts)? [...new Set(Object?.keys(history?.concepts)?.map((el) => parseInt(history?.concepts[el]?.document_id)))] : null
  const extraBillIDs = (extraHistory?.length)? [...new Set(extraHistory?.map((el) => parseInt(el?.bill_id)))] : null

  const [getBillsByF10IDs, { data: dataBills, loading: loadingBills }] = useLazyQuery(GetBillsByF10IDs, { 
    fetchPolicy: 'no-cache',
    variables: {
        own_user_token: currentUser?.token,
        own_customer_token: currentCustomer?.token,
        ids: (conceptBillIDs?.length)? conceptBillIDs : null,
        obj_ids: (extraBillIDs?.length)? extraBillIDs : null,
    }
  })







      
  useEffect(() => {   
    if (history?.documents && Object.keys(history?.documents)?.length) {
        window.addEventListener('click', clickOutside);
    } else {
        window.removeEventListener('click', clickOutside)
    }

    function clickOutside(e){   
        const element = document.getElementById('clickbox');
        if (element && element.contains(e.target)){ // Clicked in box
        } else if (history) { // Clicked outside the box
            setHistory(null)
        }
    }


    return () => {
        window.removeEventListener('click', clickOutside)
    };
  }, [history])


  useEffect(() => {
    setCellHeight((isSubVideo && !showFullEditMode)? 45 : 110)
  }, [isSubVideo, showFullEditMode])

  
  useEffect(() => {
    setProdStateHistory((video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history) : null);
  }, [video])




  

  
  function getMediaTimeFunc(video) {
    return (parseInt(video?.search_media_time) || parseInt(video?.search_media_time) === 0)?
      <div className='absolute z-30 bottom-0 right-0 m-1 bg-gray-200 bg-opacity-30 text-white text-xs rounded overflow-hidden'>
        <div
          className={`bg-opacity-60 px-[4px] py-[1px]
            ${
              (video?.search_media_time?.split(':')[0] >= 20 || video?.search_media_time?.split(':')?.length > 2)? 'bg-purple-600' 
              : (video?.search_media_time?.split(':')[0] >= 10)? 'bg-red-600' 
              : (video?.search_media_time?.split(':')[0] >= 4)? 'bg-yellow-600' 
              : 'bg-green-600'
            }
          `}
        >
          {(video?.search_media_time?.split(':')?.length === 3 && video?.search_media_time?.charAt(0) === '0')? video.search_media_time.substring(1) : video.search_media_time}
        </div>
      </div>
    : null ;      
  }




  async function loadHistory(e) {
    setLoading(true)
    setAnchorElFactura10(e.currentTarget)

    const historyBillConceptsResp = await axios.post(`${process.env.REACT_APP_API_ROUTE}getHystoryBillConcepts`, { product_name: video?.name, customer_token: currentCustomer?.token });
    const extraBillIDsTMP = (historyBillConceptsResp?.data?.length)? historyBillConceptsResp?.data?.map((el) => el?.f10ID ) : null

    const factura10HistoryResp = await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_product_history.php`, { productName: video?.name, extra_bills: extraBillIDsTMP });
    const historyTMP = (factura10HistoryResp?.data?.documents && Object.keys(factura10HistoryResp?.data?.documents)?.length)? factura10HistoryResp?.data : null
    const extraBillIDs = (historyTMP?.documents && Object.keys(historyTMP?.documents)?.length)? Object.keys(historyTMP?.documents)?.map((el) => parseInt(el)) : null

    const historyExtraConceptsResp = await axios.post(`${process.env.REACT_APP_API_ROUTE}getHystoryExtraConcepts`, { history_ids: (extraBillIDs?.length)? extraBillIDs : null });
    const extrasTMP = (historyExtraConceptsResp?.data[0]?.length)? historyExtraConceptsResp?.data[0] : null
    const extrasExternalsTMP = (historyExtraConceptsResp?.data[1]?.length)? historyExtraConceptsResp?.data[1] : null

    if (extrasExternalsTMP?.length) {
      if (!historyTMP) { historyTMP = {} } 
      if (!historyTMP?.documents) { historyTMP.documents = {} } 

      for (var extrasExternalTMP of extrasExternalsTMP) {
          historyTMP.documents[extrasExternalTMP?.createdAt] = extrasExternalTMP;
      }
    }

    setHistory((historyTMP && Object.keys(historyTMP)?.length)? historyTMP : null)
    setExtraHistory((extrasTMP?.length)? extrasTMP : null)

    if (extrasTMP?.length || (historyTMP && Object.keys(historyTMP)?.length)) {
        getBillsByF10IDs()
    } else {
        setErrorAlertMsg('No se han encontrado facturas de este vídeo.')
        setAnchorElFactura10(null)
    }

    setLoading(false)
  }





  function downloadFunc() {
    setDownloading(0)

    axios.post(`${process.env.REACT_APP_API_ROUTE}videoFileDownload`, { own_user_token: currentUser?.token, video_id: video?.id }).then((response) => {
      axios({
        url: `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${video?.download_video}`,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => setDownloading(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      },)?.then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', video?.download_video?.replaceAll('_', ' '));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setTimeout(() => {
            setDownloading(null)
          }, 300 )
        }
      });
    });
  }







  const advancedUserCredentials = (!currentUser?.rol?.includes('user') || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? true : false




  return <>
    <TableCell
      colSpan={1}
      align={column.align}
      sx={{ 
        padding: 0,
        width: 'auto', 
        minWidth: 'auto', 
        maxWidth: 'auto', 
      }}
      className="group-active/row:bg-gray-400 group-active/row:bg-opacity-[0.2] duration-100"
    >
      <label 
        htmlFor={((editMode || canBeAddedToFolder) && (!showFullEditMode || (showFullEditMode && column.id !== 'name')))? 'check-'+listKey : ''}
        className={`block ${(editMode || canBeAddedToFolder)? 'MuiButtonBase-root cursor-pointer' : ''}`}
        onClick={(event) => { if (!showFullEditMode || (showFullEditMode && column.id !== 'name')) { checksFunc(listKey, event.shiftKey? true : false, lastChecked, setLastChecked) } }}
        style={{ height: cellHeight }}
      >
        <a 
          style={{ height: `${cellHeight}px`, justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}
          className={`
            flex items-center relative reset-this-link duration-200 transition-width
            ${(isSubVideo && !column?.id?.includes('endDateFormated'))? 'text-xs' : ''} 
            ${(column.id === 'thumbnail')? `${(showFullEditMode)? 'w-[115px] max-w-[115px]' : 'w-[115px] xl:w-[165px]'}` : (column.id !== 'production_state')? `px-[14px]` : 'px-[6px]'}
            ${(column?.id?.includes('endDateFormated') && orderFilter?.includes('EndDate ') && (startFilterDate || endFilterDate))? (
              (checkInBetweenDatesFunc(startFilterDate, endFilterDate, orderFilter, false, video, false))? 'font-bold' : 'opacity-50'
            ) : ''} 
            ${(!video?.endDate && !currentUser?.rol?.includes('user'))? 'bg-blue-500 bg-opacity-[0.07]' : ''} 
          `}
          href={ /* VIDEO URL */
            (!editMode && !canBeAddedToFolder && column.id !== 'actions' && (!advancedUserCredentials || (advancedUserCredentials && column.id === 'thumbnail')))? (
              ("/" + currentCustomer?.abbreviated_name + "/" + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra') + "/" + ((name)? (video?.url_name?.replaceAll(' ', '_')?.split('-')[0] + "/" + video.id) : video?.url_name?.replaceAll(' ', '_'))).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) : null
          }
          onMouseUp={(event)=> {
            if (canBeAddedToFolder && !video?.is_translation_of) {
              addVideoToFolderFunc({ id: parseInt(video?.id), name: video?.name })
            } else if (
              event?.target
              && !event?.target?.getAttribute('data-avoid')
              && !event?.target?.getAttribute('aria-hidden')
              && (event.target.className && !event.target.className?.includes('MuiInputBase-root'))
              && (event.target.className && !event.target.className?.includes('MuiFormLabel-root'))
              && event?.target?.tagName !== 'BUTTON'
              && event?.target?.tagName !== 'INPUT'
            ) {
              if (!editMode && column.id !== 'actions' && (!isSubVideo || column.id !== 'production_state') && advancedUserCredentials && column.id !== 'thumbnail') {
                setSelectedVideo(video)
                setOpenVideoAdminEitor(true)
              }
            }
          }}
        >


          {(column.id === 'name')? /* NAME or EDIT MODE */


            (editMode && showFullEditMode)?
              <VideoHomeEditModeListInputs
                video={video}
                extraMarks={extraMarks}
                
                dataVideoNames={dataVideoNames}
                categoryOptions={categoryOptions}
              />
            :
              <div className="w-full">
                <div className={`${(isSubVideo)? 'flex items-center justify-start space-x-2 space-x-reverse' : 'space-y-[2px]'}`}> {/* opacity-50 group-hover/row:opacity-100 duration-200 */}
                
                  {(isSubVideo && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && currentCustomer && currentCustomer?.setting_recording_tipo_in_list) && 
                    <div className='mr-2 text-[11px] text-gray-400 flex items-center space-x-1'>
                      <VideoFormatIcons
                        category={categoryTMP}
                        note={video?.facturation_note}
                      />
                    </div>
                  }

                  <div className='flex items-center whitespace-nowrap'>
                    {(record || danger  || warning  || motionerWarning)?
                      <Tooltip disableInteractive title={(record)? `Este vídeo tiene que grabarse el ${video?.recordingDateFormated}` : (danger)? '¡Este vídeo es de alta prioridad!' : (warning)? '¡La fecha de publicación es demasiado cercana para este estado!' : (motionerWarning)? '¡Hay marcas pendientes en este vídeo!' : ''} placement="right" arrow>
                        <div 
                          className={`flex items-center
                            ${(currentCustomer?.setting_tittle_is_relevant)? `text-xs` : `font-bold`}
                            ${(danger)? 'text-red-500 ' : (warning)? 'text-yellow-500' : (motionerWarning)? 'text-orange-500' : (currentCustomer?.setting_tittle_is_relevant)? 'text-gray-500 opacity-75' : 'text-gray-600'}
                          `}
                        >
                          {(record)? <p className='bg-red-500 text-white rounded text-[10px] px-[2px] mr-1'>{(video?.recordingDateFormated)? video?.recordingDateFormated : '---'}</p> : null}
                          {(video?.has_token && currentUser?.rol === 'admin')? <div className='w-[23px] ml-[-1px]'>{publicIcon}</div> : null}
                          <p className={`truncate ${(moduleFilter === 'all')? 'max-w-[210px]' : 'max-w-[300px]'}`}>{video?.name}</p>
                        </div>
                      </Tooltip>
                    :
                      <div 
                        className={`flex items-center text-gray-600
                          ${(currentCustomer?.setting_tittle_is_relevant)? `text-xs` : `font-bold`}
                        `}
                      >
                        <p className={`truncate ${(moduleFilter === 'all')? 'max-w-[210px]' : 'max-w-[300px]'}`}>{video?.name}</p>
                      </div>
                    }

                    {(video?.is_part_of) &&
                      <Tooltip disableInteractive title={`Este vídeo es parte de ${(video?.search_is_part_of_name)? video?.search_is_part_of_name : 'otro'}`} placement="right" arrow>
                        <div className='ml-1 w-[18px] h-[18px] flex items-center justify-center'>
                          <p className='text-gray-400 rotate-180 scale-75'>{fromAnotherIcon}</p>
                        </div>
                      </Tooltip>
                    }

                    {(moduleFilter === 'all')? <p className='truncate text-[10px] font-bold text-gray-400 pl-[6px] max-w-[100px]'>/ {(video?.search_module_name)? video?.search_module_name : 'extra'}</p> : null}
                  </div>



                  <div className='flex items-center justify-between space-x-1' style={{ margin: 0 }}>
                    <div className='flex items-center space-x-1'>
                      {(!isSubVideo && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && currentCustomer && currentCustomer?.setting_recording_tipo_in_list) && 
                        <div className='text-[11px] text-gray-400 flex items-center space-x-1'>
                          <VideoFormatIcons
                            category={categoryTMP}
                            note={video?.facturation_note}
                          />
                        </div>
                      }


                      {(!isSubVideo || currentCustomer?.setting_tittle_is_relevant) && 
                        <p
                          className={`truncate
                            ${(currentCustomer?.setting_tittle_is_relevant)? `font-bold text-gray-600 max-w-[250px]` : `text-xs text-gray-500 opacity-75 max-w-[150px] xl:max-w-[250px] 2xl:max-w-[260px] min-[1650px]:max-w-[340px] min-[1650px]:pr-2 `}
                            ${(video?.title)? '' : 'opacity-50'}
                            ${(isSubVideo)? 'max-w-[210px] pl-2 truncate' : ''}
                          `}
                          dangerouslySetInnerHTML={{__html: 
                            (video?.title)? video?.title
                            : 'Sin título...'
                          }}
                        ></p>
                      }
                    </div>

                    {(video?.download_video && (prodStateHistory[0]?.video_state_name?.includes('OK-') || prodStateHistory[0]?.video_state_name?.includes('Entregado-')) && (currentUser?.rol?.includes('user') || currentUser?.rol?.includes('admin'))) &&
                      <button 
                        className='relative flex items-center text-xs text-blue-400 hover:text-blue-500 border border-transparent hover:border-gray-300 hover:bg-white hover:bg-opacity-70 hover:scale-[1.2] rounded-lg mr-1 duration-200 transition-transform cursor-pointer disabled:pointer-events-none'
                        disabled={downloading}
                        onClick={() => downloadFunc()}
                      >
                        {(downloading === null)? doneIcon : (downloading || downloading === 0)? loadingIcon : downloadIcon}
                        {(downloading || downloading === 0)? <p className='left-[22px] pr-[2px] absolute'>{downloading}%</p> : null}
                        {(downloading || downloading === 0)? <p className='pr-3'></p> : null}
                      </button>
                    }
                  </div>


                  {(!isSubVideo && currentCustomer?.setting_tittle_is_relevant && video?.extra_professors_label) &&
                    <p className='truncate max-w-[200px] text-xs text-gray-500 opacity-75'>
                      {video?.extra_professors_label?.split(',')?.map(element => {
                        return element?.split(' / ')[0]
                      })?.join(', ').replace(/,([^,]*)$/, ' y ' + '$1')}
                    </p>
                  }
                </div>
              </div>


          : (column.id === 'thumbnail')? /* THUMBNAIL */

              
            ((isSubVideo)? 
              <div className='relative w-full flex justify-center items-center'>
                <div
                  className={`z-20 absolute ${(showFullEditMode)? 'mt-[-110px]' : 'mt-[-46px]'} p-1 duration-200 rounded-tl-sm border-t border-l border-gray-400 border-opacity-50 rotate-45
                    ${(!video?.endDate && !currentUser?.rol?.includes('user'))? 'bg-[#dee3ee] group-hover/row:bg-[#cfd5e0]' : 'bg-[#e9ebee] group-hover/row:bg-[#d9dcdf]'} 
                    ${(showFullEditMode)? 'left-[58px]' : 'left-[58px] xl:left-[84px]'}
                  `}
                ></div>
              
                <div className='z-30 w-full pl-4 pr-2'> {/* opacity-50 group-hover/row:opacity-100 duration-200 */}
                  <div className={`opacity-75 font-bold border border-gray-400 text-gray-500 hover:text-gray-600 ${(showFullEditMode)? 'px-3 py-[30px]' : 'p-1'} hover:opacity-100 flex justify-center items-center bg-gray-300 hover:bg-gray-400 hover:scale-[1.08] hover:bg-opacity-50 rounded duration-200`}>
                      <p>{(video?.language)? video?.language?.toUpperCase() : '---'}</p>
                  </div>
                </div>
              </div>
            :
              <div className={`h-[79px] hover:scale-105 ${(showFullEditMode)? 'w-[90px]' : ' w-[90px] xl:w-[139px]'} ${(video?.is_sound_media)? 'bg-gray-300' : 'bg-gray-600'} duration-200 transition-all absolute top-[15px] left-[17px] rounded-md overflow-hidden flex items-center justify-center text-gray-400 border border-gray-400`}>

                {(video?.is_sound_media)? 
                  <div className='shadow-[inset_0_0px_15px_rgba(0,0,0,0.15)] bg-gray-200 w-full h-full flex items-center justify-center text-gray-700'>
                    <div>{audioIcon}</div>
                    {getMediaTimeFunc(video)}
                  </div>
                : 
                  <div className='h-full flex items-center justify-center'>
                    {(value && value !== '-') && 
                      <div className='absolute scale-150 pl-4 flex justify-center items-center z-10'>
                        {loadIcon}
                      </div>
                    }

                    {getMediaTimeFunc(video)}

                    {(video.thumbnail_small || video.thumbnail)?
                      <img src={customerPath + 'video_thumbnails/' + ((video.thumbnail_small)? video.thumbnail_small : video.thumbnail)} alt='thumbnail_image' className={`z-20 object-cover w-full h-full duration-300`}/> 
                    :
                      <div className='scale-110'>{imageNotFoundIcon}</div>
                    }

                  </div>
                }
              </div>
            )
            

          : (column.id === 'actions')?


              <div>
                {/* (!editMode && !canBeAddedToFolder)? 
                  <div className={`flex justify-end items-center`}>
                    <Tooltip disableInteractive title="Compartir" placement="bottom" arrow>
                      <div id={'share-vid-'+video?.id} onClick={(event)=>setAnchorEl(event.currentTarget)}>
                        <IconButton color='primary' size='small'>
                          <div className='scale-125'>{shareIcon}</div>
                        </IconButton> 
                      </div>
                    </Tooltip>
                    
                    <MenuVideoSharing anchorEl={anchorEl} setAnchorEl={setAnchorEl} video={video} shadow={false}/>
                  </div>
                :  */
                (canBeAddedToFolder && !video?.is_translation_of)? 
                  <div className={`flex items-center justify-end ${(addedVideosToFolderIDs?.length && addedVideosToFolderIDs?.includes(parseInt(video?.id)))? 'text-blue-500' : ' opacity-50'}`}>
                    {(addedVideosToFolderIDs?.length && addedVideosToFolderIDs?.includes(parseInt(video?.id)))? addedToFolderIcon : notAddedToFolderIcon}
                  </div>
                : (!canBeAddedToFolder)?
                  <div className='flex items-center justify-end'>
                    <input 
                      id={'check-'+listKey} 
                      name={'check-'+listKey} 
                      value={video?.id}
                      data-name={video?.name}
                      data-language={video?.language}
                      type="checkbox" 
                      className='focus:outline-none scale-[1.2]'
                      onChange={(event)=>{
                        if (!lastChecked) { setLastChecked(listKey) }
                        setAnyChecked(anyCheckbox())
                      }} 
                    />
                  </div>
                : null }
              </div>


            : (column.id === 'production_state')? /* STATE */
            

              (video?.is_translation_of && advancedUserCredentials)?
                
                <div className='w-full h-full max-h-[35px] max-w-[300px] overflow-auto smallScrollBar'>
                  <div className={`w-full h-full flex justify-start items-center space-x-1 ${(settingsRelations?.length)? 'min-w-[300px]' : 'min-w-[150px]'}`}>
                    <SmallVideoSettingsList
                      video={video}
                      settingsRelations={settingsRelations}
                      clicksoundUserNames={clicksoundUserNames}

                      loading={false}
                      label={'Estado de la localización'}
                      small={true}
                      personalSettings={false}
                    />
                  </div>
                </div>

              : (video?.is_translation_of)?

                <div className='flex items-center space-x-2 text-xs text-gray-500'>
                  <div className={`p-[6px] menu-item-ball-${(video?.search_review_version && video?.vimeo_url)? 'green' : 'purple'}`}></div>

                  <div className='flex flex-wrap space-x-1 items-center justify-start'>
                    <p className='truncate'>{checkStatePathPointFunc(settingsRelations, video)}</p>
                    <p>{(video?.vimeo_url && !video?.search_review_date)? '(dummie en vimeo)' : null}</p>
                  </div>
                </div>
                

              : (prodStateHistory?.length && (prodStateHistory[0]?.video_state_name?.split('-')[0] === 'Terminado' || !video?.is_translation_of))?
                
                <Tooltip disableInteractive title={(video?.production_state_change_label)? ((!advancedUserCredentials)? video?.production_state_change_label?.replaceAll(video?.production_state_change_label?.replaceAll('Estado cambiado ', '')?.split(' el ')[0], '') : video?.production_state_change_label) : ""}  placement="bottom" arrow>
                  <div className={`flex items-center space-x-1`}>
                    <div className={`p-[6px] menu-item-ball-${(parseInt(prodStateHistory[0]?.video_state_name?.split('-')[2]))? 'green' : prodStateHistory[0]?.video_state_name?.split('-')[1]}`}></div>

                    <div className='pl-1'>
                      <p className='text-xs text-gray-500'>
                        {(!advancedUserCredentials && (parseInt(prodStateHistory[0]?.video_state_name?.split('-')[2]) || prodStateHistory[0]?.video_state_name?.split('-')[0] === 'Revisión de marcas'))? 'Terminado' : prodStateHistory[0]?.video_state_name?.split('-')[0]}
                      </p>
                      {(currentUser?.rol === 'admin' && video?.production_state_change_label && !video?.is_translation_of) && 
                        <p className='hidden xl:block text-[10px] text-gray-400'>
                          {video?.production_state_change_label?.replaceAll('Estado cambiado por ', '').replaceAll('.', '').replaceAll(' el ', ' ')}
                        </p>
                      }
                    </div>
                  </div>
                </Tooltip>

              : null
              
            : (column.id === 'search_professor_name')? 

              <p className={`text-sm ${(value)? 'text-gray-500' : 'text-gray-300'}`}>{(value)? value?.split(' / ')[0] : `Sin ${currentCustomer?.label_professor?.toLowerCase()}`}</p>

            : (column.id === 'search_number_of_marks')? 

              <NumberOfMarksList
                video={video}
                showIcon={true}
                flex={false}
                pr={true}
              />

            : (column.id === 'search_number_of_bill_concepts')? 


              <div className='w-full flex justify-between items-center space-x-2'>
                <button 
                  onClick={(e) => (currentCustomer?.factura10_client_id)? loadHistory(e) : null}
                  className={`rounded p-[2px] duration-200 
                    ${
                      (!loading && history && video?.search_workflow_bills_state === 3)? 'bg-blue-500 text-white' 
                      : (!loading && history && video?.search_workflow_bills_state === 2)? 'bg-lime-500 text-white' 
                      : (!loading && history && video?.search_workflow_bills_state === 1)? 'bg-yellow-500 text-white' 
                      : `
                        ${(currentCustomer?.factura10_client_id)? 'hover:bg-gray-400 hover:bg-opacity-30 active:bg-opacity-60' : ''}
                        ${
                          (video['factura10_product_id'] || video?.search_workflow_bills_state === 3)? 'text-blue-500' 
                          : (video?.search_workflow_bills_state === 2)? 'text-lime-500' 
                          : (video?.search_workflow_bills_state === 1)? 'text-yellow-500' 
                          : 'opacity-20'
                        }
                      `
                    }
                  `}
                >
                  <p className='scale-90'>{(loading)? loadingIcon : euroIcon}</p>
                </button>


                {(history?.documents)? 
                  <TransitionGroup appear={true}>
                    {(history?.documents && Object.keys(history?.documents)?.length || anchorElFactura10)?
                      <CSSTransition timeout={200} classNames="fade" >

                        <RowHistoryMenu
                          anchorEl={anchorElFactura10}
                          setAnchorEl={setAnchorElFactura10}
                          history={history}
                          extraHistory={extraHistory}
                          documents={(dataBills?.getBillsByF10IDs?.length)? dataBills?.getBillsByF10IDs : null}
                          actualBillId={null}
                          video={video}

                          loading={loading}
                          center={true}
                        />

                      </CSSTransition>
                    : null }
                  </TransitionGroup>
                : null }



                <div className={`text-gray-600 flex justify-end items-baseline space-x-[1px] min-w-[30px]`}>
                  {(parseInt(video['search_number_of_bill_concepts']?.split('/')[1]))?
                    <Tooltip disableInteractive title="Conceptos facturados" placement="bottom" arrow>
                      <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(video['search_number_of_bill_concepts']?.split('/')[1])) && 'opacity-20'}`}>
                        <p className={`text-blue-500`}>{(parseInt(video['search_number_of_bill_concepts']?.split('/')[1])? video['search_number_of_bill_concepts']?.split('/')[1] : 0)}</p>
                        <p>/</p>
                      </div>
                    </Tooltip>
                  : null }

                  <p className={(parseInt(video['search_number_of_bill_concepts']?.split('/')[0]))? '' : 'opacity-50'}>{(parseInt(video['search_number_of_bill_concepts']?.split('/')[0])? video['search_number_of_bill_concepts']?.split('/')[0] : '---')}</p>
                </div>
              </div>


            : (column.id === 'endDateFormated')? /* LANGUAGE */

              <div>
                <p>{value}</p>

                {(video?.publicationEndDateFormated)? <>
                  <p>{video?.publicationEndDateFormated}</p>
                </> : null }
              </div>
            
            : (column.id === 'lastReviewDateFormated')? /* LANGUAGE */
                        

              <div className={`w-full flex flex-col items-center justify-center text-gray-500 text-xs`}>
                <p className='opacity-70'>{video?.searchReviewDateFormated}</p>
                {(video?.search_review_version)?
                  <p className='text-[10px] opacity-75 bg-gray-400 bg-opacity-20 border border-gray-400 border-opacity-50 rounded px-[3px] font-bold'>
                    Versión n{video?.search_review_version}
                  </p>
                : <p className='text-gray-400'>---</p> }
              </div>


            : <div>{value}</div> /* BASE VALUE */


          }
        </a>
      </label>
    </TableCell>
  </>
}

export default VideoHomeBaseListTableCell
