import { useState, useContext, useEffect } from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

import Tooltip from '@mui/material/Tooltip';








export default function HomeChartsToExcel({ data, filename, modules }) {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>
  const [excelData, setExcelData] = useState([]);



  useEffect(() => {    
    if (data?.length) {
      var excelDataTMP = []


      for (var i = 0; i < data?.length; i++) {
        var dataItem = data[i];
        var newItem = [];

        Object.keys(dataItem)?.map(key => {
          var value = dataItem[key];

          if (key === 'name') {
            newItem['Nombre'] = value;
          } else {
            newItem[key] = value;
          }
        });

        excelDataTMP.push(newItem)
      }
      setExcelData(excelDataTMP)

    } else {
      setExcelData([])
    }
  }, [data])




  const exportToExcel = async (excelData) => {

    var excelDataTMP = [...excelData]
    
    for (var i = 0; i < excelDataTMP?.length; i++) {
      var element = excelDataTMP[i];

      if (modules?.length) {
        for (var m = 0; m < modules?.length; m++) {
          var module = modules[m];
          
          if (element[module?.name] === undefined) { element[module?.name] = null }
        };
  
        if (element["Extras"] === undefined) { element["Extras"] = null }  
      } else {
        if (element["Masculino Español"] === undefined) { element["Masculino Español"] = null }  
        if (element["Masculino Inglés"] === undefined) { element["Masculino Inglés"] = null }  
        if (element["Femenino Español"] === undefined) { element["Femenino Español"] = null }  
        if (element["Femenino Inglés"] === undefined) { element["Femenino Inglés"] = null }  
        if (element["Multi Genero Español"] === undefined) { element["Multi Genero Español"] = null }  
        if (element["Multi Genero Inglés"] === undefined) { element["Multi Genero Inglés"] = null }  
        if (element["Sin Profesor"] === undefined) { element["Sin Profesor"] = null }  
      }


      var sortedKeys = Object.keys(element).sort(function(a, b){

        if(a === 'name') { return -1; }
        if(b === 'name') { return 1; }
        if(a === 'Nombre') { return -1; }
        if(b === 'Nombre') { return 1; }

        if(a === 'Total') { return 1; }
        if(b === 'Total') { return -1; }

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
      
      }).reduce((objEntries, key) => {

        var newKey = (key === 'name')? 'Nombre' 
        : (parseInt(key.charAt(0)))? key.replace(/[0-9]/g, '')
        : key

        objEntries[newKey] = element[key];
        return objEntries;
        
      }, {});
      
      excelDataTMP[i] = sortedKeys;
    }


    const ws = XLSX.utils.json_to_sheet(excelDataTMP);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const endData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(endData, filename);
  }


  return <Tooltip disableInteractive title={"Descargar datos en XLSX"} placement="bottom" arrow>
    <button onClick={()=> exportToExcel(excelData)} className={`bg-gray-200 py-[2px] pl-1 pr-2 text-xs font-bold flex items-center cursor-pointer duration-200 text-gray-500 hover:text-blue-500 active:text-gray-500 active:bg-gray-200 rounded`}>
      {downloadIcon}
      <p>XLSX</p>
    </button>
  </Tooltip>
}
