import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { useNavigate } from "react-router-dom";
import { FuncContext } from '../contexts/FuncContext';

import VideoTopNavigation from '../components/VideoTopNavigation'
import VideoFormInputs from '../components/VideoFormInputs'

import RemoveVideo from './../graphql/mutations/RemoveVideo'





const Video = () => {



  
  const { id, name } = useParams()
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive, setSuccessAlertMsg } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();



  const [video, setVideo] = useState(null);
  const [triggerSave, setTriggerSave] = useState(false);

  /* Loading vars */
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  /* Error var */
  const [videoNameRepeated, setVideoNameRepeated] = useState(false);

  /* Icon vars */
  const deleteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M312-144q-29.7 0-50.85-21.15Q240-186.3 240-216v-480h-12q-15.3 0-25.65-10.29Q192-716.58 192-731.79t10.35-25.71Q212.7-768 228-768h156v-12q0-15.3 10.35-25.65Q404.7-816 420-816h120q15.3 0 25.65 10.35Q576-795.3 576-780v12h156q15.3 0 25.65 10.29Q768-747.42 768-732.21t-10.35 25.71Q747.3-696 732-696h-12v479.57Q720-186 698.85-165T648-144H312Zm107.79-144q15.21 0 25.71-10.35T456-324v-264q0-15.3-10.29-25.65Q435.42-624 420.21-624t-25.71 10.35Q384-603.3 384-588v264q0 15.3 10.29 25.65Q404.58-288 419.79-288Zm120 0q15.21 0 25.71-10.35T576-324v-264q0-15.3-10.29-25.65Q555.42-624 540.21-624t-25.71 10.35Q504-603.3 504-588v264q0 15.3 10.29 25.65Q524.58-288 539.79-288Z"/></svg>
  const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 432Q495 672 505.5 661.65 516 651.3 516 636V468q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 432 454.5 442.35 444 452.7 444 468v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Zm0-72q110 0 187-77t77-187q0-110-77-187t-187-77q-110 0-187 77t-77 187q0 110 77 187t187 77Zm0-264Z"/></svg>
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;




  const [removeVideo] = useMutation(RemoveVideo)





  useEffect(() => {    
    setCurrentNavActive('videos')
  }, [])







  function removeFunc() {
    if (video && window.confirm('¿Seguro que quieres proceder a eliminar este vídeo y todos sus contenidos?')) {
      setLoadingRemove(true)
      
      removeVideo({variables: {
        id: parseInt(video.id), 
        own_user_token: currentUser? currentUser.token : null,
      }}).then((res)=>{

        setLoadingRemove(false)

        if (res?.data?.removeVideo?.status) {
          setSuccessAlertMsg('Vídeo eliminado correctamente!')
          setTimeout(() => {
            navigate("/videos" + ((name)? ('/name/' + name) : ''))
          }, "200")
        } else {
          setSuccessAlertMsg('El tema no pudo ser eliminado...')
        }
      })
    }
  }
  








  return (
    <div className='flex flex-col items-center'>



      <div className='w-full min-w-0'>
        <VideoTopNavigation
          mode={null}
          setMode={null}
          disableModeNav={null}
          navigateTo={'video'}
          
          video={video}
          newVideoMark={null}
          
          loading={(loadingSave || loadingContent || loadingRemove)? true : false}
          loadingReviews={null}
          loadingVideo={null}
          loadingUsers={null}
          loadingTabChange={null}
          setLoadingTabChange={null}
        />
      </div>



      <div className={`w-full px-5 md:px-10 min-w-[400px] max-w-[1740px] ${(loadingSave || loadingContent || loadingRemove)? 'opacity-50 pointer-events-none' : ''}`}>
        <div className='w-full flex justify-between items-center flex-wrap lg:flex-nowrap'>
          <div className='w-full pr-4 pl-2'>
            <p className='font-bold text-xs pt-14 text-gray-400'>Edición del tema:</p>
            <h1 className='font-bold text-2xl sm:text-3xl lg:text-4xl lg:pb-14'>{(id || name)? ((video?.name)? video?.name : (loadingContent)? 'Cargando...' : (video)? 'Sin nombre asignado' : 'Nuevo tema') : 'Vídeo inexistente'}</h1>
          </div>

          <div className='flex justify-between items-center space-x-2 pb-8 lg:pb-0 pt-2 lg:pt-5'>
            {(id && currentUser?.rol === 'admin') && 
              <button
                className='pl-1 pr-2 py-1 text-sm flex items-center space-x-[1px] text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'
                onClick={() => removeFunc()} 
                disabled={(!((id && video) || (!id && !video)) || loadingContent || loadingSave)? true : false}
              >
                {(loadingRemove)? loadingIcon : <div className='scale-95'>{deleteIcon}</div>}
                <p>{(loadingRemove)? 'Eliminando...' : 'Eliminar'}</p>
              </button>
            }



            {(id && video) && 
              <button /* VIDEO URL */
                className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-gray-400 hover:bg-gray-500 active:bg-gray-600 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
                onMouseUp={(event)=> navigateOrOpenFunc(event, ( `/${currentCustomer?.abbreviated_name}/${((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra')}/${video?.url_name}${((name)? `/${video?.id}` : '')}`.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ))}
              >
                {markIcon}
                <p>Ver marcas</p>
              </button>
            }

            <button
              className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
              onClick={() => setTriggerSave(true)} 
              disabled={(loadingContent || loadingRemove || videoNameRepeated)? true : false}
            >
              {(loadingSave)? loadingIcon : <div className='scale-95'>{saveIcon}</div>}
              <p>{(loadingSave)? 'Guardar...' : 'Guardar'}</p>
            </button>
          </div>
        </div>


        <hr className='border-t-2 border-gray-200'></hr>


        <div className='sm:py-6 lg:py-10'>
          <VideoFormInputs 
            simplified={false}
            initialVideoID={id}
            initialVideo={video}
            triggerSave={(triggerSave)? true : false}

            loadingContent={loadingContent}
            setLoadingContent={setLoadingContent}
            loadingSave={loadingSave}
            setLoadingSave={setLoadingSave}
            loadingRemove={loadingRemove}
            setLoadingRemove={setLoadingRemove}

            videoNameRepeated={videoNameRepeated}
            setVideoNameRepeated={setVideoNameRepeated}

            videoSetter={'set'}
            getVideos={null}
            setInitialVideo={setVideo}
          />
        </div>

      </div>
    </div>
  )
}

export default Video
