import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import Xarrow from 'react-xarrows';

import { useLocation, useNavigate } from 'react-router-dom';









export default function StarterTutorial({ 
  
  tutorialOpen,
  setTutorialOpen,
  step,
  setStep,

}) {



  
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const navigate = useNavigate();
  const location = useLocation();


  const homeIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M240 856h120V616h240v240h120V496L480 316 240 496v360Zm0 80q-33 0-56.5-23.5T160 856V496q0-19 8.5-36t23.5-28l240-180q11-8 23-12t25-4q13 0 25 4t23 12l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720 936H520V696h-80v240H240Zm240-350Z"/></svg>
  const videoIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M240 856v40q0 17-11.5 28.5T200 936q-17 0-28.5-11.5T160 896V256q0-17 11.5-28.5T200 216q17 0 28.5 11.5T240 256v40h80v-40q0-17 11.5-28.5T360 216h240q17 0 28.5 11.5T640 256v40h80v-40q0-17 11.5-28.5T760 216q17 0 28.5 11.5T800 256v640q0 17-11.5 28.5T760 936q-17 0-28.5-11.5T720 896v-40h-80v40q0 17-11.5 28.5T600 936H360q-17 0-28.5-11.5T320 896v-40h-80Zm0-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm400 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80ZM400 856h160V296H400v560Zm0-560h160-160Z"/></svg>
  const motionIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m447-431 156-104q14-9 14-25t-14-25L447-689q-15-10-31-1.5T400-664v208q0 18 16 26.5t31-1.5ZM240-240l-92 92q-19 19-43.5 8.5T80-177v-623q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240Zm-80-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>
  const tagIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m297-581 149-243q6-10 15-14.5t19-4.5q10 0 19 4.5t15 14.5l149 243q6 10 6 21t-5 20q-5 9-14 14.5t-21 5.5H331q-12 0-21-5.5T296-540q-5-9-5-20t6-21ZM700-80q-75 0-127.5-52.5T520-260q0-75 52.5-127.5T700-440q75 0 127.5 52.5T880-260q0 75-52.5 127.5T700-80Zm-580-60v-240q0-17 11.5-28.5T160-420h240q17 0 28.5 11.5T440-380v240q0 17-11.5 28.5T400-100H160q-17 0-28.5-11.5T120-140Zm580-20q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-500-20h160v-160H200v160Zm202-420h156l-78-126-78 126Zm78 0ZM360-340Zm340 80Z"/></svg>
  const userIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M80 896q-17 0-28.5-11.5T40 856v-72q0-34 17.5-62.5T104 678q62-31 126-46.5T360 616q66 0 130 15.5T616 678q29 15 46.5 43.5T680 784v72q0 17-11.5 28.5T640 896H80Zm661 0q9-7 14-17.5t5-23.5v-79q0-44-24.5-84.5T666 622q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v80q0 17-11.5 28.5T880 896H741ZM360 576q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544 264q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120 816h480v-32q0-11-5.5-20T580 750q-54-27-109-40.5T360 696q-56 0-111 13.5T140 750q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440 416q0-33-23.5-56.5T360 336q-33 0-56.5 23.5T280 416q0 33 23.5 56.5T360 496Zm0-80Zm0 280Z"/></svg>
  const professorIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M320 336v-80q0-33 23.5-56.5T400 176h160q33 0 56.5 23.5T640 256v80h160q33 0 56.5 23.5T880 416v440q0 33-23.5 56.5T800 936H160q-33 0-56.5-23.5T80 856V416q0-33 23.5-56.5T160 336h160Zm80 0h160v-80H400v80Zm400 360H600v40q0 17-11.5 28.5T560 776H400q-17 0-28.5-11.5T360 736v-40H160v160h640V696Zm-360 0h80v-80h-80v80Zm-280-80h200v-40q0-17 11.5-28.5T400 536h160q17 0 28.5 11.5T600 576v40h200V416H160v200Zm320 40Z"/></svg>
  const chartsIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M320-280q17 0 28.5-11.5T360-320v-200q0-17-11.5-28.5T320-560q-17 0-28.5 11.5T280-520v200q0 17 11.5 28.5T320-280Zm160 0q17 0 28.5-11.5T520-320v-320q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v320q0 17 11.5 28.5T480-280Zm160 0q17 0 28.5-11.5T680-320v-80q0-17-11.5-28.5T640-440q-17 0-28.5 11.5T600-400v80q0 17 11.5 28.5T640-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
  const locationIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M480-301q99-80 149.5-154T680-594q0-56-20.5-95.5t-50.5-64Q579-778 544-789t-64-11q-29 0-64 11t-65 35.5q-30 24.5-50.5 64T280-594q0 65 50.5 139T480-301Zm0 85q-10 0-19.5-3t-17.5-9q-81-63-162-158.5T200-594q0-71 25.5-124.5T291-808q40-36 90-54t99-18q49 0 99 18t90 54q40 36 65.5 89.5T760-594q0 112-81 207.5T517-228q-8 6-17.5 9t-19.5 3Zm0-304q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM240-80q-17 0-28.5-11.5T200-120q0-17 11.5-28.5T240-160h480q17 0 28.5 11.5T760-120q0 17-11.5 28.5T720-80H240Zm240-514Z"/></svg>
  const emailIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M160 896q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256h640q33 0 56.5 23.5T880 336v480q0 33-23.5 56.5T800 896H160Zm640-480L501 603q-5 3-10.5 4.5T480 609q-5 0-10.5-1.5T459 603L160 416v400h640V416ZM480 536l320-200H160l320 200ZM160 416v10-59 1-32 32-.5V426v-10 400-400Z"/></svg>
  
  const menus = [
    { key: 0, icon: homeIcon, name: 'Inicio', description: 'En esta pestaña podrás visualizar un simple resumen de actividades recientes en la plataforma.' },
    { key: 1, icon: videoIcon, name: 'Vídeos', description: 'Aquí es donde encontrarás todos tus vídeos, con las herramientas requeridas y toda la información que necesites a tu alcance.' },
    { key: 2, icon: motionIcon, name: 'Motions', description: 'Todas tus marcas reunidas en una pestaña de búsqueda para que puedas administrarlas con total libertad.' },
    { key: 3, icon: chartsIcon, name: 'Estadísticas', description: 'Algunos datos que podrían interesarte, desde resúmenes de vídeos separados por género, hasta un listado de tus fechas de grabación.' },
    { key: 4, icon: userIcon, name: 'Usuarios', description: 'Escoge bien quien puede entrar y quien no. Asegúrate de que la gente que trabaja sobre tus clientes tenga el acceso exacto que quieres que tengan.' },
    { key: 5, icon: professorIcon, name: currentCustomer?.label_professors, description: `Administra tus ${currentCustomer?.label_professors}. Como los usuarios, estos también tienen un acceso limitado a la plataforma a través de un código y se encuentran asociados a tus vídeos.` },
    { key: 6, icon: tagIcon, name: 'Segmentos', description: `Un pequeño apartado que permite etiquetar a tus ${currentCustomer?.label_professors} en categorías.` },
    { key: 7, icon: locationIcon, name: 'Sets', description: 'Como el apartado de Segmentos, este apartado tampoco es muy relevante. Permitiéndote etiquetar los vídeos con su lugar de grabación.' },
    { key: 8, icon: emailIcon, name: 'Emails', description: 'Revisa qué emails han sido enviados a los usuarios de tu cliente de forma automatizada.' },
  ]
  
  
  




  const [activeMenu, setActiveMenu] = useState(menus[0]);
  
  /* Icon vars */
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>




  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = "no";
  }, []);




  useEffect(() => {
    if (tutorialOpen) {
      setStep(0)

      setTimeout(() => {
        setStep(1)
      }, 300 )
    }
  }, [tutorialOpen]);


  useEffect(() => {
    if (tutorialOpen) {
      navigate('videos')
    }
  }, [step, tutorialOpen]);


  useEffect(() => {
    if (activeMenu?.name) {
      const menuBox = document.getElementById('menu-box');
      const menuItem = document.getElementById(`menu-item-${activeMenu?.key}`);
      if (menuBox && menuItem) { scrollToElm(menuBox, menuItem , 0.5); }
    }
  }, [activeMenu]);






  function scrollToElm(container, elm, duration){
    var pos = getRelativePos(elm);
    container.scrollLeft += pos.left;
  }

  function getRelativePos(elm){
    var pPos = elm?.parentNode?.getBoundingClientRect(); // parent pos
    var cPos = elm?.getBoundingClientRect(); // target pos
    var pos = {};

    if (cPos) {
      pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop;
      pos.right = cPos.right - pPos.right;
      pos.bottom = cPos.bottom - pPos.bottom;
      pos.left = cPos.left - pPos.left;
    }

    return pos;
  }










  

  async function closeTutorialFunc() {
    document.documentElement.style.overflow = 'auto';
    document.body.scroll = "yes";

    setStep(11)
    setTimeout(() => {
      setTutorialOpen(false)
    }, 500 )
  }




  function buttonsFunc() {
    return <div className='w-full pt-4 flex items-center justify-between space-x-4'>
      <div className='flex items-center space-x-1'>
        {(step && step !== 1) && 
          <button onMouseUp={(event) => setStep(step - 1)} className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm hover:bg-black hover:bg-opacity-50 hover:shadow-md active:scale-95 rounded duration-200" >
            {leftArrowIcon}
            <p>Anterior</p>
          </button>
        }

        {(step !== 10 && step !== 11) && 
          <button onMouseUp={(event) => closeTutorialFunc()} className="p-[6px] pr-3 flex items-center space-x-1 text-sm hover:bg-black hover:bg-opacity-50 hover:shadow-md active:scale-95 rounded duration-200">
            {cancelIcon}
            <p>Cerrar</p>
          </button>
        }
      </div>


      {/* {(currentUser?.rol === 'admin') && 
        <div className='space-x-3'>
          {[...Array(10)].map((page, i) => <button onClick={() => setStep(parseInt(i + 1))} className={`bg-white opacity-75 rounded-full transition-all duration-200 ${(parseInt(i + 1) === step)? 'p-[6px]' : 'p-[4px] opacity-50 hover:opacity-100'}`}></button>)}
        </div>
      } */}


      {(step !== 10 && step !== 11)?
        <button onClick={() => setStep(step + 1)} className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'>
          <p>{(step)? 'Siguiente' : 'Empezar'}</p>
          {rightArrowIcon}
        </button>
      :
        <button onMouseUp={(event) => closeTutorialFunc()} className='p-[6px] pl-2 pr-3 flex items-center space-x-1 text-sm bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'>
          {cancelIcon}
          <p>Finalizar</p>
        </button>
      }
    </div>
  }







  




  return (currentUser && currentCustomer && location.pathname?.includes('/videos')) && (
    <div className='absolute top-0 left-0 z-[99999] w-screen h-screen text-white'>




      {/* ARROW STEP 1 */}
      {(!step || step === 1 || step === 2) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 1)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-1" end="user-icon-tuto" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }

      {/* ARROW STEP 2 */}
      {(step === 1 || step === 2 || step === 3) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 2)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-2" end="customer-icon-tuto" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }

      {/* ARROW STEP 3 */}
      {(step === 2 || step === 3 || step === 4) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 3)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="title-step-3" end="left-menu-tuto" startAnchor="left" endAnchor="right" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }
      
      {/* ARROW STEP 4 */}
      {(step === 3 || step === 4 || step === 5) && <>
        <div className={`absolute duration-500 pointer-events-none ${(step === 4)? 'z-[999999] min-[2200px]:delay-[1s] opacity-0 min-[2200px]:opacity-100' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-4" end="modules-menu-tuto-1" startAnchor="left" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>

        <div className={`absolute duration-500 pointer-events-none ${(step === 4)? 'z-[999999] delay-[1s] min-[2200px]:delay-[0s] opacity-100 min-[2200px]:opacity-0' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-4" end="modules-menu-tuto-2" startAnchor="left" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      </>}

      {/* ARROW STEP 5 */}
      {(step === 4 || step === 5 || step === 6) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 5)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-5" end="edit-menu-tuto" startAnchor="right" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }

      {/* ARROW STEP 6 */}
      {(step === 5 || step === 6 || step === 7) && <>
        <div className={`absolute duration-500 pointer-events-none ${(step === 6)? 'z-[999999] delay-[1s] opacity-0 xl:opacity-100 min-[1400px]:opacity-0 min-[2200px]:delay-[0s] min-[2200px]:opacity-0' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-6" end="states-menu-tuto-2" startAnchor="right" endAnchor="left" color='white' strokeWidth="2" curveness="-4" showHead={false} dashness/>
        </div>

        <div className={`absolute duration-500 pointer-events-none ${(step === 6)? 'z-[999999] delay-[1s] opacity-100 xl:opacity-0 min-[1400px]:opacity-100 min-[2200px]:delay-[0s] min-[2200px]:opacity-0' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-6" end="states-menu-tuto-2" startAnchor="right" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>

        <div className={`absolute duration-500 pointer-events-none ${(step === 6)? 'z-[999999] delay-[1s] opacity-0 min-[2200px]:opacity-100' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-6" end="states-menu-tuto-1" startAnchor="right" endAnchor="left" color='white' strokeWidth="2" curveness="-2" showHead={false} dashness/>
        </div>
      </>}

      {/* ARROW STEP 7 */}
      {(step === 6 || step === 7 || step === 8) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 7)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-7" end="dirs-menu-tuto" startAnchor="right" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }

      {/* ARROW STEP 8 */}
      {(step === 7 || step === 8 || step === 9) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 8)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-8" end="upload-menu-tuto" startAnchor="right" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }

      {/* ARROW STEP 9 */}
      {(step === 8 || step === 9 || step === 10) &&
        <div className={`absolute duration-500 pointer-events-none ${(step === 9)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0'}`}>
          <Xarrow start="box-step-9" end="upload-menu-tuto" startAnchor="right" endAnchor="bottom" color='white' strokeWidth="2" curveness="0.7" showHead={false} dashness/>
        </div>
      }






      <div className='w-full h-full relative flex flex-col items-center justify-center leading-5'>




        {/* STEP 1 */}
        {(!step || step === 1 || step === 2) &&
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 1)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='pb-4 text-4xl font-bold'>1. Bienvenido a la plataforma "Review"</h1>
            

            <div className='flex items-start space-x-6'>
              <div className='w-full max-w-[170px] flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_user_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>

              <div>
                <p className='pb-4'>Esto es un tutorial automatizado para guiarle a traves de las funciones principales de la plataforma Review.</p>
                <p className='pb-4'>Si ve necesaria un poco de ayuda, estos pasos le explicaran los diferentes botones del menú izquierdo, como subir / crear vídeos y como organizarlos.</p>
                <p id="box-step-1" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>En caso de necesitar repasar este tutorial, podrá reiniciarlo en cualquier momento des de su botón de usuario.</p>
              </div>
            </div>
          

            {buttonsFunc()}
          </div>
        }




        {/* STEP 2 */}
        {(step === 1 || step === 2 || step === 3) &&
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 2)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>2. Para empezar, tus clientes / proyectos</h1>
            <p className='pb-4'>En caso de que tengas acceso a otros clientes, o proyectos si la nomenclatura encaja mejor, podrás entrar en el selector de clientes pulsando <span className="font-bold text-blue-300">sobre la flecha</span> que aparece al pasar sobre la imagen del cliente actual.</p>
            

            <div className='flex space-x-3'>
              <p id="box-step-2" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>Des de este lugar es posible, no solo cambiar de clientes, si no que también podrás administrar los ajustes de tus clientes activos autogestionados.</p>

              <div className='w-full max-w-[110px] bg-gray-50 pl-3 rounded'>
                <img src="tutorial_client_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>


            {buttonsFunc()}
          </div>
        }




        {/* STEP 3 */}
        {(step === 2 || step === 3 || step === 4) &&
          <div className={`absolute px-4 pl-20 md:pl-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 3)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>3. El menú izquierdo y sus apartados</h1>
            <p className='pb-4'>Este menú separa las pestañas que te permiten administrar tus vídeos, marcas, usuarios... En caso de que necesites una breve explicación sobre alguno de estos, clica sobre él en la siguiente lista:</p>
          

            <div className='h-[35px] flex items-center space-x-1'>
              <button
                onClick={() => setActiveMenu((menus[activeMenu?.key - 1])? menus[activeMenu?.key - 1] : menus[menus?.length - 1])}
                className="flex items-center px-1 py-[6px] active:scale-90 duration-200 rounded-md hover:bg-white hover:bg-opacity-10 active:bg-opacity-25"
              >
                {leftArrowIcon}
              </button>

              <div className='w-full h-[35px] overflow-hidden'>
                <div id="menu-box" className='w-full flex items-center space-x-1 overflow-x-auto snap-mandatory'>
                  {menus?.map((menuObj) => {
                    return <div key={`menu-item-${menuObj?.key}`} id={`menu-item-${menuObj?.key}`} className='snap-center pb-1'>
                      <button
                        onClick={() => setActiveMenu(menuObj)}
                        className={`flex items-center space-x-2 p-1 pl-2 pr-3 text-xs active:scale-90 duration-200 rounded-md 
                          ${(menuObj?.name === activeMenu?.name)? 'bg-white bg-opacity-25' : (activeMenu?.name)? 'opacity-50 hover:opacity-100 hover:bg-white hover:bg-opacity-10 active:bg-opacity-25' : 'hover:bg-white hover:bg-opacity-10 active:bg-opacity-25'}
                        `}
                      >
                        {menuObj?.icon}
                        <p>{menuObj?.name}</p>
                      </button>
                    </div>
                  })}
                </div>
              </div>

              <button
                onClick={() => setActiveMenu((menus[activeMenu?.key + 1])? menus[activeMenu?.key + 1] : menus[0])}
                className="flex items-center px-1 py-[6px] active:scale-90 duration-200 rounded-md hover:bg-white hover:bg-opacity-10 active:bg-opacity-25"
              >
                {rightArrowIcon}
              </button>
            </div>


            <div className='pb-1'>
              <div id="title-step-3" className='px-2 py-2 bg-white bg-opacity-10 rounded-md'>
                <div className="pb-2 px-1 flex items-center space-x-2 border-b border-white border-opacity-50">
                  <div>{activeMenu?.icon}</div>
                  <p>{activeMenu?.name}</p>
                </div>
                <p className='px-1 pt-3 pb-1 w-full h-[55px]'>{activeMenu?.description}</p>
              </div>
            </div>


            {buttonsFunc()}
          </div>
        }




        {/* STEP 4 */}
        {(step === 3 || step === 4 || step === 5) &&
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 4)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>4. La organización de tus videos</h1>
            <p id="box-step-4" className='px-2 py-2 bg-gray-50 bg-opacity-20 rounded'>Los {currentCustomer?.label_modules} son la principal fuente de organización de tus vídeos, como ves señalado, desde este botón podrás crearlos y modificarlos a tu voluntad.</p>
            <p className='pt-2 pb-4'>A los vídeos se les asigna un {currentCustomer?.label_module} a la hora de subirlos, o posteriormente, dentro de su configuración. Dicha configuración puede ser encontrada clicando sobre un vídeo dentro del listado general que ves detrás de este tutorial, en el exterior de la miniatura.</p>


            <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
              <img src="tutorial_modules_menu.png" className='w-full max-w-[400px] rounded-md overflow-hidden'/>
            </div>
          

            {buttonsFunc()}
          </div>
        }




        {/* STEP 5 */}
        {(step === 4 || step === 5 || step === 6) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 5)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>5. Como editar vídeos</h1>
            <p className='pb-2'>En caso de que tengas permiso, editar los vídeos será tan fácil como clicar encima de ellos. A excepción de la miniatura, cualquier punto en la tabla sirve para abrir su edición.</p>
            <p id="box-step-5" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>También existe la edición masiva, a pesar de ser mucho más limitada, permite modificar algunos daros sobre multiples vídeos a la vez.</p>

            <div className='pt-4'>
              <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_edit_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>
          

            {buttonsFunc()}
          </div>
        }




        {/* STEP 6 */}
        {(step === 5 || step === 6 || step === 7) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 6)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>6. Estados en los vídeos</h1>
            <p className='pb-2'>Los estados definen en que punto de la producción se encuentra el vídeo, estos permiten a todo el mundo conocer si les toca gestionar su parte del trabajo o esperar a que un compañero acabe la suya.</p>
            <p id="box-step-6" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>En caso de crear un cliente nuevo, asegúrese de añadir nuevos estados que se adapten a sus necesidades des de este botón.</p>


            <div className='pt-4'>
              <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_states_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>
            

            {buttonsFunc()}
          </div>
        }




        {/* STEP 7 */}
        {(step === 6 || step === 7 || step === 8) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 7)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>7. Las carpetas</h1>
            <p className='pb-2'>Dentro de cada {currentCustomer?.label_module} es posible crear una estructura de carpetas. Mientras edites o crees una carpeta, clica en los vídeos que quieras añadir o eliminar para modificar el listado.</p>
            <p id="box-step-7" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>Podrás empezar a crear carpetas des de este botón.</p>


            <div className='pt-4'>
              <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_dirs_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>


            {buttonsFunc()}
          </div>
        }




        {/* STEP 8 */}
        {(step === 7 || step === 8 || step === 9) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 8)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>8. Y antes de acabar, como subir vídeos</h1>
            <p className='pb-2'>Existen diferentes formas de subir vídeos a la plataforma, la herramienta que le mostraremos a continuación permite subirlos de forma rápida y masiva. Simplemente, es necesario arrastrar los vídeos a la caja central para poder subirlos.</p>
            <p id="box-step-8" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>Podrás acceder a este apartado des de este boton.</p>


            <div className='pt-4'>
              <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_upload_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>


            {buttonsFunc()}
          </div>
        }




        {/* STEP 9 */}
        {(step === 8 || step === 9 || step === 10) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 9)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>9. Creación de vídeos sin fichero</h1>
            <p className='pb-2'>Si es necesario tener un vídeo preparado antes de su grabación, o de la obtención de su fichero, también se podrán crear simplemente introduciendo sus nombres dentro de la caja de texto en la pestaña de subida de vídeos masiva.</p>
            <p id="box-step-9" className='px-2 py-1 bg-gray-50 bg-opacity-20 rounded'>El apartado esta señalizado con una "T" en la esquina de la caja de subida de ficheros.</p>


            <div className='pt-4'>
              <div className='w-full flex items-center justify-center bg-black bg-opacity-20 rounded'>
                <img src="tutorial_text_menu.png" className='w-full rounded-md overflow-hidden'/>
              </div>
            </div>


            {buttonsFunc()}
          </div>
        }




        {/* STEP 10 */}
        {(step === 9 || step === 10 || step === 11) && 
          <div className={`absolute px-4 max-w-[700px] flex-col items-center space-y-1 duration-500 ${(step === 10)? 'z-[999999] delay-[1s]' : 'z-[3] opacity-0 pointer-events-none'}`}>
            <h1 className='text-4xl font-bold pb-2'>10. Y mucho más por descubrir</h1>
            <p className='pb-2'>Es cierto que la herramienta de Review tiene muchas más herramientas o formas de hacer algunas cosas de las que hemos enseñado, pero tampoco nos cabe todo aquí. Por ello le animamos a que preste atención e investigue las herramientas que nos hayamos podido dejar.</p>
            <p className='pb-2'>Esperamos que se sientan comodos con la plataforma y hasta aquí llega este tutorial.</p>

            {buttonsFunc()}
          </div>
        }




      </div>
    </div>
  );
}