import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext';
import { FuncContext } from '../contexts/FuncContext'
import { useParams } from "react-router-dom";

import MenuVideoPlayerMarks from './MenuVideoPlayerMarks';
import VideoPlayerSeek from './VideoPlayerSeek';








export default function VideoPlayer({
  
  customerToken,
  videoObj,
  reviews,
  selectedReview,
  thumbnail,

  complex,
  newVideoMark,
  createMark,
  reloadThumbnails,
  mode,
  marks,
  showMarksOnScreen,

  init,
  audioRef,

}) {




  const { setIsFullScreenLayout } = useContext(LayoutContext)
  const { agentHas } = useContext(FuncContext)
  const { pathTime } = useParams()

  



  /* Video control vars */
  const [play, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.51);
  const [lowresActive, setLowresActive] = useState(selectedReview?.lowres_version);
  const [speed, setSpeed] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);

  const [seekPosition, setSeekPosition] = useState(0);
  const [seekMax, setSeekMax] = useState(1);

  const [mediaIsLoaded, setMediaIsLoaded] = useState(false);

  /* Video screen vars */
  const [showRestartScreen, setShowRestartScreen] = useState(false);
  const [updateMarkCoordinates, setUpdateMarkCoordinates] = useState(false);
  const [neverPlayed, setNeverPlayed] = useState(true);
  const [selectedMark, setSelectedMark] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrollDifference, setScrollDifference] = useState(true);

  /* Icon vars */
  const playIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M386 814q-21 14-43 2t-22-38V372q0-26 22-38t43 2l319 204q20 12 20 35t-20 35L386 814Z"/></svg>;
  const stopIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M600 856q-34.65 0-59.325-24.675Q516 806.65 516 772V380q0-34.65 24.675-59.325Q565.35 296 600 296h76q34.65 0 59.325 24.675Q760 345.35 760 380v392q0 34.65-24.675 59.325Q710.65 856 676 856h-76Zm-316 0q-34.65 0-59.325-24.675Q200 806.65 200 772V380q0-34.65 24.675-59.325Q249.35 296 284 296h76q34.65 0 59.325 24.675Q444 345.35 444 380v392q0 34.65-24.675 59.325Q394.65 856 360 856h-76Z"/></svg>;
  const restartIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M391 924q-102-29-166.5-114T160 616q0-53 16.5-102t47.5-91q11-14 29.5-15t32.5 13q11 11 11.5 26.5T288 477q-22 30-33 65.5T244 616q0 78 44 139.5T404 841q14 5 24 18t10 27q0 20-14 31.5t-33 6.5Zm178 0q-19 5-33-6.5T522 886q0-14 10-27t24-18q72-24 116-85.5T716 616q0-99-68-167.5T482 380h-1l12 12q12 12 12 29t-12 29q-13 13-30 13t-29-13l-83-82q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l83-83q12-12 29-12t30 12q12 13 12 30t-12 29l-12 12h1q134 0 226 93t92 227q0 109-64.5 194T569 924Z"/></svg>
  const mutedVolumeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="m729 959-70-70q-12 7-24 13t-26 11q-21 8-39-6.5T552 869q0-11 6.5-20t17.5-13q5-2 10.5-4.5T597 827L468 698v98q0 28-25.5 39t-45.5-9L263 696H151q-17 0-29.5-12.5T109 654V496q0-17 12.5-29.5T151 454h73L93 323q-13-13-13-30t13-30q13-13 30-13t30 13l636 637q12 12 12 29t-12 30q-12 13-29.5 13T729 959ZM609 237q105 39 168.5 131T841 575q0 54-15 104.5T782 773l-61-61q17-31 26.5-65.5T757 575q0-88-50-159.5T576 314q-11-4-17.5-13.5T552 280q0-23 18-37t39-6Zm33 396-90-90V418q46 21 73 63.5t27 93.5q0 15-2.5 29.5T642 633ZM468 458 365 355l32-31q20-20 45.5-9t25.5 39v104Z"/></svg>;
  const halfVolumeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M242 696q-17 0-29.5-12.5T200 654V498q0-17 12.5-29.5T242 456h112l135-131q20-20 45.5-9t25.5 39v442q0 28-25.5 39t-45.5-9L354 696H242Zm402 39V417q45 23 70.5 65t25.5 95q0 53-25.5 94T644 735Z"/></svg>;
  const fullVolumeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M552 904v-75q86-23 139-93.5T744 576q0-89-53.5-158.5T552 322v-75q116 25 190 117t74 211q0 119-73.5 211.5T552 904ZM144 671V479h144l192-192v576L288 671H144Zm408 55V424q45 20 70.5 61t25.5 90q0 49-25.5 89.5T552 726Z"/></svg>;
  const fullscreenIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M264-264h84q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-213 373.65-202.5 363.3-192 348-192H228q-15.3 0-25.65-10.35Q192-212.7 192-228v-120q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-384 253.5-373.65 264-363.3 264-348v84Zm0-432v84q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q213-576 202.5-586.35 192-596.7 192-612v-120q0-15.3 10.35-25.65Q212.7-768 228-768h120q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-717 373.65-706.5 363.3-696 348-696h-84Zm432 432v-84q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-384 757.5-373.65 768-363.3 768-348v120q0 15.3-10.35 25.65Q747.3-192 732-192H612q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q576-243 586.35-253.5 596.7-264 612-264h84Zm0-432h-84q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q576-747 586.35-757.5 596.7-768 612-768h120q15.3 0 25.65 10.35Q768-747.3 768-732v120q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q717-576 706.5-586.35 696-596.7 696-612v-84Z"/></svg>
  const smallscreenIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M336-336h-84q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q216-387 226.35-397.5 236.7-408 252-408h120q15.3 0 25.65 10.35Q408-387.3 408-372v120q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q357-216 346.5-226.35 336-236.7 336-252v-84Zm288 0v84q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q573-216 562.5-226.35 552-236.7 552-252v-120q0-15.3 10.35-25.65Q572.7-408 588-408h120q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q744-357 733.65-346.5 723.3-336 708-336h-84ZM336-624v-84q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q387-744 397.5-733.65 408-723.3 408-708v120q0 15.3-10.35 25.65Q387.3-552 372-552H252q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q216-603 226.35-613.5 236.7-624 252-624h84Zm288 0h84q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q744-573 733.65-562.5 723.3-552 708-552H588q-15.3 0-25.65-10.35Q552-572.7 552-588v-120q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q603-744 613.5-733.65 624-723.3 624-708v84Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;







  /* Play */
  useEffect(() => {    
    var video = document.getElementById("video");
    if (play && video) { video.play(); setNeverPlayed(false); setAnchorEl(null); } 
    else if (video) { video.pause() }
  }, [play])


  /* Volume */
  useEffect(() => {    
    var video = document.getElementById("video");
    if (video) {
      if (volume) { video.volume = volume } 
      else { video.volume = '0' }
    }
  }, [volume])

  /* Speed */
  useEffect(() => {    
    var video = document.getElementById("video");
    if (video) {
      video.defaultPlaybackRate = 1;
      video.playbackRate = parseFloat(speed);
    }
  }, [speed, lowresActive])

  /* FullsSreen */
  useEffect(() => {    
    var video = document.getElementById("video");
    if (video) {
      if (fullScreen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    }
  }, [fullScreen])




  


  useEffect(() => {  
    var wants_lowres_cookie = parseInt(localStorage.getItem('default-video-res'))
    if (selectedReview?.lowres_version && (wants_lowres_cookie || wants_lowres_cookie === 0)) {
      setLowresActive((parseInt(wants_lowres_cookie))? true : false)
    } else {
      setLowresActive((selectedReview?.lowres_version)? true : false)
    }

    if (reviews?.length && reviews[0]?.id !== selectedReview?.id && selectedReview?.lowres_version) {
      setLowresActive(true)
    }
  }, [selectedReview])


  useEffect(() => {  
    if (updateMarkCoordinates) {
      setUpdateMarkCoordinates(false)
      refreshMarkVisibility()
    }
  }, [updateMarkCoordinates])
  

  useEffect(() => {
    if (marks && showMarksOnScreen) {
      refreshMarkVisibility()
    }
  }, [mode, marks, newVideoMark, showMarksOnScreen])


  useEffect(() => {    
    if (anchorEl) {
      showCircleOnHover(false)
    }
  }, [anchorEl])

  
  useEffect(() => {    
    if (newVideoMark) { 
      setPlay(false)
    } 
  }, [newVideoMark])


  /* Listeners */
  useEffect(() => { 
    var video = document.getElementById("video");
    let circle = document.getElementById("circle");
    setSelectedMark(null)


    if (video) { video.addEventListener('loadedmetadata', initializeVideo);  }


    function initializeVideo() {  
      const video = document.getElementById("video");
      const duration = document.getElementById('duration');
      let circle = document.getElementById("circle");
  
      setMediaIsLoaded(true)

      if (video) {
        setPlay(false)
        setNeverPlayed(true)


        setSeekMax(video.duration)
        const time = formatTime(video.duration);
        if (duration) {
          duration.innerText = `${(time.hours !== '00')? time.hours+':' : ''}${time.minutes}:${time.seconds}`;
          duration.setAttribute('datetime', `${time.hours}h ${time.minutes}m ${time.seconds}s`)
        }

  
        setTimeout(()=>{
          if (video && pathTime && pathTime?.split(':')?.length === 3) {
            const pathTimeSplit = pathTime.split(':');
            video.currentTime = ((+pathTimeSplit[0]) * 60 * 60 + (+pathTimeSplit[1]) * 60 + (+pathTimeSplit[2]))
          }
        }, 200)
  

        video.addEventListener('timeupdate', timeupdateSimplified);
        if (complex && !video?.is_sound_media && selectedReview?.filename?.includes('.mp4')) { reloadThumbnails() }
      }
  
      if (circle) {
        document.addEventListener("mousemove", mousemoveSimplified);
        document.addEventListener("scroll", scrollSimplified);
      }
    }

    function timeupdateSimplified() { 
      updateTimeElapsed();
      updateProgress();
      setUpdateMarkCoordinates(true)
    }

    function mousemoveSimplified(e) { 
      move(e);
      circle.style.display = 'flex'
    }

    function scrollSimplified() { 
      circle.style.display = 'none'; 
      if (document.documentElement.scrollTop > 70) { setScrollDifference(false) } else { setScrollDifference(true) } 
    }

    function move(e) { 
      let circle = document.getElementById("circle");
      if (circle && circle.style.opacity !== '0') {
        var window_x = window.innerWidth - 60;
        var window_y = window.innerHeight - 60;

        const cursor_x_tmp = (!isTouchDevice())? e.pageX : e.touches[0].pageX;
        const cursor_y_tmp = (!isTouchDevice())? e.pageY : e.touches[0].pageY;
        const x = (cursor_x_tmp > window_x)? window_x : cursor_x_tmp;
        const y = (cursor_y_tmp > window_y)? window_y : cursor_y_tmp;

        circle.style.left = x - 50 + "px";
        circle.style.top = y - 50 + "px";
      }
    };




    return () => {
      video?.removeEventListener('loadedmetadata', initializeVideo); 
      video?.removeEventListener('timeupdate', timeupdateSimplified);
      document?.removeEventListener("mousemove", mousemoveSimplified);
      document?.removeEventListener("scroll", scrollSimplified);
    };
  }, [selectedReview])







  /* Restart video */
  function restartVideo() {
    var video = document.getElementById("video");
    if (video) {
      video.currentTime = 0;
      setSeekPosition(0)
    }
  }

  /* Check device type */
  function isTouchDevice() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  /* Show and hidde create mark tooltip */
  function showCircleOnHover(hovering) {
    let circle = document.getElementById("circle");
    if (circle) {
      if (hovering && mode === 'review' && !fullScreen) {
        circle.style.opacity = 0.6;
      } else {
        circle.style.opacity = 0;
      }
    }
  }

  /* Refresh mark dots on screen */
  function refreshMarkVisibility() {
    var video = document.getElementById("video");

    marks?.map((markTMP, key) => {
      var videoMarkElement = document.getElementById('video-mark-item-'+key);
      if (video && videoMarkElement) {
        showOrHidde(markTMP, videoMarkElement)
      }
    });

    var videoMarkElement = document.getElementById('video-mark-item-new');
    if (video && videoMarkElement && newVideoMark) {
      showOrHidde(newVideoMark, videoMarkElement)
    }

    function showOrHidde(markTMP, videoMarkElement) {
      var a = markTMP.time.split(':');
      var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 

      if (video.currentTime >= seconds && video.currentTime <= (seconds+3)) {
        videoMarkElement.classList.remove("hidden")
      } else if (!videoMarkElement.classList.contains("hidden")) {
        videoMarkElement.classList.add("hidden")
      }
    }

  }




  /* Seconds to HH:mm:ss */
  function formatTime(timeInSeconds) {
    const result = (timeInSeconds)? new Date(timeInSeconds * 1000)?.toISOString().substr(11, 8) : null;

    return {
      hours: (result)? result?.substr(0, 2) : 0,
      minutes: (result)? result?.substr(3, 2) : 0,
      seconds: (result)? result?.substr(6, 2) : 0,
    };
  };

  /* Indicates how far through the video the current playback is */
  function updateTimeElapsed() {
    const video = document.getElementById("video");
    const timeElapsed = document.getElementById('time-elapsed');
    

    if (video) {
      const time = formatTime(video.currentTime);
      timeElapsed.innerText = `${(time.hours !== '00')? time.hours+':' : ''}${time.minutes}:${time.seconds}`;
      timeElapsed.setAttribute('datetime', `${time.hours}h ${time.minutes}m ${time.seconds}s`)
      if (video.currentTime !== video.duration) { setShowRestartScreen(false); }
    }
  }

  /* Updates current second on the clock */
  function updateProgress() {
    const video = document.getElementById("video");

    if (video) {
      if (video.currentTime >= 0.2) { setNeverPlayed(false); }
      if (video.currentTime === video.duration) { setShowRestartScreen(true); setPlay(false) }
      else if (showRestartScreen) { setShowRestartScreen(false); }

      if (video.currentTime === video.duration) {
        setSeekPosition(video.duration)
      } else {
        setSeekPosition(Math.round(video.currentTime * 100) / 100)
      }
    }
  }








  /* Controls */
  window.onkeydown = (e) => {
    keyControlsFunc(e)
  }

  function keyControlsFunc(e) {
    e = e || window.event;

    var video = document.getElementById("video");
    const seek = document.getElementById('seek');
    const metaKey = (e.code?.includes('Meta') || e.keyCode === 91 || e.keyCode === 93 || e.keyCode === 17 || e.keyCode === 224 || e.metaKey)? true : false
    setAnchorEl(null)


    if (video && seek && mediaIsLoaded && ((document.activeElement.value === undefined && !document.activeElement.className?.includes('ql-editor')) || (document.activeElement.id.includes('seek') || document.activeElement.id.includes('video')))) {
      /* Spacebar plays and stops video */
      if (e.keyCode === 32) {
        e.preventDefault();
        setPlay(!play)
        setNeverPlayed(false)
      }



      /* Arrow < -5 seconds */
      if (e.keyCode === 37) {
        const skipTo = video.currentTime - ((video.duration > 20)? 5 : 1);
        video.currentTime = skipTo;
        setSeekPosition(skipTo)
        setNeverPlayed(false)
      }
      
      /* Arrow > +5 seconds */
      if (e.keyCode === 39) {
        const skipTo = video.currentTime + ((video.duration > 20)? 5 : 1);
        video.currentTime = skipTo;
        setSeekPosition(skipTo)
        setNeverPlayed(false)
      }



      /* ESC exit fullscreen */
      if ((e.keyCode === 27 || e.keyCode === 70) && !metaKey && !e.ctrlKey && !e.shiftKey && fullScreen && !selectedReview?.filename?.includes('mp3')) {
        setIsFullScreenLayout(false)
        setFullScreen(false)
      }

      /* F enters fullscreen */
      if (e.keyCode === 70 && !metaKey && !e.ctrlKey && !e.shiftKey && !fullScreen && !selectedReview?.filename?.includes('mp3')) {
        setIsFullScreenLayout(true)
        setFullScreen(true)
      }



      /* M silence the video */
      if (e.keyCode === 77) {
        setVolume((volume)? 0 : 0.5)
      }
    }
  }


  function resChangeFunc(lowresActiveTMP) { 
    var video = document.getElementById("video");
    if (video) {
      var entred = false;
      var wasPlaying = play;
      var lastTime = video.currentTime;
      
      if (!lowresActive && lowresActiveTMP) { 
        entred = true;
        setLowresActive(lowresActiveTMP); 
        localStorage.setItem('default-video-res', 1) 
      } else if (lowresActive && !lowresActiveTMP) {
        entred = true;
        setLowresActive(lowresActiveTMP); 
        localStorage.setItem('default-video-res', 0) 
      }

      setTimeout(()=>{
        if (entred) {
          var video = document.getElementById("video");
          video.currentTime = lastTime;

          if (wasPlaying) { setPlay(true) }
        }
      }, 1000)
    }
  }






  return (customerToken) && (
    <div
      className={`
        bg-[#0e131f] overflow-hidden max-h-screen 
        ${(fullScreen)? 'fixed w-screen h-screen left-0 top-0 z-[99]' : 'w-full relative rounded-lg'}
      `}
    >
      
      
      
      <div id="video-container" style={{ backgroundColor: (fullScreen)? 'transparent' : 'rgb(55 65 81)' }} className={`${(selectedReview?.filename?.includes('mp3'))? 'audio-container' : 'video-container'} max-h-screen ${(fullScreen)? 'w-full h-full' : 'h-[calc(100%-52px)] mb-[36.5px]'}`}>
        <div className={`${(fullScreen)? '' : 'overflow-hidden rounded-lg border-4 border-gray-700'} absolute w-full h-full max-h-screen top-0 ${(fullScreen) && 'bottom-0'}`}>
          

          {(!selectedReview?.filename?.includes('mp3'))? 
            <>

              {(!play && showRestartScreen) && <>
                <div 
                  className={`absolute z-30 h-full w-full top-0 flex justify-center items-center cursor-pointer bg-opacity-40 hover:bg-opacity-30 bg-black duration-200`}
                  onClick={()=>{
                    restartVideo(); 
                    setPlay(true);
                  }}
                >
                  <div className={`p-2 hover:p-5 rounded-full bg-white hover:bg-gray-200 opacity-75 hover:opacity-100 duration-200 ${(fullScreen) && 'mt-4'}`}>
                    {restartIcon}
                  </div>
                </div>
              </>}

              {((neverPlayed || mode !== 'review' || fullScreen) && thumbnail && thumbnail !== '' && !showRestartScreen) && <>
                <div 
                  id="video-thumbnail-player" 
                  className={`absolute z-30 h-full w-full top-0 flex justify-center items-center cursor-pointer ${(fullScreen && play)? 'opacity-0 bg-opacity-40 hover:bg-opacity-30' : ((play)? 'bg-opacity-10 opacity-0 hover:opacity-100' : 'bg-opacity-40 hover:bg-opacity-30')} bg-black duration-200`}
                  onClick={()=>{ if (mediaIsLoaded) { setPlay(!play) } }}
                >
                  <div className='p-2 hover:p-5 rounded-full bg-white hover:bg-gray-200 opacity-75 hover:opacity-100 duration-200'>
                    {(!mediaIsLoaded)? loadingIcon : ((play)? stopIcon : playIcon)}
                  </div>
                </div>

                {(neverPlayed && thumbnail) && 
                  <div id="video-thumbnail" className={`absolute z-20 h-full w-full top-0 flex justify-center items-center bg-gray-900`}>
                    <img className='h-full w-full object-fill' src={thumbnail} alt='video_thumbnail'/>  
                  </div>
                }
              </>}

              {(complex && showMarksOnScreen && !selectedReview?.filename?.includes('mp3')) && <>
                <div 
                  id="video-mark-screen"
                  className={`h-full w-full absolute top-0 z-30 pointer-events-none`}
                >
                  {marks?.map((markTMP, key) => {
                    if (markTMP.coordinates && !(markTMP.state === 3 && parseInt(selectedReview?.id) !== parseInt(markTMP?.review_id)) && document.getElementById('video')) {
                      var coordinatesTMP = markTMP.coordinates.split(',')
                      var x = 100
                      var y = 100
          
                      if (coordinatesTMP?.length === 2) {
                        var x = (parseFloat(coordinatesTMP[0]) * document.getElementById('video').clientWidth) / 100
                        var y = (parseFloat(coordinatesTMP[1]) * document.getElementById('video').clientHeight) / 100
                      }

                      return <div 
                        key={'video-mark-item-'+key}
                        id={'video-mark-item-'+key}
                        style={{ top: `${y}px`, left:`${x}px` }}
                        className={`absolute w-3 h-3 border-2 hover:border-4 hover:scale-[2] text-sm rounded-full bg-gray-50 border-gray-700 pointer-events-auto cursor-pointer duration-200 hidden`}
                        onMouseOver={()=>showCircleOnHover(0)}
                        onClick={(event)=>{
                          setPlay(false)
                          setSelectedMark(markTMP); 
                          setAnchorEl(event.currentTarget)

                          var focusDiv = document.getElementById('video-mark-list-item-focus-'+markTMP?.id)
                          if (focusDiv) { window.scrollTo({ top: focusDiv.getBoundingClientRect()?.top - document.body.getBoundingClientRect()?.top - 145 }) }
                        }}
                      ></div>
                    }
                  })}
                  {(newVideoMark?.coordinates) && (
                    <div 
                      id={'video-mark-item-new'}
                      style={{ top: `${(parseFloat(newVideoMark.coordinates.split(',')[1]) * document.getElementById('video').clientHeight) / 100}px`, left:`${(parseFloat(newVideoMark.coordinates.split(',')[0]) * document.getElementById('video').clientWidth) / 100}px` }}
                      className={`absolute w-3 h-3 border-4 hover:border-4 hover:scale-[2] text-sm rounded-full bg-blue-400 border-gray-700 pointer-events-auto cursor-pointer duration-200 hidden`}
                      onMouseOver={()=>showCircleOnHover(0)}
                      onClick={(event)=>{
                        setPlay(false)
                        setSelectedMark(newVideoMark); 
                        setAnchorEl(event.currentTarget)
                      }}
                    ></div>
                  )}
                </div>
                <div className='pointer-events-none'>
                  <MenuVideoPlayerMarks
                    video={videoObj}
                    selectedMark={selectedMark}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    scrollDifference={scrollDifference}
                  />
                </div>
              </>}

          
              <div className='relative h-[100%] bg-gray-900'>
                <video 
                  id="video" 
                  preload="metadata" 
                  className={`video cursor-pointer bg-transparent ${(fullScreen)? 'w-screen h-screen' : 'w-full h-full'}`}
                  style={{ height: '100px !important' }} 
                  onClick={(event)=> {
                    if (mediaIsLoaded) {
                      if (complex && mode === 'review' && !fullScreen) {
                        createMark(event)
                        setUpdateMarkCoordinates(true)
                      } else {
                        setPlay(!play)
                      }
                    }
                  }}
                  onMouseOver={()=>showCircleOnHover(1)}
                  onMouseOut={()=>showCircleOnHover(0)}
                  src={`${process.env.REACT_APP_API_ROUTE}mediaplayer/${customerToken}/${((lowresActive && selectedReview.lowres_version)? 1 : 0)}/${selectedReview.filename}`}
                />
              </div>
            </>
          : 
            <>

              <audio 
                id="video" 
                preload="metadata" 
                className={`video cursor-pointer bg-transparent ${(fullScreen)? 'w-screen h-screen' : 'w-full h-full'}`}
                style={{ margin: 'auto 0' }} 
                onClick={(event)=> {
                  if (mediaIsLoaded) {
                    if (complex && mode === 'review' && !fullScreen) {
                      createMark(null)
                    } else {
                      setPlay(!play)
                    }
                  }
                }}
                src={`${process.env.REACT_APP_API_ROUTE}mediaplayer/${customerToken}/${((lowresActive && selectedReview.lowres_version)? 1 : 0)}/${selectedReview.filename}`}
                crossOrigin="anonymous"
                ref={audioRef} 
                onPlay={init}
              ></audio>
            </>
          }



        </div>
      </div>
    




      
      <div id="video-controls" className={` ${(fullScreen)? "fixed left-0 bottom-0" : "absolute left-0 rounded-b-lg"} z-40 text-white bg-gray-700 w-full flex items-center p-1 space-x-1 mt-[-40px] duration-300`}>
        <div 
          className='cursor-pointer p-[6px] hover:bg-gray-600 active:bg-gray-400 rounded-md transition duration-200' 
          onClick={()=> { if (mediaIsLoaded) { setPlay(!play) } }}
        >
          {(!mediaIsLoaded)? loadingIcon : ((play)? stopIcon : (showRestartScreen? restartIcon : playIcon))}
        </div>


        <div className='relative'>
          <div className='volume-slider'>
            <div className='min-w-[20px] mr-1 cursor-pointer' onClick={()=>volume? setVolume(0) : setVolume(0.5)}>
              {(parseFloat(volume))? ((parseFloat(volume) > 0.5)? fullVolumeIcon : halfVolumeIcon) : mutedVolumeIcon}
            </div>

            <input 
              id="video-volume-range"
              value={volume? volume : '0'}
              onChange={(event)=>setVolume(event.target.value)}
              className={`volume-range rounded-lg appearance-none bg-gray-400 h-[10px] transition-all duration-300 w-full cursor-pointer outline-0 ${agentHas("Chrome")? 'overflow-hidden' : ''}`}
              type="range"
              min="0"
              max="1"
              step="0.01"
              onKeyDown={(e)=>{
                e.preventDefault();
              }}
            />
          </div>
        </div>


        <div className='w-full flex items-center space-x-1 text-sm 2xl:text-base'>
          <div className="video-progress w-full flex items-center space-x-2 px-2 mr-1">
            <VideoPlayerSeek
              videoObj={videoObj}
              selectedReview={selectedReview}
              mediaIsLoaded={mediaIsLoaded}

              seekPosition={seekPosition}
              setSeekPosition={setSeekPosition}
              seekMax={seekMax}
            />
          </div>


          {/* Speed */}
          <div className='pr-[6px]'>
            <div
              style={{ margin: 0 }}
              className={`relative overflow-hidden group/speed h-full py-[16px] w-[35px] hover:w-[200px] hover:bg-gray-600 hover:bg-opacity-30 flex items-center whitespace-nowrap text-xs rounded transition-all duration-500 first-letter`}
            >
              <div className='z-10 absolute left-0 group-hover/speed:left-[160px]'>
                <button 
                  className={`
                    p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                    ${(speed === 7)? 'text-white' : 'text-gray-500 opacity-0 group-hover/speed:opacity-100'}
                  `}
                  onClick={()=> setSpeed(7)}
                > x7,0 </button>
              </div>


              <div className='z-10 absolute left-0 group-hover/speed:left-[120px]'>
                <button 
                  className={`
                    p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                    ${(speed === 2)? 'text-white' : 'text-gray-500 opacity-0 group-hover/speed:opacity-100'}
                  `}
                  onClick={()=> setSpeed(2)}
                > x2,0 </button>
              </div>


              <div className='z-10 absolute left-0 group-hover/speed:left-[80px]'>
                <button 
                  className={`
                    p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                    ${(speed === 1.5)? 'text-white' : 'text-gray-500 opacity-0 group-hover/speed:opacity-100'}
                  `}
                  onClick={()=> setSpeed(1.5)}
                > x1,5 </button>
              </div>
              
              
              <div className={`absolute left-0 group-hover/speed:left-[40px] transition-all duration-300`}>
                <button 
                  className={`
                    p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                    ${(speed === 1)? 'text-white' : 'text-gray-500 opacity-0 group-hover/speed:opacity-100'}
                  `}
                  onClick={()=> setSpeed(1)}
                > x1,0 </button>
              </div>


              <div className='z-10 absolute left-0'>
                <button 
                  className={`
                    p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                    ${(speed === 0.5)? 'text-white' : 'text-gray-500 opacity-0 group-hover/speed:opacity-100'}
                  `}
                  onClick={()=> setSpeed(0.5)}
                > x0,5 </button>
              </div>

            </div>
          </div>


          {/* Resolution */}
          {(!selectedReview?.filename?.includes('mp3')) &&
            <div>
              <div style={{ margin: 0 }} className={`relative group/resolution h-full py-[16px] w-[40px] hover:bg-gray-600 hover:bg-opacity-30 flex items-center whitespace-nowrap text-xs rounded transition-all duration-300 first-letter ${(complex && selectedReview?.lowres_version && reviews[0]?.id === selectedReview?.id)? 'hover:w-[93px] ' : ''}`}>
                {(complex && reviews[0]?.id === selectedReview?.id || !selectedReview?.lowres_version) && 
                  <div className='z-10 absolute right-0'>
                    <button 
                      className={`
                        p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                        ${(!lowresActive)? 'text-white' : 'text-gray-500 opacity-0 group-hover/resolution:opacity-100'}
                      `}
                      onClick={()=> resChangeFunc(false)}
                    > {(selectedReview?.lowres_version)? '1080p' : '720p'} </button>
                  </div>
                }
                
                {(selectedReview?.lowres_version) && 
                  <div className={`absolute right-0 transition-all duration-300 ${(complex && reviews[0]?.id === selectedReview?.id)? 'group-hover/resolution:pr-[50px]' : ''}`}>
                    <button 
                      className={`
                        p-2 hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer
                        ${(lowresActive)? 'text-white' : 'text-gray-500 opacity-0 group-hover/resolution:opacity-100'}
                      `}
                      onClick={()=> resChangeFunc(true)}
                    > 720p </button>
                  </div>
                }
              </div>
            </div>
          }


          {/* Timer */}
          <div className="time whitespace-nowrap p-2 text-xs">
            <time id="time-elapsed">00:00</time>
            <span> / </span>
            <time id="duration">00:00</time>
          </div>


          {/* Resolution */}
          {(!selectedReview?.filename?.includes('mp3')) &&
            <div className={`relative group/fullscreen hover:bg-gray-600 hover:bg-opacity-30 flex items-center whitespace-nowrap text-xs rounded transition-all duration-300 first-letter select-none`}>
              <div 
                className={`p-[6px] hover:bg-gray-600 active:bg-gray-400 duration-200 rounded cursor-pointer text-white`}
                onClick={()=> {
                  setIsFullScreenLayout(!fullScreen)
                  setFullScreen(!fullScreen)
                }}
              > {(fullScreen)? smallscreenIcon : fullscreenIcon} </div>
            </div>
          }


        </div>
      </div>
    </div>
  );
}