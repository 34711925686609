import gql from "graphql-tag";
export default gql`
  mutation ChangeReviewLog(

    $own_user_token: String!, 

    $id: ID!, 
    $log: String, 
    
  ) {
    
    changeReviewLog(

      own_user_token: $own_user_token, 

      id: $id, 
      log: $log 
    
    ) {

      id
      log
      filename
      lowres_version
      time
      minutes
      time_added
      minutes_added
      user_id
      video_id
      dateAt
      createdAt
      updatedAt
      
      dateAtFormated
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
