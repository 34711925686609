import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import { FuncContext } from '../../../contexts/FuncContext';
import { LayoutContext } from '../../../contexts/LayoutContext';
import { useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import RowHistoryMenu from './RowHistoryMenu';

import GetBillsByF10IDs from '../../../graphql/queries/GetBillsByF10IDs'

import Tooltip from '@mui/material/Tooltip';
import RowButtonsMenu from './RowButtonsMenu';









export default function RowButtons({ row, factura10Bill, billOption, hiddenItems, setHiddenItems, disableHidden }) {


    const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
    const { remove_tags } = useContext(FuncContext)
    const { setErrorAlertMsg } = useContext(LayoutContext)

    

    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState(null);
    const [extraHistory, setExtraHistory] = useState(null);
    const [noteText, setNoteText] = useState(null);
    const [savedNoteText, setSavedNoteText] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElButtons, setAnchorElButtons] = useState(null);
    


    const conceptBillIDs = (history?.concepts)? [...new Set(Object?.keys(history?.concepts)?.map((el) => parseInt(history?.concepts[el]?.document_id)))] : null
    const extraBillIDs = (extraHistory?.length)? [...new Set(extraHistory?.map((el) => parseInt(el?.bill_id)))] : null
    /* const billIDs = (conceptBillIDs?.length && extraBillIDs?.length)? [...new Set([...conceptBillIDs, ...extraBillIDs])] : (conceptBillIDs?.length)? [...new Set([...conceptBillIDs])] : (extraBillIDs?.length)? [...new Set([...extraBillIDs])] : null */



    const noteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m552-216 192-192H588q-15.3 0-25.65 10.35Q552-387.3 552-372v156Zm-336.34 72Q186-144 165-165.15 144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.11Q816-773.77 816-744.12v329.74q0 14.38-5 27.38-5 13-16.2 24.2L597.2-165.2Q586-154 572.97-149q-13.04 5-27.42 5H215.66ZM324-408h120q15.3 0 25.65-10.29Q480-428.58 480-443.79t-10.35-25.71Q459.3-480 444-480H324q-15.3 0-25.65 10.29Q288-459.42 288-444.21t10.35 25.71Q308.7-408 324-408Zm0-144h312q15.3 0 25.65-10.29Q672-572.58 672-587.79t-10.35-25.71Q651.3-624 636-624H324q-15.3 0-25.65 10.29Q288-603.42 288-588.21t10.35 25.71Q308.7-552 324-552Z"/></svg>
    const buttonStyles = 'bg-gray-400 bg-opacity-[0.25] border border-gray-400 border-opacity-20 rounded text-gray-400 hover:text-gray-600 hover:bg-opacity-50 cursor-pointer duration-200';
    const historyIcon = <svg className='scale-[0.9]' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-144q-132 0-226.5-87.5T146-447q-2-14 8.5-23.5t25.439-9.5Q194-480 205-472t13 22q11 99 85.5 166.5T480-216q109 0 186.5-77.5T744-480q0-109-77.5-186.5T480-744q-62 0-114.547 25.6Q312.907-692.8 277-648h71q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-597 373.65-586.5 363.3-576 348-576H180q-15.3 0-25.65-10.35Q144-596.7 144-612v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195-816 205.5-805.65 216-795.3 216-780v94q46-60 114.5-95T480-816q70 0 131.133 26.6 61.134 26.6 106.4 71.867 45.267 45.266 71.867 106.4Q816-550 816-480t-26.6 131.133q-26.6 61.134-71.867 106.4-45.266 45.267-106.4 71.867Q550-144 480-144Zm36-366 90 90q11 11 10.5 25T605-370q-11 11-25.5 11T554-370l-98.712-98.712Q450-474 447-480.689t-3-13.825v-141.81q0-15.163 10.5-25.419Q465-672 480-672t25.5 10.35Q516-651.3 516-636v126Z"/></svg>
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
    const menuIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.79-192Q450-192 429-213.21t-21-51Q408-294 429.21-315t51-21Q510-336 531-314.79t21 51Q552-234 530.79-213t-51 21Zm0-216Q450-408 429-429.21t-21-51Q408-510 429.21-531t51-21Q510-552 531-530.79t21 51Q552-450 530.79-429t-51 21Zm0-216Q450-624 429-645.21t-21-51Q408-726 429.21-747t51-21Q510-768 531-746.79t21 51Q552-666 530.79-645t-51 21Z"/></svg>





    const [getBillsByF10IDs, { data: dataBills, loading: loadingBills }] = useLazyQuery(GetBillsByF10IDs, { 
        fetchPolicy: 'no-cache',
        variables: { 
            own_user_token: currentUser?.token,
            own_customer_token: currentCustomer?.token,
            ids: (conceptBillIDs?.length)? conceptBillIDs : null,
            obj_ids: (extraBillIDs?.length)? extraBillIDs : null,
            product_name: (row?.concepte)? row?.concepte : row?.name
        }
    })







    
    useEffect(() => {       
        if (history?.concepts && Object.keys(history?.concepts)?.length) {
            window.addEventListener('click', clickOutside);
        } else {
            window.removeEventListener('click', clickOutside)
        }


        function clickOutside(e){         
            const element = document.getElementById('clickbox');

            if (element && element.contains(e.target)){ // Clicked in box
            } else if (history) { // Clicked outside the box
                setHistory(null)
            }
        }


        return () => {
            window.removeEventListener('click', clickOutside)
        };
    }, [history])

    useEffect(() => {       
        resetNotesFunc()
    }, [billOption])

    

    




    function resetNotesFunc() {
        if (savedNoteText) {
            setNoteText(savedNoteText)
        } else {
            const billNotes = (billOption?.video_notes)? getJsonString(billOption?.video_notes) : null;
            if (row?.concepte && billNotes && billNotes[row?.concepte]) {
                setNoteText(billNotes[row?.concepte])
            } else {
                setNoteText(null)
            }
        }
    }
    

    async function loadHistory(e) {
        setLoading(true)
        setAnchorEl(e.currentTarget)

        const historyBillConceptsResp = await axios.post(`${process.env.REACT_APP_API_ROUTE}getHystoryBillConcepts`, { product_name: (row?.concepte)? row?.concepte : row?.name, customer_token: currentCustomer?.token });
        const extraBillIDsTMP = (historyBillConceptsResp?.data?.length)? historyBillConceptsResp?.data?.map((el) => el?.f10ID ) : null

        const factura10HistoryResp = await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_product_history.php`, { productName: (row?.concepte)? row?.concepte : row?.name, extra_bills: extraBillIDsTMP });
        var historyTMP = (factura10HistoryResp?.data?.documents && Object.keys(factura10HistoryResp?.data?.documents)?.length)? factura10HistoryResp?.data : null
        const extraBillIDs = (historyTMP?.documents && Object.keys(historyTMP?.documents)?.length)? Object.keys(historyTMP?.documents)?.map((el) => parseInt(el)) : null

        const historyExtraConceptsResp = await axios.post(`${process.env.REACT_APP_API_ROUTE}getHystoryExtraConcepts`, { product_name: (row?.concepte)? row?.concepte : row?.name, history_ids: (extraBillIDs?.length)? extraBillIDs : null });
        const extrasTMP = (historyExtraConceptsResp?.data[0]?.length)? historyExtraConceptsResp?.data[0] : null
        const extrasExternalsTMP = (historyExtraConceptsResp?.data[1]?.length)? historyExtraConceptsResp?.data[1] : null

        if (extrasExternalsTMP?.length) {
            if (!historyTMP) { historyTMP = {} } 
            if (!historyTMP?.documents) { historyTMP.documents = {} } 

            for (var extrasExternalTMP of extrasExternalsTMP) {
                historyTMP.documents[extrasExternalTMP?.createdAt] = extrasExternalTMP;
            }
        }

        setHistory((historyTMP && Object.keys(historyTMP)?.length)? historyTMP : null)
        setExtraHistory((extrasTMP?.length)? extrasTMP : null)

        if (extrasTMP?.length || (historyTMP && Object.keys(historyTMP)?.length)) {
            getBillsByF10IDs()
        } else {
            setErrorAlertMsg('No se han encontrado facturas de este vídeo.')
            setAnchorEl(null)
        }

        setLoading(false)
    }









    return (billOption)? 
        <div className='flex space-x-[2px]'>



            {(currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) &&
                <div className='relative flex space-x-[2px]'>
                    <button onClick={(e) => setAnchorElButtons(e.currentTarget)} className={`rounded duration-200 border border-gray-400 border-opacity-20 ${(anchorElButtons)? 'bg-blue-500 text-white' : 'bg-gray-400 bg-opacity-[0.25] text-gray-400 hover:text-gray-600 hover:bg-opacity-50'}`}>
                        {menuIcon}
                    </button>

                    <RowButtonsMenu
                        anchorEl={anchorElButtons}
                        setAnchorEl={setAnchorElButtons}
                        noteText={noteText}
                        setNoteText={setNoteText}
                        savedNoteText={savedNoteText}
                        setSavedNoteText={setSavedNoteText}
                        
                        factura10Bill={factura10Bill}
                        billOption={billOption}
                        video={row}
                        hiddenItems={hiddenItems}
                        setHiddenItems={setHiddenItems}
                        disableHidden={disableHidden}
                        
                        center={false}
                    />
                </div> 
            }



            <Tooltip disableInteractive title={(history?.concepts)? "" :  "Cargar historial del vídeo"} placement="bottom" arrow>
                <button onClick={(e) => loadHistory(e)} className={buttonStyles}>
                    {(loading || loadingBills)? loadingIcon : historyIcon}
                </button>
            </Tooltip>

            <TransitionGroup appear={true}>
                {(history?.concepts && Object.keys(history?.concepts)?.length || anchorEl) &&
                    <CSSTransition timeout={200} classNames="fade">

                        <RowHistoryMenu
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}

                            history={history}
                            extraHistory={extraHistory}
                            documents={(dataBills?.getBillsByF10IDs?.length)? dataBills?.getBillsByF10IDs : null}
                            actualBillId={billOption?.id}
                            video={row}

                            loading={loading}
                            center={false}
                        />
                        
                    </CSSTransition>
                }
            </TransitionGroup>



            {(noteText && remove_tags(noteText) && !disableHidden) &&
                <Tooltip disableInteractive title={<div dangerouslySetInnerHTML={{__html: `<p class="opacity-70">Nota del vídeo ${row?.concepte}</p><hr class="my-1 opacity-70"/>${noteText}` }}></div>} placement="bottom" arrow>
                    <div className={`absolute left-[-30px] pt-[2px] text-gray-300 hover:text-blue-500`}>
                        {noteIcon}
                    </div>
                </Tooltip>
            }
        </div>
    : null
}