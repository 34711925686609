import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import axios from 'axios';
import Slider from '@mui/material/Slider';









export default function VideoPlayerSeek({
  
  videoObj,
  selectedReview,
  mediaIsLoaded,

  seekPosition, 
  setSeekPosition,
  seekMax,

}) {



  const { currentCustomer, currentUser } = useContext(AuthContext)


  const [seekPreview, setSeekPreview] = useState(0);
  const [previewSeconds, setPreviewSeconds] = useState((videoObj?.search_preview_seconds)? JSON.parse(videoObj?.search_preview_seconds) : null);
  const [selectedPreviewSecond, setSelectedPreviewSecond] = useState((previewSeconds?.length)? previewSeconds.reduce((prev, curr) => (Math.abs(curr - seekPreview) < Math.abs(prev - seekPreview))? curr : prev) : null);










  useEffect(() => { 
    const videoEl = document.getElementById("video");
    const seekEl = document.getElementById("video");


    if (videoEl) { videoEl.addEventListener('loadedmetadata', startPlayer(seekMax)); }
    function startPlayer(seekMax) {
      const seekElTMP = document.getElementById('seek');
      seekElTMP.addEventListener("mousemove", (e) => updateSeekTooltip(e, seekMax));
      seekElTMP.addEventListener('input', skipAhead);
    }


    return () => {
      seekEl?.removeEventListener("mousemove", (e) => updateSeekTooltip(e, seekMax))
      seekEl?.removeEventListener('input', skipAhead);
    };
  }, [seekMax, previewSeconds])


  useEffect(() => { 
    if (videoObj?.search_preview_seconds) {
      setPreviewSeconds((videoObj?.search_preview_seconds)? JSON.parse(videoObj?.search_preview_seconds) : null)
    } else if (videoObj?.is_translation_of) {

      axios.post(`${process.env.REACT_APP_API_ROUTE}getOgVideoPreviews`, { own_user_token: currentUser?.token, video_id: videoObj?.is_translation_of }).then(async (response)=> {
        setPreviewSeconds((response?.data?.length)? response?.data : null)
      })

    } else {
      setPreviewSeconds(null)
    }
  }, [videoObj?.search_preview_seconds])


  /* useEffect(() => { 
      setSelectedPreviewSecond((previewSeconds?.length)? previewSeconds.reduce((prev, curr) => (Math.abs(curr - seekPreview) < Math.abs(prev - seekPreview))? curr : prev) : null)
  }, [seekPreview, previewSeconds]) */
  









  /* Updates time && position shown in the seek toolip */
  function updateSeekTooltip(event, seekMax) {
    const videoEl = document.getElementById("video");
    const seekVideoPreviewEl = document.getElementById('video-preview');
    const seekEl = document.getElementById('seek');
    const seekTooltipEl = document.getElementById('seek-tooltip');
    const seekTooltipTimeEl = document.getElementById('seek-tooltip-label');

    const viewportOffset = document.getElementById('seek')?.getBoundingClientRect();
    if (viewportOffset && seekTooltipEl) {
      const left = viewportOffset.left;
      const resX = ((event.pageX) - (left)) - 4;
      seekTooltipEl.style.left = resX + 'px';
    }


    if (videoEl && seekTooltipTimeEl && seekEl?.clientWidth) {
      const skipTo = (event.offsetX / seekEl.clientWidth) * parseInt(seekMax, 10);
      const time = formatTime(skipTo);
      const rect = videoEl?.getBoundingClientRect();
      const seekValue = (skipTo <= seekMax)? skipTo : 0;

      setSeekPreview(seekValue)
      setSelectedPreviewSecond((previewSeconds?.length)? previewSeconds.reduce((prev, curr) => (Math.abs(curr - seekValue) < Math.abs(prev - seekValue))? curr : prev) : null)

      
      if (seekVideoPreviewEl) { seekVideoPreviewEl.currentTime = skipTo; }
      seekTooltipTimeEl.textContent = `${(time.hours && time.hours !== '00')? time.hours+':' : ''}${(time.minutes)? time.minutes : '00'}:${(time.seconds)? time.seconds : '00'}`;
      seekTooltipTimeEl.style.left = `${event.pageX - rect.left}px`;
    }
  }



  function skipAhead(event) {
    const videoEl = document.getElementById("video");

    if (videoEl) {
      const skipTo = (event.target.dataset.seek)? event.target.dataset.seek : event.target.value;
      videoEl.currentTime = skipTo;
      setSeekPosition(skipTo)
    }
  }


  
  /* Seconds to HH:mm:ss */
  function formatTime(timeInSeconds) {
    const result = (timeInSeconds)? new Date(timeInSeconds * 1000)?.toISOString().substr(11, 8) : null;
    
    return { 
      hours: (result)? result?.substr(0, 2) : 0,
      minutes: (result)? result?.substr(3, 2) : 0,
      seconds: (result)? result?.substr(6, 2) : 0,
    };
  };









  return (
    <div className='seek-box w-full'>


      <div className='h-[28px] flex items-center'>
        <Slider
          aria-label="time-indicator"
          id="seek"
          value={seekPosition}
          min={0}
          step={0.1}
          max={seekMax}
          onChange={(_, value) => {
            var video = document.getElementById("video");
            if (video) {
              video.currentTime = value;
              setSeekPosition(value)
            }
          }}
          sx={{
            color: 'rgb(59, 130, 246)',
            height: 8,
            paddingBlock: 1,

            '& .MuiSlider-thumb': { /* BALL */
              color: 'white',
              width: 10,
              height: 10,
              pointerEvents: 'none',

              transition: '0.15s linear', /* '0.3s cubic-bezier(.47,1.64,.41,.8)' */
              '&::before': { boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)' },
              '&:hover, &.Mui-focusVisible': { boxShadow: `0px 0px 0px 8px 'rgb(0 0 0 / 50%)'` },
              '&.Mui-active': { width: 15, height: 15 },
              '&:hover': {
                boxShadow: '0 0 0 5px rgba(59, 130, 246, 0.16)',
              },
            },

            '& .MuiSlider-rail': {
              opacity: 1,
              color: 'rgb(41, 49, 61)',
            },

            '& .MuiSlider-valueLabel': { 
              padding: 0.5,
              backgroundColor: 'rgba(40, 40, 40)', 
              pointerEvents: 'none',
            },
          }}
        />
      </div>







      {/* Tooltip */}
      <div id="seek-tooltip" className='seek-tooltip' style={{ marginTop: '-130px' }}>
        <div className='relative flex items-center justify-center h-[96px] w-[171px] bg-black rounded overflow-hidden z-[60]' style={{ opacity: (mediaIsLoaded)? 1 : 0 }}>
          {(previewSeconds?.length)?
            <img
              className='h-full w-full object-fill'
              src={`${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_previews/${(videoObj?.is_translation_of && !videoObj?.search_preview_seconds)? videoObj?.is_translation_of : videoObj?.id}-preview-${selectedPreviewSecond}-seconds.png`} 
              alt='video_preview'
              onerror="this.style.display='none'"
            />  
          :
            <video 
              id={'video-preview'}
              preload="metadata" 
              className={`w-full h-full duration-200`}
              src={`${process.env.REACT_APP_API_ROUTE}mediaplayer/${currentCustomer?.token}/${((selectedReview.lowres_version)? 1 : 0)}/${selectedReview.filename}`}
            />
          }
        </div>

        <div className='absolute w-full h-0 flex items-center justify-end'>
          <p id="seek-tooltip-label" className='bottom-[8px] right-[0px] px-1 py-[2px] mb-7 mr-2 rounded z-[60] bg-black bg-opacity-70'>00:00</p>
          <div className='bg-black p-[4px] absolute bottom-[-5px] right-[85px] rotate-45 z-[50]'></div>
        </div>
      </div>


    </div>
  );
}