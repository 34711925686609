import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';
import PersExtraItems from './Components/PersExtraItems';

import GetAllProfessorsByCustomer from './../../graphql/queries/GetAllProfessorsByCustomer'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';








const legend = {
    'loveisdin': [
        { 
            title: [
                'Precios de las marcas localizadas',
                'Precio',
            ],
            table: [
                { title: 'Motion básico', price: '7,50 € / motion' },
                { title: 'Motion estándar', price: '10,00 € / motion' },
                { title: 'Motion avanzado', price: '40,00 € / motion' },
                { title: 'Otros', price: '50% del precio original' },
            ],
        },
        { 
            title: [
                'Traducción y subtitulación',
                'Precio',
            ],
            table: [
                { title: 'Palabras traducidas de los motions es/en', price: '0,14 € / palabra' },
                { title: 'Palabras traducidas de los motions en otros idiomas', price: '0,22 € / palabra' },
                { title: 'Primera sincronización de subtítulos', price: '6,00 € / minuto' },
                { title: 'Sincronización de subtítulos secundarios', price: '4,50 € / minuto' },
                { title: 'Sincronización de subtítulos terciarios', price: '3,00 € / minuto' },
                { title: 'Voiceover IA (Individual)', price: '14,00 € / minuto' },
                { title: 'Voiceover IA (Grupal)', price: '16,00 € / minuto' },
            ],
        },
    ],
    'other': [
        { 
            title: [
                'Precios de las marcas localizadas',
                'Precio',
            ],
            table: [
                { title: 'Motion básico', price: '7,50 € / motion' },
                { title: 'Motion estándar', price: '10,00 € / motion' },
                { title: 'Motion avanzado', price: '40,00 € / motion' },
                { title: 'Otros', price: '50% del precio original' },
            ],
        },
        { 
            title: [
                'Traducción y subtitulación',
                'Precio',
            ],
            table: [
                { title: 'Palabras traducidas de los motions', price: '0,14 € / palabra' },
                { title: 'Primera sincronización de subtítulos', price: '6,00 € / minuto' },
                { title: 'Sincronización de subtítulos secundaria', price: '4,50 € / minuto' },
                { title: 'Sincronización de subtítulos terciarios', price: '3,00 € / minuto' },
                { title: 'Voiceover IA (Individual)', price: '14,00 € / minuto' },
                { title: 'Voiceover IA (Grupal)', price: '16,00 € / minuto' },
            ],
        },
    ],
}


const deff_columns = [
    { id: 'concepte', label: 'Vídeo', minWidth: 180 },

    { id: 'minutes', label: 'Minutaje', align: 'right' },
    { id: 'subtitulation', label: 'Subtítulos', align: 'right' },
    { id: 'voiceover_ia', label: 'Voiceover IA', align: 'left' },
    { id: 'word_total', label: 'Palabras traducidas en los motions', width: 185 },

    { id: 'motions_basic', label: 'Localización motions básicos' },
    { id: 'motions_estandard', label: 'Localización motions estándar' },
    { id: 'motions_advanced', label: 'Localización motions avanzado' },
    { id: 'motions_others', label: 'Localización motions especiales' },
    { id: 'stock', label: 'Vídeo en stock' },

    { id: 'preu', label: 'Total', align: 'right' },
];


const unicodes = { 
    'u00c1': 'Á',
    'u00e1': 'á',
    'u00c9': 'É',
    'u00e9': 'é',
    'u00cd': 'Í',
    'u00ed': 'í',
    'u00d3': 'Ó',
    'u00f3': 'ó',
    'u00da': 'Ú',
    'u00fa': 'ú',
    'u00d1': 'Ñ',
    'u00f1': 'ñ',
}
















export default function Factura10MotionsLocalizations({ billOption, factura10Bill, billExtras, loadingBills, hiddenItems, setHiddenItems }) {



    const { bill_token } = useParams()

    const { currentCustomer, currentUser } = useContext(AuthContext)
    const { formatNumbersToPrint, remove_tags } = useContext(FuncContext)

    
    const [bill, setBill] = useState(null);
    const [concepts, setConcepts] = useState(null);
    const [conceptsCorrections, setConceptsCorrections] = useState(null);
    const [cats, setCats] = useState(null);
    const [columns, setColumns] = useState(deff_columns);

    const [markLegend, setMarkLegend] = useState(null);
    const [markCounter, setMarkCounter] = useState(null);
    const [correctionFinalPrice, setCorrectionFinalPrice] = useState(0);

    const [tableOpen, setTableOpen] = useState(true);
    const [tableXProfessorOpen, setTableXProfessorOpen] = useState(false);
    const [tableXTopicOpen, setTableXTopicOpen] = useState(false);
    const [secondaryTableOpen, setSecondaryTableOpen] = useState(true);
    const [descTableOpen, setDescTableOpen] = useState(false);

    const [showLegend, setShowLegend] = useState(true);
    const [focusedVideo, setFocusedVideo] = useState(null);

    const [baseLoading, setBaseLoading] = useState(true);








    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
    const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M237-285q54-38 115.5-56.5T480-360q66 0 127.5 18.5T723-285q35-41 52-91t17-104q0-129.675-91.23-220.838Q609.541-792 479.77-792 350-792 259-700.838 168-609.675 168-480q0 54 17 104t52 91Zm243-123q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm.276 312Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q52 0 100-16.5t90-48.5q-43-27-91-41t-99-14q-51 0-99.5 13.5T290-233q42 32 90 48.5T480-168Zm0-312q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21Zm0-72Zm0 319Z"/></svg>
    const doneIcon = <svg className='text-green-500' xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
    const notDoneIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.457L531-480.5l116-115.543Q658-607 658-621.5T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.913 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.413-10.913L480-429Zm.276 333Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
    
    const euroIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill='currentColor' viewBox="0 0 20 20"><path d="M10,17c-1.83,0-3.41-.61-4.72-1.82-1.31-1.22-2.06-2.71-2.24-4.49-.03-.19.03-.36.18-.49s.32-.2.53-.2c.2,0,.37.06.52.17s.24.26.27.46c.15,1.38.75,2.53,1.78,3.47,1.03.94,2.26,1.41,3.68,1.41,1.51,0,2.81-.54,3.89-1.61,1.08-1.08,1.61-2.37,1.61-3.89s-.54-2.81-1.61-3.89-2.37-1.61-3.89-1.61c-.86,0-1.66.18-2.39.53-.73.36-1.34.84-1.84,1.47h1.48c.21,0,.39.07.53.21.14.14.22.32.22.53s-.07.39-.22.54c-.14.15-.32.22-.53.22h-3.5c-.21,0-.39-.07-.53-.22-.14-.14-.22-.32-.22-.53v-3.5c0-.21.07-.39.21-.53.14-.14.32-.22.53-.22s.39.07.54.22c.15.14.22.32.22.53v1.96c.64-.83,1.43-1.49,2.39-1.98s1.99-.73,3.11-.73c.97,0,1.88.18,2.73.55.85.37,1.59.87,2.22,1.5.63.63,1.13,1.37,1.5,2.22.37.85.55,1.76.55,2.73s-.18,1.88-.55,2.73c-.37.85-.87,1.59-1.5,2.22-.63.63-1.37,1.13-2.22,1.5-.85.37-1.76.55-2.73.55Z"/><path d="M8.83,9.49h3.43v1.38h-3.43c.19.94,1.1,1.54,2.04,1.35.28-.06.55-.19.77-.37l1.18.78c-1.16,1.27-3.13,1.35-4.4.19-.55-.51-.9-1.2-.99-1.95h-.71v-1.38h.71c.19-1.71,1.73-2.94,3.44-2.75.75.08,1.44.44,1.95.99l-1.18.78c-.73-.62-1.82-.53-2.44.2-.19.22-.32.49-.37.77Z"/></svg>
    const notTimeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-771.39q61.39 0 117.09 19.5 55.69 19.5 100.13 56.06l27.85-27.84q14.1-14.11 31.21-13.61t31.5 14.89q14.4 14.39 14.4 31.5t-14.4 31.5l-26.56 27.56q36 43.87 55 99.64 19 55.76 19 115.57 0 21.06-1.59 40.6-1.59 19.54-6.32 38.02-4.44 25.65-22.18 34.2-17.74 8.54-36.83 4.54-19.08-4-31.32-18.67-12.24-14.68-6.25-39 3.65-14.29 5.07-29.24 1.42-14.95 1.42-30 0-107.74-74.74-182.48T480-673.39q-16.61 0-31.17 1.87-14.57 1.87-27.35 4.48-21.78 5.56-37.52-6.11-15.74-11.68-19.74-29.76-4-18.09 3.76-35.55 7.76-17.45 32.41-24.02 19.05-6.3 39.14-7.61 20.09-1.3 40.47-1.3Zm44.48 236.69v-39.26q0-14.91-10.35-25.41t-25.65-10.5q-8.44 0-15.88 3.22-7.45 3.22-11.99 9.09l63.87 62.86ZM480-61.52q-74.52 0-138.83-27.92-64.31-27.92-112.43-75.75-48.12-47.84-76.04-112.15-27.92-64.31-27.92-138.97 0-57.99 18.5-113.19 18.5-55.2 54.5-103.02l-86.17-86.17q-12.7-12.7-12.41-27.83.28-15.13 12.97-27.83 12.7-12.69 28.33-12.69 15.63 0 28.33 12.69L794-149.74q12.7 12.7 12.7 27.83 0 15.13-12.7 27.82-12.7 12.7-28.61 12.7t-28.61-12.7l-41-40.43q-45 36-99.91 54.5T480-61.52Zm0-98q40.74 0 77.06-11.52 36.33-11.52 68.07-34.13l-355-355.57q-22.61 32.31-34.98 69.48-12.37 37.17-12.37 75.09 0 107.74 74.74 182.19 74.74 74.46 182.48 74.46ZM386.96-816q-18.7 0-31.59-13.12-12.89-13.11-12.89-31.71 0-18.61 12.89-31.65t31.59-13.04h186.08q18.7 0 31.59 12.83t12.89 31.44q0 18.6-12.89 31.92Q591.74-816 573.04-816H386.96Zm60.39 433.04Zm114.43-114.43Z"/></svg>

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 1000,
        },
    });



    const [getProfessors, { data: dataProfessors, loading: loadingProfessors }] = useLazyQuery(GetAllProfessorsByCustomer, { 
        fetchPolicy: 'cache-and-network',
        variables: {
            customer_token: currentCustomer?.token,
        }
    })


    var selectedLegend = (billOption?.module?.includes('loveisdin') && legend['loveisdin'])? legend['loveisdin'] : legend['other'];
    const ready = (billOption?.module && selectedLegend?.length)? true : false;
    if (ready && markCounter && !markCounter?.voiceover_ia?.price) { selectedLegend[1].table = selectedLegend[1]?.table?.filter((el) => !el?.title?.includes('Voiceover') ) }












    
    useEffect( () => { 
        if (currentCustomer) {
            getProfessors()
        }
    }, [currentCustomer])


    useEffect( () => { 
        if (baseLoading) {
            setTimeout(() => {
                setBaseLoading(false)
            }, 500 )
        }
    }, [baseLoading])


    useEffect( () => { 
        if (currentCustomer) {
            if (factura10Bill?.document) {


                var correctedConcepts = (factura10Bill?.concepts?.length)? [...factura10Bill?.concepts] : null;
                const cats = factura10Bill?.cats;
                const filtredCats = cats?.filter((value, index, self) => index === self.findIndex((t) => (t.nom === value.nom )) );
                
                for (var i in correctedConcepts) {
                    var hasCat = filtredCats?.filter((el) => parseInt(correctedConcepts[i]?.document_concepte_categoria_id) === parseInt(el?.id));

                    if (!hasCat?.length) {
                        var oldCat = cats?.filter((el) => parseInt(correctedConcepts[i]?.document_concepte_categoria_id) === parseInt(el?.id));
                        oldCat = (oldCat?.length)? oldCat[0] : null

                        if (oldCat) {
                            for (var filtredCat of filtredCats) {
                                if (oldCat?.nom === filtredCat?.nom) {
                                    correctedConcepts[i].document_concepte_categoria_id = parseInt(filtredCat?.id);
                                }
                            }
                        }
                    }
                }

                setBill(factura10Bill?.document)
                setConcepts(correctedConcepts)
                setCats(filtredCats)


                var conseptCorections = {}
                
                correctedConcepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.map((row) => {
                    const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;

                    if (marksJson?.correction_price) {
                        var nameTMP = (row?.concepte?.split('-')?.length > 1 && row?.concepte?.charAt(0) === 'T')? row?.concepte?.split('-')[0] : row?.concepte

                        if (!conseptCorections[nameTMP]) { conseptCorections[nameTMP] = {} }
                        conseptCorections[nameTMP].nom = nameTMP;
                        conseptCorections[nameTMP].noms = (conseptCorections[nameTMP].noms?.length)? [...conseptCorections[nameTMP].noms, row] : [row];
                        conseptCorections[nameTMP].price = (conseptCorections[nameTMP]?.price)? (conseptCorections[nameTMP]?.price + marksJson?.correction_price) : marksJson?.correction_price;
                        conseptCorections[nameTMP].number_of_marks = conseptCorections[nameTMP].price / 15;
                    }
                });

                setConceptsCorrections(conseptCorections)

                
            } else {
                setBill(null)
                setConcepts(null)
                setCats(null)
            }
        }
    }, [factura10Bill, currentCustomer])


    useEffect( () => { 
        if (concepts?.length) {
            for (var concept of concepts) {
                var marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                if (marksJson?.difficulties?.length) {
    
                    marksJson.difficulties.unshift({ token: 'voiceover_ia' });
                    marksJson.difficulties.unshift({ token: 'subs' });
                    marksJson.difficulties.unshift({ token: 'words' });

                    setMarkLegend(marksJson)
                    break;
                }
            }
        }
    }, [concepts])


    useEffect( () => { 
        if (concepts?.length) {

            var catMarksTMP = {}
            catMarksTMP['total'] = 0
            catMarksTMP['stock'] = {}
            catMarksTMP['stock']['num'] = 0
            catMarksTMP['stock']['price'] = 0
            catMarksTMP['voiceover_ia']= {}
            catMarksTMP['voiceover_ia']['price'] = 0


            concepts?.map((concept) => {
                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                if (marksJson && marksJson['voiceover_ia']) {
                    catMarksTMP['voiceover_ia']['price'] = catMarksTMP['voiceover_ia']['price'] + marksJson['voiceover_ia'];
                }
            })

            for (var i in markLegend?.difficulties) {
                var legend = markLegend?.difficulties[i];
                var subCats = {};

                concepts?.map((concept) => {
                    const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                    if (marksJson[legend?.token]) {
                        Object.keys(marksJson[legend?.token]).map((keyName, i) => {
                            if (marksJson[legend?.token][keyName]) {
                                const num = (parseFloat(marksJson[legend?.token][keyName]))? parseFloat(marksJson[legend?.token][keyName]) : (parseFloat(marksJson[legend?.token][keyName]?.count))? parseFloat(marksJson[legend?.token][keyName]?.count) : 0
                                subCats[keyName] = (subCats[keyName])? subCats[keyName] + num : num
                            }
                        })
                    }
                })


                var subCatNum = (parseInt(subCats))? parseInt(subCats) : (parseInt(subCats?.new))? parseInt(subCats?.new) : 0
                if (subCatNum) {
                    var tokenTMP = (legend?.token?.includes('basic'))? 'basic' : (legend?.token?.includes('medium'))? 'medium' : (legend?.token?.includes('medium'))? 'advanced' : legend?.token
                    var hasExtraStock = (legend?.token?.includes('stock') && legend?.token !== 'stock')? true : false

                    if (hasExtraStock) {
                        catMarksTMP['stock']['num'] = catMarksTMP['stock']['num'] + subCatNum;
                        catMarksTMP['stock']['price'] = catMarksTMP['stock']['price'] + (subCatNum * markLegend?.prices['stock']) / 100;
                    }


                    if (!catMarksTMP[tokenTMP] || (!catMarksTMP[tokenTMP]['num'] && catMarksTMP[tokenTMP]['num'] !== 0)) {
                        catMarksTMP[tokenTMP] = {}
                        catMarksTMP[tokenTMP]['num'] = 0;
                        catMarksTMP[tokenTMP]['price'] = 0;
                    }


                    catMarksTMP[tokenTMP]['num'] = catMarksTMP[tokenTMP]['num'] + subCatNum;
                    catMarksTMP[tokenTMP]['price'] = catMarksTMP[tokenTMP]['price'] + (subCatNum * markLegend?.prices[tokenTMP]) / 100;
                    catMarksTMP['total'] = catMarksTMP['total'] + subCatNum;
                }
            }

            setMarkCounter(catMarksTMP)
        }
   

    }, [concepts, markLegend])


    useEffect( () => { 
        if (focusedVideo) {
            setTimeout(() => {
                setFocusedVideo(null)
            }, 1500)
        }
    }, [focusedVideo])


    useEffect( () => { 
        var deffColumnsTMP = deff_columns;

        if (markCounter && !markCounter?.voiceover_ia?.price) {
            deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'voiceover_ia' )
        }

        if (markCounter && !markCounter?.advanced?.price) {
            deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'motions_advanced' )
        }

        if (markCounter && !markCounter?.stock?.price) {
            deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'stock' )
        }

        if (markCounter && !markCounter?.other?.num) {
            deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'motions_others' )
        }

        setColumns(deffColumnsTMP)
    }, [markCounter])












    function escapeUnicode(str) {
        Object.keys(unicodes).map((keyName, i) => (
            str = str?.replaceAll(keyName, unicodes[keyName])
        ))
        return str;
    }
    


    function getMotionListFunc(row, type, marksJson, checks, seeMore, isNew, returnPrice, getAll) {
        const marksJsons = (marksJson?.length)? [...marksJson] : [marksJson]
        const corrKey = 'correction'
        
        var count = 0;
        var price = 0;
        var items = {};
        var other_descs = false;
        

        for (var marksJsonTMP of marksJsons) {
            var itemTMP = (marksJsonTMP && type in marksJsonTMP && marksJsonTMP[type] && isNew in marksJsonTMP[type] && marksJsonTMP[type][isNew]?.count)? marksJsonTMP[type][isNew] : (marksJsonTMP && type in marksJsonTMP && marksJsonTMP[type]?.count)? marksJsonTMP[type] : {};
           
            if (getAll) {
                var itemCorrTMP = (marksJsonTMP && type in marksJsonTMP && marksJsonTMP[type] && corrKey in marksJsonTMP[type] && marksJsonTMP[type][corrKey]?.count)? marksJsonTMP[type][corrKey] : null;
                if (itemCorrTMP && Object.keys(itemCorrTMP)?.length) {
                    for (let correctionsKey of Object.keys(itemCorrTMP)) {
                        itemTMP[correctionsKey] = (itemTMP[correctionsKey])? itemTMP[correctionsKey] + itemCorrTMP[correctionsKey] : itemCorrTMP[correctionsKey];
                    }
                }
            }


            count = count + ((parseInt(itemTMP?.count))? parseInt(itemTMP?.count) : 0);
            price = price + ((type !== 'other' && parseFloat(itemTMP?.count))? (parseFloat(itemTMP?.count) * (parseFloat(marksJsonTMP?.prices[type]) / 100)) : 0);


            const itemKeys = (itemTMP && Object.keys(itemTMP)?.length)? Object.keys(itemTMP) : [];
            for (var itemKey of itemKeys) {
                if (!items[itemKey] && items[itemKey] !== 0) { items[itemKey] = 0; }
                items[itemKey] = items[itemKey] + ((parseInt(itemTMP[itemKey]))? parseInt(itemTMP[itemKey]) : 0);
            }

            if (type === 'other' && marksJsonTMP && marksJsonTMP['other_prices']) {
                for (var matkId of Object.keys(marksJsonTMP['other_prices'])) {
                    const smallPrice = (parseFloat(marksJsonTMP['other_prices'][matkId]))? parseFloat(marksJsonTMP['other_prices'][matkId]) / 100 : 0
                    if (smallPrice) {
                        price = price + smallPrice;
                        if (marksJsonTMP['other_descs']) { other_descs = true; }
                    }
                }
            }
        }

        return (returnPrice)? 
            ((price)? price : 0)
        : (count)? <>
            <CustomWidthTooltip disableInteractive placement="bottom" arrow
                title={
                    <div className='space-y-[2px]'>
                        {Object.keys(items)?.sort((a, b) => 
                        
                            (parseInt(items[b]) !== parseInt(items[a]))? (parseInt(items[b]) - parseInt(items[a]))
                            : ((a < b)? -1 : (a > b)? 1 : 0)
                        
                        )?.map(element => {
                            var numTMP = items[element]
                            return (element && element !== 'count')? <p>x{numTMP} {escapeUnicode(element)}</p> : null
                        })}
                    </div>
                }
            >
                <div className={`flex space-x-1 items-center justify-start w-min pr-[4px] rounded cursor-pointer hover:bg-gray-400 hover:bg-opacity-30 ${(checks)? '' : 'pl-[4px]'}`}>
                    <p>{(checks)? doneIcon : count}</p>
                    
                    {(seeMore && other_descs)? 
                        <a href={'#video-'+row['id']} onClick={() => { setDescTableOpen(true); setFocusedVideo(row['id']); }} className='flex pr-1 text-blue-500 hover:underline cursor-pointer'>
                            Ver {downArrowIcon}
                        </a> 
                    : null}

                    <p className='opacity-30'>-</p>
                    <p className='opacity-50'>{formatNumbersToPrint(price, 2)} €</p>
                </div>
            </CustomWidthTooltip>
        </> : (checks)? <div className='opacity-25 text-gray-500'>{notDoneIcon}</div> : '---'
    }












    function processBaseTableFunc(valueTMP, column, row, returnPrice) {
        var value = valueTMP
        const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;

        if (column?.id === 'concepte') {
            value = <div className='flex space-x-2'>
                <RowButtons
                    row={row}
                    factura10Bill={factura10Bill}
                    billOption={billOption}
                    hiddenItems={hiddenItems}
                    setHiddenItems={setHiddenItems}
                />
                
                {(row['link'])? 
                    <a href={row['link']} target="_blank">{value}</a> : <p>{value}</p> 
                }

                {(marksJson?.disabled || (!row['link'] && billOption?.createdAt < "2024-07-03T00:00:00.000Z" && !(billOption?.label?.includes('2024129') || billOption?.label?.includes('2024134') || billOption?.label?.includes('2023135') || billOption?.label?.includes('2024137') || billOption?.label?.includes('2024136'))))?
                    <p className='text-xs text-gray-400'>(Descartado por ISD)</p> 
                : null }
            </div>
        }

        if (column?.id === 'minutes') {
            const min = (marksJson?.min_facturable)? marksJson?.min_facturable : (marksJson?.sub)? marksJson?.min_final : 0


            value = ((returnPrice)? 
                ((parseFloat(min))? parseFloat(min) : 0)
            :
                (min)? '+' + formatNumbersToPrint(parseFloat(min), 2) + 'm' : '---'
            )
        }

        if (column?.id === 'subtitulation') {
            const min = (parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : (marksJson?.sub && parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0
            const subType = Math.round((parseFloat(marksJson?.sub) / min))

            value = ((returnPrice)? 
                    ((parseFloat(marksJson?.sub))? parseFloat(marksJson?.sub) : 0)
                : (marksJson?.sub)?
                    <div className='flex space-x-1' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                        {(marksJson?.sub && parseFloat(marksJson?.sub))?
                            <Tooltip disableInteractive placement="bottom" arrow title={(subType === 3)? 'Sincronización de subtítulos terciarios' : (subType === 4.5)? 'Subtitulación de idiomas secundarios' : 'Primera sincronización de subtítulos'}>
                                <button className='px-1 py-[2px] text-xs text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded'>
                                    S{(subType === 3)? '³' : (subType === 4.5)? '²' : '¹'}
                                </button>
                            </Tooltip>
                        : null }
                        <div className={`min-w-[50px] ${(marksJson?.sub && parseFloat(marksJson?.sub))? '' : 'opacity-30'}`}>{(marksJson?.sub)? formatNumbersToPrint(marksJson?.sub, 2)+' €' : '---'}</div>
                    </div>
                : '---'
            )
        }
        
        if (column?.id === 'voiceover_ia') {
            const types = { 14: 'Voiceover IA (Individual)', 16: 'Voiceover IA (Grupal)' }
            const typeNumber = Math.round(marksJson?.voiceover_ia / parseFloat(marksJson?.min_final))
            const type = (types[typeNumber])? types[typeNumber] : types[14]

            value = ((returnPrice)? 
                    ((parseFloat(marksJson?.voiceover_ia))? parseFloat(marksJson?.voiceover_ia) : 0)
                : (marksJson?.voiceover_ia)?
                    <Tooltip disableInteractive placement="bottom" arrow title={(marksJson?.voiceover_ia)? type : ''}>
                        <p className={`w-min hover:bg-gray-400 hover:bg-opacity-30 px-[4px] rounded ${(marksJson?.voiceover_ia)? '' : 'opacity-30'}`}>
                            {(marksJson?.voiceover_ia)? formatNumbersToPrint(marksJson?.voiceover_ia, 2)+' €' : '---'}
                        </p>
                    </Tooltip>
                : '---'
            )
        }

        if (column?.id === 'motions_basic' && marksJson) {
            value = getMotionListFunc(row, 'basic', marksJson, false, false, 'new', returnPrice)
        }

        if (column?.id === 'motions_estandard' && marksJson) {
            value = getMotionListFunc(row, 'medium', marksJson, false, false, 'new', returnPrice)
        }

        if (column?.id === 'motions_advanced' && marksJson) {
            value = getMotionListFunc(row, 'advanced', marksJson, false, false, 'new', returnPrice)
        }

        if (column?.id === 'motions_others') {
            value = getMotionListFunc(row, 'other', marksJson, true, true, 'new', returnPrice)
        }

        if (column?.id === 'stock') {
            value = getMotionListFunc(row, 'stock', marksJson, true, false, 'new', returnPrice)
        }

        if (column?.id === 'word_total') {
            value = ((returnPrice)? 
                    ((parseFloat(marksJson?.word_price_total))? parseFloat(marksJson?.word_price_total) : 0)
                : ((parseFloat(marksJson?.word_price_total))? 
                    <div className='flex'>
                        <p>{marksJson?.word_total}</p> <p className='px-2 opacity-50'>-</p> <p className='opacity-50'>{formatNumbersToPrint(marksJson?.word_price_total, 2)} €</p>
                    </div> 
                : '---')
            )
        
            if (marksJson['chyrons_facturated'] >= 1 && !returnPrice && value) {
                var tooltipNames = null;
                if (marksJson['chyron_ids']+'' && dataProfessors?.professorsByCustomer?.length) {
                    for (var profId of (marksJson['chyron_ids']+'')?.split(',')) {
                        for (var prof of dataProfessors?.professorsByCustomer) {
                            if (parseInt(prof?.id) === parseInt(profId)) {
                                tooltipNames = (tooltipNames)? tooltipNames + ', ' + prof?.name : prof?.name
                            }
                        }
                    }
                }
                
                value = <div className='flex justify-between space-x-2'>
                    <div>{value}</div>
                    <div className='text-xs' style={{ marginLeft: 4 }}>
                        <Tooltip
                            disableInteractive
                            title={
                                (tooltipNames)?
                                    `Incluye las palabras traducidas de los chyrons ${(row?.concepte?.split('_')?.length > 1)? 'en (' + row?.concepte?.split('_')[row?.concepte?.split('_')?.length - 1]?.toUpperCase() + ')' : ''} de: ` + tooltipNames
                                :
                                    ('+' + ((marksJson['chyrons_facturated'] === 1)? marksJson['chyrons_facturated'] + ` chyron del ${currentCustomer?.label_professor?.toLowerCase()} traducido` : (marksJson['chyrons_facturated'] + ` chyrons de ${currentCustomer?.label_professors?.toLowerCase()} facturados`)))
                            }
                            placement="bottom"
                            arrow
                        >
                            <button className='flex items-center space-x-[2px] text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded pl-[2px]'>
                                <p>+{marksJson['chyrons_facturated']}</p>
                                {userIcon}
                            </button>
                        </Tooltip>
                    </div>
                </div>
            }
        }

        if (column?.id === 'preu') {
            const priceTMP = ((parseFloat(value))? (value - ((marksJson?.correction_price)? marksJson?.correction_price : 0)) : 0)

            value = ((returnPrice)? 
                ((parseFloat(priceTMP))? parseFloat(priceTMP) : 0)
            :
                formatNumbersToPrint(((parseFloat(priceTMP))? parseFloat(priceTMP) : 0), 2) + ' €'
            )
        }


        if (column?.id !== 'preu' && !returnPrice && (value === '---' || !value)) {
            value = checkNullTypeFunc(marksJson, returnPrice, 
                (column?.id?.includes('voiceover_ia'))? 'voiceover_ia'
                : (column?.id?.includes('subtitulation') || column?.id?.includes('minutes'))? 'subtitulation'
                : (column?.id?.includes('motions') || column?.id?.includes('word_total'))? 'motions'
                : null
            )
        }


        return value
        
    }






    function checkNullTypeFunc(marksJson, returnPrice, type) {
        switch(type) {
            case 'voiceover_ia':
                return (marksJson?.facturated?.voiceover_ia && !returnPrice)? <div className='flex justify-end opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
            case 'subtitulation':
                return (marksJson?.facturated?.subtitulation && !returnPrice)? <div className='flex justify-end opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
             case 'motions':
                return (marksJson?.facturated?.motions && !returnPrice)? <div className='flex justify-start opacity-25 text-gray-500'><div className='scale-[0.9]'>{notTimeIcon}</div></div> : '---'
            default:
                return '---'
        }
    }














  return (currentCustomer && !loadingBills && !baseLoading)? (
    <div className='space-y-2'>









        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>

                    <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.dateFormated}</p>
                </div>
            </div>



            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            {(billOption?.observations) && 
                <div className='flex flex-wrap space-y-4 pb-4 text-base'>
                    <div dangerouslySetInnerHTML={{__html: (billOption?.observations)? billOption?.observations : ''}}></div> 
                </div>
            }

            
            <div className='w-full flex overflow-auto'>
                <div className='w-full flex flex-wrap justify-end space-y-4'>
                    {markLegend?.difficulties?.map(legend => {

                        var subCats = {}
                        var price = 0
                        var catFinalPrice = 0
                        var catFinalNumber = 0
                        var catFinalNumberVideos = 0
                        var languages = []
                        var type = 'motions'
                        var nameTMP = 'Test'

                        const avoid = ['Chyron de profesor', 'Tu00edtulo de vu00eddeo']


                        if (legend?.token && legend?.token !== 'subs' && legend?.token !== 'voiceover_ia' && legend?.token !== 'words') {

                            concepts?.map((concept) => {
                                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                                if (marksJson[legend?.token]) {
                                    var keysTMP = Object.keys(marksJson[legend?.token]);
                                    var isNew = (keysTMP?.includes('new') || keysTMP?.includes('correction'))? true : false;
                                    
                                    if (isNew) {
                                        keysTMP?.map((prevKeyName, i) => {
                                            Object.keys(marksJson[legend?.token][prevKeyName])?.map((keyName, i) => {
                                                if (marksJson[legend?.token][prevKeyName][keyName]) {
                                                    subCats[keyName] = (subCats[keyName])? subCats[keyName] + marksJson[legend?.token][prevKeyName][keyName] : marksJson[legend?.token][prevKeyName][keyName]
                                                    
                                                    if (prevKeyName === 'correction' && marksJson[legend?.token][prevKeyName][keyName] && avoid?.includes(keyName)) {
                                                        const numTMP =  (parseInt(subCats['count']))? parseInt(subCats['count']) : 0
                                                        subCats['count'] = numTMP - marksJson[legend?.token][prevKeyName][keyName];
                                                    }
                                                }
                                            })
                                        })
                                    } else {
                                        keysTMP?.map((keyName, i) => {
                                            if (marksJson[legend?.token][keyName]) {
                                                subCats[keyName] = (subCats[keyName])? subCats[keyName] + marksJson[legend?.token][keyName] : marksJson[legend?.token][keyName]
                                            }
                                        })
                                    }
                                }
                            })
                            
                            catFinalPrice = formatNumbersToPrint((subCats?.count * markLegend?.prices[legend?.token]) / 100, 2)
                            nameTMP = (legend?.name)? 'Localización ' + escapeUnicode(legend.name)?.toLowerCase() : 'NONE'

                        } else if (legend?.token === 'subs') {
                            
                            concepts?.map((concept) => {
                                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                                if (marksJson?.word_total && marksJson?.word_total) { 
                                    catFinalNumber = catFinalNumber + marksJson?.word_total; 
                                    price = price + parseFloat(marksJson?.word_price_total)
                                }
                            })

                            catFinalPrice = formatNumbersToPrint(price, 2);
                            nameTMP = 'Palabras traducidas de los motions';
                            subCats = `Se han traducido un total de ${catFinalNumber} palabras de los motions de las localizaciones`;
                            type = 'palabras'

                        } else if (legend?.token === 'voiceover_ia') {
                            
                            concepts?.map((concept) => {
                                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                                if (marksJson?.min_final && marksJson?.voiceover_ia) { catFinalNumber = catFinalNumber + parseFloat(marksJson?.min_final); }
                                if (marksJson?.voiceover_ia) { 
                                    price = price + marksJson?.voiceover_ia;
                                    if (!languages?.includes(marksJson?.language)) { languages.push(marksJson?.language) }
                                    catFinalNumberVideos++;
                                }
            
                            })
                            
                            catFinalNumber = formatNumbersToPrint(catFinalNumber, 2);
                            catFinalPrice = formatNumbersToPrint(price, 2);
                            nameTMP = 'Locución y voiceover IA';
                            subCats = `Se ha implementado en un total de ${catFinalNumberVideos} ${(catFinalNumberVideos === 1)? 'vídeo' : 'vídeos'} (${catFinalNumber} minutos de contenido)`; /* ${languages?.length} idiomas */
                            type = 'minutos'

                        } else if (legend?.token === 'words') {
                            
                            var counter = 0;
                            concepts?.map((concept) => {
                                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                                if (marksJson?.sub) { 
                                    counter++;
                                    catFinalNumber = catFinalNumber + parseFloat(marksJson?.min_facturable);
                                    catFinalPrice = catFinalPrice + marksJson?.sub;
                                }
                            })
                            catFinalNumber = formatNumbersToPrint(catFinalNumber, 2);
                            catFinalPrice = formatNumbersToPrint(catFinalPrice, 2);
                            nameTMP = 'Sincronización de subtítulos';
                            subCats = `Se han subtitulado un total de ${catFinalNumber} minutos a lo largo de ${counter} vídeos`;
                            type = 'minutos'

                        }
   

                        return (parseFloat(catFinalPrice))? 
                            <div 
                                className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                                key={'extra-loc-' + nameTMP}
                            >
                                <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                    <div className='px-2 py-[6px] w-full font-bold'>{nameTMP}</div>
                                    <div className='px-2 py-[6px] text-sm text-gray-400'>{(subCats?.count)? subCats?.count + ' ' + type + ' / ' : catFinalNumber + ' ' + type + ' / '} {catFinalPrice} €</div>
                                </div>
                                <p className='px-2 pb-1 text-sm'>
                                    {(typeof subCats === 'string')? 
                                        subCats
                                    :
                                        Object.keys(subCats)?.filter((el) => el !== 'count')?.map((keyName, i) => escapeUnicode(keyName))?.join(', ')
                                    }.
                                </p>
                            </div>
                        : null
                    })}       


                        {markLegend?.difficulties?.map((legend, key) => {
                            const isValid = legend?.token === 'basic';
                            var numberOfTitles = 0;
                            var numberOfChyrons = 0;
                            var catFinalPrice = 0;
                            var hasTitles = false;
                            var hasChyrons = false;
        
                            if (isValid) {
                                concepts?.map((concept) => {
                                    const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                    
                                    if (marksJson[legend?.token] && 'correction' in marksJson[legend?.token]) {                                
                                        if ('Tu00edtulo de vu00eddeo' in marksJson[legend?.token]['correction'] && marksJson[legend?.token]['correction']['Tu00edtulo de vu00eddeo']) {
                                            numberOfTitles = numberOfTitles + marksJson[legend?.token]['correction']['Tu00edtulo de vu00eddeo']
                                            hasTitles = true;
                                        }
                                        if ('Chyron de profesor' in marksJson[legend?.token]['correction'] && marksJson[legend?.token]['correction']['Chyron de profesor']) {
                                            numberOfChyrons = numberOfChyrons + marksJson[legend?.token]['correction']['Chyron de profesor']
                                            hasChyrons = true;
                                        }
                                    }
                                })

                                var finalPriceTMP = ((numberOfTitles * 7.5) + (numberOfChyrons * 7.5));
                                catFinalPrice = formatNumbersToPrint(finalPriceTMP, 2)
                                if (correctionFinalPrice !== finalPriceTMP) { setCorrectionFinalPrice(finalPriceTMP) }
                            }
    
                            const nameTMP = (hasTitles && hasChyrons)? 'Cambios en los chyrons y títulos' : (hasTitles)? 'Cambios en los títulos' : 'Cambios en los chyrons'
                            const descTMP = (hasTitles && hasChyrons)? 'Se contabilizan las correcciones de las peticiones de cambios de chyrons y títulos.' : (hasTitles)? 'Se contabilizan las correcciones de las peticiones de cambios de títulos' : 'Se contabilizan las correcciones de las peticiones de cambios de chyrons'
                            const numTot = ((numberOfChyrons)? numberOfChyrons : 0) +  ((numberOfTitles)? numberOfTitles : 0)

                            return (isValid && finalPriceTMP)? 
                                <a 
                                    className='reset-this-link w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                                    key={'extra-' + legend?.id}
                                    href={'#corrections'}
                                >
                                    <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                        <div className='px-2 py-[6px] w-full font-bold'>{nameTMP}</div>
                                        <div className='px-2 py-[6px] text-sm text-gray-400'>{(numTot)? numTot + ' motions / ' : ''} {catFinalPrice} €</div>
                                    </div>
                                    <p className='px-2 pb-1 text-sm'>
                                        {descTMP}
                                    </p>
                                </a>
                            : null
                        })}     


                    {billExtras?.map(extra => {
                        return <div 
                            className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                            key={'extra-2-' + extra?.id}
                        >
                            <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                <div className='px-2 py-[6px] w-full font-bold'>{(extra?.title)? remove_tags(extra?.title, true) : 'Sin título'}</div>
                                <div className='px-2 py-[6px] text-sm text-gray-400'>{formatNumbersToPrint(((extra?.price / 100) * (extra?.units / 100)), 2)} €</div>
                            </div>
                            <p
                                className='px-2 pb-1 text-sm text-gray-700'
                                dangerouslySetInnerHTML={{__html: (extra?.description)? extra?.description : '' }}
                            ></p>
                        </div>
                    })}            
                    

                    <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                        <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                            <p>Base imponible:</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(bill?.total_alias) / 1.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>IVA (21%):</p>
                            <p>
                                {formatNumbersToPrint((parseFloat(bill?.total_alias) / 1.21) * 0.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>Importe total:</p>
                            <p>
                                {formatNumbersToPrint(bill?.total_alias, 2)} €
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>










        
        <p className='w-full pt-8 flex justify-between items-center space-x-8 text-xs text-gray-500'>Tablas de leyendas:</p>
        <div className={`border border-gray-300 rounded overflow-hidden bg-gray-300`}>
            <div onClick={() => { setShowLegend(!showLegend) }} className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}>
                <p className='text-lg font-bold px-2'>Leyenda</p>
                {(tableOpen)? upArrowIcon : downArrowIcon}
            </div>

            <Collapse orientation="vertical" in={showLegend} timeout={1000}>
                <div className='p-4 text-xs bg-gray-50'>
                    <div className='w-full flex items-start space-x-4'> 
                        {(selectedLegend?.length)?
                            selectedLegend?.map((selectedTable, key) => {
                                return <div key={`start-table-${key}`} className='w-full border border-gray-300 rounded overflow-hidden'>
                                    <table className='w-full font-normal text-left bg-gray-100'>
                                        <thead className='bg-gray-200'>
                                            <tr>
                                                {selectedTable?.title?.map(element => {
                                                    return <th key={`start-table-title-${element}`} className='px-2 py-[6px]'>{element} </th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedTable?.table?.map(element => {
                                                return <tr key={`start-table-row-${element?.title}`}>
                                                    <td className='px-2 py-[6px] border'>{element?.title}</td>
                                                    <td className='px-2 py-[6px] border border-r-0'>{element?.price}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            })
                        : null }
                    </div>

                    <div className='w-full pt-6 pb-2 h-[15px] flex space-x-3 opacity-60'>
                        <div className='flex items-center'> {euroIcon} <p>Ya facturado</p> </div>
                        <div className='flex items-center'> <div className='scale-[0.8]'>{notTimeIcon}</div> <p>Sin novedades</p> </div>
                        <div className='flex items-center space-x-1 pl-1'> <p>---</p> <p>No facturado</p> </div>
                    </div>
                </div>
            </Collapse>
        </div>







       
        {(concepts?.length)?
            <>
                <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>

                <div className={`border border-gray-300 rounded bg-gray-300`}>
                    <div 
                        className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                        onClick={() => { setTableOpen(!tableOpen) }}
                    >
                        <p className='text-lg font-bold px-2'>Listado por vídeo</p>
                        {(tableOpen)? upArrowIcon : downArrowIcon}
                    </div>

                    <Collapse orientation="vertical" in={tableOpen} timeout={1000}>
                        <div className='border-t border-gray-300'>
                            <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                    <Table>

                                        <TableHead>
                                            <TableRow className={`${(bill_token || !tableOpen)? 'table-stiky-0' : 'table-stiky'}`}>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={'columns-'+column.id}
                                                        className='select-none'
                                                        align={column.align}
                                                        style={{ 
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            backgroundColor: 'inherit',
                                                            padding: 8,
                                                        }}
                                                    >
                                                        <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                            <div> {column.label} </div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {cats?.map((subCat) => {
                                                var finalPriceTMP = concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => {
                                                    const marksJsonFilter = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                    const priceTMPFilter = ((parseFloat(row?.preu))? (row?.preu - ((marksJsonFilter?.correction_price)? marksJsonFilter?.correction_price : 0)) : 0)
                                                    return priceTMPFilter
                                                }).reduce((partialSum, a) => partialSum + a, 0)


                                                return (finalPriceTMP)? <>
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        className="w-full bg-blue-50 duration-200"
                                                        key={'row-cat-loc-'+subCat.id} 
                                                    >
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        {(markCounter?.advanced?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                        {(markCounter?.other?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                        {(markCounter?.stock?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                        {(markCounter?.voiceover_ia?.price)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                    </TableRow>

                                                    {concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.map((row) => {
                                                        if (parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id)) {
                                                            return (
                                                                <TableRow 
                                                                    key={'row-loc-'+row.id} 
                                                                    hover 
                                                                    role="checkbox" 
                                                                    tabIndex={-1} 
                                                                    className="hover:bg-gray-100 duration-200 whitespace-nowrap scroll-m-[100px] "
                                                                    id={'list-'+row['id']}
                                                                >
                                                                    {columns.map((column) => {
                                                                        var value = row[column.id];
                                                                        value = processBaseTableFunc(value, column, row, false)

                                                                        return (
                                                                            <TableCell
                                                                                key={'column-data-'+column.id}
                                                                                align={column.align}
                                                                                style={{ borderBottom: 0, padding: 8 }}
                                                                                sx={{ fontSize: 13 }}
                                                                                className={`${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                            >
                                                                                {value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        } 
                                                    })}
                                                </> : null
                                            })}




                                            <TableRow className='bg-gray-300 bg-opacity-20'>
                                                {columns.map((column, colKey) => {
                                                    var priceTMP = (colKey)? 0 : 'Total';

                                                    if (priceTMP === 0) {
                                                        const conceptsTMP = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )
                                                        for (let row of conceptsTMP) {
                                                            var value = (row[column.id])? row[column.id] : '---';
                                                            const priceTMPFUNC = processBaseTableFunc(value, column, row, true);

                                                            if ((parseFloat(priceTMP) || parseFloat(priceTMP) === 0) && (parseFloat(priceTMPFUNC) || parseFloat(priceTMPFUNC) === 0)) {
                                                                priceTMP = priceTMP + priceTMPFUNC;
                                                            } else {
                                                                priceTMP = priceTMPFUNC;
                                                            }
                                                        }
                                                    }

                                                    if (column.id === 'minutes') {
                                                        priceTMP = (parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + 'm' : '---'
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={'column-data-'+column.id}
                                                            align={column.align}
                                                            style={{ borderBottom: 0, borderTop: '1px solid rgba(210, 210, 210, 1)', padding: 8, fontSize: 14 }}
                                                        >
                                                            <p
                                                                className={`text-sm whitespace-nowrap
                                                                    ${
                                                                        (!priceTMP || priceTMP === '---')? 'opacity-30' 
                                                                        : ((column.id === 'concepte' || column.id === 'preu')? 'font-bold' : 'opacity-50')
                                                                    }
                                                                `}  
                                                            >
                                                                {(!isNaN(priceTMP) && parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + ' €' : (priceTMP && priceTMP !== '---')? priceTMP : ''}
                                                            </p>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>




                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </Collapse>
                </div>


            </>
        : null }



        {(conceptsCorrections && Object.keys(conceptsCorrections)?.length)?
            <>
                <div className={`border border-gray-300 rounded bg-gray-300`}>
                    <div 
                        className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                        onClick={() => { setSecondaryTableOpen(!secondaryTableOpen) }}
                    >
                        <p className='text-lg font-bold px-2'>Listado de cambios en los chyrons y títulos </p>
                        {(secondaryTableOpen)? upArrowIcon : downArrowIcon}
                    </div>

                    <Collapse orientation="vertical" in={secondaryTableOpen} timeout={1000}>
                        <div className='bg-gray-100 border-t border-gray-300 text-sm'>
                            {Object.keys(conceptsCorrections)?.map((keyName, key) => {
                                var tema = conceptsCorrections[keyName]

                                var cyronsNum = 0;
                                var cyronsVids = [];
                                var titlesNum = 0;
                                var titlesVids = [];

                                if (tema?.noms?.length) {
                                    for (var row of tema?.noms) {
                                        const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
    
                                        for (var key of Object.keys(marksJson)) {
                                            var typesTMP = marksJson[key]
                                            if (typesTMP?.correction) {
                                                titlesNum = titlesNum + ((typesTMP?.correction['Tu00edtulo de vu00eddeo'])? typesTMP?.correction['Tu00edtulo de vu00eddeo'] : 0);
                                                if (typesTMP?.correction['Tu00edtulo de vu00eddeo']) { titlesVids.push(row?.concepte) }
                                                cyronsNum = cyronsNum + ((typesTMP?.correction['Chyron de profesor'])? typesTMP?.correction['Chyron de profesor'] : 0);
                                                if (typesTMP?.correction['Chyron de profesor']) { cyronsVids.push(row?.concepte) }
                                            }
                                        }
                                    }
                                }



                                return <div key={'tema'+tema?.nom} className={`group/tema px-2 py-2 ${(key % 2)? 'bg-gray-50' : ''} hover:bg-[#ebebeb] duration-200`}>
                                    <div className='w-full flex justify-between items-center'>
                                        <div className='space-x-1 w-full flex justify-start items-center'>
                                            <p className='mr-4'>{tema?.nom}</p>
                                        
                                            {(titlesNum)? <Tooltip title={titlesVids?.join(', ').replace(/,(?=[^,]+$)/, ' y')} placement="bottom" arrow>
                                                <p><span className='text-gray-500'>x{titlesNum} Títulos corregido</span></p>
                                            </Tooltip> : null}

                                            {(titlesNum && cyronsNum)? <p className='text-gray-500'>y</p> : null}

                                            {(cyronsNum)? <Tooltip title={cyronsVids?.join(', ').replace(/,(?=[^,]+$)/, ' y')} placement="bottom" arrow>
                                                <p><span className='text-gray-500'>x{cyronsNum} Chyrons de {currentCustomer?.label_professors?.toLowerCase()} corregidos</span></p>
                                            </Tooltip> : null}
                                        </div>

                                        <p className='min-w-[80px] text-right truncate'>{formatNumbersToPrint(tema?.price, 2)} €</p>
                                    </div>
                                </div>
                            })}



                            <div
                                className={`w-full flex justify-between items-center font-bold px-2 py-2 bg-gray-300 bg-opacity-20 duration-200`}
                                style={{ borderBottom: 0, borderTop: '1px solid rgba(210, 210, 210, 1)', padding: 8, fontSize: 14 }}
                            >
                                <p className='mr-4'>Total</p>
                                <p className='min-w-[80px] text-right truncate'>{formatNumbersToPrint(Object.keys(conceptsCorrections)?.map((keyName) => conceptsCorrections[keyName]?.price)?.reduce((partialSum, a) => partialSum + a, 0), 2)} €</p>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </>
        : null }



        {(concepts?.filter(video => (video['aux_json'] && JSON.parse(video['aux_json']) && JSON.parse(video['aux_json'])?.other_descs)? true : false)?.length)? <>
            <p className='text-xs pt-8 text-gray-500'>Conceptos extra:</p>

            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setDescTableOpen(!descTableOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Descripciones de los motions especiales</p>
                    {(descTableOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={descTableOpen} timeout={1000}>
                    <div className='w-full'>
                        {concepts?.filter(video => (video['aux_json'] && JSON.parse(video['aux_json']) && JSON.parse(video['aux_json'])?.other_descs)? true : false)?.map(video => {

                            const marksJson = (JSON.parse(video['aux_json']))? JSON.parse(video['aux_json']) : null;

                            return <div id={'video-'+video?.id} key={'idk-video-'+video?.id} className={`text-sm scroll-m-[100px] border rounded ${(parseInt(focusedVideo) === parseInt(video?.id))? 'bg-blue-50 border-blue-300 shadow-md' : 'bg-gray-50 border-gray-300'} duration-1000`}>
                                <div className={`flex justify-between space-x-2 whitespace-nowrap bg-gray-300 p-2 ${(parseInt(focusedVideo) === parseInt(video?.id))? 'bg-blue-300' : ''} duration-1000`}>
                                    <p className={`font-bold ${(parseInt(focusedVideo) === parseInt(video?.id))? 'text-white' : ''} duration-1000`}>{video?.concepte}</p>
                                    <a href={'#list-'+video['id']} className='flex px-1 ml-1 text-blue-500 hover:underline cursor-pointer'>Volver a {video?.concepte} {upArrowIcon}</a>
                                </div>

                                <div className='p-2'>
                                    <div className='w-full space-y-2'>
                                        {Object.keys(marksJson?.other_descs)?.map((element, key) => {
                                            var desc = decodeURIComponent(marksJson?.other_descs[element]?.replaceAll('+', ' '))
                                            var price = (marksJson?.other_prices[element])? marksJson?.other_prices[element] / 100 : 0

                                            return <div key={'idk-'+key} className='w-full p-2 bg-gray-200 bg-opacity-30 border border-gray-300 border-opacity-60 rounded'>
                                                <div dangerouslySetInnerHTML={{__html: (desc)? desc : ''}}></div>
                                                <p className='w-full text-right text-gray-400'>{formatNumbersToPrint(((parseFloat(price))? price : 0), 2) + ' €'}</p>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </Collapse>
            </div>
        </> : null }



        {(billExtras?.filter((el) => el?.childs?.length)?.length)?
            billExtras?.filter((el) => el?.childs?.length)?.map(element => {
                return <PersExtraItems 
                    element={element}
                    billOption={billOption}
                />
            })
        : null }








        {(concepts?.length && currentUser && !currentUser?.rol?.includes('user'))? <>
            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setTableXProfessorOpen(!tableXProfessorOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Listado por profesor</p>
                    {(tableXProfessorOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={tableXProfessorOpen} timeout={1000}>
                    <div className='border-t border-gray-300'>
                        <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                            <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                <Table>

                                    <TableHead>
                                        <TableRow className={`bg-[#e7e8eb]`}>
                                            {columns/* ?.filter((el) => ['concepte', 'new', 'minutes', 'minutes_end', 'minutes_price', 'preu']?.includes(el?.id)) */.map((column) => (
                                                <TableCell
                                                    key={'col-'+column.id}
                                                    className='select-none'
                                                    align={column.align}
                                                    style={{ 
                                                        minWidth: column.minWidth,
                                                        width: column.width,
                                                        backgroundColor: 'inherit',
                                                        padding: 8,
                                                    }}
                                                >
                                                    <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                        <div>{(column.id === 'concepte')? 'Profesores' : column.label}</div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                            var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                            return (finalPriceTMP)? <>
                                                {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                    
                                                    const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                    var info = {}
                                                    var marksJsons = [];
                                                    var languages = {}

                                                    for (var i in vids) {
                                                        const row = vids[i]
                                                        const markJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                        const isLastVideo = ((vids?.length-1) === parseInt(i))? true : false
                                                        marksJsons.push(markJson)

                                                        for (var column of columns) {
                                                            if (!info[column?.id]) { info[column?.id] = {} }
                                                            if (!info[column?.id]?.value && info[column?.id]?.value !== 0) { info[column?.id].value = 0 }
                                                            if (!info[column?.id]?.id && column.id) { info[column?.id].id = column.id }
                                                            if (!info[column?.id]?.align && column.align) { info[column?.id].align = column.align }

                                                            if (column?.id === 'minutes') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.min_facturable))? parseFloat(markJson?.min_facturable) : 0)
                                                                if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? '+ ' + formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + 'm' : '---' }
                                                            }

                                                            if (column?.id === 'subtitulation') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.sub))? parseFloat(markJson?.sub) : 0)
                                                                if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---' }
                                                            }

                                                            if (column?.id === 'voiceover_ia') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.voiceover_ia))? parseFloat(markJson?.voiceover_ia) : 0)
                                                                if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---' }
                                                            }
                                            
                                                            if (column?.id === 'motions_basic' && marksJsons?.length && isLastVideo) {
                                                                info[column?.id].value = getMotionListFunc(row, 'basic', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_estandard' && marksJsons?.length && isLastVideo) {
                                                                info[column?.id].value = getMotionListFunc(row, 'medium', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_advanced' && marksJsons?.length && isLastVideo) {
                                                                info[column?.id].value = getMotionListFunc(row, 'advanced', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_others' && marksJsons?.length && isLastVideo) {
                                                                info[column?.id].value = getMotionListFunc(row, 'other', marksJsons, true, true, 'new')
                                                            }

                                                            if (column?.id === 'stock' && marksJsons?.length && isLastVideo) {
                                                                info[column?.id].value = getMotionListFunc(row, 'stock', marksJsons, true, false, 'new')
                                                            }

                                                            if (column?.id === 'word_total') {
                                                                if (!info[column?.id]?.word_total && info[column?.id]?.word_total !== 0) { info[column?.id].word_total = 0 }
                                                                info[column?.id].word_total = info[column?.id]?.word_total + ((parseFloat(markJson?.word_total))? parseFloat(markJson?.word_total) : 0)
                                                                
                                                                if (!info[column?.id]?.word_price_total && info[column?.id]?.word_price_total !== 0) { info[column?.id].word_price_total = 0 }
                                                                info[column?.id].word_price_total = info[column?.id]?.word_price_total + ((parseFloat(markJson?.word_price_total))? parseFloat(markJson?.word_price_total) : 0)
                                                                
                                                                if (!info[column?.id]?.chyrons_facturated && info[column?.id]?.chyrons_facturated !== 0) { info[column?.id].chyrons_facturated = 0 }
                                                                info[column?.id].chyrons_facturated = info[column?.id]?.chyrons_facturated + ((parseFloat(markJson['chyrons_facturated']))? parseFloat(markJson['chyrons_facturated']) : 0)

                                                                if (!info[column?.id]?.chyron_ids && info[column?.id]?.chyron_ids !== 0) { info[column?.id].chyron_ids = '' }
                                                                info[column?.id].chyron_ids = info[column?.id]?.chyron_ids + ((markJson['chyron_ids'])? ',' + markJson['chyron_ids'] : '')
                                                                if (info[column?.id].chyron_ids?.split(',')?.length) { info[column?.id].chyron_ids = [...new Set(info[column?.id].chyron_ids?.split(','))]?.join(',') }

                                                                if (markJson['chyron_ids'] && markJson?.language) {
                                                                    if (!languages[markJson?.language] && languages[markJson?.language] !== 0) { languages[markJson?.language] = 0 }
                                                                    languages[markJson?.language]++;
                                                                }


                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id]?.word_total)? <div className='flex'><p>{info[column?.id]?.word_total}</p> <p className='px-2 opacity-50'>-</p> <p className='opacity-50'>{formatNumbersToPrint(info[column?.id]?.word_price_total, 2)} €</p></div> : '---'
                                                            
                                                                    if (info[column?.id]['chyrons_facturated'] >= 1) {
                                                                        var tooltipNames = null;
                                                                        if (info[column?.id]['chyron_ids']+'' && dataProfessors?.professorsByCustomer?.length) {
                                                                            for (var profId of (info[column?.id]['chyron_ids']+'')?.split(',')) {
                                                                                for (var prof of dataProfessors?.professorsByCustomer) {
                                                                                    if (parseInt(prof?.id) === parseInt(profId)) {
                                                                                        tooltipNames = (tooltipNames)? tooltipNames + ', ' + prof?.name : prof?.name
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        
                                                                        info[column?.id].value = <div className='flex justify-between space-x-2'>
                                                                            <div>{info[column?.id].value}</div>
                                                                            <div className='text-xs' style={{ marginLeft: 4 }}>
                                                                                <Tooltip
                                                                                    disableInteractive
                                                                                    title={
                                                                                        (tooltipNames)?
                                                                                            `Incluye las palabras traducidas de los chyrons ${(languages && Object.keys(languages)?.length > 0)? 'en algunos de estos idiomas (' + Object.keys(languages)?.map((el) => el?.toUpperCase())?.join(', ') + ')' : ''} de: ` + tooltipNames
                                                                                        :
                                                                                            ('+' + ((info[column?.id]['chyrons_facturated'] === 1)? info[column?.id]['chyrons_facturated'] + ` chyron del ${currentCustomer?.label_professor?.toLowerCase()} traducido` : (info[column?.id]['chyrons_facturated'] + ` chyrons de ${currentCustomer?.label_professors?.toLowerCase()} facturados`)))
                                                                                    }
                                                                                    placement="bottom"
                                                                                    arrow
                                                                                >
                                                                                    <button className='flex items-center space-x-[2px] text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded pl-[2px]'>
                                                                                        <p>+{info[column?.id]['chyrons_facturated']}</p>
                                                                                        {userIcon}
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                }
                                                            }
                    
                                                            if (column?.id === 'preu') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(row[column?.id]))? (parseFloat(row[column?.id]) - ((parseFloat(markJson?.correction_price))? parseFloat(markJson?.correction_price) : 0)) : 0);
                                                                if (isLastVideo) { info[column?.id].value = formatNumbersToPrint(info[column?.id].value, 2) + ' €'; }
                                                            }
                                                        }
                                                    }


                                                    return (vids?.length && info && Object?.keys(info)?.length)? <>
                                                        <TableRow 
                                                            key={'prodessor-' + column?.id} 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='w-full text-sm'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                            </TableCell>

                                                            {Object?.keys(info)?.map((valKey) => {
                                                                const column = info[valKey]
                                                                return (valKey !== 'concepte')? 
                                                                    <TableCell
                                                                        key={'column-data-'+column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {(column?.value)? column?.value : '---'}
                                                                    </TableCell>
                                                                : null 
                                                            })}
                                                        </TableRow>
                                                    </> : null
                                                })}
                                            </> : null
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Collapse>
            </div>



            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setTableXTopicOpen(!tableXTopicOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Listado por tema</p>
                    {(tableXTopicOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={tableXTopicOpen} timeout={1000}>
                    <div className='border-t border-gray-300'>
                        <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                            <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                <Table>

                                    <TableHead>
                                        <TableRow className={`bg-[#e7e8eb]`}>
                                            {columns/* ?.filter((el) => ['concepte', 'new', 'minutes', 'minutes_end', 'minutes_price', 'preu']?.includes(el?.id)) */?.map((column) => (
                                                <TableCell
                                                    key={'col-'+column.id}
                                                    className='select-none'
                                                    align={column.align}
                                                    style={{ 
                                                        minWidth: column.minWidth,
                                                        width: column.width,
                                                        backgroundColor: 'inherit',
                                                        padding: 8,
                                                    }}
                                                >
                                                    <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                        <div>{(column.id === 'concepte')? 'Profesores' : column.label}</div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                            const finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                                            

                                            return (finalPriceTMP)? <>
                                                {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                    const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                    var info = {}
                                                    var marksJsons = [];
                                                    var languages = {}


                                                    for (var i in vids) {
                                                        const row = vids[i]
                                                        const nameKey = (row?.concepte?.split('-')?.length)? row?.concepte?.split('-')[0] : row?.concepte
                                                        const markJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                        const isLastVideo = (!vids[parseInt(i)+1] || !vids[parseInt(i)+1]?.concepte?.includes(nameKey))? true : false
                                                        marksJsons.push(markJson)

    
                                                        for (var column of columns/* ?.filter((el) => ['concepte', 'new', 'minutes', 'minutes_end', 'minutes_price', 'preu']?.includes(el?.id)) */) {
                                                            if (!info[nameKey]) { info[nameKey] = {} }
                                                            if (!info[nameKey][column?.id]) { info[nameKey][column?.id] = {} }
                                                            if (!info[nameKey][column?.id]?.value && info[nameKey][column?.id]?.value !== 0) { info[nameKey][column?.id].value = 0 }
                                                            if (!info[nameKey][column?.id]?.id && column.id) { info[nameKey][column?.id].id = column.id }
                                                            if (!info[nameKey][column?.id]?.align && column.align) { info[nameKey][column?.id].align = column.align }
    
                                                            if (column?.id === 'concepte') {
                                                                if (!info[nameKey][column?.id]?.value) { info[nameKey][column?.id].value = nameKey }
                                                            }

                                                            if (column?.id === 'minutes') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.min_facturable))? parseFloat(markJson?.min_facturable) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? '+ ' + formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + 'm' : '---' }
                                                            }

                                                            if (column?.id === 'subtitulation') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.sub))? parseFloat(markJson?.sub) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---' }
                                                            }

                                                            if (column?.id === 'voiceover_ia') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.voiceover_ia))? parseFloat(markJson?.voiceover_ia) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---' }
                                                            }
                                            
                                                            if (column?.id === 'motions_basic' && marksJsons?.length && isLastVideo) {
                                                                info[nameKey][column?.id].value = getMotionListFunc(row, 'basic', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_estandard' && marksJsons?.length && isLastVideo) {
                                                                info[nameKey][column?.id].value = getMotionListFunc(row, 'medium', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_advanced' && marksJsons?.length && isLastVideo) {
                                                                info[nameKey][column?.id].value = getMotionListFunc(row, 'advanced', marksJsons, false, false, 'new')
                                                            }

                                                            if (column?.id === 'motions_others' && marksJsons?.length && isLastVideo) {
                                                                info[nameKey][column?.id].value = getMotionListFunc(row, 'other', marksJsons, true, true, 'new')
                                                            }

                                                            if (column?.id === 'stock' && marksJsons?.length && isLastVideo) {
                                                                info[nameKey][column?.id].value = getMotionListFunc(row, 'stock', marksJsons, true, false, 'new')
                                                            }

                                                            if (column?.id === 'word_total') {
                                                                if (!info[nameKey][column?.id]?.word_total && info[nameKey][column?.id]?.word_total !== 0) { info[nameKey][column?.id].word_total = 0 }
                                                                info[nameKey][column?.id].word_total = info[nameKey][column?.id]?.word_total + ((parseFloat(markJson?.word_total))? parseFloat(markJson?.word_total) : 0)
                                                                
                                                                if (!info[nameKey][column?.id]?.word_price_total && info[nameKey][column?.id]?.word_price_total !== 0) { info[nameKey][column?.id].word_price_total = 0 }
                                                                info[nameKey][column?.id].word_price_total = info[nameKey][column?.id]?.word_price_total + ((parseFloat(markJson?.word_price_total))? parseFloat(markJson?.word_price_total) : 0)
                                                                
                                                                if (!info[nameKey][column?.id]?.chyrons_facturated && info[nameKey][column?.id]?.chyrons_facturated !== 0) { info[nameKey][column?.id].chyrons_facturated = 0 }
                                                                info[nameKey][column?.id].chyrons_facturated = info[nameKey][column?.id]?.chyrons_facturated + ((parseFloat(markJson['chyrons_facturated']))? parseFloat(markJson['chyrons_facturated']) : 0)

                                                                if (!info[nameKey][column?.id]?.chyron_ids && info[nameKey][column?.id]?.chyron_ids !== 0) { info[nameKey][column?.id].chyron_ids = '' }
                                                                info[nameKey][column?.id].chyron_ids = info[nameKey][column?.id]?.chyron_ids + ((markJson['chyron_ids'])? ',' + markJson['chyron_ids'] : '')
                                                                if (info[nameKey][column?.id].chyron_ids?.split(',')?.length) { info[nameKey][column?.id].chyron_ids = [...new Set(info[nameKey][column?.id].chyron_ids?.split(','))]?.join(',') }

                                                                if (markJson['chyron_ids'] && markJson?.language) {
                                                                    if (!languages[markJson?.language] && languages[markJson?.language] !== 0) { languages[markJson?.language] = 0 }
                                                                    languages[markJson?.language]++;
                                                                }


                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id]?.word_total)? <div className='flex'><p>{info[nameKey][column?.id]?.word_total}</p> <p className='px-2 opacity-50'>-</p> <p className='opacity-50'>{formatNumbersToPrint(info[nameKey][column?.id]?.word_price_total, 2)} €</p></div> : '---'
                                                            
                                                                    if (info[nameKey][column?.id]['chyrons_facturated'] >= 1) {
                                                                        var tooltipNames = null;
                                                                        if (info[nameKey][column?.id]['chyron_ids']+'' && dataProfessors?.professorsByCustomer?.length) {
                                                                            for (var profId of (info[nameKey][column?.id]['chyron_ids']+'')?.split(',')) {
                                                                                for (var prof of dataProfessors?.professorsByCustomer) {
                                                                                    if (parseInt(prof?.id) === parseInt(profId)) {
                                                                                        tooltipNames = (tooltipNames)? tooltipNames + ', ' + prof?.name : prof?.name
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        
                                                                        info[nameKey][column?.id].value = <div className='flex justify-between space-x-2'>
                                                                            <div>{info[nameKey][column?.id].value}</div>
                                                                            <div className='text-xs' style={{ marginLeft: 4 }}>
                                                                                <Tooltip
                                                                                    disableInteractive
                                                                                    title={
                                                                                        (tooltipNames)?
                                                                                            `Incluye las palabras traducidas de los chyrons ${(languages && Object.keys(languages)?.length > 0)? 'en algunos de estos idiomas (' + Object.keys(languages)?.map((el) => el?.toUpperCase())?.join(', ') + ')' : ''} de: ` + tooltipNames
                                                                                        :
                                                                                            ('+' + ((info[nameKey][column?.id]['chyrons_facturated'] === 1)? info[nameKey][column?.id]['chyrons_facturated'] + ` chyron del ${currentCustomer?.label_professor?.toLowerCase()} traducido` : (info[nameKey][column?.id]['chyrons_facturated'] + ` chyrons de ${currentCustomer?.label_professors?.toLowerCase()} facturados`)))
                                                                                    }
                                                                                    placement="bottom"
                                                                                    arrow
                                                                                >
                                                                                    <button className='flex items-center space-x-[2px] text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded pl-[2px]'>
                                                                                        <p>+{info[nameKey][column?.id]['chyrons_facturated']}</p>
                                                                                        {userIcon}
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                }
                                                            }
                    
                                                            if (column?.id === 'preu') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(row[column?.id]))? (parseFloat(row[column?.id]) - ((parseFloat(markJson?.correction_price))? parseFloat(markJson?.correction_price) : 0)) : 0);
                                                                if (isLastVideo) { info[nameKey][column?.id].value = formatNumbersToPrint(info[nameKey][column?.id].value, 2) + ' €'; }
                                                            }
                                                        }
                                                    }


                                                    return (vids?.length && info && Object.keys(info)?.length)? <>
                                                        <TableRow 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="w-full bg-blue-50 duration-200"
                                                            key={'row-sub-'+subCat?.id}
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                            </TableCell>

                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            {(markCounter?.advanced?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                            {(markCounter?.stock?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                            {(markCounter?.other?.num)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                            {(markCounter?.voiceover_ia?.price)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null }
                                                        </TableRow>

                                                        {Object.keys(info)?.map((topicKey) => {
                                                            const video = info[topicKey]


                                                            return <TableRow 
                                                                key={'row-vid-'+topicKey} 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                            >
                                                                {Object.keys(video).map((videoKey) => {
                                                                    var column = (video[videoKey])? video[videoKey] : '---';

                                                                    return <TableCell
                                                                        key={'column-data-'+column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {(column?.value)? column?.value : '---'}
                                                                    </TableCell>
                                                                })}
                                                            </TableRow>

                                                        })}
                                                    </> : null
                                                })}
                                            </> : null
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Collapse>
            </div>
        </> : null }




    </div>

  ) : 

    <div className='absolute z-10 w-full h-full'>
        <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>

}