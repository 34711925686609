export default function LoadAnimation({ loading, times, hasSomeData, isList }) {
  return (loading)? (
    <div className={`z-0 w-full space-y-1 flex items-center justify-start flex-wrap ${(isList)? 'pt-2' : 'absolute top-[60px]'}`}>


      {(!hasSomeData && isList)? <div className={`w-full h-[35px] bg-gray-200 bg-opacity-75 animate-pulse rounded-xl`}></div> : null }

      {new Array((parseInt(times))? parseInt(times) : 5).fill("").map((_, i) => {
        const percentage = parseInt(Math.abs( (((i) / ((parseInt(times))? parseInt(times) : 5)) * 100) - 100 ))
        return <div
          className={`w-full ${(isList)? 'h-[110px]' : 'h-[57vw] min-h-[270px] sm:min-h-0 sm:h-[22.5vw] md:h-[21.5vw] lg:h-[17vw] xl:h-[16.5vw] 2xl:h-[13.5vw] min-[1800px]:h-[11.3vw] sm:w-[33.3%] lg:w-[25%] 2xl:w-[20%] min-[1800px]:w-[16.66%] p-2'}`}
          style={{ opacity: `${percentage}%` }}
        >
          <div className='w-full h-full bg-gray-200 bg-opacity-75 animate-pulse rounded-xl'></div>
        </div>
      })}


    </div>
  ) : null ;
}