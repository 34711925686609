import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { useParams } from "react-router-dom";

import GetEmail from './../graphql/queries/GetEmail'





const EmailViewer = () => {




  const { id } = useParams()
  const { currentUser } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)


  const [email, setEmail] = useState(null);


  const [getEmail, { data: dataEmail }] = useLazyQuery(GetEmail, {
    variables: {
      own_user_token: currentUser?.token,
      id: id,
    }
  })





  useEffect(() => { 
    setCurrentNavActive('dashboard')

    getEmail()
  }, [])


  useEffect(() => { 
    if (dataEmail?.emailLog) {
      setEmail(dataEmail.emailLog)
    } else {
      setEmail(null)
    }
  }, [dataEmail])


  

  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl py-14 pl-2'>Visor de emails</h1>
      </div>

      {(email)? 
        <div>
          <p className='px-1'>Estás leyendo el email  con título <strong>{email.title}</strong>{email.sended? ' enviado el ' + email.sendedAtFormated : ', el cual se encuentra sin ser enviado'}.</p>
          <div className='bg-gray-100 p-6 rounded-md mb-10'>
            <div className='email-viewer' dangerouslySetInnerHTML={{__html: email.body }}></div>
          </div>
        </div>
      :
        <div>
          <p>Email no encontrado.</p>
        </div>
      }


    </div>
  )
}

export default EmailViewer
