import { useState, useContext, useEffect } from 'react'








export default function TipologyPlusSegment({ video, small, fill }) {



  const segments = (video?.tags)? video?.tags?.split(',') : null;



  return (video?.search_module_name || segments?.length)? (
    <div
      className={`relative whitespace-nowrap flex flex-wrap items-center justify-center rounded px-2
        ${(fill)? 'w-full h-full' : ''} 
        ${(small)? 'text-[12px]' : `text-[12px]`}
      `}
    >
      <p className='w-full opacity-70'>{video?.search_module_name}</p>
      <p className={`${(segments?.length)? '' : 'opacity-30 h-[10px]'}`}>{(segments?.length)? segments?.join(', ').replace(/,([^,]*)$/, ' y ' + '$1') : '---'}</p>
    </div>
  ) : <p className='text-gray-500 text-[11px]'>---</p> ;
}