import { useContext } from 'react'
import RowButtons from '../../components/CustomTables/Components/RowButtons'
import { FuncContext } from '../../contexts/FuncContext'







const static_data = [
  {
    id: 94,
    number: "#2024003",
    description: "Curso 3 Edición",
    date: "(Diciembre 2023)",
    price: "1.067,56",
    link: "https://review.clicksound.io/your-billing/ISD/isd-c3_es-edicion-diciembre-2023",

    data: [
      [
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Sara Gómez",
          videos: [
            ["T000282-V1-0045SGO_es", "1,56", "1,56", "10,00", "15,60"], 
            ["T000282-V3-0045SGO_es", "1,84", "1,84", "10,00", "18,40"], 
            ["T000282-V4-0045SGO_es", "2,90", "2,90", "10,00", "29,00"], 
            ["T000289-V1-0045SGO_es", "1,42", "1,42", "10,00", "14,20"], 
            ["T000289-V2-0045SGO_es", "2,23", "2,23", "10,00", "22,30"], 
            ["T000289-V3-0045SGO_es", "1,48", "1,48", "10,00", "14,80"],
          ]
        },
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          anotation: "(Cambio de categoría)",
          professor: "Sara Gómez",
          videos: [
            ["T000246-V3-0045SGO_es", "5,49", "5,49", "10,00", "54,90"], 
            ["T000282-V2-0045SGO_es", "2,02", "2,02", "10,00", "20,20"], 
            ["T000289-V4-0045SGO_es", "2,18", "2,18", "10,00", "21,80"], 
            ["T000289-V5-0045SGO_es", "6,32", "6,32", "10,00", "63,20"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición básica",
          professor: "José María Ricart",
          videos: [
            ["T000310-V0-0115JRI_es", "6,69", "6,69", " 9,00", "60,21"], 
            ["T000310-V1-0115JRI_es", "5,56", "5,56", " 9,00", "50,04"], 
            ["T000310-V2-0115JRI_es", "2,53", "2,53", " 9,00", "22,77"], 
            ["T000310-V3-0115JRI_es", "3,23", "3,23", " 9,00", "29,07"], 
            ["T000310-V4-0115JRI_es", "4,62", "4,62", " 9,00", "41,58"], 
            ["T000310-V5-0115JRI_es", "9,18", "9,18", " 9,00", "82,62"], 
            ["T000310-V6-0115JRI_es", "5,57", "5,57", " 9,00", "50,13"], 
            ["T000310-V7-0115JRI_es", "1,59", "1,59", " 9,00", "14,31"], 
            ["T000310-V8-0115JRI_es", "3,12", "3,12", " 9,00", "28,08"], 
            ["T000310-V10-0115JRI_es", "6,62", "6,62", " 9,00", "59,58"], 
            ["T000310-V11-0115JRI_es", "5,59", "5,59", " 9,00", "50,31"], 
            ["T000310-V12-0115JRI_es", "5,90", "5,90", " 9,00", "53,10"], 
            ["T000310-V13-0115JRI_es", "4,87", "4,87", " 9,00", "43,83"], 
            ["T000310-V14-0115JRI_es", "4,93", "4,93", " 9,00", "44,37"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición estándar",
          professor: "José María Ricart",
          videos: [
            ["T000310-V9-0115JRI_es", "4,26", "4,26", " 36,00", "153,36"]
          ]
        },
      ],

      [
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Alba Villanueva",
          videos: [
            ["T000254-V1-0053AVI_es", "0,24", "0,94", " 10,00", "2,40"], 
            ["T000254-V2-0053AVI_es", "0,23", "4,71", " 10,00", "2,30"], 
            ["T000254-V3-0053AVI_es", "0,25", "2,13", " 10,00", "2,50"], 
            ["T000254-V4-0053AVI_es", "0,26", "1,37", " 10,00", "2,60"]
          ]
        },
      ]
    ]
  },

  {
    id: 149,
    number: "#2024021",
    description: "Curso 3 Edición",
    date: "(Hasta el 31/01/2024)",
    price: "5.748,18",
    link: "https://review.clicksound.io/your-billing/ISD/isd-c3_es-edición-31-01-2024",

    data: [
      [
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Sara Gómez",
          videos: [
            ["T000282-V1-0045SGO_es", "1,56", "1,56", "10,00", "15,60"], 
            ["T000282-V3-0045SGO_es", "1,84", "1,84", "10,00", "18,40"], 
            ["T000282-V4-0045SGO_es", "2,90", "2,90", "10,00", "29,00"], 
            ["T000289-V1-0045SGO_es", "1,42", "1,42", "10,00", "14,20"], 
            ["T000289-V2-0045SGO_es", "2,23", "2,23", "10,00", "22,30"], 
            ["T000289-V3-0045SGO_es", "1,48", "1,48", "10,00", "14,80"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en plató) / Edición estándar",
          anotation: "(Cambio de categoría)",
          professor: "Sara Gómez",
          videos: [
            ["T000246-V3-0045SGO_es", "5,49", "5,49", "40,00", "219,60"], 
            ["T000282-V2-0045SGO_es", "2,02", "2,02", "40,00", "80,80"], 
            ["T000289-V4-0045SGO_es", "2,18", "2,18", "40,00", "87,20"], 
            ["T000289-V5-0045SGO_es", "6,32", "6,32", "40,00", "252,80"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición básica",
          professor: "José María Ricart",
          videos: [
            ["T000310-V0-0115JRI_es", "6,69", "6,69", "9,00", "60,21"], 
            ["T000310-V1-0115JRI_es", "5,56", "5,56", "9,00", "50,04"], 
            ["T000310-V2-0115JRI_es", "2,53", "2,53", "9,00", "22,77"], 
            ["T000310-V3-0115JRI_es", "3,23", "3,23", "9,00", "29,07"], 
            ["T000310-V4-0115JRI_es", "4,62", "4,62", "9,00", "41,58"], 
            ["T000310-V5-0115JRI_es", "9,18", "9,18", "9,00", "82,62"], 
            ["T000310-V6-0115JRI_es", "5,57", "5,57", "9,00", "50,13"], 
            ["T000310-V7-0115JRI_es", "1,59", "1,59", "9,00", "14,31"], 
            ["T000310-V8-0115JRI_es", "3,12", "3,12", "9,00", "28,08"], 
            ["T000310-V10-0115JRI_es", "6,62", "6,62", "9,00", "59,58"], 
            ["T000310-V11-0115JRI_es", "5,59", "5,59", "9,00", "50,31"], 
            ["T000310-V12-0115JRI_es", "5,90", "5,90", "9,00", "53,10"], 
            ["T000310-V13-0115JRI_es", "4,87", "4,87", "9,00", "43,83"], 
            ["T000310-V14-0115JRI_es", "4,93", "4,93", "9,00", "44,37"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición estándar",
          professor: "José María Ricart",
          videos: [
            ["T000310-V9-0115JRI_es", "4,26", "4,26", "36,00", "153,36"]
          ]
        },
      ],
      [
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Alba Villanueva",
          videos: [
            ["T000248-V1-0053AVI_es", "1,44", "1,44", "10,00", "14,40"], 
            ["T000248-V3-0053AVI_es", "1,40", "1,40", "10,00", "14,00"], 
            ["T000312-V1-0053AVI_es", "1,11", "1,11", "10,00", "11,10"], 
            ["T000312-V4-0053AVI_es", "3,95", "3,95", "10,00", "39,50"], 
            ["T000312-V5-0053AVI_es", "2,65", "2,65", "10,00", "26,50"], 
            ["T000312-V6-0053AVI_es", "1,95", "1,95", "10,00", "19,50"], 
            ["T000314-V1-0053AVI_es", "1,14", "1,14", "10,00", "11,40"], 
            ["T000314-V2-0053AVI_es", "1,07", "1,07", "10,00", "10,70"], 
            ["T000314-V3-0053AVI_es", "2,10", "2,10", "10,00", "21,00"], 
            ["T000314-V4-0053AVI_es", "1,04", "1,04", "10,00", "10,40"], 
            ["T000314-V5-0053AVI_es", "1,91", "1,91", "10,00", "19,10"], 
            ["T000315-V1-0053AVI_es", "1,33", "1,33", "10,00", "13,30"], 
            ["T000315-V5-0053AVI_es", "0,80", "0,80", "10,00", "8,00"]
          ]
        },
        {
          professor: "Daniel Morgado",
          videos: [
            ["T000304-V1-0066DMO_es", "1,15", "1,15", "10,00", "11,50"], 
            ["T000304-V2-0066DMO_es", "2,95", "2,95", "10,00", "29,50"], 
            ["T000304-V3-0066DMO_es", "6,36", "6,36", "10,00", "63,60"]
          ]
        },
        {
          professor: "Esther Serra",
          videos: [
            ["T000261-V0-0107ESE_es", "1,72", "1,72", "10,00", "17,20"], 
            ["T000261-V1-0107ESE_es", "1,70", "1,70", "10,00", "17,00"], 
            ["T000261-V2-0107ESE_es", "6,63", "6,63", "10,00", "66,30"], 
            ["T000261-V3-0107ESE_es", "2,65", "2,65", "10,00", "26,50"], 
            ["T000261-V4-0107ESE_es", "3,27", "3,27", "10,00", "32,70"], 
            ["T000261-V5-0107ESE_es", "2,19", "2,19", "10,00", "21,90"], 
            ["T000261-V6-0107ESE_es", "2,88", "2,88", "10,00", "28,80"]
          ]
        },
        {
          professor: "Ignasi Carrera",
          videos: [
            ["T000250-V1-0070ICA_es", "1,39", "1,39", "10,00", "13,90"], 
            ["T000250-V2-0070ICA_es", "6,36", "6,36", "10,00", "63,60"], 
            ["T000250-V3-0070ICA_es", "4,03", "4,03", "10,00", "40,30"], 
            ["T000250-V4-0070ICA_es", "5,92", "5,92", "10,00", "59,20"]
          ]
        },
        {
          professor: "Mònica Velázquez",
          videos: [
            ["T000257-V0-0130MVE_es", "1,25", "1,25", "10,00", "12,50"], 
            ["T000257-V2-0130MVE_es", "2,74", "2,74", "10,00", "27,40"], 
            ["T000258-V1-0130MVE_es", "0,92", "0,92", "10,00", "9,20"]
          ]
        },
        {
          professor: "Sergio Jarque",
          videos: [
            ["T000280-V1-0122SJA_es", "1,40", "1,40", "10,00", "14,00"], 
            ["T000280-V2-0122SJA_es", "2,42", "2,42", "10,00", "24,20"], 
            ["T000280-V4-0122SJA_es", "2,70", "2,70", "10,00", "27,00"]
          ]
        },

        {
          cat_name: "Vídeo en persona (en plató) / Edición estándar",
          professor: "Alba Villanueva",
          videos: [
            ["T000248-V2-0053AVI_es", "1,90", "1,90", "40,00", "76,00"], 
            ["T000248-V4-0053AVI_es", "1,73", "1,73", "40,00", "69,20"], 
            ["T000312-V2-0053AVI_es", "1,90", "1,90", "40,00", "76,00"], 
            ["T000312-V3-0053AVI_es", "3,90", "3,90", "40,00", "156,00"], 
            ["T000312-V7-0053AVI_es", "2,90", "2,90", "40,00", "116,00"], 
            ["T000312-V8-0053AVI_es", "3,60", "3,60", "40,00", "144,00"], 
            ["T000315-V2-0053AVI_es", "2,55", "2,55", "40,00", "102,00"], 
            ["T000315-V3-0053AVI_es", "4,54", "4,54", "40,00", "181,60"], 
            ["T000315-V4-0053AVI_es", "3,83", "3,83", "40,00", "153,20"]
          ]
        },
        {
          professor: "Ignasi Carrera",
          videos: [
            ["T000250-V0-0070ICA_es", "1,25", "1,25", "40,00", "50,00"], 
            ["T000250-V5-0070ICA_es", "3,41", "3,41", "40,00", "136,40"], 
            ["T000250-V6-0070ICA_es", "0,91", "0,91", "40,00", "36,40"]
          ]
        },
        {
          professor: "Mònica Velázquez",
          videos: [
            ["T000257-V1-0130MVE_es", "2,17", "2,17", "40,00", "86,80"], 
            ["T000257-V3-0130MVE_es", "2,22", "2,22", "40,00", "88,80"], 
            ["T000257-V4-0130MVE_es", "3,34", "3,34", "40,00", "133,60"], 
            ["T000258-V2-0130MVE_es", "3,27", "3,27", "40,00", "130,80"], 
            ["T000258-V3-0130MVE_es", "1,85", "1,85", "40,00", "74,00"], 
            ["T000258-V4-0130MVE_es", "2,94", "2,94", "40,00", "117,60"], 
            ["T000258-V5-0130MVE_es", "3,66", "3,66", "40,00", "146,40"]
          ]
        },
        {
          professor: "Sergio Jarque",
          videos: [
            ["T000280-V0-0122SJA_es", "0,95", "0,95", "40,00", "38,00"], 
            ["T000280-V3-0122SJA_es", "9,42", "9,42", "40,00", "376,80"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición básica",
          professor: "María Jesús Lucero",
          videos: [
            ["T000247-V0-0125MLU_es", "2,54", "2,54", "9,00", "22,86"], 
            ["T000247-V1-0125MLU_es", "3,06", "3,06", "9,00", "27,54"], 
            ["T000247-V2-0125MLU_es", "9,95", "9,95", "9,00", "89,55"], 
            ["T000247-V3-0125MLU_es", "6,97", "6,97", "9,00", "62,73"], 
            ["T000247-V5-0125MLU_es", "5,96", "5,96", "9,00", "53,64"], 
            ["T000247-V6-0125MLU_es", "7,21", "7,21", "9,00", "64,89"], 
            ["T000247-V7-0125MLU_es", "7,87", "7,87", "9,00", "70,83"], 
            ["T000253-V1-0125MLU_es", "1,89", "1,89", "9,00", "17,01"], 
            ["T000253-V2-0125MLU_es", "6,15", "6,15", "9,00", "55,35"], 
            ["T000253-V3-0125MLU_es", "5,74", "5,74", "9,00", "51,66"], 
            ["T000253-V4-0125MLU_es", "3,21", "3,21", "9,00", "28,89"], 
            ["T000253-V5-0125MLU_es", "8,05", "8,05", "9,00", "72,45"], 
            ["T000303-V1-0125MLU_es", "1,47", "1,47", "9,00", "13,23"], 
            ["T000303-V2-0125MLU_es", "2,98", "2,98", "9,00", "26,82"], 
            ["T000303-V3-0125MLU_es", "9,42", "9,42", "9,00", "84,78"]
          ]
        },
        {
          professor: "Minerva Gómez",
          videos: [
            ["T000097-V0-0119MGO_es", "1,57", "1,57", "9,00", "14,13"], 
            ["T000097-V1-0119MGO_es", "2,51", "2,51", "9,00", "22,59"], 
            ["T000097-V2-0119MGO_es", "3,24", "3,24", "9,00", "29,16"], 
            ["T000097-V3-0119MGO_es", "3,34", "3,34", "9,00", "30,06"], 
            ["T000097-V4-0119MGO_es", "3,99", "3,99", "9,00", "35,91"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición estándar",
          professor: "Sergio Vañó",
          videos: [
            ["T000311-V3-0105SVA_en", "0,17", "1,60", "36,00", "6,12"], 
            ["T000311-V4-0105SVA_en", "0,10", "0,56", "36,00", "3,60"], 
            ["T000311-V6-0105SVA_en", "0,15", "1,07", "36,00", "5,40"], 
            ["T000311-V7-0105SVA_en", "0,17", "0,52", "36,00", "6,12"]
          ]
        },
      ]
    ]
  },

  {
    id: 952,
    number: "P_2024145",
    description: "Curso 3 Edición",
    date: "(23/04/2024 - 29/07/2024)",
    price: "1.057,76",
    link: null,

    data: [
      [
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Sara Gómez",
          videos: [
            ["T000282-V1-0045SGO_es", "15,60"], 
            ["T000282-V3-0045SGO_es", "18,40"], 
            ["T000282-V4-0045SGO_es", "29,00"], 
            ["T000289-V1-0045SGO_es", "14,20"], 
            ["T000289-V2-0045SGO_es", "22,30"], 
            ["T000289-V3-0045SGO_es", "14,80"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en plató) / Edición básica",
          professor: "Sara Gómez",
          videos: [
            ["T000246-V3-0045SGO_es", "54,90"], 
            ["T000282-V2-0045SGO_es", "20,20"], 
            ["T000289-V4-0045SGO_es", "21,80"], 
            ["T000289-V5-0045SGO_es", "63,20"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición básica",
          professor: "José María Ricart",
          videos: [
            ["T000310-V0-0115JRI_es", "60,21"], 
            ["T000310-V1-0115JRI_es", "50,04"], 
            ["T000310-V2-0115JRI_es", "22,77"], 
            ["T000310-V3-0115JRI_es", "29,07"], 
            ["T000310-V4-0115JRI_es", "41,58"], 
            ["T000310-V5-0115JRI_es", "82,62"], 
            ["T000310-V6-0115JRI_es", "50,13"], 
            ["T000310-V7-0115JRI_es", "14,31"], 
            ["T000310-V8-0115JRI_es", "28,08"], 
            ["T000310-V10-0115JRI_es", "59,58"], 
            ["T000310-V11-0115JRI_es", "50,31"], 
            ["T000310-V12-0115JRI_es", "53,10"], 
            ["T000310-V13-0115JRI_es", "43,83"], 
            ["T000310-V14-0115JRI_es", "44,37"]
          ]
        },
        {
          cat_name: "Vídeo en persona (en remoto) / Edición estándar",
          professor: "José María Ricart",
          videos: [
            ["T000310-V9-0115JRI_es", "153,36"]
          ]
        },
      ],
    ]
  },
]



const vimeo_urls = {
  "T000311-V3-0105SVA_en": "https://vimeo.com/user169707483/review/893293228/1afe69ace7",
  "T000311-V4-0105SVA_en": "https://vimeo.com/manage/videos/893293266/482d55a98e",
  "T000311-V6-0105SVA_en": "https://vimeo.com/user169707483/review/893293284/90f96bb5d3",
  "T000311-V7-0105SVA_en": "https://vimeo.com/manage/videos/893293309/1b3ae8e8bc",
  "T000254-V1-0053AVI_es": "https://vimeo.com/user169707483/review/723369709/0942cd4803",
  "T000254-V2-0053AVI_es": "https://vimeo.com/user169707483/review/723369829/6d814502dc",
  "T000254-V3-0053AVI_es": "https://vimeo.com/user169707483/review/723370227/2005b8a3d9",
  "T000254-V4-0053AVI_es": "https://vimeo.com/user169707483/review/723370403/aa723c3631",
  "T000310-V0-0115JRI_es": "https://vimeo.com/user169707483/review/899206715/83b7c26d40",
  "T000310-V1-0115JRI_es": "https://vimeo.com/user169707483/review/899206738/117e1681b4",
  "T000310-V10-0115JRI_es": "https://vimeo.com/user169707483/review/899206969/64ae7f3a17",
  "T000310-V11-0115JRI_es": "https://vimeo.com/user169707483/review/899207000/ffc397d1e6",
  "T000310-V12-0115JRI_es": "https://vimeo.com/user169707483/review/899207034/300943f65f",
  "T000310-V13-0115JRI_es": "https://vimeo.com/user169707483/review/899207069/2c90c3164e",
  "T000310-V14-0115JRI_es": "https://vimeo.com/user169707483/review/899207091/18b10e729b",
  "T000310-V2-0115JRI_es": "https://vimeo.com/user169707483/review/899206759/c069c58579",
  "T000310-V3-0115JRI_es": "https://vimeo.com/user169707483/review/899206784/b737b29038",
  "T000310-V4-0115JRI_es": "https://vimeo.com/user169707483/review/899206816/5ff3d60cf7",
  "T000310-V5-0115JRI_es": "https://vimeo.com/user169707483/review/899206837/9499af4dbe",
  "T000310-V6-0115JRI_es": "https://vimeo.com/user169707483/review/899206872/936db09c71",
  "T000310-V7-0115JRI_es": "https://vimeo.com/user169707483/review/899206900/9cb071fff9",
  "T000310-V8-0115JRI_es": "https://vimeo.com/user169707483/review/899206922/97f5714e84",
  "T000310-V9-0115JRI_es": "https://vimeo.com/user169707483/review/899206937/1810e3abbb",
  "T000246-V3-0045SGO_es": "https://vimeo.com/user169707483/review/899793568/b43506ad73",
  "T000282-V1-0045SGO_es": "https://vimeo.com/user169707483/review/899793604/f2b4fe4e84",
  "T000282-V2-0045SGO_es": "https://vimeo.com/user169707483/review/899793617/dc1554d4b1",
  "T000282-V3-0045SGO_es": "https://vimeo.com/user169707483/review/899793640/240501cdb2",
  "T000282-V4-0045SGO_es": "https://vimeo.com/user169707483/review/899793657/953e098bfd",
  "T000289-V1-0045SGO_es": "https://vimeo.com/user169707483/review/899793673/c361ff269c",
  "T000289-V2-0045SGO_es": "https://vimeo.com/user169707483/review/899793688/e87a4afa16",
  "T000289-V3-0045SGO_es": "https://vimeo.com/user169707483/review/899793703/d077d2aa4a",
  "T000289-V4-0045SGO_es": "https://vimeo.com/user169707483/review/899793718/4bf6b91aa6",
  "T000289-V5-0045SGO_es": "https://vimeo.com/user169707483/review/899793740/88b3a7543b",
  "T000247-V0-0125MLU_es": "https://vimeo.com/user169707483/review/903617042/99d969ab0a",
  "T000247-V1-0125MLU_es": "https://vimeo.com/user169707483/review/903617081/52e449cd2e",
  "T000247-V2-0125MLU_es": "https://vimeo.com/user169707483/review/903617107/0eca95cb14",
  "T000247-V3-0125MLU_es": "https://vimeo.com/user169707483/review/903617163/0a705ce66b",
  "T000247-V5-0125MLU_es": "https://vimeo.com/user169707483/review/903617211/65ad9692c2",
  "T000247-V6-0125MLU_es": "https://vimeo.com/user169707483/review/903617232/da60608533",
  "T000247-V7-0125MLU_es": "https://vimeo.com/user169707483/review/903617277/657af46260",
  "T000253-V1-0125MLU_es": "https://vimeo.com/user169707483/review/903617307/14d4982ccf",
  "T000253-V2-0125MLU_es": "https://vimeo.com/user169707483/review/903617330/0c0d0cb670",
  "T000253-V3-0125MLU_es": "https://vimeo.com/user169707483/review/903617354/ba61e6fb23",
  "T000253-V4-0125MLU_es": "https://vimeo.com/user169707483/review/903617398/5a3f3239ea",
  "T000253-V5-0125MLU_es": "https://vimeo.com/user169707483/review/903617423/f031afc40b",
  "T000303-V1-0125MLU_es": "https://vimeo.com/user169707483/review/903617451/627272baa7",
  "T000303-V2-0125MLU_es": "https://vimeo.com/user169707483/review/903617487/f3206ed0b2",
  "T000303-V3-0125MLU_es": "https://vimeo.com/user169707483/review/903617501/4606085248",
  "T000257-V0-0130MVE_es": "https://vimeo.com/user169707483/review/904368737/99da9cdc29",
  "T000257-V1-0130MVE_es": "https://vimeo.com/user169707483/review/904368776/b7de197832",
  "T000257-V2-0130MVE_es": "https://vimeo.com/user169707483/review/904368824/355d3b0004",
  "T000257-V3-0130MVE_es": "https://vimeo.com/user169707483/review/904368870/388f8796a6",
  "T000257-V4-0130MVE_es": "https://vimeo.com/user169707483/review/904368905/cd6afcedfe",
  "T000258-V1-0130MVE_es": "https://vimeo.com/user169707483/review/904368945/1c6a1ef48d",
  "T000258-V2-0130MVE_es": "https://vimeo.com/user169707483/review/904368965/7b9eda9ca2",
  "T000258-V3-0130MVE_es": "https://vimeo.com/user169707483/review/904369006/7634c5e596",
  "T000258-V4-0130MVE_es": "https://vimeo.com/user169707483/review/904369030/481ebabca8",
  "T000258-V5-0130MVE_es": "https://vimeo.com/user169707483/review/904369065/a913fcdb09",
  "T000250-V0-0070ICA_es": "https://vimeo.com/user169707483/review/904392260/a586357088",
  "T000250-V1-0070ICA_es": "https://vimeo.com/user169707483/review/904392288/eebbe33fc6",
  "T000250-V2-0070ICA_es": "https://vimeo.com/user169707483/review/904392322/3762852dfc",
  "T000250-V3-0070ICA_es": "https://vimeo.com/user169707483/review/904392482/35648c3d96",
  "T000250-V4-0070ICA_es": "https://vimeo.com/user169707483/review/904392562/0a46c2ff7d",
  "T000250-V5-0070ICA_es": "https://vimeo.com/user169707483/review/904392672/734fa4d4d2",
  "T000250-V6-0070ICA_es": "https://vimeo.com/user169707483/review/904392761/f5618a978f",
  "T000261-V0-0107ESE_es": "https://vimeo.com/user169707483/review/906318443/3757ef607e",
  "T000261-V1-0107ESE_es": "https://vimeo.com/user169707483/review/906318581/d731812629",
  "T000261-V2-0107ESE_es": "https://vimeo.com/user169707483/review/906318654/d7bbcfda67",
  "T000261-V3-0107ESE_es": "https://vimeo.com/user169707483/review/906318898/90a74ecb83",
  "T000261-V4-0107ESE_es": "https://vimeo.com/user169707483/review/906318993/2616aadcd8",
  "T000261-V5-0107ESE_es": "https://vimeo.com/user169707483/review/906319107/1191f5d7f8",
  "T000261-V6-0107ESE_es": "https://vimeo.com/user169707483/review/906319181/c5601a8cce",
  "T000280-V0-0122SJA_es": "https://vimeo.com/user169707483/review/906371991/a600ac6930",
  "T000280-V1-0122SJA_es": "https://vimeo.com/user169707483/review/906372027/80e954e052",
  "T000280-V2-0122SJA_es": "https://vimeo.com/user169707483/review/906372081/aeb1a7b685",
  "T000280-V3-0122SJA_es": "https://vimeo.com/user169707483/review/906372143/18112657e5",
  "T000280-V4-0122SJA_es": "https://vimeo.com/user169707483/review/906372340/2da353b0f4",
  "T000248-V1-0053AVI_es": "https://vimeo.com/user169707483/review/906636263/b49cc00257",
  "T000248-V2-0053AVI_es": "https://vimeo.com/user169707483/review/906636288/45dfd5e870",
  "T000248-V3-0053AVI_es": "https://vimeo.com/user169707483/review/906636307/21e9bf1623",
  "T000248-V4-0053AVI_es": "https://vimeo.com/user169707483/review/906636331/bb603b73ce",
  "T000312-V1-0053AVI_es": "https://vimeo.com/user169707483/review/906636355/838514059f",
  "T000312-V2-0053AVI_es": "https://vimeo.com/user169707483/review/906636373/2578d198b9",
  "T000312-V3-0053AVI_es": "https://vimeo.com/user169707483/review/906636409/b6e5b47019",
  "T000312-V4-0053AVI_es": "https://vimeo.com/user169707483/review/906636456/1588ba79af",
  "T000312-V5-0053AVI_es": "https://vimeo.com/user169707483/review/906636500/ad7243dcdc",
  "T000312-V6-0053AVI_es": "https://vimeo.com/user169707483/review/906636531/0221d490bb",
  "T000312-V7-0053AVI_es": "https://vimeo.com/user169707483/review/906636546/8e7937f289",
  "T000312-V8-0053AVI_es": "https://vimeo.com/user169707483/review/906636575/45cdbfd0fe",
  "T000315-V1-0053AVI_es": "https://vimeo.com/user169707483/review/906636714/4627b6170c",
  "T000315-V2-0053AVI_es": "https://vimeo.com/user169707483/review/906636738/60eec1b3a4",
  "T000315-V3-0053AVI_es": "https://vimeo.com/user169707483/review/906636793/bdb3a68a0e",
  "T000315-V4-0053AVI_es": "https://vimeo.com/user169707483/review/906636837/e45dac437d",
  "T000315-V5-0053AVI_es": "https://vimeo.com/user169707483/review/906636880/87dbe74d58",
  "T000314-V1-0053AVI_es": "https://vimeo.com/user169707483/review/906636604/2314dd0c01",
  "T000314-V2-0053AVI_es": "https://vimeo.com/user169707483/review/906636622/1012128c35",
  "T000314-V3-0053AVI_es": "https://vimeo.com/user169707483/review/906636642/5aec872cbb",
  "T000314-V4-0053AVI_es": "https://vimeo.com/user169707483/review/906636673/a47fb20fb7",
  "T000314-V5-0053AVI_es": "https://vimeo.com/user169707483/review/906636688/b6d4c15992",
  "T000304-V1-0066DMO_es": "https://vimeo.com/user169707483/review/906659721/e8c5e593fb",
  "T000304-V2-0066DMO_es": "https://vimeo.com/user169707483/review/906659791/be1db2230e",
  "T000304-V3-0066DMO_es": "https://vimeo.com/user169707483/review/906659911/aa1ca1416c",
  "T000097-V0-0119MGO_es": "https://vimeo.com/user169707483/review/908608998/086bd41892",
  "T000097-V1-0119MGO_es": "https://vimeo.com/user169707483/review/908608977/1410c172b4",
  "T000097-V2-0119MGO_es": "https://vimeo.com/user169707483/review/908608948/ddeca4d4e3",
  "T000097-V3-0119MGO_es": "https://vimeo.com/user169707483/review/908608923/500d13ef35",
  "T000097-V4-0119MGO_es": "https://vimeo.com/user169707483/review/908609037/17a5a20885"
}



 







const Correction_Bill_31_01_2024_C3_Duplicates_Lists = ({ custom, billOption }) => {

  const { formatNumbersToPrint } = useContext(FuncContext)

  return (
    <div className='w-[90vw] flex flex-col items-center justify-center pb-[120px]'>
      {(!custom) &&
        <div className='w-full pt-28 pb-20 max-w-[1500px] min-[1900px]:max-w-[1820px] flex justify-between'>
          <p className='text-3xl'>Correcciones en la facturación de minutos del módulo "Curso 3"</p>
          <p className='text-xl opacity-50'>31/01/2024</p>
        </div>
      }

      <div className={`w-full flex justify-center whitespace-nowrap ${(custom)? 'flex-wrap space-y-20' : 'space-x-3'}`}>
        {static_data?.map((bill, key) => {
          const price = parseFloat(bill?.price?.replaceAll('.', '')?.replaceAll(',', '.'))
          const ivaPrice = price * 0.21
          const totalPrice = price + ivaPrice

          return <div
            key={'bill-' + key}
            className={`
              ${(custom)? 'w-full' : (key === 0 || key === 1)? 'max-w-[650px] min-w-[500px] min-[1900px]:min-w-[650px]' : 'w-full max-w-[520px] min-w-[300px] min-[1900px]:min-w-[520px]'}
            `}
          >
            <div className='px-2 pb-3'>
              <p className='opacity-40 font-bold'>Factura {(key === 0)? 'inicial' : (key === 1)? 'con duplicados' : 'de corrección'}</p>

              <a href={bill?.link} target='_blank' className={`flex hover:text-blue-700 hover:underline ${(custom)? 'w-full space-x-1 text-2xl' : 'flex-wrap space-y-1 min-[1900px]:space-x-1 min-[1900px]:space-y-0 min-[1900px]:flex-nowrap text-xl'}`}>
                <p>{bill?.number} | {bill?.description}</p>
                <p className='w-full'>{bill?.date}</p>
              </a>

              <div className='text-gray-600 leading-5'>
                <div className='flex justify-between space-x-2 font-bold'>
                  <p>{(key === 2)? 'Abono /' : ''} Base imponible:</p>
                  <p>{bill?.price} €</p>
                </div>

                <div className='flex justify-between space-x-2 opacity-50'>
                  <p>IVA (21%):</p>
                  <p>{formatNumbersToPrint(ivaPrice, 2)} €</p>
                </div>

                <div className='flex justify-between space-x-2 opacity-50'>
                  <p>Importe total:</p>
                  <p>{formatNumbersToPrint(totalPrice, 2)} €</p>
                </div>
              </div>
            </div>


            <div className='text-sm border-y border-gray-300 rounded overflow-hidden'>
              <div 
                className={`flex justify-between font-bold 
                  ${(key === 2)? 'bg-green-400 bg-opacity-70' : (key === 1)? 'bg-red-400 bg-opacity-70' : 'bg-gray-400 bg-opacity-70'}
                `}
              >
                {(key !== 2)? <>
                  <p className='pl-[6px] pr-2 py-[2px] w-full'>Vídeos facturados</p>
                  <p className='px-2 py-[2px] w-[140px] text-right'>Minutaje</p>
                  <p className='px-2 py-[2px] w-[140px] text-right'>Minutaje Final</p>
                  <p className='px-2 py-[2px] w-[170px] text-right'>Precio/m</p>
                  <p className='px-2 py-[2px] w-[140px] text-right'>Precio</p>
                </> : <>
                  <p className='pl-[6px] pr-2 py-[2px] w-full'>Vídeos</p>
                  <p className='px-2 py-[2px] w-[140px] text-right'>Abono</p>
                </>}
              </div>

        
              {bill?.data?.map((sections, second_key) => {                
                return sections?.map((section, third_key) => {
                  const nextSection = (sections[parseInt(third_key) + 1])? sections[parseInt(third_key) + 1] : null
                  const showSecondaryTitle = (second_key && !third_key)? true : false
                  const isSecondary = (second_key)? true : false

                  const isOrange = ((key === 0 || key === 1) && !second_key && third_key === 1)? true : false

                  return <div
                    key={'section-' + key + '-' + second_key + '-' + third_key}
                    className={`border-x border-gray-300 overflow-hidden ${(showSecondaryTitle)? 'mt-4 border-t rounded-t' : (!nextSection)? 'border-b rounded-b' : ''}`}
                  >
                    {(showSecondaryTitle) && 
                      <p className="px-2 py-2 font-bold bg-gray-400 bg-opacity-60">VÍDEOS NO AFECTADOS</p>
                    }

                    {(section?.cat_name) && 
                      <div 
                        className={`px-2 py-2 flex justify-between font-bold 
                          ${
                            (isOrange)? 'bg-orange-400 bg-opacity-40' 
                            : (!isSecondary && key === 2)? 'bg-green-400 bg-opacity-40' 
                            : (!isSecondary && key === 1)? 'bg-red-400 bg-opacity-40' 
                            : 'bg-gray-400 bg-opacity-[0.35]'
                          }
                        `}
                      >
                        <p className='opacity-90'>{section?.cat_name}</p>
                        {(section?.anotation) && <p className='opacity-40'>{section?.anotation}</p>}
                      </div>
                    }

                    <div
                      className={`px-2 py-1 font-bold 
                        ${
                          (isOrange)? 'bg-orange-400 bg-opacity-20'
                          : (!isSecondary && key === 2)? 'bg-green-400 bg-opacity-20' 
                          : (!isSecondary && key === 1)? 'bg-red-400 bg-opacity-20' 
                          : 'bg-gray-400 bg-opacity-25' 
                        }
                      `}
                    >
                      <p className='opacity-90'>{section?.professor}</p>
                    </div>
                    
                    {section?.videos?.map((video, fouth_key) => {
                      return <div 
                        key={'video-' + key + '-' + second_key + '-' + third_key + '-' + fouth_key}
                        className={`flex justify-between py-[2px] pl-1 
                          ${
                            (isOrange)? 'bg-orange-50 hover:bg-orange-100'
                            : (!isSecondary && key === 2)? 'bg-green-50 hover:bg-green-100' 
                            : (!isSecondary && key === 1)? 'bg-red-50 hover:bg-red-100' 
                            : 'bg-gray-50 hover:bg-blue-100' 
                          }
                        `}
                      >
                        <div className='flex items-center'>
                          <RowButtons
                            row={{ name: video[0] }}
                            factura10Bill={null}
                            billOption={(billOption)? { ...billOption, id: bill?.id } : null}
                          />
                        </div>

                        <p className='pl-[6px] pr-2 py-[2px] w-full'><a href={vimeo_urls[video[0]]} target='_blank' className='hover:text-blue-700'>{video[0]}</a></p>
                        <p className='px-2 py-[2px] w-[140px] text-right'>+{video[1]}</p>

                        {(key !== 2) && <>
                          <p className='px-2 py-[2px] w-[140px] text-right'>{video[2]}m</p>
                          <p className='px-2 py-[2px] w-[170px] text-right'>{video[3]} €/m</p>
                          <p className='px-2 py-[2px] w-[140px] text-right'>{video[4]} €</p>
                        </>}
                      </div>
                    })}
                  </div>
                })
              })}
            </div>


          </div>
        })}
      </div>


    </div>
  )
}

export default Correction_Bill_31_01_2024_C3_Duplicates_Lists
