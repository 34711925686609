import { useState, useEffect, useContext } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";









export default function SimpleOrderList({droppableId, objects, setObjects, callOnDragEnd, direction, small, disabled, color}) {




  const draggIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>

  
  const rearangeArr = (arr, source, destination) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);
    return arrCopy;
  };


  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !objects?.length) { return; }
    var newOrderTMP = rearangeArr(objects, source, destination);
    setObjects(newOrderTMP);

    var newOrderItem = {}
    for (var i in newOrderTMP) {
      var newOrderObj = newOrderTMP[i]
      newOrderItem[newOrderObj?.id] = i;
    }

    if (callOnDragEnd) {
      callOnDragEnd((Object.keys(newOrderItem).length)? newOrderItem : null)
    }
  };




  return (
    <div className={`w-full ${(disabled)? 'opacity-50 pointer-events-none' : ''}`}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId} direction={direction}>
          {(provided, snapshot) => (
            <div 
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`
                ${(direction === 'horizontal')? 'overflow-x-scroll flex' : ''}
                smallScrollBar w-full overflow-hidden whitespace-nowrap bg-gray-50 border border-gray-400 border-opacity-60 rounded
              `}
            >
              {objects?.map((objectTMP, key) => {
                return <Draggable key={droppableId+objectTMP?.id} draggableId={droppableId+objectTMP.id} index={key}>
                  {(provided, snapshot) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: "none",
                        margin: `0 0 0px 0`,
              
                        ...provided.draggableProps.style
                      }}
                      className={`
                        ${(direction === 'horizontal')? ('border-b' + ((key+1) !== objects?.length || objects?.length < 7)? 'border-r' : '') : (((key+1) !== objects?.length)? 'border-b' : '')} 
                        ${(small)? 'text-sm' : ''} 
                        ${(color === 'white')? 'bg-gray-100 hover:bg-gray-200 border-gray-400 border-opacity-60' : 'bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60'}
                        flex items-center space-x-2 text-gray-500 hover:text-gray-700 pr-3 p-1
                      `}
                    >
                      {draggIcon}
                      <p>{(objectTMP?.name)? objectTMP?.name : (objectTMP?.title)? objectTMP?.title : (objectTMP?.nom)? objectTMP?.nom : 'NONE'}</p>
                    </div>
                  )}
                </Draggable>
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}