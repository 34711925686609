import { useState, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import StateChanger from './StateChanger';

import UpdateUserState from './../graphql/mutations/UpdateUserState'

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { FuncContext } from '../contexts/FuncContext';










export default function MotionListProfessorItemLanguage({
  professor,
  setProfessor,
  professorPositions,
  setPositions,
  position,
  mode,

  stateBox,
  setStateBox,
  motionStateBox,
  setMotionStateBox,

  editing,
  setEditing,
  loading,
  setLoading,
}) {
  



  const { currentUser, currentCustomer, languageList, getJsonString } = useContext(AuthContext)
  const { getRevNumberOfWordsFunc } = useContext(FuncContext)
  
  


  
  const language = getLanguageFunc(position?.language);
  const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null

  /* State vars */  
  const [state, setState] = useState((parseInt(position?.state))? parseInt(position?.state) : 1);
  const [loadingState, setLoadingState] = useState(null);
  const [motionState, setMotionState] = useState((parseInt(position?.motion_state))? parseInt(position?.motion_state) : 1);
  const [loadingMotionState, setLoadingMotionState] = useState(null);
  const [revisionState, setRevisionState] = useState((parseInt(position?.revision_state))? parseInt(position?.revision_state) : 1);
  const [loadingRevisionState, setLoadingRevisionState] = useState(false);
  const [tradState, setTradState] = useState((professor && `trad_done` in professor)? (professor[`trad_done`]? true : false) : false);
  const [loadingTradState, setLoadingTradState] = useState(false);

  



  const [updateUserState] = useMutation(UpdateUserState)




  /* Update professor states */
  useEffect( () => { 
    var stateTMP = 1;
    var motionStateTMP = 1;

    
    
    if (position) {
      const deffaultPositionsTMP = (professor?.positions)? getJsonString(professor?.positions, true) : null;
      if (deffaultPositionsTMP && deffaultPositionsTMP[position?.language]) {
        stateTMP = (parseInt(deffaultPositionsTMP[position?.language]?.state))? parseInt(deffaultPositionsTMP[position?.language]?.state) : 1;
        motionStateTMP = (parseInt(deffaultPositionsTMP[position?.language]?.motion_state))? parseInt(deffaultPositionsTMP[position?.language]?.motion_state) : 1;
      }
    }

    if (state < stateBox || motionState < motionStateBox) {
      setStateBox(state)
      setMotionStateBox(motionState)
    }



    setState(stateTMP);
    setMotionState(motionStateTMP);
  }, [position])




  

  /* SAVE STATE CHANGE */
  async function saveProfessorStateEdit(stateNum, motionStateNum, motion) {
    if (!loading && professor?.id) {
      setLoading(true);
      if (motion) { setLoadingMotionState(motionStateNum); } else { setLoadingState(stateNum); }



      /* Correct states */
      var both = false;

      /* Remove done from state */
      if ((motionStateNum === 2 || motionStateNum === 3 || motionStateNum === 4) && stateNum === 3 && motion) { both = true; stateNum = 1; }
      /* Detect done */
      if (stateNum === 3 || motionStateNum === 5) { both = true; }
      /* Remove done from motion state */
      if ((stateNum === 2 || stateNum === 1) && motionStateNum === 5) { motionStateNum = 1; both = true; }
      /* Clicked same motion state */
      if (motionStateNum === motionState && motion) { motionStateNum = 1; }
      /* Remove correction from state */
      if (motionStateNum > 1 && motionStateNum < 5 && motion) { both = true; stateNum = 1; }



      setState(stateNum)
      setMotionState(motionStateNum)



      setTimeout( async () => {
        const getIn = (!(stateNum > 2 || motionStateNum > 3) && (parseInt(position?.state) !== parseInt(stateNum) || parseInt(position?.motion_state) !== parseInt(motionStateNum)))? true : false;
        const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
        var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};


        
        if (getIn) {
          /* Mount input */
          if (!motion || both) { 
            professorPositionsTMP[position?.language].state = (parseInt(stateNum))? parseInt(stateNum) : 1 
            professorPositionsTMP[position?.language].state_change_label = change_label 
          }

          if (motion || both) { 
            professorPositionsTMP[position?.language].motion_state = (parseInt(motionStateNum))? parseInt(motionStateNum) : 1 
            professorPositionsTMP[position?.language].motion_state_change_label = change_label
          }

          var input = {
            own_user_token: currentUser?.token, 
            own_customer_token: currentCustomer?.token,

            id: parseInt(professor?.id),
            positions: (professorPositionsTMP)? JSON.stringify(professorPositionsTMP) : null,
          };

          var professorTMP = await updateUserState({variables: input}); 
          if (professorTMP?.data?.createOrUpdateUser) { setProfessor({...professor, ...professorTMP?.data?.createOrUpdateUser}) }
        }



        if (stateNum < stateBox || motionStateNum < motionStateBox) {
          setStateBox(state)
          setMotionStateBox(motionState)
        }


        
        setLoading(false);
        if (motion) { setLoadingMotionState(null); } else { setLoadingState(null); }
      }, 400)
    }
  }


  async function saveProfessorRevisionStateEdit(val) {
    if (!loading && professor?.id) {
      setLoadingRevisionState(true);

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      professorPositionsTMP[position?.language].revision_state = (parseInt(val))? parseInt(val) : 1 
      professorPositionsTMP[position?.language].revision_state_change_label = change_label 


      if (professorPositionsTMP[position?.language]?.text) {
        const newNumberOfWordsTMP = getRevNumberOfWordsFunc((professorPositionsTMP[position?.language]?.text)? professorPositionsTMP[position?.language]?.text : null)
        if (newNumberOfWordsTMP) {
          const numWords = (parseInt(professorPositionsTMP[position?.language]?.manual_number_of_words))? parseInt(professorPositionsTMP[position?.language]?.manual_number_of_words) : 0;
          const numWordsEnd = (parseInt(val) === 2)? numWords + newNumberOfWordsTMP : numWords - newNumberOfWordsTMP;
          if (numWordsEnd > 0) { professorPositionsTMP[position?.language].manual_number_of_words = numWordsEnd; } else if (professorPositionsTMP[position?.language]?.manual_number_of_words) { delete professorPositionsTMP[position?.language].manual_number_of_words; }
        }
      }

      console.log(' ')
      console.log('professorPositionsTMP')
      console.log(professorPositionsTMP)


      var input = {
        own_user_token: currentUser?.token, 
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor.id), 
        positions: JSON.stringify(professorPositionsTMP),
      };



      var professorTMP = await updateUserState({variables: input}); 
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setProfessor(professorTMP) }

      setTimeout(() => { 
        setLoadingRevisionState(false);
      }, "100")
    }
  }
  

  async function saveProfessorTradStateEdit(val) {
    if (!loading && professor?.id) {
      setLoadingTradState(true);

      var input = {
        own_user_token: currentUser?.token, 
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor.id), 
        trad_done: (val)? true : false,
        trad_done_change_label: (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null
      };

      var professorTMP = await updateUserState({variables: input}); 
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setProfessor(professorTMP) }

      setLoadingTradState(false);
    }
  }


  function getLanguageFunc(langKey) {
    return (langKey && languageList?.filter((el) => (el?.name === langKey) || (el?.abbreviated_name === langKey) || (el?.file_key === langKey))?.length)? languageList?.filter((el) => (el?.name === langKey) || (el?.abbreviated_name === langKey) || (el?.file_key === langKey))[0] : languageList[0];
  }








  


  return <div className={`w-full duration-300 border-opacity-40 px-3 pt-3`} >
    <div className={`${(mode === 'review' || mode === 'translation')? 'mb-1' : ''}`}>

      




      {(!editing)?
        <div className={`w-full overflow-hidden`}>
          <div className='space-y-1 pb-2'>
            <div className={`bg-gray-500 bg-opacity-[0.15] rounded-md`}>
              <p className='text-xs text-gray-400 px-2 py-1'>Cargo en {language?.name}</p>
              <Tooltip disableInteractive title={'Copiar'} placement="right" arrow>
                <div>
                  <CopyToClipboard text={(position?.text)? position?.text : ''}>
                    <div 
                      onClick={()=> (motionState === 1) && saveProfessorStateEdit(state, 2, true) }
                      className={`w-full text-base px-3 py-2 rounded bg-gray-400 duration-200 transition-colors
                        ${(position?.text)? 'bg-opacity-20 hover:bg-opacity-40 active:bg-opacity-50 cursor-pointer' 
                          : 'opacity-30 bg-opacity-30 cursor-not-allowed'
                        }
                      `}
                      dangerouslySetInnerHTML={{__html: 
                        (position?.text)? position?.text.replace(/\n/g, "<br />") : `No se ha encontrado el cargo en ${language?.name}.`
                      }}
                    >
                    </div>
                  </CopyToClipboard>
                </div>    
              </Tooltip>
            </div>

            <div className='w-full flex justify-end px-1'>
              <StateChanger
                type_name={'user'}
                object={professor}

                saveStateEdit={saveProfessorStateEdit}
                saveRevisionStateEdit={saveProfessorRevisionStateEdit}
                saveTradStateEdit={saveProfessorTradStateEdit}

                state={state}
                stateChangeLabel={(position?.state_change_label)? position?.state_change_label : null}
                loadingState={loadingState}
                motionState={motionState}
                motionStateChangeLabel={(position?.motion_state_change_label)? position?.motion_state_change_label : null}
                loadingMotionState={loadingMotionState}

                revisionState={revisionState}
                revisionStateChangeLabel={(position?.revision_state_change_label)? position?.revision_state_change_label : null}
                loadingRevisionState={loadingRevisionState}

                tradState={tradState}
                tradStateChangeLabel={(position?.trad_state_change_label)? position?.trad_state_change_label : null}
                loadingTradState={loadingTradState}

                small={false}
                disabled={false}
                showRevisionState={true}
                language={position?.language}
              />
            </div>
          </div>
        </div>
      :
        <div id={'editing-box-' + professor?.id + '-' + position?.language} className={`w-full`}>
          <TextField 
            value={(position?.text)? position?.text : ''} 
            onChange={event => {
              var professorPositionsTMP = (professorPositions)? {...professorPositions} : {};
              if (!(position?.language in professorPositionsTMP)) { professorPositionsTMP[position?.language] = {}}
              professorPositionsTMP[position?.language].text = (event.target.value)? event.target.value : null;
              professorPositionsTMP[position?.language].text_change_label = change_label;
              setPositions(professorPositionsTMP)
            }} 
            id="position_esp" 
            label={`Cargo en ${language?.name}`}
            variant="outlined"
            className='w-full mui-bg-gray-200 mui-bg-opacity-50'
            size="small"
            autoComplete='off'
            multiline
            maxRows={5}
            minRows={2}
          />
        </div>
      }
    </div>

    




  </div>
}