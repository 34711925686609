import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useLocation, useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import axios from 'axios'

import GetProfessorByCode from '../graphql/queries/GetProfessorByCode'
import GetCustomerVideoNames from '../graphql/queries/GetCustomerVideoNames'
import GetVideosCustomer from '../graphql/queries/GetVideosCustomer'
import GetVideosCustomerSettings from '../graphql/queries/GetVideosCustomerSettings'
import GetVideosCustomerSettingsOnlyOriginals from '../graphql/queries/GetVideosCustomerSettingsOnlyOriginals'
import GetCustomerModules from './../graphql/queries/GetCustomerModules'
import GetStatesByCustomer from '../graphql/queries/GetStatesByCustomer'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'
import GetSettingRelationsByVideo from './../graphql/queries/GetSettingRelationsByVideo'
import GetClicksoundUserNames from '../graphql/queries/GetClicksoundUserNames';

import VideoHomeBaseList from '../components/VideoHomeBaseList';
import VideoHomeEditMode from '../components/VideoHomeEditMode';
import VideoHomeSettingsList from '../components/VideoHomeSettingsList';
import SimpleToggle from '../components/SVGs/SimpleToggle';
import VideoFolderManager from '../components/VideoFolderManager';
import VideoFilters from '../components/VideoFilters';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import ToggleButton from '@mui/material/ToggleButton';
import CircularProgress from '@mui/material/CircularProgress';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Collapse from '@mui/material/Collapse';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GetAllVideoFolders from '../graphql/queries/GetAllVideoFolders';
import LoadAnimation from '../components/LoadAnimation';









const Videos = () => {




  const { id, name, module_name, professorCode } = useParams()
  const params = useParams()
  const { currentUser, currentCustomer, showNavTop, getJsonString } = useContext(AuthContext)
  const { setCurrentNavActive, startTutorial, step } = useContext(LayoutContext)
  const { anyCheckbox, navigateOrOpenFunc, inBetweenDatesFilterFunc, checkInBetweenDatesFunc, formatNumbersToPrint, getChyronMotionStateFunc } = useContext(FuncContext)
  const oneDay = 24 * 60 * 60 * 1000;
  const location = useLocation();



  
  const startDateRemoved = (localStorage.getItem(currentCustomer?.token + '-start-date-removed'))? new Date(localStorage.getItem(currentCustomer?.token + '-start-date-removed')) > new Date() : false
  const localModuleFilter = localStorage.getItem(currentCustomer?.token + '-video-module-filter')
  const localSettingsGroupVisibility = (localStorage.getItem(currentCustomer?.token + '-groups-visibility'))? JSON.parse( localStorage.getItem(currentCustomer?.token + '-groups-visibility') ) : null
  
  if (localStorage.getItem(currentCustomer?.token + '-legend-visibility')?.includes('{') || localStorage.getItem(currentCustomer?.token + '-legend-visibility')?.includes('[')) { 
    localStorage.removeItem(currentCustomer?.token + '-legend-visibility')
  }

  const localSettingsLegendVisibility = (localStorage.getItem(currentCustomer?.token + '-legend-visibility'))? localStorage.getItem(currentCustomer?.token + '-legend-visibility') : null


  const localVideoOrderFilter = (localStorage.getItem(currentCustomer?.token + '-video-order-filter'))? localStorage.getItem(currentCustomer?.token + '-video-order-filter') : null
  const localVideoListFormat = (localStorage.getItem(currentCustomer?.token + '-video-list-format'))? getJsonString( localStorage.getItem(currentCustomer?.token + '-video-list-format') ) : true
  const localVideoStateFilter = (localStorage.getItem(currentCustomer?.token + '-video-state-filter'))? localStorage.getItem(currentCustomer?.token + '-video-state-filter') + '' : null


  const localVideoOriginals = (parseInt(localStorage.getItem(currentCustomer?.token + '-video-originals')) === 1)? true : (parseInt(localStorage.getItem(currentCustomer?.token + '-video-originals')) === 0)? false : true
  const localVideoLocalizations = (parseInt(localStorage.getItem(currentCustomer?.token + '-video-localizations')) === 1)? true : (parseInt(localStorage.getItem(currentCustomer?.token + '-video-localizations')) === 0)? false : true
  const localVideoPriority = (parseInt(localStorage.getItem(currentCustomer?.token + '-video-prioritary-first')) === 1)? true : (parseInt(localStorage.getItem(currentCustomer?.token + '-video-prioritary-first')) === 0)? false : true





  /* Filter vars */
  const [search, setSearch] = useState('');
  const [moduleFilter, setModuleFilter] = useState(((!currentUser?.professor)? ((parseInt(localModuleFilter) || parseInt(localModuleFilter) === 0)? parseInt(localModuleFilter) : 'all') : 'all'));
  const [genderFilter, setGendetFilter] = useState(null);

  const [userTagsFilter, setUserTagsFilter] = useState(null);

  const [professorFilter, setProfessorFilter] = useState(['null']);
  const [languageFilter, setLanguageFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState((localVideoStateFilter)? localVideoStateFilter?.split(',') : null);
  const [orderFilter, setOrderFilter] = useState((localVideoOrderFilter)? localVideoOrderFilter : 'ProdState DESC');
  const [startFilterDate, setStartFilterDate] = useState((currentCustomer?.setting_deffault_date_filter && ((localVideoOrderFilter?.includes('EndDate ') || !localVideoOrderFilter) && !startDateRemoved))? new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay()===0 ? -6:1))) : null );
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [hasPrioritary, setHasPrioritary] = useState(false);

  const [prodStateObj, setProdStateObj] = useState(null);


  useEffect(() => {    
    console.log(' ')
    console.log('stateFilter')
    console.log(stateFilter)
  }, [stateFilter])
  

  
  

  var defShowFilters = []
  if (localVideoOriginals) { defShowFilters?.push('originals') }
  if (localVideoLocalizations) { defShowFilters?.push('localizations') }
  if (localVideoPriority) { defShowFilters?.push('prioritary') }

  const [showFilters, setShowFilters] = useState((defShowFilters?.length)? defShowFilters : null);

  




  const [orderGroupsPanelOpen, setOrderGroupsPanelOpen] = useState(false);
  const [legendVisibility, setLegendVisibility] = useState((localSettingsLegendVisibility)? localSettingsLegendVisibility : null);
  const [groupsVisibility, setGroupsVisibility] = useState((localSettingsGroupVisibility)? localSettingsGroupVisibility : null);

  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  
  
  
  /* List vars */
  const [videos, setVideos] = useState(null);
  const [videoFolders, setVideoFolders] = useState(null);
  const [pathProfessor, setPathProfessor] = useState(null);
  const [showMore, setShowMore] = useState(30);
  const [listFormat, setListFormat] = useState(localVideoListFormat);
  const [anyChecked, setAnyChecked] = useState(null);

  /* Edit mode vars */
  const [editMode, setEditMode] = useState((!currentUser?.professor)? ((parseInt(localStorage.getItem(currentCustomer?.token+'-video-edit-mode')))? true : false) : false);
  const [settingsMode, setSettingsMode] = useState((!currentUser?.professor)? ((parseInt(localStorage.getItem(currentCustomer?.token+'-video-settings-mode')))? true : false) : false);
  const [showFullEditMode, setShowFullEditMode] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [category, setCategory] = useState('null');

  /* Option vars */
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOption, setCategoryOption] = useState({ label: 'Sin modificar', token: null, id: 'null' });

  /* Folders */
  const [pathFolders, setPathFolders] = useState(null);
  const [createFolder, setCreateFolder] = useState(false);
  const [addedVideosToFolder, setAddedVideosToFolder] = useState(null);
  

  /* Loading vars */
  const [loading, setLoading] = useState(true);
  const [loadingEditMode, setLoadingEditMode] = useState(false);
  const [loadingSettingsMode, setLoadingSettingsMode] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);




  /* Icon vars */
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const multipleVideoUploaderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M160 976q-33 0-56.5-23.5T80 896V496q0-33 23.5-56.5T160 416h640q33 0 56.5 23.5T880 496v400q0 33-23.5 56.5T800 976H160Zm271-141 184-122q9-6 9-17t-9-17L431 557q-10-7-20.5-1.5T400 573v246q0 12 10.5 17.5T431 835ZM200 376q-17 0-28.5-11.5T160 336q0-17 11.5-28.5T200 296h560q17 0 28.5 11.5T800 336q0 17-11.5 28.5T760 376H200Zm120-120q-17 0-28.5-11.5T280 216q0-17 11.5-28.5T320 176h320q17 0 28.5 11.5T680 216q0 17-11.5 28.5T640 256H320Z"/></svg>;
  const chartIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M320-480q-17 0-28.5 11.5T280-440v120q0 17 11.5 28.5T320-280q17 0 28.5-11.5T360-320v-120q0-17-11.5-28.5T320-480Zm320-200q-17 0-28.5 11.5T600-640v320q0 17 11.5 28.5T640-280q17 0 28.5-11.5T680-320v-320q0-17-11.5-28.5T640-680ZM480-400q-17 0-28.5 11.5T440-360v40q0 17 11.5 28.5T480-280q17 0 28.5-11.5T520-320v-40q0-17-11.5-28.5T480-400ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Zm280 280q17 0 28.5-11.5T520-520q0-17-11.5-28.5T480-560q-17 0-28.5 11.5T440-520q0 17 11.5 28.5T480-480Z"/></svg>
  const usersIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M40-272q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v32q0 33-23.5 56.5T600-160H120q-33 0-56.5-23.5T40-240v-32Zm698 112q11-18 16.5-38.5T760-240v-40q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v40q0 33-23.5 56.5T840-160H738ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113Z"/></svg>
  const addFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm400-240v40q0 17 11.5 28.5T600-320q17 0 28.5-11.5T640-360v-40h40q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480h-40v-40q0-17-11.5-28.5T600-560q-17 0-28.5 11.5T560-520v40h-40q-17 0-28.5 11.5T480-440q0 17 11.5 28.5T520-400h40Z"/></svg>
  const workflowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M528-432.12v-72.13Q528-534 549.15-555T600-576h24v-72h-24q-29.7 0-50.85-21.19Q528-690.37 528-720.12v-72.13Q528-822 549.15-843T600-864h120q29.7 0 50.85 21.19Q792-821.63 792-791.88v72.13Q792-690 770.85-669T720-648h-24v72h24q29.7 0 50.85 21.19Q792-533.63 792-503.88v72.13Q792-402 770.85-381T720-360H600q-29.7 0-50.85-21.19Q528-402.37 528-432.12Zm-336 288v-72.13Q192-246 213.15-267T264-288h24v-72h-24q-29.7 0-50.85-21.19Q192-402.37 192-432.12v-72.13Q192-534 213.15-555T264-576h24v-72h-24q-29.7 0-50.85-21.19Q192-690.37 192-720.12v-72.13Q192-822 213.15-843T264-864h120q29.7 0 50.85 21.19Q456-821.63 456-791.88v72.13Q456-690 434.85-669T384-648h-24v72h24q29.7 0 50.85 21.19Q456-533.63 456-503.88v72.13Q456-402 434.85-381T384-360h-24v72h24q29.7 0 50.85 21.19Q456-245.63 456-215.88v72.13Q456-114 434.85-93T384-72H264q-29.7 0-50.85-21.19Q192-114.37 192-144.12Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const editOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M180.182-144Q165-144 154.5-154.297q-10.5-10.298-10.5-25.52v-86.856Q144-281 149-294q5-13 16-24l477-477q11-11 23.836-16 12.836-5 27-5T720-811q13 5 24 16l51 51q11 11 16 24t5 26.542q0 14.444-5.022 27.534Q805.957-652.833 795-642L318-165q-11 11-23.95 16-12.949 5-27.239 5h-86.629ZM693-642l51-51-51-51-51 51 51 51Z"/></svg>
  const editOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M606-453 454-607l188-188q10.685-10.957 23.596-15.978Q678.507-816 692.753-816 707-816 720-811t24 16l51 51q11 11 16 24t5 26.542q0 14.444-5.022 27.534Q805.957-652.833 795-642L606-453Zm136.809 337.812L505-352 318-165q-11 11-23.95 16-12.949 5-27.239 5h-86.629Q165-144 154.5-154.365 144-164.73 144-180.053v-86.526Q144-281 149-294t16-24l187-187-236.854-237.851Q104-754 104.462-768.182 104.923-782.364 116-793q10.667-11 25.333-11 14.667 0 25.745 11.078l626.844 626.844Q805-155 804.538-140.333 804.077-125.667 793-115q-10.636 11-24.818 11-14.182 0-25.373-11.188ZM693-642l51-51-51-51-51 51 51 51Z"/></svg>

  const listOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M864-480ZM130-624q-15 0-24.5-9.775T96-658v-76q0-14.45 9.5-24.225Q115-768 130-768h100q14.45 0 24.225 9.775Q264-748.45 264-734v76q0 14.45-9.775 24.225Q244.45-624 230-624H130Zm239 0q-14.025 0-23.513-9.775Q336-643.55 336-658v-76q0-14.45 9.487-24.225Q354.975-768 369-768h461q14.45 0 24.225 9.775Q864-748.45 864-734v76q0 14.45-9.775 24.225Q844.45-624 830-624H369Zm0 216q-14.025 0-23.513-9.775Q336-427.55 336-442v-76q0-14.45 9.487-24.225Q354.975-552 369-552h461q14.45 0 24.225 9.775Q864-532.45 864-518v76q0 14.45-9.775 24.225Q844.45-408 830-408H369Zm0 216q-14.025 0-23.513-9.775Q336-211.55 336-226v-76q0-14.45 9.487-24.225Q354.975-336 369-336h461q14.45 0 24.225 9.775Q864-316.45 864-302v76q0 14.45-9.775 24.225Q844.45-192 830-192H369Zm-239 0q-15 0-24.5-9.775T96-226v-76q0-14.45 9.5-24.225Q115-336 130-336h100q14.45 0 24.225 9.775Q264-316.45 264-302v76q0 14.45-9.775 24.225Q244.45-192 230-192H130Zm0-216q-15 0-24.5-9.775T96-442v-76q0-14.45 9.5-24.225Q115-552 130-552h100q14.45 0 24.225 9.775Q264-532.45 264-518v76q0 14.45-9.775 24.225Q244.45-408 230-408H130Z"/></svg>
  const listOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M215.736-528Q186-528 165-549.5 144-571 144-600v-144q0-29.7 21.176-50.85Q186.352-816 216.088-816h144.176Q390-816 411-794.85q21 21.15 21 50.85v144q0 29-21.176 50.5T359.912-528H215.736Zm0 384Q186-144 165-165.176q-21-21.176-21-50.912v-144.176Q144-390 165.176-411q21.176-21 50.912-21h144.176Q390-432 411-410.824q21 21.176 21 50.912v144.176Q432-186 410.824-165q-21.176 21-50.912 21H215.736ZM600-528q-29 0-50.5-21.5T528-600v-144q0-29.7 21.5-50.85Q571-816 600-816h144q29.7 0 50.85 21.15Q816-773.7 816-744v144q0 29-21.15 50.5T744-528H600Zm0 384q-29 0-50.5-21.176T528-216.088v-144.176Q528-390 549.5-411q21.5-21 50.5-21h144q29.7 0 50.85 21.176Q816-389.648 816-359.912v144.176Q816-186 794.85-165 773.7-144 744-144H600Z"/></svg>


  



  

  const { data: dataPathProfessor, loading: loadingPathProfessor } = useQuery(GetProfessorByCode, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professor_code: (professorCode && professorCode?.split('-')?.length && /^\d+$/.test(professorCode?.split('-')[0]))? null : professorCode,
      id: (professorCode && professorCode?.split('-')?.length && /^\d+$/.test(professorCode?.split('-')[0]))? parseInt(professorCode?.split('-')[0]) : null,
    }
  })




  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })

  
  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })


  const [getVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery((settingsMode)? ((showFilters && showFilters?.includes('originals'))? GetVideosCustomerSettingsOnlyOriginals : GetVideosCustomerSettings) : GetVideosCustomer, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      name: (name)? name : null,
      professor_id: (currentUser?.professor)? currentUser?.id+'' : (pathProfessor?.id)? pathProfessor?.id+'' : (professorFilter?.length)? JSON.stringify(professorFilter) : null,
      tags: (userTagsFilter)? JSON.stringify(userTagsFilter) : null,
      module: (id || name || moduleFilter === 'all')? JSON.stringify(dataModules?.getCustomerModules?.filter((el) => !currentUser?.rol?.includes('user') || el?.visible)?.map((el) => el?.id)) : (parseInt(moduleFilter))? moduleFilter+'' : '0',
      folder: (id || name)? null : (selectedFolder?.id)? ''+selectedFolder?.id : 'null',
      production_state: (id || name || stateFilter === 'null' || (stateFilter?.length === 1 && stateFilter[0] === 'null'))? null : (stateFilter?.length)? JSON.stringify(stateFilter) : null,
      gender: (genderFilter && genderFilter !== 'null')? genderFilter : null,
      language: (languageFilter && languageFilter !== 'null')? languageFilter : null,
      localizations: (
        (showFilters)? (
          (showFilters?.includes('originals') && showFilters?.includes('localizations'))? null 
          : (showFilters?.includes('localizations') && !showFilters?.includes('originals'))? 2
          : null
        ) : null
      ),
      settings_group_visibility: (settingsMode && groupsVisibility && Object.keys(groupsVisibility)?.length)? JSON.stringify(groupsVisibility) : null,
      settings_legend_visibility: (settingsMode && legendVisibility)? legendVisibility : null,
    }
  })


  const [getVideoFolders, { data: dataVideoFolders, loading: loadingVideoFolders }] = useLazyQuery(GetAllVideoFolders, { 
    fetchPolicy: 'cache-and-network',
    variables: {
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
      module: (id || name || moduleFilter === 'all')? JSON.stringify(dataModules?.getCustomerModules?.filter((el) => !currentUser?.rol?.includes('user') || el?.visible)?.map((el) => el?.id)) : (parseInt(moduleFilter))? moduleFilter+'' : '0',
    }
  })



  const [getSettingRelations, { data: dataSettingRelations, loading: loadingSettingRelations }] = useLazyQuery(GetSettingRelationsByVideo)


  const [getClicksoundUserNames, { data: clicksoundUserNames, loading: loadingClicksoundUserNames }] = useLazyQuery(GetClicksoundUserNames, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
    }
  })


  const [getVideoNames, { data: dataVideoNames, loading: loadingVideoNames }] = useLazyQuery(GetCustomerVideoNames, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })


  const [getProfessors, { data: dataProfessors, loading: loadingProfessors }] = useLazyQuery(GetAllProfessorsByCustomer, { 
    fetchPolicy: 'cache-and-network',
    variables: {
      customer_token: currentCustomer?.token,
    }
  })






  useEffect(() => {
    setCurrentNavActive('videos')

    getModules();
    getStatesByCustomer();
    getClicksoundUserNames();

    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
      var categoriesSelect = [{ label: 'No facturable', token: null, id: null }];
      categoriesSelect.push({ label: 'Sin modificar', token: null, id: 'null' })

      if (response?.data && Object.keys(response?.data)?.length) {
        for (var key in response?.data) {
          var element = response?.data[key]
          categoriesSelect.push({ label: element.nom, token: element.codi, id: element.id, carpeta: element.carpeta })
        }
        setCategoryOptions(categoriesSelect);
      }
    })

    var listSearchInput = document.getElementById("search-input");
    if (listSearchInput) {
      listSearchInput.addEventListener("focus", focusTMPFunc);
      listSearchInput?.focus();  
    }

    return () => {
      listSearchInput.removeEventListener('focus', focusTMPFunc)
    };
  }, [])

  
  useEffect( () => { 
    var prodStateObjTMP = {}
    const dataStatesTMP = (dataStates?.getStatesByCustomer?.length)? [...dataStates?.getStatesByCustomer]?.sort((a, b) => a?.position - b?.position) : null

    if (dataStatesTMP?.length) {     
      for (var stateTMP of dataStatesTMP) {
        prodStateObjTMP[stateTMP?.id] = stateTMP;
      }
    }

    setProdStateObj((Object.keys(prodStateObjTMP)?.length)? prodStateObjTMP : null)
  }, [dataStates])


  useEffect( () => { 
    if (baseLoading) {     
      setTimeout(()=> {
        setBaseLoading(false)
      }, 3000)
    }
  }, [baseLoading])


  useEffect( () => {
    if (params && params?.module_name && videoFolders?.length && params?.folder_name_1) {
      var moduleFilterTMP = (moduleFilter)? moduleFilter : null;

      var folderName = { key: 'folder_name_1', url_name: params?.folder_name_1 };
      for (var folder_key of Object.keys(params)) {
        if (folder_key?.includes('folder_name_') && parseInt(folderName?.key?.replaceAll('folder_name_', '')) < parseInt(folder_key?.replaceAll('folder_name_', ''))) {
          folderName = { key: folder_key, url_name: params[folder_key] };
        }
      }

      for (var videoFolder of videoFolders) {
        if ((moduleFilterTMP === 'all' || (!moduleFilterTMP && !videoFolder?.module_id) || parseInt(videoFolder?.module_id) === parseInt(moduleFilterTMP)) && videoFolder?.url_name?.toLowerCase() === folderName?.url_name?.toLowerCase()) {
          setSelectedFolder(videoFolder)
          break;
        }
      }
    }
  }, [params, videoFolders, moduleFilter])


  useEffect( () => {
    if (dataPathProfessor?.professorByCode) {
      setPathProfessor(dataPathProfessor?.professorByCode);
    } else {
      setPathProfessor(null);
    }
  }, [dataPathProfessor])


  useEffect( () => {
    var videoFoldersTMP = (!(id || name) && dataVideoFolders?.getVideoFolders?.length)? dataVideoFolders?.getVideoFolders : null

    /* Search */
    if (videoFoldersTMP?.length && search && search.replaceAll(' ', '') !== '') {
      videoFoldersTMP = videoFoldersTMP?.filter((folder) => {
        var nameFound = folder?.name?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
        var professorsFound = folder?.search_professor_names?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());

        return (nameFound || professorsFound)
      })
    }

    if (professorFilter?.length && parseInt(professorFilter?.length[0])) {
      videoFoldersTMP = videoFoldersTMP?.filter((folder) => {
        const profeIDS = (folder?.search_professor_ids)? folder?.search_professor_ids?.split(',') : null;
        var includesProfessor = false;

        if (profeIDS?.length) {
          for (var profID of professorFilter) {
            if (profeIDS?.includes(profID)) {
              includesProfessor = true;
              break;
            }
          }
        }

        return (includesProfessor)
      })
    }

    setVideoFolders(videoFoldersTMP);
  }, [dataVideoFolders, search, professorFilter])
  

  useEffect( () => {
    /* setListFormat((createFolder)? true : localVideoListFormat) */
    setSettingsMode((createFolder)? false : ((!currentUser?.professor)? ((parseInt(localStorage.getItem(currentCustomer?.token+'-video-settings-mode')))? true : false) : false));
    setEditMode((createFolder)? false : ((!currentUser?.professor)? ((parseInt(localStorage.getItem(currentCustomer?.token+'-video-edit-mode')))? true : false) : false));
  }, [createFolder])
  
  
  useEffect( () => {
    if (!orderGroupsPanelOpen && currentCustomer && currentUser && dataModules) {
      getVideos();
      getVideoFolders();
    }
  }, [moduleFilter, stateFilter, languageFilter, genderFilter, userTagsFilter, settingsMode, dataModules, showFilters, orderGroupsPanelOpen])


  useEffect(() => {
    if (!orderGroupsPanelOpen) {
      callGetSettingRelationsFunc();
    }
  }, [videos, showMore, orderGroupsPanelOpen])


  useEffect(() => {
    if (currentUser && currentCustomer?.setting_professor_filter) {
      const vinculatedToTMP = (!currentUser?.professor && dataProfessors?.professorsByCustomer?.length)? dataProfessors?.professorsByCustomer?.filter((el) => parseInt(el?.vinculated_to_id) === parseInt(currentUser?.id))[0] : null
      setProfessorFilter((currentUser?.professor)? [currentUser?.id] : (vinculatedToTMP?.id)? [vinculatedToTMP?.id] : ['null'])
    }
  }, [currentUser, currentCustomer, dataProfessors])





  function callGetSettingRelationsFunc(){
    if (videos?.length) {
      const videoIds = (videos?.length)? videos?.slice(0, showMore)?.map((el) => ((parseInt(el?.is_translation_of))? parseInt(el?.is_translation_of) : parseInt(el?.id)) ) : []

      getSettingRelations({ 
        fetchPolicy: 'cache-and-network',
        variables: { 
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,
          type: 'video_checkbox',
          video_list: videoIds,
          customer_settings_groups: (settingsMode)? null : 'post_edit,vimeo_loc',
          settings_group_visibility: (settingsMode && groupsVisibility && Object.keys(groupsVisibility)?.length)? JSON.stringify(groupsVisibility) : null,
        }
      })
    }
  }










  useEffect(() => {
    if (orderGroupsPanelOpen) {
      document.addEventListener("dragstart", dragTMPFunc, false);
      return () => { document.removeEventListener('dragstart', dragTMPFunc) };
    } else { document.removeEventListener('dragstart', dragTMPFunc); }
  }, [orderGroupsPanelOpen])
  function dragTMPFunc(event) { event.dataTransfer.setDragImage(event.target, window.outerWidth, window.outerHeight); }



  function focusTMPFunc() {
    var navSearchInput = document.getElementById("search-input-global");
    if (navSearchInput?.value && navSearchInput.value.replaceAll(' ', '') !=='') {
      document.getElementById("search-input").value = navSearchInput?.value?.trim();
      setSearch(navSearchInput.value?.trim())
      navSearchInput.value = '';
    }
  }


  
 






  useEffect( () => { 
    if (currentCustomer?.setting_professor_filter || editMode) {
      getProfessors()
    } 

    if (editMode) {
      getVideoNames()
    }
    

    if (!editMode) {
      setShowFullEditMode(false)
    }
  }, [currentCustomer, editMode])


  useEffect( () => { 
    if (settingsMode) {
      setEditMode(false)
      setShowFullEditMode(false)

      setTimeout(() => {
        setLoadingSettingsMode(false)
      }, 1000)


      var defShowFiltersTMP = (showFilters?.length)? [...showFilters] : []
      if (defShowFiltersTMP?.includes('originals') && defShowFiltersTMP?.includes('localizations')) { defShowFiltersTMP = defShowFiltersTMP?.filter((el) => el !== 'localizations') }
      if (!defShowFiltersTMP?.includes('originals') && !defShowFiltersTMP?.includes('localizations')) { defShowFiltersTMP?.push('originals') }
      if (!defShowFiltersTMP?.includes('prioritary') && localVideoPriority) { defShowFiltersTMP?.push('prioritary') }
      if (defShowFiltersTMP?.length !== showFilters?.length) { setShowFilters(defShowFiltersTMP) }


      if (!settingsMode) {
        setTimeout(()=>{
          setAnyChecked(anyCheckbox())
        }, 200)
      }
    } else {

      setShowFilters(defShowFilters)

    }
  }, [settingsMode])
















  useEffect( () => { 
    if (categoryOption?.id) {
      setCategory(categoryOption?.id)
    } else {
      setCategory(null)
    }
  }, [categoryOption])






  
  useEffect( () => { 
    if (loadingVideos || loadingSettingRelations || loadingClicksoundUserNames || loadingModules || loadingStates) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingVideos, loadingSettingRelations, loadingClicksoundUserNames, loadingModules, loadingStates])

  useEffect( () => { 
    if (loadingProfessors || loadingVideoNames) {
      setLoadingEditMode(true)
    } else {
      setLoadingEditMode(false)
    }
  }, [loadingProfessors, loadingVideoNames])




  



  useEffect( () => {   
    const dataModulesTMP = (dataModules?.getCustomerModules?.length)? dataModules?.getCustomerModules : null;

    var selectedModuleTMP = (
      (!module_name && !localModuleFilter)? ((dataModulesTMP?.length === 1)? dataModulesTMP[(dataModulesTMP.length - 1)]?.id : 'all')
      : (module_name && dataModulesTMP?.length)? ((module_name === 'all')? 'all' : dataModulesTMP?.filter((el) => el?.abbreviated_name?.toLowerCase() === module_name?.toLowerCase())[0]?.id)
      : ((localModuleFilter || localModuleFilter === 0) && dataModulesTMP?.length)? ((localModuleFilter === 'all')? 'all' : (localModuleFilter === 0)? currentCustomer?.label_extras_module_url : dataModulesTMP?.filter((el) => el?.abbreviated_name?.toLowerCase() === module_name?.toLowerCase())[0]?.id)
      : 'all'
    );

    if ((module_name || parseInt(localModuleFilter)) && dataModulesTMP?.length) {
        for (var dataModule of dataModulesTMP) {
          if ((module_name && module_name?.toLowerCase() === dataModule.abbreviated_name?.toLowerCase()) || (!module_name && parseInt(dataModule.id) === parseInt(localModuleFilter))) {
            selectedModuleTMP = dataModule.id
            break;
          }
        }
    }

    setModuleFilter((selectedModuleTMP)? selectedModuleTMP : 0)
  }, [module_name, dataModules])


  useEffect(() => {
    if (!listFormat) {
      setEditMode(false)
      setShowFullEditMode(false)
      setSettingsMode(false)
    } 
  }, [listFormat])

  
  useEffect(() => {
    if (!orderFilter.includes('Date')) {
      setStartFilterDate(null)
      setEndFilterDate(null)
    }
  }, [orderFilter])






  function processText(inputText) {
    var out = [];

    if (inputText) {

      out = inputText;
      out = out.split(/(\d+)/);
      out = out.filter(Boolean);
      out = out?.map((el) => {
        var text = (parseInt(el.replace(/^0+/, '')))? el.replace(/^0+/, '') : el

        if (parseInt(text) && text?.length < 6) {
          var diff = 6 - text?.length;
          if (diff > 0) { new Array(diff).fill(0).forEach(function(){ text = '0' + text }); }
        }

        return text;
      })

    }

    return out?.join('');
  } 


  function nameFilterFunc(a,b) {
    var aName = processText(a?.name?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());
    var bName = processText(b?.name?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());

    if((orderFilter.includes(' ASC'))? (aName < bName) : (aName > bName)) { return -1; }
    if((orderFilter.includes(' ASC'))? (aName > bName) : (aName < bName)) { return 1; }
    return 0; 
  }

  /* Apply some filters */
  useEffect(() => {
    if (dataVideos?.getCustomerVideos?.length > 0) {
      setLoading(true)

      var videosTMP = [...dataVideos.getCustomerVideos].sort(nameFilterFunc);
      
      /* Search */
      if (search && search.replaceAll(' ', '') !== '') {
        videosTMP = videosTMP.filter(video => {
          var nameFound = video?.name?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
          var titleFound = video?.title?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
          var professorFound = video?.search_professor_name?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
          var professorsFound = video?.extra_professors_label?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());

          var anyTrue = false;
          if (showFilters?.includes('localizations') && video?.list_localizations?.length) {
            for (var i in video?.list_localizations) {
              var localizationTMP = video?.list_localizations[i];
              var nameFoundLocal = localizationTMP?.name?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
              var titleFoundLocal = localizationTMP?.title?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
              var professorFoundLocal = localizationTMP?.search_professor_name?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());
              var professorsFoundLocal = localizationTMP?.extra_professors_label?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(search.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase());

              if (nameFoundLocal || titleFoundLocal || professorFoundLocal || professorsFoundLocal) { anyTrue = true; break; }
            }
          }

          return (nameFound || titleFound || professorFound || professorsFound || anyTrue)
        })
      }

      var searchBar = document.getElementById('search-input');
      if (searchBar && ((!searchBar?.value && search) || (searchBar?.value && !search))) { searchBar.value = search; }



      if (orderFilter?.includes('Date ')) {
        videosTMP = inBetweenDatesFilterFunc(startFilterDate, endFilterDate, orderFilter, showFilters?.includes('localizations'), videosTMP, false)
      }

      
      /* Order filter */
      if (orderFilter && orderFilter !== 'null') {
        localStorage.setItem(currentCustomer?.token+'-video-order-filter', orderFilter)
        
        if (orderFilter.includes('EndDate ') || orderFilter.includes('CreationDate ') || orderFilter.includes('UpdatedDate ') || orderFilter.includes('ReviewDate ') || orderFilter.includes('RecordDate ')) { /* DATES */
          videosTMP = videosTMP.sort(function(a, b){ 
            var aTMPDate = checkInBetweenDatesFunc(startFilterDate, endFilterDate, orderFilter, showFilters?.includes('localizations'), a, true);
            var bTMPDate = checkInBetweenDatesFunc(startFilterDate, endFilterDate, orderFilter, showFilters?.includes('localizations'), b, true);
            var aEndDate = (aTMPDate)? new Date(aTMPDate) : ((orderFilter.includes(' ASC'))? 1 : 99999999999999);
            var bEndDate = (bTMPDate)? new Date(bTMPDate) : ((orderFilter.includes(' ASC'))? 1 : 99999999999999);
            return (orderFilter.includes(' ASC'))? bEndDate - aEndDate : aEndDate - bEndDate; }
          )
        } else if (orderFilter.includes('Marks ')) { /* Date */
          videosTMP = videosTMP.sort(function(a,b){ 
            var aNum = (parseInt(a?.search_number_of_marks?.split('/')[0]))? parseInt(a.search_number_of_marks.split('/')[0]) : 0;
            var bNum = (parseInt(b?.search_number_of_marks?.split('/')[0]))? parseInt(b.search_number_of_marks.split('/')[0]) : 0;
            return (orderFilter.includes(' ASC'))? aNum - bNum : bNum - aNum; 
          })
        } else if (orderFilter.includes('ProdState ')) { /* Prod State */
          videosTMP = videosTMP.sort(function(a,b){ 
            const aLastState = (a?.production_state && prodStateObj && Object.keys(prodStateObj)?.length)? prodStateObj[a?.production_state] : null;
            const bLastState = (b?.production_state && prodStateObj && Object.keys(prodStateObj)?.length)? prodStateObj[b?.production_state] : null;
            return (orderFilter.includes(' DESC') && (aLastState?.done || bLastState?.done))? (aLastState?.done === bLastState?.done)? 0 : (bLastState?.done)? -1 : 1 : bLastState?.position - aLastState?.position; 
          })
        }


        if (showFilters?.includes('prioritary') && !currentUser?.professor) {
          videosTMP = videosTMP.sort(function(a,b){ 

            var positive = [true, true];
            if (new Date() >= new Date(a?.endDate)) { positive[0] = false }
            if (new Date() >= new Date(b?.endDate)) { positive[1] = false }

            const afirstDate = Math.abs(new Date(a?.endDate));
            const bfirstDate = Math.abs(new Date(b?.endDate));
            const secondDate = new Date();
            const endDateDifference = [Math.round((afirstDate - secondDate) / oneDay), Math.round((bfirstDate - secondDate) / oneDay)];
            
            const aLastState = (a?.production_state && prodStateObj && Object.keys(prodStateObj)?.length)? prodStateObj[a?.production_state] : null;
            const bLastState = (b?.production_state && prodStateObj && Object.keys(prodStateObj)?.length)? prodStateObj[b?.production_state] : null;

            const warning_a = (currentUser?.rol?.includes('user') && !currentUser?.professor && !a.is_translation_of && aLastState?.trigger_publication && a?.endDate && endDateDifference[0] <= 4)? true : false
            const warning_b = (currentUser?.rol?.includes('user') && !currentUser?.professor && !b.is_translation_of && bLastState?.trigger_publication && b?.endDate && endDateDifference[1] <= 4)? true : false

            const chyronMotionState_a = getChyronMotionStateFunc(a);
            const nmb_marks_a = (a?.search_number_of_marks)? a?.search_number_of_marks?.split('/') : 0;
            const nmb_notes_a = (a?.search_number_of_notes)? a?.search_number_of_notes?.split('/') : 0;
            const motionerWarning_a = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && a?.endDate && (endDateDifference[0] <= 4 || !positive[0]) && (aLastState?.position <= 7 || (aLastState?.ended_marks && aLastState?.name !== 'Descartado'))
              && (
                (!currentCustomer?.setting_tittle_is_relevant && !a?.is_sound_media && !a?.title_disabled && a?.motion_state < 5)
                || (currentCustomer?.setting_initial_chyron && !a?.is_sound_media && !a?.chyrons_disabled && chyronMotionState_a < 5)
                || parseInt(nmb_marks_a[1]) > 0 || parseInt(nmb_marks_a[2]) > 0 || parseInt(nmb_marks_a[3]) > 0 || parseInt(nmb_marks_a[4]) > 0
                || parseInt(nmb_notes_a[1]) > 0 || parseInt(nmb_notes_a[2]) > 0 || parseInt(nmb_notes_a[3]) > 0 || parseInt(nmb_notes_a[4]) > 0
              )
            )? true : false

            const chyronMotionState_b = getChyronMotionStateFunc(b);
            const nmb_marks_b = (b?.search_number_of_marks)? b?.search_number_of_marks?.split('/') : 0;
            const nmb_notes_b = (b?.search_number_of_notes)? b?.search_number_of_notes?.split('/') : 0;
            const motionerWarning_b = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && b?.endDate && (endDateDifference[1] <= 4 || !positive[1]) && (bLastState?.position <= 7 || (bLastState?.ended_marks && bLastState?.name !== 'Descartado'))
              && (
                (!currentCustomer?.setting_tittle_is_relevant && !b?.is_sound_media && !b?.title_disabled && b?.motion_state < 5)
                || (currentCustomer?.setting_initial_chyron && !b?.is_sound_media && !b?.chyrons_disabled && chyronMotionState_b < 5)
                || parseInt(nmb_marks_b[1]) > 0 || parseInt(nmb_marks_b[2]) > 0 || parseInt(nmb_marks_b[3]) > 0 || parseInt(nmb_marks_b[4]) > 0
                || parseInt(nmb_notes_b[1]) > 0 || parseInt(nmb_notes_b[2]) > 0 || parseInt(nmb_notes_b[3]) > 0 || parseInt(nmb_notes_b[4]) > 0
              )
            )? true : false

            const aHasWarning = (warning_a || motionerWarning_a)? true : false
            const bHasWarning = (warning_b || motionerWarning_b)? true : false

            return (aHasWarning === bHasWarning)? 0 : (aHasWarning)? -1 : 1;
          })

          videosTMP = videosTMP.sort(function(a,b){ 
            const aPriority = (a?.is_priority)? new Date(a.is_priority) : null
            const bPriority = (b?.is_priority)? new Date(b.is_priority) : null
            return (!aPriority && !bPriority)? 0 : (bPriority - aPriority)? -1 : 1
          })
        }
      }



      setVideos((videosTMP?.length)? [...videosTMP?.filter((el) => el)] : null)
      setLoading(false)

    } else { setVideos(null) }
  }, [search, orderFilter, dataVideos, videoFolders, startFilterDate, endFilterDate, showFilters, prodStateObj])





  useEffect(() => {
    var hasPrioritaryTMP = false;

    if (videos?.length) {
      for (var video of videos) {
        var endDateDifference = null
        var positive = true
        if (video?.endDate) {
          const firstDate = new Date(video?.endDate);
          const secondDate = new Date();
          if (secondDate >= firstDate) { positive = false }
          endDateDifference = Math.round((firstDate - secondDate) / oneDay) 
        }

        const lastState = (video?.production_state && prodStateObj && Object.keys(prodStateObj)?.length)? prodStateObj[video?.production_state]?.position : null;
        const lastStatePosition = (lastState?.position)? lastState?.position : 0;

        const chyronMotionState = getChyronMotionStateFunc(video);
        const nmb_marks = (video?.search_number_of_marks)? video?.search_number_of_marks?.split('/') : 0;
        const nmb_notes = (video?.search_number_of_notes)? video?.search_number_of_notes?.split('/') : 0;
        const motionerWarning = ((currentUser?.rol === 'admin' || currentUser?.rol?.includes('editor')) && video?.endDate && (endDateDifference <= 4 || !positive) && (lastStatePosition <= 7 || (lastState?.ended_marks && lastState?.name !== 'Descartado'))
          && (
            (!currentCustomer?.setting_tittle_is_relevant && !video?.is_sound_media && !video?.title_disabled && video?.motion_state < 5)
            || (currentCustomer?.setting_initial_chyron && !video?.is_sound_media && !video?.chyrons_disabled && chyronMotionState < 5)
            || parseInt(nmb_marks[1]) > 0 || parseInt(nmb_marks[2]) > 0 || parseInt(nmb_marks[3]) > 0 || parseInt(nmb_marks[4]) > 0
            || parseInt(nmb_notes[1]) > 0 || parseInt(nmb_notes[2]) > 0 || parseInt(nmb_notes[3]) > 0 || parseInt(nmb_notes[4]) > 0
          )
        )? true : false

        if (motionerWarning) { hasPrioritaryTMP = true; break; }
      }
    }

    setHasPrioritary(hasPrioritaryTMP)
  }, [videos, prodStateObj])
















  function toMS(str) {
    if (str) {
      if(str && !str.includes(":")) return parseFloat(str);
      const [first, second, third] = str?.split(":");
  
      const inSeconds = (
        (parseInt(first) && parseInt(third))? (parseInt(first) * 60) + parseInt(second) + (parseInt(third) / 60) 
        : (!parseInt(second) && !parseInt(third))? parseInt(first)
        : (parseInt(first) + (parseInt(second) / 60))
      )
  
      return inSeconds;
    }
  }
  
  function addVideoToFolderFunc(video) {
    var addedVideosToFolderTMP = (addedVideosToFolder?.length)? [...addedVideosToFolder] : [];
    var addedIDS = (addedVideosToFolderTMP?.length)? addedVideosToFolder.map(el => el?.id) : null;

    if (addedIDS?.includes(video?.id)) { addedVideosToFolderTMP = addedVideosToFolderTMP?.filter((el) => el?.id !== video?.id) }
    else { addedVideosToFolderTMP.push(video) }

    setAddedVideosToFolder(addedVideosToFolderTMP)
  }









  
  return (
    <div className={`px-5 md:px-10 min-w-[400px] ${(settingsMode)? 'h-[calc(100vh-60px)] flex flex-col' : ''}`}>




      {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 9 || step === 10 || step === 11))? true : false) && 
        <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 10)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
          <div /* Tutorial prop */
            className="ml-[-1000px] mt-[-2300px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
          ></div>
        </div>
      }




      <div className='flex justify-between items-center pl-2'>
        <div className='flex flex-wrap py-14'>
          <div className='flex flex-col lg:flex-row lg:flex-nowrap lg:items-center w-full'>
            <h1 className='font-bold text-4xl whitespace-nowrap'>
              {(!showNavTop && currentCustomer)? currentCustomer?.abbreviated_name + ' / ' : ''}
              Vídeo{(name)? '' : 's'}
              {(currentUser?.professor || pathProfessor || (pathProfessor && videos?.length && videos[0]?.professor?.name))? ' de ' + ((currentUser?.professor)? currentUser?.name : ((pathProfessor)? pathProfessor?.name : '')) : ''}
              {(name)? ' ' + name : ''}
            </h1>
            {(loading)?
              <div className='flex items-center space-x-1 mt-0 lg:mt-2 ml-2 text-gray-400'>
                {loadingIcon}
                <p>Cargando contenidos...</p>
              </div>
            :
              <p className='text-gray-400 mt-0 sm:mt-2 lg:ml-2 text-xs sm:text-base'>
                {(videos?.length)? '' + videos?.length + ' resultados encontrados' : '0 resultados encontrados'}
                {(search && search?.replaceAll(' ', '') !== '')? (' en la busqueda de "' + search?.trim() + '"' + ((startFilterDate || endFilterDate)? ',' : '')) : ''}
                {(startFilterDate && endFilterDate)? ' entre las fechas de inicio y fin introducidas' : (startFilterDate)? ' posteriores la fecha de inicio introducida' : (endFilterDate)? ' anteriores a la fecha de fin introducida' : ''}
                . 
                {(videos?.length)? 
                  <span className='opacity-50 hover:opacity-100'> {(formatNumbersToPrint(videos?.map((el) => toMS(el?.search_media_time))?.reduce((a, b) => a + b, 0), 2) + ' minutos de contenido.')}</span>
                : '' }
              </p>
            }
          </div>

          {((name || pathProfessor) && !currentUser?.professor) && 
            <button 
              className='font-bold text-sm flex items-center space-x-1 text-blue-400 hover:text-blue-700 duration-300 cursor-pointer' 
              onMouseUp={(event)=> navigateOrOpenFunc(event, "/videos") }
            >
              {leftArrowIcon}
              <p>Volver a todos los vídeos</p>
            </button>
          }
        </div>

      


        <div className='flex'>
          {/* <ImportExcelButton /> */}

          {(!currentCustomer?.free_customer && (currentUser?.rol?.includes('user') && currentCustomer?.token === 'pUtZ97TxAs2IDUREraAO')) &&
            <a href="/charts">
              <IconButton color="primary" size="medium">
                {chartIcon}
              </IconButton>
            </a>
          }



          {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && <>
            <Tooltip disableInteractive title="Crear carpeta" placement="bottom" arrow>
              <IconButton onClick={() => setCreateFolder((createFolder)? null : {})} color="primary" size="medium">
                <div id="dirs-menu-tuto">
                  {addFolderIcon}

                  {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 6 || step === 7 || step === 8))? true : false) && 
                    <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 7)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                      <div /* Tutorial prop */
                        className="ml-[-3987px] mt-[-2015px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                      ></div>
                    </div>
                  }
                </div>
              </IconButton>
            </Tooltip>

            <Tooltip disableInteractive title="Subir vídeos" placement="bottom" arrow>
              <a id="upload-menu-tuto" href="/multiple-video-uploader">
                <IconButton color="primary" size="medium">
                  <div>
                    {multipleVideoUploaderIcon}
                    
                    {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 7 || step === 8 || step === 9))? true : false) && 
                      <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 8)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                        <div /* Tutorial prop */
                          className="ml-[-3981px] mt-[-2015px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                        ></div>
                      </div>
                    }

                    {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 8 || step === 9 || step === 10))? true : false) && 
                      <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 9)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                        <div /* Tutorial prop */
                          className="ml-[-3981px] mt-[-2015px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                        ></div>
                      </div>
                    }
                  </div>
                </IconButton>
              </a>
            </Tooltip>
          </>}



          {(currentUser?.rol === 'super_user' && !currentCustomer?.free_customer) && <>
            <Tooltip disableInteractive title="Ver usuarios" placement="bottom" arrow>
              <a href="/users">
                <IconButton color="primary" size="medium">
                  {usersIcon}
                </IconButton>
              </a>
            </Tooltip>
          </>}
        </div>
      </div>










      <div className='pb-[10px] flex items-center flex-wrap space-y-3 lg:space-x-2 lg:flex-nowrap lg:space-y-0'>
        <div className='w-full flex items-center space-x-2'>
          <Collapse orientation="horizontal" in={loading || loadingEditMode || loadingSettingsMode} timeout={100} className='rounded' style={{ minWidth: 'auto' }}>
            <div className='pr-2'>
              <div className={`w-[30px] h-[30px] bg-blue-500 overflow-hidden rounded border-blue-600 flex justify-center`}>
                <div className='scale-50 mt-[-3px] text-white'>
                  <CircularProgress color="inherit"/>
                </div>
              </div>
            </div>
          </Collapse>

          <div className='w-full h-[30px] flex items-center' style={{ margin: 0 }}>
            <div className="search-box h-[30px] transition-width duration-300">
              <IconButton 
                type="button" 
                sx={{ pr: '2px', pl: '4px', borderRadius: 0 }} 
                aria-label="search"
                onMouseUp={() => setSearch(document.getElementById('search-input')?.value?.trim())}
                disabled={saveChanges}
              >
                <SearchIcon />
              </IconButton>

              <InputBase
                id="search-input"
                name="search-input"
                className='w-full'
                sx={{ ml: 1, flex: 1 }}
                placeholder={`Busca por el nombre del tema, su título, o el nombre del ${currentCustomer?.label_professor?.toLowerCase()}`}
                onKeyUp={(event)=> setSearch(event.target.value?.trim())}
                disabled={saveChanges}
                defaultValue={search}
              />

              {(search && search?.trim() !== '') && 
                <IconButton 
                  type="button" 
                  sx={{ p: '4px', borderRadius: 0 }} 
                  aria-label="search"
                  onMouseUp={() => {
                    setSearch(null);
                    document.getElementById('search-input').value = '';
                  }}
                  disabled={saveChanges}
                >
                  <ClearRoundedIcon />
                </IconButton>
              }
            </div>
          </div>
        </div>
        

        <div className='w-full hidden min-[2200px]:block'>
          <VideoFilters
            keyItem={1}
            moduleFilter={moduleFilter}
            setModuleFilter={setModuleFilter}
            selectedModule={selectedModule}
            setSelectedModule={setSelectedModule}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            
            orderFilter={orderFilter}
            setOrderFilter={setOrderFilter}
            languageFilter={languageFilter}
            setLanguageFilter={setLanguageFilter}
            genderFilter={genderFilter}
            setGendetFilter={setGendetFilter}
            professorFilter={professorFilter}
            setProfessorFilter={setProfessorFilter}
            userTagsFilter={userTagsFilter}
            setUserTagsFilter={setUserTagsFilter}
            stateFilter={stateFilter}
            setStateFilter={setStateFilter}
            startFilterDate={startFilterDate}
            setStartFilterDate={setStartFilterDate}
            endFilterDate={endFilterDate}
            setEndFilterDate={setEndFilterDate}

            pathProfessor={pathProfessor}

            dataModules={dataModules?.getCustomerModules}
            dataProfessors={dataProfessors?.professorsByCustomer}
            dataStates={dataStates?.getStatesByCustomer}
            
            saveChanges={saveChanges}
            setLoading={setLoading}
          />
        </div>





        <div className='whitespace-nowrap flex space-y-2 md:space-y-0 md:space-x-2 flex-wrap md:flex-nowrap w-full xl:w-auto'>
          <div className='whitespace-nowrap flex space-x-2 w-full xl:w-auto'>
            <ToggleButtonGroup
              value={showFilters}
              onChange={(event, filtersTMP) => {
                if (settingsMode && filtersTMP?.includes('localizations')) { filtersTMP = filtersTMP?.filter((el) => el !== 'localizations') }
                if (!filtersTMP?.includes('originals') && !filtersTMP?.includes('localizations')) { filtersTMP?.push('localizations') }
                if (filtersTMP?.includes('originals')) { localStorage.setItem(currentCustomer?.token + '-video-originals', 1) } else { localStorage.setItem(currentCustomer?.token + '-video-originals', 0) }
                setShowFilters((filtersTMP?.length)? filtersTMP : null)
              }}
              aria-label="text formatting"
              sx={{ "& .MuiButtonBase-root": { paddingInline: 1, paddingBlock: 0.2, height: 30 } }}
              className='w-full xl:w-auto bg-gray-100'
            >
              <ToggleButton value="originals" aria-label="originals" className='w-full xl:w-auto' sx={{ "&.Mui-selected": { color: "white", backgroundColor: 'rgb(59 130 246)' }, "&.Mui-selected:hover": { color: "white", backgroundColor: 'rgb(37 99 235)' } }}>
                <SimpleToggle
                  active={(showFilters?.includes('originals'))? true : false}
                  borderClasses={(showFilters?.includes('originals'))? 'border-white' : 'border-gray-500'}
                  ballClasses={(showFilters?.includes('originals'))? 'bg-white' : 'bg-gray-500'}
                />

                <p className='text-[12px] ml-2'>Originales</p>
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={showFilters}
              onChange={(event, filtersTMP) => {
                if (settingsMode && filtersTMP?.includes('originals')) { filtersTMP = filtersTMP?.filter((el) => el !== 'originals') }
                if (!filtersTMP?.includes('localizations') && !filtersTMP?.includes('originals')) { filtersTMP?.push('originals') }
                if (filtersTMP?.includes('localizations')) { localStorage.setItem(currentCustomer?.token + '-video-localizations', 1) } else { localStorage.setItem(currentCustomer?.token + '-video-localizations', 0) }
                setShowFilters((filtersTMP?.length)? filtersTMP : null)
              }}
              aria-label="text formatting"
              sx={{ "& .MuiButtonBase-root": { paddingInline: 1, paddingBlock: 0.2, height: 30 } }}
              className='w-full xl:w-auto bg-gray-100'
            >
              <ToggleButton value="localizations" aria-label="localizations" className='w-full xl:w-auto' sx={{ "&.Mui-selected": { color: "white", backgroundColor: 'rgb(59 130 246)' }, "&.Mui-selected:hover": { color: "white", backgroundColor: 'rgb(37 99 235)' } }}>
                <SimpleToggle
                  active={(showFilters?.includes('localizations'))? true : false}
                  borderClasses={(showFilters?.includes('localizations'))? 'border-white' : 'border-gray-500'}
                  ballClasses={(showFilters?.includes('localizations'))? 'bg-white' : 'bg-gray-500'}
                />

                <p className='text-[12px] ml-2'>Localizaciónes</p>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className='whitespace-nowrap flex space-x-2 w-full xl:w-auto'>
            <ToggleButtonGroup
              value={showFilters}
              onChange={(event, filtersTMP) => {
                if (filtersTMP?.includes('prioritary')) { localStorage.setItem(currentCustomer?.token + '-video-prioritary-first', 1) } else { localStorage.setItem(currentCustomer?.token + '-video-prioritary-first', 0) }
                setShowFilters((filtersTMP?.length)? filtersTMP : null)
              }}
              aria-label="text formatting"
              sx={{ "& .MuiButtonBase-root": { paddingInline: 1, paddingBlock: 0.2, height: 30 } }}
              className={`w-full xl:w-auto ${(hasPrioritary && !showFilters?.includes('prioritary'))? `bg-red-400` : 'bg-gray-100 '}`}
            >
              <ToggleButton value="prioritary" aria-label="prioritary" className='w-full xl:w-auto' sx={{ "&.Mui-selected": { color: "white", backgroundColor: 'rgb(59 130 246)' }, "&.Mui-selected:hover": { color: "white", backgroundColor: 'rgb(37 99 235)' } }}>
                <SimpleToggle
                  active={(showFilters?.includes('prioritary'))? true : false}
                  borderClasses={(showFilters?.includes('prioritary') || hasPrioritary)? 'border-white' : 'border-gray-500'}
                  ballClasses={(showFilters?.includes('prioritary') || hasPrioritary)? 'bg-white' : 'bg-gray-500'}
                />

                <p className={`text-[12px] ml-2 ${(showFilters?.includes('prioritary') || hasPrioritary)? 'text-white' : 'text-gray-500'}`}>Prioritarios</p>
              </ToggleButton>
            </ToggleButtonGroup>


            {(!currentUser?.rol?.includes('user') && listFormat && (!selectedModule || !selectedModule?.blocked)) && 
              <div className={`transition-all duration-300 rounded ${(settingsMode)? 'rotate-[180deg] bg-blue-500 hover:bg-blue-600' : 'bg-gray-100'}`}>
                <Tooltip disableInteractive title={(settingsMode)? "Salir del modo workflow" : "Entrar al modo workflow"} placement="bottom" arrow>
                  <ToggleButton
                    value="check"
                    selected={settingsMode}
                    onChange={()=> { 
                      if (!settingsMode) { setLoadingSettingsMode(true); } 
                      setSettingsMode(!settingsMode) 
                      localStorage.setItem(currentCustomer?.token+'-video-settings-mode', (!settingsMode)? 1 : 0)
                    }}
                    className='w-[30px] h-[30px] disabled:opacity-50 disabled:pointer-events-none'
                    disabled={saveChanges || editMode || loading || createFolder}
                  >
                    {(loadingSettingsMode)? <div className={`${(settingsMode)? 'text-white' : ''}`}>{loadingIcon}</div> : ((settingsMode)? <div className='text-white rotate-[-180deg]'>{workflowIcon}</div> : <div>{workflowIcon}</div>)}
                  </ToggleButton>
                </Tooltip>
              </div>
            }

            {((currentUser?.rol === 'admin' || currentUser?.rol?.includes('translator') || currentUser?.rol === 'facturator' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && listFormat && (!selectedModule || !selectedModule?.blocked)) && 
              <div id="edit-menu-tuto" className={`transition-all duration-300 rounded ${(editMode)? 'rotate-[180deg] bg-blue-500 hover:bg-blue-600' : 'bg-gray-100'}`}>
                <Tooltip disableInteractive title={(editMode)? "Salir del modo de edición" : "Entrar al modo de edición"} placement="bottom" arrow>
                  <ToggleButton
                    value="check"
                    selected={editMode}
                    onChange={()=> { 
                      if (!editMode) { setLoadingEditMode(true); } 
                      setEditMode(!editMode) 
                      localStorage.setItem(currentCustomer?.token+'-video-edit-mode', (!editMode)? 1 : 0)
                    }}
                    className='w-[30px] h-[30px] disabled:opacity-50 disabled:pointer-events-none'
                    disabled={saveChanges || settingsMode || loading || createFolder}
                  >
                    {(loadingEditMode)? <div className={`${(editMode)? 'text-white' : ''}`}>{loadingIcon}</div> : ((editMode)? <div className='text-white rotate-[-180deg]'>{editOffIcon}</div> : <div>{editOnIcon}</div>)}
                  </ToggleButton>
                </Tooltip>
              </div>
            }

            {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 4 || step === 5 || step === 6))? true : false) && 
              <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 5)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                <div /* Tutorial prop */
                  className="ml-[-3827px] mt-[-1980px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                ></div>
              </div>
            }

            <div className={`transition-all duration-300 rounded ${(listFormat)? 'rotate-[180deg] bg-blue-500 hover:bg-blue-600' : 'bg-gray-100'} ${(saveChanges || editMode || settingsMode)? 'opacity-50' : ''}`}>
              <Tooltip disableInteractive title={(listFormat)? "Cambiar formato a cuadrícula" : "Cambiar formato a listado"} placement="bottom" arrow>
                <ToggleButton
                  value="check"
                  selected={listFormat}
                  onChange={()=> { setListFormat(!listFormat); localStorage.setItem(currentCustomer?.token+'-video-list-format', !listFormat) }}
                  className='w-[30px] h-[30px] disabled:opacity-50 disabled:pointer-events-none'
                  disabled={saveChanges || editMode || settingsMode || createFolder}
                >
                  {(listFormat)? <div className='text-white rotate-[-180deg]'>{listOnIcon}</div> : <div>{listOffIcon}</div>}
                </ToggleButton>
              </Tooltip>
            </div>
          </div>


        </div>
      </div>


      <div className='block min-[2200px]:hidden xl:space-y-0 space-y-[12px] lg:mt-0 mt-[1px]'>
        <VideoFilters
          keyItem={2}
          moduleFilter={moduleFilter}
          setModuleFilter={setModuleFilter}
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          
          orderFilter={orderFilter}
          setOrderFilter={setOrderFilter}
          languageFilter={languageFilter}
          setLanguageFilter={setLanguageFilter}
          genderFilter={genderFilter}
          setGendetFilter={setGendetFilter}
          professorFilter={professorFilter}
          setProfessorFilter={setProfessorFilter}
          userTagsFilter={userTagsFilter}
          setUserTagsFilter={setUserTagsFilter}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          startFilterDate={startFilterDate}
          setStartFilterDate={setStartFilterDate}
          endFilterDate={endFilterDate}
          setEndFilterDate={setEndFilterDate}

          pathProfessor={pathProfessor}

          dataModules={dataModules?.getCustomerModules}
          dataProfessors={dataProfessors?.professorsByCustomer}
          dataStates={dataStates?.getStatesByCustomer}

          saveChanges={saveChanges}
          setLoading={setLoading}
        />
      </div>







      {/* FOLDERS */}
      {(/* listFormat && */ !settingsMode)?
      <VideoFolderManager
        createFolder={createFolder}
        setCreateFolder={setCreateFolder}
        pathFolders={pathFolders}
        setPathFolders={setPathFolders}
        addedVideosToFolder={addedVideosToFolder}
        setAddedVideosToFolder={setAddedVideosToFolder}
        module_id={(parseInt(moduleFilter))? parseInt(moduleFilter) : null}
        loadingData={(loading || loadingModules || baseLoading)}

        dataModules={dataModules}
        selectedModule={(selectedModule)? selectedModule : moduleFilter}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        videoFolders={videoFolders}

        getVideos={getVideos}
        getVideoFolders={getVideoFolders}
      />
      : null }





      {/* Video tables */}
      <div className='relative'>
        {(!settingsMode)?
          <>
            {/* Edit mode */}
            <VideoHomeEditMode 
              showFullEditMode={showFullEditMode}
              setShowFullEditMode={setShowFullEditMode}
            
              videos={videos}
              dataModules={dataModules?.getCustomerModules}
              dataStates={dataStates?.getStatesByCustomer}
              dataProfessors={dataProfessors?.professorsByCustomer}
            
              anyChecked={anyChecked}
              setAnyChecked={setAnyChecked}

              getVideos={getVideos}
            
              categoryOptions={categoryOptions}
              categoryOption={categoryOption}
              setCategoryOption={setCategoryOption}
              category={category}
              setCategory={setCategory}
            
              editMode={(editMode && !createFolder)}
              setEditMode={setEditMode}
              loadingEditMode={loadingEditMode}
            
              saveChanges={saveChanges}
              setSaveChanges={setSaveChanges}
            />



            <VideoHomeBaseList
              videos={videos}
              dataStates={prodStateObj}
              getVideos={getVideos}
              saveChanges={saveChanges}

              clicksoundUserNames={clicksoundUserNames?.getClicksoundUserNames}
              settingsRelations={dataSettingRelations?.customerSettingRelations}

              hasVideos={(!(loading || loadingEditMode || loadingSettingsMode || baseLoading) && dataVideos?.getCustomerVideos?.length)? true : false}
              hasVideosPostFilter={(videos?.length)? true : false}

              showMore={showMore}
              setShowMore={setShowMore}
              anyChecked={anyChecked}
              setAnyChecked={setAnyChecked}
              selectedModule={selectedModule}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}

              listFormat={listFormat}
              editMode={editMode}
              showFullEditMode={showFullEditMode}

              search={search}
              setSearch={setSearch}
              orderFilter={orderFilter}
              setOrderFilter={setOrderFilter}
              startFilterDate={startFilterDate}
              setStartFilterDate={setStartFilterDate}
              endFilterDate={endFilterDate}
              setEndFilterDate={setEndFilterDate}
              moduleFilter={moduleFilter}
              setModuleFilter={setModuleFilter}
              showFilters={showFilters}
              professorFilter={professorFilter}
              setProfessorFilter={setProfessorFilter}

              dataVideoNames={dataVideoNames?.getCustomerVideos}
              categoryOptions={categoryOptions}

              loading={loading}
              loadingEditMode={loadingEditMode}
              loadingSettingsMode={loadingSettingsMode}
              baseLoading={baseLoading}
              setBaseLoading={setBaseLoading}

              videoFolders={videoFolders}
              pathFolders={pathFolders}
              canBeAddedToFolder={(createFolder)? true : false}
              addedVideosToFolder={addedVideosToFolder}
              addVideoToFolderFunc={addVideoToFolderFunc}
              createFolder={createFolder}
              setCreateFolder={setCreateFolder}
            />
          </>
        :
          <VideoHomeSettingsList
            videos={videos}
            
            clicksoundUserNames={clicksoundUserNames?.getClicksoundUserNames}
            settingsRelations={dataSettingRelations?.customerSettingRelations}
            categoryOptions={categoryOptions}

            hasVideos={(!(loading || loadingEditMode || loadingSettingsMode || baseLoading) && dataVideos?.getCustomerVideos?.length)? true : false}
            hasVideosPostFilter={(videos?.length)? true : false}

            showMore={showMore}
            setShowMore={setShowMore}

            orderGroupsPanelOpen={orderGroupsPanelOpen}
            setOrderGroupsPanelOpen={setOrderGroupsPanelOpen}
            legendVisibility={legendVisibility}
            setLegendVisibility={setLegendVisibility}
            groupsVisibility={groupsVisibility}
            setGroupsVisibility={setGroupsVisibility}
            dataStates={prodStateObj}
            callGetSettingRelationsFunc={callGetSettingRelationsFunc}
            getVideos={getVideos}

            settingsMode={settingsMode}
            search={search}
            setSearch={setSearch}
            startFilterDate={startFilterDate}
            setStartFilterDate={setStartFilterDate}
            endFilterDate={endFilterDate}
            setEndFilterDate={setEndFilterDate}
            moduleFilter={moduleFilter}
            setModuleFilter={setModuleFilter}
            orderFilter={orderFilter}
            professorFilter={professorFilter}
            setProfessorFilter={setProfessorFilter}

            loading={loading || loadingEditMode || loadingSettingsMode}
          />
        }


        <LoadAnimation
          loading={((loading || loadingEditMode || loadingSettingsMode || baseLoading) && !videos?.length)? true : false}
          times={(listFormat)? 6 : 20}
          hasSomeData={(videoFolders?.length)? true : false}
          isList={(listFormat)? true : false}
        />
      </div>



      <div className='w-full mb-10'></div>

    </div>
  )
}

export default Videos
