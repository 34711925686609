import { gql } from "@apollo/client";
export default gql`
  query ($email: String!, $password: String!) {
    getUserByEmailAndPassword(email: $email, password: $password) {
      id
      token
      abbreviated_name
      name
      email
      profile_picture
      language
      professor
      roles
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      visible
      enabled
      
      original_customer_token
      navigation_customer_tokens
      customer_free
      license_token

      pinned_settings

      customer_permission {
        id
        token
        icon
        abbreviated_name
        name
        language
        factura10_client_id
  
        label_module
        label_modules
        label_professor
        label_professors
        label_extras_module
        label_extras_module_url

        setting_upload_name_format
        setting_language_filter
        setting_sex_filter
        setting_professor_filter
        setting_initial_chyron
        setting_recording_tipo_in_list
        setting_translators_can_create_marks
        setting_tittle_is_relevant
        setting_professor_col
        setting_deffault_date_filter
        setting_professors_can_see_marks
        search_bills_to_do
        
        createdAt
        updatedAt
        
        createdAtFormated
        updatedAtFormated
        __typename
      }

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
      }
    }
  }
`;
