import gql from "graphql-tag";
export default gql`
  query GetPublicVideo($token: String!) {
    getPublicVideo(token: $token) {

      id
      token
      name
      title
      thumbnail
      thumbnail_small
      language
      customer_token
      
      last_review {
        filename
        lowres_version
        time
        minutes
      }

    }
  }
`;
