import { useState, useEffect, useContext } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useNavigate } from "react-router-dom";
import md5 from 'md5'

import CustomTextField from "../../components/Inputs/CustomTextField";
import axios from "axios";







const RecoverPasswordNewBox = ({

  tab,
  recoverEmail,
  setRecoverEmail,
  recoverCode,
  setRecoverCode,

}) => {


  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const navigate = useNavigate();


  const [password, setPassword] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState(null);
  const [alreadySubmited, setAlreadySubmited] = useState(false);

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [recoveryBlocked, setRecoveryBlocked] = useState(false);
  const [unknownError, setUnknownError] = useState(false);


  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  const passwordIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M120-280h720q17 0 28.5 11.5T880-240q0 17-11.5 28.5T840-200H120q-17 0-28.5-11.5T80-240q0-17 11.5-28.5T120-280Zm40-222-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34H70q-13 0-21.5-8.5T40-562q0-13 8.5-21.5T70-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T280-562q0 13-8.5 21.5T250-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Zm320 0-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34h-38q-13 0-21.5-8.5T360-562q0-13 8.5-21.5T390-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T600-562q0 13-8.5 21.5T570-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Zm320 0-19 34q-6 11-18 14t-23-3q-11-6-14-18t3-23l19-34h-38q-13 0-21.5-8.5T680-562q0-13 8.5-21.5T710-592h38l-19-32q-6-11-3-23t14-18q11-6 23-3t18 14l19 32 19-32q6-11 18-14t23 3q11 6 14 18t-3 23l-19 32h38q13 0 21.5 8.5T920-562q0 13-8.5 21.5T890-532h-38l19 34q6 11 3 23t-14 18q-11 6-23 3t-18-14l-19-34Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>






  useEffect(() => {    
    setAlreadySubmited(false);

    if ((!recoverEmail || !recoverCode) && tab === 3) {
      navigate('/recover-password')
    }
  }, [tab])





  async function recoverPasswordFunc() {
    setLoading(true)
    setAlreadySubmited(true)

    setApiError(false)
    setRecoveryBlocked(false)
    setUnknownError(false)

    if (recoverEmail?.trim() && recoverEmail?.trim() !== '' && recoverCode?.trim() && recoverCode?.trim() !== '' && password?.trim() && password?.trim() !== '' && password?.trim() === passwordRepeat?.trim()) {
      const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}changePassword`, { new_password: md5(password?.trim()), email: recoverEmail?.trim(), code: recoverCode?.trim() }).catch(function (error) { setApiError(true) });

      if (parseInt(response?.data) || parseInt(response?.data) === 0) {
        if (parseInt(response?.data) === 2) {

          setTimeout(() => {
            setRecoverCode(null)
            setRecoverEmail(null)
            setSuccessAlertMsg("Contraseña reestablecida correctamente, pruebe a iniciar sesión de nuevo.")
            navigate('/signIn')
          }, 3000)

        } else if (parseInt(response?.data) === 1) {
          setRecoveryBlocked(true)
        } else {
          setUnknownError(true)
        }
      }
    }

    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }






  return (
    <div className='flex justify-center items-center h-screen w-screen pb-20'>
      <div className='w-full max-w-3xl min-w-[400px] p-4 space-y-3'>

        <div className="px-[2px]">
          <h1 className='font-bold text-4xl pb-1'>Nueva contraseña</h1>
          <p className="text-lg opacity-75 leading-6">Introduce una nueva contraseña para tu cuenta.</p>
        </div>

        {(alreadySubmited && !loading && (apiError || recoveryBlocked || unknownError)) && 
          <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5">
            <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">
              {(apiError || recoveryBlocked)? errorIcon : passwordIcon}
            </div>

            {
              (apiError)? <p>No se ha podido establecer conexión con el servidor, espere unos minutos y vuelva a intentarlo o contacte con nosotros para más información.</p>
              : (recoveryBlocked)? <p>Se han registrado demásiados intentos de recuperación, por ello, hemos bloqueado esta función por un tiempo. Intentelo de nuevo mas tarde.</p>
              : <p>Credenciales no válidas.</p>
              }
          </div>
        }



        <div className="mt-2 space-y-2">
          <CustomTextField
            label="Nueva contraseña"
            type="password"

            variant="filled"
            error={(password === '' || (alreadySubmited && !password))? 'Este campo es obligatorio.' : null }
            disabled={loading || recoveryBlocked}
            big

            value={(password)? password : ''}
            onChange={(event) => setPassword(event.target.value)}
            onKeyUp={(event) => { 
              if (event.key === 'Enter' && !recoveryBlocked) {
                
              } 
            }}
          />

          <CustomTextField
            label="Repita la nueva contraseña"
            type="password"

            variant="filled"
            error={(passwordRepeat === '' || (alreadySubmited && !passwordRepeat))? 'Este campo es obligatorio.' : (password !== passwordRepeat)? 'Las dos contraseñas deben ser iguales.' : null }
            disabled={loading || recoveryBlocked}
            big

            value={(passwordRepeat)? passwordRepeat : ''}
            onChange={(event) => setPasswordRepeat(event.target.value)}
            onKeyUp={(event) => { 
              if (event.key === 'Enter' && !recoveryBlocked) {
                
              } 
            }}
          />
        </div>



        <div className="w-full flex items-start justify-between whitespace-nowrap">
          <button 
            onClick={() => {
              setRecoverEmail(null)
              setRecoverCode(null)
              navigate("/signIn")
            }}
            className='py-[10px] pr-3 pl-2 flex items-center text-sm text-blue-400 hover:text-blue-600 active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            disabled={loading}
          >
            {leftArrowIcon}
            <p>Volver al inicio de sesión</p>
          </button>


          <button 
            onClick={() => recoverPasswordFunc()}
            className='py-2 pl-4 pr-3 flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            disabled={(!password || password !== passwordRepeat || loading || recoveryBlocked)}
          >
            {(loading)? <p>Guardando nueva contraseña</p> : <p>Guardar nueva contraseña</p>}
            {(loading)? <div className="px-1">{loadingIcon}</div> : saveIcon}
          </button>
        </div>

      </div>
    </div>
  )
}

export default RecoverPasswordNewBox
