import { useState, useContext } from 'react'
import { FuncContext } from '../../../contexts/FuncContext';

import RowButtons from './RowButtons';

import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';








export default function PersExtraItems({ element, billOption }) {



    const { formatNumbersToPrint, remove_tags } = useContext(FuncContext)



    const [tableOpen, setTableOpen] = useState(true);

    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;


    
    function numFunc(num, secNum) {
        return (num || num === 0)? num : secNum;
    }



    return (element)? (
        <div className={`border border-gray-300 rounded bg-gray-300`}>
            <div 
                className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                onClick={() => { setTableOpen(!tableOpen) }}
            >
                <p className='text-lg font-bold px-2' dangerouslySetInnerHTML={{ __html: (element?.title)? remove_tags(element?.title, true) : 'Sin título' }} ></p>
                {(tableOpen)? upArrowIcon : downArrowIcon}
            </div>

            <Collapse orientation="vertical" in={tableOpen} timeout={1000}>
                <div className='border-t border-gray-300'>
                    <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                        

                        <div className=''>
                            {(element?.childs?.length)?
                                [...element?.childs]?.sort((a, b) => {
                                    const a_text = `${a.small_text}-${a?.video?.name}`
                                    const b_text = `${b.small_text}-${b?.video?.name}`

                                    if(a_text < b_text) { return -1; }
                                    if(a_text > b_text) { return 1; }
                                    return 0;
                                })?.map(child => {
                                    return <div key={'bill-child-'+child?.id} className='text-sm hover:bg-gray-200 hover:bg-opacity-80 p-2'>
                                        <div className='flex items-center justify-between space-x-2'>
                                            <div className='flex items-center space-x-2'>
                                                <div className='flex space-x-2'>
                                                    <RowButtons
                                                        row={child?.video}
                                                        factura10Bill={null}
                                                        billOption={billOption}
                                                        hiddenItems={null}
                                                        setHiddenItems={null}
                                                        disableHidden={true}
                                                    />
                                                    
                                                    <div className={`flex items-baseline space-x-1 ${(child?.video?.vimeo_url)? '' : 'text-gray-500'}`}>
                                                        {(child?.video?.vimeo_url)? 
                                                            <a href={child?.video?.vimeo_url} target="_blank">{child?.video?.name}</a> 
                                                            : <>
                                                                <p>{child?.video?.name}</p>
                                                                {(/* marksJson?.disablled */ false || !(billOption?.label?.includes('2024129') || billOption?.label?.includes('2024134') || billOption?.label?.includes('2023135') || billOption?.label?.includes('2024137') || billOption?.label?.includes('2024136'))) && <p className='text-xs text-gray-400'>(Descartado por ISD)</p>}

                                                            </>
                                                        }
                                                    </div>
                                                </div>


                                                {(child?.small_text)? <>
                                                    <p className='text-gray-400'>/</p>
                                                    <p dangerouslySetInnerHTML={{ __html: remove_tags(child?.small_text, true) }} className={`text-gray-400`}></p>
                                                </> : null }
                                            </div>

                                            <div className='flex space-x-2 whitespace-nowrap'>
                                                {(child?.units / 100 > 1)? <>
                                                    <p className='text-gray-400'>x{formatNumbersToPrint((child?.units / 100), 0)} {element?.units_name}</p>
                                                    <p className='text-gray-400'>/</p>
                                                    <p className='text-gray-400'>{formatNumbersToPrint(numFunc(child?.price / 100, 0), 2)} € x {element?.units_name?.substring(0, element?.units_name?.length - 1)}</p>
                                                    <p className='text-gray-400'>/</p>
                                                </> : null }

                                                <p>{formatNumbersToPrint(numFunc(child?.units / 100, 0) * (numFunc(child?.price / 100, 0)), 2)} €</p>
                                            </div>
                                        </div>
                                        {/* <p dangerouslySetInnerHTML={{ __html: (child?.description)? child?.description?.replaceAll('<p><br></p>', '') : 'Sin descripción' }} className={`text-sm text-gray-500 ${(child?.description)? '' : 'opacity-40'}`}></p> */}
                                    </div>
                                })
                            : null }

                            <div
                                className={`w-full flex justify-between items-center font-bold px-2 py-2 bg-gray-300 bg-opacity-20 duration-200`}
                                style={{ borderBottom: 0, borderTop: '1px solid rgba(210, 210, 210, 1)', padding: 8, fontSize: 14 }}
                            >
                                <p className='mr-4'>Total</p>
                                <p className='min-w-[80px] text-right truncate'>{formatNumbersToPrint(element?.childs?.map((el) => (numFunc(el?.units / 100, 0) * (numFunc(el?.price / 100, 0))) )?.reduce((partialSum, a) => partialSum + a, 0), 2)} €</p>
                            </div>
                        </div>


                    </Paper>
                </div>
            </Collapse>
        </div>

    ) : null
}