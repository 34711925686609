import gql from "graphql-tag";
export default gql`
  query GetAllUsersList($own_user_token: String!, $own_customer_token: String!) {
    usersByCustomer(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      abbreviated_name
      name
      email
      profile_picture
      language
      rol
      enabled
      visible

      original_customer_token
      navigation_customer_tokens
      
      createdAt
      updatedAt
      
      customer_permission {
        id
        token
        icon
        abbreviated_name
        name
        language
        createdAt
        updatedAt
      }

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
        language
        createdAt
        updatedAt
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
