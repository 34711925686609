import gql from "graphql-tag";
export default gql`
  query GetBillsByF10IDs($own_customer_token: String!, $own_user_token: String, $ids: [Int], $obj_ids: [Int], $product_name: String) {
    getBillsByF10IDs(own_customer_token: $own_customer_token, own_user_token: $own_user_token, ids: $ids, obj_ids: $obj_ids, product_name: $product_name) {
      id
      token
      label
      observations
      video_notes
      video_edits
      f10ID
      type
      module
      hidden_concepts
      customer_token

      visible
      public
      blocked

      include_extras
      include_c1_new

      external_price
      external_video_data

      date
      createdAt
      updatedAt

      customer
      dateFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
