import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateRecordingSet(
    
    $own_user_token: String!,

    $id: Int,
    $name: String,
    $mark_label: String,
    $description: String,
    $show_mark_label: Boolean,
    $customer_token: String
    
  ) {
    createOrUpdateRecordingSet(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      name: $name,
      mark_label: $mark_label,
      description: $description,
      show_mark_label: $show_mark_label,
      customer_token: $customer_token
    
    }) {

      id
      name
      mark_label
      description
      show_mark_label
      customer_token

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
