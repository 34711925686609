import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


 


export default function BillGeneratorAlertButton({ video, getVideo }) {
  

  
  const { currentCustomer, getJsonString } = useContext(AuthContext)
  const { billTypesList, formatNumbersToPrint } = useContext(FuncContext)
  const nightResume = (currentCustomer?.search_bills_to_do)? getJsonString(currentCustomer?.search_bills_to_do) : null
  

  const alertIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-96q-16 0-28-5t-23-16L117-429q-11-11-16-23t-5-28q0-16 5-28t15.702-22.702L429-843q11-11 23-16t28-5q16 0 28 5t23 16l312 312q11 11 16 23t5 28q0 16-5 28t-16 23L530.702-116.702Q520-106 508-101q-12 5-28 5Zm0-72 312-312-312-312-312 312 312 312Zm-.211-288Q495-456 505.5-466.334q10.5-10.334 10.5-25.611v-143.779Q516-651 505.711-661.5q-10.29-10.5-25.5-10.5Q465-672 454.5-661.666 444-651.332 444-636.055v143.779Q444-477 454.289-466.5q10.29 10.5 25.5 10.5Zm0 120Q495-336 505.5-346.289q10.5-10.29 10.5-25.5Q516-387 505.711-397.5q-10.29-10.5-25.5-10.5Q465-408 454.5-397.711q-10.5 10.29-10.5 25.5Q444-357 454.289-346.5q10.29 10.5 25.5 10.5ZM480-480Z"/></svg>
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480.79-272q20.21 0 34.71-14.35T530-321v-158q0-20.3-14.29-34.65Q501.42-528 481.21-528t-34.71 14.35Q432-499.3 432-479v158q0 20.3 14.29 34.65Q460.58-272 480.79-272Zm-1.18-344q19.39 0 32.89-13.11 13.5-13.12 13.5-32.5 0-19.39-13.11-32.89-13.12-13.5-32.5-13.5-19.39 0-32.89 13.11-13.5 13.12-13.5 32.5 0 19.39 13.11 32.89 13.12 13.5 32.5 13.5Zm.67 552q-85.92 0-161.76-32.52-75.84-32.52-132.66-89.34-56.82-56.82-89.34-132.62Q64-394.28 64-480.46q0-86.44 32.58-162 32.58-75.57 89.5-132.55Q243-832 318.7-864q75.69-32 161.76-32 86.43 0 161.98 32Q718-832 775-775t89 132.81q32 75.81 32 162T864-318.5q-32 75.5-88.99 132.42-56.98 56.92-132.77 89.5Q566.45-64 480.28-64Zm-.28-98q132.51 0 225.26-92.74Q798-347.49 798-480t-92.74-225.26Q612.51-798 480-798t-225.26 92.74Q162-612.51 162-480t92.74 225.26Q347.49-162 480-162Zm0-318Z"/></svg>

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });






  return (nightResume && Object.keys(nightResume)?.length)? <>
    
    <CustomWidthTooltip
      /* disableInteractive */
      title={
        <div className='flex flex-col space-y-1 max-w-[450px]'> 
          {Object.keys(nightResume).map(module_id => {
            const type_group = nightResume[module_id];
            const module_name = (type_group && Object.keys(type_group)?.length)? type_group[Object.keys(type_group)[0]]?.module_abbreviated_name : null;

            return (type_group && Object.keys(type_group)?.length)?
              <div
                key={`resume-group-${module_name}`}
                className='p-2  whitespace-nowrap'
              >
                <p className='text-sm'>Facturas del módulo {module_name} + Extras</p>

                <div>
                  {Object.keys(type_group).map(type_id => {
                    const data = type_group[type_id];
                    const type = (type_id && billTypesList?.length)? billTypesList?.filter((el) => el?.id === type_id)[0] : null;
                    const date = new Date(data?.createdAt);
                    const dateFormated = (("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2)) + 'h';

                    return <div
                      key={`resume-${module_id}-${type_id}`}
                      className='w-full flex justify-between space-x-6 opacity-80'
                    >
                      <p>{type?.name}</p>
                      <p>~ {formatNumbersToPrint(data?.price, 2)} €</p>
                    </div>
                  })}
                </div>
              </div>
            : null 
          })}

          <p className='text-xs pb-2 pt-1 px-2 text-yellow-400'>* Estos datos son generados durante la noche para darte un resumen general de lo que quedaba hasta entonces.</p>
        </div>
      }
      placement="bottom"
      arrow
    >
      <button className='p-[3px] text-white border border-yellow-600 bg-yellow-500 rounded animate-pulse'>
        {infoIcon}
      </button>
    </CustomWidthTooltip>

  </> : null
}