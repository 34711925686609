import gql from "graphql-tag";
export default gql`
  query GetLastActivities(

    $own_user_token: String!,
    $own_customer_token: String!,
    $user_id: Int,
    $types: String,

    $date: String,
    $date_start: String,
    $date_end: String,

    $mark_state: Int,
    $mark_motion_state: Int,
    $mark_rev_state: Int,
    $mark_trad_state: Int,
    
    $limit: Int,
    $visible: Boolean
    $flip_sort: Boolean
    
  ) {
    getLastActivities(input: { 
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,
      user_id: $user_id,
      types: $types,

      date: $date,
      date_start: $date_start,
      date_end: $date_end,

      mark_state: $mark_state,
      mark_motion_state: $mark_motion_state,
      mark_rev_state: $mark_rev_state,
      mark_trad_state: $mark_trad_state,

      limit: $limit,
      visible: $visible
      flip_sort: $flip_sort

    }) {
      
      id
      type
      own_user_id

      user_id
      video_id
      video_state_id
      video_state_name
      video_review_id
      video_mark_id
      video_comment_id
      customer_token

      mark_type
      mark_state
      mark_motion_state
      mark_rev_state
      mark_trad_state
      language

      createdAt

      video {
        name
        title
        url_name
        language
      }

      createdAtFormated
      __typename

    }
  }
`;
