import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client'
import axios from 'axios'

import BillGeneratorAlertButton from './BillGeneratorAlertButton';
import Factura10BillGenerator from './Factura10BillGenerator';

import C1ExtraMotions from './CustomTables/C1ExtraMotions'
import C1Words from './CustomTables/C1Words'

import GetBillConceptsFromBill from '../graphql/queries/GetBillConceptsFromBill'

import Factura10Metraje from './CustomTables/Factura10Metraje';
import Factura10Motions from './CustomTables/Factura10Motions';
import Factura10Traduccion from './CustomTables/Factura10Traduccion';
import Factura10TraduccionMultipleLangs from './CustomTables/Factura10TraduccionMultipleLangs';
import Factura10Correccion from './CustomTables/Factura10Correccion';
import Factura10MotionsLocalizations from './CustomTables/Factura10MotionsLocalizations';
import Factura10MetrajeYTraduccion from './CustomTables/Factura10MetrajeYTraduccion';
import Correction_Bill_31_01_2024_C3_Duplicates from '../pages/facturation_corrections/_correction_bill_31_01_2024_c3_duplicates';
















export default function BillingManager({
  
  billOptions,
  getOptionBills,
  billOption,
  setBillOption,
  moduleOptions,
  moduleOption,
  setModuleOption,
  filterOption,
  setFilterOption,

  encryptedPasswordTMP,

  dataBillNames,
  getBillNames,

}) {


  const { customer_name, bill_token } = useParams()
  const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)




  const [factura10BillOld, setFactura10BillOld] = useState(0);
  const [factura10Bill, setFactura10Bill] = useState(null);
  
  const [billExtras, setBillExtras] = useState(null);
  const [loadingBills, setLoadingBills] = useState(false);
  const [showTables, setShowTables] = useState(true);
  const [showGenerateNewBill, setShowGenerateNewBill] = useState(false);
  const [hiddenItems, setHiddenItems] = useState((billOption?.hidden_concepts)? billOption?.hidden_concepts?.split(',') : null);




  const billIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M244 976q-52 0-87.5-35.5T121 853v-87q0-17 12.5-29.5T163 724h76V209q0-15 8.5-15.5T267 204l32 32 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 44-44q7-7 16-7t16 7l44 44 32-32q11-11 19.5-10t8.5 15v644q0 52-35.5 87.5T716 976H244Zm472-84q17 0 28-11t11-28V299H323v425h312q17 0 29.5 12.5T677 766v87q0 17 11 28t28 11ZM400 460q-17 0-29-12.5T359 418q0-17 12.5-29.5T401 376h157q17 0 29.5 12.5T600 418q0 17-12.5 29.5T558 460H400Zm0 121q-17 0-29-12.5T359 539q0-17 12.5-29.5T401 497h157q17 0 29.5 12.5T600 539q0 17-12.5 29.5T558 581H400Zm279-121q-17 0-29.5-12.5T637 418q0-17 12.5-29.5T679 376q17 0 29.5 12.5T721 418q0 17-12.5 29.5T679 460Zm0 121q-17 0-29.5-12.5T637 539q0-17 12.5-29.5T679 497q17 0 29.5 12.5T721 539q0 17-12.5 29.5T679 581ZM244 892h349v-84H205v45q0 17 11 28t28 11Zm-39 0v-84 84Z"/></svg>


  

  const billTables = {
    "0": <C1ExtraMotions billOption={(billOptions?.length)? billOptions[0] : null} />,
    "1": <C1Words billOption={(billOptions?.length)? billOptions[1] : null} />,
    "952": <Correction_Bill_31_01_2024_C3_Duplicates billOptionTMP={billOption} custom={(!customer_name)? true : false} encryptedPasswordTMP={encryptedPasswordTMP} />,
  }
  
  

  const [getBillExtras, { data: dataExtras, loadingExtras }] = useLazyQuery(GetBillConceptsFromBill, {
    fetchPolicy: 'cache-and-network',
    variables: {
      own_user_token: (currentUser)? currentUser.token : (bill_token)? bill_token : null, 
      bill_id: (parseInt(billOption?.id))? parseInt(billOption?.id) : null,
    }
  })
  


  
  
  useEffect( () => {     
    if (currentCustomer && billOption?.id) {
      getF10Bill()
      getBillExtras()
      setHiddenItems((billOption?.hidden_concepts)? billOption?.hidden_concepts?.split(',') : null)
    }
  }, [currentCustomer, billOption])


  
  useEffect( () => {     
    if (dataExtras?.billConceptsFromBill?.length) {
      setBillExtras(dataExtras?.billConceptsFromBill)
    } else {
      setBillExtras(null)
    }
  }, [dataExtras])












  useEffect( () => {     
    if (factura10BillOld?.document && factura10BillOld?.concepts?.length) {
      var factura10BillTMP = (factura10BillOld?.document)? JSON.parse(JSON.stringify(factura10BillOld)) : null 

      
      /* HIDDEN CONCEPTS */
      if (hiddenItems?.length && factura10BillTMP?.concepts?.length && factura10BillTMP.document.total_alias) {
        var hiddenConcepts = [...factura10BillTMP?.concepts]?.filter((el) => hiddenItems?.includes(el?.concepte) )?.map((el) => el?.preu * 1.21 ).reduce((a, b) => a + b, 0);
        factura10BillTMP.document.total_alias = factura10BillTMP.document.total_alias - hiddenConcepts;

        var conceptsTMP = [...factura10BillTMP?.concepts]?.filter((el) => !hiddenItems?.includes(el?.concepte) )
        factura10BillTMP.concepts = conceptsTMP;


        /* REMOVE UNUSED CATS */
        var catsTMP = (factura10BillTMP?.cats?.length)? factura10BillTMP?.cats : null
        if (catsTMP?.length) {
          for (var key in catsTMP) {
            var catTMP = catsTMP[key];

            if (!parseInt(catTMP?.is_parent)) {
              var remove = true;
    
              for (var conceptTMP of conceptsTMP) {
                if (!conceptTMP?.document_concepte_categoria_id || parseInt(catTMP?.id) === parseInt(conceptTMP?.document_concepte_categoria_id)) {
                  remove = false;
                }
              }
    
              if (remove) { delete catsTMP[key] }
            }
          }

          catsTMP = catsTMP?.filter((el) => el);
          factura10BillTMP.cats = (catsTMP?.length)? catsTMP : null;
        }
      }


      /* EXTRA CONCEPTS */
      if (factura10BillTMP?.document?.total_alias) {
        var priceTMP = parseFloat(factura10BillTMP?.document?.total_alias);

        if (billExtras?.length) {
          for (var extra of billExtras) {
            if (extra?.price) {
              var priceCalc = (extra?.units / 100) * ((extra?.price / 100) + ((extra?.price / 100) * (extra?.iva / 10000)));
              priceTMP = priceTMP + priceCalc;
            }
          }
        }


        if (factura10BillTMP?.concepts) {
          for (var row of factura10BillTMP?.concepts) {
            const billEditedMins = (billOption?.video_edits && row?.concepte)? getJsonString(billOption?.video_edits) : null;
            const deffaultEditedMins = (billEditedMins && row?.concepte in billEditedMins && parseFloat(billEditedMins[row?.concepte]['minutes']))? parseFloat(billEditedMins[row?.concepte]['minutes']) : null;
            if (deffaultEditedMins) {
              const marksJson = (parseFloat(deffaultEditedMins) && row['aux_json'])? getJsonString(row['aux_json']) : null;
              if (deffaultEditedMins && marksJson?.preu_unitat) {
                const diff = (parseFloat(deffaultEditedMins) * marksJson?.preu_unitat)
                priceTMP = priceTMP + ((diff * 0.21) + diff);
              }
            }
          }
        }


        factura10BillTMP.document.total_alias = priceTMP;
      }
      
      setFactura10Bill((factura10BillTMP?.document)? factura10BillTMP : null)

    } else {

      getF10Bill()
      
    }
  }, [factura10BillOld?.document?.id, factura10BillOld?.concepts?.length, billExtras, billOption, hiddenItems])





  

  function getF10Bill() {
    if (currentCustomer?.factura10_client_id && billOption?.f10ID) {
      setLoadingBills(true)

      axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_get_past_bills.php`, { client_id: currentCustomer.factura10_client_id, document_id: billOption?.f10ID }).then( async (response) => { 
        if (response?.data) {
          if (response?.data?.concepts?.length) {
            response.data.concepts = [...response?.data?.concepts]?.sort((a,b) => {
              var aName = processText(a?.concepte?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());
              var bName = processText(b?.concepte?.trim()?.normalize("NFD")?.replace(/\p{Diacritic}/gu, "").toLowerCase());
          
              if(aName < bName) { return -1; }
              if(aName > bName) { return 1; }
              return 0; 
            });
          }
          
          setFactura10BillOld(response?.data)
        } else {
          setFactura10BillOld(null)
        }

        setLoadingBills(false)
      })
    } else {
      setFactura10BillOld(null)
    }
  }




  function processText(inputText) {
    var out = [];

    if (inputText) {
      out = inputText;
      out = out.split(/(\d+)/);
      out = out.filter(Boolean);
      out = out?.map((el) => {
        var text = (parseInt(el.replace(/^0+/, '')))? el.replace(/^0+/, '') : el

        if (parseInt(text) && text?.length < 6) {
          var diff = 6 - text?.length;
          if (diff > 0) { new Array(diff).fill(0).forEach(function(){ text = '0' + text }); }
        }

        return text;
      })
    }

    return out?.join('');
  }





  






  return (
    <div className='w-full flex flex-wrap space-y-3'>



      {(!customer_name) && 
        <div className='w-full flex justify-between items-end pb-2'>
          <h1 className='font-bold pl-2 text-4xl'>{(showGenerateNewBill)? 'Parámetros de la nueva factura' : ((customer_name)? billOption?.label : 'Factura seleccionada')}</h1>

          <div className='flex justify-end items-center space-x-1'>
            {((currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && !showGenerateNewBill) && <>
              <BillGeneratorAlertButton />

              <button 
                className={`h-[28px] pr-2 pl-1 flex items-center justify-center space-x-1 rounded duration-200 hover:opacity-80 active:opacity-60 text-sm border text-white bg-blue-500 border-blue-600 cursor-pointer`}
                onClick={ async ()=> setShowGenerateNewBill(true)}
                disabled={(!showTables || loadingBills)? true : false}
              >
                {billIcon}
                <p>Crear una nueva factura</p>
              </button>
            </>}
          </div>
        </div>
      }



      {(!customer_name) && <>
        <Factura10BillGenerator
          billOptions={billOptions}
          getOptionBills={getOptionBills}
          billOption={billOption}
          setBillOption={setBillOption}
          factura10BillOld={factura10BillOld}
          factura10Bill={factura10Bill}
          getF10Bill={getF10Bill}
          moduleOptions={moduleOptions}
          moduleOption={moduleOption}
          setModuleOption={setModuleOption}
          filterOption={filterOption}
          setFilterOption={setFilterOption}

          hiddenItems={hiddenItems}
          setHiddenItems={setHiddenItems}

          billExtras={billExtras}
          setBillExtras={setBillExtras}
          getBillExtras={getBillExtras}
          loadingExtras={loadingExtras}

          showGenerateNewBill={showGenerateNewBill}
          setShowGenerateNewBill={setShowGenerateNewBill}
          showTables={showTables}
          setShowTables={setShowTables}

          dataBillNames={dataBillNames}
          getBillNames={getBillNames}
        />
      </>}





      {(billOption && parseInt(billOption?.id) >= 0 && showTables && !showGenerateNewBill) &&
        <div id={`factura-${billOption?.f10ID}`} className='w-full pb-20 relative'>
          {(!billOption?.f10ID)? /* Personalized */

            billTables[billOption?.id] 

          : (billOption?.type === 'minutes')? /* F10 Minutes */
            <Factura10Metraje billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} /> 

          : (billOption?.type === 'motions')? /* F10 Motions */
            <Factura10Motions billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} />
            
          : (billOption?.type === 'translation')? /* F10 Translations */
            <Factura10Traduccion billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} /> 

          : (billOption?.type === 'translation_multiple_langs')? /* F10 Translations */
            <Factura10TraduccionMultipleLangs billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} /> 

          : (billOption?.type === 'linguisticcorrection')? /* F10 Correcciones lingüísticas */
            <Factura10Correccion billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} />

          : (billOption?.type === 'localizations_motions')? /* F10 Motions localizations */
            <Factura10MotionsLocalizations billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} />


            


          : (billOption?.type === 'minutes_translation') && /* F10 Minutes + Translations */
            <Factura10MetrajeYTraduccion billOption={billOption} factura10Bill={factura10Bill} loadingBills={loadingBills} billExtras={billExtras} hiddenItems={hiddenItems} setHiddenItems={setHiddenItems} />       /* OLD */
          }
        </div>
      }
    </div>
  );
}