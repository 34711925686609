import { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';
import { CopyToClipboard } from "react-copy-to-clipboard";

import GetEmailTemplateBySlug from './../graphql/queries/GetEmailTemplateBySlug'
import CreateOrUpdateEmailTemplate from './../graphql/mutations/CreateOrUpdateEmailTemplate'

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';






const EmailTemplate = () => {




  const { slug: pathSlug } = useParams()
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive, setSuccessAlertMsg, setErrorAlertMsg } = useContext(LayoutContext)
  const navigate = useNavigate();




  /* Other vars */
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  /* Form vars */
  const [slug, setSlug] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  /* Loading vars */
  const [loadingContents, setLoadingContents] = useState(true);


  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;


  
  
  const [createOrUpdateEmailTemplate] = useMutation(CreateOrUpdateEmailTemplate)

  const [getEmailTemplateBySlug, { data: dataEmailTemplate, loading: loadingTemplate }] = useLazyQuery(GetEmailTemplateBySlug, {
    variables: {
      own_customer_token: currentCustomer?.token,
      slug: pathSlug,
    }
  })







  useEffect(() => {    
    setCurrentNavActive('summary')
  }, [])

  
  useEffect(() => { 
    if (pathSlug) {
      getEmailTemplateBySlug()
    }
  }, [pathSlug])


  useEffect(() => { 
    if (dataEmailTemplate?.emailTemplateBySlug) {
      setEmailTemplate(dataEmailTemplate.emailTemplateBySlug)
    } else {
      setEmailTemplate(null)
    }
  }, [dataEmailTemplate])


  useEffect(() => { 
    setSlug((emailTemplate)? emailTemplate?.slug : null)
    setTitle((emailTemplate)? emailTemplate?.title : null)
    setBody((emailTemplate)? emailTemplate?.body : null)

    setTimeout(()=>{
      setLoadingContents(false)
    }, 500)
  }, [emailTemplate])





  function actionCode(code) {
    return <>
      <Tooltip disableInteractive title="Haz clic para copiar código de acción" placement="bottom" arrow>
        <div>
          <CopyToClipboard text={code}>
            <button className='px-2 py-1 rounded bg-gray-100 cursor-pointer hover:text-gray-900 hover:bg-gray-300 active:bg-gray-500 duration-300'>{code}</button>
          </CopyToClipboard>
        </div>
      </Tooltip>
    </>
  }


  async function saveEmailTemplateData() {
    setLoadingSave(true)
    setAlreadySubmited(true)

    var response = null
    if (slug && slug?.replaceAll(' ', '') !== '' && title && title?.replaceAll(' ', '') !== '') {
      response = await createOrUpdateEmailTemplate({
        variables: {
          own_user_token: currentUser? currentUser.token : null, 
  
          id: (emailTemplate?.id)? parseInt(emailTemplate.id) : null, 
          slug: (slug)? slug : '',
          title: (title)? title : '',
          body: (body)? body : '',
          customer_token: currentCustomer?.token
        }
      })

      response = response?.data?.createOrUpdateEmailTemplate
    }

    getEmailTemplateBySlug();


    if (response?.id) {
      navigate("/emails")
      setSuccessAlertMsg('La plantilla se ha guardado correctamente.')
    } else {
      setErrorAlertMsg('Error al guardar la plantilla.')
    }

    setLoadingSave(false);
  }





  return (
    <div className="px-3 sm:px-10 min-w-[400px] flex justify-center">
      <div className='w-full max-w-[1200px]'>

        <div className='flex justify-between items-center'>

          <div className='flex flex-wrap py-14 pl-2'>
            <div className='flex items-center'>
              <h1 className='font-bold text-4xl'>{((pathSlug && emailTemplate) || (!pathSlug && !emailTemplate) || loadingContents || loadingTemplate)? (title && title?.replaceAll(' ', '') !==''? title : 'Cargando...') : 'Plantilla inexistente'}</h1>
            </div>
            <p className='text-sm w-full text-gray-400'>* Las plantillas de correos electrónico son un elemento global.</p>
          </div>

          <div className='pb-2'>
            <button
              className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
              onClick={saveEmailTemplateData}
              disabled={(loadingSave || !title)}
            >
              {(loadingSave)? loadingIcon : saveIcon}
              <p>{(loadingSave)? 'Guardando' : 'Guardar'}</p>
            </button>
          </div>
        </div>


        {((pathSlug && emailTemplate) || (!pathSlug && !emailTemplate) || loadingContents || loadingTemplate)?
          !(loadingContents || loadingTemplate)?
            <div>
              <div className='w-full flex flex-wrap lg:flex-nowrap space-x-0 space-y-3 lg:space-x-2 lg:space-y-0'>
                <TextField
                  value={(slug)? slug : ''}
                  onChange={event => setSlug(event.target.value)}
                  id="slug"
                  label="Token"
                  variant="outlined"
                  className='w-full sm:w-60 mui-bg-gray-100'
                  InputProps={{ maxLength: 20, sx: { height: "33.5px", overflow: 'hidden' } }}
                  InputLabelProps={{ sx: { top: "-10px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                  disabled
                />

                <TextField
                  value={(title)? title : ''}
                  onChange={event => setTitle(event.target.value)}
                  id="title"
                  label="Título"
                  variant="outlined"
                  className='w-full mui-bg-gray-100'
                  InputProps={{ maxLength: 100, sx: { height: "33.5px", overflow: 'hidden' } }}
                  InputLabelProps={{ sx: { top: "-10px", "&.MuiInputLabel-shrink": { top: 0 } } }}
                  type="email"
                  error={(alreadySubmited || pathSlug) && (title==='' || !title)} helperText={(alreadySubmited || pathSlug) && (title==='' || !title)? 'Este campo es obligatorio.' : '' }
                />
              </div>

              <div className='mt-4 mb-10'>
                <p className='text-sm text-gray-400'>Cuerpo del email</p>
                <ReactQuill id="body" className='react-quill-textarea bg-gray-100' theme="snow" value={body? body : ''} onChange={setBody}/>
                
                <p className='mt-3 px-1 text-sm text-gray-500'>
                  Escribir estos comandos en el cuerpo del correo electrónico generará una lista, mostrando los elementos que han 
                  sido objeto de alguna actividad en las últimas 24h.
                </p>
                
                <div className='flex justify-start items-center flex-wrap text-xs text-gray-500'>
                  <div className='mt-2 mr-4'>
                    <p className='text-gray-400 pl-1'>Usuarios:</p>
                    <div className='flex justify-start items-center space-x-1'>
                      {actionCode('%update_user%')}
                      {actionCode('%create_user%')}
                    </div>
                  </div>

                  
                  <div className='mt-2 mr-4'>
                    <p className='text-gray-400 pl-1'>Vídeos:</p>
                    <div className='flex justify-start items-center space-x-1'>
                      {actionCode('%priority_videos%')}
                      {actionCode('%warning_videos%')}
                      {actionCode('%video_priority_up%')}
                      {actionCode('%update_video%')}
                      {actionCode('%create_video%')}
                      {actionCode('%remove_video%')}
                      {actionCode('%video_state_change%')}
                      {actionCode('%video_title_change%')}
                    </div>
                  </div>


                  <div className='mt-2 mr-4'>
                    <p className='text-gray-400 pl-1'>Reviews:</p>
                    <div className='flex justify-start items-center space-x-1'>
                      {actionCode('%create_video_review%')}
                      {actionCode('%remove_video_review%')}
                    </div>
                  </div>


                  <div className='mt-2 mr-4'>
                    <p className='text-gray-400 pl-1'>Marcas:</p>
                    <div className='flex justify-start items-center space-x-1'>
                      {actionCode('%update_video_mark%')}
                      {actionCode('%create_video_mark%')}
                      {actionCode('%remove_video_mark%')}
                    </div>
                  </div>


                  <div className='mt-2 mr-4'>
                    <p className='text-gray-400 pl-1'>Comentarios en las marcas:</p>
                    <div className='flex justify-start items-center space-x-1'>
                      {actionCode('%update_video_mark_comment%')}
                      {actionCode('%create_video_mark_comment%')}
                      {actionCode('%remove_video_mark_comment%')}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          :
            <div className='w-full flex justify-center items-center space-x-4 text-gray-400 py-[200px]'>
              <CircularProgress  color="inherit"/>
              <p>Cargando...</p>
            </div>
        :
          <>
            <hr className='border-gray-300'/>
            <p className='text-gray-400 px-1 py-4'>Es probable que esta plantilla de correo electrónico haya sido eliminado o directamente no haya existido nunca.</p>
          </>
        }

      </div>
    </div>
  )
}

export default EmailTemplate
