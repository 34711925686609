import gql from "graphql-tag";
export default gql`
  mutation CreateVideoLocalizations(

    $own_user_token: String!, 

    $id: ID!, 
    $new_language: String!, 
    $new_name: String, 
    $endDate: Date, 
  
  ) {
    createVideoLocalizations(

      own_user_token: $own_user_token,

      id: $id,
      new_language: $new_language,
      new_name: $new_name,
      endDate: $endDate,
    
    ) {

      id
      name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled

      vimeo_url
      thumbnail
      thumbnail_small
      production_state
      production_state_change_label
      tags
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      folder_id
      recording_set_ids
      customer_token
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      is_sound_media
      search_review_version
      search_media_time
      search_review_date
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_translated
      search_module_name
      search_module_abbreviated_name
      search_professor_name
      search_production_state_change_history

      factura10_product_id
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words

      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      chyron_states

      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt

      professor {
        id
        name
        professor_code
        vinculated_to_id
        positions
      }

      translations {
        id
        name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }

      finalVideo {
        id
        name
        url_name
        language
        original_language

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_translated
        search_module_name
        search_module_abbreviated_name
      }
      
      videoGroup

      searchReviewDateFormated
      isPriorityDateFormated
      recordingDateFormated
      publicationEndDateFormated
      endDateFormated
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
