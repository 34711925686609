import gql from "graphql-tag";
export default gql`
  query GetCustomerVideoNames($own_user_token: String!, $own_customer_token: String!, $localizations: Int, $module: String,) {
    getCustomerVideos(own_user_token: $own_user_token, own_customer_token: $own_customer_token, localizations: $localizations, module: $module) {
      id
      name
      url_name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled
      
      vimeo_url
      thumbnail
      thumbnail_small
      production_state
      production_state_change_label
      tags
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      recording_set_ids
      customer_token
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words

      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      extra_marks_json
      extra_languages_json
      facturation_note
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      chyron_states

      is_sound_media
      search_review_version
      search_media_time
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name
      search_professor_name
      search_substituted_chyrons
      search_production_state_change_history

      search_number_of_marks
      search_number_of_notes
      search_number_of_marks_trad
      search_number_of_bill_concepts

      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt


      professor {
        id
        name
        professor_code
        vinculated_to_id
        email
      }

      reviews {
        id
        filename
        time
        minutes
        time_added
        minutes_added
        video_id

        dateAtFormated
        createdAtFormated
        updatedAtFormated
      }
    }
  }
`;
