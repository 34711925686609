import UserProfilePic from './UserProfilePic'


export default function MarkItemSimpleHeader({ mark }) {

  const guest_name = (mark?.search_guest_data)? mark?.search_guest_data?.split(',')[1] : null

  return (
    <div className='flex items-end justify-between space-x-1'>
      <div className="flex items-center space-x-1 py-1">
        <UserProfilePic
          user={mark?.user}
          size={19}
          className={`text-gray-700`}
        />
        
        <p className='whitespace-nowrap'>{(mark?.user?.name)? mark?.user?.name : (guest_name)? guest_name : 'Guest'}</p>
      </div>


      <div className='py-[2px] flex items-center space-x-2 text-[10px] leading-3 opacity-40'>
        {(mark?.createdAtFormated) && <p className='truncate'>Creado el {mark?.createdAtFormated?.replaceAll(' a las', '')}h {(mark?.createdAtFormated !== mark?.updatedAtFormated)? ` / última edición el ${mark?.updatedAtFormated?.replaceAll(' a las', '')}h${(mark?.edit_user_ids?.split(',')?.length)? ' por ' + mark?.edit_user_ids?.split(',')?.length + ' personas mas' : ''}` : null}</p> }
        {(mark?.createdAtFormated && mark?.time)? <p className='hidden sm:inline-block'>/</p> : null}
        {(mark?.time) && <p className='hidden sm:inline-block truncate'>Mínuto {mark?.time} del vídeo</p> }
      </div>

    </div>
  );
}