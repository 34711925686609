import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { AuthContext } from '../../contexts/AuthContext'
import { LayoutContext } from '../../contexts/LayoutContext'
import md5 from 'md5'
import { useParams } from 'react-router-dom';

import Correction_Bill_18_04_2024_Mark_List_Static from './_correction_bill_18_04_2024_mark_list_static'

import GetCustomerByAbreviatedName from '../../graphql/queries/GetCustomerByAbreviatedName'
import GetCustomerModules from '../../graphql/queries/GetCustomerModules'

import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios'








const Correction_Bill_18_04_2024 = () => {



  const { customer_name, module_name, bill_token } = useParams()

  const { currentUser, currentCustomer, setCurrentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)

  

  const [password, setPassword] = useState(null);
  const [encryptedPassword, setEncryptedPassword] = useState(null);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('Bill');
  const [moduleID, setModuleID] = useState(null);

  const [loading, setLoading] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);


  /* Icon vars */
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;




  

  const [getCustomer, { data: dataCustomer, loading: loadingCustomer }] = useLazyQuery(GetCustomerByAbreviatedName, {
    variables: { 
      abbreviated_name: customer_name 
    }
  })

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })








  useEffect(() => {    
    getModules()
  }, [])



  useEffect(() => {    
    setCurrentNavActive('billing')

    const moduleIDTMP = (dataModules?.getCustomerModules?.length)? dataModules?.getCustomerModules?.filter((el) => module_name?.toUpperCase() === el?.abbreviated_name?.toUpperCase()) : null;    
    if (moduleIDTMP?.length && parseInt(moduleIDTMP[0]?.id)) {

      var titleString = `Correcciones en las palabras traducidas del módulo "${moduleIDTMP[0]?.name}" (18/04/2024)`
      setSelectedTitle(titleString)
      document.title = titleString;

      setModuleID(parseInt(moduleIDTMP[0]?.id))
    } else {
      setModuleID(null)
    }
  }, [dataModules])


  useEffect(() => {    
    if (baseLoading) { 
      setTimeout(() => {
        setBaseLoading(false)
      }, 500)
    }
  }, [baseLoading])


  useEffect(() => {
    if (loadingModules || loadingCustomer || baseLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loadingModules, loadingCustomer, baseLoading])


  useEffect(() => {
    if (customer_name) { getCustomer() }
  }, [customer_name])


  useEffect(() => {
    if (customer_name && (!currentCustomer || currentCustomer?.abbreviated_name !== customer_name)) {
      setCurrentCustomer((dataCustomer?.getCustomerByAbreviatedName)? dataCustomer?.getCustomerByAbreviatedName : null)
    }
  }, [dataCustomer])






  useEffect(() => {    
    if ((currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && currentCustomer) {
      getPasswordFunc()
    }
  }, [currentUser, currentCustomer])


  async function getPasswordFunc() {
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkCustommerPassword`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token });
    const correct_password = (response?.data)? response?.data : null

    if (correct_password) {
      setEncryptedPassword(correct_password)
      setAlreadySubmited(true);
    }
  }
  


  async function enterFunc(passwordTMP) {
    setEncryptedPassword(null)
    setAlreadySubmited(true);
    
    var encryptedPasswordTMP = md5(passwordTMP)
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkCustommerPassword`, { own_customer_token: currentCustomer?.token, password: encryptedPasswordTMP });
    const correct_password = (parseInt(response?.data))? true : false

    if (correct_password) {
      setEncryptedPassword(encryptedPasswordTMP)
    }
  }







  

  
  return <>
    {(!loading && alreadySubmited && parseInt(moduleID) && encryptedPassword)? (
      <div className={`px-10 min-w-[970px] flex justify-center pt-10`}>
        <div className='w-full min-w-[1050px] max-w-[1300px]'>


          <Correction_Bill_18_04_2024_Mark_List_Static 
            moduleID={moduleID}
            dataModules={dataModules}
          />
          

        </div>
      </div>
    ) : (!loading && !encryptedPassword && selectedTitle)? (
      <div>
        

        <div className={`flex justify-center items-center h-screen w-screen pb-16 p-8 ${(!customer_name)? 'max-w-[calc(100vw-72px)]' : 'max-w-[calc(100vw)]'}`}>
          <div className='w-full max-w-2xl min-w-[500px] pb-20 flex flex-wrap space-y-2'>
            {(selectedTitle) && 
              <div>
                <h1 className='text-sm text-gray-500'>Entrar a la factura</h1>
                <h1 className='font-bold text-xl mb-4'>{selectedTitle}</h1>
              </div>
            }


            <TextField
              value={password? password : ''}
              onChange={event => setPassword(event.target.value.replaceAll(' ', ''))}
              id="password"
              name="password"
              label="Password"
              variant="filled"
              type="password"
              error={alreadySubmited && (!encryptedPassword || !password)}
              helperText={alreadySubmited && (!encryptedPassword || !password)? 'Contraseña incorrecta' : '' }
              className='w-full'
              onKeyPress={(event) => {if(event.key === 'Enter'){enterFunc(password)}}}
            />

            <LoadingButton variant="contained" onClick={() => enterFunc(password)} className="w-full" size="large">
              Entrar
            </LoadingButton>


          </div>
        </div>


      </div>
    ) : (!parseInt(moduleID))?
      <div className='absolute z-10 w-screen h-screen flex items-center justify-center'>
          <div className='mb-40 w-full h-full max-w-[700px] max-h-[200px] flex justify-center items-center px-4 py-[100px] bg-gray-200 rounded text-neutral-500'>
              <p>Esta factura no existe.</p>
          </div>
      </div>
    : 
      <div className={'absolute z-10 top-0 left-0 w-screen h-screen flex items-center justify-center bg-white'}>
        <div className='mb-40 w-full h-full max-w-[300px] max-h-[100px] flex justify-center items-center space-x-2 px-4 py-[50px] animate-pulse bg-gray-100 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
      </div>
    } 

    
    <div className={`
      fixed z-50 top-0 left-0 w-screen h-screen flex items-center justify-center bg-white duration-300
      ${(!loading)? 'opacity-0 pointer-events-none' : 'opacity-100'}`
    }>
        <div className='mb-20 w-full h-full max-w-[300px] max-h-[100px] flex justify-center items-center space-x-2 px-4 py-[50px] animate-pulse bg-gray-100 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>


  </>
}

export default Correction_Bill_18_04_2024
