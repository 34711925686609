import { useState, useEffect, useContext } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useParams } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext';
import axios from 'axios'
import { CopyToClipboard } from "react-copy-to-clipboard";

import VideoLanguages from '../components/VideoLanguages'
import NotificateToInputs from '../components/NotificateToInputs';

import GetCustomerVideoNamesSimple from './../graphql/queries/GetCustomerVideoNamesSimple'
import GetCustomerModules from './../graphql/queries/GetCustomerModules'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'
import GetAllUserTags from './../graphql/queries/GetAllUserTags'

import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import AddExtraRecordingSets from './AddExtraRecordingSets';
import VideoCategoryHistory from './VideoCategoryHistory';
import GetVideo from '../graphql/queries/GetVideo';
import CreateOrUpdateVideo from '../graphql/mutations/CreateOrUpdateVideo';

import CustomTextField from './Inputs/CustomTextField';
import CustomSwitch from './Inputs/CustomSwitch';
import CustomDatePicker from './Inputs/CustomDatePicker';
import CustomAutocomplete from './Inputs/CustomAutocomplete';
import CustomSelect from './Inputs/CustomSelect';
import CustomMultipleAutocomplete from './Inputs/CustomMultipleAutocomplete';







export default function VideoFormInputs({

  simplified,
  initialVideoID,
  initialVideo,
  triggerSave,

  loadingContent,
  setLoadingContent,
  loadingSave,
  setLoadingSave,
  loadingRemove,
  setLoadingRemove,

  videoNameRepeated,
  setVideoNameRepeated,

  videoSetter,
  getVideos,
  setInitialVideo,

}) {


  const { id } = useParams()
  const { currentUser, currentCustomer, languageList } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)






  /* Video vars */
  const [video, setVideo] = useState(null);

  const [filename, setFilename] = useState(null);
  const [urlName, setUrlName] = useState(null);
  const [title, setTitle] = useState(null);
  const [titleDisabled, setTitleDisabled] = useState(false);
  const [chyronsDisabled, setChyronsDisabled] = useState(false);
  const [vimeoURL, setVimeoURL] = useState(null);
  const [category, setCategory] = useState(null);
  const [professor, setProfessor] = useState(null);
  const [extraProfessors, setExtraProfessors] = useState([]);
  const [module, setModule] = useState((parseInt(video?.module_id))? parseInt(video?.module_id) : null);
  const [recordingSetsIds, setRecordingSetsIds] = useState(null);
  const [active, setActive] = useState(true);
  const [priorityOption, setPriorityOption] = useState(false);

  const [language, setLanguage] = useState('esp');
  const [originalLanguage, setOriginalLanguage] = useState(null);
  const [hasTranscription, setHasTranscription] = useState(0);
  const [hasSubtitulation, setHasSubtitulation] = useState(0);
  const [hasTranslation, setHasTranslation] = useState(false);
  const [isTraductionOf, setIsTraductionOf] = useState(null);
  const [isPartOfId, setIsPartOfId] = useState(null);
  const [isPartOfName, setIsPartOfName] = useState(null);
  const [numberOfWords, setNumberOfWords] = useState(0);
  const [selectedTags, setSelectedTags] = useState(null);
  const [facturationNote, setFacturationNote] = useState(null);
  
  const [extraMarks, setExtraMarks] = useState([]);
  const [extraLanguages, setExtraLanguages] = useState([]);

  const [recordingDate, setRecordingDate] = useState(null);
  const [publicationEndDate, setPublicationEndDate] = useState(null);
  const [endDateDisabled, setEndDateDisabled] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [pubEndDateActive, setPubEndDateActive] = useState(false);


  /* Deff input vars */
  const defaultMarksItem = { text_motions: 0, chart_motions: 0, motion_number_of_words: 0, phone_adaptations: 0  };
  const defaultLanguagesItem = { language: 'other', has_subtitulation: 0, has_translation: true, number_of_words: ((parseInt(numberOfWords))? parseInt(numberOfWords) : 0), is_translation_of: null };
  


  /* Option input vars */
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOption, setCategoryOption] = useState({ label: 'Vídeo en persona (en plató)', token: 'persona_plato', id: 1 });
  const [moduleOptions, setModuleOptions] = useState([]);
  const [moduleOption, setModuleOption] = useState({ label: currentCustomer?.label_extras_module, abbreviated_name: currentCustomer?.label_extras_module_url, id: null });  
  const [professorOptions, setProfessorOptions] = useState([]);
  const [professorOption, setProfessorOption] = useState({ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null });
  const [allTags, setAllTags] = useState(null);

  const [videoOptions, setVideoOptions] = useState([]);
  const [tradVideoOption, setTradVideoOption] = useState({ label: 'Sin vídeo seleccionado', id: null });
  const [isFromVideoOption, setIsFromVideoOption] = useState({ label: 'Sin vídeo seleccionado', id: null });


  /* Loading vars */
  const [baseLoading, setBaseLoading] = useState(true);


  /* Icon vars */
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const locationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-231q-8.966 0-17.483-3-8.517-3-16.157-8.826Q369-304 292.5-395.414 216-486.829 216-594.037q0-67.963 24.065-118.228t61.814-84.089q37.749-33.823 84.935-50.734Q434-864 480-864q46 0 93.186 16.912 47.186 16.911 84.935 50.734 37.749 33.824 61.814 84.089Q744-662 744-593.716 744-486 668-395.5 592-305 513.73-242.913 506-237 497.483-234T480-231Zm.212-291Q510-522 531-543.737q21-21.738 21-52.263 0-30.938-21.212-52.969-21.213-22.031-51-22.031Q450-671 429-648.956q-21 22.045-21 53Q408-565 429.212-543.5q21.213 21.5 51 21.5ZM252-96q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q216-147 226.35-157.5 236.7-168 252-168h456q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q744-117 733.65-106.5 723.3-96 708-96H252Z"/></svg>
  
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>






  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)

  const [getVideo, { data: dataVideo, loading: loadingVideo }] = useLazyQuery(GetVideo, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      id: (parseInt(initialVideo?.id))? parseInt(initialVideo?.id) : ((parseInt(id))? parseInt(id) : null),
      name: (parseInt(initialVideo?.id))? null : ((parseInt(id))? null : id),
    }
  })

  const [getModules, { data: dataModules, loading: loadingModules }] = useLazyQuery(GetCustomerModules, { 
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })

  const [getVideoNames, { data: dataVideoNames, loading: loadingVideoNames }] = useLazyQuery(GetCustomerVideoNamesSimple, { 
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })

  const [getUserTags, { data: dataUserTags, loading: loadingUserTagsData }] = useLazyQuery(GetAllUserTags, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })

  const [getprofessors, { data: dataProfessors, loading: loadingProfessors }] = useLazyQuery(GetAllProfessorsByCustomer, {
    variables: { 
      customer_token: currentCustomer?.token,
    }
  })
  




  useEffect(() => {
    getprofessors()
    getModules()
    getVideoNames()
    getUserTags()
  }, [])





  useEffect(() => {
    getprofessors()
    getModules()
    getVideoNames()
    getUserTags()
  }, [])

  
  useEffect(() => {
    if (initialVideoID || parseInt(initialVideo?.id)) {
      getVideo()
    } else {
      setVideo(null)
    }
  }, [initialVideoID, initialVideo?.id])


  useEffect(() => {
    if (baseLoading) {
      setTimeout(()=> {
        setBaseLoading(false)
      }, 500)
    }
  }, [baseLoading])


  useEffect(() => { 
    if (baseLoading || loadingVideo || loadingModules || loadingProfessors || loadingVideoNames || loadingUserTagsData) {
      setLoadingContent(true)
    } else {
      setTimeout(()=> {
        setLoadingContent(false)
      }, 400)
    }
  }, [baseLoading, loadingVideo, loadingModules, loadingProfessors, loadingVideoNames, loadingUserTagsData])


  useEffect(() => {    
    setAllTags((dataUserTags?.userTags?.length)? dataUserTags?.userTags?.map((el) => { return { name: el?.name } }) : null)
  }, [dataUserTags])
  

  useEffect(() => {
    setVideo((dataVideo?.video?.id)? dataVideo?.video : null)
  }, [dataVideo, initialVideoID])

  useEffect(() => {
    if (!initialVideo && initialVideoID && dataVideo?.video?.id) {
      setInitialVideo(dataVideo?.video)
    }
  }, [initialVideo, dataVideo, initialVideoID])


  /* Select professors */
  useEffect( () => { 
    var professorsSelect = [{ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null }];
    setProfessorOption({ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado...`, id: null })

    if (dataProfessors && dataProfessors?.professorsByCustomer) {
      dataProfessors.professorsByCustomer?.map((element, key) => {
        if (video?.professor_id === parseInt(element.id) && professorOptions[key+1]) {
          setProfessorOption(professorOptions[key+1])
        }
        professorsSelect.push({ label: element.name + ((element.professor_code) && (' / ' + element.professor_code)), id: element.id })
      });
    }

    if (!professorOptions?.length || professorOptions?.length < 2) {
      setProfessorOptions(professorsSelect);
    }
  }, [dataProfessors, video, professorOptions])


  /* Select categories */
  useEffect( () => { 
    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: (moduleOption?.abbreviated_name && moduleOption?.abbreviated_name !== 'extra')? moduleOption?.abbreviated_name : null }).then( async (response) => {

      var categoriesSelect = [{ label: 'No facturable', token: null, id: null }];
      var defCategory = { label: 'No facturable', token: null, id: null };

      if (response?.data?.length > 0) {
        await response?.data?.map((element, key) => {
          if (parseInt(video?.category_id) === parseInt(element.id)) {
            defCategory = { label: element.nom, token: element.codi, id: element.id }
          }
          categoriesSelect.push({ label: element.nom, token: element.codi, id: element.id })
        });
      }

      setCategoryOption(defCategory);
      setCategoryOptions(categoriesSelect);
    })
  }, [video, moduleOption])


  /* Select modules */
  useEffect( () => { 
    var modulesSelect = [{ label: currentCustomer?.label_extras_module, abbreviated_name: currentCustomer?.label_extras_module_url, id: null }];
    setModuleOption({ label: currentCustomer?.label_extras_module, abbreviated_name: currentCustomer?.label_extras_module_url, id: null })

    if (dataModules && dataModules?.getCustomerModules) {
      dataModules?.getCustomerModules?.map((element, key) => {
        var optionTMP = { label: element.name, abbreviated_name: element.abbreviated_name, id: element.id, module: element }
        modulesSelect.push(optionTMP)
      });
    }

    setModuleOptions(modulesSelect);
  }, [dataModules, video])
  

  useEffect( () => { 
    if (dataModules && dataModules?.getCustomerModules) {
      dataModules?.getCustomerModules?.map((element, key) => {
        var idTMP = key+1;           
        var optionTMP = { label: element.name, abbreviated_name: element.abbreviated_name, id: element.id }

        if (video?.module_id === parseInt(element.id) && moduleOptions[idTMP]) {
          setTimeout(() => {setModuleOption(moduleOptions[idTMP])}, "300")
        } else if (!id && (parseInt(localStorage.getItem(currentCustomer?.token+'-video-module-filter')) === parseInt(idTMP))) {
          setModule(idTMP)
          setModuleOption(optionTMP)
        }

      });
    }
  }, [dataModules, moduleOptions])


  /* Select videos */
  useEffect( () => { 
    var videosSelect = [{ label: 'Sin vídeo seleccionado', id: null }];
    setTradVideoOption({ label: 'Sin vídeo seleccionado', id: null })

    if (dataVideoNames && dataVideoNames?.getCustomerVideos?.length) {
      dataVideoNames?.getCustomerVideos?.map((element, key) => {
        var optionTMP = { label: element.name, id: parseInt(element.id) }
        videosSelect.push(optionTMP)

        if (video?.is_translation_of === parseInt(element.id)) {
          setIsTraductionOf(parseInt(element.id))
          setTradVideoOption(optionTMP)
        }

        if (video?.is_part_of === parseInt(element.id)) {
          setIsPartOfId(parseInt(element.id))
          setIsPartOfName(element.name)
          setIsFromVideoOption(optionTMP)
        }
      });
    }

    videosSelect = videosSelect?.reduce((unique, o) => {
      if(!unique.some(obj => obj.label === o.label /* && obj.id === o.id */)) { unique.push(o); }
      return unique;
    },[]);

    setVideoOptions(videosSelect);
  }, [dataVideoNames, video])


  useEffect( () => { 
    setProfessor((professorOption?.id)? professorOption?.id : null)
  }, [professorOption])
  

  useEffect( () => { 
    setCategory((categoryOption?.id)? categoryOption?.id : null)
  }, [categoryOption])


  useEffect( () => {     
    setModule((moduleOption?.id)? moduleOption?.id : null)
  }, [moduleOption])


  useEffect( () => { 
    setIsTraductionOf((tradVideoOption?.id)? tradVideoOption?.id : null)
  }, [tradVideoOption])


  useEffect( () => { 
    setIsPartOfId((isFromVideoOption.id)? parseInt(isFromVideoOption.id) : null)
    setIsPartOfName((isFromVideoOption.name)? isFromVideoOption.name : null)
  }, [isFromVideoOption])








  async function checkTopicNameFunc() {
    var nameRepeated = false;

    if (dataVideoNames?.getCustomerVideos?.length && filename && filename?.replaceAll(' ', '') !== '') {
      await dataVideoNames?.getCustomerVideos.map((videoTMP) => {
        if (videoTMP.name.replaceAll(' ', '') === filename.replaceAll(' ', '') && filename.replaceAll(' ', '') !== video?.name.replaceAll(' ', '')) {
          nameRepeated = true;
        }
      });
    }
    setVideoNameRepeated(nameRepeated);
  }



















  useEffect(() => {

    setFilename((video?.name)? video?.name : null)
    setUrlName((video?.url_name)? video?.url_name : null)
    setTitle((video?.title)? video?.title : null)
    setTitleDisabled((video?.title_disabled)? true : false)
    setChyronsDisabled((video?.chyrons_disabled)? true : false)
    setVimeoURL((video?.vimeo_url)? video?.vimeo_url : null)
    setCategory((video?.category_id)? video?.category_id : null)
    setModule((video?.module_id)? video?.module_id : null)
    setRecordingSetsIds((video?.recording_set_ids)? video?.recording_set_ids : null)
    setProfessor((video?.professor_id)? video?.professor_id : null)
    setExtraProfessors([]);
    setActive((video?.id)? video?.is_active : true)

    setLanguage((video?.language)? video?.language : 'esp')
    setOriginalLanguage((video?.original_language)? video?.original_language : null)
    setHasSubtitulation((video?.id)? video?.has_subtitulation : false)
    setHasTranscription((video?.id)? video?.has_transcription : 0)
    setHasTranslation((video?.id)? video?.has_translation : false)
    setIsTraductionOf((video?.id)? video?.is_translation_of : null)
    setIsPartOfId((video?.is_part_of)? video?.is_part_of : null)
    setIsPartOfName((video?.search_is_part_of_name)? video?.search_is_part_of_name : null)
    setNumberOfWords((video?.number_of_words)? video?.number_of_words : 0)
    setSelectedTags((video?.tags)? video?.tags?.split(',') : null)
    setFacturationNote((video?.facturation_note)? video?.facturation_note : null)

    setExtraMarks((video?.extra_marks_json)? JSON.parse(video?.extra_marks_json) : [])
    setExtraLanguages((video?.extra_languages_json)? JSON.parse(video?.extra_languages_json) : [])

    setPriorityOption((video && !video?.is_priority)? {label: 'Prioridad base', value: false} : {label: 'Prioridad alta', value: true})
    setRecordingDate((video?.recordingDate)? new Date(video?.recordingDate) : null)
    setEndDateDisabled((video?.end_date_disabled)? true : false)
    setPublicationEndDate((video?.publicationEndDate)? new Date(video?.publicationEndDate) : null)
    setEndDate((video?.endDate)? new Date(video?.endDate) : null)
    setPubEndDateActive((video?.publicationEndDate)? true : false)
    
  }, [video])


  useEffect(() => {
    if (triggerSave) {
      saveChangesFunc()
    }
  }, [triggerSave])


  async function saveChangesFunc() {
    setLoadingSave(true)

    var isModule1 = (parseInt(module) === 1)? true : false;

    var input = {
      own_user_token: currentUser? currentUser.token : null, 

      id: (video?.id)? parseInt(video?.id) : null, 
      name: (filename && filename?.replaceAll(' ', '') !== '')? filename.trim() : null,
      url_name: (urlName && urlName?.replaceAll(' ', '') !== '')? urlName.trim() : null,
      title: (title && title?.replaceAll(' ', '') !== '')? title.trim() : null,
      title_disabled: (titleDisabled)? true : false,
      chyrons_disabled: (chyronsDisabled)? true : false,
      vimeo_url: (vimeoURL && vimeoURL?.replaceAll(' ', '') !== '')? vimeoURL.replaceAll(' ', '') : null,
      professor_id: (professor)? parseInt(professor) : null,
      extra_professors: (extraProfessors?.length && extraProfessors.map((element)=> element.label)?.join(',') !== `Sin ${currentCustomer?.label_professors?.toLowerCase()} extra`)? extraProfessors.map((element)=> element.id)?.join(',') : null,
      extra_professors_label: (extraProfessors?.length && extraProfessors.map((element)=> element.label)?.join(',') !== `Sin ${currentCustomer?.label_professors?.toLowerCase()} extra`)? extraProfessors.map((element)=> element.label)?.join(',') : null,
      category_id: (category)? parseInt(category) : null,
      module_id: (parseInt(module))? parseInt(module) : null,
      recording_set_ids: (recordingSetsIds)? recordingSetsIds : null,
      customer_token: currentCustomer?.token,
      is_active: active,
      is_priority: priorityOption?.value,
      is_part_of: (parseInt(isPartOfId))? parseInt(isPartOfId) : null,
      search_is_part_of_name: (parseInt(isPartOfId) && isPartOfName)? isPartOfName : null,

      language: (language)? language : 'esp',
      original_language: (originalLanguage && originalLanguage !== 'null')? originalLanguage : null,
      has_subtitulation: (parseInt(hasSubtitulation))? parseInt(hasSubtitulation) : 0,
      has_transcription: (parseInt(hasTranscription))? parseInt(hasTranscription) : 0,
      has_translation: (hasTranslation)? true : false,
      is_translation_of: (parseInt(isTraductionOf))? parseInt(isTraductionOf) : null,
      number_of_words: (parseInt(numberOfWords))? parseInt(numberOfWords) : null,
      tags: (selectedTags?.length)? selectedTags?.join(',') : null,

      extra_marks_json: (isModule1 && extraMarks?.length)? JSON.stringify(extraMarks) : null,
      extra_languages_json: (extraLanguages?.length)? JSON.stringify(extraLanguages) : null,
      facturation_note: (facturationNote && facturationNote?.replaceAll(' ', '') !== '')? facturationNote : null,

      recordingDate: (recordingDate)? new Date(recordingDate) : null,
      publicationEndDate: (publicationEndDate)? new Date(publicationEndDate) : null,
      endDate: (endDate)? new Date(endDate) : null,
      end_date_disabled: (endDate)? false : true,
    };

    
    const newVid = await createOrUpdateVideo({variables: input})
    if ((videoSetter === 'set' || videoSetter === 'both') && newVid?.data?.createOrUpdateVideo) { setVideo(newVid?.data?.createOrUpdateVideo); }


    setTimeout(() => {
      setLoadingSave(false)
      setSuccessAlertMsg('Vídeo guardado correctamente!')
      if (videoSetter === 'get' || videoSetter === 'both') { getVideos() }
    }, "300")
  }
















  return (true)? 
    <div id="video-form-box" className={`relative w-full`}>


      <div className={`z-50 w-full h-full pt-2 absolute bg-gray-200 rounded-lg ${(loadingContent || loadingSave || loadingRemove)? '' : 'opacity-0 pointer-events-none'}`}>
        <div className={`animate-pulse w-full h-full flex justify-center items-center space-x-4 bg-gray-200 rounded-lg text-gray-400`}>
          <CircularProgress color="inherit"/>
          <p>Cargando...</p>
        </div>
      </div>


      <div className='flex justify-between space-x-2 items-center'>
        <h1 className='w-full text-gray-800 font-bold text-xl pb-4 whitespace-nowrap'>Información básica</h1>

        <Tooltip disableInteractive title="Copiar nombre" placement="bottom" arrow>
          <button> {/* VIDEO URL */}
            <CopyToClipboard text={process.env.REACT_APP_ROUTE + currentCustomer?.abbreviated_name + "/" + (moduleOption? moduleOption?.abbreviated_name : 'extra') + "/" + urlName}>
              <div className='flex items-center space-x-1 cursor-pointer hover:bg-gray-300 hover:bg-opacity-50 active:bg-gray-400 active:bg-opacity-60 px-2 py-1 rounded duration-200'>
                <div>{copyIcon}</div>
                <p className='truncate'>Marks URL</p>
              </div>
            </CopyToClipboard>
          </button>
        </Tooltip>
      </div>



      <div className='space-y-3 mb-4'>

        <div className='w-full flex items-center flex-wrap 2xl:flex-nowrap space-x-0 2xl:space-x-2 space-y-3 2xl:space-y-0'>
          <div className='w-full 2xl:w-3/5 flex items-center flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-3 md:space-y-0'>
            <div className="w-full">
              <CustomTextField 
                label="Nomenclatura"
                error={videoNameRepeated}
                disabled={(currentUser?.rol !== 'admin' && currentUser?.rol !== 'super_user')? true : false}

                value={(filename)? filename : ''}
                onChange={(event) => setFilename(event.target.value)}
                onKeyUp={() => checkTopicNameFunc()}
              />
              <p className='text-red-600 text-sm h-0'>{(videoNameRepeated)? 'Ya existe un tema con este nombre' : ''}</p>
            </div>

            <CustomTextField 
              label="Nombre de la url"
              disabled={(currentUser?.rol !== 'admin' && currentUser?.rol !== 'super_user')? true : false}

              value={(urlName)? urlName : ''}
              onChange={(event) => setUrlName((event.target.value && event.target.value?.replaceAll(' ', '') !== '')? event.target.value?.replaceAll(' ', '_')?.replaceAll('#', '') : null)}
            />
          </div>



          <div className='w-full flex items-center flex-wrap lg:flex-nowrap space-x-0 lg:space-x-2 space-y-3 lg:space-y-0'>
            <div className='w-full lg:w-auto flex items-center space-x-2'>
              <CustomSwitch 
                label="Chyrons iniciales"
                addClassName="w-full lg:w-auto"

                value={!chyronsDisabled}
                onClick={() => setChyronsDisabled(!chyronsDisabled)}
              />

              <CustomSwitch 
                label="Título"
                addClassName="w-full lg:w-auto"

                value={!titleDisabled}
                onClick={() => setTitleDisabled(!titleDisabled)}
              />
            </div>

            <CustomTextField 
              label="Título"
              disabled={titleDisabled}
              value={(title)? title : ''}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
        </div>




        <div className="w-full flex items-start flex-wrap space-y-3">
          <div className="w-full flex items-start flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-3 md:space-y-0">
            <CustomTextField 
              label="Vimeo URL"
              value={(vimeoURL)? vimeoURL : ''}
              onChange={(event) => setVimeoURL(event.target.value?.replaceAll(' ', ''))}
            />

            <div>
              <CustomDatePicker
                label="Fecha de grabación"
                className="mui-bg-gray-100 w-[180px]"
                inputFormat="DD/MM/YYYY"

                value={(recordingDate)? recordingDate : ''}
                onChange={(value)=> (new Date(value).getFullYear() > 1970)? setRecordingDate(value) : setRecordingDate(null)}
              />
            </div>
            
            <div className="flex items-center space-x-2">
              <CustomSwitch 
                label="Fecha de publicación"
                inputFormat="DD/MM/YYYY"

                value={!endDateDisabled}
                onClick={() => setEndDateDisabled(!endDateDisabled)}
              />

              {(!endDateDisabled) && <>
                <div>
                  <CustomDatePicker
                    label="Fecha de publicación"
                    className="mui-bg-gray-100 w-[190px]"
                    inputFormat="DD/MM/YYYY"

                    value={(endDate)? endDate : ''}
                    onChange={(value)=> (new Date(value).getFullYear() > 1970)? setEndDate(value) : setEndDate(null)}
                  />
                </div>

                {(pubEndDateActive) &&
                  <div>
                    <CustomDatePicker
                      label={`Fecha de publicación ${(pubEndDateActive)? 'final' : ''}`}
                      className="mui-bg-gray-100 w-[220px]"
                      inputFormat="DD/MM/YYYY"

                      value={(publicationEndDate)? publicationEndDate : ''}
                      onChange={(value)=> (new Date(value).getFullYear() > 1970)? setPublicationEndDate(value) : setPublicationEndDate(null)}
                    />
                  </div>
                }

                <Tooltip disableInteractive title={(pubEndDateActive)? "Eliminar fecha final" : "Convertir fecha de publicación en un rango añadiendo una fecha final"} placement="bottom" arrow>
                  <button 
                    onClick={() => {
                      if (publicationEndDate && pubEndDateActive) { setPublicationEndDate(null) }
                      setPubEndDateActive(!pubEndDateActive)
                    }}
                    className={`p-1 border rounded duration-200
                      ${(pubEndDateActive)? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 border-red-600' : 'text-gray-500 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border-gray-400 border-opacity-60'}
                    `}
                  >
                    {(pubEndDateActive)? cancelIcon :  addIcon}
                  </button>
                </Tooltip>
              </>}
            </div>
          </div>



          <div className='w-full min-w-0 flex items-start flex-wrap lg:flex-nowrap space-x-0 lg:space-x-2 space-y-3 lg:space-y-0'>
            <CustomAutocomplete 
              disableClearable
              label={currentCustomer?.label_professor}
              className='w-full lg:max-w-[290px] mui-bg-gray-100 min-w-[250px]'
              listFontSize={13}
              disabled={(currentUser?.rol !== 'admin' && currentUser?.rol !== 'super_user')? true : false}

              options={professorOptions} 
              value={(professorOption)? professorOption : ''} 
              onChange={(event, value) => setProfessorOption(value)}
            />
          
            <div className='w-full min-w-0'>
              <NotificateToInputs 
                notiKey={`format-`}
                users={dataProfessors?.professorsByCustomer}
                infoUserId={null}
                selectedMark={null}
                publicReply={true}
                groups={false}
                previousNotis={video?.extra_professors} /* Put here previous ids */

                notificateTo={extraProfessors}
                setNotificateTo={setExtraProfessors}
                avoidLabel={true}
                small={true}
                showCustomerIcon={false}

                labelInput={`${(currentCustomer?.label_professors === 'Leaders')? 'Ponentes' : currentCustomer?.label_professors} extra`}
                labelSelect={`Añadir a un ${((currentCustomer?.label_professors === 'Leaders')? 'Ponente' : currentCustomer?.label_professors)?.toLowerCase()}`}
                labelNone={`Sin ${((currentCustomer?.label_professors === 'Leaders')? 'Ponentes' : currentCustomer?.label_professors)?.toLowerCase()} extra`}
              />
            </div>

            <AddExtraRecordingSets 
              previousValues={video?.recording_set_ids}
              setIds={setRecordingSetsIds}
              small={true}
              icon={locationIcon}
              noChangeOption={false}

              labelSelect={'Añadir set'}
              labelNone={'Sin sets de grabación'}
              inputWidth={'150px'}
              disabled={false}
            />
          </div>
        </div>

   
        {(allTags?.length) &&
          <CustomMultipleAutocomplete
            label={'Tags'}
            disabled={(currentUser?.rol !== 'admin' && currentUser?.rol !== 'super_user')? true : false}
            getOptionLabel={(option) => (option?.name)? option?.name : 'AAA'}

            options={(allTags?.length)? allTags?.filter((el) => !selectedTags?.includes(el?.name)) : []}
            value={(selectedTags?.length)? ((selectedTags?.length)? selectedTags : [])?.map((el) => { return { name: el } }) : []}
            onChange={(event, value) => setSelectedTags((value?.length)? value?.map((el) => el?.name) : null)}
          />
        }
      </div>




      <div className='flex justify-start items-baseline space-x-2 whitespace-nowrap'>
        <h1 className='input-section-title'>Información avanzada</h1>

        {(moduleOption?.module?.blocked) && <p className='text-xs text-red-500'>(El {currentCustomer?.label_module?.toLowerCase()} de este vídeo se encuentra bloqueado)</p>}
      </div>

      <div className={`${(currentUser?.rol?.includes('user'))? 'flex items-center space-y-3 flex-wrap lg:flex-nowrap lg:space-x-2 lg:space-y-0' : 'space-y-3'} ${(moduleOption?.module?.blocked)? 'opacity-50 pointer-events-none' : ''}`}>
      
        <div className={`flex items-center flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-3 md:space-y-0 ${(currentUser?.rol?.includes('user'))? '' : 'w-full'}`}>
          <CustomAutocomplete 
            disableClearable
            label={currentCustomer?.label_module}
            className='w-full min-w-[150px] md:max-w-[200px] mui-bg-gray-100'

            options={moduleOptions}
            value={(moduleOption)? moduleOption : ''}
            onChange={(event, value) => setModuleOption(value)}
          />


          {(!currentUser?.rol?.includes('user')) && <>
            <div className='w-full flex items-center space-x-1'>
              <CustomAutocomplete 
                disableClearable
                label="Formato"
                disabled={(currentUser?.rol !== 'admin' && currentUser?.rol !== 'facturator')? true : false}

                options={categoryOptions}
                value={(categoryOption)? categoryOption : null}
                onChange={(event, value) => setCategoryOption(value)}
              />

              <VideoCategoryHistory
                video={video}
              />
            </div>

            <CustomTextField 
              label="Nota de facturación"
              value={(facturationNote)? facturationNote : ''}
              onChange={(event) => setFacturationNote(event.target.value?.substring(0, 250))}
            />
          </>}
        </div>




        <div className={`w-full flex items-center flex-wrap space-x-0 space-y-3 ${(currentUser?.rol?.includes('user'))? 'lg:w-auto md:min-w-[430px] md:flex-nowrap md:space-x-2 md:space-y-0' : 'lg:flex-nowrap lg:space-x-2 lg:space-y-0'}`}>
          <CustomSelect
            label="Idioma del vídeo original"
            className='w-full mui-bg-gray-100 lg:max-w-[285px]'

            value={(originalLanguage)? originalLanguage : 'null'}
            onChange={(event) => setOriginalLanguage(event.target.value)}
          >
            <MenuItem value={'null'}>Este és un vídeo original en ({language})</MenuItem>
              
            {languageList?.map(element => {
              return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                <p style={{ margin: 0 }}>{element?.name}</p>
              </MenuItem> 
            })}

            <MenuItem value={'other'}>Otro</MenuItem>
          </CustomSelect>

          <CustomSelect
            label="Idioma"
            className='w-full mui-bg-gray-100 lg:max-w-[150px]'

            value={(language)? language : 'esp'}
            onChange={(event) => setLanguage(event.target.value)}
          >
            {languageList?.map(element => {
              return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                <p style={{ margin: 0 }}>{element?.name}</p>
              </MenuItem> 
            })}

            <MenuItem value={'other'}>Otro</MenuItem>
          </CustomSelect>


          {(!currentUser?.rol?.includes('user')) && <>
            <CustomSelect
              label="Tiene transcripción"

              value={(parseInt(hasTranscription))? parseInt(hasTranscription) : 0}
              onChange={(event) => setHasTranscription(event.target.value)}
            >
              <MenuItem value={2}>Transcripción estándar</MenuItem>
              <MenuItem value={1}>Transcripción prompterizada</MenuItem>
              <MenuItem value={0}>Sin transcripción</MenuItem>
            </CustomSelect>

            <CustomSelect
              label="Tiene subtitulación"

              value={(parseInt(hasSubtitulation))? parseInt(hasSubtitulation) : 0}
              onChange={(event) => setHasSubtitulation(event.target.value)}
            >
              <MenuItem value={0}>Sin subtitulación</MenuItem>
              <MenuItem value={1}>Subtitulación de primer idioma</MenuItem>
              <MenuItem value={2}>Subtitulación de segundo idioma</MenuItem>
              <MenuItem value={3}>Subtitulación de tercer idioma o posterior</MenuItem>
            </CustomSelect>

            <CustomSelect
              label="Tiene traducción"
              className='w-full lg:max-w-[170px] mui-bg-gray-100'
              value={(hasTranslation)? true : false}
              onChange={(event) => setHasTranslation(event.target.value)}
            >
              <MenuItem value={true}>Con traducción</MenuItem>
              <MenuItem value={false}>Sin traducción</MenuItem>
            </CustomSelect>
          </>}
        </div>





        <div className='w-full flex items-center flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-3 md:space-y-0'>
          {(!currentUser?.rol?.includes('user')) &&
            <CustomTextField 
              type={'number'} 
              label="N. Palabras" 
              className='w-full mui-bg-gray-100 md:max-w-[100px]' 

              value={(numberOfWords)? numberOfWords : 0} 
              onChange={(event) => setNumberOfWords(event.target.value)} 
            />
          }

          <CustomAutocomplete 
            disableClearable
            label="Este vídeo es una localización"
            disabled={(videoOptions?.length < 1 || !originalLanguage || originalLanguage === 'null')? true : false}

            options={videoOptions}
            value={(tradVideoOption)? tradVideoOption : ''}
            onChange={(event, value) => setTradVideoOption(value)}
          />

          <CustomAutocomplete 
            disableClearable
            label="Este vídeo prtenece a otro"
            disabled={(videoOptions?.length < 1)? true : false}

            options={videoOptions}
            value={(isFromVideoOption)? isFromVideoOption : ''}
            onChange={(event, value) => setIsFromVideoOption(value)}
          />
        </div>


        {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && 
          <div className='pt-6'>
            <VideoLanguages 
              video={video}
              extraMarks={extraMarks}
              setExtraMarks={setExtraMarks}
              extraLanguages={extraLanguages}
              setExtraLanguages={setExtraLanguages}

              module={module}
              defaultMarksItem={defaultMarksItem}
              defaultLanguagesItem={defaultLanguagesItem}
            />
          </div>
        }
      </div>
    </div>
  :
    <div className={`${simplified? 'w-[calc(80vw)] max-w-[1000px] py-[166px]' : 'w-full py-[100px]'} flex justify-center items-center space-x-4 text-gray-400`}>
      <CircularProgress color="inherit"/>
      <p>Cargando...</p>
    </div>

}