import gql from "graphql-tag";
export default gql`
  query GetAllUserTags($own_user_token: String!, $own_customer_token: String!) {
    userTags(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      name
      user_ids
      customer_token
      createdAt
      updatedAt

      assignedUsers {
        id
        name
      }

      assignedVideos {
        id
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
