import gql from "graphql-tag";
export default gql`
  query GetUserTag($id: ID!) {
    userTag(id: $id) {
      id
      name
      user_ids
      customer_token
      createdAt
      updatedAt

      assignedUsers {
        id
        name
      }

      assignedVideos {
        id
        name
        title
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
