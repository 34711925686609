import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';
import PersExtraItems from './Components/PersExtraItems';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import EditMinutesCell from './Components/EditMinutesCell';











const legend = {
    'c1': [
        { 
            title: [
                'Proceso',
                'Precio',
            ],
            table: [
                { title: 'Vídeo en persona (en plató)', price: '40,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) y postproducción audio proveniente de Bakery', price: '50,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) y postproducción vídeo proveniente de Kreativfilm GmbH (GER)', price: '57,00 € / minuto' },
                { title: 'Vídeo en persona (en remoto)', price: '36,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en plató)', price: '60,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en plató) y postproducción audio proveniente de Bakery', price: '70,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en plató) y postproducción vídeo proveniente de Kreativfilm GmbH (GER)', price: '77,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en remoto)', price: '40,00 € / minuto' },
                { title: 'Podcast', price: '20,00 € / minuto' },

                { title: 'Vídeo en persona (en plató) / Edición básica', price: '10,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) / Edición estándar', price: '40,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) / Edición avanzada', price: '50,00 € / minuto' },

                { title: 'Vídeo en persona (en remoto) / Edición básica', price: '9,00 € / minuto' },
                { title: 'Vídeo en persona (en remoto) / Edición estándar', price: '36,00 € / minuto' },
                { title: 'Vídeo en persona (en remoto) / Edición avanzada', price: '40,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en remoto)', price: '40,00 € / minuto' },
            ],
        },
    ],
    'other': [
        { 
            title: [
                'Proceso',
                'Precio',
            ],
            table:[
                { title: 'Vídeo en persona (en plató) / Edición básica', price: '10,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) / Edición estándar', price: '40,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) / Edición avanzada', price: '50,00 € / minuto' },
        
                { title: 'Vídeo en persona (en remoto) / Edición básica', price: '9,00 € / minuto' },
                { title: 'Vídeo en persona (en remoto) / Edición estándar', price: '36,00 € / minuto' },
                { title: 'Vídeo en persona (en remoto) / Edición avanzada', price: '40,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en remoto)', price: '40,00 € / minuto' },
        
                { title: 'Vídeo entrevista entre doctores (en plató)', price: '60,00 € / minuto' },
                { title: 'Vídeo en persona (en plató) y postproducción audio proveniente de Bakery', price: '50,00 € / minuto' },
                { title: 'Vídeo entrevista entre doctores (en plató) y postproducción audio proveniente de Bakery', price: '70,00 € / minuto' },
    
                { title: 'Podcast', price: '20,00 € / minuto' },
                { title: 'Podcast (en remoto)', price: '20,00 € / minuto' },
            ],
        },
    ],
}

const deff_columns = [
    { id: 'concepte', label: 'Vídeo', minWidth: 180  },

    { id: 'new', label: 'Nuevo' },
    { id: 'minutes', label: 'Minutaje' },
    { id: 'minutes_end', label: 'Minutaje Final' },
    { id: 'minutes_price', label: 'Precio/m' },
    { id: 'preu', label: 'Total', align: 'right' },
];


const deff_columns_cats = [
    { id: 'concepte', label: 'Vídeo' },

    { id: 'old_cat_name', label: 'Categoría anterior' },
    { id: 'old_cat_price', label: 'Precio anterior' },
    { id: 'new_cat_name', label: 'Categoría actual' },
    { id: 'new_cat_price', label: 'Precio final' },

    { id: 'preu', label: 'Diferencia', align: 'right' },
];


const unicodes = { 
    'u00c1': 'Á',
    'u00e1': 'á',
    'u00c9': 'É',
    'u00e9': 'é',
    'u00cd': 'Í',
    'u00ed': 'í',
    'u00d3': 'Ó',
    'u00f3': 'ó',
    'u00da': 'Ú',
    'u00fa': 'ú',
    'u00d1': 'Ñ',
    'u00f1': 'ñ',
}










export default function Factura10Metraje({ billOption, factura10Bill, billExtras, loadingBills, hiddenItems, setHiddenItems }) {



    const { bill_token } = useParams()

    const { currentCustomer, currentUser, getJsonString } = useContext(AuthContext)
    const { formatNumbersToPrint, remove_tags } = useContext(FuncContext)
    const selectedLegend = (billOption?.module?.includes('C1') && legend['c1'])? legend['c1'] : legend['other'];


    
    const [bill, setBill] = useState(null);
    const [concepts, setConcepts] = useState(null);
    const [conceptsCorrected, setConceptsCorrected] = useState(null);
    const [cats, setCats] = useState(null);
    
    const [tableOpen, setTableOpen] = useState(true);
    const [tableXProfessorOpen, setTableXProfessorOpen] = useState(false);
    const [tableXTopicOpen, setTableXTopicOpen] = useState(false);
    const [tableCorrOpen, setTableCorrOpen] = useState(true);
    const [showLegend, setShowLegend] = useState(true);

    const [baseLoading, setBaseLoading] = useState(true);


    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const doneIcon = <svg className='text-green-500' xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
    const notDoneIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.457L531-480.5l116-115.543Q658-607 658-621.5T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.913 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.413-10.913L480-429Zm.276 333Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

    const euroIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill='currentColor' viewBox="0 0 20 20"><path d="M10,17c-1.83,0-3.41-.61-4.72-1.82-1.31-1.22-2.06-2.71-2.24-4.49-.03-.19.03-.36.18-.49s.32-.2.53-.2c.2,0,.37.06.52.17s.24.26.27.46c.15,1.38.75,2.53,1.78,3.47,1.03.94,2.26,1.41,3.68,1.41,1.51,0,2.81-.54,3.89-1.61,1.08-1.08,1.61-2.37,1.61-3.89s-.54-2.81-1.61-3.89-2.37-1.61-3.89-1.61c-.86,0-1.66.18-2.39.53-.73.36-1.34.84-1.84,1.47h1.48c.21,0,.39.07.53.21.14.14.22.32.22.53s-.07.39-.22.54c-.14.15-.32.22-.53.22h-3.5c-.21,0-.39-.07-.53-.22-.14-.14-.22-.32-.22-.53v-3.5c0-.21.07-.39.21-.53.14-.14.32-.22.53-.22s.39.07.54.22c.15.14.22.32.22.53v1.96c.64-.83,1.43-1.49,2.39-1.98s1.99-.73,3.11-.73c.97,0,1.88.18,2.73.55.85.37,1.59.87,2.22,1.5.63.63,1.13,1.37,1.5,2.22.37.85.55,1.76.55,2.73s-.18,1.88-.55,2.73c-.37.85-.87,1.59-1.5,2.22-.63.63-1.37,1.13-2.22,1.5-.85.37-1.76.55-2.73.55Z"/><path d="M8.83,9.49h3.43v1.38h-3.43c.19.94,1.1,1.54,2.04,1.35.28-.06.55-.19.77-.37l1.18.78c-1.16,1.27-3.13,1.35-4.4.19-.55-.51-.9-1.2-.99-1.95h-.71v-1.38h.71c.19-1.71,1.73-2.94,3.44-2.75.75.08,1.44.44,1.95.99l-1.18.78c-.73-.62-1.82-.53-2.44.2-.19.22-.32.49-.37.77Z"/></svg>
    const notTimeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-771.39q61.39 0 117.09 19.5 55.69 19.5 100.13 56.06l27.85-27.84q14.1-14.11 31.21-13.61t31.5 14.89q14.4 14.39 14.4 31.5t-14.4 31.5l-26.56 27.56q36 43.87 55 99.64 19 55.76 19 115.57 0 21.06-1.59 40.6-1.59 19.54-6.32 38.02-4.44 25.65-22.18 34.2-17.74 8.54-36.83 4.54-19.08-4-31.32-18.67-12.24-14.68-6.25-39 3.65-14.29 5.07-29.24 1.42-14.95 1.42-30 0-107.74-74.74-182.48T480-673.39q-16.61 0-31.17 1.87-14.57 1.87-27.35 4.48-21.78 5.56-37.52-6.11-15.74-11.68-19.74-29.76-4-18.09 3.76-35.55 7.76-17.45 32.41-24.02 19.05-6.3 39.14-7.61 20.09-1.3 40.47-1.3Zm44.48 236.69v-39.26q0-14.91-10.35-25.41t-25.65-10.5q-8.44 0-15.88 3.22-7.45 3.22-11.99 9.09l63.87 62.86ZM480-61.52q-74.52 0-138.83-27.92-64.31-27.92-112.43-75.75-48.12-47.84-76.04-112.15-27.92-64.31-27.92-138.97 0-57.99 18.5-113.19 18.5-55.2 54.5-103.02l-86.17-86.17q-12.7-12.7-12.41-27.83.28-15.13 12.97-27.83 12.7-12.69 28.33-12.69 15.63 0 28.33 12.69L794-149.74q12.7 12.7 12.7 27.83 0 15.13-12.7 27.82-12.7 12.7-28.61 12.7t-28.61-12.7l-41-40.43q-45 36-99.91 54.5T480-61.52Zm0-98q40.74 0 77.06-11.52 36.33-11.52 68.07-34.13l-355-355.57q-22.61 32.31-34.98 69.48-12.37 37.17-12.37 75.09 0 107.74 74.74 182.19 74.74 74.46 182.48 74.46ZM386.96-816q-18.7 0-31.59-13.12-12.89-13.11-12.89-31.71 0-18.61 12.89-31.65t31.59-13.04h186.08q18.7 0 31.59 12.83t12.89 31.44q0 18.6-12.89 31.92Q591.74-816 573.04-816H386.96Zm60.39 433.04Zm114.43-114.43Z"/></svg>





    useEffect( () => { 
        if (baseLoading) {
            setTimeout(() => {
                setBaseLoading(false)
            }, 500 )
        }
    }, [baseLoading])


    useEffect( () => { 
        if (currentCustomer) {
            if (factura10Bill?.document) {
                setBill(factura10Bill?.document)
                setConcepts(factura10Bill?.concepts?.filter((el) => {
                    const marksJson = (el['aux_json'] && JSON.parse(el['aux_json']))? JSON.parse(el['aux_json']) : null;
                    return (!marksJson['diff_cat_price'])? true : false
                }))
                setConceptsCorrected(factura10Bill?.concepts?.filter((el) => {
                    const marksJson = (el['aux_json'] && JSON.parse(el['aux_json']))? JSON.parse(el['aux_json']) : null;
                    return (marksJson['diff_cat_price'])? true : false
                }))
                setCats(factura10Bill?.cats)
            } else {
                setBill(null)
                setConcepts(null)
                setConceptsCorrected(null)
                setCats(null)
            }
        }
    }, [factura10Bill, currentCustomer])








    function escapeUnicode(str) {
        Object.keys(unicodes).map((keyName, i) => (
            str = str?.replaceAll(keyName, unicodes[keyName])
        ))
        return str;
    }
    


    function processBaseTableFunc(valueTMP, column, row, returnPrice) {
        var value = valueTMP
        const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
            
        const billEditedMins = (billOption?.video_edits && row?.concepte)? getJsonString(billOption?.video_edits) : null;
        const deffaultEditedMins = (billEditedMins && row?.concepte in billEditedMins && parseFloat(billEditedMins[row?.concepte]['minutes']))? parseFloat(billEditedMins[row?.concepte]['minutes']) : null;
    

        if (column?.id === 'concepte') {
            value = <div className='flex space-x-2'>
                <RowButtons
                    row={row}
                    factura10Bill={factura10Bill}
                    billOption={billOption}
                    hiddenItems={hiddenItems}
                    setHiddenItems={setHiddenItems}
                />
                
                {(row['link'])? 
                    <a href={row['link']} target="_blank">{value}</a> : <p>{value}</p> 
                }

                {(marksJson?.disabled || (!row['link'] && billOption?.createdAt < "2024-07-03T00:00:00.000Z" && !(billOption?.label?.includes('2024129') || billOption?.label?.includes('2024134') || billOption?.label?.includes('2023135') || billOption?.label?.includes('2024137') || billOption?.label?.includes('2024136'))))?
                    <p className='text-xs text-gray-400'>(Descartado por ISD)</p> 
                : null }
            </div>
        }


        if (column?.id === 'new') {
            value = ((returnPrice)? '---' : ((marksJson?.min)? notDoneIcon : doneIcon))
        }


        if (column?.id === 'minutes_start') {
            value = ((returnPrice)? 
                ((parseFloat(marksJson?.min))? parseFloat(marksJson?.min) : 0)
            :
                (marksJson?.min)? formatNumbersToPrint(parseFloat(marksJson?.min), 2) + 'm'  : '---'
            )
        }
        if (column?.id === 'minutes') {
            value = ((returnPrice)? 
                ((parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : 0)
            :
                (marksJson?.min_facturable)? <EditMinutesCell f10Bill={bill} billOption={billOption} video={row} colKey={column?.id} originalMins={parseFloat(marksJson?.min_facturable)} /> : '---'
            )
        }
        if (column?.id === 'minutes_end') {
            value = ((returnPrice)? 
                ((parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) + deffaultEditedMins : 0)
            :
                (marksJson?.min_final)? formatNumbersToPrint(parseFloat(marksJson?.min_final) + deffaultEditedMins, 2) + 'm'  : '---'
            )
        }
        if (column?.id === 'minutes_price') {
            value = ((returnPrice)? 
                '---'
            :
                (marksJson?.preu_unitat)? formatNumbersToPrint(marksJson?.preu_unitat, 2) + ' € / m'  : '---'
            )
        }
        if (column?.id === 'minutes_total') {
            var priceTMP = ((marksJson?.min_facturable && marksJson?.preu_unitat)? marksJson?.min_facturable * marksJson?.preu_unitat  : 0)

            value = ((returnPrice)? 
                ((parseFloat(priceTMP))? parseFloat(priceTMP) : 0)
            :
                (priceTMP)? formatNumbersToPrint(priceTMP, 2)+' €'  : '---'
            )
        }

        if (column?.id === 'preu') {
            var priceTMP = ((parseFloat(value))? (value - ((marksJson?.correction_price)? marksJson?.correction_price : 0)) : 0)

            if (deffaultEditedMins) {
                priceTMP = (parseFloat(marksJson?.min_facturable) + parseFloat(deffaultEditedMins)) * marksJson?.preu_unitat;
            }

            value = ((returnPrice)? 
                ((parseFloat(priceTMP))? parseFloat(priceTMP) : 0)
            :
                formatNumbersToPrint(((parseFloat(priceTMP))? parseFloat(priceTMP) : 0), 2) + ' €'
            )
        }

        return value
        
    }



















    return (currentCustomer && !loadingBills && !baseLoading /* && checkedIfAllNews */)? (
    <div className='space-y-2'>


        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>

                    <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.dateFormated}</p>
                </div>
            </div>



            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            {(billOption?.observations) && 
                <div className='flex flex-wrap space-y-4 pb-4 text-base'>
                    <div dangerouslySetInnerHTML={{__html: (billOption?.observations)? billOption?.observations : ''}}></div> 
                </div>
            }



            <div className='w-full flex overflow-auto'>
                <div className='w-full flex flex-wrap justify-end space-y-4'>
                    {cats?.filter((cat) => !parseInt(cat?.pare_id))?.map(cat => {

                        const finalNumberTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.length ).reduce((partialSum, a) => partialSum + a, 0)
                        
                        const finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => {
                            const billEditedMins = (billOption?.video_edits && row?.concepte)? getJsonString(billOption?.video_edits) : null;
                            const deffaultEditedMins = (billEditedMins && row?.concepte in billEditedMins && parseFloat(billEditedMins[row?.concepte]['minutes']))? parseFloat(billEditedMins[row?.concepte]['minutes']) : null;
                            const marksJson = (parseFloat(deffaultEditedMins) && row['aux_json'])? getJsonString(row['aux_json']) : null;
                            const diff = (parseFloat(deffaultEditedMins))? parseFloat(deffaultEditedMins) * marksJson?.preu_unitat : 0;
                            return ((parseFloat(row?.preu))? parseFloat(row?.preu) : 0) + diff
                        }).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                        
                        const finalMinsTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => {
                            const billEditedMins = (billOption?.video_edits && row?.concepte)? getJsonString(billOption?.video_edits) : null;
                            const deffaultEditedMins = (billEditedMins && row?.concepte in billEditedMins && parseFloat(billEditedMins[row?.concepte]['minutes']))? parseFloat(billEditedMins[row?.concepte]['minutes']) : null;
                            const marksJson = (row['aux_json'])? getJsonString(row['aux_json']) : null;
                            const diff = (parseFloat(deffaultEditedMins))? parseFloat(deffaultEditedMins) : 0;
                            return ((parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : 0) + diff
                        }).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                    

                        const catFinalPrice = formatNumbersToPrint(finalPriceTMP, 2)
                        const subCats = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))
                        const subCatNames = subCats?.map((element) => element?.nom)?.join(', ')?.replaceAll('Otro...', 'Otros');

                        return (finalPriceTMP)? 
                            <div
                                onClick={()=> {
                                    var element = document.getElementById('cat-'+cat?.id);
                                    window.scrollTo({ top: (element.getBoundingClientRect().top + window.pageYOffset - 95), behavior: "smooth" });
                                }}
                                className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                                key={'extra-' + cat?.id}
                            >
                                <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                    <div className='px-2 py-[6px] w-full font-bold'>{cat?.nom}</div>
                                    <div className='px-2 py-[6px] text-sm text-gray-400'>{finalNumberTMP} vídeos / {formatNumbersToPrint(finalMinsTMP, 2)}m / {catFinalPrice} €</div>
                                </div>
                                <p className={`px-2 pb-1 text-sm ${(billOption?.module && billOption?.module !== 'extra')? '' : 'opacity-0'}`}>
                                    {subCatNames}.
                                </p>
                            </div>
                        : null
                    })}



                    {(conceptsCorrected?.length)? 
                        <div
                            onClick={()=> {
                                var element = document.getElementById('corrections');
                                window.scrollTo({ top: (element.getBoundingClientRect().top + window.pageYOffset - 95), behavior: "smooth" });
                            }}
                            className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                            key="correction"
                        >
                            <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                <div className='px-2 py-[6px] w-full font-bold'>Categorías corregidas</div>
                                <div className='px-2 py-[6px] text-sm text-gray-400'>{conceptsCorrected?.length} vídeos / {formatNumbersToPrint(conceptsCorrected?.map((el) => el?.preu).reduce((partialSum, a) => parseFloat(partialSum) + parseFloat(a), 0), 2)} €</div>
                            </div>
                            <p className='px-2 pb-1 text-sm'>
                                Se incluyen vídeos que fueron facturados con la categoría de vídeo errónea, sumando o restando la diferencia de precio a modo de corrección.
                            </p>
                        </div>
                    : null }



                    {billExtras?.map(extra => {
                        return <div 
                            className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                            key={'extra-2-' + extra?.id}
                        >
                            <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                <div className='px-2 py-[6px] w-full font-bold'>{(extra?.title)? remove_tags(extra?.title, true) : 'Sin título'}</div>
                                <div className='px-2 py-[6px] text-sm text-gray-400'>{formatNumbersToPrint(((extra?.price / 100) * (extra?.units / 100)), 2)} €</div>
                            </div>
                            <p
                                className='px-2 pb-1 text-sm text-gray-700'
                                dangerouslySetInnerHTML={{__html: (extra?.description)? extra?.description : '' }}
                            ></p>
                        </div>
                    })}           
                    

                    <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                        <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                            <p>Base imponible:</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(bill?.total_alias) / 1.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>IVA (21%):</p>
                            <p>
                                {formatNumbersToPrint((parseFloat(bill?.total_alias) / 1.21) * 0.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>Importe total:</p>
                            <p>
                                {formatNumbersToPrint(bill?.total_alias, 2)} €
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>






        <p className='w-full pt-8 flex justify-between items-center space-x-8 text-xs text-gray-500'>Tablas de leyendas:</p>
        <div className={`border border-gray-300 rounded overflow-hidden bg-gray-300`}>
            <div onClick={() => { setShowLegend(!showLegend) }} className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}>
                <p className='text-lg font-bold px-2'>Leyenda</p>
                {(tableOpen)? upArrowIcon : downArrowIcon}
            </div>

            <Collapse orientation="vertical" in={showLegend} timeout={1000}>
                <div className='p-4 text-xs bg-gray-50'>
                    <div className='w-full flex items-start space-x-4'> 
                        {(selectedLegend?.length)?
                            selectedLegend?.map((selectedTable, key) => {
                                return <div key={`start-table-${key}`} className='w-full border border-gray-300 rounded overflow-hidden'>
                                    <table className='w-full font-normal text-left bg-gray-100'>
                                        <thead className='bg-gray-200'>
                                            <tr>
                                                {selectedTable?.title?.map(element => {
                                                    return <th key={`start-table-title-${element}`} className='px-2 py-[6px]'>{element} </th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedTable?.table?.map(element => {
                                                var pass = true;
                                                if (!key) {
                                                    const list = cats?.filter((cat) => !parseInt(cat?.pare_id));
                                                    const cat = (list?.length)? list?.filter((el) => el?.nom?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() === element?.title?.replaceAll(' ', '').normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase() )[0]  : null
                                                    const finalPriceTMP = (parseInt(cat?.id))? cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => factura10Bill?.concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => parseFloat(row?.preu) ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0) : null
                                                    pass = (cat?.id && finalPriceTMP)? true : false;
                                                }

                                                return (pass)? 
                                                    <tr key={`start-table-row-${element?.title}`}>
                                                        <td className='px-2 py-[6px] border'>{element?.title}</td>
                                                        <td className='px-2 py-[6px] border border-r-0'>{element?.price}</td>
                                                    </tr>
                                                : null
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            })
                        : null }
                    </div>

                    <div className='w-full pt-6 pb-2 h-[15px] flex space-x-3 opacity-60'>
                        <div className='flex items-center'> {euroIcon} <p>Ya facturado</p> </div>
                        <div className='flex items-center'> <div className='scale-[0.8]'>{notTimeIcon}</div> <p>Sin novedades</p> </div>
                        <div className='flex items-center space-x-1 pl-1'> <p>---</p> <p>No facturado</p> </div>
                    </div>
                </div>
            </Collapse>
        </div>










        {(concepts?.length || conceptsCorrected?.length)?
            <>
                <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>

                
                {(concepts?.length)? <>
                    <div className={`border border-gray-300 rounded bg-gray-300`}>
                        <div 
                            className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                            onClick={() => { setTableOpen(!tableOpen) }}
                        >
                            <p className='text-lg font-bold px-2'>Listado por vídeo</p>
                            {(tableOpen)? upArrowIcon : downArrowIcon}
                        </div>

                        <Collapse orientation="vertical" in={tableOpen} timeout={1000}>
                            <div className='border-t border-gray-300'>
                                <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                    <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                        <Table>

                                            <TableHead>
                                                <TableRow className={`${(bill_token || !tableOpen)? 'table-stiky-0' : 'table-stiky'}`}>
                                                    {deff_columns.map((column) => (
                                                        <TableCell
                                                            key={'col-'+column.id}
                                                            className='select-none'
                                                            align={column.align}
                                                            style={{ 
                                                                minWidth: column.minWidth,
                                                                width: column.width,
                                                                backgroundColor: 'inherit',
                                                                padding: 8,
                                                            }}
                                                        >
                                                            <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                                <div> {column.label} </div>
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {

                                                    var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                                    return (finalPriceTMP)? <>
                                                        <TableRow 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="w-full bg-blue-100 duration-200"
                                                            id={'cat-'+cat?.id}
                                                            key={'row-e-'+cat?.id}
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='font-bold w-5 whitespace-nowrap'>{cat?.nom}</p>
                                                            </TableCell>

                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        </TableRow>

                                                        {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                            const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));

                                                            return (vids?.length)? <>
                                                                {(billOption?.module && billOption?.module !== 'extra' && (cat?.nom !== 'Otros' || subCat?.nom !== 'Otro...'))?
                                                                    <TableRow 
                                                                        hover 
                                                                        role="checkbox" 
                                                                        tabIndex={-1} 
                                                                        className="w-full bg-blue-50 duration-200"
                                                                        key={'row-sub-'+subCat?.id}
                                                                    >
                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                            <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                                        </TableCell>

                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                    </TableRow>
                                                                : null }

                                                                {vids?.map((row) => {
                                                                    return (
                                                                        <TableRow 
                                                                            key={'row-f-'+row.id} 
                                                                            hover 
                                                                            role="checkbox" 
                                                                            tabIndex={-1} 
                                                                            className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                                        >
                                                                            {deff_columns.map((column) => {
                                                                                var value = (row[column.id])? row[column.id] : '---';
                                                                                value = processBaseTableFunc(value, column, row, false)

                                                                                return (
                                                                                    <TableCell
                                                                                        key={'column-data-'+column.id}
                                                                                        align={column.align}
                                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                                        className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                                    >
                                                                                        {value}
                                                                                    </TableCell>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </> : null
                                                        })}
                                                    </> : null
                                                })}




                                                <TableRow className='bg-gray-300 bg-opacity-20'>
                                                    {deff_columns.map((column, colKey) => {
                                                        var priceTMP = (colKey)? 0 : 'Total';

                                                        if (priceTMP === 0) {
                                                            const conceptsTMP = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )
                                                            for (let row of conceptsTMP) {
                                                                var value = (row[column.id])? row[column.id] : '---';
                                                                const priceTMPFUNC = processBaseTableFunc(value, column, row, true);
                                                                if ((parseFloat(priceTMP) || parseFloat(priceTMP) === 0) && (parseFloat(priceTMPFUNC) || parseFloat(priceTMPFUNC) === 0)) {
                                                                    priceTMP = priceTMP + priceTMPFUNC
                                                                } else {
                                                                    priceTMP = priceTMPFUNC;
                                                                }
                                                            }
                                                        }

                                                        if (column.id === 'minutes') {
                                                            priceTMP = (parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + 'm' : '---'
                                                        }

                                                        if (column.id === 'minutes_end') {
                                                            priceTMP = (parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + 'm' : '---'
                                                        }

                                                        return (
                                                            <TableCell
                                                                key={'column-data-'+column.id}
                                                                align={column.align}
                                                                style={{ borderBottom: 0, borderTop: '1px solid rgba(210, 210, 210, 1)', padding: 8, fontSize: 14 }}
                                                            >
                                                                <p
                                                                    className={`text-sm whitespace-nowrap
                                                                        ${
                                                                            (!priceTMP || priceTMP === '---')? 'opacity-30' 
                                                                            : ((column.id === 'concepte' || column.id === 'preu')? 'font-bold' : 'opacity-50')
                                                                        }
                                                                    `}  
                                                                >
                                                                    {(!isNaN(priceTMP) && parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + ' €' : (priceTMP && priceTMP !== '---')? priceTMP : ''}
                                                                </p>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>




                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Collapse>
                    </div>


                </> : null }


                {(conceptsCorrected?.length)?
                    <div id="corrections" className={`border border-gray-300 rounded bg-gray-300`}>
                        <div 
                            className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                            onClick={() => { setTableCorrOpen(!tableCorrOpen) }}
                        >
                            <p className='text-lg font-bold px-2'>Listado por vídeo con categorías corregidas</p>
                            {(tableCorrOpen)? upArrowIcon : downArrowIcon}
                        </div>

                        <Collapse orientation="vertical" in={tableCorrOpen} timeout={1000}>
                            <div className='border-t border-gray-300'>
                                <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                    <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                        <Table>

                                            <TableHead>
                                                <TableRow className={`bg-[#e7e8eb]`}>
                                                    {deff_columns_cats.map((column) => (
                                                        <TableCell
                                                            key={'col-'+column.id}
                                                            className='select-none'
                                                            align={column.align}
                                                            style={{ 
                                                                minWidth: column.minWidth,
                                                                width: column.width,
                                                                backgroundColor: 'inherit',
                                                                padding: 8,
                                                            }}
                                                        >
                                                            <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                                <div> {column.label} </div>
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {conceptsCorrected?.map((row) => {
                                                    return (
                                                        <TableRow 
                                                            key={'row-a-'+row.id} 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                        >
                                                            {deff_columns_cats.map((column) => {
                                                                var value = (row[column.id])? row[column.id] : '---';
                                                                const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                                var videoCat = cats?.filter((element) => parseInt(element?.id) === parseInt(row['document_concepte_categoria_id']));
                                                                videoCat = (videoCat[0])? cats?.filter((element) => parseInt(element?.id) === parseInt(videoCat[0]['pare_id']))[0] : null;


                                                                if (column?.id === 'concepte') {
                                                                    value = <div className='flex space-x-2'>
                                                                        <RowButtons
                                                                            row={row}
                                                                            factura10Bill={factura10Bill}
                                                                            billOption={billOption}
                                                                            hiddenItems={hiddenItems}
                                                                            setHiddenItems={setHiddenItems}
                                                                        />
                                                                        
                                                                        {(row['link'])? 
                                                                            <a href={row['link']} target="_blank">{value}</a> 
                                                                            : <p>{value}</p>
                                                                        }
                                                                    </div>
                                                                }




                                                                if (column?.id === 'old_cat_name') {
                                                                    var nomTMP = (escapeUnicode(marksJson?.old_category_name)?.split(' / ')?.length > 1)? escapeUnicode(marksJson?.old_category_name)?.split(' / ')[1] : escapeUnicode(marksJson?.old_category_name);
                                                                    value = (nomTMP)? 
                                                                        <Tooltip title={(marksJson?.old_category_name?.includes(' / '))? escapeUnicode(marksJson?.old_category_name) : ''} placement="bottom" arrow>
                                                                            <p className='hover:bg-gray-300 rounded py-[2px] px-1 text-xs truncate max-w-[150px] duration-200'>{nomTMP}</p>
                                                                        </Tooltip>
                                                                    : '---'
                                                                }

                                                                if (column?.id === 'old_cat_price') {
                                                                    value = (marksJson?.old_category_price)? formatNumbersToPrint(parseFloat(marksJson?.old_category_price), 2) + ' €'  : '---'
                                                                }


                                                                if (column?.id === 'new_cat_name') {
                                                                    var nomTMP = (escapeUnicode(videoCat?.nom)?.split(' / ')?.length > 1)? escapeUnicode(videoCat?.nom)?.split(' / ')[1] : escapeUnicode(videoCat?.nom);
                                                                    value = (nomTMP)? 
                                                                        <Tooltip title={(marksJson?.new_category_name?.includes(' / '))? escapeUnicode(marksJson?.new_category_name) : ''} placement="bottom" arrow>
                                                                            <p className='hover:bg-gray-300 rounded py-[2px] px-1 text-xs truncate max-w-[150px] duration-200'>{nomTMP}</p>
                                                                        </Tooltip>
                                                                    : '---'
                                                                }

                                                                if (column?.id === 'new_cat_price') {
                                                                    var oldPriceTMP = ((marksJson?.old_category_price)? parseFloat(marksJson?.old_category_price) : 0) + parseFloat(marksJson?.diff_cat_price)
                                                                    value = (oldPriceTMP)? formatNumbersToPrint(parseFloat(oldPriceTMP), 2) + ' €'  : '---'
                                                                }
                

                                                                if (column?.id === 'preu') {
                                                                    value = ((parseFloat(marksJson?.diff_cat_price) > 0)? ' + ' : ' - ') + formatNumbersToPrint(parseFloat(marksJson?.diff_cat_price), 2) + ' €';
                                                                }
                                                                

                                                                return (
                                                                    <TableCell
                                                                        key={'column-data-'+column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Collapse>
                    </div>
                : null }
            </>
        : null }



        {(billExtras?.filter((el) => el?.childs?.length)?.length)?
            billExtras?.filter((el) => el?.childs?.length)?.map(element => {
                return <PersExtraItems 
                    element={element}
                    billOption={billOption}
                />
            })
        : null }





        {((concepts?.length || conceptsCorrected?.length) && currentUser && !currentUser?.rol?.includes('user'))? <>
            {(billOption?.module && billOption?.module !== 'extra')? 
                <div className={`border border-gray-300 rounded bg-gray-300`}>
                    <div 
                        className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                        onClick={() => { setTableXProfessorOpen(!tableXProfessorOpen) }}
                    >
                        <p className='text-lg font-bold px-2'>Listado por profesor</p>
                        {(tableXProfessorOpen)? upArrowIcon : downArrowIcon}
                    </div>

                    <Collapse orientation="vertical" in={tableXProfessorOpen} timeout={1000}>
                        <div className='border-t border-gray-300'>
                            <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                    <Table>

                                        <TableHead>
                                            <TableRow className={`bg-[#e7e8eb]`}>
                                                {deff_columns.map((column) => (
                                                    <TableCell
                                                        key={'col-'+column.id}
                                                        className='select-none'
                                                        align={column.align}
                                                        style={{ 
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            backgroundColor: 'inherit',
                                                            padding: 8,
                                                        }}
                                                    >
                                                        <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                            <div>{(column.id === 'concepte')? 'Profesores' : column.label}</div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                                var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                                return (finalPriceTMP)? <>
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        className="w-full bg-blue-100 duration-200"
                                                        id={'cat-'+cat?.id}
                                                        key={'row-b-'+cat?.id}
                                                    >
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='font-bold w-full whitespace-nowrap'>{cat?.nom}</p>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    </TableRow>

                                                    {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                        
                                                        const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                        var info = {}

                                                        for (var i in vids) {
                                                            const row = vids[i]
                                                            const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                            const isLastVideo = ((vids?.length-1) === parseInt(i))? true : false

                                                            for (var column of deff_columns) {
                                                                if (!info[column?.id]) { info[column?.id] = {} }
                                                                if (!info[column?.id]?.value && info[column?.id]?.value !== 0) { info[column?.id].value = 0 }
                                                                if (!info[column?.id]?.id && column.id) { info[column?.id].id = column.id }
                                                                if (!info[column?.id]?.align && column.align) { info[column?.id].align = column.align }

                                                                if (column?.id === 'new') {
                                                                    if (!info[column?.id]?.value) { info[column?.id].value = doneIcon }
                                                                    if (marksJson?.min) { info[column?.id].value = notDoneIcon }
                                                                }

                                                                if (column?.id === 'minutes') {
                                                                    info[column?.id].value = info[column?.id].value + ((parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : 0)
                                                                    if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? '+ ' + formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + 'm' : '---' }
                                                                }

                                                                if (column?.id === 'minutes_end') {
                                                                    info[column?.id].value = info[column?.id].value + ((parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0)
                                                                    if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + 'm' : '---' }
                                                                }

                                                                if (column?.id === 'minutes_price') {
                                                                    if (isLastVideo) { 
                                                                        info[column?.id].value = info[column?.id].value + ((parseFloat(marksJson?.preu_unitat))? parseFloat(marksJson?.preu_unitat) : 0)
                                                                        info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + 'm' : '---' 
                                                                    }
                                                                }

                                                                if (column?.id === 'minutes_total') {
                                                                    info[column?.id].value = info[column?.id].value + ((parseFloat(marksJson?.min_facturable) && parseFloat(marksJson?.preu_unitat))? (parseFloat(marksJson?.min_facturable) * parseFloat(marksJson?.preu_unitat)) : 0)
                                                                    if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' € / m' : '---' }
                                                                }

                                                                if (column?.id === 'preu') {
                                                                    info[column?.id].value = info[column?.id].value + ((parseFloat(row[column.id]))? parseFloat(row[column.id]) : 0)
                                                                    if (isLastVideo) { info[column?.id].value = (parseFloat(info[column?.id].value))? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---' }
                                                                }
                                                            }
                                                        }


                                                        return (vids?.length && info && Object?.keys(info)?.length)? <>
                                                            <TableRow 
                                                                key={'prodessor-' + column?.id} 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                            >
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                    <p className='w-full'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                                </TableCell>

                                                                {Object?.keys(info)?.map((valKey) => {
                                                                    const column = info[valKey]
                                                                    return (valKey !== 'concepte')? 
                                                                        <TableCell
                                                                            key={'column-data-'+column.id}
                                                                            align={column.align}
                                                                            style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                            className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                        >
                                                                            {(column?.value)? column?.value : '---'}
                                                                        </TableCell>
                                                                    : null 
                                                                })}
                                                            </TableRow>
                                                        </> : null
                                                    })}
                                                </> : null
                                            })}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </Collapse>
                </div>
            : null }



            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setTableXTopicOpen(!tableXTopicOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Listado por tema</p>
                    {(tableXTopicOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={tableXTopicOpen} timeout={1000}>
                    <div className='border-t border-gray-300'>
                        <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                            <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                <Table>

                                    <TableHead>
                                        <TableRow className={`bg-[#e7e8eb]`}>
                                            {deff_columns?.map((column) => (
                                                <TableCell
                                                    key={'col-'+column.id}
                                                    className='select-none'
                                                    align={column.align}
                                                    style={{ 
                                                        minWidth: column.minWidth,
                                                        width: column.width,
                                                        backgroundColor: 'inherit',
                                                        padding: 8,
                                                    }}
                                                >
                                                    <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                        <div>{(column.id === 'concepte')? 'Profesores' : column.label}</div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                            const finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                                            const subCats = {};
                                            

                                            return (finalPriceTMP)? <>
                                                <TableRow 
                                                    hover 
                                                    role="checkbox" 
                                                    tabIndex={-1} 
                                                    className="w-full bg-blue-100 duration-200"
                                                    id={'cat-'+cat?.id}
                                                    key={'row-'+cat?.id}
                                                >
                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                        <p className='font-bold w-full whitespace-nowrap'>{cat?.nom}</p>
                                                    </TableCell>

                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                    <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                </TableRow>

                                                {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                    const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                    var info = {}


                                                    for (var i in vids) {
                                                        const row = vids[i]
                                                        const nameKey = (row?.concepte?.split('-')?.length)? row?.concepte?.split('-')[0] : row?.concepte
                                                        const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                        const isLastVideo = (!vids[parseInt(i)+1] || !vids[parseInt(i)+1]?.concepte?.includes(nameKey))? true : false

    
                                                        for (var column of deff_columns) {
                                                            if (!info[nameKey]) { info[nameKey] = {} }
                                                            if (!info[nameKey][column?.id]) { info[nameKey][column?.id] = {} }
                                                            if (!info[nameKey][column?.id]?.value && info[nameKey][column?.id]?.value !== 0) { info[nameKey][column?.id].value = 0 }
                                                            if (!info[nameKey][column?.id]?.id && column.id) { info[nameKey][column?.id].id = column.id }
                                                            if (!info[nameKey][column?.id]?.align && column.align) { info[nameKey][column?.id].align = column.align }

                                                            if (column?.id === 'concepte') {
                                                                if (!info[nameKey][column?.id]?.value) { info[nameKey][column?.id].value = nameKey }
                                                            }

                                                            if (column?.id === 'new') {
                                                                if (!info[nameKey][column?.id]?.value) { info[nameKey][column?.id].value = doneIcon }
                                                                if (marksJson?.min) { info[nameKey][column?.id].value = notDoneIcon }
                                                            }

                                                            if (column?.id === 'minutes') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? '+ ' + formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + 'm' : '---' }
                                                            }

                                                            if (column?.id === 'minutes_end') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + 'm' : '---' }
                                                            }

                                                            if (column?.id === 'minutes_price') {
                                                                info[nameKey][column?.id].value = (parseFloat(marksJson?.preu_unitat))? formatNumbersToPrint(parseFloat(marksJson?.preu_unitat), 2) + 'm' : '---' 
                                                            }

                                                            if (column?.id === 'minutes_total') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(marksJson?.min_facturable) && parseFloat(marksJson?.preu_unitat))? (parseFloat(marksJson?.min_facturable) * parseFloat(marksJson?.preu_unitat)) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' € / m' : '---' }
                                                            }

                                                            if (column?.id === 'preu') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(row[column.id]))? parseFloat(row[column.id]) : 0)
                                                                if (isLastVideo) { info[nameKey][column?.id].value = (parseFloat(info[nameKey][column?.id].value))? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---' }
                                                            }
                                                        }
                                                    }


                                                    return (vids?.length && info && Object.keys(info)?.length)? <>
                                                        {(billOption?.module && billOption?.module !== 'extra')? 
                                                            <TableRow 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="w-full bg-blue-50 duration-200"
                                                                key={'row-c-'+subCat?.id}
                                                            >
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                    <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                                </TableCell>

                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                                <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            </TableRow>
                                                        : null }

                                                        {Object.keys(info)?.map((topicKey) => {
                                                            const video = info[topicKey]



                                                            return <TableRow 
                                                                key={'row-d-'+topicKey} 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                            >
                                                                {Object.keys(video).map((videoKey) => {
                                                                    var column = (video[videoKey])? video[videoKey] : '---';
                                                                    return <TableCell
                                                                        key={'column-data-'+column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {(column?.value)? column?.value : '---'}
                                                                    </TableCell>
                                                                })}
                                                            </TableRow>

                                                        })}
                                                    </> : null
                                                })}
                                            </> : null
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Collapse>
            </div>
        </> : null }



    </div>

  ) : 

    <div className='absolute z-10 w-full h-full'>
        <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>

}