import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { useLazyQuery, useMutation } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext';
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import GetCustomerSettings from '../graphql/queries/GetCustomerSettings'
import CreateOrUpdateCustomerSetting from './../graphql/mutations/CreateOrUpdateCustomerSetting'

import CustomerSettingsListItem from './CustomerSettingsListItem';

import InputBase from '@mui/material/InputBase';
import Collapse from '@mui/material/Collapse';
import RemoveCustomerSetting from '../graphql/mutations/RemoveCustomerSetting';






export default function CustomerSettingsList({ customer, type, customerSettings, setCustomerSettings }) {
  

  const { currentUser } = useContext(AuthContext)
  const { setLoadingText } = useContext(LayoutContext)



  const [open, setOpen] = useState(true);
  const [groups, setGroups] = useState(null);
  const [baseLoading, setBaseLoading] = useState(true);

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;

  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;



  const [createOrUpdateCustomerSetting] = useMutation(CreateOrUpdateCustomerSetting)
  const [removeCustomerSetting] = useMutation(RemoveCustomerSetting)

  const [getCustomerSettings, { data: dataCustomerSettings, loading: loadingCustomerSettings }] = useLazyQuery(GetCustomerSettings, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: customer?.token,
      type: type,
    }
  })





  useEffect(() => {
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false);
      }, 300 )
    }
  }, [baseLoading])


  useEffect(() => {
    if (customer?.token && type) {
      getCustomerSettings();
    }
  }, [customer?.token, type])


  useEffect(() => {
    setCustomerSettings((dataCustomerSettings?.customerSettings?.length)? dataCustomerSettings?.customerSettings : null)
  }, [dataCustomerSettings])


  useEffect(() => {
    if (customerSettings?.length)  {
      var groupsTMP = [...customerSettings?.map((el) => el?.setting_group), null];
      groupsTMP = groupsTMP.filter((item, index) => groupsTMP.indexOf(item) === index);
      setGroups(groupsTMP?.sort((a, b) => (a < b)? -1 : (a > b)? 1 : 0 ))
    } else if (customerSettings?.length === 0) {
      setGroups([null])
    }
  }, [customerSettings])







  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !customerSettings?.length) { return; }
    const rearrangedArray = rearangeArr(customerSettings, source, destination);
    setCustomerSettings(rearrangedArray);
  };

  const rearangeArr = (arr, source, destination) => {
    const arrCopy = [...arr];

    var groupTMP = (!destination.droppableId || destination.droppableId === 'Otros')? null : destination.droppableId
    if (arrCopy[source.index]?.setting_group !== groupTMP) {
      arrCopy[source.index] = {...arrCopy[source.index], setting_group: groupTMP}
      if (source.index < destination.index && destination.index) { destination.index = destination.index - 1 }
    }

    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);

    return arrCopy?.map((el, key) => { return {...el, position: key} });
  };










  function checkChangesFunc(item) {
    if (customerSettings?.length) {
      setCustomerSettings(
        [...customerSettings]?.map((customerSetting) => {
          var customerSettingTMP = {...customerSetting};
          if (customerSettingTMP?.id === item?.id) { 
            customerSettingTMP = item; 
          }
          return customerSettingTMP;
        })
      );
    }
  }


  async function addCheckBoxFunc() {
    setLoadingText('Cargando...')

    var itemTMP = {
      'own_user_token': currentUser?.token,
      'customer_token': customer?.token,
      'position': (customerSettings?.length)? customerSettings?.length + 1 : 1,
      'type': 'video_checkbox',
      'title': 'Nueva checkbox',
      'small_title': null,
      'states': '✅,✏️,🚨,🔥',
      'token': null,
    };


    var newCheck = await createOrUpdateCustomerSetting({variables: itemTMP});
    newCheck = newCheck?.data?.createOrUpdateCustomerSetting;
    if (newCheck?.id) { (customerSettings?.length)? setCustomerSettings([...customerSettings, newCheck]) : setCustomerSettings([newCheck]) }
    
    setTimeout( async () => {
      setLoadingText(null)
    }, "500")
  }


  async function addGroupFunc() {
    var groupsTMP = (groups?.length)? [...groups, (groups?.length) + ' - Nuevo grupo'] : ['1 - Nuevo grupo']
    setGroups(groupsTMP)
  }


  async function removeItemFunc(id) {
    await removeCustomerSetting({variables: { 
      'own_user_token': currentUser?.token,
      'id': id,
    }});

    setCustomerSettings(customerSettings?.filter((el) => el?.id !== id))
  }



  













  return <div className='w-full bg-gray-100 rounded'>

    <div className={`flex justify-between items-center space-x-2 p-1 border border-gray-300 bg-gray-200 ${(open)? ' rounded-t' : 'rounded'}`}>
      <p className='text-sm text-gray-600  pl-[2px]'>Tareas de los vídeos</p>

      <div className='flex space-x-2'>
        {(open) && <>
          <button onClick={addGroupFunc} className='flex items-center p-[2px] pr-2 text-sm hover:text-white bg-gray-50 border border-gray-300 hover:bg-blue-700 active:bg-blue-400 rounded duration-200'>
            {addIcon}
            <p>Grupo</p>
          </button>

          {(currentUser?.rol === 'admin')?
            <button onClick={addCheckBoxFunc} className='flex items-center p-[2px] pr-2 text-sm hover:text-white bg-gray-50 border border-gray-300 hover:bg-blue-700 active:bg-blue-400 rounded duration-200'>
              {addIcon}
              <p>Checkbox</p>
            </button>
          : null }
        </>}


        {(groups?.length || customerSettings?.length)?
          <button disabled={!(!loadingCustomerSettings && groups?.length && customerSettings?.length)} onClick={() => setOpen(!open)} className='flex items-center p-[2px] text-sm hover:text-white bg-gray-50 border border-gray-300 hover:bg-blue-700 active:bg-blue-400 rounded duration-200'>
            {(open)? upArrowIcon : downArrowIcon}
          </button>
        : null }
      </div>
    </div>




    {(!loadingCustomerSettings && !baseLoading && groups?.length && customerSettings?.length)?
      <Collapse orientation="vertical" in={open} timeout={300} className='rounded'>
        <div className="p-2 pt-0 min-h-[400px] max-h-[calc(100vh-800px)] overflow-auto border border-t-0 rounded-b border-gray-300">
          <DragDropContext onDragEnd={onDragEnd}>
            {groups?.map((group, key) => {

              var groupNameSplit = (group?.split(' - ')?.length > 1)? group?.split(' - ') : [(key+1), group];
              var groupNumber = (groupNameSplit?.length > 1)? groupNameSplit[0] : (key+1);
              var groupName = (groupNameSplit?.length > 1)? groupNameSplit.slice(1).join(' - ') : group;

              var customerSettingsFiltred = customerSettings?.filter((el) => group === el?.setting_group)
              
              return <div key={'group-'+group} className=' pt-2'>

                {(group)?
                  <div className='flex space-x-1 px-1 pb-1'>
                    <InputBase
                      id={'group-number-' + group}
                      className='w-[20px] bg-gray-200 rounded overflow-hidden'
                      sx={{ fontSize: 12 }}
                      defaultValue={groupNumber}
                      inputProps={{ sx: { paddingInline: 0.8, paddingBlock: 0.5 }, className: 'w-[20px] hover:bg-gray-200 focus:bg-gray-300 rounded duration-200' }}
                    />

                    <InputBase
                      id={'group-name-' + group}
                      className='w-full'
                      sx={{ fontSize: 12 }}
                      placeholder="Nombre del grupo"
                      defaultValue={groupName}
                      inputProps={{ maxLength: 100, sx: { paddingInline: 0.8, paddingBlock: 0.5 }, className: 'w-full hover:bg-gray-200 focus:bg-gray-300 rounded duration-200' }}
                    />
                  </div>
                :
                  <label className='text-xs text-gray-500 px-2'>{(group)? group : 'Otros'}</label>
                }


                <Droppable droppableId={(group)? group : 'Otros'}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`w-full p-1 pb-[1px] rounded duration-300 ${(snapshot.isDraggingOver)? 'bg-blue-100' : ''}`}
                    >
                      {(customerSettingsFiltred?.length)?
                        customerSettings?.map((cSetting, index) => {
                          return (group === cSetting?.setting_group)?

                            <CustomerSettingsListItem
                              key={'setting-'+cSetting.id}
                              customer={customer}
                              cSetting={cSetting}
                              index={index}
                              checkChangesFunc={checkChangesFunc}
                              removeItemFunc={removeItemFunc}
                            />

                          : null 
                        })
                      :
                        <p className='w-full text-xs text-gray-500 px-2 opacity-60 bg-white border border-gray-300 p-2 mb-1 rounded'>Sin elementos...</p>
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div> 
            })}
          </DragDropContext>
        </div>
      </Collapse>
    : (loadingCustomerSettings || baseLoading)?
      <div className='py-8 px-6 flex items-center space-x-1 text-sm text-gray-400 border-x border-b border-gray-300 rounded-b'>
        {loadingIcon}
        <p>Cargando...</p>
      </div>
    :
      <div className='py-8 px-6 flex items-center space-x-1 text-sm text-gray-400 border-x border-b border-gray-300 rounded-b'>
        <p>No se han encontrado ajustes.</p>
      </div>
    }

  </div>
}