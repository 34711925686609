import gql from "graphql-tag";
export default gql`
  query Factura10GetAllVideoMarks(
    
    $own_user_token: String!,
    $own_customer_token: String!,
    $state: Int,
    $module: String,
    $type: String,
    $avoid_notes: Boolean,
    $localizations: Boolean,
    $hidde_disabled: Boolean
    $hidde_facturation_disabled: Boolean
  
  ) {
    videoMarksForMotions(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,
      state: $state,
      module: $module,
      type: $type,
      avoid_notes: $avoid_notes,
      localizations: $localizations,
      hidde_disabled: $hidde_disabled
      hidde_facturation_disabled: $hidde_facturation_disabled
      
    ) {
      
      id
      time
      texts
      
      state

      video_id
      user_id
      difficulty_id
      is_translation_of
      trad_done
      revision_state

      disabled
      facturation_disabled
      facturation_force_insertion

      revision_state
      extra_mark_facturation
      extra_facturation_correction
      manual_number_of_words
      new_price
      new_price_observations

      type {
        id
        name
        type
      }

      __typename
    }
  }
`;
