import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';





const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1000,
  },
});





export default function StateChanger({

  type_name,
  object, 
  video,

  saveStateEdit,
  saveRevisionStateEdit,
  saveTradStateEdit,
  
  state,
  stateChangeLabel,
  loadingState,
  motionState,
  motionStateChangeLabel,
  loadingMotionState, 
  overlapStates,

  revisionState,
  revisionStateChangeLabel,
  loadingRevisionState,
  
  tradState,
  tradStateChangeLabel,
  loadingTradState,
  
  small,
  disabled,
  showRevisionState,
  language,

}) {
  




  const { currentUser, currentCustomer, clicksoundCustomer } = useContext(AuthContext);


  const [history, setHistory] = useState(null);
  const [loadingHistory, setLoadingHistory] = useState(0);


  /* Icon vars */
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const motionInsertedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m425-338 187-119q13-8.341 13-23.171Q625-495 612-503L425-622q-14-8-27.5-.471Q384-614.943 384-599v238q0 15.943 13.5 23.471Q411-330 425-338Zm55.276 242Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const motionDoneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82Q156-260 126-330T96-479q0-110 57-201.5T305-821q14-7 28-2t22 19q7 13 1.5 26.5T337-756q-77 41-123 115t-46 163q0 129 91 219.5T480-168q130 0 221-91t91-220q0-89-46-163T623-757q-14-8-19.5-21.5T605-805q8-14 22-19t28 2q95 49 152 140.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm-36-378v-354q0-15 10.5-25.5T480-864q15 0 25.5 10.5T516-828v354l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-503L505-361q-11 11-25 11t-25-11L313-503q-11-11-10.5-25.5T314-554q11-11 25.5-11t25.5 11l79 80Z"/></svg>
  const motionInProcessIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M483-337q-29 0-56.5-10T378-378q-20-20-31-46.282-11-26.282-11-55.718 0-9.737.8-18.987.8-9.25 3.2-18.013 2-10-1.656-18.823-3.656-8.824-12.594-12.501-9.75-3.676-18.375.513t-11.625 14.243Q292-521 290-507.63q-2 13.369-2 27.63 0 38 14.712 73.416T344-344q28 28 64.5 42t75.5 14l-27 27q-7 7.364-7 17.182Q450-234 457-227q7 7 16.818 7 9.818 0 17.182-7l59.789-59.789Q562-298 562-312.182T551-337l-59-60q-7.364-7-17.182-7Q465-404 458-397q-7 7-7 16.818 0 9.818 7 17.182l25 26Zm-7-287q29.699 0 57.349 10.5Q561-603 582-582q20 20 31 46.282 11 26.282 11 55.718 0 9-.8 18.5T620-443q-2 10 1.5 19t12.587 12q9.913 3 18.897-1.324 8.985-4.325 12.11-14.703Q669-441 670.5-453.5 672-466 672-480q0-38-15-73.5T615-616q-28-28-65-41.5T474-670l29-29q7-7.364 7-17.182Q510-726 503-733q-7-7-16.818-7-9.818 0-17.182 7l-59.789 59.789Q398-662 398-647.818T409-623l60 60q7.364 7 17.182 7Q496-556 503-563q7-7 7-16.818 0-9.818-7-17.182l-27-27Zm4.276 528Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const correctionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-288q75 0 129-49t62-121q1-9-6-15.5t-17-6.5q-10 0-17 6t-9 15q-8 53-47.5 88T480-336q-60 0-102-42t-42-102q0-60 43-102t104-42l-26 27q-7 7-7.5 16.5T457-563q7 7 17 7t17-7l72-72q5-5 5-13t-5-13l-72-72q-7-7-16.5-7.5T457-733q-7 7-7 17t7 17l26 27q-81 0-138 55.5T288-480q0 80 56 136t136 56Zm0 192q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const pendingIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M6 11.25q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q6.521 8.75 6 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q10.521 8.75 10 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q14.521 8.75 14 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"/></svg>;

  const revisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l84 85q11 11 25.5 11t25.5-11l170-170q11-11 11-25t-11-25q-11-11-25.5-11T582-585L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-23 12.5-41t33.5-26l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26t12.5 41v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Z"/></svg>
  const nonRevisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-10.636-11-24.818-11Q339-512 328-501q-11 11-11 25.667 0 14.666 11 25.333l84 85q10.909 11 25.455 11Q452-354 463-365l170-170q11-11 11-25t-11-25q-11-11-25.667-11-14.666 0-25.333 11L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-22.692 12.5-40.846Q193-754 214-762l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26.154T792-695v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Zm0-71q104-32.25 172-129t68-215v-180l-240-92-240 92v180q0 118.25 68 215t172 129Zm0-308Z"/></svg>
  const historyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-144q-132 0-226.5-87.5T146-447q-2-14 8.5-23.5t25.439-9.5Q194-480 205-472t13 22q11 99 85.5 166.5T480-216q109 0 186.5-77.5T744-480q0-109-77.5-186.5T480-744q-62 0-114.547 25.6Q312.907-692.8 277-648h71q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-597 373.65-586.5 363.3-576 348-576H180q-15.3 0-25.65-10.35Q144-596.7 144-612v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195-816 205.5-805.65 216-795.3 216-780v94q46-60 114.5-95T480-816q70 0 131.133 26.6 61.134 26.6 106.4 71.867 45.267 45.266 71.867 106.4Q816-550 816-480t-26.6 131.133q-26.6 61.134-71.867 106.4-45.266 45.267-106.4 71.867Q550-144 480-144Zm36-366 90 90q11 11 10.5 25T605-370q-11 11-25.5 11T554-370l-98.712-98.712Q450-474 447-480.689t-3-13.825v-141.81q0-15.163 10.5-25.419Q465-672 480-672t25.5 10.35Q516-651.3 516-636v126Z"/></svg>
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;

  const billIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M240-96q-46 0-71-24.5T144-192v-108q0-15 10.5-25.5T180-336h60v-506q0-6 5-9t10 2l30 30q5 5 13 5t13-5l31-32q5-5 13-5t13 5l32 32q5 5 13 5t13-5l31-32q5-5 13-5t13 5l32 32q5 5 13 5t13-5l32-32q5-5 13-5t13 5l31 32q5 5 13 5t13-5l32-32q5-5 13-5t13 5l31 32q5 5 13 5t13-5l30-30q5-5 10-2t5 9v638q0 47-31 77.5T708-96H240Zm468-72q16 0 26-9.5t10-26.5v-540H312v408h324q15 0 25.5 10.5T672-300v96q0 17 10 26.5t26 9.5ZM396-672h144q15 0 25.5 10.5T576-636q0 15-10.5 25.5T540-600H396q-15 0-25.5-10.5T360-636q0-15 10.5-25.5T396-672Zm0 120h144q15 0 25.5 10.5T576-516q0 15-10.5 25.5T540-480H396q-15 0-25.5-10.5T360-516q0-15 10.5-25.5T396-552Zm264-48q-14 0-25-10.5T624-636q0-15 11-25.5t25-10.5q15 0 25.5 10.5T696-636q0 15-10.5 25.5T660-600Zm0 120q-14 0-25-10.5T624-516q0-15 11-25.5t25-10.5q15 0 25.5 10.5T696-516q0 15-10.5 25.5T660-480ZM240-168h360v-96H216v72q0 17 3.5 20.5T240-168Zm-24 0v-96 96Z"/></svg>









  const isProfe = (currentUser?.professor)? true : false;
  const userRol = currentUser?.rol;

  const freeCustomer = (currentCustomer?.free_customer)? true : false;

  const isBaseUser = (userRol === 'user' || (userRol?.includes('user') && !freeCustomer))? true : false;
  const userIsAdmin = (userRol === 'admin' || userRol === 'facturator')? true : false;
  const userIsEditor = (userIsAdmin || userRol?.includes('editor'))? true : false;
  const userIsTranslator = (userIsAdmin || userRol?.includes('translator'))? true : false;
  const userIsFromClick = (currentUser?.original_customer_token === clicksoundCustomer?.token || (userRol?.includes('user') && freeCustomer))? true : false;

  const motionStateActive = (motionState === 2 || motionState === 3 || motionState === 4);
  const tradStateActive = (revisionState === 2 || tradState);


  const isTranslation = (video?.is_translation_of)? true : false;
  const vidLang = (video?.language)? video?.language : 'esp';
  const videoChyronStates = (overlapStates?.length)? overlapStates : null;
  const professorOverlapState = (type_name === 'user' && videoChyronStates?.length && videoChyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(object?.id) && el?.language === vidLang )?.length)? videoChyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(object?.id) )[0] : null;
  const disableOverlapStates = (type_name === 'user' && !video)? true : false;
  


  const stateLegend = [
    { icon: pendingIcon, text: 'Pendiente', color: 'text-gray-200'},
    { icon: correctionIcon, text: 'Corrección', color: 'text-yellow-300'},
    { icon: motionInProcessIcon, text: 'En creación', color: 'text-orange-300'},
    { icon: motionDoneIcon, text: 'Creado', color: 'text-blue-300'},
    { icon: motionInsertedIcon, text: 'Insertado', color: 'text-purple-300'},
    { icon: doneIcon, text: 'Finalizado', color: 'text-green-300'},

    { icon: <div className='scale-[0.8]'>{tradIcon}</div>, text: 'Traducción eliminada', color: 'text-gray-200'},
    { icon: <div className='scale-[0.8]'>{tradIcon}</div>, text: 'Traducido', color: 'text-blue-300'},
    { icon: nonRevisedIcon, text: 'Revisión eliminada', color: 'text-gray-200'},
    { icon: revisedIcon, text: 'Revisado', color: 'text-blue-300'},

    { icon: correctionIcon, text: 'Corrección revertida', color: 'text-red-300'},

    { icon: billIcon, text: 'Factura ', color: 'text-red-300'},
  ]





  


  /* Get hisroty */
  async function getObjectHistory(type, element_id, video_id) {
    if (type && parseInt(element_id)) {
      setLoadingHistory(true)

      const responseStates = await axios.post(`${process.env.REACT_APP_API_ROUTE}getObjectStateHistory`, { id: element_id, video_id: (type_name === 'user' && video_id)? video_id : null, type: type, language: language })
      const historyStateData = (responseStates?.data?.length)? responseStates?.data : [];

      const responseBills = await axios.post(`${process.env.REACT_APP_API_ROUTE}getObjectBillHistory`, { own_user_token: currentUser?.token, video_id: video?.id })
      const historyBillsData = (responseBills?.data?.length)? responseBills?.data : [];

      const responseF10Bills = (currentCustomer.factura10_client_id && historyBillsData?.length)? await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_review_get_past_bills.php`, { video_name: video?.name, client_id: currentCustomer.factura10_client_id, document_ids: historyBillsData?.map((el) => el?.f10ID) }) : null
      const f10BillsData = (responseF10Bills?.data?.documents?.length)? responseF10Bills?.data?.documents?.map((el) => parseInt(el?.id)) : null;

      const historyFiltredBillsData = (historyBillsData?.length)? historyBillsData?.filter((el) => f10BillsData?.includes(parseInt(el?.f10ID))) : [];
      const historyData = [...historyStateData, ...historyFiltredBillsData]?.filter((el) => el)?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      setHistory((historyData?.length)? historyData : [])
      setLoadingHistory(false)
    }
  }


  if (object && type_name === 'user' && professorOverlapState && parseInt(professorOverlapState[`state`]) && state !== 2) {
    state = parseInt(professorOverlapState[`state`]);
    motionState = parseInt(professorOverlapState[`motion_state`]);

    var professorOverlapStateTMP = JSON.parse(JSON.stringify({...professorOverlapState}));
    delete professorOverlapStateTMP['professor_id']
    delete professorOverlapStateTMP['language']
    object = {...object, ...professorOverlapStateTMP};
  }



















  function getPrioritaryStateFunc(historyItem, stateTMP, motionStateTMP, revStateTMP, tradStateTMP, type) {
    var stateObjTMP = stateLegend[0];
    var clickable = null;


    if (type === 'state_update_video_mark') {

      if (stateTMP === 3) { stateObjTMP = stateLegend[5] }
      else if (motionStateTMP === 4) { stateObjTMP = stateLegend[4] }
      else if (motionStateTMP === 3) { stateObjTMP = stateLegend[3] }
      else if (motionStateTMP === 2) { stateObjTMP = stateLegend[2] }
      else if (stateTMP === 2) { stateObjTMP = stateLegend[1] }
      else if (stateTMP === 1) { stateObjTMP = stateLegend[0] }
  
    } else if (type === 'state_update_video_mark_trad') {
      
      if (tradStateTMP === 0) { stateObjTMP = stateLegend[6] }
      else if (tradStateTMP === 1) { stateObjTMP = stateLegend[7] }
      else if (revStateTMP === 1) { stateObjTMP = stateLegend[8] }
      else if (revStateTMP === 2) { stateObjTMP = stateLegend[9] }

    } else if (type === 'correction_removed') {

      stateObjTMP = stateLegend[10]
      
    } else {

      stateObjTMP = stateLegend[11]
      clickable = '/your-billing/' + currentCustomer.abbreviated_name + '/' + historyItem?.token;

    }

    return <button
      className={`flex items-center pr-1 saturate-200 text-white bg-black bg-opacity-70 rounded ${(clickable)? 'cursor-pointer hover:bg-gray-700 active:bg-black duration-200' : ' cursor-text'}`}
      onClick={() => (clickable)? window.open(clickable, '_blank').focus() : null}
    >
      <span className={`mr-[2px] scale-[0.8] ${stateObjTMP?.color}`}>{stateObjTMP?.icon}</span> {stateObjTMP?.text}
    </button>
  }
  






















  return <div className={`flex space-x-2`}>
    <div className={`flex items-center ${(!small || isBaseUser)? 'space-x-1' : 'space-x-[2px]'} ${!(isProfe || !userIsFromClick)? null : 'pointer-events-none'}`}>
      
      

      {(object && !isBaseUser) && 
        <div className={`flex items-center pl-1 space-x-2 ${(!small || isBaseUser)? 'pr-[2px]' : 'pr-[6px]'}`}>
          {(!disabled && !isBaseUser && showRevisionState) && <>
            {(!video || !isTranslation)?
              <Tooltip disableInteractive title={`${(revisionStateChangeLabel)? ((revisionState === 2)? 'Revisado' : 'Sin revisar') + ' por ' + revisionStateChangeLabel : ((userIsTranslator)? ((tradState === 2)? 'Desmarcar como revisado' : 'Marcar como revisado') : ((tradState === 2)? 'Revisado' : 'Sin revisar'))}`} placement="bottom" arrow>
                <button 
                  className={`
                    group/revised truncate overflow-hidden cursor-pointer text-xs flex items-center duration-200 rounded
                    ${(revisionState === 2)? 'text-blue-500' : 'text-gray-400 hover:text-gray-600'}
                  `}
                  onMouseUp={()=> (userIsTranslator) && saveRevisionStateEdit((revisionState === 2)? 1 : 2)}
                >
                  {(loadingRevisionState)? loadingIcon : ((revisionState === 2)? revisedIcon : nonRevisedIcon)}
                  {(userIsTranslator && !userIsAdmin) && <div className={`duration-200 group-hover/revised:ml-[3px] w-0 group-hover/revised:w-[60px]`}><p>{(revisionState === 2)? 'Revisado' : 'Sin revisar'}</p></div>}
                </button>
              </Tooltip>
            : null }


            {(isTranslation)?
              <Tooltip disableInteractive title={`${(tradStateChangeLabel)? ((tradState)? 'Traducido' : 'Sin traducir') + ' por ' + tradStateChangeLabel : ((userIsTranslator)? ((tradState)? 'Desmarcar como traducido' : 'Marcar como traducido') : ((tradState)? 'Traducido' : 'Sin traducir'))}`} placement="bottom" arrow>
                <button 
                  className={`
                    group/revised truncate overflow-hidden cursor-pointer text-xs flex items-center duration-200 rounded
                    ${(tradState)? 'text-blue-500' : 'text-gray-400 hover:text-gray-600'}
                  `}
                  onMouseUp={()=> (userIsTranslator) && saveTradStateEdit(!tradState)}
                >
                  {(loadingTradState)? loadingIcon : ((tradState)? tradIcon : tradIcon)}
                  {(userIsTranslator && !userIsAdmin) && <div className={`duration-200 group-hover/revised:ml-[3px] w-0 group-hover/revised:w-[60px]`}><p>{(tradState)? 'Traducido' : 'Sin traducir'}</p></div>}
                </button>
              </Tooltip>
            : null }
          </>}


          
          {(object && type_name && !isBaseUser) && 
            <CustomWidthTooltip
              /* disableInteractive */
              id={'tooltip+' + object?.id + '-' + video?.id}
              title={(loadingHistory || history)?
                <div className='pb-1'>
                  <p className='font-bold border-b border-white mb-2 p-1'>Historial de actividad</p>
                  <div className='flex flex-col space-y-[2px] max-h-[210px] overflow-y-auto'>
                    {(loadingHistory)?

                      <div>Cargando historial...</div>

                    : (history?.length)?

                      history?.map(historyItem => {
                        return <div
                          key={`${(historyItem?.mark_state)? 'state' : 'bill'}-${object?.id}-${historyItem?.id}`}
                          className='flex items-center space-x-1 text-gray-300'
                        >
                          {getPrioritaryStateFunc(historyItem, historyItem?.mark_state, historyItem?.mark_motion_state, historyItem?.mark_rev_state, historyItem?.mark_trad_state, historyItem?.type)} 

                          <div className='flex items-center'>
                            <p className='flex space-x-1'>
                              {(historyItem?.mark_state || historyItem?.mark_rev_state || historyItem?.mark_trad_state)? 
                                <p> por <span className='text-white'> {(historyItem?.user_name)? historyItem?.user_name + ' ' : 'Nueva revisión '} </span> </p>
                              : 
                                <p className='text-white truncate max-w-[200px]'> {historyItem?.label} </p>
                              }
                               
                              

                              {(historyItem?.createdAt)? <>
                                <p>
                                  el {new Date(historyItem?.createdAt)?.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' '} 
                                  a las {historyItem?.createdAt?.split('T')[1]?.split(':')[0]}:{historyItem?.createdAt?.split('T')[1]?.split(':')[1] + 'h'}
                                </p>
                              </> : null }
                            </p>
                          </div>
                        </div>
                      })

                    : 
                      <div>Sin historial</div> 
                    }
                  </div>
                </div>
              : null }
              placement="bottom" 
              arrow
            >
              <div className='flex items-center'>
                <button 
                  onMouseEnter={() => getObjectHistory(type_name, (parseInt(object?.id))? parseInt(object?.id) : null, (parseInt(video?.id))? parseInt(video?.id) : null) }
                  className={`group/hystory truncate overflow-hidden text-gray-400 hover:text-gray-600 cursor-pointer text-xs flex justify-center items-center duration-300 rounded
                    ${(loadingHistory)? 'animate-one-spin' : ''}
                  `}
                >
                  {historyIcon}
                </button>
              </div>
            </CustomWidthTooltip>
          }
        </div>
      }







      {(object && !disabled)? <>
        

        {(!disableOverlapStates || parseInt(currentUser?.id) === 3)?
          <Tooltip disableInteractive title={`${(stateChangeLabel && !isBaseUser && state === 3)? ((stateChangeLabel?.includes('nueva revisión'))? '' : 'Finalizado por ') + stateChangeLabel : (userIsEditor)? 'Marcar como finalizado' : '' }`} placement="bottom" arrow>
            <button
              className={`
                truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-green-600 hover:opacity-100 duration-300
                ${(state === 3)? 'text-green-600' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'text-gray-400 opacity-60'}
                ${(disableOverlapStates)? 'opacity-50 pointer-events-none' : ''}
              `} 
              onMouseUp={() => (state !== 3 && userIsEditor && !disableOverlapStates) && saveStateEdit(3, 5, false)}
            >
              <div className='mr-[3px]'>{(loadingState === 3)? loadingIcon : doneIcon}</div>
              {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>Finalizado</p>}
            </button>
          </Tooltip>
        : null }



        {(!isBaseUser) && <>
          {(((!disableOverlapStates || parseInt(currentUser?.id) === 3)) && (userIsEditor || (state === 3 && (motionState <= 4 && motionState >= 2)))) && 
            <div className='opacity-30 px-1 pointer-events-none'>
              /
            </div>
          }



          {(!disableOverlapStates || parseInt(currentUser?.id) === 3)?
            <Tooltip disableInteractive title={`${(motionStateChangeLabel && motionState === 4)? 'Insertado por ' + motionStateChangeLabel : (userIsEditor)? 'Marcar como insertado' : '' }`} placement="bottom" arrow>
              <button 
                className={`
                  truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-purple-500 hover:opacity-100 duration-300
                  ${(motionState === 4)? 'text-purple-500' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'text-gray-400 opacity-60'}
                  ${(disableOverlapStates)? 'opacity-50 pointer-events-none' : ''}
                `}
                onMouseUp={() => (userIsEditor && !disableOverlapStates) && saveStateEdit(state, 4, true)}
              >
                <div className='mr-[3px]'>{(loadingMotionState === 4)? loadingIcon : motionInsertedIcon}</div>
                {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>Insertado</p>}
              </button>
            </Tooltip>
          : null }



          <Tooltip disableInteractive title={`${(motionStateChangeLabel && motionState === 3)? 'Creado por ' + motionStateChangeLabel : (userIsEditor)? 'Marcar como creado' : '' }`} placement="bottom" arrow>
            <button
              className={`
                truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-blue-500 hover:opacity-100 duration-300
                ${(motionState === 3)? 'text-blue-500' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'text-gray-400 opacity-60'} 
              `}
              onMouseUp={() => (userIsEditor) && saveStateEdit(state, 3, true)}
            >
              <div className='mr-[3px]'>{(loadingMotionState === 3)? loadingIcon : motionDoneIcon}</div>
              {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>Creado</p>}
            </button>
          </Tooltip>



          <Tooltip disableInteractive title={`${(motionStateChangeLabel && motionState === 2)? 'En creación por ' + motionStateChangeLabel : (userIsEditor)? 'Marcar como en creación' : '' }`} placement="bottom" arrow>
            <button
              className={`
                truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-orange-500 hover:opacity-100 duration-300
                ${(motionState === 2)? 'text-orange-500' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'text-gray-400 opacity-60'}
              `}
              onMouseUp={() => (userIsEditor) && saveStateEdit(state, 2, true)}
            >
              <div className='mr-[3px]'>{(loadingMotionState === 2)? loadingIcon : motionInProcessIcon}</div>
              {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>En creación</p>}
            </button>
          </Tooltip>



          {(userIsEditor || (state === 1 && (motionStateActive || tradStateActive))) && 
            <div className='opacity-30 px-1 pointer-events-none'>
              /
            </div>
          }
        </>}



        {(isBaseUser && motionState !== 5 && (motionState !== 1 || tradState || revisionState === 2)) &&
          <Tooltip disableInteractive title={`${(stateChangeLabel && !isBaseUser && (motionStateActive || tradStateActive))? 'En proceso de creación por ' + stateChangeLabel : (userIsEditor)? 'En proceso de creación' : '' }`} placement="bottom" arrow>
            <button
              className={`
                truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-gray-400 hover:opacity-100
                ${(motionStateActive || tradStateActive)? 'text-gray-400' : 'hidden text-gray-400 opacity-60'}
              `}
            >
              <div className='mr-[3px]'>{motionInProcessIcon}</div>
              <p>En creación...</p>
            </button>
          </Tooltip>
        }



        <Tooltip disableInteractive title={`${(stateChangeLabel && !isBaseUser && state === 2)? 'Corrección de ' + stateChangeLabel : (userIsEditor)? 'Marcar como corrección' : '' }`} placement="bottom" arrow>
          <button
            className={`
              truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-yellow-500 hover:opacity-100
              ${(state === 2)? 'text-yellow-500' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'hidden text-gray-400 opacity-60'}
            `}
            onMouseUp={() => (state !== 2 && (userIsEditor)) && saveStateEdit(2, motionState, false)}
          >
            <div className='mr-[3px]'>{(loadingState === 2)? loadingIcon : correctionIcon}</div>
            {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>Corrección</p>}
          </button>
        </Tooltip>



        {(!isBaseUser || !(motionStateActive || tradStateActive)) && 
          <Tooltip disableInteractive title={`${(stateChangeLabel && !isBaseUser && state === 1)? 'Pendiente por ' + stateChangeLabel : (userIsEditor)? 'Marcar como pendiente' : '' }`} placement="bottom" arrow>
            <button
              className={`
                truncate overflow-hidden cursor-pointer text-xs flex items-center hover:text-gray-600 hover:opacity-100 duration-300
                ${(state === 1)? 'text-gray-600' : (isProfe || !userIsFromClick || !userIsEditor)? 'hidden' : 'text-gray-400 opacity-60'}
              `}
              onMouseUp={() => (state !== 1 && (userIsEditor)) && saveStateEdit(1, motionState, false)}
            >
              <div className='mr-[3px]'>{(loadingState === 1)? loadingIcon : pendingIcon}</div>
              {(!small || isBaseUser) && <p className={`${(userIsEditor)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>Pendiente</p>}
            </button>
          </Tooltip>
        }


      </> : null }


    </div>
  </div>

}