import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';

import Factura10GetAllVideos from './../../graphql/queries/Factura10GetAllVideos'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';





const columns = [
    { id: 'name', label: 'Concepto'},
    { id: 'words', label: 'Palabras traducidas' },
    { id: 'minutes', label: 'Minutos de subtítulos' },
    { id: 'motions', label: 'Inserciones' },
    /* { id: 'adaptations', label: 'Adaptaciones gráficas' }, */
    { id: 'total', label: 'Total', align: 'right' },
];

  
const tables = [ 'Traducción, subtitulación y adaptación de los vídeos' ];





export default function C1Words({billOption}) {



    const { bill_token } = useParams()

    const { currentUser, currentCustomer } = useContext(AuthContext)
    const { formatNumbersToPrint } = useContext(FuncContext)



    const [loadingBills, setLoadingBills] = useState(true);
    const [tablesOpen, setTablesOpen] = useState([ true ]);

    const [videos, setVideos] = useState(null);
    const [videosEN, setVideosEN] = useState(null);
    const [professors, setProfessors] = useState(null);


    const [totalPrice, setTotalPrice] = useState(0);
    const [totalTranslation, setTotalTranslation] = useState([{price: 0.14, wordsOrInserts: 0, minutes: 0, work: 0}]);
    const [totalSubtitles, setTotalSubtitles] = useState([{price: 6, wordsOrInserts: 0, minutes: 0, work: 0}]);
    const [totalAdaptations, setTotalAdaptations] = useState([{price: 3, wordsOrInserts: 0, minutes: 0, work: 0}]);
    const [totalVoiceover, setTotalVoiceover] = useState([{price: 1400, wordsOrInserts: 0, minutes: 0, work: 0}]);


    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
    const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>
    const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;

    




    const { data: dataVideos, loading: loadingVideos } = useQuery(Factura10GetAllVideos, {
        variables: { 
            own_user_token: (currentUser?.token)? currentUser?.token : '369sTmR2pL2ISUR2v94f',
            customer_token: currentCustomer?.token,
            module: '1'
        }
    })

    const { data: dataVideosEN, loading: loadingVideosEN } = useQuery(Factura10GetAllVideos, {
        variables: { 
            own_user_token: (currentUser?.token)? currentUser?.token : '369sTmR2pL2ISUR2v94f',
            own_customer_token: currentCustomer?.token,
            localizations: 1,
            module: '31'
        }
    })






    useEffect(() => {
        setTimeout(()=> {
            setLoadingBills(false)
        }, 1000)
    }, [loadingBills])


    useEffect(() => {
        setVideos((dataVideos?.getCustomerVideos?.length)? dataVideos?.getCustomerVideos : null)
    }, [dataVideos])

    useEffect(() => {
        setVideosEN((dataVideosEN?.getCustomerVideos?.length)? dataVideosEN?.getCustomerVideos : null)
    }, [dataVideosEN])




    useEffect(() => {
        /* var all = null; */
        if (videos?.length && videosEN?.length) { 
            /* all = [...videos, ...videosEN] 
            setVideosALL(all) */

            var totalTranslationTMP = [{price: 0.14, wordsOrInserts: 0, minutes: 0, work: 0}]
            var totalSubtitlesTMP = [{price: 6, wordsOrInserts: 0, minutes: 0, work: 0}]
            var totalAdaptationsTMP = [{price: 3, wordsOrInserts: 0, minutes: 0, work: 0}]
            var totalVoiceoverTMP = [{price: 1400, wordsOrInserts: 0, minutes: 0, work: 0}]

            var professorsTMP = {}


            for (var tKey in tables) {
                var videosTMP = videosEN

                for (var key in videosTMP) {
                    var video = videosTMP[key]

                    const extraMarksJson = (JSON.parse(video['extra_marks_json'])?.length)? JSON.parse(video['extra_marks_json'])[0] : null;

                    /* [{"text_motions":9,"chart_motions":6,"motion_number_of_words":52,"phone_adaptations":3,"text_motions_duplicated":1,"chart_motions_duplicated":0}] */

                    totalTranslationTMP[tKey].wordsOrInserts = totalTranslationTMP[tKey]?.wordsOrInserts + ((parseInt(video?.number_of_words))? parseInt(video?.number_of_words) : 0) + ((parseInt(extraMarksJson?.motion_number_of_words))? parseInt(extraMarksJson?.motion_number_of_words) : 0)
                    totalSubtitlesTMP[tKey].minutes = totalSubtitlesTMP[tKey]?.minutes + ((parseFloat(video?.minutes))? parseFloat(video?.minutes) : 0)
                    totalAdaptationsTMP[tKey].wordsOrInserts = totalAdaptationsTMP[tKey]?.wordsOrInserts + ((parseInt(extraMarksJson?.text_motions))? parseInt(extraMarksJson?.text_motions) : 0)  + ((parseInt(extraMarksJson?.chart_motions))? parseInt(extraMarksJson?.chart_motions) : 0)

                    totalVoiceoverTMP[tKey].work = 11


                    var finalPrice = parseFloat(
                        (parseFloat((parseInt(video?.number_of_words) || parseInt(extraMarksJson?.motion_number_of_words))? (((parseInt(video?.number_of_words))? parseInt(video?.number_of_words) : 0) + ((parseInt(extraMarksJson?.motion_number_of_words))? parseInt(extraMarksJson?.motion_number_of_words) : 0)) : 0) * 0.14)
                        + parseFloat((parseFloat(video?.minutes))? parseFloat(video?.minutes) * 6 : 0)
                        + parseFloat((((parseInt(extraMarksJson?.text_motions))? parseInt(extraMarksJson?.text_motions) : 0) + ((parseInt(extraMarksJson?.chart_motions))? parseInt(extraMarksJson?.chart_motions) : 0)) * 3)
                    )
                    if (!professorsTMP[tKey]) {professorsTMP[tKey] = {};}
                    if (!professorsTMP[tKey][video?.professor?.name]) {professorsTMP[tKey][video?.professor?.name] = {};}
                    if (!professorsTMP[tKey][video?.professor?.name]['profe']) {professorsTMP[tKey][video?.professor?.name]['profe'] = video?.professor;}
                    if (!professorsTMP[tKey][video?.professor?.name][video?.name]) {professorsTMP[tKey][video?.professor?.name][video?.name] = finalPrice;}
                    professorsTMP[tKey][video?.professor?.name]['price'] = (parseFloat(professorsTMP[tKey][video?.professor?.name]['price']))? parseFloat(professorsTMP[tKey][video?.professor?.name]['price']) + finalPrice : finalPrice;

                }
            }

            setTotalTranslation(totalTranslationTMP)
            setTotalSubtitles(totalSubtitlesTMP)
            setTotalAdaptations(totalAdaptationsTMP)
            setTotalVoiceover(totalVoiceoverTMP)

            setProfessors(professorsTMP)
        }
    }, [videosEN])

    useEffect(() => {
        setTotalPrice(
            totalTranslation[0]?.wordsOrInserts * totalTranslation[0]?.price
            + totalSubtitles[0]?.minutes * totalSubtitles[0]?.price
            + totalAdaptations[0]?.wordsOrInserts * totalAdaptations[0]?.price
            + totalVoiceover[0]?.work * totalVoiceover[0]?.price
        )
    }, [totalTranslation, totalSubtitles, totalVoiceover])




      


  return (currentCustomer && !loadingBills)? (
    <div className='space-y-2'>


       


        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>
                    <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.date}</p>
                </div>

                {/* <div className='text-sm pt-2'>
                    (Descripción de la factura aquí)
                    
                </div> */}
            </div>



            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            <div className='w-full flex overflow-auto'>
                <div className='w-full flex flex-wrap justify-end space-y-4'>
                    {tables?.map((table, tKey) => {
                        return <table className='w-full bg-gray-200 rounded p-2 font-normal whitespace-nowrap overflow-hidden text-sm' key={'list-' + tKey}>
                            
                            <thead>
                                <tr className='text-gray-500 text-xs text-left bg-gray-300 bg-opacity-75'>
                                    <th className='text-black text-base px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>{table}</th>
                                    <th className='font-normal px-6 py-[6px] w-full'>Precio</th>
                                    <th className='font-normal px-2 py-[6px] w-full'>Palabras / Inserciones</th>
                                    <th className='font-normal px-2 py-[6px] w-full'>Minutos</th>
                                    <th className='font-normal px-2 py-[6px] w-full'>Jornadas</th>
                                    <th className='font-normal text-right px-2 py-[6px] w-full'>Total</th>
                                </tr>
                            </thead>


                            <tbody>
                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>Traducción del contenido de vídeo</td>
                                    <td className='px-6 py-[6px] w-full'>{formatNumbersToPrint(totalTranslation[tKey]?.price, 2)} € / palabra</td>
                                    <td className='px-2 py-[6px] w-full'>{formatNumbersToPrint(totalTranslation[tKey]?.wordsOrInserts)}</td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalTranslation[tKey]?.wordsOrInserts * totalTranslation[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>Sincronización de subtítulos</td>
                                    <td className='px-6 py-[6px] w-full'>{formatNumbersToPrint(totalSubtitles[tKey]?.price, 2)} € / minutos</td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'>{formatNumbersToPrint(totalSubtitles[tKey]?.minutes, 2)}</td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalSubtitles[tKey]?.minutes * totalSubtitles[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>Adaptación de gráficos, diapositivas y animaciones</td>
                                    <td className='px-6 py-[6px] w-full'>{formatNumbersToPrint(totalAdaptations[tKey]?.price, 2)} € / inserción</td>
                                    <td className='px-2 py-[6px] w-full'>{formatNumbersToPrint(totalAdaptations[tKey]?.wordsOrInserts)}</td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalAdaptations[tKey]?.wordsOrInserts * totalAdaptations[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>Servicio completo de locución con voiceover + IA</td>
                                    <td className='px-6 py-[6px] w-full'>{formatNumbersToPrint(totalVoiceover[tKey]?.price, 2)} € / jornada</td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'>{totalVoiceover[tKey]?.work}</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalVoiceover[tKey]?.work * totalVoiceover[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200 font-bold text-base'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Total</td>
                                    <td className='px-6 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='px-2 py-[6px] w-full'></td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(
                                        totalTranslation[tKey]?.wordsOrInserts * totalTranslation[tKey]?.price
                                        + totalSubtitles[tKey]?.minutes * totalSubtitles[tKey]?.price
                                        + totalAdaptations[tKey]?.wordsOrInserts * totalAdaptations[tKey]?.price
                                        + totalVoiceover[tKey]?.work * totalVoiceover[tKey]?.price
                                    , 2)} €</td>
                                </tr>
                            </tbody>

                        </table>
                    })}

                    <div className='w-full flex flex-wrap justify-end space-y-4'>
                        <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                            <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                                <p>Base imponible:</p>
                                <p>
                                    {formatNumbersToPrint(parseFloat(totalPrice), 2)} €
                                </p>
                            </div>

                            <div className='w-full flex justify-between space-x-8'>
                                <p>IVA (21%):</p>
                                <p>
                                    {formatNumbersToPrint(parseFloat(totalPrice) * 0.21, 2)} €
                                </p>
                            </div>

                            <div className='w-full flex justify-between space-x-8'>
                                <p>Importe total:</p>
                                <p>
                                    {formatNumbersToPrint(totalPrice + (totalPrice * 0.21), 2)} €
                                </p>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>












        <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>
        {(videosEN?.length)?
            tables?.map((table, tKey) => {
                var counterTMP = 0;

                return <div key={'table-' + tKey} className=''>

                    <div className={`border border-gray-300 rounded bg-gray-300`}>
                        <div 
                            className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                            onClick={() => { 
                                var tablesOpenTMP = [...tablesOpen]
                                tablesOpenTMP[tKey] = !tablesOpenTMP[tKey];
                                setTablesOpen(tablesOpenTMP)
                            }}
                        >
                            <p className='text-lg font-bold px-2'>{table}</p>
                            {(tablesOpen[tKey])? upArrowIcon : downArrowIcon}
                        </div>

                        <Collapse orientation="vertical" in={tablesOpen[tKey]} timeout={1000}>
                            <div className='border-t border-gray-300'>
                                <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                    <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                        <Table>

                                            <TableHead>
                                                <TableRow className={`${(bill_token || !tablesOpen[tKey])? 'table-stiky-0' : 'table-stiky'}`}>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={'columns-'+column.id}
                                                            className='select-none'
                                                            align={column.align}
                                                            style={{ 
                                                                minWidth: column.minWidth,
                                                                width: column.width,
                                                                backgroundColor: 'inherit',
                                                                padding: 8,
                                                            }}
                                                        >
                                                            <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                                <div> {column.label} </div>
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                            {professors && professors[tKey] && Object.keys(professors[tKey])?.length && Object.keys(professors[tKey])?.sort((a, b) => (a < b)? -1 : (a > b)? 1 : 0 )?.map((keyName, key) => {
                                                
                                                var professor = professors[tKey][keyName]['profe']
                                                var professorPrice = professors[tKey][keyName]['price']

                                                var videosTMP = (videosEN)?.filter((element) => element?.professor?.name === professor?.name)
                                                


                                                return (videosTMP?.length)? <>
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        className="w-full bg-blue-100 duration-200"
                                                    >
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='font-bold w-5 whitespace-nowrap'>{professor?.name }</p>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='w-full font-bold whitespace-nowrap text-right'>{formatNumbersToPrint(professorPrice, 2)} €</p>
                                                        </TableCell>
                                                    </TableRow>

                                                        {videosTMP?.map((row) => {
                                                            
                                                            return (
                                                                <TableRow 
                                                                    key={'row-'+row.id} 
                                                                    hover 
                                                                    role="checkbox" 
                                                                    tabIndex={-1} 
                                                                    className="hover:bg-gray-100 duration-200"
                                                                >
                                                                    {columns.map((column) => {
                                                                        var value = row[column.id];
                                                                        const extraMarksJson = (JSON.parse(row['extra_marks_json'])?.length)? JSON.parse(row['extra_marks_json'])[0] : null;

                                                                        if (column?.id === 'name') {
                                                                            value = <div className='flex space-x-2'>
                                                                                <RowButtons
                                                                                    row={row}
                                                                                    billOption={billOption}
                                                                                />
                                                                                
                                                                                {(row['vimeo_url'])? 
                                                                                    <a href={row['vimeo_url']} target="_blank">{value}</a> 
                                                                                    : <p>{value}</p>
                                                                                }
                                                                            </div>
                                                                        }

                                                                        if (column?.id === 'words') {
                                                                            value = (parseInt(row?.number_of_words) || parseInt(extraMarksJson?.motion_number_of_words))? (((parseInt(row?.number_of_words))? parseInt(row?.number_of_words) : 0) + ((parseInt(extraMarksJson?.motion_number_of_words))? parseInt(extraMarksJson?.motion_number_of_words) : 0)) : '---';
                                                                        }

                                                                        if (column?.id === 'minutes') {
                                                                            value = (parseFloat(row?.minutes))? formatNumbersToPrint(parseFloat(row?.minutes), 2) + ' m' : '---';
                                                                        }

                                                                        /* if (column?.id === 'adaptations') {
                                                                            value = (parseInt(extraMarksJson?.phone_adaptations))? parseInt(extraMarksJson?.phone_adaptations) : '---';
                                                                        } */

                                                                        if (column?.id === 'motions') {
                                                                            value = ((parseInt(extraMarksJson?.text_motions))? parseInt(extraMarksJson?.text_motions) : 0)  + ((parseInt(extraMarksJson?.chart_motions))? parseInt(extraMarksJson?.chart_motions) : 0)
                                                                            value = (value)? value : '---'
                                                                        }

                                                                        if (column?.id === 'total') { /* CHART MOTIONS */
                                                                            value = formatNumbersToPrint(
                                                                                
                                                                                (
                                                                                    (parseFloat((parseInt(row?.number_of_words) || parseInt(extraMarksJson?.motion_number_of_words))? (((parseInt(row?.number_of_words))? parseInt(row?.number_of_words) : 0) + ((parseInt(extraMarksJson?.motion_number_of_words))? parseInt(extraMarksJson?.motion_number_of_words) : 0)) : 0) * 0.14)
                                                                                    + (parseFloat((parseFloat(row?.minutes))? parseFloat(row?.minutes) : 0) * 6)
                                                                                    + (parseFloat((((parseInt(extraMarksJson?.text_motions))? parseInt(extraMarksJson?.text_motions) : 0) + ((parseInt(extraMarksJson?.chart_motions))? parseInt(extraMarksJson?.chart_motions) : 0))) * 3)
                                                                                )
                                                                            
                                                                            , 2) + ' €';
                                                                        }


                                                                        return (
                                                                            <TableCell
                                                                                key={'column-data-'+column.id}
                                                                                align={column.align}
                                                                                style={{ borderBottom: 0, padding: 8 }}
                                                                                className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                            >
                                                                                {value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </> : null
                                                })}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Collapse>
                    </div>
                </div>
            })
        : null }














    </div>
    
  ) : 
  
  <div className='absolute z-10 w-full h-full'>
      <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
          {loadingIcon}
          <p>Cargando...</p>
      </div>
  </div>
}