import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';

import { NestedMenuItem } from 'mui-nested-menu';

import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';











export default function ModuleSelector({

  modules,
  moduleFilter,
  setModuleFilter,

  selectedModule,
  setSelectedModule,
  noSelectedModule,

  selectedFolder,
  setSelectedFolder,

  showAll,
  load,
  setLoading,
  disabled,
  color,
  redirect

}) {


  const { currentCustomer } = useContext(AuthContext)
  const navigate = useNavigate();



  const [internalSelectedModule, setInternalSelectedModule] = useState([]);
  const [parentModules, setParentModules] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [lastAnchorEl, setLastAnchorEl] = useState(null);
  const open = Boolean(anchorEl);



  const invisibleIcon = <svg className='text-gray-500 opacity-50' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M776-305 649-431q6-14 9.5-32t3.5-38q0-76-53-129t-129-53q-18 0-35.5 3.5T410-670l-98-98q41-17 82-24.5t86-7.5q143 0 257 74.5T908-529q2 5 3.5 12.5T913-501q0 8-1.5 15.5T908-473q-23 50-56.5 92.5T776-305ZM758-85 621-221q-35 11-70 16t-71 5q-143 0-257-75.5T52-473q-2-6-3.5-13T47-501q0-8 1-15.5t4-12.5q20-43 48.5-80.5T164-678l-80-81q-12-12-12.5-29.5T84-818q12-12 29.5-12t29.5 12l674 675q12 12 12 29t-12 29q-12 12-29.5 12T758-85ZM480-319q10 0 20-1t19-3L303-539q-2 10-3.5 19t-1.5 19q0 76 53 129t129 53Zm108-173L471-609q51-5 86 32t31 85Z"/></svg>
  const lockIcon = <svg className='text-gray-500 opacity-50' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M263.717-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.226-135.84t136-56.16Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Zm216.495-192Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Z"/></svg>
  const rightArrowIcon = <svg className='text-gray-500 mt-[-5px]' xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor'><path d="M7.208 14.375q-.27-.271-.27-.625t.27-.625L10.333 10 7.208 6.875q-.27-.271-.27-.625t.27-.625q.271-.271.625-.271.355 0 .625.271l3.75 3.75q.146.146.209.302.062.156.062.323t-.062.323q-.063.156-.209.302l-3.75 3.75q-.27.271-.625.271-.354 0-.625-.271Z"/></svg>
  
  const folderIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M168-192q-32 0-52-21.162T96-264.04v-432.243Q96-726 116-747t52-21h185.643q14.349 0 27.353 5Q394-758 405-747l75 75h313q31 0 50.5 21t21.5 51H451l-96-96H168v432l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168Zm75-72h538l59-192H300l-57 192Zm-75-213v-219 219Zm75 213 57-192-57 192Z"/></svg>
  const folderFillIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M865-600H299q-54 0-92.5 35T168-477v213l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168q-32 0-52-21t-20-51v-432q0-30 20-51t52-21h186q14 0 27.5 5t23.5 16l75 75h313q31 0 50.5 21t21.5 51Z"/></svg>

  const selectDownArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M480-360 280-560h400L480-360Z"/></svg>
  const selectUpArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m280-400 200-200 200 200H280Z"/></svg>









  


  useEffect( () => { 
    var parentModulesTMP = []
    var parentModulesCopyTMP = []


    const extraModuleTMP = {
      id: 0,
      name: currentCustomer?.label_extras_module,
      abbreviated_name: currentCustomer?.label_extras_module_url,
      position: 9999,
      visible: true,
      blocked: false,
      customer_token: currentCustomer?.token,
    };

    const allModuleTMP = {
      id: 'all',
      name: 'Todos',
      abbreviated_name: 'all',
      position: 0,
      visible: true,
      blocked: false,
      customer_token: currentCustomer?.token,
    };


    var modulesTMP = (modules?.length)? [extraModuleTMP, ...modules] : [extraModuleTMP]
    if (modules?.length && showAll) { modulesTMP = [...modulesTMP, allModuleTMP] }


    for (let i = modulesTMP.length - 1; i >= 0; i--) {
      var module = modulesTMP[i];

      var nameSplit = module?.abbreviated_name?.split('_');
      var groupname = (module?.inside_extras)? currentCustomer?.label_extras_module_url : (nameSplit?.length > 1 && nameSplit[0] === currentCustomer?.label_extras_module_url)? nameSplit[1] : (nameSplit?.length)? nameSplit[0] : null;
      var existingModules = parentModulesTMP[groupname]?.modules;

      parentModulesTMP[groupname] = { 'groupname': groupname, 'position': module?.position, 'name': module?.name, 'modules': (existingModules?.length)? [...existingModules, module] : [module] };
    }

    for (var groupname of Object.keys(parentModulesTMP)) {
      parentModulesCopyTMP.push(parentModulesTMP[groupname])
    }
    
    
    setParentModules((parentModulesCopyTMP?.length)? parentModulesCopyTMP?.sort((a, b) => a?.position - b?.position) : null)
  }, [modules])


  useEffect( () => {
    var entred = false;
    if (parseInt(moduleFilter) && modules?.length) {
      for (var moduleTMP of modules) {
        if (parseInt(moduleTMP?.id) === parseInt(moduleFilter)) {
          setSelectedModuleFunc(moduleTMP)
          entred = true;
        }
      }
    } else if (showAll && moduleFilter === 'all' && parentModules[0]?.modules[0]) {
      setSelectedModuleFunc(parentModules[0]?.modules[0])
      entred = true;
    }

    if (!entred) { setSelectedModuleFunc(null) }
  }, [modules, moduleFilter, parentModules, noSelectedModule])


  useEffect( () => { 
    if (anchorEl) {
      setLastAnchorEl(anchorEl)
    }
  }, [anchorEl])





  function selectedModuleFunc() {
    if (noSelectedModule) { return internalSelectedModule } else { return selectedModule }
  }

  function setSelectedModuleFunc(moduleTMP) {
    if (noSelectedModule) { setInternalSelectedModule(moduleTMP) } else { setSelectedModule(moduleTMP) }
  }


  


  
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleModuleChange = (moduleId, moduleAbbreviatedName) => {
    if (selectedFolder) { setSelectedFolder(null) }

    if (moduleId !== moduleFilter) { 
      if (load) { setLoading(true) }
      setModuleFilter(moduleId)
      sessionStorage.setItem(currentCustomer?.token+'-video-module-filter', moduleId)
      localStorage.setItem(currentCustomer?.token+'-video-module-filter', moduleId)

      if (redirect) { navigate(`/videos/${moduleAbbreviatedName}`) }
    }
    
    setAnchorEl(null)
  };





  return ((parentModules) && 
    <>
      <TextField
        type='button'
        value={(selectedModuleFunc())? selectedModuleFunc()?.name : currentCustomer?.label_extras_module}
        onClick={handleClick}
        label={currentCustomer?.label_module}
        className={`flex-grow mui-bg-${color} text-left`}
        sx={{ 
          "& .MuiInputBase-root": { height: 30 /* 'inherit' */, paddingTop: 0.1, paddingRight: 0, paddingLeft: 1.3, cursor: 'pointer' }, 
          "& .MuiInputBase-input": { textAlign: 'left', paddingBlock: 0, paddingLeft: 0.4, paddingRight: 2, cursor: 'pointer' },
        }}

        InputLabelProps={{ sx: { top: "-10.5px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}
        
        style={{ minWidth: 150 }}
        disabled={disabled}
        focused={(anchorEl)? true : false}
        

        InputProps={{
          min: 0, 
          style: { textAlign: 'left' },
          startAdornment: (
            <InputAdornment position="start">
              <div className='w-[15px]'>{folderIcon}</div>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              {(selectedModuleFunc() && !selectedModuleFunc()?.visible)? invisibleIcon : null}
              {(selectedModuleFunc() && selectedModuleFunc()?.blocked)? lockIcon : null}
              {(anchorEl)? selectUpArrowIcon : selectDownArrowIcon}
            </InputAdornment>
          ),
        }}

      ></TextField>





      <Menu 
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { minWidth: lastAnchorEl && lastAnchorEl.offsetWidth }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >


        {parentModules?.map((moduleGroup, key) => {
          var modules = (moduleGroup?.modules?.length)? ((moduleGroup?.modules?.length > 1)? moduleGroup?.modules?.sort(function(a,b) { return a.position - b.position }) : moduleGroup?.modules[0] ) : null;
          
          var selected = false;
          for (var i in modules) { if ((!moduleFilter && !parseInt(modules[i]?.id)) || (parseInt(modules[i]?.id) === parseInt(moduleFilter))) { selected = true } }

          if (modules?.length > 1) {

            return <NestedMenuItem
              key={`module-group-${i}-${key}`}
              leftIcon={folderFillIcon}
              rightIcon={rightArrowIcon}
              onClick={() => handleModuleChange(modules[0]?.id, modules[0]?.abbreviated_name)}
              label={(moduleGroup?.name === currentCustomer?.label_extras_module)? moduleGroup?.name : moduleGroup?.name?.replaceAll(currentCustomer?.label_extras_module, '')?.replaceAll('(nuevos)', '')}
              parentMenuOpen={open}
              style={{ paddingInline: 10, backgroundColor: (selected)? 'rgb(219 234 254 / 0.6)' : null }}
            >
              {modules?.map(module => {
                return <MenuItem
                  key={'module-option-' + module?.id}
                  onClick={()=> handleModuleChange(module?.id, module?.abbreviated_name)}
                  style={{ paddingInline: 10, backgroundColor: (parseInt(moduleFilter) === parseInt(module?.id) || (!moduleFilter && !module?.id))? 'rgb(219 234 254 / 0.6)' : null }}
                  className={`${(!module?.visible)? 'opacity-50' : ''}`}
                >
                  <div className={`w-full flex justify-between items-center space-x-5`}>
                    <div className='w-full flex justify-start items-center space-x-2 font-normal'>
                      <div className='text-gray-500'>{folderIcon}</div>
                      <p>{module?.name}</p>
                    </div>

                    <div className='flex'>
                      <div className='text-gray-400'>{(module?.blocked)? lockIcon : null}</div>
                      <div className='text-gray-400'>{(!module?.visible)? invisibleIcon : null}</div>
                    </div>
                  </div>
                </MenuItem>
              })}
            </NestedMenuItem>

          } else if (modules) {

            return <MenuItem key={'module-option-' + modules?.id} onClick={() => handleModuleChange(modules?.id, modules?.abbreviated_name)} style={{ paddingInline: 10, backgroundColor: (moduleFilter === modules?.id)? 'rgb(219 234 254 / 0.6)' : null }}>
              <div className='w-full flex justify-between items-center space-x-5'>
                <div className='w-full flex justify-start items-center space-x-2 font-normal'>
                  <div className='text-gray-500'>{folderIcon}</div>
                  <p>{modules?.name}</p>
                </div>

                <div className='flex'>
                  <div className='text-gray-400'>{(modules?.blocked)? lockIcon : null}</div>
                  <div className='text-gray-400'>{(!modules?.visible)? invisibleIcon : null}</div>
                </div>
              </div>
            </MenuItem>

          }
        })}

      </Menu>
    </>
  );
}