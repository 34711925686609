import { useContext } from "react"
import { FuncContext } from "../../../contexts/FuncContext"
import { AuthContext } from "../../../contexts/AuthContext";

import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';





export default function RowHistoryMenu({ anchorEl, setAnchorEl, history, extraHistory, documents, actualBillId, video, center }) {



    const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
    const { formatNumbersToPrint, billTypesList } = useContext(FuncContext)

    const hasPermision = (currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? true : false

    const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M444-444H276q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q240-495 250.35-505.5 260.7-516 276-516h168v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-720 505.5-709.65 516-699.3 516-684v168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q720-465 709.65-454.5 699.3-444 684-444H516v168q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q465-240 454.5-250.35 444-260.7 444-276v-168Z"/></svg>;
    const openInNewWindowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h228q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q480-765 469.65-754.5 459.3-744 444-744H216v528h528v-228q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q795-480 805.5-469.65 816-459.3 816-444v228q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm528-549L412-361q-11 11-25 10.5T362-362q-11-11-11-25.5t11-25.5l331-331h-81q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q576-795 586.35-805.5 596.7-816 612-816h204v204q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q765-576 754.5-586.35 744-596.7 744-612v-81Z"/></svg>;
    const checkOcpm = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m389-369 299-299q10.91-11 25.45-11Q728-679 739-668t11 25.58q0 14.58-10.61 25.19L415-292q-10.91 11-25.45 11Q375-281 364-292L221-435q-11-11-11-25.5t11-25.5q11-11 25.67-11 14.66 0 25.33 11l117 117Z"/></svg>

    const tHeadClasses = 'px-2'
    const tdClasses = 'px-2 py-1'
    




    const videoID = (parseInt(video?.codi))? parseInt(video?.codi) : (parseInt(video?.id))? parseInt(video?.id) : null;
    const videoName = (video?.concepte)? video?.concepte : (video?.name)? video?.name : null;
    var totalVideo = 0;
    var totalBills = 0;
    var totalExtras = 0;


    if (history?.concepts && Object?.keys(history?.concepts)?.length) {
        for (var element of Object?.keys(history?.concepts)) {
            const conceptTMP = history?.concepts[element]
            const documentTMP = (Object?.keys(history?.documents)?.length)? history?.documents[conceptTMP?.document_id] : null
            const docObjTMP = (documents?.length)? documents?.filter((el) => parseInt(el?.f10ID) === parseInt(conceptTMP?.document_id))[0] : null
            const isHidden = docObjTMP?.hidden_concepts?.split(',')?.includes(conceptTMP?.concepte)

            if (docObjTMP?.label && documentTMP && !isHidden && parseFloat(conceptTMP?.preu)) {
                totalVideo = totalVideo + parseFloat(conceptTMP?.preu);
            }
        }
    }

    if (videoName && history?.documents && Object?.keys(history?.documents)?.length) {
        for (var element of Object?.keys(history?.documents)) {
            const documentTMP = history?.documents[element]

            if (documentTMP?.external_video_data && JSON.parse(documentTMP?.external_video_data)[videoName]) {
                totalVideo = totalVideo + (parseFloat(JSON.parse(documentTMP?.external_video_data)[videoName]?.price) / 100);
            }
        }
    }

    if (extraHistory && extraHistory?.length) {
        for (var element of extraHistory) {
            if (element?.video_id ===  videoID && element?.price && parseFloat(element?.price)) {
                totalExtras = totalExtras + (parseFloat(element?.price) / 100);
            }
        }
    }


    




    return (history && Object?.keys(history?.documents)?.length)?
        
        <Popover
            id={actualBillId}
            open={(anchorEl && history)? true : false}
            anchorEl={anchorEl}
            onClose={() => { setTimeout(() => { setAnchorEl(null) }, 400) }}
            anchorOrigin={{ vertical: 'bottom', horizontal: ((center)? 'center' : 'left') }}
            transformOrigin={{ vertical: -10, horizontal: ((center)? 340 : (hasPermision)? 22 : 22) }}
            sx={{ '& .MuiPaper-root': { overflow: 'visible !important' } }}
            data-avoid
        >
            <div data-avoid className={`text-sm whitespace-nowrap text-left z-[30] ${(center)? '' : ((hasPermision)? 'left-[30px]' : 'left-[-13px]')}`}>
                {(!center)?
                    <div data-avoid className={`absolute w-1 h-1 p-1 mt-[-4px] ${(center)? 'right-[527px] ml-auto mr-auto' : 'left-[28px]'} rotate-45 bg-white border-t border-l border-gray-400 border-opacity-60 z-[31]`}></div>
                : null }

                <div data-avoid id='clickbox' className={`px-3 py-2 min-w-[200px] bg-white border border-gray-400 border-opacity-60 rounded shadow-2xl`}>
                    {(history && Object?.keys(history?.documents)?.length)? 
                        <table data-avoid>
                            <tr data-avoid>
                                <th data-avoid className={`${tHeadClasses} text`}>Facturas</th>
                                <th data-avoid className={`${tHeadClasses}`}>Fecha</th>

                                {(currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? 
                                    <th data-avoid className={`${tHeadClasses}`}>Tipo</th>
                                : null }

                                <th data-avoid className={`${tHeadClasses} text-right`}>Precio vídeo</th>
                            </tr>



                            {(history && Object?.keys(history?.documents)?.length)? 
                                Object?.keys(history?.documents)?.sort((a, b) => {
                                    const data_a = (history?.documents[a]?.data)? new Date(history?.documents[a]?.data) : new Date(history?.documents[a]?.createdAt);
                                    const data_b = (history?.documents[b]?.data)? new Date(history?.documents[b]?.data) : new Date(history?.documents[b]?.createdAt);
                                    return (data_a < data_b)? 1 : (data_a > data_b)? -1 : 0
                                })?.map((element) => {
                                    const documentTMP = history?.documents[element]
                                        
                                    const videoID = (video?.codi)? video?.codi : (video?.id)? video?.id : null
                                    const videoName = (video?.concepte)? video?.concepte : (video?.name)? video?.name : null
                                    const conceptTMP = (history?.concepts?.length)? history?.concepts?.filter((el) => parseInt(el?.document_id) === parseInt(element))[0] : null
                                    const marksJson = (conceptTMP && conceptTMP['aux_json'])? getJsonString(conceptTMP['aux_json']) : null;

                                    const docObjTMP = (documents?.length)? documents?.filter((el) => parseInt(el?.f10ID) === parseInt(element))[0] : null
                                    const extrasTMP = (extraHistory)? extraHistory?.filter((el) => parseInt(el?.bill_id) === parseInt(docObjTMP?.id)) : null
                                    
                                    const type = (billTypesList?.length)? billTypesList?.filter((el) => el?.id === docObjTMP?.type)[0] : null
                                    const isHidden = (conceptTMP?.concepte && docObjTMP?.hidden_concepts)? docObjTMP?.hidden_concepts?.split(',')?.includes(conceptTMP?.concepte) : false

                                    var priceTMP = ((parseFloat(conceptTMP?.preu))? parseFloat(conceptTMP?.preu) : 0)
                                    const billEditedMins = (docObjTMP?.video_edits)? getJsonString(docObjTMP?.video_edits)[videoName]?.minutes : null;
                                    if (billEditedMins) { priceTMP = (parseFloat(marksJson?.min_facturable) + parseFloat(billEditedMins)) * marksJson?.preu_unitat; }

                                    const extrasPriceTMP = (extrasTMP?.length)? extrasTMP?.map((el) => (el?.video_id === parseInt(videoID))? (el?.price / 100) : 0 ).reduce((partialSum, a) => partialSum + a, 0) : 0
                                    priceTMP = priceTMP + ((parseFloat(extrasPriceTMP))? parseFloat(extrasPriceTMP) : 0)

                                    const created_date = new Date(documentTMP?.createdAt)
                                    const change_label = (!docObjTMP?.dateFormated)? ("0" + created_date.getDate()).slice(-2) + '/' + ("0" + (created_date.getMonth() + 1)).slice(-2) + '/' + created_date.getFullYear() : null
                                    const video_price = (documentTMP?.external_video_data)? getJsonString(documentTMP?.external_video_data)[videoName]?.price / 100 : null

                                    return ((docObjTMP?.label && documentTMP && !isHidden) || documentTMP?.external_price)? 
                                        <tr data-avoid className="bg-gray-200 bg-opacity-80 hover:bg-gray-300 hover:bg-opacity-80 duration-200 border-b-4 border-white">
                                            <td data-avoid className={`px-2 pl-1`}>
                                                <div data-avoid className={`flex items-center space-x-1 min-w-[400px] max-w-[calc(100vw-${(hasPermision)? '600px' : '500px'})] xl:max-w-[${(hasPermision)? '660px' : '730px'}]`}>
                                                    {(parseInt(actualBillId) === parseInt(docObjTMP?.id) || (documentTMP?.external_price && parseInt(actualBillId) === parseInt(documentTMP?.id)))? 
                                                        <div data-avoid className="py-[3px] px-2 text-xs bg-gray-800 bg-opacity-50 rounded text-white">Factura actual</div>
                                                    :
                                                        <Tooltip data-avoid disableInteractive title={'Ver factura'} placement="bottom" arrow>
                                                            <a data-avoid href={`${process.env.REACT_APP_ROUTE}${(hasPermision)? 'billing/' : 'your-billing/' + currentCustomer?.abbreviated_name + '/'}${docObjTMP?.token}`} target="_blank">{openInNewWindowIcon}</a>
                                                        </Tooltip>
                                                    }

                                                    <p data-avoid className="truncate max-w-[400px]">{(docObjTMP?.label)? docObjTMP?.label : documentTMP?.label}</p>
                                                </div>
                                            </td>

                                            <td data-avoid className={`${tdClasses}`}>
                                                {(docObjTMP?.dateFormated)? docObjTMP?.dateFormated : change_label}
                                            </td>

                                            {(currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? 
                                                <td data-avoid className={`px-2 py-[2px]`}>
                                                    <Tooltip data-avoid disableInteractive title={type?.name} placement="bottom" arrow>
                                                        <div data-avoid className="flex items-center justify-center py-[2px] bg-gray-400 bg-opacity-30 hover:bg-opacity-40 rounded">
                                                            {(type?.icons)? type?.icons : checkOcpm}
                                                        </div>
                                                    </Tooltip>
                                                </td>
                                            : null }

                                            <td data-avoid className={`${tdClasses} text-right`}>{formatNumbersToPrint((priceTMP)? priceTMP : video_price, 2)} €</td>
                                        </tr> 
                                    : null
                                })
                            : null }




                            <tr data-avoid className="bg-gray-200 bg-opacity-80 hover:bg-gray-300 hover:bg-opacity-80 duration-200 border-b-4 border-white">
                                <td data-avoid className={`px-2 pl-1`}>
                                    <div data-avoid className={`flex items-center space-x-1 max-w-[calc(100vw-${(hasPermision)? '600px' : '500px'})] xl:max-w-[${(hasPermision)? '660px' : '730px'}]`}>
                                        <div className="text-gray-500">{addIcon}</div>
                                        <p data-avoid className="truncate">TOTAL</p>
                                    </div>
                                </td>

                                <td data-avoid className={`${tdClasses}`}></td>
                                {(currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? 
                                    <td data-avoid className={`px-2 py-[2px]`}></td>
                                : null }
                                <td data-avoid className={`${tdClasses} text-right`}>{formatNumbersToPrint(totalVideo + totalExtras, 2)} €</td>
                            </tr> 


                        </table>
                    :
                        <div><p>Esta factura no se encuentra en ningúna factura.</p></div>
                    }
                </div>
            </div>
        </Popover>

    : null
}