import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import axios from 'axios'

import StateChanger from './StateChanger'
import Diff from "./DiffViewer/Diff";
import ModalSelectLanguage from './ModalSelectLanguage';
import TextLogs from './TextLogs';

import CreateOrUpdateUser from './../graphql/mutations/CreateOrUpdateUser'
import UpdateUserState from './../graphql/mutations/UpdateUserState'
import UpdateVideoChyronState from './../graphql/mutations/UpdateVideoChyronState'

import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';








export default function ChyronEditor({ professorTMP, video, getVideo, chyronStates, setChyronStates, mode }) {
  

  
  const { currentUser, currentCustomer, languageList, getNewChyronLanguageFunc, getJsonString } = useContext(AuthContext)
  const { textDifferenceCounterFunc, remove_tags, format_texts, getRevNumberOfWordsFunc } = useContext(FuncContext)


  

  /* Edit vars */
  const [videoLanguage, setVideoLanguage] = useState(getLanguageFunc(video?.language));
  const [professor, setProfessor] = useState((professorTMP)? professorTMP : null);

  const deffaullt_overlap_states = (video?.chyron_states)? getJsonString(video?.chyron_states, true)?.filter((el) => parseInt(el?.professor_id) === parseInt(professorTMP?.id) && el?.language === video?.language) : null;
  const deffaullt_overlap_state = (deffaullt_overlap_states?.length)? deffaullt_overlap_states[0] : null;

  const deffault_positions = (professor?.positions)? getJsonString(professor?.positions, true) : null;
  const deffault_position = (deffault_positions && deffault_positions[video?.language])? deffault_positions[video?.language] : null;

  const [professorPositions, setProfessorPositions] = useState((deffault_positions)? deffault_positions : null);
  const [professorPosition, setProfessorPosition] = useState((deffault_position)? deffault_position : null);

  const text_tmp = (professorPosition?.text)? professorPosition?.text : '';
  const old_text_tmp = (professorPosition?.old_text)? professorPosition?.old_text : '';
  const old_text_changes_tmp = (professorPositions && parseInt(professorPosition?.old_text_changes))? parseInt(professorPosition?.old_text_changes) : 0;
  



  const [hasTextLogs, setHasTextLogs] = useState(false);
  const [diffOldText, setDiffOldText] = useState((old_text_tmp)? old_text_tmp : text_tmp);
  const [diffNewText, setDiffNewText] = useState((text_tmp)? text_tmp : null);




  /* Show edits */
  const [showEditPosition, setShowEditPosition] = useState(false);
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [showCorrectionChanges, setShowCorrectionChanges] = useState(false);
  const [differenceCounter, setDifferenceCounter] = useState((old_text_changes_tmp)? old_text_changes_tmp : 0);
  const [openAddLanguagePanel, setOpenAddLanguagePanel] = useState(false);


  /* Loading vars */
  const [loading, setLoading] = useState(false);
  const [loadingSaveChyron, setLoadingSaveChyron] = useState(false);
  const [loadingNewStates, setLoadingNewStates] = useState(false);


  /* State vars */
  const disabledLanguages = (deffault_positions)? Object.keys(deffault_positions) : [];
  
  const [state, setState] = useState((parseInt(deffault_position?.state))? parseInt(deffault_position?.state) : 1);
  const [loadingState, setLoadingState] = useState(null);
  const [motionState, setMotionState] = useState((parseInt(deffault_position?.motion_state))? parseInt(deffault_position?.motion_state) : 1);
  const [loadingMotionState, setLoadingMotionState] = useState(null);
  const [revisionState, setRevisionState] = useState((parseInt(deffault_position?.revision_state))? parseInt(deffault_position?.revision_state) : 1);
  const [loadingRevisionState, setLoadingRevisionState] = useState(false);
  const [tradState, setTradState] = useState((deffault_position?.trad_done)? true : false);
  const [loadingTradState, setLoadingTradState] = useState(false);


  /* Icon vars */
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const seeChangesIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M432-336q-20 0-39-3.5t-37-9.5q-14-5-20-19t1-28q7-14 21-19t29 0q11 3 22 5t23 2q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119l28-27q11-11 25.5-10t25.5 12q11 11 11 25.5T343-550l-86 85q-11 11-25 11t-25-11l-86-86q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l20 20q2-98 71.5-166.5T432-816q100 0 170 70t70 170q0 40-12.5 76T625-434l214 214q11 11 11 25t-11 25q-11 11-25.5 11T788-170L574-383q-30 22-66 34.5T432-336Z"/></svg>
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;

  const spellCheckIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m544-246 196-196q11-11 25.5-11t25.5 11q11 11 11 25.5T791-391L595-195q-11 11-24 16t-27 5q-14 0-27-5t-24-16l-93-94q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l93 93ZM271-507l-35 98q-4 11-14 18t-22 7q-20 0-32-17t-4-36l150-401q5-12 15-19t23-7h30q13 0 23 7t15 19l151 403q7 19-4.5 35T535-384q-13 0-23-7.5T498-411l-33-96H271Zm25-69h143l-70-199h-4l-69 199Z"/></svg>







  const [createOrUpdateUser] = useMutation(CreateOrUpdateUser)
  const [updateUserState] = useMutation(UpdateUserState)
  const [updateVideoChyronState] = useMutation(UpdateVideoChyronState)

  


  



  useEffect(() => {    
    window.addEventListener("visibilitychange", onFocus)

    return () => {
      window.removeEventListener('visibilitychange', onFocus)
    };
  }, [video, professor, chyronStates])


  useEffect(() => {  
    setVideoLanguage(getLanguageFunc(video?.language))
  }, [video])

  
  useEffect(() => {  
    var positionsTMP = (professor?.positions)? getJsonString(professor?.positions , true) : null;
    if (positionsTMP) {
      setProfessorPositions(positionsTMP)
    } else {
      setProfessorPositions(null)
    }
  }, [professor])


  useEffect(() => {  
    if (professorPositions && professorPositions[video?.language]) {
      setProfessorPosition(professorPositions[video?.language])
    } else {
      setProfessorPosition(null)
    }
  }, [professorPositions])
  

  /* OG professor changed */
  useEffect( () => { 
    setProfessor((professorTMP)? professorTMP : null)
  }, [professorTMP])


  /* Show edit chyron */
  useEffect( () => { 
    if (showEditPosition) {
      setTimeout(()=>{
        document.getElementById('position_esp').focus()
      }, 50)
    } else {
      setProfessorPositions(deffault_positions)
    }
  }, [showEditPosition])


  /* Update professor states */
  useEffect( () => { 
    const deffaultPositionsTMP = (professor && professor?.positions)? getJsonString(professor?.positions, true) : null;
    var stateTMP = 1;
    var motionStateTMP = 1;

    
  
    if (deffaultPositionsTMP && deffaultPositionsTMP[video?.language]) {
      stateTMP = (parseInt(deffaultPositionsTMP[video?.language]?.state))? parseInt(deffaultPositionsTMP[video?.language]?.state) : 1;
      motionStateTMP = (parseInt(deffaultPositionsTMP[video?.language]?.motion_state))? parseInt(deffaultPositionsTMP[video?.language]?.motion_state) : 1;
    }

    const hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )?.length)? true : false;
    const additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )[0] : null;
    if (stateTMP !== 2 && additionalState) {
      stateTMP = (parseInt(additionalState?.state))? parseInt(additionalState?.state) : 1;
      motionStateTMP = (parseInt(additionalState?.motion_state))? parseInt(additionalState?.motion_state) : 1;
    }



    setState(stateTMP);
    setMotionState(motionStateTMP);
    setRevisionState((deffaultPositionsTMP && deffaultPositionsTMP[video?.language]?.revision_state === 2)? 2 : 1)
    setTradState((deffaultPositionsTMP && deffaultPositionsTMP[video?.language]?.trad_done)? true : false)
  }, [professor, chyronStates])


  /* Update difference counter */
  useEffect( () => { 
    if (professorPosition) {
      var oldTextTMP = (isCorrecting)? text_tmp : diffOldText;
      var newTextTMP = (isCorrecting && professorPosition?.text)? professorPosition?.text?.trim() : diffNewText;

      if (oldTextTMP && newTextTMP && remove_tags(format_texts(oldTextTMP?.replace(/\n/g, "<br />"))) && remove_tags(format_texts(newTextTMP?.replace(/\n/g, "<br />")))) {
        setDifferenceCounter(textDifferenceCounterFunc(oldTextTMP, newTextTMP))
      }
    } else {
      setDifferenceCounter(0)
    }
  }, [professorPositions, isCorrecting, diffOldText, diffNewText])







  function onFocus() {
    if (document?.visibilityState === 'visible' && parseInt(video?.id) && parseInt(professor?.id)) {
      setLoadingNewStates(true)

      axios.post(`${process.env.REACT_APP_API_ROUTE}updateProfessor`, { own_user_token: currentUser?.token, professor_id: parseInt(professor?.id), video_id: parseInt(video?.id) }).then( async (response) => { 
        if (parseInt(response?.data?.professor?.id)) {

          var profeStatesTMP = response?.data?.professor;
          setProfessor(profeStatesTMP)
          setChyronStates((response?.data?.chyron_states?.length)? [...response?.data?.chyron_states] : null)

        }

        setLoadingNewStates(false)
      })
    }
  }


  /* Edit chyrons */
  async function saveChargeFunc() {
    if (professor?.id) {
      setLoadingSaveChyron(true)

      var hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )?.length)? true : false;
      var additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )[0] : null;

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      const deffaultPositionsTMP = (professor?.positions && getJsonString(professor?.positions, true))? getJsonString(professor?.positions, true) : {};
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      var statesChanged = false;

      if (professorPositionsTMP && Object.keys(professorPositionsTMP)?.length) {
        for (var langKey of Object.keys(professorPositionsTMP)) {

          if (professorPositionsTMP && !professorPositionsTMP[langKey]) { professorPositionsTMP[langKey] = {} }
          if (professorPositionsTMP && !professorPositionsTMP[langKey]?.language) { professorPositionsTMP[langKey] = { ...professorPositionsTMP[langKey], language: langKey } }
          var oldTextTMP = (deffaultPositionsTMP[langKey]?.text && deffaultPositionsTMP[langKey]?.text?.replaceAll(' ', '') !== '')? deffaultPositionsTMP[langKey]?.text?.trim() : null;
          var newTextTMP = (professorPositionsTMP[langKey]?.text && professorPositionsTMP[langKey]?.text?.replaceAll(' ', '') !== '')? professorPositionsTMP[langKey]?.text?.trim() : null;
          var hasChanges = (oldTextTMP && remove_tags(format_texts(oldTextTMP?.trim()?.replace(/\n/g, "<br />"))) === remove_tags(format_texts(newTextTMP?.trim()?.replace(/\n/g, "<br />"))))? false : true;

          if (hasChanges) {
            professorPositionsTMP[langKey].text = (professorPositionsTMP[langKey].text && professorPositionsTMP[langKey].text?.replaceAll(' ', '') !== '')? professorPositionsTMP[langKey].text?.trim() : null;
            professorPositionsTMP[langKey].text_change_label = change_label;
            
            professorPositionsTMP[langKey].old_text = null;
            professorPositionsTMP[langKey].old_text_changes = null;
            professorPositionsTMP[langKey].old_text_change_label = null;

            professorPositionsTMP[langKey].revision_state = (currentUser?.rol?.includes('translator'))? 2 : 1;
            professorPositionsTMP[langKey].revision_state_change_label = change_label;

            /* Motion was already in production */
            if (additionalState?.state === 3 || additionalState?.motion_state === 4 || professorPositionsTMP[langKey]?.motion_state > 1) { 
              professorPositionsTMP[langKey].state = 2;
              professorPositionsTMP[langKey].state_change_label = change_label; 
              professorPositionsTMP[langKey].motion_state = 1;
              professorPositionsTMP[langKey].motion_state_change_label = change_label; 
              statesChanged = true;
            }
          }
        }
      }



      var professorTMP = await createOrUpdateUser({ 
        variables: {
          own_user_token: currentUser?.token,
          own_customer_token: currentCustomer?.token,

          id: parseInt(professor?.id),
          positions: JSON.stringify(professorPositionsTMP),
        }
      })

      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (statesChanged) { await removeVideoOverlapStatesFunc(langKey) }

      if (professorTMP?.id) {
        setProfessor(professorTMP)
        getVideo()
      }
      
      setIsCorrecting(false)
      setShowEditPosition(false)
      setLoadingSaveChyron(false)
    }
  }


  /* Correct chyrons */
  async function saveCorrectionChargeFunc() {
    if (professor?.id) {
      setLoadingSaveChyron(true)

      var hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )?.length)? true : false;
      var additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )[0] : null;

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      var statesChanged = false;

      if (professorPositionsTMP && Object.keys(professorPositionsTMP)?.length) {
        for (var langKey of Object.keys(professorPositionsTMP)) {


          if (!professorPositionsTMP[langKey]) { professorPositionsTMP[langKey] = {} }
          var oldTextTMP = (old_text_tmp && old_text_tmp?.replaceAll(' ', '') !== '')? old_text_tmp : text_tmp;
          var newTextTMP = (professorPositionsTMP[langKey]?.text && professorPositionsTMP[langKey]?.text?.replaceAll(' ', '') !== '')? professorPositionsTMP[langKey]?.text?.trim() : null;
          var hasChanges = (oldTextTMP && remove_tags(format_texts(oldTextTMP?.trim()?.replace(/\n/g, "<br />"))) === remove_tags(format_texts(newTextTMP?.trim()?.replace(/\n/g, "<br />"))))? false : true;


          if (hasChanges) {
            professorPositionsTMP[langKey].text = newTextTMP;
            professorPositionsTMP[langKey].text_change_label = change_label;
  
            professorPositionsTMP[langKey].old_text = (hasChanges)? oldTextTMP : null;
            professorPositionsTMP[langKey].old_text_change_label = change_label;
            professorPositionsTMP[langKey].old_text_changes = (hasChanges && parseInt(differenceCounter))? parseInt(differenceCounter) : null;
            
            professorPositionsTMP[langKey].revision_state = 2;
            professorPositionsTMP[langKey].revision_state_change_label = change_label;

            if (additionalState?.state === 3 || additionalState?.motion_state === 4 || professorPositionsTMP[langKey]?.motion_state > 1) { 
              professorPositionsTMP[langKey].state = 2;
              professorPositionsTMP[langKey].state_change_label = change_label; 
              professorPositionsTMP[langKey].motion_state = 1;
              professorPositionsTMP[langKey].motion_state_change_label = change_label; 
              professorPositionsTMP[langKey].motion_state_change_label = change_label; 
              statesChanged = true;
            }
          }
        }
      }

      
      var input = {
        own_user_token: currentUser?.token,
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor?.id),
        positions: JSON.stringify(professorPositionsTMP),
      };

      var professorTMP = await createOrUpdateUser({variables: input})
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (statesChanged) { await removeVideoOverlapStatesFunc(langKey) }


      if (professorTMP?.id) {
        setProfessor(professorTMP)
        getVideo()
      }
      
      setIsCorrecting(false)
      setShowEditPosition(false)
      setLoadingSaveChyron(false)
    }
  }


  /* Remove overlap states */
  async function removeVideoOverlapStatesFunc(langKey) {
    if (parseInt(video?.id) && currentUser?.token && parseInt(professorTMP?.id)) {
      var vidInput = { 
        own_user_token: currentUser?.token,  
        id: parseInt(video?.id), 
        professor_id: parseInt(professorTMP?.id),
        language: langKey,
        action: 'remove',
      };

      var videoResTMP = await updateVideoChyronState({variables: vidInput}); 
      videoResTMP = videoResTMP?.data?.updateVideoChyronState;
      var chyronStates = (videoResTMP)? videoResTMP?.chyron_states : null ;
      
      setChyronStates((chyronStates)? JSON.parse(chyronStates) : null);
    }
  }


  /* SAVE STATE CHANGE */
  async function saveProfessorStateEdit(stateNum, motionStateNum, motion) {
    if (!loading && professor?.id) {
      setLoading(true);
      if (motion) { setLoadingMotionState(motionStateNum); } else { setLoadingState(stateNum); }


      /* Correct states */
      var both = false;
      var hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )?.length)? true : false;
      var additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professor?.id) && el?.language === video?.language )[0] : null;
      var repeatedInserted = (hasAdditionalStates && additionalState?.motion_state === 4 && motionStateNum === 4)? true : false;

      /* Remove done from state */
      if ((motionStateNum === 2 || motionStateNum === 3 || motionStateNum === 4) && stateNum === 3 && motion) { both = true; stateNum = 1; }
      /* Detect done */
      if (stateNum === 3 || motionStateNum === 5) { both = true; }
      /* Remove done from motion state */
      if ((stateNum === 2 || stateNum === 1) && motionStateNum === 5) { motionStateNum = 1; both = true; }
      /* Clicked same motion state */
      if (motionStateNum === motionState && motion && !hasAdditionalStates) { motionStateNum = 1; }
      /* Remove correction from state */
      if (motionStateNum > 1 && motionStateNum < 5 && motion) { both = true; stateNum = 1; }
      /* Remove overlap from state */
      if (repeatedInserted) { both = true; stateNum = 1; motionStateNum = 1; }


      setState(stateNum)
      setMotionState(motionStateNum)


      setTimeout( async () => {

        /* Video state */
        const getIn = (!(motionStateNum > 3 || stateNum === 3) && (parseInt(state) !== parseInt(stateNum) || parseInt(motionState) !== parseInt(motionStateNum)))? true : false;
        var getInUpdate = false;
        const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
        var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
        if (!professorPositionsTMP[video?.language]) { professorPositionsTMP[video?.language] = {} }
        

        var vidInput = { 
          own_user_token: currentUser?.token,  
          id: parseInt(video?.id), 
          professor_id: parseInt(professor?.id),
          language: (video?.language)? video?.language : 'esp',

          state: (stateNum)? parseInt(stateNum) : 1,
          motion_state: (motionStateNum)? parseInt(motionStateNum) : 1,
        };

        if (motion || both) { vidInput[`motion_state_change_label`] = change_label }
        if (!motion || both) { vidInput[`state_change_label`] = change_label }

        if (motionStateNum > 3 || stateNum === 3) { vidInput[`action`] = 'add'; } 
        else { vidInput[`action`] = (!getIn)? 'back' : 'remove'; }

        var videoResTMP = await updateVideoChyronState({variables: vidInput}); 
        videoResTMP = videoResTMP?.data?.updateVideoChyronState;
        var chyronStates = (videoResTMP)? videoResTMP?.chyron_states : null ;
        
        setChyronStates((chyronStates)? JSON.parse(chyronStates) : null);

        if (!getIn && (motionStateNum > 3 || stateNum === 3) && professorPositionsTMP[video?.language].state < 3) { 
          getInUpdate = true;
          both = true;
          motion = true;
          stateNum = 1;
          motionStateNum = 3;
        }


        /* Chyron state */
        if (getIn || getInUpdate) {
          if (!motion || both) { 
            professorPositionsTMP[video?.language].state = (parseInt(stateNum))? parseInt(stateNum) : 1 
            professorPositionsTMP[video?.language].state_change_label = change_label 
          }

          if (motion || both) { 
            professorPositionsTMP[video?.language].motion_state = (parseInt(motionStateNum))? parseInt(motionStateNum) : 1 
            professorPositionsTMP[video?.language].motion_state_change_label = change_label
          }

          var input = {
            own_user_token: currentUser?.token, 
            own_customer_token: currentCustomer?.token,
    
            id: parseInt(professor?.id),
            positions: (professorPositionsTMP)? JSON.stringify(professorPositionsTMP) : null,
          };

          var professorTMP = await updateUserState({variables: input}); 
          if (professorTMP?.data?.createOrUpdateUser) { setProfessor(professorTMP?.data?.createOrUpdateUser) }
        }


        setLoading(false);
        setLoadingMotionState(null);
        setLoadingState(null);
      }, 200)
    }
  }





  



  function getLanguageFunc(langKey) {
    return (langKey && languageList?.filter((el) => (el?.name === langKey) || (el?.abbreviated_name === langKey) || (el?.file_key === langKey))?.length)? languageList?.filter((el) => (el?.name === langKey) || (el?.abbreviated_name === langKey) || (el?.file_key === langKey))[0] : languageList[0];
  }






  
  async function saveProfessorRevisionStateEdit(val) {
    if (!loading && professor?.id) {
      setLoadingRevisionState(true);

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      if (!professorPositionsTMP[video?.language]) { professorPositionsTMP[video?.language] = {} }
      professorPositionsTMP[video?.language].revision_state = (parseInt(val))? parseInt(val) : 1 
      professorPositionsTMP[video?.language].revision_state_change_label = change_label 



      if (professorPositionsTMP[video?.language]?.text) {
        const newNumberOfWordsTMP = getRevNumberOfWordsFunc((professorPositionsTMP[video?.language]?.text)? professorPositionsTMP[video?.language]?.text : null)
        if (newNumberOfWordsTMP) {
          const numWords = (parseInt(professorPositionsTMP[video?.language]?.manual_number_of_words))? parseInt(professorPositionsTMP[video?.language]?.manual_number_of_words) : 0;
          const numWordsEnd = (parseInt(val) === 2)? numWords + newNumberOfWordsTMP : numWords - newNumberOfWordsTMP;
          if (numWordsEnd > 0) { professorPositionsTMP[video?.language].manual_number_of_words = numWordsEnd; } else if (professorPositionsTMP[video?.language]?.manual_number_of_words) { delete professorPositionsTMP[video?.language].manual_number_of_words; }
        }
      }



      var input = {
        own_user_token: currentUser?.token, 
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor.id), 
        positions: JSON.stringify(professorPositionsTMP),
      };



      var professorTMP = await updateUserState({variables: input}); 
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setProfessor(professorTMP) }

      setTimeout(() => { 
        setLoadingRevisionState(false);
        getVideo()
      }, "100")
    }
  }


  async function saveProfessorTradStateEdit(val) {
    if (!loading && professor?.id) {
      setLoadingTradState(true);

      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      if (!professorPositionsTMP[video?.language]) { professorPositionsTMP[video?.language] = {} }
      professorPositionsTMP[video?.language].trad_done = (val)? true : false 
      professorPositionsTMP[video?.language].trad_done_change_label = change_label 

      var input = {
        own_user_token: currentUser?.token, 
        own_customer_token: currentCustomer?.token,

        id: parseInt(professor.id), 
        positions: JSON.stringify(professorPositionsTMP),
      };

      var professorTMP = await updateUserState({variables: input}); 
      professorTMP = professorTMP?.data?.createOrUpdateUser;
      if (professorTMP) { setProfessor(professorTMP) }

      setLoadingTradState(false);

      setTimeout(() => { 
        getVideo()
      }, "100")
    }
  }







  async function setLanguagesFunc(selectedLanguages) {
    if (selectedLanguages?.length) {
      var professorPositionsTMP = (professorPositions && Object.keys(professorPositions)?.length)? {...professorPositions} : {};
      setProfessorPositions(getNewChyronLanguageFunc(selectedLanguages, professorPositionsTMP))
    }
  }

  /* TO DO */
  /* async function removeLastCorrectionFunc(historyObj) {
    if (historyObj?.search_is_correction && historyObj?.search_last_motion_state && historyObj?.search_last_state) {
      const change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;

      await createOrUpdateVideo({variables: {
        own_user_token: currentUser?.token,

        id: parseInt(tradVideo?.id),
        state: historyObj?.search_last_state,
        state_change_label: change_label,
        motion_state: historyObj?.search_last_motion_state,
        motion_state_change_label: change_label,
        extra_facturation_correction: (parseInt(tradVideo?.extra_mark_facturation))? parseInt(tradVideo?.extra_mark_facturation) + 1 : 1,
      }})
    }
  } */




  


  return (!currentUser?.professor) && <>
    

    <ModalSelectLanguage
      open={openAddLanguagePanel}
      setOpen={setOpenAddLanguagePanel}

      setLanguagesFunc={setLanguagesFunc}
      disabledLanguages={disabledLanguages}
    />



  
    <div className={`w-full px-[10px] pt-2 border-t-[1px] border-gray-300  ${(loadingSaveChyron)? 'opacity-50 pointer-events-none' : ''}`}>
      <p className='text-xs font-bold text-gray-600 pb-[2px] whitespace-nowrap px-1'>Chyron/Cargo de {professor?.name} en {videoLanguage?.name}:</p>


      <Collapse orientation="vertical" in={(showCorrectionChanges || isCorrecting)? true : false} timeout={300}>
        <div className='space-y-1 mt-1 mb-2'>
          <div className={`w-full text-base px-3 py-2 flex justify-between items-start space-x-3 border border-gray-400 border-opacity-50 bg-gray-400 bg-opacity-30 test-spacing duration-200 rounded`}>
            <Diff
              string1={remove_tags(format_texts(((isCorrecting)? text_tmp : diffOldText)?.replace(/\n/g, "<br />")))}
              string2={remove_tags(format_texts((isCorrecting)? ((professorPosition?.text)? professorPosition.text?.replace(/\n/g, "<br />") : '') : diffNewText))}
              mode="words"
            />

            {(!currentUser?.rol?.includes('user') && diffOldText && showCorrectionChanges) &&
              <Tooltip disableInteractive title={"Ocultar cambios de la corrección"} placement="bottom" arrow>
                <button 
                  className={`flex items-center justify-center p-1 border border-opacity-50 hover:bg-opacity-50 duration-300 rounded ${(showCorrectionChanges)? 'bg-blue-500 bg-opacity-80 text-white border-blue-600' : 'hover:text-blue-500 bg-gray-300 border-gray-400'}`}
                  onMouseUp={() => setShowCorrectionChanges(!showCorrectionChanges)}
                >
                  {seeChangesIcon}
                </button>
              </Tooltip>
            }
          </div>

          <div className='flex w-full justify-between space-x-1'>
            <TextLogs
              showCorrectionChanges={showCorrectionChanges}
              language={video?.language}
              user_id={professor?.id}

              setHasTextLogs={setHasTextLogs}
              setDiffNewText={setDiffNewText}
              setDiffOldText={setDiffOldText}

              lacksRemoveFunc={true}
              removeLastCorrectionFunc={null /* removeLastCorrectionFunc */}
            />

            <div className='flex items-center space-x-1 text-xs px-2 py-1 border border-gray-400 border-opacity-50 bg-gray-400 bg-opacity-30 rounded whitespace-nowrap'>
              <p>{((isCorrecting)? text_tmp : diffNewText)? getRevNumberOfWordsFunc((isCorrecting)? text_tmp : diffNewText) : 0} palabras</p>
              <p>y</p>
              <p>{(differenceCounter)? differenceCounter : 0} diferencias</p>
            </div>
          </div>
        </div>
      </Collapse>


      <Tooltip disableInteractive title={(video?.language !== 'esp' && !currentUser?.rol?.includes('user') && professor?.position_change_label_eng)? 'Chyron editado por ' + (professor?.position_change_label_eng) : ((professor?.position_change_label_esp && !currentUser?.rol?.includes('user'))? 'Chyron editado por ' + (professor?.position_change_label_esp) : null)} placement="bottom" arrow>
        <Collapse orientation="vertical" in={(!showCorrectionChanges && !showEditPosition && !isCorrecting)? true : false} timeout={300}>
          <div
            className={`
              w-full flex text-sm justify-between items-center text-gray-600 cursor-pointer duration-300 bg-gray-200 pl-2 pr-1 py-1 rounded 
              ${(mode === 'translation' || currentUser?.rol === 'facturator')? '' : 'hover:text-blue-500 hover:bg-gray-300'}
            `}
          >
            <div 
              onMouseUp={()=> (mode === 'translation' || currentUser?.rol === 'facturator')? null : ((currentUser?.rol?.includes('translator') && !video?.is_translation_of)? setIsCorrecting(true) : setShowEditPosition(true))}
              dangerouslySetInnerHTML={{__html: 
                (professorPosition?.text)? professorPosition?.text?.replaceAll(/\n/g, "<br />") 
                : 'Sin chyron/cargo en ' + videoLanguage?.name
              }}
            ></div>
          





            <div className='flex flex-wrap justify-end w-[70px] min-w-[70px]'>
              {(!currentUser?.rol?.includes('user') && ((video?.language === 'esp' || video?.language === 'eng')) && hasTextLogs) &&
                <Tooltip disableInteractive title={"Ver cambios de la corrección"} placement="bottom" arrow>
                  <button 
                    className={`flex items-center justify-center p-1 m-[2px] border border-opacity-50 hover:bg-opacity-50 duration-300 rounded ${(showCorrectionChanges)? 'bg-blue-500 bg-opacity-80 text-white' : 'hover:text-blue-500 bg-gray-300 border-gray-400'}`}
                    onMouseUp={() => setShowCorrectionChanges(!showCorrectionChanges)}
                  >
                    {seeChangesIcon}
                  </button>
                </Tooltip>
              }


              <button
                className='flex items-center justify-center p-1 m-[2px] bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-blue-500 duration-300 rounded'
                onMouseUp={() => { setShowEditPosition(true); setIsCorrecting(false) }}
              >
                {(currentUser?.rol?.includes('translator'))? tradIcon : editIcon}
              </button>


              {(!video?.is_translation_of && text_tmp && (video?.language === 'esp' || video?.language === 'eng') && (currentUser?.rol?.includes('translator') || currentUser?.rol === 'admin'))?
                <button
                  className='flex items-center justify-center p-1 m-[2px] bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-blue-500 duration-300 rounded'
                  onMouseUp={() => { setShowEditPosition(true); setIsCorrecting(true) }}
                >
                  {spellCheckIcon}
                </button>
              : null }
            </div>
          </div>
        </Collapse>
      </Tooltip>


      <Collapse orientation="vertical" in={(showEditPosition || isCorrecting)? true : false} timeout={300}>
        <div className={`w-full flex space-x-1 pt-2`}>
          <div className='w-full flex flex-wrap items-start space-y-4'>

            <TextField
              value={(professorPosition && professorPosition?.text)? professorPosition?.text : ''}
              onChange={event => {
                var professorPositionsTMP = (professorPositions)? {...professorPositions} : {};
                if (!(videoLanguage?.abbreviated_name in professorPositionsTMP)) { professorPositionsTMP[videoLanguage?.abbreviated_name] = {}}
                const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null

                professorPositionsTMP[videoLanguage?.abbreviated_name].text = (event.target.value)? event.target.value : null;
                professorPositionsTMP[videoLanguage?.abbreviated_name].text_change_label = change_label;

                setProfessorPositions(professorPositionsTMP)
              }}
              label={"Escribe el chyron/cargo en " + videoLanguage?.name}
              variant="outlined"
              className='w-full mui-bg-gray-200'
              size="small"
              autoComplete='off'
              multiline
              maxRows={5}
              minRows={2}
              disabled={(false)? true : false}

              onKeyDown={(event) => {
                if(event.code === 'Enter' && event.ctrlKey) {
                  if (isCorrecting) {
                    saveCorrectionChargeFunc()
                  } else {
                    saveChargeFunc()
                  }
                } else if (event.code === 'Escape') {
                  setIsCorrecting(false)
                  setShowEditPosition(false)
                }
              }}
            />


            {(!isCorrecting && professorPositions && Object.keys(professorPositions))?
              Object.keys(professorPositions)?.map(langKey => {

                const lantTMP = getLanguageFunc(langKey)
                
                return (langKey !== videoLanguage?.abbreviated_name)? 
                  <TextField
                    key={'text-box-'+langKey} 
                    value={(professorPositions && professorPositions[langKey]?.text)? professorPositions[langKey]?.text : ''}
                    onChange={event => {
                      var professorPositionsTMP = (professorPositions)? {...professorPositions} : {};
                      if (!professorPositionsTMP[langKey]) { professorPositionsTMP[langKey] = {}}
                      const change_label = (currentUser?.name)? (currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2))) : null

                      professorPositionsTMP[langKey].text = (event.target.value)? event.target.value : null;
                      professorPositionsTMP[langKey].text_change_label = change_label;
                      setProfessorPositions(professorPositionsTMP)
                    }}
                    label={"Escribe el chyron/cargo en " + lantTMP?.name}
                    variant="outlined"
                    className='w-full mui-bg-gray-200'
                    size="small"
                    autoComplete='off'
                    multiline
                    maxRows={5}
                    minRows={2}
                    disabled={(false)? true : false}
      
                    onKeyDown={(event) => {
                      if(event.code === 'Enter' && event.ctrlKey) {
                        if (isCorrecting) {
                          saveCorrectionChargeFunc()
                        } else {
                          saveChargeFunc()
                        }
                      } else if (event.code === 'Escape') {
                        setIsCorrecting(false)
                        setShowEditPosition(false)
                      }
                    }}
                  />
                : null 
              })
            : null }

          </div>

          {(showEditPosition || isCorrecting) &&
            <div className='space-y-1'>
              <button
                className='flex items-center justify-center p-1 bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-100 bg-opacity-60 hover:text-blue-500 duration-300 rounded'
                onMouseUp={()=> (isCorrecting)? saveCorrectionChargeFunc() : saveChargeFunc()}
              >
                  {(loadingSaveChyron)? loadingIcon : saveIcon}
              </button>
              

              {(!isCorrecting && (currentUser?.rol === 'admin' || currentUser?.rol?.includes('translator')))? 
                <Tooltip disableInteractive title={"Añadir idioma"} placement="right" arrow>
                  <button
                    className='flex items-center justify-center p-1 bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-100 bg-opacity-60 hover:text-green-500 duration-300 rounded'
                    onMouseUp={()=> setOpenAddLanguagePanel(!openAddLanguagePanel)}
                  >
                    {tradIcon}
                  </button>
                </Tooltip>
              : null }


              <button
                className='flex items-center justify-center p-1 bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-100 bg-opacity-60 hover:text-red-500 duration-300 rounded'
                onMouseUp={()=> { setIsCorrecting(false); setShowEditPosition(false) }}
              >
                  {cancelIcon}
              </button>
            </div>
          }
        </div>
      </Collapse>




      <div className='w-full flex items-center justify-between space-x-2 pt-[3px] pb-2'>
        <div>
        </div>

        {(!loadingNewStates)?
          <StateChanger
            type_name={'user'}
            object={professor}
            video={video}

            saveStateEdit={saveProfessorStateEdit}
            saveRevisionStateEdit={saveProfessorRevisionStateEdit}
            saveTradStateEdit={saveProfessorTradStateEdit}

            state={state}
            stateChangeLabel={(deffaullt_overlap_state?.state_change_label)? deffaullt_overlap_state?.state_change_label : (deffault_position?.state_change_label)? deffault_position?.state_change_label : null}
            loadingState={loadingState}
            motionState={motionState}
            motionStateChangeLabel={(deffault_position?.motion_state_change_label)? deffault_position?.motion_state_change_label : null}
            loadingMotionState={loadingMotionState}
            overlapStates={chyronStates}

            revisionState={revisionState}
            revisionStateChangeLabel={(deffault_position?.revision_state_change_label)? deffault_position?.revision_state_change_label : null}
            loadingRevisionState={loadingRevisionState}

            tradState={tradState}
            tradStateChangeLabel={(deffault_position?.trad_state_change_label)? deffault_position?.trad_state_change_label : null}
            loadingTradState={loadingTradState}

            language={video?.language}

            small={true}
            disabled={false}
            showRevisionState={true}
          />
        : 
          <div className='flex space-x-1 items-center text-gray-400 text-xs bg-gray-200 rounded pl-1 pr-2 animate-pulse'>
            <div className='py-[2px]'>{loadingIcon}</div>
            <p>Recargando estados...</p>
          </div>
        }
      </div>
    </div>
  </>
}