import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';

import GetAllMarkTypes from '../graphql/queries/GetAllMarkTypes';
import CreateOrUpdateMarkType from '../graphql/mutations/CreateOrUpdateMarkType';
import GetAllCustomersForTypes from '../graphql/queries/GetAllCustomersForTypes';

import ModalSelectCustomer from './ModalSelectCustomer';

import CircularProgress from '@mui/material/CircularProgress';
import CustomTextField from './Inputs/CustomTextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Collapse } from '@mui/material';




import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
  sortableKeyboardCoordinates,
  arrayMove,
} from '@dnd-kit/sortable';

import {
  DndContext,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { CSS } from '@dnd-kit/utilities';
import RemoveVideoMarkType from '../graphql/mutations/RemoveVideoMarkType';




const deffaultColors = [
  '107, 114, 128',
  '65, 112, 255',
  '135, 206, 235',
  '58, 176, 164',
  '183, 201, 75',
  '247, 220, 45',
  '255, 171, 0',
  '255, 112, 67',
  '255, 98, 101',
  '240, 98, 146',
  '205, 81, 205',
  '143, 108, 214',
  '171, 130, 104',
]




const noTextIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M132-216q-15.3 0-25.65-10.29Q96-236.58 96-251.79t10.35-25.71Q116.7-288 132-288h312q15.3 0 25.65 10.29Q480-267.42 480-252.21t-10.35 25.71Q459.3-216 444-216H132Zm0-180q-15.3 0-25.65-10.29Q96-416.58 96-431.79t10.35-25.71Q116.7-468 132-468h120q15.3 0 25.65 10.29Q288-447.42 288-432.21t-10.35 25.71Q267.3-396 252-396H132Zm0-180q-15.3 0-25.65-10.29Q96-596.58 96-611.79t10.35-25.71Q116.7-648 132-648h120q15.3 0 25.65 10.29Q288-627.42 288-612.21t-10.35 25.71Q267.3-576 252-576H132Zm420 240q-79.68 0-135.84-56.23-56.16-56.22-56.16-136Q360-608 416.23-664q56.22-56 136-56Q632-720 688-663.84q56 56.16 56 135.84 0 30-8.5 57.5T711-420l127.84 127.84Q850-281 850-267t-11 25q-11 11-25.5 11t-25.66-11.08L660-369q-23 16-50.5 24.5T552-336Zm0-72q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
const singleLineIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M228.29-444q-15.29 0-25.79-10.29t-10.5-25.5q0-15.21 10.34-25.71 10.35-10.5 25.64-10.5h503.73q15.29 0 25.79 10.29t10.5 25.5q0 15.21-10.34 25.71-10.35 10.5-25.64 10.5H228.29Z"/></svg>
const multiLineIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M180.29-264q-15.29 0-25.79-10.29t-10.5-25.5q0-15.21 10.34-25.71t25.63-10.5h359.74q15.29 0 25.79 10.29t10.5 25.5q0 15.21-10.34 25.71T540.03-264H180.29ZM180-444q-15.3 0-25.65-10.29Q144-464.58 144-479.79t10.35-25.71Q164.7-516 180-516h600q15.3 0 25.65 10.29Q816-495.42 816-480.21t-10.35 25.71Q795.3-444 780-444H180Zm0-180q-15.3 0-25.65-10.29Q144-644.58 144-659.79t10.35-25.71Q164.7-696 180-696h600q15.3 0 25.65 10.29Q816-675.42 816-660.21t-10.35 25.71Q795.3-624 780-624H180Z"/></svg>
const listIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M324-600q-15.3 0-25.65-10.29Q288-620.58 288-635.79t10.35-25.71Q308.7-672 324-672h456q15.3 0 25.65 10.29Q816-651.42 816-636.21t-10.35 25.71Q795.3-600 780-600H324Zm0 156q-15.3 0-25.65-10.29Q288-464.58 288-479.79t10.35-25.71Q308.7-516 324-516h456q15.3 0 25.65 10.29Q816-495.42 816-480.21t-10.35 25.71Q795.3-444 780-444H324Zm0 156q-15.3 0-25.65-10.29Q288-308.58 288-323.79t10.35-25.71Q308.7-360 324-360h456q15.3 0 25.65 10.29Q816-339.42 816-324.21t-10.35 25.71Q795.3-288 780-288H324ZM180-600q-14 0-25-11t-11-25.5q0-14.5 11-25t25.5-10.5q14.5 0 25 10.35T216-636q0 14-10.35 25T180-600Zm0 156q-14 0-25-11t-11-25.5q0-14.5 11-25t25.5-10.5q14.5 0 25 10.35T216-480q0 14-10.35 25T180-444Zm0 156q-14 0-25-11t-11-25.5q0-14.5 11-25t25.5-10.5q14.5 0 25 10.35T216-324q0 14-10.35 25T180-288Z"/></svg>
const chyronIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-269q56-46 124-68.5T480-360q72 0 140 22t124 69v-475H216v475Zm264.24-139Q540-408 582-450.24q42-42.24 42-102T581.76-654q-42.24-42-102-42T378-653.76q-42 42.24-42 102T378.24-450q42.24 42 102 42ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm49-72h430q-46-35-101-53.5T480-288q-59 0-113.5 18.5T265-216Zm215-264q-30 0-51-21t-21-51q0-30 21-51t51-21q30 0 51 21t21 51q0 30-21 51t-51 21Zm0-72Z"/></svg>

const noFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M864-600.16v315.2Q864-267 852.75-258T828-249q-13.5 0-24.75-9.22T792-285v-315H492.4q-14.4 0-27.4-5-13-5-24-16l-93-93q-11-11.26-9.5-24.13T348-759q8-8 20.87-9.5Q381.74-770 393-759l87 87h312q29.7 0 50.85 21.1Q864-629.79 864-600.16ZM168-192q-29 0-50.5-21.5T96-264v-432q0-29.7 21.5-50.85Q139-768 168-768h24l72 72h-96v432h427L81-777q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l695.66 695.66Q839-120 839-106t-11 25.48Q817-70 803-70t-25-11L667-192H168Zm210-288Zm201-3Z"/></svg>
const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M216-144q-29.7 0-50.85-21.5Q144-187 144-216v-528q0-29 21.15-50.5T216-816h528q29.7 0 50.85 21.5Q816-773 816-744v528q0 29-21.15 50.5T744-144H216Zm0-72h528v-528H216v528Zm0 0v-528 528Zm84-72h360.19Q671-288 676-298t-2-19L566-461q-5.25-8-14-8t-14 8l-94 125-58-77q-5.25-8-14-8t-14 8l-71.82 96.03Q279-308 284.25-298q5.25 10 15.75 10Z"/></svg>
const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M624-465q8-5.36 8-15.18T624-495L412-630q-9-6-18.5-.75T384-615v269.68q0 11.32 9.5 16.32t18.5-1l212-135ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h528q29.7 0 50.85 21.15Q816-773.7 816-744v528q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm0-72h528v-528H216v528Zm0-528v528-528Z"/></svg>
const schemaIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M192-144.12v-72.13Q192-246 213.15-267T264-288h24v-84h-24q-29.7 0-50.85-21.19Q192-414.37 192-444.12v-72.13Q192-546 213.15-567T264-588h24v-60h-24q-29.7 0-50.85-21.19Q192-690.37 192-720.12v-72.13Q192-822 213.15-843T264-864h120q29.7 0 50.85 21.19Q456-821.63 456-791.88v72.13Q456-690 434.85-669T384-648h-24v60h24q29.7 0 50.85 21.15Q456-545.7 456-516h96q0-30 21.15-51T624-588h120q29.7 0 50.85 21.19Q816-545.63 816-515.88v72.13Q816-414 794.85-393T744-372H624q-29.7 0-50.85-21.15Q552-414.3 552-444h-96q0 30-21.15 51T384-372h-24v84h24q29.7 0 50.85 21.19Q456-245.63 456-215.88v72.13Q456-114 434.85-93T384-72H264q-29.7 0-50.85-21.19Q192-114.37 192-144.12Zm72 .12h120v-72H264v72Zm0-300h120v-72H264v72Zm360 0h120v-72H624v72ZM264-720h120v-72H264v72Zm60-36Zm0 276Zm360 0ZM324-180Z"/></svg>
const folderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M168-192q-29 0-50.5-21.5T96-264v-432q0-29.7 21.5-50.85Q139-768 168-768h185.64q14.35 0 27.36 5 13 5 24 16l75 75h312q29.7 0 50.85 21.15Q864-629.7 864-600v336q0 29-21.15 50.5T792-192H168Zm0-72h624v-336H450l-96-96H168v432Zm0 0v-432 432Z"/></svg>
const dragIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M359.788-192Q330-192 309-213.212q-21-21.213-21-51Q288-294 309.212-315q21.213-21 51-21Q390-336 411-314.788q21 21.213 21 51Q432-234 410.788-213q-21.213 21-51 21Zm240 0Q570-192 549-213.212q-21-21.213-21-51Q528-294 549.212-315q21.213-21 51-21Q630-336 651-314.788q21 21.213 21 51Q672-234 650.788-213q-21.213 21-51 21Zm-240-216Q330-408 309-429.212q-21-21.213-21-51Q288-510 309.212-531q21.213-21 51-21Q390-552 411-530.788q21 21.213 21 51Q432-450 410.788-429q-21.213 21-51 21Zm240 0Q570-408 549-429.212q-21-21.213-21-51Q528-510 549.212-531q21.213-21 51-21Q630-552 651-530.788q21 21.213 21 51Q672-450 650.788-429q-21.213 21-51 21Zm-240-216Q330-624 309-645.212q-21-21.213-21-51Q288-726 309.212-747q21.213-21 51-21Q390-768 411-746.788q21 21.213 21 51Q432-666 410.788-645q-21.213 21-51 21Zm240 0Q570-624 549-645.212q-21-21.213-21-51Q528-726 549.212-747q21.213-21 51-21Q630-768 651-746.788q21 21.213 21 51Q672-666 650.788-645q-21.213 21-51 21Z"/></svg>


const deffaultTypes = [
  [
    { key: null, label: 'Solo observaciones', icon: noTextIcon },
    { key: 'basic_text', label: 'Texto de una línea', icon: singleLineIcon },
    { key: 'text', label: 'Texto multilínea', icon: multiLineIcon },
    { key: 'list', label: 'Listado', icon: listIcon },
    { key: 'chyron', label: 'Chyron', icon: chyronIcon },
  ],
  [
    { key: null, label: 'Sin fichero', icon: noFolderIcon },
    { key: 'image', label: 'Imagen', icon: imageIcon },
    { key: 'video', label: 'Vídeo', icon: videoIcon },
    { key: 'scheme', label: 'Esquema', icon: schemaIcon },
    { key: 'file', label: 'Cualquiera', icon: folderIcon },
  ],
  [
    { key: false, label: 'Solo un fichero', icon: folderIcon },
    { key: true, label: 'Más de un fichero', icon: folderIcon },
  ]
]







export default function CustomerEditorMarks({
  
  customer,
  openCustomerSelector,
  loading,
  step,
  setStep,
  
  small,

}) {


  const { currentUser } = useContext(AuthContext)
  const { setSuccessAlertMsg, setErrorAlertMsg } = useContext(LayoutContext)
  const { token } = useParams()


  const [markTypes, setMarkTypes] = useState(null);

  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState(null);

  const [selectedTypeID, setSelectedTypeID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [showTypePanel, setShowTypePanel] = useState(false);
  const [unChangedSelectedType, setUnChangedSelectedType] = useState(null);
  
  const [newTypeThumb, setNewTypeThumb] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [textTypeArray, setTextTypeArray] = useState(null);
  const [fileTypeArray, setFileTypeArray] = useState(null);

  const [loadingType, setLoadingType] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  
  /* Icon vars */
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const paletteIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30.5-149.5t84-122Q264-804 335.5-834T488-864q78 0 146.5 27T754-763q51 47 80.5 110T864-518q0 96-67 163t-163 67h-68q-8 0-14 5t-6 13q0 15 15 25t15 53q0 37-27 66.5T480-96Zm0-384Zm-216 36q25 0 42.5-17.5T324-504q0-25-17.5-42.5T264-564q-25 0-42.5 17.5T204-504q0 25 17.5 42.5T264-444Zm120-144q25 0 42.5-17.5T444-648q0-25-17.5-42.5T384-708q-25 0-42.5 17.5T324-648q0 25 17.5 42.5T384-588Zm192 0q25 0 42.5-17.5T636-648q0-25-17.5-42.5T576-708q-25 0-42.5 17.5T516-648q0 25 17.5 42.5T576-588Zm120 144q25 0 42.5-17.5T756-504q0-25-17.5-42.5T696-564q-25 0-42.5 17.5T636-504q0 25 17.5 42.5T696-444ZM480-168q11 0 17.5-8.5T504-192q0-16-15-28t-15-50q0-38 26.5-64t64.5-26h69q66 0 112-46t46-112q0-115-88.5-194.5T488-792q-134 0-227 91t-93 221q0 130 91 221t221 91Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-120q-17 0-28.5-11.5T120-160v-97q0-16 6-30.5t17-25.5l505-504q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L313-143q-11 11-25.5 17t-30.5 6h-97Zm544-528 56-56-56-56-56 56 56 56Z"/></svg>;
  const importIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h180q15.3 0 25.65 10.29Q384-747.42 384-732.21t-10.35 25.71Q363.3-696 348-696H168v432h624v-432H612q-15.3 0-25.65-10.29Q576-716.58 576-731.79t10.35-25.71Q596.7-768 612-768h180q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm276-282v-258q0-15.3 10.29-25.65Q464.58-768 479.79-768t25.71 10.35Q516-747.3 516-732v258l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5t-10.86 25.36L505-361q-10.64 11-24.82 11T455-361L312.86-503.14Q302-514 302.5-528.5T314-554q11-11 25.5-11t25.5 11l79 80Z"/></svg>
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>


  

  const [createOrUpdateMarkType] = useMutation(CreateOrUpdateMarkType)
  const [removeMarkType] = useMutation(RemoveVideoMarkType)

  const [getTypes, { data: markTypesData, loading: loadingTypes }] = useLazyQuery(GetAllMarkTypes, {
    fetchPolicy: 'no-cache',
    variables: {
      own_customer_token: customer?.token,
    }
  })

  const [getCustomers, { data: dataCustomers, loading: loadingCustomers }] = useLazyQuery(GetAllCustomersForTypes, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      user_id: (currentUser?.professor)? parseInt(currentUser?.id) : null,
    }
  })



  

  

  useEffect(() => { 
    if (openCustomerSelector) {
      setShowCustomerSelector(true)
    }
  }, [openCustomerSelector])


  useEffect(() => { 
    if (customer?.token) {
      getTypes()
      getCustomers()
    }
  }, [customer])





  useEffect(() => { 
    setCustomers((dataCustomers?.customers?.length)? dataCustomers.customers : null)
  }, [dataCustomers])


  useEffect(() => { 
    var markTypesTMP = (markTypesData?.videoMarkTypes?.length)? JSON.parse(JSON.stringify(markTypesData?.videoMarkTypes)) : null;

    const noteMark = {
      id: 'null',
      name: "Nota",
      color: "107, 114, 128",
      thumbnail: "0.png",
      type: "basic_text",

      multiple: false,
      default_difficulty: 1,
    }
    
    const newOne = {
      id: 'new',
      name: "Crear nueva tipología",
      color: "107, 114, 128",
      thumbnail: "new.png",
      type: "text",

      multiple: false,
      default_difficulty: 1,
    }

    setMarkTypes((markTypesTMP?.length)? [newOne, noteMark, ...markTypesTMP] : [newOne, noteMark])
  }, [markTypesData])
  

  useEffect(() => {
    setLoadingType(true)

    const selectedMarkTypeTMP = (markTypes?.length)? markTypes?.filter((el) => el?.id === selectedTypeID)[0] : null;
    setSelectedType((selectedMarkTypeTMP?.name)? {...selectedMarkTypeTMP} : null)
    setUnChangedSelectedType((selectedMarkTypeTMP?.name)? {...selectedMarkTypeTMP} : null)
    setShowTypePanel((selectedMarkTypeTMP?.name)? true : false)

    const typeSplit = (selectedMarkTypeTMP?.type)? selectedMarkTypeTMP?.type?.split(' ')?.filter((el) => el !== 'special') : null
    setTextTypeArray((typeSplit?.length && typeSplit[0] && typeSplit[0] !== 'null')? typeSplit[0] : null)
    setFileTypeArray((typeSplit?.length && typeSplit[1] && typeSplit[1] !== 'null')? typeSplit[1] : null)
    setNewTypeThumb(null)
    if (document.getElementById('new-mark-thumbnail')?.value) { document.getElementById('new-mark-thumbnail').value = ""; }

    setTimeout(() => {
      setLoadingType(false)
    }, 500 )
  }, [markTypes, selectedTypeID])






  


  async function saveMarkTypeData() {
    setLoadingSave(true)
    var editedMarkType = null;


    if (customer.token && (parseInt(selectedType?.id) || selectedType?.id === "new")) {
      const response = await createOrUpdateMarkType({ 
        variables: {
          own_user_token: currentUser?.token,
          id: (parseInt(selectedType?.id))? parseInt(selectedType?.id) : null,
          name: (selectedType?.name)? selectedType?.name : 'Sin nombre',
          color: (selectedType?.color)? selectedType?.color : null,
          type: `${(textTypeArray)? textTypeArray : 'null'} ${(fileTypeArray)? fileTypeArray : 'null'}`,
          multiple: (selectedType?.multiple)? true : false,
          customer_token: customer.token,
        }
      })

      editedMarkType = (response?.data?.createOrUpdateMarkType?.id)? response?.data?.createOrUpdateMarkType : null
    }


    if (editedMarkType?.id && newTypeThumb?.name) {
      const matkThumbData = new FormData();
      matkThumbData.append('own_user_token', currentUser?.token);
      matkThumbData.append('mark_type_id', editedMarkType?.id);
      matkThumbData.append('file', newTypeThumb);

      const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}newMarkThumb`, matkThumbData)
    }

    getTypes()

    setTimeout(() => {
      setLoadingSave(false)
      setSuccessAlertMsg('Datos guardados correctamente.')
      setSelectedTypeID(null)
    }, "500")
  }


  async function removeMarkTypeData() {
    setLoadingRemove(true)

    var response = null
    if (!selectedType?.type?.includes('special') && window.confirm("¿Seguro que quieres eliminar esta tipología? Solo podrá ser eliminada si no se está utilizando y su eliminación será completa.")) {
      response = await removeMarkType({ 
        variables: {
          own_user_token: currentUser?.token,
          id: (parseInt(selectedType?.id))? parseInt(selectedType?.id) : null,
        }
      })
    }

    getTypes()

    setTimeout(() => {
      if (response?.data?.removeMarkType?.status) {
        setSuccessAlertMsg('Datos guardados correctamente.')
      } else if (response?.data?.removeMarkType) {
        setErrorAlertMsg('Esta tipología tiene marcas asociadas y por ello no puede ser eliminada.')
      }

      setLoadingRemove(false)
      setSelectedTypeID(null)
    }, "500")
  }


  async function savePositionsFunc() {
    if (markTypes?.length) {
      setLoadingSave(true)

      for (let markKey in markTypes) {
        const markTypeTMP = markTypes[markKey];

        if (parseInt(markTypeTMP?.id)) {
          await createOrUpdateMarkType({ 
            variables: {
              own_user_token: currentUser?.token,
              id: (parseInt(markTypeTMP?.id))? parseInt(markTypeTMP?.id) : null,
              position: parseInt(markKey),
              /* customer_token: customer.token, */
            }
          })
        }
      }

      setTimeout(() => {
        setLoadingSave(false)
        setSuccessAlertMsg('Datos guardados correctamente.')
        if (step) {setStep(7)}
      }, "500")
    }
  }







  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = ({ active, over }) => {
    if (!over) { return; }
    if (active.id == over.id) { return; }

    setMarkTypes((markTypes) => {
      return arrayMove(
        markTypes,
        markTypes.findIndex((it) => it.id == active.id),
        markTypes.findIndex((it) => it.id == over.id)
      );
    });
  };




  
  const SortableItem = ({ markType }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: markType?.id });

    const disableDrag = (!parseInt(markType?.id) || selectedTypeID)? true : false

    const style = {
      transform: CSS?.Transform?.toString(transform),
      transition,
    };

    return (
      <div 
        ref={(!disableDrag)? setNodeRef : null}
        style={style}
        {...attributes}
        className="group/typeBox snap-center h-min pr-1 lg:pr-[10px] py-2 w-[calc(33.33%-0.01rem)] md:w-[calc(25%-0.01rem)] lg:w-[calc(20%-0.01rem)]"
      >
        <div>
          <div {...listeners}>
            <div className={`flex items-center space-x-1 py-1 px-[6px] mb-1 bg-gray-200 rounded-md duration-200 ${(disableDrag)? 'cursor-default' : 'hover:bg-gray-300 cursor-grab'}`}>
              <div className='p-[5px] rounded-full' style={{ backgroundColor: markType?.color? `rgba(${markType?.color}, 0.35)` : 'rgba(107, 114, 128, 0.1)' }}></div>
              <p className={`w-full text-left text-xs truncate transition-colors duration-200 ${(selectedTypeID === markType?.id)? 'text-black' : 'text-gray-600 group-hover/typeBox:text-black'}`}>{markType?.name}</p>
              {(!disableDrag) && <div className='opacity-60 h-[15px] w-[15px] flex items-center justify-center'>{dragIcon}</div>} 
            </div>
          </div>

          <button
            className={`relative flex justify-center items-center active:opacity-50 bg-gray-300 overflow-hidden duration-200 border-2 rounded-md
              ${(selectedTypeID === markType?.id)? 'border-blue-600 opacity-100' : 'border-gray-400 group-hover/typeBox:border-gray-500 opacity-50 hover:opacity-100'}
            `}

            onClick={()=> {
              if (markType?.id === selectedTypeID) {
                setShowTypePanel(false)
    
                setTimeout(() => {
                  setSelectedType(null)
                  setSelectedTypeID(null)
                }, 300)
              } else {
                setShowTypePanel(false)
    
                setTimeout(() => {
                  setSelectedTypeID(markType?.id)
                  setSelectedType(markType)
    
                  setTimeout(() => {
                    setShowTypePanel(true)
                  }, 300)
                }, 300)
              }
            }}
          >
            <img 
              src={
                (parseInt(markType?.id) && markType?.thumbnail)? `${process.env.REACT_APP_API_ROUTE}customer_media/${customer?.token}/mark_thumbnails/${markType.thumbnail}` 
                : `${process.env.REACT_APP_API_ROUTE}mark_type_thumbnails/${(markType?.thumbnail)? markType?.thumbnail : (markType)? '0_no_image.png' : '0.png'}`
              } 
              alt='mark_type_thumb'
              className='absolute z-20 w-full object-cover pointer-events-none'
            />

            <img
              src="/no_image.png"
              alt='no_mark_type_thumb'
              className='z-0 object-contain pointer-events-none'
            />
          </button>
        </div>
      </div>
    );
  };
  











  return ((!loading && customer?.token)? 
    <div className='flex-col space-y-6'>

      <div className="rounded-lg border border-gray-300 overflow-hidden shadow-md">
        <div 
          className={`relative flex flex-wrap items-start justify-start snap-y snap-mandatory pb-1 pt-1 pl-3 bg-gray-100 overflow-y-auto overflow-x-hidden transition-height duration-300
            ${
              (small)?
                (!selectedTypeID)? 'h-[146px] md:h-[290px] lg:h-[312px]' : 'h-[158px]'
              :
                (!selectedTypeID)? 'h-[336px] md:h-[320px] lg:h-[348px]' : 'h-[176px]'
            }
          `}
        >
          <div className={`${(loadingSave || loadingRemove)? 'opacity-70' : 'opacity-0 pointer-events-none'} absolute z-[9990] top-0 left-0 w-full h-full flex items-center justify-center space-x-2 bg-gray-200 duration-200`}>
            {loadingIcon}
            <p>{(loadingSave)? 'Guardando' : (loadingRemove)? 'Eliminadno' : 'Cargando'}...</p>
          </div>

          {(markTypes?.length)?
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
              <SortableContext items={markTypes} strategy={rectSortingStrategy}>
                {markTypes?.map((markType, key) => {
                  return <SortableItem
                    key={`mark-type-${markType?.id}-${key}`}
                    markType={markType}
                  />
                })}
              </SortableContext>
            </DndContext>
          : null }
        </div>
      </div>



      {/* Inputs */}
      <Collapse orientation="vertical" in={(showTypePanel)? true : false} timeout={300} className='overflow-hidden rounded-md'>
        <div className='space-y-2'>
          <div className={`flex items-center flex-wrap lg:flex-nowrap ${(unChangedSelectedType?.id === 'null')? 'opacity-50 pointer-events-none' : ''}`}>

            <button className="w-full lg:max-w-[320px] relative flex justify-center items-center rounded-md bg-gray-600 overflow-hidden duration-200 border-2 border-gray-400">
              <div 
                className='absolute z-30 overflow-hidden p-5 flex flex-col items-center justify-center space-y-2 text-white bg-gray-500 bg-opacity-60 opacity-0 hover:opacity-70 active:bg-blue-300 duration-300'
                onClick={()=> {
                  setNewTypeThumb(null);
                  document.getElementById('new-mark-thumbnail').value = "";
                  document.getElementById('new-mark-thumbnail')?.click();
                }}
              >
                <div className='pb-7 pt-14 px-32 scale-[1.8]'>{editIcon}</div>

                <div className='p-1 text-xs bg-gray-800 bg-opacity-60 rounded-md whitespace-nowrap'>
                  <p>Imágen png, jpeg o gif</p>
                  <p>Con un aspect ratio 16:9</p>
                </div>

                <input
                  id="new-mark-thumbnail"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  onChange={(e) => setNewTypeThumb(e.target.files[0])}
                />
              </div>

              <img
                src={
                  (newTypeThumb)? URL.createObjectURL(newTypeThumb) 
                  : (parseInt(selectedType?.id) && selectedType?.thumbnail)? `${process.env.REACT_APP_API_ROUTE}customer_media/${customer?.token}/mark_thumbnails/${selectedType.thumbnail}` 
                  : `${process.env.REACT_APP_API_ROUTE}mark_type_thumbnails/${(selectedType?.thumbnail)? selectedType?.thumbnail : (selectedType)? '0_no_image.png' : '0.png'}`
            } 
                alt='mark_type_thumb'
                className='absolute z-20 w-full object-cover'
              />          
                      
              <img
                src="/no_image.png"
                alt='no_mark_type_thumb'
                className='z-10 object-contain'
              />
            </button>




            <div className='w-full lg:pl-4 lg:pt-0 pt-4 flex items-center'>
              {(!loadingTypes && !loadingCustomers && !loadingType)?
                <div className='w-full flex-col space-y-1'>
                  <div className='w-full px-1 pb-1'>
                    <p className='text-3xl opacity-60'>{(parseInt(unChangedSelectedType?.id))? `Editando: ${unChangedSelectedType?.name}` : unChangedSelectedType?.name}</p>
                  </div>


                  <div className='w-full flex-col items-center space-y-2'>
                    <div className='w-full flex items-center space-x-1'>
                      {/* Color selector */}
                      <div
                        style={{ marginRight: '1px' }}
                        className="relative flex items-center min-w-fit rounded-md overflow-hidden"
                        onClick={() => (showColors)? null : setShowColors(true)}
                      >
                        <div className={`absolute left-0 top-0 p-[5px] text-white z-10 pointer-events-none duration-300 ${(showColors)? 'opacity-0' : ''}`}>
                          <div className='scale-110'>{paletteIcon}</div>
                        </div>

                        {deffaultColors?.map((color) => {
                          const noColor = (color === '107, 114, 128')? true : false;

                          return <div
                            key={`color-(${color})`}
                            className={`transition-opacity duration-200 rounded-md overflow-hidden
                              ${(color === selectedType?.color || (noColor && !selectedType?.color))? 'opacity-80' : (showColors)? 'opacity-50 hover:opacity-80' : 'opacity-60'}
                            `}
                          >
                            <div 
                              className={`h-[30px] hover:scale-90 cursor-pointer rounded-md transition-all duration-500 overflow-hidden
                                ${(color === selectedType?.color || (noColor && !selectedType?.color))? 'w-[30px] mr-[2px]' : (showColors)? 'w-[30px] mr-[2px]' : 'w-[0px] mr-0'}
                              `}
                              style={{ backgroundColor: `rgb(${(color)? color : '107, 114, 128'})` }}
                              onClick={() => {
                                if (showColors) {
                                  var selectedTypeTMP = {...selectedType}
                                  selectedTypeTMP.color = (color)? color : null;
                                  setSelectedType(selectedTypeTMP)
                                  setShowColors(false)
                                }
                              }}
                            ></div>
                          </div>
                        })}
                      </div>


                      <CustomTextField
                        label="Nombre de la tipología" 
                        value={(selectedType?.name)? selectedType?.name : ''} 
                        onChange={(event) => {
                          var selectedTypeTMP = {...selectedType}
                          selectedTypeTMP.name = (event.target.value)? event.target.value?.slice(0, 30) : null;
                          setSelectedType(selectedTypeTMP)
                        }} 
                      />
                    </div>


                    {(selectedType?.id !== 'null' && !selectedType?.type?.includes('special'))?
                      <div className='space-y-1'>
                        {deffaultTypes.map((typeGroup, groupKey) => {
                          const show = (parseInt(groupKey) !== 2 || fileTypeArray)? true : false

                          return (show)?
                            <ToggleButtonGroup
                              key={`toggle-button-group-${groupKey}`}
                              className='h-[28px] bg-gray-100 whitespace-nowrap'
                              fullWidth
                              exclusive

                              value={(parseInt(groupKey) === 0)? textTypeArray : (parseInt(groupKey) === 1)? fileTypeArray : ((selectedType?.multiple)? true : false)}
                              onChange={(event, value) => {
                                if (parseInt(groupKey) === 0) {
                                  setTextTypeArray(value)
                                } else if (parseInt(groupKey) === 1) {
                                  setFileTypeArray(value)
                                } else {
                                  var selectedTypeTMP = {...selectedType}
                                  selectedTypeTMP.multiple = (value)? true : false;
                                  setSelectedType(selectedTypeTMP)
                                }
                              }}
                            >
                              {typeGroup?.map((typeItem, itemKey) => {
                                return <ToggleButton
                                  key={`toggle-button-${groupKey}-${itemKey}`}
                                  sx={{
                                    color: 'rgb(156 163 175)',
                                    "&.Mui-selected, &.Mui-selected:hover": {
                                      color: 'rgb(59 130 246)',
                                    },
                                  }}
                                  value={typeItem?.key}
                                >
                                  <div className='py-[2px] w-full flex items-center justify-center space-x-1'>
                                    <div className='w-[16px] h-[16px] flex items-center justify-center scale-110'>
                                      {typeItem?.icon}
                                    </div>
                                    <p>{typeItem?.label}</p>
                                  </div>
                                </ToggleButton>
                              })}
                            </ToggleButtonGroup>
                          : null
                        })}
                      </div>
                    :
                      <div className='px-4 py-9 flex items-center justify-center space-x-1 bg-gray-100 rounded-md text-gray-400'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M774-613 638-749 536-648l-68-67 136-136q6-6 14.5-9.5T636-864q10 0 19.5 3t16.5 10l102 102q13 13 20 30t8 35q1 20-7 38t-21 33ZM230-409q-14-14-14-34.5t14-34.5l102-101 68 68-102 102q-14 14-34 14t-34-14Zm-50 288q-11-11-16-24t-5-27q0-14 5-27t16-24l288-288-135-137q-14-14-14-33.5t14-33.5q14-14 33.5-14t33.5 14l136 136 68-68 101 102q14 14 14 33.5T705-478q-14 15-34 15t-34-15L281-121q-11 11-23.5 16t-26.5 5q-14 0-27-5t-24-16Z"/></svg>
                        <p>Tipología especial con edición limitada</p>
                      </div>
                    }


                    
                    
                  </div>
                </div>
              :
                <div className='w-full flex justify-center items-center space-x-4 text-gray-400 bg-gray-100 rounded-md py-[65px] shadow-md'>
                  <CircularProgress color="inherit"/>
                  <p>{(loadingTypes)? 'Cargando tipologías' : (loadingCustomers)? 'Cargando clientes' : 'Cargando tipología' }...</p>
                </div>
              }
            </div>
          </div>
        </div>
      </Collapse>



      <div style={{ margin: 0 }} className={`${(selectedType)? 'pt-5' : ''}`}>
        <div className={`relative flex justify-between items-start space-x-4 ${(step)? `duration-1000 ${(step === 6)? 'delay-[9s]' : 'opacity-0'}` : ''}`}>
          <div className="relative flex items-center flex-wrap md:flex-nowrap">
            <button 
              onClick={() => removeMarkTypeData()}
              className={`${(!parseInt(selectedTypeID))? 'pointer-events-none opacity-0' : 'disabled:opacity-50'} absolute left-0 p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-red-500 hover:bg-red-700 shadow-md active:scale-95 disabled:pointer-events-none rounded duration-200`}
              disabled={
                (
                  loadingSave
                  || loadingRemove
                  || unChangedSelectedType?.id === 'null'
                  || selectedType?.type?.includes('special')
                )? true : false
              }
            >
              {(loadingRemove)? loadingIcon : removeIcon}
              <p>{((loadingRemove)? 'Eliminando' : 'Eliminar')} tipología</p>
            </button>
            
            <button
              className={`${(selectedTypeID)? 'pointer-events-none opacity-0' : 'disabled:opacity-50'} p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-gray-500 hover:bg-gray-700 shadow-md active:scale-95 disabled:pointer-events-none rounded duration-200`}
              onMouseUp={(event) => {
                setSelectedTypeID(null)
                setShowCustomerSelector(true)
              }}
              disabled={
                (
                  loadingSave
                  || loadingRemove
                  || unChangedSelectedType?.id === 'null'
                  || customer?.hasMarks
                )? true : false
              }
            >
              {importIcon}
              <p>{(customer?.hasMarks)? 'Importar' : 'Importar tipologías de otro cliente'}</p>
            </button>

            {(customer?.hasMarks) && <p className='p-2 w-full text-xs opacity-50 leading-3'>Este cliente contiene marcas que utilizan estas <br/> tipologías, por ello no es posible importar de nevas.</p>}


            <ModalSelectCustomer
              open={showCustomerSelector}
              setOpen={setShowCustomerSelector}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              getTypes={getTypes}
              
              originalCustomer={customer}
              customers={customers}
            />
          </div>


          <div className='relative whitespace-nowrap flex items-center'>
            <button 
              onClick={() => saveMarkTypeData()}
              className={`${(!selectedTypeID)? 'pointer-events-none opacity-0' : 'disabled:opacity-50'} p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:pointer-events-none rounded duration-200`}
              disabled={
                (
                  loadingSave
                  || loadingRemove
                  || unChangedSelectedType?.id === 'null'
                )? true : false
              }
            >
              {(loadingSave)? loadingIcon : saveIcon}
              <p>{((loadingSave)? 'Guardando' : (selectedTypeID === 'new')? 'Crear nueva' : 'Guardar')} tipología</p>
            </button>

            <button 
              onClick={() => savePositionsFunc()}
              className={`${(selectedTypeID)? 'pointer-events-none opacity-0' : 'disabled:opacity-50'} absolute right-0 p-[6px] pl-2 pr-3 flex items-center space-x-2 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:pointer-events-none rounded duration-200`}
              disabled={
                (
                  loadingSave
                  || loadingRemove
                  || unChangedSelectedType?.id === 'null'
                )? true : false
              }
            >
              {(loadingSave)? loadingIcon : saveIcon}
              <p>{(step)? 'Finalizar cliente con estas tipologías' : 'Guardar orden de las tipologías'}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  :
    <div className='w-full flex justify-center items-center space-x-4 text-gray-400 bg-gray-100 rounded-md py-[111px] shadow-md'>
      <CircularProgress color="inherit"/>
      <p>{(loadingSave)? 'Guardando' : 'Cargando'} tipologías...</p>
    </div>
  );
}