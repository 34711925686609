import gql from "graphql-tag";
export default gql`
  query GetVideoMarksForMotions(
    
    $own_user_token: String!,
    $own_customer_token: String!,
    $state: Int,
    $module: String,
    $type: String,
    $avoid_notes: Boolean,
    $making_state: [Boolean],
    $hidde_disabled: Boolean
    
  ) {
    videoMarksForMotions(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,
      state: $state,
      module: $module,
      type: $type,
      avoid_notes: $avoid_notes,
      making_state: $making_state,
      hidde_disabled: $hidde_disabled
      
    ) {
      
      id
      time
      time_end
      observations
      texts
      url
      coordinates

      swap_date
      swap_user
      info_user_id
      hidde_original

      public
      notificate_to
      notificate_to_label
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      video_id
      user_id
      edit_user_ids
      type_id
      review_id
      difficulty_id
      is_translation_of
      original_video_id
      file_process_id

      extra_mark_facturation
      extra_facturation_correction
      manual_number_of_words
      new_price
      new_price_observations

      search_guest_data
      search_type_name
      search_type_color
      search_type_type
      search_review_name
      search_recording_set_label
      localization_end_date
      
      disabled
      disabled_change_label
      facturation_disabled
      facturation_disabled_change_label
      facturation_force_insertion
      
      createdAt
      updatedAt

      video {
        id
        name
        url_name
        title
        language
        original_language
        category_id
        is_priority
        is_priority_change_label
        is_priority_date
        production_state
        extra_professors_label
        endDate
        
        is_translation_of

        is_sound_media
        search_review_version
        search_media_time
        search_reviewed
        search_reviewed_label
        search_translated
        search_translated_label
        search_module_name
        search_module_abbreviated_name
        search_professor_name
        
        isPriorityDateFormated
        endDateFormated
      }

      info_user {
        id
        name
        positions
      }

      user {
        id
        name
        profile_picture
        professor
        visible

        from_customer {
          token
          icon
          abbreviated_name
          name
        }
      }

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
