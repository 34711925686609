import gql from "graphql-tag";

export default gql`
  mutation MarkNotifications($own_user_token: String!, $own_customer_token: String!, $id: Int, $type: String, $to_user_id: Int!, $readed: Boolean, $saved: Boolean) {
    markNotifications(own_user_token: $own_user_token, own_customer_token: $own_customer_token, id: $id, type: $type, to_user_id: $to_user_id, readed: $readed, saved: $saved) {
      id
      title
      text
      url
      type
      saved
      readed
      from_user_id
      to_user_id

      createdAt
      updatedAt

      from_user {
        id
        name
        profile_picture
        professor
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
