import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../contexts/AuthContext';
import { FuncContext } from '../../../contexts/FuncContext';
import { useMutation } from '@apollo/client'

import CreateOrUpdateBill from '../../../graphql/mutations/CreateOrUpdateBill'

import Tooltip from '@mui/material/Tooltip';








export default function EditMinutesCell({ f10Bill, billOption, video, colKey, originalMins }) {



    const { currentUser, getJsonString } = useContext(AuthContext)
    const { formatNumbersToPrint } = useContext(FuncContext)


    const billEditedMins = (billOption?.video_edits)? getJsonString(billOption?.video_edits) : null;
    const deffaultEditedMins = (billEditedMins && video?.concepte in billEditedMins && parseFloat(billEditedMins[video?.concepte][colKey]))? parseFloat(billEditedMins[video?.concepte][colKey]) : null;
    const deffaultEditedObservations = (deffaultEditedMins && billEditedMins[video?.concepte][colKey+'_obs'])? billEditedMins[video?.concepte][colKey+'_obs'] : null;


    const [showEditor, setShowEditor] = useState(false);

    const [savedMins, setSavedMins] = useState((deffaultEditedMins)? deffaultEditedMins : 0);
    const [savedObservations, setSavedObservations] = useState((deffaultEditedObservations)? deffaultEditedObservations : null);
    const [editedMins, setEditedMins] = useState((deffaultEditedMins)? deffaultEditedMins : 0);
    const [editedObservations, setEditedObservations] = useState((deffaultEditedObservations)? deffaultEditedObservations : null);

    const [loading, setLoading] = useState(false);



    const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
    const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
    const redoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-192q-120 0-204-84t-84-204q0-120 84-204t204-84q65 0 120.5 27t95.5 72v-63q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-768 757.5-757.65 768-747.3 768-732v168q0 15.3-10.35 25.65Q747.3-528 732-528H564q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q528-579 538.35-589.5 548.7-600 564-600h95q-29-44-76-70t-103-26q-90 0-153 63t-63 153q0 90 63 153t153 63q75 0 132-45.5T687-424q4-14 16.5-22t26.532-6Q745-450 754-438.5t6 25.5q-23 96-100 158.5T480-192Z"/></svg>
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;




    const [createOrUpdateBill] = useMutation(CreateOrUpdateBill)






    function cancelEditFunc() {
        setEditedMins(savedMins)
        setEditedObservations(savedObservations)
        setShowEditor(false)
    }

    function resetEditFunc() {
        saveEditFunc(0, null)

        setSavedMins(0)
        setSavedObservations(null)
        setEditedMins(0)
        setEditedObservations(null)

        setShowEditor(false)
    }



    async function saveEditFunc(mins, observations) {
        if (!parseInt(f10Bill?.sended)) {
            setLoading(true) 

            var billEdits = (billOption?.video_edits)? getJsonString(billOption?.video_edits) : {};
            if (!billEdits[video?.concepte]) { billEdits[video?.concepte] = {}; } 
            if (mins && parseFloat(mins)) { 
                billEdits[video?.concepte][colKey] = parseFloat(mins); 

                if (observations) {
                    billEdits[video?.concepte][colKey+'_obs'] = observations; 
                } else if (billEdits[video?.concepte][colKey+'_obs']) {
                    delete billEdits[video?.concepte][colKey+'_obs'];
                }

            } else if (video?.concepte in billEdits) { delete billEdits[video?.concepte] }

            await createOrUpdateBill({variables: { 
                own_user_token: currentUser?.token,
                id: parseInt(billOption?.id),
                video_edits: (billEdits && Object.keys(billEdits)?.length)? JSON.stringify(billEdits) : null,
            }})

            setTimeout(() => {
                setSavedMins(parseFloat(mins))
                setSavedObservations((observations)? observations : null)

                setLoading(false) 
                setShowEditor(false)
            }, 200 )
        }
    }










    return (originalMins)? (

        (!parseInt(f10Bill?.sended) && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))?
            <div className='flex min-w-[140px] max-w-[140px]'>
                {(loading)?
                    <div className='flex items-center opacity-50'>
                        <div className='h-[20px] w-[20px] flex items-center justify-center'><div className='scale-[0.8]'>{loadingIcon}</div></div>
                        <p>Guardando...</p>
                    </div>
                : (!showEditor)?
                    <button
                        onClick={() => setShowEditor(!showEditor)}
                        className={`flex space-x-1
                            ${(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')? 'hover:bg-blue-500 hover:bg-opacity-50 active:bg-opacity-30 rounded text-[14px]' : ''}
                        `}
                    >
                        <p className='py-[1px] px-1'>+{formatNumbersToPrint(originalMins, 2)}m</p>
                        {(savedMins)? 
                            <Tooltip title={(savedObservations)? savedObservations : ''} placement="bottom" disableInteractive arrow>
                                <p className='px-1 py-[1px] bg-gray-300 bg-opacity-60 rounded'>{(parseFloat(savedMins) > 0)? '+' : ''}{formatNumbersToPrint(savedMins, 2)}m</p>
                            </Tooltip>
                        : null }
                    </button>
                :
                    <div className='relative flex flex-col items-center justify-center'>
                        <div className='flex space-x-1'>
                            <input 
                                type="number" 
                                className='w-full px-[2px] border border-gray-400 hover:border-gray-600 border-opacity-60 duration-200 rounded'

                                value={editedMins}
                                onChange={(event) => setEditedMins(event?.target?.value)} 
                            />

                            <button
                                onClick={() => saveEditFunc(editedMins, editedObservations)}
                                className='h-[20px] w-[20px] flex items-center justify-center text-white bg-blue-400 hover:bg-blue-500 active:bg-blue-700 rounded duration-200'
                            >
                                <div className='scale-[0.8]'>{saveIcon}</div>
                            </button>

                            <button
                                onClick={() => resetEditFunc()}
                                className='h-[20px] w-[20px] flex items-center justify-center text-white bg-red-400 hover:bg-red-500 active:bg-red-700 rounded duration-200'
                            >
                                <div className='scale-[0.8]'>{redoIcon}</div>
                            </button>

                            <button
                                onClick={() => cancelEditFunc()}
                                className='h-[20px] w-[20px] flex items-center justify-center text-white bg-red-400 hover:bg-red-500 active:bg-red-700 rounded duration-200'
                            >
                                <div className='scale-[0.8]'>{cancelIcon}</div>
                            </button>
                        </div>


                        <textarea 
                            rows="2"
                            className='absolute top-[25px] w-[250px] h-[80px] p-1 placeholder:text-[rgb(156,163,175,0.7)] bg-white border border-gray-400 hover:border-gray-600 border-opacity-60 duration-200 rounded' 
                            style={{ outlineColor: 'rgb(54, 120, 227)' }}
                            placeholder='Observaciones...' 
                            
                            value={editedObservations}
                            onChange={(event) => setEditedObservations(event?.target?.value)} 
                        ></textarea>
                    </div>
                }
      
            </div>
        :
            <div className='flex space-x-1 min-w-[140px] max-w-[140px]'>
                <p className='py-[1px] px-1'>+{formatNumbersToPrint(originalMins, 2)}m</p>
                {(savedMins)?
                    <Tooltip title={(savedObservations)? savedObservations : ''} placement="bottom" disableInteractive arrow>
                        <p className='px-1 py-[1px] bg-gray-300 bg-opacity-60 rounded'>{(parseFloat(savedMins) > 0)? '+' : ''}{formatNumbersToPrint(savedMins, 2)}m</p>
                    </Tooltip>
                : null }
            </div>
    ) : null
}