import gql from "graphql-tag";
export default gql`
  query Factura10GetAllVideosSimple(
    
    $own_user_token: String!,
    $own_customer_token: String!,

    $name: String,
    $professor_id: String,
    $module: String,
    $localizations: Int,
    $avoidAudios: Boolean,
    $translationDone: Boolean
    $needs_category_id: Boolean
    
  ) {
    getCustomerVideos(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,

      name: $name,
      professor_id: $professor_id,
      module: $module,
      localizations: $localizations,
      avoidAudios: $avoidAudios,
      translationDone: $translationDone
      needs_category_id: $needs_category_id

    ) {
      
      id
      name
      url_name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled

      thumbnail
      thumbnail_small
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      vimeo_url
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      chyron_states

      factura10_product_id
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words

      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_substituted_chyrons
      search_module_name
      search_module_abbreviated_name
      
      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of

      extra_marks_json
      extra_languages_json
      facturation_note

      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt

      time
      minutes

      professor {
        id
        name
        sex
        positions
      }

      extraProfessors {
        id
        name
        sex
        positions
      }

      __typename

    }
  }
`;
