import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { useMutation } from '@apollo/client'
import { useParams } from "react-router-dom";

import VideoFormInputs from '../components/VideoFormInputs';

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingButton from '@mui/lab/LoadingButton';






const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};







export default function ModalVideoEditing({ open, setOpen, video, setVideo, getVideos }) {





  const { currentCustomer } = useContext(AuthContext)

  const [triggerSave, setTriggerSave] = useState(false);

  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  /* Error var */
  const [videoNameRepeated, setVideoNameRepeated] = useState(false);




  useEffect(() => {
    if (triggerSave) {
      setTimeout(() => {
        setTriggerSave(false)
      }, 300 )
    }
  }, [triggerSave])





  return (
    <div className='w-full'>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        sx={{ "& .MuiPaper-root": { maxWidth: '1200px !important' } }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>setOpen(false)}>
          <p className='text-xl max-w-[800px] text-gray-600 truncate'>
            <span className={`${(currentCustomer?.setting_tittle_is_relevant)? 'opacity-50' : ''}`}>{video?.name}</span>
            {(currentCustomer?.setting_tittle_is_relevant) && <span className='opacity-50'> - </span> }
            {(currentCustomer?.setting_tittle_is_relevant) && <span>{video?.title}</span> }
          </p>
        </BootstrapDialogTitle>

       
        <DialogContent dividers>
          <div className='lg:px-4 py-3'>
            <VideoFormInputs 
              simplified={true}
              initialVideoID={null}
              initialVideo={video}
              triggerSave={(triggerSave)? true : false}

              loadingContent={loadingContent}
              setLoadingContent={setLoadingContent}
              loadingSave={loadingSave}
              setLoadingSave={setLoadingSave}
              loadingRemove={loadingRemove}
              setLoadingRemove={setLoadingRemove}

              videoNameRepeated={videoNameRepeated}
              setVideoNameRepeated={setVideoNameRepeated}

              videoSetter={'both'}
              getVideos={getVideos}
              setInitialVideo={setVideo}
            />
          </div>
        </DialogContent>


        <DialogActions>
          <div className='w-full flex items-center justify-between'>
            <Button
              variant="contained"
              size='small'
              color="error"
              startIcon={<ClearIcon />}
              onClick={() => setOpen(false)}
              disabled={videoNameRepeated || loadingContent || loadingSave || loadingRemove}
            >
              Cancelar
            </Button>

            <LoadingButton 
              loading={loadingSave}
              loadingPosition="start"
              variant="contained"
              size='small'
              autoFocus
              startIcon={<SaveIcon />}
              onClick={() => setTriggerSave(true)}
              disabled={videoNameRepeated || loadingContent || loadingRemove}
            >
              Guardar cambios
            </LoadingButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}