import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateCustomer(

    $own_user_token: String!,
    
    $id: Int,
    $icon: String,
    $abbreviated_name: String,
    $name: String,
    $factura10_client_id: Int,

    $label_module: String,
    $label_modules: String,
    $label_professor: String,
    $label_professors: String
    $label_extras_module: String
    $label_extras_module_url: String

    $setting_upload_name_format: String
    $setting_language_filter: Boolean
    $setting_sex_filter: Boolean
    $setting_professor_filter: Boolean
    $setting_initial_chyron: Boolean
    $setting_recording_tipo_in_list: Boolean
    $setting_translators_can_create_marks: Boolean
    $setting_tittle_is_relevant: Boolean
    $setting_professor_col: Boolean
    $setting_deffault_date_filter: Boolean
    $setting_professors_can_see_marks: Boolean
    $setting_instant_prod_state_notification: Boolean

  ) {
    createOrUpdateCustomer(input: { 
      
      own_user_token: $own_user_token,

      id: $id,
      icon: $icon,
      abbreviated_name: $abbreviated_name,
      name: $name,
      factura10_client_id: $factura10_client_id,
    
      label_module: $label_module,
      label_modules: $label_modules,
      label_professor: $label_professor,
      label_professors: $label_professors
      label_extras_module: $label_extras_module
      label_extras_module_url: $label_extras_module_url

      setting_upload_name_format: $setting_upload_name_format
      setting_language_filter: $setting_language_filter
      setting_sex_filter: $setting_sex_filter
      setting_professor_filter: $setting_professor_filter
      setting_initial_chyron: $setting_initial_chyron
      setting_recording_tipo_in_list: $setting_recording_tipo_in_list
      setting_translators_can_create_marks: $setting_translators_can_create_marks
      setting_tittle_is_relevant: $setting_tittle_is_relevant
      setting_professor_col: $setting_professor_col
      setting_deffault_date_filter: $setting_deffault_date_filter
      setting_professors_can_see_marks: $setting_professors_can_see_marks
      setting_instant_prod_state_notification: $setting_instant_prod_state_notification
      
    }) {
      
      id
      token
      icon
      abbreviated_name
      name
      language
      factura10_client_id

      label_module
      label_modules
      label_professor
      label_professors
      label_extras_module
      label_extras_module_url

      setting_upload_name_format
      setting_language_filter
      setting_sex_filter
      setting_professor_filter
      setting_initial_chyron
      setting_recording_tipo_in_list
      setting_translators_can_create_marks
      setting_tittle_is_relevant
      setting_professor_col
      setting_deffault_date_filter
      setting_professors_can_see_marks
      search_bills_to_do
      
      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
