import gql from "graphql-tag";
export default gql`
  query GetEmail($own_user_token: String!, $id: ID!) {
    emailLog(own_user_token: $own_user_token, id: $id) {
      id
      user_id
      email
      name
      title
      body
      sended
      
      sendedAt
      createdAt
      updatedAt

      sendedAtFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
