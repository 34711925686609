import gql from "graphql-tag";
export default gql`
  {
    videoMarkDifficulties {
      id
      token
      name
      price
      partial_price
      correction_price

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
