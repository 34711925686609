import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';
import PersExtraItems from './Components/PersExtraItems';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';






const legend = {
    'c1': [
        { 
            title: [
                'Transcripción / Subtitulación / Traducción',
                'Precio',
            ],
            table: [
                { title: 'Postproducción de audio avanzada proveniente de Bakery', price: '10,00 € / minuto' },
                { title: 'Postproducción de vídeo avanzada proveniente de Kreativfilm GmbH (GER)', price: '17,00 € / minuto' },
                { title: 'Subtitulación', price: '6,00 € / minuto' },
                { title: 'Transcripción', price: '7,00 € / minuto' },
                { title: 'Traducción (SPA-ENG, ENG-SPA)', price: '0,14 € / palabra' },
            ],
        },
    ],
    'other': [
        { 
            title: [
                'Transcripción / Subtitulación / Traducción',
                'Precio',
            ],
            table:[
                { title: 'Transcripción automática / prompterizada', price: '2,80 € / minuto' },
                { title: 'Transcripción estándar', price: '7,00 € / minuto' },
                { title: 'Subtitulación del idioma original', price: '6,00 € / minuto' },
                { title: 'Subtitulación de idiomas secundarios', price: '4,50 € / minuto' },
                { title: 'Traducción (ENG-SPA & SPA-ENG)', price: '0,14 € / palabra' }, 
                { title: 'Traducción (POR-ENG)', price: '0,22 € / palabra' },
            ],
        },
    ],
}


const deff_start_list = [
    { token: 'transcription_promp', label: 'Transcripción en modalidad semi-automática y/o prompterizada', price: 0, type: 'mins', number: 0 },
    { token: 'transcription', label: 'Transcripción estándar', price: 0, type: 'mins', number: 0 },
    { token: 'subtitulation_org', label: 'Subtitulación del idioma original', price: 0, type: 'mins', number: 0 },
    { token: 'subtitulation_sec', label: 'Subtitulación de idiomas secundarios', price: 0, type: 'mins', number: 0 },
    { token: 'translation_eng', label: 'Traducción (ENG-SPA)', price: 0, type: 'palabras', number: 0 },
    { token: 'translation_esp', label: 'Traducción (SPA-ENG)', price: 0, type: 'palabras', number: 0 },
    { token: 'translation_por', label: 'Traducción (POR-ENG)', price: 0, type: 'palabras', number: 0 },
];



const deff_columns = [
    { id: 'concepte', label: 'Vídeo' },

    { id: 'minutes', label: 'Minutaje', align: 'right' },

    { id: 'transcription', label: 'Transcripción', align: 'right' },
    { id: 'subtitulation_eng', label: 'Subs ENG', align: 'right' },
    { id: 'subtitulation_esp', label: 'Subs SPA', align: 'right' },

    { id: 'number_of_words', label: 'Núm.Palabras traducidas', align: 'center', width: 150 },
    { id: 'translation_eng', label: 'Trad ENG', align: 'right' },
    { id: 'translation_esp', label: 'Trad SPA', align: 'right' },

    { id: 'preu', label: 'Total', align: 'right' },
];










export default function Factura10Traduccion({ billOption, factura10Bill, billExtras, loadingBills, hiddenItems, setHiddenItems }) {



    const { bill_token } = useParams()
    
    const { currentCustomer, currentUser } = useContext(AuthContext)
    const { formatNumbersToPrint, remove_tags } = useContext(FuncContext)
    const selectedLegend = (billOption?.module?.includes('C1') && legend['C1'])? legend['c1'] : legend['other'];

    

    const [startListcats, setStartListcats] = useState(deff_start_list);
    const [columns, setColumns] = useState(deff_columns);

    const [bill, setBill] = useState(null);
    const [concepts, setConcepts] = useState(null);
    const [cats, setCats] = useState(null);
    
    const [tableOpen, setTableOpen] = useState(true);
    const [tableXProfessorOpen, setTableXProfessorOpen] = useState(false);
    const [tableXTopicOpen, setTableXTopicOpen] = useState(false);
    const [showLegend, setShowLegend] = useState(true);
    const [hasSubs, setHasSubs] = useState(null);

    const [baseLoading, setBaseLoading] = useState(true);



    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
    const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M237-285q54-38 115.5-56.5T480-360q66 0 127.5 18.5T723-285q35-41 52-91t17-104q0-129.675-91.23-220.838Q609.541-792 479.77-792 350-792 259-700.838 168-609.675 168-480q0 54 17 104t52 91Zm243-123q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm.276 312Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q52 0 100-16.5t90-48.5q-43-27-91-41t-99-14q-51 0-99.5 13.5T290-233q42 32 90 48.5T480-168Zm0-312q30 0 51-21t21-51q0-30-21-51t-51-21q-30 0-51 21t-21 51q0 30 21 51t51 21Zm0-72Zm0 319Z"/></svg>
    const notDoneIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m480-429 116 116q11 11 25.5 10.5T647-314q11-11 11-25.5t-11-25.457L531-480.5l116-115.543Q658-607 658-621.5T647-647q-11-11-25.5-11T596-647L480-531 364-647q-11-11-25-11t-25 11q-11 11-11 25.5t10.913 25.5L429-480 313-364q-11 11-10.5 25t11.5 25q11 11 25.5 11t25.413-10.913L480-429Zm.276 333Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>

    const euroIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill='currentColor' viewBox="0 0 20 20"><path d="M10,17c-1.83,0-3.41-.61-4.72-1.82-1.31-1.22-2.06-2.71-2.24-4.49-.03-.19.03-.36.18-.49s.32-.2.53-.2c.2,0,.37.06.52.17s.24.26.27.46c.15,1.38.75,2.53,1.78,3.47,1.03.94,2.26,1.41,3.68,1.41,1.51,0,2.81-.54,3.89-1.61,1.08-1.08,1.61-2.37,1.61-3.89s-.54-2.81-1.61-3.89-2.37-1.61-3.89-1.61c-.86,0-1.66.18-2.39.53-.73.36-1.34.84-1.84,1.47h1.48c.21,0,.39.07.53.21.14.14.22.32.22.53s-.07.39-.22.54c-.14.15-.32.22-.53.22h-3.5c-.21,0-.39-.07-.53-.22-.14-.14-.22-.32-.22-.53v-3.5c0-.21.07-.39.21-.53.14-.14.32-.22.53-.22s.39.07.54.22c.15.14.22.32.22.53v1.96c.64-.83,1.43-1.49,2.39-1.98s1.99-.73,3.11-.73c.97,0,1.88.18,2.73.55.85.37,1.59.87,2.22,1.5.63.63,1.13,1.37,1.5,2.22.37.85.55,1.76.55,2.73s-.18,1.88-.55,2.73c-.37.85-.87,1.59-1.5,2.22-.63.63-1.37,1.13-2.22,1.5-.85.37-1.76.55-2.73.55Z"/><path d="M8.83,9.49h3.43v1.38h-3.43c.19.94,1.1,1.54,2.04,1.35.28-.06.55-.19.77-.37l1.18.78c-1.16,1.27-3.13,1.35-4.4.19-.55-.51-.9-1.2-.99-1.95h-.71v-1.38h.71c.19-1.71,1.73-2.94,3.44-2.75.75.08,1.44.44,1.95.99l-1.18.78c-.73-.62-1.82-.53-2.44.2-.19.22-.32.49-.37.77Z"/></svg>
    const notTimeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-771.39q61.39 0 117.09 19.5 55.69 19.5 100.13 56.06l27.85-27.84q14.1-14.11 31.21-13.61t31.5 14.89q14.4 14.39 14.4 31.5t-14.4 31.5l-26.56 27.56q36 43.87 55 99.64 19 55.76 19 115.57 0 21.06-1.59 40.6-1.59 19.54-6.32 38.02-4.44 25.65-22.18 34.2-17.74 8.54-36.83 4.54-19.08-4-31.32-18.67-12.24-14.68-6.25-39 3.65-14.29 5.07-29.24 1.42-14.95 1.42-30 0-107.74-74.74-182.48T480-673.39q-16.61 0-31.17 1.87-14.57 1.87-27.35 4.48-21.78 5.56-37.52-6.11-15.74-11.68-19.74-29.76-4-18.09 3.76-35.55 7.76-17.45 32.41-24.02 19.05-6.3 39.14-7.61 20.09-1.3 40.47-1.3Zm44.48 236.69v-39.26q0-14.91-10.35-25.41t-25.65-10.5q-8.44 0-15.88 3.22-7.45 3.22-11.99 9.09l63.87 62.86ZM480-61.52q-74.52 0-138.83-27.92-64.31-27.92-112.43-75.75-48.12-47.84-76.04-112.15-27.92-64.31-27.92-138.97 0-57.99 18.5-113.19 18.5-55.2 54.5-103.02l-86.17-86.17q-12.7-12.7-12.41-27.83.28-15.13 12.97-27.83 12.7-12.69 28.33-12.69 15.63 0 28.33 12.69L794-149.74q12.7 12.7 12.7 27.83 0 15.13-12.7 27.82-12.7 12.7-28.61 12.7t-28.61-12.7l-41-40.43q-45 36-99.91 54.5T480-61.52Zm0-98q40.74 0 77.06-11.52 36.33-11.52 68.07-34.13l-355-355.57q-22.61 32.31-34.98 69.48-12.37 37.17-12.37 75.09 0 107.74 74.74 182.19 74.74 74.46 182.48 74.46ZM386.96-816q-18.7 0-31.59-13.12-12.89-13.11-12.89-31.71 0-18.61 12.89-31.65t31.59-13.04h186.08q18.7 0 31.59 12.83t12.89 31.44q0 18.6-12.89 31.92Q591.74-816 573.04-816H386.96Zm60.39 433.04Zm114.43-114.43Z"/></svg>




    


    


    useEffect( () => { 
        if (hasSubs) {
            var deffColumnsTMP = deff_columns;

            if (!hasSubs?.esp) {
                deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'subtitulation_esp' )
            }
    
            if (!hasSubs?.eng) {
                deffColumnsTMP = deffColumnsTMP?.filter((el) => el?.id !== 'subtitulation_eng' )
            }

            setColumns(deffColumnsTMP)
        }
    }, [hasSubs])


    useEffect( () => { 
        if (baseLoading) {
            setTimeout(() => {
                setBaseLoading(false)
            }, 500 )
        }
    }, [baseLoading])

    
    useEffect( () => {        
        if (currentCustomer) {
            if (factura10Bill?.document) {

                
                var correctedConcepts = (factura10Bill?.concepts?.length)? [...factura10Bill?.concepts] : null;
                const cats = factura10Bill?.cats;
                const filtredCats = cats?.filter((value, index, self) => index === self.findIndex((t) => (t.nom === value.nom )) );
                var hasSubsTMP = { esp: 0, eng: 0 }

                for (var i in correctedConcepts) {
                    var hasCat = filtredCats?.filter((el) => parseInt(correctedConcepts[i]?.document_concepte_categoria_id) === parseInt(el?.id));

                    if (!hasCat?.length) {
                        var oldCat = cats?.filter((el) => parseInt(correctedConcepts[i]?.document_concepte_categoria_id) === parseInt(el?.id));
                        oldCat = (oldCat?.length)? oldCat[0] : null

                        if (oldCat) {
                            for (var filtredCat of filtredCats) {
                                if (oldCat?.nom === filtredCat?.nom) {
                                    correctedConcepts[i].document_concepte_categoria_id = parseInt(filtredCat?.id);
                                }
                            }
                        }
                       
                    }
                }



                setBill(factura10Bill?.document)
                setConcepts(correctedConcepts)
                setCats(filtredCats)



                var startListTMP = JSON.parse(JSON.stringify(deff_start_list));
                startListTMP[0]['transc'] = 0;
                startListTMP[1]['transc'] = 0;
                
                startListTMP[2]['subs_org'] = 0;
                startListTMP[3]['subs_second'] = 0;

                startListTMP[4]['videos_en'] = 0;
                startListTMP[4]['words_en'] = 0;

                startListTMP[5]['videos_es'] = 0;
                startListTMP[5]['words_es'] = 0;

                startListTMP[6]['videos_por'] = 0;
                startListTMP[6]['words_por'] = 0;
                

                for (var i in factura10Bill?.concepts) {
                    const concept = factura10Bill?.concepts[i]
                    const marksJson = (concept['aux_json'] && JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                    const extraLanguagesJson = (concept['extra_languages_json'] && JSON.parse(concept['extra_languages_json']))? JSON.parse(concept['extra_languages_json']) : null;
                    


                    if (marksJson?.trancr) {
                        var indexTMP = (marksJson?.trancr_promp)? 0 : 1;
                        const min = (parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : (marksJson?.trancr && parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0

                        startListTMP[indexTMP].price = startListTMP[indexTMP]?.price + marksJson?.trancr;
                        startListTMP[indexTMP].number = startListTMP[indexTMP]?.number + min;
                        startListTMP[indexTMP]['transc']++; 
                    }

                    if (marksJson?.sub) {
                        var subES = (parseFloat(marksJson?.sub_esp))? parseFloat(marksJson?.sub_esp) : (parseFloat(marksJson?.sub_es))? parseFloat(marksJson?.sub_es) : 0;
                        var subEN = (parseFloat(marksJson?.sub_eng))? parseFloat(marksJson?.sub_eng) : (parseFloat(marksJson?.sub_en))? parseFloat(marksJson?.sub_en) : 0;

                        hasSubsTMP['esp'] = hasSubsTMP['esp'] + subES;
                        hasSubsTMP['eng'] = hasSubsTMP['eng'] + subEN;

                        var min_fac = parseFloat(marksJson?.min_facturable);
                        var min_fin = parseFloat(marksJson?.min_final);

                        var min_fac_es = round(subES / marksJson?.min_facturable, 1);
                        var min_fin_es = round(subES / marksJson?.min_final, 1);
                        var min_fac_en = round(subEN / marksJson?.min_facturable, 1);
                        var min_fin_en = round(subEN / marksJson?.min_final, 1);

                        var primary = 6;
                        var primaryPrice = (min_fac_es === primary || min_fin_es === primary)? subES : (min_fac_en === primary || min_fin_en === primary)? subEN : 0;
                        
                        var secondary = 4.5;
                        var secondaryPrice = (min_fac_es === secondary || min_fin_es === secondary)? subES : (min_fac_en === secondary || min_fin_en === secondary)? subEN : 0;

                        if (primaryPrice) {
                            startListTMP[2].price = startListTMP[2]?.price + primaryPrice;
                            startListTMP[2].number = startListTMP[2]?.number + (((min_fac / primaryPrice) === primary)? min_fac : min_fin);
                            startListTMP[2]['subs_org']++;
                        }

                        if (secondaryPrice) {
                            startListTMP[3].price = startListTMP[3]?.price + secondaryPrice;
                            startListTMP[3].number = startListTMP[3]?.number + (((min_fac / secondaryPrice) === secondary)? min_fac : min_fin);
                            startListTMP[3]['subs_second']++;
                        }
                    }


                    const nb_paraules_all = (marksJson?.nb_paraules)? marksJson?.nb_paraules : marksJson?.nb_paraules_all;
                    if (marksJson?.traduc && nb_paraules_all) {
                        var indexTMP = (marksJson?.original_language === 'por')? 6 : (marksJson?.language === 'esp')? 5 : 4;
                        startListTMP[indexTMP].price = startListTMP[indexTMP]?.price + marksJson?.traduc;
                        startListTMP[indexTMP].number = startListTMP[indexTMP]?.number + nb_paraules_all;

                        if (marksJson?.original_language === 'por' && marksJson?.language === 'eng') { startListTMP[indexTMP]['videos_por']++; startListTMP[indexTMP].words_por = startListTMP[indexTMP]?.words_por + nb_paraules_all; }
                        else if ((!marksJson?.original_language && marksJson?.language === 'eng') || marksJson?.original_language === 'eng') { startListTMP[indexTMP]['videos_en']++; startListTMP[indexTMP].words_en = startListTMP[indexTMP]?.words_en + nb_paraules_all; }
                        else if ((!marksJson?.original_language && marksJson?.language === 'esp') || marksJson?.original_language === 'esp') { startListTMP[indexTMP]['videos_es']++; startListTMP[indexTMP].words_es = startListTMP[indexTMP]?.words_es + nb_paraules_all; }
                    }
                
                    if (extraLanguagesJson?.length) {
                        for (var t in extraLanguagesJson) {
                            var extraLanguageTMP = extraLanguagesJson[t];
                            if (marksJson['traduc_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_translation']) {
                                if (marksJson['traduc_extra_'+extraLanguageTMP['language']]) { 
                                    var indexTMP = (extraLanguageTMP?.original_language === 'por')? 6 : (extraLanguageTMP?.language === 'esp')? 5 : 4;

                                    startListTMP[indexTMP].price = startListTMP[indexTMP]?.price + marksJson['traduc_extra_'+extraLanguageTMP['language']]; 
                                    startListTMP[indexTMP].number = startListTMP[indexTMP]?.number + marksJson['nb_paraules_extra_'+extraLanguageTMP['language']];

                                    if (extraLanguageTMP?.original_language === 'por' && extraLanguageTMP?.language === 'eng') { startListTMP[indexTMP].words_por = startListTMP[indexTMP]?.words_por + marksJson['nb_paraules_extra_'+extraLanguageTMP['language']]; }
                                    else if ((!extraLanguageTMP?.original_language && extraLanguageTMP?.language === 'eng') || extraLanguageTMP?.original_language === 'eng') { startListTMP[indexTMP].words_en = startListTMP[indexTMP]?.words_en + marksJson['nb_paraules_extra_'+extraLanguageTMP['language']]; }
                                    else if ((!extraLanguageTMP?.original_language && extraLanguageTMP?.language === 'esp') || extraLanguageTMP?.original_language === 'esp') { startListTMP[indexTMP].words_es = startListTMP[indexTMP]?.words_es + marksJson['nb_paraules_extra_'+extraLanguageTMP['language']]; }
                                }
                            }
                        }
                    }
                }



                startListTMP[0]['desc'] = <div>
                    Se han transcrito de forma semi-automática y/o prompterizada un total de <strong>{formatNumbersToPrint(startListTMP[0]['number'], 2)} minutos</strong> a lo largo de <strong>{startListTMP[0]['transc']} {(parseInt(startListTMP[0]['transc']) === 1)? 'vídeo' : 'vídeos'}</strong>. 
                </div>

                startListTMP[1]['desc'] = <div>
                    Se han transcrito de forma estándar un total de <strong>{formatNumbersToPrint(startListTMP[1]['number'], 2)} minutos</strong> a lo largo de <strong>{startListTMP[1]['transc']} {(parseInt(startListTMP[1]['transc']) === 1)? 'vídeo' : 'vídeos'}</strong>. 
                </div>

                startListTMP[2]['desc'] = <div>
                    Del idioma original se han subtitulado un total de <strong>{formatNumbersToPrint(startListTMP[2]['number'], 2)} minutos</strong> a lo largo de <strong>{startListTMP[2]['subs_org']} {(parseInt(startListTMP[2]['subs_org']) === 1)? 'vídeo' : 'vídeos'}</strong>.
                </div>

                startListTMP[3]['desc'] = <div>
                    Se han subtitulado a un idioma secundario un total de <strong>{formatNumbersToPrint(startListTMP[3]['number'], 2)} minutos</strong> a lo largo de <strong>{startListTMP[3]['subs_second']} {(parseInt(startListTMP[3]['subs_second']) === 1)? 'vídeo' : 'vídeos'}</strong>.
                </div>

                startListTMP[4]['desc'] = <div>
                    Se han realizado traducciones al español en un total de <strong>{startListTMP[4]['videos_en']} {(parseInt(startListTMP[4]['videos_en']) === 1)? 'vídeo' : 'vídeos'}</strong>, sumando un total 
                    que equivale a <strong>{formatNumbersToPrint(startListTMP[4]['words_en'], 0)} palabras</strong>.
                </div>

                startListTMP[5]['desc'] = <div>
                    Se han realizado traducciones al inglés en un total de <strong>{startListTMP[5]['videos_es']} {(parseInt(startListTMP[4]['videos_en']) === 1)? 'vídeo' : 'vídeos'}</strong>, sumando un total 
                    que equivale a <strong>{formatNumbersToPrint(startListTMP[5]['words_es'], 0)} palabras</strong>.
                </div>

                startListTMP[6]['desc'] = <div>
                    Se han traducido del portugués al inglés un total de <strong>{startListTMP[6]['videos_por']} {(parseInt(startListTMP[4]['videos_en']) === 1)? 'vídeo' : 'vídeos'}</strong> sumando un total que equivale a <strong>{formatNumbersToPrint(startListTMP[6]['words_por'], 0)} palabras</strong>.
                </div>


                setHasSubs(hasSubsTMP)
                setStartListcats(startListTMP)


            } else {
                setBill(null)
                setConcepts(null)
                setCats(null)
            }
        }
    }, [factura10Bill, currentCustomer])

        




    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }








    function processBaseTableFunc(valueTMP, column, row, returnPrice) {
        var value = valueTMP
        const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
        const extraLanguagesJson = (row['extra_languages_json'] && JSON.parse(row['extra_languages_json']))? JSON.parse(row['extra_languages_json']) : null;

        if (column?.id === 'concepte') {
            value = <div className='flex space-x-2'>
                <RowButtons
                    row={row}
                    factura10Bill={factura10Bill}
                    billOption={billOption}
                    hiddenItems={hiddenItems}
                    setHiddenItems={setHiddenItems}
                />

                {(row['link'])? 
                    <a href={row['link']} target="_blank">{value}</a> : <p>{value}</p> 
                }

                {(marksJson?.disabled || (!row['link'] && billOption?.createdAt < "2024-07-03T00:00:00.000Z" && !(billOption?.label?.includes('2024129') || billOption?.label?.includes('2024134') || billOption?.label?.includes('2023135') || billOption?.label?.includes('2024137') || billOption?.label?.includes('2024136'))))?
                    <p className='text-xs text-gray-400'>(Descartado por ISD)</p> 
                : null }


                {(marksJson['chyrons_facturated'] >= 1)?
                    <div className='text-xs' style={{ marginLeft: 4 }}>
                        <Tooltip disableInteractive title={'+' + ((marksJson['chyrons_facturated'] === 1)? marksJson['chyrons_facturated'] + ` chyron del ${currentCustomer?.label_professor?.toLowerCase()} revisado / corregido` : (marksJson['chyrons_facturated'] + ` chyrons de ${currentCustomer?.label_professors?.toLowerCase()} facturados`))} placement="bottom" arrow>
                            <div className='flex items-center space-x-[2px] text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded pl-[2px]'>
                                <p>+{marksJson['chyrons_facturated']}</p>
                                {userIcon}
                            </div>
                        </Tooltip>
                    </div>
                : null }
            </div>
        }


        if (column?.id === 'minutes') {
            const minsTMP = (marksJson?.min_facturable)? parseFloat(marksJson?.min_facturable) : (marksJson?.min_final)? parseFloat(marksJson?.min_final) : 0

            value = ((returnPrice)? 
                ((parseFloat(minsTMP))? parseFloat(minsTMP) : 0)
            :
                (minsTMP)? formatNumbersToPrint(parseFloat(minsTMP), 2) + 'm' 
                : '---'
            )
        }
        
        if (column?.id === 'number_of_words') {
            var paraules = ((parseInt(marksJson?.nb_paraules))? parseInt(marksJson?.nb_paraules) : (parseInt(marksJson?.nb_paraules_all))? parseInt(marksJson?.nb_paraules_all) : 0 /* (((parseInt(marksJson?.nb_paraules_eng))? parseInt(marksJson?.nb_paraules_eng) : 0) + ((parseInt(marksJson?.nb_paraules_esp))? parseInt(marksJson?.nb_paraules_esp) : 0)) */)

            if (extraLanguagesJson?.length) {
                for (var t in extraLanguagesJson) {
                    var extraLanguageTMP = extraLanguagesJson[t];
                    if (parseInt(extraLanguagesJson?.nb_paraules_extra_esp) || parseInt(extraLanguagesJson?.nb_paraules_extra_eng)) {
                        paraules = paraules + ((parseInt(extraLanguagesJson?.nb_paraules_extra_esp))? parseInt(extraLanguagesJson?.nb_paraules_extra_esp) : 0) + ((parseInt(extraLanguagesJson?.nb_paraules_extra_eng))? parseInt(extraLanguagesJson?.nb_paraules_extra_eng) : 0)
                    }
                }
            }

            value = ((returnPrice)? 
                ((parseFloat(paraules) && parseFloat(marksJson?.traduc))? parseFloat(paraules) : 0)
            :
                (parseFloat(paraules) && parseFloat(marksJson?.traduc))? parseFloat(paraules) : '---'
            )
        }

        if (column?.id === 'transcription') {
            /* const hasTranscr = (marksJson?.min && (marksJson?.trancr || marksJson?.trancr_promp))? true : false */
            
            value = ((returnPrice)?  ((parseFloat(marksJson?.trancr))? parseFloat(marksJson?.trancr) : 0)
            : (marksJson?.trancr)? formatNumbersToPrint(marksJson?.trancr, 2)+' €' 
            : '---' )
        
            if (parseInt(marksJson['trancr_promp']) && !returnPrice && value && parseFloat(value)) {
                value = <div className='flex space-x-1' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                    {(value && parseFloat(value))?
                        <Tooltip
                            disableInteractive
                            title={"Transcripción automática / prompterizada"}
                            placement="bottom"
                            arrow
                        >
                            <button className='px-1 py-[2px] text-xs text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded'>
                                TP
                            </button>
                        </Tooltip>
                    : null }
                    <div className={`min-w-[50px] ${(value && parseFloat(value))? '' : 'opacity-30'}`}>{value}</div>
                </div>
            }
        }

        if (column?.id === 'subtitulation_esp') {
            const subsTMP = (parseFloat(marksJson?.sub_esp))? parseFloat(marksJson?.sub_esp) : (parseFloat(marksJson?.sub_es))? parseFloat(marksJson?.sub_es) : 0;
            const min = (parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : (subsTMP && parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0
            const subType = Math.round((parseFloat(subsTMP) / min) * 10) / 10

            value = ((returnPrice)? 
                ((parseFloat(subsTMP))? parseFloat(subsTMP) : 0)
            :(subsTMP)?
                <div className='flex space-x-1' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                    {(subsTMP)?
                        <Tooltip disableInteractive placement="bottom" arrow title={(subType === 3)? 'Sincronización de subtítulos terciarios' : (subType === 4.5)? 'Subtitulación de idiomas secundarios' : 'Primera sincronización de subtítulos'}>
                            <button className='px-1 py-[2px] text-xs text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded'>
                                S{(subType === 3)? '³' : (subType === 4.5)? '²' : '¹'}
                            </button>
                        </Tooltip>
                    : null }
                    <div className={`min-w-[50px] ${(subsTMP)? '' : 'opacity-30'}`}>{(subsTMP)? formatNumbersToPrint(subsTMP, 2)+' €' : '---'}</div>
                </div>
            : '---' )
        }

        if (column?.id === 'subtitulation_eng') {
            const subsTMP = (parseFloat(marksJson?.sub_eng))? parseFloat(marksJson?.sub_eng) : (parseFloat(marksJson?.sub_en))? parseFloat(marksJson?.sub_en) : 0;
            const min = (parseFloat(marksJson?.min_facturable))? parseFloat(marksJson?.min_facturable) : (subsTMP && parseFloat(marksJson?.min_final))? parseFloat(marksJson?.min_final) : 0
            const subType = Math.round((parseFloat(subsTMP) / min) * 10) / 10

            value = ((returnPrice)? 
                ((parseFloat(subsTMP))? parseFloat(subsTMP) : 0)
            :(subsTMP)?
                <div className='flex space-x-1' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                    {(subsTMP)?
                        <Tooltip disableInteractive placement="bottom" arrow title={(subType === 3)? 'Sincronización de subtítulos terciarios' : (subType === 4.5)? 'Subtitulación de idiomas secundarios' : 'Primera sincronización de subtítulos'}>
                            <button className='px-1 py-[2px] text-xs text-gray-400 hover:text-gray-700 duration-200 cursor-pointer hover:bg-gray-300 rounded'>
                                S{(subType === 3)? '³' : (subType === 4.5)? '²' : '¹'}
                            </button>
                        </Tooltip>
                    : null }
                    <div className={`min-w-[50px] ${(subsTMP)? '' : 'opacity-30'}`}>{(subsTMP)? formatNumbersToPrint(subsTMP, 2)+' €' : '---'}</div>
                </div>
            : '---' )
        }

        if (column?.id === 'translation_esp' && ((!marksJson?.original_language && marksJson?.language === 'eng') || (marksJson?.original_language && marksJson?.language === 'esp'))) {
            value = ((returnPrice)? 
                ((parseFloat(marksJson?.traduc))? parseFloat(marksJson?.traduc) : 0)
            :
                ((marksJson?.traduc)? formatNumbersToPrint(marksJson?.traduc, 2)+' €' : '---')
            )
        }

        if (column?.id === 'translation_eng' && ((!marksJson?.original_language && marksJson?.language === 'esp') || (marksJson?.original_language && marksJson?.language === 'eng'))) {
            value = ((returnPrice)? 
                ((parseFloat(marksJson?.traduc))? parseFloat(marksJson?.traduc) : 0)
            :
                ((marksJson?.traduc)? formatNumbersToPrint(marksJson?.traduc, 2)+' €' : '---')
            )
        }


        if (extraLanguagesJson?.length) {
            for (var t in extraLanguagesJson) {
                var extraLanguageTMP = extraLanguagesJson[t];

                if (marksJson['traduc_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_translation'] && column?.id === 'translation_'+extraLanguageTMP['language']) {
                    value = ((returnPrice)? 
                        (value + ((parseFloat(marksJson['traduc_extra_'+extraLanguageTMP['language']]))? parseFloat(marksJson['traduc_extra_'+extraLanguageTMP['language']]) : 0))
                    :
                        (
                            (marksJson['traduc_extra_'+extraLanguageTMP['language']])? formatNumbersToPrint(marksJson['traduc_extra_'+extraLanguageTMP['language']], 2)+' €' 
                            : '---'
                        )
                    )
                }
            }
        }

        if (column?.id === 'preu') {
            value = ((returnPrice)? 
                ((parseFloat(value))? parseFloat(value) : 0)
            :
                formatNumbersToPrint(((parseFloat(value))? parseFloat(value) : 0), 2) + ' €'
            )
        }




        if (column?.id !== 'preu' && !returnPrice && (value === '---' || !value)) {
            value = checkNullTypeFunc(column, marksJson, returnPrice, 
                (value === '---' && ((marksJson?.language === 'eng' && column?.id === 'translation_eng') || (marksJson?.language === 'esp' && column?.id === 'translation_esp')))? 'no_aplicable'
                : (column?.id?.includes('subtitulation'))? 'subtitulation'
                : (column?.id?.includes('transcription'))? 'transcription'
                : (column?.id?.includes('translation'))? 'translation'
                : (column?.id?.includes('number_of_words'))? 'number_of_words'
                : null
            )
        }



        return value
        
    }



    function checkNullTypeFunc(column, marksJson, returnPrice, type) {
        switch(type) {
            case 'no_aplicable':
                return <div className='flex opacity-25' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                    <Tooltip title={"Traducción no aplicable: mismo idioma"} placement="bottom" disableInteractive arrow>
                        {notDoneIcon}
                    </Tooltip>
                </div>
            case 'subtitulation':
                return (marksJson?.facturated?.subtitulation && !returnPrice)? <div className='flex justify-end opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
            case 'transcription':
                return (marksJson?.facturated?.transcription && !returnPrice)? <div className='flex justify-end opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
            case 'translation':
                return (marksJson?.facturated?.translation && !returnPrice)? <div className='flex justify-end opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
            case 'number_of_words':
                return (marksJson?.facturated?.translation && !returnPrice)? <div className='flex justify-center opacity-25 text-gray-500'><div className='scale-[1.1]'>{euroIcon}</div></div> : '---'
            default:
                return '---'
        }
    }












  return (currentCustomer && !loadingBills && !baseLoading)? (
    <div className='space-y-2'>









        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>

                    <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.dateFormated}</p>
                </div>
            </div>



            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            {(billOption?.observations) && 
                <div className='flex flex-wrap space-y-4 pb-4 text-base'>
                    <div dangerouslySetInnerHTML={{__html: (billOption?.observations)? billOption?.observations : ''}}></div> 
                </div>
            }



            <div className='w-full flex overflow-auto'>
                <div className='w-full flex flex-wrap justify-end space-y-4'>

                    {startListcats?.map(legend => {
                        return (legend?.price)? 
                            <div 
                                className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                                key={'extra-1-' + legend?.token}
                            >
                                <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                    <div className='px-2 py-[6px] w-full font-bold'>{legend?.label}</div>
                                    <div className='px-2 py-[6px] text-sm text-gray-400'>{formatNumbersToPrint(legend?.price, 2)} €</div>
                                </div>
                                <p className='px-2 pb-1 text-sm text-gray-700'>
                                    {legend?.desc}
                                </p>
                            </div>
                        : null
                    })}

                    {billExtras?.map(extra => {
                        return <div 
                            className='w-full bg-gray-300 bg-opacity-75 duration-200 rounded font-normal overflow-hidden hover:bg-gray-400 hover:bg-opacity-50 cursor-pointer'
                            key={'extra-2-' + extra?.id}
                        >
                            <div className='flex justify-between text-left whitespace-nowrap w-full text-base'>
                                <div className='px-2 py-[6px] w-full font-bold'>{(extra?.title)? remove_tags(extra?.title, true) : 'Sin título'}</div>
                                <div className='px-2 py-[6px] text-sm text-gray-400'>{formatNumbersToPrint(((extra?.price / 100) * (extra?.units / 100)), 2)} €</div>
                            </div>
                            <p
                                className='px-2 pb-1 text-sm text-gray-700'
                                dangerouslySetInnerHTML={{__html: (extra?.description)? extra?.description : '' }}
                            ></p>
                        </div>
                    })}



                    <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                        <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                            <p>Base imponible:</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(bill?.total_alias) / 1.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>IVA (21%):</p>
                            <p>
                                {formatNumbersToPrint((parseFloat(bill?.total_alias) / 1.21) * 0.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>Importe total:</p>
                            <p>
                                {formatNumbersToPrint(bill?.total_alias, 2)} €
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>









        <p className='w-full pt-8 flex justify-between items-center space-x-8 text-xs text-gray-500'>Tablas de leyendas:</p>
        <div className={`border border-gray-300 rounded overflow-hidden bg-gray-300`}>
            <div onClick={() => { setShowLegend(!showLegend) }} className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}>
                <p className='text-lg font-bold px-2'>Leyenda</p>
                {(tableOpen)? upArrowIcon : downArrowIcon}
            </div>

            <Collapse orientation="vertical" in={showLegend} timeout={1000}>
                <div className='p-4 text-xs bg-gray-50'>
                    <div className='w-full flex items-start space-x-4'> 
                        {(selectedLegend?.length)?
                            selectedLegend?.map((selectedTable, key) => {
                                return <div key={`start-table-${key}`} className='w-full border border-gray-300 rounded overflow-hidden'>
                                    <table className='w-full font-normal text-left bg-gray-100'>
                                        <thead className='bg-gray-200'>
                                            <tr>
                                                {selectedTable?.title?.map(element => {
                                                    return <th key={`start-table-title-${element}`} className='px-2 py-[6px]'>{element} </th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedTable?.table?.map(element => {
                                                return <tr key={`start-table-row-${element?.title}`}>
                                                    <td className='px-2 py-[6px] border'>{element?.title}</td>
                                                    <td className='px-2 py-[6px] border border-r-0'>{element?.price}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            })
                        : null }
                    </div>

                    <div className='w-full pt-6 pb-2 h-[15px] flex space-x-3 opacity-60'>
                        <div className='flex items-center'> {euroIcon} <p>Ya facturado</p> </div>
                        <div className='flex items-center'> <div className='scale-[0.8]'>{notTimeIcon}</div> <p>Sin novedades</p> </div>
                        <div className='flex items-center space-x-1 pl-1'> <p>---</p> <p>No facturado</p> </div>
                    </div>
                </div>
            </Collapse>
        </div>










        {(concepts?.length)?
            <>
                <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>
                
                <div className={`border border-gray-300 rounded bg-gray-300`}>
                    <div 
                        className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                        onClick={() => { setTableOpen(!tableOpen) }}
                    >
                        <p className='text-lg font-bold px-2'>Listado por vídeo</p>
                        {(tableOpen)? upArrowIcon : downArrowIcon}
                    </div>

                    <Collapse orientation="vertical" in={tableOpen} timeout={1000}>
                        <div className='border-t border-gray-300'>
                            <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                    <Table>

                                        <TableHead>
                                            <TableRow className={`${(bill_token || !tableOpen)? 'table-stiky-0' : 'table-stiky'}`}>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={'columns-1-'+column.id}
                                                        className='select-none'
                                                        align={column.align}
                                                        style={{ 
                                                            minWidth: column.minWidth,
                                                            width: column.width,
                                                            backgroundColor: 'inherit',
                                                            padding: 8,
                                                        }}
                                                    >
                                                        <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                            <div> {column.label} </div>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {cats?.filter((element) => parseInt(element?.pare_id))?.map((subCat) => {

                                                var finalPriceTMP = concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)

                                                return (finalPriceTMP)? <>
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        className="w-full bg-blue-50 duration-200"
                                                    >
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                            <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                        </TableCell>

                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                        {(hasSubs?.esp)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null}
                                                        {(hasSubs?.eng)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null}

                                                    </TableRow>

                                                    {concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.map((row) => {
                                                        if (parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id)) {
                                                            return (
                                                                <TableRow 
                                                                    key={'row-1-'+row.id} 
                                                                    hover 
                                                                    role="checkbox" 
                                                                    tabIndex={-1} 
                                                                    className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                                >
                                                                    {columns.map((column) => {
                                                                        var value = (row[column.id])? row[column.id] : '---';
                                                                        value = processBaseTableFunc(value, column, row, false)

                                                                        return (
                                                                            <TableCell
                                                                                key={'column-data-1-'+column.id}
                                                                                align={column.align}
                                                                                style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                                className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                            >
                                                                                {value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        } 
                                                    })}
                                                </> : null
                                            })}




                                            <TableRow className='bg-gray-300 bg-opacity-20'>
                                                {columns.map((column, colKey) => {
                                                    var priceTMP = (colKey)? 0 : 'Total';

                                                    if (priceTMP === 0) {
                                                        const conceptsTMP = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )
                                                        for (let row of conceptsTMP) {
                                                            var value = (row[column.id])? row[column.id] : (column.id?.includes('translation'))? 0 :'---';
                                                            const priceTMPFUNC = processBaseTableFunc(value, column, row, true);

                                                            if ((parseFloat(priceTMP) || parseFloat(priceTMP) === 0) && (parseFloat(priceTMPFUNC) || parseFloat(priceTMPFUNC) === 0)) {
                                                                priceTMP = priceTMP + priceTMPFUNC
                                                            } else {
                                                                priceTMP = priceTMPFUNC;
                                                            }
                                                        }
                                                    }

                                                    if (column.id === 'minutes') {
                                                        priceTMP = (parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + 'm' : '---'
                                                    }

                                                    if (column.id === 'number_of_words') {
                                                        priceTMP = (parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP)) + ' palabras' : '---'
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={'column-data-'+column.id}
                                                            align={column.align}
                                                            style={{ borderBottom: 0, borderTop: '1px solid rgba(210, 210, 210, 1)', padding: 8, fontSize: 14 }}
                                                        >
                                                            <p
                                                                className={`text-sm whitespace-nowrap
                                                                    ${
                                                                        (!priceTMP || priceTMP === '---')? 'opacity-30' 
                                                                        : ((column.id === 'concepte' || column.id === 'preu')? 'font-bold' : 'opacity-50')
                                                                    }
                                                                `}  
                                                            >
                                                                {(!isNaN(priceTMP) && parseFloat(priceTMP))? formatNumbersToPrint(parseFloat(priceTMP), 2) + ' €' : (priceTMP && priceTMP !== '---')? priceTMP : ''}
                                                            </p>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>




                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </Collapse>
                </div>

            </>
        : null } 





        {(billExtras?.filter((el) => el?.childs?.length)?.length)?
            billExtras?.filter((el) => el?.childs?.length)?.map(element => {
                return <PersExtraItems 
                    element={element}
                    billOption={billOption}
                />
            })
        : null }






        {(concepts?.length && currentUser && !currentUser?.rol?.includes('user'))? <>
            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setTableXProfessorOpen(!tableXProfessorOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Listado por profesor</p>
                    {(tableXProfessorOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={tableXProfessorOpen} timeout={1000}>
                    <div className='border-t border-gray-300'>
                        <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                            <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                <Table>

                                    <TableHead>
                                        <TableRow className={`bg-[#e7e8eb]`}>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={'col-2-'+column.id}
                                                    className='select-none'
                                                    align={column.align}
                                                    style={{ 
                                                        minWidth: column.minWidth,
                                                        width: column.width,
                                                        backgroundColor: 'inherit',
                                                        padding: 8,
                                                    }}
                                                >
                                                    <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                                                        <div> {column.label} </div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                            var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

                                            return (finalPriceTMP)? <>
                                                {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                    
                                                    const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                    var info = {}
                    
                                                    for (var i in vids) {
                                                        const row = vids[i]
                                                        const markJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                        const extraLanguagesJson = (row['extra_languages_json'] && JSON.parse(row['extra_languages_json']))? JSON.parse(row['extra_languages_json']) : null;
                                                        const isLastVideo = ((vids?.length-1) === parseInt(i))? true : false

                                                        for (var column of columns) {
                                                            if (!info[column?.id]) { info[column?.id] = {} }
                                                            if (!info[column?.id]?.value && info[column?.id]?.value !== 0) { info[column?.id].value = 0 }
                                                            if (!info[column?.id]?.id && column.id) { info[column?.id].id = column.id }
                                                            if (!info[column?.id]?.align && column.align) { info[column?.id].align = column.align }
                                                            
                                                            if (column?.id === 'minutes') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.min_facturable))? parseFloat(markJson?.min_facturable) : (parseFloat(markJson?.min_final))? parseFloat(markJson?.min_final) : 0)

                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + 'm' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'number_of_words') {
                                                                info[column?.id].value = info[column?.id].value + ((parseInt(markJson?.nb_paraules) && markJson?.traduc)? parseInt(markJson?.nb_paraules) : 0)

                                                                if (extraLanguagesJson?.length) {
                                                                    for (let t in extraLanguagesJson) {
                                                                        var extraLanguageTMP = extraLanguagesJson[t];
                                                                        if (parseInt(extraLanguagesJson?.nb_paraules_extra_esp) || parseInt(extraLanguagesJson?.nb_paraules_extra_eng)) {
                                                                            info[column?.id].value = info[column?.id].value + (((parseInt(extraLanguagesJson?.nb_paraules_extra_esp))? parseInt(extraLanguagesJson?.nb_paraules_extra_esp) : 0) + ((parseInt(extraLanguagesJson?.nb_paraules_extra_eng))? parseInt(extraLanguagesJson?.nb_paraules_extra_eng) : 0))
                                                                        }
                                                                    }
                                                                }

                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseInt(info[column?.id].value), 0) : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'transcription') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.trancr))? parseFloat(markJson?.trancr) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'subtitulation_esp') {
                                                                const subsTMP = (parseFloat(markJson?.sub_esp))? parseFloat(markJson?.sub_esp) : (parseFloat(markJson?.sub_es))? parseFloat(markJson?.sub_es) : 0;
                                                                info[column?.id].value = info[column?.id].value + subsTMP
                                                            
                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'subtitulation_eng') {
                                                                const subsTMP = (parseFloat(markJson?.sub_eng))? parseFloat(markJson?.sub_eng) : (parseFloat(markJson?.sub_en))? parseFloat(markJson?.sub_en) : 0;
                                                                info[column?.id].value = info[column?.id].value + subsTMP
                                                            
                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'translation_esp' && ((!markJson?.original_language && markJson?.language === 'eng') || (markJson?.original_language && markJson?.language === 'esp'))) {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.traduc))? parseFloat(markJson?.traduc) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'translation_eng' && ((!markJson?.original_language && markJson?.language === 'esp') || (markJson?.original_language && markJson?.language === 'eng'))) {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(markJson?.traduc))? parseFloat(markJson?.traduc) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[column?.id].value = (info[column?.id].value)? formatNumbersToPrint(parseFloat(info[column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (extraLanguagesJson?.length) {
                                                                for (let t in extraLanguagesJson) {
                                                                    var extraLanguageTMP = extraLanguagesJson[t];

                                                                    if (markJson['traduc_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_translation'] && column?.id === 'translation_'+extraLanguageTMP['language']) {
                                                                        info[column?.id].value = (markJson['traduc_extra_'+extraLanguageTMP['language']])? formatNumbersToPrint(markJson['traduc_extra_'+extraLanguageTMP['language']], 2)+' €' : '---'
                                                                    }
                                                                }
                                                            }

                                                            if (column?.id === 'preu') {
                                                                info[column?.id].value = info[column?.id].value + ((parseFloat(row[column?.id]))? parseFloat(row[column?.id]) : 0);
                                                                if (isLastVideo) { info[column?.id].value = formatNumbersToPrint(info[column?.id].value, 2) + ' €'; }
                                                            }
                                                        }
                                                    }

                                                    return (vids?.length && info && Object?.keys(info)?.length)? <>
                                                        <TableRow 
                                                            key={'prodessor-' + column?.id} 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='w-full'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                            </TableCell>

                                                            {Object?.keys(info)?.map((valKey) => {
                                                                const column = info[valKey]
                                                                return (valKey !== 'concepte')? 
                                                                    <TableCell
                                                                        key={'column-data-2-' + column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {(column?.value)? column?.value : '---'}
                                                                    </TableCell>
                                                                : null 
                                                            })}
                                                        </TableRow>
                                                    </> : null
                                                })}
                                            </> : null
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Collapse>
            </div>



            <div className={`border border-gray-300 rounded bg-gray-300`}>
                <div 
                    className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                    onClick={() => { setTableXTopicOpen(!tableXTopicOpen) }}
                >
                    <p className='text-lg font-bold px-2'>Listado por tema</p>
                    {(tableXTopicOpen)? upArrowIcon : downArrowIcon}
                </div>

                <Collapse orientation="vertical" in={tableXTopicOpen} timeout={1000}>
                    <div className='border-t border-gray-300'>
                        <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                            <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                <Table>

                                    <TableHead>
                                        <TableRow className={`bg-[#e7e8eb]`}>
                                            {columns?.map((column) => (
                                                <TableCell
                                                    key={'col-' + column.id}
                                                    className='select-none'
                                                    align={column.align}
                                                    style={{ 
                                                        minWidth: column.minWidth,
                                                        width: column.width,
                                                        backgroundColor: 'inherit',
                                                        padding: 8,
                                                    }}
                                                >
                                                    <div className='flex text-xs' style={{ justifyContent: (column.align === 'right')? 'flex-end' : (column.align === 'center')? 'center' : 'flex-start'}}>
                                                        <div> {column.label} </div>
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cats?.filter((element) => !parseInt(element?.pare_id))?.map((cat) => {
                                            const finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(cat?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => row?.preu ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                                            const subCats = {};
                                            

                                            return (finalPriceTMP)? <>
                                                {cats?.filter((element) => parseInt(element?.pare_id) === parseInt(cat?.id))?.map((subCat) => {
                                                    const vids = concepts?.sort((a, b) => (a?.nom < b?.nom)? -1 : (a?.nom > b?.nom)? 1 : 0 )?.filter((row) => parseInt(row?.document_concepte_categoria_id) === parseInt(subCat?.id));
                                                    var info = {}
                    
                                                    for (var i in vids) {
                                                        const row = vids[i]
                                                        const nameKey = (row?.concepte?.split('-')?.length)? row?.concepte?.split('-')[0] : row?.concepte
                                                        const markJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                                                        const extraLanguagesJson = (row['extra_languages_json'] && JSON.parse(row['extra_languages_json']))? JSON.parse(row['extra_languages_json']) : null;
                                                        const isLastVideo = (!vids[parseInt(i)+1] || !vids[parseInt(i)+1]?.concepte?.includes(nameKey))? true : false
    
                                                        for (var column of columns) {
                                                            if (!info[nameKey]) { info[nameKey] = {} }
                                                            if (!info[nameKey][column?.id]) { info[nameKey][column?.id] = {} }
                                                            if (!info[nameKey][column?.id]?.value && info[nameKey][column?.id]?.value !== 0) { info[nameKey][column?.id].value = 0 }
                                                            if (!info[nameKey][column?.id]?.id && column.id) { info[nameKey][column?.id].id = column.id }
                                                            if (!info[nameKey][column?.id]?.align && column.align) { info[nameKey][column?.id].align = column.align }

                                                            if (column?.id === 'concepte') {
                                                                if (!info[nameKey][column?.id]?.value) { info[nameKey][column?.id].value = nameKey }
                                                            }

                                                            if (column?.id === 'minutes') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.min_facturable))? parseFloat(markJson?.min_facturable) : (parseFloat(markJson?.min_final))? parseFloat(markJson?.min_final) : 0)

                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + 'm' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'number_of_words') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseInt(markJson?.nb_paraules) && markJson?.traduc)? parseInt(markJson?.nb_paraules) : 0)

                                                                if (extraLanguagesJson?.length) {
                                                                    for (let t in extraLanguagesJson) {
                                                                        var extraLanguageTMP = extraLanguagesJson[t];
                                                                        if (parseInt(extraLanguagesJson?.nb_paraules_extra_esp) || parseInt(extraLanguagesJson?.nb_paraules_extra_eng)) {
                                                                            info[nameKey][column?.id].value = info[nameKey][column?.id].value + (((parseInt(extraLanguagesJson?.nb_paraules_extra_esp))? parseInt(extraLanguagesJson?.nb_paraules_extra_esp) : 0) + ((parseInt(extraLanguagesJson?.nb_paraules_extra_eng))? parseInt(extraLanguagesJson?.nb_paraules_extra_eng) : 0))
                                                                        }
                                                                    }
                                                                }

                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseInt(info[nameKey][column?.id].value), 0) : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'transcription') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.trancr))? parseFloat(markJson?.trancr) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'subtitulation_esp') {
                                                                const subsTMP = (parseFloat(markJson?.sub_esp))? parseFloat(markJson?.sub_esp) : (parseFloat(markJson?.sub_es))? parseFloat(markJson?.sub_es) : 0;
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + subsTMP
                                                            
                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'subtitulation_eng') {
                                                                const subsTMP = (parseFloat(markJson?.sub_eng))? parseFloat(markJson?.sub_eng) : (parseFloat(markJson?.sub_en))? parseFloat(markJson?.sub_en) : 0;
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + subsTMP
                                                            
                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'translation_esp' && ((!markJson?.original_language && markJson?.language === 'eng') || (markJson?.original_language && markJson?.language === 'esp'))) {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.traduc))? parseFloat(markJson?.traduc) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (column?.id === 'translation_eng' && ((!markJson?.original_language && markJson?.language === 'esp') || (markJson?.original_language && markJson?.language === 'eng'))) {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(markJson?.traduc))? parseFloat(markJson?.traduc) : 0)
                                                            
                                                                if (isLastVideo) {
                                                                    info[nameKey][column?.id].value = (info[nameKey][column?.id].value)? formatNumbersToPrint(parseFloat(info[nameKey][column?.id].value), 2) + ' €' : '---'
                                                                }
                                                            }

                                                            if (extraLanguagesJson?.length) {
                                                                for (let t in extraLanguagesJson) {
                                                                    var extraLanguageTMP = extraLanguagesJson[t];

                                                                    if (markJson['traduc_extra_'+extraLanguageTMP['language']] && extraLanguageTMP['has_translation'] && column?.id === 'translation_'+extraLanguageTMP['language']) {
                                                                        info[nameKey][column?.id].value = (markJson['traduc_extra_'+extraLanguageTMP['language']])? formatNumbersToPrint(markJson['traduc_extra_'+extraLanguageTMP['language']], 2)+' €' : '---'
                                                                    }
                                                                }
                                                            }

                                                            if (column?.id === 'preu') {
                                                                info[nameKey][column?.id].value = info[nameKey][column?.id].value + ((parseFloat(row[column?.id]))? parseFloat(row[column?.id]) : 0);
                                                                if (isLastVideo) { info[nameKey][column?.id].value = formatNumbersToPrint(info[nameKey][column?.id].value, 2) + ' €'; }
                                                            }
                                                        }
                                                    }

                                                    return (vids?.length && info && Object.keys(info)?.length)? <>
                                                        <TableRow 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="w-full bg-blue-50 duration-200"
                                                            key={'row-subcat-' + subCat?.id}
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='text-xs font-bold w-5 whitespace-nowrap'>{subCat?.nom?.replaceAll('Otro...', 'Otros')}</p>
                                                            </TableCell>

                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            {(hasSubs?.esp)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null}
                                                            {(hasSubs?.eng)? <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell> : null}
                                                        </TableRow>

                                                        {Object.keys(info)?.map((topicKey) => {
                                                            const video = info[topicKey]
                                                            return <TableRow 
                                                                key={'row-video-' + video?.concepte?.value} 
                                                                hover 
                                                                role="checkbox" 
                                                                tabIndex={-1} 
                                                                className="hover:bg-gray-100 duration-200 whitespace-nowrap"
                                                            >
                                                                {Object.keys(video).map((videoKey) => {
                                                                    var column = (video[videoKey])? video[videoKey] : '---';

                                                                    return <TableCell
                                                                        key={'column-data-' + column.id}
                                                                        align={column.align}
                                                                        style={{ borderBottom: 0, padding: 8, fontSize: 14 }}
                                                                        className={`text-sm ${(!column?.value || column?.value === '---')? 'opacity-30' : ''}`}
                                                                    >
                                                                        {(column?.value)? column?.value : '---'}
                                                                    </TableCell>
                                                                })}
                                                            </TableRow>

                                                        })}
                                                    </> : null
                                                })}
                                            </> : null
                                        })}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Collapse>
            </div>
        </> : null }








    </div>

  ) : 

    <div className='absolute z-10 w-full h-full'>
        <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>

}