import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLazyQuery } from '@apollo/client'

import GetStatesByCustomer from '../graphql/queries/GetStatesByCustomer'

import MenuItem from '@mui/material/MenuItem';
import CustomMultipleSelect from './Inputs/CustomMultipleSelect';






export default function ItemListStatesSelect({selectedStates, setSelectedStates, disabled, cookies}) {



  const { currentUser, currentCustomer } = useContext(AuthContext)

  const [states, setStates] = useState(null);
  const [isHiddenState, setIsHiddenState] = useState(null);





  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })





  useEffect(() => {    
    if (selectedStates && states?.length) {
      var isHiddenState = false;
      var endState = null;

      if (currentUser?.rol?.includes('user')) {
        for (var stateTMP of states) {
          if (parseInt(selectedStates) === parseInt(stateTMP?.id) && stateTMP?.hidden) {
            isHiddenState = true;
          }
  
          if (stateTMP?.ended_marks && stateTMP?.color === 'green' && !stateTMP?.hidden) {
            endState = stateTMP?.id;
          }
        }
      }

      setIsHiddenState((isHiddenState && endState)? endState : null)
    }
  }, [selectedStates, states])


  useEffect(() => {
    getStatesByCustomer()
  }, []);


  useEffect(() => {
    if (dataStates?.getStatesByCustomer?.length) {
      setStates(dataStates?.getStatesByCustomer)
    } else {
      setStates(null)
    }
  }, [dataStates]);










  return (
    <CustomMultipleSelect
      label="Estados"
      disabled={disabled}

      value={(isHiddenState)? isHiddenState : (selectedStates)? selectedStates : ['null']}
      onChange={(event) => {
        const valueTMP = (
          (event.target.value?.length > 1 && event.target.value[event.target.value?.length-1] === 'null')? ['null']
          : (event.target.value?.length > 1)? event.target.value?.filter((el) => el !== 'null')
          : (event.target.value?.length)? event.target.value
          : ['null']
        );
        setSelectedStates([...new Set(valueTMP)])
        if (cookies) {
          localStorage.setItem(currentCustomer?.token+'-video-state-filter', event.target.value)
        }
      }}
      renderValue={(selected) => {
        var names = [];
        if (states?.length && selected?.length && selected[0] !== 'null') {
          for (var itemSelected of selected) {
            const itemTMP = states?.find((x) => parseInt(x?.id) === parseInt(itemSelected))
            names.push(itemTMP?.name)
          }
        }

        return <p className={`${(names?.length > 4)? 'text-xs' : (names?.length > 2)? 'text-sm' : ''}`}>
          {(names?.length)? names?.join(', ').replace(/,([^,]*)$/, ' y $1') : 'Sin filtro por estado'}
        </p>
      }}
    >
      <MenuItem value={'null'}>
        <div className={`flex items-center space-x-2 truncate w-full menu-item- gray`}>
          <div className={`hover:opacity-50 p-[6px] menu-item-ball-gray`}></div>
          <p>Sin filtro por estado</p>
        </div>
      </MenuItem>

      {states?.map((stateTMP, key) => {
        const hiddenState = (currentUser?.rol?.includes('user') && stateTMP?.hidden)? true : false

        return (!hiddenState)? 
          <MenuItem 
            key={'state-list-idk-' + stateTMP.id} 
            value={stateTMP.id} 
          >
            <div className={`flex items-center space-x-2 truncate w-full menu-item- ${stateTMP.color}`}>
              <div className={`hover:opacity-50 p-[6px] menu-item-ball-${stateTMP.color}`}></div>
              <p className='text-sm'>{stateTMP.name}</p>
            </div>
          </MenuItem>
        : null 
      })}
    </CustomMultipleSelect>
  );
}