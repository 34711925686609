export default function FALSO_DIRECTO_EN_PLATO() {
	return <>
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor'>
			<g>
				<path d="M379.42,153.57C343.96,94.6,275.77,67.39,208.88,85.54c-63.76,17.3-109.18,77.29-109.33,149.93   c0.07,21.35,6.19,46.8,20.16,69.79c6.67,10.98,15.21,20.89,23.48,30.81c4.27,5.12,11.03,4.9,15.36,0.73   c4.32-4.15,4.33-10.39,0.01-15.54c-0.66-0.79-1.41-1.5-2.12-2.25c-46.96-49.46-47.2-127.51-0.53-176.85   c47.57-50.3,125.75-54.59,177.96-9.76c51.93,44.58,60.13,122.33,18.52,176.41c-3.63,4.72-7.91,8.92-11.63,13.57   c-3.68,4.6-3.21,10.74,0.77,14.57c3.99,3.84,10.03,3.93,14.57,0.16c1.1-0.92,2.06-2.01,3.06-3.05   C405.36,285.54,413.76,210.68,379.42,153.57z"/>
				<path d="M244.95,181.38c-22.49,1.87-40.96,20.03-43.96,43.2c-2.94,22.72,10.57,44.59,32.72,52.1   c4.41,1.49,5.52,3.54,5.48,7.85c-0.2,20.16-0.09,40.33-0.09,60.49c0,20.37-0.03,40.74,0.01,61.11c0.02,8.76,3.9,13.74,10.63,13.88   c6.9,0.14,11-5.06,11.09-14.1c0.02-1.44,0-2.88,0-4.32c0-36.83,0.28-73.66-0.21-110.49c-0.11-8.56,0.81-14.76,10.52-16.21   c0.96-0.14,1.83-0.91,2.73-1.41c20.63-11.51,30.15-35.03,23.32-57.57C290.38,193.44,269.1,179.37,244.95,181.38z"/>
				<path d="M348.9,216.46c-6.9-54.77-61.27-94.66-115.64-84.61c-55.31,10.22-90.66,60-81.68,115.57   c3.31,20.45,12.55,38.18,27.24,52.9c4.76,4.77,11.12,4.88,15.42,0.73c4.12-3.97,4.29-10.03,0.37-15.05   c-1.01-1.3-2.14-2.5-3.22-3.74c-13.02-14.84-19.58-32.14-19.66-51.88c-0.07-32.08,19.42-60.97,49.03-72.71   c29.95-11.87,64.14-4.51,85.78,18.46c22.56,23.95,28.51,58.55,14.21,87.54c-4.03,8.17-10.25,15.27-15.6,22.77   c-3.58,5.03-3.3,11.26,0.94,15.06c4.11,3.68,10.05,3.56,14.56-0.43c1.69-1.49,3.23-3.18,4.68-4.9   C344.82,273.04,352.69,246.55,348.9,216.46z"/>
			</g>
		</svg>
	</>
}

