import gql from "graphql-tag";
export default gql`
  query GetEmailTemplateBySlug($own_customer_token: String!, $slug: String!) {
    emailTemplateBySlug(own_customer_token: $own_customer_token, slug: $slug) {
      id
      slug
      title
      body
      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
