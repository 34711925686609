import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext'
import ProgressiveImage from "react-progressive-graceful-image";

import Tooltip from '@mui/material/Tooltip';







export default function VideoMarkFileList({ mark, markTypeObj, markFilename, hasSmallFiles, lang, boxSize, hasContent, mode, newVideoMarkOpen }) {
  



  const { customerPath } = useContext(AuthContext)
  const { fileExists } = useContext(FuncContext)



  /* Icon vars */
  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill='currentColor'><path d="M9.525 18.025q-.5.325-1.013.037Q8 17.775 8 17.175V6.825q0-.6.512-.888.513-.287 1.013.038l8.15 5.175q.45.3.45.85t-.45.85Z"/></svg>;
  const cloudOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="m755 970-73-72H262q-94 0-158-64.5T40 675q0-76 47-137.5T209 459q2-6 4-13.5t5-13.5L82 296q-12-12-12-29t13-30q12-12 29-12t29 13l673 674q12 12 12 29t-12 29q-13 13-30 13t-29-13Zm111-125L324 302q35-24 73.5-36t82.5-12q117 0 199.5 80.5T762 532q68 8 113 60.5T920 715q0 40-15 73t-39 57Z"/></svg>;
  const fileErrorIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M263.717 960Q234 960 213 938.85T192 888V414q0-14.222 5-27.111Q202 374 213 363l150-150q11-11 23.889-16T414 192h282q29.7 0 50.85 21.15Q768 234.3 768 264v624q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717Zm216.072-192Q495 768 505.5 757.711q10.5-10.29 10.5-25.5Q516 717 505.711 706.5q-10.29-10.5-25.5-10.5Q465 696 454.5 706.289q-10.5 10.29-10.5 25.5Q444 747 454.289 757.5q10.29 10.5 25.5 10.5Zm0-144Q495 624 505.5 613.65 516 603.3 516 588V468q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 432 454.5 442.35 444 452.7 444 468v120q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Z"/></svg>;
  const loadIcon = <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
  const linkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h238.132Q459 216 471.5 228.5T484 258q0 17-12.5 29.5T442.132 300H204v552h552V613.868Q756 597 768.5 584.5T798 572q17 0 29.5 12.5t12.5 29.368V852q0 34.65-24.675 59.325Q790.65 936 756 936H204Zm155.957-240.043Q348 684 348 666.5t12-29.5l337-337h-87q-17 0-29.5-12.5T568 258q0-17 12.5-29.5T610 216h188q17 0 29.5 12.5T840 258v188q0 17-12.5 29.5T798 488q-17 0-29.5-12.5T756 446v-87L418 697q-11.512 12-28.256 12t-29.787-13.043Z"/></svg>






  function downloadFileFunc(filename, url) {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', filename);

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }





  
  return ((markFilename || (!lang && mode === 'review') || (!markFilename && lang && mode === 'motion')) && <>
    <div className="group/filebox bg-gray-300 bg-opacity-20 hover:bg-opacity-40 duration-300 rounded-md border border-gray-400 border-opacity-50 hover:border-opacity-100 overflow-hidden">
      <p className='text-xs text-gray-500 group-hover/filebox:text-gray-600 px-2 py-1 border-b border-gray-400 border-opacity-50 group-hover/filebox:border-opacity-100 duration-300'>
        {
          markTypeObj?.type?.includes('images')? 'Imágenes'
          : markTypeObj?.type?.includes('video')? 'Vídeo'
          : markTypeObj?.type?.includes('scheme')? 'Esquema'
          : markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')? 'Fichero'
          : 'Imagen'
        }
        {
          lang === 'esp'? ' en español' 
          : lang === 'eng'? ' en inglés' 
          : markTypeObj?.type?.includes('images')? ' originales' 
          : ' original'
        }
      </p>



      <Tooltip 
        disableInteractive 
        title={(!markFilename || markFilename === '')? (mark?.url? mark.url : "Fichero no subido") : ''}
        placement="bottom" 
        arrow
      >
        <div className={`${boxSize} ${(!markTypeObj?.type?.includes('image') || markTypeObj?.type?.includes('images'))? 'overflow-x-auto' : 'overflow-x-hidden'} flex items-center overflow-y-hidden bg-gray-500 bg-opacity-20`}>
          {(markFilename)? 
            markFilename.split(',')?.map((element, key) => {
              var elementName = element?.split('-')
              if (elementName?.length) { elementName.shift() }
              elementName = elementName?.join('-')

              const filename = `${customerPath}mark_file/${element}`;
              const filenameSecondary = filename.replaceAll('sm-', '')

              const checkIfExists = (filename && hasSmallFiles)? fileExists(filename) : false;
              const finalFilename = (checkIfExists)? filename : filenameSecondary;

              return (finalFilename)? 
                markTypeObj?.type?.includes('video')?

                  <Tooltip key={'video-'+key} disableInteractive title={elementName + " / Abrir en una nueva pestaña" } placement="bottom" arrow>
                    <div 
                      className={`${boxSize} cursor-pointer overflow-hidden flex items-center justify-center text-gray-100 bg-gray-400 bg-opacity-20 group/boxItem hover:bg-gray-300 transition-colors duration-200`} 
                      onClick={() => window.open(`${customerPath}mark_file/${element}`, '_blank').focus()}
                    >
                      <div className='scale-100 group-hover/boxItem:scale-[1.5] p-1 duration-200 bg-gray-500 bg-opacity-50 group-hover/boxItem:bg-opacity-100 rounded-full'>{videoIcon}</div>
                    </div>
                  </Tooltip>

                : markTypeObj?.type?.includes('scheme') || markTypeObj?.type?.includes('special') || markTypeObj?.type?.includes('file')?

                  <Tooltip key={'file-'+key} disableInteractive title={elementName + " / Descargar"} placement="bottom" arrow>
                    <div 
                      className={`${boxSize} cursor-pointer overflow-hidden flex items-center justify-center text-gray-100 bg-gray-400 bg-opacity-20 group/boxItem hover:bg-gray-300 transition-colors duration-200`}
                      onClick={() => downloadFileFunc(element, `${customerPath}mark_file/${element}`)}
                    >
                      <div className='scale-100 group-hover/boxItem:scale-[1.5] p-1 duration-200 bg-gray-500 bg-opacity-50 group-hover/boxItem:bg-opacity-100 rounded-full'>{fileIcon}</div>
                    </div>
                  </Tooltip>

                :

                  <Tooltip disableInteractive key={'img-prev-'+key} title={elementName + " / Abrir en una nueva pestaña"} placement="bottom" arrow>
                    <div className={`${boxSize} relative flex items-center justify-center`}>
                      <ProgressiveImage
                        src={finalFilename}
                        placeholder={`${process.env.REACT_APP_API_ROUTE}image-placeholder.jpg`}
                      >
                        {(src, loading) => {
                          var listItemTMP = document.getElementById('mark-item-box-'+mark?.id);
                          if ((loading && newVideoMarkOpen) && listItemTMP && listItemTMP.style.display !== "none") {
                            listItemTMP.style.display = "none";
                          } else if (listItemTMP && listItemTMP.style.display !== "block") {
                            listItemTMP.style.display = "block";
                          }

                          return <div 
                            onClick={() => window.open(`${customerPath}mark_file/${element}`, '_blank').focus()}
                            className={`group/img flex items-center justify-center h-full w-full bg-gray-400 bg-opacity-20 
                              ${(loading)? '' : 'cursor-pointer'} 
                              ${(typeof markTypeObj === 'string' && (markTypeObj?.includes('images') || markTypeObj?.includes('scheme') || markTypeObj?.includes('special') || markTypeObj?.type?.includes('file')))? 'border-r-[1px] border-gray-300 group-hover/filebox:border-gray-400 duration-300' : ''}
                            `}
                          >
                            {loading && <div className='absolute scale-150 pl-4 flex justify-center items-center z-10 pointer-events-none'>
                              {loadIcon}
                            </div>}

                            <img 
                              className={`z-10 object-cover hover:object-contain max-w-[1000px] h-full text-gray-300 grayscale-[1] contrast-[0.6] duration-300 
                                ${(markTypeObj?.type?.includes('images'))? 'w-full' : ''}
                                ${(loading)? '' : 'group-hover/img:opacity-100 group-hover/img:grayscale-[0] group-hover/img:contrast-[1]'}
                              `}
                              src={src} 
                              alt='mark_img'
                            />
 
                          </div>
                        }}
                      </ProgressiveImage>
                    </div>
                  </Tooltip>

              :

                <div key={'img-prev-'+key} className={`${boxSize} bg-gray-400 bg-opacity-20 flex items-center justify-center space-x-2 text-gray-500 text-xs`}>
                  {fileErrorIcon}
                  <p className='w-16'>Fichero no encontrado</p>
                </div>

            })
          : 
            <div className={`${boxSize} bg-gray-400 bg-opacity-20 flex items-center justify-center space-x-2 text-gray-500 text-xs`} onClick={()=>mark?.url? window.open(mark?.url, '_blank').focus() : null}>
              <div className={`${mark?.url? (mode === 'review'? 'ml-[7%]' : 'ml-[7%]') : null}`}>{mark?.url? linkIcon : cloudOffIcon}</div>
              <p className='w-16'>{mark?.url? 'URL' : 'Fichero no subido'}</p>
            </div>
          }
        </div>
      </Tooltip>
    </div>
  </>);
}