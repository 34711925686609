import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

import DivVideoEditing from '../components/DivVideoEditing';
import ItemListStateChanger from '../components/ItemListStateChanger'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';






export default function UploadingVideoItem({ 

  itemKey,
  item,
  list,
  setList,
  isText,

  dataVideoNames,
  dataProfessors,
  dataModules,

  selectedModule,

  openVideoAdminEitor,
  openPreUploadEditors,

  triggerSaveAll,
  setTriggerSaveAll,

  uploadNewReviews,
  uploadingVideos,
  uploadingEnded,
  uploadingProgress,
  
}) {



  const { currentCustomer, languageList } = useContext(AuthContext)



  const [selectedVideo, setSelectedVideo] = useState(null);



  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>

  const okayIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="m429 618-60-59q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l85 85q11 11 25 11t25-11l187-187q11-11 11-25.5T641 457q-11-11-25-11t-25 11L429 618Zm51 342q-79 0-149-30t-122.5-82.5Q156 795 126 725T96 576q0-80 30-149.5t82.5-122Q261 252 331 222t149-30q80 0 149.5 30t122 82.5Q804 357 834 426.5T864 576q0 79-30 149t-82.5 122.5Q699 900 629.5 930T480 960Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>;
  const invalidIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.789-288Q495-288 505.5-298.289q10.5-10.29 10.5-25.5Q516-339 505.711-349.5q-10.29-10.5-25.5-10.5Q465-360 454.5-349.711q-10.5 10.29-10.5 25.5Q444-309 454.289-298.5q10.29 10.5 25.5 10.5Zm0-144Q495-432 505.5-442.35 516-452.7 516-468v-168q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465-672 454.5-661.65 444-651.3 444-636v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35Zm.487 336Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const questionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M479.887 816Q500 816 514 802.113q14-13.888 14-34Q528 748 514.113 734q-13.888-14-34-14Q460 720 446 733.887q-14 13.888-14 34Q432 788 445.887 802q13.888 14 34 14Zm.389 144Q401 960 331 930q-70-30-122.5-82.5T126 725.042q-30-69.959-30-149.5Q96 496 126 426.5t82.5-122Q261 252 330.958 222q69.959-30 149.5-30Q560 192 629.5 222t122 82.5Q804 357 834 426.724q30 69.725 30 149Q864 655 834 725q-30 70-82.5 122.5T629.276 930q-69.725 30-149 30ZM480 888q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546 436.41 546 460.718 546 483 533 500q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565 561q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591 336Q446 336 412.5 352.5 379 369 358.84 401.267 351 413 354.5 427t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  








  function removeFileFunc() {
    var listTMP = null;

    if (list?.length > 1) {
      listTMP = [];

      list?.map((element, key) => {
        if (key !== itemKey) {
          listTMP.push(element);
        }
      });
    }

    setList(listTMP)
  }


  function changeVideoFunc(videoIDTMP) {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {
          element.alreadyExists = true;
          element.originalVideo = null;

          dataVideoNames?.map((videoTMP) => {
            if (videoTMP.id === videoIDTMP) {
              element.originalVideo = videoTMP;

              if (videoTMP?.professor) {
                element.professor_name = videoTMP?.professor?.name;
                element.professor_code = videoTMP?.professor?.professor_code;
                element.professor_id = videoTMP?.professor?.id;
              }
            }
          });
        }
        listTMP.push(element);
      });
    }

    setList(listTMP)
  }


  function changeProfessorFunc(professorIDTMP) {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {

          dataProfessors?.map((professorTMP) => {
            if (professorTMP.id === professorIDTMP) {
              element.professor_name = professorTMP?.name;
              element.professor_code = professorTMP?.professor_code;
              element.professor_id = professorTMP?.id;
            }
          });


        }
        listTMP.push(element);
      });
    }

    setList(listTMP)
  }

  function changeLocalizationFunc(videoIDTMP) {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {
          element.is_translation_of = null;

          dataVideoNames?.map((videoTMP) => {
            if (videoTMP.id === videoIDTMP) {
              element.is_translation_of = videoTMP;
            }
          });
        }

        listTMP.push(element);
      });
    }

    setList(listTMP)
  }

  function changeModuleFunc(moduleID) {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {
          element.module_id = null;

          dataModules?.map((moduleTMP) => {
            if (!moduleID || parseInt(moduleTMP.id) === parseInt(moduleID)) {
              element.module_id = (parseInt(moduleID))? parseInt(moduleID) : null;
              element.search_module_name = (parseInt(moduleID))? moduleTMP?.name : currentCustomer?.label_extras_module;
              
            }
          });
        }

        listTMP.push(element);
      });
    }

    setList(listTMP)
  }

  function changeLanguageFunc(lang) {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {
          element.language = lang;
        }
        listTMP.push(element);
      });
    }

    setList(listTMP)
  }



  function showEditFunc() {
    var listTMP = null;

    if (list[itemKey]) {
      listTMP = [];

      list?.map((element, key) => {
        if (key === itemKey) {
          element.showEdit = (element.showEdit)? false : true;
        }
        listTMP.push(element);
      });
    }

    setList(listTMP)
  }













  function getLocalizationOptions(video) {
    var resp = [{ label: 'No es una localización', id: null }, ...dataVideoNames?.map(element => { 
      var vidName = video?.name?.split('-')[0]?.toLowerCase()?.replaceAll(' ', '');
      var videoName = element?.name?.split('-')[0]?.toLowerCase()?.replaceAll(' ', '');
      if (vidName && videoName && vidName === videoName) {
        return { label: element?.name, id: element?.id } 
      }
    }).filter((element)=> element?.label && element?.label !== video?.name )]?.filter((value, index, self) => index === self.findIndex((t) => (parseInt(t.id) === parseInt(value.id) || t.label === value.label)) )
    return resp;
  }

  function getModuleOptions(video) {
    var resp = [{ label: currentCustomer?.label_extras_module, id: null }, ...dataModules?.map(element => { return { label: element?.name, id: element?.id } })]
    return resp;
  }

  


  
  function fileSizeConversorFunc(bytes) {
    var gb = (bytes / (1024*1024*1024)).toFixed(2)
    var mb = (bytes / (1024*1024)).toFixed(2)
    var kb = (bytes / (1024)).toFixed(2)

    return (
      (gb > 1)? gb+' GB' 
      : (mb > 1)? mb+' MB' 
      : kb+' KB'
    )
  }
  

  











  return (
    <div 
      className={`truncate flex flex-wrap items-center justify-between text-gray-500 border rounded duration-300 
        ${(uploadingVideos)? 'opacity-70' : ''}
        ${(item?.alreadyExists)? `${(isText)? `bg-gray-300 ${uploadingEnded && 'hover:bg-gray-400'} border-gray-500 opacity-50` : `bg-red-100 ${uploadingEnded && 'hover:bg-red-200'} border-red-300`}`
          : (item?.nameError)? `bg-yellow-100 ${uploadingEnded && 'hover:bg-yellow-200'} border-yellow-300`
          : ` bg-gray-100 ${uploadingEnded && 'hover:bg-gray-200'} border-gray-300`
        }
      `}
    >

      <div className='pl-2 pr-0 w-full flex items-center justify-between space-x-3'>

        {(!uploadingVideos && !uploadingEnded)?
          <Tooltip 
            disableInteractive 
            title={
              (item?.alreadyExists)? ((isText)? 'Nombre repetido / no se creara nada' : "Fichero repetido / nueva revisión")
              : (item?.nameError)? "Nuevo vídeo con posibles errores en el nombre" 
              : "Nuevo vídeo con buen formato"
            } 
            placement="bottom" 
            arrow
          >
            <div 
              className={`cursor-pointer 
                ${(item?.alreadyExists)? 'text-red-500'
                : (item?.nameError)? 'text-yellow-500'
                : 'text-blue-500'}
              `}
            >
              {
                (item?.alreadyExists)? invalidIcon
                : (item?.nameError)? questionIcon
                : okayIcon
              }
            </div>
          </Tooltip>
        : (uploadingEnded) &&
          <div className='flex space-x-1 text-gray-800'>
            <Tooltip disableInteractive title="Abrir editor de marcas en una nueva pestaña" placement="bottom" arrow>
              <button 
                type="button" 
                className="flex justify-center text-sm text-gray-500 hover:text-blue-500 duration-300" 
                onClick={()=>{ if (uploadingEnded && item?.originalVideo) { window.open("/" + currentCustomer?.abbreviated_name + "/" + item?.originalVideo?.search_module_abbreviated_name + "/" + item?.originalVideo?.name, '_blank').focus() } }}
              >
                {markIcon}
              </button> 
            </Tooltip>
          </div>
        }

        <div className={`w-full flex items-center space-x-1 text-gray-800 text-sm`}>
          <p className='py-[6px]'>{item?.name}</p>


          {(!isText || !item?.alreadyExists)? 
            <button 
              className={`flex justify-between space-x-1 text-gray-500 ${(!uploadingVideos && !uploadingEnded)? 'hover:bg-gray-300 hover:bg-opacity-50 hover:text-blue-500 cursor-pointer' : 'cursor-default'} pl-2 pr-1 py-[2px] rounded duration-300`}
              onClick={()=> showEditFunc()}
            >
              <small className='pt-[1px] flex space-x-1'>
                <p>
                  {(item?.reviewNumber)? 
                    (
                      (
                        (uploadNewReviews)? 
                          (
                            (item.is_lowres)? 'Revisión en baja resolución ' 
                            : (item?.is_translation_of?.id)? 'Nueva revisión de una localización ' 
                            : 'Nueva revisión '
                          ) 
                        : 'Substitución de la ultima revisión dentro de '
                      ) 
                      + ((item?.reviewNumber && uploadNewReviews)? ' v' + item?.reviewNumber + ' ' : '') 
                      + ((item?.originalVideo?.name)? item?.originalVideo?.name : '') 
                    )
                    : (item?.is_translation_of)? 'Nueva localización de ' + item?.is_translation_of?.name
                    :'Nuevo vídeo'
                  }
                </p>

                {(item?.language)? <p>en <strong>{(item?.language)? item?.language?.toUpperCase() : '???'}</strong></p> : null }
                {(item?.professor_name)? <p>por <strong>{item?.professor_name}</strong></p> : null }
                {(item?.dateFormated)? <p>con fecha de publicación <strong>{item?.dateFormated}</strong></p> : null }
              </small>

              {(!uploadingVideos && !uploadingEnded) &&
                <div className='scale-75 text-gray-400'>{editIcon}</div>
              }
            </button>
          : null }
        </div>


        {(!item?.is_translation_of && item?.originalVideo && !item.is_lowres && uploadingEnded) && 
          <div className='w-full max-w-[300px] relative h-[25px] m-1 flex items-center justify-center rounded-lg overflow-hidden border opacity-60 hover:opacity-100 border-gray-400 border-opacity-60'>
            <div className='w-[310px] absolute'>
              <ItemListStateChanger 
                video={item?.originalVideo}
                disabled={false}
                small={true}
              />
            </div>
          </div>
        }


        {(!isText && item?.size)? <p className='text-xs text-gray-400'>{fileSizeConversorFunc(item?.size)}</p> : null}

        {(!uploadingVideos && !uploadingEnded )?
          <div className='pr-1'>
            {(!isText)? 
              <div className='flex justify-center items-center w-6 h-6 overflow-hidden rounded'>
                <div className='scale-75'>
                  <Tooltip disableInteractive title="Eliminar vídeo" placement="bottom" arrow>
                    <IconButton color="error" size='small' onClick={() => removeFileFunc()}>
                        <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            : null}
          </div>
        :
          <div>
            <Tooltip disableInteractive title={(item?.uploaded === 2)? "Vídeo subido y procesado" : (item?.uploaded === 1)? "Subiendo y procesando vídeo..." : "A la espera"} placement="bottom" arrow>
              <div className={`flex items-center space-x-1 text-xs pr-2 ${(item?.uploaded === 2)? 'text-green-500' : (item?.uploaded === 1)? "text-blue-500" : 'text-gray-500'}`}>
                {(item?.uploaded === 1 && uploadingProgress !== null)? uploadingProgress + '%' : ''}
                {(item?.uploaded === 2)? okayIcon : (item?.uploaded === 1)? loadingIcon : questionIcon}
              </div>
            </Tooltip>
          </div>
        }
      </div>





      {(((selectedVideo && (selectedVideo?.id === item?.originalVideo?.id))? true : false || openVideoAdminEitor) && uploadingEnded) &&
        <DivVideoEditing
          video={item?.originalVideo}
          setVideo={null}
          getVideos={null}
          triggerSaveAll={triggerSaveAll}
          setTriggerSaveAll={setTriggerSaveAll}
        />
      }







      {((!isText || !item?.alreadyExists) && ((item?.showEdit || openPreUploadEditors) && !uploadingVideos && !uploadingEnded)) &&
        <div className='w-full mx-2 pb-2 pt-2 flex items-center justify-start space-x-2'>

          {(!isText)?
            <Autocomplete 
              value={(item?.originalVideo?.id)? { label: item?.originalVideo?.name + ' / Feedback implementado', id: item?.originalVideo?.id } : (item?.alreadyExists? null : { label: 'Nuevo vídeo', id: null })} 
              onChange={(event, value) => {
                changeVideoFunc(value?.id)
              }} 
              options={[{ label: 'Nuevo vídeo', id: null }, ...dataVideoNames?.map(element => { return { label: element?.name + ' / Feedback implementado', id: element?.id } })]} 
              renderInput={(params) => <TextField {...params} id="tema" label="Subir como" sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }}/>} 
              disableClearable 
              className='w-full mui-bg-white' 
              ListboxProps={{ sx: { fontSize: 13 } }}
              sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 30, paddingBlock: '5px !important' }}}
            />
          : null }
          

          
          {(!item?.originalVideo)? 
            <>
              {(currentCustomer?.setting_upload_name_format !== 'name-title-extra_professors-professor')?
                <Autocomplete 
                  value={(item?.is_translation_of?.id)? { label: item?.is_translation_of?.name, id: item?.is_translation_of?.id } : ((item?.alreadyExists)? null : { label: 'Sin localización', id: null })} 
                  onChange={(event, value) => {
                    changeLocalizationFunc(value?.id)
                  }} 
                  options={getLocalizationOptions(item)} 
                  renderInput={(params) => <TextField {...params} id="tema" label="Localización" sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }}/>} 
                  disableClearable 
                  className='w-full mui-bg-white' 
                  ListboxProps={{ sx: { fontSize: 13 } }}
                  sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 30, paddingBlock: '5px !important' }}}
                />
              : (!item?.alreadyExists)?
                <Autocomplete 
                  value={('module_id' in item)? { label: item?.label_extras_module, id: item?.module_id } : ((item?.alreadyExists || !selectedModule)? { label: currentCustomer?.label_extras_module, id: null } : { label: selectedModule?.name, id: selectedModule?.id })} 
                  onChange={(event, value) => {
                    changeModuleFunc(value?.id)
                  }} 
                  options={getModuleOptions(item)} 
                  renderInput={(params) => <TextField {...params} id="tema" label="Módulo" sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }}/>} 
                  disableClearable 
                  className='w-full mui-bg-white' 
                  ListboxProps={{ sx: { fontSize: 13 } }}
                  sx={{ maxWidth: 180, "& .MuiInputBase-root": { fontSize: 14, height: 30, paddingBlock: '5px !important' }}}
                />
              : null }

              <Autocomplete 
                value={(item?.professor_name)? { label: item?.professor_name + ' / ' + item?.professor_code, id: item?.professor_id } : { label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado`, id: null }} 
                onChange={(event, value) => {
                  changeProfessorFunc(value?.id)
                }} 
                options={[{ label: `Sin ${currentCustomer?.label_professor?.toLowerCase()} asignado`, id: null }, ...dataProfessors?.filter((el) => (!currentCustomer?.setting_professor_filter || el?.treatment === 2))?.map(element => { return { label: element?.name + ' / ' + element?.professor_code, id: element?.id } })]} 
                renderInput={(params) => <TextField {...params} id="tema" label={`Del ${currentCustomer?.label_professor?.toLowerCase()}`} sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }}/>} 
                disableClearable 
                className='w-full md:max-w-[400px] mui-bg-white' 
                ListboxProps={{ sx: { fontSize: 13 } }}
                sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 30, paddingBlock: '5px !important' }}}
              />

              <TextField
                select
                value={(item?.language)? item.language : 'esp'}
                onChange={(event) => {
                  changeLanguageFunc(event.target.value)
                }} 
                label="Idioma"
                className='w-full md:max-w-[250px] mui-bg-white'
                sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 30 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-14px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}
              >
                {languageList?.map(element => {
                  return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                    <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                    <p style={{ margin: 0 }}>{element?.name}</p>
                  </MenuItem> 
                })}

                <MenuItem value={'other'}>Otro</MenuItem>
              </TextField>
            </>
          : null }

        </div>
      }
    </div>
  );
}