import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


 


export default function ChyronExtrasInput({ video, getVideo }) {
  

  


  const { currentUser, showPrices } = useContext(AuthContext)
  const { isFullScreenLayout } = useContext(LayoutContext)



  const [loadingChyronExtras, setLoadingChyronExtras] = useState(false);
  const [extraChyronFacturation, setExtraChyronFacturation] = useState((parseInt(video?.extra_mark_facturation_chyron))? parseInt(video?.extra_mark_facturation_chyron) : 0);
  const [extraChyronFacturationCorrection, setExtraChyronFacturationCorrection] = useState((parseInt(video?.extra_facturation_correction_chyron))? parseInt(video?.extra_facturation_correction_chyron) : 0);



  
  /* Icon vars */
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const motionDonedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M168-240q-29 0-50.5-21.5T96-312v-432q0-29 20.5-50.5T168-816h48l72 144h96l-72-144h72l72 144h96l-72-144h72l72 144h96l-72-144h144q30 0 51 21.5t21 50.5v144H168v288h312v72H168Zm584-237 85 85-189 189q-5 5-11.5 8t-14.5 3h-52q-8 0-13-5t-5-13v-53q0-8 3-14.5t8-11.5l189-188Zm119 51-84-85 33-34q8-8 18.5-7t18.5 9l48 49q8 7 8.5 16t-6.5 16l-36 36Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });
  
  


  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  useEffect( () => { 
    if (video) {
      setExtraChyronFacturation((parseInt(video?.extra_mark_facturation_chyron))? parseInt(video?.extra_mark_facturation_chyron) : 0);
      setExtraChyronFacturationCorrection((parseInt(video?.extra_facturation_correction_chyron))? parseInt(video?.extra_facturation_correction_chyron) : 0);
    }
  }, [video])





  async function saveExtraChyronsFunc() {
    if (video?.id && window.confirm('¿Seguro que quieres cambiar estos datos?')) {
      setLoadingChyronExtras(true)


      
      var input = {
        own_user_token: currentUser?.token,

        id: parseInt(video?.id),
        extra_mark_facturation_chyron: (parseInt(extraChyronFacturation))? parseInt(extraChyronFacturation) : 0,
        extra_facturation_correction_chyron: (parseInt(extraChyronFacturationCorrection))? parseInt(extraChyronFacturationCorrection) : 0,
      };

      await createOrUpdateVideo({variables: input})
      await getVideo()


      setTimeout(() => {
        setLoadingChyronExtras(false)
      }, 1000)
    }
  }



  

  


  return ((!isFullScreenLayout && (showPrices || (parseInt(video?.extra_mark_facturation_chyron) || parseInt(video?.extra_facturation_correction_chyron))))? true : false) && 
    <div className='w-full hidden sm:flex lg:hidden xl:flex justify-end items-center space-x-1'>
      {(showPrices && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? <>
        <TextField
          defaultValue={(extraChyronFacturation)? extraChyronFacturation : 0}
          onChange={event => {
            setExtraChyronFacturation((parseInt(event.target.value))? parseInt(event.target.value) : 0);
          }}
          label="U.Extra"
          variant="outlined"
          className='w-[60px] mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 22 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 4 } } }}
          type='number'
          inputProps={{ step: "1", min: 0 }}
        />

        <TextField
          defaultValue={(extraChyronFacturationCorrection)? extraChyronFacturationCorrection : 0}
          onChange={event => {
            setExtraChyronFacturationCorrection((parseInt(event.target.value))? parseInt(event.target.value) : 0);
          }}
          label="U.Parciales"
          variant="outlined"
          className='w-[80px] mui-bg-white'
          sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 22 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
          InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 4 } } }}
          type='number'
          inputProps={{ step: "1", min: 0 }}
        />


        {(parseInt(extraChyronFacturation) !== parseInt(video?.extra_mark_facturation_chyron) || parseInt(extraChyronFacturationCorrection) !== parseInt(video?.extra_facturation_correction_chyron)) && 
          <Tooltip disableInteractive title={'Guardar unidades extra de los chyrons en este vídeo'} placement="bottom" arrow>
            <div className='flex items-center'>
              <button 
                onClick={saveExtraChyronsFunc} 
                disabled={(parseInt(extraChyronFacturation) === parseInt(video?.extra_mark_facturation_chyron) && parseInt(extraChyronFacturationCorrection) === parseInt(video?.extra_facturation_correction_chyron))? true : false}
                className={`border border-gray-300 disabled:opacity-50 disabled:pointer-events-none rounded duration-200 ${(loadingChyronExtras)? 'text-white bg-blue-500 pointer-events-none' : 'text-gray-600 bg-gray-300 hover:bg-opacity-80 hover:text-blue-500 active:bg-gray-100 bg-opacity-50'}`}
              >
                {(loadingChyronExtras)? loadingIcon : saveIcon}
              </button>
            </div>
          </Tooltip>
        }
      </> : null }


      {(showPrices || (parseInt(video?.extra_mark_facturation_chyron) || parseInt(video?.extra_facturation_correction_chyron)))?
        <CustomWidthTooltip
          disableInteractive
          title={
            <p> 
              Chyron / Veces creado (1 base
                {((video?.extra_mark_facturation_chyron)? ', ' + video?.extra_mark_facturation_chyron + ((video?.extra_mark_facturation_chyron === 1)? ' total' : ' totales') : '')}
                {((video?.extra_facturation_correction_chyron)? ', ' + video?.extra_facturation_correction_chyron + ((video?.extra_facturation_correction_chyron === 1)? ' parcial' : ' parciales') : '')} 
              )
            </p>
          }
          placement="bottom"
          arrow
        >
          <div>
            <button className="truncate overflow-hidden text-xs flex items-center duration-300 border border-gray-300 text-gray-600 bg-gray-300 bg-opacity-50 pl-[2px] pr-1 rounded">
              <div className='mr-[3px] h-[20px] flex items-center'><div className='scale-[0.9] mt-[1px]'>{motionDonedIcon}</div></div>
              <p>x{((video?.extra_mark_facturation_chyron)? parseInt(video?.extra_mark_facturation_chyron) : 0) + ((video?.extra_facturation_correction_chyron)? parseInt(video?.extra_facturation_correction_chyron) : 0) + 1}</p>
            </button>
          </div>
        </CustomWidthTooltip>
      : null }
    </div>
}