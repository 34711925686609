
import { defaultStaticRanges, defaultInputRanges } from "react-date-range/dist/defaultRanges";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';






export default function CalendarDateInput({
  
  startDate,
  setStartDate,
  endDate,
  setEndDate,

}) {





  function handleSelect(ranges) {
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }

    var startDateTMP = (new Date(ranges?.selection?.startDate).getFullYear() > 1970)? new Date(ranges?.selection?.startDate) : null;
    var endDateTMP = (new Date(ranges?.selection?.endDate).getFullYear() > 1970)? new Date(ranges?.selection?.endDate) : null;

    setStartDate(startDateTMP)
    setEndDate(endDateTMP)
  }

  const staticRangesLabels = {
    "Today": "Hoy",
    "Yesterday": "Ayer",
    "This Week": "Esta semana",
    "Last Week": "Semana pasada",
    "This Month": "Este mes",
    "Last Month": "Mes pasado"
  };
  
  const inputRangesLabels = {
    "days up to today": "días hasta ahora",
    "days starting today": "días desde ahora"
  }

  function translateRange(dictionary) {
    return (item) => {
      /* var itemTMP = {...item} */

      if (item.label === 'This Week') {
        item = {...item, 
          range: () => ({
            startDate: getMonday(new Date()),
            endDate: new Date(),
          }),
        }
      }

      if (item.label === 'Last Week') {
        var dateTMP = new Date();
        dateTMP.setDate(dateTMP.getDate() - 7)

        item = {...item, 
          range: () => ({
            startDate: getMonday(dateTMP),
            endDate: getSunday(dateTMP),
          }),
        }
      }

      return dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item
    };
  }
  
  const esStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
  const esInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
  




  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day + ((day===0)? -6 : 1);
    var date = new Date(d.setDate(diff))

    if (date) {
      date.setHours(1,0,0,0);
    }

    return date;
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay();
    var diff = d.getDate() - day + ((day===0)? -6 : 1);
    var date = new Date(d.setDate(diff))
    date.setDate(date.getDate() + 6)

    if (date) {
      date.setHours(23,59,59,0);
    }

    return date;
  }




  return (
    <div>
      <div className='z-40 group/dates flex items-start justify-center relative w-[318px] h-[35px] hover:w-[560px] duration-300'>
        <div className='absolute flex justify-end w-[334px] h-[52px] mt-[-8.5px] group-hover/dates:mt-[0px] group-hover/dates:w-[560px] group-hover/dates:h-[345px] overflow-hidden duration-300 border border-transparent group-hover/dates:border-gray-400 group-hover/dates:border-opacity-60 rounded'>
          <DateRangePicker
            ranges={[{
              startDate: startDate,
              endDate: endDate,
              key: 'selection',
            }]}
            onChange={handleSelect}
            locale={es}
            staticRanges={esStaticRanges}
            inputRanges={esInputRanges}
          />
        </div>
      </div>
    </div>
  );
}