import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useParams } from "react-router-dom";

import MenuVideoSharing from './MenuVideoSharing';






export default function VideoListItem({
  
  video,
  priorityList,
  setSelectedVideo,
  setOpenVideoAdminEitor,

  canBeAddedToFolder,
  addedVideosToFolderIDs,
  addVideoToFolderFunc,
  
}) {
  




  const { name } = useParams()
  const { currentUser, currentCustomer, customerPath } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)





  /* Share menu vars */
  const [anchorEl, setAnchorEl] = useState(null);

  /* Icon vars */
  const imageNotFoundIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill='currentColor'><path d="M33.833 37.833 30.958 35H7.792q-1.167 0-1.98-.812Q5 33.375 5 32.208V9.042L2.417 6.458q-.417-.416-.396-1 .021-.583.437-1 .375-.375.959-.375.583 0 .958.375l31.417 31.417q.416.417.396.979-.021.563-.438.979-.417.417-.958.417-.542 0-.959-.417ZM11.458 27.792H23.75l-3.667-3.709-1.75 2.125-2.791-3.833q-.25-.25-.563-.25-.312 0-.521.25l-3.541 4.25q-.292.375-.084.771.209.396.625.396ZM35 31.167 8.833 5h23.375q1.167 0 1.98.812.812.813.812 1.98Z"/></svg>;
  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M696 960q-50 0-85-35t-35-85q0-8 1-14.5t3-14.5L342 666q-15 16-35.354 23T264 696q-50 0-85-35t-35-85q0-50 35-85t85-35q22 0 42.5 7.5T342 486l238-145q-2-8-3-14.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-22.292 0-42.646-7T618 402L380 547q2 8 3 14.5t1 14.5q0 8-1 14.5t-3 14.5l238 145q15-17 35.354-23.5T696 720q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>;
  const priorityIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M479.876 936Q446 936 422 911.876t-24-58Q398 820 422.124 796t58-24Q514 772 538 796.124t24 58Q562 888 537.876 912t-58 24Zm0-240Q446 696 422 671.912 398 647.825 398 614V298q0-33.825 24.124-57.912 24.124-24.088 58-24.088Q514 216 538 240.088q24 24.087 24 57.912v316q0 33.825-24.124 57.912-24.124 24.088-58 24.088Z"/></svg>;
  const addedToFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm400-240v40q0 17 11.5 28.5T600-320q17 0 28.5-11.5T640-360v-40h40q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480h-40v-40q0-17-11.5-28.5T600-560q-17 0-28.5 11.5T560-520v40h-40q-17 0-28.5 11.5T480-440q0 17 11.5 28.5T520-400h40Z"/></svg>
  const notAddedToFolderIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h207q16 0 30.5 6t25.5 17l57 57h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"/></svg>
  
  const loadIcon = <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>




  

/* VIDEO URL */
  const video_url = ("/" + currentCustomer?.abbreviated_name + "/" + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra') + '/' + ((name)? (video?.url_name?.split('-')[0] + "/" + video.id) : video?.url_name)).normalize("NFD").replace(/[\u0300-\u036f]/g, "")



  return (
    <div className={`w-full sm:w-[33.3%] lg:w-[25%] 2xl:w-[20%] min-[1800px]:w-[16.66%] p-2 z-10`}>
      <div className={`group bg-gray-50 hover:bg-white rounded-lg overflow-hidden shadow hover:shadow-lg cursor-pointer transition-colors duration-200 ${(video?.is_priority)? ' border-[1.5px] border-red-300 hover:border-red-500' : ' border-[1.5px] border-gray-300 hover:border-gray-600'}`}>
        
        
        <a href={video_url} className='py-[28.125%] relative border-b rounded-t-md bg-[#d8d8d8] overflow-hidden flex items-center justify-center text-gray-400'>
          <div className='absolute w-full h-full z-30 transition-all duration-200 group-hover:shadow-[inset_0_0px_40px_0_rgba(0,0,0,0.15)]'></div>
          
          {(video?.thumbnail || video.thumbnail_small)?
            <div className='flex items-center justify-center'>
              <div className='absolute scale-150 pl-4 flex justify-center items-center z-10'>
                {loadIcon}
              </div>



              {(video?.search_media_time) && 
                <div
                  className={`
                    absolute z-30 bottom-0 right-0 m-1 bg-opacity-60 text-white px-[4px] py-[1px] text-xs rounded
                    ${(video?.search_media_time > 20)? 'bg-red-900' : 'bg-gray-900'}
                  `}
                >
                  {(video?.search_media_time?.length === 3)? video.search_media_time + '0' : video.time}
                </div>
              }

              <img 
                className={`z-20 object-cover w-full h-full absolute`}
                src={customerPath + 'video_thumbnails/' + ((video.thumbnail)? video.thumbnail : video.thumbnail_small) /* ((video.thumbnail_small)? video.thumbnail_small : video.thumbnail) */ } 
                alt='video_list_item'
              />  
            </div>
          :
            <div className='absolute w-full h-full flex justify-center items-center shadow-[inset_0_0px_70px_0_rgba(0,0,0,0.11)]'>
              <div className='scale-[1.6] opacity-50'>
                {imageNotFoundIcon}
              </div>
            </div>
          }
        </a>


        <div className={`w-full flex justify-between items-center box-border`}>
          <div 
            className='w-full pl-2 pt-[4px] pb-[6px]' 
            onMouseUp={(event)=> {
              if (canBeAddedToFolder && !video?.is_translation_of) {
                addVideoToFolderFunc({ id: parseInt(video?.id), name: video?.name })
              } else if (!currentUser?.rol?.includes('user') && !priorityList) {
                setSelectedVideo(video)
                setOpenVideoAdminEitor(true)
              } else {
                navigateOrOpenFunc(event, video_url)
              }
            }}
          >
            <div className={`w-full h-[23px] flex items-center sm:max-w-[120px] md:max-w-none ${(video?.is_priority)? 'text-red-500 font-bold' : ''}`}>
              {(video?.is_priority && !currentUser?.professor)? <div className='w-[17px] ml-[-6px]'>{priorityIcon}</div> : null}
              <p className='text-[13px] xl:text-[14px] max-w-[225px] truncate'>{video?.name}</p>
            </div>

            <p className='text-[11px] xl:text-xs text-gray-400 sm:max-w-[120px] md:max-w-none truncate'>{(priorityList && video.isPriorityDateFormated)? 'Prioridad des del ' + video.isPriorityDateFormated : 'Actualizado el ' + video.updatedAtFormated }</p>
          </div>




          {(!currentUser?.professor) && <>
            {(canBeAddedToFolder && !video?.is_translation_of)? 

              <div className={`py-1 px-[1px] mr-2 hover:bg-gray-100 text-gray-400 hover:scale-125 hover:text-blue-500 active:scale-100 rounded duration-100 ${(addedVideosToFolderIDs?.length && addedVideosToFolderIDs?.includes(parseInt(video?.id)))? 'text-blue-500' : ' opacity-50'}`}>
                {(addedVideosToFolderIDs?.length && addedVideosToFolderIDs?.includes(parseInt(video?.id)))? addedToFolderIcon : notAddedToFolderIcon}
              </div>
            
            : <>

              <button
                onClick={(event)=>setAnchorEl(event.currentTarget)}
                className={`py-1 px-[1px] mr-2 hover:bg-gray-100 text-gray-400 hover:scale-125 hover:text-blue-500 active:scale-100 rounded duration-100
                  ${(anchorEl)? 'bg-gray-200' : ''}
                `}
              >
                {shareIcon}
              </button> 
              <MenuVideoSharing anchorEl={anchorEl} setAnchorEl={setAnchorEl} video={video} shadow={true}/>

            </>}

          </>}
        </div>


      </div>
    </div>
  );
}