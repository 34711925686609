import gql from "graphql-tag";
export default gql`
  mutation MatchFactura10ProductIdVideo(

    $own_user_token: String!,
    $ids: [String]!,
  
  ) {
    
    matchFactura10ProductIdVideo(

      own_user_token: $own_user_token,
      ids: $ids,
    
    ) {

      status
      
    }
  }
`;
