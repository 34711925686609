import gql from "graphql-tag";
export default gql`
  query GetBillConceptsFromVideo($own_user_token: String!, $video_id: Int!) {
    billConceptsFromVideo(own_user_token: $own_user_token, video_id: $video_id) {

      id
      position
      title
      small_text
      description
      units_name
      units
      price
      iva

      bill_id
      parent_id
      video_id
      module_id

      billed

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated

    }
  }
`;
