import gql from "graphql-tag";

export default gql`
  mutation RemoveVideoMarkCorrection($id: ID!, $own_user_token: String!) {
    removeVideoMarkCorrection(id: $id, own_user_token: $own_user_token) {
      
      
      id
      time
      time_end
      observations
      texts
      url
      coordinates

      swap_date
      swap_user
      info_user_id
      hidde_original
      
      public
      notificate_to
      notificate_to_label
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label

      video_id
      user_id
      type_id
      review_id
      difficulty_id
      file_process_id

      extra_mark_facturation
      extra_facturation_correction
      new_price
      new_price_observations
      manual_number_of_words

      search_type_name
      search_type_color
      search_type_type
      search_review_name

      createdAt
      updatedAt

      info_user {
        id
        name
        positions
      }

      video {
        name
        url_name
        language
      }
      
      createdAtFormated
      updatedAtFormated
      __typename


    }
  }
`;
