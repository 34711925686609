import gql from "graphql-tag";
export default gql`
  query GetPublicMarks($token: String!) {
    getPublicMarks(token: $token) {

      id
      time
      texts
      notificate_to
      notificate_to_label

      video_id
      user_id
      type_id
      review_id

      search_guest_data
      
      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated

      user {
        name
        profile_picture
      }

      replies {
        id
        text
        filename
        order

        video_mark_id
        video_comment_id

        search_guest_data

        createdAt
        updatedAt

        createdAtFormated
        updatedAtFormated

        user {
          name
          profile_picture
        }
      }

    }
  }
`;
