import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { useQuery } from '@apollo/client'

import GetAllRecordingSets from './../graphql/queries/GetAllRecordingSets';

import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';




export default function AddExtraRecordingSets({

  previousValues,
  setIds,
  small,
  color,
  icon,
  noChangeOption,
  
  labelInput,
  labelSelect,
  labelNone,
  inputWidth,
  disabled,

}) {
  

  const { currentCustomer } = useContext(AuthContext)


  const endColor = (
    (color === 'gray')? 'rgb(243 244 246)' 
    : (color === 'darkgray')? 'rgb(229, 231, 235)' 
    : 'white'
  )



  /* Users */
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(null);
  
  const [selectedOption, setSelectedOption] = useState({ label: labelNone, id: 'null' });
  const [activeOptions, setActiveOptions] = useState(null);



  const { data: dataRecordingSets, loading: loadingRecordingSets } = useQuery(GetAllRecordingSets, { 
    variables: {
      customer_token: currentCustomer?.token,
    },
  })






  useEffect(() => {
    if (dataRecordingSets?.recordingSets?.length) {
      setLoading(true)

      var optionsTMP = (noChangeOption)? [{ label: 'Sets sin modificar', id: null }, { label: labelNone, id: 'null' }] : [{ label: labelNone, id: 'null' }]
      optionsTMP = [...optionsTMP, ...dataRecordingSets?.recordingSets?.map((el) => { return { id: el?.id, label: el?.name} })]
      setOptions(optionsTMP)

      setTimeout(() => {
        setLoading(false)
      }, 300 )

    } else {
      setOptions(null)
    }

    if (parseInt(selectedOption?.id)) {
      setSelectedOption({ label: labelSelect, id: 'noact' })
    }
  }, [dataRecordingSets])


  useEffect(() => {
    if (noChangeOption) {
      setActiveOptions([{ label: 'Sets sin modificar', id: null }])
    } else {
      const recordingSets = (dataRecordingSets?.recordingSets?.length)? dataRecordingSets?.recordingSets : null
      var previousValuesTMP = (previousValues)? previousValues?.split(',')?.filter((el) => el) : null;
      if (!previousValuesTMP?.length) { previousValuesTMP = null; }
  
      if (previousValuesTMP?.length && recordingSets?.length) {
        var activeOptionsTMP = []
  
        for (var previousValueTMP of previousValuesTMP) {
          for (var recordingSet of recordingSets) {
            if (parseInt(previousValueTMP) === parseInt(recordingSet?.id)) {
              activeOptionsTMP.push({ id: recordingSet?.id, label: recordingSet?.name})
            }
          }
        }
  
        setActiveOptions((activeOptionsTMP?.length)? activeOptionsTMP : null)
        
      } else {
        setActiveOptions([{ label: labelNone, id: 'null' }])
      }
    }
  }, [previousValues, dataRecordingSets])


  useEffect(() => {
    if (selectedOption && selectedOption?.id !== 'noact') {
      if (selectedOption && selectedOption?.id === null) { 
        setActiveOptions([{ label: 'Sets sin modificar', id: null }]) 
      }
  
      if (selectedOption?.id === 'null') { 
        setActiveOptions([{ label: labelNone, id: 'null' }]) 
      }
  
      if (parseInt(selectedOption?.id) && activeOptions?.length && ![...activeOptions]?.filter((el) => parseInt(el?.id) === parseInt(selectedOption?.id))?.length) { 
        setActiveOptions((activeOptions?.length)? [selectedOption, ...activeOptions]?.filter((el) => parseInt(el?.id)) : [selectedOption]) 
      }
  
      if (parseInt(selectedOption?.id)) {
        setSelectedOption({ label: labelSelect, id: 'noact' })
      }
    }
  }, [selectedOption])


  useEffect(() => {
    const idsTMP = (activeOptions?.length && activeOptions?.filter((el) => el && (parseInt(el?.id) || el?.id === null))?.length)? activeOptions?.map((el) => (parseInt(el?.id))? ',' + el?.id + ',' : 'null' )?.join('') : null
    setIds(idsTMP)
  }, [activeOptions])







  function removeItemFunc(removeOption, activeOptions) {
    var activeOptionsTMP = (activeOptions?.length)? activeOptions?.filter((el)=> parseInt(el?.id) !== parseInt(removeOption?.id)) : null;
    if (!activeOptionsTMP?.length) { activeOptionsTMP = [{ label: labelNone, id: 'null' }] }
    setActiveOptions(activeOptionsTMP)
  }





  return (!loading && !loadingRecordingSets && options?.length)? 
    <div className='w-full min-w-0'>

      <Collapse orientation="vertical" in={(options && options?.length)? true : false} timeout={100}>
        <div className={`${(disabled)? 'opacity-60 pointer-events-none' : ''}`}>

          <div className="w-full flex space-x-1">
            <div
              className={`
                group/notis relative rounded duration-300 transition-all
                ${(disabled)? ((small)? 'max-w-[32px]' : 'max-w-[40px]') : (isOpen)? ((small)? 'max-w-[300px]' : 'max-w-[400px]') : ((small)? 'max-w-[32px] hover:max-w-[300px]' : 'max-w-[40px] hover:max-w-[400px]')}
              `}
            >
              <div
                className={`
                  absolute z-20 flex justify-center items-center text-gray-500 border border-gray-400 border-opacity-60 w-full h-full top-0 left-0 rounded duration-300 pointer-events-none
                  ${(disabled)? 'opacity-100' : (isOpen)? 'opacity-0' : 'opacity-100 group-hover/notis:opacity-0'}
                `}
                style={{ backgroundColor: endColor }}
              >
                {icon}
              </div>

              <div className={`${(disabled)? 'opacity-0' : (isOpen)? 'opacity-100' : 'opacity-0 group-hover/notis:opacity-100 pointer-events-none group-hover/notis:pointer-events-auto'} duration-300`}>
                <Autocomplete 
                  value={selectedOption} 
                  onChange={(event, value) => setSelectedOption(value)} 
                  options={options} 
                  renderInput={(params) => <TextField {...params} id="tema" label={(labelInput)? labelInput : null} sx={{ "& .MuiInputBase-input": { height: (small)? 14 : 16 } }} InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1 } } }} />}
                  renderOption={(props, element) => {
                    return <Box sx={{ paddingInline: '8px !important'}} component="li" {...props}>
                      <p className='whitespace-nowrap'>{element.label}</p>
                    </Box>
                  }}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  disableClearable 
                  className={`mui-bg-gray-200 ${(inputWidth)? `` : ((activeOptions?.length)? ((small)? 'min-w-[225px]' : 'min-w-[300px]') : ((small)? 'w-full min-w-[200px]' : 'w-full min-w-[300px]')) }`}
                  size="small"
                  ListboxProps={{ sx: { fontSize: 13 } }}
                  sx={{ width: (inputWidth)? inputWidth : 'auto', "& .MuiInputBase-root": { fontSize: (small)? 14 : 16, height: (small)? 30 : 40, backgroundColor: endColor } }}
                />
              </div>
            </div>



              <div className="flex w-full text-sm justify-start items-center p-1 space-x-1 whitespace-nowrap overflow-x-auto smallScrollBar bg-white border border-gray-400 border-opacity-80 rounded">
                {(activeOptions?.length)?
                  activeOptions?.map(option => {
                    return (!option?.user || (option?.user?.visible)) && <Tooltip key={'notify-to-' + option?.id} disableInteractive title={(option?.id !== 'null')? "Eliminar" : ''} placement="bottom" arrow>
                      <button
                        className={`flex space-x-2 bg-gray-300 hover:bg-gray-400 cursor-pointer duration-300 ${(small)? 'rounded-md px-1' : 'rounded px-2 py-1'}`}
                        onClick={()=> removeItemFunc(option, activeOptions)}
                      >
                        <p>{option?.label}</p>
                      </button>
                    </Tooltip>
                  })
                : 
                  <button
                    className={`flex space-x-2 bg-gray-300 hover:bg-gray-400 cursor-pointer duration-300 ${(small)? 'rounded-md px-1' : 'rounded px-2 py-1'}`}
                  >
                    <p>{labelNone}</p>
                  </button>
                }
              </div>

          </div>
        </div>
      </Collapse>
    </div>

  : (loading || loadingRecordingSets)? 

    <div>Cargando...</div>

  : null
}