import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

export default function CustomDatePicker({

    id,
    label,
    className,
    inputFormat,
    disabled,

    value,
    onChange,
    
}) {

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
            id={id}
            label={label}
            className={`${(className)? className : 'w-full mui-bg-gray-100'}`}
            inputFormat={inputFormat}
            disabled={disabled}

            value={value}
            onChange={onChange}

            InputProps={{ sx: { height: 30, fontSize: 15, "& .MuiInputBase-input": { paddingInline: 1.2, paddingTop: 0.7, paddingBottom: 0.6 } } }}

            renderInput={(params) => 
                <TextField
                    {...params}
                    size="small"
                    error={false}             
                    InputLabelProps={{ sx: { top: "-4px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}
                />
            }
        />
    </LocalizationProvider>
}