import gql from "graphql-tag";
export default gql`
  query GetAllUsersAndProfessors($own_user_token: String!, $own_customer_token: String!, $professors: Boolean) {
    usersAndProfessors(own_user_token: $own_user_token, own_customer_token: $own_customer_token, professors: $professors) {
      id
      abbreviated_name
      name

      positions

      email
      profile_picture
      language
      professor
      professor_code
      vinculated_to_id
      sex
      treatment
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      visible
      enabled

      original_customer_token
      navigation_customer_tokens

      createdAt
      updatedAt

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
