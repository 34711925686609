import { useState, useEffect } from 'react'

import VideoFormInputs from '../components/VideoFormInputs';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';












export default function DivVideoEditing({ video, getVideos, triggerSaveAll, setTriggerSaveAll }) {





  const [triggerSave, setTriggerSave] = useState(false);

  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  /* Error var */
  const [videoNameRepeated, setVideoNameRepeated] = useState(false);




  useEffect(() => {
    if (triggerSave) {
      setTimeout(() => {
        setTriggerSave(false)
      }, 300 )
    }
  }, [triggerSave])
  





  return (
    <div className='w-full bg-gray-200'>
      <hr className='border-gray-300'/>

      <div className='w-full sm:p-2 lg:p-6'>
        <VideoFormInputs 
          simplified={false}
          initialVideoID={null}
          initialVideo={video}
          triggerSave={(triggerSave || triggerSaveAll)? true : false}

          loadingContent={loadingContent}
          setLoadingContent={setLoadingContent}
          loadingSave={loadingSave}
          setLoadingSave={setLoadingSave}
          loadingRemove={loadingRemove}
          setLoadingRemove={setLoadingRemove}

          videoNameRepeated={videoNameRepeated}
          setVideoNameRepeated={setVideoNameRepeated}

          videoSetter={'get'}
          getVideos={getVideos}
          setInitialVideo={null}
        />
     

        <div className='w-full flex items-center justify-end sm:pt-2 lg:pt-6'>
          <LoadingButton 
            loading={loadingSave}
            loadingPosition="start"
            variant="contained"
            size='small'
            autoFocus
            startIcon={<SaveIcon />}
            onClick={() => setTriggerSave(true)}
            disabled={videoNameRepeated || loadingContent || loadingRemove}
          >
            Guardar cambios
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}