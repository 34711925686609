import gql from "graphql-tag";
export default gql`
  query GetCustomerSettings($own_user_token: String!, $own_customer_token: String!, $type: String!) {
    customerSettings(own_user_token: $own_user_token, own_customer_token: $own_customer_token, type: $type) {
      
      id
      token
      customer_token
      type
      title
      small_title
      setting_group
      states
      position

      facturation_type
      from_localization
      from_localization_panel
      automated
      visible
      soft_hidden
      
    }
  }
`;
