import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import CustomSelect from './Inputs/CustomSelect';
import CustomTextField from './Inputs/CustomTextField';






export default function VideoLanguages({video, extraMarks, setExtraMarks, extraLanguages, setExtraLanguages, module, defaultMarksItem, defaultLanguagesItem}) {




  const { languageList } = useContext(AuthContext)

  const [addRow, setAddRow] = useState(false);
  
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;






  useEffect(() => {
    if (!extraMarks?.length) {
      setExtraMarks([defaultMarksItem])
    }
  }, [extraMarks])



  function moveOrCreateFunc(key, name, value) {
    var input = document.getElementById(name + '-' + (key+1));
    
    if (input) {
      input.focus()
    } else {

      var dataTMP = [...extraLanguages];
      dataTMP.push(defaultLanguagesItem)
      setExtraLanguages(dataTMP);

      setAddRow(!addRow)

      setTimeout(()=>{ 
        var input = document.getElementById(name+'-'+(dataTMP?.length - 1));
        if (input) {
          input.focus()
        }
      }, 50)
    }
  }


  function removeRowFunc(removeKey) {
    var dataTMP = [...extraLanguages];
    dataTMP = dataTMP.filter((dataTMP, key) => key !== removeKey);
    setExtraLanguages(null);

    setTimeout(()=> {
      setExtraLanguages([...dataTMP]);
    }, 10)
  }







  return (
    <div>
      
      <div className='flex justify-between items-center space-x-2'>
        <h1 className='w-full input-section-title whitespace-nowrap' style={{ padding: 0 }}>Facturación extra</h1>

        <Tooltip disableInteractive title="Añadir idioma extra" placement="bottom" arrow>
          <div className='bg-gray-300 bg-opacity-60 rounded-full hover:text-green-500 duration-300' onClick={(event)=>moveOrCreateFunc(extraLanguages?.length - 1, 'price', event.target.value)}>
            <IconButton color='inherit' size="small">
              {addIcon}
            </IconButton>
          </div>
        </Tooltip>
      </div>
      <hr className='border-gray-300' style={{ marginTop: 10, marginBottom: 16 }} />


      <div className='space-y-4'>


        {/* Languages */}
        {(!extraLanguages?.length) && 
          <div className='text-sm bg-gray-100 px-2 py-[9.5px] rounded text-gray-400 border border-gray-300 opacity-50' style={{ marginTop: 10 }}>
            <p>Sin idiomas de facturación extra.</p>
          </div>
        }

        {extraLanguages?.map((element, key) => {
          return <div key={'extra-test-'+key} className='w-full' style={{ marginTop: 10 }}>

            <div className='w-full flex items-center flex-wrap md:flex-nowrap space-x-0 md:space-x-2 space-y-2 md:space-y-0'>
              <CustomSelect
                id={`language-${key}`}
                label="Idioma extra"
                className='w-full md:max-w-[150px] mui-bg-gray-100'

                defaultValue={element?.language}
                onChange={(event) => {
                  var dataTMP = [...extraLanguages];
                  dataTMP[key].language = event.target.value
                  setExtraLanguages(dataTMP);
                }}
              >
                {languageList?.map(element => {
                  return (video?.language !== element?.name)? 
                    <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                      <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                      <p style={{ margin: 0 }}>{element?.name}</p>
                    </MenuItem> 
                  : null;
                })}

                <MenuItem value={'other'}>Otro</MenuItem>
              </CustomSelect>


              <CustomSelect
                id={`has_subtitulation-${key}`}
                label="Tiene subtitulación extra"
                className='w-full mui-bg-gray-100'

                defaultValue={element?.has_subtitulation}
                onChange={event => {
                  var dataTMP = [...extraLanguages];
                  dataTMP[key].has_subtitulation = event.target.value
                  setExtraLanguages(dataTMP);
                }}
                disabled
              >
                <MenuItem value={0}>Sin subtitulación</MenuItem>
                <MenuItem value={1}>Subtitulación de primer idioma</MenuItem>
                <MenuItem value={2}>Subtitulación de segundo idioma</MenuItem>
                <MenuItem value={3}>Subtitulación de tercer idioma o posterior</MenuItem>
                <MenuItem value={(parseInt(video?.has_subtitulation))? parseInt(video?.has_subtitulation) : null}>Como en el vídeo original</MenuItem>
              </CustomSelect>

              <CustomSelect
                id={`has_translation-${key}`}
                label="Tiene traducción extra"
                className='w-full mui-bg-gray-100'

                defaultValue={element?.has_translation}
                onChange={event => {
                  var dataTMP = [...extraLanguages];
                  dataTMP[key].has_translation = event.target.value
                  setExtraLanguages(dataTMP);
                }}
                disabled
              >
                <MenuItem value={true}>Con traducción</MenuItem>
                <MenuItem value={false}>Sin traducción</MenuItem>

                <MenuItem value={true}>Como en el vídeo original</MenuItem>
              </CustomSelect>

              <CustomTextField 
                type="number"
                id={`number_of_words-${key}`}
                label="Número de palabras extra" 
                className='w-full md:max-w-[180px] mui-bg-gray-100' 

                defaultValue={element?.number_of_words}
                onChange={event => {
                  var dataTMP = [...extraLanguages];
                  dataTMP[key].number_of_words = parseInt(event.target.value)
                  setExtraLanguages(dataTMP);
                }}
              />
            
              <Tooltip disableInteractive title="Eliminar idioma extra" placement="bottom" arrow>
                <div className='bg-gray-300 bg-opacity-60 rounded-full hover:text-red-500 duration-300' onClick={(event)=>removeRowFunc(key)}>
                  <IconButton color='inherit' size="small">
                    {cancelIcon}
                  </IconButton>
                  </div>
              </Tooltip>
            </div>
          </div>
        })}

      </div>
    </div>
  );
}