import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateBill(
    
    $own_user_token: String!,
    $old_id: Int,

    $id: Int,
    $token: String,
    $label: String,
    $observations: String,
    $video_notes: String,
    $video_edits: String,
    $f10ID: Int,
    $type: String,
    $module: String,
    $hidden_concepts: String,
    $customer_token: String,
    
    $visible: Boolean,
    $public: Boolean,
    $blocked: Boolean,

    $include_extras: Boolean,
    $include_c1_new: Boolean,

    $date: Date,

  ) {
      createOrUpdateBill(input: { 

        own_user_token: $own_user_token,
        old_id: $old_id,

        id: $id,
        token: $token,
        label: $label,
        observations: $observations,
        video_notes: $video_notes,
        video_edits: $video_edits,
        f10ID: $f10ID,
        type: $type,
        module: $module,
        hidden_concepts: $hidden_concepts,
        customer_token: $customer_token,

        visible: $visible,
        public: $public,
        blocked: $blocked,
        
        include_extras: $include_extras,
        include_c1_new: $include_c1_new,

        date: $date,
      
      }) {
      
      id
      token
      label
      observations
      video_notes
      video_edits
      f10ID
      type
      module
      hidden_concepts
      customer_token

      visible
      public
      blocked

      include_extras
      include_c1_new

      external_price
      external_video_data

      date
      createdAt
      updatedAt

      customer
      dateFormated
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
