import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLazyQuery, useMutation } from '@apollo/client'

import CreateOrUpdateVideo from '../graphql/mutations/CreateOrUpdateVideo'
import GetStatesByCustomer from '../graphql/queries/GetStatesByCustomer'

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';






export default function ItemListStateChanger({video, disabled, small}) {



  const { currentUser, currentCustomer } = useContext(AuthContext)



  const [state, setState] = useState(1);
  const [states, setStates] = useState(null);




  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)

  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })





  useEffect(() => {
    getStatesByCustomer()
  }, []);


  useEffect(() => {    
    if (video?.id) {
      setState(video?.production_state)
    } else {
      setState(1)
    }
  }, [video])


  useEffect(() => {
    if (dataStates?.getStatesByCustomer?.length) {
      setStates(dataStates?.getStatesByCustomer)
    } else {
      setStates(null)
    }
  }, [dataStates]);






  async function setStateFunc(state) {
    if (!video?.is_translation_of || parseInt(state) === 11) {
      var input = {
        own_user_token: currentUser?.token, 
  
        id: parseInt(video?.id), 
        production_state: parseInt(state),
      };
  
      var videoTMP = await createOrUpdateVideo({variables: input})
      videoTMP = videoTMP?.data?.createOrUpdateVideo;
    }
  }

  





  return (
    <TextField
      select
      value={(state)? state : (states?.length)? states[0]?.id : 1}
      onChange={event => {
        if (currentUser?.rol === 'admin' || window.confirm("¿Seguro que quieres cambiar el estado de este vídeo?" + ((state > event.target.value)? ' Si es posible, recuerda poner una nota con el motivo de este cambio.' : ''))) {
          setState(event.target.value)
          setStateFunc(event.target.value)
        }
      }}
      label={(small)? '' : 'Estado'}
      className={`bg-gray-50 w-full`}
      style={{ minWidth: 'inherit' }}
      size="small"
      variant={(small)? 'outlined' : 'filled'}
      disabled={disabled}

      SelectProps={{
        MenuProps: {
          sx: {
            ".MuiList-root": {
              padding: '8px !important',
            },
          },
        },
      }}
    >

      {states?.map((stateTMP, key) => {
        return <MenuItem key={'state-list-' + stateTMP.id} value={stateTMP.id} style={{ marginBottom: ((key+1) !== states?.length)? 4 : 0 }}>
          <div className={`flex items-center space-x-2 truncate w-full menu-item-${stateTMP.color}`}>
            <div className={`hover:opacity-50 p-[4px] menu-item-ball-${stateTMP.color}`}></div>
            <p>{stateTMP.name}</p>
          </div>
        </MenuItem>
      })}
      
    </TextField>
  );
}    


{/* <Tooltip 
  disableInteractive 
  title={(video?.production_state_change_label)? (currentUser?.rol?.includes('user'))? video?.production_state_change_label?.replaceAll(video?.production_state_change_label?.replaceAll('Estado cambiado ', '')?.split(' el ')[0], '') : video?.production_state_change_label : ""} 
  placement="bottom" 
  arrow
> */}

/* </Tooltip> */
