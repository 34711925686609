export default function VIDEO_ENTREVISTA_BAKERY() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor'>
			<path d="M173.06,151.99c6.1,2.62,9.94-1.8,14.52-6.62c18.6-19.6,41.76-28.2,68.66-26.45c24.88,1.62,45.17,12.39,60.94,31.64
			c1.31,1.6,2.52,1.99,4.57,1.76c36.77-4.18,69.04,25.56,67.91,62.59c-0.07,2.35,0.63,3.83,2.3,5.39
			c21.79,20.3,32.05,45.44,30.73,75.13c-0.68,15.22-5.06,29.53-12.94,42.61c-5.83,9.68-11.89,19.21-18.04,28.69
			c-8.45,13.03-24.48,17.97-38.74,12.14c-13.8-5.65-21.65-20.49-18.62-35.51c1.44-7.15,3.12-14.25,4.81-21.34
			c0.43-1.79,0.1-2.98-1.31-4.17c-2.52-2.12-4.8-4.54-7.39-6.58c-0.93-0.73-2.58-1.22-3.7-0.96c-12.85,3.08-24.98,1.42-36.54-4.95
			c-0.97-0.53-2.54-0.62-3.6-0.25c-24.41,8.54-48.77,8.61-73.17-0.06c-1.05-0.37-2.62-0.2-3.61,0.35c-11.57,6.35-23.71,7.99-36.55,4.9
			c-1.1-0.27-2.75,0.27-3.68,1.01c-2.58,2.04-4.85,4.47-7.38,6.58c-1.45,1.21-1.67,2.42-1.25,4.19c1.72,7.29,3.46,14.59,4.91,21.94
			c2.76,13.98-4.63,28.34-17.44,34.27c-13.12,6.07-29.38,3.01-37.66-8.55C101,356,91.21,342.08,84.62,326.43
			c-15.59-37.01-6.09-79.21,23.6-106.19c1.6-1.46,2.33-2.84,2.25-5.11C109.31,180.52,137.24,151.84,173.06,151.99z M249.53,138.87
			c-20.78,0.32-38.23,8.51-51.56,25.25c-1.21,1.52-1.9,2.91-1.4,5.13c8.65,38.4,17.19,76.83,25.65,115.27
			c0.42,1.92,1.38,2.64,3.03,3.11c16.57,4.81,33.12,4.8,49.68-0.02c1.68-0.49,2.56-1.28,2.98-3.16c8.42-38.15,16.91-76.28,25.5-114.38
			c0.6-2.66,0.01-4.49-1.63-6.5C288.4,147.2,271.11,139.12,249.53,138.87z M329.17,172.24c-5.53-0.13-5.55-0.13-6.76,5.33
			c-2.86,12.87-5.7,25.75-8.56,38.62c-4.99,22.43-9.97,44.86-15.04,67.27c-0.54,2.38,0.04,3.63,2.23,4.71
			c6.83,3.39,13.91,4.44,21.22,2.22c1.81-0.55,3.77-1.94,4.8-3.52c13.22-20.13,26.29-40.36,39.36-60.59c0.8-1.23,1.48-2.63,1.85-4.04
			C374.64,197.45,355.38,172.85,329.17,172.24z M130.66,212.73c-0.27,0.07-0.54,0.15-0.81,0.22c1.22,4.35,1.53,9.29,3.83,12.97
			c12.5,20,25.61,39.61,38.23,59.54c2.32,3.66,5.23,5.18,9.14,5.74c6.45,0.92,12.5-0.19,18.29-3.18c1.91-0.99,2.52-2.1,2.04-4.25
			c-8.12-36.26-16.2-72.53-24.22-108.82c-0.42-1.91-1.26-2.57-3.18-2.69C150.94,170.78,130.64,189.7,130.66,212.73z M117.73,239.34
			c-1.02,1.11-1.92,2.02-2.75,2.99c-20.06,23.43-23.19,58.33-7.43,84.89c5.56,9.36,11.53,18.48,17.36,27.69
			c3.47,5.48,9.05,7.45,14.41,5.18c5.4-2.28,7.84-7.56,6.39-13.9c-2.35-10.33-4.65-20.66-7.12-30.96c-0.49-2.05-0.04-3.3,1.51-4.65
			c4.37-3.81,8.51-7.88,12.93-11.63c1.85-1.58,1.89-2.72,0.6-4.7c-11.48-17.57-22.86-35.2-34.28-52.81
			C118.93,240.78,118.4,240.2,117.73,239.34z M382.45,239.37c-0.55,0.58-0.87,0.83-1.07,1.15c-11.8,18.2-23.57,36.41-35.4,54.59
			c-0.94,1.44-0.57,2.25,0.58,3.27c4.25,3.79,8.3,7.83,12.69,11.44c2.27,1.87,2.76,3.61,2.08,6.4c-2.48,10.09-4.71,20.24-7.01,30.37
			c-1.36,5.99,1.28,11.35,6.6,13.54c5.17,2.13,10.79,0.27,14.04-4.83c5.8-9.1,11.8-18.1,17.21-27.43
			c11.83-20.41,13.64-41.86,5.6-64.05C394.46,254.72,389.38,246.66,382.45,239.37z"/>
		</svg>
	</>
}

