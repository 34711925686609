import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext';
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom';

import RowButtons from './Components/RowButtons';

import Factura10GetAllVideos from './../../graphql/queries/Factura10GetAllVideos'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';





const columns = [
    { id: 'name', label: 'Nombre' },
    { id: 'intro&closing', label: 'Intro + Cierre' },
    { id: 'vimeo', label: 'Exportación clip y reemplazo en vimeo' },
    { id: 'text_motions', label: 'Motions de texto' },
    { id: 'chart_motions', label: 'Motion formato esquema' },
    { id: 'mobile', label: 'Adaptaciones para móviles' },
    { id: 'total', label: 'Total', align: 'right' },
];

  
const tables = [
    'Implementación nuevo estilo gráfico vídeos ES',
    'Implementación nuevo estilo gráfico vídeos EN (traducidos del español)',
    'Implementación nuevo estilo gráfico vídeos EN',
];


const extras = [
    { name: 'Pre-producción', price: 390.00, desc: 'Preparación y coordinación' },
    { name: 'Propuesta videográfica urgente', price: 450.00, desc: 'En 5 días se entrega clip con los nuevos aplicativos en: INTRO / CLOSING / CHYRON / TEXTO / COMPO CON IMG / ESQUEMA / BIBLIOGRAFÍA' },
    { name: 'Estudio adaptación a dispositivos móviles textos y elementos', price: 390.00, desc: 'Incluye análisis de los 220 vídeos del Módulo 1, detección y clasificación de los errores de tamaño de textos, gráficos y contraste de las fuentes con su correspondiente captura. Entrega del documento en 5 días hábiles de la aceptación del presupuesto.' },
];






export default function C1ExtraMotions({billOption}) {



    const { bill_token } = useParams()

    const { currentUser, currentCustomer } = useContext(AuthContext)
    const { formatNumbersToPrint } = useContext(FuncContext)


    const [loadingBills, setLoadingBills] = useState(true);
    const [tablesOpen, setTablesOpen] = useState([ true, true, true ]);

    const [videos, setVideos] = useState(null);
    const [videosEN, setVideosEN] = useState(null);
    const [professors, setProfessors] = useState(null);


    const [totalPrice, setTotalPrice] = useState(0);
    const [totalIntroClosing, setTotalIntroClosing] = useState([{unidades: 0, price: 1.70}, {unidades: 0, price: 1.70}, {unidades: 0, price: 1.70}]);
    const [totalVimeo, setTotalVimeo] = useState([{unidades: 0, price: 2.50}, {unidades: 0, price: 2.50}, {unidades: 0, price: 2.50}]);
    const [totalTextMotions, setTotalTextMotions] = useState([{unidades: 0, price: 2.00}, {unidades: 0, price: 2.00}, {unidades: 0, price: 2.00}]);
    const [totalChyronMotions, setTotalChyronMotions] = useState([{unidades: 0, price: 8.00}, {unidades: 0, price: 8.00}, {unidades: 0, price: 8.00}]);
    const [totalMobileAdaptations, setTotalMobileAdaptations] = useState([{unidades: 0, price: 5.00}, {unidades: 0, price: 5.00}, {unidades: 0, price: 5.00}]);


    const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
    const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
    const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;









    const { data: dataVideos, loading: loadingVideos } = useQuery(Factura10GetAllVideos, {
        variables: { 
            own_user_token: (currentUser?.token)? currentUser?.token : '369sTmR2pL2ISUR2v94f',
            own_customer_token: currentCustomer?.token,
            module: '1'
        }
    })

    const { data: dataVideosEN, loading: loadingVideosEN } = useQuery(Factura10GetAllVideos, {
        variables: { 
            own_user_token: (currentUser?.token)? currentUser?.token : '369sTmR2pL2ISUR2v94f',
            own_customer_token: currentCustomer?.token,
            module: '31'
        }
    })





    useEffect(() => {
        setTimeout(()=> {
            setLoadingBills(false)
        }, 1000)
    }, [loadingBills])


    useEffect(() => {
        setVideos((dataVideos?.getCustomerVideos?.length)? [...dataVideos?.getCustomerVideos]?.sort((a, b) => b?.professor?.name - a?.professor?.name) : null)
    }, [dataVideos])

    useEffect(() => {
        setVideosEN((dataVideosEN?.getCustomerVideos?.length)? [...dataVideosEN?.getCustomerVideos]?.sort((a, b) => b?.professor?.name - a?.professor?.name) : null)
    }, [dataVideosEN])




    useEffect(() => {

        var all = null;
        if (videos?.length && videosEN?.length) { all = [...videos, ...videosEN] }

        var totalIntroClosingTMP = [{unidades: 0, price: 1.70}, {unidades: 0, price: 1.70}, {unidades: 0, price: 1.70}]
        var totalVimeoTMP = [{unidades: 0, price: 2.50}, {unidades: 0, price: 2.50}, {unidades: 0, price: 2.50}]
        var totalTextMotionsTMP = [{unidades: 0, price: 2.00}, {unidades: 0, price: 2.00}, {unidades: 0, price: 2.00}]
        var totalChyronMotionsTMP = [{unidades: 0, price: 8.00}, {unidades: 0, price: 8.00}, {unidades: 0, price: 8.00}]
        var totalMobileAdaptationsTMP = [{unidades: 0, price: 5.00}, {unidades: 0, price: 5.00}, {unidades: 0, price: 5.00}]

        var professorsTMP = {}


        for (var tKey in tables) {
            

            var videosTMP = (videos)?.filter((element) => 

                ((!parseInt(tKey) || parseInt(tKey) === 1) && element?.language === 'esp')
                || (parseInt(tKey) === 2 && element?.language === 'eng') 

            )

            for (var key in videosTMP) {
                var video = videosTMP[key]
                const extraMarksJson = (JSON.parse(video['extra_marks_json'])?.length)? JSON.parse(video['extra_marks_json'])[0] : null;

                totalIntroClosingTMP[tKey].unidades = totalIntroClosingTMP[tKey]?.unidades + 1
                totalVimeoTMP[tKey].unidades = totalVimeoTMP[tKey]?.unidades + 1
                totalTextMotionsTMP[tKey].unidades = totalTextMotionsTMP[tKey]?.unidades + ((extraMarksJson?.text_motions)? extraMarksJson?.text_motions : 0)
                totalChyronMotionsTMP[tKey].unidades = totalChyronMotionsTMP[tKey]?.unidades + ((extraMarksJson?.chart_motions)? extraMarksJson?.chart_motions : 0)
                totalMobileAdaptationsTMP[tKey].unidades = totalMobileAdaptationsTMP[tKey]?.unidades + ((extraMarksJson?.phone_adaptations)? extraMarksJson?.phone_adaptations : 0)    

                var finalPrice = parseFloat(1.7 + 2.5 + ((extraMarksJson?.text_motions)? extraMarksJson?.text_motions * 2 : 0) + ((extraMarksJson?.chart_motions)? extraMarksJson?.chart_motions * 8 : 0) + ((extraMarksJson?.phone_adaptations)? extraMarksJson?.phone_adaptations * 5 : 0))
                if (!professorsTMP[tKey]) {professorsTMP[tKey] = {};}
                if (!professorsTMP[tKey][video?.professor?.name]) {professorsTMP[tKey][video?.professor?.name] = {};}
                if (!professorsTMP[tKey][video?.professor?.name]['profe']) {professorsTMP[tKey][video?.professor?.name]['profe'] = video?.professor;}
                if (!professorsTMP[tKey][video?.professor?.name][video?.name]) {professorsTMP[tKey][video?.professor?.name][video?.name] = finalPrice;}
                professorsTMP[tKey][video?.professor?.name]['price'] = (parseFloat(professorsTMP[tKey][video?.professor?.name]['price']))? parseFloat(professorsTMP[tKey][video?.professor?.name]['price']) + finalPrice : finalPrice;

            }
        }

        setTotalIntroClosing(totalIntroClosingTMP)
        setTotalVimeo(totalVimeoTMP)
        setTotalTextMotions(totalTextMotionsTMP)
        setTotalChyronMotions(totalChyronMotionsTMP)
        setTotalMobileAdaptations(totalMobileAdaptationsTMP)
        
        setProfessors(professorsTMP)

    }, [videos, videosEN])


    useEffect(() => {
        setTotalPrice(
            totalIntroClosing[0]?.unidades * totalIntroClosing[0]?.price
            + totalVimeo[0]?.unidades * totalVimeo[0]?.price
            + totalTextMotions[0]?.unidades * totalTextMotions[0]?.price
            + totalChyronMotions[0]?.unidades * totalChyronMotions[0]?.price
            + totalMobileAdaptations[0]?.unidades * totalMobileAdaptations[0]?.price

            + totalIntroClosing[1]?.unidades * totalIntroClosing[1]?.price
            + totalVimeo[1]?.unidades * totalVimeo[1]?.price
            + totalTextMotions[1]?.unidades * totalTextMotions[1]?.price
            + totalChyronMotions[1]?.unidades * totalChyronMotions[1]?.price
            + totalMobileAdaptations[1]?.unidades * totalMobileAdaptations[1]?.price

            + totalIntroClosing[2]?.unidades * totalIntroClosing[2]?.price
            + totalVimeo[2]?.unidades * totalVimeo[2]?.price
            + totalTextMotions[2]?.unidades * totalTextMotions[2]?.price
            + totalChyronMotions[2]?.unidades * totalChyronMotions[2]?.price
            + totalMobileAdaptations[2]?.unidades * totalMobileAdaptations[2]?.price

            + extras[0]?.price
            + extras[1]?.price
            + extras[2]?.price
        )
    }, [totalIntroClosing, totalVimeo, totalTextMotions, totalChyronMotions, totalMobileAdaptations, extras])



      


  return (currentCustomer && !loadingBills)? (
    <div className='space-y-2'>





        <p className='text-xs pt-8 text-gray-500'>Resumen de la factura:</p>
        <div className='w-full p-4 space-y-4 rounded bg-gray-50 border border-gray-300'>
            <div>
                <div className='w-full text-sm flex justify-between items-start space-x-10 py-3 px-1'>
                    <h1 className='text-xl font-bold'>{billOption?.label}</h1>
                    <p className='bg-gray-200 rounded px-2 py-1'>{billOption?.date}</p>
                </div>

                {/* <div className='text-sm pt-2'>
                    (Descripción de la factura aquí)
                </div> */}
            </div>



            <div className='pb-4'>
                <hr className='border-gray-300' />
            </div>



            <div className='w-full flex overflow-auto'>
                <div className='flex flex-wrap justify-end space-y-4'>
                    {extras?.map(element => {
                        return <table className='w-full bg-gray-200 duration-200 rounded p-2 font-normal overflow-hidden  text-sm'  key={'extra-' + element?.name}>
                            <thead>
                                <tr className='text-gray-400 text-md text-left bg-gray-300 bg-opacity-75'>
                                    <th className='text-black text-base p-2 max-w-[300px] w-full'>{element?.name}</th>
                                    <th className='text-black text-base p-2 max-w-[300px] w-full whitespace-nowrap'>{formatNumbersToPrint(element?.price, 2)} €</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='p-2 max-w-[300px] w-full'>{element?.desc}</td>
                                    <td className=''></td>
                                </tr>
                            </tbody>
                        </table>
                    })}



                    {tables?.map((table, tKey) => {
                        return <table className='w-full bg-gray-200 rounded p-2 font-normal whitespace-nowrap overflow-hidden  text-sm' key={'list-' + tKey}>
                            
                            <thead>
                                <tr className='text-gray-500 text-xs text-left bg-gray-300 bg-opacity-75'>
                                    <th className='text-black text-base px-2 py-[6px] max-w-[300px] w-full whitespace-normal'>{table}</th>
                                    <th className='font-normal text-right px-6 py-[6px] w-full'>Unidades</th>
                                    <th className='font-normal text-right px-6 py-[6px] w-full'>Precio/unidad</th>
                                    <th className='font-normal text-right px-2 py-[6px] w-full'>Total</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Intro + Cierre</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalIntroClosing[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalIntroClosing[tKey]?.price, 2)} €</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalIntroClosing[tKey]?.unidades * totalIntroClosing[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Exportación clip y reemplazo en vimeo</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalVimeo[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalVimeo[tKey]?.price, 2)} €</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalVimeo[tKey]?.unidades * totalVimeo[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Motion de texto</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalTextMotions[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalTextMotions[tKey]?.price, 2)} €</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalTextMotions[tKey]?.unidades * totalTextMotions[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Motion formato esquema</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalChyronMotions[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalChyronMotions[tKey]?.price, 2)} €</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalChyronMotions[tKey]?.unidades * totalChyronMotions[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Adaptaciones para móviles</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalMobileAdaptations[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalMobileAdaptations[tKey]?.price, 2)} €</td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(totalMobileAdaptations[tKey]?.unidades * totalMobileAdaptations[tKey]?.price, 2)} €</td>
                                </tr>

                                <tr className='hover:bg-gray-300 duration-200 font-bold text-base'>
                                    <td className='px-2 py-[6px] max-w-[300px] w-full'>Total</td>
                                    <td className='text-right px-6 py-[6px] w-full'>{formatNumbersToPrint(totalIntroClosing[tKey]?.unidades + totalVimeo[tKey]?.unidades + totalTextMotions[tKey]?.unidades + totalChyronMotions[tKey]?.unidades)} unidades</td>
                                    <td className='text-right px-6 py-[6px] w-full'></td>
                                    <td className='text-right px-2 py-[6px] w-full'>{formatNumbersToPrint(
                                        totalIntroClosing[tKey]?.unidades * totalIntroClosing[tKey]?.price
                                        + totalVimeo[tKey]?.unidades * totalVimeo[tKey]?.price
                                        + totalTextMotions[tKey]?.unidades * totalTextMotions[tKey]?.price
                                        + totalChyronMotions[tKey]?.unidades * totalChyronMotions[tKey]?.price
                                        + totalMobileAdaptations[tKey]?.unidades * totalMobileAdaptations[tKey]?.price
                                    , 2)} €</td>
                                </tr>
                            </tbody>

                        </table>
                    })}




                    <div className='space-y-1 text-sm bg-gray-300 bg-opacity-75 rounded px-4 py-3'>
                        <div className='w-full flex justify-between space-x-8 text-xl font-bold'>
                            <p>Base imponible:</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(totalPrice), 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>IVA (21%):</p>
                            <p>
                                {formatNumbersToPrint(parseFloat(totalPrice) * 0.21, 2)} €
                            </p>
                        </div>

                        <div className='w-full flex justify-between space-x-8'>
                            <p>Importe total:</p>
                            <p>
                                {formatNumbersToPrint(totalPrice + (totalPrice * 0.21), 2)} €
                            </p>
                        </div>
                    </div>



                </div>
            </div>
        </div>







        <p className='text-xs pt-8 text-gray-500'>Tablas de información:</p>
        {(videos?.length || videosEN?.length)?
            tables?.map((table, tKey) => {

                return <div key={'table-' + tKey} className=''>

                    <div className={`border border-gray-300 rounded bg-gray-300`}>
                        <div 
                            className={`hover:bg-gray-400 active:bg-gray-200 duration-200 cursor-pointer rounded p-2 flex justify-between items-center`}
                            onClick={() => { 
                                var tablesOpenTMP = [...tablesOpen]
                                tablesOpenTMP[tKey] = !tablesOpenTMP[tKey];
                                setTablesOpen(tablesOpenTMP)
                            }}
                        >
                            <p className='text-lg font-bold px-2'>{table}</p>
                            {(tablesOpen[tKey])? upArrowIcon : downArrowIcon}
                        </div>

                        <Collapse orientation="vertical" in={tablesOpen[tKey]} timeout={1000}>
                            <div className='border-t border-gray-300'>
                                <Paper sx={{ width: '100%', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)' }}>
                                    <TableContainer sx={{ width: '100%', overflow: 'inherit', boxShadow: 0 }}>
                                        <Table>

                                            <TableHead>
                                                <TableRow className={`${(bill_token || !tablesOpen[tKey])? 'table-stiky-0' : 'table-stiky'}`}>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={'columns-'+column.id}
                                                            className='select-none'
                                                            align={column.align}
                                                            style={{ 
                                                                minWidth: column.minWidth,
                                                                width: column.width,
                                                                backgroundColor: 'inherit',
                                                                padding: 8,
                                                            }}
                                                        >
                                                            <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                                                                <div> {column.label} </div>
                                                            </div>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {professors && professors[tKey] && Object.keys(professors[tKey])?.length && Object.keys(professors[tKey])?.sort((a, b) => (a < b)? -1 : (a > b)? 1 : 0 )?.map((keyName, key) => {
                     
                                                    var professor = professors[tKey][keyName]['profe']
                                                    var professorPrice = professors[tKey][keyName]['price']

                                                    var videosTMP = (videos)?.filter((element) => element?.professor?.name === professor?.name).filter((element) => 

                                                        ((!parseInt(tKey) || parseInt(tKey) === 1) && element?.language === 'esp')
                                                        || (parseInt(tKey) === 2 && element?.language === 'eng') 

                                                    )
                                                    


                                                    return (videosTMP?.length)? <>
                                                        <TableRow 
                                                            hover 
                                                            role="checkbox" 
                                                            tabIndex={-1} 
                                                            className="w-full bg-blue-100 duration-200"
                                                        >
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='font-bold w-5 whitespace-nowrap'>{professor?.name }</p>
                                                            </TableCell>

                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>
                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}></TableCell>

                                                            <TableCell style={{ borderBottom: 0, padding: 8 }}>
                                                                <p className='w-full font-bold whitespace-nowrap text-right'>{formatNumbersToPrint(professorPrice, 2)} €</p>
                                                            </TableCell>
                                                        </TableRow>
                                                    
                                                    
                                                        {videosTMP?.map((row) => {

                                                            return (
                                                                <TableRow 
                                                                    key={'row-'+row.id} 
                                                                    hover 
                                                                    role="checkbox" 
                                                                    tabIndex={-1} 
                                                                    className="hover:bg-gray-100 duration-200"
                                                                >
                                                                    {columns.map((column) => {
                                                                        var value = row[column.id];
                                                                        const extraMarksJson = (JSON.parse(row['extra_marks_json'])?.length)? JSON.parse(row['extra_marks_json'])[0] : null;


                                                                        if (column?.id === 'name') {
                                                                            value = <div className='flex space-x-2'>
                                                                                <RowButtons
                                                                                    row={row}
                                                                                    billOption={billOption}
                                                                                />
                                                                                
                                                                                {(row['vimeo_url'])? 
                                                                                    <a href={row['vimeo_url']} target="_blank">{value}</a> 
                                                                                    : <p>{value}</p>
                                                                                }
                                                                            </div>
                                                                        }

                                                                        if (column?.id === 'intro&closing') {
                                                                            value = <div className='text-green-500'>{doneIcon}</div>;
                                                                        } else if (column?.id === 'vimeo') {
                                                                            value = <div className='text-green-500'>{doneIcon}</div>;
                                                                        }
                                                                        
                                                                        if (column?.id === 'text_motions') { /* TEXT MOTIONS */
                                                                            value = (extraMarksJson?.text_motions)? extraMarksJson?.text_motions : '---';
                                                                        }

                                                                        if (column?.id === 'chart_motions') { /* CHART MOTIONS */
                                                                            value = (extraMarksJson?.chart_motions)? extraMarksJson?.chart_motions : '---';
                                                                        } 

                                                                        if (column?.id === 'mobile') { /* CHART MOTIONS */
                                                                            value = (extraMarksJson?.phone_adaptations)? extraMarksJson?.phone_adaptations : '---';
                                                                        } 
                                                                            

                                                                        if (column?.id === 'total') { /* CHART MOTIONS */
                                                                            value = formatNumbersToPrint(1.7 + 2.5 + ((extraMarksJson?.text_motions)? extraMarksJson?.text_motions * 2 : 0) + ((extraMarksJson?.chart_motions)? extraMarksJson?.chart_motions * 8 : 0) + ((extraMarksJson?.phone_adaptations)? extraMarksJson?.phone_adaptations * 5 : 0), 2) + ' €';
                                                                        }
                                                                        

                                                                        return (
                                                                            <TableCell
                                                                                key={'column-data-'+column.id}
                                                                                align={column.align}
                                                                                style={{ borderBottom: 0, padding: 8 }}
                                                                                className={`text-sm ${(!value || value === '---')? 'opacity-30' : ''}`}
                                                                            >
                                                                                {value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </> : null
                                                })}

                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Collapse>
                    </div>
                </div>
            })
        : null }


















    </div>

  ) : 
  
    <div className='absolute z-10 w-full h-full'>
        <div className='w-full h-full flex justify-center items-center px-4 py-[200px] animate-pulse bg-gray-200 rounded text-neutral-500'>
            {loadingIcon}
            <p>Cargando...</p>
        </div>
    </div>
}