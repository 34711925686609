import { useRef, useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { useVisualizer, models } from 'react-audio-viz'
import { useQuery } from '@apollo/client'

import GetAllUsers from './../graphql/queries/GetAllUsers'
import GetCustomerByAbreviatedName from '../graphql/queries/GetCustomerByAbreviatedName'
import GetVideo from './../graphql/queries/GetVideo'
import GetVideoMarks from './../graphql/queries/GetVideoMarks'
import GetAllVideoReviews from './../graphql/queries/GetAllVideoReviews'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'
import GetBillConceptsFromVideo from './../graphql/queries/GetBillConceptsFromVideo'

import VideoTopNavigation from '../components/VideoTopNavigation'
import VideoMarks from '../components/VideoMarks';
import VideoPlayer from '../components/VideoPlayer'
import VideoOptionsPanel from '../components/VideoOptionsPanel'

import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import GetClicksoundUserNames from '../graphql/queries/GetClicksoundUserNames';










const VideoReviews = ({ defMode }) => {




  const { path_customer, path_module, path_name, id, pathTime } = useParams()
  const { currentUser, currentCustomer, customerPath, getNewMarkLanguageFunc, setCustomPageTitle } = useContext(AuthContext)
  const { setCurrentNavActive, loadingText, setLoadingText, isFullScreenLayout } = useContext(LayoutContext)
  const { fileExists, navigateOrOpenFunc, remove_tags } = useContext(FuncContext)
  const navigate = useNavigate();

  
  
  const audioRef = useRef(null)
  const [AudioViz, init] = useVisualizer(audioRef)






  

  /* Action vars? */
  const [mode, setMode] = useState(defMode);
  const [disableModeNav, setDisableModeNav] = useState(false);
  const [tab, setTab] = useState(0);

  const [reviewFilter, setReviewFilter] = useState(true);

  const [markStateFilter, setMarkStateFilter] = useState([true, true, true, true, true, true]);
  const [stateCounter, setStateCounter] = useState([0, 0, 0, 0, 0, 0]);
  const [droppedFiles, setDroppedFiles] = useState(null);


  /* Review and Marks vars */
  const [selectedReview, setSelectedReview] = useState(null);
  const [newVideoMark, setNewVideoMark] = useState(null);
  const [showMarksOnScreen, setShowMarksOnScreen] = useState(true);
  const [newReviewFile, setNewReviewFile] = useState(null);

  /* Basic video vars */
  const [video, setVideo] = useState(null);
  const [videoMarks, setVideoMarks] = useState(null);
  const [billConcepts, setBillConcepts] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [professors, setProfessors] = useState(null);
  const [fromAnotherModule, setFromAnotherModule] = useState(false);

  /* Thumbnail vars */
  const [predefThumbnails, setPredefThumbnails] = useState([0, 0, 0, 0]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const [thumbTime, setThumbTime] = useState(dayjs().set('hour', '00').set('minute', '00').set('second', '01'));
  const [thumbTimeToSeconds, setThumbTimeToSeconds] = useState(1);
  const [thumbFirstime, setThumbFirstime] = useState(false);
  const [thumbFirstimeSelection, setThumbFirstimeSelection] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const [reviewLogs, setReviewLogs] = useState(reviews);
  const [userFromSelectedReview, setUserFromSelectedReview] = useState(null);



  /* Loading vars */
  const [baseLoading, setBaseLoading] = useState(true);
  const [loadingNewVideo, setLoadingNewVideo] = useState(null);
  
  /* Icon vars */
  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const notVisibleIcon = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="currentColor"><path d="M480-80q-85 0-158-30.5T195-195q-54-54-84.5-127T80-480q0-84 30.5-157T195-764q54-54 127-85t158-31q84 0 157 31t127 85q54 54 85 127t31 157q0 8-.5 16t-1.5 16q-13-8-28.5-17T820-480q0-145-97.5-242.5T480-820q-66 0-123 21.5T258-740l273 273q-13 6-25 13t-24 15L219-702q-37 43-58 100t-21 122q0 125 74.5 216.5T406-148q15 17 35 36t39 32Zm200-60q64 0 121-32.5t93-87.5q-36-55-93-87.5T680-380q-64 0-121 32.5T466-260q36 55 93 87.5T680-140Zm0 60q-96 0-171.5-50.5T400-260q33-79 108.5-129.5T680-440q96 0 171.5 50.5T960-260q-33 79-108.5 129.5T680-80Zm0-120q-25 0-42.5-17.5T620-260q0-25 17.5-42.5T680-320q25 0 42.5 17.5T740-260q0 25-17.5 42.5T680-200Z"/></svg>
  const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor' viewBox="0 96 960 960"><path d="M244 852h112V612h248v240h112V498L480 321 244 498v354Zm0 84q-34.65 0-59.325-24.675Q160 886.65 160 852V498q0-19.891 9.031-37.688Q178.062 442.516 194 431l236.175-177.128Q441 246 453.72 241.5q12.72-4.5 26.5-4.5t26.28 4.5q12.5 4.5 23.325 12.372L766 431q15.938 11.516 24.969 29.312Q800 478.109 800 498v354q0 34.65-24.675 59.325Q750.65 936 716 936H520V696h-80v240H244Zm236-350Z"/></svg>;
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  


  
  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsers, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })

  const [getCustomerByAbreviatedName, { data: dataCustomer, loading: loadingCustomer }] = useLazyQuery(GetCustomerByAbreviatedName, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: (currentUser)? currentUser?.token : null,
      abbreviated_name: (path_customer)? path_customer : null,
    }
  })

  const [getVideo, { data: dataVideo, loading: loadingVideo }] = useLazyQuery(GetVideo, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: (dataCustomer?.getCustomerByAbreviatedName?.token)? dataCustomer?.getCustomerByAbreviatedName?.token : currentCustomer?.token,

      id: ((/^\d+$/.test(id)) && parseInt(id))? parseInt(id) : null,
      name: (/^\d+$/.test(id) && parseInt(id))? null : id,
      module_name: path_module,
      check_other_modules: true,
    }
  })

  const [getVideoReviews, { data: dataReviews, loading: loadingReviews }] = useLazyQuery(GetAllVideoReviews, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token,
      video_id: ((/^\d+$/.test(id)) && parseInt(id))? parseInt(id) : parseInt(video?.id),
    }
  })

  const [getVideoMarks, { data: dataVideoMarks, loading }] = useLazyQuery(GetVideoMarks, {
    fetchPolicy: 'cache-and-network',
    variables: {
      own_user_token: currentUser?.token, 
      video_id: parseInt(video?.id),
      user_id: /* (currentUser?.professor)? parseInt(currentUser?.id) :  */null,
    }
  })

  const [getAllProfessors, { data: dataProfessors, loading: loadingProfessors }] = useLazyQuery(GetAllProfessorsByCustomer, { 
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'cache-and-network' 
  })

  const [getBillConceptsFromVideo, { data: dataBillConcepts, loadingBillConcepts }] = useLazyQuery(GetBillConceptsFromVideo, {
    fetchPolicy: 'cache-and-network',
    variables: {
      own_user_token: currentUser?.token, 
      video_id: ((/^\d+$/.test(id)) && parseInt(id))? parseInt(id) : parseInt(video?.id),
    }
  })

  const [getClicksoundUserNames, { data: clicksoundUserNames, loading: loadingClicksoundUserNames }] = useLazyQuery(GetClicksoundUserNames, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
    }
  })








  useEffect(() => {    
    setCurrentNavActive('videos')

    handleResize()
    window.addEventListener('resize', handleResize);


    setTimeout(()=> {
      if (pathTime) {
        var focus = document.getElementById('time-mark-'+pathTime)
        if (focus) { scrollToJustAbove(focus) }
      }
    }, 4500)


    setTimeout(()=> {
      setBaseLoading(false)
    }, 500)


    if (currentCustomer) {
      if (currentUser?.professor) {
        setReviewFilter(true)
        localStorage.setItem(currentCustomer?.token+'-review-version-filter', 1)
      } else if (localStorage.getItem(currentCustomer?.token+'-review-version-filter')) {
        setReviewFilter(parseInt(localStorage.getItem(currentCustomer?.token+'-review-version-filter'))? true : false)
      }
    }


    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

    
  useEffect(() => { 
    setReviewLogs(reviews)
  }, [reviews])
  

  useEffect(() => {
    if (reviewLogs && reviewLogs.length && reviewLogs[tab]) {
      setSelectedReview(reviewLogs[tab])
      
      dataUsers?.users?.map(element => {
        if (parseInt(element.id) === parseInt(reviewLogs[tab]?.user_id)) {
          setUserFromSelectedReview(element)
        }
      });

    } else {
      setSelectedReview(null)
    }
  }, [video, tab, reviewLogs, dataUsers])


  function scrollToJustAbove(element, margin=130) {
    let dims = element.getBoundingClientRect();
    window.scrollTo(window.scrollX, dims.top - margin);
  }


  useEffect(() => {    
    setMode(defMode)
    setThumbFirstimeSelection(false)
    if (selectedReview) { setSelectedReview(null) }

    setReviews([])

    if (path_customer) {
      getCustomerByAbreviatedName()
    }
  }, [navigate])


  
  
  useEffect( () => {
    getVideo()
  }, [dataCustomer])


  

  useEffect( () => {
    if (parseInt(dataCustomer?.getCustomerByAbreviatedName?.id) && parseInt(dataCustomer?.getCustomerByAbreviatedName?.id) !== parseInt(currentCustomer?.id)) {
      sessionStorage.setItem('localCurrentCustomer', JSON.stringify(dataCustomer?.getCustomerByAbreviatedName))
      localStorage.setItem('localCurrentCustomer', JSON.stringify(dataCustomer?.getCustomerByAbreviatedName))
      navigate(0)
    }
  }, [dataCustomer])


  useEffect(() => { 
    var videoTMP = dataVideo?.video;
    if (videoTMP?.id && (!path_module || videoTMP?.search_module_abbreviated_name.toLowerCase() === path_module.toLowerCase() || (!videoTMP?.module_id && path_module.toLowerCase() === 'extra'))) {
      setFromAnotherModule(null);
      setVideo(videoTMP)
      if (videoTMP.module_id) { localStorage.setItem(currentCustomer?.token+'-video-module-filter', videoTMP.module_id) }
    } else if (videoTMP?.id && path_module) {
      setFromAnotherModule(videoTMP);
      setVideo(null)
    } else {
      setFromAnotherModule(null);
      setVideo(null)
    }
  }, [dataVideo])




  useEffect(() => {
    if (video?.id && currentUser) {
      getVideoReviews()
      getVideoMarks() 
      getAllProfessors()
      getClicksoundUserNames()

      setCustomPageTitle(currentCustomer?.abbreviated_name + ' - ' + video?.name + ((video?.title)? ` - ${remove_tags(video?.title, true)}` : ''))
    }
  }, [video?.id])





  useEffect(() => { 
    if (currentUser?.professor && currentCustomer?.abbreviated_name && fromAnotherModule?.url_name) {
      navigate("/" + currentCustomer?.abbreviated_name + "/" + ((fromAnotherModule?.search_module_abbreviated_name)? fromAnotherModule?.search_module_abbreviated_name : 'extra') + "/" + fromAnotherModule?.url_name);
    }
  }, [fromAnotherModule])



  useEffect(() => { 
    if (dataReviews?.videoReviewsByVideoId && dataReviews.videoReviewsByVideoId?.length) {
      setReviews(dataReviews.videoReviewsByVideoId)
      setTab(0)
    } else {
      setReviews([])
    }
  }, [dataReviews])


  useEffect(() => { 
    if (dataVideoMarks?.videoMarksByVideoId?.length) {
      setVideoMarks(dataVideoMarks?.videoMarksByVideoId)
    } else {
      setVideoMarks(null)
    }
  }, [dataVideoMarks])



  useEffect(() => { 
    if (dataBillConcepts?.billConceptsFromVideo?.length) {
      setBillConcepts(dataBillConcepts?.billConceptsFromVideo)
    } else {
      setBillConcepts(null)
    }
  }, [dataBillConcepts])
  


  useEffect(() => { 
    if (dataProfessors?.professorsByCustomer) {
      setProfessors(dataProfessors?.professorsByCustomer)
    } else {
      setProfessors(null)
    }
  }, [dataProfessors])


  useEffect( () => { 
    if (videoMarks?.length) {
      var counterTMP = [0, 0, 0, 0, 0, 0]
      counterTMP = countStates(videoMarks, counterTMP)
      setStateCounter([...counterTMP])

    } else {
      setStateCounter([0, 0, 0, 0, 0, 0])
    }
  }, [videoMarks, mode])


  useEffect(() => { 
    if (thumbTime) {
      var secondsTMP = ((thumbTime?.get('hours') * 60) * 60) + (thumbTime?.get('minutes') * 60) + (thumbTime?.get('seconds'));
      
      if (secondsTMP && secondsTMP !== NaN) {
        setThumbTimeToSeconds(secondsTMP)
        /* var videoTMP = document.getElementById('pers-video-thumb')
        if (videoTMP) { videoTMP.currentTime = secondsTMP } */
        setPredefThumbnails([secondsTMP, predefThumbnails[1], predefThumbnails[2], predefThumbnails[3]])
      } else {
        setThumbTimeToSeconds(1)
        setPredefThumbnails([0, predefThumbnails[1], predefThumbnails[2], predefThumbnails[3]])
      }

      var videoElementTMP = document.getElementById('video');
      if (videoElementTMP && secondsTMP && thumbFirstime) { 
        /* videoElementTMP.currentTime = secondsTMP  */
        setThumbFirstime(false)
      }
    }
  }, [selectedReview, thumbTime, thumbFirstime])



  useEffect(() => { 
    var videoElementTMP = document.getElementById('video');
    if (videoElementTMP && thumbFirstime) { 
      /* videoElementTMP.currentTime = predefThumbnails[selectedThumbnail]  */
      setThumbFirstime(false)
    }
  }, [selectedThumbnail, thumbFirstime])
   
  
  useEffect(() => { 
    if (video && !thumbFirstimeSelection && thumbTimeToSeconds > 1) {
      predefThumbnails?.map((duration, key) => { 
        if (duration === parseInt(thumbTimeToSeconds)) { 
          setThumbFirstimeSelection(true)
          setSelectedThumbnail(key) 
        } 
      });
    }
  }, [navigate, video, predefThumbnails])


  useEffect( () => { 
    if (loadingReviews || loadingCustomer || loadingClicksoundUserNames || baseLoading) {
      setLoadingText('Cargando vídeo...')
    } else if (loadingNewVideo) {
      setLoadingText(loadingNewVideo)
    } else {
      setTimeout(()=>{
        setLoadingText(null)
      }, 1000)
    }
  }, [loadingReviews, loadingCustomer, loadingClicksoundUserNames, loadingNewVideo, baseLoading])
  










  function handleResize() {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }
  

  function reloadThumbnails() {
    var videoElement = document.getElementById("video");

    if (videoElement && video && !video?.is_sound_media && selectedReview?.filename?.includes('.mp4')) {
      if (video?.thumbnail) {
        var time = formatTime(Math.round(parseInt(video?.thumbnail?.split('-')[0])));
        setThumbTime(dayjs().set('hour', time.hours+'').set('minute', time.minutes+'').set('second', time.seconds+''))
      } else {
        setThumbTime(dayjs().set('hour', '00').set('minute', '00').set('second', '01'))
      }

      /* 0.98 : 0.5 : 0.06 */
      /* Math.floor(parseInt(video?.duration * 0.06) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + ':' + (parseInt(video?.duration * 0.06) - Math.floor(parseInt(video?.duration * 0.06) / 60) * 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) */
      var timeStartTMP = parseInt(videoElement?.duration * 0.1);
      var timeMiddleTMP = parseInt(videoElement?.duration * 0.5);
      var timeEndTMP = parseInt(videoElement?.duration * 0.98);
      setPredefThumbnails([parseInt(video?.thumbnail?.split('-')[0]), timeStartTMP, timeMiddleTMP, timeEndTMP])
    }
  }



  // formatTime segundos a HH:mm:ss
  function formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000)?.toISOString()?.substr(11, 8);

    return {
      hours: result.substr(0, 2),
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  };


  function createMark(event) {

    var time = null;
    var coordinates = null;

    var videoElement = document.getElementById("video");
    if (videoElement) {
      time = formatTime(Math.floor(videoElement.currentTime));
      time = time.hours + ':' + time.minutes + ':' + time.seconds

      if (event) {
        videoElement = document.getElementById("video");
        if (videoElement) {
          const rect = videoElement?.getBoundingClientRect()
          const w = videoElement.clientWidth
          const h = videoElement.clientHeight
          const x = ((((event.clientX - 5) - rect.left) / w) * 100).toFixed(4)
          const y = ((((event.clientY - 5) - rect.top) / h) * 100).toFixed(4)    
          coordinates = x + ',' + y;
        }
      }
    }


    var langsTMP = (video?.translations?.length)? video?.translations?.map((el) => el?.language) : [video?.language];
    var input = {
      time: (time)? time : '00:00:00',
      video_id: (parseInt(video?.is_translation_of))? parseInt(video?.is_translation_of) : parseInt(video?.id),
      user_id: currentUser? parseInt(currentUser.id) : null,
      
      type_id: null,
      observations: null,
      texts: (video?.language)? JSON.stringify(getNewMarkLanguageFunc(langsTMP, video?.language, null)) : null,
      url: null,
      filename: null,
      coordinates: coordinates,
    };

    setNewVideoMark(input)

    setTimeout(()=> {
      /* window.scrollTo({ top: 200 }); */

      var focusDiv = document.getElementById('video-mark-list-item-focus-undefined')
      if (focusDiv) { window.scrollTo({ top: focusDiv.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 130 }) }
    }, '500')
  }

  function countStates(element, counterTMP) {
    if (element?.length) {
      for (let i = 0; i < element?.length; i++) {
        var motionState = ((element[i]?.motion_state !== 2 && element[i]?.motion_state !== 3 && element[i]?.motion_state !== 4) && mode === 'motion') || mode !== 'motion';

        if (element[i]?.state === 1 && motionState) { counterTMP[0]++ }
        if (element[i]?.state === 2 && motionState) { counterTMP[1]++ }

        if (element[i]?.motion_state === 2) { counterTMP[2]++ }
        if (element[i]?.motion_state === 3) { counterTMP[3]++ }
        if (element[i]?.motion_state === 4 ) { counterTMP[4]++ }

        if (element[i]?.state === 3 ) { counterTMP[5]++ }
      }
    }
    return counterTMP
  }












  return (
    (!baseLoading && (!selectedReview || (selectedReview?.filename && (process.env.REACT_APP_IN_PRODUCTION === '0' || fileExists(customerPath + 'video_reviews/' + selectedReview?.filename) || fileExists(customerPath + 'video_reviews_lowres/' + selectedReview?.filename)))) && ((id && video && video?.customer_token === currentCustomer?.token) || (!id && !video) || loadingReviews))?
      <div className='relative flex flex-col items-center justify-center'>






        {(!isFullScreenLayout && (!video?.is_translation_of || !currentUser?.rol?.includes('user'))) && 
          <div className={`circle ${(!currentUser?.rol?.includes('user'))? 'ml-[-60px]' : 'ml-[3px]'}`} id='circle'>
            <div className='scale-90'>{addIcon}</div>
            <p className='whitespace-nowrap'>Crear marca</p>
          </div>
        }





     




        
        <VideoTopNavigation
          mode={mode}
          setMode={setMode}
          disableModeNav={disableModeNav}
          navigateTo={'review'}

          video={video}
          newVideoMark={newVideoMark}

          loading={loading}
          loadingReviews={loadingReviews}
          loadingVideo={loadingVideo}
        />









        {(!currentUser?.professor || currentCustomer?.setting_professors_can_see_marks || (parseInt(currentUser?.id) === parseInt(video?.professor_id) || video?.extra_professors?.split(',')?.includes(currentUser?.id+'')))?
          <div className="w-full min-w-[400px]">
            <div className='w-full md:mt-[6px] px-3 md:px-10'>
              {!((loadingText === 'Cargando vídeo...')? true : false)?
                <div>
                  <div className={`w-full ${(selectedReview && selectedReview?.filename)? 'min-h-[calc(100vw-750px)]' : null} flex justify-center items-stretch flex-col-reverse lg:flex-row flex-wrap lg:flex-nowrap mb-0 lg:mb-10`}>
                    
                    
                    <div className={`w-full min-w-[300px] xl:min-w-[700px] 2xl:min-w-[900px] mr-0 lg:mr-10 mt-5 mb-8 lg:mb-0 max-w-[980px]`}>
                      <VideoMarks 
                        video={video} 
                        setVideo={setVideo} 
                        getVideo={getVideo} 

                        newVideoMark={newVideoMark} 
                        setNewVideoMark={setNewVideoMark} 
                        droppedFiles={droppedFiles} 
                        setDroppedFiles={setDroppedFiles} 

                        createMark={createMark} 
                        selectedReview={reviews && reviews[tab]? reviews[tab] : null} 
                        reviews={reviews} 
                        markStateFilter={markStateFilter} 
                        reviewFilter={reviewFilter}
                        mode={mode}
                        disableModeNav={disableModeNav}
                        setDisableModeNav={setDisableModeNav}
                        professors={professors}

                        videoMarks={videoMarks}
                        getVideoMarks={getVideoMarks}
                        loadingMarks={loading}

                        billConcepts={billConcepts}
                        setBillConcepts={setBillConcepts}
                        getBillConceptsFromVideo={getBillConceptsFromVideo}
                        loadingBillConcepts={loadingBillConcepts}
                      />
                    </div>


                    <div
                      className={`w-full flex flex-col max-h-[calc(100vh-200px)] min-w-[200px] lg:mb-0 xl:max-w-[550px] min-[1800px]:max-w-[700px] 
                        ${(selectedReview?.filename)? 'md:mt-9' : ''} 
                        ${(windowWidth > 1023)? 'super-stiky' : null}
                      `}
                    >
                      <div>
                        {(video && selectedReview?.filename)? 
                          <>
                            {(selectedReview?.filename?.includes('mp3')) && 
                              <div className='relative z-10 w-full mb-[-31px] h-[66px] px-[5px] pt-[5px] bg-gray-700 rounded-t-lg'>
                                <Tooltip disableInteractive title={(mode === 'review' && (!video?.is_translation_of || !currentUser?.rol?.includes('user')))? 'Crear una nueva marca' : ''} placement="top" arrow>
                                  <div
                                    className={`w-full h-[50px] rounded-lg overflow-hidden opacity-90 bg-black duration-200 ${(mode === 'review' && (!video?.is_translation_of || !currentUser?.rol?.includes('user')))? 'cursor-pointer hover:opacity-50' : ''}`}
                                    onClick={(event)=> { if (mode === 'review' && (!video?.is_translation_of || !currentUser?.rol?.includes('user'))) { createMark(null) } }}
                                  >
                                    <AudioViz
                                      model={models.horizontal({
                                        darkMode: true,
                                        reversed: false,
                                        fadeBars: false,
                                        scale: 1,
                                        color: '#60a5fa',
                                        binSize: 1,
                                        frequencyRange: [ 0, 16000 ] /* 0, 16000 */
                                      })}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            }
                            

                            <VideoPlayer 
                              customerToken={currentCustomer?.token}
                              videoObj={video}
                              selectedReview={selectedReview}
                              reviews={reviews}
                              thumbnail={(video?.thumbnail)? (customerPath + 'video_thumbnails/' + video?.thumbnail) : null} 
                              
                              complex={true}
                              newVideoMark={newVideoMark}
                              createMark={createMark}
                              reloadThumbnails={reloadThumbnails}
                              mode={mode}
                              marks={videoMarks}
                              showMarksOnScreen={showMarksOnScreen}

                              init={init}
                              audioRef={audioRef}
                            />
                          </> 
                        : (video && !reviews?.length && !selectedReview)?
                          <div className='flex justify-between w-full mt-[36px] p-[5px] bg-gray-700 rounded-lg'>
                            <Tooltip disableInteractive title={(mode === 'review' && !currentUser?.rol?.includes('translator') && currentUser?.rol !== 'facturator' && (!video?.is_translation_of || (currentUser?.rol !== 'user' && currentUser?.rol !== 'super_user')))? 'Crear una nueva marca sin coordenadas' : ''} placement="bottom" arrow>
                              <div>
                                <button
                                  className={`w-full text-start rounded-lg overflow-hidden bg-gray-700 duration-200 ${(mode === 'review' && !currentUser?.rol?.includes('translator') && currentUser?.rol !== 'facturator')? 'hover:bg-gray-800 active:bg-gray-600' : ''}`}
                                  onClick={(event)=> { if (mode === 'review' && !currentUser?.rol?.includes('translator') && currentUser?.rol !== 'facturator') { createMark(null) } }}
                                  disabled={(mode !== 'review' || currentUser?.rol?.includes('translator') || currentUser?.rol === 'facturator')? true : false}
                                >
                                  <p className='text-gray-100 px-3 py-2'>Sin contenido multimedia</p>
                                </button>
                              </div>
                            </Tooltip>

                            {(currentUser?.rol === 'admin') && 
                              <Tooltip disableInteractive title="Subir nueva revisión" placement="bottom" arrow>
                                <div className={`h-10 w-10 flex items-center text-white justify-center ml-[6px] bg-gray-800 hover:bg-gray-900 active:bg-gray-600 cursor-pointer rounded-md duration-300`}>
                                  <IconButton className='text-white' aria-label="upload picture" component="label">
                                    <input id="videoFile" hidden accept="video/*" type="file" onChange={(e) => setNewReviewFile(e.target.files[0])}/>
                                    <AddIcon className='text-white scale-75'/>
                                  </IconButton>
                                </div>
                              </Tooltip>
                            }
                          </div>
                        :
                          <div className='flex items-center space-x-2 w-full mt-[36px] px-3 py-2 bg-gray-700 rounded-lg text-gray-100'>
                            {loadingIcon}
                            <p>Cargando...</p>
                          </div>
                        }
                      </div>




                      <VideoOptionsPanel 
                        mode={mode}
                        video={video} 
                        setVideo={setVideo}
                        reviews={reviews} 
                        users={dataUsers?.users}
                        selectedReview={selectedReview} 
                        tab={tab}
                        setTab={setTab}
                        stateCounter={stateCounter}
                        clicksoundUserNames={clicksoundUserNames?.getClicksoundUserNames}
                        reviewLogs={reviewLogs}
                        setReviewLogs={setReviewLogs}
                        userFromSelectedReview={userFromSelectedReview}
                        droppedFiles={droppedFiles} 
                        setDroppedFiles={setDroppedFiles} 

                        loading={(loadingText === 'Cargando vídeo...')? true : false}
                        loadingNewVideo={loadingNewVideo} 
                        setLoadingNewVideo={setLoadingNewVideo} 

                        thumbTime={thumbTime}
                        setThumbTime={setThumbTime}
                        selectedThumbnail={selectedThumbnail}
                        setSelectedThumbnail={setSelectedThumbnail}

                        markStateFilter={markStateFilter}
                        setMarkStateFilter={setMarkStateFilter}
                        
                        newReviewFile={newReviewFile}
                        setNewReviewFile={setNewReviewFile}

                        predefThumbnails={predefThumbnails}
                        setThumbFirstime={setThumbFirstime}
                        reviewFilter={reviewFilter}
                        setReviewFilter={setReviewFilter}
                        showMarksOnScreen={showMarksOnScreen}
                        setShowMarksOnScreen={setShowMarksOnScreen}

                        getVideo={getVideo}
                        getVideoReviews={getVideoReviews}

                        windowHeight={windowHeight} 
                      />
                    </div>



                  </div>
                </div>
              : null }
            </div>
          </div>
        :
          (currentUser?.professor && video) && 
            <div className={`w-screen h-[calc(100vh-150px)] min-w-[400px] flex justify-center items-center`}>


              <div className='p-5 text-gray-400'>
                <div className='w-full flex justify-center scale-[3] mb-20 text-gray-400'>{notVisibleIcon}</div>
                <h1 className='w-full text-center text-4xl text-gray-500'>No eres participe en este tema.</h1>
                <p className='w-full text-center max-w-[600px]'>No puedes visualizar este vídeo porque no figuras como participante.</p>


                <button
                  className={`w-full h-[16px] flex justify-center items-center mt-8 font-bold text-gray-400 hover:text-blue-500 cursor-pointer duration-300 disabled:opacity-50 disabled:pointer-events-none ${currentUser?.rol === 'admin'? '' /* 'invisible' */ : ''}`}
                  onMouseUp={(event)=> navigateOrOpenFunc(event, "/videos") }
                >
                  <div className='flex items-center mr-2'>
                    {leftArrowIcon}
                    <div style={{ marginTop: -4 }}>{homeIcon}</div>
                  </div>
                  <p>Volver a todos los vídeos</p>
                </button>
              </div>


            </div>
        }
      

      </div>
    : ((!selectedReview || selectedReview?.filename) && !fromAnotherModule && !baseLoading && !(loadingText === 'Cargando vídeo...'))?

      <div className="px-3 sm:px-10 min-w-[400px] mt-3">
        <h1 className='font-bold text-4xl pt-9 pb-8'>Error al cargar el vídeo</h1>
        <hr className='border-t-2 border-gray-200'></hr>
        <p className='text-gray-400 px-1 py-4'>Es probable que la última revisión de este vídeo se esté subiendo, prueba a recargar la página en unos segundos y en caso de no ver ningún cambio, contacte con ClickSound para resubir este vídeo.</p>
      </div> 
    
    : (video && video?.customer_token !== currentCustomer?.token)?

      <div className="px-3 sm:px-10 min-w-[400px] mt-3">
        <h1 className='font-bold text-4xl pt-9 pb-8'>Cliente incorrecto {video?.customer_token} - {currentCustomer?.token}</h1>
        <hr className='border-t-2 border-gray-200'></hr>
        <p className='text-gray-400 px-1 py-4'>Es probable que hayas escogido el cliente equivocado o que el acceso a este tema no se te sea permitido.</p>
      </div>

    : (fromAnotherModule)?

      <div className="px-3 sm:px-10 min-w-[400px] mt-3">
        <h1 className='font-bold text-4xl pt-9 pb-8'>El vídeo que buscas se encuentra en otro {currentCustomer?.label_module?.toLowerCase()}</h1>
        <hr className='border-t-2 border-gray-200'></hr>

        <div className='flex items-center space-x-1 text-gray-400'>
          <p className='px-1 py-4'>
            Es posible que el vídeo "{id}" haya sido movido de {currentCustomer?.label_module?.toLowerCase()}, para visualizarlo deberá visitar el enlace correcto.
          </p>
        </div>

        <div className='flex text-sm'>
          <a 
            href={"/" + currentCustomer?.abbreviated_name + "/" + ((fromAnotherModule?.search_module_abbreviated_name)? fromAnotherModule?.search_module_abbreviated_name : currentCustomer?.label_extras_module_url) + "/" + fromAnotherModule?.url_name} 
            className='flex items-center space-x-2 font-bold px-2 py-1 bg-gray-100 border border-gray-300 hover:bg-blue-500 hover:text-white rounded'
          >
            <p>Ir al vídeo dentro del {currentCustomer?.label_module?.toLowerCase()} "{(fromAnotherModule?.label_extras_module)? fromAnotherModule?.label_extras_module : currentCustomer?.label_extras_module}"</p>
            <p className='rotate-180'>{leftArrowIcon}</p>
          </a> 
        </div>
      </div>
      
    : (currentCustomer && !(loadingText === 'Cargando vídeo...') && !loading && !baseLoading) &&
    
      <div className="px-3 sm:px-10 min-w-[400px] mt-3">
        <h1 className='font-bold text-4xl pt-9 pb-8'>No se ha encontrado el vídeo</h1>
        <hr className='border-t-2 border-gray-200'></hr>
        <p className='text-gray-400 px-1 py-4'>No se ha encontrado ningún vídeo con el nombre de "{id}" dentro de {currentCustomer?.name}.</p>
      </div>
    
  )
}

export default VideoReviews
