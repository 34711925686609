import gql from "graphql-tag";
export default gql`
  query GetAllMarkTypes($own_customer_token: String!) {
    videoMarkTypes(own_customer_token: $own_customer_token) {

      id
      name
      thumbnail
      color
      position
      multiple
      type
      default_difficulty
      customer_token
      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
