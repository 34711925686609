import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';

import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';


const columns = [
  { id: 'professor_code', label: 'Código', filterUp: 'Code ASC', filterDown: 'Code DESC', width: 100 },
  { id: 'name', label: 'Nombre', filterUp: 'Name ASC', filterDown: 'Name DESC' },
  { id: 'vinculated_to_id', label: 'Vinculado' },
  /* { id: 'email', label: 'Email', filterUp: 'Email ASC', filterDown: 'Email DESC' }, */
  { id: 'treatment', label: 'Trato', filterUp: 'Name ASC', filterDown: 'Name DESC' },
  { id: 'from_customer', label: 'Cliente', filterUp: 'FromCustomer ASC', filterDown: 'FromCustomer DESC' },
  { id: 'customer_free', label: 'Permisos de navegación', filterUp: 'Navigation ASC', filterDown: 'Navigation DESC' },
  { id: 'createdAtFormated',label: 'Creado el', align: 'right', filterUp: 'CreatedAt ASC', filterDown: 'CreatedAt DESC' },
];






const Professors = () => {




  const { currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)

  

  const [professors, setProfessors] = useState([]);
  const [search, setSearch] = useState('');
  const [orderFilter, setOrderFilter] = useState('CreatedAt DESC');






  const { loading, data: dataProfessors } = useQuery(GetAllProfessorsByCustomer, { 
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'no-cache' 
  })


  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;





  useEffect(() => {    
    setCurrentNavActive('professors')
  }, [])


  useEffect(() => {    
    if (dataProfessors?.professorsByCustomer) {
      setProfessors(dataProfessors.professorsByCustomer)
    } else {
      setProfessors([])
    }
  }, [dataProfessors])


  useEffect(() => {    
    if (dataProfessors?.professorsByCustomer?.length > 0) {

      var professorsTMP = [...dataProfessors.professorsByCustomer];
      var entred = false;
      
      /* Search */
      if (search && search.replaceAll(' ', '') !=='') {
          entred = true;
          professorsTMP = professorsTMP.filter(professor => {
            var nameFound = professor?.name?.toLowerCase().includes(search.toLowerCase());
            var codeFound = professor?.professor_code?.toLowerCase().includes(search.toLowerCase());
            var emailFound = professor?.email?.toLowerCase().includes(search.toLowerCase());
            return (nameFound || codeFound || emailFound)
          })
      }


      /* Order filter */
      if (orderFilter && orderFilter !== 'null') {
        entred = true;

        if (orderFilter.includes('Name')) { /* Text */
          professorsTMP = professorsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.name > a.name)? -1 : (b.name < a.name)? 1 : 0) : ((a.name > b.name)? -1 : (a.name < b.name)? 1 : 0) })
        } else if (orderFilter.includes('Email ')) { /* Text */
          professorsTMP = professorsTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b.email > a.email)? -1 : (b.email < a.email)? 1 : 0) : ((a.email > b.email)? -1 : (a.email < b.email)? 1 : 0) })
        }  else if (orderFilter.includes('Code')) { /* Text */
          professorsTMP = professorsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? ((b.professor_code > a.professor_code)? -1 : (b.professor_code < a.professor_code)? 1 : 0) : ((a.professor_code > b.professor_code)? -1 : (a.professor_code < b.professor_code)? 1 : 0) })
        } else if (orderFilter.includes('FromCustomer ')) { /* Text */
          professorsTMP = professorsTMP.sort(function(a,b){ return orderFilter.includes(' ASC')? ((b?.from_customer?.name > a?.from_customer?.name)? -1 : (b?.from_customer?.name < a?.from_customer?.name)? 1 : 0) : ((a?.from_customer?.name > b?.from_customer?.name)? -1 : (a?.from_customer?.name < b?.from_customer?.name)? 1 : 0) })
        } else if (orderFilter.includes('Navigation ')) { /* Text */
          professorsTMP = professorsTMP.sort(function(a,b){ 
            var aCustomerName = (a.customer_free)? 'Libre / Sin cliente' : a?.customer_permission?.name;
            var bCustomerName = (b.customer_free)? 'Libre / Sin cliente' : b?.customer_permission?.name;
            return orderFilter.includes(' ASC')? ((bCustomerName > aCustomerName)? -1 : (bCustomerName < aCustomerName)? 1 : 0) : ((aCustomerName > bCustomerName)? -1 : (aCustomerName < bCustomerName)? 1 : 0)
          })
        } else if (orderFilter.includes('CreatedAt')) { /* Date */
          professorsTMP = professorsTMP.sort(function(a,b){ return orderFilter.includes('ASC')? (new Date(b.createdAt) - new Date(a.createdAt)) : (new Date(a.createdAt) - new Date(b.createdAt)); })
        }
      }


      if (!entred) { resetProfessors() } else {setProfessors(professorsTMP)}
    } else { resetProfessors() }

  }, [orderFilter, search, dataProfessors])




  function resetProfessors() {
    if (dataProfessors?.professorsByCustomer) {
      setProfessors(dataProfessors.professorsByCustomer)
    } else {
      setProfessors(null)
    }
  }




  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <div className='flex flex-wrap py-14 pl-2'>
          <div className='flex flex-wrap sm:flex-nowrap items-center'>
            <h1 className='font-bold text-4xl'>{currentCustomer?.label_professors?.toLowerCase()}</h1>
            <p className='text-gray-400 mt-0 sm:mt-2 ml-2 text-xs sm:text-base'>{(professors)? professors?.length + ' resultados encontrados.' : ''}</p>
          </div>
        </div>

        <Tooltip disableInteractive title={`Crear un nuevo ${currentCustomer?.label_professor?.toLowerCase()}`} placement="bottom" arrow>
          <IconButton 
            color="primary" 
            size="medium"
            onMouseUp={(event)=> navigateOrOpenFunc(event, "/professor/new") }
          >
            {createItemIcon}
          </IconButton>
        </Tooltip>
      </div>


      <div className='mb-4 flex items-center space-x-2'>
        <div className="search-box h-[33px] flex items-center">
          <IconButton
            type="button"
            sx={{ p: '4px', borderRadius: 0 }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>

          <InputBase
            id="search-input"
            className='w-full'
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Busca por nombre del ${currentCustomer?.label_professor?.toLowerCase()} o por su código`}
            value={search}
            onChange={(element)=>setSearch(element.target.value)}
          />
        </div>
      </div>


      <div className='w-full'>
        {(loading)?
          <div className='w-full text-sm bg-gray-100 border border-gray-300 p-2 rounded-md text-gray-500 mb-4'> 
            <p className='mb-1'>{(professors)? 'Actualizando' : 'Cargando'} contenidos...</p>
            <LinearProgress className='rounded-full'/>
          </div>
        : null}
        {(!loading && professors?.length === 0)? <p className='text-sm text-gray-400 px-1'>No se han encontrado {currentCustomer?.label_professors?.toLowerCase()} dentro de {currentCustomer?.name}</p>  : null}
      </div>

    
      {(professors && professors?.length)?
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0, backgroundColor: 'rgb(245, 245, 245)', marginBottom: 5 }} className='border border-gray-400 border-opacity-60'>
          <TableContainer sx={{ maxHeight: 'calc(100vh - 310px)', }}>
            <Table stickyHeader aria-label="sticky table">

              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={'columns-'+column.id}
                      className='select-none'
                      align={column.align}
                      style={{ minWidth: column.minWidth, width: column.width, backgroundColor: 'rgb(243 244 246)' }}
                      onClick={()=>{
                        if (column?.filterUp === orderFilter) {
                          if (column?.filterDown) {
                            setOrderFilter(column?.filterDown)
                          } else {
                            setOrderFilter(null)
                          }
                        } else if (column?.filterDown === orderFilter) {
                          setOrderFilter(null)
                        } else if (column?.filterUp) {
                          if (column?.filterUp) {
                            setOrderFilter(column?.filterUp)
                          } else {
                            setOrderFilter(null)
                          }
                        }
                      }}
                    >
                      <div className='flex' style={{ justifyContent: (column.align === 'right')? 'flex-end' : 'flex-start'}}>
                        <div> {column.label} </div>
                        {(column?.filterUp === orderFilter) && upArrowIcon}
                        {(column?.filterDown === orderFilter) && downArrowIcon}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {professors?.map((row) => {
                  return (
                    <TableRow 
                      key={'professors-'+row.id} 
                      hover 
                      role="checkbox" 
                      tabIndex={-1} 
                      className="hover:bg-gray-100 duration-200 cursor-pointer"
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/professor/"+row.id) }
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={'column-data-'+column.id} align={column.align} style={{borderBottom: 0}}>
                            {
                              (column.id === 'professor_code')? ((value)? value : 'Sin código')
                              : (column.id === 'vinculated_to_id')? ((!value)? 'No' : 'Sí')
                              : (column.id === 'email')? ((!value)? '---' : value)
                              : (column.id === 'treatment')? 
                                (
                                  (value === 1)? `Doctor/a`
                                  : (value === 2)? currentCustomer?.label_professor
                                  : (value === 3)? `Sr/a`
                                  : `Ponente`
                                )
                              : (column.id === 'customer_free')? ((value)? 'Libre / Sin cliente' : row?.customer_permission?.abbreviated_name)
                              : (column.id === 'from_customer')? ((!value)? 'Otro' : row?.from_customer?.abbreviated_name)
                              : value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </TableContainer>
        </Paper>
      : null}


    </div>
  )
}

export default Professors
