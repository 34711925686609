import { useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom';

import MarkActionListItemMenu from './MarkActionListItemMenu';

import CreateOrUpdateVideoMark from './../graphql/mutations/CreateOrUpdateVideoMark'








export default function MarkActionButtons({

  mark,
  video,
  motionState,
  simplified,
  guest_data,

  defTradLang,
  setSelectedLanguage,

  setIsEditing,
  setIsCommenting,
  setIsTranslating,
  setIsCorrecting,
  setIsCommentCorrecting,

  goToMinute,
  getVideoMarks,

}) {
  


  const { path_video_token } = useParams()
  const { currentUser } = useContext(AuthContext)
  const showMenu = (simplified !== 2 && mark?.type_id && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator'))? true : false

  const coment_guest_data = (mark?.search_guest_data)? mark?.search_guest_data?.split(',') : null
  const own_guest_data = (guest_data)? guest_data?.split(',') : null
  const same_guest = (path_video_token && coment_guest_data?.length && own_guest_data?.length && parseInt(coment_guest_data[0]) && parseInt(own_guest_data[0]) && parseInt(coment_guest_data[0]) === parseInt(own_guest_data[0]))? true : false



  const [anchorEl, setAnchorEl] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);



  const dotsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M263.788-408Q234-408 213-429.212q-21-21.213-21-51Q192-510 213.212-531q21.213-21 51-21Q294-552 315-530.788q21 21.213 21 51Q336-450 314.788-429q-21.213 21-51 21Zm216 0Q450-408 429-429.212q-21-21.213-21-51Q408-510 429.212-531q21.213-21 51-21Q510-552 531-530.788q21 21.213 21 51Q552-450 530.788-429q-21.213 21-51 21Zm216 0Q666-408 645-429.212q-21-21.213-21-51Q624-510 645.212-531q21.213-21 51-21Q726-552 747-530.788q21 21.213 21 51Q768-450 746.788-429q-21.213 21-51 21Z"/></svg>
  const replyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M798 856q-17 0-29.5-12.5T756 814V699q0-49-34.5-83.5T638 581H280l113 113q12 12 12 28.5T392 752q-12 12-29.5 12T333 752L150 569q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l184-184q12-12 29-12.5t30 12.5q12 12 12 29.5T393 384L280 497h358q84 0 143 59t59 143v115q0 17-12.5 29.5T798 856Z"/></svg>;
  const pencilIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49ZM162 936q-17 0-29.5-12.5T120 894V774q0-8 3-15.5t9-13.5l407-407 179 179-407 407q-6 6-13.5 9t-15.5 3H162Z"/></svg>;
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;
  const goBackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M323 856q-17 0-29.5-12.5T281 814q0-17 12.5-29.5T323 772h239q62 0 108-38t46-97q0-59-46-97.5T562 501H320l73 73q12 12 11.5 29T392 632q-12 12-29.5 12T333 632L190 489q-7-7-10-14.5t-3-15.5q0-8 3-15.5t10-14.5l144-144q12-12 29.5-12t29.5 12q12 12 12 29.5T393 344l-73 73h242q97 0 167.5 62.5T800 636q0 94-70.5 157T562 856H323Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const insertionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M444.25-444v72q0 15.3 10.38 25.65Q465-336 480.23-336q15.24 0 25.54-10.35 10.31-10.35 10.31-25.65v-72h71.69q15.23 0 25.73-10.29t10.5-25.5q0-15.21-10.35-25.58-10.35-10.38-25.65-10.38h-72v-72.17q0-15.33-10.35-25.71Q495.3-624 480-624q-15.3 0-25.65 10.37Q444-603.25 444-587.92v72.17h-72q-15.3 0-25.65 10.38Q336-495 336-480q0 15.3 10.37 25.65Q356.75-444 372.08-444h72.17ZM480-96q-16 0-28-5t-23-16L117-429q-11-11-16-23t-5-28q0-16 5-28t15.77-22.73L429-843q11-11 23-16t28-5q16 0 28 5t23 16l312 312q11 11 16 23t5 28q0 16-5 28t-16 23L530.8-116.73Q520-106 508-101t-28 5Zm0-72 312-312-312-312-312 312 312 312Zm0-312Z"/></svg>

  const dollarOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M507-144q-15 0-26-11t-11-28v-41q-42-11-75-36t-50-61q-7-14-1-29t22-21q15-5 30-.5t23 18.5q17 28 41.5 43.5T512-294q38 0 64-21t26-51q0-30-21.5-49T503-454q-85-30-119-63t-34-87q0-49 33.5-86.5T470-737v-40q0-17 10.5-28t25.5-11q15 0 25.5 11t10.5 28v40q33 5 60 21.5t44 42.5q9 14 4.5 29T631-623q-14 5-29.5 0T573-642q-11-13-27.5-19.5T509-668q-35 0-58 18t-23 44q0 26 27.5 46t93.5 42q69 23 100 59t31 93q0 54-39 94.5T542-221v38q0 17-10.5 28T507-144Z"/></svg>
  const dollarOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M444-737v-43q0-15 10.5-25.5T480-816q15 0 25.5 10.5T516-780v42q29 4 53.5 18.5T612-683q10 12 8.5 27.5T607-630q-12 10-28 8t-26-14q-13-16-31.5-24t-38.5-8q-11 0-22 3t-22 8q-14 7-29 1.5T388-675q-7-14-1-29t20-22q9-4 18-6.5t19-4.5Zm299 620L602-257q-12 12-36.5 22T516-222v42q0 15-10.5 25.5T480-144q-15 0-25.5-10.5T444-180v-44q-43-10-74.5-34T319-321q-8-14-1-29t22-22q15-7 30-.5t23 20.5q16 29 40 43.5t54 14.5q17 0 32-4.5t28-12.5L115-744q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-117Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  const [disableVideoMark] = useMutation(CreateOrUpdateVideoMark)



  
  async function disableMark(id, disableFacturation) {
    if (currentUser) {
      setLoadingDisable(disableFacturation)
      
      if (disableFacturation === 3) {
        
        await disableVideoMark({ variables: {
          own_user_token: currentUser?.token, 
          
          id: (mark?.id)? parseInt(mark.id) : null, 
          facturation_force_insertion: (mark?.facturation_disabled || mark?.facturation_force_insertion)? false : true, 
        }})

      } else if (disableFacturation === 2) {
        
        await disableVideoMark({ variables: {
          own_user_token: currentUser?.token, 
          
          id: (mark?.id)? parseInt(mark.id) : null, 
          facturation_disabled: (mark?.facturation_disabled)? false : true, 
          facturation_disabled_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
          facturation_force_insertion: (mark?.facturation_disabled)? false : (mark?.facturation_force_insertion)? true : false, 
        }})

      } else {

        await disableVideoMark({ variables: {
          own_user_token: currentUser?.token, 
          
          id: (mark?.id)? parseInt(mark.id) : null, 
          disabled: (mark?.disabled)? false : true, 
          disabled_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
        }})

      }
    
      await getVideoMarks();
  
      setTimeout(() => {
        setIsEditing(false)
        setIsCorrecting(false);
        setIsCommentCorrecting(false)
        setIsTranslating(false);
        setLoadingDisable(false)
      }, "300")
    }
  }








  function joinButtonsFunc(inMenu) {
    return <>
      <div className={`w-full ${(!showMenu)? 'flex space-x-1' : (inMenu)? 'flex flex-col min-[870px]:hidden' : 'hidden min-[870px]:flex min-[870px]:space-x-1'}`}> {/* ${(inMenu)? 'flex space-y-2 flex-col min-[1520px]:hidden' : 'hidden min-[1520px]:flex min-[1520px]:space-x-1'} */}
        {(!mark?.disabled && (!currentUser?.rol?.includes('user') || !mark?.is_translation_of) && (!currentUser?.rol?.includes('user') || !mark?.type_id || !(mark?.revision_state === 2 || mark?.trad_done || motionState === 2 || motionState > 1))) &&
          <button 
            className={`truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
            onClick={() => setIsCommenting(true)}
          >
            <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`} style={{ transform: 'rotate(180deg)' }}>{replyIcon}</div>
            <p>Responder</p>
          </button>
        }

        {(currentUser?.rol?.includes('translator') && !mark?.disabled && mark?.type_id) &&
          <button 
            className={`truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
            onClick={() => { setIsTranslating(true); setSelectedLanguage(defTradLang) }}
          >
            <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`}>{tradIcon}</div>
            <p>Traducir</p>
          </button>
        }

        {(!mark?.disabled && !mark?.is_translation_of && !video?.is_translation_of && currentUser?.rol?.includes('translator') && (mark?.revision_state === 2 || mark?.trad_done || motionState > 1)) &&
          <button 
            className={`truncate overflow-hidden text-xs flex items-center active:scale-95 duration-200 cursor-pointer rounded
              ${(inMenu)? 'w-full px-[6px] py-1' : 'pl-[4px] pr-[6px] py-[2px]'}
              ${(currentUser?.rol?.includes('user'))? 'text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-400' : 'text-gray-500 hover:text-blue-500 hover:bg-gray-400 hover:bg-opacity-20 '}
            `} 
            onClick={() => setIsCorrecting(true)}
          >
            <div className={`${(inMenu)? 'mr-[6px]' : 'scale-[0.9] mr-[3px]'}`}>{pencilIcon}</div>
            <p>Corregir texto</p>
          </button>
        }

        {(!mark?.disabled && (same_guest || (currentUser && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator' || !mark?.is_translation_of)) && (currentUser && (!currentUser?.rol?.includes('user') || !(mark?.revision_state === 2 || mark?.trad_done || motionState > 1))))) &&
          <button 
            className={`truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[2px] pr-[6px] py-[2px]'}`} 
            onClick={() => setIsEditing(true)}
          >
            <div className={`${(inMenu)? 'mr-[6px]' : 'scale-[0.9] mr-[3px]'}`}>{pencilIcon}</div>
            <p>Editar {(currentUser?.rol?.includes('user') && parseInt(mark?.user_id) !== parseInt(currentUser?.id))? ' marca de ' + mark?.user?.name : ''}</p>
          </button>
        }
      </div>



      {(mark?.type_id)?
        <div className={`w-full ${(!showMenu)? 'flex space-x-1' : (inMenu)? 'flex flex-col' : 'hidden'}`}>
          {((currentUser?.rol === 'admin') && !mark?.disabled) &&
            <button 
              className={`truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 active:scale-95 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
              onClick={() => { setIsTranslating(true); setSelectedLanguage(defTradLang) }}
            >
              <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`}>{tradIcon}</div>
              <p>Traducir</p>
            </button>
          }

          {(!mark?.disabled && !mark?.is_translation_of && !video?.is_translation_of && (currentUser?.rol?.includes('user') || currentUser?.rol === 'admin') && (mark?.revision_state === 2 || mark?.trad_done || motionState > 1)) && 
            <button 
              className={`truncate overflow-hidden text-xs flex items-center active:scale-95 duration-200 cursor-pointer rounded
                ${(inMenu)? 'w-full px-[6px] py-1' : 'pl-[4px] pr-[6px] py-[2px]'}
                ${(currentUser?.rol?.includes('user'))? 'text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-400' : 'text-gray-500 hover:text-blue-500 hover:bg-gray-400 hover:bg-opacity-20 '}
              `} 
              onClick={() => setIsCorrecting(true)}
            >
              <div className={`${(inMenu)? 'mr-[6px]' : 'scale-[0.9] mr-[3px]'}`}>{pencilIcon}</div>
              <p>Corregir texto</p>
            </button>
          }

          {(!mark?.disabled && ((currentUser?.rol?.includes('user') || currentUser?.rol === 'admin') && (mark?.revision_state === 2 || mark?.trad_done || motionState > 1))) &&
            <button 
              className={`truncate overflow-hidden text-xs flex items-center active:scale-95 duration-200 cursor-pointer rounded
                ${(inMenu)? 'w-full px-[6px] py-1' : 'pl-[4px] pr-[6px] py-[2px]'}
                ${(currentUser?.rol?.includes('user'))? 'text-white bg-blue-500 hover:bg-blue-700 active:bg-blue-400' : 'text-gray-500 hover:text-blue-500 hover:bg-gray-400 hover:bg-opacity-20 '}
              `} 
              onClick={() => setIsCommentCorrecting(true)}
            >
              <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`} style={{ transform: 'rotate(180deg)' }}>{replyIcon}</div>
              <p>{(inMenu)? 'Pedir corrección' : 'Pedir otro tipo de corrección'}</p>
            </button>
          }


          {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') &&
            <button 
              className={`truncate overflow-hidden text-gray-500 text-xs flex items-center active:scale-95 hover:text-blue-500 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
              onClick={() => disableMark(mark?.id, 1)}
            >
              <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`}>{(loadingDisable === 1)? loadingIcon : (mark?.disabled)? goBackIcon : cancelIcon}</div>
              <p>{(mark?.disabled)? 'Rehabilitar' : 'Descartar'}</p>
            </button>
          }


          {(mark?.type_id && mark?.is_translation_of && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator')) && <>
            <button 
              className={`truncate overflow-hidden text-gray-500 text-xs flex items-center active:scale-95 hover:text-blue-500 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
              onClick={() => disableMark(mark?.id, 2)}
            >
              <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`}>{(loadingDisable === 2)? loadingIcon : (mark?.facturation_disabled)? dollarOffIcon : dollarOnIcon}</div>
              <p>{(mark?.facturation_disabled)? 'No facturable' : 'Facturable'}</p>
            </button>

            {(!mark?.facturation_disabled) &&
              <button 
                className={`truncate overflow-hidden text-gray-500 text-xs flex items-center active:scale-95 hover:text-blue-500 duration-200 cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 rounded ${(inMenu)? 'w-full px-[6px] py-1 rounded' : 'pl-[4px] pr-[6px] py-[2px]'}`} 
                onClick={() => disableMark(mark?.id, 3)}
              >
                <div className={`${(inMenu)? 'mr-[6px]' : 'mr-[3px]'}`}>{(loadingDisable === 3)? loadingIcon : (mark?.facturation_force_insertion)? insertionIcon : dollarOnIcon}</div>
                <p>{(mark?.facturation_force_insertion)? 'Facturar insercion' : 'Facturación normal'}</p>
              </button>
            }
          </>}
        </div>
      : null }
    </>
  }





  return <div onClick={()=>goToMinute()}>


    {(showMenu)? <>
      <MarkActionListItemMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl} 
        items={joinButtonsFunc(true)}
      />
    </> : null }


    <div className={`flex space-x-1`}>
      {(showMenu)?
        <button
          onClick={(event) => setAnchorEl(event.currentTarget) }
          className={`text-gray-500 hover:bg-gray-400 hover:bg-opacity-30 active:bg-gray-500 active:bg-opacity-30 duration-200 rounded-md px-1 py-[2px] 
            ${(anchorEl)? 'bg-gray-500 bg-opacity-30' : ''}
            ${(!mark?.type_id)? 'block min-[870px]:hidden' : ''}
          `}
        >
          {dotsIcon}
        </button>
      : null }

      <div>
        {joinButtonsFunc(false)}
      </div>
    </div>


  </div>
}