import { useState, useEffect, useContext } from 'react'


import Menu from '@mui/material/Menu';



export default function MarkActionListItemMenu({anchorEl, setAnchorEl, items}) {




  const [lastAnchorEl, setLastAnchorEl] = useState(null);






  useEffect( () => { 
    if (anchorEl) {
      setLastAnchorEl(anchorEl)
    }
  }, [anchorEl])


  
  return (
    <>
      <Menu 
        anchorEl={anchorEl}
        open={(anchorEl)? true : false}
        onClose={()=>setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { minWidth: lastAnchorEl && lastAnchorEl.offsetWidth }
        }}
        sx={{ marginTop: '4px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='flex flex-wrap p-1 max-w-[180px]'>

          {items}

        </div>
      </Menu>
    </>
  );
}