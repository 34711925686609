
export default function RevisedAndTranslated({ ogVideo, small, hiddeDates, fill, localization }) {


  const revisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l84 85q11 11 25.5 11t25.5-11l170-170q11-11 11-25t-11-25q-11-11-25.5-11T582-585L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-23 12.5-41t33.5-26l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26t12.5 41v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Z"/></svg>
  const nonRevisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-10.636-11-24.818-11Q339-512 328-501q-11 11-11 25.667 0 14.666 11 25.333l84 85q10.909 11 25.455 11Q452-354 463-365l170-170q11-11 11-25t-11-25q-11-11-25.667-11-14.666 0-25.333 11L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-22.692 12.5-40.846Q193-754 214-762l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26.154T792-695v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Zm0-71q104-32.25 172-129t68-215v-180l-240-92-240 92v180q0 118.25 68 215t172 129Zm0-308Z"/></svg>
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;


  const video = ogVideo;
  const label = (
    (localization)? ((video?.search_translated_label)? video?.search_translated_label : null) 
    : (video?.search_reviewed_label)? video?.search_reviewed_label
    : null
  );


  /* const name = (label)? ((label?.includes(' / '))? label?.split(' el ')[0]?.split(' / ')[1] : label?.split(' el ')[0]) : null; */
  const small_name = (label)? ((label?.includes(' / '))? label?.split(' el ')[0]?.split(' / ')[0] : label?.split(' el ')[0]) : null;
  var date = (label)? label?.split(' el ')[1]?.replaceAll(' a las ', ' ')?.split('/') : null;
  if (date?.length) { date[2] = date[2].substring(2); date = date?.join('/') }


  return (video)? <>
    <div className={`relative whitespace-nowrap justify-center rounded ${(hiddeDates)? 'flex items-center bg-gray-400 bg-opacity-[0.25]' : 'flex-col items-end'} ${(fill)? 'w-full h-full' : ''} ${(small)? 'pr-1 pl-[2px] text-[12px] bg-gray-400 bg-opacity-[0.25]' : `p-1 px-[6px] text-[14px]`} `}>
      <div className={`flex justify-center items-center space-x-[4px]`}>
        {
          (localization)? 
            <div className={`${(video?.search_translated)? 'text-blue-500' : 'text-gray-400'}`}>{tradIcon}</div>
          : (
            (video?.search_reviewed)? <div className={`text-blue-500`}>{revisedIcon}</div>
            : <div className={`text-gray-400`}>{nonRevisedIcon}</div>
          )
        }
        
        {(small_name && ((localization && video?.search_translated) || video?.search_reviewed))? <p className='text-[11px] font-bold'>{small_name}</p> : null}
      </div>

      {(!small && !hiddeDates && date && ((localization && video?.search_translated) || video?.search_reviewed))? 
        <div className='h-[10px] pt-1 opacity-70 flex justify-center items-center'>
            <p className='text-[10px] font-bold'>{date}</p>
        </div>
      : null }
    </div>
  </> : <p className='text-gray-500 text-[11px]'>---</p> ;
}