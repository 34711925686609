import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext'
import { LayoutContext } from '../contexts/LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client'
import axios from 'axios'
import Resizer from "react-image-file-resizer";

import CreateOrUpdateCustomer from './../graphql/mutations/CreateOrUpdateCustomer'

import CustomSwitch from './Inputs/CustomSwitch';
import CustomTextField from './Inputs/CustomTextField';

import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CustomSelect from './Inputs/CustomSelect';
import { MenuItem } from '@mui/material';










export default function CustomerEditorData({ customer, loading }) {


  const { currentUser } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { fileExists } = useContext(FuncContext)
  const { token } = useParams()
  const navigate = useNavigate();




  const [loadingSave, setLoadingSave] = useState(false);

  /* Naming vars */
  const [icon, setIcon] = useState(null);
  const [abbreviatedName, setAbbreviatedName] = useState('');
  const [name, setName] = useState('');
  const [factura10ClientID, setFactura10ClientID] = useState(null);

  /* Label vars */
  const [selectedLabelModule, setSelectedLabelModule] = useState(0);
  const [labelModule, setLabelModule] = useState('Módulo');
  const [labelModules, setLabelModules] = useState('Módulos');

  const [selectedLabelProfessor, setSelectedLabelProfessor] = useState(0);
  const [labelProfessor, setLabelProfessor] = useState('Participante');
  const [labelProfessors, setLabelProfessors] = useState('Participantes');

  const [selectedLabelExtrasModule, setSelectedLabelExtrasModule] = useState(0);
  const [labelExtrasModule, setLabelExtrasModule] = useState('Otros');
  const [labelExtrasModuleURL, setLabelExtrasModuleURL] = useState('Otros');

  /* Setting vars */
  const [settingLanguageFilter, setSettingLanguageFilter] = useState(true);
  const [settingSexFilter, setSettingSexFilter] = useState(false);
  const [settingProfessorFilter, setSettingProfessorFilter] = useState(true);
  const [settingDeffaultDateFilter, setSettingDeffaultDateFilter] = useState(true);

  const [settingInitialChyrons, setSettingInitialChyrons] = useState(true);
  const [settingRecordingTipology, setSettingRecordingTipology] = useState(false);
  const [settingTitleIsRelevant, setSettingTitleIsRelevant] = useState(false);
  const [settingProfessorColumn, setSettingProfessorColumn] = useState(false);
  const [settingProfessorsCanSeeMarks, setSettingProfessorsCanSeeMarks] = useState(true);
  const [settingProdStateNotification, setSettingProdStateNotification] = useState(true);
  
  
  /* Video icon vars */
  const [iconFile, setIconFile] = useState(null);
  const [iconURLTMP, setIconURLTMP] = useState(null);

  /* Icon vars */
  const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-480ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h276q15.3 0 25.65 10.29Q528-795.42 528-780.21t-10.35 25.71Q507.3-744 492-744H216v528h528v-276q0-15.3 10.29-25.65Q764.58-528 779.79-528t25.71 10.35Q816-507.3 816-492v276q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm48-144h432L552-480 444-336l-72-96-108 144Zm408.23-384h-36.11q-15.35 0-25.74-10.35Q600-692.7 600-708q0-15.3 10.38-25.65Q620.77-744 636.12-744h36.11v-36q0-15.3 10.39-25.65Q693-816 708.21-816q15.21 0 25.5 10.35T744-780v36h35.79q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.35 25.59-10.35 10.39-25.65 10.39h-36v36.11q0 15.35-10.35 25.74Q723.3-600 708-600q-15 0-25.38-10.35-10.39-10.35-10.39-25.65v-36Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  const participantOptions = [
    { label: 'Participante / Participantes', value_singular: 'Participante', value_plural: 'Participantes' },
    { label: 'Profesor / Profesores', value_singular: 'Profesor', value_plural: 'Profesores' },
    { label: 'Profesor/a /  Profesores/as', value_singular: 'Profesor/a', value_plural: 'Profesores/as' },
    { label: 'Leader / Leaders', value_singular: 'Leader', value_plural: 'Leaders' },
    { label: 'Responsable / Responsables', value_singular: 'Responsable', value_plural: 'Responsables' },
    { label: 'Propietario / Propietarios', value_singular: 'Propietario', value_plural: 'Propietarios' },
  ]

  const moduleOptions = [
    { label: 'Módulo / Módulos', value_singular: 'Módulo', value_plural: 'Módulos' },
    { label: 'Apartado / Apartados', value_singular: 'Apartado', value_plural: 'Apartados' },
    { label: 'Directorio principal / Directorios principales', value_singular: 'Directorio principal', value_plural: 'Directorios principales' },
    { label: 'Sección / Secciones', value_singular: 'Sección', value_plural: 'Secciones' },
    { label: 'Tipología / Tipologías', value_singular: 'Tipología', value_plural: 'Tipologías' },
  ]

  const extraModuleOptions = [
    { label: 'Otros', value: 'Otros', value_url: 'otros' },
    { label: 'Extras', value: 'Extras', value_url: 'extras' },
    { label: 'Iniciales', value: 'Iniciales', value_url: 'iniciales' },
    { label: 'Apartados', value: 'Apartados', value_url: 'apartados' },
  ]





  const [createOrUpdateCustomer] = useMutation(CreateOrUpdateCustomer)






  useEffect(() => { 
    if (iconFile) {
      var url = URL.createObjectURL(iconFile);
      setIconURLTMP(url)
    } else {
      setIconURLTMP(null)
    }
  }, [iconFile])


  useEffect(() => { 
    setIcon((customer)? customer?.icon : null)
    setAbbreviatedName((customer)? customer?.abbreviated_name : '')
    setName((customer)? customer?.name : '')
    setFactura10ClientID((customer)? customer?.factura10_client_id : null)


    const selectedLabelModuleTMP = (customer?.label_module)? moduleOptions?.map((el, key) => (el?.value_singular === customer?.label_module)? key : null)?.filter((el) => el !== null) : 0
    setSelectedLabelModule((selectedLabelModuleTMP)? selectedLabelModuleTMP : 0);
    setLabelModule((customer)? customer?.label_module : 'Módulo');
    setLabelModules((customer)? customer?.label_modules : 'Módulos');

    const selectedLabelProfessorTMP = (customer?.label_professor)? participantOptions?.map((el, key) => (el?.value_singular === customer?.label_professor)? key : null)?.filter((el) => el !== null) : 0
    setSelectedLabelProfessor((selectedLabelProfessorTMP)? selectedLabelProfessorTMP : 0);
    setLabelProfessor((customer)? customer?.label_professor : 'Participante');
    setLabelProfessors((customer)? customer?.label_professors : 'Participantes');

    const selectedLabelExtrasModuleTMP = (customer?.label_extras_module)? extraModuleOptions?.map((el, key) => (el?.value === customer?.label_extras_module)? key : null)?.filter((el) => el !== null) : 0
    setSelectedLabelExtrasModule((selectedLabelExtrasModuleTMP)? selectedLabelExtrasModuleTMP : 0);
    setLabelExtrasModule((customer)? customer?.label_extras_module : 'Otros');
    setLabelExtrasModuleURL((customer)? customer?.label_extras_module_url : 'Otros');


    setSettingLanguageFilter((customer)? customer?.setting_language_filter : true);
    setSettingSexFilter((customer)? customer?.setting_sex_filter : false);
    setSettingProfessorFilter((customer)? customer?.setting_professor_filter : true);
    setSettingDeffaultDateFilter((customer)? customer?.setting_deffault_date_filter : true);

    setSettingInitialChyrons((customer)? customer?.setting_initial_chyron : true);
    setSettingRecordingTipology((customer)? customer?.setting_recording_tipo_in_list : false);
    setSettingTitleIsRelevant((customer)? customer?.setting_tittle_is_relevant : false);
    setSettingProfessorColumn((customer)? customer?.setting_professor_col : false);
    setSettingProfessorsCanSeeMarks((customer)? customer?.setting_professors_can_see_marks : true);
    setSettingProdStateNotification((customer)? customer?.setting_instant_prod_state_notification : true);
  }, [customer])






  

  async function saveCustomerDataFunc() {
    setLoadingSave(true)

    var input = {
      own_user_token: (currentUser)? currentUser.token : null, 

      id: (customer?.id)? parseInt(customer?.id) : null, 
      abbreviated_name: abbreviatedName?.replaceAll(' ', '')? abbreviatedName : null,
      name: (name?.replaceAll(' ', ''))? name : null,
      factura10_client_id: (!isNaN(factura10ClientID) && parseInt(factura10ClientID))? parseInt(factura10ClientID) : null,

      label_module: (labelModule)? labelModule : 'Módulo',
      label_modules: (labelModules)? labelModules : 'Módulos',
      label_professor: (labelProfessor)? labelProfessor : 'Participante',
      label_professors: (labelProfessors)? labelProfessors : 'Participantes',
      label_extras_module: (labelExtrasModule)? labelExtrasModule : 'Otros',
      label_extras_module_url: (labelExtrasModuleURL)? labelExtrasModuleURL : 'Otros',

      setting_language_filter: (settingLanguageFilter)? settingLanguageFilter : true,
      setting_sex_filter: (settingSexFilter)? settingSexFilter : false,
      setting_professor_filter: (settingProfessorFilter)? settingProfessorFilter : true,
      setting_deffault_date_filter: (settingDeffaultDateFilter)? settingDeffaultDateFilter : true,

      setting_initial_chyron: (settingInitialChyrons)? settingInitialChyrons : true,
      setting_recording_tipo_in_list: (settingRecordingTipology)? settingRecordingTipology : false,
      setting_tittle_is_relevant: (settingTitleIsRelevant)? settingTitleIsRelevant : false,
      setting_professor_col: (settingProfessorColumn)? settingProfessorColumn : false,
      setting_professors_can_see_marks: (settingProfessorsCanSeeMarks)? settingProfessorsCanSeeMarks : true,
      setting_instant_prod_state_notification: (settingProdStateNotification)? settingProdStateNotification : true,
    };


    const response = await createOrUpdateCustomer({variables: input})
    const customerTMP = (response?.data?.createOrUpdateCustomer?.id)? response?.data?.createOrUpdateCustomer : null


    if (iconFile && customerTMP?.id) {
      await Resizer.imageFileResizer(iconFile, 100, 100, "png", 100, 0,
        async (uri) => {
          var resizedIconFile = uri;
          var saveFileFormData = new FormData();
          saveFileFormData.append('own_user_token', currentUser?.token);
          saveFileFormData.append('customerID', customerTMP.id);
          saveFileFormData.append('file', resizedIconFile);
          await axios.post(`${process.env.REACT_APP_API_ROUTE}setCustomerIcon`, saveFileFormData);
        },
        "file",
        5,
        5
      );
    }

    setTimeout(() => {
      setLoadingSave(false)
      setSuccessAlertMsg('Datos guardados correctamente.')

      if (!token) {
        navigate(`/customer/${customerTMP?.token}`)
      }
    }, "500")
  }











  return ((!loading && !loadingSave)? 
    <div className='flex-col space-y-3'>
      
      <div className='flex-col space-y-2'>
        <div className='flex items-center space-x-2 pb-1'>
          <Tooltip disableInteractive title="Subir icono de cliente" placement="bottom" arrow>
            <label htmlFor="iconFile" component="label" aria-label="upload picture" className='min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] relative flex items-center justify-center rounded-lg overflow-hidden border border-gray-400 bg-gray-50 text-gray-400 hover:text-gray-600 hover:bg-gray-100 hover:border-gray-700 cursor-pointer duration-300'>
              <input id="iconFile" hidden accept="image/*" type="file" onChange={(e)=>setIconFile(e.target.files[0])}/>

              {(iconURLTMP || fileExists(process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + icon))? 
                <div className='absolute top-0 left-0 w-full h-full'>
                  <img 
                    className='object-cover h-full w-full'
                    src={(iconURLTMP)? iconURLTMP : process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + icon} 
                    alt='edit_customer_icon'
                  />  
                </div>
              :
                <div className='absolute scale-125'>{imageIcon}</div>
              }
            </label>
          </Tooltip>

          <CustomTextField
            label="Nombre abreviado *"
            className='w-full mui-bg-gray-100 max-w-[170px]'
            big

            value={(abbreviatedName)? abbreviatedName : ''}
            onChange={(event) => setAbbreviatedName((event.target.value)? event.target.value?.slice(0, 20) : null)}
          />

          <CustomTextField
            label="Nombre *"
            big

            value={(name)? name : ''}
            onChange={(event) => setName((event.target.value)? event.target.value?.slice(0, 50) : null)}
          />

          {(currentUser?.rol === 'admin') &&
            <CustomTextField 
              label="ID Fac10"
              type='number'
              className='w-full mui-bg-gray-100 max-w-[170px]'
              disabled={(customer?.id)? true : false}
              big

              value={(factura10ClientID)? factura10ClientID : ''} 
              onChange={(event) => (parseInt(event.target.value) > 0)? setFactura10ClientID(event.target.value) : setFactura10ClientID(null)} 
            />
          }
        </div>


        {/* Labels */}
        <div className='flex items-center flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-2'>
          <CustomSelect 
            label="Label participantes"
            
            value={(selectedLabelProfessor)? selectedLabelProfessor : 0} 
            onChange={(event) => {
              const valKey = event.target.value;
              setSelectedLabelProfessor(valKey)
              setLabelProfessor(participantOptions[valKey]?.value_singular)
              setLabelProfessors(participantOptions[valKey]?.value_plural)
            }}
          >
            {participantOptions?.map((element, key) => {
              return <MenuItem key={`part-${element?.value_singular}`} value={key}>{element?.label}</MenuItem>
            })}
          </CustomSelect>

          <CustomSelect
            label="Label módulos (Singular)"
            
            value={(selectedLabelModule)? selectedLabelModule : 0} 
            onChange={(event) => {
              const valKey = event.target.value;
              setSelectedLabelModule(valKey)
              setLabelModule(moduleOptions[valKey]?.value_singular)
              setLabelModules(moduleOptions[valKey]?.value_plural)
            }}
          >
            {moduleOptions?.map((element, key) => {
              return <MenuItem key={`part-${element?.value_singular}`} value={key}>{element?.label}</MenuItem>
            })}
          </CustomSelect>

          <CustomSelect
            label="Label módulo extra" 

            value={(selectedLabelExtrasModule)? selectedLabelExtrasModule : 0} 
            onChange={(event) => {
              const valKey = event.target.value;
              setSelectedLabelExtrasModule(valKey)
              setLabelExtrasModule(extraModuleOptions[valKey]?.value)
              setLabelExtrasModuleURL(extraModuleOptions[valKey]?.value_url)
            }}
          >
            {extraModuleOptions?.map((element, key) => {
              return <MenuItem key={`part-${element?.value_url}`} value={key}>{element?.label}</MenuItem>
            })}
          </CustomSelect>
        </div>



        {/* Settings */}
        <div className='w-full space-y-2 pt-4'>
          <p className='opacity-50 px-1 text-sm'>Ajustes</p>

          <div className='w-full flex space-x-2 space-y-0' style={{ marginTop: 2 }}>
            <div className='w-full flex flex-wrap space-y-2 lg:flex-nowrap lg:space-x-2 lg:space-y-0'>
              <CustomSwitch
                label="Filtro por idioma"
                addClassName="w-full"

                value={settingLanguageFilter}
                onClick={() => setSettingLanguageFilter(!settingLanguageFilter)}
              />

              <CustomSwitch
                label="Filtro por genero"
                addClassName="w-full"

                value={settingSexFilter}
                onClick={() => setSettingSexFilter(!settingSexFilter)}
              />
            </div>

            <div className='w-full flex flex-wrap space-y-2 lg:flex-nowrap lg:space-x-2 lg:space-y-0'>
              <CustomSwitch
                label={`Filtro por ${(labelProfessor)? labelProfessor?.toLowerCase() : 'participante'}`}
                addClassName="w-full"

                value={settingProfessorFilter}
                onClick={() => setSettingProfessorFilter(!settingProfessorFilter)}
              />

              <CustomSwitch
                label="Fecha inicial automática en el filtro"
                addClassName="w-full"

                value={settingDeffaultDateFilter}
                onClick={() => setSettingDeffaultDateFilter(!settingDeffaultDateFilter)}
              />
            </div>
          </div>


          <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2'>
            <CustomSwitch
              label="El título es más relevante que el nombre del vídeo"
              addClassName="w-full"

              value={settingTitleIsRelevant}
              onClick={() => setSettingTitleIsRelevant(!settingTitleIsRelevant)}
            />

            {(currentUser?.rol === 'admin') && 
              <CustomSwitch
                label="Mostrar tipología del vídeo en el listado"
                addClassName="w-full"

                value={settingRecordingTipology}
                onClick={() => setSettingRecordingTipology(!settingRecordingTipology)}
              />
            }

            <CustomSwitch
              label="Notificación del cambio de estado en los vídeos"
              addClassName="w-full"

              value={settingProdStateNotification}
              onClick={() => setSettingProdStateNotification(!settingProdStateNotification)}
            />

            <CustomSwitch
              label={`Chyron de los ${(labelProfessors)? labelProfessors?.toLowerCase() : 'participantes'} al inicio de cada vídeo`}
              addClassName="w-full"

              value={settingInitialChyrons}
              onClick={() => setSettingInitialChyrons(!settingInitialChyrons)}
            />

            <CustomSwitch
              label={`Columna de ${(labelProfessor)? labelProfessor?.toLowerCase() : 'participante'} en el listado de vídeos`}
              addClassName="w-full"

              value={settingProfessorColumn}
              onClick={() => setSettingProfessorColumn(!settingProfessorColumn)}
            />

            <CustomSwitch
              label={`Los ${(labelProfessors)? labelProfessors?.toLowerCase() : 'participantes'} pueden ver todas las marcas`}
              addClassName="w-full"

              value={settingProfessorsCanSeeMarks}
              onClick={() => setSettingProfessorsCanSeeMarks(!settingProfessorsCanSeeMarks)}
            />
          </div>
        </div>
      </div>



      <div className='w-full flex justify-end items-end space-x-2'>
        <button 
          onClick={() => saveCustomerDataFunc()}
          className='p-[6px] pr-2 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          disabled={
            (
              !((token && customer && (currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && customer?.free_customer))) || (!token && !customer))
              || loading
              || loadingSave
              || !name
              || !(abbreviatedName && abbreviatedName.replaceAll(' ', '') !== '' && name && name.replaceAll(' ', '') !== '') 
            )? true : false
          }
        >
          {(loadingSave)? loadingIcon : saveIcon}
          <p>{(loadingSave)? 'Guardando' : 'Guardar'} {(!token)? ((loadingSave)? '' : 'y continuar') : 'información básica'}</p>
        </button>
      </div>

    </div>
  :
    <div className='w-full flex justify-center items-center space-x-4 text-gray-400 bg-gray-100 rounded-md py-[111px] shadow-md'>
      <CircularProgress color="inherit"/>
      <p>{(loadingSave)? 'Guardando' : 'Cargando' } información...</p>
    </div>

  );
}