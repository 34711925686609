import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'


const ScriptDeletOldHdReviews = () => {

  const { currentUser, currentCustomer } = useContext(AuthContext)
  const [loading, setLoading] = useState(true);



  useEffect(() => {    
    if (currentUser && currentUser?.rol === 'admin') {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API_ROUTE}removeOldHighResReviews`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token }).then((response)=> {

        setTimeout(()=> {
          setLoading(false)
        }, 1000)

      });
    }
  }, [currentUser])



  return (
    <div className='text-gray-500 font-bold p-4'>
      <p>Delete Old HD Reviews:</p>
      <p>{loading? 'LOADING' : 'ENDED'}</p>
    </div>
  )
}

export default ScriptDeletOldHdReviews
