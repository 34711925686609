import gql from "graphql-tag";
export default gql`
query GetAllVideoFolders($own_user_token: String!, $customer_token: String!, $module: String) {
  getVideoFolders(own_user_token: $own_user_token, customer_token: $customer_token, module: $module) {

    id
    name
    url_name
    module_id
    folder_id
    customer_token

    visible
    blocked

    search_number_of_videos
    search_module_name
    search_module_abbreviated_name
    search_professor_names
    search_professor_ids
    search_everything_completed
    
    datedAt
    datedAtFormated
    
    thumbnails

    __typename

  }
}
`;
