import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { useLazyQuery, useMutation } from '@apollo/client'

import CreateOrUpdateBillConcept from './../graphql/mutations/CreateOrUpdateBillConcept'
import RemoveBillConcept from './../graphql/mutations/RemoveBillConcept'
import GetBillConceptsFromModule from '../graphql/queries/GetBillConceptsFromModule';

import F10BillExtrasItem from './F10BillExtrasItem';






export default function F10BillExtras({ factura10Bill, billOption, billExtras, setBillExtras, getBillExtras, moduleFilter, loadingExtras }) {



  const { currentUser } = useContext(AuthContext)
  const { format_texts } = useContext(FuncContext)




  const [open, setOpen] = useState(false);
  const [openNonAdded, setOpenNonAdded] = useState(false);
  const [videoExtras, setVideoExtras] = useState(null);
  const [editItem, setEditItem] = useState(null);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  




  const [createOrUpdateBillConcept] = useMutation(CreateOrUpdateBillConcept)
  const [removeBillConcept] = useMutation(RemoveBillConcept)

  const [getBillConceptsByModule, { data: dataBillConcepts }] = useLazyQuery(GetBillConceptsFromModule, {
    variables: { 
      own_user_token: currentUser?.token,
      module_id: (parseInt(moduleFilter))? parseInt(moduleFilter) : null,
      include_extras: (billOption?.include_extras)? true : false,
      include_c1_new: (billOption?.include_c1_new)? true : false,
    }
  })
  





  useEffect(() => {   
    getBillConceptsByModule()
  }, [moduleFilter])


  useEffect(() => {
    setVideoExtras((dataBillConcepts?.billConceptsFromModule?.length)? dataBillConcepts?.billConceptsFromModule : null)
  }, [dataBillConcepts?.billConceptsFromModule?.length])
  

  useEffect(() => {  
    if (!billExtras?.length) { setOpen(false) }
  }, [billExtras?.length])


  useEffect(() => {  
    if (!videoExtras?.length) { setOpenNonAdded(false) }
  }, [videoExtras?.length])






  
  async function saveChangesFunc(added) {
    setLoadingSave(true)

    const localItems = (added)? billExtras : videoExtras/* ?.filter((el) => !parseInt(el?.bill_id) ) */;
    var billExtrasTMP = [];
    var counter = 1;

    for (var localItem of localItems) {
      var itemTMP = {...localItem}
      if (parseInt(itemTMP.id)) { itemTMP.id = parseInt(itemTMP.id); }
      if (!parseInt(itemTMP.bill_id) && added) { itemTMP.bill_id = parseInt(billOption?.id); } 
      else if (itemTMP.bill_id) { itemTMP.bill_id = parseInt(itemTMP.bill_id); }
      itemTMP.own_user_token = currentUser?.token;
      itemTMP.title = format_texts(itemTMP.title);
      itemTMP.description = format_texts(itemTMP.description);
      /* if (!parseInt(itemTMP.video_id)) { itemTMP.position = counter; } */

      var conceptTMP = await createOrUpdateBillConcept({variables: itemTMP})
      conceptTMP = conceptTMP?.data?.createOrUpdateBillConcept;
      billExtrasTMP.push(conceptTMP)
      counter++;
    }

    setTimeout(() => {
      if (added) { setBillExtras((billExtrasTMP?.length)? billExtrasTMP : null) } else { setVideoExtras((billExtrasTMP?.length)? billExtrasTMP : null) }
      setEditItem(null)
      setLoadingSave(false)
    }, 300 )
  }
  

  function addExtraItemFunc() {
    setLoadingCreate(true)
    setOpen(true)

    var positionTMP = 1;
    var billExtrasTMP = (billExtras?.length)? [...billExtras] : [];

    var alreadyExists = billExtrasTMP?.filter((el) => el?.position === positionTMP)?.length
    var counter = 2;
    while (alreadyExists) {
      positionTMP = counter;
      alreadyExists = billExtrasTMP?.filter((el) => el?.position === positionTMP)?.length
      counter++;
    }

    setTimeout(() => {
      billExtrasTMP.push({ position: positionTMP, title: 'Nuevo elemento', description: null, price: 1000, iva: 2100, units: 100, bill_id: billOption?.id })
      setBillExtras(billExtrasTMP.sort((a, b) => a?.position - b?.position))
      setLoadingCreate(false)
    }, 300 )
  }


  async function removeExtraItemFunc(removePosition, itemKey) {
    if (billExtras?.length && window.confirm('¿Seguro que quieres eliminar este elemento de forma permanente?')) {
      var loadingRemoveTMP = [...loadingRemove];
      loadingRemoveTMP[itemKey] = true;
      setLoadingRemove(loadingRemoveTMP)

      var billExtrasTMP = [];
      for (var localItem of billExtras) {
        if (parseInt(removePosition) === parseInt(localItem?.position) && !localItem?.video_id) {

          await removeBillConcept({variables: {
            id: parseInt(localItem?.id), 
            own_user_token: currentUser?.token,
          }})

        } else {
          billExtrasTMP.push(localItem)
        }
      }

      setTimeout(() => {
        setBillExtras(billExtrasTMP)
        var loadingRemoveTMP = [...loadingRemove];
        delete loadingRemoveTMP[itemKey]
        setLoadingRemove(loadingRemoveTMP)
      }, 300 )
    }
  }


  async function addExtraFunc(element, itemKey) {
    if (parseInt(element.id)) {
      var loadingRemoveTMP = [...loadingRemove];
      loadingRemoveTMP[itemKey] = true;
      setLoadingRemove(loadingRemoveTMP)

      var billExtrasTMP = (billExtras?.length)? [...billExtras] : [];
      var billVideoExtrasTMP = [];

      for (var localItem of videoExtras) {
        if (parseInt(localItem.id) === parseInt(element.id)) {
          var itemTMP = {...localItem}
          itemTMP.id = parseInt(itemTMP.id);
          itemTMP.bill_id = parseInt(billOption?.id)
          itemTMP.own_user_token = currentUser?.token;
    
          var conceptTMP = await createOrUpdateBillConcept({variables: itemTMP})
          conceptTMP = conceptTMP?.data?.createOrUpdateBillConcept;
          billExtrasTMP.push(conceptTMP)
        } else {
          billVideoExtrasTMP.push(localItem)
        }
      }

      setTimeout(() => {
        setVideoExtras((billVideoExtrasTMP?.length)? billVideoExtrasTMP : null);
        getBillExtras()

        var loadingRemoveTMP = [...loadingRemove];
        delete loadingRemoveTMP[itemKey]
        setLoadingRemove(loadingRemoveTMP)
      }, 300)
    }
  }

  
  async function removeParentFunc(parent, element) {
    if (parseInt(element?.id)) {
      var itemTMP = {}
      itemTMP.id = parseInt(element?.id);
      itemTMP.bill_id = null;
      itemTMP.parent_id = null;
      itemTMP.own_user_token = currentUser?.token;

      var conceptTMP = await createOrUpdateBillConcept({variables: itemTMP})
      conceptTMP = conceptTMP?.data?.createOrUpdateBillConcept;

      var parentItemTMP = {}
      parentItemTMP.id = parseInt(parent?.id);
      parentItemTMP.own_user_token = currentUser?.token;
      parentItemTMP.price = 0;

      if (parent?.childs?.length) {
        for (var child of parent?.childs) {
          if (child?.price && child?.id !== element?.id) {
            parentItemTMP.price = parentItemTMP.price + (numFunc(child?.units / 100, 0) * (numFunc(child?.price, 0)));
          }
        }
      }

      var partentConceptTMP = await createOrUpdateBillConcept({variables: parentItemTMP})
      partentConceptTMP = partentConceptTMP?.data?.createOrUpdateBillConcept;

      setTimeout(() => {
        getBillConceptsByModule()
        getBillExtras()
      }, 300)
    }
  }

  
  function numFunc(num, secNum) {
    return (num || num === 0)? num : secNum;
  }


  async function desAssignExtraItemFunc(elementId, itemKey) {
    if (parseInt(elementId)) {
      var loadingRemoveTMP = [...loadingRemove];
      loadingRemoveTMP[itemKey] = true;
      setLoadingRemove(loadingRemoveTMP)

      var billExtrasTMP = [];
      var billVideoExtrasTMP = (videoExtras?.length)? [...videoExtras] : [];

      for (var localItem of billExtras) {
        if (parseInt(localItem.id) === parseInt(elementId)) {
          var itemTMP = {...localItem}
          itemTMP.id = parseInt(itemTMP.id);
          itemTMP.bill_id = null;
          itemTMP.own_user_token = currentUser?.token;
    
          var conceptTMP = await createOrUpdateBillConcept({variables: itemTMP})
          conceptTMP = conceptTMP?.data?.createOrUpdateBillConcept;
          billVideoExtrasTMP.push(conceptTMP)
        } else {
          billExtrasTMP.push(localItem)
        }
      }

      setTimeout(() => {
        setBillExtras((billExtrasTMP?.length)? billExtrasTMP : null);
        setVideoExtras((billVideoExtrasTMP?.length)? billVideoExtrasTMP : null);

        var loadingRemoveTMP = [...loadingRemove];
        delete loadingRemoveTMP[itemKey]
        setLoadingRemove(loadingRemoveTMP)
      }, 300)
    }
  }








  return <div className={`space-y-2 ${(loadingExtras)? 'opacity-50 pointer-events-none' : ''}`}>

    <F10BillExtrasItem 
      factura10Bill={factura10Bill}
      added={true}
      localItems={billExtras}
      setLocalItems={setBillExtras}

      open={open}
      setOpen={setOpen}
      editItem={editItem}
      setEditItem={setEditItem}

      saveChangesFunc={saveChangesFunc}
      addExtraItemFunc={addExtraItemFunc}
      removeExtraItemFunc={removeExtraItemFunc}
      addExtraFunc={addExtraFunc}
      desAssignExtraItemFunc={desAssignExtraItemFunc}
      removeParentFunc={removeParentFunc}

      loadingCreate={loadingCreate}
      loadingSave={loadingSave}
      loadingRemove={loadingRemove}
    />

    {(videoExtras?.length)? 
      <F10BillExtrasItem 
        factura10Bill={factura10Bill}
        added={false}
        localItems={videoExtras}
        setLocalItems={setVideoExtras}

        open={openNonAdded}
        setOpen={setOpenNonAdded}
        editItem={editItem}
        setEditItem={setEditItem}

        saveChangesFunc={saveChangesFunc}
        addExtraItemFunc={addExtraItemFunc}
        removeExtraItemFunc={removeExtraItemFunc}
        addExtraFunc={addExtraFunc}
        desAssignExtraItemFunc={desAssignExtraItemFunc}

        loadingCreate={loadingCreate}
        loadingSave={loadingSave}
        loadingRemove={loadingRemove}
      />
    : null}
  </div>
}
