import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateMarkType(
    
    $own_user_token: String!,

    $id: Int
    $name: String
    $thumbnail: String
    $color: String
    $position: Int
    $multiple: Boolean
    $type: String
    $default_difficulty: Int
    $customer_token: String

  ) {
    createOrUpdateMarkType(input: { 

      own_user_token: $own_user_token,
      
      id: $id,
      name: $name,
      thumbnail: $thumbnail,
      color: $color,
      position: $position,
      multiple: $multiple,
      type: $type,
      default_difficulty: $default_difficulty,
      customer_token: $customer_token,

    }) {
      
      id
      name
      thumbnail
      color
      position
      multiple
      type
      default_difficulty
      customer_token
      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
