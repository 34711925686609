import SimpleToggle from '../SVGs/SimpleToggle';

export default function CustomSwitch({

    id,
    label,
    className,
    addClassName,
    disabled,
    strong,
    darker,

    value,
    onClick,

}) {

    return <button
        id={id}
        onClick={onClick}
        className={`
            ${
                (className)? className 
                : `${addClassName} flex items-center space-x-2 py-1 px-2 text-sm border border-gray-400 border-opacity-60 disabled:pointer-events-none disabled:opacity-40 rounded whitespace-nowrap duration-200 transition-colors
                    ${(strong)?
                        (value)? 'text-white  bg-blue-500 hover:bg-blue-600 active:bg-blue-700' : (darker)? 'text-gray-500 bg-gray-200 hover:bg-gray-300 active:bg-gray-400' : 'text-gray-500 bg-gray-100 hover:bg-gray-200 active:bg-gray-300'
                    :
                        (value)? 'text-blue-500  bg-blue-100 hover:bg-blue-200 active:bg-blue-300' : (darker)? 'text-gray-500 bg-gray-200 hover:bg-gray-300 active:bg-gray-400' : 'text-gray-500 bg-gray-100 hover:bg-gray-200 active:bg-gray-300'
                    }
                `
            }
        `}
        disabled={disabled}
    >
        <SimpleToggle
            active={(value)? true : false}
            borderClasses={(strong)? ((value)? 'border-white' : 'border-gray-500') : ((value)? 'border-blue-500' : 'border-gray-500')}
            ballClasses={(strong)? ((value)? 'bg-white' : 'bg-gray-500') : ((value)? 'bg-blue-500' : 'bg-gray-500')}
        />

        <p className='text-[15px]'>{label}</p>
    </button>
}