import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateVideoMarkComment(
    
    $own_user_token: String, 
    $path_video_token: String, 
    $search_guest_data: String, 

    $id: Int, 
    $text: String, 
    $video_mark_id: Int, 
    $video_comment_id: Int, 
    $order: String, 
    $user_id: Int, 
    $to_user_id: Int, 
    $state: Int, 
    $state_change_label: String
    $is_correction: Boolean
    $is_reply_to_correction: Boolean

    $prioritary: Boolean
    $public: Boolean
    $notificate_to: String, 
    $notificate_to_label: String, 

  ) {
    createOrUpdateVideoMarkComment(input: { 

      own_user_token: $own_user_token, 
      path_video_token: $path_video_token, 
      search_guest_data: $search_guest_data, 

      id: $id, text: $text, 
      video_mark_id: $video_mark_id, 
      video_comment_id: $video_comment_id, 
      order: $order, 
      user_id: $user_id, 
      to_user_id: $to_user_id, 
      state: $state, 
      state_change_label: $state_change_label 
      is_correction: $is_correction
      is_reply_to_correction: $is_reply_to_correction

      prioritary: $prioritary 
      public: $public 
      notificate_to: $notificate_to, 
      notificate_to_label: $notificate_to_label, 

    }) {
      
      id
      text
      filename
      video_mark_id
      video_comment_id
      order
      user_id
      to_user_id
      
      state
      state_change_label

      is_correction
      is_reply_to_correction
      
      public
      notificate_to
      notificate_to_label

      search_guest_data
      
      createdAt
      updatedAt

      notificate_to_users {
        id
        name
      }
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
