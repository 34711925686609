import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateEmailTemplate($own_user_token: String!, $id: Int, $slug: String!, $title: String!, $body: String!) {
    createOrUpdateEmailTemplate(input: { own_user_token: $own_user_token, id: $id, slug: $slug, title: $title, body: $body }) {
      id
      slug
      title
      body
      
      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
