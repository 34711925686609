import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext';
import { FuncContext } from '../contexts/FuncContext';
import { CopyToClipboard } from "react-copy-to-clipboard";

import TableCell from '@mui/material/TableCell';






const VideoHomeBaseListFolderCell = ({

  column,
  folder,
  
  setSelectedFolder,
  pathFolders,

  showFullEditMode,
  createFolder,
  setCreateFolder,

}) => {




  const { currentUser, currentCustomer, customerPath } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  
  const cellHeight = 110
  const folder_thumbnails = (folder?.thumbnails)? folder?.thumbnails?.split(',') : null


  const [copied, setCopied] = useState(false);
  const [urlText, setUrlText] = useState(null);
  const [localUrlText, setLocalUrlText] = useState(null);

  const folderFillIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M865-600H299q-54 0-92.5 35T168-477v213l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168q-32 0-52-21t-20-51v-432q0-30 20-51t52-21h186q14 0 27.5 5t23.5 16l75 75h313q31 0 50.5 21t21.5 51Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M696 960q-50 0-85-35t-35-85q0-8 1-14.5t3-14.5L342 666q-15 16-35.354 23T264 696q-50 0-85-35t-35-85q0-50 35-85t85-35q22 0 42.5 7.5T342 486l238-145q-2-8-3-14.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-22.292 0-42.646-7T618 402L380 547q2 8 3 14.5t1 14.5q0 8-1 14.5t-3 14.5l238 145q15-17 35.354-23.5T696 720q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>;
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;





  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000 )
    }
  }, [copied])


  useEffect( () => {
    const folder_part = (folder)? `${(pathFolders?.length)? pathFolders?.map((el) => el?.url_name)?.join('/')+'/' : ''}${folder?.url_name}` : null
    const videos_url = `${process.env.REACT_APP_ROUTE}videos/${folder?.search_module_abbreviated_name}${(folder_part)? `/${folder_part}` : ''}`
    const local_videos_url = `/videos/${folder?.search_module_abbreviated_name}${(folder_part)? `/${folder_part}` : ''}`
    
    setUrlText(videos_url)
    setLocalUrlText(local_videos_url)
  }, [folder, pathFolders])



  function copyFolderURLFunc() {
    setSuccessAlertMsg('La URL de la carpeta ha sido copiada')
    setCopied(true)
  }






  



  return <>
    <TableCell
      colSpan={1}
      align={column.align}
      sx={{ height: `${cellHeight}px`, padding: 0 }}
      className='cursor-pointer bg-gray-100 group-hover/row:bg-gray-200 group-hover/row:bg-opacity-[0.5] group-active/row:bg-gray-400 group-active/row:bg-opacity-[0.4] duration-100'
    >
      {(column.id === 'name')? <>



        <div className='w-full absolute left-0 top-0 pl-4 h-full flex items-center justify-between space-x-4 text-gray-600 text-base whitespace-nowrap'>
          <button
            onClick={(event) => {
              /* setModuleFilter((folder?.module_id)? folder?.module_id : null) */
              setSelectedFolder(folder);
              navigateOrOpenFunc(event, localUrlText);
            }}
            className='w-full h-full flex items-center justify-start space-x-4'
          >
            <div className='relative flex items-center justify-center hover:scale-105 bg-gray-200 duration-200 overflow-hidden rounded-md border border-gray-400'>
              <div className={`absolute z-30 shadow-[inset_0_0px_15px_rgba(0,0,0,0.15)] w-full h-full flex items-center justify-center bg-opacity-60 pointer-events-none ${(folder_thumbnails?.length)? 'bg-gray-100' : ''}`}>
                <div className='absolute z-30 scale-[2.0] text-gray-700'>{folderFillIcon}</div>
              </div>

              <div className={`z-10 h-[79px] ${(showFullEditMode)? 'w-[90px]' : ' w-[90px] xl:w-[139px]'} duration-200 transition-all flex flex-wrap overflow-hidden text-gray-400`}>
                {folder_thumbnails?.slice(0, 4)?.map((thumb, key) => {
                  return <div className={`w-1/2 h-[40px]`}>
                    <img src={customerPath + 'video_thumbnails/' + thumb} className={`z-20 object-cover w-full h-full`}/> 
                  </div>
                })}
              </div>
            </div>
            
            <div className='text-left px-[6px]'>
              <div className='flex items-baseline justify-start space-x-1'>
                <p className='font-bold'>{folder?.name}</p>
                <p className='truncate text-[10px] font-bold text-gray-400 pl-[6px]'>/ {folder?.search_module_name}</p>
              </div>

              {(folder?.datedAtFormated) && <p className='text-xs opacity-70'>Con fecha: {folder?.datedAtFormated}</p>}
            </div>
          </button>


          <div className='flex items-center space-x-1 pr-2'>
            <p className='hidden sm:inline-block opacity-70 text-[15px] pr-6'>
              {(folder?.search_number_of_videos)? `Contiene ${folder?.search_number_of_videos} ${(folder?.search_number_of_videos === 1)? 'vídeo' : 'vídeos'}` : 'Carpeta vacía'}{(folder?.search_number_of_videos === 1 && folder?.search_everything_completed)? ' finalizado' : (folder?.search_number_of_videos && folder?.search_everything_completed)? ', todos finalizados' : ''}

            </p>

            {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))?
              <button
                onClick={() => setCreateFolder((folder?.id === createFolder?.id)? null : folder)}
                className={`p-1 text-gray-500 disabled:opacity-50 disabled:pointer-events-none hover:scale-125 active:scale-100 rounded duration-200 hover:animate-one-spin
                  ${(folder?.id === createFolder?.id)? 'hover:text-red-500' : 'hover:text-blue-500'}
                `}
              >
                {(folder?.id === createFolder?.id)? cancelIcon : editIcon}
              </button>
            : null }


            <CopyToClipboard text={urlText}>
              <button
                onClick={() => copyFolderURLFunc()}
                className={`p-1 text-gray-500 disabled:opacity-50 disabled:pointer-events-none hover:scale-125 active:scale-100 rounded duration-200 hover:animate-one-spin
                  ${(copied)? 'text-blue-500' : 'hover:text-blue-500'}
                `}
              >
                {(copied)? copyIcon : shareIcon}
              </button>
            </CopyToClipboard>
          </div>
        </div>

      

      </> : null }
    </TableCell>
  </>
}

export default VideoHomeBaseListFolderCell
