import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { FuncContext } from '../../contexts/FuncContext'
import { useQuery, useLazyQuery } from '@apollo/client'

import HomeChartsToExcel from './HomeChartsToExcel'

import GetAllUsersAndProfessorsSimple from '../../graphql/queries/GetAllUsersAndProfessorsSimple'
import GetCustomerModules from '../../graphql/queries/GetCustomerModules'
import Factura10GetAllVideosSimple from '../../graphql/queries/Factura10GetAllVideosSimple'

import Tooltip from '@mui/material/Tooltip';


import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';










export default function HomeCharts({
    
  tab,
  chartColors,
  
}) {



  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { formatNumbersToPrint } = useContext(FuncContext)


  

  
  /* Filter vars */
  const [valueName, setValueName] = useState(null);
  const [tableKeys, setTableKeys] = useState(null);

  /* Chart vars */
  const [chartVideosInModules, setChartVideosInModules] = useState(null);
  const [chartMinutesInModules, setChartMinutesInModules] = useState(null);
  const [chartMinutesInProfessor, setChartMinutesInProfessor] = useState(null);

  const [loading, setLoading] = useState(false);

  /* Icon vars */
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  const { data: dataModules, loading: loadingModules } = useQuery(GetCustomerModules, {
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })

  const [getDataUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQuery(GetAllUsersAndProfessorsSimple, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: true, 
    }
  })

  const [getDataVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery(Factura10GetAllVideosSimple, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      localizations: 1,
    }
  })










  useEffect(() => {
    if (currentUser?.token && currentCustomer?.token && dataModules?.getCustomerModules?.length) {
      getDataUsers();
      getDataVideos();
    }
  }, [currentUser, currentCustomer, dataModules])


  /* Generate charts */
  useEffect(() => {
    const videos = dataVideos?.getCustomerVideos;
    const users = dataUsers?.usersAndProfessors;
    const modules = (dataModules?.getCustomerModules?.length)? [...dataModules?.getCustomerModules]?.filter((element)=> parseInt(element?.numberOfVideos)) : null;

    if (videos?.length && users?.length && modules?.length) {
      var minutesCounter = [];

      for (var u = 0; u < users?.length; u++) {
        if (users[u]?.professor) {
          var chartVal = { name: users[u]?.name + ' / ' + users[u]?.professor_code }

          for (var v = 0; v < videos?.length; v++) {
            if (parseInt(users[u]?.id) === parseInt(videos[v]?.professor_id)) {
              const module_key = (videos[v]?.search_module_name === 'Extra')? 'Extras' : videos[v]?.search_module_name
              chartVal[module_key] = (chartVal[module_key])? parseFloat((chartVal[module_key] + parseFloat(videos[v]?.minutes))) : parseFloat(videos[v]?.minutes)
              chartVal['Total'] = (chartVal['Total'])? parseFloat((chartVal['Total'] + parseFloat(videos[v]?.minutes))) : parseFloat(videos[v]?.minutes)
            }
          }

          if (Object.keys(chartVal).length > 1) {
            minutesCounter.push(chartVal)
          }
        }
      }

      setChartMinutesInProfessor(orderChartArrayFunc(minutesCounter, modules?.filter((element)=> parseInt(element?.numberOfVideos)), 3))
    }
  }, [dataVideos, dataUsers, dataModules, tab])


  /* Generate charts */
  useEffect(() => {    
    const videos = (dataVideos?.getCustomerVideos?.length)? [...dataVideos?.getCustomerVideos] : null;
    var modules = (dataModules?.getCustomerModules?.length)? [...dataModules?.getCustomerModules]?.filter((element)=> parseInt(element?.numberOfVideos)) : null;
    setLoading(true)

    if (videos?.length && modules?.length) {
      if (modules?.length) { modules =  [{ id: null, name: "Extras" }, ...modules] } else { modules = [{ id: null, name: "Extras" }] }
      var chartVideosInModulesTMP = [];  /* Videos x Module */
      var chartMinutesInModulesTMP = [];  /* Minutes x Module */

      for (var i = 0; i < modules?.length; i++) {

        /* Videos */
        var videoMaleSpanishCounter = [0, 0];
        var videoMaleEnglishCounter = [0, 0];

        var videoFemaleSpanishCounter = [0, 0];
        var videoFemaleEnglishCounter = [0, 0];

        var videoBothGenderSpanishCounter = [0, 0];
        var videoBothGenderEnglishCounter = [0, 0];
        
        var videoNoProfessorCounter = [0, 0];
        var videoTotalCounter = [0, 0];


        /* Minutes */
        var minutesMaleSpanishCounter = [0, 0];
        var minutesMaleEnglishCounter = [0, 0];

        var minutesFemaleSpanishCounter = [0, 0];
        var minutesFemaleEnglishCounter = [0, 0];

        var minutesBothGenderSpanishCounter = [0, 0];
        var minutesBothGenderEnglishCounter = [0, 0];
        
        var minutesNoProfessorCounter = [0, 0];
        var minutesTotalCounter = [0, 0];


        for (var v = 0; v < videos?.length; v++) {
          if ((!modules[i]?.id && !videos[v]?.module_id) || parseInt(videos[v]?.module_id) === parseInt(modules[i]?.id)) {
            var moduleNum = (modules[i]?.id)? 0 : 1;
            var sex = (parseInt(videos[v]?.professor?.sex))? parseInt(videos[v]?.professor?.sex) : null; /* 1 MEN / 2 WOMEN */
            var allProfSameSex = (!videos[v]?.extraProfessors?.length || videos[v]?.extraProfessors?.filter((element)=> element?.sex === sex)?.length === videos[v]?.extraProfessors?.length)? true : false


            /* Total */
            videoTotalCounter[moduleNum]++
            minutesTotalCounter[moduleNum] = minutesTotalCounter[moduleNum] + parseFloat(videos[v]?.minutes)


            if (videos[v]?.language === 'eng') { /* ENG */

              if (videos[v]?.professor) {
                if (allProfSameSex) {
                  if (sex === 1) { /* MEN */
                    videoMaleEnglishCounter[moduleNum]++
                    minutesMaleEnglishCounter[moduleNum] = minutesMaleEnglishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                  } else if (sex === 2) { /* WOMEN */
                    videoFemaleEnglishCounter[moduleNum]++
                    minutesFemaleEnglishCounter[moduleNum] = minutesFemaleEnglishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                  }
                } else {
                  videoBothGenderEnglishCounter[moduleNum]++
                  minutesBothGenderEnglishCounter[moduleNum] = minutesBothGenderEnglishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                }
              } else {
                videoNoProfessorCounter[moduleNum]++
                minutesNoProfessorCounter[moduleNum] = minutesNoProfessorCounter[moduleNum] + parseFloat(videos[v]?.minutes)
              }

            } else if (videos[v]?.language === 'esp') { /* SPA */

              if (videos[v]?.professor) {
                if (allProfSameSex) {
                  if (sex === 1) { /* MEN */
                    videoMaleSpanishCounter[moduleNum]++
                    minutesMaleSpanishCounter[moduleNum] = minutesMaleSpanishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                  } else if (sex === 2) { /* WOMEN */
                    videoFemaleSpanishCounter[moduleNum]++
                    minutesFemaleSpanishCounter[moduleNum] = minutesFemaleSpanishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                  }
                } else {
                  videoBothGenderSpanishCounter[moduleNum]++
                  minutesBothGenderSpanishCounter[moduleNum] = minutesBothGenderSpanishCounter[moduleNum] + parseFloat(videos[v]?.minutes)
                }
              } else {
                videoNoProfessorCounter[moduleNum]++
                minutesNoProfessorCounter[moduleNum] = minutesNoProfessorCounter[moduleNum] + parseFloat(videos[v]?.minutes)
              }

            }
          }
        }


        chartVideosInModulesTMP.push({
          'name': modules[i]?.name,
          'Masculino Español': videoMaleSpanishCounter[moduleNum],
          'Masculino Inglés': videoMaleEnglishCounter[moduleNum],
          'Femenino Español': videoFemaleSpanishCounter[moduleNum],
          'Femenino Inglés': videoFemaleEnglishCounter[moduleNum],
          'Multi Genero Español': videoBothGenderSpanishCounter[moduleNum],
          'Multi Genero Inglés': videoBothGenderEnglishCounter[moduleNum],
          'Sin Profesor': videoNoProfessorCounter[moduleNum],
          'Total': videoTotalCounter[moduleNum],
        })
        
        chartMinutesInModulesTMP.push({
          'name': modules[i]?.name,
          'Masculino Español': minutesMaleSpanishCounter[moduleNum],
          'Masculino Inglés': minutesMaleEnglishCounter[moduleNum],
          'Femenino Español': minutesFemaleEnglishCounter[moduleNum],
          'Femenino Inglés': minutesFemaleSpanishCounter[moduleNum],
          'Multi Genero Español': minutesBothGenderSpanishCounter[moduleNum],
          'Multi Genero Inglés': minutesBothGenderEnglishCounter[moduleNum],
          'Sin Profesor': minutesNoProfessorCounter[moduleNum],
          'Total': minutesTotalCounter[moduleNum],
        })
      }

      
      setChartVideosInModules(orderChartArrayFunc(clean(chartVideosInModulesTMP), null, 1))
      setChartMinutesInModules(orderChartArrayFunc(clean(chartMinutesInModulesTMP), null, 2))

      
    } else {

      setChartVideosInModules(null)
      setChartMinutesInModules(null)

    }

    setLoading(false)
  }, [dataVideos, dataModules, tab])











  function clean(obj) {
    for (var propName in obj) {
      if (!obj[propName]) {
        delete obj[propName];
      }
    }
    return obj
  }


  let keyPresenceFunc = (key, arrOfObj) => arrOfObj?.some(obj => Object.keys(obj).includes(key) && obj[key]);


  function orderChartArrayFunc(excelData, modules, newTab) {
    var excelDataTMP = [...excelData]
    
      
    for (var i = 0; i < excelDataTMP?.length; i++) {
      var element = excelDataTMP[i];

      if (modules?.length) {
        var moduleNames = [...modules]?.map((el) => el?.name)
        moduleNames = ['name', 'Total', currentCustomer?.label_extras_module, ...moduleNames]
        
        for (var m = 0; m < modules?.length; m++) {
          var module = modules[m];
          if (element[module?.name] === undefined) { element[module?.name] = null }
        };

        Object.keys(element).map((keyName, i) => { if (!moduleNames?.includes(keyName)) { delete element[keyName] } })

        if (element[currentCustomer?.label_extras_module] === undefined) { element[currentCustomer?.label_extras_module] = null }  
      } else {
        if (element["Masculino Español"] === undefined) { element["Masculino Español"] = null }  
        if (element["Masculino Inglés"] === undefined) { element["Masculino Inglés"] = null }  
        if (element["Femenino Español"] === undefined) { element["Femenino Español"] = null }  
        if (element["Femenino Inglés"] === undefined) { element["Femenino Inglés"] = null }  
        if (element["Multi Genero Español"] === undefined) { element["Femenino Inglés"] = null }  
        if (element["Multi Genero Inglés"] === undefined) { element["Femenino Inglés"] = null }  
        if (element["Sin Profesor"] === undefined) { element["Femenino Inglés"] = null }  
      }


      var sortedKeys = Object.keys(element)?.sort(function(a, b){

        if(a === 'name') { return -1; }
        if(b === 'name') { return 1; }

        if(a === 'Total') { return 1; }
        if(b === 'Total') { return -1; }

        if(a === currentCustomer?.label_extras_module) { return -1; }
        if(b === currentCustomer?.label_extras_module) { return 1; }

        if(a?.includes(currentCustomer?.label_extras_module)) { return -1; }
        if(b?.includes(currentCustomer?.label_extras_module)) { return 1; }

        /* if(a < b) { return -1; }
        if(a > b) { return 1; } */
        return 0;
      
      })

      if (newTab === tab) {
        setTableKeys(sortedKeys);
      }

      sortedKeys = sortedKeys.reduce((objEntries, key) => {
        objEntries[key] = element[key];
        return objEntries;
      }, {});
      
      excelDataTMP[i] = sortedKeys;
    }

    return excelDataTMP
  }


  function toDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return (Math.trunc(num * calcDec) / calcDec)? (Math.trunc(num * calcDec) / calcDec) : '---';
  }


  const LoadingChart = () => {
    return <div className='absolute z-10 w-full h-full'>
      <div className='w-full h-full flex justify-center items-center p-4 animate-pulse bg-gray-200 rounded text-neutral-500'>
        {loadingIcon}
        <p>Cargando...</p>
      </div>
    </div>
  };


  const VideosModuleChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div className="bg-white text-sm text-gray-700 rounded border border-gray-400">
          <div className='p-2'>
            <h1 className='text-gray-800 font-bold'>{label} / Vídeos por {currentCustomer?.label_module?.toLowerCase()}</h1>

            <hr className='border-gray-400 my-2' />

            <div className='w-full space-y-1'>
              {payload?.map(element => {
                return (element.value)? 
                  <div className='w-full flex justify-between space-x-2'>
                    <div className='flex items-center space-x-2'>
                      <div style={{ backgroundColor: element?.color }} className='w-[10px] h-[10px] rounded-[3px] mb-[2px]'></div>
                      <p>{(parseInt(element?.name.charAt(0)))? element?.name.replace(/[0-9]/g, '') : element?.name}:</p>
                    </div>
                    <p>{element.value} vídeos</p>
                  </div>
                : null
              })}
            </div>
          </div>

        </div>
      );
    }
  
    return null;
  };


  const MinutesModuleChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white text-sm text-gray-700 rounded border border-gray-400">
          <div className='p-2'>
            <h1 className='text-gray-800 font-bold'>{label} / Minutos por {currentCustomer?.label_module?.toLowerCase()}</h1>

            <hr className='border-gray-400 my-2' />

            <div className='w-full space-y-1'>
              {payload?.map(element => {
                return <div className='w-full flex justify-between space-x-2'>
                  <div className='flex items-center space-x-2'>
                    <div style={{ backgroundColor: element?.color }} className='w-[10px] h-[10px] rounded-[3px] mb-[2px]'></div>
                    <p>{(parseInt(element?.name.charAt(0)))? element?.name.replace(/[0-9]/g, '') : element?.name}:</p>
                  </div>
                  <p>{element.value.toFixed(2)} minutos</p>
                </div>
              })}
            </div>
          </div>

        </div>
      );
    }
  
    return null;
  };


  function downloadChartInTXT(data, filename, itemName, decimals) {
    var content = filename;


    for (var i = 0; i < data?.length; i++) {
      var dataItem = data[i];

      Object.keys(dataItem)?.map(key => {
        var value = dataItem[key];

        if (key === 'name') {
          content = (content + "\n\n\n\n - " + value + "\n");
        } else {
          content = (content + "\n\xa0\xa0\xa0\xa0" + key + ":\t\t" + value?.toFixed(decimals) + ((parseInt(value) === 1)? itemName.slice(0, -1) : itemName));
        }
      });
    }


    const link = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = filename + ".txt";
    link.click();
    URL.revokeObjectURL(link.href);
  }








  







  return (<>

  

    <div className='absolute top-0 right-0'>
      <div className='p-2 flex space-x-1 rounded-t overflow-hidden'>
        <Tooltip disableInteractive title={"Descargar datos en TXT"} placement="bottom" arrow>
          <button 
            onClick={()=> {
              if (tab === 1) {
                downloadChartInTXT(chartVideosInModules, `Vídeos por ${currentCustomer?.label_module?.toLowerCase()}`, ' vídeos', 0)
              } else if (tab === 2) {
                downloadChartInTXT(chartMinutesInModules, `Minutos por ${currentCustomer?.label_module?.toLowerCase()}`, ' minutos', 2)
              } else if (tab === 3) {
                downloadChartInTXT(chartMinutesInProfessor, `Minutos por ${currentCustomer?.professor?.toLowerCase()} y ${currentCustomer?.label_module?.toLowerCase()}`, ' minutos', 2)
              }
            }} 
            className={`bg-gray-200 py-[2px] pl-1 pr-2 text-xs font-bold flex items-center cursor-pointer duration-200 text-gray-500 hover:text-blue-500 active:text-gray-500 active:bg-gray-200 rounded`}
          >
            {downloadIcon}
            <p>TXT</p>
          </button>
        </Tooltip>

        <HomeChartsToExcel 
          data={
            (tab === 1)? chartVideosInModules
            : (tab === 2)? chartMinutesInModules
            : chartMinutesInProfessor
          } 
          filename={
            (tab === 1)? `Vídeos por ${currentCustomer?.label_module?.toLowerCase()}`
            : (tab === 2)? `Minutos por ${currentCustomer?.label_module?.toLowerCase()}`
            : `Minutos por ${currentCustomer?.professor?.toLowerCase()} y ${currentCustomer?.label_module?.toLowerCase()}`
          }

          modules={
            (tab === 1)? null
            : (tab === 2)? null
            : dataModules?.getCustomerModules?.filter((element)=> parseInt(element?.numberOfVideos))
          }
        />
      </div>
    </div>




    <div className={`w-full flex flex-wrap`}>

      <div className='w-full flex p-5'>
        <div className={`w-full overflow-x-auto overflow-y-hidden bg-gray-200 p-5 pb-1 pl-0 rounded`}>
          <div className={`flex relative w-full h-[25vw] text-xs ${(tab === 3)? 'min-w-[2700px]' : ''}`}>

            {/* Videos x Module */}
            {(tab === 1) &&
              <>
                {(valueName !== 'vídeos') && setValueName('vídeos')}
                {(loading || loadingVideos || loadingModules || loadingUsers)? <LoadingChart /> : null}

                {(chartVideosInModules)?
                  <ResponsiveContainer width="100%" height="100%" >
                    <BarChart data={chartVideosInModules} barCategoryGap={20} barGap={2}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" interval={0}/>
                      <YAxis unit=''  width={45}/>

                      <ChartTooltip content={<VideosModuleChartTooltip />} />
                      <Legend iconType='circle' iconSize={10} />

                      <Bar dataKey="Masculino Español" fill={chartColors[0]} />
                      <Bar dataKey="Masculino Inglés" fill={chartColors[1]} />
                      <Bar dataKey="Femenino Español" fill={chartColors[2]} />
                      <Bar dataKey="Femenino Inglés" fill={chartColors[3]} />
                      <Bar dataKey="Multi Genero Español" fill={chartColors[4]} />
                      {(keyPresenceFunc("Multi Genero Inglés", chartVideosInModules)) && <Bar dataKey="Multi Genero Inglés" fill={chartColors[5]} />}
                      <Bar dataKey="Sin Profesor" fill={chartColors[6]}/>
                      <Bar dataKey="Total" fill={chartColors[7]}/>
                    </BarChart>
                  </ResponsiveContainer>
                : null }
              </>
            }
            



            {/* Minutes x Module */}
            {(tab === 2) &&
              <>
                {(valueName !== 'min') && setValueName('min')}
                {(loading || loadingVideos || loadingModules || loadingUsers)? <LoadingChart /> : null}

                {(chartMinutesInModules)?
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartMinutesInModules} barCategoryGap={20} barGap={2}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" interval={0} />
                      <YAxis unit=' min' width={70}/>

                      <ChartTooltip content={<MinutesModuleChartTooltip />} />
                      <Legend iconType='circle' iconSize={10} />

                      <Bar dataKey="Masculino Español" fill={chartColors[0]} />
                      <Bar dataKey="Masculino Inglés" fill={chartColors[1]} />
                      <Bar dataKey="Femenino Español" fill={chartColors[2]} />
                      <Bar dataKey="Femenino Inglés" fill={chartColors[3]} />
                      <Bar dataKey="Multi Genero Español" fill={chartColors[4]} />
                      {(keyPresenceFunc("Multi Genero Inglés", chartMinutesInModules)) && <Bar dataKey="Multi Genero Inglés" fill={chartColors[5]} />}
                      <Bar dataKey="Sin Profesor" fill={chartColors[6]}/>
                      <Bar dataKey="Total" fill={chartColors[7]}/>
                    </BarChart>
                  </ResponsiveContainer>
                : null }
              </>
            }




            {/* Minutes x Professor */}
            {(tab === 3) &&
              <>
                {(valueName !== 'min') && setValueName('min')}
                {(loading || loadingVideos || loadingModules || loadingUsers)? <LoadingChart /> : null}

                {(chartMinutesInProfessor)?
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartMinutesInProfessor} barCategoryGap={0} barGap={0}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" interval={0} className='text-[0px] min-[2400px]:text-[4px]'/>
                      <YAxis unit=' min' width={70}/>

                      <ChartTooltip content={<MinutesModuleChartTooltip />} />
                      <Legend iconType='circle' iconSize={10} />

                      <Bar dataKey="Extras" fill={chartColors[1]} type="circle" />

                      {dataModules?.getCustomerModules?.filter((element)=> parseInt(element?.numberOfVideos))?.map((element, key) => {
                        return <Bar dataKey={element?.name} fill={chartColors[key+2]} type="circle" />
                      })}

                      <Bar dataKey="Total" fill={chartColors[0]} type="circle" />
                    </BarChart>
                  </ResponsiveContainer>
                : null }
              </>
            }

          </div>
        </div>
      </div>


      <div className='w-full p-5'>
        <div className='w-full overflow-auto '>
          <table className='w-full text-sm whitespace-nowrap'>
            <tr className='text-left'>
              {tableKeys?.map(element => {
                return <th className='p-2 bg-gray-100'> {/* sticky top-[55px] */}
                  {
                    (element === 'name')? 'Nombre' 
                    : (parseInt(element.charAt(0)))? element.replace(/[0-9]/g, '')
                    : element
                  }
                </th>
              })}
            </tr>

            {(              
              (tab === 1)? chartVideosInModules
              : (tab === 2)? chartMinutesInModules
              : chartMinutesInProfessor
            )?.map(element => {
              return <tr className='border-b-4 border-gray-100 bg-gray-200 hover:bg-gray-300 rounded duration-200'>
                {tableKeys?.map(key => {
                  return Object.keys(element)?.map(tableKey => {
                    if (tableKey === key) {
                      return <td>
                        <div className='p-2'>
                          <p className={`${(key !== 'name')? '' : 'font-bold'}`}>
                            {
                              (parseFloat(element[key]) > 0)? formatNumbersToPrint(element[key], (valueName === 'vídeos')? 0 : 2) + ' ' + valueName
                              : (element[key])? element[key]
                              : '-'
                            }
                          </p>
                        </div>
                      </td>
                    }
                  })
                })}
              </tr>
            })} 
          </table>
        </div>
      </div>





    </div>
  </>);
}