import gql from "graphql-tag";
export default gql`
  query GetVideoMarkType($own_customer_token: String!, $id: ID!) {
    videoMarkType(own_customer_token: $own_customer_token, id: $id) {

      id
      name
      thumbnail
      color
      position
      multiple
      type
      default_difficulty
      customer_token
      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
      
    }
  }
`;
