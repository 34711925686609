import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from "react-router-dom";

import GetAllUserTags from '../graphql/queries/GetAllUserTags';

import ModalModuleAdministration from '../components/ModalModuleAdministration';
import ModuleSelector from '../components/ModuleSelector';
import UserProfilePic from '../components/UserProfilePic'

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomSelect from './Inputs/CustomSelect';
import CustomDatePicker from './Inputs/CustomDatePicker';
import CustomMultipleSelect from './Inputs/CustomMultipleSelect';
import ItemListStatesSelect from './ItemListStatesSelect';
import ModalProductionStateAdministration from './ModalProductionStateAdministration';
import { LayoutContext } from '../contexts/LayoutContext';





export default function VideoFilters({

  keyItem,
  moduleFilter,
  setModuleFilter,
  selectedModule,
  setSelectedModule,
  selectedFolder,
  setSelectedFolder,

  orderFilter,
  setOrderFilter,
  languageFilter,
  setLanguageFilter,
  genderFilter,
  setGendetFilter,
  professorFilter,
  setProfessorFilter,
  userTagsFilter,
  setUserTagsFilter,
  stateFilter,
  setStateFilter,
  startFilterDate,
  setStartFilterDate,
  endFilterDate,
  setEndFilterDate,

  pathProfessor,

  dataModules,
  dataProfessors,
  dataStates,

  saveChanges,
  setLoading,

}) {
  




  const { id, name, pathModule, professorCode } = useParams()
  const { currentUser, currentCustomer, languageList } = useContext(AuthContext)
  const { startTutorial, step } = useContext(LayoutContext)
  const location = useLocation();
  

  const startDateRemoved = (localStorage.getItem(currentCustomer?.token + '-start-date-removed'))? new Date(localStorage.getItem(currentCustomer?.token + '-start-date-removed')) > new Date() : false


  const [openModuleAdminModal, setOpenModuleAdminModal] = useState(false);
  const [openProductionAdminModal, setOpenProductionAdminModal] = useState(false);
  const [allTags, setAllTags] = useState(null);


  const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M399-80q-14 0-24-9t-12-23l-11-94q-10-6-22-13t-22-13l-88 37q-13 5-25.5 1T175-210L94-351q-7-12-4.5-25t13.5-21l75-57v-52l-75-57q-11-8-13.5-21t4.5-25l81-141q7-12 19.5-16t25.5 1l88 37q10-6 22-13t22-13l11-94q2-14 12-23t24-9h162q14 0 24 9t12 23l11 94q10 6 22 13t22 13l88-37q13-5 25.5-1t19.5 16l81 141q7 12 4.5 25T857-563l-74 57v52l74 57q11 8 13.5 21t-4.5 25l-81 141q-7 12-19.5 16t-25.5-1l-88-37q-10 6-22 13t-22 13l-11 94q-2 14-12 23t-24 9H399Zm81-257q59 0 101-42t42-101q0-59-42-101t-101-42q-59 0-101 42t-42 101q0 59 42 101t101 42Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>



  const { data: dataUserTags } = useQuery(GetAllUserTags, {
    fetchPolicy: 'no-cache',
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })








  useEffect(() => {    
    if (dataUserTags?.userTags?.length) {
      setAllTags(dataUserTags?.userTags?.map((el) => { return { name: el?.name } }))
    } else {
      setAllTags(null)
    }
  }, [dataUserTags])
  



  function getNextMonday() {
    var d = new Date();
    d.setDate(d.getDate() + 1 * 7);
    d.setHours(4, 0, 0);
    var day = d.getDay();
    var diff = d.getDate() - day + ((day == 0)? -6 : 1); 
    return new Date(d.setDate(diff));
  }
  



  
  return <div
    className={`
      w-full h-auto flex items-center 
      ${
        (orderFilter?.includes('Date'))? '2xl:h-[30px] flex-wrap 2xl:flex-nowrap space-x-0 2xl:space-x-2 space-y-[10px] 2xl:space-y-0' 
        : 'xl:h-[30px] flex-wrap xl:flex-nowrap space-x-0 xl:space-x-2 space-y-[10px] xl:space-y-0'
      }
    `}
  >
    <div className="w-full h-auto lg:h-[30px] flex flex-wrap lg:flex-nowrap items-center space-x-0 lg:space-x-2 space-y-[10px] lg:space-y-0">
      {((!pathModule && !name) || currentCustomer?.setting_language_filter || currentCustomer?.setting_sex_filter) && 
        <div className={`w-full xl:w-auto h-auto md:h-[30px] flex flex-wrap md:flex-nowrap items-center space-x-0 md:space-x-2 space-y-[10px] md:space-y-0`}>
          {(!pathModule && !name) &&
            <div className="flex items-center justify-center space-x-2 w-full">
            
              <ModuleSelector 
                modules={dataModules} 
                moduleFilter={moduleFilter} 
                setModuleFilter={setModuleFilter} 
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}

                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}

                showAll={true}
                load={true}
                setLoading={setLoading}
                disabled={saveChanges}
                color='gray-100'
                redirect={true}
              />

              {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && 
                <div>
                  <Tooltip disableInteractive title={`Administrar ${currentCustomer?.label_modules?.toLowerCase()}`} placement="bottom" arrow>
                    <div id={`modules-menu-tuto-${keyItem}`} onMouseUp={() => setOpenModuleAdminModal(!openModuleAdminModal)} className={`max-w-[30px] w-[30px] max-h-[30px] h-[30px] justify-center flex items-center rounded border duration-300 ${(openModuleAdminModal)? 'text-white bg-blue-500 border-blue-600 ' : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-blue-500 border-gray-400 border-opacity-60'}`}>
                      <IconButton color="inherit" type="small" disabled={saveChanges}>
                        <div className='w-[30px] h-[30px] flex items-center justify-center'>
                          {settingsIcon}

                          
                          {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 3 || step === 4 || step === 5))? true : false) && 
                            <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 4)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                              <div /* Tutorial prop */
                                className="ml-[0] mt-[0] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                              ></div>
                            </div>
                          }
                        </div>
                      </IconButton>
                    </div>
                  </Tooltip>

                  <ModalModuleAdministration
                    open={openModuleAdminModal}
                    setOpen={setOpenModuleAdminModal}
                    modules={(dataModules?.length)? dataModules : []}
                    moduleFilter={moduleFilter}
                    setModuleFilter={setModuleFilter}
                  />
                </div>
              }
            </div>
          }



          {(currentCustomer?.setting_language_filter || currentCustomer?.setting_sex_filter) && 
            <div className={`w-full xl:w-auto h-[30px] flex flex-nowrap items-center space-x-2`}>
              {(currentCustomer?.setting_language_filter) && 
                <div className='w-full min-w-[100px] xl:min-w-[140px] xl:w-auto'>
                  <CustomSelect
                    label="Idioma"
                    className={`w-full flex-grow mui-bg-gray-100 ${(name)? '' : 'xl:max-w-[230px]'}`}
                    disabled={saveChanges}

                    value={(languageFilter)? languageFilter : 'null'}
                    onChange={(event) => setLanguageFilter(event.target.value)}
                  >
                    <MenuItem value={'null'}>Todos</MenuItem>

                    {languageList?.map(element => {
                      return <MenuItem key={element?.abbreviated_name} value={element?.abbreviated_name}>
                        <div className='w-[20px] max-w-[20px] min-w-[20px] mr-2'><img className='w-full' src={element?.flag} /></div>
                        <p style={{ margin: 0 }}>{element?.name}</p>
                      </MenuItem>
                    })}

                    <MenuItem value={'other'}>Otros</MenuItem>
                  </CustomSelect>
                </div>
              }


              {(currentCustomer?.setting_sex_filter) && 
                <div className='w-full min-w-[100px] xl:min-w-[140px] xl:w-auto'>
                  <CustomSelect
                    label="Genero"
                    disabled={saveChanges}

                    value={(genderFilter)? genderFilter : 'null'}
                    onChange={(event) => setGendetFilter(event.target.value)}
                  >
                    <MenuItem value={'null'}>Todos</MenuItem>
                    <MenuItem value={'male'}>Masculino</MenuItem>
                    <MenuItem value={'female'}>Femenino</MenuItem>
                    <MenuItem value={'mix'}>Mixtos</MenuItem>
                  </CustomSelect>
                </div>
              }
            </div>
          }
        </div>
      }


      {(currentCustomer?.setting_professor_filter && !pathProfessor)? <>
        <div className='w-full 2xl:w-auto min-w-[100px] xl:min-w-[140px]'>
          <CustomMultipleSelect
            label={currentCustomer?.label_professor}
            disabled={saveChanges}
            
            value={(professorFilter?.length)? professorFilter : ['null']}
            onChange={(event) => {
              const valueTMP = (
                (event.target.value?.length > 1 && event.target.value[event.target.value?.length-1] === 'null')? ['null']
                : (event.target.value?.length > 1)? event.target.value?.filter((el) => el !=='null')
                : (event.target.value?.length)? event.target.value 
                : ['null']
              );
              setProfessorFilter(valueTMP);
            }}
            renderValue={(selected) => {
              var names = [];

              if (dataProfessors?.length && selected?.length && selected[0] !== 'null') {
                for (var itemSelected of selected) {
                  const itemTMP = dataProfessors?.find((x) => parseInt(x.id) === parseInt(itemSelected))
                  names.push(itemTMP?.name)
                }
              }

              return <p className={`${(names?.length > 4)? 'text-xs' : (names?.length > 2)? 'text-sm' : ''}`}>
                {(names?.length)? names?.join(', ').replace(/,([^,]*)$/, ' y $1') : 'Todos'}
              </p>
            }}
          >
            <MenuItem value={'null'} sx={{ paddingBlock: 0, paddingInline: 1 }}>
              <div className='flex items-center space-x-2 p-1'>
                <p>Todos</p>
              </div>
            </MenuItem>

            {dataProfessors?.filter((el) => el?.visible && el?.enabled && (el?.treatment === 2 || currentCustomer?.token !== 'ARdNHNnzeRrSE5MW2sEk'))?.map(professorTMP => {
              return <MenuItem key={'professor-filter-' + professorTMP?.id} value={professorTMP?.id} sx={{ paddingBlock: 0, paddingInline: 1 }}>
                <div className='flex items-center space-x-2 p-1 text-gray-500'>
                  {(parseInt(professorTMP?.id))?
                    <UserProfilePic user={professorTMP?.user} size={18} clickable={false} checkPictureExists={false}/>
                  : null }
                  
                  <p className='text-gray-700'>{professorTMP?.name}</p>
                </div>
              </MenuItem>
            })}
          </CustomMultipleSelect>
        </div>
      </> : null }


      {(allTags?.length)?
        <div className='w-full 2xl:w-auto min-w-[100px] xl:min-w-[140px]'>
          <CustomMultipleSelect
            label="Segmentos"
            disabled={saveChanges}

            value={(userTagsFilter?.length)? userTagsFilter : ['null']}
            onChange={(event) => {
              const valueTMP = (
                (event.target.value?.length > 1 && event.target.value[event.target.value?.length-1] === 'null')? ['null']
                : (event.target.value?.length > 1)? event.target.value?.filter((el) => el !=='null')
                : (event.target.value?.length)? event.target.value 
                : ['null']
              );
              setUserTagsFilter(valueTMP);
            }}
            renderValue={(selected) => {
              var names = [];

              if (allTags?.length && selected?.length && selected[0] !== 'null') {
                for (var itemSelected of selected) {
                  const itemTMP = allTags?.find((x) => x?.name === itemSelected)
                  names.push(itemTMP?.name)
                }
              }

              return <p className={`${(names?.length > 4)? 'text-xs' : (names?.length > 2)? 'text-sm' : ''}`}>
                {(names?.length)? names?.join(', ').replace(/,([^,]*)$/, ' y $1') : 'Todos'}
              </p>
            }}
          >
            <MenuItem value={'null'} sx={{ paddingBlock: 0, paddingInline: 1 }}>
              <div className='flex items-center space-x-2 p-1'>
                <p>Todos</p>
              </div>
            </MenuItem>

            {allTags?.map(tag => {
              return <MenuItem key={'professor-filter-' + tag?.name} value={tag?.name} sx={{ paddingBlock: 0, paddingInline: 1 }}>
                <div className='flex items-center space-x-2 p-1'>
                  <p>{tag?.name}</p>
                </div>
              </MenuItem>
            })}
          </CustomMultipleSelect>
        </div>
      : null }




      

      <div className="w-full flex items-center space-x-2 md:min-w-[180px]">
        <ItemListStatesSelect
          selectedStates={stateFilter}
          setSelectedStates={setStateFilter}

          disabled={saveChanges}
          cookies={true}
        />

        {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer)) && 
          <div>
            <Tooltip disableInteractive title={`Administrar estados del los vídeos`} placement="bottom" arrow>
              <div id={`states-menu-tuto-${keyItem}`}  onMouseUp={() => setOpenProductionAdminModal(!openProductionAdminModal)} className={`max-w-[30px] w-[30px] max-h-[30px] h-[30px] justify-center flex items-center rounded border duration-300 ${(openProductionAdminModal)? 'text-white bg-blue-500 border-blue-600 ' : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-blue-500 border-gray-400 border-opacity-60'}`}>
                <IconButton color="inherit" type="small" disabled={saveChanges}>
                  <div className='w-[30px] h-[30px] flex items-center justify-center'>
                    {settingsIcon}

                    {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 5 || step === 6 || step === 7))? true : false) && 
                      <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 6)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                        <div /* Tutorial prop */
                          className="ml-[0] mt-[0] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                        ></div>
                      </div>
                    }
                  </div>
                </IconButton>
              </div>
            </Tooltip>


            <ModalProductionStateAdministration
              open={openProductionAdminModal}
              setOpen={setOpenProductionAdminModal}
              prodStates={(dataStates?.length)? dataStates : []}
              stateFilter={stateFilter}
              setStateFilter={setStateFilter}
            />
          </div>
        }
      </div>

    </div>


    <div className={`w-full flex flex-nowrap max-[760px]:flex-wrap space-x-2 max-[760px]:space-x-0 max-[760px]:space-y-[10px] ${(orderFilter?.includes('Date'))? '2xl:w-auto 2xl:min-w-[700px]' : 'xl:w-auto min-w-[300px]'}`}>
      <div className='w-full xl:max-w-[300px]'>
        <CustomSelect
          label="Orden"
          className='w-full flex-grow mui-bg-gray-100'
          disabled={saveChanges}

          value={(orderFilter)? orderFilter : 'Name ASC'}
          onChange={(event) => setOrderFilter(event.target.value)}
        >
          <MenuItem value={'Name ASC'}>Nombre A-Z</MenuItem>
          <MenuItem value={'Name DESC'}>Nombre Z-A</MenuItem>
          <MenuItem value={'Marks ASC'}>Número de marcas 0-9</MenuItem>
          <MenuItem value={'Marks DESC'}>Número de marcas 9-0</MenuItem>
          {/* <MenuItem value={'UpdatedDate ASC'}>Actualizados recientemente</MenuItem>
          <MenuItem value={'CreationDate ASC'}>Fecha de creación más reciente</MenuItem>
          <MenuItem value={'CreationDate DESC'}>Fecha de creación más antigua</MenuItem> */}
          <MenuItem value={'EndDate DESC'}>Fecha de publicación más reciente</MenuItem>
          <MenuItem value={'EndDate ASC'}>Fecha de publicación más antigua</MenuItem>
          <MenuItem value={'RecordDate DESC'}>Fecha de grabación más reciente</MenuItem>
          <MenuItem value={'RecordDate ASC'}>Fecha de grabación más antigua</MenuItem>
          <MenuItem value={'ReviewDate DESC'}>Revisión más reciente</MenuItem>
          <MenuItem value={'ReviewDate ASC'}>Revisión más antigua</MenuItem>
          <MenuItem value={'ProdState DESC'}>Estado finalizados al fondo</MenuItem>
          <MenuItem value={'ProdState ASC'}>Estados finalizados primero</MenuItem>
        </CustomSelect>
      </div>

      {(orderFilter?.includes('Date')) &&
        <div className='w-full min-[570px]:min-w-[350px] 2xl:max-w-[550px] flex flex-nowrap space-x-2'>
          <div className='w-full flex items-center space-x-1'>
            <CustomDatePicker
              label={`Inicio (${
                (orderFilter.includes('UpdatedDate '))? 'Actualización'
                : (orderFilter.includes('CreationDate '))? 'Creación' 
                : (orderFilter.includes('EndDate '))? 'Publicación' 
                : (orderFilter.includes('ReviewDate '))? 'Revisión' 
                : 'Publicación'
              })`}
              inputFormat="DD/MM/YYYY"

              value={(startFilterDate)? startFilterDate : null}
              onChange={(value)=> (new Date(value).getFullYear() > 1970)? setStartFilterDate(value) : setStartFilterDate(null)}
            />

            {(startFilterDate) &&
              <Tooltip disableInteractive title={"Quitar fecha inicio"} placement="bottom" arrow>
                <button 
                  type='button' 
                  onMouseUp={() => {
                    setStartFilterDate(null); 
                    if (!startDateRemoved) { localStorage.setItem(currentCustomer?.token + '-start-date-removed', getNextMonday()) }
                  }} 
                  className="text-white bg-red-500 rounded p-[5px] hover:opacity-50 duration-200"
                >
                  {cancelIcon}
                </button>
              </Tooltip>
            }
          </div>

          <div className='w-full flex items-center space-x-1'>
            <CustomDatePicker
              label={`Fin (${
                (orderFilter.includes('UpdatedDate '))? 'Actualización'
                : (orderFilter.includes('CreationDate '))? 'Creación' 
                : (orderFilter.includes('EndDate '))? 'Publicación' 
                : (orderFilter.includes('ReviewDate '))? 'Revisión' 
                : 'Publicación'
              })`}
              inputFormat="DD/MM/YYYY"

              value={(endFilterDate)? endFilterDate : null}
              onChange={(value)=> (new Date(value).getFullYear() > 1970)? setEndFilterDate(value) : setEndFilterDate(null)}
            />


            {(endFilterDate) &&
              <Tooltip disableInteractive title={"Quitar fecha fin"} placement="bottom" arrow>
                <button type='button' onMouseUp={() => setEndFilterDate(null)} className="text-white bg-red-500 rounded p-[5px] hover:opacity-50 duration-200">
                  {cancelIcon}
                </button>
              </Tooltip>
            }
          </div>
        </div>
      }
    </div>
  </div>
}