export default function VIDEO_EN_PERSONA_EN_REMOTO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="scale-[0.7]">
			<g>
				<g>
					<path d="M388.52,356.7c-0.79-21.2,0.5-17.05-3.77-37.57c-8.48-40.78-47.36-71.03-89.15-71.94c-30.25-0.66-60.54-0.71-90.79-0.01
						c-50.05,1.17-91.49,42.78-93.07,92.82c-0.46,14.61-0.25,3.37-0.13,17.98c0.08,9.27,5.61,15.3,13.64,15.41
						c8.35,0.12,13.79-6.02,14.04-15.81c0.44-17.41-0.77-9.18,1.7-26.3c4.64-32.12,33.04-55.73,65.51-56.5
						c14.61-0.34,29.24-0.06,43.86-0.06c0,0.45,0-0.45,0.01,0c18.17,0,36.61-0.61,54.46,1.79c31.2,4.2,54.94,32.84,55.91,64.38
						c0.43,13.84,0.08,1.83,0.17,15.68c0.06,10.5,5.28,16.78,13.8,16.81C383.26,373.44,388.91,367.16,388.52,356.7z"/>
					<path d="M250.99,219.26c45.29-0.22,82.46-37.93,82.19-83.41c-0.28-46.25-37.92-83.17-84.39-82.77c-45.3,0.39-82.02,38-81.78,83.79
						C167.24,182.64,204.79,219.48,250.99,219.26z M249.64,80.8c30.8-0.13,55.8,24.73,55.8,55.51c0.01,30.29-24.98,55.24-55.3,55.22
						c-30.42-0.02-55.31-24.78-55.41-55.13C194.63,105.97,219.35,80.93,249.64,80.8z"/>
				</g>
				<path d="M490.03,374.34c0.07-10.34-5.55-15.83-16.01-15.88c-4.55-0.02-9.1,0-14.05,0c0-2.31,0-4.08,0-5.86
					c0-67.93,0.02-135.86-0.01-203.8c-0.01-27.43-19-46.55-46.27-46.55c-23.26,0-46.52,0-69.77,0c3.25,8.98,5.2,18.46,5.76,28.22
					c21.36,0,42.72,0,64.07,0c11.62,0,18,6.41,18,18.09c0.01,68.36,0.01,136.72,0.01,205.08c0,1.37,0,2.75,0,4.54
					c-1.62,0.11-2.95,0.28-4.29,0.28c-9.94,0.01-19.89,0-29.83,0v0.09H296c-4.49,0.4-8.63,1.71-12.43,5.06
					c-1.92,1.69-5.39,2.24-8.16,2.27c-16.93,0.2-33.87,0.28-50.8-0.05c-3.34-0.07-7.06-1.52-9.87-3.42c-3.11-2.11-6.19-3.51-9.76-3.85
					H101.95v-0.1c-10.53-0.01-21.06-0.01-31.59-0.02c-0.54,0-1.07-0.18-2.01-0.34c-0.07-1.5-0.2-2.97-0.2-4.44
					c-0.01-68.36-0.01-136.72,0-205.08c0-11.71,6.35-18.1,17.98-18.1c21.46,0,42.92,0,64.38,0c0.58-9.84,2.58-19.31,5.8-28.2
					c-23.4,0.01-46.79,0.01-70.19,0.02c-3.86,0-7.82,0.3-11.57,1.15c-20.89,4.71-34.58,22.37-34.59,44.63
					c-0.04,68.21-0.02,136.42-0.02,204.63c0,1.66,0,3.31,0,5.77c-5.31,0-10.14-0.05-14.98,0.01c-9.36,0.13-15.05,5.84-15.1,15.13
					c-0.04,7.92-0.18,15.84,0.05,23.75c0.15,5.38,0.18,10.91,1.43,16.1c4.91,20.38,22.58,33.46,44.65,33.46
					c129.33,0.02,258.67,0.01,388,0.01c0.42,0,0.83,0,1.25,0c22.73-0.33,40.88-15.17,44-37.65C490.85,397.82,489.95,386,490.03,374.34z
					M461.42,405.69c-0.82,7.2-6.9,12.17-14.32,12.93c-1.24,0.13-2.5,0.09-3.75,0.09c-128.93,0-257.86,0-386.8,0
					c-10.06,0-17.01-4.6-18.07-13.02c-0.77-6.11-0.15-12.39-0.15-19.08c1.71,0,3.2,0,4.7,0c50.71,0,101.42-0.03,152.13,0.09
					c2.61,0.01,5.45,0.88,7.77,2.13c6.52,3.53,13.38,5.3,20.76,5.29c17.51-0.03,35.01-0.03,52.52,0c7.83,0.01,15.09-1.87,21.82-5.95
					c1.46-0.88,3.35-1.48,5.04-1.48c51.82-0.08,103.65-0.06,155.47-0.05c0.95,0,1.89,0.19,3.02,0.31
					C461.57,393.3,462.12,399.56,461.42,405.69z"/>
			</g>
		</svg>
	</>
}
