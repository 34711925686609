import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';







export default function NumberOfMarksList({ video, showIcon, flex, small, pr }) {


  const { currentUser, currentCustomer, getJsonString, marksNearName } = useContext(AuthContext)




  const stateHistory = (video?.search_production_state_change_history)? JSON.parse(video?.search_production_state_change_history) : null;
  const lastState = (stateHistory?.length)? stateHistory[0]?.video_state_name?.split('-')[1] : null;

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 1000,
    },
  });









  const [chyronState, setChyronState] = useState(1);
  const [chyronMotionState, setChyronMotionState] = useState(1);

  const [numberOfMarks, setNumberOfMarks] = useState((video?.search_number_of_marks?.split('/')?.length)? video?.search_number_of_marks?.split('/') : null);
  const [numberOfNotes, setNumberOfNotes] = useState((video?.search_number_of_notes?.split('/')?.length)? video?.search_number_of_notes?.split('/') : null);
  const [numberOfMarksTrad, setNumberOfMarksTrad] = useState((video?.search_number_of_marks_trad?.split('/')?.length)? video?.search_number_of_marks_trad?.split('/') : null);


  const revisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-11-11-25-11t-25 11q-11 11-11 25.5t11 25.5l84 85q11 11 25.5 11t25.5-11l170-170q11-11 11-25t-11-25q-11-11-25.5-11T582-585L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-23 12.5-41t33.5-26l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26t12.5 41v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Z"/></svg>
  const nonRevisedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m438-441-60-60q-10.636-11-24.818-11Q339-512 328-501q-11 11-11 25.667 0 14.666 11 25.333l84 85q10.909 11 25.455 11Q452-354 463-365l170-170q11-11 11-25t-11-25q-11-11-25.667-11-14.666 0-25.333 11L438-441Zm42 341q-6 0-12-.5t-11-2.5q-131-43-210-159t-79-253v-180q0-22.692 12.5-40.846Q193-754 214-762l240-92q13-5 26-5t26 5l240 92q21 8 33.5 26.154T792-695v180q0 137-79 253T503-103q-5 2-11 2.5t-12 .5Zm0-71q104-32.25 172-129t68-215v-180l-240-92-240 92v180q0 118.25 68 215t172 129Zm0-308Z"/></svg>
  const tradIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M11.104 18.333q-.5 0-.719-.323-.218-.322-.052-.802l3.063-8.104q.125-.333.479-.562.354-.23.708-.23.355 0 .698.23.344.229.469.562l3.083 8.104q.167.48-.062.802-.229.323-.729.323-.23 0-.427-.145-.198-.146-.282-.355l-.708-2.062h-4.063l-.708 2.062q-.083.209-.302.355-.219.145-.448.145Zm1.979-4.021h2.979l-1.458-4.104h-.062ZM5.958 7.083q.334.605.709 1.115.375.51.875 1.073.937-1 1.531-2.052.594-1.052.989-2.24H1.667q-.355 0-.594-.239-.24-.24-.24-.594t.24-.594q.239-.24.594-.24h5v-.833q0-.354.239-.594.24-.239.594-.239t.594.239q.239.24.239.594v.833h5q.355 0 .594.24.24.24.24.594t-.24.594q-.239.239-.594.239h-1.604q-.417 1.479-1.156 2.875-.74 1.396-1.844 2.625l1.979 2.042-.625 1.708-2.562-2.562-3.583 3.583q-.23.229-.584.229t-.583-.25q-.25-.229-.25-.583t.25-.604l3.604-3.604q-.563-.646-1.042-1.303-.479-.656-.854-1.385-.229-.438-.01-.771t.739-.333q.209 0 .427.125.219.125.323.312Z"/></svg>;




  useEffect(() => {
    setNumberOfMarks((video?.search_number_of_marks?.split('/')?.length)? video?.search_number_of_marks?.split('/') : null);
    setNumberOfNotes((video?.search_number_of_notes?.split('/')?.length)? video?.search_number_of_notes?.split('/') : null);
    setNumberOfMarksTrad((video?.search_number_of_marks_trad?.split('/')?.length)? video?.search_number_of_marks_trad?.split('/') : null);

    if (currentCustomer?.setting_initial_chyron && video?.professors?.length) {
      const chyronOverlapStates = (video?.chyron_states)? getJsonString(video?.chyron_states , true) : null;
      var chyronStateTMP = 3;
      var chyronMotionStateTMP = 5;

      if (video?.professors?.length) {
        var hasOverlapStates = false;
  
        for (var profTMP of video?.professors) {
          const deffault_positions = (profTMP?.positions)? getJsonString(profTMP?.positions , true) : null;
          const deffault_position = (deffault_positions && video?.language && deffault_positions[video?.language])? deffault_positions[video?.language] : null;
  
          if (currentUser?.rol?.includes('translator')) {

            const stateTMP = (video?.is_translation_of)? ((deffault_position?.trad_done)? true : false) : ((parseInt(deffault_position?.revision_state))? parseInt(deffault_position?.revision_state) : 1);
            chyronStateTMP = (video?.is_translation_of)? ((chyronStateTMP !== true || stateTMP === false)? false : true) : ((parseInt(stateTMP) < chyronStateTMP)? stateTMP : chyronStateTMP)

          } else {
            if (chyronOverlapStates?.length) {
              for (var statesTMP of chyronOverlapStates) {
                if (parseInt(statesTMP?.professor_id) === parseInt(profTMP?.id) && statesTMP?.language === video?.language) {
                  if (parseInt(statesTMP?.state) < chyronStateTMP && chyronStateTMP !== 2) { chyronStateTMP = parseInt(statesTMP?.state); }
                  if (parseInt(statesTMP?.motion_state) < chyronMotionStateTMP) { chyronMotionStateTMP = parseInt(statesTMP?.motion_state); }
                  hasOverlapStates = true
                }
              }
            }

            if (!hasOverlapStates || !deffault_position?.state || !deffault_position?.motion_state || parseInt(deffault_position?.state) === 2 || parseInt(deffault_position?.motion_state) === 1) {
              chyronStateTMP = (deffault_position && parseInt(deffault_position?.state) && deffault_position?.state < chyronStateTMP)? deffault_position?.state : 1;
              chyronMotionStateTMP = (deffault_position && parseInt(deffault_position?.motion_state) && deffault_position?.motion_state < chyronStateTMP)? deffault_position?.motion_state : 1;
            }
          }
        }
      }

      setChyronState(chyronStateTMP)
      setChyronMotionState(chyronMotionStateTMP)  

    } else {
      setChyronState(1)
      setChyronMotionState(1)
    }

  }, [video])





  return (
    <div
      className={`w-full flex
        ${(
            !flex
            && currentUser?.rol !== 'user'
            && currentUser?.rol !== 'super_user'
            && !currentUser?.rol?.includes('translator')
            && ((numberOfMarks && 5 in numberOfMarks && parseInt(numberOfMarks[5])? numberOfMarks[5] : 0) > 0)
          )? 'max-w-[150px]' : (flex)? 'max-w-[70px]' : 'max-w-[150px]'
        }
        ${(marksNearName && !small && pr)? 'pr-[30px]' : ''}
        ${(small)? 'max-h-[15px] text-[13px]' : ''}
        ${(small)? 'flex items-center space-x-2' : (flex)? 'flex-wrap justify-center items-center space-y-1' : 'justify-between items-center space-x-2'}
      `}
    >

      {(currentUser?.rol !== 'user' && currentUser?.rol !== 'super_user' && !currentUser?.rol?.includes('translator'))? <>
        


        
        <div className={`flex items-center space-x-[1px] ${(small)? '' : 'w-full'} ${(flex)? 'justify-center' : 'justify-start'}`}>
          {(!video?.is_sound_media && !currentCustomer?.setting_tittle_is_relevant)?
            <p 
              className={`${
                (video?.title_disabled)? 'text-gray-300 line-through'
                : (parseInt(video?.state) === 3)? 'text-green-500' 
                : (parseInt(video?.motion_state) === 4)? 'text-purple-500'
                : ((lastState === 'green' || lastState === 'yellow') && video?.category_id)? 'text-red-500'
                : (parseInt(video?.motion_state) === 3)? 'text-blue-500'
                : (parseInt(video?.motion_state) === 2)? 'text-orange-500'
                : (parseInt(video?.state) === 2)? 'text-yellow-500'
                : 'text-gray-300'
              }`}
            >T</p>
          : null }

          {(!video?.is_sound_media && currentCustomer?.setting_initial_chyron && (!video?.search_substituted_chyrons || !video?.search_substituted_chyrons?.split(',')?.includes(video?.professor?.id)))? <>
            <p 
              className={
                `px-[1px] 
                ${
                  (video?.chyrons_disabled)? 'text-gray-300 line-through'
                  : (chyronState === 3)? 'text-green-500'
                  : (chyronState === 2)? 'text-yellow-500'
                  : (chyronMotionState === 4)? 'text-purple-500'
                  : ((lastState === 'green' || lastState === 'yellow') && video?.category_id)? 'text-red-500'
                  : (chyronMotionState === 3)? 'text-blue-500'
                  : (chyronMotionState === 2)? 'text-orange-500'
                  : 'text-gray-300'
                }`
              }
            >C</p>
          </> : null }

          {(numberOfNotes && 0 in numberOfNotes && parseInt(numberOfNotes[0]) > 0)?
            <CustomWidthTooltip
              disableInteractive
              title={
                <div className='space-y-[2px]'>
                  <p className='text-gray-200'>{numberOfNotes[0]} Notas totales</p>
                  <hr className='py-1' />
                  {(parseInt(numberOfNotes[1]))? <p className='text-red-200'>{numberOfNotes[1]} Notas pendientes</p> : null }
                  {(parseInt(numberOfNotes[2]))? <p className='text-yellow-200'>{numberOfNotes[2]} Notas con correcciones</p> : null }
                  {(parseInt(numberOfNotes[3]))? <p className='text-blue-200'>{numberOfNotes[3]} Notas creadas</p> : null }
                  {(parseInt(numberOfNotes[4]))? <p className='text-purple-200'>{numberOfNotes[4]} Notas insertadas</p> : null }
                  {(parseInt(numberOfNotes[5]))? <p className='text-green-200'>{numberOfNotes[5]} Notas Finalizadas</p> : null }
                </div>
              }
              placement="bottom"
              arrow
            >
              <p 
                className={`${
                  (parseInt(numberOfNotes[5]) === parseInt(numberOfNotes[0]))? 'text-green-500' 
                  : ((lastState === 'green' || lastState === 'yellow')? 'text-red-500' : 'text-yellow-500')
                }`}
              >N</p>
            </CustomWidthTooltip>
          : null }
        </div>

        <div className={`text-gray-600 flex items-baseline space-x-[1px] ${(small)? '' : 'w-full min-w-[50px]'} ${(flex)? 'justify-center' : 'justify-end'}`}>
          {(numberOfMarks && 1 in numberOfMarks && parseInt(numberOfMarks[1]))?
            <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarks[1])) && 'opacity-20'}`}>
              <p className={`text-red-500`}>{(parseInt(numberOfMarks[1])? numberOfMarks[1] : 0)}</p>
              <p>/</p>
            </div>
          : null }
          
          {(numberOfMarks && 2 in numberOfMarks && parseInt(numberOfMarks[2]))?
            <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarks[2])) && 'opacity-20'}`}>
              <p className={`${((lastState === 'green' || lastState === 'yellow')? 'text-red-500' : 'text-yellow-500')}`}>{(parseInt(numberOfMarks[2])? numberOfMarks[2] : 0)}</p>
              <p>/</p>
            </div>
          : null }

          {(numberOfMarks && 3 in numberOfMarks && parseInt(numberOfMarks[3]))?
            <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarks[3])) && 'opacity-20'}`}>
              <p className={`text-blue-500`}>{(parseInt(numberOfMarks[3])? numberOfMarks[3] : 0)}</p>
              <p>/</p>
            </div>
          : null }

          {(numberOfMarks && 4 in numberOfMarks && parseInt(numberOfMarks[4]))?
            <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarks[4])) && 'opacity-20'}`}>
              <p className='text-purple-500'>{(parseInt(numberOfMarks[4])? numberOfMarks[4] : 0)}</p>
              <p>/</p>
            </div>
          : null }

          {(numberOfMarks && 5 in numberOfMarks && parseInt(numberOfMarks[5]))?
            <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarks[5])) && 'opacity-20'}`}>
              <p className='text-green-500'>{(parseInt(numberOfMarks[5])? numberOfMarks[5] : 0)}</p>
              <p>/</p>
            </div>
          : null }

          <p className={`whitespace-nowrap ${(numberOfMarks && 0 in numberOfMarks && parseInt(numberOfMarks[0]))? '' : 'opacity-50'}`}>{(numberOfMarks && 0 in numberOfMarks && parseInt(numberOfMarks[0])? numberOfMarks[0] : '---')}</p>
        </div>




      </> : (currentUser?.rol?.includes('translator'))? <>



        {(!video?.is_sound_media)?
          <div className={`w-full flex items-center space-x-[1px] ${(flex)? 'justify-center' : 'justify-start'}`}>
            <p className={`${((video?.is_translation_of)? video?.trad_done : (parseInt(video?.revision_state) >= 2))? 'text-blue-500' : 'text-gray-300'}`}>T</p>
            <p className={`px-1 ${(parseInt(chyronState) === 2 || chyronState === true)? 'text-blue-500' : 'text-gray-300'}`}>C</p>
          </div>
        : <div className='w-full'></div>}

        {(numberOfMarksTrad) && 
          <div className={`text-gray-600 flex items-baseline space-x-[1px] min-w-[40px] ${(flex)? 'w-full justify-center' : 'justify-end'}`}>
            {(numberOfMarksTrad && 0 in numberOfMarksTrad && parseInt(numberOfMarksTrad[1]))?
              <Tooltip disableInteractive title={(video?.is_translation_of)? "Marcas traducidas" : "Marcas revisadas"} placement="bottom" arrow>
                <div className={`flex justify-end space-x-[1px] duration-200 ${!(parseInt(numberOfMarksTrad[1])) && 'opacity-20'}`}>
                  <p className={`text-blue-500`}>{(parseInt(numberOfMarksTrad[1])? numberOfMarksTrad[1] : 0)}</p>
                  <p>/</p>
                </div>
              </Tooltip>
            : null }


            {(numberOfMarksTrad && 0 in numberOfMarksTrad)?
              <Tooltip disableInteractive title={"Marcas totales"} placement="bottom" arrow>
                <p className={(parseInt(numberOfMarksTrad[0]))? '' : 'opacity-50'}>{(parseInt(numberOfMarksTrad[0])? numberOfMarksTrad[0] : '---')}</p>
              </Tooltip>
            : null }
          </div>
        }


      
      </> : <p className='w-full whitespace-nowrap'>{(numberOfMarks && 0 in numberOfMarks && parseInt(numberOfMarks[0]))? numberOfMarks[0] : 'Sin marcas'}</p> }





      {(showIcon && !currentUser?.rol?.includes('user') && !video?.is_translation_of) && 
        <Tooltip disableInteractive title={(video?.search_reviewed)? 'Revisión completada' + ((video?.search_reviewed_label)? ` por ${video?.search_reviewed_label?.split(' / ')[1]}` : '') : 'Revisión no completada'} placement="bottom" arrow>
          <div className={`py-1 flex items-start justify-center w-[12px] ${(small)? 'scale-90' : ''} ${(video?.search_reviewed)? 'text-blue-500' : 'text-gray-400 opacity-70'}`}>
            {(video?.search_reviewed)? <p className='scale-90'>{revisedIcon}</p> : <p className='scale-90'>{nonRevisedIcon}</p>}
          </div>
        </Tooltip>
      }

      {(showIcon && !currentUser?.rol?.includes('user') && video?.is_translation_of) && 
        <Tooltip disableInteractive title={(video?.search_translated)? 'Traducción completada' + ((video?.search_translated_label)? ` por ${video?.search_translated_label?.split(' / ')[1]}` : '') : 'Traducción no completada'} placement="bottom" arrow>
          <div className={`py-1 flex items-start justify-center w-[12px] ${(small)? 'scale-90' : ''} ${(video?.search_translated)? 'text-blue-500' : 'text-gray-400 opacity-70'}`}>
            {(video?.search_translated)? <p className='scale-90'>{tradIcon}</p> : <p className='scale-90'>{tradIcon}</p>}
          </div>
        </Tooltip>
      }




    </div>
  );
}