export default function VimeoUrlSettingCell({ ogVideo, small, hiddeDates, fill, localization }) {


  const vimeoIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill='currentColor'><path d="M0 0v24h24v-24h-24zm19.249 10.732c-1.628 3.478-5.558 8.213-8.042 8.213-2.448 0-2.802-5.221-4.139-8.696-.658-1.709-1.083-1.317-2.316-.454l-.752-.97c1.798-1.581 3.599-3.418 4.705-3.52 1.245-.12 2.012.731 2.299 2.554.379 2.396.908 6.114 1.832 6.114.72 0 2.494-2.95 2.585-4.004.162-1.544-1.135-1.591-2.261-1.109 1.781-5.836 9.194-4.761 6.089 1.872z"/></svg>;

  
  const video = (localization)? ((ogVideo?.list_localizations?.length)? ogVideo?.list_localizations[0] : (ogVideo?.is_translation_of)? ogVideo : null) : ogVideo;
  const label = (video?.vimeo_url_change_label)? video?.vimeo_url_change_label : null;
  /* const name = (label)? ((label?.includes(' / '))? label?.split(' el ')[0]?.split(' / ')[1] : label?.split(' el ')[0]) : null; */
  const small_name = (label)? ((label?.includes(' / '))? label?.split(' el ')[0]?.split(' / ')[0] : label?.split(' el ')[0]) : null;
  const date = (label)? label?.split(' el ')[1]?.replaceAll(' a las ', ' ') : null;


  return (video)? <>

    <div className={`relative whitespace-nowrap justify-center rounded ${(hiddeDates)? 'flex items-center bg-gray-400 bg-opacity-[0.25]' : 'flex-col items-end'} ${(fill)? 'w-full h-full' : ''} ${(small)? 'pr-1 pl-[2px] py-[2px] text-[12px] bg-gray-400 bg-opacity-[0.25]' : `p-1 text-[14px]`} `}>
      <div className={`flex justify-center items-center space-x-[4px]`}>
        {
          (video?.search_review_version && video?.vimeo_url)? <div className={`rounded overflow-hidden text-sky-500 ${(label || small)? 'scale-[0.85]' : 'scale-[1.1]'}`}>{vimeoIcon}</div> 
          : (video?.vimeo_url)? <div className={`${(small)? 'text-[12px]' : `${(label)? 'text-[14px]' : 'text-[17px]'}`}`}><p>🤖</p></div>
          : <div className={`rounded overflow-hidden text-gray-500 opacity-0 ${(label || small)? 'scale-[0.85]' : 'scale-[1.1]'}`}>{vimeoIcon}</div> 
        }
        
        {(label && video?.vimeo_url)? <p className='text-[11px] font-bold'>{small_name}</p> : null }
      </div>

      {(!small && !hiddeDates && video?.vimeo_url && date)? 
        <div className='h-[10px] pt-1 opacity-70 flex justify-center items-center'>
            <p className='text-[10px] font-bold'>{date}</p>
        </div>
      : null }
    </div>

  </> : <p className='text-gray-500 text-[11px]'>---</p> ;
}