export default function VIDEO_EN_PERSONA_EN_PLATO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor'>
			<g>
				<path d="M385.91,390.76c-0.78-20.8,0.49-16.72-3.69-36.86c-8.32-40.01-46.47-69.69-87.47-70.58c-29.68-0.65-59.4-0.7-89.08-0.01
					c-49.11,1.15-89.77,41.97-91.32,91.08c-0.45,14.33-0.25,3.3-0.13,17.65c0.07,9.09,5.5,15.01,13.38,15.12
					c8.2,0.11,13.53-5.91,13.78-15.51c0.43-17.08-0.76-9.01,1.67-25.8c4.55-31.52,32.42-54.68,64.27-55.43
					c14.34-0.34,28.69-0.06,43.03-0.06c0,0.44,0-0.44,0.01,0c17.83,0,35.92-0.6,53.43,1.76c30.61,4.12,53.9,32.22,54.85,63.17
					c0.42,13.58,0.08,1.79,0.16,15.38c0.06,10.3,5.18,16.47,13.54,16.5C380.75,407.18,386.3,401.02,385.91,390.76z"/>
				<path d="M250.97,255.9c44.44-0.21,80.91-37.22,80.64-81.84c-0.27-45.38-37.21-81.6-82.81-81.22
					c-44.45,0.38-80.47,37.29-80.24,82.21C168.8,219.98,205.64,256.12,250.97,255.9z M249.65,120.05
					c30.22-0.13,54.75,24.26,54.75,54.47c0.01,29.72-24.51,54.2-54.26,54.18c-29.85-0.02-54.27-24.31-54.37-54.09
					C195.67,144.75,219.93,120.18,249.65,120.05z"/>
			</g>
		</svg>
	</>
}

