import gql from "graphql-tag";

export default gql`
  mutation UpdateUserState(
    
    $own_user_token: String!, 
    $own_customer_token: String!, 
    

    $id: Int,
    $positions: String, 
    
  ) {
    createOrUpdateUser(input: { 

      own_user_token: $own_user_token, 
      own_customer_token: $own_customer_token, 

      id: $id,
      positions: $positions, 

    }) {

      id
      name
      professor_code
      positions

    }
  }
`;
