import { useRef, useEffect } from 'react'
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';





export default function ReactQuillSimpleImput({

  id,
  label,
  placeholder,
  singleLine,
  smallEditor,
  forcesmallEditor,
  style,
  replacementRef,

  disabled,
  deffVal,
  readOnly,
  
  value,
  onChange,
  onKeyDown,
  enterException,

}) {




  const quillRef = useRef();
  const quillFinalRef = (replacementRef)? replacementRef : quillRef;
  var startFocus = true;



  var formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    /* 'image', */
    /* 'video', */
  ];







  useEffect(() => {
    if (quillFinalRef.current.editor && enterException) {
      quillFinalRef.current.editor.keyboard.bindings[13].unshift({
        key: 13,
        handler: (range, context) => {
          enterException(range, context)
        }
      });
    }
  }, [quillFinalRef]);









  return (
    <div className={`w-full overflow-visible ${(disabled)? 'opacity-50 pointer-events-none' : ''}`}>
      {(label)? <p className='text-xs px-[6px] text-gray-500 h-[17px]'>{label}</p> : null }
      
      
      {(deffVal)?
        <ReactQuill
          id={id} 
          ref={quillFinalRef}
          placeholder={placeholder}
          onFocus={(event) => {
            if (startFocus && event?.index === 0 && quillFinalRef?.current?.editor && value?.length) {
              quillFinalRef.current.editor.setSelection(value?.length, 0);
              startFocus = false;
            }
          }}

          readOnly={disabled || readOnly}
          defaultValue={(value)? value?.replaceAll('\n', '<br>') : ''}
          onChange={(val, delta, source) => {
            if (source?.includes('user') && value !== val && value?.replaceAll('</p>', '')?.replaceAll('<p>', '') !== val?.replaceAll('</p>', '')?.replaceAll('<p>', '')) {
              val = (val)? val?.replace(/<\/?span[^>]*>/g,"")?.replaceAll('\n', '<br>') : null;
              if (singleLine && val) { val = val?.replaceAll('</p><p>', '')?.replaceAll('<br>', ''); }
              onChange(val, quillFinalRef)
            }
          }}
          onKeyDown={(event) => (typeof onKeyDown !== "undefined")? onKeyDown(event, quillFinalRef) : null}

          formats={formats}
          
          className={`
            react-quill-textarea overflow-visible email-viewer w-full cursor-text rounded 
            ${(style === 3)? 'react-quill-minimalist-white' : (style === 2)? 'react-quill-minimalist' : (style)? 'react-quill-pers' : 'react-quill-pers-no-toolbox'} 
            ${(style === 3)? 'small-big-editor-white' : (style === 2)? 'small-big-editor' : (forcesmallEditor)? 'small-force-editor' : (singleLine)? '' : (smallEditor)? 'small-editor' : 'big-editor'} 
            ${(readOnly)? 'readonly-max-size bg-gray-200 bg-opacity-50' : (style && style > 1)? '' : 'bg-white'}
          `}
          theme="snow"
        />
      :
        <ReactQuill
          id={id} 
          ref={quillFinalRef}
          placeholder={placeholder}
          onFocus={(event) => {
            if (startFocus && event?.index === 0 && quillFinalRef?.current?.editor && value?.length) {
              quillFinalRef.current.editor.setSelection(value?.length, 0);
              startFocus = false;
            }
          }}
          
          readOnly={disabled || readOnly}

          value={(value)? value?.replaceAll('\n', '<br>') : ''}
          onChange={(val, delta, source) => {
            if (source?.includes('user') && value !== val && value?.replaceAll('</p>', '')?.replaceAll('<p>', '') !== val?.replaceAll('</p>', '')?.replaceAll('<p>', '')) {
              val = (val)? val?.replace(/<\/?span[^>]*>/g,"")?.replaceAll('\n', '<br>') : null;
              if (singleLine && val) { val = val?.replaceAll('</p><p>', '')?.replaceAll('<br>', ''); }
              onChange(val, quillFinalRef)
            }
          }}
          onKeyDown={(event) => (typeof onKeyDown !== "undefined")? onKeyDown(event, quillFinalRef) : null}
          
          formats={formats}

          className={`
            react-quill-textarea overflow-visible email-viewer w-full cursor-text rounded 
            ${(style === 3)? 'react-quill-minimalist-white' : (style === 2)? 'react-quill-minimalist' : (style)? 'react-quill-pers' : 'react-quill-pers-no-toolbox'} 
            ${(style === 3)? 'small-big-editor-white' : (style === 2)? 'small-big-editor' : (forcesmallEditor)? 'small-force-editor' : (singleLine)? '' : (smallEditor)? 'small-editor' : 'big-editor'} 
            ${(readOnly)? 'readonly-max-size bg-gray-200 bg-opacity-50' : (style && style > 1)? '' : 'bg-white'}
          `}
          theme="snow"
        />
      }

      
    </div>
  );
}