import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';








const VideoHomeEditModeListInputs = ({

  video,
  extraMarks,
  
  dataVideoNames,
  categoryOptions,

}) => {



  const { currentCustomer } = useContext(AuthContext)

  const [topicNameExists, setTopicNameExists] = useState('');

  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;






  function checkTopicNameFunc(value, video) {
    var nameRepeated = false;
    var topicNameExistsTMP = topicNameExists;

    if (dataVideoNames?.length && value && value?.replaceAll(' ', '') !== '') {
      dataVideoNames.map((videoTMP) => {
        if (videoTMP.name.replaceAll(' ', '').toLowerCase() === value.replaceAll(' ', '').toLowerCase() && value.replaceAll(' ', '').toLowerCase() !== video?.name.replaceAll(' ', '').toLowerCase()) {
          nameRepeated = true;
        }
      });
    }

    if (nameRepeated && !topicNameExistsTMP.includes('-' + video.id + '-')) {
      topicNameExistsTMP = topicNameExistsTMP + '-' + video.id + '-'
    } else if (!nameRepeated && topicNameExistsTMP.includes('-' + video.id + '-')) {
      topicNameExistsTMP = topicNameExistsTMP.replaceAll(('-' + video.id + '-'), '')
    }

    setTopicNameExists(topicNameExistsTMP)
  }




  
  return <>
    <div className='w-full space-y-[2px] cursor-default'>
      <div className='w-full flex space-x-1 mb-[3px]'>
        <TextField 
          defaultValue={video?.name}
          onKeyUp={(event)=> {
            checkTopicNameFunc(event.target.value, video)
          }}
          id={video?.id}
          name={"name-"+video?.id}
          label="Nombre"
          variant="outlined"
          className='w-full min-w-[180px] max-w-[250px] mui-bg-gray-50'
          sx={{ "& .MuiInputBase-input": { height: 10, paddingBottom: 0.6, paddingInline: 1 } }}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}
          size="small"
          error={topicNameExists.includes('-'+video.id+'-')}
        />

        <TextField 
          defaultValue={video?.vimeo_url}
          id={"vimeoURL-"+video?.id}
          name={"vimeoURL-"+video?.id}
          label="Vimeo URL"
          variant="outlined"
          className='w-full mui-bg-gray-50'
          sx={{ "& .MuiInputBase-input": { height: 10, paddingBottom: 0.6, paddingInline: 1 } }}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}
          size="small"
        />


        <TextField 
          type={'number'} 
          defaultValue={(parseInt(video?.number_of_words))? parseInt(video?.number_of_words) : 0}
          id={"number-of-words-"+video?.id}
          name={"number-of-words-"+video?.id}
          label="N. Palabras" 
          variant="outlined" 
          className='w-full md:max-w-[95px] mui-bg-gray-50' 
          sx={{ "& .MuiInputBase-input": { height: 10, paddingBottom: 0.6, paddingInline: 1 } }}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}
          size="small"
        />

        <Tooltip disableInteractive title={"Copiar enlace del vídeo"} placement="bottom" arrow>
          <div>
            <CopyToClipboard /* VIDEO URL */
              text={(process.env.REACT_APP_ROUTE + currentCustomer?.abbreviated_name + '/' + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra')  + '/' + video?.url_name)}
            >
              <button className='px-[2px] text-gray-500 rounded hover:bg-gray-300 hover:text-gray-700 active:bg-gray-400 cursor-pointer duration-200'>
                {copyIcon}
              </button>
            </CopyToClipboard>
          </div>
        </Tooltip>

      </div>

      
      <div className='w-full flex space-x-1 justify-end items-center text-[11px] pt-[3px] pb-[6px]'>
        <TextField 
          defaultValue={video?.title}
          id={"title-"+video?.id}
          name={"title-"+video?.id}
          label="Título"
          variant="outlined"
          className='w-full mui-bg-gray-50'
          sx={{ "& .MuiInputBase-input": { height: 10, paddingBottom: 0.6, paddingInline: 1 } }}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}
          size="small"
        />
        
        {/* <ReactQuillSimpleImput 
          id={"title-"+video?.id} 
          placeholder="Título"
          singleLine={true}
          value={(editTitle)? editTitle : ''}
          superSmall={true}

          onChange={(value) => {
            setEditTitle(value)
          }} 
          onKeyDown={(event) => {
            if((event.code === 'Enter')) {
              if (isCorrecting) {
                saveCorrectionTitleFunc()
              } else {
                saveTitleFunc()
              }
            } else if (event.code === 'Escape') {
              setIsCorrecting(false)
              setShowEditTitle(false)
            }
          }}
        /> */}

        <p className={`w-full text-center max-w-[65px] py-[3px] whitespace-nowrap bg-gray-300 rounded border border-gray-400 ${(video?.has_transcription)? '' : 'opacity-40'}`}>{(video?.has_transcription === 1)? 'Transc.P' : 'Transc'}</p>
        <p className={`w-full text-center max-w-[50px] py-[3px] whitespace-nowrap bg-gray-300 rounded border border-gray-400 ${(parseInt(video?.has_subtitulation))? '' : 'opacity-40'}`}>Subs {(video?.has_subtitulation)? video?.has_subtitulation : ''}</p>
        <p className={`w-full text-center max-w-[50px] py-[3px] whitespace-nowrap bg-gray-300 rounded border border-gray-400 ${(video?.has_translation)? '' : 'opacity-40'}`}>Trad</p>
      </div>


      {(parseInt(video?.module?.id) === 1 || parseInt(video?.module?.id) === 31)? 
        <div className='w-full flex items-center space-x-1'> {/* HERE ------------------------------------------------------------------------- */}
          <TextField 
            type={'number'} 
            defaultValue={(parseInt(extraMarks[0]?.text_motions))? parseInt(extraMarks[0]?.text_motions) : 0}
            id={"extra-text-motions-"+video?.id}
            name={"extra-text-motions-"+video?.id}
            label="N. Motions de texto" 
            variant="outlined" 
            className='w-full mui-bg-gray-50'
            sx={{ "& .MuiInputBase-root": { height: 23 } }}
            InputProps={{ style: { fontSize: 12, minWidth: 100 } }}
            InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}  
            size="small"
          />

          <TextField 
            type={'number'} 
            defaultValue={(parseInt(extraMarks[0]?.chart_motions))? parseInt(extraMarks[0]?.chart_motions) : 0}
            id={"extra-chart-motions-"+video?.id}
            name={"extra-chart-motions-"+video?.id}
            label="N. Motions formato esquema" 
            variant="outlined" 
            className='w-full mui-bg-gray-50'
            sx={{ "& .MuiInputBase-root": { height: 23 } }}
            InputProps={{ style: { fontSize: 12, minWidth: 100 } }}
            InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}  
            size="small"
          />
          <input 
            type='checkbox' 
            id={"extra-text-motions-duplicated-"+video?.id}
            name={"extra-text-motions-duplicated-"+video?.id}
            defaultChecked={(extraMarks[0]?.text_motions_duplicated)? true : false}
          />

          <TextField 
            type={'number'} 
            defaultValue={(parseInt(extraMarks[0]?.motion_number_of_words))? parseInt(extraMarks[0]?.motion_number_of_words) : 0}
            id={"extra-number-of-words-"+video?.id}
            name={"extra-number-of-words-"+video?.id}
            label="N. Palabras traducidas" 
            variant="outlined" 
            className='w-full mui-bg-gray-50'
            sx={{ "& .MuiInputBase-root": { height: 23 } }}
            InputProps={{ style: { fontSize: 12, minWidth: 100 } }}
            InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}  
            size="small"
          />


          <TextField 
            type={'number'} 
            defaultValue={(parseInt(extraMarks[0]?.phone_adaptations))? parseInt(extraMarks[0]?.phone_adaptations) : 0}
            id={"extra-phone-adaptations-"+video?.id}
            name={"extra-phone-adaptations-"+video?.id}
            label="N. Adaptaciones para móviles" 
            variant="outlined" 
            className='w-full mui-bg-gray-50'
            sx={{ "& .MuiInputBase-root": { height: 23 } }}
            InputProps={{ style: { fontSize: 12, minWidth: 100 } }}
            InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}  
            size="small"
          />
        </div>
      :
        <TextField
          select
          defaultValue={parseInt(video?.category_id)}
          id={"category-"+video?.id}
          name={"category-"+video?.id}
          label="Formato"
          className='w-full mui-bg-gray-50'
          sx={{ "& .MuiInputBase-root": { height: 27 } }}
          InputProps={{ style: { fontSize: 12 } }}
          InputLabelProps={{ sx: { fontSize: 15, top: "-7px", left: "-4px", "&.MuiInputLabel-shrink": { top: 2 } } }}
          size="small"
        >
          {categoryOptions?.map(element => {
            if (parseInt(element?.id)) {
              return <MenuItem key={'cat-option-' + element?.id} value={parseInt(element?.id)}>{element?.label}</MenuItem>
            }
          })}
        </TextField>
      }

    </div>
  </>
}

export default VideoHomeEditModeListInputs
