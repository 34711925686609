import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function CustomAutocomplete({

    id,
    label,
    className,
    disabled,
    listFontSize,
    disableClearable,
    multiple,

    options,
    value,
    onChange,
    
}) {

    return <Autocomplete
        id={id} 
        disableClearable={disableClearable} 
        multiple={multiple} 

        options={options} 
        value={value} 
        onChange={onChange} 
        getOptionLabel={(option) => (option?.name)? option?.name : (option?.label)? option?.label : null}

        className={`${(className)? className : 'w-full mui-bg-gray-100'}`}
        disabled={disabled}

        ListboxProps={{ sx: { fontSize: (listFontSize)? listFontSize : 15 } }}
        renderInput={(params) => 
            <TextField
                {...params}
                label={label}
                sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' } }}
                InputLabelProps={{ sx: { top: "-10.5px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}
            />
        }
        sx={{ "& .MuiInputBase-root": { height: 30, fontSize: 15, paddingInline: 0.6, paddingBlock: '5px !important' }}}
    />

}