import gql from "graphql-tag";
export default gql`
  query GetAllRecordingSets($customer_token: String!) {
    recordingSets(customer_token: $customer_token) {
      id
      name
      mark_label
      description
      show_mark_label
      customer_token

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
