import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { CopyToClipboard } from "react-copy-to-clipboard";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';










export default function MenuFolderOptions({ 

  anchorEl,
  setAnchorEl,
  folder,
  
  createFolder,
  setCreateFolder,
  copied,
  setCopied,
  urlText,

}) {




  const { currentUser } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)


  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const shareIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M696 960q-50 0-85-35t-35-85q0-8 1-14.5t3-14.5L342 666q-15 16-35.354 23T264 696q-50 0-85-35t-35-85q0-50 35-85t85-35q22 0 42.5 7.5T342 486l238-145q-2-8-3-14.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-22.292 0-42.646-7T618 402L380 547q2 8 3 14.5t1 14.5q0 8-1 14.5t-3 14.5l238 145q15-17 35.354-23.5T696 720q50 0 85 35t35 85q0 50-35 85t-85 35Z"/></svg>;
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>


  function copyFolderURLFunc() {
    setSuccessAlertMsg('La URL de la carpeta ha sido copiada')
    setCopied(true)
  }



  
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={(anchorEl)? true : false}
      onClose={() => setAnchorEl(null)}
      onClick={() => setAnchorEl(null)}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          ml: 2.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 2,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 27,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className='p-1 text-sm'>



        {(currentUser?.rol === 'admin')?
          <button
            onClick={() => setCreateFolder((folder?.id === createFolder?.id)? null : folder)}
            className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-gray-500 hover:bg-gray-100 ${(folder?.id === createFolder?.id)? 'hover:text-red-500' : 'hover:text-blue-500'} duration-200 rounded`}
          >
            
            {(folder?.id === createFolder?.id)? <p>Cancelar editar</p> : <p>Editar</p>}
            {(folder?.id === createFolder?.id)? cancelIcon : editIcon}
          </button>
        : null }

        <CopyToClipboard text={urlText}>
          <button 
            onClick={copyFolderURLFunc} 
            className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-gray-500 hover:bg-gray-100 hover:text-blue-500 duration-200 rounded`}
          >
            <p>Copiar enlace</p>
            {(copied)? copyIcon : shareIcon}
          </button>
        </CopyToClipboard>



      </div>
    </Menu>
  );
}