import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { useParams } from "react-router-dom";
import { useLazyQuery } from '@apollo/client'

import GetPublicVideo from './../graphql/queries/GetPublicVideo'

import VideoPlayer from '../components/VideoPlayer';
import PublicVideoCommentBox from '../components/PublicVideoCommentBox';








const VideoReviewsPublic = () => {



  const { path_video_token } = useParams()
  const { customerPath } = useContext(AuthContext)


  const [video, setVideo] = useState(null);
  const [hasComments, setHasComments] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);

  const warningIcon = <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="currentColor"><path d="M97.67-120q-9.67 0-17.11-4.58-7.43-4.59-11.56-12.09-4.44-7.4-4.89-16.03-.44-8.63 4.89-17.3l382-660q5.33-8.67 12.83-12.67t16.17-4q8.67 0 16.17 4 7.5 4 12.83 12.67l382 660q5.33 8.67 4.89 17.3-.45 8.63-4.89 16.03-4.13 7.5-11.56 12.09Q872-120 862.33-120H97.67Zm385.11-118q14.22 0 23.72-9.62 9.5-9.61 9.5-23.83 0-14.22-9.62-23.72-9.61-9.5-23.83-9.5-14.22 0-23.72 9.62-9.5 9.62-9.5 23.83 0 14.22 9.62 23.72 9.62 9.5 23.83 9.5Zm0-114q14.22 0 23.72-9.58 9.5-9.59 9.5-23.75v-149.34q0-14.16-9.62-23.75-9.61-9.58-23.83-9.58-14.22 0-23.72 9.58-9.5 9.59-9.5 23.75v149.34q0 14.16 9.62 23.75 9.62 9.58 23.83 9.58Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  
  const [getVideo, { data: dataVideo, loading: loadingVideo }] = useLazyQuery(GetPublicVideo, {
    fetchPolicy: 'no-cache',
    variables: { 
      token: path_video_token,
    }
  })





  useEffect(() => {
    getVideo()
  }, [])

  useEffect(() => { 
    setVideo((dataVideo?.getPublicVideo)? dataVideo?.getPublicVideo : null)
  }, [dataVideo])

  useEffect(() => { 
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false)
      }, 1000 )
    }
  }, [baseLoading])






  return (
    <div>
      <div className={`min-w-[400px] max-w-[100vw] h-[100vh] flex justify-center transition-all duration-300 ${(video?.name && hasComments)? 'pt-[1vw]' : 'pt-[5vh]'}`}> {/* ${(video?.name && hasComments)? 'items-start' : 'items-center'} */}



        <div className='w-full max-w-[900px] px-4 py-[5vw] text-gray-700'>
          {(!baseLoading && video?.name && video?.last_review?.filename)?

            <div className='w-full flex flex-col items-center space-y-4'>
              <div className='w-full px-4'>
                <p className='text-2xl md:text-4xl md:leading-10'>{video?.name}</p>
                <p className='text-sm md:text-lg md:leading-5 text-gray-400'>{video?.title}</p>
              </div>

              <VideoPlayer
                customerToken={video?.customer_token}
                videoObj={video}
                selectedReview={video?.last_review}
                thumbnail={(video?.thumbnail)? (customerPath?.replaceAll('undefined', video?.customer_token) + 'video_thumbnails/' + video?.thumbnail) : null} 
              />

              <div className='w-full p-1 py-8 min-h-[150px] max-w-[700px]'>
                <PublicVideoCommentBox 
                  video={video}
                  setHasComments={setHasComments}
                />
              </div>
            </div>

          :

            <div className={`w-full flex flex-col items-center space-y-4 pointer-events-none ${(baseLoading || loadingVideo)? 'animate-pulse' : ''}`}>
              <div className='w-full px-4 flex space-y-2'>
                <div className='flex text-2xl md:text-4xl md:leading-10 bg-gray-200 rounded-lg'><p className='opacity-0'>T000146-V3-0034NDL</p></div>
              </div>

              <div className='w-full space-y-1 mb-[36.5px]'>
                <div className='w-full relative flex items-center justify-center'>
                  <div style={{ backgroundColor: 'rgb(229 231 235)' }} className='w-full video-container max-h-screen rounded-lg overflow-hidden'>
                  </div>
                  <div className={`absolute flex flex-col items-center justify-center space-y-2 ${(baseLoading || loadingVideo)? 'text-blue-500' : 'text-red-700 opacity-90'}`}>
                    <div className='scale-[1.5]'>{(baseLoading || loadingVideo)? loadingIcon : warningIcon}</div>
                    {(!(baseLoading || loadingVideo))? <p className='font-bold opacity-95'>Vídeo no encontrado</p> : null}
                  </div>
                </div>

                <div className='w-full p-4 bg-gray-200 rounded-lg'></div>
              </div>

              <div className='w-full pb-1 pt-8 min-h-[150px] max-w-[700px] space-y-2'>
                <div className='w-[250px] p-3 bg-gray-200 rounded-lg'></div>
                <div className='w-full p-4 bg-gray-200 rounded-lg'></div>
              </div>
            </div>

          }
        </div>
        


      </div>
    </div>
  )
}

export default VideoReviewsPublic