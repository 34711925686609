import gql from "graphql-tag";
export default gql`
  query GetBillConceptsFromBill($own_user_token: String!, $bill_id: Int!) {
    billConceptsFromBill(own_user_token: $own_user_token, bill_id: $bill_id) {

      id
      position
      title
      small_text
      description
      units_name
      units
      price
      iva

      bill_id
      parent_id
      video_id
      module_id

      billed

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated



      childs {
        id
        position
        title
        small_text
        description
        units_name
        units
        price
        iva
  
        bill_id
        parent_id
        video_id
        module_id
  
        billed
  
        createdAt
        updatedAt
  
        createdAtFormated
        updatedAtFormated

        video {
          id
          name
          url_name
          vimeo_url

          factura10_product_id

          search_module_abbreviated_name
        }
      }

      

    }
  }
`;
