import gql from "graphql-tag";
export default gql`
  query GetClicksoundUserNames($own_user_token: String!) {
    getClicksoundUserNames(own_user_token: $own_user_token) {
      id
      abbreviated_name
      name
      __typename
    }
  }
`;
