import TextField from '@mui/material/TextField';

export default function CustomTextField({

    id,
    name,
    label,
    type,

    variant,
    className,
    error,
    disabled,
    big,

    defaultValue,
    value,
    onChange,
    onKeyUp,
    
}) {

    return <TextField 
        id={id}
        name={name}
        type={(type)? type : 'text'}
        label={label}
        
        defaultValue={defaultValue} 
        value={value} 
        onChange={onChange} 
        onKeyUp={onKeyUp} 

        variant={(variant)? variant : 'outlined'}
        className={`${(className)? className : 'w-full mui-bg-gray-100'}`}
        error={(error)? true : false}
        helperText={error}
        disabled={disabled}

        InputProps={{ sx: { 
            height: (big && variant)? 60 : (big)? 40 : 30, 
            fontSize: (big && variant)? 18 : (big)? 17 : 15, 
            "& .MuiInputBase-input": { 
                paddingInline: 1.2, 
                paddingTop: (big && variant)? 3 : (big)? 1.1 : 0.7, 
                paddingBottom: (big && variant)? 1 : (big)? 1 : 0.6 
            }
        } }}
        InputLabelProps={{ sx: { 
            top: (big && variant)? "2px" : (big)? "-5.5px" : "-10.5px", 
            fontSize: (big && variant)? 17 : (big)? 15 : 14, 
            "&.MuiInputLabel-shrink": { 
                top: 1.5, 
                fontSize: 15 
            } 
        } }}
    />
}