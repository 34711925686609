import gql from "graphql-tag";
export default gql`
  query GetVideosCustomerSettings(

    $own_user_token: String!,
    $own_customer_token: String!,

    $name: String,
    $gender: String,
    $language: String,
    $production_state: String,
    $professor_id: String,
    $tags: String,
    $module: String
    $folder: String
    $localizations: Int
    $avoidAudios: Boolean
    $settings_group_visibility: String
    $settings_legend_visibility: String

  ) {
    getCustomerVideos(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,

      name: $name,
      gender: $gender,
      language: $language,
      production_state: $production_state,
      professor_id: $professor_id,
      tags: $tags,
      module: $module
      folder: $folder
      localizations: $localizations
      avoidAudios: $avoidAudios
      settings_group_visibility: $settings_group_visibility
      settings_legend_visibility: $settings_legend_visibility

    ) {
      
      id
      name
      url_name
      vimeo_url
      vimeo_url_change_label
      title
      title_disabled
      chyrons_disabled
      thumbnail
      thumbnail_small
      production_state
      production_state_change_label
      tags
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      folder_id
      recording_set_ids
      customer_token

      factura10_product_id
      factura10_date
      factura10_text
      factura10_facturated_date
      factura10_facturated_text
      
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of
      
      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      extra_marks_json
      extra_languages_json
      facturation_note
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label

      chyron_states
      workflow_done
      
      download_video
      download_video_change_label
      is_sound_media

      search_review_version
      search_media_time
      search_review_date
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name
      search_professor_name
      search_substituted_chyrons
      search_production_state_change_history
      search_is_part_of_name
      search_workflow_bills_state
      
      search_number_of_marks
      search_number_of_notes
      search_number_of_marks_trad

      recordingDate
      endDate
      publicationEndDate
      createdAt
      updatedAt

      has_token
      
      professors { 
        id
        name
        professor_code
        vinculated_to_id
        email
        positions
      }

      list_localizations {
        id
        name
        url_name
        vimeo_url
        title
        title_disabled
        chyrons_disabled
        language
        number_of_words
        
        is_translation_of
        
        search_reviewed
        search_reviewed_label
        search_translated
        search_translated_label
        
        search_review_version
        searchReviewDateFormated
        search_substituted_chyrons

        search_number_of_marks
        search_number_of_notes
        search_number_of_marks_trad

        isPriorityDateFormated
        
        endDate
        recordingDateFormated
        publicationEndDateFormatedSimple
        endDateFormated
        endDateFormatedSimple
        updatedAtFormated
        createdAtFormated
      }

      
      searchReviewDateFormated
      isPriorityDateFormated
      recordingDateFormated
      publicationEndDateFormatedSimple
      endDateFormated
      endDateFormatedSimple
      updatedAtFormated
      createdAtFormated

    }
  }
`;

