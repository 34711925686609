import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import Tooltip from '@mui/material/Tooltip';










export default function VideoMarksTypeList({
  
  video,
  mark,
  startSelectionDone,
  setStartSelectionDone,
  markTypes,
  selectedMarkType,
  setSelectedMarkType,
  
  isEditing,
  enterEdit,
  isTranslating,
  counter,
  prioritaryCounter,
  
  markStateFilter,
  showAll,
  setShowAll,
  creationMode

}) {
  

  const { currentUser, currentCustomer } = useContext(AuthContext)





  function counterFunc(type) {
    if (!creationMode) {
      var prioritary = 0;
      var done = 0;
      var all = 0;

      if (prioritaryCounter && prioritaryCounter[type]) {
        prioritary = prioritaryCounter[type]
      }

      if (counter) {
        done = counter[type + '-done']? counter[type + '-done'] : 0;
        all = counter[type]? counter[type] : 0;
      }

      return <div className='text-xs text-gray-500 flex space-x-1'>

        {(markStateFilter[4]) ? <>
          <Tooltip disableInteractive title={"Número de motions insertados, que hayan sido encontrados."} placement="bottom" arrow>
            <div className='flex space-x-1'>
              <p className='text-green-500'>{(done)? done : 0}</p>
              <p>/</p>
            </div>
          </Tooltip>
        </> : null}

        {(prioritary) ? <>
          <Tooltip disableInteractive title={"Número de motions prioritarios sin crear, que hayan sido encontrados."} placement="bottom" arrow>
            <div className='flex space-x-1'>
              <p className='text-red-500'>{(prioritary)? prioritary : 0}</p>
              <p>/</p>
            </div>
          </Tooltip>
        </> : null}

        <Tooltip disableInteractive title={"Número total de motions encontrados."} placement="bottom" arrow>
          <p>{(all)? all : 0}</p>
        </Tooltip>

      </div>
      
    } else {
      return null
    }
  }


  

  return (
    <div>

              
      {(isEditing || enterEdit || isTranslating) && <>
        <div className={`${(isTranslating || currentUser?.professor || video?.is_translation_of || !creationMode)? '' : 'mb-3'}`}>
          {(!isTranslating && !currentUser?.professor && !video?.is_translation_of) && 
            <div
              className={`flex flex-wrap justify-start items-stretch rounded pb-2 pl-2 pr-1 pt-1 overflow-auto bg-gray-200 max-h-[330px] 
                ${(mark)? 'border border-gray-400 border-opacity-40 bg-opacity-70' : ' bg-opacity-50'}
              `}
            >
              
              <button 
                className={`group/typeBox w-[calc(50%-0.01rem)] md:w-[calc(33.33%-0.01rem)] lg:w-[calc(25%-0.01rem)] ${(!creationMode)? 'pr-1 pt-1' : 'p-1 pt-2'} active:opacity-50 duration-100`} 
                onClick={()=> {
                  if (showAll) { setShowAll(false) };

                  setSelectedMarkType(null);
                  localStorage.setItem('mark-type', null);
                  setStartSelectionDone(true)
                }}
              >
                <div className={`flex items-center space-x-1 duration-300 ${(!creationMode)? `hover:bg-gray-300 py-1 px-[6px] rounded ${((!selectedMarkType && (startSelectionDone || !creationMode)) || showAll) && 'bg-[rgb(225,225,225)]'}` : 'ml-1'}`}>
                  <div className='p-1 rounded-full' style={{ backgroundColor: 'rgba(107, 114, 128, 0.3)' }}></div>
                  <p className={`w-full text-left text-xs truncate duration-300 ${((!selectedMarkType && (startSelectionDone || !creationMode)) || showAll)? 'text-black' : 'text-gray-600 group-hover/typeBox:text-black'}`}>Nota / Sin motion</p>
                
                  {(showAll || (!selectedMarkType && (startSelectionDone || !creationMode)))? counterFunc('null') : null}
                </div>

                {(creationMode) &&
                  <div className={`relative flex justify-center items-center rounded-md bg-gray-300 overflow-hidden duration-200 border-2 ${(!selectedMarkType && (startSelectionDone || !creationMode))? 'border-blue-600 opacity-100' : 'border-gray-400 group-hover/typeBox:border-gray-600 opacity-30'} cursor-pointer`}>
                    <img 
                      src={`${process.env.REACT_APP_API_ROUTE}mark_type_thumbnails/0.png`} 
                      alt='mark_type_thumb'
                      className='absolute z-20 w-full object-cover'
                    />  

                    <img
                      src="/no_image.png"
                      alt='no_mark_type_thumb'
                      className='z-10 object-contain'
                    />
                  </div>
                }
              </button>



              {(!creationMode) && <>
              
                <button 
                  className={`group/typeBox w-[calc(50%-0.01rem)] md:w-[calc(33.33%-0.01rem)] lg:w-[calc(25%-0.01rem)] pr-1 pt-1 active:opacity-50 duration-100`} 
                  onClick={()=> {if (showAll) { setShowAll(false) }; setSelectedMarkType('video-titles'); localStorage.setItem('mark-type', 'video-titles'); setStartSelectionDone(true)}}
                >
                  <div className={`flex items-center space-x-1 duration-200 hover:bg-gray-300 py-1 px-[6px] rounded ${(selectedMarkType === 'video-titles' || showAll) && 'bg-[rgb(225,225,225)]'}`}>
                    <div className='p-1 rounded-full' style={{ backgroundColor: 'rgba(107, 114, 128, 0.3)' }}></div>
                    <p className={`w-full text-left text-xs truncate duration-300 ${(selectedMarkType === 'video-titles' || showAll)? 'text-black' : 'text-gray-600 group-hover/typeBox:text-black'}`}>Título de vídeos</p>
                    
                    {(showAll || selectedMarkType === 'video-titles')? counterFunc('video-titles') : null}
                  </div>
                </button>

                <button className={`group/typeBox w-[calc(50%-0.01rem)] md:w-[calc(33.33%-0.01rem)] lg:w-[calc(25%-0.01rem)] pr-1 pt-1 active:opacity-50 duration-100`} onClick={()=> {if (showAll) { setShowAll(false) }; setSelectedMarkType('professor-chyrons'); localStorage.setItem('mark-type', 'professor-chyrons'); setStartSelectionDone(true)}}>
                  <div className={`flex items-center space-x-1 duration-200 hover:bg-gray-300 py-1 px-[6px] rounded ${(selectedMarkType === 'professor-chyrons' || showAll) && 'bg-[rgb(225,225,225)]'}`}>
                    <div className='p-1 rounded-full' style={{ backgroundColor: 'rgba(107, 114, 128, 0.3)' }}></div>
                    <p className={`w-full text-left text-xs truncate duration-300 ${(selectedMarkType === 'professor-chyrons')? 'text-black' : 'text-gray-600 group-hover/typeBox:text-black'}`}>Chyrons de {currentCustomer?.label_professor?.toLowerCase()}</p>
                    {(showAll || selectedMarkType === 'professor-chyrons')? counterFunc('professor-chyrons') : null}
                  </div>
                </button>
              
              </>}



              {markTypes?.map((markTypeTMP, key) => {
                return <>
                  <button 
                    key={`type-img-${((mark && mark?.id)? mark?.id : markTypeTMP?.id)}-${key}`} 
                    className={`group/typeBox w-[calc(50%-0.01rem)] md:w-[calc(33.33%-0.01rem)] lg:w-[calc(25%-0.01rem)] ${(!creationMode)? 'pr-1 pt-1' : 'p-1 pt-2'} active:opacity-50 duration-100`} 
                    onClick={()=> {if (showAll) { setShowAll(false) }; setSelectedMarkType(parseInt(markTypeTMP?.id)); localStorage.setItem('mark-type', parseInt(markTypeTMP?.id)); setStartSelectionDone(true)}}
                  >
                    <div className={`flex items-center space-x-1 duration-200 ${(!creationMode)? `hover:bg-gray-300 py-1 px-[6px] rounded ${(parseInt(selectedMarkType) === parseInt(markTypeTMP?.id) || showAll) && 'bg-[rgb(225,225,225)]'}` : 'ml-1'}`}>
                      <div className='p-1 rounded-full' style={{ backgroundColor: markTypeTMP?.color? 'rgba('+markTypeTMP?.color+', 0.35)' : 'rgba(107, 114, 128, 0.1)' }}></div>
                      <p className={`w-full text-left text-xs truncate duration-300 ${(parseInt(selectedMarkType) === parseInt(markTypeTMP?.id) || showAll)? 'text-black' : 'text-gray-600 group-hover/typeBox:text-black'}`}>{markTypeTMP?.name}</p>
                      {(showAll || parseInt(selectedMarkType) === parseInt(markTypeTMP?.id))? counterFunc(markTypeTMP?.id) : null}
                    </div>

                    {(creationMode) &&
                      <div className={`relative flex justify-center items-center rounded-md bg-gray-300 overflow-hidden duration-200 border-2 ${(parseInt(selectedMarkType) === parseInt(markTypeTMP?.id))? 'border-blue-600 opacity-100' : 'border-gray-400 group-hover/typeBox:border-gray-600 opacity-30'} cursor-pointer`}>
                        <img 
                          src={
                            (parseInt(markTypeTMP?.id) && markTypeTMP?.thumbnail)? `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/mark_thumbnails/${markTypeTMP.thumbnail}` 
                            : `${process.env.REACT_APP_API_ROUTE}mark_type_thumbnails/${(markTypeTMP?.thumbnail)? markTypeTMP?.thumbnail : (markTypeTMP)? '0_no_image.png' : '0.png'}`
                          } 
                          alt='mark_type_thumb'
                          className='absolute z-20 w-full object-cover'
                        />          
                                
                        <img
                          src="/no_image.png"
                          alt='no_mark_type_thumb'
                          className='z-10 object-contain'
                        />
                      </div>
                    }
                  </button>
                </>
              })}


            </div>
          }
        </div>
      </>}


    </div>
  );
}