import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateModule(
    
    $own_user_token: String!,
    
    $id: Int,
    $name: String,
    $abbreviated_name: String,
    $position: Int,
    $customer_token: String!,

    $inside_extras: Boolean,
    $visible: Boolean,
    $blocked: Boolean
    
  ) {
    createOrUpdateModule(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      name: $name,
      abbreviated_name: $abbreviated_name,
      position: $position,
      customer_token: $customer_token,
      
      inside_extras: $inside_extras,
      visible: $visible,
      blocked: $blocked 
    
    }) {
      
      id
      name
      abbreviated_name
      position

      inside_extras
      visible
      blocked

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
