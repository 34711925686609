import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { useQuery, useMutation } from '@apollo/client'
import axios from 'axios'

import RecentActivityList from '../components/RecentActivityList'
import RecentWorkflowList from '../components/RecentWorkflowList'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'
import GetVideos from './../graphql/queries/GetVideos'
import GetAllUsersAndProfessors from './../graphql/queries/GetAllUsersAndProfessors'












const Dashboard = () => {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)

  const classBtnBlue = 'whitespace-nowrap flex items-center space-x-1 px-2 py-[2px] m-[2px] text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-800 border border-blue-600 duration-200 disabled:opacity-40 disabled:pointer-events-none rounded select-none'
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;


  const [loading, setLoading] = useState(null);
  const [users, setUsers] = useState(null);
  const [videos, setVideos] = useState(null);




  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)

  const { data: dataUsers, loading: loadingUsers } = useQuery(GetAllUsersAndProfessors, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: true, 
    }
  })

  const { data: dataVideoNames, loading: loadingVideoNames } = useQuery(GetVideos, {
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      professors: true, 
    }
  })





  useEffect(() => {    
    setCurrentNavActive('dashboard')
  }, [])

  useEffect(() => {
    setUsers((dataUsers?.usersAndProfessors?.length)? dataUsers?.usersAndProfessors?.filter((user)=> (user?.customer_free || user?.navigation_customer_tokens?.split(',')?.includes(currentCustomer?.token))) : null)
  }, [dataUsers])
  
  useEffect(() => {
    setVideos((dataVideoNames?.videos?.length)? dataVideoNames?.videos : null)
  }, [dataVideoNames])
  






  



  
  async function fac10VideosFix() {
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log('START FIX')
    setLoading('fac10VideosFix')

    await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_videos_fix.php`).then( async function (response) {
      var endResponse = response.data;

      console.log(' ')
      console.log('endResponse')
      console.log(endResponse)

      if (endResponse) {
        await axios.post(`${process.env.REACT_APP_API_ROUTE}fac10VideosFix`, { endResponse: endResponse })?.then( async (res) => {
          console.log(' ')
          console.log('END')
          console.log(res)
          setLoading(null)
        })
      }

    }).catch(function (error) {
      console.log(' ');
      console.log('ERROR');
      console.log(error);
      setLoading(null)
    });
  }



  async function fac10RemoveRepeatedFix() {
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log('START FIX')
    console.log(' ')
    setLoading('fac10RemoveRepeatedFix')

    await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_fix_product_ids.php`).then( async function (response) {
      const videos = (response?.data && Object.keys(response?.data)?.length)? response?.data : null

      console.log(' ')
      console.log('videos')
      console.log(videos)

      if (videos) {
        for (var videoKeys of Object.keys(videos)) {
          const video = videos[videoKeys]

          console.log(video?.name)

          var input = {
            own_user_token: currentUser?.token,
    
            id: parseInt(video?.id),
            factura10_product_id: (parseInt(video?.newF10ID))? parseInt(video?.newF10ID) : null,
          };
    
          await createOrUpdateVideo({variables: input})
        }
      }

      setLoading(null)

    }).catch(function (error) {
      console.log(' ');
      console.log('ERROR');
      console.log(error);
      setLoading(null)
    });
  }


  async function fac10RepairDocConceptsFix() {
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log('START FIX')
    console.log(' ')
    setLoading('fac10RepairDocConceptsFix')

    await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_fix_concept_ids.php`).then( async function (response) {
      const videos = (response?.data && Object.keys(response?.data)?.length)? response?.data : null

      console.log(' ')
      console.log('videos')
      console.log(videos)

      setLoading(null)

    }).catch(function (error) {
      console.log(' ');
      console.log('ERROR');
      console.log(error);
      setLoading(null)
    });
  }

  

  async function repeatedFacturations(moduleID) {

    console.log(' ')
    console.log(' ')
    console.log('START')
    console.log(' ')
    console.log(' ')

    setLoading('repeatedFacturations_'+moduleID)

    await axios.post(`${process.env.REACT_APP_API_ROUTE}getVideos`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, module_id: moduleID, require_factura10: true })?.then( async (res) => {
      const videosTMP = (res?.data?.length)? res?.data : null
      var counter = 0

      console.log(' ')
      console.log(`${videosTMP?.length} vídeos`)
      console.log(' ')

      if (videosTMP?.length) {
        /* for (var key in videosTMP) { */
        for (var key = 0; key < videosTMP?.length; ++key) {
          const videoTMP = videosTMP[key]
          var entred = false

          if (videoTMP?.factura10_product_id) {
            await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_product_history.php`, { productId: videoTMP?.factura10_product_id }).then( async (response) => {
              const history = response?.data;

              if (history && history?.documents && Object.keys(history?.documents)?.length > 1) {
                var repeatedDocs = {};


                for (var docKey of Object.keys(history?.documents)) {
                  const docTMP = history?.documents[docKey]
                  if (docTMP?.type && parseInt(docTMP?.actiu) && parseInt(docTMP?.sended)) {
                    repeatedDocs[docTMP?.type] = (repeatedDocs[docTMP?.type])? repeatedDocs[docTMP?.type] + 1 : 1;
                  }
                }
                

                if (repeatedDocs && Object.keys(repeatedDocs)?.length) {
                  for (var docType of Object.keys(repeatedDocs)) {
                    const docTypeNumber = repeatedDocs[docType]
                    if (docType && docTypeNumber > 1 && docType === 'minutes') {
                      const conceptsFromDocs = {};

                      for (var documentKey of Object.keys(history?.documents)) {
                        const documentTMP = history?.documents[documentKey]
                        if (documentTMP?.type === docType) {
                          for (var conceptKey of Object.keys(history?.concepts)) {
                            const conceptTMP = history?.concepts[conceptKey]
                            if (conceptTMP?.document_id === documentTMP?.id) {
                              const marksJson = (conceptTMP['aux_json'])? JSON.parse(conceptTMP['aux_json']) : null;
                              conceptsFromDocs[marksJson?.min_final] = (conceptsFromDocs[marksJson?.min_final]?.length)? [...conceptsFromDocs[marksJson?.min_final], conceptTMP] : [conceptTMP]  
                            }
                          }
                        }
                      }


                      if (conceptsFromDocs && Object.keys(conceptsFromDocs)?.length) {
                        var hasRepeated = false;

                        for (var repeatedKey of Object.keys(conceptsFromDocs)) {
                          const number = conceptsFromDocs[repeatedKey]?.length
                          if (number > 1) { hasRepeated = true }
                        }

                        if (hasRepeated) {
                          var priceKeys = Object.keys(conceptsFromDocs)

                          console.log(' ')
                          console.log(`%c ${videoTMP?.name}`, 'color: #75a5ff')  
                          console.log(`Factura de ${(docType === 'minutes')? 'metraje' : docType} facturada ${docTypeNumber} veces / Primer precio: ${conceptsFromDocs[priceKeys[0]][0]?.preu}€ / Segundo precio: ${conceptsFromDocs[priceKeys[0]][1]?.preu}€`)
                          counter++;
                          entred = true;
                        }
                      }
                    }
                  }
                }
                

              }
            })
          }
        }
      }
      


      console.log(' ')
      console.log(' ')
      console.log('END - ' + counter + ' vídeos')
      console.log(' ')
      console.log(' ')

      setLoading(null)
 
    }).catch(function (error) {
      console.log(' ');
      console.log('ERROR');
      console.log(error);
      setLoading(null)
    });
  }



  /* 
  async function basicCall() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getOauthToken`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function getRaskSourceList() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getRaskSourceList`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function createRaskSource() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}createRaskSource`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function getRaskSource() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getRaskSource`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function createRaskOutput() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}createRaskOutput`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function getRaskOutputList() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getRaskOutputList`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function getRaskOutputContent() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getRaskOutputContent`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  }
  async function getRaskOutputTranscription() {
    await axios.post(`${process.env.REACT_APP_API_ROUTE}getRaskOutputTranscription`, { own_user_token: currentUser?.token })?.then( async (res) => {
      console. log(' ')
      console. log('res')
      console. log(res)
    })
  } 
  */



  async function reloadAlreadyFacturated() {
    setLoading('reloadAlreadyFacturated')

    const respIDs = await axios.post(`${process.env.REACT_APP_API_ROUTE}getFirstBillIdOfEveryType`, { own_user_token: currentUser?.token })
    const document_ids = (respIDs?.data?.length)? respIDs?.data : null

    console.log(' ')
    console.log(' ')
    console.log(' ')
    console.log('document_ids')
    console.log(document_ids)

    if (document_ids?.length) {
      const resp = await axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}/api_reload_already_facturated.php`, { document_ids: document_ids })
      
      console.log(' ')
      console.log('resp')
      console.log(resp?.data)
    }

    setLoading(null)
  }
  

    

  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl py-14 pl-2'>Inicio</h1>
      </div>


      <div className='space-y-10'>
        {/* Dashboard */}
        <div className='w-full space-y-4'>
          <div className='w-full py-40 bg-gray-100 rounded-md'></div>

          <div className='w-full flex space-x-4'>
            <div className='w-full py-20 bg-gray-100 rounded-md'></div>
            <div className='w-full py-20 bg-gray-100 rounded-md'></div>
          </div>
        </div>



        {/* Latest activity */}
        <div className='flex flex-col space-y-4'>
          {/* <RecentWorkflowList users={users} videos={videos} userId={null} defOpen={false} emergency={true} flipSort={true}/>
          <RecentActivityList users={users} videos={videos} userId={null} defOpen={false} emergency={true} flipSort={true}/> */}
          
          {(true && (currentUser?.rol === 'admin' || currentUser?.rol === 'facturator' || currentUser?.rol === 'super_user')) &&
          <RecentActivityList users={users} videos={videos} serId={null} defOpen={true}/> 
          }
        </div>



        {/* Test panel */}
        {(parseInt(currentUser?.id) === 3) && <>

          <h1 className='text-gray-500'>Test area</h1>
          <div className='mb-10 p-4 border-2 border-dashed rounded flex flex-wrap'>
            <div className='w-full flex flex-wrap'>
              <button className={classBtnBlue} onMouseUp={fac10VideosFix} /* disabled */>
                {(loading === 'fac10VideosFix')? loadingIcon : null}
                <p>Reasignar vídeos sin F10 ID</p>
              </button>

              <button className={classBtnBlue} onMouseUp={fac10RemoveRepeatedFix} /* disabled */>
                {(loading === 'fac10RemoveRepeatedFix')? loadingIcon : null}
                <p>Eliminar productos repetidos</p>
              </button>

              <button className={classBtnBlue} onMouseUp={fac10RepairDocConceptsFix} /* disabled */>
                {(loading === 'fac10RepairDocConceptsFix')? loadingIcon : null}
                <p>Corregir IDs de los conceptos</p>
              </button>

              <button className={classBtnBlue} onMouseUp={reloadAlreadyFacturated} /* disabled */>
                {(loading === 'reloadAlreadyFacturated')? loadingIcon : null}
                <p>Recargar ya facturados</p>
              </button>
            </div>


            <button className={classBtnBlue} onMouseUp={() => repeatedFacturations(32)}>
              {(loading === 'repeatedFacturations_32')? loadingIcon : null}
              <p>Facturas repetidas C1 new</p>
            </button>

            <button className={classBtnBlue} onMouseUp={() => repeatedFacturations(2)}>
              {(loading === 'repeatedFacturations_2')? loadingIcon : null}
              <p>Facturas repetidas C2</p>
            </button>

            <button className={classBtnBlue} onMouseUp={() => repeatedFacturations(30)}>
              {(loading === 'repeatedFacturations_30')? loadingIcon : null}
              <p>Facturas repetidas C3</p>
            </button>

            <button className={classBtnBlue} onMouseUp={() => repeatedFacturations(36)}>
              {(loading === 'repeatedFacturations_36')? loadingIcon : null}
              <p>Facturas repetidas loveisdin</p>
            </button>
          </div>

        </>}
      </div>

    </div>
  )
}

export default Dashboard
