import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateUserTag(
    
    $own_user_token: String!,

    $id: Int,
    $name: String,
    $user_ids: String,
    $customer_token: String
    
  ) {
    createOrUpdateUserTag(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      name: $name,
      user_ids: $user_ids,
      customer_token: $customer_token
    
    }) {

      id
      name
      user_ids
      customer_token
      createdAt
      updatedAt

      assignedUsers {
        id
        name
      }

      assignedVideos {
        id
        name
        title
      }
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
