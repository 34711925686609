import gql from "graphql-tag";
export default gql`
  query GetUser($own_user_token: String!, $own_customer_token: String!, $id: ID!, $professor: Int) {
    userByRol(own_user_token: $own_user_token, own_customer_token: $own_customer_token, id: $id, professor: $professor) {
      id
      abbreviated_name
      name

      positions

      email
      profile_picture
      language
      professor
      professor_code
      vinculated_to_id
      tags
      sex
      treatment
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      visible
      enabled
      
      original_customer_token
      navigation_customer_tokens
      customer_free
      license_token

      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
