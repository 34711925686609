import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'

import ChangeReviewLog from './../graphql/mutations/ChangeReviewLog'

import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';






export default function VideoOptionsPanelChangelog({
  
  oldSelector,
  showThumbnailSelector,
  containerRef,

  reviewLogs,
  setReviewLogs,

  windowHeight,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)


  const [loadingNewReviewLog, setLoadingNewReviewLog] = useState(false);
  const [editingLog, setEditingLog] = useState(null);


  const [changeReviewLog] = useMutation(ChangeReviewLog)







  useEffect(() => {   
    var element = document.getElementById("event-"+editingLog);

    if (editingLog !== null && element) {
      element.focus() 
      if (element?.value?.length) { element.setSelectionRange(element.value.length, element.value.length); }
      element.scrollTop = element.scrollHeight;
    }
  }, [editingLog])






  async function saveChangelogFunc(key) {
    var allReviewTMP = [...reviewLogs];
    var newLogTMP = document.getElementById("event-"+key);

    if (!currentUser?.rol?.includes('user') && parseInt(allReviewTMP[key]?.id) && newLogTMP) {
      setLoadingNewReviewLog(true)


      var input = {
        own_user_token: currentUser?.token, 

        id: parseInt(allReviewTMP[key]?.id), 
        log: (newLogTMP?.value && newLogTMP?.value?.replaceAll(' ', '') !== '')? newLogTMP.value : null,
      };

      var newLogTMP = await changeReviewLog({variables: input})
      if (newLogTMP?.data?.changeReviewLog) { allReviewTMP[key] = newLogTMP?.data?.changeReviewLog }


      setTimeout(() => {
        setEditingLog(null)
        setLoadingNewReviewLog(false)
        setReviewLogs(allReviewTMP)
      }, "1000")

    }
  }






  return (<>

    <Slide direction={(oldSelector >= 3)? "right" : "left"} in={showThumbnailSelector === 3} container={containerRef.current}>
      <div className={`rounded-lg absolute top-0 w-full `}>
        <div className={`pt-1 bg-gray-100 border border-gray-300 rounded-lg smallScrollBar overflow-y-auto ${(windowHeight < 750)? 'max-h-[170px]' :  'max-h-[calc(100vh-550px)]'}`}>

          
          <div className='rounded overflow-auto'>
            <Timeline position="right">
              {reviewLogs?.map((event, key) => {
                return <TimelineItem key={'event-'+key}>
                  <TimelineOppositeContent sx={{ display: 'none' }} color="textSecondary">
                    Test
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot />
                    {(reviewLogs?.length !== (key + 1)) && <TimelineConnector />}
                  </TimelineSeparator>

                  <TimelineContent sx={{ paddingRight: 0 }}>
                    <div onDoubleClick={() => setEditingLog((!currentUser?.rol?.includes('user'))? key : null)}>
                      <div className='flex flex-wrap xl:flex-nowrap items-baseline xl:space-x-2'>
                        <p className='text-gray-800 font-bold text-xl whitespace-nowrap'>
                          {
                            (event?.__typename === 'VideoReview' && (key+1) === reviewLogs?.length)? 'Vídeo subido' 
                            : (event?.__typename === 'VideoReview')? 'Nueva review' 
                            : 'Nueva review'
                          }
                        </p>
                        <p className='text-gray-500 text-xs'>{event?.createdAtFormated} {event?.createdAt?.split('T')[1]?.substring(0,5) + 'h'}</p>

                      </div>
                      
                      <p className='text-sm w-full text-gray-500 whitespace-nowrap mb-2'>
                        {event?.filename}
                      </p>

                      <div className='pb-2'>
                        {(editingLog === key)?
                          <div className='space-y-1'>
                            <textarea 
                              rows="5" 
                              defaultValue={(event?.log)? event?.log : ''} 
                              id={"event-"+key} 
                              className='w-full h-full mb-[-5px] basic-textarea-style-small' 
                              style={{ paddingInline: 11, paddingBlock: 7 }}
                              placeholder='Nueva revisión subida a la plataforma.' 
                              disabled={loadingNewReviewLog}
                            ></textarea>
                            <div className='w-full flex items-center justify-between'>
                              <Button
                                variant="contained"
                                size='small'
                                color="error"
                                startIcon={<ClearIcon />}
                                onMouseUp={() => setEditingLog(null)}
                                disabled={loadingNewReviewLog}
                              >
                                Cancelar
                              </Button>

                              <LoadingButton 
                                loading={loadingNewReviewLog}
                                loadingPosition="start"
                                variant="contained"
                                size='small'
                                autoFocus
                                startIcon={<SaveIcon />}
                                onMouseUp={() => saveChangelogFunc(key)}
                              >
                                Guardar cambios
                              </LoadingButton>
                            </div>
                          </div>
                        :
                          <p 
                            className={`text-sm bg-gray-200 py-2 px-3 rounded ${(!currentUser?.rol?.includes('user'))? 'hover:bg-gray-300 duration-300 cursor-pointer' : ''} ${(event?.log)? 'text-gray-700' : 'text-gray-400'}`}
                            dangerouslySetInnerHTML={{__html: 
                              (event?.log)? event?.log?.replace(/\n/g, "<br />")
                              : (reviewLogs?.length === (key + 1))? 'Subida del vídeo ya editado a la plataforma para recibir el feedback.' 
                              : 'Nueva revisión a la plataforma.' 
                            }}
                          ></p>
                        }
                      </div>

                    </div>
                  </TimelineContent>
                </TimelineItem>
              })}
            </Timeline>
          </div>


        </div>
      </div>
    </Slide>
      
  </>
  );
}