import gql from "graphql-tag";
export default gql`
 
  query GetAllUsers($own_user_token: String!, $own_customer_token: String!) {
    users(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      abbreviated_name
      name
      email
      profile_picture
      language
      rol
      visible
      enabled

      original_customer_token
      navigation_customer_tokens
      
      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
