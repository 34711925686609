import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import MenuFolderOptions from './MenuFolderOptions';






export default function FolderListItem({

  folder,

  moduleFilter,
  selectedModule,
  pathFolders,

  createFolder,
  setCreateFolder,

}) {
  




  const { currentCustomer, customerPath } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)

  const folder_thumbnails = (folder?.thumbnails)? folder?.thumbnails?.split(',') : null




  /* Share vars */
  const [optionsAnchor, setOptionsAnchor] = useState(null);


  const [copied, setCopied] = useState(false);
  const [urlText, setUrlText] = useState(null);
  const [localUrlText, setLocalUrlText] = useState(null);

  /* Icon vars */
  const folderFillIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M865-600H299q-54 0-92.5 35T168-477v213l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168q-32 0-52-21t-20-51v-432q0-30 20-51t52-21h186q14 0 27.5 5t23.5 16l75 75h313q31 0 50.5 21t21.5 51Z"/></svg>
  const menuIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M479.79-192Q450-192 429-213.21t-21-51Q408-294 429.21-315t51-21Q510-336 531-314.79t21 51Q552-234 530.79-213t-51 21Zm0-216Q450-408 429-429.21t-21-51Q408-510 429.21-531t51-21Q510-552 531-530.79t21 51Q552-450 530.79-429t-51 21Zm0-216Q450-624 429-645.21t-21-51Q408-726 429.21-747t51-21Q510-768 531-746.79t21 51Q552-666 530.79-645t-51 21Z"/></svg>





  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000 )
    }
  }, [copied])

  
  useEffect( () => {
    const module_part = ((selectedModule?.abbreviated_name)? selectedModule?.abbreviated_name : (moduleFilter === 'all')? 'all' : currentCustomer?.label_extras_module_url)
    const folder_part = (folder)? `${(pathFolders?.length)? pathFolders?.map((el) => el?.url_name)?.join('/')+'/' : ''}${folder?.url_name}` : null
    const videos_url = `${process.env.REACT_APP_ROUTE}videos/${module_part}${(folder_part)? `/${folder_part}` : ''}`
    const local_videos_url = `/videos/${module_part}${(folder_part)? `/${folder_part}` : ''}`
    setUrlText(videos_url)
    setLocalUrlText(local_videos_url)
  }, [moduleFilter, selectedModule, folder, pathFolders])





  return (
    <div className={`w-full sm:w-[33.3%] lg:w-[25%] 2xl:w-[20%] min-[1800px]:w-[16.66%] p-2 z-10`}>
      <button className={`w-full relative group bg-gray-50 hover:bg-white rounded-lg overflow-hidden shadow hover:shadow-lg active:border-blue-600 cursor-pointer transition-colors duration-200 border-[1.5px] text-left border-gray-300 hover:border-gray-600`}>
        
        
        <a href={urlText} className='py-[28.125%] relative border-b rounded-t-md bg-gray-100 overflow-hidden flex items-center justify-center text-gray-400'>
          <div className='absolute w-full h-full z-30 transition-all duration-200 group-hover:shadow-[inset_0_0px_40px_0_rgba(0,0,0,0.10)]'></div>

          <div className={`absolute z-20 shadow-[inset_0_0px_15px_rgba(0,0,0,0.15)] w-full h-full flex items-center justify-center bg-opacity-60 pointer-events-none ${(folder_thumbnails?.length)? 'bg-gray-100' : ''}`}>
            <div className='z-30 absolute scale-[4.0] text-gray-700'>{folderFillIcon}</div>
          </div>

          <div className={`absolute z-10 h-full w-full duration-200 transition-all flex flex-wrap overflow-hidden text-gray-400`}>
            {folder_thumbnails?.slice(0, 4)?.map((thumb, key) => {
              return <div className={`w-1/2 min-h-[102px] h-[23vw] sm:min-h-[50px] sm:h-[8vw] lg:h-[6.1vw] 2xl:h-[4.8vw] min-[1800px]:h-[4.2vw]`}>
                <img src={customerPath + 'video_thumbnails/' + thumb} className={`w-full h-full object-cover`}/> 
              </div>
            })}
          </div>

        </a>


        <div className={`w-full flex justify-between items-center`}>
          <div className='w-full pl-2 pt-[4px] pb-[6px]' onMouseUp={(event)=> navigateOrOpenFunc(event, localUrlText) }>
            <div className='w-full h-[23px]'><p className='text-[13px] xl:text-[14px] max-w-[225px] truncate'>{folder?.name}</p></div>

            <p className='text-xs max-w-[225px] text-gray-400 truncate'>
              {folder?.search_module_name} / {(folder?.search_number_of_videos)? `Contiene ${folder?.search_number_of_videos} ${(folder?.search_number_of_videos === 1)? 'vídeo' : 'vídeos'}` : 'Carpeta vacía'}{(folder?.search_number_of_videos && folder?.search_everything_completed)? ' finalizado' : (folder?.search_number_of_videos === 1 && folder?.search_everything_completed)? ', todos finalizados' : ''}
            </p>
          </div>

          <button
            onClick={(event) => setOptionsAnchor(event.currentTarget)} 
            className={`py-1 px-[1px] mr-2 hover:bg-gray-100 text-gray-400 hover:scale-125 hover:text-blue-500 active:scale-100 rounded duration-100
              ${(optionsAnchor)? 'bg-gray-200' : ''}
            `}
          >
            {menuIcon}
          </button>

          <MenuFolderOptions 
            anchorEl={optionsAnchor} 
            setAnchorEl={setOptionsAnchor} 
            folder={folder}

            createFolder={createFolder}
            setCreateFolder={setCreateFolder}
            copied={copied}
            setCopied={setCopied}
            urlText={urlText}
          />
        </div>






      </button>
    </div>
  );
}