import gql from "graphql-tag";
export default gql`
  query GetVideoMarks(
    
    $own_user_token: String!,
    $video_id: Int!,
    $user_id: Int
    
  ) {
    videoMarksByVideoId(
      
      own_user_token: $own_user_token,
      video_id: $video_id,
      user_id: $user_id
      
    ) {
      
      id
      time
      time_end
      observations
      texts
      url
      coordinates
      
      swap_date
      swap_user
      info_user_id
      hidde_original
      
      public
      notificate_to
      notificate_to_label
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      video_id
      user_id
      edit_user_ids
      type_id
      review_id
      difficulty_id
      is_translation_of
      original_video_id
      file_process_id

      extra_mark_facturation
      extra_facturation_correction
      manual_number_of_words
      new_price
      new_price_observations

      search_guest_data
      search_type_name
      search_type_color
      search_type_type
      search_review_name
      search_recording_set_label
      localization_end_date
      
      disabled
      facturation_disabled
      facturation_force_insertion

      createdAt
      updatedAt

      info_user {
        id
        name
        positions
      }

      user {
        id
        name
        email
        profile_picture
        language
        rol
        visible
        enabled

        original_customer_token
        navigation_customer_tokens

        createdAt
        updatedAt
        
        from_customer {
          token
          icon
          abbreviated_name
          name
        }
      }

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
