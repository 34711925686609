import gql from "graphql-tag";
export default gql`
  query Factura10GetAllVideos(
    
    $own_user_token: String!,
    $own_customer_token: String!,

    $name: String,
    $professor_id: String,
    $module: String,
    $localizations: Int,
    $avoidAudios: Boolean,
    $translationDone: Boolean
    $needs_category_id: Boolean
    
  ) {
    getCustomerVideos(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,

      name: $name,
      professor_id: $professor_id,
      module: $module,
      localizations: $localizations,
      avoidAudios: $avoidAudios,
      translationDone: $translationDone
      needs_category_id: $needs_category_id
      
    ) {
      
      id
      name
      title
      old_title
      title_disabled
      chyrons_disabled

      customer_token
      category_id
      module_id
      vimeo_url
      production_state
      is_active
      is_part_of
      
      state
      revision_state
      trad_done

      chyron_states
      
      factura10_product_id
      extra_mark_facturation
      extra_facturation_correction
      extra_mark_facturation_chyron
      extra_facturation_correction_chyron
      manual_number_of_words
      
      search_reviewed
      search_substituted_chyrons
      search_module_abbreviated_name
      
      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words

      extra_marks_json
      extra_languages_json
      facturation_note

      hasSRTinVIMEO
      hasRask

      professor {
        id
        name
        professor_code
        vinculated_to_id
        sex
        positions
      }

      extraProfessors {
        id
        name
        sex
        positions
      }

      productionState {
        id
        name
        color
        position
        customer_token
        original
        
        done
        facturate_marks
        ended_marks
        trigger_notifications
        trigger_publication
      }

      original_video {
        id
        name

        old_title
        title

        language
        original_language
        has_subtitulation
        has_transcription
        has_translation
        is_translation_of
        chyrons_disabled
          
        numberOfWords
      }

      trad_videos {
        id
        name
        
        language
        original_language
        has_subtitulation
        has_transcription
        has_translation
        is_translation_of

        numberOfWords
      }
      
      numberOfWords
      time
      minutes
      __typename

    }
  }
`;
