import gql from "graphql-tag";
export default gql`
  query ChartGetAllVideos(
    
    $own_user_token: String!,
    $own_customer_token: String!,

    $name: String,
    $professor_id: String,
    $module: String,
    $localizations: Int,
    $avoidAudios: Boolean,
    $translationDone: Boolean
    $needs_category_id: Boolean

    $recordingDateStart: String
    $recordingDateEnd: String
    
  ) {
    getCustomerVideos(
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,

      name: $name,
      professor_id: $professor_id,
      module: $module,
      localizations: $localizations,
      avoidAudios: $avoidAudios,
      translationDone: $translationDone
      needs_category_id: $needs_category_id

      recordingDateStart: $recordingDateStart
      recordingDateEnd: $recordingDateEnd

    ) {
      
      id
      name
      url_name

      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled

      thumbnail
      thumbnail_small
      professor_id
      extra_professors
      extra_professors_label
      category_id
      module_id
      folder_id
      recording_set_ids
      vimeo_url
      is_active
      is_priority
      is_priority_change_label
      is_priority_date
      is_part_of

      language
      original_language
      has_subtitulation
      has_transcription
      has_translation
      is_translation_of
      number_of_words
      
      recordingDate
      publicationEndDate
      endDate
      createdAt
      updatedAt

      moduleName
      moduleAbbreviatedName
      time
      minutes

      search_module_name

      firstReviewMinutes
      
      recordingDateFormated
      publicationEndDateFormated
      endDateFormated
      createdAtFormated


      __typename

    }
  }
`;
