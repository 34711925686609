import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'

 


export default function SearchRecomendations({

  searchedText,
  setSearchedText,

  moduleFilter,
  setModuleFilter,

  startFilterDate,
  setStartFilterDate,
  endFilterDate,
  setEndFilterDate,
  professorFilter,
  setProfessorFilter,

  hasVideos,
  hasVideosPostFilter,

}) {
  

  const { currentUser, currentCustomer } = useContext(AuthContext)

  const [recomendations, setRecomendations] = useState(null);
  const [moduleRecomendations, setModuleRecomendations] = useState(null);


  const researchIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M432-336q-20 0-39-3.5t-37-9.5q-14-5-20-19t1-28q7-14 21-19t29 0q11 3 22 5t23 2q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119l28-27q11-11 25.5-10t25.5 12q11 11 11 25.5T343-550l-86 85q-11 11-25 11t-25-11l-86-86q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l20 20q2-98 71.5-166.5T432-816q100 0 170 70t70 170q0 40-12.5 76T625-434l214 214q11 11 11 25t-11 25q-11 11-25.5 11T788-170L574-383q-30 22-66 34.5T432-336Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const folderIcon = <svg className='text-gray-500' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M168-192q-32 0-52-21.162T96-264.04v-432.243Q96-726 116-747t52-21h185.643q14.349 0 27.353 5Q394-758 405-747l75 75h313q31 0 50.5 21t21.5 51H451l-96-96H168v432l63-212q7-23 26-37.5t43-14.5h539q36 0 58 29t11 64l-57 192q-8 23-21 37t-38 14H168Zm75-72h538l59-192H300l-57 192Zm-75-213v-219 219Zm75 213 57-192-57 192Z"/></svg>




  
  useEffect(() => {
    if (searchedText?.replaceAll(' ', '')) {
      setRecomendationsFunc();      
    } else {
      setRecomendations(null)
    }
  }, [searchedText])




  async function setRecomendationsFunc() {
    var recomendations = []
    var moduleRecomendations = {}
    var textSplit = searchedText?.split('-')

    if (textSplit?.length === 3) {
      recomendations?.push(textSplit[0])
      recomendations?.push(textSplit[0] + '-' + textSplit[1])
      
      var numbersMatch = (textSplit[2]?.replace(/[^0-9]/g, "") + '')?.length;
      var numbersDiff = (numbersMatch && numbersMatch <= 4)? (4 - numbersMatch) : 0;
      var numbersFix = null;
      if (numbersDiff) { new Array(numbersDiff).fill(0).forEach(function(){ numbersFix = (numbersFix)? numbersFix + '0' : '0' }); }
      if (numbersFix) {
        var numbersCorrected = numbersFix +'' + textSplit[2]?.replaceAll('0', '')
        recomendations?.push(textSplit[0] + '-' + textSplit[1] + '-' + numbersCorrected)
      }

      if (textSplit[2]?.includes('_')) { recomendations?.push(textSplit[0] + '-' + textSplit[1] + '-' + textSplit[2]?.replaceAll('_es', '')?.replaceAll('_en', '')) }
    }


    
    if (hasVideos && !hasVideosPostFilter) {
      const response =  await axios.post(`${process.env.REACT_APP_API_ROUTE}getSearchSuggestedVideos`, { search: searchedText, own_customer_token: currentCustomer?.token, own_user_token: currentUser?.token })
      const responseData = (response?.data?.length)? response?.data : null;

      if (responseData?.length) {
        for (var videoTMP of responseData) {
          moduleRecomendations[videoTMP?.module_id] = videoTMP?.search_module_name;
        }
      }
    }


    setRecomendations((recomendations?.length)? recomendations : null)
    setModuleRecomendations((moduleRecomendations && Object.keys(moduleRecomendations)?.length)? moduleRecomendations : null)
  }





  return (hasVideos && !hasVideosPostFilter && (searchedText || (moduleFilter !== 'all' && moduleRecomendations && Object.keys(moduleRecomendations)?.length) || professorFilter || startFilterDate || endFilterDate))?
    <div className='flex'>
      <div className='flex items-center space-x-2 text-sm text-gray-500'>
        <p className='px-2 py-[3px] text-gray-400 bg-gray-100 border border-gray-300 border-opacity-50 rounded'>Sugerencias:</p>

        
        {(!professorFilter?.length || professorFilter[0] !== 'null') && 
          <button
            className='flex items-center space-x-1 font-bold text-red-500 hover:text-red-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-red-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => setProfessorFilter(null)}
          >
            {cancelIcon}
            <p>Filtro por {currentCustomer?.label_professor?.toLowerCase()}</p>
          </button>
        }


        {(startFilterDate) && 
          <button
            className='flex items-center space-x-1 font-bold text-red-500 hover:text-red-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-red-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => setStartFilterDate(null)}
          >
            {cancelIcon}
            <p>Fecha de inicio</p>
          </button>
        }


        {(endFilterDate) && 
          <button
            className='flex items-center space-x-1 font-bold text-red-500 hover:text-red-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-red-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => setEndFilterDate(null)}
          >
            {cancelIcon}
            <p>Fecha fin</p>
          </button>
        }


        {(searchedText) && 
          <button
            className='flex items-center space-x-1 font-bold text-red-500 hover:text-red-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-red-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => {
              setSearchedText('')
              var searchBar = document.getElementById('search-input');
              if (searchBar) { searchBar.value = ''; }
            }}
          >
            {cancelIcon}
            <p>Texto de busqueda</p>
          </button>
        }



        {/* {(moduleFilter !== 'all' && !moduleRecomendations) && 
          <button
            className='flex items-center space-x-1 font-bold text-red-500 hover:text-red-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-red-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => setModuleFilter('all')}
          >
            {cancelIcon}
            <p>Filtro por {currentCustomer?.label_module?.toLowerCase()}</p>
          </button>
        } */}


        {((moduleFilter !== 'all' && moduleRecomendations && Object.keys(moduleRecomendations)?.length)? Object.keys(moduleRecomendations) : [])?.map((module_id) => {
          const moduleName = moduleRecomendations[module_id];
          const sameModule = parseInt(module_id) === parseInt(moduleFilter);

          return (!sameModule)? 
            <button
              key={'mod-' + module_id}
              className='flex items-center space-x-1 font-bold text-blue-500 hover:text-blue-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-blue-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
              onClick={() => setModuleFilter(module_id)}
            >
              {folderIcon}
              <p>{moduleName}</p>
            </button>
          : null
        })}





        {recomendations?.reverse()?.map((rec, key) => {
          return <button
            key={'rec-' + rec}
            className='flex items-center space-x-1 font-bold text-blue-500 hover:text-blue-600 bg-gray-100 border border-gray-300 hover:bg-gray-300 hover:bg-opacity-60 active:bg-blue-500 active:text-white duration-200 rounded py-[3px] pl-1 pr-2'
            onClick={() => {
              setSearchedText(rec)
              var searchBar = document.getElementById('search-input');
              if (searchBar) { searchBar.value = rec; }
            }}
          >
            {researchIcon}
            <p>{rec}</p>
          </button>
        })}
        
      </div>
    </div>
  : null
}