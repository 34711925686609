export default function VIDEO_ENTREVISTA_EN_PLATO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="mt-[-1px] scale-[0.9]">
			<g>
				<path d="M434.35,398.27c-2.04-4.06-4.07-8.12-6.1-12.18c-4.42-8.84-8.99-17.97-13.54-26.93c-0.74-1.46-0.71-2.22,0.14-3.5
					c16.33-24.65,23.04-51.08,19.93-78.57c-5.58-49.32-32.06-84.04-78.68-103.19c-14.87-6.11-31.3-8.49-53.31-7.7
					c-2.64,0.1-3.67-0.58-4.76-3.13c-18.91-43.85-63.38-73.14-110.9-73.14c-0.35,0-0.69,0-1.03,0c-24.17,0.26-46.73,7.11-67.04,20.36
					c-26.92,17.56-45.39,44.91-52.01,77c-6.6,31.99-0.49,64.28,17.19,90.93c1.52,2.29,1.6,3.63,0.38,5.99
					c-4.22,8.16-8.39,16.52-12.42,24.61c-2.17,4.36-4.35,8.72-6.54,13.07c-1.84,3.65-1.55,6.87,0.87,9.55
					c1.69,1.88,4.77,3.64,9.83,1.35c0.36-0.16,0.7-0.34,1.05-0.52l8.48-4.23c9.89-4.93,19.78-9.86,29.66-14.82
					c1.36-0.68,1.95-0.79,2.96-0.12c24.29,16.21,51.13,22.98,79.77,20.13c1.57-0.16,2.01,0.14,2.76,1.9
					c14.35,33.82,42.38,59.25,76.92,69.74c11.49,3.49,23.29,5.21,35.07,5.21c23.62,0,47.11-6.92,67.62-20.34
					c1.54-1,2.45-1.05,4.07-0.23c10.58,5.38,21.37,10.76,31.81,15.96l5.89,2.93c0.23,0.11,0.46,0.25,0.7,0.38
					c1.01,0.57,2.15,1.21,3.38,1c2.23-0.39,5.01-0.88,6.9-2.47C435.89,405.22,436.22,402.01,434.35,398.27z M94.52,306.4
					c-1.63,0.81-3.26,1.62-4.89,2.43c0.58-1.2,1.15-2.4,1.75-3.59c0.88-1.77,1.76-3.55,2.63-5.33c2.28-4.63,4.63-9.42,7.1-14.03
					c2.22-4.15,1.95-7.78-0.86-11.79c-20.98-29.9-25.79-62.58-14.31-97.14c15.14-45.54,62.75-76.04,110.74-70.95
					c50.76,5.38,89.19,43.14,95.63,93.97c6.99,55.22-33.75,108.84-88.97,117.1c-28.99,4.34-55.7-2.1-79.37-19.13
					c-2.02-1.46-3.98-2.18-6.02-2.18c-1.66,0-3.36,0.48-5.19,1.43C106.73,300.34,100.52,303.42,94.52,306.4z M400.5,349.02
					c-3.58,5.04-3.91,9.33-1.11,14.32c2.77,4.93,5.29,10.13,7.74,15.15c1,2.06,2,4.12,3.02,6.16c0.06,0.12,0.12,0.24,0.18,0.37
					c-2.08-1.03-4.16-2.05-6.24-3.07c-5.31-2.6-10.81-5.29-16.08-8.16c-4.52-2.46-8.37-2.2-12.87,0.87
					c-33.67,23.01-69.51,26.34-106.5,9.91c-22.89-10.17-40.29-27.56-51.73-51.7c-0.37-0.77-0.68-1.57-1.04-2.49
					c33.09-8.96,58.44-27.84,75.36-56.13c16.9-28.24,21.42-59.36,13.44-92.52c10.43-0.27,19.31-0.05,28.15,1.67
					c47.37,9.22,82.21,47.54,86.68,95.35C421.86,304.28,415.48,327.92,400.5,349.02z"/>
				<path d="M237.26,232.38c0.02,0,0.03,0,0.05,0c11.13-0.02,20.29-9.09,20.42-20.21c0.06-5.44-2.02-10.58-5.86-14.48
					c-3.86-3.92-8.99-6.09-14.47-6.12c-0.04,0-0.07,0-0.11,0c-5.47,0-10.6,2.1-14.45,5.92c-3.86,3.83-5.99,8.95-6,14.42
					c-0.01,5.47,2.12,10.61,5.99,14.49C226.69,230.25,231.82,232.38,237.26,232.38z M237.27,207.01
					C237.27,207.01,237.27,207.01,237.27,207.01c1.26,0,2.46,0.52,3.4,1.46c0.93,0.94,1.46,2.2,1.46,3.46c0,2.69-2.17,4.89-4.85,4.9h0
					c0,0-0.01,0-0.01,0c-1.3,0-2.51-0.5-3.43-1.42c-0.92-0.92-1.42-2.15-1.43-3.46C232.41,209.27,234.63,207.02,237.27,207.01z"/>
				<path d="M186.25,232.38c0.08,0,0.16,0,0.24,0c5.39,0,10.45-2.06,14.27-5.83c3.89-3.83,6.05-8.97,6.07-14.47
					c0.02-5.54-2.07-10.72-5.88-14.56c-3.8-3.83-8.92-5.94-14.43-5.96c-0.02,0-0.04,0-0.06,0c-5.47,0-10.6,2.12-14.45,5.97
					c-3.86,3.86-5.98,8.99-5.97,14.45C166.06,223.1,175.13,232.25,186.25,232.38z M189.84,215.46c-0.93,0.91-2.17,1.35-3.44,1.38h0
					c-2.67-0.04-4.82-2.27-4.79-4.97c0.02-1.28,0.57-2.55,1.52-3.47c0.92-0.9,2.1-1.39,3.32-1.39c0.02,0,0.05,0,0.07,0
					c2.64,0.04,4.84,2.33,4.8,5C191.32,213.31,190.79,214.54,189.84,215.46z"/>
				<path d="M135.65,232.37c0.01,0,0.02,0,0.03,0c5.46,0,10.6-2.13,14.46-6c3.87-3.87,5.99-9.01,5.98-14.47
					c-0.02-11.11-9.09-20.23-20.22-20.34h0c-5.52,0-10.69,2.01-14.56,5.82c-3.86,3.8-6,8.93-6.02,14.43
					c-0.02,5.53,2.08,10.71,5.93,14.59C125.08,230.25,130.19,232.37,135.65,232.37z M132.23,208.53c0.95-0.96,2.19-1.51,3.42-1.52
					c0.01,0,0.02,0,0.02,0c2.64,0,4.88,2.24,4.9,4.9c0.02,2.69-2.16,4.9-4.85,4.92c-0.01,0-0.03,0-0.04,0c-2.6,0-4.89-2.26-4.92-4.86
					C130.75,210.74,131.28,209.49,132.23,208.53z"/>
				<path d="M313.61,267.7c-0.06,0-0.12,0-0.17,0c-5.42,0-10.5,2.08-14.32,5.86c-3.88,3.83-6.02,8.98-6.03,14.47
					c-0.01,5.53,2.08,10.7,5.91,14.53c3.81,3.83,8.94,5.94,14.45,5.95c0.01,0,0.02,0,0.02,0c5.47,0,10.61-2.13,14.47-6.01
					c3.86-3.87,5.97-9.01,5.94-14.47C333.82,276.91,324.73,267.79,313.61,267.7z M318.3,288.06c0.04,2.67-2.16,4.97-4.79,5.02
					c-1.23,0.09-2.44-0.47-3.38-1.39c-0.96-0.93-1.53-2.2-1.54-3.48c-0.04-2.7,2.11-4.92,4.78-4.96c0.03,0,0.05,0,0.08,0
					c1.27,0,2.46,0.49,3.37,1.37C317.75,285.52,318.28,286.75,318.3,288.06z"/>
				<path d="M364.29,267.71c-0.02,0-0.04,0-0.06,0c-5.46,0-10.59,2.12-14.45,5.98c-3.87,3.86-6,8.99-5.99,14.45
					c0.01,5.47,2.14,10.6,6.01,14.44c3.85,3.83,8.97,5.93,14.41,5.93c0.04,0,0.07,0,0.11,0c5.51-0.03,10.63-2.16,14.43-6.01
					c3.81-3.86,5.88-9.03,5.83-14.56C384.48,276.63,375.57,267.74,364.29,267.71z M364.25,293.06c0,0-0.01,0-0.01,0
					c-2.64,0-4.88-2.25-4.89-4.91c-0.01-2.69,2.17-4.89,4.86-4.91c0.01,0,0.02,0,0.03,0c1.27,0,2.49,0.51,3.43,1.45
					c0.95,0.94,1.48,2.15,1.49,3.42c0.01,1.23-0.54,2.49-1.49,3.44C366.72,292.51,365.47,293.06,364.25,293.06L364.25,293.06z"/>
			</g>
		</svg>
	</>
}
