import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateUser(

      $own_user_token: String!,
      $own_customer_token: String!,

      $id: Int,
      $professor_code: String,
      $vinculated_to_id: Int,
      $abbreviated_name: String,
      $name: String,

      $positions: String,

      $email: String,
      $language: String,
      $password: String,
      $rol: String,
      $recive_emails: Int,
      $recive_automated_emails: Boolean,
      $recive_review_emails: Boolean,
      $recive_reply_emails: Boolean,
      $professor: Boolean,
      $tags: String,
      $sex: Int,
      $treatment: Int,
      $visible: Boolean,
      $enabled: Boolean,
      $original_customer_token: String,
      $navigation_customer_tokens: String,
      $customer_free: Boolean,

      $generate_vinculated_user: Boolean,
      $generate_vinculated_user_email: String,
      $generate_vinculated_user_password: String,

    ) {
    createOrUpdateUser(input: { 

      own_user_token: $own_user_token, 
      own_customer_token: $own_customer_token, 

      id: $id, 
      professor_code: $professor_code, 
      vinculated_to_id: $vinculated_to_id, 
      abbreviated_name: $abbreviated_name, 
      name: $name, 

      positions: $positions, 

      email: $email, 
      language: $language, 
      password: $password, 
      rol: $rol, 
      recive_emails: $recive_emails, 
      recive_automated_emails: $recive_automated_emails, 
      recive_review_emails: $recive_review_emails, 
      recive_reply_emails: $recive_reply_emails, 
      professor: $professor, 
      tags: $tags, 
      sex: $sex, 
      treatment: $treatment, 
      visible: $visible, 
      enabled: $enabled, 
      original_customer_token: $original_customer_token, 
      navigation_customer_tokens: $navigation_customer_tokens, 
      customer_free: $customer_free, 

      generate_vinculated_user: $generate_vinculated_user, 
      generate_vinculated_user_email: $generate_vinculated_user_email, 
      generate_vinculated_user_password: $generate_vinculated_user_password, 

    }) {

      id
      token
      abbreviated_name
      name

      positions
      
      email
      profile_picture
      language
      professor
      professor_code
      vinculated_to_id
      tags
      sex
      treatment
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      enabled
      
      original_customer_token
      navigation_customer_tokens
      customer_free
      license_token

      createdAt
      updatedAt

      customer_permission {
        id
        token
        icon
        abbreviated_name
        factura10_client_id
        name
        language
        createdAt
        updatedAt
      }
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
