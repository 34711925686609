
export default function CustomButton({

    id,
    icon,
    label,
    loading,

    className,
    disabled,
    color,
    size,

    onClick,

}) {

    const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

    return <button
        id={id}
        className={`
            ${(className)? 
                className 
            : 
                `text-sm flex items-center active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap
                    ${
                        (size === 'big')? 'pl-2 pr-3 py-2 space-x-2' 
                        : 'pl-1 pr-2 py-1 space-x-1'
                    }
                    ${
                        (color === 'lightgray')? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 shadow-md' 
                        : (color === 'red')? 'text-white bg-red-500 hover:bg-red-600 active:bg-red-700 shadow-md' 
                        : 'text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 shadow-md'
                    }
                `
            }
        `}

        onClick={onClick} 
        disabled={disabled}
    >
        {(loading)? loadingIcon : icon}
        <p>{(loading)? loading : label}</p>
    </button>
}