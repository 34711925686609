import { useContext } from "react";
import * as diff from "diff";
import PropTypes from "prop-types";

import { FuncContext } from '../../contexts/FuncContext'




window.diff = diff;

const styles = {
  added: {
    color: "green",
    backgroundColor: "#b5efdb",
    paddingInline: 2,
    borderRadius: 3,
  },
  removed: {
    color: "red",
    backgroundColor: "#fec4c0",
    paddingInline: 2,
    borderRadius: 3,
    textDecoration: 'line-through',
  }
};




const Diff = ({ string1 = "", string2 = "", mode = "characters" }) => {

  const { format_texts, remove_tags } = useContext(FuncContext)

  let groups = [];

  if (mode === "characters") groups = diff.diffChars(string1, string2);
  if (mode === "words") groups = diff.diffWords(string1, string2);

  const mappedNodes = groups.map((group, key) => {
    const { value, added, removed } = group;
    let nodeStyles;

    if (added) nodeStyles = styles.added;
    if (removed) nodeStyles = styles.removed;
    return (value === '\n')? '\n' : <span key={'span-'+value+'-'+key} style={nodeStyles} dangerouslySetInnerHTML={{__html: format_texts(value.replace(/\n/g, "<br />")) }}></span>;
  });

  return <span>{ mappedNodes }</span>;
};

Diff.propTypes = {
  string1: PropTypes.string,
  string2: PropTypes.string,
  mode: PropTypes.oneOf(["characters", "words"])
};

export default Diff;
