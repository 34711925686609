import gql from "graphql-tag";
export default gql`
  query GetBill($id: Int, $f10ID: Int, $own_user_token: String, $own_customer_token: String!, $password: String) {
    bill(id: $id, f10ID: $f10ID, own_user_token: $own_user_token, own_customer_token: $own_customer_token, password: $password) {
      id
      token
      label
      observations
      video_notes
      video_edits
      f10ID
      type
      module
      hidden_concepts
      customer_token

      visible
      public
      blocked

      include_extras
      include_c1_new

      external_price
      external_video_data

      date
      createdAt
      updatedAt

      customer
      dateFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
