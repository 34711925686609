import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useParams } from "react-router-dom";
import axios from 'axios'

import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';





export default function VideoTopNavigation({
  mode,
  setMode,
  disableModeNav,
  navigateTo,

  video,
  newVideoMark,

  loading,
  loadingReviews,
  loadingVideo,
}) {
  


  
  const { singular, name, professorCode, path_module } = useParams()
  const { currentUser, currentCustomer, showNavTop } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  
  const localCurrentMode = localStorage.getItem(currentUser?.token+'-'+currentCustomer?.token+'-video-window');



  const [viweportMode, setViweportMode] = useState((localCurrentMode)? localCurrentMode : mode);
  const [disableTopMenu, setDisableTopMenu] = useState(false);
  const [loadingTabChange, setLoadingTabChange] = useState(true);

  /* Vide group vars */
  const [videoGroupPrev, setVideoGroupPrev] = useState(null);
  const [videoGroupPrevID, setVideoGroupPrevID] = useState(null);

  const [videoGroupNext, setVideoGroupNext] = useState(null);
  const [videoGroupNextID, setVideoGroupNextID] = useState(null);

  /* Icon vars */
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const reviewIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor" viewBox="0 96 960 960"><path d="M264 840v36q0 15.3-10.289 25.65-10.29 10.35-25.5 10.35Q213 912 202.5 901.65 192 891.3 192 876V276q0-15.3 10.35-25.65Q212.7 240 228 240q15.3 0 25.65 10.35Q264 260.7 264 276v36h72v-36q0-15.3 10.35-25.65Q356.7 240 372 240h216q15.3 0 25.65 10.35Q624 260.7 624 276v36h72v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747 240 757.5 250.35 768 260.7 768 276v600q0 15.3-10.35 25.65Q747.3 912 732 912q-15.3 0-25.65-10.35Q696 891.3 696 876v-36h-72v36q0 15.3-10.35 25.65Q603.3 912 588 912H372q-15.3 0-25.65-10.35Q336 891.3 336 876v-36h-72Zm0-72h72v-80h-72v80Zm0-152h72v-80h-72v80Zm0-152h72v-80h-72v80Zm360 304h72v-80h-72v80Zm0-152h72v-80h-72v80Zm0-152h72v-80h-72v80ZM408 840h144V312H408v528Zm0-528h144-144Z"/></svg>
  const translationIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor" viewBox="0 96 960 960"><path d="M533 976q-24 0-34.5-15.568t-2.5-38.668L642.948 533.07Q649 517 666 506t34.478-11q16.508 0 33.015 11t22.582 27.075L903.9 921.892Q912 945 901 960.5 890 976 865.694 976q-10.694 0-20.103-7T832 952l-34.149-99H603l-34.103 98.907Q565 962 554.677 969q-10.322 7-21.677 7Zm95-193h143l-70-197h-3l-70 197ZM286 436q16 29 34 53.5t42 51.5q45-48 73.256-98.5Q463.513 392 483 335H80q-17 0-28.5-11.5T40 295q0-17 11.5-28.5T80 255h240v-40q0-17 11.5-28.5T360 175q17 0 28.5 11.5T400 215v40h240q17 0 28.5 11.5T680 295q0 17-11.5 28.5T640 335h-77q-20 71-55.712 138T419 599l95 98-30 82-123-123-172 172q-11 11-28 11t-28-12q-12-11-12-28t12-29l173-173.463q-27-30.904-50-62.307-23-31.403-41-66.295Q204 447 214.5 431q10.5-16 35.5-16 10 0 20.593 6.205Q281.185 427.409 286 436Z"/></svg>
  const motionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor" viewBox="0 96 960 960"><path d="M321.808 260Q305 260 292.5 247.5T280 218q0-17 12.5-29.5t29.308-12.5h316.384Q655 176 667.5 188.5T680 218q0 17-12.5 29.5T638.192 260H321.808ZM203.8 380q-16.8 0-29.3-12.5T162 338q0-17 12.5-29.5t29.3-12.5h553.4q16.8 0 29.3 12.5T799 338q0 17-12.5 29.5T757.2 380H203.8ZM164 976q-34.65 0-59.325-24.675Q80 926.65 80 892V500q0-34.65 24.675-59.325Q129.35 416 164 416h632q34.65 0 59.325 24.675Q880 465.35 880 500v392q0 34.65-24.675 59.325Q830.65 976 796 976H164Zm0-84h632V500H164v392Zm300-77 126-84q19-13 19.5-35T591 661l-127-84q-20.968-14-42.984-1.853Q399 587.294 399 612v168q0 26 22.016 37.5T464 815ZM164 499v393-393Z"/></svg>




  /* Toggle button style var */
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));





  
  /* useEffect( () => { 
    if (mode !== null) {
      setViweportMode((localCurrentMode)? localCurrentMode : mode)
    }
  }, [mode]) */


  useEffect( () => { 
    if (loadingTabChange) {
      setTimeout(() => {
        setLoadingTabChange(false)
      }, "500")
    }
  }, [loadingTabChange])


  useEffect(() => { 
    setVideoGroupPrev(null)
    setVideoGroupPrevID(null)

    setVideoGroupNext(null)
    setVideoGroupNextID(null)

    if (video && !singular) {
      var videoGroupTMP = !(video?.videoGroup)? null : /* (professorCode? video?.videoGroupByProfessors : */ video?.videoGroup/* ) */;

      if (videoGroupTMP && videoGroupTMP.split('.')[0]?.split(',')?.length > 1) {
        var videoGroupsTMP = videoGroupTMP?.split('.');
        var names = videoGroupsTMP[0]?.split(',');
        var ids = videoGroupsTMP[1]?.split(',');
        var modules = videoGroupsTMP[2]?.split(',');

        names?.map((element, key) => {
          if (element === video?.name && names[key-1]) {
            setVideoGroupPrev(names[key-1])
            setVideoGroupPrevID(ids[key-1])
          }
          if (element === video?.name && names[key+1]) {
            setVideoGroupNext(names[key+1])
            setVideoGroupNextID(ids[key+1])
          }
        });
      }
    }
  }, [video])


  useEffect(() => { 
    setDisableTopMenu(newVideoMark? true : false || disableModeNav || loadingTabChange || loadingReviews || loadingVideo || loading)
  }, [newVideoMark, disableModeNav, loadingTabChange, loadingReviews, loadingVideo, loading])





  async function getVideoFolderPathFunc(event, video) {
    var path_data = null;

    if (video?.folder_id) {
      const path_response = await axios.post(`${process.env.REACT_APP_API_ROUTE}getVideoFolderPath`, { folder_id: video?.folder_id })
      path_data = (path_response?.data)? path_response?.data : null
    }

    navigateOrOpenFunc(event, (path_data)? path_data : `/videos${(name)? `/name/${name}` : ''}`)
  }
              




  return (!singular) && (
    <div className='w-full flex justify-center'>
      <div className='w-full max-w-[1740px] px-[6px] flex items-center justify-between truncate text-[10px] md:text-xs m-1 md:m-0'>
        <div className='flex justify-start w-full py-[6px]'>
          <button
            className={`h-[25px] pr-2 flex justify-start items-center space-x-1 text-gray-400 hover:bg-gray-200 hover:bg-opacity-75 active:bg-gray-300 rounded hover:text-blue-500 cursor-pointer duration-200 disabled:opacity-50 disabled:pointer-events-none`}
            onMouseUp={(event) => getVideoFolderPathFunc(event, video)}
            disabled={disableTopMenu}
          >
            {leftArrowIcon}
            <p>Volver <span className='hidden lg:inline-block'>a todos los vídeos {(!showNavTop && currentCustomer)? 'de ' + currentCustomer?.abbreviated_name : ''}</span></p>
          </button>
        </div>



        <div>
          {(viweportMode && !name && !professorCode && !currentUser?.rol?.includes('user')) && 
            <StyledToggleButtonGroup
              color="primary"
              value={viweportMode}
              exclusive
              onChange={(event, value)=>{
                setLoadingTabChange(true)
                setViweportMode(value)

                localStorage.setItem(currentUser?.token+'-'+currentCustomer?.token+'-video-window', value)

                setTimeout(()=>{
                  setMode(value)
                }, 200)
              }}
              size='small'
              className='border-0'
              disabled={disableTopMenu}
              sx={{ height: 35 }}
            >
              <ToggleButton value={'review'} disabled={(viweportMode === 'review')}>
                <div className='flex items-center space-x-1 text-[10px] md:text-xs'>
                  <div>{(loadingTabChange && viweportMode === 'review')? <div className='animate-spin'>{loadingIcon}</div> : reviewIcon}</div>
                  <div className='hidden sm:inline-block'>Revisor</div>
                </div>
              </ToggleButton>

              {(currentUser?.rol?.includes('translator') || currentUser?.rol === 'admin') && 
                <ToggleButton value={'translation'} disabled={(viweportMode === 'translation')}>
                  <div className='flex items-center space-x-1 text-[10px] md:text-xs'>
                    <div>{(loadingTabChange && viweportMode === 'translation')? <div className='animate-spin'>{loadingIcon}</div> : translationIcon}</div>
                    <div className='hidden sm:inline-block'>Traductor</div>
                  </div>
                </ToggleButton>
              }

              {(currentUser?.rol?.includes('editor') || currentUser?.rol === 'admin') && 
                <ToggleButton value={'motion'} disabled={(viweportMode === 'motion')}>
                  <div className='flex items-center space-x-1 text-[10px] md:text-xs'>
                    <div>{(loadingTabChange && viweportMode === 'motion')? <div className='animate-spin'>{loadingIcon}</div> : motionIcon}</div>
                    <div className='hidden sm:inline-block'>Motion</div>
                  </div>
                </ToggleButton>
              }
            </StyledToggleButtonGroup>
          }
        </div>



        <div className='flex justify-end items-center w-full py-[6px]'>
          {(videoGroupPrev) && 
            <button 
              className={`h-[25px] pr-2 flex justify-start items-center space-x-1 text-gray-400 hover:bg-gray-200 hover:bg-opacity-75 active:bg-gray-300 rounded hover:text-blue-500 cursor-pointer duration-200 disabled:opacity-50 disabled:pointer-events-none`}
              disabled={disableTopMenu}
              onMouseUp={(event)=> 
                (videoGroupPrev)? (
                  navigateTo === 'video'? navigateOrOpenFunc(event, ("/video" + ((name)? ("/" + (videoGroupPrev?.split('-')[0])) : "") + "/" + videoGroupPrevID))  /* videoGroupPrevID */
                  : navigateOrOpenFunc(event, ("/" + currentCustomer?.abbreviated_name + "/" + path_module + "/" + ((name)? videoGroupPrev?.split('-')[0] + '/' + videoGroupPrevID : videoGroupPrev) ))  /* videoGroupPrevID */
                ) : null 
              }
            >
              {leftArrowIcon}
              <div className='hidden sm:inline-block'>{(videoGroupPrev)? videoGroupPrev : video?.name}</div>
            </button>
          }

          {(videoGroupPrev && videoGroupNext)? <div className={`border-l border-gray-300 mx-2 h-[17px]`}></div> : null }

          {(videoGroupNext) && 
            <button 
              className={`h-[25px] pl-2 flex justify-start items-center space-x-1 text-gray-400 hover:bg-gray-200 hover:bg-opacity-75 active:bg-gray-300 rounded hover:text-blue-500 cursor-pointer duration-200 disabled:opacity-50 disabled:pointer-events-none`}
              disabled={disableTopMenu}
              onMouseUp={(event)=> 
                (videoGroupNext)? (
                  navigateTo === 'video'? navigateOrOpenFunc(event, ("/video" + ((name)? ("/" + (videoGroupNext?.split('-')[0])) : "") + "/" + videoGroupNextID))  /* videoGroupNextID */
                  : navigateOrOpenFunc(event, ("/" + currentCustomer?.abbreviated_name + "/" + path_module + "/" + (((name)? videoGroupNext?.split('-')[0] + '/' + videoGroupNextID : videoGroupNext)) ))  /* videoGroupNextID */
                ) : null 
              }
            >
              <div className='hidden sm:inline-block'>{(videoGroupNext)? videoGroupNext : video?.name}</div>
              {rightArrowIcon}
            </button>
          }
        </div>

      </div>
    </div>
  );
}