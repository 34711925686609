import React from "react"
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { ApolloProvider } from "@apollo/client"
import App from "./App"
import './index.css'
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';



const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_ROUTE}graphql`,
  cache: new InMemoryCache()
});


const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);


if (process.env.REACT_APP_IN_PRODUCTION === '1') {
  disableReactDevTools();
}



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ApolloProvider>
)