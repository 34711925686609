import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateProdState(
    
    $own_user_token: String!,
    
    $id: Int,
    $name: String,
    $color: String,
    $position: Int,
    $customer_token: String!,

    $done: Boolean,
    $facturate_marks: Boolean,
    $ended_marks: Boolean
    $trigger_notifications: Boolean
    $trigger_publication: Boolean
    $trigger_start_reviewing: Boolean
    $hidden: Boolean
    
  ) {
    createOrUpdateVideoState(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      name: $name,
      color: $color,
      position: $position,
      customer_token: $customer_token,
      
      done: $done,
      facturate_marks: $facturate_marks,
      ended_marks: $ended_marks 
      trigger_notifications: $trigger_notifications 
      trigger_publication: $trigger_publication 
      trigger_start_reviewing: $trigger_start_reviewing 
      hidden: $hidden 

    }) {
    
      id
      name
      color
      position
      customer_token
      original

      done
      facturate_marks
      ended_marks
      trigger_notifications
      trigger_publication
      trigger_start_reviewing
      hidden

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
