import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { CopyToClipboard } from "react-copy-to-clipboard";

import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';









export default function BillTagsGenerator({
  
  billOption,
  setBillOption,

  hiddenItems,
  billExtras,

  factura10Bill,

}) {



  const { currentCustomer } = useContext(AuthContext)
  const { formatNumbersToPrint } = useContext(FuncContext)






  
  const [bill, setBill] = useState(null);
  const [concepts, setConcepts] = useState(null);
  const [cats, setCats] = useState(null);

  const [dataList, setDataList] = useState([]);
  const [catMinsDataList, setCatMinsDataList] = useState([]);
  const [percentageCatMinsDataList, setPercentageCatMinsDataList] = useState([]);
  const [numMotionsDataList, setNumMotionsDataList] = useState([]);
  const [priceMotionsDataList, setPriceMotionsDataList] = useState([]);
  const [percentageMotionsDataList, setPercentageMotionsDataList] = useState([]);
  

  const [open, setOpen] = useState(false);
  const [copiedList, setCopiedList] = useState(null);
  const [loadingList, setLoadingList] = useState(false);


  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;

  




  useEffect( () => {
    setDataList([])

    if (currentCustomer) {
        if (factura10Bill?.document) {
            setBill(factura10Bill?.document)
            setConcepts(factura10Bill?.concepts)
            setCats(factura10Bill?.cats)
        } else {
            setBill(null)
            setConcepts(null)
            setCats(null)
        }
    }
  }, [factura10Bill])






  useEffect( () => { 
    if (concepts?.length) {

      /* Base Lists */
      var dataListTMP = [];
      var numMotionsDataListTMP = []
      var priceMotionsDataListTMP = []
      var percentageMotionsDataListTMP = []


      /* Total Videos */
      var totalVideos = [];
      var totalVideosExtra = [];

      for (var i in concepts) {
        var concept = concepts[i];
        if (!checkIfIsHiddenFunc(concept, hiddenItems) && !totalVideos?.includes(concept?.concepte)) { totalVideos?.push(concept?.concepte) }
      }

      if (billExtras?.length) {
        for (var billExtraParent of billExtras) {
          if (billExtraParent?.childs?.length) {
            for (var billExtraChild of billExtraParent?.childs) {
              if (!totalVideos?.includes(billExtraChild?.video?.name) && !totalVideosExtra?.includes(billExtraChild?.video?.name)) { totalVideosExtra?.push(billExtraChild?.video?.name) }
            }
          }
        }
      }

      dataListTMP?.push({ token: 'total_vids', value: formatNumbersToPrint(totalVideos?.length + totalVideosExtra?.length, 0), tail: 'vídeos', desc: 'Vídeos totales en la factura' })




      if (billOption?.type === 'minutes') {
        var totalMins = 0;
        for (var i in concepts) {
            var concept = concepts[i];
            const marksJson = (concept['aux_json'] && JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
            totalMins = totalMins + parseFloat(marksJson?.min_facturable);
        }
        
        /* Total Minutes */
        dataListTMP?.push({ token: 'total_mins', value: formatNumbersToPrint(totalMins, 2), tail: 'minutos', desc: 'Minutos totales en la factura' })

        /* Avarage Minutes */
        const avarageMins = (totalMins / totalVideos?.length);
        dataListTMP?.push({ token: 'promedio_mins', value: formatNumbersToPrint(avarageMins, 2), tail: 'minutos', desc: 'Media de minutos por vídeo' })

        /* Avarage Minutes */
        const avarageMinsPrice = (bill?.total_alias / 1.21) / totalVideos?.length;
        dataListTMP?.push({ token: 'promedio_mins_precio', value: formatNumbersToPrint(avarageMinsPrice, 2), tail: '€ por cada vídeo', desc: 'Precio de la media de minutos por vídeo' })
      }



      if (billOption?.type === 'motions') {
        const markLegend = (JSON.parse(concepts[0]['aux_json']))? JSON.parse(concepts[0]['aux_json']) : null;

        var catMarksTMP = {}
        
        catMarksTMP['total'] = 0
        catMarksTMP['stock'] = {}
        catMarksTMP['stock']['num'] = 0
        catMarksTMP['stock']['price'] = 0

        for (var i in markLegend?.difficulties) {
            var legend = markLegend?.difficulties[i];
            var subCats = {};

            concepts?.map((concept) => {
                const marksJson = (JSON.parse(concept['aux_json']))? JSON.parse(concept['aux_json']) : null;
                if (marksJson[legend?.token]) {
                    Object.keys(marksJson[legend?.token]).map((keyName, i) => {
                        if (marksJson[legend?.token][keyName]) {
                            subCats[keyName] = (subCats[keyName])? subCats[keyName] + marksJson[legend?.token][keyName] : marksJson[legend?.token][keyName]
                        }
                    })
                }
                return concept;
            })


            if (subCats?.count) {
                var tokenTMP = (legend?.token?.includes('basic'))? 'basic' : (legend?.token?.includes('medium'))? 'medium' : (legend?.token?.includes('medium'))? 'advanced' : legend?.token
                var hasExtraStock = (legend?.token?.includes('stock') && legend?.token !== 'stock')? true : false

                if (hasExtraStock) {
                    catMarksTMP['stock']['num'] = catMarksTMP['stock']['num'] + subCats?.count;
                    catMarksTMP['stock']['price'] = catMarksTMP['stock']['price'] + (subCats?.count * markLegend?.prices['stock']) / 100;
                }


                if (!catMarksTMP[tokenTMP] || (!catMarksTMP[tokenTMP]['num'] && catMarksTMP[tokenTMP]['num'] !== 0)) {
                    catMarksTMP[tokenTMP] = {}
                    catMarksTMP[tokenTMP]['num'] = 0;
                    catMarksTMP[tokenTMP]['price'] = 0;
                }


                catMarksTMP[tokenTMP]['num'] = catMarksTMP[tokenTMP]['num'] + subCats?.count;
                catMarksTMP[tokenTMP]['price'] = catMarksTMP[tokenTMP]['price'] + (subCats?.count * markLegend?.prices[tokenTMP]) / 100;
                catMarksTMP[tokenTMP]['name'] = escapeUnicode(legend?.name);
                catMarksTMP['total'] = catMarksTMP['total'] + subCats?.count;
            }
        }
        
        /* Total Motions */
        dataListTMP?.push({ token: 'total_motions', value: formatNumbersToPrint(catMarksTMP['total'], 0), tail: 'motions', desc: 'Motions totales en la factura' })



        Object.keys(catMarksTMP).map((keyName, i) => {
          if (catMarksTMP[keyName]?.price) {
            numMotionsDataListTMP?.push({ token: catMarksTMP[keyName]['name'], value: formatNumbersToPrint(catMarksTMP[keyName]?.num, 2), tail: catMarksTMP[keyName]['name'], desc: 'Número de motions en la categoría' })
            priceMotionsDataListTMP?.push({ token: catMarksTMP[keyName]['name'], value: formatNumbersToPrint(catMarksTMP[keyName]?.price, 2), tail: '€', desc: 'Precio total de la categoría' })
            percentageMotionsDataListTMP?.push({ token: catMarksTMP[keyName]['name'], value: formatNumbersToPrint((catMarksTMP[keyName]?.num / catMarksTMP['total']) * 100, 2), tail: '%', desc: 'Porcentaje de motions en la caegoría' })
          }
        })



      }


      setDataList((dataListTMP?.length)? dataListTMP : null)
      setNumMotionsDataList((numMotionsDataListTMP?.length)? numMotionsDataListTMP : null)
      setPriceMotionsDataList((priceMotionsDataListTMP?.length)? priceMotionsDataListTMP : null)
      setPercentageMotionsDataList((percentageMotionsDataListTMP?.length)? percentageMotionsDataListTMP : null)
    }
  }, [billOption, hiddenItems, billExtras, bill, concepts])
  







  useEffect( () => { 
      var parentCatsTMP = cats?.filter((el) => parseInt(el?.is_parent));
      if (parentCatsTMP?.length && billOption?.type === 'minutes') {

          var catMins = []
          var percentageCatMins = []
          var total = 0;

          for (var i in parentCatsTMP) {
            var catTMP = parentCatsTMP[i]
            var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(catTMP?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => {
              const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
              return (marksJson?.min_facturable)? parseFloat(marksJson?.min_facturable) : 0
            } ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

            total = total + finalPriceTMP;
          }


          for (var i in parentCatsTMP) {
              var catTMP = parentCatsTMP[i]
              var finalPriceTMP = cats?.filter((subCat) => parseInt(subCat?.pare_id) === parseInt(catTMP?.id))?.map((subCat) => concepts?.filter((concept) => parseInt(subCat?.id) === parseInt(concept?.document_concepte_categoria_id))?.map((row) => {
                  const marksJson = (JSON.parse(row['aux_json']))? JSON.parse(row['aux_json']) : null;
                  return (marksJson?.min_facturable)? parseFloat(marksJson?.min_facturable) : 0
              } ).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)

              if (finalPriceTMP) {
                catMins?.push({ token: catTMP?.nom, value: formatNumbersToPrint(finalPriceTMP, 2), tail: 'minutos', desc: 'Minutos de la categoía' })
                percentageCatMins?.push({ token: catTMP?.nom, value: formatNumbersToPrint(((finalPriceTMP / total) * 100), 2), tail: '%', desc: 'Porcentaje de minutos de la categoía' })
              }
          }

          /* Video Cat Lists */
          setCatMinsDataList(catMins)
          setPercentageCatMinsDataList(percentageCatMins)
      } else {
        setCatMinsDataList(null)
        setPercentageCatMinsDataList(null)
      }
  }, [cats])



  


  useEffect( () => { 
    if (copiedList) {
      setTimeout(() => {
        setCopiedList(null)
      }, 2000)
    }
  }, [copiedList])









  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  
  const unicodes = { 
    'u00c1': 'Á',
    'u00e1': 'á',
    'u00c9': 'É',
    'u00e9': 'é',
    'u00cd': 'Í',
    'u00ed': 'í',
    'u00d3': 'Ó',
    'u00f3': 'ó',
    'u00da': 'Ú',
    'u00fa': 'ú',
    'u00d1': 'Ñ',
    'u00f1': 'ñ',
}

  function escapeUnicode(str) {
    Object.keys(unicodes).map((keyName, i) => (
        str = str?.replaceAll(keyName, unicodes[keyName])
    ))
    return str;
  }


  function checkIfIsHiddenFunc(concept, hiddenItems) {
    var isHidden = false;
    if (hiddenItems?.length) {
      for (var videoName in hiddenItems) {
        if (videoName && videoName === concept?.concepte) {
          isHidden = true;
        }
      }
    }
    return isHidden
  }







  return (
    <div className='w-full'>

      <button onClick={() => setOpen(!open)} className={`w-full px-2 p-1 text-gray-600 bg-white hover:bg-gray-100 rounded text-sm flex items-center space-x-2 whitespace-nowrap cursor-pointer duration-200`}>
        <p>Listado de datos</p>
        <hr style={{ marginTop: 0 }} className='w-full border-gray-300'/>
        {(open)? downArrowIcon : upArrowIcon}
      </button>



      <Collapse orientation="vertical" in={open} timeout={500}>
        <div className={`space-y-1 pt-1`}>
          {[

            { label: 'Totales', list: dataList},

            { label: 'Minutos por categoría', list: catMinsDataList},
            { label: 'Porcentaje de los minutos', list: percentageCatMinsDataList},

            { label: 'Número de motions por categoría', list: numMotionsDataList},
            { label: 'Precio total de la categoría', list: priceMotionsDataList},
            { label: 'Porcentaje de motions en la categoría', list: percentageMotionsDataList},

          ]?.map(listObj => {
            return (listObj?.list?.length? true : false) && <div key={`${listObj?.label}`} className='w-full flex items-center justify-between space-x-1'>
                <p className='grow text-xs text-gray-600 px-2 py-[6px] bg-gray-300 rounded whitespace-nowrap'>{listObj?.label}</p>
                <div className='flex items-start overflow-x-scroll bg-gray-400 border border-gray-400 bg-opacity-60 border-opacity-60 rounded space-x-[1px] smallScrollBar'> {/* grow */}
                  {listObj?.list?.map(element => {
                    return <div key={`${listObj?.label} ${element?.value} ${element?.tail}`}>
                      <Tooltip disableInteractive title={(copiedList === `${listObj?.label} ${element?.value} ${element?.tail}`)? '¡Copiado!' : ''} placement="bottom" arrow>
                        <div>
                          <CopyToClipboard text={`${element?.value} ${element?.tail}`}>
                            <button
                              onClick={() => {
                                setCopiedList(`${listObj?.label} ${element?.value} ${element?.tail}`)
                              }}
                              className={`text-sm px-2 py-[3px] whitespace-nowrap duration-200 ${(copiedList === `${listObj?.label} ${element?.value} ${element?.tail}`)? 'bg-blue-400 hover:bg-blue-500 active:bg-blue-600 text-white' : 'bg-white hover:bg-gray-200 active:bg-gray-400 '}`}
                            >
                              <p>{capitalizeFirstLetter(element?.token)?.replaceAll('_', ' ')}</p>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </Tooltip>
                    </div>
                  })}
                </div>
            </div>
          })}
        </div>
      </Collapse>
    </div>
  );
}