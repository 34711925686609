import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios';

import UserProfilePic from './UserProfilePic'

import Tooltip from '@mui/material/Tooltip';

















export default function VideoOptionsPanelChatMessageItem({
  
  itemkey,
  items,

  video,
  users,
  el,
  elGroup,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)

  const [downloading, setDownloading] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(null);




  /* Keys */
  const isLast = (itemkey === (items?.length-1))
  const lastOneKey = (isLast)? itemkey-1 : itemkey+1


  /* Items */
  const lastItem = (items[lastOneKey])? items[lastOneKey] : null;
  const item = el;

  const lastIsFile = (lastItem?.__typename !== 'Message' && lastItem?.user_id)? true : false
  const isFile = (item?.__typename !== 'Message' && item?.user_id)? true : false


  /* Item users */
  const lastSubstituteUser = (lastIsFile)? users?.filter((user) => parseInt(user?.id) === parseInt(lastItem?.user_id))[0] : null
  const substituteUser = (isFile)? users?.filter((user) => parseInt(user?.id) === parseInt(item?.user_id))[0] : null

  const lastUser = (lastSubstituteUser?.id)? lastSubstituteUser : (lastItem?.from_user)? lastItem?.from_user : null
  const user = (substituteUser?.id)? substituteUser : (item?.from_user)? item?.from_user : null


  const rightPosition = (parseInt(user?.id) === parseInt(currentUser?.id))? true : false


  const lastCreatedAt = (lastItem && lastItem?.createdAt)? new Date(lastItem?.createdAt) : null
  const lastCreatedAtFormated = (lastItem && lastItem?.createdAtFormated)? lastItem?.createdAtFormated : (lastCreatedAt)? lastCreatedAt?.toLocaleString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" })?.split(' ')[0]?.replace(/,/g, '') + ' ' + (lastCreatedAt?.getUTCHours() + ':' + ((lastCreatedAt?.getMinutes() < 10)? '0' + lastCreatedAt?.getMinutes() : lastCreatedAt?.getMinutes())) : null
  const createdAt = (item?.createdAt)? new Date(item?.createdAt) : null
  const createdAtFormated = (item?.createdAtFormated)? item?.createdAtFormated : (createdAt)? createdAt?.toLocaleString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" })?.split(' ')[0]?.replace(/,/g, '') + ' ' + (createdAt?.getUTCHours() + ':' + ((createdAt?.getMinutes() < 10)? '0' + createdAt?.getMinutes() : createdAt?.getMinutes())) : null
  

  const userAlreadyShown = (!isLast && (lastUser && user && parseInt(lastUser?.id) === parseInt(user?.id)))? true : false
  const dateAlreadyShown = (!isLast && (lastUser && user && parseInt(lastUser?.id) === parseInt(user?.id) && lastCreatedAtFormated === createdAtFormated))? true : false


  const taggedUserIDs = (item?.tagged_user_ids)? item?.tagged_user_ids?.split(',') : null
  const youAreTagged = (taggedUserIDs?.includes(currentUser?.id+''))? true : false


  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;






  

  useEffect(() => {    
    if (downloadProgress === 100) {
      setTimeout(() => {
        setDownloadProgress(null)
      }, 3000)
    }
  }, [downloadProgress])






  function profilePicFunc(user, userAlreadyShown, isInLeft, youAreTagged) {
    return <div className={`relative flex ${(isInLeft)? 'pr-[6px]' : 'pl-[6px]'}`}>

      {(!userAlreadyShown && parseInt(user?.id))? <>
        <div 
          className={`absolute bg-white group-hover/message:bg-blue-50 p-[4px] overflow-hidden  duration-200
            ${(isInLeft)? 'right-[-3px] rounded-tl-[4px] rounded-bl-[60px]' : 'left-[-3px] rounded-tr-[4px] rounded-br-[60px]'}
            ${(youAreTagged)? `border-blue-500 border-opacity-60 border-t-[3px] border-b-[3px] ${(isInLeft)? 'border-l-[3px]' : 'border-r-[3px]'}` : ''}
          `}
        ></div>

        <div onClick={() => (currentUser?.rol === 'admin')? window.open(`/${(user?.professor)? 'professor' : 'user'}/${user?.id}`, '_blank').focus() : null } className={`text-gray-500 ${(currentUser?.rol === 'admin')? 'hover:opacity-60 cursor-pointer duration-200' : ''}`}>
          <UserProfilePic user={user} size={28} clickable={false} checkPictureExists={false}/> 
        </div>
      </>: (userAlreadyShown)? <div className='px-[14px]'></div>
      : null }

    </div>
  }


  function downloadFileFunc(droppedFile) {
    if (droppedFile?.name && !droppedFile?.deleted) {
      setDownloading(droppedFile?.name)
    
      axios({
        url: `${process.env.REACT_APP_API_ROUTE}customer_media/${currentCustomer?.token}/video_files/video-${video?.id}/${droppedFile?.name}`,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }).then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', droppedFile?.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          setTimeout(() => {
            setDownloading(null)
          }, 300 )
        }
      });
    }
  }














  return <div
    keu={`video-message-${item?.id}`}
    className={`flex
      ${(rightPosition)? 'justify-end' : 'justify-start'}
      ${(!userAlreadyShown || !dateAlreadyShown)? 'pt-3' : 'pt-1'}
    `}
  >
    <div
      className={`flex flex-col
        ${(rightPosition)? 'items-end' : 'items-start'}
      `}
    >


      {(!dateAlreadyShown)? 
        <div className='h-[12px] px-[40px] flex items-center'>
          <p className='text-[9px] opacity-40'>{createdAtFormated}</p>
        </div> 
      : null}


      <div className='group/message flex'> {/* hover:animate-message-wiggle */}
        {(!rightPosition)? profilePicFunc(user, userAlreadyShown, true, youAreTagged) : null}
      
        <div 
          style={{ hyphens: 'auto' }}
          className={`p-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg max-w-[400px] break-words duration-200 bg-white group-hover/message:bg-blue-50
            ${(rightPosition)? 'rounded-tr-[1px] text-left' : 'rounded-tl-[1px] text-left'}
            ${(youAreTagged)? 'border-[3px] border-blue-500 border-opacity-60' : ''}
          `}
        >
          {(!userAlreadyShown)?
            <div className={`w-full h-[15px] flex items-center ${(rightPosition)? 'justify-end' : 'justify-start'}`}>
              <p className='px-1 text-[13px] text-blue-500'>{user?.name}</p>
            </div>
          : null}


          {(item?.text)?
            <div className={`px-1 ${(!userAlreadyShown || elGroup?.length)? 'py-1' : ''}`} dangerouslySetInnerHTML={{__html: (item?.text)? item?.text : '' }}></div>
          : null }
          

          {(elGroup?.length || el?.path)?
            <div className={`w-full text-xs flex overflow-auto whitespace-nowrap smallScrollBar`}>
              {((elGroup?.length)? [...elGroup] : [el])?.map(element => {
                return (element?.name)? 
                  <div 
                    onClick={() => downloadFileFunc(element)}
                    className={`flex space-x-[1px] items-center m-[2px] border rounded pr-1 py-[2px] duration-300 cursor-pointer
                      ${(element?.deleted)? 'border-gray-500 text-gray-500 bg-gray-100' : 'border-blue-500 text-blue-500 hover:text-white bg-blue-100 hover:bg-blue-500'}
                    `}
                  >
                    {(downloading === element?.name && (downloadProgress || downloadProgress === 0))? <div className='pl-2'>{downloadProgress}%</div> : null}
                    <div className='scale-90'>{(downloading === element?.name)? loadingIcon : fileIcon}</div>

                    <Tooltip title={(element?.deleted)? 'Eliminado' : 'Descargar ' + element?.name} placement="bottom" disableInteractive arrow>
                      <p className='truncate max-w-[180px]'>{element?.name}</p>
                    </Tooltip>
                  </div>
                : null 
              })}
            </div>
          : null }
        </div>

        {(rightPosition)? profilePicFunc(user, userAlreadyShown, false, youAreTagged) : null}
      </div>


    </div>
  </div>
}