import { useState, useEffect, useContext } from 'react'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";

import CustomerBasicInfo from '../components/CustomerBasicInfo';






const CustomerEasyCreation = () => {



  
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const navigate = useNavigate();


  /* Customer vars */
  const [step, setStep] = useState(-1);







  useEffect(() => {
    setCurrentNavActive('videos')
    var localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )

    if (!currentUser && !localCurrentUser) {
      navigate("/signin");
    } else if (!currentUser && localCurrentUser) {
      setCurrentUser(localCurrentUser)
    }


    setTimeout(() => {
      setStep(0)

      setTimeout(() => {
        setStep(1)
      }, 2000 )
    }, 1000 )
  }, [])












  return (
    <div className="w-screen h-screen flex items-center justify-center overflow-hidden p-4">
      <div
        className={`relative flex items-center justify-center w-full h-full min-w-[500px] duration-1000
          ${(step === 6)? 'max-w-[1000px]' : 'max-w-[800px]'}
          ${(step === 6)? 'max-h-[600px]' : (step === 5)? 'max-h-[200px]' : 'max-h-[400px]'}
        `}
      >


        <CustomerBasicInfo
          step={step}
          setStep={setStep}
        />


      </div>
    </div>
  )
}

export default CustomerEasyCreation
