import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import Resizer from "react-image-file-resizer";

import CreateOrUpdateCustomer from '../graphql/mutations/CreateOrUpdateCustomer';

import CustomTextField from './Inputs/CustomTextField';
import CustomSwitch from './Inputs/CustomSwitch';
import CustomerEditorMarks from './CustomerEditorMarks';
import { useNavigate } from 'react-router-dom';
import CustomSelect from './Inputs/CustomSelect';
import { MenuItem } from '@mui/material';









export default function CustomerBasicInfo({ step, setStep }) {



  
  const { currentUser, setCurrentCustomer } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();



  const [customer, setCustomer] = useState(null);
  const [openCustomerSelector, setOpenCustomerSelector] = useState(false);
  
  /* Base info vars */
  const [name, setName] = useState(null);
  const [abbreviatedName, setAbbreviatedName] = useState(null);
  const [campsRequiredError, setCampsRequiredError] = useState(false);

  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [profilePictureSizeError, setProfilePictureSizeError] = useState(null);

  /* Label vars */
  const [selectedLabelModule, setSelectedLabelModule] = useState(0);
  const [labelModule, setLabelModule] = useState('Módulo');
  const [labelModules, setLabelModules] = useState('Módulos');

  const [selectedLabelProfessor, setSelectedLabelProfessor] = useState(0);
  const [labelProfessor, setLabelProfessor] = useState('Participante');
  const [labelProfessors, setLabelProfessors] = useState('Participantes');

  const [selectedLabelExtrasModule, setSelectedLabelExtrasModule] = useState(0);
  const [labelExtrasModule, setLabelExtrasModule] = useState('Otros');
  const [labelExtrasModuleURL, setLabelExtrasModuleURL] = useState('Otros');

  /* Setting vars */
  const [settingLanguageFilter, setSettingLanguageFilter] = useState(true);
  const [settingSexFilter, setSettingSexFilter] = useState(false);
  const [settingProfessorFilter, setSettingProfessorFilter] = useState(true);
  const [settingDeffaultDateFilter, setSettingDeffaultDateFilter] = useState(true);

  const [settingInitialChyrons, setSettingInitialChyrons] = useState(true);
  const [settingRecordingTipology, setSettingRecordingTipology] = useState(false);
  const [settingTitleIsRelevant, setSettingTitleIsRelevant] = useState(false);
  const [settingProfessorColumn, setSettingProfessorColumn] = useState(false);
  const [settingProfessorsCanSeeMarks, setSettingProfessorsCanSeeMarks] = useState(true);
  const [settingProdStateNotification, setSettingProdStateNotification] = useState(true);
  const [errorWhileSaving, setErrorWhileSaving] = useState(false);

  /* Icon vars */
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="m438-480 164 164q11 11 11 25.5T602-265q-11 11-25.5 11t-25.84-11.34L361-455q-5-5.4-7.5-11.7-2.5-6.3-2.5-13.5t2.5-13.5Q356-500 361-505l189.66-189.66Q562-706 576.5-706t25.5 11q11 11 11 25.5T602-644L438-480Z"/></svg>
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const imageIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-480ZM216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h276q15.3 0 25.65 10.29Q528-795.42 528-780.21t-10.35 25.71Q507.3-744 492-744H216v528h528v-276q0-15.3 10.29-25.65Q764.58-528 779.79-528t25.71 10.35Q816-507.3 816-492v276q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm48-144h432L552-480 444-336l-72-96-108 144Zm408.23-384h-36.11q-15.35 0-25.74-10.35Q600-692.7 600-708q0-15.3 10.38-25.65Q620.77-744 636.12-744h36.11v-36q0-15.3 10.39-25.65Q693-816 708.21-816q15.21 0 25.5 10.35T744-780v36h35.79q15.21 0 25.71 10.29t10.5 25.5q0 15.21-10.35 25.59-10.35 10.39-25.65 10.39h-36v36.11q0 15.35-10.35 25.74Q723.3-600 708-600q-15 0-25.38-10.35-10.39-10.35-10.39-25.65v-36Z"/></svg>
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

  const bgCheckIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="m424-408-86-86q-11-11-28-11t-28 11q-11 11-11 28t11 28l114 114q12 12 28 12t28-12l226-226q11-11 11-28t-11-28q-11-11-28-11t-28 11L424-408Zm56 328q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
  const bgSettingIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M80-240v-32q0-34 17-62.5t47-43.5q57-29 118.5-46T388-441q14 0 22 12.5t3 26.5q-6 21-9 42t-3 43q0 29 6 56t17 53q8 17-1.5 32.5T396-160H160q-33 0-56.5-23.5T80-240Zm600 0q33 0 56.5-23.5T760-320q0-33-23.5-56.5T680-400q-33 0-56.5 23.5T600-320q0 33 23.5 56.5T680-240ZM400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm234 328-6-28q-12-5-22.5-10.5T584-204l-29 9q-13 4-25.5-1T510-212l-8-14q-7-12-5-26t13-23l22-19q-2-14-2-26t2-26l-22-19q-11-9-13-22.5t5-25.5l9-15q7-11 19-16t25-1l29 9q11-8 21.5-13.5T628-460l6-29q3-14 13.5-22.5T672-520h16q14 0 24.5 9t13.5 23l6 28q12 5 22.5 11t21.5 15l27-9q14-5 27 0t20 17l8 14q7 12 5 26t-13 23l-22 19q2 12 2 25t-2 25l22 19q11 9 13 22.5t-5 25.5l-9 15q-7 11-19 16t-25 1l-29-9q-11 8-21.5 13.5T732-180l-6 29q-3 14-13.5 22.5T688-120h-16q-14 0-24.5-9T634-152Z"/></svg>



  const participantOptions = [
    { label: 'Participante / Participantes', value_singular: 'Participante', value_plural: 'Participantes' },
    { label: 'Profesor / Profesores', value_singular: 'Profesor', value_plural: 'Profesores' },
    { label: 'Profesor/a /  Profesores/as', value_singular: 'Profesor/a', value_plural: 'Profesores/as' },
    { label: 'Leader / Leaders', value_singular: 'Leader', value_plural: 'Leaders' },
    { label: 'Responsable / Responsables', value_singular: 'Responsable', value_plural: 'Responsables' },
    { label: 'Propietario / Propietarios', value_singular: 'Propietario', value_plural: 'Propietarios' },
  ]

  const moduleOptions = [
    { label: 'Módulo / Módulos', value_singular: 'Módulo', value_plural: 'Módulos' },
    { label: 'Apartado / Apartados', value_singular: 'Apartado', value_plural: 'Apartados' },
    { label: 'Directorio principal / Directorios principales', value_singular: 'Directorio principal', value_plural: 'Directorios principales' },
    { label: 'Sección / Secciones', value_singular: 'Sección', value_plural: 'Secciones' },
    { label: 'Tipología / Tipologías', value_singular: 'Tipología', value_plural: 'Tipologías' },
  ]

  const extraModuleOptions = [
    { label: 'Otros', value: 'Otros', value_url: 'otros' },
    { label: 'Extras', value: 'Extras', value_url: 'extras' },
    { label: 'Iniciales', value: 'Iniciales', value_url: 'iniciales' },
    { label: 'Apartados', value: 'Apartados', value_url: 'apartados' },
  ]





  const [createOrUpdateCustomer] = useMutation(CreateOrUpdateCustomer)








  useEffect(() => { 
    if (profilePicture) {
      var url = URL.createObjectURL(profilePicture);
      setProfilePictureURL(url)
      setProfilePictureSizeError(null)
    }
  }, [profilePicture])


  useEffect(() => { 
    if (openCustomerSelector) {
      setOpenCustomerSelector(false)
    }
  }, [openCustomerSelector])





  async function saveCustomerDataFunc() {
    setCampsRequiredError(false)
    setErrorWhileSaving(false);

    if (name && name?.replaceAll(' ', '') !== '' && abbreviatedName && abbreviatedName?.replaceAll(' ', '') !== '') {
      setStep(5)

      var input = {
        own_user_token: (currentUser)? currentUser.token : null, 
  
        abbreviated_name: (abbreviatedName?.replaceAll(' ', ''))? abbreviatedName : null,
        name: (name?.replaceAll(' ', ''))? name : null,
  
        label_module: (labelModule)? labelModule : 'Módulo',
        label_modules: (labelModules)? labelModules : 'Módulos',
        label_professor: (labelProfessor)? labelProfessor : 'Participante',
        label_professors: (labelProfessors)? labelProfessors : 'Participantes',
        label_extras_module: (labelExtrasModule)? labelExtrasModule : 'Otros',
        label_extras_module_url: (labelExtrasModuleURL)? labelExtrasModuleURL : 'Otros',
  
        setting_language_filter: (settingLanguageFilter)? settingLanguageFilter : true,
        setting_sex_filter: (settingSexFilter)? settingSexFilter : false,
        setting_professor_filter: (settingProfessorFilter)? settingProfessorFilter : true,
        setting_deffault_date_filter: (settingDeffaultDateFilter)? settingDeffaultDateFilter : true,
  
        setting_initial_chyron: (settingInitialChyrons)? settingInitialChyrons : true,
        setting_recording_tipo_in_list: (settingRecordingTipology)? settingRecordingTipology : false,
        setting_tittle_is_relevant: (settingTitleIsRelevant)? settingTitleIsRelevant : false,
        setting_professor_col: (settingProfessorColumn)? settingProfessorColumn : false,
        setting_professors_can_see_marks: (settingProfessorsCanSeeMarks)? settingProfessorsCanSeeMarks : true,
        setting_instant_prod_state_notification: (settingProdStateNotification)? settingProdStateNotification : true,
      };
  
  
      const response = await createOrUpdateCustomer({variables: input})
      const customerTMP = (response?.data?.createOrUpdateCustomer?.id)? response?.data?.createOrUpdateCustomer : null
      if (customerTMP?.id) {
        setCustomer(customerTMP)
      } else {
        setStep(4); 
        setErrorWhileSaving(true);
        return null;
      }
  
      if (profilePicture /* && profilePicture?.size < 20001 */ && customerTMP?.id) {
        await Resizer.imageFileResizer(profilePicture, 100, 100, "png", 100, 0,
          async (uri) => {
            var resizedIconFile = uri;
            var saveFileFormData = new FormData();
            saveFileFormData.append('own_user_token', currentUser?.token);
            saveFileFormData.append('own_customer_token', customerTMP?.token);
            saveFileFormData.append('file', resizedIconFile);
            await axios.post(`${process.env.REACT_APP_API_ROUTE}setCustomerIcon`, saveFileFormData);
          },
          "file",
          5,
          5
        );
      }
  
      setTimeout(() => {
        setStep(6)
      }, "6000")

    } else {

      setStep(1)
      setCampsRequiredError(true)
      
    }
  }

  function selectCurrentCustomer(element) {
    sessionStorage.setItem('localCurrentCustomer', JSON.stringify(element));
    localStorage.setItem('localCurrentCustomer', JSON.stringify(element));
    setCurrentCustomer(element);

    window.location.href = "/videos";
  }







  return (<>


    <div className={`absolute z-[0] mt-[-100px] w-full h-full flex items-center justify-center pointer-events-none duration-1000 overflow-hidden ${(!step)? 'text-blue-500 max-h-[900px] min-h-[900px]' : 'text-blue-100 max-h-[600px] min-h-[600px]'} ${(step !== 7 && step !== -1)? 'delay-[0.5s]' : 'opacity-0'}`}>
      <div className='z-[1] absolute w-full h-full bg-gradient-to-b from-white via-transparent to-white'></div>
      <div className='z-[0] absolute scale-[30] opacity-[0.4]'>{bgSettingIcon}</div>
    </div>


    {/* STEP 1 */}
    {(!step || step === 1 || step === 2) && 
      <div className={`absolute w-full h-full ${(step === 1)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-5 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 1)? 'delay-[1s]' : 'opacity-0'}`}>
            Bienvenido a la creación guiada de clientes
          </h1>

          <p className={`pb-4 leading-5 text-lg duration-1000 ${(step === 1)? 'delay-[2s]' : 'opacity-0'}`}>A contnuación, escoge un nombre para tu nuevo cliente, además de un nombre abreviado para poder identificar tu cliente de forma más rápida y concisa.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 1)? 'delay-[4s]' : 'opacity-0'}`}>Ejemplo de nombre abreviado: ISD</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 1)? 'delay-[5s]' : 'opacity-0'}`}>Ejemplo de nombre: International School Of Derama</p>
        </div>



        {(campsRequiredError) && 
          <div className='pb-2'>
            <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5 text-sm">
              <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">{errorIcon}</div>
              <p>Estos campos son obligatorios</p>
            </div>
          </div>
        }



        <div className={`w-full flex items-center space-x-2 duration-1000 ${(step === 1)? 'delay-[6s]' : 'opacity-0'}`}>
          <CustomTextField
            label="Nombre abreviado *"
            name="client-abbreviated-name"
            className='w-full mui-bg-gray-100 max-w-[170px]'
            big

            value={(abbreviatedName)? abbreviatedName : ''}
            onChange={(event) => setAbbreviatedName((event.target.value)? event.target.value?.slice(0, 15) : null)}
            onKeyUp={(event) => { 
              if (event.key === 'Enter' && name && abbreviatedName) {
                setStep(2)
              } 
            }}
          />

          <CustomTextField
            label="Nombre del cliente *"
            name="client-name"
            big

            value={(name)? name : ''}
            onChange={(event) => setName((event.target.value)? event.target.value?.slice(0, 50) : null)}
            onKeyUp={(event) => { 
              if (event.key === 'Enter' && name && abbreviatedName) {
                setStep(2)
              } 
            }}
          />
        </div>


        <div className={`pt-5 flex justify-between items-center space-x-4 duration-1000 ${(step === 1)? 'delay-[7s]' : 'opacity-0'}`}>
          <button 
            onMouseUp={(event) => navigateOrOpenFunc(event, "/customer-selector")}
            className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 hover:shadow-md active:scale-95 rounded duration-200"
          >
            {leftArrowIcon}
            <p>Volver al selector de clientes</p>
          </button>

          <button 
            onClick={() => setStep(2)}
            className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
            disabled={(!name || !abbreviatedName)? true : false}
          >
            <p>Continuar</p>
            {rightArrowIcon}
          </button>
        </div>
      </div>
    }




    {/* STEP 2 */}
    {(step === 1 || step === 2 || step === 3) && 
      <div className={`absolute w-full h-full ${(step === 2)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-5 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 2)? 'delay-[1s]' : 'opacity-0'}`}>
            Una imagen vale más que mil palabras
          </h1>

          <p className={`pb-4 leading-5 text-lg duration-1000 ${(step === 2)? 'delay-[2s]' : 'opacity-0'}`}>Añada una imagen como retrato para su nuevo cliente, si crees que no es necesario por el momento, puede continuar sin ningún problema y añadirla / cambiarla posteriormente.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 2)? 'delay-[4s]' : 'opacity-0'}`}>Tamaño recomendaedo: 300x300 o inferior.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 2)? 'delay-[5s]' : 'opacity-0'}`}>Con un aspect ratio de 1x1.</p>
        </div>



        {(profilePictureSizeError) && 
          <div className='pb-2'>
            <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5 text-sm">
              <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">{errorIcon}</div>
              <p>El fichero introducido és demasiado grande para un retrato más bien pequeño. Este pesa {profilePictureSizeError} mientras que el máximo permitido son 20kb, baja la resolución de dicha imagen o intente con otra.</p>
            </div>
          </div>
        }



        <div className={`w-full flex justify-center items-center space-x-2 duration-1000 ${(step === 2)? 'delay-[6s]' : 'opacity-0'}`}>
          <button
            onClick={() => document.getElementById('iconFile').click()} 
            className="group/icon p-3 w-full flex items-center space-x-2 text-left bg-gray-100 hover:bg-blue-100 border hover:border-blue-300 active:scale-[0.98]  shadow rounded-md duration-200"
          >
            <label
              htmlFor="iconFile"
              component="label"
              aria-label="upload picture"
              className='min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px] overflow-hidden relative flex items-center justify-center rounded-lg border border-gray-300 bg-white bg-opacity-90 group-hover/icon:bg-opacity-50 group-hover/icon:border-blue-300 duration-200 cursor-pointer'
            >
              <input id="iconFile" hidden accept="image/*" type="file" onChange={(e )=> setProfilePicture(e.target.files[0])}/>

              {(profilePictureURL)? 
                <div className='absolute w-full h-full'>
                  <img 
                    className='object-cover h-full w-full'
                    src={profilePictureURL} 
                    alt='edit_customer_icon'
                  />  
                </div>
              :
                <div className='absolute scale-[1.8] opacity-50'>{imageIcon}</div>
              }
            </label>

            <div className='pl-2'>
              <p className='text-xl'>{abbreviatedName} <small className='text-xl opacity-75'> / {name}</small></p>
              <p className='opacity-50'>Clica aquí para añadir una imagen.</p>
            </div>
          </button>
        </div>


        <div className={`pt-6 flex justify-between items-center space-x-4 duration-1000 ${(step === 2)? 'delay-[7s]' : 'opacity-0'}`}>
          <button 
            onMouseUp={(event) => setStep(1)}
            className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 hover:shadow-md active:scale-95 rounded duration-200"
          >
            {leftArrowIcon}
            <p>Cambiar nombre</p>
          </button>

          <button 
            onClick={() => setStep(3)}
            className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          >
            <p>Continuar {(profilePicture)? 'con este retrato' : 'sin retrato'}</p>
            {rightArrowIcon}
          </button>
        </div>
      </div>
    }




    {/* STEP 3 */}
    {(step === 2 || step === 3 || step === 4) && 
      <div className={`absolute  w-full h-full ${(step === 3)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-6 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 3)? 'delay-[1s]' : 'opacity-0'}`}>
            Toca definir algunas nomenclaturas
          </h1>

          <p className={`pb-4 leading-5 text-lg duration-1000 ${(step === 3)? 'delay-[2s]' : 'opacity-0'}`}>Existen algunos términos que pueden entrar en conflicto con los que utiliza tu equipo, en caso de que sea necesario ajústalos a tu gusto.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 3)? 'delay-[4s]' : 'opacity-0'}`}>Participantes: Usuarios asignados al vídeo, ya sea por ser responsabilidad con él, por su aparición como profesores...</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 3)? 'delay-[5s]' : 'opacity-0'}`}>Módulos: Directorios principales en los que se organizan los vídeos.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 3)? 'delay-[6s]' : 'opacity-0'}`}>Módulo extra: Nombre y url del directorio al que van los vídeos cuando no tienen asignado ningún otro módulo.</p>
        </div>


        <div className='flex items-center flex-wrap space-y-3 md:flex-nowrap md:space-y-0 md:space-x-2'>
          <div className={`w-full transition-opacity duration-1000 ${(step === 3)? 'delay-[7s]' : 'opacity-0'}`}>
            <CustomSelect 
              label="Label participantes"
              
              value={(selectedLabelProfessor)? selectedLabelProfessor : 0} 
              onChange={(event) => {
                const valKey = event.target.value;
                setSelectedLabelProfessor(valKey)
                setLabelProfessor(participantOptions[valKey]?.value_singular)
                setLabelProfessors(participantOptions[valKey]?.value_plural)
              }}
            >
              {participantOptions?.map((element, key) => {
                return <MenuItem key={`part-${element?.value_singular}`} value={key}>{element?.label}</MenuItem>
              })}
            </CustomSelect>
          </div>

          <div className={`w-full transition-opacity duration-1000 ${(step === 3)? 'delay-[7.5s]' : 'opacity-0'}`}>
            <CustomSelect
              label="Label módulos (Singular)"
              
              value={(selectedLabelModule)? selectedLabelModule : 0} 
              onChange={(event) => {
                const valKey = event.target.value;
                setSelectedLabelModule(valKey)
                setLabelModule(moduleOptions[valKey]?.value_singular)
                setLabelModules(moduleOptions[valKey]?.value_plural)
              }}
            >
              {moduleOptions?.map((element, key) => {
                return <MenuItem key={`part-${element?.value_singular}`} value={key}>{element?.label}</MenuItem>
              })}
            </CustomSelect>
          </div>

          <div className={`w-full transition-opacity duration-1000 ${(step === 3)? 'delay-[8s]' : 'opacity-0'}`}>
            <CustomSelect
              label="Label módulo extra" 

              value={(selectedLabelExtrasModule)? selectedLabelExtrasModule : 0} 
              onChange={(event) => {
                const valKey = event.target.value;
                setSelectedLabelExtrasModule(valKey)
                setLabelExtrasModule(extraModuleOptions[valKey]?.value)
                setLabelExtrasModuleURL(extraModuleOptions[valKey]?.value_url)
              }}
            >
              {extraModuleOptions?.map((element, key) => {
                return <MenuItem key={`part-${element?.value_url}`} value={key}>{element?.label}</MenuItem>
              })}
            </CustomSelect>
          </div>
        </div>


        <div className={`pt-5 flex justify-between items-center space-x-4 duration-1000 ${(step === 3)? 'delay-[9s]' : 'opacity-0'}`}>
          <button 
            onMouseUp={(event) => setStep(2)}
            className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 hover:shadow-md active:scale-95 rounded duration-200"
          >
            {leftArrowIcon}
            <p>Cambiar retrato</p>
          </button>

          <button 
            onClick={() => setStep(4)}
            className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          >
            <p>Continuar con estas nomenclaturas</p>
            {rightArrowIcon}
          </button>
        </div>
      </div>
    }



    {/* STEP 4 */}
    {(step === 3 || step === 4 || step === 5) && 
      <div className={`absolute w-full h-full ${(step === 4)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-5 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 4)? 'delay-[1s]' : 'opacity-0'}`}>
            Antes del último paso
          </h1>

          <p className={`pb-4 leading-5 text-lg duration-1000 ${(step === 4)? 'delay-[2s]' : 'opacity-0'}`}>Hay funcionalidades que pueden ser prescindibles para este proyecto, en caso de que alguna de estas no sea necesaria, desactívala. Por lo contrario, si quieres que se incluyan en el proyecto, asegúrate de que se encuentren habilitadas.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 4)? 'delay-[4s]' : 'opacity-0'}`}>En caso de no estar seguro, puede dejar los ajustes como están y cambiarlos después en cualquier momento.</p>
        </div>



        {(errorWhileSaving && step === 4) && 
          <div className='pb-2'>
            <div className="w-full p-2 bg-red-500 text-white rounded-md flex items-center space-x-3 leading-5 text-sm">
              <div className="min-w-[55px] min-h-[47px] flex justify-center items-center bg-red-700 bg-opacity-75 rounded-md ">{errorIcon}</div>
              <p>Ha habido un error al guardar los datos, vuélvalo a intentar más tarde.</p>
            </div>
          </div>
        }



        <div className={`w-full pb-3 flex flex-wrap items-center md:flex-nowrap md:space-x-2 space-y-2 md:space-y-0 duration-1000 ${(step === 4)? 'delay-[5s]' : 'opacity-0'}`}>
          <CustomSwitch
            label="Filtro por idioma"
            addClassName="w-full"

            value={settingLanguageFilter}
            onClick={() => setSettingLanguageFilter(!settingLanguageFilter)}
          />

          <CustomSwitch
            label="Filtro por genero"
            addClassName="w-full"

            value={settingSexFilter}
            onClick={() => setSettingSexFilter(!settingSexFilter)}
          />
        </div>

        <div className={`w-full pb-3 flex flex-wrap items-center md:flex-nowrap md:space-x-2 space-y-2 md:space-y-0 duration-1000 ${(step === 4)? 'delay-[5.5s]' : 'opacity-0'}`}>
          <CustomSwitch
            label={`Filtro por ${(labelProfessor)? labelProfessor?.toLowerCase() : 'participante'}`}
            addClassName="w-full"

            value={settingProfessorFilter}
            onClick={() => setSettingProfessorFilter(!settingProfessorFilter)}
          />

          <CustomSwitch
            label="Fecha inicial automática en el filtro"
            addClassName="w-full"

            value={settingDeffaultDateFilter}
            onClick={() => setSettingDeffaultDateFilter(!settingDeffaultDateFilter)}
          />
        </div>

        <div className={`w-full pb-3 flex flex-wrap items-center md:flex-nowrap md:space-x-2 space-y-2 md:space-y-0 duration-1000 ${(step === 4)? 'delay-[6s]' : 'opacity-0'}`}>
          <CustomSwitch
            label="El título es más relevante que el nombre del vídeo"
            addClassName="w-full"

            value={settingTitleIsRelevant}
            onClick={() => setSettingTitleIsRelevant(!settingTitleIsRelevant)}
          />

          {(currentUser?.rol === 'admin') && 
            <CustomSwitch
              label="Mostrar tipología del vídeo en el listado"
              addClassName="w-full"

              value={settingRecordingTipology}
              onClick={() => setSettingRecordingTipology(!settingRecordingTipology)}
            />
          }
        </div>

        <div className={`w-full pb-3 flex flex-wrap items-center md:flex-nowrap md:space-x-2 space-y-2 md:space-y-0 duration-1000 ${(step === 4)? 'delay-[6.5s]' : 'opacity-0'}`}>
          <CustomSwitch
            label="Notificación del cambio de estado en los vídeos"
            addClassName="w-full"

            value={settingProdStateNotification}
            onClick={() => setSettingProdStateNotification(!settingProdStateNotification)}
          />

          <CustomSwitch
            label={`Chyron de los ${(labelProfessors)? labelProfessors?.toLowerCase() : 'participantes'} al inicio de cada vídeo`}
            addClassName="w-full"

            value={settingInitialChyrons}
            onClick={() => setSettingInitialChyrons(!settingInitialChyrons)}
          />
        </div>

        <div className={`w-full flex flex-wrap items-center md:flex-nowrap md:space-x-2 space-y-2 md:space-y-0 duration-1000 ${(step === 4)? 'delay-[7s]' : 'opacity-0'}`}>
          <CustomSwitch
            label={`Columna de ${(labelProfessor)? labelProfessor?.toLowerCase() : 'participante'} en el listado de vídeos`}
            addClassName="w-full"

            value={settingProfessorColumn}
            onClick={() => setSettingProfessorColumn(!settingProfessorColumn)}
          />

          <CustomSwitch
            label={`Los ${(labelProfessors)? labelProfessors?.toLowerCase() : 'participantes'} pueden ver todas las marcas`}
            addClassName="w-full"

            value={settingProfessorsCanSeeMarks}
            onClick={() => setSettingProfessorsCanSeeMarks(!settingProfessorsCanSeeMarks)}
          />
        </div>


        <div className={`pt-5 flex justify-between items-center space-x-4 duration-1000 ${(step === 4)? 'delay-[8s]' : 'opacity-0'}`}>
          <button 
            onMouseUp={(event) => setStep(3)}
            className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 hover:shadow-md active:scale-95 rounded duration-200"
          >
            {leftArrowIcon}
            <p>Cambiar nomenclaturas</p>
          </button>

          <button 
            onClick={() => saveCustomerDataFunc()}
            className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          >
            <p>Continuar con estos ajustes</p>
            {rightArrowIcon}
          </button>
        </div>
      </div>
    }



    {/* STEP 5 */}
    {(step === 4 || step === 5 || step === 6) && 
      <div className={`absolute w-full h-full ${(step === 5)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-4 text-gray-600'>
          <div className={`pb-3 flex items-center space-x-2 duration-1000 ${(step === 5)? 'delay-[1s]' : 'opacity-0'}`}>
            <div className='w-[35px] h-[35px] bg-gray-200 rounded-md flex items-center justify-center'><div className='scale-[1.2]'>{loadingIcon}</div></div>

            <h1 className="font-bold text-3xl lg:text-4xl">
              Guardando la información...
            </h1>
          </div>

          <p className={`leading-5 text-lg duration-1000 ${(step === 5)? 'delay-[2s]' : 'opacity-0'}`}>Ya casi hemos acabado con la creación de {name}. Solo nos falta definir los tipos de marcas que querréis crear.</p>
        </div>
      </div>
    }



    {/* STEP 6 */}
    {(customer?.id && (step === 5 || step === 6 || step === 7)) && 
      <div className={`absolute w-full h-full ${(step === 6)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-5 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 6)? 'delay-[1s]' : 'opacity-0'}`}>
            Por último. ¡Las marcas!
          </h1>

          <p className={`pb-2 leading-5 text-lg duration-1000 ${(step === 6)? 'delay-[2s]' : 'opacity-0'}`}>Las tipologías de marcas definen que tipo de información puedes adjuntar dentro de un vídeo.</p>
          <p className={`pb-4 leading-5 text-lg duration-1000 ${(step === 6)? 'delay-[3s]' : 'opacity-0'}`}>En caso de ya tener un cliente con tipologías que se adapten a sus necesidades, puede clicar el botón de abajo a la izquierda <strong onClick={() => setOpenCustomerSelector(true)} className='text-blue-500 hover:text-blue-700 cursor-pointer duration-200'>"Importar tipologías de otro cliente"</strong> para copiarlas y continuar con ellas.</p>
          <p className={`text-sm text-gray-400 duration-1000 ${(step === 6)? 'delay-[5s]' : 'opacity-0'}`}>Ejemplos: Texto básico, Listado, Imagen con texto...</p>
        </div>


        <div className={`w-full flex items-center space-x-2 duration-1000 ${(step === 6)? 'delay-[6s]' : 'opacity-0'}`}>
          <CustomerEditorMarks
            customer={customer}
            openCustomerSelector={openCustomerSelector}
            loading={false}
            step={step}
            setStep={setStep}
            small
          />
        </div>
      </div>
    }



    {/* STEP 7 - END */}
    {(customer?.id && (step === 6 || step === 7)) && <>

      <div className={`absolute z-[0] mt-[-200px] w-full h-full max-h-[400px] min-h-[400px] overflow-hidden flex items-center justify-center pointer-events-none duration-1000 ${(step === 7)? 'delay-[0.5s]' : 'opacity-0'}`}>
        <div className='z-[1] absolute w-full h-full bg-gradient-to-b from-white via-transparent to-white'></div>
        <div className='z-[0] absolute scale-[30] opacity-[0.1] text-blue-500'>{bgCheckIcon}</div>
      </div>

      <div className={`absolute w-full h-full ${(step === 7)? 'z-[9999]' : 'z-[3] pointer-events-none'}`}>
        <div className='pb-5 text-gray-600'>
          <h1 className={`pb-3 font-bold text-3xl lg:text-4xl duration-1000 ${(step === 7)? 'delay-[1s]' : 'opacity-0'}`}>
            Su cliente ha sido creado correctamente.
          </h1>

          <p className={`pb-2 leading-5 text-lg duration-1000 ${(step === 7)? 'delay-[2s]' : 'opacity-0'}`}>Todos estos ajustes y más pueden ser modificados en cualquier momento des de la edición del cliente dentro del selector de clientes. En caso de estar interesado en más funcionalidades, no dude en visitarlo.</p>
        </div>


        <div className={`pt-1 flex justify-between items-center space-x-4 duration-1000 ${(step === 7)? 'delay-[3s]' : 'opacity-0'}`}>
          <button 
            onMouseUp={(event) => navigate('/customer-selector')}
            className="p-[6px] pl-1 pr-3 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 hover:shadow-md active:scale-95 rounded duration-200"
          >
            {leftArrowIcon}
            <p>Volver al selector de clientes</p>
          </button>

          <button 
            onClick={() => selectCurrentCustomer(customer)}
            className='p-[6px] pr-1 pl-3 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          >
            <p>Acceder al cliente</p>
            {rightArrowIcon}
          </button>
        </div>
      </div>
    </>}



  </>);
}