import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useNavigate } from "react-router-dom";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';










export default function MenuUserProfile({

  anchorEl,
  setAnchorEl,
  startTutorial,
  setStartTutorial

}) {




  const { currentUser, setCurrentUser, currentCustomer, setHistory, showPrices, toggleShowPricesFunc, marksNearName, toggleMarksNearName } = useContext(AuthContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();




  const signOutIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M606 752q-12-12-12-29.625T606 693l75-75H403q-17 0-29.5-12.5T361 576q0-17 12.5-29.5T403 534h275l-73-74q-11-12.267-11-29.133Q594 414 606 402q11.75-12 29.375-12T665 402l145 145q7.091 6.6 10.045 14.3Q823 569 823 577q0 8-2.955 15.7-2.954 7.7-10.045 14.3L664 753q-12 12-28.5 12T606 752ZM204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h233.463Q454 216 466.5 228.5T479 258q0 17-12.5 29.5T437.463 300H204v552h233.463Q454 852 466.5 864.5T479 894q0 17-12.5 29.5T437.463 936H204Z"/></svg>;
  const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M556 976H404q-16 0-27.5-10.5T363 939l-11-89q-10-6-22-13t-22-13l-83 35q-15 6-30 1t-23-19L97 710q-8-14-4.5-29t15.5-25l70-54v-52l-70-54q-12-10-15.5-25t4.5-29l75-131q8-14 23-19t30 1l83 35q10-6 22-13t22-13l11-89q2-16 13.5-26.5T404 176h152q16 0 27.5 10.5T597 213l11 89q10 6 22 13t22 13l83-35q15-6 30-1t23 19l76 131q8 14 5 29t-16 25l-70 54v52l70 54q13 10 16 25t-5 29l-76 131q-8 14-23 19t-30-1l-83-35q-10 6-22 13t-22 13l-11 89q-2 16-13.5 26.5T556 976Zm-76-257q59 0 101-42t42-101q0-59-42-101t-101-42q-59 0-101 42t-42 101q0 59 42 101t101 42Z"/></svg>;
  const customerIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M686.46 635q12.54 0 21.54-8.783 9-8.784 9-22.217 0-13.433-8.783-22.217Q699.433 573 686 573h-96.46Q577 573 568 581.783q-9 8.784-9 22.217 0 13.433 8.783 22.217Q576.567 635 590 635h96.46Zm0 121q12.54 0 21.54-8.783 9-8.784 9-22.217 0-13.433-8.783-22.217Q699.433 694 686 694h-96.46Q577 694 568 702.783q-9 8.784-9 22.217 0 13.433 8.783 22.217Q576.567 756 590 756h96.46ZM604 376h192q34.65 0 59.325 24.675Q880 425.35 880 460v432q0 34.65-24.675 59.325Q830.65 976 796 976H164q-34.65 0-59.325-24.675Q80 926.65 80 892V460q0-34.65 24.675-59.325Q129.35 376 164 376h193V260q0-34.65 24.675-59.325Q406.35 176 441 176h79q34.65 0 59.325 24.675Q604 225.35 604 260v116Zm-163 84h79V260h-79v200Zm39 215Zm-118 18q25 0 42.5-17.208Q422 658.583 422 634q0-26-17.5-43.5T362 573q-25 0-42.5 17.5t-17.5 43q0 25.5 17.5 42.5t42.5 17ZM242 815h239v-18q0-19-9.5-34T445 740.941Q425 733 404 728.5q-21-4.5-42-4.5t-42 4.5q-21 4.5-41.541 12.441Q261 748 251.5 763t-9.5 34v18Zm115-355H164v432h632V460H604q0 34-24.381 59-24.382 25-58.619 25h-82q-33.825 0-57.913-25Q357 494 357 460Z"/></svg>;
  const startIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M144-330.29v-299.38Q144-646 154.93-656q10.94-10 25.51-10 4.56 0 9.06 1.5 4.5 1.5 9.5 4.5l234 150q8 5.44 12 13.15t4 16.78q0 9.07-4 16.83-4 7.76-12 13.24L199-300q-5 3-9.5 4.5t-9.06 1.5q-14.57 0-25.51-9.98Q144-313.96 144-330.29Zm384 0v-299.38Q528-646 538.93-656q10.94-10 25.51-10 4.56 0 9.06 1.5 4.5 1.5 9.5 4.5l234 150q8 5.44 12 13.15t4 16.78q0 9.07-4 16.83-4 7.76-12 13.24L583-300q-5 3-9.5 4.5t-9.06 1.5q-14.57 0-25.51-9.98Q528-313.96 528-330.29Z"/></svg>

  const toggleOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70h400q100 0 170 70t70 170q0 100-70 170t-170 70H280Zm0-80h400q66 0 113-47t47-113q0-66-47-113t-113-47H280q-66 0-113 47t-47 113q0 66 47 113t113 47Zm400-40q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM480-480Z"/></svg>
  const toggleOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70h400q100 0 170 70t70 170q0 100-70 170t-170 70H280Zm0-80h400q66 0 113-47t47-113q0-66-47-113t-113-47H280q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-40q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm200-120Z"/></svg>






  function logOut() {
    setCurrentUser(null);
    navigate("/signin");
  }




  
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl? true : false}
      onClose={()=>setAnchorEl(null)}
      onClick={()=>setAnchorEl(null)}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          ml: 0.8,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >



      <div 
        className='group px-4 py-1 m-2 flex flex-wrap items-baseline justify-between max-w-[240px] rounded-md hover:bg-gray-100 duration-300 cursor-pointer bg-gradient-to-r from-gray-200 to-gray-50'
        onMouseUp={(event)=> navigateOrOpenFunc(event, '/own-user-settings') }
      >
        <p className='w-full truncate font-bold text-lg group-hover:text-blue-500 group-active:text-blue-300 cursor-pointer transition-all duration-300'>{currentUser?.name}</p>
        <p className='w-full text-gray-400 text-sm group-hover:text-blue-500 group-active:text-blue-300 duration-300'> /
          {
            (currentUser?.rol === 'admin')? ' Usuario administrador' 
            : (currentUser?.rol?.includes('translator'))? ' Usuario traductor' 
            : (currentUser?.rol?.includes('editor'))? ' Usuario editor' 
            : (currentUser?.rol === 'facturator')? ' Usuario de facturación' 
            : (currentUser?.rol === 'super_user')? ' Super usuario' 
            : ' Usuario base'
          }
        </p>
      </div>



      <Divider/>



      <div className='p-2 mb-0'>
        
        {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') &&
          <MenuItem onMouseUp={toggleShowPricesFunc} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}>
            <ListItemIcon>
              {(showPrices)? <div className='text-blue-500'>{toggleOnIcon}</div> : toggleOffIcon}
            </ListItemIcon>
            <p>{(showPrices)? 'Precios a la vista' : 'Precios a la vista'}</p>
          </MenuItem>
        }

        <MenuItem onMouseUp={toggleMarksNearName} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}>
          <ListItemIcon>
            {(marksNearName)? <div className='text-blue-500'>{toggleOnIcon}</div> : toggleOffIcon}
          </ListItemIcon>
          <p>{(marksNearName)? 'Marcas cerca del título' : 'Marcas cerca del título'}</p>
        </MenuItem>

        {(currentUser?.customer_free || (currentCustomer?.free_customer && currentUser?.rol === 'super_user'))?
          <MenuItem
            onMouseUp={(event)=> {
              navigate('/videos')
              setStartTutorial(true)
            }}
            style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}
          >
            <ListItemIcon>
              {startIcon}
            </ListItemIcon>
            <p>Iniciar tutorial</p>
          </MenuItem>
        : null }
      </div>



      <Divider/>



      <div className='p-2 mb-0'>

        <MenuItem onMouseUp={(event)=> navigateOrOpenFunc(event, '/own-user-settings') } style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}>
          <ListItemIcon>
            {settingsIcon}
          </ListItemIcon>
          <p>Editar perfil</p>
        </MenuItem>

        {(currentUser?.customer_free && !currentUser?.professor) &&
          <MenuItem onClick={()=>{setHistory(['', '', '', '']); navigate('/customer-selector')}} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}>
            <ListItemIcon>
              {customerIcon}
            </ListItemIcon>
            <p>Cambiar de cliente</p>
          </MenuItem>
        }

        <MenuItem onClick={()=>logOut()} style={{ borderRadius: 5, transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 10 }}>
          <ListItemIcon>
            {signOutIcon}
          </ListItemIcon>
          <p>Cerrar sesión</p>
        </MenuItem>

      </div>
    </Menu>
  );
}