import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLazyQuery } from '@apollo/client'

import GetStatesByCustomer from '../graphql/queries/GetStatesByCustomer'

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';





export default function ItemListStates({state, setState, setStateFunc, filter, disabled, cookies, small}) {



  const { currentUser, currentCustomer } = useContext(AuthContext)


  const [states, setStates] = useState(null);
  const [isHiddenState, setIsHiddenState] = useState(null);



  

  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })





  useEffect(() => {    
    if (state && states?.length) {
      var isHiddenState = false;
      var endState = null;

      if (currentUser?.rol?.includes('user')) {
        for (var stateTMP of states) {
          if (parseInt(state) === parseInt(stateTMP?.id) && stateTMP?.hidden) {
            isHiddenState = true;
          }
  
          if (stateTMP?.ended_marks && stateTMP?.color === 'green' && !stateTMP?.hidden) {
            endState = stateTMP?.id;
          }
        }
      }

      setIsHiddenState((isHiddenState && endState)? endState : null)
    }
  }, [state, states])


  useEffect(() => {
    getStatesByCustomer()
  }, []);


  useEffect(() => {
    if (dataStates?.getStatesByCustomer?.length) {
      setStates(dataStates?.getStatesByCustomer)
    } else {
      setStates(null)
    }
  }, [dataStates]);










  return (
    <TextField
      select
      value={(isHiddenState)? isHiddenState : (state)? state : (filter? 'null' : 1)}
      onChange={event => {
        if (filter) {
          setState(event.target.value)
          if (cookies) {
            localStorage.setItem(currentCustomer?.token+'-video-state-filter', event.target.value)
          }
        } else {
          if (currentUser?.rol === 'super_user' || window.confirm("¿Seguro que quieres cambiar el estado de este vídeo?" + ((state > event.target.value)? ' Si es posible, recuerda poner una nota con el motivo de este cambio.' : ''))) {
            setState(event.target.value)
            setStateFunc(event.target.value)
          }
        }
      }}
      label="Estado"
      className={`addPaddings ${(filter)? 'mui-bg-gray-100 flex-grow w-full 2xl:w-auto' : 'mui-bg-gray-50 w-full'}`}
      style={{ minWidth: (filter)? 240 : 'inherit' }}
      size="small"
      variant={(filter || small)? 'outlined' : 'filled'}
      disabled={disabled}
      InputProps={{ sx: { height: (small)? "30px" : "inherit", minWidth: 130, "& .MuiInputBase-input": { paddingInline: 1.4 } } }}
      InputLabelProps={{ sx: { top: "-10.5px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}

      SelectProps={{
        MenuProps: {
          sx: {
            ".MuiList-root": {
              padding: '8px !important',
            },
          },
        },
      }}
    >

      {(filter) && 
        <MenuItem value={'null'} style={{ marginInline: 0, marginBottom: ((1) !== states?.length)? 4 : 0 }}>
          <div className={`flex items-center space-x-2 truncate w-full menu-item-gray`}>
            <div className={`hover:opacity-50 p-[6px] menu-item-ball-gray`}></div>
            <p>Sin filtro por estado</p>
          </div>
        </MenuItem>
      }

      {states?.map((stateTMP, key) => {
        const hiddenState = (currentUser?.rol?.includes('user') && stateTMP?.hidden)? true : false

        return (!hiddenState)? 
          <MenuItem 
            key={'state-list-idk-' + stateTMP.id} 
            value={stateTMP.id} 
            style={{ marginInline: 0, marginBottom: ((key+1) !== states?.length)? 4 : 0 }}
          >
            <div className={`flex items-center space-x-2 truncate w-full menu-item-${stateTMP.color}`}>
              <div className={`hover:opacity-50 p-[6px] menu-item-ball-${stateTMP.color}`}></div>
              <p className='text-sm'>{stateTMP.name}</p>
            </div>
          </MenuItem>
        : null 
      })}
    </TextField>
  );
}