import gql from "graphql-tag";
export default gql`
  query GetLastDailyEmails($own_user_token: String!, $own_customer_token: String!, $date_start: Date!, $date_end: Date!) {
    getLastDailyEmails(own_user_token: $own_user_token, own_customer_token: $own_customer_token, date_start: $date_start, date_end: $date_end) {
      id
      customer_token
      user_id
      email
      name
      title
      body
      sended
      
      sendedAt
      createdAt
      updatedAt

      sendedAtFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
