import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';

import Tooltip from '@mui/material/Tooltip';












export default function SettingFilters({

  customerSettings,

  orderGroupsPanelOpen,
  setOrderGroupsPanelOpen,
  groupsVisibility,
  setGroupsVisibility,
  legendVisibility,
  setLegendVisibility,
  visibleCustomerSettings,
  setVisibleCustomerSettings,
  filterOrderFunc,

  callGetSettingRelationsFunc,

}) {


  const { currentCustomer } = useContext(AuthContext)



  const localSettingsGroupVisibility = (localStorage.getItem(currentCustomer?.token + '-groups-visibility'))? JSON.parse( localStorage.getItem(currentCustomer?.token + '-groups-visibility') ) : null
  localStorage.removeItem(currentCustomer?.token + '-settings-order')
  /* const localSettingsLegendVisibility = (localStorage.getItem(currentCustomer?.token + '-legend-visibility'))? localStorage.getItem(currentCustomer?.token + '-legend-visibility') : null */
  /* const localSettingsOrder = (localStorage.getItem(currentCustomer?.token + '-settings-order'))? JSON.parse( localStorage.getItem(currentCustomer?.token + '-settings-order') ) : null */




  const [groups, setGroups] = useState(null);
  const [legend, setLegend] = useState(null);
  const [loadingReload, setLoadingReload] = useState(false);




  /* Icon vars */
  const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M556 976H404q-16 0-27.5-10.5T363 939l-11-89q-10-6-22-13t-22-13l-83 35q-15 6-30 1t-23-19L97 710q-8-14-4.5-29t15.5-25l70-54v-52l-70-54q-12-10-15.5-25t4.5-29l75-131q8-14 23-19t30 1l83 35q10-6 22-13t22-13l11-89q2-16 13.5-26.5T404 176h152q16 0 27.5 10.5T597 213l11 89q10 6 22 13t22 13l83-35q15-6 30-1t23 19l76 131q8 14 5 29t-16 25l-70 54v52l70 54q13 10 16 25t-5 29l-76 131q-8 14-23 19t-30-1l-83-35q-10 6-22 13t-22 13l-11 89q-2 16-13.5 26.5T556 976Zm-76-257q59 0 101-42t42-101q0-59-42-101t-101-42q-59 0-101 42t-42 101q0 59 42 101t101 42Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M479.887-240Q500-240 514-253.887q14-13.888 14-34Q528-308 514.113-322q-13.888-14-34-14Q460-336 446-322.113q-14 13.888-14 34Q432-268 445.887-254q13.888 14 34 14Zm.389 144Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546-619.59 546-595.282 546-573 533-556q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565-495q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591-720Q446-720 412.5-703.5 379-687 358.84-654.733 351-643 354.5-629t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>
  const reloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-192q-120 0-204-84t-84-204q0-120 84-204t204-84q65 0 120.5 27t95.5 72v-63q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-768 757.5-757.65 768-747.3 768-732v168q0 15.3-10.35 25.65Q747.3-528 732-528H564q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q528-579 538.35-589.5 548.7-600 564-600h95q-29-44-76-70t-103-26q-90 0-153 63t-63 153q0 90 63 153t153 63q75 0 132-45.5T687-424q4-14 16.5-22t26.532-6Q745-450 754-438.5t6 25.5q-23 96-100 158.5T480-192Z"/></svg>

  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;







  
  





  useEffect(() => {
    if (customerSettings?.length)  {

      var groupsTMP = [null, ...customerSettings?.map((el) => el?.setting_group)];
      groupsTMP = groupsTMP.filter((item, index) => groupsTMP.indexOf(item) === index);
      setGroups(groupsTMP?.sort((a, b) => (a < b)? -1 : (a > b)? 1 : 0 ))

      var legendTMP = [...new Set( [null].concat(...customerSettings?.map((el) => el?.states?.split(','))) )];
      setLegend(legendTMP)

    } else if (customerSettings?.length === 0) {
      setGroups([null])
      setLegend([null])
    }
  }, [customerSettings])







  function saveGroupVisibilityFunc(groupNameTMP) {
    if (groupNameTMP) {
      var groupsCookies = (localSettingsGroupVisibility)? localSettingsGroupVisibility : {}
      groupsCookies[groupNameTMP] = (groupsCookies && groupNameTMP in groupsCookies)? !groupsCookies[groupNameTMP] : false

      setGroupsVisibility(groupsCookies)
      localStorage.setItem(currentCustomer?.token + '-groups-visibility', JSON.stringify(groupsCookies))
    }
  }

  function saveSettingsOrderFunc(newOrderTMP) {
    if (newOrderTMP) {
      localStorage.setItem(currentCustomer?.token + '-settings-order', JSON.stringify(newOrderTMP))
    } else {
      localStorage.removeItem(currentCustomer?.token + '-settings-order')

      var filtredTMP = filterOrderFunc(customerSettings, false);
      setVisibleCustomerSettings((filtredTMP?.length)? filtredTMP : null)
    }
  }

  function setHiddenSettingStateFunc(settingState) {
    if (settingState && settingState !== legendVisibility) {
      setLegendVisibility(settingState)
      localStorage.setItem(currentCustomer?.token + '-legend-visibility', settingState) 
    } else {
      setLegendVisibility(null)
      localStorage.removeItem(currentCustomer?.token + '-legend-visibility') 
    }
  }



  function callReloadFunc() {
    setLoadingReload(true)

    setTimeout(() => {
      callGetSettingRelationsFunc()

      setTimeout(() => {
        setLoadingReload(false)
      }, 1000 )
    }, 200 )

  }









  return (customerSettings?.length)?
    <div className={`relative w-full pt-[10px] flex justify-between ${(orderGroupsPanelOpen)? '' : 'space-x-2'}`}>
      <div className='z-20'>
        <Tooltip disableInteractive title={'Recargar estados'} placement="bottom" arrow>
          <button
            onClick={() => callReloadFunc()}
            className={`group/reload absolute right-[45px] flex justify-center items-center p-1 border duration-200 border-gray-400 border-opacity-60 rounded
              ${(loadingReload)? 'bg-blue-500 text-white cursor-wait opacity-60' : 'text-gray-500 bg-gray-100 hover:bg-blue-500 hover:text-white'}
            `}
          >
            <div className={`${(loadingReload)? '' : 'group-hover/reload:scale-[1.2] group-hover/reload:animate-one-spin-back duration-600 ease-linear'}`}>
              {(loadingReload)? loadingIcon : reloadIcon}
            </div>
          </button>
        </Tooltip>

        <div className="absolute duration-500 overflow-hidden rounded">
          <div className="flex justify-between space-x-1 w-full duration-500 text-xs overflow-hidden rounded">

            <div className='w-full flex items-center space-x-1'>
              {legend?.map((element, key) => { 
                var elementNameTMP = (element)? element : 'N/A';

                return <div key={'setting-filter-' + key}>
                  <button
                    onClick={() => setHiddenSettingStateFunc(elementNameTMP)}
                    className={`${(legendVisibility && elementNameTMP === legendVisibility)? 'hover:bg-blue-500 bg-blue-400 text-white border-blue-400' : 'opacity-40 hover:opacity-100 bg-gray-200 border-gray-400 border-opacity-60'} active:scale-95 px-2 py-[6px] flex justify-center items-center space-x-1 border rounded duration-200`}
                    style={{ marginTop: 0 }}
                  >
                    <p>{elementNameTMP}</p>
                  </button>
                </div>
              })}
            </div>

            <Tooltip disableInteractive title={'Filtro de estados'} placement="bottom" arrow>
              <div>
                <button className='p-[4px] flex justify-center space-x-1 hover:bg-gray-200 text-gray-500 opacity-50 hover:opacity-100 rounded duration-200' style={{ marginTop: 0 }}>
                  {infoIcon}
                </button>
              </div>
            </Tooltip>

          </div>
        </div>
      </div>
      
      <div className={`${(orderGroupsPanelOpen)? 'w-full' : 'w-[30px] delay-300'} z-30 duration-500 overflow-hidden text-xs flex flex-wrap items-center space-y-2 bg-gray-100 border border-gray-400 border-opacity-60 rounded`}>
        <div onClick={() => setOrderGroupsPanelOpen(!orderGroupsPanelOpen) } className='w-full p-1 flex items-center cursor-pointer hover:bg-gray-200 duration-200'>
          <Tooltip disableInteractive title={(orderGroupsPanelOpen)? '' : 'Abrir panel de orden y visibilidad de la tabla'} placement="bottom" arrow>
            <div className={`scale-90 text-gray-500 ${(orderGroupsPanelOpen)? 'animate-one-spin' : 'animate-one-spin-back'}`}>{settingsIcon}</div>
          </Tooltip>

          <p className='w-full truncate pl-2'>Orden y visibilidad de la tabla</p>

          <Tooltip disableInteractive title={(orderGroupsPanelOpen)? 'Cerrar panel de orden y visibilidad de la tabla' : ''} placement="bottom" arrow>
            <div className={`scale-90 opacity-50 hover:bg-gray-300 rounded ${(orderGroupsPanelOpen)? 'animate-one-spin' : 'animate-one-spin-back'}`}>{(orderGroupsPanelOpen)? cancelIcon : loadingIcon}</div>
          </Tooltip>
        </div>


        <div className={`w-full ${(orderGroupsPanelOpen)? `${(visibleCustomerSettings?.length)? 'h-[70px]' : 'h-[70px]'} delay-500` : 'h-0'} duration-200 whitespace-nowrap`} style={{ margin: 0 }}>
          <hr className='w-full border-gray-400 border-opacity-60' style={{ margin: 0 }} />

          <div className='w-full flex flex-wrap items-center space-y-2 p-2'>
            {(groups?.length > 1) &&
              <div className='w-full'>
                <p className='text-gray-500 text-xs px-1'>Visibilidad de los grupos:</p>
                <div className={`overflow-hidden w-full flex bg-gray-50 border border-gray-400 border-opacity-60 rounded`}>
                  {groups?.map((groupTMP, key) => {

                    var nameSplittedTMP = (groupTMP)? groupTMP?.split(' - ') : null
                    if (groupTMP?.split(' - ')?.length > 1) { nameSplittedTMP?.shift() }
                    var nameTMP = (nameSplittedTMP?.length)? nameSplittedTMP?.join(' - ') : groupTMP

                    var hasItems = (customerSettings?.filter((element)=> element?.setting_group === groupTMP)?.length)? true : false;
                    var isVisible = (groupsVisibility && nameTMP in groupsVisibility)? groupsVisibility[nameTMP] : true

                    return (hasItems) && <button key={'setting-butt-filter-' + key} type='button' onClick={()=> saveGroupVisibilityFunc((nameTMP)? nameTMP : 'Otros')} className={`${((key+1) !== groups?.length)? ' border-r' : ''} w-full flex items-center space-x-2 text-gray-500 hover:text-gray-700 bg-gray-200 hover:bg-gray-300 border-gray-400 border-opacity-60 pr-3 p-2 duration-200`}>
                      {(isVisible)? visibilityOnIcon : visibilityOffIcon}
                      <p>{(nameTMP)? nameTMP : 'Otros'}</p>
                    </button>
                  })}
                </div>
              </div>
            }

            {/* {(visibleCustomerSettings?.length) && 
              <div className='w-full flex items-end space-x-2'>
                <div className='w-full overflow-hidden'>
                  <p className='text-gray-500 text-xs px-1'>Orden del listado:</p>
                  <SimpleOrderList
                    droppableId={'settings-order'}
                    objects={visibleCustomerSettings}
                    setObjects={setVisibleCustomerSettings}
                    callOnDragEnd={saveSettingsOrderFunc}
                    direction={'horizontal'}
                    small={false}
                    disabled={false}
                  />
                </div>

                {(localSettingsOrder)?
                  <Tooltip disableInteractive title={'Reestablecer orden predeterminado'} placement="bottom" arrow>
                    <button onClick={() => { saveSettingsOrderFunc(null); }} className={`p-1 my-1 text-gray-500 bg-gray-200 border border-gray-400 border-opacity-40 hover:bg-red-500 hover:text-white rounded duration-200`}>
                      {(orderGroupsPanelOpen)? cancelIcon : loadingIcon}
                    </button>
                  </Tooltip>
                : null }
              </div>
            } */}
          </div>
        </div>
      </div>
    </div>
  : null 
}