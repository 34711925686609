export default function FALSO_DIRECTO_EN_REMOTO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="scale-[0.7]">
			<g>
				<path d="M244.96,152.46c-22.49,1.87-40.96,20.03-43.96,43.2c-2.94,22.72,10.57,44.59,32.72,52.1c4.41,1.49,5.52,3.54,5.48,7.85
					c-0.2,20.16-0.09,40.33-0.09,60.49c0,20.37-0.03,40.74,0.01,61.11c0.02,8.76,3.9,13.74,10.63,13.88c6.9,0.14,11-5.06,11.09-14.1
					c0.02-1.44,0-2.88,0-4.32c0-36.83,0.28-73.66-0.21-110.49c-0.11-8.56,0.81-14.76,10.52-16.21c0.96-0.14,1.83-0.91,2.73-1.41
					c20.63-11.51,30.15-35.03,23.32-57.57C290.39,164.51,269.11,150.45,244.96,152.46z"/>
				<g>
					<path d="M120.87,276.01c6.61,10.88,15.08,20.71,23.27,30.54c4.23,5.07,10.93,4.86,15.22,0.73c4.28-4.12,4.29-10.3,0.01-15.4
						c-0.65-0.78-1.39-1.49-2.1-2.23c-46.55-49.03-46.78-126.38-0.53-175.29c47.15-49.86,124.64-54.11,176.39-9.67
						c51.47,44.19,59.59,121.25,18.36,174.85c-3.6,4.68-7.84,8.84-11.53,13.45c-3.65,4.56-3.18,10.65,0.76,14.44
						c3.95,3.8,9.94,3.9,14.44,0.16c1.09-0.91,2.05-1.99,3.03-3.02c45.79-48.11,54.11-122.31,20.08-178.91
						c-35.15-58.46-102.74-85.42-169.03-67.43c-63.2,17.15-108.21,76.61-108.36,148.6C100.96,228,107.02,253.23,120.87,276.01z"/>
					<path d="M239.2,366.33c-4.86-0.03-9.73-0.09-14.59-0.19c-3.34-0.07-7.06-1.52-9.87-3.42c-3.11-2.11-6.19-3.51-9.76-3.85H101.95
						v-0.1c-10.53-0.01-21.06-0.01-31.59-0.02c-0.54,0-1.07-0.18-2.01-0.34c-0.07-1.5-0.2-2.97-0.2-4.44
						c-0.01-68.36-0.01-136.72,0-205.08c0-11.71,6.35-18.1,17.98-18.1c4.92,0,9.85,0,14.77,0c4.64-9.88,10.22-19.31,16.71-28.18
						c-10.5,0-21,0-31.49,0.01c-3.86,0-7.82,0.3-11.57,1.15c-20.89,4.71-34.58,22.37-34.59,44.63c-0.04,68.21-0.02,136.42-0.02,204.63
						c0,1.66,0,3.31,0,5.77c-5.31,0-10.14-0.05-14.98,0.01c-9.36,0.13-15.05,5.84-15.1,15.13c-0.04,7.92-0.18,15.84,0.05,23.75
						c0.15,5.38,0.18,10.91,1.43,16.1c4.91,20.38,22.58,33.46,44.65,33.46c129.33,0.02,258.67,0.01,388,0.01c0.42,0,0.83,0,1.25,0
						c22.73-0.33,40.88-15.17,44-37.65c1.59-11.46,0.69-23.28,0.77-34.93c0.07-10.34-5.55-15.83-16.01-15.88c-4.55-0.02-9.1,0-14.05,0
						c0-2.31,0-4.08,0-5.86c0-67.93,0.02-135.86-0.01-203.8c-0.01-27.43-19-46.55-46.27-46.55c-10.29,0-20.59,0-30.88,0
						c3.46,4.62,6.72,9.45,9.75,14.49c2.68,4.45,5.12,9.03,7.35,13.73c4.61,0,9.23,0,13.84,0c11.62,0,18,6.41,18,18.09
						c0.01,68.36,0.01,136.72,0.01,205.08c0,1.37,0,2.75,0,4.54c-1.62,0.11-2.95,0.28-4.29,0.28c-9.94,0.01-19.89,0-29.83,0v0.09H296
						c-4.49,0.4-8.63,1.71-12.43,5.06c-1.92,1.69-5.39,2.24-8.16,2.27c-4.89,0.06-9.78,0.1-14.66,0.13 M461.42,406.04
						c-0.82,7.2-6.9,12.17-14.32,12.93c-1.24,0.13-2.5,0.09-3.75,0.09c-128.93,0-257.86,0-386.8,0c-10.06,0-17.01-4.6-18.07-13.02
						c-0.77-6.11-0.15-12.39-0.15-19.08c1.71,0,3.2,0,4.7,0c50.71,0,101.42-0.03,152.13,0.09c2.61,0.01,5.45,0.88,7.77,2.13
						c6.52,3.53,13.38,5.3,20.76,5.29c17.51-0.03,35.01-0.03,52.52,0c7.83,0.01,15.09-1.87,21.82-5.95c1.46-0.88,3.35-1.48,5.04-1.48
						c51.82-0.08,103.65-0.06,155.47-0.05c0.95,0,1.89,0.19,3.02,0.31C461.57,393.64,462.12,399.91,461.42,406.04z"/>
					<path d="M304.67,257.35c-3.55,4.98-3.27,11.16,0.93,14.93c4.07,3.65,9.96,3.53,14.43-0.42c1.67-1.48,3.2-3.15,4.64-4.86
						c19.3-22.92,27.11-49.17,23.35-79c-6.84-54.29-60.73-93.82-114.62-83.86c-54.82,10.13-89.86,59.47-80.95,114.55
						c3.28,20.26,12.44,37.84,27,52.43c4.72,4.73,11.02,4.84,15.29,0.72c4.09-3.94,4.25-9.94,0.37-14.92c-1-1.28-2.12-2.48-3.2-3.7
						c-12.91-14.71-19.41-31.85-19.49-51.42c-0.07-31.8,19.25-60.43,48.6-72.07c29.69-11.77,63.57-4.47,85.02,18.29
						c22.36,23.74,28.26,58.04,14.09,86.76C316.13,242.87,309.97,249.92,304.67,257.35z"/>
				</g>
			</g>
		</svg>
	</>
}

