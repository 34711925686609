import { useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { FuncContext } from '../contexts/FuncContext'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';




/* Open and Close Navbar */
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);






export default function MiniDrawer({children}) {
  



  const { currentUser, currentCustomer, showNavTop, showNavLeft } = useContext(AuthContext)
  const { currentNavActive, isFullScreenLayout } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)



  /* Remember nav var can open */
  const [open, setOpen] = useState(false);


  
  /* const [openDelay, setOpenDelay] = useState(true);
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setOpenDelay(open)
      }, 300);
    } else {
      setOpenDelay(open)
    }
  }, [open]) */




  /* Icon vars */
  const homeIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M240 856h120V616h240v240h120V496L480 316 240 496v360Zm0 80q-33 0-56.5-23.5T160 856V496q0-19 8.5-36t23.5-28l240-180q11-8 23-12t25-4q13 0 25 4t23 12l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720 936H520V696h-80v240H240Zm240-350Z"/></svg>
  const videoIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M240 856v40q0 17-11.5 28.5T200 936q-17 0-28.5-11.5T160 896V256q0-17 11.5-28.5T200 216q17 0 28.5 11.5T240 256v40h80v-40q0-17 11.5-28.5T360 216h240q17 0 28.5 11.5T640 256v40h80v-40q0-17 11.5-28.5T760 216q17 0 28.5 11.5T800 256v640q0 17-11.5 28.5T760 936q-17 0-28.5-11.5T720 896v-40h-80v40q0 17-11.5 28.5T600 936H360q-17 0-28.5-11.5T320 896v-40h-80Zm0-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm400 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80ZM400 856h160V296H400v560Zm0-560h160-160Z"/></svg>
  const userIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M80 896q-17 0-28.5-11.5T40 856v-72q0-34 17.5-62.5T104 678q62-31 126-46.5T360 616q66 0 130 15.5T616 678q29 15 46.5 43.5T680 784v72q0 17-11.5 28.5T640 896H80Zm661 0q9-7 14-17.5t5-23.5v-79q0-44-24.5-84.5T666 622q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v80q0 17-11.5 28.5T880 896H741ZM360 576q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544 264q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120 816h480v-32q0-11-5.5-20T580 750q-54-27-109-40.5T360 696q-56 0-111 13.5T140 750q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440 416q0-33-23.5-56.5T360 336q-33 0-56.5 23.5T280 416q0 33 23.5 56.5T360 496Zm0-80Zm0 280Z"/></svg>
  const professorIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M320 336v-80q0-33 23.5-56.5T400 176h160q33 0 56.5 23.5T640 256v80h160q33 0 56.5 23.5T880 416v440q0 33-23.5 56.5T800 936H160q-33 0-56.5-23.5T80 856V416q0-33 23.5-56.5T160 336h160Zm80 0h160v-80H400v80Zm400 360H600v40q0 17-11.5 28.5T560 776H400q-17 0-28.5-11.5T360 736v-40H160v160h640V696Zm-360 0h80v-80h-80v80Zm-280-80h200v-40q0-17 11.5-28.5T400 536h160q17 0 28.5 11.5T600 576v40h200V416H160v200Zm320 40Z"/></svg>
  const emailIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M160 896q-33 0-56.5-23.5T80 816V336q0-33 23.5-56.5T160 256h640q33 0 56.5 23.5T880 336v480q0 33-23.5 56.5T800 896H160Zm640-480L501 603q-5 3-10.5 4.5T480 609q-5 0-10.5-1.5T459 603L160 416v400h640V416ZM480 536l320-200H160l320 200ZM160 416v10-59 1-32 32-.5V426v-10 400-400Z"/></svg>
  const factura10Icon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M240 976q-50 0-85-35t-35-85v-80q0-17 11.5-28.5T160 736h80V205q0-9 8-11t16 6l36 36 46-46q6-6 14-6t14 6l46 46 46-46q6-6 14-6t14 6l46 46 46-46q6-6 14-6t14 6l46 46 46-46q6-6 14-6t14 6l46 46 36-36q8-8 16-6.5t8 11.5v651q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760 856V296H320v440h320q17 0 28.5 11.5T680 776v80q0 17 11.5 28.5T720 896ZM399 456q-17 0-28-11.5T360 416q0-17 11.5-28.5T400 376h160q17 0 28.5 11.5T600 416q0 17-11.5 28.5T560 456H399Zm0 120q-17 0-28-11.5T360 536q0-17 11.5-28.5T400 496h160q17 0 28.5 11.5T600 536q0 17-11.5 28.5T560 576H399Zm281-120q-17 0-28.5-11.5T640 416q0-17 11.5-28.5T680 376q17 0 28.5 11.5T720 416q0 17-11.5 28.5T680 456Zm0 120q-17 0-28.5-11.5T640 536q0-17 11.5-28.5T680 496q17 0 28.5 11.5T720 536q0 17-11.5 28.5T680 576ZM240 896h360v-80H200v40q0 17 11.5 28.5T240 896Zm-40 0v-80 80Z"/></svg>
  const motionIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m447-431 156-104q14-9 14-25t-14-25L447-689q-15-10-31-1.5T400-664v208q0 18 16 26.5t31-1.5ZM240-240l-92 92q-19 19-43.5 8.5T80-177v-623q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240Zm-80-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>
  const chartsIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M320-280q17 0 28.5-11.5T360-320v-200q0-17-11.5-28.5T320-560q-17 0-28.5 11.5T280-520v200q0 17 11.5 28.5T320-280Zm160 0q17 0 28.5-11.5T520-320v-320q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v320q0 17 11.5 28.5T480-280Zm160 0q17 0 28.5-11.5T680-320v-80q0-17-11.5-28.5T640-440q-17 0-28.5 11.5T600-400v80q0 17 11.5 28.5T640-280ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
  const tagIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m297-581 149-243q6-10 15-14.5t19-4.5q10 0 19 4.5t15 14.5l149 243q6 10 6 21t-5 20q-5 9-14 14.5t-21 5.5H331q-12 0-21-5.5T296-540q-5-9-5-20t6-21ZM700-80q-75 0-127.5-52.5T520-260q0-75 52.5-127.5T700-440q75 0 127.5 52.5T880-260q0 75-52.5 127.5T700-80Zm-580-60v-240q0-17 11.5-28.5T160-420h240q17 0 28.5 11.5T440-380v240q0 17-11.5 28.5T400-100H160q-17 0-28.5-11.5T120-140Zm580-20q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-500-20h160v-160H200v160Zm202-420h156l-78-126-78 126Zm78 0ZM360-340Zm340 80Z"/></svg>
  const locationIcon = <svg className='scale-[0.8] sm:scale-100' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="M480-301q99-80 149.5-154T680-594q0-56-20.5-95.5t-50.5-64Q579-778 544-789t-64-11q-29 0-64 11t-65 35.5q-30 24.5-50.5 64T280-594q0 65 50.5 139T480-301Zm0 85q-10 0-19.5-3t-17.5-9q-81-63-162-158.5T200-594q0-71 25.5-124.5T291-808q40-36 90-54t99-18q49 0 99 18t90 54q40 36 65.5 89.5T760-594q0 112-81 207.5T517-228q-8 6-17.5 9t-19.5 3Zm0-304q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM240-80q-17 0-28.5-11.5T200-120q0-17 11.5-28.5T240-160h480q17 0 28.5 11.5T760-120q0 17-11.5 28.5T720-80H240Zm240-514Z"/></svg>




  return (
    <div className="w-full min-w-0 flex select-none">
      


      {(showNavLeft && !isFullScreenLayout) && 
        <Drawer
          variant="permanent"
          open={open}
          sx={{ 
            width: '40px !important', 
            "& .MuiPaper-root": { borderColor: 'rgb(209 213 219) !important', marginTop: '56px' },
            '@media (min-width: 640px)' : { width: '60px !important' },
            "& .MuiDrawer-paper": { 
              width: '40px !important',
              '@media (min-width: 640px)' : { width: '60px !important' },
            } 
          }}
        >



          {/* <div style={{ marginTop: 65 }}>
            {(open) && <div onMouseUp={() => setOpen(false)} className="w-full px-1 flex justify-end hover:bg-gray-200 transition duration-300"> CLOSE </div> }
            {(!open) && <div onMouseUp={() => setOpen(true)} className="w-full px-1 flex justify-center hover:bg-gray-200 transition duration-300"> OPEN </div> }
          </div> */}



          <ListItem disablePadding sx={{ display: 'block' }} className='h-full relative bg-gradient-to-b from-gray-100 to-white'>
            {/* <Divider style={{ height: 56 }}/> */}


            <div id="left-menu-tuto" className='bg-gray-200 bg-opacity-60'>
              {(!currentUser?.rol?.includes('user') || (currentCustomer?.free_customer && currentUser?.rol === 'super_user')) &&
                <Tooltip disableInteractive title="Inicio" placement="right" arrow>
                  <ListItemButton 
                    sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'dashboard')? 'rgb(219 234 254)' : '' }}
                    onMouseUp={(event)=> navigateOrOpenFunc(event, "/dashboard") }
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'dashboard')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.55)' }}>
                      {homeIcon}
                    </ListItemIcon>
                    <ListItemText primary={'Inicio'} sx={{ opacity: (open)? 1 : 0 }} />
                  </ListItemButton>
                </Tooltip>
              }



              <Tooltip disableInteractive title="Vídeos" placement="right" arrow>
                <ListItemButton 
                  sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'videos')? 'rgb(219 234 254)' : '' }}
                  onMouseUp={(event)=> navigateOrOpenFunc(event, "/videos") }
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'videos')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.55)' }}>
                    {videoIcon}
                  </ListItemIcon>
                  <ListItemText primary={'Vídeos'} sx={{ opacity: (open)? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>



              {(!currentUser?.rol?.includes('user') || (currentCustomer?.free_customer && currentUser?.rol === 'super_user')) &&
                <Tooltip disableInteractive title="Motions" placement="right" arrow>
                  <ListItemButton 
                    sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'motions')? 'rgb(219 234 254)' : '' }}
                    onMouseUp={(event)=> navigateOrOpenFunc(event, "/motions") }
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'motions')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.55)' }}>
                      {motionIcon}
                    </ListItemIcon>
                    <ListItemText primary={'Motions'} sx={{ opacity: (open)? 1 : 0 }} />
                  </ListItemButton>
                </Tooltip>
              }



              <Tooltip disableInteractive title="Estadísticas" placement="right" arrow>
                <ListItemButton 
                  sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'charts')? 'rgb(219 234 254)' : '' }}
                  onMouseUp={(event)=> navigateOrOpenFunc(event, "/charts") }
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'charts')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.55)' }}>
                    {chartsIcon}
                  </ListItemIcon>
                  <ListItemText primary={'Motions'} sx={{ opacity: (open)? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            </div>


            <Divider/>



            <div>
              {(currentUser?.rol === 'admin' || currentUser?.rol === 'super_user') &&
                <Tooltip disableInteractive title="Usuarios" placement="right" arrow>
                  <ListItemButton 
                    sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'users')? 'rgb(219 234 254)' : '' }}  
                    onMouseUp={(event)=> navigateOrOpenFunc(event, "/users") }
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'users')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                      {userIcon}
                    </ListItemIcon>
                    <ListItemText primary={'Usuarios'} sx={{ opacity: (open)? 1 : 0 }} />
                  </ListItemButton>
                </Tooltip>
              }


              {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator' || (currentCustomer?.free_customer && currentUser?.rol === 'super_user')) &&
                <>
                  <Tooltip disableInteractive title={currentCustomer?.label_professors} placement="right" arrow>
                    <ListItemButton 
                      sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'professors')? 'rgb(219 234 254)' : '' }}  
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/professors") }
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'professors')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                        {professorIcon}
                      </ListItemIcon>
                      <ListItemText primary={currentCustomer?.label_professors} sx={{ opacity: (open)? 1 : 0 }} />
                    </ListItemButton>
                  </Tooltip>


                  <Tooltip disableInteractive title="Segmentos" placement="right" arrow>
                    <ListItemButton 
                      sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'segments')? 'rgb(219 234 254)' : '' }}  
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/segments") }
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'segments')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                        {tagIcon}
                      </ListItemIcon>
                      <ListItemText primary={'Segmentos'} sx={{ opacity: (open)? 1 : 0 }} />
                    </ListItemButton>
                  </Tooltip>


                  <Tooltip disableInteractive title="Sets" placement="right" arrow>
                    <ListItemButton 
                      sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'sets')? 'rgb(219 234 254)' : '' }}  
                      onMouseUp={(event)=> navigateOrOpenFunc(event, "/sets") }
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'sets')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                        {locationIcon}
                      </ListItemIcon>
                      <ListItemText primary={'Sets'} sx={{ opacity: (open)? 1 : 0 }} />
                    </ListItemButton>
                  </Tooltip>


                  {(currentUser?.rol === 'admin') && 
                    <Tooltip disableInteractive title="Emails" placement="right" arrow>
                      <ListItemButton 
                        sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'summary')? 'rgb(219 234 254)' : '' }}  
                        onMouseUp={(event)=> navigateOrOpenFunc(event, "/emails") }
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'summary')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                          {emailIcon}
                        </ListItemIcon>
                        <ListItemText primary={'Emails'} sx={{ opacity: (open)? 1 : 0 }} />
                      </ListItemButton>
                    </Tooltip>
                  }
                  
                  
                  {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') &&
                    <Tooltip disableInteractive title="Facturación" placement="right" arrow>
                      <ListItemButton 
                        sx={{ maxHeight: 35, '@media (min-width: 640px)' : { maxHeight: 48 }, justifyContent: (open)? 'initial' : 'center', px: 2.5, backgroundColor: (currentNavActive === 'billing')? 'rgb(219 234 254)' : '' }}  
                        onMouseUp={(event)=> navigateOrOpenFunc(event, "/billing") }
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: (open)? 2 : 'auto', justifyContent: 'center', color: (currentNavActive === 'billing')? 'rgb(59, 130, 246, 1)' : 'rgba(0, 0, 0, 0.45)' }}>
                          {factura10Icon}
                        </ListItemIcon>
                        <ListItemText primary={'Facturación'} sx={{ opacity: (open)? 1 : 0 }} />
                      </ListItemButton>
                    </Tooltip>
                  }
                </>
              }
            </div>


            {/* <Divider /> */}


          </ListItem>
        </Drawer>
      }



      <div className={`w-full min-w-0 ${(showNavTop) && 'mt-[56px]'}`}>
        {children}
      </div>



    </div>
  );
}