import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateCustomerSetting(
    
    $own_user_token: String!,

    $id: Int,
    $token: String,
    $customer_token: String,
    $type: String,
    $title: String,
    $small_title: String,
    $setting_group: String,
    $states: String,
    $position: Int,

    $facturation_type: Int,
    $from_localization: Boolean,
    $from_localization_panel: Boolean,
    $automated: Boolean,
    $visible: Boolean,
    $soft_hidden: Boolean,
    
  ) {
    createOrUpdateCustomerSetting(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      token: $token,
      customer_token: $customer_token,
      type: $type,
      title: $title,
      small_title: $small_title,
      setting_group: $setting_group,
      states: $states,
      position: $position,

      
      facturation_type: $facturation_type,
      from_localization: $from_localization,
      from_localization_panel: $from_localization_panel,
      automated: $automated,
      visible: $visible,
      soft_hidden: $soft_hidden,

    }) {
      
      id
      token
      customer_token
      type
      title
      small_title
      setting_group
      states
      position

      facturation_type
      from_localization
      from_localization_panel
      automated
      visible
      soft_hidden

      createdAt
      updatedAt
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
