import gql from "graphql-tag";
export default gql`
  query GetSettingRelationsByVideo(

    $own_user_token: String!,
    $own_customer_token: String!,
    $type: String!,
    $video_list: [Int],
    $customer_settings_groups: String,
    $settings_group_visibility: String,
    $start_date: String,
    $end_date: String,
    $limit: Int,
    $flip_sort: Boolean,

  ) {
    customerSettingRelations(input: { 
      
      own_user_token: $own_user_token,
      own_customer_token: $own_customer_token,

      type: $type,
      video_list: $video_list,
      customer_settings_groups: $customer_settings_groups,
      settings_group_visibility: $settings_group_visibility,
      start_date: $start_date,
      end_date: $end_date,
      limit: $limit,
      flip_sort: $flip_sort,
    
    }) {
      
      id
      customer_token
      type
      state
      user_abbreviated_name
      change_label
      own_user_abbreviated_name
      own_change_label
      observations
      language

      excel_import
      
      customer_setting_id
      own_user_id
      user_id
      video_id

      search_customer_setting_title

      updatedAt
      createdAt

      updatedAtFormated
      createdAtFormated
    }
  }
`;