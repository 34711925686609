import gql from "graphql-tag";
export default gql`
  query GetCustomerByAbreviatedName($own_user_token: String, $abbreviated_name: String!) {
    getCustomerByAbreviatedName(own_user_token: $own_user_token, abbreviated_name: $abbreviated_name) {
      
      id
      token
      icon
      abbreviated_name
      name
      language
      factura10_client_id

      label_module
      label_modules
      label_professor
      label_professors
      label_extras_module
      label_extras_module_url

      setting_upload_name_format
      setting_language_filter
      setting_sex_filter
      setting_professor_filter
      setting_initial_chyron
      setting_recording_tipo_in_list
      setting_translators_can_create_marks
      setting_tittle_is_relevant
      setting_professor_col
      setting_deffault_date_filter
      setting_professors_can_see_marks
      search_bills_to_do
      
    }
  }
`;

