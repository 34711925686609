import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext';
import { LayoutContext } from '../../contexts/LayoutContext';
import { useMutation } from '@apollo/client'

import CreateOrUpdateVideo from './../../graphql/mutations/CreateOrUpdateVideo'









export default function Factura10Info({ cellKey, keyName, video, small, fill }) {



  const { currentUser } = useContext(AuthContext)
  const { selectedNOWCell, setSelectedNOWCell } = useContext(LayoutContext)



  const [editing, setEditing] = useState(false);

  const [newSavedDate, setNewSavedDate] = useState(null);
  const [newSavedText, setNewSavedText] = useState(null);

  const defDate = (newSavedDate)? newSavedDate : (video[`${keyName}_date`])? new Date(video[`${keyName}_date`]) : null;
  const defText = (newSavedText)? newSavedText : (video[`${keyName}_text`])? video[`${keyName}_text`] : null;

  const [date, setDate] = useState(defDate);
  const [text, setText] = useState(defText);




  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)








  useEffect( () => { 
    if (selectedNOWCell === cellKey) {
      setEditing(true);
      setTimeout(() => {
        var elementTMP = document.getElementById('f10-text-' + cellKey);
        if (elementTMP) { 
          elementTMP.focus();
          elementTMP.select();
        }
      }, 100)
    }
  }, [selectedNOWCell])







  function closeFunc() {
    setDate(defDate)
    setText(defText)
    setEditing(false)
  }


  async function saveDataFunc(goToNext) {
    if (date !== defDate || text !== defText) {
      var inputTMP = {
        own_user_token: currentUser?.token,
        id: parseInt(video?.id),
      }

      inputTMP[`${keyName}_date`] = (date)? date : null;
      inputTMP[`${keyName}_text`] = (text)? text : null;

      await createOrUpdateVideo({variables: inputTMP})

      setNewSavedDate((date)? date : null)
      setNewSavedText((text)? text : null)
    }
    
    setEditing(false)
    if (goToNext) { 
      setSelectedNOWCell(parseInt(cellKey) + 1)
    }
  }





  return <div
      onClick={() => {
        if (!editing) {
          setEditing(true)
          setTimeout(() => { 
            var elementTMP = document.getElementById('f10-text-' + cellKey)
            if (elementTMP) { elementTMP.focus(); elementTMP.select(); }
          }, 100) 
        }
      }}
      className={`relative whitespace-nowrap flex flex-wrap items-center justify-center rounded hover:bg-gray-500 hover:bg-opacity-30 active:bg-opacity-60
        ${(fill)? 'w-full h-full min-w-[100px] min-h-[48px]' : ''} 
        ${(small)? 'text-[12px]' : `text-[12px]`}
      `}
    >
      {(editing)?
        <div className='w-full flex flex-col space-y-[2px] max-w-[100px]'>
          <input
            id={'f10-text-' + cellKey}
            className={`w-full h-full box-border resize-none bg-white rounded px-[4px] py-[2px] border-2 focus:outline-none border-blue-500`}
            placeholder='Número'
            defaultValue={text}
            onChange={(event) => setText(event?.target?.value)}
            onKeyDown={(e) => {
              if (e?.code === "Tab") {

              } else if (e?.code === "Enter") {
                e.preventDefault()
                saveDataFunc(false)
              } else if (e?.code === "Escape") {
                closeFunc()
              }
            }}
          />

          <input
            id={'f10-date-' + cellKey}
            type='date'
            className={`w-full h-full box-border resize-none bg-white rounded px-[2px] border-2 focus:outline-none border-blue-500`}
            placeholder='Fecha'
            defaultValue={(date && date?.getFullYear())? (date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)) : null}
            onChange={(event) => setDate(new Date(event?.target?.value))}
            onKeyDown={(e) => {
              if (e?.code === "Enter" || e?.code === "Tab") {
                e.preventDefault()
                saveDataFunc((e?.code === "Tab")? true : false)
              } else if (e?.code === "Escape") {
                closeFunc()
              }
            }}
          />
        </div>
      :
        <div className='text-[11px]'>
          <p className={`${(text)? '' : 'opacity-40'}`}>{(text)? text : '---'}</p>
          <p className={`${(date)? '' : 'opacity-40'}`}>
            {
              (date)? (("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear())
              : '--/--/--'
            }
           </p>
        </div>
      }
    </div>
}