import gql from "graphql-tag";
export default gql`
  query GetVideos($own_user_token: String!, $own_customer_token: String!) {
    videos(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      
        id
        name
        url_name

        module_id
   
        has_subtitulation
        has_transcription
        has_translation

        search_module_abbreviated_name

      }
    }
`;
