import { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { useParams, useNavigate } from "react-router-dom";

import GetUserTag from './../graphql/queries/GetUserTag'
import GetAllProfessorsByCustomer from './../graphql/queries/GetAllProfessorsByCustomer'
import CreateOrUpdateUserTag from './../graphql/mutations/CreateOrUpdateUserTag'
import RemoveUserTag from './../graphql/mutations/RemoveUserTag'

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import CustomTextField from '../components/Inputs/CustomTextField';
import CustomAutocomplete from '../components/Inputs/CustomAutocomplete';






const Segment = () => {


  

  const { id } = useParams()
  const { currentCustomer } = useContext(AuthContext)
  const { setCurrentNavActive, setSuccessAlertMsg, setErrorAlertMsg } = useContext(LayoutContext)
  const navigate = useNavigate();

  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )
  



  /* Loading vars */
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  /* Set vars */
  const [segment, setSegment] = useState(null);
  const [name, setName] = useState(null);
  const [users, setUsers] = useState(null);

  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>




  const [createOrUpdateUserTag] = useMutation(CreateOrUpdateUserTag)
  const [removeUserTag] = useMutation(RemoveUserTag)
  
  const [getSegment, { data: dataSegment, loading: loadingSegmentData }] = useLazyQuery(GetUserTag, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      id: (id)? id : null,
    }
  })

  const { data: dataProfessors, loading: loadingProfessors } = useQuery(GetAllProfessorsByCustomer, {
    variables: {
      customer_token: currentCustomer?.token,
    },
    fetchPolicy: 'no-cache' 
  })





  

  useEffect(() => {    
    setCurrentNavActive('segments')
  }, [])


  useEffect(() => { 
    if (id) {
      getSegment()
    }
  }, [id])


  useEffect(() => { 
    const setTMP = (dataSegment?.userTag)? dataSegment?.userTag : null;
    const profesTMP = (dataProfessors && dataProfessors?.professorsByCustomer?.length)? dataProfessors?.professorsByCustomer : null;
    
    setSegment((setTMP)? setTMP : null)
    setName((setTMP)? setTMP?.name : null)

    const userIDsSplit = (setTMP?.user_ids)? setTMP?.user_ids?.split(',') : null
    const userLisTMP = (userIDsSplit?.length && profesTMP?.length)? profesTMP?.map((el) => { if (userIDsSplit?.includes(el?.id)) { return { id: el?.id, name: el?.name} } })?.filter((el) => parseInt(el?.id)) : null
    setUsers((userLisTMP?.length)? userLisTMP : null)
  }, [dataSegment, dataProfessors])








  async function saveSegmentFunc() {
    setLoadingSave(true)
    setAlreadySubmited(true)

    var response = null;
    if (name && name?.replaceAll(' ', '') !== '') {
      const finalUserList = (users?.length)? users?.map((el) => el?.id)?.filter((el) => parseInt(el)) : null

      response = await createOrUpdateUserTag({
        variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null, 

          id: (parseInt(id))? parseInt(id) : null, 
          name: (name)? name : '---',
          user_ids: (finalUserList?.length)? finalUserList?.join(',') : null,
          customer_token: currentCustomer?.token,
        }
      })
      response = response?.data?.createOrUpdateUserTag;
    }

    if (response?.id) {
      navigate("/segments")
      setSuccessAlertMsg('El segmento se ha guardado correctamente.')
    } else {
      setErrorAlertMsg('Error al guardar el segmento.')
    }

    setLoadingSave(false); 
  }


  async function removeUserTagFunc() {
    setLoadingRemove(true)

    var response = null;
    if (id) {
      response = await removeUserTag({
        variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null, 

          id: (parseInt(id))? parseInt(id) : null, 
        }
      })
      response = response?.data?.removeUserTag?.status;
    }

    if (response) {
      navigate("/segments")
      setSuccessAlertMsg('El segmento se ha eliminado correctamente.')
    } else {
      setErrorAlertMsg('Error al eliminar el segmento.')
    }

    setLoadingRemove(false); 
  }



  








  return (
    <div className="px-3 sm:px-10 min-w-[400px]">

      {((id && segment) || !id)?
        !(loadingSegmentData || loadingProfessors)?
          <div>
            <div className='flex items-center justify-between py-14 pl-2'>
              <h1 className='font-bold text-4xl'>{(id)? 'Segmento' : 'Nuevo segmento'} de usuario</h1>


              <div className='flex flex-wrap justify-end space-x-2'>
                {(id) &&
                  <button
                    className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-red-500 hover:bg-red-600 active:bg-red-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
                    onClick={removeUserTagFunc}
                    disabled={(loadingSave || loadingRemove || !name)}
                  >
                    {(loadingRemove)? loadingIcon : removeIcon}
                    <p>{(loadingRemove)? 'Eliminando' : 'Eliminar'}</p>
                  </button>
                }

                <button
                  className='pl-2 pr-3 py-2 text-sm flex items-center space-x-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 active:scale-95 shadow-md disabled:opacity-50 disabled:pointer-events-none duration-200 rounded whitespace-nowrap'
                  onClick={saveSegmentFunc}
                  disabled={(loadingSave || loadingRemove || !name)}
                >
                  {(loadingSave)? loadingIcon : saveIcon}
                  <p>{(loadingSave)? 'Guardando' : 'Guardar'}</p>
                </button>
              </div>
            </div>



            <div className='flex justify-between items-start space-x-2'>
              <CustomTextField 
                id="name" 
                label="Nombre" 
                error={(alreadySubmited && (name === '' || !name))? 'Este campo es obligatorio.' : null} 
                value={(name)? name : ''} 
                onChange={event => setName((event.target.value)? event.target.value?.slice(0, 100) : null)} 
              />

              {(dataProfessors && dataProfessors?.professorsByCustomer?.length)?
                <CustomAutocomplete
                  label="Segmentos"
                  multiple

                  options={(dataProfessors?.professorsByCustomer?.length)? dataProfessors?.professorsByCustomer?.filter((el) => !users?.length || !users?.includes(el?.id)) : []}
                  value={(users?.length)? users?.map((el) => { return { id: el?.id, name: el?.name } }) : []}
                  onChange={(e, newValue) => setUsers((newValue?.length)? newValue : null)}
                />
              : null  }

            </div>


          </div>
        :
          <div className='w-full flex justify-center items-center space-x-4 text-gray-400 py-[200px]'>
            <CircularProgress  color="inherit"/>
            <p>Cargando...</p>
          </div>
      :
        <p className='text-gray-400 px-1 py-4'>Es probable que este segmento haya sido eliminado o directamente no haya existido nunca.</p>
      }

    </div>
  )
}

export default Segment
