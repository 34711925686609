import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from '@apollo/client'
import axios from 'axios'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'





const ScriptFactura10Videos = () => {

  const { currentUser, currentCustomer } = useContext(AuthContext)
  const [loading, setLoading] = useState(true);



  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)




  useEffect(() => {    
    if (currentUser && currentUser?.rol === 'admin') {
      setLoading(true)

      axios.post(`${process.env.REACT_APP_API_ROUTE}getVideos`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token }).then((response)=> {        
        const videos = (response?.data?.length)? response?.data : null

        if (videos?.length) {
          axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_videos_fix.php`, { videos: videos }).then( async (response)=> {        
            const productes = (response?.data?.length)? response?.data : null

            if (productes?.length) {
              for (var profucte of productes) {
                var input = {
                  own_user_token: currentUser?.token,
          
                  id: parseInt(profucte?.codi),
                  factura10_product_id: (parseInt(profucte?.id))? parseInt(profucte?.id) : null,
                };
          
                await createOrUpdateVideo({variables: input})
              }
            }

            setTimeout(()=> {
              setLoading(false)
            }, 1000)
          
          });

        }
      });

    }
  }, [currentUser])



  return (
    <div className='text-gray-500 font-bold p-4'>
      <p>Script Factura10 Videos:</p>
      <p>{loading? 'LOADING' : 'ENDED'}</p>
    </div>
  )
}

export default ScriptFactura10Videos
