import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext.jsx';
import { FuncContext } from '../../contexts/FuncContext'
import { LayoutContext } from '../../contexts/LayoutContext'
import axios from 'axios'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import RowHistoryMenu from '../../components/CustomTables/Components/RowHistoryMenu.jsx';

import Tooltip from '@mui/material/Tooltip';












const Correction_Bill_18_04_2024_Marks_Item = ({ list, videoKey, video, history, setHistory, og_language, language, canCheckBill, dataBills, getBillsByF10IDs }) => {

  const { currentCustomer, currentUser } = useContext(AuthContext)
  const { formatNumbersToPrint } = useContext(FuncContext)
  const { setErrorAlertMsg } = useContext(LayoutContext)
  

  const [anchorElFactura10, setAnchorElFactura10] = useState(null);
  const [totalStart, setTotalStart] = useState(0);
  const [totalEnd, setTotalEnd] = useState(0);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const euroIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M576-144q-109 0-196-62.5T258-372h-78q-15 0-25.5-10.5T144-408q0-15 10.5-25.5T180-444h62q-1-8-1.5-17t-.5-18q0-9 .5-18.5T242-516h-62q-15 0-25.5-10.5T144-552q0-15 10.5-25.5T180-588h78q35-103 122-165.5T576-816q58 0 112.5 19t99.5 56q12 10 13 25.5T791-689q-11 11-26 10.5t-27-9.5q-35-27-76-41.5T576-744q-78 0-143.5 42T335-588h205q15 0 25.5 10.5T576-552q0 15-10.5 25.5T540-516H314q-1 8-1.5 17t-.5 18q0 9 .5 18.5T314-444h226q15 0 25.5 10.5T576-408q0 15-10.5 25.5T540-372H335q32 72 97.5 114T576-216q45 0 86-14.5t76-41.5q12-9 26.5-9.5T790-271q11 11 10.5 26.5T788-219q-45 37-99.5 56T576-144Z"/></svg>





  const start_price = (
    (video?.module_id === 36)
    || (language === 'eng' && (og_language === 'esp' || !og_language))
    || (language === 'eng' && (og_language === 'esp' || !og_language))
  )? 0.14 : 0.22

  const end_price = (
    (language === 'esp' && (og_language === 'eng' || !og_language))
    || (language === 'eng' && (og_language === 'esp' || !og_language))
  )? 0.14 : 0.22




  useEffect(() => {    
    if (list?.length) {
      var totalStartTMP = 0;
      var totalEndTMP = 0;

      for (var wordObjTMP of list) {
        totalStartTMP = totalStartTMP + wordObjTMP?.words_facturated?.length ;
        totalEndTMP = totalEndTMP + wordObjTMP?.words_clear?.length;
      }

      setTotalStart(totalStartTMP)
      setTotalEnd(totalEndTMP)
    }
  }, [list])




  function loadHistory(e, video) {
    setLoadingHistory(true)
    setAnchorElFactura10(e.currentTarget)

    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_product_history.php`, { productId: (video?.factura10_product_id)? video?.factura10_product_id : video?.producte_id }).then( async (response) => {

        if (response?.data?.concepts && Object.keys(response?.data?.concepts)?.length) {
            setHistory(response?.data)
            if (canCheckBill) { getBillsByF10IDs() }
        } else {
            setHistory(null)
            setErrorAlertMsg('No se han encontrado facturas de este vídeo.')
            setAnchorElFactura10(null)
        }

        setLoadingHistory(false)
    });
  }


  






  return (
    <div className='bg-gray-100 rounded p-2'>


      <div className='w-full flex items-center space-x-2 text-gray-300'>
        <div className='w-full min-w-[280px] max-w-[280px] text-left flex items-center space-x-2'>
          {(canCheckBill)?
            <button 
              onClick={(e) => loadHistory(e, video)}
              className={`rounded p-[2px] duration-100 bg-gray-200 hover:bg-gray-400 hover:bg-opacity-30 active:bg-opacity-60 ${(video?.factura10_product_id)? 'text-blue-500' : 'opacity-20'}`}
            >
              <p className='scale-90'>{(loadingHistory)? loadingIcon : euroIcon}</p>
            </button>
          : null }


          <a 
            href={(process.env.REACT_APP_ROUTE + currentCustomer?.abbreviated_name + '/' + ((video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra')  + '/' + video?.url_name)}
          >
            {video?.name}
          </a>


          {(video?.factura10_product_id)? 
            <TransitionGroup appear={true}>
              {(history?.concepts && Object.keys(history?.concepts)?.length || anchorElFactura10)?
                <CSSTransition timeout={200} classNames="fade" >

                  <RowHistoryMenu
                    anchorEl={anchorElFactura10}
                    setAnchorEl={setAnchorElFactura10}
                    history={history}
                    documents={(dataBills?.getBillsByF10IDs?.length)? dataBills?.getBillsByF10IDs : null}
                    actualBillId={null}

                    loading={loadingHistory}
                    center={true}
                  />

                </CSSTransition>
              : null }
            </TransitionGroup>
          : null }
        </div>

        <p> / </p>

        <div className='w-full text-gray-500'>
          <p>Precio facturado</p>
        </div>

        <p> / </p>

        <div className='w-full text-gray-500'>
          <p>Precio real</p>
        </div>

        <p> / </p>

        <div className='w-full min-w-[80px] max-w-[80px] text-gray-500'>
          <p>Diferencia</p>
        </div>
      </div>




      <hr className='my-2 border-gray-300 opacity-50 border-[1px] rounded' />




      {list?.map(wordObj => {
        const markStartPrice = wordObj?.words_facturated?.length * start_price;
        const markEndPrice = wordObj?.words_clear?.length * end_price;
        const diffInWords = (wordObj?.words_facturated?.length !== wordObj?.words_clear?.length);

        return <div 
          key={'word-' + wordObj?.id + '-' + wordObj?.name + '-' + videoKey} 
          className='flex justify-between text-center text-gray-300 space-x-2 items-center text-xs whitespace-nowrap hover:bg-gray-200 px-1 rounded'
        >
          <p className={`w-full min-w-[280px] max-w-[280px] text-left ${(diffInWords)? 'text-gray-800' : 'text-gray-400'}`}>
            {wordObj?.type} {(!wordObj?.type?.includes('Título'))? ' / ' + wordObj?.name?.split(' / ')[0] : null}
            <span className='opacity-60'>
              {(wordObj?.time)? ' (' + wordObj?.time + ')' : ''}
              {/* {(currentUser?.id === 3)? ' #' + wordObj?.id : ''} */}
            </span>
          </p>
          <p> / </p>

          <div className={`w-full flex justify-between space-x-4 px-1 ${(markStartPrice !== markEndPrice)? 'text-gray-800' : ''}`}>
            <Tooltip disableInteractive title={(currentUser && !currentUser?.rol?.includes('user') && wordObj?.words_facturated)? wordObj?.words_facturated?.join(' | ') : ''} placement="bottom" arrow>
              <div className='flex space-x-1'>
                <p className='text-gray-400 cursor-pointer'>{start_price} € / palabra x </p>
                <p className={`cursor-pointer ${(diffInWords)? 'text-gray-700' : 'text-gray-400'}`}>{wordObj?.words_facturated?.length} palabras {(diffInWords)? '+ errores' : ''}</p>
              </div>
            </Tooltip>
            <p>{formatNumbersToPrint(markStartPrice, 2)} €</p>
          </div>

          <p> / </p>

          <div className={`w-full flex justify-between space-x-4 px-1 ${(markStartPrice !== markEndPrice)? 'text-gray-800' : ''}`}>
            <Tooltip disableInteractive title={(currentUser && !currentUser?.rol?.includes('user') && wordObj?.words_clear)? wordObj?.words_clear?.join(' | ') : ''} placement="bottom" arrow>
              <div className='flex space-x-1'>
                <p className='text-gray-400 cursor-pointer'>{end_price} € / palabra x </p>
                <p className={`cursor-pointer ${(diffInWords)? 'text-gray-700' : 'text-gray-400'}`}>{wordObj?.words_clear?.length} palabras</p>
              </div>
            </Tooltip>
            <p>{formatNumbersToPrint(markEndPrice, 2)} €</p>
          </div>

          <p> / </p>

          <p className={`w-full min-w-[80px] max-w-[80px] text-right ${(wordObj?.price_diff)? 'text-gray-800' : 'text-gray-300'}`}>
            {(wordObj?.price_diff > 0)? ' + ' : ''} {formatNumbersToPrint(wordObj?.price_diff, 2)} €
          </p>
        </div>
      })}




      <hr className='my-2 border-gray-300 opacity-50 border-[1px] rounded' />




      <div 
        className='flex justify-between text-center text-gray-300 space-x-2 items-center text-xs whitespace-nowrap hover:bg-gray-200 px-1 rounded'
      >
        <p className='w-full min-w-[280px] max-w-[280px] text-left text-gray-400'>Total</p>
        <p> / </p>

        <div className='w-full flex justify-between space-x-4 text-gray-800 px-1'>
          <p className='text-gray-400'>{start_price} € / palabra x {totalStart} palabras</p>
          <p>{formatNumbersToPrint(totalStart * start_price, 2)} €</p>
        </div>

        <p> / </p>

        <div className='w-full flex justify-between space-x-4 text-gray-800 px-1'>
          <p className='text-gray-400'>{end_price} € / palabra x {totalEnd} palabras</p>
          <p>{formatNumbersToPrint(totalEnd * end_price, 2)} €</p>
        </div>

        <p> / </p>

        <p 
        className={`w-full min-w-[100px] max-w-[100px] text-right text-gray-800`}
        >
          {(((totalEnd * end_price) - (totalStart * start_price)) > 0)? ' + ' : ''}
          {formatNumbersToPrint((totalEnd * end_price) - (totalStart * start_price), 2)} €
        </p>
      </div>
    </div>
  )
}

export default Correction_Bill_18_04_2024_Marks_Item
