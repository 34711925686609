import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';
import axios from 'axios'

import Diff from "./DiffViewer/Diff";
import TextLogs from './TextLogs';

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';




export default function TitleEditor({ 

  video,
  setVideo,
  
  state,
  motionState,

  mode,
  small,
  hasBackground,
  disabled

}) {
  

  
  const { currentCustomer, currentUser } = useContext(AuthContext)
  const { textDifferenceCounterFunc, remove_tags, format_texts, getRevNumberOfWordsFunc } = useContext(FuncContext)




  const [showEditTitle, setShowEditTitle] = useState(false);
  const [editTitle, setEditTitle] = useState((video?.title)? video.title : null);
  const [editTitleENG, setEditTitleENG] = useState((video?.title_eng)? video.title_eng : null);

  const [hasTextLogs, setHasTextLogs] = useState(false);
  const [diffOldText, setDiffOldText] = useState((video?.old_title)? video?.old_title : video?.title);
  const [diffNewText, setDiffNewText] = useState((video?.title)? video?.title : null);

  const [isCorrecting, setIsCorrecting] = useState(false);
  const [showCorrectionChanges, setShowCorrectionChanges] = useState(false);
  const [differenceCounter, setDifferenceCounter] = useState((parseInt(video?.old_title_changes))? parseInt(video?.old_title_changes) : 0);

  const [loadingRemoveLastCorrection, setLoadingRemoveLastCorrection] = useState(false);


  const showTitle = (!currentCustomer?.setting_tittle_is_relevant)? true : false;


  /* Icon vars */
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const spellCheckIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m544-246 196-196q11-11 25.5-11t25.5 11q11 11 11 25.5T791-391L595-195q-11 11-24 16t-27 5q-14 0-27-5t-24-16l-93-94q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l93 93ZM271-507l-35 98q-4 11-14 18t-22 7q-20 0-32-17t-4-36l150-401q5-12 15-19t23-7h30q13 0 23 7t15 19l151 403q7 19-4.5 35T535-384q-13 0-23-7.5T498-411l-33-96H271Zm25-69h143l-70-199h-4l-69 199Z"/></svg>
  const seeChangesIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M432-336q-20 0-39-3.5t-37-9.5q-14-5-20-19t1-28q7-14 21-19t29 0q11 3 22 5t23 2q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119l28-27q11-11 25.5-10t25.5 12q11 11 11 25.5T343-550l-86 85q-11 11-25 11t-25-11l-86-86q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l20 20q2-98 71.5-166.5T432-816q100 0 170 70t70 170q0 40-12.5 76T625-434l214 214q11 11 11 25t-11 25q-11 11-25.5 11T788-170L574-383q-30 22-66 34.5T432-336Z"/></svg>






  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)





  
  useEffect(() => { 
    var oldTextTMP = (isCorrecting)? ((video?.old_title)? video?.old_title : video?.title) : diffOldText;
    var newTextTMP = (isCorrecting)? editTitle : diffNewText;
    
    if (oldTextTMP && newTextTMP && remove_tags(format_texts(oldTextTMP?.replace(/\n/g, "<br />"))) && remove_tags(format_texts(newTextTMP?.replace(/\n/g, "<br />")))) {
      setDifferenceCounter(textDifferenceCounterFunc(oldTextTMP, newTextTMP))
    }
  }, [video, isCorrecting, editTitle, diffOldText, diffNewText])


  useEffect( () => { 
    if (video) {
      setEditTitle((video?.title)? video.title : null)
      setEditTitleENG((video?.title_eng)? video.title_eng : null)
    }
  }, [video])


  useEffect( () => { 
    if (showEditTitle) {
      setTimeout(()=>{
        document.getElementById('title').focus()
      }, 50)
    } else {
      setEditTitle((video?.title)? video.title : null)
      setEditTitleENG((video?.title_eng)? video.title_eng : null)
    }
  }, [showEditTitle])









  
  async function saveCorrectionTitleFunc() {

    if (video?.id) {
      var input = {
        own_user_token: currentUser?.token,

        id: parseInt(video?.id),
        title: (editTitle && editTitle?.replaceAll(' ', '') !== '')? editTitle?.trim() : null,
        title_change_label: currentUser?.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)),
        old_title: (video?.old_title)? video?.old_title?.trim() : video?.title?.trim(),
        old_title_changes: (parseInt(differenceCounter))? parseInt(differenceCounter) : null,
        revision_state: (currentUser?.rol?.includes('translator'))? 2 : 1
      };


      var hasChanges = (((video?.old_title)? video?.old_title?.trim() : video?.title?.trim()) && remove_tags(format_texts(((video?.old_title)? video?.old_title?.trim() : video?.title?.trim())?.replace(/\n/g, "<br />"))) === remove_tags(format_texts(editTitle?.trim()?.replace(/\n/g, "<br />"))))? false : true;
      if (!hasChanges) { input['old_title'] = null; input['old_title_changes'] = null; }
      if (showTitle && hasChanges && (state === 3 || motionState > 1)) { 
        input['state'] = 2;
        input['state_change_label'] = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null; 
        input['motion_state'] = 1;
        input['motion_state_change_label'] = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null; 
        input['extra_facturation_correction'] = (parseInt(video?.extra_facturation_correction))? parseInt(video?.extra_facturation_correction) + 1 : 1;
      }


      var videoTMP = await createOrUpdateVideo({variables: input})
      if (videoTMP?.data?.createOrUpdateVideo) {
        setVideo(videoTMP?.data?.createOrUpdateVideo)
      }

      
      setIsCorrecting(false)
      setShowEditTitle(false)
    }
  }




  async function saveTitleFunc() {
    if (video?.id) {

      const change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;
      var input = {
        own_user_token: currentUser?.token,

        id: parseInt(video?.id),
        title: (editTitle && editTitle?.replaceAll(' ', '') !== '')? editTitle?.trim() : null,
        title_change_label: change_label,
        old_title: null,
        old_title_changes: 0,
        title_eng: (editTitleENG && editTitleENG?.replaceAll(' ', '') !== '')? editTitleENG?.trim() : null,
        title_eng_change_label: (editTitleENG !== video?.title_eng)? change_label : video?.title_eng_change_label,

        revision_state: (currentUser?.rol?.includes('translator'))? 1 : 0,
        revision_state_change_label: change_label,
        trad_done: (currentUser?.rol?.includes('translator'))? true : false,
        trad_done_change_label: change_label,
      };

      const hasChanges = (((video?.old_title)? video?.old_title?.trim() : video?.title?.trim()) && remove_tags(format_texts(((video?.old_title)? video?.old_title?.trim() : video?.title?.trim())?.replace(/\n/g, "<br />"))) === remove_tags(format_texts(editTitle?.trim()?.replace(/\n/g, "<br />"))))? false : true;
      if (showTitle && hasChanges && (state === 3 || motionState > 1)) { 
        input['state'] = 2; 
        input['state_change_label'] = change_label; 
        input['motion_state'] = 1;
        input['motion_state_change_label'] = change_label; 
        input['extra_mark_facturation'] = (parseInt(video?.extra_mark_facturation))? parseInt(video?.extra_mark_facturation) + 1 : 1;
      

        if (video?.endDate && new Date() > new Date(video?.endDate) && !video?.is_translation_of && input['state'] === 2 && video?.state !== 2 && video?.motion_state > 1 && !currentUser?.rol?.includes('translator')) {
          const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}getVideoLocalizations`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, video_id: video?.id })
          if (response?.data?.length) {
            for (var tradVideo of response?.data) {
              await createOrUpdateVideo({variables: {
                own_user_token: currentUser?.token,

                id: parseInt(tradVideo?.id),
                state: 2,
                state_change_label: change_label,
                motion_state: 1,
                motion_state_change_label: change_label,
                extra_mark_facturation: (parseInt(tradVideo?.extra_mark_facturation))? parseInt(tradVideo?.extra_mark_facturation) + 1 : 1,
              }})
            }
          }
        }
      }


      const videoTMP = await createOrUpdateVideo({variables: input})
      if (videoTMP?.data?.createOrUpdateVideo) {
        setVideo(videoTMP?.data?.createOrUpdateVideo)
      }


      setIsCorrecting(false)
      setShowEditTitle(false)
    }
  }






  async function removeLastCorrectionFunc(historyObj) {
    if (historyObj?.search_is_correction && historyObj?.search_last_motion_state && historyObj?.search_last_state) {
      setLoadingRemoveLastCorrection(true)
      const change_label = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;

      var videoTMP = await createOrUpdateVideo({variables: {
        own_user_token: currentUser?.token,

        id: parseInt(video?.id),
        state: historyObj?.search_last_state,
        state_change_label: change_label,
        motion_state: historyObj?.search_last_motion_state,
        motion_state_change_label: change_label,
        extra_facturation_correction: (parseInt(video?.extra_mark_facturation))? parseInt(video?.extra_mark_facturation) - 1 : 0,
        setting_remove_correction: true,
      }}) 
      if (videoTMP?.data?.createOrUpdateVideo) { setVideo(videoTMP?.data?.createOrUpdateVideo) }
     

      setTimeout(() => {
        setShowCorrectionChanges(false)
        setLoadingRemoveLastCorrection(false)
      }, 300 )
    }
  }

  
  


  return (
    <div className={`w-full rounded ${(hasBackground)? 'border border-gray-300 bg-gray-300 bg-opacity-50' : ''}`}>
      {(!currentUser?.professor)? 
        <>
          <Collapse orientation="vertical" in={(isCorrecting || showCorrectionChanges)? true : false} timeout={300}>
            <div className={`space-y-1 mt-1 p-2`}>
              
              <div className={`w-full text-base px-3 py-2 flex justify-between items-start space-x-3 border border-gray-400 border-opacity-50 bg-gray-400 bg-opacity-30 test-spacing duration-200 rounded`}>
                <Diff
                  string1={remove_tags(format_texts(((isCorrecting)? video?.title : diffOldText)?.replace(/\n/g, "<br />")))}
                  string2={remove_tags(format_texts(((isCorrecting)? editTitle : diffNewText)?.replace(/\n/g, "<br />")))}
                  mode="words"
                />

                {(!currentUser?.rol?.includes('user') && (diffOldText || hasTextLogs) && showCorrectionChanges) &&
                  <Tooltip disableInteractive title={"Ocultar cambios de la corrección"} placement="bottom" arrow>
                    <button 
                      className={`flex items-center justify-center p-1 border border-opacity-50 hover:bg-opacity-50 duration-300 rounded ${(showCorrectionChanges)? 'bg-blue-500 bg-opacity-80 text-white border-blue-600' : 'hover:text-blue-500 bg-gray-300 border-gray-400'}`}
                      onMouseUp={() => setShowCorrectionChanges(!showCorrectionChanges)}
                    >
                      {seeChangesIcon}
                    </button>
                  </Tooltip>
                }
              </div>
              

              <div className='flex w-full justify-between space-x-1'>
                <TextLogs
                  showCorrectionChanges={showCorrectionChanges}
                  language={video?.language}
                  video_id={video?.id}

                  setHasTextLogs={setHasTextLogs}
                  setDiffNewText={setDiffNewText}
                  setDiffOldText={setDiffOldText}

                  removeLastCorrectionFunc={removeLastCorrectionFunc}
                  loadingRemoveLastCorrection={loadingRemoveLastCorrection}
                />

                <div className='flex items-center space-x-1 text-xs px-2 py-1 border border-gray-400 border-opacity-50 bg-gray-400 bg-opacity-30 rounded whitespace-nowrap'>
                  <p>{((isCorrecting)? ((video?.old_title)? video?.old_title : video?.title) : diffOldText)? getRevNumberOfWordsFunc((isCorrecting)? ((video?.old_title)? video?.old_title : video?.title) : diffOldText) : 0} palabras</p>
                  <p>y</p>
                  <p>{(differenceCounter)? differenceCounter : 0} diferencias</p>
                </div>
              </div>
            </div>
          </Collapse>

          <Collapse orientation="vertical" in={(showEditTitle)? true : false} timeout={300}>
            <div className='w-full flex items-center space-x-2 p-2'>
              <div className={`w-full flex flex-wrap items-center space-y-2`}>
                <TextField 
                  value={(editTitle)? editTitle : ''}
                  onChange={event => setEditTitle(event.target.value)}
                  id="title"
                  label="Título"
                  variant="outlined"
                  className='w-full'
                  sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 30, backgroundColor: 'rgb(209 213 219)' }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                  InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}

                  onKeyDown={(event) => {
                    if (!currentUser?.professor) {
                      if((event.code === 'Enter')) {
                        if (isCorrecting) {
                          saveCorrectionTitleFunc()
                        } else {
                          saveTitleFunc()
                        }
                      } else if (event.code === 'Escape') {
                        setIsCorrecting(false)
                        setShowEditTitle(false)
                      }
                    }
                  }}
                />

                {/* <ReactQuillSimpleImput 
                  id={"title_text_"+video?.id} 
                  placeholder="Título"
                  singleLine={true}
                  value={(editTitle)? editTitle : ''}

                  onChange={(value) => {
                    setEditTitle(value)
                  }} 
                  onKeyDown={(event) => {
                    if((event.code === 'Enter')) {
                      if (isCorrecting) {
                        saveCorrectionTitleFunc()
                      } else {
                        saveTitleFunc()
                      }
                    } else if (event.code === 'Escape') {
                      setIsCorrecting(false)
                      setShowEditTitle(false)
                    }
                  }}
                /> */}
              </div>


              <button
                className='flex items-center justify-center p-1 bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-blue-500 duration-300 rounded'
                onMouseUp={() => (isCorrecting)? saveCorrectionTitleFunc() : saveTitleFunc()}
              >
                {saveIcon}
              </button>

              <button
                className='flex items-center justify-center p-1 bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-red-500 duration-300 rounded'
                onMouseUp={() => { setShowEditTitle(false); setIsCorrecting(false) }}
              >
                {cancelIcon}
              </button>
            </div>
          </Collapse>
        </>
      : null }



      <Collapse orientation="vertical" in={(!showEditTitle && !showCorrectionChanges)? true : false} timeout={300}>
        <div 
          className={`
            w-full p-2 flex justify-between space-x-1 duration-300 
            ${!video?.title && 'opacity-40'}
            ${(mode !== 'translation' && !currentUser?.professor && currentUser?.rol !== 'facturator')? 'hover:text-blue-500 cursor-pointer' : null}
            ${(small)? 'items-center text-md' : 'items-start text-xl font-bold text-gray-500'}
            ${(currentCustomer?.setting_tittle_is_relevant && !small && video?.title)? 'text-gray-700' : ''}
          `} 
        >
          <Tooltip disableInteractive title={(!currentUser?.professor && !currentUser?.rol?.includes('user') && video?.title_change_label) && 'Título cambiado por ' + video?.title_change_label} placement="bottom" arrow>
            <div 
              className='w-full'
              onMouseUp={()=> {
                if (mode !== 'translation' && currentUser?.rol !== 'facturator' && !currentUser?.professor) {
                  if (currentUser?.rol?.includes('translator')) {
                    setShowEditTitle(true)
                    setIsCorrecting(true)
                  } else {
                    setShowEditTitle(true)
                    setIsCorrecting(false)
                  } 
                }
              }}
            >
              <h1 
                className='pl-[3px] py-[3px] font-normal'
                dangerouslySetInnerHTML={{__html: 
                  (video?.title)? video?.title
                  : 'Sin título'
                }}
              ></h1>
            </div>
          </Tooltip>

          
          <div className='flex flex-wrap justify-end w-[80px] min-w-[80px]'>
            {(!currentUser?.rol?.includes('user') && showTitle && !disabled && hasTextLogs) &&
              <Tooltip disableInteractive title={"Ver cambios de la corrección"} placement="bottom" arrow>
                <button 
                  className={`flex items-center justify-center p-1 m-[2px] border border-opacity-50 hover:bg-opacity-50 duration-300 rounded ${(showCorrectionChanges)? 'bg-blue-500 bg-opacity-80 text-white' : 'hover:text-blue-500 bg-gray-300 border-gray-400'}`}
                  onMouseUp={() => setShowCorrectionChanges(!showCorrectionChanges)}
                >
                  {seeChangesIcon}
                </button>
              </Tooltip>
            }


            {(!currentUser?.professor) && 
              <button
                className='flex items-center justify-center p-1 m-[2px] bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-blue-500 duration-300 rounded'
                onMouseUp={() => { setShowEditTitle(true); setIsCorrecting(false); }}
              >
                {editIcon}
              </button>
            }


            {(showTitle && !disabled && video?.title && (currentUser?.rol?.includes('translator') || currentUser?.rol === 'admin'))?
              <button
                className='flex items-center justify-center p-1 m-[2px] bg-gray-300 border border-gray-400 border-opacity-50 hover:bg-opacity-50 hover:text-blue-500 duration-300 rounded'
                onMouseUp={() => { setShowEditTitle(true); setIsCorrecting(true); }}
              >
                {spellCheckIcon}
              </button>
            : null }
          </div>
        </div>
      </Collapse>
    </div>
  )
}