import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'

import HomeCharts from '../components/Charts/HomeCharts'
import MotionUserChart from '../components/Charts/MotionUserChart'


import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RecordingDates from '../components/Charts/RecordingDates'
import axios from 'axios'








const Charts = () => {


  const { currentUser, currentCustomer, showNavLeft } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)



  /* Colors */
  const chartColors = [
    "#8c6abd", /* Dark Purple */
    "#8884d8", /* Dark Blue */
    "#7c96e6", /* Clear Blue */
    "#84add8", /* Blue */
    "#6ccba4", /* Turques */
    "#6ccb79", /* Green */
    "#afd884", /* Light Green */
    "#edd74e", /* Yellow */
    "#d8b484", /* Orange */
    "#d8a084", /* Hot Orange */
    "#d37d79", /* Red */
    "#d37992", /* Violet */
    "#da7cb2", /* Pink */
    "#cf79d3", /* Clear Purple */


    "#8c6abd", /* Dark Purple */
    "#8884d8", /* Dark Blue */
    "#7c96e6", /* Clear Blue */
    "#84add8", /* Blue */
    "#6ccba4", /* Turques */
    "#6ccb79", /* Green */
    "#afd884", /* Light Green */
    "#edd74e", /* Yellow */
    "#d8b484", /* Orange */
    "#d8a084", /* Hot Orange */
    "#d37d79", /* Red */
    "#d37992", /* Violet */
    "#da7cb2", /* Pink */
    "#cf79d3", /* Clear Purple */


    "#b179d3", /* Purple */
  ];




  const [tab, setTab] = useState((parseInt(localStorage.getItem(currentCustomer?.token+'-chart-tab')))? parseInt(localStorage.getItem(currentCustomer?.token+'-chart-tab')) : 3);

  const [hasVideos, setHasVideos] = useState(false);
  const [baseLoading, setBaseLoading] = useState(true);


  /* Icon vars */
  const goBackIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='currentColor'><path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;




  useEffect(() => {    
    setCurrentNavActive('charts')

    checkIfThereAreVideosFunc()
  }, [])



  async function checkIfThereAreVideosFunc() {
    const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}checkIfThereAreVideos`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token })
    setHasVideos((response?.data)? true : false)

    setTimeout(() => {
      setBaseLoading(false)
    }, 300)
  }





  
  return (
    <div className="px-5 md:px-10 min-w-[400px]">


      <div className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl py-14 pl-2'>Estadísticas</h1>

        {(currentUser?.rol?.includes('user') && !(currentCustomer?.free_customer && currentUser?.rol === 'super_user')) &&
          <div className='flex'>
            <Tooltip disableInteractive title="Volver a los vídeos" placement="bottom" arrow>
              <a href="/videos">
                <IconButton color="primary" size="medium">
                  {goBackIcon}
                </IconButton>
              </a>
            </Tooltip>
          </div>
        }
      </div>

      

      <div className='px-2'>
        <div className="relative w-full rounded bg-gray-50 border border-gray-300 mb-[45px]">
          <div className='w-full pr-[130px] border-b bg-gray-100 border-gray-300'>
            <Tabs
              value={tab}
              onChange={(event, newValue) => { setTab(newValue); localStorage.setItem(currentCustomer?.token+'-chart-tab', newValue) }}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
              variant="scrollable"
              scrollButtons="auto"

              sx={{ "& .Mui-selected": { color: 'rgb(25, 118, 210) !important' } }}
            >
              <Tab value={3} label={`Minutos por ${currentCustomer?.label_professor?.toLowerCase()} y ${currentCustomer?.label_module?.toLowerCase()}`} />
              <Tab value={1} label={`Vídeos por ${currentCustomer?.label_module?.toLowerCase()}`} />
              <Tab value={2} label={`Minutos por ${currentCustomer?.label_module?.toLowerCase()}`} />
              {(currentUser?.rol === 'admin')? <Tab value={4} label="Motions por usuario" /> : null}
              <Tab value={5} label="Fechas de grabación" />

            </Tabs>
          </div>


          {(hasVideos)?
            (
              (tab === 1 || tab === 2 || tab === 3)? <HomeCharts tab={tab} chartColors={chartColors} />
              : (tab === 4)? <MotionUserChart chartColors={chartColors} />
              : (tab === 5)? <RecordingDates chartColors={chartColors} />
              : null
            )
          : (baseLoading)?
            <div className='flex items-center space-x-2 px-4 py-3 text-sm text-gray-400'>
              {loadingIcon}
              <p>Cargando datos...</p>
            </div>
          :
            <p className='text-sm text-gray-400 px-4 py-3'>No se han encontrado vídeos con los que generar estadísticas.</p>
          }
        </div>
      </div>


    </div>
  )
}

export default Charts
