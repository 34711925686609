import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateMessage(

    $own_user_token: String!,

    $id: Int,
    $text: String,
    $customer_token: String,

    $has_files: Boolean,

    $from_user_id: Int,
    $to_user_id: Int,
    $to_video_id: Int,
    $tagged_user_ids: String,
    $tagged_user_names: String,

    $new_date: Date,

  ) {
    createOrUpdateMessage(input: { 
      
      own_user_token: $own_user_token,
      
      id: $id,
      text: $text,
      customer_token: $customer_token,
      
      has_files: $has_files,

      from_user_id: $from_user_id,
      to_user_id: $to_user_id,
      to_video_id: $to_video_id,
      tagged_user_ids: $tagged_user_ids,
      tagged_user_names: $tagged_user_names,

      new_date: $new_date,
    
    }) {
      
      id
      text
      customer_token

      has_files

      from_user_id
      to_user_id
      to_video_id
      tagged_user_ids
      tagged_user_names
      
      createdAt
      updatedAt

      from_user {
        id
        name
        profile_picture
      }

      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
