import gql from "graphql-tag";
export default gql`
  query GetVideoMarkComments($own_user_token: String!, $video_mark_id: Int!) {
    getVideoMarkComments(own_user_token: $own_user_token, video_mark_id: $video_mark_id) {

      id
      text
      filename
      video_mark_id
      video_comment_id
      order
      user_id
      
      state
      state_change_label

      is_correction
      is_reply_to_correction
      
      public
      notificate_to
      notificate_to_label

      search_guest_data

      createdAt
      updatedAt

      user {
        id
        name
        email
        profile_picture
        language
        rol
        visible
        enabled

        original_customer_token
        navigation_customer_tokens

        createdAt
        updatedAt
        
        
        from_customer {
          token
          icon
          abbreviated_name
          name
        }
      }

      notificate_to_users {
        id
        name
      }

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
