import { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLazyQuery, useMutation } from '@apollo/client'

import Factura10GetAllVideos from '../graphql/queries/Factura10GetAllVideos'
import Factura10GetAllVideoMarks from '../graphql/queries/Factura10GetAllVideoMarks'

import UpdateVideoState from './../graphql/mutations/UpdateVideoState'
import UpdateVideoMarkState from './../graphql/mutations/UpdateVideoMarkState'
import UpdateUserState from './../graphql/mutations/UpdateUserState'
import UpdateVideoChyronState from './../graphql/mutations/UpdateVideoChyronState'









const ScriptFinalizateAll = () => {

  const { currentUser, currentCustomer, getJsonString } = useContext(AuthContext)
  const [videos, setVideos] = useState(null);
  const [marks, setMarks] = useState(null);
  const [start, setStart] = useState(false);
  const [loading, setLoading] = useState(false);


  

  const [updateVideoState] = useMutation(UpdateVideoState)
  const [updateVideoMarkState] = useMutation(UpdateVideoMarkState)
  const [updateUserState] = useMutation(UpdateUserState)
  const [updateVideoChyronState] = useMutation(UpdateVideoChyronState)
  
  const [getVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery(Factura10GetAllVideos, { 
    fetchPolicy: 'network-only',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      module: JSON.stringify([1, 31]),
      localizations: 1,
    }
  })

  const [getVideoMarks, { data: dataVideoMarks, loading: loadingVideoMarks }] = useLazyQuery(Factura10GetAllVideoMarks, {
    fetchPolicy: 'network-only',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      
      module: JSON.stringify([1, 31]),
      hidde_disabled: true,
    }
  })

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;



  




  useEffect(() => {
    getVideos()
    getVideoMarks()
  }, [])
  

  useEffect(() => {
    const filtredVideosTMO = (dataVideos?.getCustomerVideos?.length)? dataVideos?.getCustomerVideos?.filter((el) => parseInt(el?.module_id) === 1 || parseInt(el?.module_id) === 31) : null
    if (filtredVideosTMO?.length) {
      setVideos(filtredVideosTMO)
    } else {
      setVideos(null)
    }

    if (dataVideoMarks?.videoMarksForMotions?.length) {
      setMarks(dataVideoMarks?.videoMarksForMotions)
    } else {
      setMarks(null)
    }
  }, [dataVideos, dataVideoMarks])


  useEffect(() => {
    if (start) {
      startLoop() 
    }
  }, [start])






  async function startLoop() {
    var count = 0;

    for (var video of videos) {
      if (!start) { break; }

      if (parseInt(video?.module_id) === 1 || parseInt(video?.module_id) === 31) {
        count++;
        setLoading(`Finalizating vídeo n${count} - ${video?.name} from module ${video?.search_module_abbreviated_name} (${video?.module_id})...`)
        const videoMarks = marks?.filter((el) => parseInt(el?.video_id) === parseInt(video?.id))

        await markVideoAsFinalizatedFunc(video, videoMarks)
      }
    }

    setTimeout(() => {
      setLoading(false)
      setStart(false)
    }, 2000)
  }

  

  async function markVideoAsFinalizatedFunc(video, videoMarks) {
    const stateNumCorrectionTMP = 2;
    const motionStateNumCorrectionTMP = 1;
    const stateNumTMP = 3;
    const motionStateNumTMP = 5;
    const stateNumProfTMP = 1;
    const motionStateNumProfTMP = 3;

    const professorsTMP = (video?.professor && video?.extraProfessors?.length)? [video?.professor, ...video?.extraProfessors] : (video?.professor)? [video?.professor] : (video?.extraProfessors?.length)? [...video?.extraProfessors] : [];
    const changeLabel = (currentUser)? currentUser.name + ' el ' + (("0" + new Date().getDate()).slice(-2) + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + new Date().getFullYear() + ' a las ' + ("0" + new Date().getHours()).slice(-2) + ':' + ("0" + new Date().getMinutes()).slice(-2)) : null;
    const chyronStates = (video?.chyron_states)? getJsonString(video?.chyron_states, true) : null;


    /* TITLE */
    if (video?.title && video?.state < 3) {
      const hasTextChange = (video?.old_title)? true : false;
      await updateVideoState({variables: {
        own_user_token: currentUser?.token, 
        id: parseInt(video.id),

        state: (hasTextChange)? stateNumCorrectionTMP: stateNumTMP,
        state_change_label: changeLabel,
        motion_state: (hasTextChange)? motionStateNumCorrectionTMP: motionStateNumTMP,
        motion_state_change_label: changeLabel,
        extra_facturation_correction: (hasTextChange)? 1 : null,
      }});
    }

    
    /* CHYRONS */
    if (professorsTMP?.length) {
      for (var professorTMP of professorsTMP) {
        var positionsTMP = (professorTMP?.positions)? getJsonString(professorTMP?.positions , true) : null;
        const positionTMP = (positionsTMP && positionsTMP[video?.language])? positionsTMP[video?.language] : null;

        if (positionTMP && positionTMP?.text) {
          var professorStateNumTMP = (parseInt(positionTMP?.state))? parseInt(positionTMP?.state) : 1;
          const hasAdditionalStates = (chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professorTMP?.id) && el?.language === video?.language )?.length)? true : false;
          const additionalState = (hasAdditionalStates)? chyronStates?.filter((el) => parseInt(el?.professor_id) === parseInt(professorTMP?.id) && el?.language === video?.language )[0] : null;
          if (professorStateNumTMP !== 2 && additionalState) {
            professorStateNumTMP = (parseInt(additionalState?.state))? parseInt(additionalState?.state) : 1;
          }
  
          if (/* !positionTMP?.state */ (!positionTMP?.state || parseInt(positionTMP?.state) !== 2) && professorStateNumTMP < 3) {
            const hasTextChange = (positionTMP && positionTMP?.old_text)? true : false;
  
            await updateVideoChyronState({variables: { 
              own_user_token: currentUser?.token,  
              id: parseInt(video?.id), 
              professor_id: parseInt(professorTMP?.id),
              language: (video?.language)? video?.language : 'esp',
              action: (hasTextChange)? 'remove' : 'add',
  
              state: stateNumTMP,
              state_change_label: changeLabel,
              motion_state: motionStateNumTMP,
              motion_state_change_label: changeLabel,
            }});
            
            if (!positionTMP?.state || parseInt(positionTMP?.state) < stateNumProfTMP) {
              positionsTMP[video?.language] = {
                ...positionsTMP[video?.language],
  
                state: (hasTextChange)? stateNumCorrectionTMP: stateNumProfTMP,
                state_change_label: changeLabel,
                motion_state: (hasTextChange)? motionStateNumCorrectionTMP: motionStateNumProfTMP,
                motion_state_change_label: changeLabel,
              }
  
              var input = {
                own_user_token: currentUser?.token, 
                own_customer_token: currentCustomer?.token,
        
                id: parseInt(professorTMP?.id),
                positions: (positionsTMP)? JSON.stringify(positionsTMP) : null,
              };
  
              await updateUserState({variables: input});
            }
          }
        }
      }
    }


    /* MARKS */
    if (videoMarks?.length) {
      for (var markTMP of videoMarks) {
        if (markTMP?.state < 3) {
          const texts = (markTMP && markTMP?.texts)? getJsonString(markTMP?.texts, true) : null 
          const hasTextChange = (texts && video?.language && texts[video?.language]?.old_text)? true : false        
          await updateVideoMarkState({variables: {
            own_user_token: currentUser?.token, 
            id: parseInt(markTMP.id),

            state: (hasTextChange)? stateNumCorrectionTMP: stateNumTMP,
            state_change_label: changeLabel,
            motion_state: (hasTextChange)? motionStateNumCorrectionTMP: motionStateNumTMP,
            motion_state_change_label: changeLabel,
            extra_facturation_correction: (hasTextChange)? 1 : null,
          }});
        }
      }
    }
    

    return true
  }






  return (
    <div className='w-full p-40 flex flex-col items-center justify-center'>

      <p className='w-full min-w-[800px] max-w-[800px] px-2'>Finalizate all{(videos)? ` (${videos?.length} vídeos)` : ''}</p>
      <div className='w-full min-w-[800px] max-w-[800px] flex justify-between items-center text-xl bg-gray-100 rounded px-3 py-2'>
        <div>
          <div className='flex space-x-2 items-center'>
            {(loadingVideos || loadingVideoMarks || loading)? loadingIcon : null}
            <p className={`text-xl text-gray-400`}>
              {(loadingVideos || loadingVideoMarks)? 'Cargando vídeos y marcas...' : (loading)? loading : ((videos?.length)? 'ENDED' : 'AWATING')}
            </p>
          </div>
        </div>

        <button
          onClick={() => setStart(!start)}
          className={`px-2 text-white rounded 
            ${(loadingVideos || loadingVideoMarks)? 'opacity-30 pointer-events-none' : ''} 
            ${(start)? 'bg-red-400 hover:bg-red-600 active:bg-red-700' : 'bg-blue-400 hover:bg-blue-600 active:bg-blue-700'}
          `}
        >
          <p className={`text-xl text-white`}>{(start)? 'STOP' : 'START'}</p>
        </button>
      </div>
    </div>
  )
}

export default ScriptFinalizateAll
