import gql from "graphql-tag";
export default gql`
query GetCustomerModules($own_user_token: String, $customer_token: String) {
  getCustomerModules(own_user_token: $own_user_token, customer_token: $customer_token) {
      id
      name
      abbreviated_name
      position

      inside_extras
      visible
      blocked

      numberOfVideos
      __typename
    }
  }
`;
