import gql from "graphql-tag";

export default gql`
  mutation UpdateVideoMarkState(
    
    $own_user_token: String,

    $id: Int,
    $video_id: Int,

    $state:Int,
    $state_change_label: String,
    $motion_state: Int,
    $motion_state_change_label: String,
    $revision_state: Int,
    $revision_state_change_label: String

    $trad_done: Boolean,
    $trad_done_change_label: String

    $extra_mark_facturation: Int
    $extra_facturation_correction: Int
    $manual_number_of_words: Int

  ) {
    createOrUpdateVideoMark(input: { 

      own_user_token: $own_user_token,
      
      id: $id,
      video_id: $video_id,
      
      state: $state,
      state_change_label: $state_change_label,
      motion_state: $motion_state,
      motion_state_change_label: $motion_state_change_label,
      revision_state: $revision_state,
      revision_state_change_label: $revision_state_change_label,

      trad_done: $trad_done,
      trad_done_change_label: $trad_done_change_label,

      extra_mark_facturation: $extra_mark_facturation
      extra_facturation_correction: $extra_facturation_correction
      manual_number_of_words: $manual_number_of_words

    }) {
      
      id

      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label

      trad_done
      trad_done_change_label

      extra_mark_facturation
      extra_facturation_correction
      manual_number_of_words

      search_type_name
      search_type_color
      search_type_type
      search_review_name
      
    }
  }
`;
