import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function CustomMultipleAutocomplete({

    id,
    label,
    className,
    disabled,
    listFontSize,
    disableClearable,
    getOptionLabel,

    options,
    value,
    onChange,
    
}) {

    return <Autocomplete
        id={id} 
        disableClearable={disableClearable} 
        multiple

        options={options} 
        value={value} 
        onChange={onChange} 

        className={`${(className)? className : 'w-full mui-bg-gray-100'}`}
        disabled={disabled}
        getOptionLabel={getOptionLabel}

        ListboxProps={{ sx: { fontSize: (listFontSize)? listFontSize : 15 } }}
        renderInput={(params) => 
            <TextField
                {...params}
                label={label}
                sx={{ "& .MuiInputBase-input": { paddingBlock: '0px !important' }, "& .MuiButtonBase-root": { marign: 0, height: 24 }, "& .MuiChip-label": { paddingLeft: 1, paddingRight: 0.5 }, "& .MuiSvgIcon-root": { margin: 0 } }}
                InputLabelProps={{ sx: { top: "-10.5px", fontSize: 14, "&.MuiInputLabel-shrink": { top: 1.5, fontSize: 15 } } }}
            />
        } 
        sx={{ "& .MuiInputBase-root": { height: 30, fontSize: 15, paddingInline: 0.6, paddingBlock: 0 }}}
    />
}