import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { FuncContext } from '../contexts/FuncContext'

import UserProfilePic from './UserProfilePic'
import CustomerProfilePic from './CustomerProfilePic'

import GetLastActivities from '../graphql/queries/GetLastActivities'
import GetStatesByCustomer from './../graphql/queries/GetStatesByCustomer'
/* import CreateOrUpdateActivityLog from './../graphql/mutations/CreateOrUpdateActivityLog' */

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TheatersRoundedIcon from '@mui/icons-material/TheatersRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';






export default function RecentActivityList({ users, videos, userId, defOpen, emergency, flipSort }) {




  const { currentUser, currentCustomer, clicksoundCustomer, activityTypes } = useContext(AuthContext)
  const { activityTypeTranslatorFunc } = useContext(FuncContext)


  const emergencyOptionsTypes = [
    { value: 'state_update_video_mark, state_update_video_mark_trad', name: 'Cambios de estado en las marcas'},
    { value: 'video_state_change', name: 'Cambios de estado en los vídeos'},
  ]
  const cookieKey = (emergency)? 'emergency-type-filter' : 'activity-type-filter'

  var startDateTMP = new Date();
  startDateTMP = new Date(startDateTMP.setMonth(startDateTMP.getMonth() - 1));



  const [open, setOpen] = useState((localStorage.getItem("recent-activity") === null)? defOpen : (parseInt(localStorage.getItem('recent-activity')))? true : false);
  const [activities, setActivities] = useState(null);
  
  /* Activity filter vars */
  const [limit, setLimit] = useState(200);
  const [typeFilter, setTypeFilter] = useState(localStorage.getItem(cookieKey)? localStorage.getItem(cookieKey) : (emergency)? 'state_update_video_mark, state_update_video_mark_trad' : null);
  const [stateChecksFilter, setStateChecksFilter] = useState(null);
  const [startDate, setStartDate] = useState((!emergency)? startDateTMP : new Date('2024-06-03T03:00:00Z'));
  const [endDate, setEndDate] = useState((!emergency)? new Date() : new Date('2024-06-03T21:00:00Z'));
  
  /* Option vars */
  const [userFilterOption, setUserFilterOption] = useState({ label: 'Todos' });
  
  /* Icon vars */
  const upArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;




  const [getDailyActivity, { data: dataActivities, loading: loadingActivities }] = useLazyQuery(GetLastActivities, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      user_id: (userId)? parseInt(userId) : ((parseInt(userFilterOption?.id))? parseInt(userFilterOption?.id) : null),
      types: (typeFilter)? typeFilter : null,
      date_start: (startDate)? startDate : null,
      date_end: (endDate)? endDate : null,
      
      limit: parseInt(limit)? parseInt(limit) : 150,
      visible: (!emergency)? true : false,
      flip_sort: (flipSort)? true : false,
    }
  })

  const [getStatesByCustomer, { data: dataStates, loading: loadingStates }] = useLazyQuery(GetStatesByCustomer, { 
    variables: {
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
    }
  })







  useEffect(() => { 
    if (currentCustomer?.token && open) {
      getDailyActivity()
      getStatesByCustomer();
    }
  }, [userFilterOption, limit, open])


  useEffect(() => {
    if (open) {
      setStateChecksFilter((dataStates?.getStatesByCustomer?.length)? dataStates.getStatesByCustomer : null)
    } else {
      setTimeout(() => {
        setStateChecksFilter(null)
      }, 1000 )
    }
  }, [dataStates, open])


  useEffect(() => { 
    if (dataActivities?.getLastActivities?.length) {
      setActivities(
        dataActivities.getLastActivities.filter((activity) => {
          const userTMP = users?.filter((user)=> parseInt(user.id) === activity.own_user_id)[0]

          return (!((userTMP && activity?.type !== 'state_update_video_mark') || emergency))? false
          : (typeFilter?.includes('video_state_change') && stateChecksFilter?.length)?
            (stateChecksFilter.filter((element)=> parseInt(element) === parseInt(activity?.video_state_id))?.length? true : false)
          : true
        })
      )
    } else if (!loadingActivities && !dataActivities?.getLastActivities?.length) {
      setActivities(null)
    }
  }, [dataActivities, loadingActivities, stateChecksFilter, users])







  function changeStateCheckFilterFunc(key, id) {
    var stateChecksFilterTMP = [...stateChecksFilter];
    stateChecksFilterTMP[key] = stateChecksFilterTMP[key]? null : id;
    setStateChecksFilter(stateChecksFilterTMP);
  }










  return (
    <div className='w-full rounded overflow-hidden border border-gray-300'>

      <div 
        className={`group/activity w-full flex justify-between items-center space-x-4 font-bold truncate px-4 py-3 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 duration-300 cursor-pointer ${open? '' : 'opacity-40 hover:opacity-100'}`} 
        onClick={()=>{
          setOpen(!open)
          localStorage.setItem('recent-activity', (!open)? 1 : 0)
        }}
      >
        <p className='text-gray-700'>{(emergency)? 'Actividad anterior' : 'Actividad reciente'}</p>
        <p>{(open)? upArrowIcon : downArrowIcon}</p>
      </div>
      <hr className='w-full border-t border-gray-300'/>


      <Collapse orientation="vertical" in={open} timeout={1000}>
        {(!(loadingActivities || loadingStates))?
          <div className="w-full pt-4 pb-2 duration-300">

            <div className='h-auto xl:h-[45px] pr-2 mb-2 flex flex-wrap xl:flex-nowrap items-center space-x-2 space-y-3 xl:space-y-0'>
              <Collapse orientation="horizontal" in={loadingActivities || loadingStates} timeout={100} className='rounded-md' style={{ minWidth: 'auto' }}>
                <div className={`w-[40px] h-[40px] bg-blue-500 overflow-hidden rounded-lg border-blue-600 ml-2 flex justify-center`}>
                  <div className='scale-50 mt-[-2px] text-white'>
                    <CircularProgress color="inherit"/>
                  </div>
                </div>
              </Collapse>


              {(!userId && users?.length)?
                <Autocomplete 
                  value={(userFilterOption?.id)? { id: userFilterOption?.id, label: userFilterOption?.label, user: userFilterOption } : { label: 'Todos' }} 
                  onChange={(event, value) => {
                    setUserFilterOption((value?.id)? { id: value?.id, label: value?.label, user: value?.user } : { label: 'Todos' })
                  }} 
                  options={[{ label: 'Todos' }, ...users?.map(element => { return { id: element?.id, label: element?.name, user: element } })]} 
                  renderInput={(params) => <TextField {...params} id="tema" label={`Usuario o ${currentCustomer?.label_professor?.toLowerCase()}`}/>} 
                  renderOption={(props, option) => {
                    return (!option?.user || option?.user?.visible) && <Box component="li" {...props}>
                      <hr/>
                      <div className='flex space-x-1 text-gray-500'>
                        {(option?.user?.from_customer || option?.id === currentCustomer?.token || option?.id === clicksoundCustomer?.token)? 
                          <CustomerProfilePic customer={(option?.user)? option?.user?.from_customer : ((option?.id === currentCustomer?.token)? currentCustomer : clicksoundCustomer)} size={18} clickable={false} checkPictureExists={false} professor={(option?.user)? option?.user?.professor : false}/> 
                        : null}

                        {(parseInt(option?.id))? 
                          <UserProfilePic user={option?.user} size={18} clickable={false} checkPictureExists={false}/> 
                        : null}
                      </div>
                      <p className='ml-2'>{option.label}</p>
                    </Box>
                  }}
                  disableClearable 
                  className='w-full mui-bg-gray-100'
                  size="small"
                />
              : null }


              <TextField
                select
                value={(typeFilter)? typeFilter : 'null'}
                onChange={event => {setTypeFilter(event?.target?.value !== 'null'? event?.target?.value : null); localStorage.setItem(cookieKey, event.target.value)}}
                label="Tipo de actividad"
                className='flex-grow mui-bg-gray-100'
                style={{ minWidth: 290 }}
                size="small"
              >
                {((emergency)? emergencyOptionsTypes : activityTypes)?.map(element => {
                  return <MenuItem key={'type-act-'+element.id} value={element?.value}>{element?.name}</MenuItem>
                })}
              </TextField>


              {(!emergency || currentUser?.rol === 'admin')?
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className='w-full max-w-[150px] bg-gray-100'
                    label="Fecha inicio"
                    inputFormat="DD/MM/YYYY"
                    value={(startDate)? startDate : ''}
                    onChange={(newValue)=> {
                      (new Date(newValue).getFullYear() > 1970)? setStartDate(newValue) : setStartDate(new Date())
                    }}
                    renderInput={(params) => <TextField {...params} size="small" error={false}/>}
                  />

                  <DesktopDatePicker
                    className='w-full max-w-[150px] bg-gray-100'
                    label="Fecha fin"
                    inputFormat="DD/MM/YYYY"
                    value={(endDate)? endDate : ''}
                    onChange={(newValue)=> {
                      (new Date(newValue).getFullYear() > 1970)? setEndDate(newValue) : setEndDate(new Date())
                    }}
                    renderInput={(params) => <TextField {...params} size="small" error={false}/>}
                  />
                </LocalizationProvider>
              : null }
            </div>




            {(typeFilter?.includes('video_state_change') && stateChecksFilter) &&
              <div className='mb-3 mx-2 text-sm flex overflow-auto space-x-1 pb-1'>
                {dataStates?.videoStates?.map((element, key) => {
                  return <button key={'stateCheck-'+key} onClick={()=> changeStateCheckFilterFunc(key, element.id)}>
                    <div style={{ margin: 0 }} className='MuiButtonBase-root flex items-center rounded pr-3 border border-gray-400 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 duration-300'>
                      <Checkbox checked={stateChecksFilter[key]? true : false} size='small'/>
                      
                      <div className={`flex items-center space-x-2 truncate w-full menu-item-${element.color}`}>
                        <div className={`hover:opacity-50 p-[6px] menu-item-ball-${element.color}`}></div>
                        <p>{element.name}</p>
                      </div>
                    </div>
                  </button>
                })}
              </div>
            }



            <p className='px-3 pt-4 pb-1 text-gray-400 text-xs'>Actividad</p>

            <div className='flex flex-wrap space-y-2 w-full px-2'>
              {(activities && activities.length > 0)? 
                activities?.map((activity, key) => {
                  const userTMP = users?.filter((user)=> parseInt(user.id) === activity.own_user_id)[0]
                  const videoTMP = (videos?.length)? videos?.filter((video)=> parseInt(video.id) === activity?.video_id)[0] : null

                  return ((userTMP && activity?.type !== 'state_update_video_mark') || emergency)? 
                    <div key={'activity-'+cookieKey+'-'+key} className="w-full text-sm whitespace-nowrap">
                      <div className={`w-full ${(emergency)? '' : 'pl-3'} border border-gray-300 bg-gray-100 hover:bg-gray-200 hover:bg-opacity-60 active:bg-opacity-80 duration-300 rounded flex items-center justify-between space-x-3 overflow-hidden`}>
                        {/* {(emergency)? 
                          <div className='flex items-center pl-[2px]'>
                            <button
                              onClick={(e) => checkFunc(key, e)}
                              className={`group/buttonCheck p-2 rounded hover:bg-gray-300 hover:bg-opacity-75 active:opacity-60 duration-200 ${(activity?.checked)? 'text-blue-500' : ''}`}
                            >
                              {(activity?.checked)? boxCheckedIcon : boxIcon}
                            </button>
                          </div>
                        : null } */}
                        
                        <div className='flex space-x-2 text-xs text-gray-400 min-w-[100px]'>
                          <p>{activity?.createdAtFormated?.split(' a las ')[0]?.replaceAll('/20', '/')}</p>
                          <p>{activity?.createdAt?.split('T')[1]?.slice(0, -8)}h</p>
                        </div>

                        <div className='w-full flex space-x-1'>
                          <p>{userTMP?.name}</p>
                          <p className='flex space-x-1' dangerouslySetInnerHTML={{__html: activityTypeTranslatorFunc(activity) }}></p>
                        </div>

                        <div className='flex'>

                          <Tooltip disableInteractive title={parseInt(userTMP?.id) === activity.own_user_id? 'Abrir tu perfil en una nueva pestaña' : "Abrir perfil del usuario ejecutor en una nueva pestaña"} placement="bottom" arrow>
                            <IconButton onClick={(e)=>window.open('/user/'+activity.own_user_id, '_blank').focus()} disabled={!activity.own_user_id}>
                              <div className='text-gray-500'>
                                <UserProfilePic user={userTMP} size={26} clickable={true} checkPictureExists={false}/>
                              </div>
                            </IconButton>
                          </Tooltip>


                          {(activity.user_id && currentUser?.rol === 'admin')?
                            <Tooltip disableInteractive title="Abrir perfil del usuario modificado en una nueva pestaña" placement="bottom" arrow>
                              <button 
                                className='w-[125px] flex items-center justify-center space-x-1 px-2 text-sm text-gray-600 bg-gray-200 hover:bg-gray-400 hover:bg-opacity-30 whitespace-nowrap'
                                onClick={(e) => window.open('/user/'+activity.user_id, '_blank').focus()} 
                                disabled={!activity.user_id}
                              >
                                <AccountCircleIcon className='scale-110 text-gray-500'/>
                                <p>Abrir usuario</p>
                              </button>
                            </Tooltip>
                          : activity.video_id?
                            <Tooltip disableInteractive title="Abrir vídeo en una nueva pestaña" placement="bottom" arrow>
                              <button 
                                className='w-[125px] flex items-center justify-center space-x-1 px-2 text-sm text-gray-600 bg-gray-200 hover:bg-gray-400 hover:bg-opacity-30 whitespace-nowrap'
                                onClick={(e)=> window.open(`${process.env.REACT_APP_ROUTE}${currentCustomer?.abbreviated_name}/${((videoTMP?.search_module_abbreviated_name)? videoTMP?.search_module_abbreviated_name : 'extra')}/${videoTMP?.url_name}`, '_blank').focus()} 
                                disabled={!activity.video_id}
                              >
                                <TheatersRoundedIcon className='scale-110 text-gray-500'/>
                                <p>Abrir vídeo</p>
                              </button>
                            </Tooltip>
                          :
                            <IconButton disabled className='opacity-30'>
                              <div className='w-[25px] h-[25px] flex justify-center items-center'>
                                <CircleRoundedIcon className='scale-125'/>
                              </div>
                            </IconButton>
                          }

                        </div>
                      </div>
                    </div>
                  : null
                })
              :
                <div className='w-full px-3 py-[18px] bg-gray-100 rounded text-sm text-gray-400'>
                  <p>{(loadingActivities || loadingStates)? 'Cargando mas actividades...' : 'No se han encontrado actividades recientes.'}</p>
                </div>
              }

              {(activities?.length > (limit-1)) ? 
                <div 
                  className='w-full flex items-center px-2 py-1 text-xs rounded border border-gray-300 bg-gray-100 space-x-3 whitespace-nowrap text-gray-400 hover:text-gray-700 hover:bg-gray-200 active:bg-gray-300 cursor-pointer duration-200' 
                  onClick={()=> {
                    var lastScroll = window.scrollY;
                    setLimit(limit+200)
                    
                    setTimeout(()=>{
                      window.scrollTo(0, lastScroll);
                    }, 600)
                  }}
                >
                  {downArrowIcon}
                  <div>Cargar más</div>
                  <hr className='w-full border-gray-300'/>
                  {downArrowIcon}
                </div>
              : null }
            </div>
          </div>
        :
          <div className='w-full flex justify-center items-center space-x-4 bg-gray-200 text-gray-400 py-[200px] animate-pulse'>
            <CircularProgress  color="inherit"/>
            <p>Cargando actividad reciente...</p>
          </div>
        }
      </Collapse>

    </div>
    
  );
}