import gql from "graphql-tag";
export default gql`
  query GetProfessorByCode($own_user_token: String!, $own_customer_token: String!, $id: ID, $professor_code: String) {
    professorByCode(own_user_token: $own_user_token, own_customer_token: $own_customer_token, id: $id, professor_code: $professor_code) {
      id
      name

      positions

      email
      profile_picture
      language
      professor
      professor_code
      vinculated_to_id
      tags
      sex
      treatment
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      enabled
      visible

      original_customer_token
      navigation_customer_tokens

      createdAt
      updatedAt

      customer_permission {
        id
        token
        icon
        abbreviated_name
        name
      }

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
      }
      
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
