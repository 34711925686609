import { useState, useEffect, useContext } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { AuthContext } from '../../contexts/AuthContext';
import { FuncContext } from '../../contexts/FuncContext';
import axios from 'axios'

import CalendarDateInput from '../CalendarDateInput';
import ModuleSelector from '../ModuleSelector';

import ChartGetAllVideos from '../../graphql/queries/ChartGetAllVideos'
import GetCustomerModules from '../../graphql/queries/GetCustomerModules'

import Tooltip from '@mui/material/Tooltip';


import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector } from 'recharts';










export default function RecordingDates({
    
  chartColors,
  
}) {


  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { formatNumbersToPrint } = useContext(FuncContext)

  const localModuleFilter = localStorage.getItem(currentCustomer?.token+'-video-module-filter')




  
  /* Filter vars */
  const [moduleFilter, setModuleFilter] = useState((parseInt(localModuleFilter))? localModuleFilter : 'all');
  const [startDate, setStartDate] = useState(getMonday(new Date("July 21, 2022 00:00:01")));
  const [endDate, setEndDate] = useState(new Date());
  

  /* Chart vars */
  const [tableKeys, setTableKeys] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [moduleChartData, setModuleChartData] = useState(null);
  const [moduleChartDataMinutes, setModuleChartDataMinutes] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);

  
  

  function getMonday(d) {
    d = new Date(d);
    d?.setFullYear(d.getFullYear(), 0, 1);
    return d;
  }





  /* Icon vars */
  const downloadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M479.8-351q-7.2 0-13.5-2.5T455-361L313-503q-11-10.909-10.5-25.455Q303-543 313.522-554 325-565 339.5-565q14.5 0 25.5 11l79 80v-306q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q495-816 505.5-805.65 516-795.3 516-780v306l80-80q10.667-11 25.333-10.5Q636-564 647.478-553 658-542 658-527.5T647-502L505-361q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5ZM263.717-192Q234-192 213-213.15T192-264v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q243-336 253.5-325.65 264-315.3 264-300v36h432v-36q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q747-336 757.5-325.65 768-315.3 768-300v36q0 29.7-21.162 50.85Q725.676-192 695.96-192H263.717Z"/></svg>
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M630-444H228.049q-15.321 0-25.685-10.289Q192-464.579 192-479.789 192-495 202.364-505.5q10.364-10.5 25.685-10.5H630l-80-80q-11-11-11-25.5t10.522-25.5Q561-658 575.5-658q14.5 0 25.5 11l142.333 142.333Q748-500 750.5-493.7q2.5 6.3 2.5 13.5t-2.5 13.5q-2.5 6.3-7.5 11.7L601-313q-11 11-25 10.5T550.522-314Q540-325 540-339.5t11-25.5l79-79Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;







  const { data: dataModules, loading: loadingModules } = useQuery(GetCustomerModules, {
    variables: { 
      own_user_token: currentUser?.token,
      customer_token: currentCustomer?.token,
    }
  })


  const [getVideos, { data: dataVideos, loading: loadingVideos }] = useLazyQuery(ChartGetAllVideos, { 
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,

      module: (moduleFilter === 'all')? JSON.stringify(dataModules?.getCustomerModules?.filter((el) => el?.visible)?.map((el) => el?.id)) : (parseInt(moduleFilter))? moduleFilter+'' : '0',

      recordingDateStart: (startDate)? startDate : null,
      recordingDateEnd: (endDate)? endDate : null,
    }
  })







  useEffect(() => {
    axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
      var categoriesSelect = [];

      if (response?.data && Object.keys(response?.data)?.length) {
        for (var key in response?.data) {
          var element = response?.data[key]
          categoriesSelect.push({ label: element.nom, token: element.codi, id: element.id })
        }
        setCategoryOptions(categoriesSelect);
      }
    })
  }, [])


  useEffect(() => {
    getVideos()
  }, [moduleFilter, startDate, endDate])








  /* Generate charts */
  useEffect(() => {
    var videos = dataVideos?.getCustomerVideos?.filter((el) => el?.recordingDate);
    var chartDataTMP = [];
    var tableKeysTMP = [];


    if (videos?.length) {
      videos = videos?.sort((a, b) =>
        (new Date(a?.recordingDate) < new Date(b?.recordingDate))? -1
        : (new Date(a?.recordingDate) > new Date(b?.recordingDate))? 1
        : 0
      )

      var dateListTotal = [];
      var dateListPresencial = [];
      var dateListRemoto = [];
      var moduleNames = {};

      for (var video of videos) {
        if (video?.recordingDateFormated) {
          const catTMP = categoryOptions?.filter((el) => parseInt(el?.id) === parseInt(video?.category_id))[0]

          dateListTotal[video?.recordingDateFormated] = (dateListTotal[video?.recordingDateFormated])? [...dateListTotal[video?.recordingDateFormated], video] : [video];
          moduleNames[video?.search_module_name] = dateListTotal[video?.recordingDateFormated]?.length;

          if (catTMP?.token?.includes('remoto')) {
            dateListRemoto[video?.recordingDateFormated] = (dateListRemoto[video?.recordingDateFormated])? [...dateListRemoto[video?.recordingDateFormated], video] : [video];
          } else {
            dateListPresencial[video?.recordingDateFormated] = (dateListPresencial[video?.recordingDateFormated])? [...dateListPresencial[video?.recordingDateFormated], video] : [video];
          }
        }
      }


      setModuleChartData(
        Object.keys(moduleNames)?.map(key => {
          var value = moduleNames[key];
          return { name: key, value: value, type: 'grab' }
        })
      )

      setModuleChartDataMinutes(
        Object.keys(moduleNames)?.map(key => {
          var minutesTMP = (videos)? videos?.map((el) => (key === el?.search_module_name)? parseFloat(el?.firstReviewMinutes) : 0) : 0
          minutesTMP = (minutesTMP && minutesTMP?.length)? minutesTMP?.filter((el) => parseFloat(el)).reduce((a, b) => a + b, 0) : 0
          return { name: key, value: minutesTMP, type: 'min' }
        })
      )


      for (var date in dateListTotal) {

        var minutesTMP = (dateListTotal[date]?.length)? dateListTotal[date]?.map((el) => parseFloat(el?.firstReviewMinutes)) : null
        minutesTMP = minutesTMP?.filter((el) => parseFloat(el)).reduce((a, b) => a + b, 0)
        
        var minutesPresencialTMP = (dateListPresencial[date]?.length)? dateListPresencial[date]?.map((el) => parseFloat(el?.firstReviewMinutes)) : null
        minutesPresencialTMP = minutesPresencialTMP?.filter((el) => parseFloat(el)).reduce((a, b) => a + b, 0)
        
        var minutesRemotaTMP = (dateListRemoto[date]?.length)? dateListRemoto[date]?.map((el) => parseFloat(el?.firstReviewMinutes)) : null
        minutesRemotaTMP = minutesRemotaTMP?.filter((el) => parseFloat(el)).reduce((a, b) => a + b, 0)


        chartDataTMP.push({
          'name': date,

          'Total': dateListTotal[date]?.length,
          'Total minutos': minutesTMP,

          'Presenciales': dateListPresencial[date]?.length,
          'Total Presenciales': minutesPresencialTMP,

          'Remotas': dateListRemoto[date]?.length,
          'Total Remotas': minutesRemotaTMP,

          'Vídeos': videos?.filter((el) => el?.recordingDateFormated === date),
        })
      }


      tableKeysTMP = ['name', 'Presenciales', 'Remotas', 'Total'];


      setTableKeys(tableKeysTMP);
      setChartData(chartDataTMP)


    } else {
      setChartData(null)
    }
  }, [dataVideos, categoryOptions])















  const ChartTooltipFunc = ({ active, payload, label }) => {
    
    if (active && payload && payload.length) {


      return (
        <div className="bg-white text-sm text-gray-700 rounded border border-gray-400">
          <div className='p-2'>
            <p className='font-bold px-1'>Grabaciones del día {payload[0]?.payload['name']}</p>
            <hr className='border-gray-400 my-2' />


            <div className='w-full space-y-1 px-1'>
              <div className='w-full flex justify-between space-x-10'>
                <p>Total</p>
                <p>{payload[0]?.payload['Total']} Grabaciones / {formatNumbersToPrint(payload[0]?.payload['Total minutos'], 2)} min</p>
              </div>

              {payload?.map((element, key) => {
                return (element.value)? 
                  <div key={'recording-'+key} className='w-full flex justify-between space-x-10'>
                    <p>{element?.dataKey}</p>
                    <p>{formatNumbersToPrint(element.value, 0)} Grabaciones / {formatNumbersToPrint(payload[0]?.payload['Total ' + element?.dataKey], 2)} min</p>
                  </div>
                : null
              })}
            </div>


            <hr className='border-gray-400 my-2' />


            <div className='w-full space-y-1 px-1'>
              {payload[0]?.payload?.Vídeos?.map(element => {
                return <div key={'vid-'+element?.name} className='w-full flex justify-between space-x-10'>
                  <p>{element?.name}</p>
                  <p>{formatNumbersToPrint(element?.firstReviewMinutes, 2)} min</p>
                </div>
              })}
            </div>
          </div>
        </div>
      );
    }
  
    return null;
  };


  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`} className='text-[0px] lf:text-[8px] xl:text-[10px]'>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }











  function downloadChartInTXT(data, filename, itemName, decimals) {
    var content = filename;


    for (var i = 0; i < data?.length; i++) {
      var dataItem = data[i];

      Object.keys(dataItem)?.map(key => {
        var value = dataItem[key];

        if (key === 'name') {
          content = (content + "\n\n\n\n - " + value + "\n");
        } else {
          content = (content + "\n\xa0\xa0\xa0\xa0" + key + ":\t\t" + value?.toFixed(decimals) + ((parseInt(value) === 1)? itemName.slice(0, -1) : itemName));
        }
      });
    }


    const link = document.createElement("a");
    const file = new Blob([content], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);
    link.download = filename + ".txt";
    link.click();
    URL.revokeObjectURL(link.href);
  }








  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (dataTable, { cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.35;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {dataTable[index]?.name}
        {' / '}
        {formatNumbersToPrint(dataTable[index]?.value, (dataTable[index]?.type === 'min')? 2 : 0)} {dataTable[index]?.type} 
        {' / '}
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };







  return (<>

  

    <div className='absolute top-0 right-0'>
      <div className='p-2 flex space-x-1 rounded-t overflow-hidden'>
        <Tooltip disableInteractive title={"Descargar datos en TXT"} placement="bottom" arrow>
          <button 
            onClick={()=> {
              downloadChartInTXT(chartData, `Minutos por ${currentCustomer?.label_professor?.toLowerCase()} y ${currentCustomer?.label_module?.toLowerCase()}`, ' minutos', 2)

            }} 
            className={`bg-gray-200 py-[2px] pl-1 pr-2 text-xs font-bold flex items-center cursor-pointer duration-200 text-gray-500 hover:text-blue-500 active:text-gray-500 active:bg-gray-200 rounded`}
          >
            {downloadIcon}
            <p>TXT</p>
          </button>
        </Tooltip>

        {/* <HomeChartsToExcel 
          data={chartData} 
          filename={'Motions por usuario'}
          modules={null}
        /> */}
      </div>
    </div>


    <div className='w-full p-5 flex flex-wrap space-y-3 lg:space-y-0 lg:space-x-2 lg:flex-nowrap'>
      <div className='w-full'>
        <ModuleSelector 
          modules={dataModules?.getCustomerModules} 
          moduleFilter={moduleFilter} 
          setModuleFilter={setModuleFilter} 
          noSelectedModule

          showAll={true}
          color='white'
        />
      </div>
      
      <CalendarDateInput
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>



    <div className={`w-full flex flex-wrap`}>
      <div className={`w-full p-5 pt-0`}>
        <div className={`flex relative w-full text-xs`}>


          {/* Chart */}
          {(chartData?.length && !loadingVideos && !loadingModules)? 
            <div className='flex flex-wrap relative w-full space-y-5'>

              <div className='w-full bg-gray-300 bg-opacity-50 rounded'>
                <p className='w-full px-1 pt-[2px]'>Grabaciones x fecha</p>

                <div className={`w-full p-5 pl-0 pb-1 ${(chartData?.length && !loadingVideos && !loadingModules)? 'h-[30vw] ' : ''}`}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartData} barCategoryGap={0} barGap={0}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />}/>
                      <YAxis unit='' width={40}/>

                      <ChartTooltip content={<ChartTooltipFunc />} />
                      <Legend iconType='circle' iconSize={10} wrapperStyle={{ paddingTop: "30px" }}/>

                      <Bar dataKey={'Presenciales'} fill={chartColors[0]} type="circle" />
                      <Bar dataKey={'Remotas'} fill={chartColors[1]} type="circle" />
                      {/* <Bar dataKey={'Total'} fill={chartColors[2]} type="circle" /> */}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {(moduleFilter === 'all')? 
                <div className='w-full px-2 flex flex-wrap lg:flex-nowrap items-center justify-center space-y-5 lg:space-y-0 lg:space-x-5'>
                  {(moduleChartData)?
                    <div className='w-full lg:w-1/2 flex flex-wrap bg-gray-300 bg-opacity-50 rounded'>
                      <p className='w-full px-1 pt-[2px]'>Grabaciones</p>

                      <div className={`w-full items-center ${(chartData?.length && !loadingVideos && !loadingModules)? 'h-[250px]' : ''}`}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart width={400} height={400}>
                            <Pie
                              data={moduleChartData}
                              dataKey="value"
                              isAnimationActive={false}
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              label={(element) => renderCustomizedLabel(moduleChartData, element)}
                            >
                              {moduleChartData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={chartColors[1]} />
                              ))}
                              <Tooltip />
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  : null }

                  {(moduleChartDataMinutes)?
                    <div className='w-full lg:w-1/2 flex flex-wrap bg-gray-300 bg-opacity-50 rounded'>
                      <p className='w-full px-1 pt-[2px]'>Minutos</p>

                      <div className={`w-full items-center ${(chartData?.length && !loadingVideos && !loadingModules)? 'h-[250px]' : ''}`}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart width={400} height={400}>
                            <Pie
                              data={moduleChartDataMinutes}
                              dataKey="value"
                              isAnimationActive={false}
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              label={(element) => renderCustomizedLabel(moduleChartDataMinutes, element)}
                            >
                              {moduleChartDataMinutes?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={chartColors[1]} />
                              ))}
                              <Tooltip />
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  : null }
                </div>
              : null }

            </div>
          : (!loadingVideos && !loadingModules)?
            <div className='w-full'>
              <div className='w-full px-5 py-5 flex items-center space-x-2 rounded text-gray-500 bg-gray-200'>
                <p>No se ha encontrado información entre las fechas selecionadas.</p>
              </div>
            </div>
          : 
            <div className='w-full'>
              <div className='w-full px-5 py-5 flex items-center space-x-2 rounded text-gray-500 bg-gray-200'>
                {loadingIcon}
                <p>Cargando...</p>
              </div>
            </div>
          }


        </div>
      </div>




      {/* Table */}
      {(chartData?.length)? 
        <div className='w-full p-5'>
          <div className='w-full overflow-auto '>
            <table className='w-full text-sm whitespace-nowrap'>
              <tr className='text-left'>
                {tableKeys?.map(element => {
                  return <th className='py-2 px-6 bg-gray-100'> {/* sticky top-[55px] */}
                    {
                      (element === 'name')? 'Fecha' 
                      : element
                    }
                  </th>
                })}
              </tr>

              {chartData?.map(element => {
                return <tr className='border-b-4 border-gray-100 bg-gray-200 hover:bg-gray-300 rounded duration-200'>
                  {tableKeys?.map(key => {
                    return <td>
                      <div className='w-full flex items-center justify-between space-x-2 pl-6'>
                        <p className={`${(key !== 'name')? '' : 'font-bold'}`}>
                          {
                            (element[key])? element[key]
                            : '-'
                          }
                        </p>

                        {(key === 'Total' && element['Vídeos']?.length)? <>
                          <div className='text-gray-400 opacity-70'>{rightArrowIcon}</div>

                          <div className='w-full flex items-center max-w-[calc(100vw-620px)] overflow-auto smallScrollBar opacity-50 hover:opacity-100'>
                            {element['Vídeos']?.map(element => {
                              return <div className='text-xs py-2 px-3 text-gray-500 hover:bg-gray-400 hover:bg-opacity-60 hover:text-gray-700 duration-200'>
                                {element?.name}
                              </div>
                            })}
                          </div>
                        </> : null }
                      </div>
                    </td>
                  })}
                </tr>
              })} 
            </table>
          </div>
        </div>
      : null }





    </div>
  </>);
}