import { useState, useContext, useEffect } from 'react'
import { FuncContext } from '../contexts/FuncContext';
import { useMutation, useLazyQuery } from '@apollo/client'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import GetPublicMarks from '../graphql/queries/GetPublicMarks';
import CreateOrUpdateVideoMark from '../graphql/mutations/CreateOrUpdateVideoMark';

import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import LoadAnimation from './LoadAnimation';
import MarkItem from './MarkItem';

import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';








export default function PublicVideoCommentBox({

  video,
  setHasComments,

}) {


  const { path_video_token } = useParams()
  const { remove_tags, format_texts } = useContext(FuncContext)


  const localGuestName = (localStorage.getItem('guest-name'))? localStorage.getItem('guest-name') : null
  const localGuestEmail = (localStorage.getItem('guest-email'))? localStorage.getItem('guest-email') : null

  const [guestUser, setGuestUser] = useState(null);
  const [guestName, setGuestName] = useState(localGuestName);
  const [guestEmail, setGuestEmail] = useState(localGuestEmail);

  const [disableSave, setDisableSave] = useState(true);
  const [alreadySubmited, setAlreadySubmited] = useState(false);
  const [loadingLogIn, setLoadingLogIn] = useState(false);


  const [commentText, setCommentText] = useState(null);
  const [comments, setComments] = useState(null);
  const [editingComent, setEditingComent] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);

  const userIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-429 363.09-313Q352-302 338-302t-25-11q-11-11-11-25t11-25.09L429-480 313-595.91Q302-607 302-621t11-25q11-11 25-11t25.09 11L480-530l115.91-116Q607-657 621-657t25 11q11 11 11 25t-11 25.09L530-480l116 116.91Q657-352 657-338t-11 25q-11 11-25 11t-25.09-11L480-429Z"/></svg>
  const arrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M522-480 358-644q-11-11-11-25.5t11-25.5q11-11 25.5-11t25.84 11.34L599-505q5 5.4 7.5 11.7 2.5 6.3 2.5 13.5t-2.5 13.5Q604-460 599-455L409.34-265.34Q398-254 384-254.5T359-266q-11-11-11-25.5t11-25.5l163-163Z"/></svg>
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const signOutIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M606 752q-12-12-12-29.625T606 693l75-75H403q-17 0-29.5-12.5T361 576q0-17 12.5-29.5T403 534h275l-73-74q-11-12.267-11-29.133Q594 414 606 402q11.75-12 29.375-12T665 402l145 145q7.091 6.6 10.045 14.3Q823 569 823 577q0 8-2.955 15.7-2.954 7.7-10.045 14.3L664 753q-12 12-28.5 12T606 752ZM204 936q-34.65 0-59.325-24.675Q120 886.65 120 852V300q0-34.65 24.675-59.325Q169.35 216 204 216h233.463Q454 216 466.5 228.5T479 258q0 17-12.5 29.5T437.463 300H204v552h233.463Q454 852 466.5 864.5T479 894q0 17-12.5 29.5T437.463 936H204Z"/></svg>;







  const [getMarks, { data: dataMarks, loadingMarks }] = useLazyQuery(GetPublicMarks, {
    fetchPolicy: 'cache-and-network',
    variables: {
      token: video?.token,
    }
  })

  const [createOrUpdateVideoMark] = useMutation(CreateOrUpdateVideoMark)





  useEffect(() => { 
    if (video?.token) {
      getMarks()
    }
  }, [video, guestUser])

  useEffect(() => { 
    setComments((dataMarks?.getPublicMarks?.length)? dataMarks?.getPublicMarks : null)
    setHasComments((dataMarks?.getPublicMarks?.length)? true : false)
  }, [dataMarks])

  useEffect(() => { 
    const nameCheck = (guestName)? true : false
    const emailCheck = (guestEmail && String(guestEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))? true : false;
    setDisableSave((nameCheck && emailCheck)? false : true)
  }, [guestName, guestEmail])

  useEffect(() => { 
    if (!disableSave && localGuestName && localGuestEmail && !guestUser) { guestLoginFunc() }
  }, [disableSave, guestUser])




  async function guestLoginFunc() {
    localStorage.removeItem('guest-name')
    localStorage.removeItem('guest-email')

    if (!disableSave) {
      setLoadingLogIn(true)
  
      const response = await axios.post(`${process.env.REACT_APP_API_ROUTE}getVideoGuestUser`, { user_name: guestName, user_email: guestEmail });
      const data = (response?.data)? response?.data : null
      setGuestUser((data?.id)? data : null)

      if (data?.id) {
        localStorage.setItem('guest-name', guestName)
        localStorage.setItem('guest-email', guestEmail)
      }
  
      setTimeout(() => {
        setLoadingLogIn(false)
      }, 300 )
    }
  }


  async function saveCommentTextFunc() {
    const textTMP = (commentText)? commentText?.replaceAll('<p><br></p>', '\n')?.trim()?.replaceAll('\n', '<p><br></p>')?.replace(/<img[^>]*>/g,"") : null

    if (textTMP) {
      setLoadingSend(true)

      var texts = {}
      texts[video?.language] = {};
      texts[video?.language].text = textTMP;
  
      const videoEl = document.getElementById('video');
      const time = (videoEl)? videoEl?.currentTime : null;
  
      var input = {
        path_video_token: path_video_token, 
        search_guest_data: (guestUser?.id)? `${guestUser?.id},${guestUser?.name},${guestUser?.email}` : null, 
  
        time: (time)? formatSecondsFunc(time) : null,
        texts: (texts && Object.keys(texts)?.length)? JSON.stringify(texts) : null,
  
        video_id: parseInt(video.id),
        review_id: (video?.last_review?.length && parseInt(video?.last_review[0].id))? parseInt(video?.last_review[0].id) : null,
      };
          
      var commentTMP = await createOrUpdateVideoMark({variables: input});
      commentTMP = commentTMP?.data?.createOrUpdateVideoMark;
  
      await getMarks()

      setTimeout(() => {
        if (commentTMP?.id) {
          var focusDiv = document.getElementById('video-mark-list-item-focus-'+commentTMP?.id)
          if (focusDiv) { window.scrollTo({ top: focusDiv.getBoundingClientRect()?.top - document.body.getBoundingClientRect()?.top - 145 }) }
        }

        setCommentText(null)
        setLoadingSend(false)
      }, 400 )
    }
  }


  function formatSecondsFunc(seconds_num) {
    var sec_num = parseInt(seconds_num, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }







  return (!guestUser?.id)?


    <div className='space-y-2 text-sm'>
      <div className='flex items-center justify-end flex-wrap md:flex-nowrap space-y-6 md:space-y-0 md:space-x-2 '>
          <TextField
            name="name"
            label="Nombre de invitado"
            variant="filled"
            className='w-full'
            sx={{ "& .MuiInputBase-root": { height: 40 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
            InputLabelProps={{ sx: { paddingBlock: 0.5, top: "-11px", "&.MuiInputLabel-shrink": { top: -28, left: -4 } } }}

            value={(guestName)? guestName : ''}
            onChange={(event) => setGuestName(event.target.value?.replaceAll(',', ''))}
            error={(guestName === '' || (alreadySubmited && !guestName))}
            onKeyPress={(event) => { if (event.key === 'Enter') { setAlreadySubmited(true); guestLoginFunc() } }}
          />

          <TextField
            name="email"
            label="Email de invitado"
            variant="filled"
            className='w-full'
            sx={{ "& .MuiInputBase-root": { height: 40 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
            InputLabelProps={{ sx: { paddingBlock: 0.5, top: "-11px", "&.MuiInputLabel-shrink": { top: -28, left: -4 } } }}

            value={(guestEmail)? guestEmail : ''}
            onChange={(event) => setGuestEmail(event.target.value?.replaceAll(',', ''))}
            error={((guestEmail === '' || (guestEmail && !String(guestEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) || (alreadySubmited && !guestEmail))}
            onKeyPress={(event) => { if (event.key === 'Enter') { setAlreadySubmited(true); guestLoginFunc() } }}
          />

          <button onClick={() => { setAlreadySubmited(true); guestLoginFunc() }} disabled={disableSave} className='pl-[6px] pr-[10px] py-2 flex items-center space-x-1 bg-gray-100 hover:bg-blue-500 hover:text-white active:text-white active:bg-blue-600 border border-gray-300 hover:border-blue-600 active:border-blue-700 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded'>
            {(loadingLogIn)? loadingIcon : saveIcon}
            {(loadingLogIn)? <p>Guardando...</p> : <p>Guardar</p>}
          </button>
      </div>

      <p className='text-xs text-blue-500 px-1'>*Introduce un nombre y un correo para ver y escribir comentarios.</p>
    </div>


  :


    <div className='space-y-4 text-sm'>

      <div className='space-y-1'>
        <div className='px-1 flex items-center justify-between space-x-[5px]'>
          <div className='flex items-center space-x-[5px]'>
            {userIcon}
            <p>{guestUser?.name}</p>
            <p className='opacity-50 text-sm'>/ {guestUser?.email}</p>
          </div>

          <button className='p-[2px] flex items-center text-gray-500 hover:text-blue-500 active:scale-95 duration-200 rounded'
            onClick={() => {
              localStorage.removeItem('guest-name')
              localStorage.removeItem('guest-email')
              setGuestEmail(null)
              setGuestName(null)
              setGuestUser(null)
              setAlreadySubmited(false)
            }} 
          >
            <div className='scale-90'>{signOutIcon}</div>
          </button>
        </div>


        <div>
          <ReactQuillSimpleImput 
            id={"comment_box"} 
            placeholder={'Escribe un comentario...'}
            value={(commentText)? commentText : ''}
            style={2}

            onChange={(value) => setCommentText((value)? value : null)} 
          />
        </div>

        <Collapse orientation="vertical" in={(remove_tags(format_texts(commentText?.replace(/\n/g, "<br />"))))? true : false} timeout={100}>
          <div className='flex items-center justify-end space-x-2'>
            <button onClick={() => setCommentText(null)} className='pl-[2px] pr-[6px] py-[2px] flex items-center bg-gray-100 hover:bg-red-500 hover:text-white active:text-white active:bg-red-600 border border-gray-300 hover:border-red-600 active:border-red-700 active:scale-95 duration-200 rounded'>
              {cancelIcon}
              <p>Cancelar</p>
            </button>

            <button onClick={() => saveCommentTextFunc()} className='pr-[2px] pl-[6px] py-[2px] flex items-center bg-gray-100 hover:bg-blue-500 hover:text-white active:text-white active:bg-blue-600 border border-gray-300 hover:border-blue-600 active:border-blue-700 active:scale-95 duration-200 rounded'>
              {(loadingSend)? 'Comentando...' : 'Comentar'}
              {(loadingSend)? loadingIcon : arrowIcon}
            </button>
          </div>
        </Collapse>
      </div>



      {(!loadingMarks && comments?.length)?
        <div>
          <p className='pb-1 pt-8 px-1 opacity-50 border-b border-gray-400'>Caja de comentarios</p>

          <div className='pt-6'>
            <TransitionGroup appear={true}>
              {comments?.map((comment, key) => {
                return <CSSTransition key={'mark_item_' + comment.id} classNames="fade" timeout={200} appear={true} mountOnEnter unmountOnExit>
                  <div>
                    <MarkItem
                      itemKey={key}
                      mark={comment}
                      video={video}
                      setNewVideoMark={setEditingComent}
                      getVideoMarks={getMarks}
                      mode={'review'}
                      simplified={2}
                      guest_data={(guestUser?.id)? `${guestUser?.id},${guestUser?.name},${guestUser?.email}` : null}
                    />
                  </div>
                </CSSTransition>
              })}
            </TransitionGroup>
          </div>
        </div>
      :
        <LoadAnimation 
        />
      }

    </div>
}
