import { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from "react-router-dom";

import VideoOptionsPanelThumbnail from './VideoOptionsPanelThumbnail';
import VideoOptionsPanelChangelog from './VideoOptionsPanelChangelog';
import VideoOptionsPanelVideo from './VideoOptionsPanelVideo';
import VideoOptionsPanelChat from './VideoOptionsPanelChat';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';










export default function VideoOptionsPanel({
  
  mode,
  video,
  setVideo,
  reviews,
  users,
  selectedReview,
  tab,
  setTab,
  stateCounter,
  clicksoundUserNames,
  reviewLogs,
  setReviewLogs,
  userFromSelectedReview,
  droppedFiles,
  setDroppedFiles,

  loading,
  loadingNewVideo, 
  setLoadingNewVideo,

  thumbTime,
  setThumbTime,
  selectedThumbnail,
  setSelectedThumbnail,
  predefThumbnails,
  setThumbFirstime,

  markStateFilter,
  setMarkStateFilter,

  newReviewFile,
  setNewReviewFile,

  reviewFilter,
  setReviewFilter,
  showMarksOnScreen,
  setShowMarksOnScreen,

  getVideo,
  getVideoReviews,

  windowHeight,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const localSelectedTabKey = currentUser?.token + '-' + currentUser?.rol + '-' + currentCustomer?.token + '-video-selected-tab'
  const localSelectedTab = (parseInt(localStorage.getItem(localSelectedTabKey)))? parseInt(localStorage.getItem(localSelectedTabKey)) : null





  /* Vars */
  const [oldSelector, setOldSelector] = useState((localSelectedTab)? localSelectedTab : 1);
  const [showThumbnailSelector, setShowThumbnailSelector] = useState((localSelectedTab)? localSelectedTab : 1);
  const [newMessagesNum, setNewMessagesNum] = useState(0);


  /* Icon vars */
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.5 4 6 7h2L6.5 4H8l1.5 3h2L10 4h1.5L13 7h2l-1.5-3h3q.625 0 1.062.448Q18 4.896 18 5.5v9q0 .604-.438 1.052Q17.125 16 16.5 16h-13q-.604 0-1.052-.448Q2 15.104 2 14.5v-9q0-.604.427-1.052Q2.854 4 3.5 4Z"/></svg>;
  const changeLogIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M168-144q-29.7 0-50.85-21.15Q96-186.3 96-216v-528q0-29.7 21.15-50.85Q138.3-816 168-816h624q29.7 0 50.85 21.15Q864-773.7 864-744v528q0 29.7-21.15 50.85Q821.7-144 792-144H168Zm43-143h192v-72H211v72Zm371-73 170-170-51-51-119 119-51-51-51 51 102 102Zm-371-84h192v-72H211v72Zm0-156h192v-72H211v72Z"/></svg>
  const thumbnailIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M2.5 16q-.625 0-1.062-.438Q1 15.125 1 14.5v-9q0-.625.438-1.062Q1.875 4 2.5 4h9q.625 0 1.062.438Q13 4.875 13 5.5v9q0 .625-.438 1.062Q12.125 16 11.5 16ZM15 9.25q-.208 0-.354-.146T14.5 8.75V4.5q0-.208.146-.354T15 4h3.5q.208 0 .354.146T19 4.5v4.25q0 .208-.146.354t-.354.146ZM4.25 13h5.5q.229 0 .333-.208.105-.209-.041-.396l-1.521-2q-.104-.146-.302-.146t-.302.146L6.5 12.229l-.917-1.208q-.104-.167-.302-.167t-.302.167l-1.021 1.375q-.146.187-.031.396.115.208.323.208ZM15 16q-.208 0-.354-.146T14.5 15.5v-4.25q0-.208.146-.354T15 10.75h3.5q.208 0 .354.146t.146.354v4.25q0 .208-.146.354T18.5 16Z"/></svg>;
  const chatIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M360-240q-30 0-51-21t-21-51v-48h456v-312h48q30 0 51 21t21 51v417q0 24-22 33.5t-39-7.5l-83-83H360ZM240-432l-83 83q-17 17-39 7.5T96-375v-417q0-30 21-51t51-21h432q30 0 51 21t21 51v288q0 30-21 51t-51 21H240Z"/></svg>


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 3px',
      fontSize: 11,
    },
  }));




  

  useEffect(() => {    
    setOldSelector((localSelectedTab)? localSelectedTab : 1)
    setShowThumbnailSelector((localSelectedTab)? localSelectedTab : 1)
  }, [navigate])


  function selectTabFunc(num) {
    setShowThumbnailSelector(num); 
    setOldSelector(showThumbnailSelector); 
    localStorage.setItem(localSelectedTabKey, num)
  }




  





  return (<div className={`${(currentUser?.professor)? 'hidden' : 'flex h-full flex-col'}`}>


    {(video && selectedReview?.filename && !currentUser?.professor) && <>
      <div className='flex items-center space-x-3 text-sm whitespace-nowrap px-2 pt-3 pb-1'>
        <div
          className={`flex space-x-1 cursor-pointer ${(showThumbnailSelector === 1)? 'text-blue-500' : 'text-gray-600 hover:text-blue-500'} duration-200`}
          onMouseUp={() => selectTabFunc(1)}
        >
          {videoIcon}
          <p>Vídeo{(video?.is_translation_of)? ` localizado ${(parseInt(selectedReview?.video_id) !== parseInt(video?.id))? '(no subido)' : ''}` : ''}</p>
        </div>



        {(currentCustomer?.token === 'ARdNHNnzeRrSE5MW2sEk' && !currentUser?.rol?.includes('user')) && <>
          <hr className='w-full border-gray-300'/>
          <div
            className={`flex space-x-1 cursor-pointer ${(showThumbnailSelector === 2)? 'text-blue-500' : 'text-gray-600 hover:text-blue-500'} duration-200`}
            onMouseUp={() => selectTabFunc(2)}
          >
            <StyledBadge badgeContent={newMessagesNum} color="error" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              {chatIcon}
            </StyledBadge>

            <p>Chat</p>
          </div>
        </>}


        {(!currentCustomer?.free_customer && (!video?.is_translation_of || parseInt(selectedReview?.video_id) === parseInt(video?.id))) && <>
          <hr className='w-full border-gray-300'/>
          <div
            className={`flex space-x-1 cursor-pointer ${(showThumbnailSelector === 3)? 'text-blue-500' : 'text-gray-600 hover:text-blue-500'} duration-200`}
            onMouseUp={() => selectTabFunc(3)}
          >
            {changeLogIcon}
            <p>Changelog</p>
          </div>
        </>}


        {(currentUser?.rol === 'admin' && (!video?.is_translation_of || parseInt(selectedReview?.video_id) === parseInt(video?.id)) && !selectedReview?.filename?.includes('mp3')) && <>
          <hr className='w-full border-gray-300'/>
          <div
            className={`flex space-x-1 cursor-pointer ${(showThumbnailSelector === 4)? 'text-blue-500' : 'text-gray-600 hover:text-blue-500'} duration-200`}
            onMouseUp={() => selectTabFunc(4)}
          >
            {thumbnailIcon}
            <p>Thumbnails</p>
          </div>
        </>}
      </div>
    </>}





    <div className={`w-full relative mt-2 flex rounded-lg overflow-hidden pr-[1px] h-full min-h-[170px] ${(selectedReview?.filename && !currentUser?.professor)? '' : 'invisible pointer-events-none'}`} ref={containerRef}>
      {/* VIDEO PANEL */}
      <VideoOptionsPanelVideo 
        oldSelector={oldSelector}
        showThumbnailSelector={showThumbnailSelector}
        containerRef={containerRef}

        video={video}
        setVideo={setVideo}
        reviews={reviews}
        selectedReview={selectedReview}
        reviewLogs={reviewLogs}
        newReviewFile={newReviewFile}
        setNewReviewFile={setNewReviewFile}
        predefThumbnails={predefThumbnails}
        selectedThumbnail={selectedThumbnail}
        tab={tab}
        setTab={setTab}
        clicksoundUserNames={clicksoundUserNames}
        stateCounter={stateCounter}
        mode={mode}
        userFromSelectedReview={userFromSelectedReview}

        reviewFilter={reviewFilter}
        setReviewFilter={setReviewFilter}
        showMarksOnScreen={showMarksOnScreen}
        setShowMarksOnScreen={setShowMarksOnScreen}

        markStateFilter={markStateFilter}
        setMarkStateFilter={setMarkStateFilter}
      
        getVideo={getVideo}
        getVideoReviews={getVideoReviews}

        loading={loading}
        loadingNewVideo={loadingNewVideo}
        setLoadingNewVideo={setLoadingNewVideo}

        windowHeight={windowHeight}
      />




      {(selectedReview?.filename) && <>
      
        {/* CHANGELOG PANEL */}
        {(!currentUser?.rol?.includes('user')) &&
          <VideoOptionsPanelChat
            oldSelector={oldSelector}
            showThumbnailSelector={showThumbnailSelector}
            containerRef={containerRef}

            video={video}
            users={users}
            newMessagesNum={newMessagesNum}
            setNewMessagesNum={setNewMessagesNum}
            droppedFiles={droppedFiles}
            setDroppedFiles={setDroppedFiles}

            windowHeight={windowHeight}
          />
        }



        {/* CHANGELOG PANEL */}
        <VideoOptionsPanelChangelog
          oldSelector={oldSelector}
          showThumbnailSelector={showThumbnailSelector}
          containerRef={containerRef}

          reviewLogs={reviewLogs}
          setReviewLogs={setReviewLogs}
        
          windowHeight={windowHeight}
        />



        {(currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && currentCustomer?.free_customer))? <>
          {/* THUMBNAIL SELECTOR PANEL */}
          <VideoOptionsPanelThumbnail 
            oldSelector={oldSelector}
            showThumbnailSelector={showThumbnailSelector}
            containerRef={containerRef}

            video={video}
            selectedReview={selectedReview}
          
            thumbTime={thumbTime}
            setThumbTime={setThumbTime}
            selectedThumbnail={selectedThumbnail}
            setSelectedThumbnail={setSelectedThumbnail}
            predefThumbnails={predefThumbnails}
            setThumbFirstime={setThumbFirstime}
          
            windowHeight={windowHeight}
          />
        </> : null }



      </>}
    </div>



  </div>);
}