export default function PODCAST_EN_REMOTO() {
	return <>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500" fill='currentColor' className="scale-[0.7]">
			<g>
				<path d="M258.56,361.12c0-5.01-0.13-12.64,0.14-17.75c0.04-0.74,1.97-1.86,3.11-1.98c21.34-2.3,40.89-9.43,58.08-22.32
					c30.44-22.83,46.92-53.42,48.8-91.57c0.63-12.79,0.26-25.63,0.17-38.44c-0.08-10.44-7.04-17.28-17.42-17.41
					c-4.3-0.05-8.6-0.15-12.89,0.04c-2.71,0.12-3.69-0.76-3.62-3.54c0.16-6.9,0.08-13.8,0.04-20.7c-0.23-44.72-30.17-79.42-74.19-86
					c-3.6-0.54-7.2-0.79-10.79-0.81c-3.58,0.02-7.18,0.27-10.79,0.81c-44.01,6.58-73.95,41.28-74.19,86c-0.04,6.9-0.11,13.81,0.04,20.7
					c0.06,2.78-0.91,3.66-3.62,3.54c-4.29-0.19-8.59-0.09-12.89-0.04c-10.38,0.13-17.34,6.98-17.42,17.41
					c-0.1,12.82-0.46,25.66,0.17,38.44c1.88,38.16,18.35,68.75,48.8,91.57c17.2,12.89,36.75,20.02,58.08,22.32
					c1.14,0.12,3.07,1.24,3.11,1.98c0.27,5.11,0.14,12.74,0.14,17.75 M335.1,191.07c0.05-0.87,1.41-2.34,2.24-2.39
					c4.14-0.24,8.31-0.2,12.47-0.02c0.72,0.03,1.97,1.17,1.99,1.83c0.17,4.88,0.09,9.78,0.09,15.16c-5.27,0-9.9,0.12-14.52-0.11
					c-0.83-0.04-2.21-1.48-2.26-2.33C334.86,199.18,334.86,195.11,335.1,191.07z M317.85,144.13c0.22,4.64,0.11,9.3,0.11,14.43
					c-12.11,0-23.72,0.15-35.33-0.08c-4.2-0.08-7.24-4.09-7.16-8.38c0.07-4.42,3.26-8.16,7.79-8.26c10.77-0.23,21.54-0.15,32.31-0.02
					C316.37,141.84,317.81,143.29,317.85,144.13z M317.8,224.55c-0.04,0.79-1.55,2.15-2.4,2.16c-10.67,0.13-21.34,0.18-32-0.01
					c-4.43-0.08-7.51-3.55-7.68-7.89c-0.18-4.56,2.73-8.54,7.24-8.65c11.5-0.27,23.01-0.09,34.95-0.09
					C317.92,215.24,318.03,219.9,317.8,224.55z M248.34,77.99c0.56-0.01,1.1,0,1.66,0c0.55,0,1.1-0.01,1.66,0
					c35.34,0.8,58.14,27.95,62.16,46.65c-1.41,0-2.73,0-4.06,0c-8.35,0-16.69-0.04-25.04,0.02c-8.32,0.05-15.41,3.01-20.56,9.71
					c-6.13,7.98-7.43,16.85-3.36,26.1c4.2,9.53,11.82,14.77,22.14,15.21c10.17,0.43,20.37,0.14,30.56,0.17c1.42,0,2.83,0,4.2,0
					c0,5.97,0,11.33,0,16.93c-1.62,0-2.96,0-4.3,0c-9.7,0-19.39-0.14-29.09,0.04c-14.59,0.27-25.68,11.33-25.78,25.46
					c-0.1,14.18,11.24,25.51,25.9,25.73c8.47,0.13,16.94,0.03,25.41,0.03c1.32,0,2.64,0,4.01,0c-3.12,23.68-35.05,46.78-63.86,46.7
					c-28.8,0.08-60.73-23.03-63.86-46.7c1.38,0,2.69,0,4.01,0c8.47,0,16.94,0.1,25.41-0.03c14.67-0.22,26-11.56,25.9-25.73
					c-0.1-14.13-11.19-25.19-25.78-25.46c-9.69-0.18-19.39-0.04-29.09-0.04c-1.34,0-2.68,0-4.3,0c0-5.6,0-10.95,0-16.93
					c1.37,0,2.78,0,4.2,0c10.19-0.03,20.39,0.27,30.56-0.17c10.32-0.44,17.94-5.68,22.14-15.21c4.07-9.25,2.77-18.12-3.36-26.1
					c-5.15-6.7-12.23-9.66-20.56-9.71c-8.35-0.05-16.69-0.02-25.04-0.02c-1.32,0-2.65,0-4.06,0C190.2,105.93,213.01,78.79,248.34,77.99
					z M184.59,226.71c-0.84-0.01-2.36-1.37-2.4-2.16c-0.23-4.65-0.11-9.31-0.11-14.47c11.94,0,23.45-0.18,34.95,0.09
					c4.51,0.1,7.43,4.08,7.24,8.65c-0.17,4.35-3.26,7.82-7.68,7.89C205.93,226.89,195.26,226.84,184.59,226.71z M182.15,144.13
					c0.04-0.84,1.47-2.29,2.28-2.3c10.77-0.14,21.55-0.21,32.31,0.02c4.53,0.09,7.71,3.84,7.79,8.26c0.07,4.29-2.96,8.3-7.16,8.38
					c-11.6,0.23-23.21,0.08-35.33,0.08C182.04,153.43,181.93,148.77,182.15,144.13z M148.2,190.5c0.02-0.66,1.27-1.8,1.99-1.83
					c4.15-0.18,8.32-0.22,12.47,0.02c0.83,0.05,2.19,1.51,2.24,2.39c0.24,4.04,0.24,8.11,0,12.14c-0.05,0.86-1.44,2.29-2.26,2.33
					c-4.62,0.23-9.26,0.11-14.52,0.11C148.11,200.27,148.03,195.38,148.2,190.5z M250,325.08c-46.29-0.06-89.93-33.07-99.56-81.05
					c-1.37-6.81-1.9-13.79-2.9-21.36c5.83,0,10.48,0.06,15.13-0.02c2.71-0.04,2.41,1.85,2.48,3.55c0.59,14.45,4.65,27.86,12,40.27
					c15.56,26.26,43.8,41.32,72.86,41.33c29.06-0.02,57.3-15.07,72.86-41.33c7.35-12.41,11.41-25.82,12-40.27
					c0.07-1.7-0.24-3.59,2.48-3.55c4.65,0.08,9.3,0.02,15.13,0.02c-1.01,7.58-1.54,14.56-2.9,21.36
					C339.93,292.01,296.29,325.02,250,325.08z"/>
				<path d="M490.03,366.79c0.07-10.34-5.55-15.83-16.01-15.88c-4.55-0.02-9.1,0-14.05,0c0-2.31,0-4.08,0-5.86
					c0-67.93,0.02-135.86-0.01-203.8c-0.01-27.43-19-46.55-46.27-46.55c-25.27-0.01-50.54,0-75.81-0.01
					c5.02,8.72,8.71,18.19,10.99,28.22c21.63,0,43.25,0,64.88,0c11.62,0,18,6.41,18,18.09c0.01,68.36,0.01,136.72,0.01,205.08
					c0,1.37,0,2.75,0,4.54c-1.62,0.11-2.95,0.28-4.29,0.28c-9.94,0.01-19.89,0-29.83,0v0.09H296c-4.49,0.4-8.63,1.71-12.43,5.06
					c-1.92,1.69-5.39,2.24-8.16,2.27c-16.93,0.2-33.87,0.28-50.8-0.05c-3.34-0.07-7.06-1.52-9.87-3.42c-3.11-2.11-6.19-3.51-9.76-3.85
					H101.95v-0.1c-10.53-0.01-21.06-0.01-31.59-0.02c-0.54,0-1.07-0.18-2.01-0.34c-0.07-1.5-0.2-2.97-0.2-4.44
					c-0.01-68.36-0.01-136.72,0-205.08c0-11.71,6.35-18.1,17.98-18.1c21.66,0,43.33,0,64.99,0c2.28-10.02,5.97-19.48,10.98-28.2
					c-25.33,0.01-50.66,0.01-75.99,0.02c-3.86,0-7.82,0.3-11.57,1.15c-20.89,4.71-34.58,22.37-34.59,44.63
					c-0.04,68.21-0.02,136.42-0.02,204.63c0,1.66,0,3.31,0,5.77c-5.31,0-10.14-0.05-14.98,0.01c-9.36,0.13-15.05,5.84-15.1,15.13
					c-0.04,7.92-0.18,15.84,0.05,23.75c0.15,5.38,0.18,10.91,1.43,16.1c4.91,20.38,22.58,33.46,44.65,33.46
					c129.33,0.02,258.67,0.01,388,0.01c0.42,0,0.83,0,1.25,0c22.73-0.33,40.88-15.17,44-37.65
					C490.85,390.27,489.95,378.45,490.03,366.79z M461.42,398.14c-0.82,7.2-6.9,12.17-14.32,12.93c-1.24,0.13-2.5,0.09-3.75,0.09
					c-128.93,0-257.86,0-386.8,0c-10.06,0-17.01-4.6-18.07-13.02c-0.77-6.11-0.15-12.39-0.15-19.08c1.71,0,3.2,0,4.7,0
					c50.71,0,101.42-0.03,152.13,0.09c2.61,0.01,5.45,0.88,7.77,2.13c6.52,3.53,13.38,5.3,20.76,5.29c17.51-0.03,35.01-0.03,52.52,0
					c7.83,0.01,15.09-1.87,21.82-5.95c1.46-0.88,3.35-1.48,5.04-1.48c51.82-0.08,103.65-0.06,155.47-0.05c0.95,0,1.89,0.19,3.02,0.31
					C461.57,385.75,462.12,392.01,461.42,398.14z"/>
			</g>
		</svg>
	</>
}

