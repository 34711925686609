import gql from "graphql-tag";
export default gql`
  query GetCustomerVideosForMotions($own_user_token: String!, $own_customer_token: String!, $name: String, $production_state: String, $professor_id: String, $module: String, $localizations: Int, $has_title: Boolean) {
    getCustomerVideos(own_user_token: $own_user_token, own_customer_token: $own_customer_token, name: $name, production_state: $production_state, professor_id: $professor_id, module: $module, localizations: $localizations, has_title: $has_title) {
      id
      name
      url_name

      category_id
      
      title
      title_change_label
      old_title
      old_title_changes
      title_eng
      title_eng_change_label
      title_disabled
      chyrons_disabled

      is_priority
      is_priority_change_label
      is_priority_date

      production_state
      language
      original_language
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label
      
      chyron_states
      
      is_translation_of

      is_sound_media
      search_review_version
      search_media_time
      search_reviewed
      search_reviewed_label
      search_translated
      search_translated_label
      search_module_name
      search_module_abbreviated_name

      endDate
      createdAt
      updatedAt

      endDateFormated
      updatedAtFormated
      createdAtFormated
    }
  }
`;
