import gql from "graphql-tag";
export default gql`
  query GetAllProfessorsForMotions($customer_token: String!) {
    professorsByCustomer(customer_token: $customer_token) {
      id
      name

      positions

      email
      profile_picture
      language
      professor
      professor_code
      tags
      sex
      treatment
      rol
      recive_emails
      recive_automated_emails
      recive_review_emails
      recive_reply_emails
      enabled
      visible

      original_customer_token
      navigation_customer_tokens

      createdAt
      updatedAt

      customer_permission {
        id
        token
        icon
        abbreviated_name
        name
      }

      from_customer {
        id
        token
        icon
        abbreviated_name
        name
      }

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
