import Tooltip from '@mui/material/Tooltip';







export default function StateFilter({ mode, markStateFilter, setMarkStateFilter, simplified, stateCounter }) {



  const localCurrentUser = JSON.parse(localStorage.getItem('localCurrentUser'))



  const pendingIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M6 11.25q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q6.521 8.75 6 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q10.521 8.75 10 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q14.521 8.75 14 8.75t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"/></svg>;
  const correctionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-288q75 0 129-49t62-121q1-9-6-15.5t-17-6.5q-10 0-17 6t-9 15q-8 53-47.5 88T480-336q-60 0-102-42t-42-102q0-60 43-102t104-42l-26 27q-7 7-7.5 16.5T457-563q7 7 17 7t17-7l72-72q5-5 5-13t-5-13l-72-72q-7-7-16.5-7.5T457-733q-7 7-7 17t7 17l26 27q-81 0-138 55.5T288-480q0 80 56 136t136 56Zm0 192q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const motionInProcessIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M483-337q-29 0-56.5-10T378-378q-20-20-31-46.282-11-26.282-11-55.718 0-9.737.8-18.987.8-9.25 3.2-18.013 2-10-1.656-18.823-3.656-8.824-12.594-12.501-9.75-3.676-18.375.513t-11.625 14.243Q292-521 290-507.63q-2 13.369-2 27.63 0 38 14.712 73.416T344-344q28 28 64.5 42t75.5 14l-27 27q-7 7.364-7 17.182Q450-234 457-227q7 7 16.818 7 9.818 0 17.182-7l59.789-59.789Q562-298 562-312.182T551-337l-59-60q-7.364-7-17.182-7Q465-404 458-397q-7 7-7 16.818 0 9.818 7 17.182l25 26Zm-7-287q29.699 0 57.349 10.5Q561-603 582-582q20 20 31 46.282 11 26.282 11 55.718 0 9-.8 18.5T620-443q-2 10 1.5 19t12.587 12q9.913 3 18.897-1.324 8.985-4.325 12.11-14.703Q669-441 670.5-453.5 672-466 672-480q0-38-15-73.5T615-616q-28-28-65-41.5T474-670l29-29q7-7.364 7-17.182Q510-726 503-733q-7-7-16.818-7-9.818 0-17.182 7l-59.789 59.789Q398-662 398-647.818T409-623l60 60q7.364 7 17.182 7Q496-556 503-563q7-7 7-16.818 0-9.818-7-17.182l-27-27Zm4.276 528Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>
  const motionDoneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-96q-79 0-149-30t-122.5-82Q156-260 126-330T96-479q0-110 57-201.5T305-821q14-7 28-2t22 19q7 13 1.5 26.5T337-756q-77 41-123 115t-46 163q0 129 91 219.5T480-168q130 0 221-91t91-220q0-89-46-163T623-757q-14-8-19.5-21.5T605-805q8-14 22-19t28 2q95 49 152 140.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm-36-378v-354q0-15 10.5-25.5T480-864q15 0 25.5 10.5T516-828v354l80-80q11-11 25.5-11t25.5 11q11 11 11 25.5T647-503L505-361q-11 11-25 11t-25-11L313-503q-11-11-10.5-25.5T314-554q11-11 25.5-11t25.5 11l79 80Z"/></svg>
  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;
  const motionInsertedIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="m425-338 187-119q13-8.341 13-23.171Q625-495 612-503L425-622q-14-8-27.5-.471Q384-614.943 384-599v238q0 15.943 13.5 23.471Q411-330 425-338Zm55.276 242Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>



  function changeMarkStateFilterFunc(key) {
    var markStateFilterTMP = [...markStateFilter]
    markStateFilterTMP[key] = !markStateFilterTMP[key]
    setMarkStateFilter(markStateFilterTMP);
  }

  

  return (
    <div className={`text-[11px] rounded flex space-x-1 ${(simplified)? 'justify-end' : 'justify-between'}`}>
      <button 
        className={`
          truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-green-600 duration-300
          ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
          ${
            (markStateFilter[5])? `text-green-600 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
            : `text-gray-400 opacity-40 border-transparent`
          } 
        `}
        onClick={()=> changeMarkStateFilterFunc(5)}  
      >
        <div className='flex items-center space-x-1'>
          <div>{doneIcon}</div>
          <p>Finalizado</p>
        </div>
        {(!simplified && stateCounter) && <p>{stateCounter[5]}</p>}
      </button>



      {((mode === 'motion' || (stateCounter && stateCounter[4] && stateCounter[3] && stateCounter[2])) || ((localCurrentUser?.rol === 'admin' || localCurrentUser?.rol?.includes('editor')) && stateCounter)) && <>
        {(simplified) && 
          <div className='opacity-30 px-1 flex items-center'>
            /
          </div>
        }

        {(mode === 'motion' || stateCounter[4])?
          <button 
            className={`
              truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-purple-500 duration-300
              ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
              ${
                (markStateFilter[4])? `text-purple-500 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
                : `text-gray-400 opacity-40 border-transparent`
              } 
            `}
            onClick={()=> changeMarkStateFilterFunc(4)}
          >
            <div className='flex items-center space-x-1'>
              <div>{motionInsertedIcon}</div>
              <p>Insertado</p>
            </div>
            {(!simplified && stateCounter) && <p>{stateCounter[4]}</p>}
          </button>
        : null }

        {(mode === 'motion' || stateCounter[3])?
          <button 
            className={`
              truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-blue-500 duration-300
              ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
              ${
                (markStateFilter[3])? `text-blue-500 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
                : `text-gray-400 opacity-40 border-transparent`
              } 
            `}
            onClick={()=> changeMarkStateFilterFunc(3)}
          >
            <div className='flex items-center space-x-1'>
              <div>{motionDoneIcon}</div>
              <p>Creado</p>
            </div>
            {(!simplified && stateCounter) && <p>{stateCounter[3]}</p>}
          </button>
        : null }

        {(mode === 'motion' || stateCounter[2])?
          <button 
            className={`
              truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-orange-500 duration-300
              ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
              ${
                (markStateFilter[2])? `text-orange-500 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
                : `text-gray-400 opacity-40 border-transparent`
              } 
            `}
            onClick={()=> changeMarkStateFilterFunc(2)}
          >
            <div className='flex items-center space-x-1'>
              <div>{motionInProcessIcon}</div>
              <p>En creación</p>
            </div>
            {(!simplified && stateCounter) && <p>{stateCounter[2]}</p>}
          </button>
        : null }

        {(simplified) && 
          <div className='opacity-30 px-1 flex items-center'>
            /
          </div>
        }
      </>}


      {(simplified || stateCounter[1])?
        <button 
          className={`
            truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-yellow-500 duration-300
            ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
            ${
              (markStateFilter[1])? `text-yellow-500 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
              : `text-gray-400 opacity-40 border-transparent`
            } 
          `}
          onClick={()=> changeMarkStateFilterFunc(1)}
        >
          <div className='flex items-center space-x-1'>
            <div>{correctionIcon}</div>
            <p>Corrección</p>
          </div>
          {(!simplified && stateCounter) && <p>{stateCounter[1]}</p>}
        </button>
      : null }

      <button 
        className={`
          truncate overflow-hidden rounded space-x-1 hover:bg-gray-200 cursor-pointer flex justify-between items-center hover:text-gray-600 duration-300
          ${(simplified)? 'pl-1 pr-2 py-[2px]' : 'pl-1 pr-2 py-1 w-full border'}
          ${
            (markStateFilter[0])? `text-gray-600 ${(simplified)? 'border-transparent bg-gray-100' : 'bg-blue-100 bg-opacity-60 border-gray-300'}` 
            : `text-gray-400 opacity-40 border-transparent`
          } 
        `}
        onClick={()=> changeMarkStateFilterFunc(0)}
      >
        <div className='flex items-center space-x-1'>
          <div>{pendingIcon}</div>
          <p>Pendiente</p>
        </div>
        {(!simplified && stateCounter) && <p>{stateCounter[0]}</p>}
      </button>
    </div>
  );
}