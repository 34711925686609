import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateVideoFolder(
    
    $own_user_token: String!,

    $id: Int,
    $name: String,
    $url_name: String,
    $module_id: Int,
    $folder_id: Int,
    $customer_token: String,
    
    $visible: Boolean,
    $blocked: Boolean,

    $videos_added: String,

    $datedAt: Date,

  ) {
      createOrUpdateVideoFolder(input: { 

        own_user_token: $own_user_token,

        id: $id,
        name: $name,
        url_name: $url_name,
        module_id: $module_id,
        folder_id: $folder_id,
        customer_token: $customer_token,

        visible: $visible,
        blocked: $blocked,
        
        videos_added: $videos_added,

        datedAt: $datedAt,

    }) {
    
      id
      name
      url_name
      module_id
      folder_id
      customer_token

      visible
      blocked

      search_number_of_videos
      search_module_name
      search_module_abbreviated_name
      search_professor_names
      search_professor_ids
      search_everything_completed

      datedAt
      datedAtFormated

      thumbnails
      
      __typename

    }
  }
`;
