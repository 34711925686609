import { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'

import CreateOrUpdateVideo from './../graphql/mutations/CreateOrUpdateVideo'

import { FuncContext } from '../contexts/FuncContext';









export default function VideoSettingsStateVar({

  cellKey,
  video,
  customerSettings,
  settingsRelations,
  workflowDone,
  setWorkflowDone,

}) {
  

  const { formatNumbersToPrint } = useContext(FuncContext)



  const localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )


  const totalNumber = (customerSettings?.length)? customerSettings?.length : 0;
  var doneNumber = (settingsRelations?.length)? settingsRelations?.length : 0;




  if (!video?.is_translation_of && video?.vimeo_url && customerSettings?.filter((el) => el?.token === 'vimeo_org')?.length) { doneNumber++ }

  if (video?.is_translation_of && video?.search_reviewed && customerSettings?.filter((el) => el?.token === 'revised')?.length) { doneNumber++ }
  if (video?.is_translation_of && video?.search_translated && customerSettings?.filter((el) => el?.token === 'translated')?.length) { doneNumber++ }
  if (video?.is_translation_of && video?.vimeo_url && customerSettings?.filter((el) => el?.token === 'vimeo_loc')?.length) { doneNumber++ }
  if (video?.is_translation_of && parseInt(video?.number_of_words) && customerSettings?.filter((el) => el?.token === 'number_of_words')?.length) { doneNumber++ }







  const [showEditor, setShowEditor] = useState(false);
  const [error, setError] = useState(false);
  const [newSavedDate, setNewSavedDate] = useState(null);

  const defDateString = (newSavedDate && new Date(newSavedDate)+'' !== 'Invalid Date')? new Date(newSavedDate) : (video?.endDate && new Date(video?.endDate)+'' !== 'Invalid Date')? new Date(video?.endDate) : null;
  const defMonth = (defDateString)? ((defDateString?.getUTCMonth() + 1) < 10)? '0'+(defDateString?.getUTCMonth() + 1) : (defDateString?.getUTCMonth() + 1) : null
  const defDay = (defDateString)? ((defDateString?.getDate() < 10)? '0'+defDateString?.getDate() : defDateString?.getDate()) : null
  const defDate  = (defDateString)? defDateString?.getFullYear() + '-' + defMonth + '-' + defDay : null;

  const [date, setDate] = useState(defDate);



  const doneIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="m8.875 11.354-1.75-1.75q-.25-.25-.615-.25-.364 0-.614.25-.271.271-.271.636 0 .364.271.614l2.333 2.334q.25.27.615.27.364 0 .635-.27l4.646-4.646q.25-.25.25-.604 0-.355-.25-.605t-.615-.25q-.364 0-.614.25ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>;


  var percentageDoneNumber = (doneNumber)? ((doneNumber * 100) / totalNumber) : 0;
  percentageDoneNumber = (percentageDoneNumber >= 100)? 100 : percentageDoneNumber;
  if (workflowDone?.length && workflowDone?.includes(video?.name)) { percentageDoneNumber = 100 }


  const [createOrUpdateVideo] = useMutation(CreateOrUpdateVideo)














  function closeFunc() {
    setDate(defDate)
    setShowEditor(false)
    setError(false)
  }


  async function saveEndDateFunc(date) {
    setError(false)

    if (date && new Date(date)+'' !== 'Invalid Date') {

      const dateFormatted = new Date(date)?.toLocaleString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" })?.split(' ')[0]?.replace(/,/g, '')

      if ((newSavedDate !== null && newSavedDate !== date) || (newSavedDate === null && video?.endDateFormated !== dateFormatted)) {
        await createOrUpdateVideo({variables: {
          own_user_token: (localCurrentUser.token)? localCurrentUser.token : null,
  
          id: parseInt(video?.id),
          endDate: new Date(date),
        }})
  
        setNewSavedDate(date)
      }
      
      setShowEditor(false)
 
    } else {
      document.getElementById('end-date-cell-' + cellKey).classList.remove('animate-wiggle')
      setTimeout(() => { document.getElementById('end-date-cell-' + cellKey).classList.add('animate-wiggle') }, 100)
      setError(true)
    }
  }




  

  function markVideoWorkflowAsDoneFunc(video_name) {
    var val = false;
    var workflowDoneTMP = (workflowDone?.length)? [...workflowDone] : []
    if (workflowDoneTMP?.includes(video_name)) { workflowDoneTMP = workflowDoneTMP?.filter((el) => el !== video_name ) }
    else { workflowDoneTMP.push(video_name); val = true; }
    setWorkflowDone(workflowDoneTMP)

    createOrUpdateVideo({variables: {
      own_user_token: (localCurrentUser.token)? localCurrentUser.token : null,

      id: parseInt(video?.id),
      workflow_done: val,
    }})
  }







  return <>
      {(showEditor)?
        <div className='w-full h-[16px] relative flex items-center justify-center'>
          <input
            id={'end-date-cell-' + cellKey}
            type='date'
            className={`w-full h-[20px] absolute box-border resize-none bg-white rounded px-[2px] border-2 focus:outline-none ${(error)? 'border-red-500' : 'border-blue-500'}`}
            sx={{ fontSize: 13 }}
            defaultValue={date}
            onBlur={() => closeFunc()}
            onChange={(event) => {
              if (event?.target?.value && new Date(event?.target?.value)+'' !== 'Invalid Date') {
                setError(false)
                setDate(event?.target?.value)
              } else {
                document.getElementById('end-date-cell-' + cellKey).classList.remove('animate-wiggle')
                setTimeout(() => { document.getElementById('end-date-cell-' + cellKey).classList.add('animate-wiggle') }, 100)
                setError(true)
              }
            }}
            onKeyDown={(e) => {
              if (e?.code === "Enter") {
                e.preventDefault()
                saveEndDateFunc(date)
              } else if (e?.code === "Escape") {
                closeFunc()
              }
            }}
          />
        </div>
      :
        <div className='w-full flex space-x-1'>
          <div
            onClick={() => {
              setShowEditor(!showEditor)
              setTimeout(() => { 
                var elementTMP = document.getElementById('end-date-cell-' + cellKey)
                if (elementTMP) { elementTMP.focus(); elementTMP.select(); }
              }, 100) 
            }}
            className={`relative w-full h-[16px] bg-gray-400 bg-opacity-[0.20] hover:bg-opacity-30 duration-200 rounded cursor-pointer ${(percentageDoneNumber === 100)? 'text-black opacity-40' : 'text-gray-700'}`}
          >
            {/* <Tooltip disableInteractive title={formatNumbersToPrint(percentageDoneNumber, 0) + '% en ' + ((video?.is_translation_of)? ' la localización' : ' el vídeo original')} placement="bottom" arrow> */}
              <div className='w-full flex justify-between items-center space-x-2'>
                <p className='z-30 px-[3px]'>{(!video?.is_translation_of)? 'OG' : video?.language?.substring(0, 2)?.toUpperCase()}</p>
              </div>
            {/* </Tooltip> */}

            <div
              className={`z-10 absolute py-2 left-0 top-0 rounded overflow-hidden duration-200 bg-white pointer-events-none`}
              style={{ width: formatNumbersToPrint(percentageDoneNumber, 0) + '%' }}
            >
              <div
                className={`w-full absolute py-2 left-0 top-0 opacity-60 rounded overflow-hidden duration-200   flex justify-between items-center space-x-1 text-black
                  ${
                    (percentageDoneNumber === 100)? 'bg-green-400'
                    : (percentageDoneNumber >= 75)? 'bg-yellow-400' 
                    : (percentageDoneNumber >= 50)? 'bg-orange-400' 
                    : 'bg-red-400'
                  }
                `}
              >
              </div>
            </div>
          </div>


          <button 
            onClick={() => markVideoWorkflowAsDoneFunc(video?.name)}
            className={`w-[70px] h-[16px] px-1 flex items-center justify-center rounded duration-200
              ${(workflowDone?.length && workflowDone?.includes(video?.name))? 'text-white bg-green-500 bg-opacity-80 hover:bg-green-700' : 'text-gray-500 bg-gray-300 hover:text-white hover:bg-blue-500 bg-opacity-50 active:bg-blue-700'}
            `}
          >
            <p>{(workflowDone?.length && workflowDone?.includes(video?.name))? 'Finalizado' : 'Finalizar'}</p>
          </button>
        </div>
      }
      
      
  </>
}