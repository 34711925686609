import { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import io from "socket.io-client";
import axios from 'axios';

import GetAllVideoMessages from './../graphql/queries/GetAllVideoMessages'
import CreateOrUpdateMessage from './../graphql/mutations/CreateOrUpdateMessage'

import ReactQuillSimpleImput from './ReactQuillSimpleImput';
import UserProfilePic from './UserProfilePic'
import VideoOptionsPanelChatMessageItem from './VideoOptionsPanelChatMessageItem';

import Slide from '@mui/material/Slide';


















export default function VideoOptionsPanelChat({
  
  oldSelector,
  showThumbnailSelector,
  containerRef,

  video,
  users,
  newMessagesNum,
  setNewMessagesNum,
  droppedFiles,
  setDroppedFiles,

  windowHeight,

}) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { remove_tags, format_message } = useContext(FuncContext)
  const quillRef = useRef();

  const [socketConnection, setSocketConnection] = useState(null);

  const [items, setItems] = useState(null);
  const [messages, setMessages] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState(null);

  const [usersFiltred, setUsersFiltred] = useState(null);
  const [defUser, setDefUser] = useState(0);

  const [loadedMessages, setLoadedMessages] = useState([0, 100]);
  const [text, setText] = useState(null);
  const [tagText, setTagText] = useState(null);

  const [baseLoading, setBaseLoading] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);

  const sendIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M792-443 176-183q-20 8-38-3.5T120-220v-520q0-22 18-33.5t38-3.5l616 260q25 11 25 37t-25 37ZM200-280l474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/></svg>
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const uploadIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520v-286l36 35q11 11 27.5 11t28.5-12q11-11 11-28t-11-28L508-572q-12-12-28-12t-28 12L348-468q-11 11-11.5 27.5T348-412q11 11 27.5 11.5T404-411l36-35v286H260Z"/></svg>
  const cloudIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor"><path d="M260-200q-74.85 0-127.42-52.23Q80-304.46 80-379.31q0-68.77 47-122.07 47-53.31 116.85-57.24Q257.31-646 324.23-703q66.92-57 155.77-57 100.08 0 170.04 69.96T720-520v40h24.62q57.46 1.85 96.42 42.19Q880-397.46 880-340q0 58.85-40.58 99.42Q798.85-200 740-200H260Z"/></svg>
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-397 332-249q-18 18-41 17.5T250-250q-18-18-18-41.5t18-41.5l147-147-148-148q-18-18-17.5-41.5T250-711q18-18 41.5-18t41.5 18l147 148 148-148q18-18 41.5-18t41.5 18q18 18 18 41.5T711-628L563-480l148 148q18 18 18 41t-18 41q-18 18-41.5 18T628-250L480-397Z"/></svg>
  const fileIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M371.96 816h215.757Q603 816 613.5 805.711q10.5-10.29 10.5-25.5Q624 765 613.662 754.5 603.323 744 588.04 744H372.283Q357 744 346.5 754.289q-10.5 10.29-10.5 25.5Q336 795 346.338 805.5 356.677 816 371.96 816Zm0-144h215.757Q603 672 613.5 661.711q10.5-10.29 10.5-25.5Q624 621 613.662 610.5 603.323 600 588.04 600H372.283Q357 600 346.5 610.289q-10.5 10.29-10.5 25.5Q336 651 346.338 661.5 356.677 672 371.96 672ZM263.717 960Q234 960 213 938.85T192 888V264q0-29.7 21.15-50.85Q234.3 192 264 192h282q14 0 27.5 5t23.5 16l150 150q11 10 16 23.5t5 27.5v474q0 29.7-21.162 50.85Q725.676 960 695.96 960H263.717ZM528 396q0 15.3 10.35 25.65Q548.7 432 564 432h132L528 264v132Z"/></svg>;






  const [createOrUpdateMessage] = useMutation(CreateOrUpdateMessage)

  const [getMessages, { data: dataMessages, loading: loadingMessages }] = useLazyQuery(GetAllVideoMessages, { 
    fetchPolicy: 'cache-and-network',
    variables: { 
      own_user_token: currentUser?.token,
      own_customer_token: currentCustomer?.token,
      video_id: (parseInt(video?.id))? parseInt(video?.id) : null,
    }
  })

  




  useEffect(() => {    
    getMessages()

    const newSocketConnection = io.connect(`${process.env.REACT_APP_API_SOCKETS_ROUTE}`);
    setSocketConnection(newSocketConnection)
  }, [])


  useEffect(() => {    
    var itemsTMP = []
    var finalItemsTMP = []


    if (messages?.length) { itemsTMP = [...itemsTMP, ...messages] }
    if (droppedFiles?.length) { itemsTMP = [...itemsTMP, ...droppedFiles] }


    if (itemsTMP?.length) { 
      itemsTMP = itemsTMP?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) 

      for (var itemTMP of itemsTMP) {
        const isFile = (itemTMP?.__typename !== 'Message' && itemTMP?.user_id)? true : false
        const dateTMP = (itemTMP?.createdAt)? new Date(itemTMP?.createdAt) : null
        const dateFormatedTMP = (itemTMP?.createdAtFormated)? itemTMP?.createdAtFormated : (dateTMP)? dateTMP?.toLocaleString('es-ES', { year: "numeric", month: "2-digit", day: "2-digit" })?.split(' ')[0]?.replace(/,/g, '') + ' ' + (dateTMP?.getUTCHours() + ':' + ((dateTMP?.getMinutes() < 10)? '0' + dateTMP?.getMinutes() : dateTMP?.getMinutes())) : null
        const itemsKey = (isFile || itemTMP?.has_files)? `${dateFormatedTMP}` : `${itemTMP?.id}-${dateFormatedTMP}`
        finalItemsTMP[itemsKey] = (finalItemsTMP[itemsKey]?.length)? [...finalItemsTMP[itemsKey], itemTMP] : [itemTMP]
      }

      itemsTMP = []
      if (finalItemsTMP && Object.keys(finalItemsTMP)?.length) {
        for (var finalKey of Object.keys(finalItemsTMP)) {
          const itemTMP = finalItemsTMP[finalKey];
          if (finalItemsTMP[finalKey]) { itemsTMP.push((itemTMP?.length <= 1)? itemTMP[0] : itemTMP) }
        }
      }
    }


    setItems((itemsTMP?.length)? itemsTMP : null)
  }, [messages, droppedFiles])


  useEffect(() => {    
    if (users?.length) {
      var usersTMP = users?.filter((el) => el && el?.name?.toLowerCase()?.includes(tagText?.toLowerCase()?.replace('@', '')) && el?.enabled && (el?.visible || currentUser?.rol === 'admin') && parseInt(el?.id) !== parseInt(currentUser?.id) && (!currentUser?.rol?.includes('user') || el?.original_customer_token !== 'jUtZ97TxAs2IDUREraAO' || parseInt(el?.id) === 80))
      setUsersFiltred((usersTMP?.length)? usersTMP : null)
    } else {
      setUsersFiltred(null)
    }
  }, [users, tagText])


  useEffect(() => {    
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false)
      }, 1000 )
    }
  }, [baseLoading])


  useEffect(() => {    
    if (dataMessages?.getVideoMessages?.length) {
      setMessages([...dataMessages?.getVideoMessages]?.reverse())
    } else {
      setMessages(null)
    }
  }, [dataMessages])


  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit("join_video_chat", video?.id);
    }
  }, [socketConnection]);


  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('receive_video_messages', function (data) {
        if (data?.message?.id) {
          setMessages((messages?.length)? [data?.message, ...messages] : [data?.message])
          if (showThumbnailSelector !== 2 && parseInt(data?.message?.from_user_id) !== parseInt(currentUser?.id)) { setNewMessagesNum(newMessagesNum+1) } else { setNewMessagesNum(0) }
        }
      });
    }
  }, [socketConnection, messages, showThumbnailSelector, newMessagesNum]);


  useEffect(() => {
    if (showThumbnailSelector === 2) {
      setNewMessagesNum(0)
    }
  }, [showThumbnailSelector]);







  










  async function sendMessage() {
    const textFormated = (text && text?.replaceAll('\n', '')?.replaceAll(' ', '') !== '')? 
      text?.replaceAll('<p><br></p>', '\n')?.trim()?.replaceAll('\n', '<p><br></p>')?.replace(/<img[^>]*>/g,"")
    : null;

    if (textFormated && !loadingSend && currentUser?.token) {
      setLoadingSend(true)

      const taggedUsers = (users?.length)? users?.filter((el) => remove_tags(text)?.toLowerCase()?.includes(` @${el?.name}`?.toLowerCase())) : null
      const taggedUserIDs = (taggedUsers?.length)? taggedUsers?.map((el) => parseInt(el?.id)) : null
      const taggedUserNames = (taggedUsers?.length)? taggedUsers?.map((el) => el?.name) : null
      const newDateTMP = new Date();



      var hasFiles = await uploadFilesFunc(newDateTMP)
      var messageTMP = await createOrUpdateMessage({ variables: {
        own_user_token: currentUser?.token,
      
        text: (textFormated)? format_message(textFormated, [taggedUserIDs, taggedUserNames]) : null,
        customer_token: currentCustomer?.token,
        has_files: (hasFiles)? true : false,
  
        from_user_id: parseInt(currentUser?.id),
        to_video_id: parseInt(video?.id),
        tagged_user_ids: (taggedUserIDs?.length)? taggedUserIDs?.join(',') : null,
        tagged_user_names: (taggedUserNames?.length)? taggedUserNames?.join(',') : null,

        new_date: (newDateTMP)? newDateTMP : null,
      }})


      if (messageTMP?.data?.createOrUpdateMessage?.id) {
        await socketConnection.emit("send_video_message", { 
          message: messageTMP?.data?.createOrUpdateMessage, 
          video_id: parseInt(video?.id),
        });
      }


      setTimeout(() => {
        setLoadingSend(false)
        setText(null)
      }, 200)
    }
  }

  async function uploadFilesFunc(newDateTMP) {
    var filesTMP = (filesToUpload)? Array.from(filesToUpload) : null
    var ogDroppedFiles = (droppedFiles?.length)? [...droppedFiles] : [];
    var hasFiles = false;

    if (filesTMP?.length) {
      for (var fileKey in filesTMP) {
        var file = filesTMP[fileKey]
        const videoFormData = new FormData();
        videoFormData.append('own_user_token', currentUser?.token);
        videoFormData.append('videoId', video?.id);
        videoFormData.append('newDate', newDateTMP);
        videoFormData.append('filename', file?.name);
        videoFormData.append('file', file);
          
        var droppedFilesTMP = (ogDroppedFiles?.length)? [...ogDroppedFiles] : []
        droppedFilesTMP.push({ ...file, name: file?.name, loading: true })
        ogDroppedFiles.push({ ...file, name: file?.name, loading: true })
        setDroppedFiles(droppedFilesTMP)
        hasFiles = true;

        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}uploadFileToVideo`,
          videoFormData
        )
        
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}checkFileData`,
          { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, video_id: video?.id, name: file?.name }
        )
        
        await axios.post(
          `${process.env.REACT_APP_API_ROUTE}getVideoUploadedFiles`,
          { own_user_token: currentUser?.token, video_id: video?.id }
        ).then(async (response)=> {

          setDroppedFiles((response?.data?.length)? response?.data : null)

        })
      }
    }

    setFilesToUpload(null)
    return hasFiles;
  }




  function getLastTagFunc(textTMP, cursorPosition, log) {
    var lastTag = (textTMP && cursorPosition > 0 && textTMP?.split(' ')?.length)? remove_tags(textTMP, true, true)?.substring(0, cursorPosition) : null;
    lastTag = (lastTag)? lastTag?.split(' ')[lastTag?.split(' ')?.length-1] : null;
    lastTag = (lastTag && (lastTag?.lastIndexOf(" @") >= 0 || lastTag?.lastIndexOf("@") === 0))? lastTag?.substring(lastTag?.lastIndexOf("@"), lastTag?.length) : null;
    lastTag = (lastTag?.split(' ')?.length > 1)? null : lastTag;
    return lastTag;
  }

  function insertUserTagFunc(user, textFormated, cursorPosition, editor) {
    const startString = textFormated.substring(0, (cursorPosition - (tagText?.length + 1)))
    const replace = ` @${user?.name} `
    const endString = textFormated.substring((cursorPosition), textFormated.length)
    const textReplaced = (startString + replace + endString);

    setText(textReplaced)
    setTagText(null)

    setTimeout(() => {
      editor?.setSelection(cursorPosition + ((replace?.length - (tagText?.length + 1))), 0)
    }, 100 )
  }
  

  function removeFileToUploadFunc(fileKey) {
    const filesToUploadTMP = (filesToUpload?.length > 1)? Array.from(filesToUpload).filter((el, keyFilter) => fileKey !== keyFilter) : null
    setFilesToUpload(filesToUploadTMP); 
    document.getElementById('new-video-files').value = filesToUploadTMP;
  }













  return (<>

    <Slide direction={(oldSelector >= 2)? "right" : "left"} in={showThumbnailSelector === 2} container={containerRef.current}>
      <div className={`rounded-lg absolute top-0 w-full h-full`}>
        <div className={`relative w-full h-full flex flex-col text-sm bg-gray-100 border border-gray-400 border-opacity-60 rounded-lg`}>




          <div className='w-full px-2 py-[6px] text-xs bg-gray-100 border-b border-gray-400 border-opacity-60 rounded-t-lg'>
            <p className='opacity-30 truncate'>Chat del vídeo {(currentCustomer?.setting_tittle_is_relevant)? video?.name + ' / ' + video?.title : video?.name}</p>
          </div>




          {/* LoadingScreen */}
          {(baseLoading)?
            <div className='absolute h-[calc(100%-48px)] w-full flex justify-center items-center bg-gray-50 opacity-[0.95] z-[999]'>
              <div className='w-full h-full flex justify-center items-center space-x-1 bg-gray-300 animate-pulse'>
                {loadingIcon}
                <p>Cargando mensajes</p>
              </div>
            </div>
          : null }




          {/* Messages */}
          <div className='p-[6px] pb-2 w-full h-full smallScrollBar overflow-y-auto bg-gradient-to-b from-gray-200 to-gray-300 bg-opacity-40 flex flex-col-reverse'>
            {items?.slice(loadedMessages[0], loadedMessages[1])?.map((el, key) => {
              return <VideoOptionsPanelChatMessageItem 
                itemkey={parseInt(key)}
                items={items}

                video={video}
                users={users}
                el={(el?.length)? el[0] : el} 
                elGroup={(el?.length > 1)? el : null} 
              />
            })}

            {(loadedMessages[1] < (messages?.length - 1))?
              <button onClick={() => setLoadedMessages([0, loadedMessages[1] + 50])} className='w-full flex items-center justify-between space-x-3 px-3 opacity-50 text-blue-500 hover:opacity-100 grayscale hover:grayscale-0 whitespace-nowrap duration-200'>
                <hr className='w-full border-blue-500 border-[1.5px] rounded border-dashed'/>
                <p>Cargar mas</p>
                <hr className='w-full border-blue-500 border-[1.5px] rounded border-dashed'/>
              </button>
            : null }

            {(!items?.length)? 
              <div className='m-2 px-2 py-[6px] bg-gray-300 rounded opacity-60 text-gray-600'>
                <p>No hay mensajes en este chat.</p>
              </div>
            : null }
          </div>




          {/* User list */}
          {(usersFiltred?.length && tagText)? 
            <div className='w-full flex justify-center absolute bottom-[60px] px-2 pt-2'>
              <div id='scroll-chat-notis' className='group/userTags w-full max-w-[400px] space-y-1 p-2 bg-white border border-gray-400 border-opacity-60 rounded max-h-[200px] overflow-auto'>
                {usersFiltred?.map((user, userKey) => {
                  return <button 
                    key={`chat-user-list-${user?.id}`}
                    id={`chat-user-list-${userKey}`}
                    className={`w-full flex items-center space-x-2 px-[6px] py-[4px] text-left rounded hover:bg-blue-100 active:bg-blue-200 focus:bg-blue-100
                      ${(parseInt(userKey) !== parseInt(defUser))? '' : 'bg-gray-200 bg-opacity-60'}
                    `}
                    onClick={() => {
                      const cursorPosition = ((quillRef.current.editor?.getSelection(true)?.index)? quillRef.current.editor?.getSelection(true)?.index : 0);
                      const textTMP = (quillRef?.current?.value && remove_tags(quillRef?.current?.value))? quillRef?.current?.value : null;
                      const textFormatedTMP = (textTMP && remove_tags(textTMP))? remove_tags(textTMP) : null;
    
                      if (tagText && user?.id) {
                        insertUserTagFunc(user, textFormatedTMP, cursorPosition, quillRef?.current?.editor)
                      }
                    }}
                  >
                    <div>
                      <UserProfilePic user={user} size={20} clickable={false} checkPictureExists={false}/>
                    </div>
                    <p>{user?.name}</p>
                  </button>
                })}
              </div>
            </div>
          : null }




          {/* Text bar */}
          <div className='w-full flex items-center space-x-1 p-2 pr-1 border-t border-gray-400 border-opacity-60'>
            <div 
              className='w-full'
              onClick={(event) => {
                if (text) {
                  const cursorPosition = ((quillRef.current.editor?.getSelection(true)?.index)? quillRef.current.editor?.getSelection(true)?.index : 0);
                  const textTMP = (text && remove_tags(text))? text : null;
                  const lastTag = getLastTagFunc(textTMP, cursorPosition, true);
                  setTagText((textTMP && lastTag)? lastTag : null)
                } else if (!text && tagText) {
                  setTagText(null)
                }
              }}
            >
              <ReactQuillSimpleImput 
                id="chat-text-input"
                placeholder={'Escribe un mensaje...'}
                smallEditor={true}
                forcesmallEditor={loadingSend}
                replacementRef={quillRef}
                
                disabled={loadingSend}
                value={(text && remove_tags(text))? text : ''}
                
                onChange={(value, quillRef) => {
                  const cursorPosition = ((quillRef.current.editor?.getSelection(true)?.index)? quillRef.current.editor?.getSelection(true)?.index : 0);
                  const textTMP = (value && remove_tags(value))? value : null;
                  const lastTag = getLastTagFunc(textTMP, cursorPosition);

                  if (textTMP && lastTag) { setTagText(lastTag) } else { setTagText(null) }
                  setText(textTMP)
                }} 
                onKeyDown={(event, quillRef) => {
                  const cursorPosition = ((quillRef.current.editor?.getSelection(true)?.index)? quillRef.current.editor?.getSelection(true)?.index : 0);
                  const textTMP = (quillRef?.current?.value && remove_tags(quillRef?.current?.value))? quillRef?.current?.value : null;
                  const textFormatedTMP = (textTMP && remove_tags(textTMP))? remove_tags(textTMP) : null;
                  const lastTag = getLastTagFunc(textTMP, cursorPosition, (event.code === 'Space' && event.ctrlKey && textTMP));

                  /* 
                  if (event.code === 'ArrowDown' && defUser < usersFiltred?.length) { setDefUser(defUser+1) }
                  if (event.code === 'ArrowUp' && defUser) { setDefUser(defUser-1) } 
                  */

                  if (event.code === 'Space' && event.ctrlKey) {
                    setTagText((textTMP && lastTag)? lastTag : null)
                  } 

                  if(!loadingSend && event.code === 'Enter' && !event.ctrlKey && !event.shiftKey) {
                    if (tagText && usersFiltred?.length && usersFiltred[0]?.id) {
                      insertUserTagFunc(usersFiltred[0], textFormatedTMP, cursorPosition, quillRef?.current?.editor)
                    } else {
                      sendMessage()
                      setTagText(null)
                    }
                  }
                }}
                enterException={(range, context) => (tagText)? true : false }
              />
            </div>




            {/* Buttons */}
            <button 
              onClick={()=> {
                if (filesToUpload) { setFilesToUpload(null); document.getElementById('new-video-files').value = ""; } 
                else { document.getElementById('new-video-files')?.click(); }
              }} 
              className={`p-1 bg-gray-300 bg-opacity-50 hover:text-blue-500 active:animate-wiggle duration-200 rounded disabled:opacity-50 disabled:pointer-events-none ${(filesToUpload)? 'text-blue-400 hover:text-red-500' : 'text-gray-400'}`}
              disabled={(loadingSend)? true : false}
            >
              <input id="new-video-files" hidden multiple accept="*" type="file" onChange={(e) => setFilesToUpload(e.target.files)}/>

              <div className='w-[25px] flex items-center justify-center'>
                {(filesToUpload && !loadingSend)? <div className='absolute text-gray-50 pt-[3px] scale-[0.6]'>{cancelIcon}</div> : null}
                <div>{(filesToUpload && loadingSend)? loadingIcon : (filesToUpload)? cloudIcon : uploadIcon}</div>
              </div>
            </button>

            <button
              onClick={() => (!loadingSend)? sendMessage() : null}
              className={`p-1 bg-gray-300 bg-opacity-50 text-gray-400 hover:text-blue-500 active:animate-wiggle duration-200 rounded disabled:opacity-50 disabled:pointer-events-none`}
              disabled={(loadingSend || (!filesToUpload && !text))? true : false}
            >
              <div className='w-[25px] flex items-center justify-center'>{(loadingSend)? loadingIcon : sendIcon}</div>
            </button>
          </div>




          {/* Files */}
          <div className={`duration-200 ${(filesToUpload)? 'h-[38px]' : ' h-[0px]'} ${(loadingSend)? 'opacity-50 pointer-events-none' : ''}`}>
            <div className='mx-2 mb-2 overflow-auto rounded smallScrollBar'>
              <div className='text-xs flex whitespace-nowrap space-x-1'>
                {((filesToUpload)? Array.from(filesToUpload) : [])?.map((element, fileKey) => {
                  return <div 
                    key={`file-chat-${fileKey}`} 
                    className='flex space-x-[1px] items-center m-[2px] border border-blue-500 text-blue-500 bg-blue-100 rounded pr-1 py-[2px] duration-300 cursor-pointer'
                  >
                    <div className='scale-90'>{fileIcon}</div>
                    <p>{(element?.name?.length > 25)? element?.name?.substring(0, 24) + '...' : element?.name}</p>
                    <div onClick={() => removeFileToUploadFunc(fileKey)} className='scale-90 text-red-500 hover:scale-100'>{cancelIcon}</div>
                  </div>
                })}
              </div>
            </div>
          </div>



        </div>
      </div>
    </Slide>
      
  </>
  );
}