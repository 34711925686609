import { useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { LayoutContext } from '../contexts/LayoutContext'
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from 'axios'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';










export default function MenuVideoSharing({ anchorEl, setAnchorEl, video, shadow, professors }) {




  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { setSuccessAlertMsg, setErrorAlertMsg } = useContext(LayoutContext)

  const [tokenGenerated, setTokenGenerated] = useState((video?.has_token)? true : false);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const vimeoIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill='currentColor'><path d="M0 0v24h24v-24h-24zm19.249 10.732c-1.628 3.478-5.558 8.213-8.042 8.213-2.448 0-2.802-5.221-4.139-8.696-.658-1.709-1.083-1.317-2.316-.454l-.752-.97c1.798-1.581 3.599-3.418 4.705-3.52 1.245-.12 2.012.731 2.299 2.554.379 2.396.908 6.114 1.832 6.114.72 0 2.494-2.95 2.585-4.004.162-1.544-1.135-1.591-2.261-1.109 1.781-5.836 9.194-4.761 6.089 1.872z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>

  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  async function generatePublicUrlFunc(e) {
    e.preventDefault();
    setLoading(true)

    const urlResponse = await axios.post(`${process.env.REACT_APP_API_ROUTE}getPublicVideoToken`, { own_user_token: currentUser?.token, video_id: video?.id })
    const token = (urlResponse?.data)? urlResponse?.data : null
    const public_url = `${process.env.REACT_APP_ROUTE}shared-video/${token}`

    navigator.clipboard.writeText(public_url);

    setTimeout(() => {
      setLoading(false)
      setSuccessAlertMsgFunc('¡URL copiada correctamente!')
      setTokenGenerated(true)
    }, 100 )
  }

  async function removePublicUrlFunc(e) {
    e.preventDefault();
    setLoadingRemove(true)

    const urlResponse = await axios.post(`${process.env.REACT_APP_API_ROUTE}removePublicVideoToken`, { own_user_token: currentUser?.token, video_id: video?.id })
    const response = (urlResponse?.data)? urlResponse?.data : null

    setTimeout(() => {
      setLoadingRemove(false)
      if (response) { setSuccessAlertMsgFunc('¡URL eliminada correctamente!') } else { setErrorAlertMsgFunc('¡La URL no se pudo eliminar!') }
      setTokenGenerated(false)
    }, 100 )
  }



  function setSuccessAlertMsgFunc(message) {
    setSuccessAlertMsg(message)
    setAnchorEl(null)
  }

  function setErrorAlertMsgFunc(message) {
    setErrorAlertMsg(message)
    setAnchorEl(null)
  }


  const professors_list = (video?.professors?.length)? video?.professors : (((video?.extra_professors?.split(',')?.length)? [video?.professor, ...video?.extra_professors?.split(',')] : [video?.professor])?.filter((el) => !el?.vinculated_to_id))




  
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={(anchorEl /* && anchorEl?.id === 'share-vid-'+video?.id */)? true : false}
      onClose={() => setAnchorEl(null)}
      /* onClick={() => setAnchorEl(null)} */
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: shadow? 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))' : '',
          mt: 1.5,
          ml: 2.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 2,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 27,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className='p-2'>

        <div className='w-[270px] bg-gray-100 rounded-md mb-2 overflow-hidden'>
          {(professors_list?.length)? 
            professors_list?.filter((el) => !el?.vinculated_to_id)?.map((element, key) => {
              var profeTMP = (element?.id)? element : professors?.filter((prof)=> parseInt(prof?.id) === parseInt(element))[0]

              return (profeTMP?.name)? 
                <div key={'profe-'+profeTMP?.id}>
                  {(key !== 0) && <hr className='border-gray-300' />}

                  <CopyToClipboard text={profeTMP?.professor_code}>
                    <MenuItem onMouseUp={() => setSuccessAlertMsgFunc('¡URL copiada correctamente!')} style={{transitionProperty: 'all', transitionDuration: '300ms', paddingInline: 13 }}>
                      <div className={`w-full`}>
                        <p className={`w-full text-xs text-gray-400 pb-1 ${(key === 0)? '' : 'pt-2'}`}>Credenciales para {profeTMP?.name}:</p>

                        <div className='flex'>
                          <div className='w-full flex items-center space-x-1'>
                            <p>{profeTMP?.professor_code}</p>
                            <p className='opacity-40 text-xs'>(user/pwd)</p>
                          </div>

                          {copyIcon}
                        </div>
                      </div>
          
                    </MenuItem>
                  </CopyToClipboard>
                </div>
              : null
            })
          :
            <p className='text-xs text-gray-400 py-1 p-2'>Este {currentCustomer?.label_professor?.toLowerCase()} no tiene credenciales</p>
          }
        </div>


        {/* VIDEO URL */}
        {(currentCustomer?.token && video?.name && currentUser?.rol === 'admin') && 
          <div className='flex items-center space-x-1'>
            <button
              onMouseUp={generatePublicUrlFunc}
              className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-start text-gray-500 hover:bg-gray-100 hover:text-blue-500 active:scale-95 duration-200 rounded`}
            >
              <div className='w-full text-sm'>Copiar enlace público</div>
              {(loading)? loadingIcon : copyIcon}
            </button>

            {(video?.has_token || tokenGenerated) && 
              <button
                onMouseUp={removePublicUrlFunc}
                className={`p-1 flex items-center justify-between space-x-4 text-start text-gray-500 hover:bg-gray-100 hover:text-red-500 active:scale-95 duration-200 rounded`}
              >
                <div className='scale-95'>{(loadingRemove)? loadingIcon : removeIcon}</div>
              </button>
            }
          </div>
        }


        {/* VIDEO URL */}
        {(currentCustomer?.token && video?.name) &&
          <CopyToClipboard text={`${process.env.REACT_APP_ROUTE}${currentCustomer?.abbreviated_name}/${(video?.search_module_abbreviated_name)? video?.search_module_abbreviated_name : 'extra'}/${video?.url_name}`}>
            <button
              onMouseUp={() => setSuccessAlertMsgFunc('¡URL copiada correctamente!')}
              className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-start text-gray-500 hover:bg-gray-100 hover:text-blue-500 active:scale-95 duration-200 rounded`}
            >
              <div className='w-full text-sm'>Copiar enlace del vídeo</div>
              {copyIcon}
            </button>
          </CopyToClipboard>
        }


        {/* VIDEO URL */}
        {(currentCustomer?.token && video?.videoGroup && currentCustomer?.videoGroup === 'name-professor-language') &&
          <CopyToClipboard text={(process.env.REACT_APP_ROUTE + 'videos/' + (video?.url_name?.split('-')[0])).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}>
            <button
              onMouseUp={() => setSuccessAlertMsgFunc('¡URL copiada correctamente!')}
              className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-start text-gray-500 hover:bg-gray-100 hover:text-blue-500 active:scale-95 duration-200 rounded`}
            >
              <div className='w-full text-sm'>Copiar enlace del tema</div>
              {copyIcon}
            </button>
          </CopyToClipboard>
        }

        <button
          onMouseUp={() => { window.open(video?.vimeo_url, '_blank').focus(); setAnchorEl(null); } }
          className={`w-full p-1 pl-2 flex items-center justify-between space-x-4 text-start text-gray-500 hover:bg-gray-100 hover:text-blue-500 active:scale-95 disabled:opacity-50 disabled:pointer-events-none duration-200 rounded`}
          disabled={!(video?.vimeo_url && video?.vimeo_url?.replaceAll(' ', '') !== '')? true : false}
        >
          <div className='w-full text-sm'>Ver vídeo en Vimeo</div>
          <div className='rounded overflow-hidden flex'>{vimeoIcon}</div>
        </button>


      </div>
    </Menu>
  );
}