import { useState } from 'react'

import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';







export default function MenuChangeFormat({
  
  anchorEl,
  setAnchorEl,
  category,
  categoryOptions,
  saveChange,

}) {




  const [showAll, setShowAll] = useState(false);


  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>



  
  return (
    <>
      <Menu 
        anchorEl={anchorEl}
        open={(anchorEl)? true : false}
        onClose={()=> setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: '4px', zIndex: 2000 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='p-1 border border-gray-300 rounded overflow-hidden text-sm'>
          <Tooltip disableInteractive title={(showAll)? 'Mostrar solo formatos relacionados' : 'Mostrar todos los formatos'} placement="right" arrow>
            <button onClick={() => setShowAll(!showAll)} className='w-full flex justify-between items-center text-gray-400 text-xs px-1 hover:bg-gray-100 rounded'>
              <h1>Cambiar formato:</h1>

                <div className={`opacity-70 hover:opacity-100 duration-500 ${(showAll)? 'text-blue-500' : 'text-gray-600'}`}>
                  {(showAll)? visibilityOnIcon : visibilityOffIcon}
                </div>
            </button>
          </Tooltip>


          <hr className='mb-1 mt-[2px]' />


          <div className='w-full flex flex-col whitespace-nowrap'>
            {categoryOptions?.filter((el) => 

              (showAll && el?.token && el?.carpeta?.includes('C2')) || (
                !showAll
                && el?.token?.split('_')?.length === category?.token?.split('_')?.length
                && el?.token?.includes(category?.token?.replaceAll('basica', '')?.replaceAll('estandar', '')?.replaceAll('avanzada', ''))
              )
            
            )?.map(element => {
              return <button onClick={() => saveChange(element)} className={`w-full overflow-hidden px-1 py-[2px] text-left rounded hover:bg-gray-200 duration-100 ${(element?.token === category?.token)? 'bg-blue-500 bg-opacity-20' : ''}`}>
                <p>{element?.label}</p>
              </button>
            })}
          </div>
        </div>
      </Menu>
    </>
  );
}