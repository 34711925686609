import gql from "graphql-tag";
export default gql`
  query GetRecordingSet($id: ID!) {
    recordingSet(id: $id) {
      id
      name
      mark_label
      description
      show_mark_label
      customer_token

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
