import {useState, useEffect, useContext} from 'react'
import { AuthContext } from '../contexts/AuthContext';

import Menu from '@mui/material/Menu';






export default function MenuVideoPlayerMarks({video, selectedMark, anchorEl, setAnchorEl, scrollDifference}) {



  const { getJsonString } = useContext(AuthContext)


  var deffault_texts = (selectedMark && selectedMark?.texts)? getJsonString(selectedMark?.texts, true) : null 
  const markText = (deffault_texts && video?.language && deffault_texts[video?.language]?.text)? deffault_texts[video?.language]?.text : null


  const [fitstime, setFirstime] = useState(true);
  const [change, setChange] = useState(false);




  useEffect(() => {    
    if (!fitstime) {
      setChange(true)
    } else {
      setFirstime(false)
    }
  }, [scrollDifference])

  
  useEffect(() => {    
    if (anchorEl) {
      setChange(false)
    }
  }, [anchorEl])





  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={anchorEl? true : false}
      onClose={()=>setAnchorEl(null)}
      onClick={()=>setAnchorEl(null)}
      className='border border-gray-400'
      PaperProps={{
        elevation: 0,
        sx: {
          border: '1px solid rgb(200, 200, 200)',
          marginLeft: change? 1.6 : 0.8,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: change? 1 : 0.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: -1,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            borderTop: '1px solid rgb(200, 200, 200)',
            borderLeft: '1px solid rgb(200, 200, 200)',

          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className='px-3 max-w-[350px]'>
        {(selectedMark?.search_review_name) && <>
          <div className='w-full flex justify-between items-center space-x-4 text-xs text-gray-400'>
            <p className='truncate'>{selectedMark?.search_review_name}</p>
            <p>{selectedMark?.createdAtFormated}</p>
          </div>
          {(selectedMark?.observations || markText || !selectedMark?.id) && <hr className='my-1'/>}
        </>}

        {(selectedMark?.observations || markText || !selectedMark?.id) && 
          <div
            className='text-sm'
            dangerouslySetInnerHTML={{__html: 
              (selectedMark?.observations && selectedMark?.observations !== '')? selectedMark.observations.replace(/\n/g, "<br />") 
              : (markText && markText !== '')? markText.replace(/\n/g, "<br />") 
              : !selectedMark?.id? 'Esta marca se encuentra en proceso de creación...' 
              : '' 
            }}
          ></div>
        }
      </div>
    </Menu>
  );
}