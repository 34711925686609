import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'



import { AuthContextProvider } from './contexts/AuthContext'
import { FuncContextProvider } from './contexts/FuncContext'
import { LayoutContextProvider } from './contexts/LayoutContext'







import PageNotFound from './pages/404'


import SignIn from './pages/signIn'

import CustomerSelector from './pages/customerSelector'
import Customer from './pages/customer'
import CustomerEasyCreation from './pages/customerEasyCreation'



import Dashboard from './pages/dashboard'
import Billing from './pages/billing'
import Charts from './pages/charts'

import Emails from './pages/emails'
import EmailViewer from './pages/emailViewer'
import EmailTemplate from './pages/emailTemplate'

import Videos from './pages/videos'
import Video from './pages/video'
import VideoReviews from './pages/videoReviews'
import VideoUploader from './pages/videoUploader'
import VideoReviewsPublic from './pages/videoReviewsPublic'

import Motions from './pages/motions'

import Users from './pages/users'
import User from './pages/user'

import Professors from './pages/professors'
import Professor from './pages/professor'

import Segments from './pages/segments'
import Segment from './pages/segment'

import Sets from './pages/sets'
import Set from './pages/set'





import ScriptDeletOldHdReviews from './pages/_script_delete_old_hd_reviews'

import ScriptCheckCustomerDirs from './pages/_script_check_customer_dirs'

import ScriptFactura10Videos from './pages/_script_factura_10_videos'

import ScriptFinalizateAll from './pages/_script_finalizate_all'




import Correction_Bill_18_04_2024 from './pages/facturation_corrections/_correction_bill_18_04_2024'
import Correction_Bill_31_01_2024 from './pages/facturation_corrections/_correction_bill_31_01_2024_c3_duplicates'







const App = () => {
  
  
  const sesionCurrentUser = (sessionStorage.getItem('localCurrentUser'))? JSON.parse(sessionStorage.getItem('localCurrentUser')) : null
  const localCurrentUser = (sesionCurrentUser)? sesionCurrentUser : JSON.parse(localStorage.getItem('localCurrentUser'))

  const sesionCurrentCustomer = (sessionStorage.getItem('localCurrentCustomer'))? JSON.parse(sessionStorage.getItem('localCurrentCustomer')) : null
  const localCurrentCustomer = (sesionCurrentCustomer)? sesionCurrentCustomer : (localStorage.getItem('localCurrentCustomer'))? JSON.parse( localStorage.getItem('localCurrentCustomer') ) : null
  
  const localCurrentModeStorage = localStorage.getItem(localCurrentUser?.token+'-'+localCurrentCustomer?.token+'-video-window');
  const localCurrentMode = (localCurrentUser?.rol?.includes('user') && !localCurrentCustomer?.free_customer)? 'review' : (localCurrentUser?.rol?.includes('editor'))? 'motion' : (localCurrentUser?.rol?.includes('translator'))? 'translation' : (localCurrentModeStorage)? localCurrentModeStorage : 'review'


  const superUserPermissions = (localCurrentCustomer?.free_customer && localCurrentUser?.rol === 'super_user')? true : false


  return (
    <Router>
      <AuthContextProvider>
        <FuncContextProvider>
          <LayoutContextProvider>
            <HttpsRedirect>
              <Routes>



              <Route exact path='/your-billing/:customer_name/:module_name/correction_bill_04_2024' element={<Correction_Bill_18_04_2024/>}/>
              <Route exact path='/your-billing/:customer_name/:module_name/correction_bill_31_01_2024' element={<Correction_Bill_31_01_2024/>}/>
              

                <Route exact path='/your-billing/:customer_name/:bill_token' element={<Billing/>}/>

                <Route exact path="/signin" element={<SignIn deffaultTab={0} />} />
                <Route exact path="/recover-password" element={<SignIn deffaultTab={1} />} />
                <Route exact path="/recover-password-code" element={<SignIn deffaultTab={2} />} />
                <Route exact path="/recover-password-reset" element={<SignIn deffaultTab={3} />} />

                <Route exact path="/customer-selector" element={<CustomerSelector/>} />

                <Route exact path='/shared-video/:path_video_token' element={<VideoReviewsPublic/>}/>




                {(localCurrentUser?.token)? <>

                  <Route exact path='/' element={<Videos/>}/>
                  <Route exact path='/videos/name/:name?' element={<Videos/>}/>
                  <Route exact path='/videos/professor/:professorCode' element={<Videos/>}/>
                  <Route exact path='/videos/:module_name?/:folder_name_1?/:folder_name_2?/:folder_name_3?/:folder_name_4?/:folder_name_5?/:folder_name_6?/:folder_name_7?/:folder_name_8?/:folder_name_9?/:folder_name_10?/:folder_name_11?/:folder_name_12?/:folder_name_13?/:folder_name_14?/:folder_name_15?' element={<Videos/>}/>

                  <Route exact path='/mark-review/type/:pathType?' element={<Motions defMode={localCurrentMode} access={(localCurrentUser?.rol === 'admin')? true : true} />}/>
                  <Route exact path='/mark-review/:pathModule?/:pathType?' element={<Motions defMode={localCurrentMode} access={(localCurrentUser?.rol === 'admin')? true : true} />}/>
                  
                  <Route exact path='/charts' element={<Charts/>}/>



                  <Route exact path="/own-user-settings" element={<User ownUserId={localCurrentUser?.id} />} />
                  {(localCurrentUser?.rol === 'admin' || localCurrentUser?.rol === 'super_user') && <>
                    <Route exact path='/users' element={<Users/>}/>
                    <Route exact path='/user/new' element={<User professorMode={false}/>}/>
                    <Route exact path='/user/:id' element={<User professorMode={false}/>}/>
                  </>}



                  {(localCurrentUser?.rol === 'admin' || localCurrentUser?.rol === 'facturator') && <>
                    <Route exact path='/billing/:bill_token?' element={<Billing/>}/>
                  </>}



                  {/* Only Admins */}
                  {(localCurrentUser?.rol === 'admin') && <>
                    <Route exact path='/emails' element={<Emails/>}/>
                    <Route exact path='/email-viewer/:id' element={<EmailViewer/>}/>
                    <Route exact path='/email-template/:slug' element={<EmailTemplate/>}/>

                    <Route exact path='/_script_delete_old_hd_reviews' element={<ScriptDeletOldHdReviews/>}/>
                    <Route exact path='/_script_check_customer_dirs' element={<ScriptCheckCustomerDirs/>}/>
                    <Route exact path='/_script_factura_10_videos' element={<ScriptFactura10Videos/>}/>
                    <Route exact path='/_script_finalizate_all' element={<ScriptFinalizateAll/>}/>
                  </>}


                  {(localCurrentUser?.rol === 'admin' || localCurrentUser?.rol === 'super_user') && <>
                    <Route exact path='/customer/new' element={<Customer/>}/>
                    <Route exact path='/customer/easy-creation' element={<CustomerEasyCreation />}/>
                    <Route exact path='/customer/:token' element={<Customer/>}/>
                    
                    <Route exact path='/multiple-video-uploader' element={<VideoUploader/>}/>
                  </>}

                  {(!localCurrentUser?.rol?.includes('user') || superUserPermissions) && <>
                    <Route exact path='/home' element={<Dashboard/>}/>
                    <Route exact path='/dashboard' element={<Dashboard/>}/>

                    <Route exact path='/motions' element={<Motions defMode={localCurrentMode}  access={true}/>}/>

                    <Route exact path='/professors' element={<Professors/>}/>
                    <Route exact path='/professor/new' element={<Professor/>}/>
                    <Route exact path='/professor/:id' element={<User professorMode={true}/>}/>

                    <Route exact path='/segments' element={<Segments/>}/>
                    <Route exact path='/segments/new' element={<Segment/>}/>
                    <Route exact path='/segments/:id' element={<Segment/>}/>

                    <Route exact path='/sets' element={<Sets/>}/>
                    <Route exact path='/set/new' element={<Set/>}/>
                    <Route exact path='/set/:id' element={<Set/>}/>

                    <Route exact path='/video/new' element={<Video/>}/>
                    <Route exact path='/video/:name?/:id' element={<Video/>}/>
                  </>}



                  <Route exact path='/reviews/:id' element={<VideoReviews/>}/>
                  <Route exact path='/:path_customer/:path_module/:path_name?/:id/time?/:pathTime?' element={<VideoReviews defMode={localCurrentMode} />}/>
                  <Route exact path='/:path_customer/:path_module/motions/:path_name?/:id/time?/:pathTime?' element={<VideoReviews defMode={localCurrentMode} />}/>
  


                  {(localCurrentUser?.rol?.includes('translator') || localCurrentUser?.rol === 'admin') && <>
                    <Route exact path='/:path_customer/:path_module/translator/:path_name?/:id/time?/:time?' element={<VideoReviews defMode={localCurrentMode} />}/>
                  </>}



                </> : <Route path="*" element={<SignIn deffaultTab={0}/>} /> }



                <Route path='*' element={<PageNotFound />}/>



              </Routes>
            </HttpsRedirect>
          </LayoutContextProvider>
        </FuncContextProvider>
      </AuthContextProvider>
    </Router>

  )
}

export default App
