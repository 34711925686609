import gql from "graphql-tag";
export default gql`
  query GetAllVideoReviews($own_user_token: String!, $video_id: Int!) {
    videoReviewsByVideoId(own_user_token: $own_user_token, video_id: $video_id) {
      id
      log
      filename
      lowres_version
      time
      minutes
      time_added
      minutes_added
      user_id
      video_id
      dateAt
      createdAt
      updatedAt
      
      dateAtFormated
      createdAtFormated
      updatedAtFormated
      __typename
    }
  }
`;
