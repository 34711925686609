import { useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { LayoutContext } from '../contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client'
import { CopyToClipboard } from "react-copy-to-clipboard";

import CustomerSettingsList from '../components/CustomerSettingsList';

import CreateOrUpdateCustomerSetting from './../graphql/mutations/CreateOrUpdateCustomerSetting'

import CircularProgress from '@mui/material/CircularProgress';





const tokens = [
  { token: 'vimeo_org', name: 'ORG Vimeo URL', desc: 'Muestra el estado de la url de Vimeo de los vídeos originals.'},
  { token: 'vimeo_loc', name: 'LOC Vimeo URL', desc: 'Muestra el estado de la url de Vimeo de los vídeos localizados.'},
  { token: 'revised', name: 'ORG Revisado', desc: 'Muestra el estado de la revisión de los vídeos originales.'},
  { token: 'translated', name: 'LOC Traducida', desc: 'Muestra el estado de la traducción de los vídeos localizados.'},

  { token: 'number_of_marks', name: 'Número de marcas ORG', desc: 'Muestra el estado de las marcas en un vídeo original.'},
  { token: 'number_of_marks_loc', name: 'Número de marcas LOC', desc: 'Muestra el estado de las marcas en un vídeo localizado.'},
  { token: 'production_state', name: 'Estado', desc: 'Muestra el estado de producción del vídeo original.'},
  { token: 'tipology_segment', name: 'Tipología', desc: 'Muestra la tipología del vídeo original.'},
  { token: 'uploaded', name: 'Vídeo subido', desc: 'Muestra si el fichero del vídeo/audio en question ha sido subido.'},
  { token: 'download', name: 'Tipología', desc: 'Muestra el link a la versión de descarga de un vídeo, si la hay.'},
]






export default function CustomerEditorWorkflow({ customer, loading }) {


  const { currentUser } = useContext(AuthContext)
  const { setSuccessAlertMsg } = useContext(LayoutContext)
  const { token } = useParams()

  


  const [customerSettings, setCustomerSettings] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);

  /* Icon vars */
  const copyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M216 960q-29.7 0-50.85-21.15Q144 917.7 144 888V372q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195 336 205.5 346.35 216 356.7 216 372v516h420q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q672 939 661.65 949.5 651.3 960 636 960H216Zm144-144q-29.7 0-50.85-21.15Q288 773.7 288 744V264q0-29.7 21.15-50.85Q330.3 192 360 192h384q29.7 0 50.85 21.15Q816 234.3 816 264v480q0 29.7-21.15 50.85Q773.7 816 744 816H360Zm0-72h384V264H360v480Zm0 0V264v480Z"/></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  const [createOrUpdateCustomerSetting] = useMutation(CreateOrUpdateCustomerSetting)









  

  async function saveVideoData() {
    setLoadingSave(true)


    if (customerSettings?.length) {
      var count = 0;
      var customerSettingsTMP = [...customerSettings]?.sort(function(a, b) { 
        var aTextCompare = a?.setting_group + ' - ' + (a?.position+'')?.padStart(4, '0');
        var bTextCompare = b?.setting_group + ' - ' + (b?.position+'')?.padStart(4, '0');
        return aTextCompare?.localeCompare(bTextCompare);
      });
  
      for (var i in customerSettingsTMP) {
        var itemTMP = {...customerSettingsTMP[i]};
        itemTMP.id = parseInt(itemTMP.id);
        itemTMP.own_user_token = currentUser?.token;
        itemTMP.position = parseInt(count);

        if (customer?.factura10_client_id) { itemTMP.facturation_type = 0; }
  
        var numberElement = document.getElementById('group-number-' + itemTMP?.setting_group)
        var nameElement = document.getElementById('group-name-' + itemTMP?.setting_group)
  
        if (nameElement?.value && nameElement?.value?.replaceAll(' ', '') !== '' && parseInt(numberElement?.value)) {
          var newGroupName = numberElement?.value + ' - ' + nameElement?.value;
          if (newGroupName !== itemTMP?.setting_group) { itemTMP.setting_group = newGroupName; }
        }
  
        await createOrUpdateCustomerSetting({variables: itemTMP})
        count++;
      }
    }


    setTimeout(() => {
      setLoadingSave(false)
      setSuccessAlertMsg('Datos guardados correctamente.')
    }, "500")
  }











  return ((!loading && !loadingSave && token)? 
    <div className='flex-col space-y-3'>

      <CustomerSettingsList
        customer={customer}
        type={'video_checkbox'} 
        customerSettings={customerSettings}
        setCustomerSettings={setCustomerSettings}
      />

      <div className='flex justify-between items-start whitespace-nowrap' style={{ margin: 0 }}>
        <div className='text-xs text-gray-500 pt-2'>
          <p className='px-1'>Tokens automatizados</p>

          <div className='flex flex-wrap items-center'>
            {tokens?.map((element, key) => {
              return <CopyToClipboard key={'name-'+element?.name + '-' + key} text={element?.token}>
                <div className='pr-1 pb-1'>
                  <button
                    className='flex items-center space-x-1 bg-gray-300 bg-opacity-20 hover:bg-opacity-70 active:bg-gray-400 active:bg-opacity-60 border border-gray-400 border-opacity-30 rounded pl-[2px] pr-2 py-[2px] duration-200'
                  >
                    <div className='scale-90'>{copyIcon}</div>
                    <p>{element?.name}</p>
                  </button>
                </div>
              </CopyToClipboard>
            })}
          </div>
        </div>

        <button 
          onClick={() => saveVideoData()}
          className='mt-6 p-[6px] pr-2 flex items-center space-x-1 text-sm text-white bg-blue-500 hover:bg-blue-700 shadow-md active:scale-95 disabled:opacity-50 disabled:pointer-events-none rounded duration-200'
          disabled={
            (
              !((token && customer && (currentUser?.rol === 'admin' || (currentUser?.rol === 'super_user' && customer?.free_customer))) || (!token && !customer))
              || loading
              || loadingSave
            )? true : false
          }
        >
          {(loadingSave)? loadingIcon : saveIcon}
          <p>{(loadingSave)? 'Guardando' : 'Guardar'} workflow</p>
        </button>
      </div>

    </div>
  :
    <div className='w-full flex justify-center items-center space-x-4 text-gray-400 bg-gray-100 rounded-md py-[111px] shadow-md'>
      <CircularProgress color="inherit"/>
      <p>{(loadingSave)? 'Guardando' : 'Cargando' } workflow...</p>
    </div>
  );
}