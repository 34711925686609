import { useState, useEffect, useContext } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { NumericFormat } from "react-number-format";

import ReactQuillSimpleImput from './ReactQuillSimpleImput';

import CreateOrUpdateBillConcept from './../graphql/mutations/CreateOrUpdateBillConcept'
import RemoveBillConcept from './../graphql/mutations/RemoveBillConcept'

import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';









export default function ExtraBillItem({

  itemKey, 
  ogItem, 
  setOgItem,
  list,
  setList,

  video,
  setOpen,
  setDisableModeNav,

}) {
  



  const { currentUser } = useContext(AuthContext)
  const { format_texts, remove_tags, formatNumbersToPrint } = useContext(FuncContext)





  const [item, setItem] = useState(ogItem);

  const [isEditing, setIsEditing] = useState((!itemKey && itemKey !== 0)? true : false);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);



  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M630-444H228.049q-15.321 0-25.685-10.289Q192-464.579 192-479.789 192-495 202.364-505.5q10.364-10.5 25.685-10.5H630l-80-80q-11-11-11-25.5t10.522-25.5Q561-658 575.5-658q14.5 0 25.5 11l142.333 142.333Q748-500 750.5-493.7q2.5 6.3 2.5 13.5t-2.5 13.5q-2.5 6.3-7.5 11.7L601-313q-11 11-25 10.5T550.522-314Q540-325 540-339.5t11-25.5l79-79Z"/></svg>
  const pencilIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M777 458 598 279l49-49q25-25 59.5-25t59.5 25l60 60q24 24 24 59.5T826 409l-49 49ZM162 936q-17 0-29.5-12.5T120 894V774q0-8 3-15.5t9-13.5l407-407 179 179-407 407q-6 6-13.5 9t-15.5 3H162Z"/></svg>;
  const itemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M562-117q-21 21-51 21t-51-21L117-460q-9.625-9.927-15.312-23.463Q96-497 96-511v-281q0-29.7 21.15-50.85Q138.3-864 168-864h281q14.468 0 27.234 5 12.766 5 23.493 15.698L843-500q21 21 21 50.5T843-399L562-117ZM264-636q25 0 42.5-17.5T324-696q0-25-17.5-42.5T264-756q-25 0-42.5 17.5T204-696q0 25 17.5 42.5T264-636Z"/></svg>
  const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M479.887-240Q500-240 514-253.887q14-13.888 14-34Q528-308 514.113-322q-13.888-14-34-14Q460-336 446-322.113q-14 13.888-14 34Q432-268 445.887-254q13.888 14 34 14Zm.389 144Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546-619.59 546-595.282 546-573 533-556q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565-495q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591-720Q446-720 412.5-703.5 379-687 358.84-654.733 351-643 354.5-629t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>

  const billOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M333-376q12 42 42 71.5t71 40.5v15q0 14 10 23.5t24 9.5q14 0 23.5-10t9.5-24v-13q49-8 81-42t32-79q0-45-27.5-77T514-514q-61-22-80.5-37.5T414-592q0-20 17.5-33t45.5-13q28 0 49 13.5t28 36.5l59-25q-12-33-38.5-55.5T513-697v-14q0-14-9.5-23.5T480-744q-14 0-23.5 9.5T447-711v15q-45 10-72 38.5T348-591q0 45 30.5 76.5T475-460q45 16 65.5 34t20.5 42q0 26-21 43.5T488-323q-33 0-58.5-22T395-402l-62 26ZM480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Z"/></svg>
  const billOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M333-376q12 42 42 71.5t71 40.5v15q0 14 10 23.5t24 9.5q14 0 23.5-10t9.5-24v-13q49-8 81-42t32-79q0-45-27.5-77T514-514q-61-22-80.5-37.5T414-592q0-20 17.5-33t45.5-13q28 0 49 13.5t28 36.5l59-25q-12-33-38.5-55.5T513-697v-14q0-14-9.5-23.5T480-744q-14 0-23.5 9.5T447-711v15q-45 10-72 38.5T348-591q0 45 30.5 76.5T475-460q45 16 65.5 34t20.5 42q0 26-21 43.5T488-323q-33 0-58.5-22T395-402l-62 26ZM480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/></svg>




  const [createOrUpdateBillConcept] = useMutation(CreateOrUpdateBillConcept)
  const [removeBillConcept] = useMutation(RemoveBillConcept)





  useEffect( () => {
    if (item?.id && isEditing) {
      setTimeout(()=>{
        var focusDiv = document.getElementById('bill-list-item-focus-'+item?.id)
        if (focusDiv) { window.scrollTo({ top: focusDiv.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 130 }) }
      }, 200)
    }
  }, [isEditing])


  useEffect( () => { 
    if (ogItem && itemKey === null) {
      setItem(ogItem)
      setIsEditing(true)
    }
  }, [ogItem])



  useEffect( () => { 
    if (loading || loadingRemove) {
      setDisableModeNav(true)
    } else {
      setDisableModeNav(false)
    }
  }, [loading, loadingRemove])








  async function saveItemFunc() {
    setLoading(true)

    if (item && parseInt(video?.id)) {
      var itemTMP = {...item}
      if (parseInt(itemTMP.id)) { itemTMP.id = parseInt(itemTMP.id); }
      itemTMP.own_user_token = currentUser?.token;
      itemTMP.video_id = parseInt(video?.id);
      itemTMP.module_id = parseInt(video?.module_id);
      itemTMP.title = format_texts(itemTMP.title);
      itemTMP.description = format_texts(itemTMP.description);
      itemTMP.position = (itemKey + 1);


      var conceptTMP = await createOrUpdateBillConcept({variables: itemTMP})
      conceptTMP = conceptTMP?.data?.createOrUpdateBillConcept;

      if (conceptTMP) {
        editListFunc(conceptTMP, (itemKey === null)? true : false, false)
      }
    }

    setTimeout(() => {
      setLoading(false)
      if (itemKey === null) { setOpen(false); setOgItem({ price: 0, units: 100 }); } else { setIsEditing(false) }
    }, 500)
  }



  function numFunc(num, secNum) {
    return (num || num === 0)? num : secNum;
  }



  function editListFunc(itemTMP, isNew, remove) {
    var newList = (list?.length)? [...list] : [];

    if (!remove) {
      if (isNew) { newList = [itemTMP, ...newList]; } 
      else { newList[itemKey] = itemTMP; }
    } else {
      newList = newList?.filter((el) => parseInt(el?.id) !== parseInt(itemTMP?.id))
    }

    setList(newList)
  }



  function removeItemFunc() {
    setLoadingRemove(true)


    removeBillConcept({variables: {
      id: parseInt(item?.id), 
      own_user_token: currentUser? currentUser.token : null,
    }})
    editListFunc(item, false, true)


    setTimeout(() => {
      setLoadingRemove(false)
      setIsEditing(false)
    }, 500)
  }
  





  return (
    <div 
      id={'mark-item-box-'+item?.id}
      className={`
        ${(!item?.id)? '' : 'mb-7'}
        ${(loading || loadingRemove)? 'cursor-wait opacity-70' : ''}
      `}
    >

      <div 
        id={'bill-list-item-focus-'+item?.id}
        className={`
          group/box relative z-10 rounded-lg rounded-tl-md border border-gray-500 border-opacity-40 transition-all duration-300 overflow-hidden hover:border-opacity-100 hover:shadow-lg
          ${(item?.billed || item?.bill_id)? 'bg-green-100' : 'bg-gray-100'}
          ${(item?.id) && 
            'hover:bg-opacity-90 ' +
            ((item?.billed || item?.bill_id)? 'hover:bg-green-200' : 'hover:bg-gray-200')
          }
        `} 
      >

      
        {(isEditing || (!itemKey && itemKey !== 0))?
          <div className={`w-full duration-300 p-2 space-y-2`} >
            <div id={'data-box-'+item?.id} className={`w-full text-gray-600 overflow-visible transition-all duration-500 space-y-2`}>
              
              <div className='flex items-center justify-center pb-[2px] px-[1px]'>
                <ReactQuillSimpleImput 
                  id={"text_"+item?.id} 
                  label={'Título'}
                  placeholder={'Título del elemento a facturar...'}
                  singleLine={true}
                  value={(item?.title)? item?.title : ''}

                  onChange={(value) => {
                    var itemTMP = {...item};
                    itemTMP.title = (value)? remove_tags(value, true) : null
                    setItem(itemTMP)
                  }} 
                />
              </div>

              <div className='flex items-center justify-center pb-[2px] px-[1px]'>
                <ReactQuillSimpleImput 
                  id={"small_text_"+item?.id} 
                  label={'Texto intermedio'}
                  placeholder={'Texto intermedio del elemento a facturar...'}
                  singleLine={true}
                  value={(item?.small_text)? item?.small_text : ''}

                  onChange={(value) => {
                    var itemTMP = {...item};
                    itemTMP.small_text = (value)? remove_tags(value, true) : null
                    setItem(itemTMP)
                  }} 
                />
              </div>

              <div className='flex items-center justify-center pb-[2px] px-[1px]'>
                <ReactQuillSimpleImput 
                  id={"text_"+item?.id} 
                  label={'Descripción'}
                  placeholder={'Descripción del elemento a facturar...'}
                  value={(item?.description)? item?.description : ''}

                  onChange={(value) => {
                    var itemTMP = {...item};
                    itemTMP.description = value
                    setItem(itemTMP)
                  }} 
                />
              </div>
              
              <div className='flex justify-between space-x-1'>
                <NumericFormat
                  value={(item?.units || item?.units === 0)? item?.units / 100 : null}
                  customInput={TextField}
                  onValueChange={(ev) => {
                    var itemTMP = {...item};
                    itemTMP.units = (ev.floatValue)? parseInt(ev.floatValue * 100) : 0;
                    setItem(itemTMP)
                  }}
                  thousandSeparator="."
                  decimalSeparator=","

                  label="Unidades"
                  className='w-full mui-bg-white'
                  sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 25 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                  InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-14px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}
                  inputProps={{ step: "1", min: 0 }}
                />


                <div className='w-full flex items-center justify-center bg-gray-300 rounded'>
                  <NumericFormat
                    value={(item?.price || item?.price === 0)? item?.price / 100 : null}
                    customInput={TextField}
                    onValueChange={(ev) => {
                      var itemTMP = {...item};
                      itemTMP.price = (ev.floatValue)? parseInt(ev.floatValue * 100) : 0;
                      setItem(itemTMP)
                    }}
                    thousandSeparator="."
                    decimalSeparator=","

                    label="Precio/unidad"
                    className='w-full mui-bg-white'
                    sx={{ "& .MuiInputBase-root": { fontSize: 15, height: 25 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                    InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-14px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}
                    inputProps={{ step: "1"/* , min: 0 */ }}
                  />
                  <p className='pl-[6px] pr-2 text-sm text-gray-500'>€</p>
                </div>

                
                <TextField
                  select
                  label="IVA"
                  className='mui-bg-white'
                  sx={{ minWidth: 70, "& .MuiInputBase-root": { fontSize: 15, height: 25 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                  InputLabelProps={{ sx: { fontSize: 14, paddingBlock: 0.1, top: "-14px", "&.MuiInputLabel-shrink": { top: 1.5 } } }}
                  inputProps={{ step: "1", min: 0 }}

                  
                  value={(item?.iva || item?.iva === 0)? item?.iva / 100 : 21}
                  onChange={event => {
                    var itemTMP = {...item};
                    itemTMP.iva = (parseFloat(event.target.value))? parseInt(parseFloat(event.target.value) * 100) : 0;
                    setItem(itemTMP)
                  }}
                >
                  <MenuItem value={21}>21%</MenuItem>
                  <MenuItem value={14}>14%</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={5}>5%</MenuItem>
                  <MenuItem value={4}>4%</MenuItem>
                  <MenuItem value={0}>0%</MenuItem>
                </TextField>


                <div className='flex items-center opacity-50 pl-[5px] pr-1'>{rightArrowIcon}</div>


                <div className='flex items-center whitespace-nowrap space-x-1 text-xs'> {/* md:hidden xl:flex */}
                  <p className='px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                    IVA: {formatNumbersToPrint((numFunc(item?.units / 100, 0) * (numFunc(item?.price / 100, 0)) * (numFunc(item?.iva / 100, 21) / 100)), 2)}€
                  </p>
                  <p className='px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                    TOTAL: {formatNumbersToPrint(((numFunc(item?.units / 100, 0) * (numFunc(item?.price / 100, 0)) + (numFunc(item?.price / 100, 0)) * (numFunc(item?.iva / 100, 21) / 100))), 2)}€
                  </p>
                </div>
              </div>
            </div>



            <div className={`w-full flex justify-between items-end space-x-2`}>
              <div className='flex items-end space-x-2'>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<ClearIcon />}
                  onMouseUp={() => { setItem(ogItem); setIsEditing(false); if (itemKey === null) { setOpen(false) } }}
                  style={{ paddingBlock: 3, paddingInline: 8, fontSize: 12 }}
                >
                  Cancelar
                </Button>


                {(item?.id) && 
                  <LoadingButton 
                    loading={loadingRemove} 
                    color="error" 
                    loadingPosition="start" 
                    variant="contained" 
                    startIcon={<DeleteIcon />} 
                    onMouseUp={() => removeItemFunc()} disabled={loading}
                    style={{ paddingBlock: 3, paddingInline: 8, fontSize: 12 }}
                  >
                    {(loadingRemove)? 'Eliminando...' : 'Eliminar'}
                  </LoadingButton>
                }
              </div>


              <LoadingButton 
                loading={loading} 
                loadingPosition="start" 
                variant="contained" 
                startIcon={<SaveIcon />} 
                onMouseUp={() => saveItemFunc()} 
                style={{ paddingBlock: 3, paddingInline: 8, fontSize: 12 }}
                disabled={(
                  !(item?.title && remove_tags(item.title)?.replaceAll(' ', '').replaceAll('\n', '') !== '')
                  || !(item?.description && remove_tags(item.description)?.replaceAll(' ', '').replaceAll('\n', '') !== '')
                )? true : false }
              >
                {(loading)? 'Guardando...' : 'Guardar'}
              </LoadingButton>
            </div>
          </div>


        :


          <div className={`w-full duration-300 p-2 space-y-2`} >
            <div id={'data-box-'+item?.id} className={`w-full text-gray-600 overflow-visible transition-all duration-500 space-y-2`}>

              <div className='w-full px-1 flex items-center space-x-2'>
                <div className='rotate-90'>{itemIcon}</div>
                <div className='whitespace-nowrap font-bold' dangerouslySetInnerHTML={{__html: (item?.title)? item?.title : 'Sin título' }}></div>
                
                {(item?.createdAtFormated) && 
                  <Tooltip disableInteractive title={`Creado el ${item?.createdAtFormated} ${(item?.createdAtFormated !== item?.updatedAtFormated)? ' / última edición el ' + item?.updatedAtFormated : ''}`} placement="bottom" arrow>
                    <p className='text-xs text-gray-500 opacity-50 hover:opacity-100 truncate duration-200'>Creado el {item?.createdAtFormated} {(item?.createdAtFormated !== item?.updatedAtFormated)? ' / última edición el ' + item?.updatedAtFormated : ''}</p>
                  </Tooltip>
                }
              </div>

              <hr className='border-gray-300' />

              <div className='max-h-[410px] overflow-auto text-sm bg-gray-600 bg-opacity-[0.15] rounded email-viewer'>
                <div
                  className=' px-3 py-2 email-viewer'
                  dangerouslySetInnerHTML={{__html: (item?.small_text)? item?.small_text : 'Sin descripción' }}
                ></div>
              </div>

              <div className='max-h-[410px] overflow-auto text-sm bg-gray-500 bg-opacity-[0.15] rounded email-viewer'>
                <div
                  className=' px-3 py-2 email-viewer opacity-80'
                  dangerouslySetInnerHTML={{__html: (item?.description)? item?.description : 'Sin descripción' }}
                ></div>
              </div>


              <div className='flex justify-between space-x-1'>
                <div className='w-full flex items-center whitespace-nowrap space-x-1 text-xs'>
                  {((item?.units / 100) > 1) && 
                    <p className='w-full px-2 py-[3.5px] bg-gray-500 bg-opacity-[0.15] rounded'>
                      {formatNumbersToPrint(item?.units / 100, 0)} Unidades
                    </p>
                  }
                  <p className='w-full px-2 py-[3.5px] bg-gray-500 bg-opacity-[0.15] rounded'>
                    Precio {((item?.units / 100) > 1) && ' por unidad '} {formatNumbersToPrint(item?.price / 100, 2)}€
                  </p>
                  <p className='px-2 py-[3.5px] bg-gray-500 bg-opacity-[0.15] rounded'>
                    {formatNumbersToPrint(item?.iva / 100, 2)}% de IVA
                  </p>
                </div>

                <div className='flex items-center opacity-50 pl-[5px] pr-1'>{rightArrowIcon}</div>

                <div className='flex items-center whitespace-nowrap space-x-1 text-xs'>
                  <p className='px-2 py-[3.5px] bg-gray-500 bg-opacity-[0.15] rounded'>
                    IVA: {formatNumbersToPrint((numFunc(item?.units / 100, 0) * (numFunc(item?.price / 100, 0)) * (numFunc(item?.iva / 100, 21) / 100)), 2)}€
                  </p>
                  <p className='px-2 py-[3.5px] bg-gray-500 bg-opacity-[0.15] rounded'>
                    TOTAL: {formatNumbersToPrint(((numFunc(item?.units / 100, 0) * (numFunc(item?.price / 100, 0)) + (numFunc(item?.price / 100, 0)) * (numFunc(item?.iva / 100, 21) / 100))), 2)}€
                  </p>
                </div>
              </div>



              <div className={`w-full flex justify-between pl-[2px] space-x-2`}>
                {(currentUser?.rol === 'admin' || currentUser?.rol === 'facturator') && <>
                  <button disabled={item?.billed || item?.bill_id} className='truncate overflow-hidden text-gray-500 text-xs flex items-center hover:text-blue-500 disabled:pointer-events-none disabled:opacity-50 duration-300 cursor-pointer' onMouseUp={() => setIsEditing(true)}>
                    <div className='mr-[3px]'>{pencilIcon}</div>
                    <p>Editar</p>
                  </button>
                </>}

                <Tooltip disableInteractive title={`${(item?.billed || item?.bill_id)? 'Este elemento se encuentra en una factura' : 'Este elemento no ha sido facturado'}`} placement="bottom" arrow>
                  <button className={`truncate overflow-hidden ${(item?.billed || item?.bill_id)? 'text-green-500' : 'text-gray-400 opacity-60'} cursor-pointer text-xs flex items-center duration-300`}>
                    <div className='mr-[3px]'>{(item?.billed || item?.bill_id)? billOnIcon : billOffIcon}</div>
                    <p>{(item?.billed || item?.bill_id)? 'En factura' : 'No facturado'}</p>
                  </button>
                </Tooltip>
              </div>



            </div>
          </div>
        }
      </div>
    </div>
  );
}