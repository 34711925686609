import gql from "graphql-tag";
export default gql`
  query GetBillConceptsFromModule(
  
    $own_user_token: String!,
    $module_id: Int,
    $include_extras: Boolean,
    $include_c1_new: Boolean,
  
  ) {
    billConceptsFromModule(
    
      own_user_token: $own_user_token,
      module_id: $module_id,
      include_extras: $include_extras,
      include_c1_new: $include_c1_new,
    
    ) {

      id
      position
      title
      small_text
      description
      units_name
      units
      price
      iva

      bill_id
      parent_id
      video_id
      module_id

      billed

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated

      video {
        id
        name
        url_name
        vimeo_url

        factura10_product_id

        search_module_abbreviated_name
      }

    }
  }
`;
