import { useContext } from 'react'
import { FuncContext } from '../contexts/FuncContext'

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';


export default function CustomerProfilePic({customer, size, clickable, checkPictureExists, professor}) {


  const { fileExists } = useContext(FuncContext)
  var sacle = (size * 0.03).toFixed(2);


  return (
    <div>
      {(!professor && customer?.icon && (!checkPictureExists || (checkPictureExists && fileExists(process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + customer?.icon))))?
        <div style={{ padding: parseInt(size/2) }} className={`relative flex items-center justify-center rounded overflow-hidden border border-gray-400 border-opacity-60 bg-gray-100 ${clickable? 'cursor-pointer' : null}`}>
          <div className='absolute top-0 left-0 w-full h-full'>
            <img 
              className='object-cover h-full w-full'
              src={process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + customer?.icon} 
              alt='customer_profile_pic'
            />  
          </div>
        </div>
      : (professor) &&
        <div style={{ width: `${size}px`, height: `${size}px` }} className={`flex justify-center items-center bg-gray-500 rounded text-gray-300`}>
          <BusinessCenterIcon style={{ scale: sacle }}/>
        </div>
      }
    </div>
  );
}