import gql from "graphql-tag";
export default gql`
  query GetStatesByCustomer($own_user_token: String!, $own_customer_token: String!) {
    getStatesByCustomer(own_user_token: $own_user_token, own_customer_token: $own_customer_token) {
      id
      name
      color
      position
      customer_token
      original

      done
      ended_marks
      trigger_publication
      hidden

      createdAt
      updatedAt

      updatedAtFormated
      createdAtFormated
    }
  }
`;
