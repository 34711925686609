import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';



const PageNotFound = () => {




  const { showNavTop, showNavLeft } = useContext(AuthContext)

  const warningIcon = <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="currentColor"><path d="M92-120q-9 0-15.65-4.13Q69.7-128.25 66-135q-4.17-6.6-4.58-14.3Q61-157 66-165l388-670q5-8 11.5-11.5T480-850q8 0 14.5 3.5T506-835l388 670q5 8 4.58 15.7-.41 7.7-4.58 14.3-3.7 6.75-10.35 10.87Q877-120 868-120H92Zm392.18-117q12.82 0 21.32-8.68 8.5-8.67 8.5-21.5 0-12.82-8.68-21.32-8.67-8.5-21.5-8.5-12.82 0-21.32 8.68-8.5 8.67-8.5 21.5 0 12.82 8.68 21.32 8.67 8.5 21.5 8.5Zm0-111q12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-164q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v164q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63Z"/></svg>
  
  


  return (
    <div>
      <div className={`w-full min-w-[400px] flex justify-center items-center ${(showNavTop)? 'h-[calc(100vh-64px)]' : 'h-[100vh]'}`}>


        <div className='p-5 pb-20'>
          <div className='w-full flex justify-center scale-[3] mb-20 text-gray-200'>{warningIcon}</div>
          <h1 className='w-full text-center text-4xl text-gray-300'><strong>Error 404:</strong> Página no encontrada.</h1>
        </div>


      </div>
    </div>
  )
}

export default PageNotFound