import React from 'react'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';





import VIDEO_ENTREVISTA_BAKERY from '../components/SVGs/VIDEO_ENTREVISTA_BAKERY'

import VIDEO_EN_PERSONA_EN_PLATO from '../components/SVGs/VIDEO_EN_PERSONA_EN_PLATO'
import VIDEO_EN_PERSONA_EN_REMOTO from '../components/SVGs/VIDEO_EN_PERSONA_EN_REMOTO'

import VIDEO_ENTREVISTA_EN_PLATO from '../components/SVGs/VIDEO_ENTREVISTA_EN_PLATO'
import VIDEO_ENTREVISTA_EN_REMOTO from '../components/SVGs/VIDEO_ENTREVISTA_EN_REMOTO'

import FALSO_DIRECTO_EN_PLATO from '../components/SVGs/FALSO_DIRECTO_EN_PLATO'
import FALSO_DIRECTO_EN_REMOTO from '../components/SVGs/FALSO_DIRECTO_EN_REMOTO'

import PODCAST_EN_PLATO from '../components/SVGs/PODCAST_EN_PLATO'
import PODCAST_EN_REMOTO from '../components/SVGs/PODCAST_EN_REMOTO'




const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1000,
  },
});






export default function VideoFormatIcons({ category, note, showLabel }) {




  const questionIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor' className='scale-[0.6]'><path d="M479.887-240Q500-240 514-253.887q14-13.888 14-34Q528-308 514.113-322q-13.888-14-34-14Q460-336 446-322.113q-14 13.888-14 34Q432-268 445.887-254q13.888 14 34 14Zm.389 144Q401-96 331-126q-70-30-122.5-82.5T126-330.958q-30-69.959-30-149.5Q96-560 126-629.5t82.5-122Q261-804 330.958-834q69.959-30 149.5-30Q560-864 629.5-834t122 82.5Q804-699 834-629.276q30 69.725 30 149Q864-401 834-331q-30 70-82.5 122.5T629.276-126q-69.725 30-149 30ZM480-168q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Zm3.225-172q25.313 0 44.044 16.205Q546-619.59 546-595.282 546-573 533-556q-13 17-30 31-23 20-41 45t-17 56q0 14 10.08 22.5t23.52 8.5q14.4 0 24.4-8.5 10-8.5 13-22.5 5-22 19-38.844T565-495q23-22 37.5-49t14.5-58q0-51-39.5-84.5T483.591-720Q446-720 412.5-703.5 379-687 358.84-654.733 351-643 354.5-629t16.5 20q14 7 28 3t23-15q11-14 27-22.5t34.225-8.5Z"/></svg>;
  const peaceIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20px" height="20px" viewBox="0 0 256 256" id="Flat"><path d="M216.957,189.16846a108,108,0,1,0-177.91406,0c.15527.262.31055.52417.48828.77783.124.177.26709.332.39844.50024a107.91163,107.91163,0,0,0,176.14062,0c.13135-.16821.27442-.32324.39844-.50024C216.64648,189.69263,216.80176,189.43042,216.957,189.16846ZM212,128a83.45668,83.45668,0,0,1-9.041,37.83789L140,121.75293V44.87085A84.11944,84.11944,0,0,1,212,128ZM116,44.87085v76.88208l-62.959,44.085A83.9265,83.9265,0,0,1,116,44.87085ZM66.83057,185.481,116,151.05176v60.07739A83.8642,83.8642,0,0,1,66.83057,185.481ZM140,211.12915V151.05176l49.16943,34.4292A83.8642,83.8642,0,0,1,140,211.12915Z"/></svg>




  function renderSwitch(code, name) {

    if (name?.includes('Bakery') || name?.includes('bakery')) return <VIDEO_ENTREVISTA_BAKERY />

    else if (code?.includes('doctores_plato') || code?.includes('entrevista_plato')) return <VIDEO_ENTREVISTA_EN_PLATO />
    else if (code?.includes('doctores_remoto') || code?.includes('entrevista_remoto')) return <VIDEO_ENTREVISTA_EN_REMOTO />

    else if (code?.includes('persona_plato')) return <VIDEO_EN_PERSONA_EN_PLATO />
    else if (code?.includes('persona_remoto')) return <VIDEO_EN_PERSONA_EN_REMOTO />

    else if (code?.includes('fake_live_plato')) return <FALSO_DIRECTO_EN_PLATO />
    else if (code?.includes('fake_live_remoto')) return <FALSO_DIRECTO_EN_REMOTO />

    else if (code?.includes('podcast_plato')) return <PODCAST_EN_PLATO />
    else if (code?.includes('podcast_remoto')) return <PODCAST_EN_REMOTO />
  
    else if (!code || code === 'edicion_no_facturable') return <div className='mt-[-1.5px]'><div className='scale-[0.6]'>{peaceIcon}</div></div>

    else return <div className='mt-[-1.5px]'>{questionIcon}</div>

  }




  return (
    <div className='flex space-x-2 items-center'>
      <CustomWidthTooltip disableInteractive placement="bottom" arrow
        title={
          (!showLabel || note)?
            <div className='text-xs'> 
              {(!showLabel) && <p>{(category?.token === 'edicion_no_facturable')? 'Edición no facturable' : (category?.token)? category?.token?.replaceAll('_', ' / ')?.replaceAll('doctores', 'entrevista') : 'No facturable'}</p>}
              {(note) && <p className='opacity-70'>Nota: {note}</p>}
            </div>
          : null
        }
      >
        <div className={`whitespace-nowrap overflow-hidden text-white bg-opacity-60 rounded duration-200 ${(showLabel)? 'scale-125' : 'hover:scale-125'}
          ${
            (category?.token?.includes("avanzada"))? 'bg-red-500' 
            : (category?.token?.includes("estandar"))? 'bg-orange-500' 
            : (category?.token?.includes("basic"))? 'bg-green-500' : 
            'bg-gray-500'
          }
        `}>

          <div className='w-[17px] h-[17px] overflow-hidden'>
            <div className='scale-[1.2] flex items-center justify-center'>
              {renderSwitch(category?.token, category?.label)}
            </div>
          </div>
      
        </div>
      </CustomWidthTooltip>

      {(showLabel && category?.token) &&
        <div className='bg-gray-200 rounded px-2 py-[3px]'>
          <p>{category?.token?.replaceAll('_', ' / ')?.replaceAll('doctores', 'entrevista')}</p>
       </div>
      }
    </div>
  );
}