import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import axios from 'axios';

import GetAllCustomers from './../graphql/queries/GetAllCustomers'

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';





const CustomerSelector = () => {



  const { currentUser, setCurrentUser, setCurrentCustomer, history, getJsonString } = useContext(AuthContext)
  const { fileExists, navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();

  const currentUserRoles = (currentUser?.id && !currentUser?.customer_free && currentUser?.roles)? getJsonString(currentUser?.roles) : null;




  /* Customer selector vars */
  const [customers, setCustomers] = useState(null);
  const [search, setSearch] = useState('')

  /* Icon vars */
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const customerIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M360.113 648Q340 648 326 634.113q-14-13.888-14-34Q312 580 325.887 566q13.888-14 34-14Q380 552 394 565.887q14 13.888 14 34Q408 620 394.113 634q-13.888 14-34 14Zm240 0Q580 648 566 634.113q-14-13.888-14-34Q552 580 565.887 566q13.888-14 34-14Q620 552 634 565.887q14 13.888 14 34Q648 620 634.113 634q-13.888 14-34 14ZM480.458 888q130.458 0 221-91T792 576.24q0-23.24-5-52.74-5-29.5-13-51.5-21 5-38 6.5t-40 1.5q-85.964 0-162.482-33.5T397 350q-37 78-93.5 129T170 556q-1 4-1.5 10t-.5 10q0 130 91 221t221.458 91ZM480 960q-79.376 0-149.188-30Q261 900 208.5 847.5T126 725.042q-30-69.959-30-149.5Q96 496 126 426t82.5-122q52.5-52 122.458-82 69.959-30 149.5-30 79.542 0 149.548 30.24 70.007 30.24 121.792 82.08 51.786 51.84 81.994 121.92T864 576q0 79.376-30 149.188Q804 795 752 847.5T629.869 930Q559.738 960 480 960Z"/></svg>;
  const createItemIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24" fill='currentColor'><path d="M440 616v120q0 17 11.5 28.5T480 776q17 0 28.5-11.5T520 736V616h120q17 0 28.5-11.5T680 576q0-17-11.5-28.5T640 536H520V416q0-17-11.5-28.5T480 376q-17 0-28.5 11.5T440 416v120H320q-17 0-28.5 11.5T280 576q0 17 11.5 28.5T320 616h120Zm40 360q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Z"/></svg>
  const idIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M720-440q17 0 28.5-11.5T760-480q0-17-11.5-28.5T720-520H600q-17 0-28.5 11.5T560-480q0 17 11.5 28.5T600-440h120Zm0-120q17 0 28.5-11.5T760-600q0-17-11.5-28.5T720-640H600q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560h120ZM360-440q-36 0-65 6.5T244-413q-21 13-32 29.5T201-348q0 12 9 20t22 8h256q13 0 22-8.5t9-21.5q0-17-11-33t-32-30q-22-14-51-20.5t-65-6.5Zm0-40q33 0 56.5-23.5T440-560q0-33-23.5-56.5T360-640q-33 0-56.5 23.5T280-560q0 33 23.5 56.5T360-480ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>

  const lockOpenIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M263.72-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h336v-96q0-50-35-85t-85-35q-42 0-74 26t-42 65q-3 14-14.56 21.5T324-720q-14.87 0-24.94-11.5-10.06-11.5-7.2-25.74 13.37-65.51 65.9-110.14Q410.29-912 480-912q79.68 0 135.84 56.16T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Zm.28-72h432v-384H264v384Zm216.21-120Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM264-168v-384 384Z"/></svg>
  const lockCloseIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5f6368"><path d="M263.72-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.23-135.84 56.22-56.16 136-56.16Q560-912 616-855.84q56 56.16 56 135.84v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.16 50.85Q725.68-96 695.96-96H263.72Zm216.49-192Q510-288 531-309.21t21-51Q552-390 530.79-411t-51-21Q450-432 429-410.79t-21 51Q408-330 429.21-309t51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Z"/></svg>

  const visibilityOnIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-130 0-239-69.5T68-445q-5-8-7-16.769-2-8.769-2-18T61-498q2-9 7-17 64-114 173-183.5T480-768q130 0 239 69.5T892-515q5 8 7 16.769 2 8.769 2 18T899-462q-2 9-7 17-64 114-173 183.5T480-192Z"/></svg>
  const visibilityOffIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="m637-425-62-62q4-38-23-65.5T487-576l-62-62q13.151-5 27.075-7.5Q466-648 480-648q70 0 119 49t49 119q0 14-2.5 28t-8.5 27Zm133 133-52-52q36-28 65.5-61.5T833-480q-49-101-144.5-158.5T480-696q-25.893 0-50.446 3.25Q405-689.5 380-683l-58-58q38-15 77.545-21 39.546-6 80.455-6 134 0 246.5 68.5t170.625 188.623Q901-503 902.5-495.481 904-487.962 904-480t-1.5 15.481Q901-457 898-449q-22 47-55 86t-73 71ZM480-192q-131 0-241-69.5T65.928-446.19Q61-454 59.5-462.526q-1.5-8.525-1.5-17.5Q58-489 59.5-497.5T66-514q23-45 53.764-83.981Q150.529-636.961 190-669l-75-75q-11-11-11-25t11-25q11-11 25.5-11t25.5 11l628 628q11 11 11 25t-11 25q-11 11-25.5 11T743-116L638-220q-38.403 14-77.903 21-39.5 7-80.097 7ZM241-617q-35 28-65 61.5T127-480q49 101 144.5 158.5T480-264q26.207 0 51.103-3.5Q556-271 581-277l-45-45q-14 5-28 7.5t-28 2.5q-70 0-119-49t-49-119q0-14 3.5-28t6.5-28l-81-81Zm287 89Zm-96 96Z"/></svg>



  
  const [getCustomers, { data: dataCustomers, loading: loadingCustomers }] = useLazyQuery(GetAllCustomers, {
    fetchPolicy: 'no-cache',
    variables: { 
      own_user_token: currentUser?.token,
      user_id: (currentUser?.professor)? parseInt(currentUser?.id) : null,
    }
  })






  useEffect(() => {
    localStorage.removeItem("localCurrentCustomer")
    setCurrentCustomer(null)
  
    const localCurrentUser = JSON.parse(localStorage.getItem('localCurrentUser'))
    if (!currentUser && !localCurrentUser) {
      navigate("/signin");
    } else if (!currentUser && localCurrentUser) {
      setCurrentUser(localCurrentUser)
    }

    getCustomers()
  }, [])


  useEffect(() => {
    if (customers?.length && (!currentUser || (!currentUser?.customer_free && !currentUser?.license_token && !(currentUser?.navigation_customer_tokens?.split(',')?.length > 1)))) {
      selectCurrentCustomer(customers[0])
    }
  }, [customers, currentUser])


  useEffect(() => { 
    setCustomers((dataCustomers?.customers?.length)? dataCustomers.customers : null)
  }, [dataCustomers])


  useEffect(() => { 
    if (dataCustomers?.customers?.length) {
      if (search && search.replaceAll(' ', '') !== '') {
        setCustomers(dataCustomers.customers.filter(customer => {
          var nameFound = customer.name.toLowerCase().includes(search.toLowerCase());
          var abbreviatedNameFound = customer.abbreviated_name.toLowerCase().includes(search.toLowerCase());
          return (nameFound || abbreviatedNameFound)
        }))
      } else {
        setCustomers(dataCustomers.customers)
      }
    }
  }, [dataCustomers, search])







  function selectCurrentCustomer(element) {
    sessionStorage.setItem('localCurrentCustomer', JSON.stringify(element));
    localStorage.setItem('localCurrentCustomer', JSON.stringify(element));
    setCurrentCustomer(element);

    if (history[2].includes('video-') && history[3] === '') {
      window.location.href = history[2];
    } else if (history[3].includes('video-') && history[4] === '') {
      window.location.href = history[3];
    } else {
      window.location.href = "/videos";
    }
  }


  const convertBytes = function(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes == 0) { return null }
    
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    if (i == 0) { return bytes + " " + sizes[i] }
    
    return formatNumbersToPrint((bytes / Math.pow(1024, i)).toFixed(2), 2) + " " + sizes[i]
  }


  function formatNumbersToPrint(number, decimals) {
      number = Number(`${Math.round(`${number}e2`)}e-2`);
      return (decimals || decimals === 0)? number?.toFixed(decimals)?.toString()?.replace(".", ",")?.replace(/\B(?=(\d{3})+(?!\d))/g, ".") 
      : number?.toString()?.replace(".", ",")?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }







  


  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-full min-w-[500px] p-4 pb-[10vh] max-w-[850px]">


        <div className='mb-3 px-1'>
          <div onMouseUp={() => navigate("/signin")} className='px-1 flex items-center space-x-1 text-sm text-blue-400 cursor-pointer hover:text-blue-600 active:text-blue-800 duration-200'>
            {leftArrowIcon}
            <p>Cambiar de cuenta</p>
          </div>
          <div className='flex justify-between items-baseline'>
            <h1 className='font-bold text-4xl px-2'>{(!loadingCustomers && customers?.length === 0)? 'Lo sentimos, pero este usuario no tiene acceso a ningún cliente.' : 'Selecciona un cliente'}</h1>

            <div>
              {(currentUser?.rol === 'admin' || currentUser?.license_token) && <>
                {/* {(currentUser?.license_token || currentUser?.rol === 'admin')?
                  <Tooltip disableInteractive title={(currentUser?.rol === 'admin')? "Administrar licencias" : "Tú licencia"} placement="bottom" arrow>
                    <IconButton
                      color="primary"
                      size="medium"
                      onMouseUp={(event) => (currentUser?.rol === 'admin')? navigateOrOpenFunc(event, "/licenses") : navigateOrOpenFunc(event, "/your-license") }
                    >
                      {idIcon}
                    </IconButton>
                  </Tooltip>
                : null } */}
              
                <Tooltip disableInteractive title="Crear un nuevo cliente" placement="bottom" arrow>
                  <IconButton
                    color="primary"
                    size="medium"
                    onMouseUp={(event) => navigateOrOpenFunc(event, "/customer/easy-creation") }
                  >
                    {createItemIcon}
                  </IconButton>
                </Tooltip>
              </>}
            </div>
          </div>
        </div>


        <div className='flex items-center space-x-2 px-1'>
          <div className="w-full bg-gray-100 flex rounded-lg overflow-hidden border border-gray-300 hover:border-gray-500 mx-[1px] my-[1px]  transition-opacity duration-200">
            <IconButton 
              type="button" 
              sx={{ p: '4px', borderRadius: 0 }} 
              aria-label="search"
              onMouseUp={() => document.getElementById('search-input-global').focus()}
            >
              <SearchIcon />
            </IconButton>

            <InputBase
              id="search-input-global"
              className='w-full'
              sx={{ ml: 1, flex: 1 }}
              placeholder="Busca por nombre del cliente"
              value={search}
              onChange={(element) => setSearch(element.target.value)}
            />
          </div>
        </div>

        <div className='space-y-2 max-h-[50vh] overflow-y-auto mt-[6px] p-1'>
          {(customers?.length) && 
            customers?.map((customer) => {
              const currentRol = (currentUser?.id && currentUser?.customer_free && currentUser?.roles && !currentUser?.roles?.includes('{'))? currentUser?.roles : (customer?.token && currentUserRoles && currentUserRoles[customer?.token])? currentUserRoles[customer?.token] : 'user'

              return <button 
                key={'customer-'+customer.id} 
                className={`w-full flex items-center justify-between border border-gray-200 border-opacity-75 hover:border-opacity-100 rounded-lg cursor-pointer hover:text-gray-900 text-gray-500 hover:shadow-md active:scale-[0.99] duration-200 text-start
                  ${
                    (!customer?.enabled)? 'bg-red-100 hover:bg-red-200 active:bg-red-300' 
                    : (customer?.free_customer && !currentRol?.includes('user'))? 'bg-yellow-100 hover:bg-yellow-200 active:bg-yellow-300' 
                    : 'bg-gray-100 hover:bg-blue-100 active:bg-blue-200'
                  }
                `}
              >

                <div onMouseUp={() => selectCurrentCustomer(customer)} className='w-full h-full flex items-center justify-between'>
                  <div className='m-1 relative flex items-center justify-center p-7 rounded-lg overflow-hidden border border-gray-200 bg-white text-gray-400'>
                    {(fileExists(process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + customer?.icon))?
                      <div className='absolute top-0 left-0 w-full h-full'>
                        <img
                          className='object-cover h-full w-full'
                          src={process.env.REACT_APP_API_ROUTE + 'customer_media/' + customer?.token + '/' + customer?.icon} 
                          alt='selector_customer_icon'
                        />
                      </div>
                    :
                      <div className='absolute scale-150'>{customerIcon}</div>
                    }
                  </div>


                  <div className='w-full p-[6px] pr-3'>
                    <p className='h-[22px] text-lg'>{customer.name}</p>

                    {(currentRol === 'admin' || currentRol === 'super_user') && 
                      <div className='h-[16px] flex items-center space-x-1 opacity-40 text-xs'>
                        <div className='h-[16px] w-[16px] flex items-center justify-center'>
                          {
                            (!customer?.enabled)? lockCloseIcon : 
                            (customer?.free_customer)? ((currentRol === 'super_user')? visibilityOnIcon : visibilityOffIcon) 
                            : ((currentRol === 'super_user')? visibilityOffIcon : visibilityOnIcon)
                          }
                        </div>

                        <p>
                          {(!customer?.enabled)? 'Deshabilitado' : (customer?.free_customer)? 'Autogestionado' : 'Gestionado por Clicksound'}
                        </p>
                      </div>
                    }
                  </div>
                </div>


                {(currentRol === 'admin' || (currentRol === 'super_user' && customer?.free_customer)) && 
                  <div className='flex items-center justify-end space-x-2 pr-2'>
                    {(customer?.file_size && parseInt(customer?.file_size)) &&
                      <p className='flex text-xs whitespace-nowrap opacity-75'>{convertBytes(parseInt(customer?.file_size))}</p>
                    }

                    <button onMouseUp={(event)=> navigateOrOpenFunc(event, "/customer/"+customer?.token) } type="button" className="p-2 text-sm text-gray-500 hover:text-blue-500 hover:bg-gray-100 border border-gray-300 border-opacity-0 hover:border-opacity-75 rounded duration-200">
                      <div className='scale-[1.08]'>{editIcon}</div>
                    </button>
                  </div>
                }
              </button>
            })
          }


          {(loadingCustomers && !customers) &&
            <div className='w-full text-sm bg-gray-100 border-2 border-gray-200 p-2 rounded-md text-gray-500'> 
              <p className='mb-1'>Cargando clientes...</p>
              <LinearProgress className='rounded-full'/>
            </div>
          }


        </div>
      </div>
    </div>
  )
}

export default CustomerSelector
