import gql from "graphql-tag";

export default gql`
  mutation UpdateVideoChyronState(
    
    $own_user_token: String!,
    $id: Int,
    $professor_id: Int,
    $action: String!,
    $language: String!,

    $state: Int,
    $state_change_label: String,

    $motion_state: Int,
    $motion_state_change_label: String,
    
  ) {
    updateVideoChyronState(input: { 
      
      own_user_token: $own_user_token,
      id: $id, 
      professor_id: $professor_id, 
      action: $action, 
      language: $language, 

      state: $state,
      state_change_label: $state_change_label,

      motion_state: $motion_state,
      motion_state_change_label: $motion_state_change_label,
      
    }) {
      
      id
      
      chyron_states
      
    }
  }
`;
