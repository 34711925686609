import { useContext } from 'react'
import { FuncContext } from '../contexts/FuncContext'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';



export default function UserProfilePic({ user, size, clickable, checkPictureExists, className }) {

  var sacle = (size * 0.05).toFixed(2);
  const { fileExists } = useContext(FuncContext)

  return (
    <div>
      {(user?.profile_picture && (!checkPictureExists || (checkPictureExists && fileExists(process.env.REACT_APP_API_ROUTE + 'user_icons/' + user?.profile_picture))))?
        <div style={{ padding: (size/2) }} className={`relative flex items-center justify-center rounded-full overflow-hidden border border-gray-300 bg-gray-400 ${clickable? 'cursor-pointer' : null}`}>
          <div className='absolute top-0 left-0 w-full h-full'>
            <img 
              className={`object-cover h-full w-full ${className}`}
              src={process.env.REACT_APP_API_ROUTE + 'user_icons/' + user?.profile_picture} 
              alt='user_profile_pic'
            />  
          </div>
        </div>
      :
        <div style={{ width: `${size}px`, height: `${size}px` }} className={`flex justify-center items-center ${className}`}>
          <AccountCircleIcon style={{ scale: sacle }}/>
        </div>
      }
    </div>
  );
}