import { useState, useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from "react-router-dom";
import { LayoutContext } from '../contexts/LayoutContext'
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext';
import { useNavigate } from "react-router-dom";

import GetAllCustomersForTypesByToken from '../graphql/queries/GetAllCustomersForTypesByToken';

import CustomerEditorData from '../components/CustomerEditorData';
import CustomerEditorWorkflow from '../components/CustomerEditorWorkflow';
import CustomerEditorMarks from '../components/CustomerEditorMarks';
import { Slide } from '@mui/material';





const Customer = () => {



  
  const { token } = useParams()
  const { currentUser, setCurrentUser, setHistory } = useContext(AuthContext)
  const { setCurrentNavActive } = useContext(LayoutContext)
  const { navigateOrOpenFunc } = useContext(FuncContext)
  const navigate = useNavigate();


  /* Customer vars */
  const [customer, setCustomer] = useState([]);
  const [tabHistory, setTabHistory] = useState(['home', 'home']);
  
  
  /* Icon vars */
  const homeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M192-228v-336q0-16.85 7.5-31.92Q207-611 221-622l216-162q20-14 43-14t43 14l216 162q14 11 21.5 26.08Q768-580.85 768-564v336q0 34.65-24.67 59.32Q718.65-144 684-144h-96q-15.3 0-25.65-10.35Q552-164.7 552-180v-192q0-15.3-10.32-25.65Q531.35-408 516.09-408h-71.83q-15.26 0-25.76 10.35Q408-387.3 408-372v192q0 15.3-10.35 25.65Q387.3-144 372-144h-96q-34.65 0-59.32-24.68Q192-193.35 192-228Z"/></svg>
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const markIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M396 240q-15.3 0-25.65-10.289-10.35-10.29-10.35-25.5Q360 189 370.35 178.5 380.7 168 396 168h168q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q600 219 589.65 229.5 579.3 240 564 240H396Zm83.789 433Q495 673 505.5 662.65 516 652.3 516 637V469q0-15.3-10.289-25.65-10.29-10.35-25.5-10.35Q465 433 454.5 443.35 444 453.7 444 469v168q0 15.3 10.289 25.65 10.29 10.35 25.5 10.35ZM480 960q-70 0-130.92-26.507-60.919-26.507-106.493-72.08-45.573-45.574-72.08-106.493Q144 694 144 624q0-70 26.507-130.92 26.507-60.919 72.08-106.493 45.574-45.573 106.493-72.08Q410 288 479.56 288q58.28 0 111.86 19.5T691 362l27.282-27.282Q729 324 743 324.5t25 11.5q11 11 11 25t-11 25l-26 27q35 45 54.5 98.808Q816 565.616 816 624.144 816 694 789.493 754.92q-26.507 60.919-72.08 106.493-45.574 45.573-106.493 72.08Q550 960 480 960Z"/></svg>
  const workflowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M528-432.12v-72.13Q528-534 549.15-555T600-576h24v-72h-24q-29.7 0-50.85-21.19Q528-690.37 528-720.12v-72.13Q528-822 549.15-843T600-864h120q29.7 0 50.85 21.19Q792-821.63 792-791.88v72.13Q792-690 770.85-669T720-648h-24v72h24q29.7 0 50.85 21.19Q792-533.63 792-503.88v72.13Q792-402 770.85-381T720-360H600q-29.7 0-50.85-21.19Q528-402.37 528-432.12Zm-336 288v-72.13Q192-246 213.15-267T264-288h24v-72h-24q-29.7 0-50.85-21.19Q192-402.37 192-432.12v-72.13Q192-534 213.15-555T264-576h24v-72h-24q-29.7 0-50.85-21.19Q192-690.37 192-720.12v-72.13Q192-822 213.15-843T264-864h120q29.7 0 50.85 21.19Q456-821.63 456-791.88v72.13Q456-690 434.85-669T384-648h-24v72h24q29.7 0 50.85 21.19Q456-533.63 456-503.88v72.13Q456-402 434.85-381T384-360h-24v72h24q29.7 0 50.85 21.19Q456-245.63 456-215.88v72.13Q456-114 434.85-93T384-72H264q-29.7 0-50.85-21.19Q192-114.37 192-144.12Z"/></svg>

  const tabs = {
    'home': { icon: homeIcon, label: "Información básica" }, 
    'marks': { icon: markIcon, label: "Marcas", disabled: 1 }, 
    'workflow': { icon: workflowIcon, label: "Workflow", disabled: 2 }, 
  }





  const [getCustomer, { data: dataCustomer, loading: loadingCustomer }] = useLazyQuery(GetAllCustomersForTypesByToken, {
    variables: {
      own_user_token: currentUser?.token,
      token: token
    }
  })





  



  useEffect(() => {
    setCurrentNavActive('videos')
    var localCurrentUser = JSON.parse( localStorage.getItem('localCurrentUser') )

    if (!currentUser && !localCurrentUser) {
      navigate("/signin");
    } else if (!currentUser && localCurrentUser) {
      setCurrentUser(localCurrentUser)
    }
  }, [])


  useEffect(() => {
    if (token) {
      getCustomer()
    }
  }, [token])


  useEffect(() => { 
    setCustomer((dataCustomer?.getCustomerByToken)? dataCustomer.getCustomerByToken : null)
  }, [dataCustomer])





  return (
    <div className="w-screen h-screen flex items-center justify-center overflow-hidden">
      <div className='w-full h-full max-h-[900px] lg:h-auto  min-h-[700px] max-w-[1200px] min-w-[500px] p-4'>


        <div className='pb-5 flex justify-between items-end flex-wrap md:flex-nowrap'>
          <div>
            <button 
              onMouseUp={(event) => {
                setHistory(['', '', '', '']);
                navigateOrOpenFunc(event, "/customer-selector")
              }}
              className='p-1 pr-2 flex items-center space-x-1 text-sm text-blue-500 hover:bg-gray-100 active:scale-95 rounded duration-200'
            >
              {leftArrowIcon}
              <p>Volver al selector de clientes</p>
            </button>

            <h1 className='font-bold text-4xl md:text-2xl lg:text-4xl'>
              {
                (loadingCustomer)? "Cargando..."
                : (token && customer?.name)? customer?.name
                : (token && customer?.id)? "Sin nombre asignado"
                : (!token && !customer?.name)? "Nuevo cliente"
                : 'Cliente inexistente'
              }
            </h1>
          </div>


          {/* MENUS */}
          {(token) && 
            <div className='w-full md:w-auto pt-4 md:pt-0 flex justify-end items-center space-x-2 pb-1 whitespace-nowrap'>
              {Object.keys(tabs)?.map((key) => {
                const menuObj = tabs[key]

                return <button 
                  key={`menu-${key}`}
                  onClick={() => setTabHistory([tabHistory[1], key])}
                  className={`w-full md:w-auto p-2 pr-3 flex items-center justify-center space-x-1 text-sm hover:bg-blue-300 hover:text-white border hover:border-blue-400 disabled:opacity-50 disabled:pointer-events-none shadow-md rounded-lg duration-200
                    ${(tabHistory[1] === key)? 'text-white bg-blue-500 border-blue-500' : 'bg-gray-200 text-gray-500 border-gray-300'}
                  `}
                >
                  {menuObj?.icon}
                  <p>{menuObj?.label}</p>
                </button>
              })}
            </div>
          }
        </div>


        {((token && customer) || (!token && !customer))?
          <div className='relative'>
            <div className={`w-full absolute duration-300 ${(tabHistory[1] === 'home')? '' : 'opacity-0 pointer-events-none'}`}>
              <Slide direction="right" in={(tabHistory[1] === 'home')? true : false} timeout={300}>
                <div className="w-full">
                  <CustomerEditorData 
                    customer={customer}
                    loading={loadingCustomer}
                  />
                </div>
              </Slide>
            </div>


            <div className={`w-full absolute duration-300 ${(tabHistory[1] === 'marks')? '' : 'opacity-0 pointer-events-none'}`}>
              <Slide direction={(tabHistory[0] === 'home' || tabHistory[1] === 'home')? "left" : "right"} in={(tabHistory[1] === 'marks')? true : false} timeout={300}>
                <div className="w-full">
                  <CustomerEditorMarks
                    customer={customer}
                    loading={loadingCustomer}
                  />
                </div>
              </Slide>
            </div>


            <div className={`w-full absolute duration-300 ${(tabHistory[1] === 'workflow')? '' : 'opacity-0 pointer-events-none'}`}>
              <Slide direction="left" in={(tabHistory[1] === 'workflow')? true : false} timeout={300}>
                <div className="w-full">
                  <CustomerEditorWorkflow
                    customer={customer}
                    loading={loadingCustomer}
                  />
                </div>
              </Slide>
            </div>
          </div>
        :
          <>
            <hr className='border-gray-300' />
            <p className='text-gray-400 px-1 py-4'>Es probable que este cliente haya sido eliminado o directamente no haya existido nunca.</p>
          </>
        }


      </div>
    </div>
  )
}

export default Customer
