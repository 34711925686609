import { useState, useContext } from "react"
import { AuthContext } from "../contexts/AuthContext";
import { LayoutContext } from "../contexts/LayoutContext";
import axios from 'axios'

import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';





export default function VideoCategoryHistory({ video, size }) {



    const { currentUser, currentCustomer } = useContext(AuthContext)
    const { setErrorAlertMsg } = useContext(LayoutContext)



    const [anchorEl, setAnchorEl] = useState(null);
    const [history, setHistory] = useState(null);
    const [categories, setCategories] = useState(null);
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(null);


    const historyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor"><path d="M480-144q-132 0-226.5-87.5T146-447q-2-14 8.5-23.5t25.439-9.5Q194-480 205-472t13 22q11 99 85.5 166.5T480-216q109 0 186.5-77.5T744-480q0-109-77.5-186.5T480-744q-62 0-114.547 25.6Q312.907-692.8 277-648h71q15.3 0 25.65 10.289 10.35 10.29 10.35 25.5Q384-597 373.65-586.5 363.3-576 348-576H180q-15.3 0-25.65-10.35Q144-596.7 144-612v-168q0-15.3 10.289-25.65 10.29-10.35 25.5-10.35Q195-816 205.5-805.65 216-795.3 216-780v94q46-60 114.5-95T480-816q70 0 131.133 26.6 61.134 26.6 106.4 71.867 45.267 45.266 71.867 106.4Q816-550 816-480t-26.6 131.133q-26.6 61.134-71.867 106.4-45.266 45.267-106.4 71.867Q550-144 480-144Zm36-366 90 90q11 11 10.5 25T605-370q-11 11-25.5 11T554-370l-98.712-98.712Q450-474 447-480.689t-3-13.825v-141.81q0-15.163 10.5-25.419Q465-672 480-672t25.5 10.35Q516-651.3 516-636v126Z"/></svg>
    const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;

    

    





    function loadHistory(e) {
        setLoading(true)
        setAnchorEl(e.currentTarget)
    
        axios.post(`${process.env.REACT_APP_FACTURA10_API_ROUTE}api_get_projecte_producte_categories.php`, { carpeta: null }).then( async (response) => {
            const cats = response?.data
            setCategories(cats)

            if (cats?.length) {
                axios.post(`${process.env.REACT_APP_API_ROUTE}getVideoCategoryHistory`, { own_user_token: currentUser?.token, own_customer_token: currentCustomer?.token, video_id: (video?.is_translation_of)? video?.is_translation_of : video?.id, cats: cats }).then( async (response) => {
                    
                    if (response?.data?.history?.length) {
                        setUsers(response?.data?.users)
                        setHistory(response?.data?.history)
                    } else {
                        setHistory(null)
                        setErrorAlertMsg('No se han encontrado registros de este vídeo.')
                        setAnchorEl(null)
                    }
            
                    setLoading(false)
                });
            }
        });
    }







    return <div>
        <Tooltip disableInteractive title={'Vet historial del formato'} placement="bottom" arrow>
            <button
                onClick={(e) => (!loading)? loadHistory(e) : null}
                className={`bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700 rounded border border-gray-400 border-opacity-60 duration-200 
                    ${(size === 'big')? 'p-[9.5px]' : (size === 'medium')? 'p-[6px]' : 'p-1'}
                `}
            >
                {(loading)? loadingIcon : historyIcon}
            </button>
        </Tooltip>


        {(history?.length && categories?.length)?
            <Popover
                id={video?.id}
                open={(anchorEl && history)? true : false}
                anchorEl={anchorEl}
                onClose={() => { setTimeout(() => { setAnchorEl(null) }, 400) }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: -10,
                    horizontal: 'right',
                  }}
                sx={{ '& .MuiPaper-root': { overflow: 'visible !important' } }}
                data-avoid
            >
                <div data-avoid className={`text-sm whitespace-nowrap text-left z-[30] left-[30px]`}>
                    <div data-avoid className={`absolute w-1 h-1 p-1 mt-[-4px] right-[16px] ml-auto mr-auto rotate-45 bg-white border-t border-l border-gray-400 border-opacity-60 z-[31]`}></div>


                    <div data-avoid id='clickbox' className={`p-2 min-w-[200px] bg-white border border-gray-400 border-opacity-60 rounded shadow-2xl`}>
                        <div className="space-y-1">
                            {history?.map(element => {
                                const catTMP = categories?.filter(el => parseInt(el?.id) === parseInt(element?.video_category_id))[0];
                                const ownUser = users?.filter(el => parseInt(el?.id) === parseInt(element?.own_user_id))[0];
                                const date = new Date(element?.createdAt);
                                const dateFormated = (("0" + date.getDate()).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' a las ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2));
 
                                return <div key={'history-' + element?.id} className="flex items-center justify-between bg-gray-200 bg-opacity-80 rounded">
                                    <p className="w-full bg-gray-300 bg-opacity-80 rounded px-[8px] py-[4px]">{catTMP?.nom}</p>
                                    <p className="px-[8px] py-[4px] text-gray-500 text-xs">por {(ownUser)? ownUser?.name : '???'} el <strong>{dateFormated}</strong></p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </Popover>
        : null }
    </div>
}