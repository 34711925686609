import { useState, createContext, useContext, useEffect, useRef } from "react";
import { AuthContext } from '../contexts/AuthContext'
import { FuncContext } from '../contexts/FuncContext'
import { useLocation, useNavigate } from "react-router-dom";

import UserButtons from "./UserButtons";
import NavBar from './NavBar'
import SuccessAlert from "../components/SuccessAlert";
import StarterTutorial from "../components/StarterTutorial";

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';






export const LayoutContext = createContext();
export const LayoutContextProvider = ({ children }) => {




  const { showNavTop, currentUser, currentCustomer, setHistory, customerPath } = useContext(AuthContext)
  const { fileExists } = useContext(FuncContext)
  const navigate = useNavigate();
  const location = useLocation();

  

  const languages = [null, 'esp', 'eng'];
  const languageLabels = [
    `Ver textos originales de la marca - (En el caso de los títulos de vídeo o los chyrons de ${currentCustomer?.label_professors?.toLowerCase()} se mostrarán los textos en español)`,
    'Ver traducciones/transcripciones en Español',
    'Ver traducciones/transcripciones en Inglés'
  ];


  /* Vars */
  const [currentNavActive, setCurrentNavActive] = useState('dashboard')
  const [isFullScreenLayout, setIsFullScreenLayout] = useState(false)

  const [startTutorial, setStartTutorial] = useState(false)
  const [step, setStep] = useState(0);
  const [changeZindex, setChangeZindex] = useState(false);
  
  

  /* Search vars */
  const [search, setSearch] = useState('')

  /* Loading Screen vars */
  const [baseLoading, setBaseLoading] = useState(true)
  const [loadingText, setLoadingText] = useState(null)
  
  /* Alert vars */
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);


  const [selectedNOWCell, setSelectedNOWCell] = useState(null)


  /* Icon vars */
  const leftArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill='currentColor' className='mt-[-5px]'><path d="m11.042 14.375-3.75-3.75q-.146-.146-.209-.302-.062-.156-.062-.323t.062-.323q.063-.156.209-.302l3.75-3.75q.27-.271.625-.271.354 0 .625.271.27.271.27.625t-.27.625L9.167 10l3.125 3.125q.27.271.27.625t-.27.625q-.271.271-.625.271-.355 0-.625-.271Z"/></svg>
  const customerIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M360.113 648Q340 648 326 634.113q-14-13.888-14-34Q312 580 325.887 566q13.888-14 34-14Q380 552 394 565.887q14 13.888 14 34Q408 620 394.113 634q-13.888 14-34 14Zm240 0Q580 648 566 634.113q-14-13.888-14-34Q552 580 565.887 566q13.888-14 34-14Q620 552 634 565.887q14 13.888 14 34Q648 620 634.113 634q-13.888 14-34 14ZM480.458 888q130.458 0 221-91T792 576.24q0-23.24-5-52.74-5-29.5-13-51.5-21 5-38 6.5t-40 1.5q-85.964 0-162.482-33.5T397 350q-37 78-93.5 129T170 556q-1 4-1.5 10t-.5 10q0 130 91 221t221.458 91ZM480 960q-79.376 0-149.188-30Q261 900 208.5 847.5T126 725.042q-30-69.959-30-149.5Q96 496 126 426t82.5-122q52.5-52 122.458-82 69.959-30 149.5-30 79.542 0 149.548 30.24 70.007 30.24 121.792 82.08 51.786 51.84 81.994 121.92T864 576q0 79.376-30 149.188Q804 795 752 847.5T629.869 930Q559.738 960 480 960Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill='none' viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;





  useEffect(() => {
    setLoadingText(null)
  }, [navigate]);


  useEffect(() => {
    if (baseLoading) {
      setTimeout(() => {
        setBaseLoading(false)
      }, 300 )
    }
  }, [baseLoading]);


  useEffect(() => {
    setChangeZindex((startTutorial && (step < 0 || step > 3))? true : false)
  }, [startTutorial, step]);

  




  function navigateToCustomerSelectorFunc() {
    setHistory(['', '', '', '']);
    navigate("/customer-selector");
  }




  return (
    <LayoutContext.Provider
      value={{
        currentNavActive,
        setCurrentNavActive,
        setSuccessAlertMsg,
        setErrorAlertMsg,

        startTutorial,
        step,

        loadingText,
        setLoadingText,

        languages,
        languageLabels,
        
        isFullScreenLayout, 
        setIsFullScreenLayout,

        selectedNOWCell,
        setSelectedNOWCell,
      }}
    >
      {/* <CssBaseline /> */}
      <div className={`fixed z-[999999999] w-screen h-screen flex items-center justify-center space-x-1 bg-gray-50 duration-400 ${(baseLoading)? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className="flex items-center justify-center space-x-1 opacity-50">
          {loadingIcon}
          <p>Cargando...</p>
        </div>
      </div>




      {(showNavTop && !isFullScreenLayout) && 
        <div className={`w-screen fixed ${(changeZindex)? 'z-[19]' : 'z-[30]'} top-0 flex justify-between items-center py-2 pr-3 pl-[6px] border-b-[1px] border-gray-300 bg-gradient-to-r from-gray-100 to-gray-50`}>


          {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 1 || step === 2 || step === 3))? true : false) &&
            <div className={`absolute top-0 left-0 z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 2)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
              <div /* Tutorial prop */
                className="ml-[-3968px] mt-[-1970px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
              ></div>
            </div>
          }

          {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (step === 2 || step === 3 || step === 4))? true : false) &&
            <div className={`absolute top-0 left-0 z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 3)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
              <div /* Tutorial prop */
                className="ml-[-3975px] mt-[-1760px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_line.png')]"
              ></div>
            </div>
          }



          <div className="group flex items-center space-x-2">
            <div className={`w-[0px] transition-all duration-300 ${(currentUser && (currentUser?.customer_free || currentUser?.navigation_customer_tokens?.split(',')?.length > 1) && !currentUser?.professor)? 'group-hover:w-[30px]' : ''}`}>
              {(showNavTop && (currentUser && (currentUser?.customer_free || currentUser?.navigation_customer_tokens?.split(',')?.length > 1) && !currentUser?.professor)) && 
                <Tooltip disableInteractive title={"Cambiar de cliente"} placement="bottom" arrow>
                  <div className="pl-2" onClick={() => navigateToCustomerSelectorFunc()}>
                    <p className="py-2 pr-1 flex items-center justify-center text-gray-500 hover:bg-gray-200 rounded cursor-pointer hover:text-blue-600 group-hover:opacity-100 opacity-0 duration-300">
                      {leftArrowIcon}
                    </p>
                  </div>
                </Tooltip>
              }
            </div>

            <div className="flex items-center space-x-3 cursor-pointer hover:text-blue-500" onClick={() => navigate("/videos")}>
              <div id="customer-icon-tuto" className='relative flex items-center justify-center p-[16px] rounded-lg overflow-hidden border border-gray-300 border-opacity-80 shadow-md bg-white group-hover:border-blue-500 transition-all duration-300'>
                {(currentCustomer?.icon && fileExists(currentCustomer?.icon))?
                  <div className='absolute top-0 left-0 w-full h-full'>
                    <img 
                      className='object-cover h-full w-full'
                      src={customerPath + currentCustomer?.icon} 
                      alt='layout_customer_icon'
                    />  
                  </div>
                :
                  <div className='absolute scale-150'>{customerIcon}</div>
                }
              </div>

              <p className="text-gray-800 hover:text-blue-500 font-extrabold text-base sm:text-xl transition-all duration-300 truncate">
                {(currentCustomer?.name)? currentCustomer?.name : 'No Customer'}
              </p>
            </div>
          </div>


          <div className='h-[39px] flex justify-end items-center space-x-2 w-[0px] sm:w-full max-w-[360px] lg:max-w-[500px] xl:max-w-[700px] pr-2'>
            <div className={`invisible sm:visible ml-4 search-box-inv ${(search && search?.replaceAll(' ', '') !== '')? 'w-full' : 'w-[34px] hover:w-full'}`}>
              <IconButton type="button" sx={{ p: '4px', borderRadius: 0 }} aria-label="search"
                onClick={()=>{
                  if (search && search?.replaceAll(' ', '') !== '') {
                    navigate("/videos");
                    var listSearchInput = document.getElementById("search-input");
                    listSearchInput?.focus();
                    setSearch(null)
                  } else {
                    document.getElementById('search-input-global').focus()
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                id="search-input-global"
                name="search-input"
                className='w-full text-[10px]'
                sx={{ ml: 1, flex: 1 }}
                placeholder={`Busca por el nombre del tema, su título, o el nombre del ${currentCustomer?.label_professor?.toLowerCase()}`}
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={(event) => {
                  if(event.code === 'Enter') {

                    navigate("/videos");
                    var listSearchInput = document.getElementById("search-input");
                    listSearchInput?.focus();
                    setSearch(null)

                  }
                }}
              />

              {(search && search?.replaceAll(' ', '') !== '') &&
                <div className="flex items-center justify-center h-[20px]">
                  <IconButton type="button" sx={{ p: '4px', borderRadius: 0 }} aria-label="search"
                    onClick={() => {
                      navigate("/videos");
                      var listSearchInput = document.getElementById("search-input");
                      listSearchInput?.focus();
                      setSearch(null)
                    }}
                  >
                    <SubdirectoryArrowLeftRoundedIcon />
                  </IconButton>
                
                  <IconButton type="button" sx={{ p: '6px', borderRadius: 0 }} aria-label="search"
                    onClick={() => {
                      document.getElementById('search-input-global').value = '';
                      setSearch(null)
                    }}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                </div>
              }
            </div>


            <UserButtons
              startTutorial={startTutorial}
              setStartTutorial={setStartTutorial}
            />

            {((currentUser && currentCustomer && location.pathname?.includes('/videos') && startTutorial && (!step || step === 1 || step === 2))? true : false) && 
              <div className={`absolute z-[9999999] h-[4000px] w-[8000px] transition-opacity duration-500 ${(step === 1)? "opacity-[0.80] delay-700" : "opacity-0 pointer-events-none"}`} style={{ margin: 0 }}>
                <div /* Tutorial prop */
                  className="ml-[3985px] mb-[705px] h-full w-full bg-cover bg-[url('/public/tutorial_bg_small.png')]"
                ></div>
              </div>
            }

          </div>
        </div>
      }



      {(loadingText) &&
        <div className="fixed z-[999999999] w-screen h-screen flex items-center justify-center space-x-1 bg-gray-50 duration-400 opacity-100">
          <div className="flex items-center justify-center space-x-1 opacity-50">
            {loadingIcon}
            <p>{loadingText}</p>
          </div>
        </div>
      }


      {(startTutorial && currentUser && currentCustomer && location.pathname?.includes('/videos')) && 
        <StarterTutorial
          tutorialOpen={startTutorial}
          setTutorialOpen={setStartTutorial}
          step={step}
          setStep={setStep}
        />
      }



      <SuccessAlert tmpMSG={successAlertMsg} setTmoMSG={setSuccessAlertMsg} type='success'/>
      <SuccessAlert tmpMSG={errorAlertMsg} setTmoMSG={setErrorAlertMsg} type='error'/>



      <div className="relative min-w-0 z-20">
        <NavBar children={children}/>
      </div>



    </LayoutContext.Provider>
  );
};
