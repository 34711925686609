import gql from "graphql-tag";
export default gql`
  mutation CreateOrUpdateBillConcept(
    
    $own_user_token: String!,

    $id: Int
    $title: String
    $small_text: String
    $position: Int
    $description: String
    $units_name: String
    $units: Int
    $price: Int
    $iva: Int

    $bill_id: Int
    $parent_id: Int
    $video_id: Int
    $module_id: Int

    $billed: Boolean

  ) {
    createOrUpdateBillConcept(input: { 

      own_user_token: $own_user_token,
      
      id: $id,
      position: $position,
      title: $title,
      small_text: $small_text,
      description: $description,
      units_name: $units_name,
      units: $units,
      price: $price,
      iva: $iva,

      bill_id: $bill_id,
      parent_id: $parent_id,
      video_id: $video_id,
      module_id: $module_id,

      billed: $billed,

    }) {
      
      id
      position
      title
      small_text
      description
      units_name
      units
      price
      iva

      bill_id
      parent_id
      video_id
      module_id

      createdAt
      updatedAt

      createdAtFormated
      updatedAtFormated



      childs {
        id
        position
        title
        description
        units_name
        units
        price
        iva
  
        bill_id
        parent_id
        video_id
        module_id
  
        billed
  
        createdAt
        updatedAt
  
        createdAtFormated
        updatedAtFormated

        video {
          id
          name
          url_name
          vimeo_url
          search_module_abbreviated_name
        }
      }

      video {
        id
        name
        url_name
        search_module_abbreviated_name
      }



    }
  }
`;
