import gql from "graphql-tag";

export default gql`
  mutation CreateOrUpdateVideoMark(
    
    $own_user_token: String, 
    $path_video_token: String, 
    $search_guest_data: String, 

    $id: Int,
    $time: String, 
    $time_end: String, 
    $observations: String, 
    $texts: String, 
    $url: String, 
    $coordinates: String,

    $prioritary: Boolean, 
    $public: Boolean, 
    $notificate_to: String, 
    $notificate_to_label: String, 

    $state: Int, 
    $state_change_label: String, 
    $motion_state: Int, 
    $motion_state_change_label: String, 
    $revision_state: Int, 
    $revision_state_change_label: String, 
    $trad_done: Boolean, 
    $trad_done_change_label: String,

    $swap_date: Boolean,
    $swap_user: Boolean,
    $info_user_id: Int, 
    $hidde_original: Boolean, 

    $video_id: Int, 
    $user_id: Int, 
    $type_id: Int, 
    $review_id: Int, 
    $difficulty_id: Int, 
    $file_process_id: Int, 
    
    $extra_mark_facturation: Int, 
    $extra_facturation_correction: Int, 
    $manual_number_of_words: Int, 
    $new_price: Int, 
    $new_price_observations: String, 

    $disabled: Boolean,
    $disabled_change_label: String,
    $facturation_disabled: Boolean,
    $facturation_disabled_change_label: String,
    $facturation_force_insertion: Boolean,
    
  ) {
    createOrUpdateVideoMark(input: { 
      
      own_user_token: $own_user_token,
      path_video_token: $path_video_token,
      search_guest_data: $search_guest_data,

      id: $id,
      time: $time,
      time_end: $time_end,
      observations: $observations,
      texts: $texts,
      url: $url,
      coordinates: $coordinates, 

      prioritary: $prioritary,
      public: $public,
      notificate_to: $notificate_to,
      notificate_to_label: $notificate_to_label,

      state: $state, 
      state_change_label: $state_change_label, 
      motion_state: $motion_state, 
      motion_state_change_label: $motion_state_change_label, 
      revision_state: $revision_state, 
      revision_state_change_label: $revision_state_change_label, 
      trad_done: $trad_done, 
      trad_done_change_label: $trad_done_change_label, 

      swap_date: $swap_date, 
      swap_user: $swap_user, 
      info_user_id: $info_user_id, 
      hidde_original: $hidde_original, 

      video_id: $video_id, 
      user_id: $user_id, 
      type_id: $type_id, 
      review_id: $review_id, 
      difficulty_id: $difficulty_id, 
      file_process_id: $file_process_id, 

      extra_mark_facturation: $extra_mark_facturation,
      extra_facturation_correction: $extra_facturation_correction,
      manual_number_of_words: $manual_number_of_words, 
      new_price: $new_price,
      new_price_observations: $new_price_observations,

      disabled: $disabled, 
      disabled_change_label: $disabled_change_label, 
      facturation_disabled: $facturation_disabled, 
      facturation_disabled_change_label: $facturation_disabled_change_label, 
      facturation_force_insertion: $facturation_force_insertion, 

    }) {
      
      id
      time
      time_end
      observations
      texts
      url
      coordinates

      swap_date
      swap_user
      info_user_id
      hidde_original

      public
      notificate_to
      notificate_to_label
      
      state
      state_change_label
      motion_state
      motion_state_change_label
      revision_state
      revision_state_change_label
      trad_done
      trad_done_change_label

      video_id
      user_id
      type_id
      review_id
      difficulty_id
      is_translation_of
      original_video_id
      file_process_id

      extra_mark_facturation
      extra_facturation_correction  
      manual_number_of_words
      new_price
      new_price_observations
      
      disabled
      disabled_change_label
      facturation_disabled
      facturation_disabled_change_label
      facturation_force_insertion

      search_guest_data
      search_type_name
      search_type_color
      search_type_type
      search_review_name
      search_recording_set_label
      localization_end_date
      
      createdAt
      updatedAt

      info_user {
        id
        name
        positions
      }

      video {
        name
        url_name
        language
      }
      
      createdAtFormated
      updatedAtFormated
      __typename

    }
  }
`;
