import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { FuncContext } from '../contexts/FuncContext';
import { NumericFormat } from "react-number-format";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useMutation } from '@apollo/client'

import CreateOrUpdateBillConcept from './../graphql/mutations/CreateOrUpdateBillConcept'

import ReactQuillSimpleImput from '../components/ReactQuillSimpleImput';

import Collapse from '@mui/material/Collapse';
import { TextField } from "@mui/material";






export default function F10BillExtrasItem({ 
  factura10Bill,
  added,
  localItems,
  setLocalItems,

  open,
  setOpen,
  editItem,
  setEditItem,

  saveChangesFunc,
  addExtraItemFunc,
  removeExtraItemFunc,
  addExtraFunc,
  desAssignExtraItemFunc,
  removeParentFunc,

  loadingCreate,
  loadingSave,
  loadingRemove,
}) {


  
  
  const { currentUser, currentCustomer } = useContext(AuthContext)
  const { remove_tags, format_texts, formatNumbersToPrint } = useContext(FuncContext)




  const addIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>;
  const downArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  const loadingIcon = <svg className="animate-spin  h-5 w-5 px-[2px]" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>;
  const saveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h9.188q.354 0 .677.135.323.136.573.386l2.291 2.291q.25.25.386.573.135.323.135.677v9.188q0 .729-.51 1.24-.511.51-1.24.51ZM10 14.917q1.062 0 1.823-.761.76-.76.76-1.823 0-1.062-.76-1.823-.761-.76-1.823-.76-1.062 0-1.823.76-.76.761-.76 1.823 0 1.063.76 1.823.761.761 1.823.761ZM5.896 8.396h5.75q.354 0 .614-.261.261-.26.261-.614V5.896q0-.354-.261-.615-.26-.26-.614-.26h-5.75q-.354 0-.615.26-.26.261-.26.615v1.625q0 .354.26.614.261.261.615.261Z"/></svg>;
  const removeIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M283 936q-35 0-59.5-24.5T199 852V342q-17 0-29.5-12.5T157 300q0-17 12.5-29.5T199 258h162q0-17 12.5-29.5T403 216h153q17 0 29.5 12.5T598 258h162q17 0 29.5 12.5T802 300q0 17-12.5 29.5T760 342v510q0 35-24.5 59.5T676 936H283Zm74-210q0 17 12.5 29.5T399 768q17 0 29.5-12.5T441 726V468q0-17-12.5-29.5T399 426q-17 0-29.5 12.5T357 468v258Zm160 0q0 17 12.5 29.5T559 768q17 0 29.5-12.5T601 726V468q0-17-12.5-29.5T559 426q-17 0-29.5 12.5T517 468v258Z"/></svg>
  const rightArrowIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M630-444H228.049q-15.321 0-25.685-10.289Q192-464.579 192-479.789 192-495 202.364-505.5q10.364-10.5 25.685-10.5H630l-80-80q-11-11-11-25.5t10.522-25.5Q561-658 575.5-658q14.5 0 25.5 11l142.333 142.333Q748-500 750.5-493.7q2.5 6.3 2.5 13.5t-2.5 13.5q-2.5 6.3-7.5 11.7L601-313q-11 11-25 10.5T550.522-314Q540-325 540-339.5t11-25.5l79-79Z"/></svg>
  const editIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="m16.188 7.542-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24ZM3.375 17.5q-.354 0-.615-.26-.26-.261-.26-.615v-2.5q0-.167.062-.323.063-.156.188-.281l8.479-8.479 3.729 3.729-8.479 8.479q-.125.125-.281.188-.156.062-.323.062Z"/></svg>;
  const cancelIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="currentColor"><path d="M480 635 287 828q-12 12-29 11.5T229 827q-12-12-12-29.5t12-29.5l192-192-193-193q-12-12-11.5-29.5T229 324q12-12 29.5-12t29.5 12l192 193 193-193q12-12 29.5-12t29.5 12q12 12 12 29.5T732 383L539 576l193 193q12 12 12 29t-12 29q-12 12-29.5 12T673 827L480 635Z"/></svg>
  const videoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'><path d="M4.5 4 6 7h2L6.5 4H8l1.5 3h2L10 4h1.5L13 7h2l-1.5-3h3q.625 0 1.062.448Q18 4.896 18 5.5v9q0 .604-.438 1.052Q17.125 16 16.5 16h-13q-.604 0-1.052-.448Q2 15.104 2 14.5v-9q0-.604.427-1.052Q2.854 4 3.5 4Z"/></svg>;

  const dragIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='currentColor'><path d="M359.788-192Q330-192 309-213.212q-21-21.213-21-51Q288-294 309.212-315q21.213-21 51-21Q390-336 411-314.788q21 21.213 21 51Q432-234 410.788-213q-21.213 21-51 21Zm240 0Q570-192 549-213.212q-21-21.213-21-51Q528-294 549.212-315q21.213-21 51-21Q630-336 651-314.788q21 21.213 21 51Q672-234 650.788-213q-21.213 21-51 21Zm-240-216Q330-408 309-429.212q-21-21.213-21-51Q288-510 309.212-531q21.213-21 51-21Q390-552 411-530.788q21 21.213 21 51Q432-450 410.788-429q-21.213 21-51 21Zm240 0Q570-408 549-429.212q-21-21.213-21-51Q528-510 549.212-531q21.213-21 51-21Q630-552 651-530.788q21 21.213 21 51Q672-450 650.788-429q-21.213 21-51 21Zm-240-216Q330-624 309-645.212q-21-21.213-21-51Q288-726 309.212-747q21.213-21 51-21Q390-768 411-746.788q21 21.213 21 51Q432-666 410.788-645q-21.213 21-51 21Zm240 0Q570-624 549-645.212q-21-21.213-21-51Q528-726 549.212-747q21.213-21 51-21Q630-768 651-746.788q21 21.213 21 51Q672-666 650.788-645q-21.213 21-51 21Z"/></svg>



  const [createOrUpdateBillConcept] = useMutation(CreateOrUpdateBillConcept)





  const rearangeArr = (arr, source, destination) => {
    const arrCopy = [...arr]?.sort((a, b) => a?.position - b?.position);
    const [removed] = arrCopy.splice(source.index, 1);
    arrCopy.splice(destination.index, 0, removed);
    return arrCopy;
  };


  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !localItems?.length) { return; }
    const rearrangedArray = rearangeArr(localItems, source, destination);
    savePositionsFunc(rearrangedArray)
  };

  async function savePositionsFunc(rearrangedArray) {
    var counter = 1;
    for (var key in rearrangedArray) {
      const itemTMP = rearrangedArray[key]
        await createOrUpdateBillConcept({variables: {
        own_user_token: currentUser?.token,
        id: parseInt(itemTMP?.id),
        position: counter,
      }})
      counter++;
    }
  }


  function numFunc(num, secNum) {
    return (num || num === 0)? num : secNum;
  }







  return <div className={`bg-gray-200 rounded overflow-hidden`}>
    <div className={`flex justify-between items-center space-x-4 text-sm text-gray-600 p-2 pl-3 border-b ${(open)? 'rounded-t bg-gray-400 bg-opacity-30' : 'rounded'}`}>
      <p>{(!added)? 'Elementos extras de los vídeos' : ((localItems?.length)? 'Elementos extra añadidos' : 'Sin elementos extra añadidos')} {(localItems?.length)? `(${localItems?.length})` : null}</p>


      <div className='flex space-x-1 items-center'>
        {(factura10Bill?.document && !parseInt(factura10Bill?.document?.sended))?
          <div>
            <Collapse orientation="horizontal" in={(open || !localItems?.length)? true : false} timeout={300} className='rounded' style={{ minWidth: 'auto' }}>
                <div className='flex space-x-1'>
                  {(added)?
                    <button disabled={loadingCreate} onClick={() => addExtraItemFunc()} className={`text-xs flex items-center space-x-1 ${(open)? 'bg-gray-200' : 'bg-gray-300'} border border-gray-400 border-opacity-60 hover:bg-blue-500 hover:text-white active:text-white active:bg-blue-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                      {(loadingCreate)? loadingIcon : addIcon}
                      <p className='truncate'>{(loadingCreate)? 'Creando elemento...' : 'Crear elemento'}</p>
                    </button>
                  : null }
                </div>
            </Collapse>
          </div>
        : null }

        
        {(localItems?.length)?
          <button
            onClick={() => setOpen(!open)}
            className={`p-[2px] flex items-center justify-center rounded duration-200 hover:opacity-80 active:opacity-60 disabled:opacity-40 disabled:pointer-events-none ${(open)? 'rotate-[180deg] bg-blue-500 border border-blue-600 text-white' : 'bg-gray-300 border border-gray-400 border-opacity-60 text-gray-500'}`}
          >
            {downArrowIcon}
          </button>
        : null }
      </div>
    </div>

    {(localItems?.length)?
      <>
        <Collapse orientation="vertical" in={(open && localItems?.length)? true : false} timeout={500}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'modules'}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='pt-4 max-h-[500px] overflow-auto'
                >
                  {[...localItems]?.sort((a, b) => a?.position - b?.position)?.map((element, key) => {
                    const itemKey = (((added)? 'added-' : 'non-added-') + ((!element?.id)? 'pos-'+element?.position : 'id-'+element?.id));

                    const BASE = (element?.price)? (numFunc(element?.units / 100, 0) * (numFunc(element?.price / 100, 0))) : 0;
                    const IVA = (element?.price)? (numFunc(element?.units / 100, 0) * (numFunc(element?.price / 100, 0) * (numFunc(element?.iva / 100, 21) / 100))) : 0;
                    const TOTAL = (element?.price)? (numFunc(element?.units / 100, 0) * (numFunc(element?.price / 100, 0) + (numFunc(element?.price / 100, 0) * (numFunc(element?.iva / 100, 21) / 100)))) : 0;

                    const units = (element?.childs?.length)? element?.childs?.map((el) => el?.units ).reduce((partialSum, a) => partialSum + a, 0) : 0

                    return  <Draggable
                      key={itemKey}
                      isDragDisabled={false}
                      draggableId={itemKey}
                      index={key}
                    >
                      {(provided, snapshot) => (
                          
                        <div 
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{ userSelect: "none", margin: `0 0 8px 0`, ...provided.draggableProps.style }}
                          className='flex items-start justify-start space-x-1 px-4 pb-4'
                        >

                          <div className='w-full'>
                            <Collapse orientation="vertical" in={(!(loadingSave && editItem === itemKey))? true : false} timeout={500}>
                              <div className='bg-white hover:bg-gray-50 border border-gray-300 duration-200 p-2 space-y-2 rounded'>

                                {(editItem === itemKey)? <>
                                  <ReactQuillSimpleImput
                                    id={"title-extra-" + itemKey} 
                                    label={"Título"}
                                    singleLine={true}
                                    
                                    deffVal={true}
                                    value={(element?.title)? element?.title : ''} 
                                    onChange={(value) => {
                                      var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                      billExtrasTMP[key].title = (value)? remove_tags(value, true) : null;
                                      setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                    }} 
                                    onKeyDown={(event) => {}}
                                  />

                                  <ReactQuillSimpleImput
                                    id={"small-text-extra-" + itemKey} 
                                    label={"Texto intermedio"}
                                    singleLine={true}
                                    
                                    deffVal={true}
                                    value={(element?.small_text)? element?.small_text : ''} 
                                    onChange={(value) => {
                                      var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                      billExtrasTMP[key].small_text = (value)? remove_tags(value, true) : null;
                                      setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                    }} 
                                    onKeyDown={(event) => {}}
                                  />

                                  <ReactQuillSimpleImput
                                    id={"description-extra-" + itemKey} 
                                    label={"Descripción"}
                                    placeholder="Escribe una descripción..."
                                    style={1}
                                    
                                    deffVal={true}
                                    value={(element.description)? element.description : ''} 
                                    onChange={(value) => {
                                      var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                      billExtrasTMP[key].description = (remove_tags(format_texts(value?.replace(/\n/g, "<br />"))))? value : null;
                                      setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                    }} 
                                    onKeyDown={(event) => {}}
                                  />
                                </> : 
                                  <div className='bg-gray-200 bg-opacity-40 rounded px-2 py-1'>
                                    <p dangerouslySetInnerHTML={{ __html: (element?.title)? remove_tags(element?.title, true) : 'Sin título' }} className={`font-bold text-gray-600 ${(element?.description)? '' : 'opacity-40'}`}></p>
                                    {(element?.small_text)? <p dangerouslySetInnerHTML={{ __html: remove_tags(element?.small_text, true) }} className='text-sm'></p> : null}
                                    <p dangerouslySetInnerHTML={{ __html: (element?.description)? element?.description?.replaceAll('<p><br></p>', '') : 'Sin descripción' }} className={`text-sm text-gray-500 ${(element?.description)? 'opacity-80' : 'opacity-40'}`}></p>
                                  </div>
                                }
                                





                                {(element?.childs?.length && editItem === itemKey)? <>
                                  <p className='text-xs text-gray-500 px-2'>Elementos añadidos</p>
                                  <div className='max-h-[250px] space-y-1 text-sm overflow-auto smallScrollBar' style={{ marginTop: 0 }}>
                                    {element?.childs?.map(child => {
                                      return <div key={'child-'+child?.id} className='p-2 bg-gray-200 bg-opacity-50 hover:bg-opacity-100 duration-200 rounded'>
                                        <div className='w-full flex justify-between items-center'>
                                          <p dangerouslySetInnerHTML={{ __html: (child?.title)? remove_tags(child?.title, true) : 'Sin título' }} className={`${(child?.description)? '' : 'opacity-40'}`}></p>


                                          <div className='flex items-center justify-end space-x-2'>
                                            <p>{formatNumbersToPrint(numFunc(child?.units / 100, 0) * (numFunc(child?.price / 100, 0)), 2)}€</p>
                                            
                                            
                                            <div className='flex items-center justify-end space-x-1'>
                                              {(child?.video)? 
                                                <a 
                                                  href={ /* VIDEO URL */
                                                    ("/" + currentCustomer?.abbreviated_name + "/" + ((child?.video?.search_module_abbreviated_name)? child?.video?.search_module_abbreviated_name : 'extra') + '/' + child?.video?.url_name?.replaceAll(' ', '_')).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                                  }
                                                  target='_blank' className={`pl-1 pr-2 text-xs flex items-center space-x-1 text-white bg-blue-500 border border-blue-600 hover:bg-blue-600 active:bg-blue-700 duration-200 rounded`}
                                                >
                                                  <div className='scale-90'>{videoIcon}</div>
                                                  <p className='truncate'>{child?.video?.name}</p>
                                                </a>
                                              : null }

                                              {(!element?.billed && !child?.billed)?
                                                <button onClick={() => removeParentFunc(element, child)} className={`text-xs flex items-center space-x-1 bg-red-500 border border-red-600 hover:bg-red-600 text-white active:bg-red-700 duration-200 rounded`}>
                                                  <div className='scale-75'>{cancelIcon}</div>
                                                </button>
                                              : null }
                                            </div>
                                          </div>

                                        
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: (child?.description)? child?.description?.replaceAll('<p><br></p>', '') : 'Sin descripción' }} className={`text-sm text-gray-500 ${(child?.description)? '' : 'opacity-40'}`}></p>
                                      </div>
                                    })}
                                  </div>
                                </> : null }






                                <div className='w-full flex flex-wrap space-y-2 min-[1050px]:flex-nowrap min-[1050px]:space-y-0 min-[1050px]:space-x-1 items-center justify-between'>
                                  

                                  <button 
                                    className={` bg-gray-100 border border-gray-400 border-opacity-40 duration-200 cursor-grab active:cursor-grabbing rounded
                                      ${(snapshot.isDragging)? 'bg-gray-300' : 'hover:bg-gray-300'}
                                    `}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className='p-[2px] opacity-50'>{dragIcon}</div>
                                  </button>

                                  
                                  <div className='w-full flex space-x-1 items-center text-[11px] md:text-xs whitespace-nowrap'>

                                    {(!element?.childs?.length)? <>
                                      {(editItem === itemKey)? <>
                                        <div className='max-w-[130px] min-w-[80px] md:min-w-[130px] flex items-center bg-gray-200 text-gray-500 rounded'>
                                          <NumericFormat
                                            value={(element?.price)? element?.price / 100 : null} 
                                            customInput={TextField}
                                            onValueChange={(ev) => {
                                              var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                              billExtrasTMP[key].price = (ev.floatValue)? parseInt(ev.floatValue * 100) : null;
                                              setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                            }}
                                            thousandSeparator="."
                                            decimalSeparator=","

                                            label="Precio"
                                            className='w-full mui-bg-white'
                                            sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 26 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                                            InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 2.5 } } }}
                                            inputProps={{ step: "1", min: 0 }}
                                          />
                                          <p className='px-2'>€</p>
                                        </div>
                                      </> : <>
                                        <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>Precio/u: {formatNumbersToPrint(((element?.price)? (element?.price / 100) : 0), 2)} €</p>
                                      </>}
                                    </> : 
                                      <div>
                                        <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>{element?.childs?.length} {(element?.childs?.length > 1)? 'elementos añadidos' : 'elemento añadido'}</p>
                                      </div> 
                                    }





                                    {(editItem === itemKey && !element?.childs?.length)? <>
                                      <div className='max-w-[130px] min-w-[80px] md:min-w-[130px] flex items-center bg-gray-200 text-gray-500 rounded'>
                                        <p className='px-2'>x</p>
                                        <NumericFormat
                                          value={(element?.units)? element?.units / 100 : null} 
                                          customInput={TextField}
                                          onValueChange={(ev) => {
                                            var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                            billExtrasTMP[key].units = (ev.floatValue)? parseInt(ev.floatValue * 100) : null;
                                            setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                          }}
                                          thousandSeparator="."
                                          decimalSeparator=","

                                          label="Unidades"
                                          className='w-full mui-bg-white'
                                          sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 26 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                                          InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 2.5 } } }}
                                          inputProps={{ step: "1", min: 0 }}
                                        />
                                      </div>
                                    </> : <>
                                      <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                                        {(element?.childs?.length)? formatNumbersToPrint(units, 0) : formatNumbersToPrint(((element?.units)? (element?.units / 100) : 0), 0)}
                                        {(editItem !== itemKey)? ((localItems[key].units_name)? ' ' + localItems[key].units_name : ' Unidades') : null} 
                                      </p>
                                    </>}


                                    {(editItem === itemKey)? <>
                                      <div className='max-w-[100px] min-w-[80px] md:min-w-[100px] flex items-center bg-gray-200 text-gray-500 rounded'>
                                        <TextField
                                          value={(element?.units_name)? element?.units_name : null} 
                                          onChange={(event) => {
                                            var billExtrasTMP = (localItems?.length)? JSON.parse(JSON.stringify([...localItems]?.sort((a, b) => a?.position - b?.position))) : [];
                                            billExtrasTMP[key].units_name = event?.target?.value;
                                            setLocalItems((billExtrasTMP?.length)? billExtrasTMP : null)
                                          }}

                                          label="Nombre/Unidades"
                                          className='w-full mui-bg-white'
                                          sx={{ "& .MuiInputBase-root": { fontSize: 14, height: 26 }, "& .MuiInputBase-input": { padding: 0.6, paddingLeft: 1 } }}
                                          InputLabelProps={{ sx: { fontSize: 12, paddingBlock: 0.1, top: "-11px", "&.MuiInputLabel-shrink": { top: 2.5 } } }}
                                          inputProps={{ step: "1", min: 0 }}
                                        />
                                      </div>
                                    </> : null }



                                  

                                    
                                    <div className='w-full flex items-center justify-center opacity-50 pl-[5px] pr-1'>{rightArrowIcon}</div>

                                          
                                    <div className='flex items-center whitespace-nowrap space-x-1'> {/* md:hidden xl:flex */}
                                      <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                                        Base: {formatNumbersToPrint(BASE, 2)}€
                                      </p>
                                      <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                                        IVA: {formatNumbersToPrint(IVA, 2)}€
                                      </p>
                                      <p className='px-1 md:px-2 py-[3.5px] bg-white border border-gray-300 rounded'>
                                        Total: {formatNumbersToPrint(TOTAL, 2)}€
                                      </p>
                                    </div>
                                  </div>



                                  <div className='w-full flex justify-end space-x-1'>
                                    {(editItem === itemKey)? <>
                                      <button onClick={() => setEditItem(null)} className={`text-xs flex items-center space-x-1 bg-red-500 border border-red-600 hover:bg-red-400 text-white active:bg-red-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                                        <div className='scale-95'>{cancelIcon}</div>
                                        <p className='truncate'>Cancelar edición</p>
                                      </button>

                                      <button disabled={loadingSave} onClick={() => saveChangesFunc(added)} className={`text-xs flex items-center space-x-1 bg-blue-500 border border-gray-400 border-opacity-60 hover:bg-blue-400 text-white active:bg-blue-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                                        {(loadingSave)? loadingIcon : saveIcon}
                                        <p className='truncate'>{(loadingSave)? 'Guardando cambios...' : 'Guardar cambios'}</p>
                                      </button>
                                    </> : <>
                                      {(element?.video)? 
                                        <a 
                                          href={
                                            "/" + currentCustomer?.abbreviated_name + "/" + ((element?.video?.search_module_abbreviated_name)? element?.video?.search_module_abbreviated_name : 'extra') + "/" + element?.video?.name?.replaceAll(' ', '_')
                                          }
                                          target='_blank' className={`text-xs flex items-center space-x-1 bg-gray-100 border border-gray-300 hover:bg-gray-200 active:bg-gray-300 duration-200 pl-1 pr-2 py-[2px] rounded`}
                                        >
                                          {videoIcon}
                                          <p className='truncate'>{element?.video?.name}</p>
                                        </a>
                                      : null }

                                      {(!parseInt(factura10Bill?.document?.sended))? <>
                                        <button onClick={() => setEditItem(itemKey)} className={`text-xs flex items-center space-x-1 bg-blue-500 border border-blue-600 hover:bg-blue-400 text-white active:bg-blue-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                                          <div className='scale-95'>{editIcon}</div>
                                          <p className='truncate'>Editar</p>
                                        </button>

                                        {(added)?
                                          <button onClick={() => { if (element?.video_id) { desAssignExtraItemFunc(element?.id, itemKey) } else { removeExtraItemFunc(element?.position, itemKey) } }} className={`text-xs flex items-center space-x-1 bg-red-500 border border-red-600 hover:bg-red-400 text-white active:bg-red-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                                            {removeIcon}
                                            <p className='truncate'>
                                              {
                                                (element?.video_id)? ((loadingRemove[itemKey])? 'Quitando...' : 'Quitar de la factura') 
                                                : ((loadingRemove[itemKey])? 'Eliminando...' : 'Eliminar')
                                              }
                                            </p>
                                          </button>
                                        : 
                                          <button onClick={() => addExtraFunc(element, itemKey)} className={`text-xs flex items-center space-x-1 bg-blue-500 border border-blue-600 hover:bg-blue-400 text-white active:bg-blue-700 duration-200 pl-1 pr-2 py-[2px] rounded`}>
                                            {addIcon}
                                            <p className='truncate'>{((loadingRemove[itemKey])? 'Añadiendo...' : 'Añadir a la factura')}</p>
                                          </button>
                                        }
                                      </>: null }
                                    </>} 
                                  </div>
                                </div>
                              </div>
                            </Collapse>


                            <Collapse orientation="vertical" in={(editItem === itemKey && loadingSave)? true : false} timeout={500}>
                              <div className='w-full'>
                                <div className='p-10 w-full flex items-center space-x-2 bg-white rounded animate-pulse'>
                                  {loadingIcon}
                                  <p>Guardando cambios...</p>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  })}
                  {provided.placeholder}
                </div>

              )}
            </Droppable>
          </DragDropContext>
        </Collapse>
      </>
    : null }
  </div>
}
