export default function MarkCommentsLines({
  
  commentKey,
  comment,

  showAllComments,
  hasHidden,
  prevComment,
  nextComment,
  laterComments,

  isEditing,

}) {




  /* Icon vars */
  const replyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M 834.703 261.57 C 823.37 261.57 813.537 265.736 805.203 274.07 C 796.87 282.403 792.703 292.236 792.703 303.57 L 792.703 418.57 C 792.703 451.236 781.203 479.07 758.203 502.07 C 735.203 525.07 707.37 536.57 674.703 536.57 L 316.703 536.57 L 429.703 423.57 C 437.703 415.57 441.703 406.07 441.703 395.07 C 441.703 384.07 437.37 374.236 428.703 365.57 C 420.703 357.57 410.87 353.57 399.203 353.57 C 387.537 353.57 377.703 357.57 369.703 365.57 L 186.703 548.57 C 182.037 553.236 178.703 558.07 176.703 563.07 C 174.703 568.07 173.703 573.236 173.703 578.57 C 173.703 583.903 174.703 589.07 176.703 594.07 C 178.703 599.07 182.037 603.903 186.703 608.57 L 370.703 792.57 C 378.703 800.57 388.37 804.736 399.703 805.07 C 411.037 805.403 421.037 801.236 429.703 792.57 C 437.703 784.57 441.703 774.736 441.703 763.07 C 441.703 751.403 437.703 741.57 429.703 733.57 L 316.703 620.57 L 674.703 620.57 C 730.703 620.57 778.37 600.903 817.703 561.57 C 857.037 522.236 876.703 474.57 876.703 418.57 L 876.703 303.57 C 876.703 292.236 872.537 282.403 864.203 274.07 C 855.87 265.736 846.037 261.57 834.703 261.57 Z" transform="matrix(0, 1, -1, 0, 1058.532229, 8.126271)"/></svg>;
  const replyIconNotArrow = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M 926.648 62.344 C 915.315 62.344 905.481 66.511 897.148 74.844 C 888.815 83.177 884.648 93.011 884.648 104.344 L 884.648 219.344 C 884.648 252.011 873.148 279.844 850.148 302.844 C 827.148 325.844 799.315 337.344 766.648 337.344 L 408.648 337.344 L 278.648 349.344 C 273.981 354.011 270.648 358.844 268.648 363.844 C 266.648 368.844 265.648 374.011 265.648 379.344 C 265.648 384.677 266.648 389.844 268.648 394.844 C 270.648 399.844 273.981 404.677 278.648 409.344 L 408.648 421.344 L 766.648 421.344 C 822.648 421.344 870.315 401.677 909.648 362.344 C 948.981 323.011 968.648 275.344 968.648 219.344 L 968.648 104.344 C 968.648 93.011 964.481 83.177 956.148 74.844 C 947.815 66.511 937.981 62.344 926.648 62.344 Z" transform="matrix(0, 1, -1, 0, 858.991996, -375.30399)"/></svg>;
  const reolyIconArrowNoCurvatrue = <svg className="-rotate-90 scale-y-[1.1]" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M726-444H180q-15.3 0-25.65-10.29Q144-464.58 144-479.79t10.35-25.71Q164.7-516 180-516h546l-56-56q-11-11-11-25.5t11-25.98Q681-634 695.5-634t25.5 11l118 118q11 10.64 11 24.82T839-455L721-337q-11 11-25 10.5t-25-11.02q-11-11.48-11-26.15 0-14.66 11-25.33l55-55Z"/></svg>
  const replyIconLine = <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill='currentColor'><path d="M 933.384 497 L 933.384 581 L -49.925 581 L -49.925 497 L 933.384 497 Z" transform="matrix(0, -1, 1, 0, -59.156533, 1018.843511)"/></svg>;
  const fadeIcon = <svg className="rotate-180 scale-x-[0.055] scale-y-[1]" xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="currentColor"><path d="M135-160q-6 0-10.5-4.5T120-175q0-6 4.5-10.5T135-190h690q6 0 10.5 4.5T840-175q0 6-4.5 10.5T825-160H135Zm15-90q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h660q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H150Zm0-120q-12.75 0-21.37-8.65-8.63-8.66-8.63-21.45v-30.11q0-12.79 8.63-21.29 8.62-8.5 21.37-8.5h660q12.75 0 21.38 8.65 8.62 8.66 8.62 21.45v30.11q0 12.79-8.62 21.29-8.63 8.5-21.38 8.5H150Zm0-150q-12.75 0-21.37-8.63Q120-537.25 120-550v-220q0-12.75 8.63-21.38Q137.25-800 150-800h660q12.75 0 21.38 8.62Q840-782.75 840-770v220q0 12.75-8.62 21.37Q822.75-520 810-520H150Z"/></svg>
  const smallFadeIcon = <svg className="rotate-180 scale-x-[0.055] scale-y-[0.5]" xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="currentColor"><path d="M135-160q-6 0-10.5-4.5T120-175q0-6 4.5-10.5T135-190h690q6 0 10.5 4.5T840-175q0 6-4.5 10.5T825-160H135Zm15-90q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h660q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H150Zm0-120q-12.75 0-21.37-8.65-8.63-8.66-8.63-21.45v-30.11q0-12.79 8.63-21.29 8.62-8.5 21.37-8.5h660q12.75 0 21.38 8.65 8.62 8.66 8.62 21.45v30.11q0 12.79-8.62 21.29-8.63 8.5-21.38 8.5H150Zm0-150q-12.75 0-21.37-8.63Q120-537.25 120-550v-220q0-12.75 8.63-21.38Q137.25-800 150-800h660q12.75 0 21.38 8.62Q840-782.75 840-770v220q0 12.75-8.62 21.37Q822.75-520 810-520H150Z"/></svg>
  
  const topNum = 9
  const tabs = (comment?.order)? ((comment?.order?.split(',')?.length > topNum)? topNum : comment?.order?.split(',')?.length) : 0
  const prev_tabs = (prevComment?.order)? ((prevComment?.order?.split(',')?.length > topNum)? topNum : prevComment?.order?.split(',')?.length) : 0
  const next_tabs = (nextComment?.order)? ((nextComment?.order?.split(',')?.length > topNum)? topNum : nextComment?.order?.split(',')?.length) : 0
  








  function checkTab(tabTMP) {
    if (laterComments?.length) { 
      for (var laterComment of laterComments) {
        const currentTab = (laterComment?.order)? ((laterComment?.order?.split(',')?.length > topNum)? topNum : laterComment?.order?.split(',')?.length) : 0;

        if (currentTab < tabTMP) {
          return false;
        } else if (currentTab === tabTMP) {
          return true;
        }
      }
    }
    return false;
  }







  return (
    <div className={`flex ${(tabs > 7 && isEditing)? 'hidden' : (isEditing)? 'hidden sm:flex lg:hidden xl:flex' : ''}`}>


      


      {new Array(tabs).fill("").map((_, i) => i + 1)?.map((num) => {
        const is_last_line = tabs === num;
        const need_full_line = checkTab(num)
        const needs_first_arrow = (tabs !== 1 && !parseInt(commentKey) && parseInt(num) === 1 && hasHidden && !showAllComments)? true : false;

        const force_initial_line = (hasHidden && num === 1)? true : false
        const show_line = (need_full_line || force_initial_line)? true : false

        const diff_line = (show_line && Math.abs(prev_tabs - tabs) > 1 && (prev_tabs - tabs) < 0)? Math.abs(prev_tabs - tabs) - 1 : null
        const no_end_line = ((num !== 1 || tabs === 1) && comment?.video_comment_id && diff_line && diff_line <= num && prev_tabs < num && (!is_last_line || next_tabs !== tabs))? true : false
        const no_end_arrow = ((tabs > 1 && !prevComment) || (prevComment && is_last_line && prev_tabs < tabs && comment?.video_comment_id && parseInt(prevComment?.id) !== parseInt(comment?.video_comment_id)))? true : false
        const diff_prev = (comment?.video_comment_id && parseInt(prevComment?.id) !== parseInt(comment?.video_comment_id) && parseInt(prevComment?.video_comment_id) !== parseInt(comment?.video_comment_id) && prev_tabs === tabs)? true : false





        return <div key={`line-${comment?.id}-${num}`} className={`px-[7px] sm:px-3 lg:px-[7px] xl:px-3 2xl:px-[18px] relative flex items-center justify-center text-gray-300`}>


          {/* <div className="absolute">
            <p>{tabs}</p>
            <p>{(no_end_line)? '1' : '0'}</p>
            <p>{(no_end_arrow)? '1' : '0'}</p>
          </div> */}

        


          {/* Top arrow */}
          {(needs_first_arrow || (Math.abs(num - tabs) === 0 && (prev_tabs < num || prev_tabs > num || next_tabs > num || next_tabs < num || prev_tabs === num))) && <>
            {(!(prev_tabs < num)) &&
              <div className={`absolute h-[full] ml-[-2px] ${(diff_prev)? 'top-[-11px]' : 'top-[-5px]'}`}>
                {(diff_prev)? smallFadeIcon : replyIconLine}
              </div>
            }

  

            {(!no_end_arrow || needs_first_arrow)?
              <div className={`absolute top-0 ml-[-2px] ${(prev_tabs < num)? 'mt-[10px]' : 'mt-[15px]'}`}>
                {(needs_first_arrow)? reolyIconArrowNoCurvatrue : (prev_tabs < num)? replyIcon : replyIconNotArrow}
              </div>
            :
              <div className="absolute top-0 ml-[-2px] mt-[-8px]">
                <div className="h-[24px]">
                  {smallFadeIcon}
                </div>
                <div className="ml-[14px]">
                  {replyIconNotArrow}
                </div>
              </div>
            }
          </>}

          {/* Full streight line */}
          {(show_line) && 
            <div className={`absolute h-[full] ml-[-2px] ${(no_end_line && !no_end_arrow)? 'bottom-[-10px]' : `top-0 ${(prev_tabs < num)? 'mt-[313px] scale-y-[30]' : 'scale-y-[60]'}`}`}>
              {(no_end_line && !no_end_arrow)? fadeIcon : replyIconLine}
            </div>
          }


        </div>
      })}



    </div>
  );
}